<script lang="ts">
  import { onMount } from 'svelte';
  import { cubicOut } from 'svelte/easing';
  import type { DrawParams, TransitionConfig } from 'svelte/transition';
  import { draw } from 'svelte/transition';

  interface Props {
    // Need to calculate these points dynamically? Check out the `perfect-arrows` npm package
    fromPoint: { x: number; y: number };
    toPoint: { x: number; y: number };
    controlPoint: { x: number; y: number };
    anchorAt?: 'from' | 'to';
    drawDuration?: number;
    drawDelay?: number;
    strokeWidth?: number;
  }

  let {
    fromPoint,
    toPoint,
    controlPoint,
    anchorAt = 'from',
    drawDuration = 0,
    drawDelay = 0,
    strokeWidth = 2,
  }: Props = $props();

  let pathForMeasurement: SVGGraphicsElement;
  let bbox = $state({ x: 0, y: 0, width: 1000, height: 1000 });
  onMount(updateBBox);

  let posTop = $state(0);
  let posLeft = $state(0);

  let condition = $state(false);
  onMount(() => {
    condition = true;
  });

  const drawTransitionIfNeeded = (
    node: SVGElement & { getTotalLength(): number },
    params: DrawParams
  ) => (drawDuration > 0 ? draw(node, params) : (): TransitionConfig => ({}));

  function updateBBox() {
    requestAnimationFrame(() => {
      bbox = pathForMeasurement.getBBox({ markers: true, stroke: true });
      bbox.width += margin * 2;
      bbox.height += margin * 2;
      bbox.x -= margin;
      bbox.y -= margin;
      posTop = -(anchorPoint.y - bbox.y - margin);
      posLeft = -(anchorPoint.x - bbox.x - margin);
    });
  }
  let anchorPoint = $derived(anchorAt == 'from' ? fromPoint : toPoint);
  let markerRadius = $derived(strokeWidth * 6);
  let margin = $derived(markerRadius + strokeWidth / 2);
</script>

<svg
  viewBox="0 0 0 0"
  class="text-hintColor absolute h-0 w-0 fill-none stroke-current"
  stroke-linecap="round"
  stroke-linejoin="round"
  xmlns="http://www.w3.org/2000/svg"
>
  <defs>
    <marker
      id="arrowhead"
      viewBox="-1 -1 9 9"
      refX="3"
      refY="3"
      markerUnits="strokeWidth"
      markerWidth="10"
      markerHeight="10"
      orient="auto"
    >
      {#if condition}
        <path
          d="M 0 0 l 4 3 l -4 3"
          in:drawTransitionIfNeeded|global={{
            duration: 200,
            delay: drawDelay + drawDuration - 100,
          }}
        />
      {/if}
    </marker>
  </defs>
</svg>

<svg
  viewBox={`${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`}
  style="width: {bbox.width}px; height: {bbox.height}px; margin: -{margin}px; position: absolute; top: {posTop}px; left: {posLeft}px;"
  class="text-hintColor fill-none stroke-current"
  stroke-linecap="round"
  stroke-linejoin="round"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d={`M${fromPoint.x} ${fromPoint.y} Q${controlPoint.x} ${controlPoint.y} ${toPoint.x} ${toPoint.y}`}
    marker-end="url(#arrowhead)"
    stroke="transparent"
    stroke-width={strokeWidth}
    bind:this={pathForMeasurement}
  />
  {#if condition}
    <path
      d={`M${fromPoint.x} ${fromPoint.y} Q${controlPoint.x} ${controlPoint.y} ${toPoint.x} ${toPoint.y}`}
      marker-end="url(#arrowhead)"
      stroke-width={strokeWidth}
      in:drawTransitionIfNeeded|global={{
        duration: drawDuration,
        delay: drawDelay,
        easing: cubicOut,
      }}
    />
  {/if}
</svg>
