import { splitCell } from '@/band/instruments/guitar/blocks/splitCell';
import { bassNote, strumDown } from '@/band/instruments/guitar/GuitarActions';
import type { GuitarIntent } from '@/band/instruments/guitar/GuitarIntent';
import type { LinearizedMeasure } from '@/chart/LinearizedMeasure';
import { lerp } from '@/utilities/lerp';

export function endingBoomChuckMeasure(
  measure: LinearizedMeasure,
  { boomPower, chuckPower }: { boomPower: number; chuckPower: number }
): (GuitarIntent | null)[] {
  const firstCell = measure.cells[0];
  if (!firstCell) return [];
  return [
    ...(firstCell.split
      ? splitCell(firstCell, { boomPower, chuckPower })
      : [
          firstCell.plans.guitar?.boomStrum
            ? strumDown('bass', {
                spread: lerp(1.25, 3, boomPower),
                db: lerp(-7, 5, boomPower) + (firstCell.beats[0].chordRootChanged ? 0 : -2),
              })
            : bassNote('root', { db: lerp(-7, 5, boomPower) }),
          null,
          null,
          null,
          ...(measure.timeSignature == '3/4' ? [null, null] : []),
        ]),
    // TODO: Play 'bass' bias if chord changed on beat 4 or boomPower > 0.85?
    strumDown('high', {
      spread: lerp(3, 4.4, Math.max(boomPower, chuckPower)),
      db: lerp(-4, 2, Math.max(boomPower, chuckPower)),
    }),
  ];
}
