import { Tracker } from 'meteor/tracker';

export const waitUntilReactive = <T>(condition: () => T): Promise<NonNullable<T>> => {
  return new Promise((resolve) => {
    Tracker.autorun((comp: Tracker.Computation) => {
      const result = condition();
      if (result) {
        comp.stop();
        resolve(result);
      }
    });
  });
};

export default waitUntilReactive;
