type CustomizationSection = {
  heading: string;
  helpText?: string;
  settingKeys: string[];
};

const mixSection: CustomizationSection = {
  heading: 'Mix',
  helpText: '',
  settingKeys: ['mix'],
};

const timingSection: CustomizationSection = {
  heading: 'Advanced controls',
  settingKeys: ['timing'],
};

export const instrumentCustomizationSections: Record<InstrumentId, CustomizationSection[]> = {
  'guitar': [
    {
      heading: 'Bass notes',
      helpText: '',
      settingKeys: ['bassNotes', 'bassRuns', 'syncopatedLeadingNotes'],
    },

    {
      heading: 'Chord shapes & voicings',
      helpText: '',
      settingKeys: ['openVoicings'],
    },

    {
      heading: 'Embellishments',
      helpText: '',
      settingKeys: ['dampenedFills'],
    },

    timingSection,
    mixSection,
  ],

  'bass': [timingSection, mixSection],

  'mandolin': [timingSection, mixSection],

  'feet': [timingSection, mixSection],
};
