import { FlowRouter } from 'meteor/ostrio:flow-router-extra';

Template.accountDeletedPage.helpers({
  refund() {
    return FlowRouter.getQueryParam('refund');
  },

  donated() {
    return FlowRouter.getQueryParam('donated');
  },

  name() {
    return FlowRouter.getQueryParam('name');
  },

  email() {
    return FlowRouter.getQueryParam('email');
  },
});
