<script lang="ts">
  import type { Chord } from '@/music/Chord';

  export let capoPosition = 0;
  export let chordShape: `${Chord['letter']}${'m' | '5' | '2' | ''}`;

  export let fretsToShow = 7;
  export let scale = 1;

  let containerWidth: number, containerHeight: number;
  $: containerWidth = 164 * 0.33333 * scale;
  $: containerHeight = containerWidth * 3.2474226804; // aspect ratio of fretboard image

  const chordShapes: Partial<Record<typeof chordShape, number[]>> = {
    'G': [3, 2, 0, 0, 0, 3],
    'G5': [3, 2, 0, 0, 3, 3],
    'C': [0, 3, 2, 0, 1, 0],
    'D': [0, 0, 0, 2, 3, 2],
    'D2': [0, 0, 0, 2, 3, 0],
    'D5': [0, 0, 0, 2, 3, 5],
    'Dm': [0, 0, 0, 2, 3, 1],
    'A': [0, 0, 2, 2, 2, 0],
    'Am': [0, 0, 2, 2, 1, 0],
    'E': [0, 2, 2, 1, 0, 0],
    'Em': [0, 2, 2, 0, 0, 0],
  };

  const nutYOffsetPercentage: number = 0.03;
  const scaleLength: number = 2.21;
  const fretPositions = Array.from({ length: 11 }).map((_, fret) => {
    return scaleLength - scaleLength / Math.pow(2, fret / 12) + nutYOffsetPercentage;
  });

  const xForFret1BigE = 0.205;
  const xForFret9BigE = 0.149;
  const xSpreadFret1 = 0.611;
  const xSpreadFret9 = 0.719;

  function xPositionFor(string: number, fret: number) {
    const atBigE = xForFret1BigE + ((xForFret9BigE - xForFret1BigE) * (fret - 1)) / 8;
    const fretSpread = xSpreadFret1 + ((xSpreadFret9 - xSpreadFret1) * (fret - 1)) / 8;
    return (atBigE + fretSpread * (string / 5)) * containerWidth;
  }

  let styles: string;
  $: styles = [
    `width: ${containerWidth}px`,
    `--scale: ${scale ?? 1}`,
    `--heightScalingFactor: ${fretPositions[fretsToShow + 1]}`,
    `--gradientStop: ${fretPositions[fretsToShow] / fretPositions[fretsToShow + 1]};`,
  ].join(';');
</script>

<div style={styles} class={`container relative ${$$props.class ?? ''}`}>
  <svg
    viewBox="0 0 {containerWidth} {containerHeight}"
    version="1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    style="position: absolute; top: 0; left: 0;"
  >
    {#if capoPosition > 0}
      <rect
        x={containerWidth * 0.08}
        y={fretPositions[capoPosition] * containerHeight - 0.032 * containerHeight}
        rx={containerWidth * 0.02}
        ry={containerWidth * 0.02}
        width={containerWidth * 0.86}
        height={containerHeight * 0.022}
        class="capo"
      />
    {/if}
    {#each chordShapes[chordShape] ?? [] as fret, string (string)}
      {#if fret > 0}
        <circle
          cx={xPositionFor(string, capoPosition + fret)}
          cy={fretPositions[capoPosition + fret] * containerHeight - 0.028 * containerHeight}
          r={containerWidth * 0.07}
          class="finger"
          class:halfway-down={(chordShape.startsWith('G') && string == 1 && fret == 2) ||
            (chordShape == 'D5' && string == 5 && fret == 5)}
        />
      {/if}
    {/each}
  </svg>
</div>

<style>
  .container {
    width: calc(164px * 0.33333 * var(--scale));
    height: calc(530px * 0.33333 * var(--scale) * var(--heightScalingFactor));
    /* this background image is now preloaded in InstrumentPanel.svelte */
    background: url('/images/instruments/guitar-fretboard-vertical-9frets.20230223.png');
    background-size: calc(164px * 0.33333 * var(--scale)) calc(530px * 0.33333 * var(--scale));
    background-repeat: no-repeat;
    background-position: top center;
    mask-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) calc(var(--gradientStop) * 100%),
      rgba(0, 0, 0, 0) 100%
    );
  }

  svg {
    display: block;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 1.5;
  }

  .finger {
    fill: #444;
    /* stroke: #333; */
    /* stroke-width: 1.25px; */
  }

  .finger.halfway-down {
    fill: #999;
  }

  .capo {
    fill: #666;
  }
</style>
