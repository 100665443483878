<script lang="ts">
  import { bassCustomizationUI } from '@/band/instruments/bass/bassCustomizationUI';
  import { feetCustomizationUI } from '@/band/instruments/feet/feetCustomizationUI';
  import { guitarCustomizationUI } from '@/band/instruments/guitar/guitarCustomizationUI';
  import type { InstrumentCustomizationUI } from '@/band/instruments/InstrumentCustomizationUI';
  import type { InstrumentSetting } from '@/band/instruments/InstrumentSetting';
  import { ChopMandolinCore } from '@/band/instruments/mandolin/cores/ChopMandolinCore';
  import { mandolinCustomizationUI } from '@/band/instruments/mandolin/mandolinCustomizationUI';
  import PanelOpenerChevron from '@/band/ui/components/PanelOpenerChevron.svelte';
  import InstrumentVolumePanPanel from '@/band/ui/panels/InstrumentVolumePanPanel.svelte';
  import SwingPanel from '@/band/ui/panels/SwingPanel.svelte';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import type { Chord } from '@/music/Chord';
  import { MultiPanelModalController } from '@/ui/settings/MultiPanelModalController.svelte';

  const modalController = MultiPanelModalController.current();

  const bandMenu = getBandMenuState();
  let band = $derived($bandMenu.band);

  interface Props {
    instId: InstrumentId;
    settingKey: string;
    blueLabel?: boolean;
  }

  let { instId, settingKey, blueLabel = false }: Props = $props();

  const settingUIs = {
    guitar: guitarCustomizationUI,
    mandolin: mandolinCustomizationUI,
    bass: bassCustomizationUI,
    feet: feetCustomizationUI,
  } as Record<InstrumentId, Record<string, InstrumentCustomizationUI<any, any>>>;

  let timeSignature: TimeSignature = $state('4/4');
  trackerEffect(() => (timeSignature = band.timeSignature()));

  let currentKey: Chord['key'] | undefined = $state();
  trackerEffect(() => (currentKey = band.currentKey()));

  function open() {
    if (settingKey == 'swing') {
      modalController.zoomInto({
        component: SwingPanel,
        props: { instId },
        title: 'Upstroke Swing',
      });
    } else if (settingKey == 'mix') {
      modalController.zoomInto({
        component: InstrumentVolumePanPanel,
        props: { instId },
        title: 'Volume & Pan',
      });
    } else if (settingUI?.panel) {
      modalController.zoomInto({
        component: settingUI.panel.component,
        props: { instId, ...settingUI.panel.props },
        title: settingUI.panel.title,
      });
    }
  }
  let core = $derived(
    $bandMenu.instruments[instId].coreOrFallbackForTimeSignature($bandMenu.timeSignature)
  );
  let setting = $derived(
    (
      $bandMenu.band.instruments[instId].settings() as Record<
        string,
        InstrumentSetting<unknown> | undefined
      >
    )[settingKey]
  );
  let settingUI = $derived(settingUIs[instId][settingKey]);
  let swingMandolinNotApplicable = $derived(
    settingKey == 'swing' &&
      core == ChopMandolinCore &&
      !core.generateViz($bandMenu.mandolinSettings)[5]?.opacity
  );
  let rowStatus = $derived(
    settingKey == 'swing'
      ? {
          prefix: 'Swing:',
          text: $bandMenu.instruments[instId]
            .swing()
            .name.replace(/swing /, '')
            .replace(
              /\((\S+:\d+)\)/,
              swingMandolinNotApplicable ? '(not applicable)' : '($1 ratio)'
            ),
        }
      : settingUI?.getRowStatus({
          setting,
          core,
          currentKey,
          timeSignature,
          instId,
        })
  );
</script>

<button
  class="colors-panelOpener relative block w-full py-3 pl-3 pr-9 text-left leading-[1.35] group-first:rounded-t-lg group-last:rounded-b-lg"
  onclick={open}
>
  {#if settingKey == 'mix'}
    {@const customVolume = typeof $bandMenu.instruments[instId].customVolume() == 'number'}
    {@const customPan = typeof $bandMenu.instruments[instId].customPan() == 'number'}
    {@const pan = $bandMenu.instruments[instId].pan()}
    {@const volumeDisplay =
      $bandMenu.instruments[instId].customVolume() === 0
        ? 'OFF'
        : (Math.round($bandMenu.instruments[instId].volume() * 20) * 5).toFixed(0) + '%'}
    <span class={customVolume ? '' : 'text-gray-500'}>
      Volume {volumeDisplay}<span class:text-gray-500={!customPan}>,</span>
    </span>
    <span class={customPan ? '' : 'text-gray-500'}>
      {#if Math.abs(pan) > 0.05}
        {#if Math.abs(pan) < 0.3}
          slight
        {:else if Math.abs(pan) < 0.6}
          moderate
        {:else}
          hard
        {/if}
        {pan > 0 ? 'right' : 'left'}
        pan
      {:else}
        center pan
      {/if}
    </span>
  {:else if rowStatus}
    <div class="flex flex-wrap gap-x-2">
      {#if rowStatus.prefix}
        <span class={blueLabel ? 'text-primary-600' : 'text-gray-500'}>
          {@html rowStatus.prefix}
        </span>
      {/if}
      <span class={rowStatus.gray ? 'text-gray-500' : ''}>
        {@html rowStatus.text?.replace(/\((.*?)\)/g, '<span class="text-gray-500">($1)</span>')}
      </span>
    </div>
  {/if}
  <PanelOpenerChevron direction="right" />
</button>
