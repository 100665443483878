import { strict as assert } from 'assert';
import AudioManager from '@/audio/engine/AudioManager';
import { Playback } from '@/audio/engine/base/Playback';
import type { PlayProps } from '@/audio/engine/base/PlayProps';
import type { WebAudioChannel } from '@/audio/engine/web/WebAudioChannel';
import type { WebAudioMasterChannel } from '@/audio/engine/web/WebAudioMasterChannel';
import type { WebAudioSample } from '@/audio/engine/web/WebAudioSample';

const isIOS =
  'webkitAudioContext' in window ||
  /iP(hone|ad|od)/i.test(navigator.userAgent) ||
  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

export class WebAudioPlayback extends Playback {
  sourceNode?: AudioBufferSourceNode;
  gainNode?: GainNode;
  outputNode?: AudioNode;
  buffer?: AudioBuffer;
  readonly context: BaseAudioContext;
  readonly channel: WebAudioChannel;
  readonly outputNodePromise: Promise<AudioNode>;

  // new Playbacks should only be constructed by the Sample class
  constructor({
    sample,
    channel,
    playProps,
  }: {
    sample: WebAudioSample;
    channel: WebAudioChannel;
    playProps: PlayProps;
  }) {
    super({ sample, playProps, currentTime: channel.outputNode.context.currentTime });
    this.context = channel.outputNode.context;

    let resolveOutputNodePromise: (result: AudioNode) => void;
    this.outputNodePromise = new Promise<AudioNode>((res) => (resolveOutputNodePromise = res));

    this.channel = channel;
    channel.addPlayback(this);

    void sample.load().then(() => {
      if (this.destroyed) return;
      assert(sample.audioBuffer, "AudioBuffer should be loaded, but isn't");
      this.buffer = sample.audioBuffer;

      this.sourceNode = this.context.createBufferSource();
      this.sourceNode.buffer = this.buffer;
      this.sourceNode.onended = () => this.destroy();

      const secondsPastDeadline = Math.max(0, this.context.currentTime - this.startTime);
      if (secondsPastDeadline > 0) {
        playProps.fadeInDuration = playProps.fadeInDuration ?? 0.01;
      }

      this.offset += secondsPastDeadline;

      const globalPlaybackRate = (AudioManager.mixer?.masterChannel as WebAudioMasterChannel)
        .masterPlaybackRate;
      const thisPlaybackRate = playProps.pitchShift ? 1.059463 ** playProps.pitchShift : 1;
      const playbackRate = globalPlaybackRate * thisPlaybackRate;
      if (playbackRate != 1) {
        this.sourceNode.playbackRate.value = playbackRate;
      }

      this.gainNode = this.context.createGain();
      this.gainNode.gain.value = playProps.fadeInDuration ? 0 : (playProps.volume ?? 1);
      this.sourceNode.connect(this.gainNode);
      this.outputNode = this.gainNode;

      this.sourceNode.start(this.startTime, this.offset, this.playDuration);

      if (playProps.fadeInDuration) {
        this.gainNode.gain.setTargetAtTime(
          playProps.volume || 1,
          Math.max(this.startTime, this.context.currentTime),
          playProps.fadeInDuration / 3
        );
      }

      resolveOutputNodePromise(this.outputNode);
    });
  }

  async fade({ to, at, duration }: { to: number; at: number; duration: number }): Promise<void> {
    await this.outputNodePromise;
    if (this.destroyed) return;
    const deadline = at ?? this.context.currentTime;
    this.gainNode?.gain.setTargetAtTime(to, deadline, duration / 3);
  }

  async stop({
    atTime,
    fadeDuration = 0.01,
  }: { atTime?: number; fadeDuration?: number } = {}): Promise<void> {
    await this.outputNodePromise;
    if (this.destroyed) return;

    let timeoutSeconds;

    if (atTime) {
      if (this.scheduledStopTime && this.scheduledStopTime <= atTime) return;
      this.scheduledStopTime = atTime;
      void this.fade({ to: 0, at: atTime, duration: fadeDuration });
      timeoutSeconds = atTime - this.context.currentTime + fadeDuration * 2;
    } else {
      // stop immediately... well, after a fadeout (to prevent popping)
      this.scheduledStopTime = this.context.currentTime;
      this.gainNode?.gain.cancelScheduledValues(this.context.currentTime);
      this.gainNode?.gain.linearRampToValueAtTime(0, this.context.currentTime + fadeDuration);
      timeoutSeconds = fadeDuration + 0.005;
    }

    window.setTimeout(() => {
      this.emit('stopped');
      void this.destroy(); // an "end" event will be dispatched in the destroy function
    }, timeoutSeconds * 1000);
  }

  _destroy(): void {
    if (this.sourceNode) {
      // Remove onended listener or we'll end up in an infinite loop
      this.sourceNode.onended = null;
      this.sourceNode.stop();
    }

    // now, manually dispatch the end event since the listener has been removed
    this.emit('end');

    if (this.sourceNode) {
      this.sourceNode.disconnect();
      if (isIOS) {
        try {
          this.sourceNode.buffer = AudioManager.webAudioScratchBuffer; // fixes a memory leak in iOS
        } catch (e) {
          /* throws in other browsers */
        }
      }
    }

    if (this.gainNode && this.gainNode.numberOfOutputs != 0) {
      this.gainNode.disconnect();
    }

    //@ts-expect-error Setting things null that "shouldn't" be null
    this.sourceNode = this.gainNode = this.outputNode = this.buffer = null;
  }
}
