import { ReactiveVar } from 'meteor/reactive-var';
import { debounce } from 'underscore';

const useCompactTools = new ReactiveVar<boolean | undefined>(undefined);

export function shouldUseCompactToolsDropdown(): boolean {
  if (typeof useCompactTools.get() == 'undefined') {
    $(window).on('resize', debounce(recalculateCTD, 300));
    $(window).on('orientationchange', debounce(recalculateCTD, 300));
    recalculateCTD();
  }
  return !!useCompactTools.get();
}

function recalculateCTD() {
  const currentFontSizePx = parseFloat(
    getComputedStyle(document.body).getPropertyValue('font-size')
  );
  const fontScalingFactor = currentFontSizePx / 16;
  const maxWidthScaledWithCurrentFontSize = 390 * fontScalingFactor;
  useCompactTools.set(window.innerWidth < maxWidthScaledWithCurrentFontSize);
}
