<script lang="ts">
  import MultiPanelModalTopNav from '@/band/ui/components/MultiPanelModalTopNav.svelte';
  import type { Song } from '@/chart/Song';
  import SectionSettingsRootScreen from '@/editor/ui/SectionSettingsRootScreen.svelte';
  import MultiPanelModal from '@/ui/settings/MultiPanelModal.svelte';
  import { MultiPanelModalController } from '@/ui/settings/MultiPanelModalController.svelte';

  interface Props {
    song: Song;
    sectionIndex: number;
  }

  let { song, sectionIndex }: Props = $props();

  // This component is the root of the menu, so it creates the context.
  const modalController = MultiPanelModalController.create({
    component: SectionSettingsRootScreen,
    props: { song, sectionIndex },
  });
</script>

<MultiPanelModal {modalController} --panelMinWidth="17rem" --panelWidth="20rem">
  {#snippet topnav()}
    <MultiPanelModalTopNav />
  {/snippet}
</MultiPanelModal>
