import * as Sentry from '@sentry/browser';
import { strict as assert } from 'assert';
import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { eventTracker } from '@/browser/analytics/eventTracker';
import { Crnt } from '@/Crnt';
import { MusicLibrary } from '@/library/MusicLibrary';
import { Roles } from '@/Roles';

Template.editMedleyMenu.helpers({
  medleyBelongsToCurrentUser() {
    const medleyUserId = Crnt.medley()?.userId();
    return medleyUserId && medleyUserId == Meteor.userId();
  },

  medleyBelongsToAnotherUser() {
    const medleyUserId = Crnt.medley()?.userId();
    return medleyUserId && medleyUserId != Meteor.userId();
  },

  showAddSong() {
    return Crnt.medley() && Roles.userHasPermission(Meteor.userId(), 'medleys.insert');
  },

  showCopy() {
    return Crnt.medley() && Roles.userHasPermission(Meteor.userId(), 'medleys.insert');
  },

  showDelete() {
    return (
      Crnt.medley()?.id() &&
      Roles.userHasPermission(Meteor.userId(), 'medleys.remove', Crnt.medley())
    );
  },
});

Template.editMedleyMenu.events({
  'click .js-editMedley': (event, instance) => {
    Meteor.defer(() => {
      Modal.show('editMedleyModal', { medley: Crnt.medley() });
    });
  },

  'click .js-copyAction': function (event) {
    event.preventDefault();
    Meteor.defer(() => {
      // defer is needed to hide menu
      FlowRouter.go('copy-medley', { medleyId: Crnt.medley()?.id() });
    });
  },

  'click .js-deleteAction': function (event) {
    event.preventDefault();
    bootbox.confirm({
      size: 'small',
      message: 'Are you sure you want to delete this medley? There is no undo!',
      closeButton: false,
      buttons: {
        confirm: {
          label: 'Delete',
          className: 'btn-danger',
        },
      },
      callback(result) {
        if (result) {
          const medley = Crnt.medley();
          const medleyId = medley?.id();
          assert(medleyId, "Trying to delete medley that isn't loaded?");
          const name = medley.name();
          MusicLibrary.medleys
            .delete(medleyId)
            .then(() => {
              FlowRouter.go('song-index');
              Bert.alert('Medley has been deleted.', 'success');
              eventTracker.medleyDeleteFromEditMenu({ label: name });
            })
            .catch((error) => {
              Sentry.captureException(error);
              Bert.alert(error.message, 'danger');
            });
        }
      },
    });
  },
});
