<script lang="ts" generics="OptionValue extends unknown">
  import { createEventDispatcher } from 'svelte';
  import { fade } from 'svelte/transition';
  import type { Instance as TippyInstance, Props as TippyProps } from 'tippy.js';
  import { makeTippyMenu } from '@/ui/dropdowns/makeTippyMenu';

  type Option = {
    caption: string;
    value: OptionValue;
  };

  let tippyInstance: TippyInstance | undefined = $state();
  function tippyMenu(node: HTMLElement, params: Partial<TippyProps> = {}) {
    if (noDropdown) return { destroy: () => {} };
    tippyInstance = makeTippyMenu(node, params, { keysThatShow: [' ', 'Enter'] });
    return { destroy: () => tippyInstance?.destroy() };
  }

  function selectNeighbor(direction: 'next' | 'previous') {
    const index = options.findIndex((option) => option.value == selectedValue);
    let newIndex = index + (direction == 'next' ? 1 : -1);
    if (wrapAround) newIndex = (newIndex + options.length) % options.length;
    const option = options[newIndex];
    if (option) dispatch('select', option.value);
  }

  const dispatch = createEventDispatcher<{ select: OptionValue }>();

  interface Props {
    options?: Option[];
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    selectedValue?: OptionValue | undefined;
    caption?: string;
    wrapAround?: boolean;
    doCrossfades?: boolean;
    noDropdown?: boolean;
    captureLeftRightKeys?: boolean;
  }

  let {
    options = [],
    selectedValue = undefined,
    caption = '',
    wrapAround = false,
    doCrossfades = false,
    noDropdown = false,
    captureLeftRightKeys = false,
  }: Props = $props();

  function onKeyDown(event: KeyboardEvent) {
    if (!captureLeftRightKeys) return;
    if (
      ['ArrowLeft', 'ArrowRight'].includes(event.key) &&
      !(event.altKey || event.metaKey || event.shiftKey || event.ctrlKey)
    ) {
      event.preventDefault();
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      event.key == 'ArrowLeft' ? selectNeighbor('previous') : selectNeighbor('next');
    }
  }
  let selectedOption = $derived(options.find((option) => option.value == selectedValue));
</script>

<svelte:window onkeydown={onKeyDown} />

<div class="flex justify-center">
  <button
    class="btn nextPreviousButton flex h-8 w-8 items-center justify-center"
    onclick={() => selectNeighbor('previous')}
    aria-label="Previous"
  >
    <i class="smi smi-chevron-left text-2xl" aria-hidden="true"></i>
  </button>
  <div
    use:tippyMenu={{ 'theme': 'light', 'placement': 'bottom' }}
    class="flex flex-1 justify-center"
  >
    <button
      class="btn-base block w-full bg-transparent font-medium text-gray-900"
      style="display: grid; grid-template: 'container'; place-items: center; place-content: center;"
    >
      {#if doCrossfades}
        {#key caption || selectedOption?.caption}
          <div style="grid-area: container;" transition:fade={{ duration: 100 }}>
            {caption || selectedOption?.caption}
          </div>
        {/key}
      {:else}
        {caption || selectedOption?.caption}
      {/if}
    </button>
    <div role="menu" class="flex flex-col py-2">
      {#each options as option, index (index)}
        <button
          class="btn m-0 block rounded-none bg-transparent px-4 py-1 text-left font-normal text-gray-800"
          onclick={() => {
            dispatch('select', option.value);
            tippyInstance?.hide();
          }}
          role="menuitem"
          aria-current={option.value == selectedOption?.value ? true : null}
        >
          {option.caption}
        </button>
      {/each}
    </div>
  </div>
  <button
    class="btn nextPreviousButton flex h-8 w-8 items-center justify-center"
    onclick={() => selectNeighbor('next')}
    aria-label="Next"
  >
    <i class="smi smi-chevron-right text-2xl" aria-hidden="true"></i>
  </button>
</div>

<style>
  .nextPreviousButton {
    border-radius: 0.3125rem;
    border: 1px solid var(--muted-100);
    background: #fff;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
  }
</style>
