import UserPreferences from '@/user/UserPreferences';
import { UserProfile } from '@/user/UserProfile';

Template.countInSettingsMenu.onRendered(() => {
  const instance = Template.instance();
  instance.autorun(() => {
    const countInDisabled = !UserPreferences.get('countInEnabled');
    instance.$('li').not('.js-countInEnabled-container').toggleClass('disabled', countInDisabled);
    instance.$('.songOptionHeading').toggleClass('disabled', countInDisabled);
  });
  UserProfile.recordMilestone('COUNT_IN_MENU_OPEN');
});

Template.countInSettingsMenu.helpers({});

Template.countInSettingsMenu.events({});
