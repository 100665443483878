<script lang="ts">
  import HorizontalRadioButtons from '@/band/ui/components/HorizontalRadioButtons.svelte';
  import PanelSettingSection from '@/band/ui/components/PanelSettingSection.svelte';
  import PrettySlider from '@/band/ui/components/PrettySlider.svelte';
  import PanelModalLayout from '@/band/ui/panels/PanelModalLayout.svelte';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';
  import { slideFade } from '@/ui/svelte-transitions';

  const bandMenu = getBandMenuState();

  let setting = $derived($bandMenu.bassSettings.onbeatNotes);

  const optionsForMode = [
    { value: 'r', caption: 'Root Only' },
    { value: 'a', caption: 'Root–Fifth' },
    // { value: 'w', caption: 'Walking' },
  ] as const;

  const optionsForRootOctaves = [
    { value: 'low', caption: 'Low' },
    { value: 'high', caption: 'High' },
    { value: 'alternating', caption: 'Low–High' },
  ] as const;

  const optionsForAlternatingOctaves = [
    { value: 'low', caption: 'Low' },
    { value: 'middle', caption: 'Middle' },
    { value: 'high', caption: 'High' },
    { value: 'random', caption: 'Mix' },
  ] as const;

  let currentMode = $derived(setting.rootOnly ? 'r' : setting.walking ? 'w' : 'a');

  function changeMode(mode: string) {
    if (mode == 'r') $bandMenu.bass.setSetting('onbeatNotes', setting.withRootOnly());
    if (mode == 'a') $bandMenu.bass.setSetting('onbeatNotes', setting.withAlternatingBass());
    if (mode == 'w') $bandMenu.bass.setSetting('onbeatNotes', setting.withWalkingBass());
  }
</script>

<PanelModalLayout>
  {#snippet header()}
    <header class="panelHeader">
      This setting is used to determine which
      <span class="whitespace-nowrap">notes to play on</span> each downbeat.
    </header>
  {/snippet}

  {#snippet main()}
    <div class="px-3 py-4">
      <HorizontalRadioButtons
        options={optionsForMode}
        selectedValue={currentMode}
        on:select={({ detail }) => changeMode(detail)}
      />

      <div class="px-4 pt-2 text-sm italic text-gray-500">
        {#if currentMode == 'r'}
          The bass will only play the root note of the chord.
        {:else if currentMode == 'a'}
          The bass will alternate between the root and fifth.
        {:else if currentMode == 'w'}
          The bass will “walk around” slowly, mostly hitting the 1, 3, 5, and 6 tones of the chord.
        {/if}
      </div>

      {#if currentMode == 'a' && false}
        <div class="pt-6" transition:slideFade>
          <PanelSettingSection
            title="Use leading notes (not currently functional)"
            helpText="Leading notes are bass note that the bass plays just before a chord change which “lead your ear” to the next chord."
          >
            <div class="flex items-center gap-5 px-4 py-2">
              <div class="text-center text-muted-500">Never</div>
              <div class="flex-1">
                <PrettySlider
                  min={0}
                  max={4}
                  step={1}
                  value={4 * setting.leadingNoteFrequency}
                  thumbDiameter={1.5}
                  tickHeight={0.175}
                  tickCount={5}
                  on:change={({ detail }) =>
                    $bandMenu.bass.setSetting(
                      'onbeatNotes',
                      setting.withLeadingNoteFrequency(detail / 4)
                    )}
                />
              </div>
              <div class="text-center text-muted-500">Often</div>
            </div>
          </PanelSettingSection>
          {#if false}
            <div class="px-4 pt-2 text-sm text-gray-500" transition:slideFade>
              Bass notes take precidence over leading notes. Since your bass runs setting is turned
              up, you may not hear quite so many leading notes.
            </div>
          {/if}
        </div>
      {/if}

      {#if currentMode == 'r'}
        <div class="pt-6">
          <PanelSettingSection
            title="Octave preference"
            helpText="Here you can select which octave(s) you'd like the bass to play"
            whiteBg={false}
          >
            <HorizontalRadioButtons
              options={optionsForRootOctaves}
              selectedValue={setting.rootOctaves}
              on:select={({ detail }) =>
                $bandMenu.bass.setSetting('onbeatNotes', setting.withRootOctaves(detail))}
            />
          </PanelSettingSection>

          <div class="mt-2 min-h-[3em] px-4 text-sm italic text-gray-500">
            {#if setting.rootOctaves == 'low'}
              The bass will play root notes in the lowest octave possible (recommended).
            {:else if setting.rootOctaves == 'high'}
              The bass will play root notes in a higher octave.
            {:else if setting.rootOctaves == 'alternating'}
              The bass will alternate between the lowest root note and a note one octave above it.
            {/if}
          </div>
        </div>
      {:else if currentMode == 'a'}
        <div class="pt-6">
          <PanelSettingSection title="Octave preference" whiteBg={false}>
            <HorizontalRadioButtons
              options={optionsForAlternatingOctaves}
              selectedValue={setting.alternatingOctaves}
              on:select={({ detail }) =>
                $bandMenu.bass.setSetting('onbeatNotes', setting.withAlternatingOctaves(detail))}
            />
          </PanelSettingSection>

          <div class="mt-2 min-h-[3em] px-4 text-sm italic text-gray-500">
            {#if setting.alternatingOctaves == 'low'}
              The bass will play in the lowest octave possible.
            {:else if setting.alternatingOctaves == 'middle'}
              The bass will play somewhere in the middle of the instrument's range (recommended).
            {:else if setting.alternatingOctaves == 'high'}
              The bass will play notes in a higher octave.
            {:else if setting.alternatingOctaves == 'random'}
              The bass will mix it up, choosing randomly between different octaves.
            {/if}
          </div>
        </div>
      {:else if currentMode == 'w'}
        <div transition:slideFade>
          <div class="px-4 text-center text-sm text-gray-500">
            Leading bass notes will be incorporated into walking bass lines whenever possible.
          </div>
        </div>
      {/if}
    </div>
  {/snippet}
</PanelModalLayout>
