import * as Sentry from '@sentry/browser';
import { isError, isPlainObject } from '@sindresorhus/is';
import { Meteor } from 'meteor/meteor';
import { ReactiveVar } from 'meteor/reactive-var';
// @ts-expect-error No types for meteor/reload
import { Reload } from 'meteor/reload';
import registerServiceWorker from '@/browser/registerServiceWorker';

// With inspiration from Reloader package:
//   https://goo.gl/ZvKCJZ (old) and https://github.com/quavedev/reloader (new)

const _updateAvailable = new ReactiveVar(false);
const _swActive = new ReactiveVar(false);
const _swRegStarted = new ReactiveVar(false);
const _swWaiting = new ReactiveVar(false);
const waitingServiceWorkers: ServiceWorker[] = [];

const MIN_INSTALL_SPACE = 25 * 1000 * 1000;

// these need to be var, not let
let reloadImmediatelyOnRetry: boolean;
let savedRetryFunc: () => void;

// Add these type declarations at the top of the file
type WebkitTemporaryStorage = {
  queryUsageAndQuota(
    success: (usage: number, quota: number) => void,
    error: (error: Error) => void
  ): void;
};

type WebkitStorageInfo = {
  TEMPORARY: number;
  queryUsageAndQuota(
    type: number,
    success: (usage: number, quota: number) => void,
    error: (error: Error) => void
  ): void;
};

type ExtendedNavigator = Navigator & {
  webkitTemporaryStorage?: WebkitTemporaryStorage;
};

type ExtendedWindow = Window & {
  webkitStorageInfo?: WebkitStorageInfo;
  todesktop?: boolean;
};

type CordovaGlobal = {
  WebAppLocalServer: {
    switchToPendingVersion(callback: () => void): void;
  };
};

if (Meteor.isCordova) {
  document.addEventListener(
    'resume',
    function cordovaResumeReloadHandler() {
      reloadImmediatelyOnRetry = true;
      Meteor.setTimeout(() => {
        if (savedRetryFunc) {
          savedRetryFunc();
        } else {
          if (!window.location.pathname.includes('/songs') && ClientManager.updateAvailable()) {
            ClientManager.update();
          }
        }
      }, 0);
      Meteor.setTimeout(() => {
        reloadImmediatelyOnRetry = false;
      }, 5000);
    },
    false
  );
}

Meteor.startup(() => {
  if (/pwa=1/.test(window.location.href) || window.todesktop) {
    window.addEventListener('load', () => void registerServiceWorker());
  }
});

const swReadyPromise = new Promise<void>((resolve) => {
  if (!('serviceWorker' in navigator)) return;
  if (navigator.serviceWorker.controller) {
    resolve();
  } else {
    navigator.serviceWorker.addEventListener('controllerchange', () => resolve());
  }
});
void swReadyPromise.then(() => _swActive.set(true));

// eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
Reload._onMigrate('sm', (retry: () => void) => {
  if (reloadImmediatelyOnRetry) return [true];

  savedRetryFunc = retry;

  // const shouldReloadNow =     // this tends to encourage infinite reload loops
  //   Meteor.isDevelopment ||
  //   !Meteor.userId() ||
  //   !window.location.href.includes('/app');

  // if (shouldReloadNow) {
  //   Reload.trigger();
  //   return [false];
  // }

  _updateAvailable.set(true);

  if ('serviceWorker' in navigator) {
    void navigator.serviceWorker.ready.then((reg) => reg.update());
  }

  return [false];
});

export const ClientManager = {
  _setWaitingServiceWorker(waiting: ServiceWorker) {
    _swWaiting.set(true);
    waitingServiceWorkers.push(waiting);
    if (clientModules.splashScreen.visible()) {
      ClientManager.update(); // in reality, this is mostly an escape hatch if the site doesn't load properly
    }
  },

  updateAvailable() {
    return _updateAvailable.get();
  },

  updateReady() {
    const canDoItOldSchool =
      !ClientManager.serviceWorkerActive() &&
      waitingServiceWorkers.length === 0 &&
      Meteor.status().connected;
    return _updateAvailable.get() && (_swWaiting.get() || canDoItOldSchool);
  },

  serviceWorkerActive() {
    return _swActive.get();
  },

  async installServiceWorker() {
    try {
      await ClientManager.checkForSpace();
      _swRegStarted.set(true);
      await registerServiceWorker();
    } catch {
      bootbox.alert({
        title: 'Insufficient Storage Space',
        message:
          "Strum Machine doesn't have enough room to install. Please free up some storage space on your device and try again.",
      });
    }
  },

  serviceWorkerRegistrationStarted() {
    return _swRegStarted.get();
  },

  update() {
    try {
      clientModules.splashScreen.updateMode();
    } catch (e) {
      Sentry.captureException(e);
    }

    let swWillTakeOver = false;
    waitingServiceWorkers
      .filter((reg) => reg.state === 'installed')
      .forEach((sw) => {
        try {
          swWillTakeOver = true; // the controllerchange event will trigger window reload
          reloadImmediatelyOnRetry = true;
          sw.postMessage({ type: 'SKIP_WAITING' }); // sets as new service worker as active for all tabs and triggers all to reload
          // Just in case the above doesn't work, reload the page
          setTimeout(() => {
            Sentry.captureMessage('Service worker skip-waiting failed. Reloading.');
            setTimeout(() => forceBrowserReload(), 500);
          }, 3000);
        } catch (e) {
          console.error(e);
          Sentry.captureException(e);
        }
      });
    if (!swWillTakeOver) {
      ClientManager.reload();
    }
  },

  reloadStarted: false,

  reload() {
    if (savedRetryFunc) {
      reloadImmediatelyOnRetry = true;
      savedRetryFunc();
      return;
    }
    ClientManager.reloadStarted = true;

    if (Meteor.isCordova) {
      (global as unknown as CordovaGlobal).WebAppLocalServer.switchToPendingVersion(() => {
        forceBrowserReload();
      });
    } else {
      forceBrowserReload();
    }
  },

  async getStorageSpace(): Promise<{ quota?: number; usage?: number }> {
    if (navigator.storage?.estimate) {
      return await navigator.storage.estimate();
    } else if ((navigator as ExtendedNavigator).webkitTemporaryStorage?.queryUsageAndQuota) {
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (navigator as ExtendedNavigator).webkitTemporaryStorage!.queryUsageAndQuota(
          (usage: number, quota: number) => resolve({ quota, usage }),
          reject
        );
      });
    } else if ((window as ExtendedWindow).webkitStorageInfo) {
      return new Promise((resolve, reject) => {
        (window as ExtendedWindow).webkitStorageInfo?.queryUsageAndQuota(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          (window as ExtendedWindow).webkitStorageInfo!.TEMPORARY,
          (usage: number, quota: number) => resolve({ quota, usage }),
          reject
        );
      });
    }
    // Client doesn't support a storage estimation API:
    return { quota: NaN, usage: NaN };
  },

  async checkForSpace() {
    const result = await ClientManager.getStorageSpace();
    // The following equations will work just fine if quota and/or space are NaN
    result.quota ??= NaN;
    result.usage ??= NaN;
    if (result.quota < MIN_INSTALL_SPACE || result.usage / result.quota > 0.95) {
      throw new Error('Insufficient storage space');
    }
  },

  async askForPersistentStorage() {
    if (!navigator.storage || !navigator.storage.persisted) return;
    if (!navigator.permissions || !navigator.permissions.query) return;
    try {
      const permission = await navigator.permissions.query({ name: 'persistent-storage' });
      if (permission.state === 'prompt') {
        return 'prompt';
      } else {
        if (ClientManager.serviceWorkerActive() && navigator.storage && navigator.storage.persist) {
          await navigator.storage.persist();
        }
        return 'granted';
      }
    } catch (err) {
      return 'prompt';
    }
  },
};

function forceBrowserReload() {
  // reload page, avoid re-validating assets with the server: https://goo.gl/4AwcvA
  if (window.location.hash || window.location.href.endsWith('#')) {
    window.location.reload();
  } else {
    window.location.replace(window.location.href);
  }
}

function refreshOnNewController() {
  let reloadInProgress = false;
  navigator.serviceWorker.addEventListener('controllerchange', (_event) => {
    // Ensure refresh is only called once. This works around a bug in "force update on reload".
    if (reloadInProgress) return;
    // the following line skips reload on initial service worker installation; however, we want to reload now
    // if ( noExistingServiceWorker && !ClientManager.updateAvailable() ) return;
    reloadInProgress = true;
    ClientManager.reload();
  });
}

// Init for service-worker-enabled browsers
if ('serviceWorker' in navigator) {
  if (!Meteor.isCordova) {
    refreshOnNewController();
  }
  navigator.serviceWorker.addEventListener('message', (event) => {
    if (isPlainObject(event.data) && event.data.type == 'ERROR') {
      Sentry.captureException(event.data.error);
    }
    if (isPlainObject(event.data) && event.data.type == 'SW_DESTROYED') {
      window.location.href = '/';
    }
  });
  if (navigator.serviceWorker.controller) {
    window.addEventListener('load', () => void registerServiceWorker());
  }
  if (!Meteor.isCordova && !/HeadlessChrome|Tizen/.test(navigator.userAgent)) {
    window.addEventListener('load', () => void reportLowSpaceToSentry());
  }
}

// This is just for reporting to Sentry
async function reportLowSpaceToSentry() {
  try {
    const { quota, usage } = await ClientManager.getStorageSpace();
    if (quota && quota < MIN_INSTALL_SPACE) {
      Sentry.withScope((scope) => {
        scope.setExtra('quota', quota);
        scope.setExtra('usage', usage);
        scope.setExtra('quotaMb', `${(quota / 1000000).toFixed(1)} MB`);
        Sentry.captureMessage('Storage quota is insufficient to install.', 'info');
      });
    }
  } catch (err: unknown) {
    Sentry.captureMessage(
      `Error reporting low space. ${isError(err) ? err.message : 'Unknown error'}`
    );
  }
}
