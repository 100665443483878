import { strumDown, strumUp } from '@/band/instruments/guitar/GuitarActions';
import type { GuitarCore } from '@/band/instruments/guitar/GuitarCore';
import { swingOptions } from '@/band/swingOptions';

export const CelticSlipJigGuitarCore: GuitarCore = {
  id: 'csj',
  title: 'Celtic-style slip jig strum',

  timeSignatures: ['9/8'],

  linkedSettingsKeys: [],
  otherSettingsKeys: [],

  chordStyle: 'celtic',

  swingCategory: 'celtic',
  defaultSwing: swingOptions['0'],

  presets: [
    {
      name: 'Celtic slip jig',
      settings: {},
    },
  ],

  processMeasure(measure, _settings) {
    return measure.beats[6]?.chordChanged && measure.endOfSong
      ? [
          strumDown('bass', { spread: Math.random() + 2, db: 0 }),
          measure.beats[0].chordChanged
            ? null
            : strumUp('mid', { spread: Math.random() + 1, db: -8 }),
          strumDown('mid', { spread: Math.random() + 2, db: -3 }),
          strumDown('bass', { spread: Math.random() + 2, db: 0 }),
          strumUp('mid', { spread: Math.random() + 1, db: -7 }),
          strumDown('low', { spread: Math.random() + 2, db: -4 }),
          strumDown('bass', { spread: Math.random() + 2, db: 0 }),
          ...(measure.endOfSong
            ? []
            : [
                strumUp('mid', { spread: Math.random() + 1, db: -7 }),
                strumDown('low', { spread: Math.random() + 2, db: -4 }),
              ]),
        ]
      : Math.random() < 0.6
        ? [
            strumDown('bass', { spread: Math.random() + 2, db: 0 }),
            measure.beats[0].chordChanged
              ? null
              : strumUp('mid', { spread: Math.random() + 1, db: -8 }),
            strumDown('high', { spread: Math.random() + 3, db: -3 }),
            strumDown('bass', { spread: Math.random() + 2, db: 0 }),
            strumUp('mid', { spread: Math.random() + 1, db: -7 }),
            strumDown('low', { spread: Math.random() + 2, db: -4 }),
            strumDown('mid', { spread: Math.random() + 1, db: -6 }),
            strumUp('high', { spread: Math.random() + 1, db: -2 }),
            strumDown('low', { spread: Math.random() + 1, db: -4 }),
          ]
        : Math.random() < 0.5
          ? [
              strumDown('bass', { spread: Math.random() + 2, db: 0 }),
              null,
              strumDown('high', { spread: Math.random() + 3, db: -1 }),
              null,
              strumUp('mid', { spread: Math.random() + 2, db: -2 }),
              strumDown('bass', { spread: Math.random() + 2, db: 0 }),
              strumDown('mid', { spread: Math.random() + 1, db: -6 }),
              strumUp('high', { spread: Math.random() + 1, db: -2 }),
              strumDown('low', { spread: Math.random() + 1, db: -4 }),
            ]
          : [
              strumDown('bass', { spread: Math.random() + 2, db: 0 }),
              null,
              strumUp('high', { spread: Math.random() + 1, db: -2 }),
              strumDown('low', { spread: Math.random() + 2, db: -4 }),
              strumUp('mid', { spread: Math.random() + 1, db: -5 }),
              strumDown('low', { spread: Math.random() + 3, db: -3 }),
              strumDown('high', { spread: Math.random() + 1, db: -2 }),
              strumUp('mid', { spread: Math.random() + 1, db: -5 }),
              strumDown('low', { spread: Math.random() + 2, db: -4 }),
            ];
  },

  generateViz(settings) {
    settings;
    return [
      { dir: 'd', biasY: 0.2, tail: 0.4, thickness: 0.9 },
      { dir: 'u', biasY: 0.5, tail: 0.3, thickness: 0.4, opacity: 0.5 },
      { dir: 'd', biasY: 1, tail: 0.6, thickness: 0.7 },
      { dir: 'd', biasY: 0.4, tail: 0.4, thickness: 0.9, opacity: 0.5 },
      { dir: 'u', biasY: 0.7, tail: 0.3, thickness: 0.4 },
      { dir: 'd', biasY: 0.6, tail: 0.6, thickness: 0.7 },
      { dir: 'd', biasY: 0.4, tail: 0.4, thickness: 0.9, opacity: 0.5 },
      { dir: 'u', biasY: 0.7, tail: 0.3, thickness: 0.4 },
      { dir: 'd', biasY: 0.6, tail: 0.6, thickness: 0.7 },
    ];
  },
};
