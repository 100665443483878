import { eventTracker } from '@/browser/analytics/eventTracker';
import { Crnt } from '@/Crnt';
import { Chord } from '@/music/Chord';
import closeNearestTippy from '@/ui/dropdowns/closeNearestTippy';
import { UserProfile } from '@/user/UserProfile';

Template.editModeKeyChooser.helpers({
  currentKey() {
    return Crnt.song()?.key();
  },

  setKeyFunc() {
    const instance = Template.instance();
    return (newKey) => {
      var willClose = !Crnt.song()?.sections.length;
      eventTracker.keySet({ key: newKey, editMode: true });
      UserProfile.recordMilestone('KEY_CHANGE');
      Crnt.song()?.setKey(newKey);
      // In 2017, I decided I didn't want the capo to reset when the key changed.
      // But I changed my mind in 2020, hence the setCapo call below.
      Crnt.song()?.prefs.setCapo(0);
      if (willClose) closeNearestTippy(instance.find('.editModeKeyChooser'));
    };
  },

  keySelected(key) {
    return key == Crnt.song()?.keyHeard() ? 'selected' : '';
  },

  settingInitialKey() {
    return Tracker.nonreactive(() => !Crnt.song()?.sections.length);
  },

  minor() {
    return /m$/.test(Crnt.song()?.key());
  },
});

Template.editModeKeyChooser.events({
  'click .js-setMajor': function (event, instance) {
    event.preventDefault();
    const currentKey = Crnt.song()?.key();
    if (!currentKey) return;
    const newKey = new Chord(currentKey).withNewType('').toKeyString();
    Crnt.song()?.setKey(newKey);
  },

  'click .js-setMinor': function (event, instance) {
    event.preventDefault();
    const currentKey = Crnt.song()?.key();
    if (!currentKey) return;
    const newKey = new Chord(currentKey).withNewType('m').toKeyString();
    Crnt.song()?.setKey(newKey);
  },
});
