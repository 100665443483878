<script lang="ts">
  import BandHelpPagePanel from '@/band/ui/panels/BandHelpPagePanel.svelte';
  import PanelModalLayout from '@/band/ui/panels/PanelModalLayout.svelte';
  import { HelpLibrary } from '@/help/HelpLibrary';
  import { MultiPanelModalController } from '@/ui/settings/MultiPanelModalController.svelte';

  const modalController = MultiPanelModalController.current();

  interface Props {
    pageId?: string;
  }

  let { pageId = '' }: Props = $props();

  let currentPageContent = $derived(HelpLibrary.fetch(pageId)?.html);

  function handleClick(event: Event) {
    const target = event.target as HTMLElement;
    if (target.tagName !== 'A') return;
    const href = target.getAttribute('href');
    if (href?.includes('help')) {
      event.preventDefault();
      const pageId = href.substring(href.indexOf('/help') + 6) || 'index';
      modalController.zoomInto({ component: BandHelpPagePanel, props: { pageId }, title: 'Help' });
    }
  }
</script>

<PanelModalLayout mainBg="white">
  {#snippet main()}
    <div class="px-5 py-4">
      <!-- svelte-ignore a11y_click_events_have_key_events -->
      <!-- svelte-ignore a11y_no_noninteractive_element_interactions -->
      <article
        class="helpPage js-helpPage helpPage--{pageId}"
        style="padding: 0; font-size: 14px; line-height: 1.25rem;"
        onclick={handleClick}
      >
        {@html currentPageContent}
      </article>
    </div>
  {/snippet}
</PanelModalLayout>
