import { ReactiveVar } from 'meteor/reactive-var';
import { Tracker } from 'meteor/tracker';

declare const jQuery: (el: any) => any;

export class ElementSwapAnimator {
  _swapInProgress = new ReactiveVar(false);
  swapInProgress = (): boolean => this._swapInProgress.get();

  swap({
    selector,
    index,
    direction,
    duration = 400,
  }: {
    selector: string;
    index: number;
    direction: 1 | -1;
    duration: number;
  }): void {
    this._swapInProgress.set(true);
    Tracker.afterFlush(() => {
      const elements = document.querySelectorAll(selector);
      const firstEl = elements[index];
      const secondEl = elements[index + direction];
      if (!firstEl || !secondEl) {
        this._swapInProgress.set(false);
        return;
      }
      jQuery(firstEl)
        .velocity('stop')
        .velocity(
          {
            translateY: [0, direction * secondEl.offsetHeight],
          },
          {
            duration,
            easing: [0.65, 0, 0.3, 1],
            complete: (els: HTMLElement[]) => {
              els[0].style.transform = '';
            },
          }
        );
      jQuery(secondEl)
        .velocity('stop')
        .velocity(
          {
            translateY: [0, direction * -firstEl.offsetHeight],
          },
          {
            duration,
            easing: [0.65, 0, 0.3, 1],
            complete: (els: HTMLElement[]) => {
              els[0].style.transform = '';
              this._swapInProgress.set(false);
            },
          }
        );
    });
  }
}
