import { Meteor } from 'meteor/meteor';
import { ReactiveVar } from 'meteor/reactive-var';
import { Tracker } from 'meteor/tracker';
import { setPageTitleAndMeta } from '@/browser/setPageTitleAndMeta';
import { Song } from '@/chart/Song';
import type { MedleyRecord } from '@/collections/MedleysCollection';
import type { SongRecord } from '@/collections/SongsCollection';
import { ReactiveIncrementor } from '@/lib/ReactiveIncrementor';
import { MusicLibrary } from '@/library/MusicLibrary';
import { MedleySongArray } from '@/medleys/MedleySongArray';
import { Roles } from '@/Roles';

export class Medley {
  songs = new MedleySongArray(this);

  constructor({
    medleyRecord,
    songRecords,
  }:
    | Record<string, never>
    | { medleyRecord: SerializedMedley | MedleyRecord; songRecords: SongRecord[] } = {}) {
    if (medleyRecord) {
      this._idInternal.set(medleyRecord._id);
      this._customName.set((medleyRecord.customName && medleyRecord.name) || '');
      this._userId.set(medleyRecord.userId);
      this._userFirst.set(medleyRecord.userFirst);
      this._userLast.set(medleyRecord.userLast);
      this._createdAt.set(medleyRecord.createdAt);
      this._bpm.set(medleyRecord.bpm);
      medleyRecord.medleySongs.forEach((medleySong) => {
        const songData = songRecords.find((sr) => sr._id == medleySong._id);
        if (songData) {
          const song = new Song({ songData, medley: this });
          song.medleyPrefs?.load(medleySong);
          song.processChanges(); // to apply custom key setting
          this.songs.append(song);
        }
      });
      Tracker.autorun((comp) => {
        this.songs.reactive();
        if (comp.firstRun) return;
        this.persistChanges();
      });
    } else {
      this._userId.set(Meteor.userId() || undefined);
    }
  }

  serialize({ duplicating = false } = {}): SerializedMedley {
    // const key = this.key();
    // assert(key, 'key for serializing song');
    const serializedMedley: SerializedMedley = {
      _id: this.id(),
      name: this.name(),
      bpm: this.bpm(),
      medleySongs: this.songs.serialize(),
    };
    if (this._customName.get().length > 0) serializedMedley.customName = true;
    if (this.userId()) {
      serializedMedley.userId = this.userId();
      serializedMedley.userFirst = this.userFirst();
      serializedMedley.userLast = this.userLast();
    }
    // if (this.aboutText()) {
    //   serializedSong.notes = this.aboutText();
    // }
    // if (this.createdAt()) serializedSong.createdAt = this.createdAt();
    // if (this.updatedAt()) serializedSong.updatedAt = this.updatedAt;
    if (duplicating) {
      serializedMedley._id = undefined;
      serializedMedley.createdAt = undefined;
      serializedMedley.updatedAt = undefined;
      serializedMedley.userId = Meteor.userId() || undefined;
    }
    return serializedMedley;
  }

  private _pendingUpdates = new ReactiveIncrementor();
  dirty(): boolean {
    return this._pendingUpdates.isZero();
  }

  async persistChanges(): Promise<void> {
    if (!Meteor.userId() || this.userId() !== Meteor.userId()) return;
    this._pendingUpdates.inc();
    await this.debouncedSave(this);
    this._pendingUpdates.dec();
  }

  private debouncedSave = (() => {
    let timeoutId: number;
    const pendingPromises: {
      resolve: (value?: unknown) => void;
      reject: (reason?: unknown) => void;
    }[] = [];
    return (medley: Medley) =>
      new Promise((resolve, reject) => {
        clearTimeout(timeoutId);
        timeoutId = Meteor.setTimeout(() => {
          const currentPending = [...pendingPromises];
          pendingPromises.length = 0;
          setPageTitleAndMeta(); // in case name got changed
          MusicLibrary.medleys
            .upsert(medley.serialize())
            .then(() => currentPending.forEach(({ resolve }) => resolve()))
            .catch(() => currentPending.forEach(({ reject }) => reject()));
        }, 2000);
        pendingPromises.push({ resolve, reject });
      });
  })();

  editable(): boolean {
    return Roles.userHasPermission(Meteor.userId(), 'medleys.remove', this);
  }

  //#region === Basic properties ===

  private _idInternal = new ReactiveVar<string | undefined>(undefined);
  id(): string | undefined {
    return this._idInternal.get();
  }
  get _id(): string | undefined {
    return this._idInternal.get(); // for Blaze interop
  }

  private _userId = new ReactiveVar<string | undefined>(undefined);
  userId(): string | undefined {
    return this._userId.get();
  }
  private _userFirst = new ReactiveVar<string | undefined>(undefined);
  userFirst(): string | undefined {
    return this._userFirst.get();
  }
  private _userLast = new ReactiveVar<string | undefined>(undefined);
  userLast(): string | undefined {
    return this._userLast.get();
  }

  private _createdAt = new ReactiveVar<Date | undefined>(undefined);
  createdAt(): Date | undefined {
    return this._createdAt.get();
  }
  // Not doing updatedAt, for now... seems to be no need here.

  //#endregion ^ Basic props

  //#region === BPM ===

  // Medley is BPM-based because we don't want the internal representation to change if the time signature of the current song changes

  private _bpm = new ReactiveVar<number | undefined>(undefined);
  bpm(): number {
    return this._bpm.get() || 100;
  }

  static MIN_BPM = 20;
  static MAX_BPM = 200;

  setBpm(bpm: number | string): void {
    if (typeof bpm == 'string') bpm = +bpm;
    if (typeof bpm == 'undefined') {
      console.error('BPM was undefined in SongPrefs.setBpm()');
      bpm = 80;
    }
    bpm = Math.min(Math.max(Math.round(bpm * 2) / 2, Medley.MIN_BPM), Medley.MAX_BPM);
    this._bpm.set(bpm);
    this.persistChanges();
  }

  adjustBpm(amount: number): void {
    this.setBpm(this.bpm() + amount);
  }

  //#endregion ^ BPM

  //#region === Name ===

  private _customName = new ReactiveVar('');
  name(): string {
    if (this._customName.get()) return this._customName.get();
    return this.autoName() || 'New Medley';
  }
  autoName(): string {
    const songNames: string[] = [];
    for (const song of this.songs.reactive()) {
      songNames.push(song.name().replace(/ +\[.+\]$/, ''));
    }
    return songNames.join(' / ');
  }
  setName(name = ''): void {
    this._customName.set(name.slice(0, 1).toUpperCase() + name.slice(1));
    if (this._customName.get() == this.autoName()) this._customName.set('');
    this.persistChanges();
  }

  //#endregion ^ Name
}
