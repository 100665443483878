/**
 * Template.disableableButton parameters and defaults:
 *   caption=null
 *   disabled=false
 *   disabledCaption="Please wait…"
 *   type=button
 */

Template.disableableButton.helpers({
  disabledAttr() {
    return Template.currentData().disabled ? 'disabled' : null;
  },

  buttonType() {
    return Template.currentData().disabled ? 'button' : Template.currentData().type || 'button';
  },

  caption() {
    const data = Template.currentData();
    return data.disabled && data.disabledCaption !== false
      ? data.disabledCaption || 'Please wait…'
      : data.caption;
  },

  classes() {
    return Template.currentData().disabled
      ? Template.currentData().classes.replace(/btn-(primary|secondary)/, 'btn-disabled')
      : Template.currentData().classes;
  },
});
