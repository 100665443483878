import { Accounts } from 'meteor/accounts-base';
import { ReactiveVar } from 'meteor/reactive-var';
import { mainIndexSearcher } from '@/library/mainIndexSearcher';
import { MusicLibrary } from '@/library/MusicLibrary';
import { rpcDownloadGodModeData } from '@/server/methods/admin/rpcDownloadGodModeData';

let godModeEnabled = false;
const godModeReady = new ReactiveVar(false);

export class GodMode {
  static async enable() {
    if (godModeEnabled) return;
    Bert.alert('Enabling God Mode...', 'info');
    try {
      godModeEnabled = true;
      const data = await rpcDownloadGodModeData();
      // @ts-expect-error Undocumented Meteor API
      // eslint-disable-next-line
      const internalUsersMap = Accounts.users._collection._docs as Map<string, any>;
      for (let i = 0; i < data.users.length; i++) {
        const user = data.users[i];
        if (user && !internalUsersMap.has(user._id)) {
          internalUsersMap.set(user._id, user);
        }
      }
      let batchNumber = 0;
      const batchSize = 1000;
      const batch = function batch() {
        for (let i = 0; i < batchSize; i++) {
          const song = data.songs[batchSize * batchNumber + i];
          if (!song) {
            mainIndexSearcher.refreshResults();
            Bert.alert('God Mode enabled! Use your power wisely...', 'success');
            godModeReady.set(true);
            return;
          }
          // @ts-expect-error Undocumented internal API
          // eslint-disable-next-line
          const internalIndexMap = MusicLibrary.songs.indexCollection._collection._docs as Map<
            string,
            any
          >;
          if (internalIndexMap && !internalIndexMap.has(song._id)) {
            internalIndexMap.set(song._id, song);
          }
          MusicLibrary.index._insertSearchRecord(song);
        }
        batchNumber += 1;
        if (batchNumber % 100 == 0) {
          Bert.alert(`Processed ${batchNumber * batchSize} records`);
          setTimeout(batch, 300);
        } else {
          setTimeout(batch, 0);
        }
      };
      batch();
    } catch (err) {
      console.error(err);
      Bert.alert(`Error enabling God Mode: ${String(err)}`);
    }
  }

  static enabled() {
    return godModeEnabled;
  }

  static ready() {
    return godModeReady.get();
  }
}
