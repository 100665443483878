<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { rangeWithAllowedValues } from '@/band/ui/actions/rangeWithAllowedValues';
  import { panSliderCSS } from '@/band/ui/components/panSliderCSS';
  import { range } from '@/utilities/range';

  const dispatch = createEventDispatcher<{ 'change': number }>();

  const notchWidth: number = 20;
  const step: number = 5;
  const allowedValues = [
    ...range(-100 - notchWidth, -notchWidth - step, step),
    0,
    ...range(notchWidth + step, 100 + notchWidth, step),
  ];

  interface Props {
    value: number;
    ariaLabel?: string;
  }

  let { value, ariaLabel = '' }: Props = $props();

  const rangeStyling = panSliderCSS('md');
  let adjustedValue = $derived(value * 100 + notchWidth * Math.sign(value));
  let ariaProps = $derived({
    'aria-label': ariaLabel,
    'aria-valuemin': -100,
    'aria-valuemax': +100,
    'aria-valuenow': value * 100,
    'aria-valuetext': !value ? 'center' : `${value * 100} percent ${value < 0 ? 'left' : 'right'}`,
  });
</script>

<div class="relative touch-none">
  <input
    type="range"
    min={-100 - notchWidth}
    max={100 + notchWidth}
    value={adjustedValue}
    {step}
    style="
      --val: {adjustedValue};
      --min: {-100 - notchWidth};
      --max: {100 + notchWidth};
      {rangeStyling.rangeCSSVariables};"
    class="styled-range from-center relative z-20"
    {...ariaProps}
    use:rangeWithAllowedValues={{
      allowedValues,
      initialValue: adjustedValue,
      valueChangedCallback: (newValue) => {
        const trueValue = (newValue - Math.sign(newValue) * notchWidth) / 100;
        dispatch('change', trueValue);
      },
    }}
  />
</div>
