function DropdownWatcher({ parent, selector, element, getValue, captionFor }) {
  return function () {
    var value = getValue();
    Tracker.afterFlush(() => {
      // defer because we want HTML to be updated first
      var $dropdown = (element && $(element)) || parent.find(selector);
      var $items = $dropdown.find('a[data-value]');
      $items.removeClass('selected');
      var $selectedItem = $items.filter('[data-value="' + value + '"]');
      $selectedItem.addClass('selected');
      var caption =
        $selectedItem.data('caption') || (captionFor ? captionFor(value) : $selectedItem.html());
      $dropdown.find('.current-value').html(caption);
    });
  };
}

export default DropdownWatcher;
