import tippyJs from 'tippy.js';
import { eventTracker } from '@/browser/analytics/eventTracker';
import { Crnt } from '@/Crnt';
import { commonCapoPositionsForKey } from '@/music/capo-knowledge';
import { Chord } from '@/music/Chord';
import UserPreferences from '@/user/UserPreferences';

Template.capoSettings.onCreated(() => {
  const instance = Template.instance();
  instance.showingAllOptions = new ReactiveVar();
  instance.autorun(() => {
    if (!commonCapoPositionsForKey(Crnt.song()?.keyHeard()).includes(Crnt.song()?.prefs.capo())) {
      instance.showingAllOptions.set(true);
    }
  });
});

Template.capoSettings.onRendered(() => {
  const instance = Template.instance();
  tippyJs(instance.find('[data-tippy-content]'), {
    placement: 'right',
    delay: [200, 50],
    hideOnClick: false,
    maxWidth: 250,
  });
});

Template.capoSettings.helpers({
  capoOptions() {
    const all = Template.instance().showingAllOptions.get();
    return all ? [1, 2, 3, 4, 5, 6, 7, 8, 9] : commonCapoPositionsForKey(Crnt.song()?.keyHeard());
  },
  notViewingLetterChords() {
    return UserPreferences.get('chordSystem') != 'letters';
  },
  viewingLetterChordsOrShowingAllOptions() {
    return (
      UserPreferences.get('chordSystem') == 'letters' || Template.instance().showingAllOptions.get()
    );
  },
  showingAllOptions() {
    return Template.instance().showingAllOptions.get();
  },
  isMoreThan(one, two) {
    return ('length' in one ? one.length : one) > +two;
  },
  isCurrentFret(fret) {
    return fret == Crnt.song()?.prefs.capo();
  },
  keyWithCapoOnFret(capoPos) {
    if (!Crnt.song()?.keyHeard()) return;
    return new Chord(Crnt.song()?.keyHeard())
      .inKey(Crnt.song()?.keyHeard())
      .shift(-capoPos)
      .toHtml();
  },
});

Template.capoSettings.events({
  'click .js-setCapo': function (event, instance) {
    const fret = $(event.currentTarget).data('value');
    Crnt.song()?.prefs.setCapo(fret);
    eventTracker.capoSet({ fret });
  },
  'click .js-showAllOptions': function (event, instance) {
    instance.showingAllOptions.set(true);
    eventTracker.capoShowAll();
  },
});
