<script lang="ts">
  interface Props {
    lowerLabel?: string;
    upperLabel?: string;
    centerLabel?: string;
  }

  let { lowerLabel = '', upperLabel = '', centerLabel = '' }: Props = $props();
</script>

<div
  class="flex w-full items-center py-4 text-xs font-extrabold uppercase tracking-widest text-gray-300"
>
  {#if lowerLabel}
    <div class="basis-3 border-y border-b-white border-t-gray-200"></div>
    <div class="mx-3 justify-start">{lowerLabel}</div>
  {/if}
  {#if centerLabel}
    <div class="flex-1 border-y border-b-white border-t-gray-200"></div>
    <div class="mx-3">{centerLabel}</div>
    <div class="flex-1 border-y border-b-white border-t-gray-200"></div>
  {:else}
    <div class="flex-1 border-y border-b-white border-t-gray-200"></div>
  {/if}
  {#if upperLabel}
    <div class="mx-3 justify-end">{upperLabel}</div>
    <div class="basis-3 border-y border-b-white border-t-gray-200"></div>
  {/if}
</div>
