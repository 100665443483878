import { FlowRouter } from 'meteor/ostrio:flow-router-extra';

Template.appEntryHeader.onRendered(() => {
  if ('StatusBar' in window && window.cordova?.platformId == 'ios') StatusBar.styleLightContent();
});

Template.appEntryHeader.onDestroyed(() => {
  if ('StatusBar' in window && window.cordova?.platformId == 'ios') StatusBar.styleDefault();
});

Template.appEntryHeader.events({
  'click .js-back': function (event, instance) {
    FlowRouter.go(
      FlowRouter.current().route.name == 'forgot-password'
        ? 'login'
        : FlowRouter.current().oldRoute?.path || '/'
    );
  },
});

Template.appEntryHeader.helpers({
  alpha() {
    return window.location.href.includes('alpha');
  },
  beta() {
    return window.location.href.includes('beta');
  },
  showBack() {
    return FlowRouter.getRouteName() != 'mobile-subscribe' && !window.todesktop;
  },
});
