<script lang="ts">
  import { onDestroy } from 'svelte';
  import { eventTracker } from '@/browser/analytics/eventTracker';
  import { TapTempoCalculator } from '@/control-bar/TapTempoCalculator.svelte';

  // Props
  interface Props {
    registerTapFn: (fn: () => void) => void;
    updateValue: (amount: number) => void;
  }

  let { registerTapFn, updateValue }: Props = $props();

  registerTapFn(doTap);

  const tapTempoCalculator = new TapTempoCalculator();

  // Watch for tap tempo calculation
  $effect(() => {
    const calculatedBpm = tapTempoCalculator.calculatedBpm;
    if (calculatedBpm) {
      updateValue(Math.round(calculatedBpm));
    }
  });

  let pulse = $state<null | 1 | 2>(null);

  function doTap() {
    tapTempoCalculator.tap();
    pulse = pulse == 1 ? 2 : 1;
  }

  function handleTap() {
    doTap();
    return false;
  }

  onDestroy(() => {
    if (tapTempoCalculator.calculatedBpm) {
      eventTracker.bpmTap({ bpm: tapTempoCalculator.calculatedBpm });
    }
  });
</script>

<div class="tapTempoDropdown">
  <div class="tapTempoDropdown-typeHint">Type in a tempo</div>
  <div class="tapTempoDropdown-orLine">OR</div>
  <button
    class="btn tapTempoDropdown-tapper"
    class:pulse1={pulse == 1}
    class:pulse2={pulse == 2}
    onmousedown={handleTap}
    ontouchstart={handleTap}
  >
    Tap Tempo
    <div class="tapTempoDropdown-spacebarHint">(spacebar)</div>
  </button>
</div>

<style>
  .tapTempoDropdown {
    padding: 6px 10px 10px;
    font-size: 14px;
    text-align: center;
    min-width: 10em;
  }

  .tapTempoDropdown-typeHint {
    color: #888;
    padding: 2px 7px;
  }

  .tapTempoDropdown-orLine {
    color: #bbb;
    font-size: 12px;
    position: relative;
    margin: 0 0 4px;
  }

  .tapTempoDropdown-orLine:before {
    content: '';
    position: absolute;
    left: 0;
    right: calc(50% + 1.5em);
    top: 50%;
    border-top: solid 1px #e5e5e5;
  }

  .tapTempoDropdown-orLine:after {
    content: '';
    position: absolute;
    left: calc(50% + 1.5em);
    right: 0;
    top: 50%;
    border-top: solid 1px #e5e5e5;
  }

  .tapTempoDropdown-tapper.btn {
    display: block;
    padding: 4px;
    min-height: 4em;
    width: 100%;
    transition:
      color 120ms ease,
      background-color 120ms ease;
    background-color: mix(var(--primary), transparent, 8%);

    &:hover {
      background-color: mix(var(--primary), transparent, 20%);
    }

    &.pulse1 {
      animation: tempoTapperPulse1 1s cubic-bezier(0.2, 0.2, 0, 1) both;
    }
    &.pulse2 {
      animation: tempoTapperPulse2 1s cubic-bezier(0.2, 0.2, 0, 1) both;
    }
  }

  .tapTempoDropdown-spacebarHint {
    opacity: 0.7;
    filter: grayscale(0.3);
    font-weight: normal;
    font-size: 13px;
    @media (max-width: 500px) {
      display: none;
    }
  }

  @keyframes tempoTapperPulse1 {
    0% {
      box-shadow: 0 0 18px 5px hsla(40, 100%, 50%, 0.5);
      background-color: hsla(60, 100%, 50%, 0.2);
      color: hsl(206, 100%, 25%);
    }
    100% {
      box-shadow: 0 0 0 hsla(40, 100%, 50%, 0);
    }
  }

  @keyframes tempoTapperPulse2 {
    0% {
      box-shadow: 0 0 18px 5px hsla(40, 100%, 50%, 0.5);
      background-color: hsla(60, 100%, 50%, 0.2);
      color: hsl(206, 100%, 25%);
    }
    100% {
      box-shadow: 0 0 0 hsla(40, 100%, 50%, 0);
    }
  }
</style>
