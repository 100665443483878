import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import DemoSongList from '@/ui/lists/DemoSongList.svelte';

var disableAnimation = new ReactiveVar();

Template.indexPageDemoNotice.onCreated(() => {
  setTimeout(() => disableAnimation.set(true), 5000);
});

Template.indexPageDemoNotice.events({
  'click .js-viewAllSongs': function (event, instance) {
    Modal.show('publicSongListModal', {});
  },
  'click .js-signup': function (event, instance) {
    if (Meteor.isCordova && /Android/i.test(navigator.userAgent)) {
      bootbox.alert({
        title: 'Need an account?',
        message:
          'You can’t sign up for Strum Machine in the app. Sorry, we know it’s a hassle. Join and come back to start practicing with Strum Machine.',
      });
      return false;
    } else {
      FlowRouter.go('signup');
    }
  },
});

Template.indexPageDemoNotice.helpers({
  animate() {
    return !disableAnimation.get();
  },
  DemoSongList() {
    return DemoSongList;
  },
});
