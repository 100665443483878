type LinkedListNode<T> = T & {
  next?: LinkedListNode<T>;
  prev?: LinkedListNode<T>;
};

/**
 * A utility function that takes an array of objects with 'next' and 'prev'
 * properties and returns a doubly-linked list with the 'next' and 'prev'
 * properties set appropriately.
 */
export function makeLinkedListInPlace<T>(items: LinkedListNode<T>[]): void {
  let prevItem: LinkedListNode<T> | undefined;
  items.forEach((item) => {
    item.prev = item.next = undefined;
    if (prevItem) {
      item.prev = prevItem;
      prevItem.next = item;
    }
    prevItem = item;
  });
}
