type SixStringNumbers = [number, number, number, number, number, number];

export interface GuitarChordStrumSpec {
  rootFifthSixth: [number, number, number];
  crossPickOrder: SixStringNumbers;
  crossPickPattern: [number, number, number, number, number, number, number, number];
  stringVolumes: SixStringNumbers;
  bias: {
    low: SixStringNumbers;
    mid: SixStringNumbers;
    strum: SixStringNumbers;
    high: SixStringNumbers;
    bass: SixStringNumbers;
    jazzD: SixStringNumbers;
    jazzU: SixStringNumbers;
    root: SixStringNumbers;
    afterRoot: SixStringNumbers;
    chuck1: SixStringNumbers;
    afterChuck1: SixStringNumbers;
    fifth: SixStringNumbers;
    afterFifth: SixStringNumbers;
    chuck2: SixStringNumbers;
    afterChuck2: SixStringNumbers;
    haye: SixStringNumbers;
  };
  closed?: boolean;
  droning?: boolean;
  dropD?: boolean;
}

export const guitarChordStrumSpecs = {
  'g': {
    rootFifthSixth: [6, 4, 4],
    crossPickOrder: [NaN, NaN, 1, 2, 3, 4],
    crossPickPattern: [6, 4, 2, 1, 4, 3, 2, 1],
    stringVolumes: [-1, 0, 0, 0, 0, 0],
    bias: {
      low: [6, 6, 1, 2, 3, 4],
      mid: [6, 6, 3, 1, 2, 4],
      high: [6, 6, 4, 3, 2, 1],
      strum: [6, 6, 4, 1, 2, 3],
      bass: [1, 2, 2, 3, 5, 6],
      jazzD: [1, NaN, 2, 3, 4, 5],
      jazzU: [NaN, NaN, 3, 2, 1, 4],
      root: [1, 2, 2, 3, 5, 6],
      afterRoot: [6, 5, 1, 2, 3, 4],
      chuck1: [6, 6, 3, 1, 2, 4],
      afterChuck1: [6, 6, 4, 3, 1, 2],
      fifth: [4, 3, 1, 2, 5, 6],
      afterFifth: [6, 6, 2, 1, 3, 4],
      chuck2: [6, 6, 3, 2, 1, 4],
      afterChuck2: [6, 6, 4, 3, 2, 1],
      haye: [4, 4, 4, 4, 4, 4],
    },
  },
  'd': {
    rootFifthSixth: [4, 5, 3],
    crossPickOrder: [NaN, 1, NaN, 2, 3, 4],
    crossPickPattern: [4, 3, 2, 1, 5, 3, 2, 1],
    stringVolumes: [-5, -3, 1, 0, 0, 0],
    bias: {
      low: [6, 3, 1, 2, 4, 5],
      mid: [6, 5, 3, 1, 2, 4],
      high: [6, 5, 4, 3, 2, 1],
      strum: [6, 5, 4, 2, 1, 3],
      bass: [4, 2, 1, 3, 5, 6],
      jazzD: [NaN, 5, 1, 2, 3, 4],
      jazzU: [NaN, 5, 4, 3, 2, 1],
      root: [5, 4, 1, 3, 5, 6],
      afterRoot: [6, 5, 3, 1, 2, 4],
      chuck1: [6, 5, 4, 2, 1, 3],
      afterChuck1: [6, 5, 4, 3, 2, 1],
      fifth: [6, 1, 2, 4, 5, 6],
      afterFifth: [6, 5, 2, 1, 3, 4],
      chuck2: [6, 5, 4, 2, 1, 3],
      afterChuck2: [6, 5, 4, 3, 2, 1],
      haye: [6, 5, 4, 4, 4, 4],
    },
  },
  'a': {
    rootFifthSixth: [5, 4, 4],
    crossPickOrder: [NaN, NaN, 1, 2, 3, 4],
    crossPickPattern: [5, 4, 2, 1, 4, 3, 2, 1],
    stringVolumes: [-3, 0, 0, 0, 0, 0],
    bias: {
      low: [6, 3, 1, 2, 4, 5],
      mid: [6, 5, 3, 1, 2, 4],
      high: [6, 5, 4, 3, 2, 1],
      strum: [6, 5, 4, 1, 2, 3],
      bass: [5, 1, 2, 3, 4, 6],
      jazzD: [NaN, 1, 2, 3, 4, 5],
      jazzU: [NaN, 5, 4, 3, 1, 1],
      root: [4, 1, 2, 4, 5, 6],
      afterRoot: [6, 5, 1, 2, 3, 4],
      chuck1: [6, 6, 4, 2, 1, 3],
      afterChuck1: [6, 6, 4, 3, 2, 1],
      fifth: [5, 3, 1, 2, 4, 6],
      afterFifth: [6, 6, 3, 1, 2, 4],
      chuck2: [6, 6, 4, 2, 1, 3],
      afterChuck2: [6, 6, 4, 3, 2, 1],
      haye: [6, 5, 4, 4, 4, 4],
    },
  },
  'c': {
    rootFifthSixth: [5, 6, 3],
    crossPickOrder: [1, NaN, 2, 3, 4, NaN],
    crossPickPattern: [5, 4, 3, 1, 6, 4, 2, 1],
    stringVolumes: [-3, 0, 0, 0, 0, 0],
    bias: {
      low: [6, 3, 1, 2, 4, 5],
      mid: [6, 5, 3, 1, 2, 4],
      high: [6, 5, 4, 3, 2, 1],
      strum: [6, 5, 4, 2, 1, 3],
      bass: [2, 1, 3, 4, 5, 6],
      jazzD: [NaN, 1, 2, 3, 4, 5],
      jazzU: [NaN, 4, 3, 2, 1, NaN],
      root: [2, 1, 3, 4, 5, 6],
      afterRoot: [6, 5, 1, 2, 3, 4],
      chuck1: [6, 6, 3, 1, 2, 4],
      afterChuck1: [6, 6, 4, 3, 2, 1],
      fifth: [1, 3, 4, 5, 6, 6],
      afterFifth: [6, 6, 1, 2, 3, 4],
      chuck2: [6, 6, 3, 1, 2, 4],
      afterChuck2: [6, 6, 4, 3, 2, 1],
      haye: [6, 4, 4, 4, 4, 4],
    },
  },
  'e': {
    rootFifthSixth: [6, 5, 5],
    crossPickOrder: [NaN, 1, NaN, 2, 3, 4],
    crossPickPattern: [6, 4, 2, 1, 5, 3, 2, 1],
    stringVolumes: [0, 0, 0, 0, 0, 0],
    bias: {
      low: [6, 5, 1, 2, 3, 4],
      mid: [6, 5, 3, 1, 2, 4],
      high: [6, 5, 4, 3, 2, 1],
      strum: [6, 5, 1, 2, 3, 4],
      bass: [1, 2, 3, 4, 5, 6],
      jazzD: [1, 2, NaN, 3, 4, NaN],
      jazzU: [5, 4, 3, 2, 1, NaN],
      root: [1, 2, 3, 4, 5, 6],
      afterRoot: [5, 1, 2, 3, 4, 6],
      chuck1: [6, 6, 3, 1, 2, 4],
      afterChuck1: [6, 6, 4, 3, 1, 2],
      fifth: [3, 1, 2, 4, 5, 6],
      afterFifth: [6, 3, 2, 1, 4, 5],
      chuck2: [6, 6, 3, 2, 1, 4],
      afterChuck2: [6, 6, 4, 3, 2, 1],
      haye: [4, 4, 4, 4, 4, 4],
    },
  },
  'eX': {
    rootFifthSixth: [6, 5, 5],
    crossPickOrder: [NaN, NaN, 1, 2, 3, 4],
    crossPickPattern: [6, 4, 3, 2, 5, 4, 3, 2],
    stringVolumes: [0, 0, 0, 0, 0, 0],
    bias: {
      low: [4, 3, 1, 2, 5, 6],
      mid: [6, 4, 3, 1, 2, 5],
      high: [6, 5, 4, 2, 1, 3],
      strum: [6, 5, 3, 1, 2, 4],
      bass: [1, 2, 3, 4, 5, 6],
      jazzD: [1, 2, 2, 3, 4, NaN],
      jazzU: [5, 4, 3, 2, 1, NaN],
      root: [1, 1, 3, 4, 5, 6],
      afterRoot: [5, 2, 1, 3, 4, 6],
      chuck1: [6, 6, 3, 1, 2, 4],
      afterChuck1: [6, 6, 4, 2, 1, 3],
      fifth: [1, 1, 3, 4, 5, 6],
      afterFifth: [6, 3, 2, 1, 4, 5],
      chuck2: [6, 6, 3, 2, 1, 4],
      afterChuck2: [6, 6, 4, 3, 1, 2],
      haye: [4, 4, 4, 4, 4, 4],
    },
    closed: true,
  },
  'aX': {
    rootFifthSixth: [5, 4, 4],
    crossPickOrder: [NaN, NaN, 1, 2, 3, 4],
    crossPickPattern: [5, 4, 3, 2, 5, 4, 3, 2],
    stringVolumes: [0, 0, 0, 0, 0, 0],
    bias: {
      low: [NaN, 3, 1, 2, 4, 5],
      mid: [NaN, 5, 3, 1, 2, 4],
      high: [NaN, 5, 4, 3, 2, 1],
      strum: [NaN, 5, 4, 1, 2, 3],
      bass: [NaN, 1, 2, 3, 4, 5],
      jazzD: [NaN, 1, 2, 3, 4, NaN],
      jazzU: [NaN, 4, 3, 2, 1, NaN],
      root: [4, 1, 2, 4, 5, 6],
      afterRoot: [6, 5, 1, 2, 3, 4],
      chuck1: [6, 6, 4, 2, 1, 3],
      afterChuck1: [6, 6, 4, 3, 2, 1],
      fifth: [5, 3, 1, 2, 4, 6],
      afterFifth: [6, 6, 3, 1, 2, 4],
      chuck2: [6, 6, 4, 2, 1, 3],
      afterChuck2: [6, 6, 4, 3, 2, 1],
      haye: [4, 4, 4, 4, 4, 4],
    },
    closed: true,
  },
  'dX': {
    rootFifthSixth: [5, 4, 4],
    crossPickOrder: [NaN, NaN, 1, 2, 3, 4],
    crossPickPattern: [4, 3, 2, 1, 4, 3, 2, 1],
    stringVolumes: [0, 0, 0, 0, 0, 0],
    bias: {
      low: [NaN, NaN, 1, 2, 3, 4],
      mid: [NaN, NaN, 3, 1, 2, 4],
      high: [NaN, NaN, 4, 3, 2, 1],
      strum: [NaN, NaN, 3, 2, 1, 4],
      bass: [NaN, NaN, 1, 2, 3, 4],
      jazzD: [NaN, 1, 1, 2, 3, 4],
      jazzU: [NaN, 4, 4, 3, 2, 1],
      root: [5, 4, 1, 3, 5, 6],
      afterRoot: [6, 5, 3, 1, 2, 4],
      chuck1: [6, 5, 4, 2, 1, 3],
      afterChuck1: [6, 5, 4, 3, 2, 1],
      fifth: [6, 2, 1, 3, 5, 6],
      afterFifth: [6, 5, 2, 1, 3, 4],
      chuck2: [6, 5, 4, 2, 1, 3],
      afterChuck2: [6, 5, 4, 3, 2, 1],
      haye: [4, 4, 4, 4, 4, 4],
    },
    closed: true,
  },
  'dD': {
    rootFifthSixth: [6, 5, 5],
    crossPickOrder: [NaN, NaN, 1, 2, 3, 4],
    crossPickPattern: [6, 4, 3, 2, 5, 4, 3, 2],
    stringVolumes: [0, 0, 0, 0, 0, 0],
    bias: {
      low: [4, 3, 1, 2, 5, 6],
      mid: [6, 4, 3, 1, 2, 5],
      high: [6, 5, 4, 2, 1, 3],
      strum: [6, 5, 3, 2, 1, 4],
      bass: [1, 2, 3, 4, 5, 6],
      haye: [4, 4, 4, 4, 4, 4],
    },
    droning: true,
  },
  'aD': {
    rootFifthSixth: [5, 4, 4],
    crossPickOrder: [NaN, NaN, 1, 2, 3, 4],
    crossPickPattern: [5, 4, 3, 2, 5, 4, 3, 2],
    stringVolumes: [0, 0, 0, 0, 0, 0],
    bias: {
      low: [4, 3, 1, 2, 5, 6],
      mid: [6, 4, 3, 1, 2, 5],
      high: [6, 5, 4, 2, 1, 3],
      strum: [6, 5, 3, 2, 1, 4],
      bass: [6, 1, 2, 3, 4, 5],
      haye: [4, 4, 4, 4, 4, 4],
    },
    droning: true,
  },
  'dropD': {
    rootFifthSixth: [6, 5, 5],
    crossPickOrder: [NaN, NaN, 1, 2, 3, 4],
    crossPickPattern: [6, 4, 3, 2, 5, 4, 3, 2],
    stringVolumes: [0, 0, 0, 0, 0, 0],
    bias: {
      low: [4, 3, 1, 2, 5, 6],
      mid: [6, 4, 3, 1, 2, 5],
      high: [6, 5, 4, 2, 1, 3],
      strum: [6, 5, 3, 2, 1, 4],
      bass: [1, 2, 3, 4, 5, 6],
      root: [1, 2, 3, 4, 5, 6],
      afterRoot: [6, 3, 1, 2, 3, 5],
      chuck1: [6, 5, 3, 1, 2, 4],
      afterChuck1: [6, 5, 4, 3, 2, 1],
      fifth: [4, 1, 2, 3, 5, 6],
      afterFifth: [6, 5, 2, 1, 3, 4],
      chuck2: [6, 5, 3, 1, 2, 4],
      afterChuck2: [6, 5, 4, 3, 2, 1],
      haye: [4, 4, 4, 4, 4, 4],
    },
    dropD: true,
  },
} as const satisfies Record<string, GuitarChordStrumSpec>;
