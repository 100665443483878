import { Meteor } from 'meteor/meteor';
import type { Context } from 'meteor/ostrio:flow-router-extra';
import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { ClientManager } from '@/browser/ClientManager';
import { Crnt } from '@/Crnt';
import { MusicLibrary } from '@/library/MusicLibrary';

/**
 * Prevent routing when there are unsaved changes
 * This function will be called on every route change.
 * Returns true to prevent the route change
 */
function preventRouteChange(targetContext: Pick<Context, 'path'>): boolean {
  if (
    Crnt.song()?.editMode() &&
    Crnt.song()?.dirty() &&
    !ClientManager.reloadStarted &&
    previousPath?.split('?')[0] != targetContext.path?.split('?')?.[0]
  ) {
    if (!window.confirm('Unsaved changes will be lost. Continue?')) {
      MusicLibrary.songs.unblock();
      return true;
    } else {
      Crnt.unloadMusic();
    }
  }
  Modal.hide();
  return false;
}

window.onbeforeunload = function () {
  const unsavedChanges =
    Crnt.song()?.editMode() &&
    Crnt.song()?.dirty() &&
    !ClientManager.reloadStarted &&
    !Meteor.isDevelopment;

  return unsavedChanges ? 'Unsaved changes will be lost. Continue?' : undefined;
};

// Workaround FlowRouter to provide the ability to prevent route changes
let previousPath: string | undefined;
let isReverting: boolean;
let routeCounter = 0;
let routeCountOnPopState: number;

window.onpopstate = function () {
  // For detecting whether the user pressed back/forward button.
  routeCountOnPopState = routeCounter;
};

FlowRouter.triggers.exit([
  function (context, _redirect, _stop) {
    // Before we leave the route, cache the current path.
    previousPath = context.path;
  },
]);

FlowRouter.triggers.enter([
  function (context, _redirect, stop) {
    routeCounter++;

    if (isReverting) {
      isReverting = false;
      // This time, we are simply 'undoing' the previous (prevented) route change.
      // So we don't want to actually fire any route actions.
      stop();
    } else if (preventRouteChange(context)) {
      // This route change is not allowed at the present time.

      // Prevent the route from firing.
      stop();

      isReverting = true;

      if (routeCountOnPopState == routeCounter - 1) {
        // This route change was due to browser history - e.g. back/forward button was clicked.
        // We want to undo this route change without overwriting the current history entry.
        // We can't use redirect() because it would overwrite the history entry we are trying
        // to preserve.

        // setTimeout allows FlowRouter to finish handling the current route change.
        // Without it, calling FlowRouter.go() at this stage would cause problems (we would
        // ultimately end up at the wrong URL, i.e. that of the current context).
        setTimeout(() => {
          FlowRouter.go(previousPath ?? '');
        });
      } else {
        // This is a regular route change, e.g. user clicked a navigation control.
        // setTimeout for the same reasons as above.
        setTimeout(() => {
          // Since we know the user didn't navigate using browser history, we can safely use
          // history.back(), keeping the browser history clean.
          history.back();
        });
      }
    }
  },
]);
