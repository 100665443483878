<script lang="ts">
  import { strict as assert } from 'assert';
  import { BoomChuckGuitarCore } from '@/band/instruments/guitar/cores/BoomChuckGuitarCore';
  import { BoomChuckWaltzGuitarCore } from '@/band/instruments/guitar/cores/BoomChuckWaltzGuitarCore';
  import { DampenedGuitarCore } from '@/band/instruments/guitar/cores/DampenedGuitarCore';
  import { ChopMandolinCore } from '@/band/instruments/mandolin/cores/ChopMandolinCore';
  import { swingOptions } from '@/band/swingOptions';
  import BandPanelAlert from '@/band/ui/components/BandPanelAlert.svelte';
  import CycleThroughDropdown from '@/band/ui/components/CycleThroughDropdown.svelte';
  import PrettySlider from '@/band/ui/components/PrettySlider.svelte';
  import SliderRule from '@/band/ui/components/SliderRule.svelte';
  import SwingVisualization from '@/band/ui/components/SwingVisualization.svelte';
  import PanelModalLayout from '@/band/ui/panels/PanelModalLayout.svelte';
  import SwingHelpPanel from '@/band/ui/panels/SwingHelpPanel.svelte';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';
  import { eventTracker } from '@/browser/analytics/eventTracker';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import { MultiPanelModalController } from '@/ui/settings/MultiPanelModalController.svelte';
  import { slideFade } from '@/ui/svelte-transitions';

  const modalController = MultiPanelModalController.current();

  const bandMenu = getBandMenuState();
  let band = $derived($bandMenu.band);

  interface Props {
    instId?: InstrumentId | undefined;
  }

  let { instId = undefined }: Props = $props();

  let currentSwingOption = $state() as SwingOption;
  trackerEffect(() => (currentSwingOption = band.swing()));

  let currentSwingValue = $derived(currentSwingOption.value);

  const swingOptionArray = Object.values(swingOptions) as SwingOption[];

  function updateSwingValueToIndex(index: number) {
    const closestOption = swingOptionArray.find((opt) => opt.index == index);
    assert(closestOption, `no swing option found for index ${index}`);
    band.setSwing(closestOption);
    eventTracker.bandSwingSet({ swing: closestOption.id });
  }

  let core = $derived(
    $bandMenu[instId ?? 'guitar'].coreOrFallbackForTimeSignature($bandMenu.timeSignature)
  );
  let vizCommands = $derived(
    core.generateViz($bandMenu.instruments[instId ?? 'guitar'].settings())
  );
  let swingMandolinNotApplicable = $derived(
    core == ChopMandolinCore && !vizCommands[$bandMenu.timeSignature == '3/4' ? 3 : 5]?.opacity
  );
</script>

<PanelModalLayout>
  {#snippet header()}
    <header class="panelHeader">
      {#if instId == 'mandolin'}
        This setting affects the timing of rhythmic accents involving upstrokes.
      {:else}
        This can have a dramatic effect on the feel of a song.
      {/if}
      <a
        href="/help/swing"
        class="whitespace-nowrap font-medium"
        onclick={(e) => {
          e.preventDefault();
          modalController.zoomInto({ component: SwingHelpPanel, title: 'Help' });
        }}
        >{#if instId == 'mandolin'}Learn more{:else}Learn more about upstroke swing{/if}</a
      >.
    </header>
  {/snippet}

  {#snippet main()}
    <div>
      <div class="flex justify-center pb-6 pt-5">
        <div
          class="bg-white px-8 pb-1 pt-5"
          style="box-shadow: 4px 6px 12px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.05), -4px -6px 12px 0px rgba(255, 255, 255, 0.95); border-radius: 12px; overflow: hidden; border: solid 2px var(--gray-100);"
        >
          <SwingVisualization
            value={currentSwingValue}
            {instId}
            firstDownstroke={vizCommands[0]}
            upstroke={vizCommands[5]}
            secondDownstroke={vizCommands[6]}
          />
        </div>
      </div>

      <!-- <div class="cycle-shadow mx-auto max-w-[13em] rounded-lg bg-muted-100 p-1.5">
          <div class="text-center font-medium transition-colors">
            {currentSwingOption?.name}
          </div>
        </div> -->
      <div>
        <div class="cycle-shadow mx-auto w-[288px] rounded-lg bg-muted-100 p-1 pb-[5px]">
          <CycleThroughDropdown
            options={swingOptionArray.map((opt) => ({
              caption: opt.name,
              value: opt,
            }))}
            selectedValue={swingOptionArray.find((o) => o.id == currentSwingOption.id)}
            on:select={(event) => band.setSwing(event.detail)}
            wrapAround={true}
            captureLeftRightKeys={true}
          />
        </div>
      </div>

      <div class="py-2"><SliderRule /></div>
      <div class="px-8">
        <PrettySlider
          min={0}
          max={6}
          value={currentSwingOption.index}
          step={1}
          ariaLabel="upstroke swing amount"
          ariaValues={{
            min: 0,
            max: 100,
            now: Math.round(currentSwingValue * 100),
            text: currentSwingOption.name,
          }}
          thumbDiameter={2.75}
          tickHeight={0.875}
          tickSqueezes={[0, 0.3, 0, 0.3, 0, 0.6, 0.6]}
          tickFades={[1, 1, 1, 1, 1, 0.65, 0.65]}
          tickCount={swingOptionArray.length}
          allowedValues={swingOptionArray.map((opt) => opt.index)}
          on:change={(e) => updateSwingValueToIndex(e.detail)}
        />
      </div>
      <div class="pb-0.5 pt-2"><SliderRule /></div>

      <div class="px-5 pb-5">
        {#if currentSwingOption.value > 0.7 && core != DampenedGuitarCore}
          <div transition:slideFade class="pb-4">
            <BandPanelAlert>
              This swing setting is considered excessive in most genres.
            </BandPanelAlert>
          </div>
        {/if}
        {#if core == BoomChuckGuitarCore || core == BoomChuckWaltzGuitarCore}
          <div class="text-center text-sm text-gray-500" transition:slideFade>
            Because simple "boom chuck" strumming is all downstrokes, this setting only affects the
            timing of eighth-note bass runs, if any.
          </div>
        {:else if core == DampenedGuitarCore}
          <div class="text-center text-sm text-gray-500" transition:slideFade>
            Because the current strumming style is all downstrokes, this setting only affects the
            timing of upstroke embellishments, if any.
          </div>
        {:else if swingMandolinNotApplicable}
          <div transition:slideFade>
            <BandPanelAlert>
              This setting has no effect on the mandolin if upstroke rhythmic accents are not being
              played. (See "Accents" on previous screen.)
            </BandPanelAlert>
          </div>
        {:else}
          <div class="text-center text-sm text-gray-500" transition:slideFade>
            Swing automatically reduced at high BPMs.
          </div>
          <!-- <i
            class="smi smi-help-circle-outline cursor-help text-gray-500 transition-colors duration-200 dsktp:hover:text-muted-600"
            aria-hidden="true"
          /> -->
        {/if}
      </div>
    </div>
  {/snippet}
</PanelModalLayout>
