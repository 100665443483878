import { Tracker } from 'meteor/tracker';
import { Crnt } from '@/Crnt';
import UserPreferences from '@/user/UserPreferences';

export function autoModulateReset(): void {
  Tracker.nonreactive(() => {
    const song = Crnt.song();
    if (!song) return;
    if (UserPreferences.get('amReset') && song.prefs.autoModulate.enabled()) {
      const startingKey = song.state.autoModulateState.startingKey();
      if (startingKey) {
        song.setKey(startingKey);
      }
      song.state.autoModulateState.reset();
    }
    song.state.autoModulateState.setStartingKey(null);
  });
}
