<script lang="ts">
  interface Props {
    name: number;
    collapsed?: boolean;
    songs?: number[];
  }
  let { name: placeholderNameChars, songs: placeholderSongs = [], collapsed }: Props = $props();

  let expanded = $derived(!collapsed);
</script>

<section
  class={[
    'mx-auto flex max-w-[600px] flex-col transition-all duration-200 ease-out contain-layout contain-style',
    collapsed && 'mb-1.5 mt-0',
    expanded && 'mb-5 mt-4',
  ]}
  role="presentation"
>
  <header
    class={[
      'relative flex items-center bg-white',
      collapsed && 'h-[43px] rounded-md',
      expanded && '-mx-2.5 h-[49px] rounded-xl [@media(max-width:449.9px)]:-mx-1',
      collapsed ? 'px-3.5' : 'px-6',
    ]}
    style:box-shadow={collapsed
      ? '0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.12)'
      : '0 6px 9px 0 rgba(0, 0, 0, 0.14), 0 3px 5px -1px rgba(0, 0, 0, 0.2)'}
  >
    <div class="w-[34px] [@media(max-width:360px)]:hidden"></div>
    <span class="inline-block h-[18px] bg-muted-50" style:width={`${placeholderNameChars * 8.5}px`}
    ></span>
  </header>
  {#if expanded}
    <div
      class={['relative mx-2.5 my-0 rounded-b-md [@media(min-width:450px)]:mx-9']}
      style:background-color="white"
      style:background-image="linear-gradient(to bottom, #ccc 0%, rgba(255, 255, 255, 0) 100%)"
      style:background-position="left top"
      style:background-repeat="repeat-x"
      style:background-size="100% 3px;"
      style:box-shadow="0 2px 4px 0 rgba(0, 0, 0, 0.17), 0 2px 7px 0 rgba(0, 0, 0, 0.13);"
    >
      {#if placeholderSongs.length > 0}
        <ul class="_songs-container songIndexList pb-1 pt-[3px]">
          {#each placeholderSongs as ps, i (i)}
            <div class="songIndexItem h-[35px]">
              <div
                class="ml-[9px] block h-4 translate-x-1 self-center bg-muted-50"
                style:width={`${ps * 7.5}px`}
              ></div>
            </div>
          {/each}
        </ul>
      {:else}
        <div class="px-4 pb-2.5 pt-3 text-sm">&nbsp;</div>
      {/if}
    </div>
  {/if}
</section>

<style>
  header,
  ._songs-container {
    position: relative;
    overflow: hidden;
    &::before {
      content: ' ';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      width: 100%;
      animation: textPlaceholderAnimation 1s linear infinite;
      background: linear-gradient(
          100deg,
          rgba(255, 255, 255, 0) 30%,
          rgba(255, 255, 255, 0.35) 50%,
          rgba(255, 255, 255, 0) 70%
        )
        top left;
    }
  }
  header::before {
    animation-delay: 800ms;
  }

  @keyframes textPlaceholderAnimation {
    0% {
      transform: translate3d(-100%, 0, 0);
    }
    100% {
      transform: translate3d(100%, 0, 0);
    }
  }
</style>
