import type { CircularStrumLandscape } from '@/band/instruments/CircularStrumLandscape';

export const bluegrassPatternLandscape: CircularStrumLandscape<EightNumbers> = {
  centerPowers: [0.9, -0.4, 0.9, 0, 0.8, 0.7, 0.9, 0.7],

  poles: [
    {
      theta: 6 / 8, // NORTH
      halfPowers: [0.9, 0.3, 0.9, 0.7, 0.9, 0.85, 0.9, 0.8],
      fullPowers: [0.9, 0.9, 0.9, 0.9, 0.9, 0.9, 0.9, 0.9],
    },
    {
      theta: 7 / 8,
      // halfPowers: [],
      fullPowers: [0.8, 0.3, 1, 0.6, 0.3, 0.4, 1, 0.6],
    },
    {
      theta: 0 / 8, // EAST
      // halfPowers: [],
      fullPowers: [0.4, 0, 1, 0, -0.1, 0.5, 1, 0.7],
    },
    {
      theta: 1 / 8,
      // halfPowers: [],
      fullPowers: [0.9, 0, 1, 0.2, -0.3, 0.6, 1, 0.9],
    },
    {
      theta: 2 / 8, // SOUTH
      halfPowers: [1, 0, 0.4, 0, 0.2, 0.7, 0.9, 1],
      fullPowers: [1, 0, 0.05, 0, -0.1, 0.6, 0.9, 1],
    },
    {
      theta: 3 / 8,
      // halfPowers: [],
      fullPowers: [1, 0, 0, 0.3, 1, 0.4, 0.7, 0.9],
    },
    {
      theta: 4 / 8, // WEST
      // halfPowers: [],
      fullPowers: [1, -0.1, 0.4, 0.3, 1, -0.1, 0.4, 0.3],
    },
    {
      theta: 5 / 8,
      halfPowers: [0.95, 0.0, 0.7, 0.6, 1, 0.2, 0.8, 0.6],
      fullPowers: [1, 0.4, 0.7, 0.7, 1, 0.4, 0.7, 0.7],
    },
  ],

  presets: [
    {
      name: 'Balanced',
      position: [0.0, 0.0],
    },
    {
      name: 'Boom-heavy',
      position: [0.75, 0.5],
    },
    {
      name: 'Busy & boom-heavy',
      position: [0.75, 0.625],
    },
    {
      name: 'Busy',
      position: [0.75, 0.75],
    },
    {
      name: 'Busy & chuck-heavy',
      position: [0.75, 0.875],
    },
    {
      name: 'Chuck-heavy',
      position: [0.75, 0.0],
    },
    {
      name: 'Spacious & chuck-heavy',
      position: [0.75, 0.125],
    },
    {
      name: 'Spacious',
      position: [0.75, 0.25],
    },
    {
      name: 'Spacious & boom-heavy',
      position: [0.75, 0.375],
    },
  ],
};
