import { FMBCordovaPlugin } from '@/audio/engine/fmb/FMBCordovaPlugin';
import countSampleDefinitions from '@/audio/samples/definitions/countSampleDefinitions';
import type { LinearizedMeasure } from '@/chart/LinearizedMeasure';

export async function doFMODSamplePriming(measures: LinearizedMeasure[]) {
  if (!FMBCordovaPlugin.canPrimeSamples()) return;

  const uniqueSampleIds = new Set<string>();
  measures.forEach((measure) => {
    if (measure.firstInRep || measure.position.cells[0] == 0) {
      measure.cells[0].beats
        .flatMap((b) => Object.values(b.playerInstructions).flat())
        .forEach((i) => {
          if (i.type == 'play') uniqueSampleIds.add(i.sample);
        });
    }
  });
  countSampleDefinitions.forEach(({ id }) => {
    if (!/[2-8]/.test(id)) uniqueSampleIds.add(id);
  });

  if (uniqueSampleIds.size == 0) return;
  console.info(`priming ${uniqueSampleIds.size} samples`);
  // eslint-disable-next-line no-console
  console.time(`primed ${uniqueSampleIds.size} samples`);
  const result = await FMBCordovaPlugin.primeSamples({ sampleIds: Array.from(uniqueSampleIds) });
  // eslint-disable-next-line no-console
  console.timeEnd(`primed ${uniqueSampleIds.size} samples`);
  return result;
}
