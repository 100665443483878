import { bassNoteFromChromaIndex } from '@/band/instruments/bass/bassNoteFromChromaIndex';
import type { LinearizedBeat } from '@/chart/LinearizedBeat';

// prettier-ignore
const rootFifths = {
  a:  [ [ -3, -8 ], [ -3, +4 ], [ +9, +4 ] ],
  ab: [ [ -2, -7 ], [ -2, +5 ], [ 10, +5 ] ],
  b:  [ [ -1, -6 ], [ -1, +6 ], [ 11, +6 ] ],
  c:  [ [ +0, -5 ], [ +0, +7 ], [ 12, +7 ] ],
  cd: [ [ +1, -4 ], [ +1, -4 ], [ 13, +8 ] ],
  d:  [ [ +2, -3 ], [ +2, -3 ], [ +2, +9 ] ],
  de: [ [ +3, -2 ], [ +3, -2 ], [ +3, 10 ] ],
  e:  [ [ -8, -1 ], [ +4, -1 ], [ +4, -1 ] ],
  f:  [ [ -7, +0 ], [ +5, +0 ], [ +5, +0 ] ],
  fg: [ [ -6, +1 ], [ +6, +1 ], [ +6, +1 ] ],
  g:  [ [ -5, +2 ], [ +7, +2 ], [ +7, +2 ] ],
  ga: [ [ -4, +3 ], [ +8, +3 ], [ +8, +3 ] ],
} as const;

export function bassNoteForBeat(
  beat: LinearizedBeat,
  onRootNote: boolean,
  octaveToFavor: 0 | 1 | 2 = 1
) {
  if (typeof beat.chord.bassChroma == 'number') {
    const lowestChroma = ([-8, -2, 0] as const)[octaveToFavor];
    const chromaIndex = ((beat.chord.bassChroma - lowestChroma + 24) % 12) + lowestChroma;
    return bassNoteFromChromaIndex(chromaIndex);
  }
  const chordLetterCode = beat.chord.letterCode();
  const rootFifth = rootFifths[chordLetterCode][octaveToFavor];
  const bassNoteChroma = rootFifth[onRootNote ? 0 : 1];
  if (beat.cell.next?.chordHeard) {
    const nextChordLetterCode = beat.cell.next.chordHeard.letterCode();
    const nextBassNote = rootFifths[nextChordLetterCode][octaveToFavor][0];
    if (
      bassNoteChroma == nextBassNote ||
      (nextChordLetterCode == chordLetterCode && beat.cell.next.chordHeard.alteredFifth)
    ) {
      return bassNoteFromChromaIndex(rootFifth[0]);
    }
  }
  return bassNoteFromChromaIndex(bassNoteChroma);
}
