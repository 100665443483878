import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { eventTracker } from '@/browser/analytics/eventTracker';
import { HelpLibrary } from '@/help/HelpLibrary';
import HelpWindow from '@/help/HelpWindow';
import { Roles } from '@/Roles';

Template.helpSearchBox.onCreated(() => {
  const instance = Template.instance();
  instance.queryPresent = new ReactiveVar();
});

Template.helpSearchBox.onRendered(() => {
  const instance = Template.instance();
  instance.autorun(() => {
    if (!instance.typingQuery) instance.$('.js-helpSearchBoxInput').val(HelpWindow.searchQuery());
  });
});

Template.helpSearchBox.helpers({
  queryPresent() {
    return Template.instance().queryPresent.get();
  },
});

Template.helpSearchBox.events({
  'click .js-searchIcon': (event, instance) => {
    instance.$('.js-helpSearchBoxInput').focus();
    instance.$('.js-helpSearchBoxInput').select();
    instance.$('.js-helpSearchBoxInput')[0].setSelectionRange(0, 9999);
    event.preventDefault();
  },

  'focus .js-helpSearchBoxInput': (event, instance) => {
    instance.$('.js-helpSearchBoxInput').select();
  },

  'keydown form': (event, instance) => {
    if (event.key == 'Escape') {
      instance.$('.js-helpSearchBoxInput').val('').blur();
    }
    if (event.key == 'Enter') {
      if (instance.$('.js-helpSearchBoxInput').val().trim()) {
        instance.$('.js-searchButton').click();
      } else {
        event.preventDefault();
      }
    }
  },

  'input .js-helpSearchBoxInput': (event, instance) => {
    instance.queryPresent.set(!!instance.$('.js-helpSearchBoxInput').val());
    if (HelpWindow.currentPageId() != 'search') return;
    instance.typingQuery = true;
    /**
     * The following line enables search-when-typing. Cool, right?
     * Yeah, except then you get a bunch of "noise" in the search analytics.
     */
    // HelpWindow.search(instance.$('.js-helpSearchBoxInput').val());
    Meteor.defer(() => (instance.typingQuery = false));
  },

  'submit form': (event, instance) => {
    event.preventDefault();
    const query = instance.$('.js-helpSearchBoxInput').val().trim();
    if (!query) return;
    instance.$('.js-helpSearchBoxInput').blur();

    if (instance.data.inMenu) {
      eventTracker.helpMenuSearch(query);
      if (
        /^(how (to|do I) ?)?(subscribe|resubscribe|^(activate|start)( my)?subscription$|activate( account)?$)/i.test(
          query
        )
      ) {
        const canSubscribe =
          Roles.userHasRole(Meteor.userId(), { $in: ['trial-member', 'trial-expired'] }) ||
          Roles.userHasRole(Meteor.userId(), 'admin');
        if (canSubscribe) {
          eventTracker.helpSearch({ query, instantResult: true });
          FlowRouter.go('subscribe');
          return;
        }
      }

      if (/^contact$/i.test(query)) {
        eventTracker.helpSearch({ query, instantResult: true });
        HelpWindow.visible() ? HelpWindow.navigate('contact') : FlowRouter.go('contact');
        return;
      }

      const instantResult = HelpLibrary.searchInstant(query)?._id;
      if (instantResult) {
        eventTracker.helpSearch({ query, instantResult: true });
        HelpWindow.navigate(instantResult);
        return;
      }
    }

    if (window.location.pathname.startsWith('/help')) {
      FlowRouter.go('docs-index', {}, { search: query });
    } else {
      HelpWindow.search(query);
    }
  },
});
