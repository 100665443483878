import * as Sentry from '@sentry/browser';
import { ClientManager } from '@/browser/ClientManager';

var loadedWithServiceWorker = 'serviceWorker' in navigator && !!navigator.serviceWorker.controller;

var registrationAttempted = false;
export default async function registerServiceWorker() {
  if (registrationAttempted) return;
  registrationAttempted = true;
  // if ( !( window.navigator.standalone == true || window.matchMedia('(display-mode: standalone)').matches ) ) {
  //   await new Promise(resolve => Tracker.autorun(() => Meteor.userId() && resolve() ) );  // only register service worker when logged in... unless already a PWA
  // }
  try {
    const registration = await navigator.serviceWorker.register('/sw.js');
    console.info('Service worker registered: ', registration);

    if (registration.installing) listenForInstalledServiceWorker(registration);
    registration.addEventListener('updatefound', () =>
      listenForInstalledServiceWorker(registration)
    );
    if (registration.waiting) ClientManager._setWaitingServiceWorker(registration.waiting);

    // prefetchDynamicModules();
  } catch (err) {
    console.error('Service worker registration failed: ', err);
    Sentry.captureException(err);
  }
}

// function prefetchDynamicModules() {
//   // prefetch all dynamic modules
//   const modulesToPrefetch = [
//     // don't need to prefetch @zootools/email-spell-checker since it's only used in signup
//     "autosize",
//     "@/client-and-server/templates/legal/privacy-policy.html",
//   ];
//   return Promise.all(modulesToPrefetch.map(m => module.prefetch(m)));
// }

function listenForInstalledServiceWorker(registration) {
  registration.installing.addEventListener('statechange', function () {
    if (this.state === 'installed') {
      if (loadedWithServiceWorker || ClientManager.updateAvailable()) {
        ClientManager._setWaitingServiceWorker(registration.waiting);
      }
    }
  });
}
