import AudioManager from '@/audio/engine/AudioManager';
import { FMBSystem } from '@/audio/engine/fmb/FMBSystem';
import Version from '@/Version';

const versionInfo = Version.history
  .map((release) => {
    const s = /\d+-\d+/.test(release.version) ? 's' : '';
    return `### Version${s} ${release.version}\n\n${release.details}`;
  })
  .join('\n\n');

const generateAboutPage = () => {
  var page = `
# About

**Strum Machine version ${Version.current.replace(/.0$/, '')}**<br>
Copyright © ${new Date().getFullYear()} Luke Abbott LLC<br>
<a href="/privacy" class="js-global-openPolicy" data-policy="privacy" data-atcl="AboutPage.Privacy">Privacy Policy</a> – <a href="/terms" class="js-global-openPolicy" data-policy="terms" data-atcl="AboutPage.Terms">Terms of Use</a><br>
    `;

  if (FMBSystem.available() && AudioManager.mode == 'cordova') {
    page += 'Audio powered by FMOD, © Firelight Technologies Pty Ltd.<br>';
  }

  if (!Meteor.isCordova && !window.todesktop) {
    page += `[${Meteor.absoluteUrl('/').replace(/^https?:\/\/|\/$/g, '')}](${Meteor.absoluteUrl('/')})\n\n`;
  }

  page += `\n\n## Release Notes\n\n${versionInfo}`;

  return page;
};

export default generateAboutPage;
