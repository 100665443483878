import { marked } from 'marked';
import { Meteor } from 'meteor/meteor';
import MiniSearch from 'minisearch';
import { eventTracker } from '@/browser/analytics/eventTracker';
import generateAboutPage from '@/help/generateAboutPage';
import helpPages from '@/help/help-pages';
import { isTouchDevice } from '@/ui/isTouchDevice';

type HelpPage = {
  _id: string;
  title: string;
  summary?: string | null;
  keywords?: string | null;
  no_search?: boolean;
  instant?: string[];
  hide_in_cordova?: boolean;
  only_in_cordova?: boolean;
  body: string;
};

export class HelpLibrary {
  static searchIndexPromise: Promise<MiniSearch>;

  static instantKeywords = new Map<RegExp, HelpPage>();

  static load(): void {
    const stopWords = new Set(
      'how do I and or the an a can also what where do my your div class href'.split(' ')
    );
    const searchIndex = new MiniSearch({
      idField: '_id',
      fields: ['title', 'summary', 'keywords', 'body'],
      storeFields: ['title', 'summary'],
      searchOptions: {
        boost: { title: 2.5, summary: 2, keywords: 4 },
        fuzzy: 0.15,
      },
      processTerm: (term, _fieldName) => {
        return stopWords.has(term) ? null : term.toLowerCase();
      },
      extractField: (document, fieldName) => {
        if (fieldName == 'body') {
          return document[fieldName]
            .replace(/<div class="help-page-toc">[^|]+?<\/div>/g, '')
            .replace(/<a [^|]+?<\/a>/g, '');
        }
        return document[fieldName];
      },
    });
    const filteredHelpPages = helpPages.filter(
      (p) => !(Meteor.isCordova && p.hide_in_cordova) && !(!Meteor.isCordova && p.only_in_cordova)
    ) as HelpPage[];
    this.searchIndexPromise = searchIndex
      .addAllAsync(filteredHelpPages.filter((p) => !p.no_search))
      .then(() => searchIndex);
    filteredHelpPages.forEach((page) => {
      page.instant?.forEach((regex) => {
        this.instantKeywords.set(new RegExp(regex.toLocaleLowerCase()), page);
      });
    });
  }

  static offlineReady = (): boolean => true;

  static fetch(pageId: string): { html: string } | undefined {
    let page: (HelpPage | { body: string }) | undefined;
    if (pageId == 'about') {
      page = { body: generateAboutPage() };
    } else {
      page = helpPages.find((p) => p._id == pageId);
    }
    if (!page?.body) return;

    let html = marked(page.body, { smartypants: true }) as string;
    if ('title' in page && page.title) html = `<h1>${page.title}</h1>\n${html}`;
    html = replaceClickTaps(html);

    html = html.replace(/href="([^"]+?)"/g, (x, href) => {
      if (/^(http|^\/)/.test(href)) return x;
      if (href == 'contact') return `href="/app/contact"`;
      return `href="/help/${href}"`;
    });

    return { ...page, html };
  }

  static searchInstant = (query: string): HelpPage | undefined => {
    query = query
      .trim()
      .toLocaleLowerCase()
      .replace(/^how (to|do I)?\s*/gi, '')
      .replace(/\?$/, '');
    for (const [regex, page] of this.instantKeywords.entries()) {
      if (regex.test(query)) {
        eventTracker.helpSearch({ query });
        return page;
      }
    }
    return undefined;
  };

  static search = async (
    query: string
  ): Promise<{ _id: string; title: string; summary: string }[]> => {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    if (!this.searchIndexPromise) HelpLibrary.load();
    return this.searchIndexPromise.then((searchIndex) => {
      if (!query) return [];
      eventTracker.helpSearch({ query });
      let results = searchIndex.search(query);
      if (results.length === 0) results = searchIndex.search(query, { prefix: true });
      return results.map((result: Record<string, any>) => {
        return {
          _id: result.id,
          title: result.title,
          summary: result.summary,
        };
      });
    });
  };
}

const replaceClickTaps = (text: string) =>
  text.replace(/[cC]lick(ing|s)?Tap(ping|s)?/g, (x) => {
    if (x == 'clickTap') return isTouchDevice() ? 'tap' : 'click';
    if (x == 'ClickTap') return isTouchDevice() ? 'Tap' : 'Click';
    if (x == 'clicksTaps') return isTouchDevice() ? 'taps' : 'clicks';
    if (x == 'ClicksTaps') return isTouchDevice() ? 'Taps' : 'Clicks';
    if (x == 'clickingTapping') return isTouchDevice() ? 'tapping' : 'clicking';
    if (x == 'ClickingTapping') return isTouchDevice() ? 'Tapping' : 'Clicking';
    return x;
  });
