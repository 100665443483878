export function vizDimensionsFromCommand(
  params: RhythmPatternVisualizationCommand,
  { topYPadded, paddedHeight }: { topYPadded: number; paddedHeight: number }
): {
  tipY: number;
  headY: number;
  headWidth: number;
  stemWidth: number;
  tailHeight: number;
} {
  const dirSign = params.dir == 'u' ? -1 : params.dir == 'd' ? 1 : 0;
  const headWidth = 5 + params.thickness * 5;
  const stemWidth = 2 + params.thickness * Math.max(2.5, (params.tail || 0) * 5);
  const headRatio = 3 / 4;
  const headY = dirSign * (headWidth * headRatio);
  const headBias = (headWidth * headRatio) / paddedHeight;
  const tailBias = (params.tail || 0) * (1 - headBias);
  const totalBias = headBias + tailBias;
  const tipY =
    topYPadded +
    paddedHeight * ((params.dir === 'u' ? 0 : totalBias) + params.biasY * (1 - totalBias));

  const tailHeight = Math.abs(tailBias * paddedHeight);

  return {
    tipY,
    headY,
    headWidth,
    stemWidth,
    tailHeight,
  };
}
