export const presetGenres: PresetGenre[] = [
  {
    id: 'bg',
    name: 'Bluegrass',
    categories: [
      {
        id: 'bg-n',
        label: 'Non-modal bluegrass presets',
        helpText:
          '"Non-modal" means that these work best for either major or minor songs, as opposed to "modal" songs which are written out in a major key but are actually not quite major and not quite minor.',
      },
      {
        id: 'bg-m',
        label: 'Modal/bluesy bluegrass presets',
        helpText:
          '"Modal" in a bluegrass or old-time context means that although the song or tune may be said to be in a major key, it uses the minor third in the scale at least as much as the major third, so it’s not quite major but not quite minor either.',
      },
      {
        id: 'bg-s',
        label: 'Basic no-frills preset',
        helpText:
          'This preset has no automatic bass runs, leading notes, mandolin chop accents, or other embellishments. It’s just a simple strumming pattern that works well for a wide variety of songs.',
      },
      {
        id: 'bg-w',
        label: 'Bluegrass waltz presets',
      },
    ],
  },

  {
    id: 'ot',
    name: 'Old-Time',
    categories: [
      {
        id: 'ot-n',
        label: 'Old-time presets',
      },
      {
        id: 'ot-m',
        label: 'Modal old-time presets',
      },
    ],
  },

  {
    id: 'cn',
    name: 'Country',
    categories: [
      {
        id: 'cn-n',
        label: 'Country presets',
      },
    ],
  },

  {
    id: 'flk',
    name: 'Folk',
    categories: [],
  },

  {
    id: 'ce',
    name: 'Celtic',
    categories: [
      {
        id: 'ce-cl',
        label: 'Celtic styles (in 4/4 time)',
      },
    ],
  },

  {
    id: 'jzsw',
    name: 'Jazz & Swing',
    categories: [
      {
        id: 'jzsw-n',
        label: 'Basic four-to-the-bar swing rhythms',
      },
      {
        id: 'jzsw-ws',
        label: 'Western swing rhythms',
      },
      {
        id: 'jzsw-g',
        label: 'Gypsy jazz rhythms',
      },
    ],
  },

  {
    id: 'bls',
    name: 'Blues',
    categories: [
      {
        id: 'bls-s',
        label: 'Blues rhythms',
      },
    ],
  },

  {
    id: 'of',
    name: 'Other Styles',
    categories: [
      {
        id: 'of-ca',
        label: 'Canadian styles',
      },
      {
        id: 'of-eu',
        label: 'European styles',
      },
      {
        id: 'of-cj',
        label: 'Cajun styles',
      },
      {
        id: 'of-bls',
        label: 'Blues styles',
      },
    ],
  },

  {
    id: 'op',
    name: 'Other Pop',
    categories: [
      {
        id: 'op-',
        label: 'TBD',
      },
    ],
  },
];

export const presetGenresById = Object.fromEntries(presetGenres.map((g) => [g.id, g]));

// export const presetGenreCategories = presetGenres.flatMap((g) => g.categories);
