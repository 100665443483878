import type { SUARecord } from '@/collections/SUACollection';
import { ObjectPropertyKeyTransformer } from '@/lib/ObjectPropertyKeyTransformer';
import type { GroundedDocumentCompressor } from '@/local-db/GroundedCollection';

export const GroundedSUACompressor: GroundedDocumentCompressor<SUARecord> = {
  compress(doc: SUARecord): Record<string, any> {
    if (!doc) return doc;
    return suaTransformer.abbreviate(doc);
  },
  decompress(compressedDoc: Record<string, any>): SUARecord {
    if (!compressedDoc) return compressedDoc;
    return suaTransformer.unabbreviate(compressedDoc);
  },
};

const suaTransformer = new ObjectPropertyKeyTransformer<SUARecord>([
  ['uAt', 'updatedAt', { date: true }],
  ['t', 'tpm'],
  ['k', 'key'],
  ['c', 'capoFret'],
  ['n', 'notes'],
  ['nv', 'notesVisible'],
  ['asDT', 'asDeltaT'],
  ['asR', 'asReps'],
  ['asMT', 'asMaxT'],
  ['afR', 'afReps'],
  ['amK', 'amKeys'],
  ['amI', 'amInc'],
  ['amS', 'amSeq'],
  ['amR', 'amReps'],
  ['bd', 'band'],
  ['bpid', 'presetId'],
]);
