const regexForA =
  /^(A) (?!(?:and|back(?:ing|up)|arpeggio|blues|changes|chords?|dorian|drone|flat|jam|key|licks?|lydian|mixo(?:lydian)?|major|minor|modal|pentatonic|practice|progressions?|rhythms?|exercises?|etudes?|scales?|shuffle|to|tuning|vamp|zu|i.+|\w|[^a-z].*)\b)(.+)$/i;
const regexForAn = /^(An) ([AEIOU].*)$/i;
const regexForThe = /^(The) (.+)$/i;

export function putArticleAtEnd(text: string): string {
  return text
    .replace(regexForA, '$2, $1')
    .replace(regexForAn, '$2, $1')
    .replace(regexForThe, '$2, $1');
}
