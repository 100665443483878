import type { PlayerInstruction } from '@/band/instruments/PlayerInstruction';

export class PlayerInstructionsArray extends Array<PlayerInstruction> {
  private _channel: string;
  constructor(channel: string) {
    super();
    this._channel = channel;
  }

  playSample(
    sample: string,
    options: {
      db: number;
      offset?: number;
      beatOffset?: number;
      attentuateBasedOnBeatOffset?: boolean;
      omitAboveTpm?: number;
      omitBelowTpm?: number;
    }
  ): void {
    this.push({
      type: 'play',
      channel: this._channel,
      sample,
      ...options,
    });
  }

  muteSample(
    sample: string,
    options: {
      fadeDuration?: number;
      offset?: number;
      beatOffset?: number;
      omitAboveTpm?: number;
      omitBelowTpm?: number;
    }
  ): void {
    this.push({
      type: 'mute',
      channel: this._channel,
      sample,
      ...options,
    });
  }

  muteUnmatchedStrings(
    allowedStrings: string,
    options: {
      fadeDuration?: number;
      offset?: number;
      beatOffset?: number;
      omitAboveTpm?: number;
      omitBelowTpm?: number;
    }
  ): void {
    this.push({
      type: 'mute',
      channel: this._channel,
      allowedStrings,
      ...options,
    });
  }
}
