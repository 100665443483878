import { Meteor } from 'meteor/meteor';
import type { Playback } from '@/audio/engine/base/Playback';
import type { PlayProps } from '@/audio/engine/base/PlayProps';
import type { Sample, SampleDefinition } from '@/audio/engine/base/Sample';

export abstract class SampleLibrary {
  protected readonly sampleMap = new Map<string, Sample>();
  get sampleCount(): number {
    return this.sampleMap.size;
  }
  // readonly loadedSampleCount = 0;

  get(id: string): Sample | undefined {
    return this.sampleMap.get(id);
  }

  has(id: string): boolean {
    return this.sampleMap.has(id);
  }

  abstract prepare(sampleDefinitions: SampleDefinition[]): Promise<void>;

  loadAudioIntoSamples(array?: (string | Sample)[]) {
    if (!array) array = [...this.sampleMap.values()];
    const donePromise = Promise.all(
      array.map(async (idOrSample) => {
        const sample = typeof idOrSample == 'string' ? this.sampleMap.get(idOrSample) : idOrSample;
        if (!sample) {
          throw new Error(
            `Could not load audio into sample "${<string>idOrSample}" - sample not found.`
          );
        }
        if (!sample.loaded) await sample.load();
      })
    );
    // eslint-disable-next-line no-console
    console.time('Time to load audio into samples');
    // eslint-disable-next-line no-console
    void donePromise.then(() => console.timeEnd('Time to load audio into samples'));
    return donePromise;
  }

  // unloadSamples(array: (string | Sample)[]) {
  //   array.forEach((idOrSample) => {
  //     const sample = typeof idOrSample == 'string' ? this._samplesByID[idOrSample] : idOrSample;
  //     if (!sample) {
  //       console.error(`Could not remove sample "${idOrSample}" - sample not found.`);
  //       return;
  //     }
  //     delete this._samplesByID[sample.id];
  //     this.sampleMap
  //     sample.destroy();
  //   });
  // }

  //@ts-expect-error TODO: Handle sample missing better
  playSample(args: Partial<PlayProps> & { id: string }): Playback {
    const sample = this.sampleMap.get(args.id);
    if (sample) {
      //@ts-expect-error Room for improvement...
      return sample.play(args);
    } else {
      // throw new Error('Could not find sample to play: ' + args.id);
      if (Meteor.isDevelopment) console.error('Could not find sample: ' + args.id);
    }
  }
}
