import { Crnt } from '@/Crnt';
import defineTippyDropdown from '@/ui/dropdowns/defineTippyDropdown';
import { UserProfile } from '@/user/UserProfile';

defineTippyDropdown(Template.controlBarAutoTools, {
  id: 'auto-tools-menu',
  onShow() {
    UserProfile.recordMilestone('TOOLS_OPEN');
  },
});

Template.controlBarAutoTools.helpers({
  anyToolEnabled() {
    return Crnt.song()?.prefs.autoFinish.enabled() || Crnt.song()?.prefs.autoSpeedup.enabled();
  },
});
