import { strict as assert } from 'assert';
import type { EQBand } from '@/audio/engine/base/EQ';
import { EQ } from '@/audio/engine/base/EQ';

export class WebAudioEQ extends EQ {
  readonly inputNode: AudioNode;
  readonly outputNode: AudioNode;
  readonly biquadFilterNodes: BiquadFilterNode[] = [];

  constructor(context: AudioContext, eqBands?: EQBand[]) {
    super(eqBands);

    let headNode;
    for (let i = 0; i < this.bands.length; i++) {
      const node = context.createBiquadFilter();

      // Just in case these need to be set...
      node.channelCount = 1;
      node.channelCountMode = 'explicit';
      node.channelInterpretation = 'speakers';

      this.biquadFilterNodes.push(node);
      if (headNode) headNode.connect(node);
      headNode = node;
    }

    assert(this.biquadFilterNodes[0], 'No biquad filter nodes created');
    this.inputNode = this.biquadFilterNodes[0];

    assert(headNode, 'No head node created');
    this.outputNode = headNode;

    this._set(this.bands);
  }

  protected _set(bands: EQBand[]): void {
    this._bands = bands;
    this.bands.forEach((band, index) => {
      const node = this.biquadFilterNodes[index];
      assert(node, `No biquad filter node found for index ${index}`);
      if (!band.type || band.type == 'peak') {
        node.type = 'peaking';
      } else {
        node.type = band.type;
      }
      node.frequency.value = band.freq;
      node.Q.value = band.q;
      node.gain.value = band.gain;
    });
  }
}
