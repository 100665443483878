import { Meteor } from 'meteor/meteor';
import { ReactiveVar } from 'meteor/reactive-var';
import _ from 'underscore';
import AudioManager from '@/audio/engine/AudioManager';
import type { ScheduledEvent } from '@/audio/engine/clock/ScheduledEvent';
import { eventTracker } from '@/browser/analytics/eventTracker';

export class MissedAudioDeadlineTracker {
  private static _glitchCount = new ReactiveVar(0);

  static reset(): void {
    this._glitchCount.set(0);
  }

  static handleMissedDeadline(event: ScheduledEvent, toleranceEarly: number): void {
    this.throttledHandler(event, toleranceEarly);
  }

  static glitchCount(): number {
    return this._glitchCount.get();
  }

  private static unthrottledHandler(event: ScheduledEvent, toleranceEarly: number): void {
    const delay = Math.round((AudioManager.currentTime() - event.deadline) * 1000);
    if (delay > 4000) return; // discard extremely long delays
    if (!document.hidden) {
      if (Meteor.isDevelopment) console.info(`Missed audio deadline by ${delay}ms`);
      this._glitchCount.set(this._glitchCount.get() + 1);
      setTimeout(() => {
        eventTracker.missedScheduledBeat({ value: delay, toleranceEarly });
        /*
      Sentry.withScope((scope) => {
        scope.setExtra('delay', delay);
        scope.setExtra('toleranceEarly', Conductor.tickScheduler.toleranceEarly);
        Sentry.captureMessage('Missed audio deadline');
      });
      */
      }, 50);
    }
  }

  private static throttledHandler = _.throttle(
    MissedAudioDeadlineTracker.unthrottledHandler.bind(this),
    500
  );
}

// This commented-out section is an experimental prototype for a
// way of tracking how many of each length of delay we capture.
// I'm almost certain it's not the right approach, but it's a start.

/*
window.delay1 = [];
window.delay2 = [];
function _trackEventTolerancePerformance(delay) {
  setTimeout(() => {
    if (delay >= 0) delay = Conductor.tickScheduler.toleranceEarly - delay;
    const index = Math.floor(Math.abs(delay * 10));
    const array = delay < 0 ? window.delay2 : window.delay1;
    array[index] = (array[index] || 0) + 1;
  }, 10);
}
// setInterval(() => { console.info("Early: ", window.delay1); console.info("Late: ", window.delay2); }, 5000);
*/
