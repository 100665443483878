import { FlowRouter } from 'meteor/ostrio:flow-router-extra';

if (typeof window.clientModules == 'undefined') window.clientModules = {};

const deferredChromePrompt = new ReactiveVar();

clientModules.pwa = {
  deferredChromePrompt() {
    return deferredChromePrompt.get();
  },
  showDeferredChromePrompt() {
    deferredChromePrompt.get().prompt();
    deferredChromePrompt.set(null);
  },
};

window.addEventListener('beforeinstallprompt', (event) => {
  event.preventDefault();
  deferredChromePrompt.set(event);
});

window.addEventListener('appinstalled', (event) => {
  FlowRouter.go('/app');
  Tracker.afterFlush(() => {
    Bert.alert('Strum Machine has been installed to your device!', 'success');
  });
});
