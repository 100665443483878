import { assignBassOctaves } from '@/band/instruments/bass/assignBassOctaves';
import { BasicWaltzBassCore } from '@/band/instruments/bass/BasicWaltzBassCore';
import { pluck } from '@/band/instruments/bass/BassActions';
import type { BassCore } from '@/band/instruments/bass/BassCore';
import { bassNoteForBeat } from '@/band/instruments/bass/bassNoteForBeat';

export const BasicBassCore: BassCore = {
  id: 'b',
  title: 'Basic bass',

  timeSignatures: ['4/4'],
  equivilantCoreFor(timeSignature) {
    return timeSignature === '3/4' ? BasicWaltzBassCore : undefined;
  },

  linkedSettingsKeys: ['onbeatNotes', 'onbeatSustain'],
  otherSettingsKeys: ['timing'],

  presets: [
    {
      name: 'Root-fifth bass',
      settings: {
        'onbeatNotes': {},
        'onbeatSustain': {},
      },
    },
  ],

  measurePreprocessing(measures, settings, _flags) {
    assignBassOctaves({ measures, settings });
  },

  processMeasure(measure, settings) {
    return measure.cells.flatMap((cell, cellIndex) => {
      return cell.beats.flatMap((beat) => {
        if (beat.rest) return [null, null];
        if (beat.beatInCell > 0 && !beat.chordChanged) return [null, null];
        const onRootNote =
          beat.chordRootChanged ||
          cellIndex === 0 ||
          cell.split ||
          cell.effect == 'stop' ||
          cell.effect == 'diamond' ||
          measure.endOfSong;
        const noFifth = beat.chord.alteredFifth || settings.onbeatNotes.rootOnly;
        const octave = cell.plans.bass?.octave ?? 1;
        const playLowD =
          settings.onbeatNotes.rootOnly &&
          settings.onbeatNotes.rootOctaves == 'alternating' &&
          onRootNote &&
          beat.chord.letterCode() == 'd' &&
          typeof beat.chord.bassChroma !== 'number';
        const stringFret = playLowD
          ? 'drop-d'
          : bassNoteForBeat(beat, onRootNote || noFifth, octave);
        const beatDuration = beat.stop
          ? 0.425
          : beat.diamond || (measure.endOfSong && cell.layout.barEnd)
            ? 0 // Infinite sustain
            : Math.max(settings.onbeatSustain.sustain * 2, 0.02);
        return [pluck(stringFret, beatDuration, { db: 0 }), null];
      });
    });
  },

  generateViz(settings) {
    const alternatingSpread = settings.onbeatNotes.rootOnly
      ? settings.onbeatNotes.rootOctaves == 'alternating'
        ? 0.3
        : 0
      : -0.2;
    return [
      {
        biasY: 0.5 + alternatingSpread,
        thickness: 0.8,
      },
      null,
      null,
      null,
      {
        biasY: 0.5 - alternatingSpread,
        thickness: 0.8,
      },
      null,
      null,
      null,
      {
        biasY: 0.5 + alternatingSpread,
        thickness: 0.8,
      },
      null,
      null,
      null,
      {
        biasY: 0.5 - alternatingSpread,
        thickness: 0.8,
      },
      null,
      null,
      null,
    ];
  },
};
