/**
 * Generate a somewhat random alphanumeric string.
 * @param length - number of random characters to generate
 * @returns A pseudorandom string.
 */
export function quickRandomId(length = 20): string {
  let id = '';
  while (id.length < length) {
    id += Math.random().toString(36).substring(2, 15);
  }
  return id.slice(0, length);
}
