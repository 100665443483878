import type { EJSONable, EJSONableProperty } from 'meteor/ejson';
import { Meteor } from 'meteor/meteor';

export function callServerMethodWithoutRetry<T = unknown>(
  methodName: string,
  ...args: (EJSONable | EJSONableProperty)[]
): Promise<T> {
  return new Promise((resolve, reject) => {
    const applyOptions = { noRetry: true };
    Meteor.apply(methodName, args ?? [], applyOptions, (error, result) => {
      if (error) {
        reject(error);
      } else {
        resolve(result as T);
      }
    });
  });
}
