// When toggling an aspect of the chord, the corresponding
// pairs will be reviewed in order. The first one that
// matches in either position A or B will be used. If
// none is found, it'll use the second item in the first pair.

export const chordTogglePairs: Record<string, [[string, string], ...[string, string][]]> = {
  'six': [
    ['', '6'],
    ['m', 'm6'],
    ['m7', 'm6'],
    ['m9', 'm6'],
  ],
  'seven': [
    ['', '7'],
    ['m', 'm7'],
    ['m6', 'm7'],
    ['m9', 'm7'],
    ['^7', '^7'],
    ['4', '7s'],
  ],
  'nine': [
    ['', '9'],
    ['m', 'm9'],
    ['m6', 'm9'],
    ['m7', 'm9'],
    ['^', '^9'],
    ['4', '9s'],
  ],
  'maj7': [
    ['', '^'],
    ['m', 'm^'],
    ['m7', 'm^'],
  ],
  'minor': [
    ['', 'm'],
    ['6', 'm6'],
    ['7', 'm7'],
    ['9', 'm9'],
    ['^', 'm^'],
  ],
  'diminished': [['', 'o']],
  'halfDiminished': [['', '0']],
  'augmented': [['', '+']],
  'five': [['', '5']],
  'sus2': [['', '2']],
  'sus4': [['', '4']],
  'suspended': [
    ['', '4'],
    ['7', '7s'],
    ['9', '9s'],
    ['13', '13s'],
  ],
};
