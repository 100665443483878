import { Meteor } from 'meteor/meteor';
import { Conductor } from '@/Conductor';
import { rpcUpdatePlayedMinutes } from '@/server/methods/user/rpcUpdatePlayedMinutes';

let started = false;

export function startTrackingPlayedMinutes(): void {
  if (started) return;
  started = true;
  let secondsPlayed = 0;
  setInterval(() => {
    if (Conductor.playing()) {
      secondsPlayed++;
      if (!Meteor.userId()) return; // no tracking in demo mode
      // report at 10s, and at 10s past every minute
      if (secondsPlayed % 60 == 10) {
        void rpcUpdatePlayedMinutes({ inc: 1 });
      }
    }
  }, 1000);
}
