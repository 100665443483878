import { assignGuitarBassRunsAndLeadingNotes } from '@/band/instruments/guitar/assignGuitarBassRunsAndLeadingNotes';
import { assignGuitarBoomStrums } from '@/band/instruments/guitar/assignGuitarBoomStrums';
import { bluegrassWaltzCell } from '@/band/instruments/guitar/blocks/bluegrassWaltzCell';
import { bluegrassWaltzPatternLandscape } from '@/band/instruments/guitar/bluegrassWaltzPatternLandscape';
import { strumDown } from '@/band/instruments/guitar/GuitarActions';
import type { GuitarCore } from '@/band/instruments/guitar/GuitarCore';
import { bluegrassWaltzSpreadGradients } from '@/band/instruments/guitar/settings/bluegrassWaltzSpreadGradients';
import { swingOptions } from '@/band/swingOptions';
import { lerp } from '@/utilities/lerp';

export const BluegrassWaltzGuitarCore: GuitarCore = {
  id: 'bgw',
  title: 'Boom chuck with upstrokes',

  timeSignatures: ['3/4'],

  linkedSettingsKeys: ['brushiness', 'circularStrumShape'],
  otherSettingsKeys: ['bassNotes', 'bassRuns', 'openVoicings', 'timing'],

  chordStyle: 'cowboy',

  swingCategory: 'normal',
  defaultSwing: swingOptions['3:2'],

  presets: [
    {
      name: 'Standard waltz strum',
      settings: {
        'circularStrumShape': {},
        'brushiness': {},
      },
    },
    {
      name: 'Heavy waltz strum',
      settings: {
        'circularStrumShape': { r: 0.75, t: 0.75 },
        'brushiness': { v: 12 / 14 },
      },
    },
  ],

  measurePreprocessing(measures, settings, flags) {
    if (!flags?.noEmbellishments) {
      assignGuitarBassRunsAndLeadingNotes({ measures, settings, core: BluegrassWaltzGuitarCore });
    }
    assignGuitarBoomStrums({ measures, settings });
  },

  processMeasure(measure, settings) {
    const intents = measure.cells.flatMap((cell) => bluegrassWaltzCell(cell, settings));
    if (measure.endOfSong) {
      return [
        ...intents.slice(0, (measure.cells.length - 1) * 6),
        strumDown('root', { spread: lerp(2, 6, settings.brushiness.value), db: 0 }),
      ];
    }
    return intents;
  },

  generateViz(settings) {
    const beatPowers = settings.circularStrumShape.powersForLandscape(
      bluegrassWaltzPatternLandscape
    );

    const beatBiasies: SixNumbers = [0, 0.5, 0.8, 1.0, 0.8, 1.0];

    const beatThicknesses = beatPowers.map(
      (power, i) => power * (i % 2 == 0 ? 1.0 : 0.7)
    ) as SixNumbers;

    const beatOpacities = beatPowers.map((power, i) => {
      const direction = i % 2 == 0 ? 'D' : 'U';
      const threshold: number = direction == 'U' ? 0.2 : 0.1;
      const multiple: number = direction == 'U' ? 1 : 2;
      return power < threshold ? 0 : Math.min(1, threshold + power * multiple);
    }) as SixNumbers;

    const beatSpreads = settings.brushiness
      .spreadsForGradients(bluegrassWaltzSpreadGradients)
      .map((spread, i) => {
        spread *= lerp(0.5, 1, beatPowers[i] ?? NaN);
        return spread;
      }) as SixNumbers;

    // Ensure "chucks" are not isolated single notes
    beatSpreads[2] = Math.max(2.2 - (beatPowers[1] + beatPowers[3]), beatSpreads[2]);
    beatSpreads[4] = Math.max(2.2 - (beatPowers[3] + beatPowers[5]), beatSpreads[4]);

    const beatTails = beatSpreads.map((adjustedSpread) =>
      Math.min(1, (adjustedSpread - 1) / 4)
    ) as SixNumbers;

    const beatTailOpacities = beatTails.map((tail) => Math.min(1, tail * 5));

    return ([0, 1, 2, 3, 4, 5] as const).map((i) => ({
      dir: i % 2 == 0 ? 'd' : 'u',
      biasY: beatBiasies[i],
      thickness: beatThicknesses[i],
      opacity: beatOpacities[i],
      tail: beatTails[i],
      tailOpacity: beatTailOpacities[i],
    }));
  },
};
