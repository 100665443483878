<script lang="ts">
  import { MasterPitch } from '@/audio/mixer/MasterPitch';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import ScalarInputWithTrimmers from '@/ui/ScalarInputWithTrimmers.svelte';

  function setValueFunc(value: string | number) {
    MasterPitch.set(+value);
  }

  function adjustFunc(value: number) {
    MasterPitch.set(MasterPitch.get() + value);
  }

  let pitch = $state(440);
  trackerEffect(() => (pitch = MasterPitch.get()));

  let controlData = $derived({
    prefix: 'A ',
    value: pitch,
    unit: 'Hz',
    min: MasterPitch.minHertz,
    max: MasterPitch.maxHertz,
    delay: true,
    setValueFunc,
    adjustFunc,
  });
</script>

<div class="IVC_wrap">
  <ScalarInputWithTrimmers {...controlData} />
</div>

<style>
  .IVC_wrap {
    display: flex;
    align-items: center;
  }
</style>
