import type { InstrumentSettingDefinitions } from '@/band/instruments/InstrumentSettingDefinitions';
import { MandolinChopAccentsSetting } from '@/band/instruments/mandolin/settings/MandolinChopAccentsSetting';
import { MandolinChopDynamicsSetting } from '@/band/instruments/mandolin/settings/MandolinChopDynamicsSetting';
import { MandolinSwingBackbeatSetting } from '@/band/instruments/mandolin/settings/MandolinSwingBackbeatSetting';
import { MandolinSwingSustainSetting } from '@/band/instruments/mandolin/settings/MandolinSwingSustainSetting';
import { MandolinTimingSetting } from '@/band/instruments/mandolin/settings/MandolinTimingSetting';

export const mandolinSettingDefinitions = {
  chopDynamics: {
    class: MandolinChopDynamicsSetting,
    serializationKey: 'm_cd',
  },
  chopAccents: {
    class: MandolinChopAccentsSetting,
    serializationKey: 'm_ca',
  },
  swingSustain: {
    class: MandolinSwingSustainSetting,
    serializationKey: 'm_ds',
  },
  swingBackbeat: {
    class: MandolinSwingBackbeatSetting,
    serializationKey: 'm_db',
  },
  timing: {
    class: MandolinTimingSetting,
    serializationKey: 'm_bt',
  },
} as const satisfies InstrumentSettingDefinitions;
