import { Meteor } from 'meteor/meteor';
import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { Roles } from '@/Roles';
import { rpcSendAccountDeletionConfirmationEmail } from '@/server/methods/user/rpcSendAccountDeletionConfirmationEmail';
import { rpcSubmitChangeOfEmail } from '@/server/methods/user/rpcSubmitChangeOfEmail';
import logout from '@/user/logout';

/**
 * @returns {[{ productId: string, platform: string, sandbox: boolean, purchaseId: string, purchaseDate: string, lastRenewalDate: string, expirationDate: string, isTrialPeriod: boolean, isIntroPeriod: boolean, renewalIntent: string, lastNotification: string, isExpired: boolean }]}
 */
function getIAPs() {
  const user = Meteor.user('inAppPurchases');
  return user?.inAppPurchases ?? [];
}

Template.accountPage.onCreated(() => {
  const instance = Template.instance();
  if (!Meteor.userId()) {
    FlowRouter.go('song-index');
  }
  instance.billingInfoReady = new ReactiveVar();
  instance.subscribe('userBillingInfo', {
    onReady: () => instance.billingInfoReady.set(true),
  });
  instance.data.setTitle('Account & Subscription');
});

Template.accountPage.helpers({
  loading() {
    return Meteor.status().connected && !Template.instance().billingInfoReady.get();
  },
  loaded() {
    return Meteor.status().connected && Template.instance().billingInfoReady.get();
  },
  usingWebBilling() {
    return (
      Meteor.user('subscription')?.subscription &&
      !Roles.userHasRole(Meteor.userId(), 'lifetime-member') &&
      !getIAPs().find((p) => !p.isExpired)
    );
  },
  usingIAPBilling() {
    return getIAPs().find((p) => !p.isExpired);
  },
  usingAppleIAPBilling() {
    return getIAPs().find((p) => !p.isExpired && p.platform == 'apple');
  },
  iapFreeTrial() {
    return getIAPs().find((p) => p.isTrialPeriod || p.isIntroPeriod);
  },
  iapWillRenew() {
    return getIAPs().find((p) => p.renewalIntent == 'Renew');
  },
  currentUserEmail() {
    return Meteor.user('emails')?.emails[0].address;
  },
  subscriptionStatusIs(status) {
    return Meteor.user('subscription')?.subscription?.status == status;
  },
});

Template.accountPage.events({
  'click .js-changeEmail': function (event, instance) {
    event.preventDefault();
    if (!Meteor.status().connected) {
      bootbox.alert('You must be connected to the internet to change your email address.');
      return;
    }
    bootbox.prompt({
      size: 'small',
      title: 'What’s your new email address?',
      inputType: 'email',
      async callback(email) {
        if (!email) return;
        event.target.disabled = true;
        event.target.innerHTML = 'Please wait...';
        try {
          await rpcSubmitChangeOfEmail({ email });
          bootbox.alert({
            message:
              'Please check the inbox of address you just entered; a confirmation link has been sent to it.',
          });
        } catch (err) {
          if (err.error == 'users.methods.addEmail.emailExists') {
            bootbox.alert({
              message:
                "This email is already in use by another account. Please email us at support@strummachine.com with both your old and new email addresses and we'll take care of it for you.",
            });
          } else {
            bootbox.alert({
              message:
                "Sorry, this link isn't working right now for some reason. Please try again, or contact support.",
            });
          }
        }
        event.target.disabled = false;
        event.target.innerHTML = 'Edit';
      },
    });
  },

  'click .js-changePassword': function (event, instance) {
    event.preventDefault();
    if (!Meteor.status().connected) {
      bootbox.alert('You must be connected to the internet to change your password.');
      return;
    }
    if (instance.changingPassword) return;
    instance.changingPassword = true;
    $(event.target).attr('disabled', true);
    Accounts.forgotPassword({ email: Meteor.user().emails[0].address }, (err) => {
      if (!err) {
        bootbox.alert({
          message: 'Please check your email. A password reset link has been emailed to you.',
        });
      } else {
        bootbox.alert({
          message:
            "Sorry, this link isn't working right now for some reason. Please try again, or contact support.",
        });
      }
    });
  },

  'click .js-deleteAccount': async function (event, instance) {
    event.preventDefault();
    if (!Meteor.status().connected) {
      bootbox.alert('You must be connected to the internet to delete your account.');
      return;
    }

    if (getIAPs().find((p) => !p.isExpired && p.platform == 'apple')) {
      await new Promise((resolve) => {
        bootbox.alert({
          size: 'small',
          title: 'Cancel your subscription FIRST',
          message:
            'Looks like you have an active subscription managed through Apple In-App Purchases. Before deleting your account, please ensure your subscription is not set to renew by going to the Settings app on your iPhone or iPad, tapping your name, then tapping Subscriptions.',
          buttons: { ok: { label: 'I Understand' } },
          callback: resolve,
        });
      });
    }

    const activeSub = Meteor.user('subscription')?.subscription?.status == 'active';
    try {
      await new Promise((resolve, reject) => {
        bootbox.confirm({
          size: 'small',
          title: 'Deleting Your Account',
          message: `After pressing “Continue” below, you'll receive a confirmation email with a link that will take you to a page where you can delete your account and all associated data.<br><br>Deletion is permanent. ${activeSub ? 'Your subscription will automatically be cancelled and a prorated refund issued. <br><br>If there’s any chance you’ll want to use Strum Machine again, we recommend merely cancelling your subscription instead of deleting your entire account.' : 'We only recommend deleting your account if there’s no chance you’ll ever want to use Strum Machine again.'}`,
          buttons: {
            confirm: {
              label: 'Continue',
              className: 'btn-danger',
            },
            cancel: {
              label: 'Never Mind',
            },
          },
          callback(result) {
            if (result) resolve();
            else reject();
          },
        });
      });
    } catch (err) {
      return;
    }

    event.target.disabled = true;

    const loader = bootbox.dialog({
      message: '<div class="LoadingDots"><b></b><b></b><b></b></div>',
      closeButton: false,
    });

    try {
      await rpcSendAccountDeletionConfirmationEmail();
      loader.modal('hide');
      bootbox.alert({
        message: 'Please check your email. An account deletion link has been sent to you.',
      });
    } catch (err) {
      loader.modal('hide');
      bootbox.alert({
        message:
          'Sorry, something went wrong. Please contact support through the Help menu and we’ll take care of deleting your account manually.',
      });
      event.target.disabled = false;
    }
  },

  'click .js-changeName': function (event, instance) {
    event.preventDefault();
    if (!Meteor.status().connected) {
      bootbox.alert('You must be connected to the internet to change your name.');
      return;
    }
    Modal.show('changeNameModal', this, {});
  },

  'click .js-manageBilling': function (event, instance) {
    store.manageBilling();
  },

  'click .js-manageSubscriptions': function (event, instance) {
    store.manageSubscriptions();
  },

  'click .js-logout': function (event, instance) {
    event.preventDefault();
    logout();
  },
});
