import { ReactiveVar } from 'meteor/reactive-var';

export class ReactiveChartPosition {
  constructor(pos: ChartPosition) {
    this._song = new ReactiveVar(pos.song);
    this._round = new ReactiveVar(pos.round);
    this._section = new ReactiveVar(pos.section);
    this._rep = new ReactiveVar(pos.rep);
    this._cell = new ReactiveVar(pos.cell);
    this._beat = new ReactiveVar(pos.beat);
  }

  _song: ReactiveVar<number>;
  song(): number {
    return this._song.get();
  }

  _round: ReactiveVar<number>;
  round(): number {
    return this._round.get();
  }

  _section: ReactiveVar<number>;
  section(): number {
    return this._section.get();
  }

  _rep: ReactiveVar<number>;
  rep(): number {
    return this._rep.get();
  }

  _cell: ReactiveVar<number>;
  cell(): number {
    return this._cell.get();
  }

  _beat: ReactiveVar<number>;
  beat(): number {
    return this._beat.get();
  }

  set(pos: ChartPosition): void {
    if ('song' in pos) this._song.set(pos.song);
    if ('round' in pos) this._round.set(pos.round);
    this._section.set(pos.section);
    this._rep.set(pos.rep);
    this._cell.set(pos.cell);
    this._beat.set(pos.beat);
  }

  toChartPosition(): ChartPosition {
    return {
      song: this._song.get(),
      round: this._round.get(),
      section: this._section.get(),
      rep: this._rep.get(),
      cell: this._cell.get(),
      beat: this._beat.get(),
    };
  }
}
