export const bluegrassWaltzSpreadGradients = [
  // Beat 1
  [
    [7 / 14, 1],
    [14 / 14, 4.5],
  ],
  // upstroke 1
  [
    [4 / 14, 1],
    [7 / 14, 2],
    [14 / 14, 3],
  ],
  // Beat 2
  [
    [0 / 14, 1],
    [1 / 14, 1.6],
    [7 / 14, 3.8],
    [14 / 14, 4.5],
  ],
  // upstroke 2
  [
    [2 / 14, 1],
    [7 / 14, 3.0],
    [14 / 14, 3.5],
  ],
  // Beat 3
  [
    [0 / 14, 1],
    [1 / 14, 1.6],
    [7 / 14, 3.8],
    [14 / 14, 4.5],
  ],
  // upstroke 3
  [
    [2 / 14, 1],
    [7 / 14, 3.0],
    [14 / 14, 3.5],
  ],
] as const;
