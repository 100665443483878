import type { MandolinChord } from '@/band/instruments/mandolin/MandolinChord';
import { mandolinChordDefinitionsArray } from '@/band/instruments/mandolin/mandolinChordDefinitionsArray';
import { Chord } from '@/music/Chord';

function loadChords() {
  function raiseStrings(strings: string, by = 1): string {
    return strings
      .split('')
      .map((s) => (/[0-9a-e]/.test(s) ? (parseInt(s, 16) + by).toString(16) : s))
      .join('');
  }

  mandolinChordDefinitionsArray.forEach(([strings, name, style]) => {
    const baseChord = new Chord(name);
    for (let raisedBy = 0; raisedBy < 11; raisedBy++) {
      const chord = baseChord.shift(raisedBy, baseChord.rootNote);
      const raisedStrings = raiseStrings(strings, raisedBy);
      MandolinChordLibrary.addChord({
        chord,
        strings: raisedStrings,
        shapeStrings: strings,
        raisedBy,
        style,
      });
      if (raisedStrings.includes('f')) break; // no chords go beyond fret 15!
    }
  });
  MandolinChordLibrary.sort();
}

export class MandolinChordLibrary {
  private static _chromaMaps: Map<string, MandolinChord[]>[] =
    // create an array of twelve Map objects
    Array.from({ length: 12 }).map(() => new Map<string, MandolinChord[]>());
  private static _unsorted = true;
  static size = 0;

  static getChords(chord: Chord): MandolinChord[] {
    if (this._unsorted) this.sort();
    return this._chromaMaps[chord.chroma]?.get(chord.type || 'major') || [];
  }

  static pickChord(
    chord: Chord | string,
    { style = undefined, useThirdlessG = false }: { style?: string; useThirdlessG?: boolean } = {}
  ): MandolinChord {
    if (typeof chord == 'string') chord = new Chord(chord);
    if (useThirdlessG && !chord.type) chord = chord.withNewType('5');
    const availableChords = this.getChords(chord);
    return availableChords.find((cd) => cd.style == style) ?? availableChords[0];
  }

  static addChord(mandolinChord: MandolinChord) {
    const mapForChroma = this._chromaMaps[mandolinChord.chord.chroma];
    if (!mapForChroma) throw new RangeError('Chroma out of range');
    const entry = mapForChroma.get(mandolinChord.chord.type || 'major');
    if (entry) {
      entry.push(mandolinChord);
      this._unsorted = true;
    } else {
      mapForChroma.set(mandolinChord.chord.type || 'major', [mandolinChord]);
    }
  }

  static sort() {
    this.size = 0;
    for (const chromas of this._chromaMaps.values()) {
      for (const array of chromas.values()) {
        array.sort((a, b) => a.raisedBy - b.raisedBy);
        this.size += array.length;
      }
    }
    this._unsorted = false;
  }
}

loadChords();
