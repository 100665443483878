import * as Sentry from '@sentry/browser';

const formatSupportedPromises = new Map<string, Promise<boolean>>();

export function canNativelyDecode({
  context,
  format,
}: {
  context: AudioContext;
  format: 'mp3' | 'opus';
}): Promise<boolean> {
  try {
    if (!formatSupportedPromises.has(format)) {
      if (
        format == 'opus' &&
        navigator.vendor.startsWith('Apple') &&
        /Version\/1[0-4]/.test(navigator.userAgent)
      ) {
        // Save ourselves the trouble of checking for nonexistent Safari Opus support
        formatSupportedPromises.set(format, Promise.resolve(false));
      } else if (format in testDataURIs) {
        const binaryString = atob(testDataURIs[format].split(',')[1] as string);
        // Uint8Array.from is broken on Safari, of course...
        const typedArray = new Uint8Array(new ArrayBuffer(binaryString.length));
        for (let i = 0; i < binaryString.length; i++) {
          typedArray[i] = binaryString.charCodeAt(i);
        }
        const buffer = typedArray.buffer;
        formatSupportedPromises.set(
          format,
          new Promise<boolean>((resolve) => {
            // Do both callback and promise APIs, because why not?
            const [succeed, fail] = [() => resolve(true), () => resolve(false)];
            context.decodeAudioData(buffer, succeed, fail)?.then(succeed, fail);
          })
        );
      }
    }
  } catch (e) {
    console.error(e);
    Sentry.captureException(e);
  }
  return formatSupportedPromises.get(format) || Promise.resolve(true);
}

const testDataURIs = {
  mp3: 'data:audio/mp3;base64,SUQzBAAAAAAAI1RTU0UAAAAPAAADTGF2ZjU2LjM2LjEwMAAAAAAAAAAAAAAA//OEAAAAAAAAAAAAAAAAAAAAAAAASW5mbwAAAA8AAAAEAAABIADAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDV1dXV1dXV1dXV1dXV1dXV1dXV1dXV1dXV6urq6urq6urq6urq6urq6urq6urq6urq6v////////////////////////////////8AAAAATGF2YzU2LjQxAAAAAAAAAAAAAAAAJAAAAAAAAAAAASDs90hvAAAAAAAAAAAAAAAAAAAA//MUZAAAAAGkAAAAAAAAA0gAAAAATEFN//MUZAMAAAGkAAAAAAAAA0gAAAAARTMu//MUZAYAAAGkAAAAAAAAA0gAAAAAOTku//MUZAkAAAGkAAAAAAAAA0gAAAAANVVV',

  opus: 'data:audio/ogg;base64,T2dnUwACAAAAAAAAAACzgr4nAAAAAI6H0BYBE09wdXNIZWFkAQE4AYC7AAAAAABPZ2dTAAAAAAAAAAAAALOCvicBAAAAbANP0wE6T3B1c1RhZ3MYAAAAbGlib3B1cyAxLjEtMTcwLWc4MDQ2MDMzAQAAAA4AAABFTkNPREVSPVJFQVBFUk9nZ1MAAMAwAAAAAAAAs4K+JwIAAAAc6sobBS8yLyoLGOEVgGJkQbWR+yseTmSlNjpHJojy4gPKSwrPdTG4avurh2arpMVOU7FUKeAbmNgY44CGHn+EGEEvK7smtD9JMS3oZ4iMdB8HTWImrHPQjmOiJBcjakMYkslsiXue9DJKuBjja+x0h+wnCEJqmUS5QdTMwi/6ok6K6CrJ+L+u3kBtHfbX7/p/DGWLagOHgxPYGONjkyAOeGxHzQF1GbdLUe/zKSGkaA4scltD2a5MO8YZCuaWVIfKsdiACIKsPdeYb+ChJGE=',
};
