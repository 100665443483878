import { strict as assert } from 'assert';
import type { Cell } from '@/chart/Cell.svelte';
import type { LinearizedBeat } from '@/chart/LinearizedBeat';
import { LinearizedCell } from '@/chart/LinearizedCell';
import type { LinearizedSong } from '@/chart/LinearizedSong';
import { Chord } from '@/music/Chord';

export class LinearizedMeasure {
  readonly song?: LinearizedSong;
  readonly cells: [LinearizedCell, ...LinearizedCell[]];
  readonly beats: [LinearizedBeat, LinearizedBeat, ...LinearizedBeat[]];
  readonly timeSignature: TimeSignature;
  timeSignatureChanged?: boolean;
  readonly key: NonNullable<Chord['key']>;
  readonly keyChord: Chord;
  readonly position: {
    section: number;
    rep: number;
    cells: number[];
  };
  // index?: number;
  next?: LinearizedMeasure;
  prev?: LinearizedMeasure;
  firstInRep?: boolean;
  lastInRep?: boolean;

  public get endOfSong(): boolean {
    return this.song?.endingMeasure == this;
  }

  private _onlyOneChord?: boolean | undefined;
  public get onlyOneChord(): boolean | undefined {
    return (this._onlyOneChord ??= this.beats.slice(1).every((b) => !b.chordChanged));
  }

  private _subdividedBeats?: boolean | undefined;
  public get subdividedBeats(): boolean | undefined {
    return (this._subdividedBeats ??= !!this.cells.find((c) => c.split));
  }

  constructor({
    song,
    timeSignature,
    rep,
    cells,
  }: {
    song?: LinearizedSong;
    timeSignature: TimeSignature;
    rep: number;
    cells: [Cell, ...Cell[]];
  }) {
    this.song = song;
    this.timeSignature = cells.find((c) => c.timeSig)?.timeSig || timeSignature;
    this.position = {
      section: cells[0].layout.sectionIndex,
      rep,
      cells: cells.map((cell) => cell.layout.cellIndex),
    };

    assert(cells[0].chordHeard.key);
    this.key = cells[0].chordHeard.key;
    this.keyChord = new Chord(this.key);

    this.cells = cells.map((cell) => new LinearizedCell(this, cell)) as typeof this.cells;
    this.beats = this.cells.flatMap((cell) => cell.beats) as typeof this.beats;
  }
}
