import * as Sentry from '@sentry/browser';
import { ReactiveVar } from 'meteor/reactive-var';
import { Tracker } from 'meteor/tracker';
import { doFMODSamplePriming } from '@/audio/doFMODSamplePriming';
import AudioManager from '@/audio/engine/AudioManager';
import { MasterPitch } from '@/audio/mixer/MasterPitch';
import { MasterVolume } from '@/audio/mixer/MasterVolume';
import AudioPackageLoader from '@/audio/samples/AudioPackageLoader';
import bassSampleDefinitions from '@/audio/samples/definitions/bassSampleDefinitions';
import clickSampleDefinitions from '@/audio/samples/definitions/clickSampleDefinitions';
import countSampleDefinitions from '@/audio/samples/definitions/countSampleDefinitions';
import percussionSampleDefinitions from '@/audio/samples/definitions/feetSampleDefinitions';
import newGuitarSampleDefinitions from '@/audio/samples/definitions/guitarSampleDefinitions';
import newMandolinSampleDefinitions from '@/audio/samples/definitions/mandolinSampleDefinitions';
import getAllAudioPackageURLs from '@/audio/samples/getAllAudioPackageURLs';
import { staticInstruments } from '@/band/staticInstruments';
import type { Song } from '@/chart/Song';
import { Conductor } from '@/Conductor';
import { Crnt } from '@/Crnt';
import UserPreferences from '@/user/UserPreferences';
import wait from '@/utilities/wait';

let downloadAudioPromise: Promise<void> | undefined;
let initialSetupPromise: Promise<void> | undefined;
let initializePromise: Promise<void> | undefined;
let automationComputation: Tracker.Computation | undefined;
let bandPlanComputation: Tracker.Computation | undefined;
let primeSamplesComputation: Tracker.Computation | undefined;

export class AudioSystem {
  private static oneTimeSetup(): void {
    AudioManager.defineChannels([
      { id: 'count', polyphonyLimit: 12 }, // 8 at 300 BPM
      { id: 'guitar', polyphonyLimit: 50 }, // 28 at 300 BPM
      { id: 'bass', polyphonyLimit: 16 }, // 14 at 300 BPM
      { id: 'mandolin', polyphonyLimit: 24 }, // 20 at 300 BPM
      { id: 'feet', polyphonyLimit: 16 },
      { id: 'click', polyphonyLimit: 4 },
    ]);

    AudioManager.defineSamples([
      ...countSampleDefinitions.map((sd) => ({ ...sd, channel: 'count' })),
      ...bassSampleDefinitions.map((sd) => ({ ...sd, channel: 'bass' })),
      ...newGuitarSampleDefinitions.map((sd) => ({ ...sd, channel: 'guitar' })),
      ...newMandolinSampleDefinitions.map((sd) => ({ ...sd, channel: 'mandolin' })),
      ...percussionSampleDefinitions.map((sd) => ({ ...sd, channel: 'feet' })), // for now
      ...clickSampleDefinitions.map((sd) => ({ ...sd, channel: 'click' })),
    ]);
  }

  private static downloadAudio(): Promise<void> {
    if (downloadAudioPromise) return downloadAudioPromise;
    return (downloadAudioPromise = AudioPackageLoader.downloadPackages(getAllAudioPackageURLs())
      .then(() => {
        return;
      })
      .catch((err) => {
        //prettier-ignore
        if (err instanceof Error && (err.message.includes('404 ') || err instanceof TypeError)) {
          Bert.alert('Unable to download audio over the network. Usually this is because of an issue with your internet connection. Try reloading the page or connecting to another network if possible.', 'danger');
        } else {
          Bert.alert("An error occured while downloading Strum Machine's audio files. Please reload the page and try again.", 'danger');
        }
        console.error(err);
        Sentry.captureException(err);
        setTimeout(() => (downloadAudioPromise = undefined), 1000);
      }));
  }

  static async initialize(): Promise<void> {
    if (!initialSetupPromise) {
      AudioSystem.oneTimeSetup();
      initialSetupPromise = AudioSystem.downloadAudio();
    }
    await initialSetupPromise;
    if (initializePromise) return initializePromise;
    let samplesLoaded = false;

    setTimeout(() => {
      if (!samplesLoaded) Sentry.captureMessage('Audio not loaded even after 20 seconds');
    }, 20000);

    return (initializePromise = AudioManager.initialize().then(async () => {
      const sampleLoadPromise = AudioManager.sampleLibrary.loadAudioIntoSamples();
      automationComputation = automateChannelAttributes();
      bandPlanComputation = Crnt.automaticallyGenerateBandPlans();
      await sampleLoadPromise;
      samplesLoaded = true;
      if (AudioManager.mode == 'cordova') {
        primeSamplesComputation = Tracker.autorun(() => {
          const allMeasures = [];
          for (const song of Crnt.reactiveSongs()) {
            allMeasures.push(...song.reactiveLinearized().measures);
          }
          if (!Conductor.playing()) doFMODSamplePriming(allMeasures);
        });
      }
    }));
  }

  static teardown(): Promise<void> {
    if (automationComputation) automationComputation.stop();
    if (bandPlanComputation) bandPlanComputation.stop();
    if (primeSamplesComputation) primeSamplesComputation.stop();
    initializePromise = undefined;
    return AudioManager.teardown();
  }

  static async restart({ pause = 0.5 } = {}): Promise<void> {
    await AudioSystem.teardown();
    await wait(pause * 1000);
    await AudioSystem.initialize();
  }
}

function automateChannelAttributes(): Tracker.Computation {
  const currentSong = new ReactiveVar<Song | undefined>(undefined);
  return Tracker.autorun(() => {
    Tracker.autorun(() => {
      const songIndexBeingPlayed = Conductor.displayState.position()?.song() ?? -1;
      const previousSong = currentSong.get();
      const song = Crnt.song() ?? Crnt.songs[songIndexBeingPlayed];
      if (previousSong !== song) currentSong.set(song);
    });

    Tracker.autorun(() => {
      // 65% multiplier is to prevent overdriving compressor with too-loud instruments
      const masterVolume = MasterVolume.get() * 0.65;
      const band = currentSong.get()?.band;
      const bandInstruments = band?.instruments ?? staticInstruments;
      Object.values(bandInstruments).forEach((inst) => {
        Tracker.autorun(() => {
          const channel = AudioManager.mixer?.channel(inst.id);
          if (!channel) return;
          const desiredVolume = inst.volume() * masterVolume;
          if (channel.volume != desiredVolume) channel.volume = desiredVolume;
          const desiredPan = inst.pan();
          if (channel.pan != desiredPan) channel.pan = desiredPan;
          const desiredMuted =
            inst.globalMute() || !!(band?.clickTrack.enabled() && band?.clickTrack.mutingBand());
          if (channel.muted != desiredMuted) channel.muted = desiredMuted;
        });
      });
    });

    Tracker.autorun(() => {
      const channel = AudioManager.mixer?.channel('count');
      if (!channel) return;
      channel.volume = Math.max(UserPreferences.get('countInVolume'), 0.001) * MasterVolume.get();
      channel.muted = !UserPreferences.get('countInEnabled');
    });

    Tracker.autorun(() => {
      const channel = AudioManager.mixer?.channel('click');
      if (!channel) return;
      channel.volume = Math.max(UserPreferences.get('clickVolume'), 0.001) * MasterVolume.get();
      channel.muted = !UserPreferences.get('clickEnabled');
    });

    Tracker.autorun(() => {
      const masterChannel = AudioManager.mixer?.masterChannel;
      if (masterChannel) masterChannel.pitchShift = MasterPitch.getCents();
    });
  });
}
