import { MusicLibrary } from '@/library/MusicLibrary';
import CollapsableSongList from '@/ui/lists/CollapsableSongList.svelte';

Template.songListModal.onRendered(() => {
  const instance = Template.instance();
  Tracker.afterFlush(() => {
    if (instance.find('.modal-dialog').clientHeight > instance.find('.modal').clientHeight * 0.8) {
      instance.find('.modal').style.alignItems = 'flex-start';
    }
  });
});

Template.songListModal.helpers({
  list() {
    const instance = Template.instance();
    return MusicLibrary.lists.findOne(instance.data.listId);
  },

  CollapsableSongList() {
    return CollapsableSongList;
  },
});

Template.songListModal.events({
  'click .js-close': function () {
    Modal.hide();
  },
});
