import SectionSettingsMenu from '@/editor/ui/SectionSettingsMenu.svelte';

Template.sectionSettingsModal.helpers({
  SectionSettingsMenu: () => SectionSettingsMenu,
});

Template.sectionSettingsModal.events({
  'click .js-close': function () {
    Modal.hide();
  },
});
