import { updateRouteMusicId } from '@/browser/updateRouteMusicId';
import { GroundedSongsCompressor } from '@/library/GroundedSongsCompressor';
import { MusicLibrary } from '@/library/MusicLibrary';
import { OfflineMusicStorageBackend } from '@/library/OfflineMusicStorageBackend';
import localStorageDBNames from '@/local-db/localStorageDBNames';
import { callServerMethodWithoutRetry } from '@/utilities/callServerMethodWithoutRetry';

interface SongRecord {
  _id: string;
}

export class OfflineSongBackend extends OfflineMusicStorageBackend<any> {
  compressor = GroundedSongsCompressor;
  constructor() {
    super({
      compressor: GroundedSongsCompressor,
      indexDBName: localStorageDBNames.songsIndex,
      fullDataDBName: localStorageDBNames.songsData,
      insertionsDBName: localStorageDBNames.songsInserted,
      updatesDBname: localStorageDBNames.songsUpdated,
      removalsDBName: localStorageDBNames.songsRemoved,
    });
  }

  protected async doServerInsert(record: SongRecord): Promise<string> {
    const oldId = record._id;
    const newId = await callServerMethodWithoutRetry('songs.insert', record);
    if (oldId && oldId !== newId) {
      updateRouteMusicId(oldId, newId);
      MusicLibrary.songs.handleUpdatedSongId(oldId, newId);
      MusicLibrary.medleys.handleUpdatedSongId(oldId, newId);
      this.groundedIndex.remove(oldId); // this will be resynced automatically from the publication
      this.fullDataStorage.delete(oldId);
    }
    return newId;
  }

  protected async doServerUpdate(record: SongRecord): Promise<void> {
    return void (await callServerMethodWithoutRetry('songs.update', record));
  }

  protected async doServerRemove({ _id: songId }: { _id: string }): Promise<void> {
    return void (await callServerMethodWithoutRetry('songs.delete', songId));
  }

  protected async getRecordsFromServer(recordIds: string[]): Promise<SongRecord[]> {
    if (recordIds.length === 0) return [];
    const result = await callServerMethodWithoutRetry('songs.fetchMultiple', recordIds);
    return result.songs;
  }
}
