import { Crnt } from '@/Crnt';

Template.songDetailsEditor.onRendered(() => {
  const instance = Template.instance();
  instance.find('.js-buttons')._uihooks = {
    insertElement(node, next) {
      $(node).insertBefore(next);
      node.style.overflow = 'hidden';
      const width = node.clientWidth;
      $(node).velocity(
        {
          width: [width, 0],
        },
        {
          duration: 600,
          easing: [0.4, 0, 0.4, 1],
          complete() {
            node.style.overflow = '';
          },
        }
      );
    },
  };
});

Template.songDetailsEditor.helpers({
  songHasKey() {
    return !!Crnt.song()?.key();
  },
});
