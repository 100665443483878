const Version = {
  current: '410.11.0',
  history: [],
};

Version.history.push({
  version: '410',
  summary: 'Alpha: 2025 Server Migration',
  details:
    "If you're seeing this and you don't already know all about it, there's something very wrong going on.",
});

Version.history.push({
  version: '409',
  summary: 'Internal release',
  details: 'Internal release',
});

Version.history.push({
  version: '408',
  summary: 'BIG Band Settings update: new presets, walking bass, and more!',
  details: `
  We’ve overhauled the Band Settings menu... again! (Hopefully for the last time.)

  Now it's **easier than ever** to load a preset musical style (or make your own presets!), and **we’ve added a bunch of new presets** (especially in the bluegrass category) and we’ll expand the list further based on user feedback.

  To go along with the new presets, we’ve made some improvements to the instruments:
  - 👉 The standup bass can now play four-note-per-bar walking bass lines! Great for jazz, swing, country, and more.
  - 👉 The mandolin chop sustain and intensity/loudness can now be adjusted – we made it somewhat "dry" (less sustain) by default.
  - 👉 The mandolin can now insert rhythmic accents (downbeat strums and upstrokes) into the chop, according to your settings.
  - 👉 There are new half-time patterns for the mandolin chop and standup bass to match the existing half-time pattern for the guitar. All three of these patterns will be improved in the future, but even this basic version opens up some fun new possibilities.
  - 👉 You can now make the guitar substitute all C chords with Cadd9 when in the key of G – I added this specifically for the new "mash" preset.

  Beyond that:
  - 👉 The overall Band Settings interface has been improved to be cleaner and easier to navigate.
  - 👉 When sharing a song that you've created or edited, the person viewing the song will be able to load your band settings. (This can be a roundabout way of sharing band presets.)
  - 👉 Various minor bugs that have been reported over the past couple months have been fixed.
  `,
});

Version.history.push({
  version: '405-407',
  summary: '',
  details: `
  Beta testing releases.
  `,
});

Version.history.push({
  version: '403-404',
  summary: '',
  details: `
  Internal and alpha testing releases.
  `,
});

Version.history.push({
  version: '402',
  summary: 'Upgraded mandolin chop patterns',
  details: `
  The mandolin chop has been overhauled and expanded in this beta release, with new options for sustain and intensity, along with the option of adding rhythmic accents (like upstrokes) between offbeat chop chords. Let me know what you think!

  Also:
  * 🚀 Performance of the band settings menus has been greatly improved.
  * 📋 There's a new keyboard shortcut for paste-in-place (Cmd+Shift+V or Ctrl+Shift+V) which overwrites the selected cells.
  * 🏝️ There are new "half-time" mandolin chop and standup bass options... very rudimetary (as is the guitar half-time strum, to be honest) but it's a start, and will be improved over time.
  `,
});

Version.history.push({
  version: '401',
  summary: '',
  details: `
  Internal testing release.
  `,
});

Version.history.push({
  version: '400',
  summary: 'Various bug fixes',
  details: `
  Feels a little weird to do a small bugfix update for "Version 400", but these version numbers are simply incremented for every substantial update (with point releases for small "addendums" to the update) and are not planned around major updates. Part of me wishes I had more traditional version numbers ("Announcing version 3.0!") but that fits more with a development style of big updates once in a blue moon, which companies do partly because it's better for marketing and press releases. Instead, I aim for more steady improvement and continual updates, which is better for users. 🙂

  Anyway, here's what's fixed in this release:

  * 🐜 When editing the reps of a section, the cursor is no longer automatically scrolled into view (which was annoying when editing long song arrangements)
  * 🐜 Editor toolbar is temporarily hidden when editing the name of a section on a phone (to keep it out of the way)
  * 🐜 When changing the key in edit mode, playback effects (rest, stop, diamond) are retained
  * 🐜 When duplicating a section in edit mode, the rep count of the new section is set to 1 (if the old behavior was preferable, I'd be curious to know)
  * 🐜 New medleys use the BPM of the first song/tune in the medley by default
  * 🐜 When creating or appending to a medley, band settings are copied from the constituent songs/tunes
  * 🐜 Fixed a bug where a song added to a medley wouldn't play at first
  * 🐜 The "View All Songs" button (under "A-Z" on the index screen) wasn't always working properly
`,
});

Version.history.push({
  version: '399',
  summary: 'Fix iOS app crash and other bugs',
  details: `
  Got some bug fixes for you in this update!

  * 🍏 There was a bug where the iOS app would sometimes crash when opening it. This has (hopefully!) been fixed now.

  * 🔨 I hammered out a new line-length algorithm, so hopefully editing the length of lines should work more smoothly and predictably now.

  * 🔗 YouTube links with a playlist in the URL are now processed correctly by the New Reference form.

  * 🚀 I made some improvement to the performance of the circular "Feel" slider on slower devices.
`,
});

Version.history.push({
  version: '398',
  summary:
    'Experimental metronome and double-tap preference, plus improved sorting and A/V syncing',
  details: `
  Note: I'm playing around with more detailed release notes. Let me know if you like this kind of detail or if you prefer the more skimmable bullet-list format.

* 🧱 **Experimental metronome feature.** Strum Machine is a great metronome replacement, but sometimes you might want to hear the crisp click of a metronome in addition to, or instead of, the other instruments. By popular demand, we've been working on a metronome feature for Strum Machine, and the "first draft" is ready for you to play with! First, opt in to this feature in Advanced Settings (top-left menu), then check out the new "Metronome" option in the sidebar menu. This feature is experimental and is very much subject to change, but I wanted to get an early version out for folks to be able to use right away. [Send me your feedback](contact), and expect a more polished version to come in the fall.

* 👉 Also in Advanced Settings, I added a toggle switch to **require two taps on a chord in order to play from that point** when using a touchscreen. We've been hearing reports of accidentally triggering playback on mobile devices when this was not wanted, such as in jam or performance scenarios where you just want a chord reference. This is my first experiment toward supporting that use case better. [Let me know](contact) what you think!

* ↕️ **The song & list sorting algorithms have been improved**. Song and list titles have always been sorted with leading articles ("The", "A", and "An") at the end, but this created problems when "A" was meant to refer to a letter, such as in "A exercises" (i.e., the key of A) or "A and E Waltz". Strum Machine will now do its best (based on my manual analysis of all song titles in the database) to determine if "A" is meant to be a letter and will sort accordingly. If you find any issues with the new sorting algorithm, [let me know](contact)!

* 🔊 **The yellow current-beat highlight now syncs up with Bluetooth speaker audio on Chrome** and Chrome-based browsers like Edge. Previously, I thought this was only possible inside a mobile app (where it's an existing feature). But recently, I did my first podcast interview on a web-development podcast called ShopTalk ([here's my episode](https://shoptalkshow.com/616/)) and I got an email from a listener who's on the Google Chrome team (Thomas Steiner). In the course of our email exchange, I learned about a new (to me) browser API that gives me the information needed to correct for Bluetooth audio latency. Unfortunately, the Firefox and Safari implementations of this API are buggy at the moment, but it works in Chrome and Chrome-based browsers (Edge, Brave, Vivaldi, and my personal favorite, Arc) and I'll check back in on Firefox and Safari later.

* 🐜 Fixed a bug with the [MP3 recorder](record) where the BPM in the filename was wrong.
  `,
});

Version.history.push({
  version: '397',
  summary: 'Editor 3.0; more chords, better UI, numbers in the editor...',
  details: `
  This update features a newly-redesigned song editor! We went back to the drawing board and made a new interface that will hopefully feel familar but better.

  More chords are also available, including almost every chord you could previously only find in iReal Pro. New settings are available in the editor, such as switching to numbered chords. Subdivided beats are easier to edit. Click-and-drag selection works more smoothly. Plus, we've paved the way for more new editing features to come in the future.

  👉 **[Watch this video](/help/april-2024-editor-update)** for a quick five-minute tour!

  Speaking of videos, I also recently filmed a multi-part video series that walks you through the process of transcribing chords by ear. [Watch them all on this forum post](https://community.strummachine.com/t/transcribing-by-ear-video-series/964).

  Other changes include:
* 👉 Waltzes (songs in 3/4 time) now have bar lines between *every* measure, not just every pair of measures.
* 👉 Songs with numbers in the title sort better – "Blue Yodel #4" now comes before "Blue Yodel #17". Same with lists.
* 👉 You can now press the "–" button in Auto-Speedup to go directly into Auto-Slowdown mode.
* 👉 Minor keys use better guitar chord voicings.
* 👉 When Capoed Chords is in use and you change keys, the capo will stay on if the same base shape can be used (e.g. A to B).
  `,
});

Version.history.push({
  version: '395-396',
  summary: 'Editor 3.0 Beta Versions',
  details: `
  Public beta releases of Editor 3.0, previously released on [beta.strummachine.com](https://beta.strummachine.com).
  `,
});

Version.history.push({
  version: '393-394',
  summary: 'Editor 3.0 Alpha Versions',
  details: `
  Alpha releases of Editor 3.0 for internal development and usability testing.
  `,
});

Version.history.push({
  version: '392',
  summary: 'Band Settings 2.0 with more guitar abilities! (Plus iOS 17 fixes and a desktop app.)',
  details: `
This is a **huge** update, eight months in the making! It's a complete overhaul of the band settings system. **[Watch this video walkthrough](/help/instruments)** for a quick tour of some of the new features and improvements.

#### Band settings interface

* ⭐️ Redesigned menu that is easier to read and navigate
* ⭐️ More prominent Presets button for quick access to band presets
* ⭐️ Improved rhythm pattern visualizations that mirror what you hear as you customize the settings
* ⭐️ Volume/pan sliders now affect the current song immediately – for global settings, continue using the [Audio/Mix panel](/help/mixer)
* ⭐️ New [help docs](/help/band) for the band settings system

#### Guitar: All boom-chuck–based strums

* ⭐️ Choose between hearing alternating bass notes or the root note only
* ⭐️ Add automatic leading notes: transitional notes before chord changes (guitar only for now, bass coming soon)
* ⭐️ Add automatic guitar bass runs, picked based on the chord progression and your preferences
* ⭐️ Change the strum to be more boom-heavy or chuck-heavy
* ⭐️ Specify the "voicing" to use (chord shape, capo position, drop-D or standard tuning, etc.)

#### Guitar: Boom chuck with upstrokes

* ⭐️ Make the pattern more strum-heavy (think Jimmy Martin) or pick-heavy (think cross-picking)
* ⭐️ Change the feel of the strum with a new "2D slider" control – see and hear your changes immediately as you drag the slider around

#### Guitar: Syncopated boom-chuck strum

* ⭐️ Revamped syncopated (D DU UDU) strumming pattern that is more configurable and works better in more situations

#### Guitar: Dampened strumming patterns

* ⭐️ A new "in-between" option for onbeats: a "short strum" that only plays 2 or 3 strings
* ⭐️ Adjustable sustain duration for dampened strums, anywhere from full sustain (no dampening) to almost no sustain (dampened immediately) – moderate sustain sounds great with slower ballads, for example
* ⭐️ Adjustable backbeat emphasis (softer onbeats and louder offbeats)

#### Standup bass instrument

* ⭐️ Choose which octaves you'd like to hear the bass use: low, middle, or high (plus a "mix" option that currently needs work)
* ⭐️ Disable root-fifth alternation and hear the bass "pedal" the root note instead
* ⭐️ Fine-tune the amount of sustain you want to hear from the bass

#### On my to-do list for the future:

* ☐ Add many more built-in presets and styles to reduce the need for fine-tuned customization in common genres
* ☐ Add more mandolin strumming patterns to match the guitar offerings
* ☐ Add leading notes and chord walk-ins to the bass
* ☐ Add walking bass (four notes per measure) for jazz/swing genres
* ☐ Continue adding more strumming patterns to the guitar, such as a syncopated waltz strum, a simpler DUDDUD jig strum, and many more
* ☐ Add more jazz/swing chords
* ☐ ...and more, depending on what folks want to see!

#### Desktop apps and iOS 17 issues

Finally, we now have a proper desktop (Windows/Mac/Linux) app for Strum Machine! [Download it here](/app/install). It’s based on the web version, with the same functionality, but it’s a standalone installable app which makes it easy to access. Offline support is also baked in. We've also made some changes to hopefully address the recent Apple bug in iOS 17 that has been breaking audio playback for some users. (It's been a doozy of a bug to track down!)

As always, please let us know about any issues you encounter, or if you have any feedback or suggestions for improvement. Thanks!
`,
});

Version.history.push({
  version: '390-391',
  details: `
  Beta versions, previously released on [beta.strummachine.com](https://beta.strummachine.com).
`,
});

Version.history.push({
  version: '389',
  details: `Alpha version shared with members of the [Strum Machine Backstage Pass](https://buttondown.email/sm-backstage/archive/early-access-to-band-settings-2/) newsletter. (You're welcome to [sign up](https://buttondown.email/sm-backstage)!)`,
});

Version.history.push({
  version: '386-388',
  details: `Internal development versions.`,
});

Version.history.push({
  version: '385',
  details: `First beta release of what would become Band Settings 2.0; we ended up going back to the drawing board after this release, but it was a good start.`,
});

Version.history.push({
  version: '382-384',
  details: `Internal development versions.`,
});

Version.history.push({
  version: '381',
  summary: 'Adjustable pitch/tuning (A ≠ 440) and more',
  details: `
  You can now retune the instruments of Strum Machine up or down, away from the standard "A = 440 Hz" pitch, using the new button at the bottom of the Audio/Mix settings panel. Once set, all songs played on the current device will be played "sharp" or "flat" by the amount specified.

  For most people, this update will not be useful and can be ignored. But for those of you who have been wanting to be able to match the pitch of a recording that is not tuned to standard pitch and asking me for this feature, I hope you enjoy it!

  In addition, I have two work-in-progress features in this release:

  1. A new "Notepad editor monospace font" option in the [Advanced Settings](/app?modal=advanced-settings). This only changes the font of the editor, but you can have parts of your notes rendered in monospace font by sticking them between pairs of three backticks ([example here](?help=notes-formatting)).

  2. A per-instrument "customizations" UI in the band settings menu, which eventually will have various options to tweak a given strumming/playing style. (Right now it just has timing tweaks which most people won't be interested in.)

  Finally, this release also includes a pretty substantial reworking and modernization of the client-server communication code. I've kicked the tires myself quite a bit, and things went fine in beta testing, but I'll still be checking the logs for errors I might have missed.
  `,
});

Version.history.push({
  version: '379-380',
  summary: 'Beta releases',
  details: `
  Beta versions, previously released on [beta.strummachine.com](https://beta.strummachine.com).
  `,
});

Version.history.push({
  version: '378',
  summary: 'Bug fixes and small improvements',
  details: `
  Happy New Year, everyone! I am back from holiday break and starting off the year with some small bug fixes and improvements:

  * 🐛 Squashed bugs relating to waltz-time upstroke timing, newly-created songs not instantly appearing in the song index, deleting a song that is used in a medley, customizing line lengths in 9/8 time, recording MP3 files when Auto-Advance is enabled, and the Major/Minor selection buttons in the editor's key selection menu.
  * 🗒️ For those of you that use the Notepad to store and refer to lyrics, you may have been annoyed by how Strum Machine automatically keeps the current chords in view, pulling you away from the Notepad on smaller screens. Now Strum Machine detects if you've scrolled down to view the Notepad and won't automatically scroll the view if this is the case. In addition, the Notepad experience should be a bit smoother on phones and small screens.
`,
});

Version.history.push({
  version: '377',
  summary: 'Corrected BPMs for 3/4 and 6/8 time; added BPM preferences',
  details: `
  This update addresses one of the most embarassing flaws that Strum Machine has had since it was first created in 2016: the BPM values for waltzes and jigs were off by a factor of 1½ and 1⅓, respectively, because the wrong formula was being used. Fixing this was trickier than you might think, but now BPM values are correct for all time signatures. 😅

  In addition, you can now change the definition of what “beats per minute” means under the [Advanced Settings](/app?modal=advanced-settings) menu.
`,
});

Version.history.push({
  version: '376',
  summary: 'BIG UPDATE: New strumming patterns and more!',
  details: `
  With this release, Strum Machine now has an assortment of new guitar strumming patterns to choose from!

  Watch this six-minute video that gives an overview of how to use the new features:

<div style="padding:56.25% 0 0 0;position:relative; margin-bottom: 1.25em;"><iframe src="https://player.vimeo.com/video/754493611?h=1e62258f29&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Introducing: new strumming patterns and band settings menu!"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>

  Simulating more complex strums in a realistic way is a big technical challenge, and this update involved countless hours of research and development, spanning nearly five *years* (though most progress happened in the last nine months). I'm thrilled with the update and the positive response I've received from my beta testers, and I hope you enjoy the upgrade. There is more to come in future updates, too!

  Highlights of this release:

  * ⭐️ Fully re-recorded guitar and mandolin samples with much-improved audio quality and clarity
  * ⭐️ A few bluegrass-style strumming patterns with upstrokes
  * ⭐️ A Celtic-style strum for reels and jigs (with drop-D tuning)
  * ⭐️ Dampened closed-chord strums for swing and jazz
  * ⭐️ A couple "pop" strumming patterns (including a half-time strum)
  * ⭐️ Configurable "upstroke swing" to adjust the feel of each strumming pattern ([learn more about upstroke swing](#swing))
  * ⭐️ Per-song band settings so that each song can have its own backup style
  * ⭐️ A few built-in band presets to get you started, and the ability to make your own presets
  * ⭐️ Ability to edit default preset settings for any song you open
  * ⭐️ Both global instrument volume settings (as before) and optional song-specific volume overrides
  * ⭐️ Pan controls for each instrument, to put them in your left or right speaker
  * ⭐️ Optional foot tap, either on downbeats or in a Canadian-style rhythm
  * ⭐️ Support for making "5" chords that have no third
  * ⭐️ A master volume control, useful for Zoom calls and JamKazam, etc.
  * ⭐️ A new audio engine for iOS, for better performance and lower battery usage
  * ⭐️ A bit of design "polish" throughout the app

  Updates planned for the future (related to this release) include:

  * 🔲 Improvements to all existing guitar strumming patterns: more variety, more realism, more options
  * 🔲 Additional strumming patterns (let me know if there are specific ones you most want to see!)
  * 🔲 Automatic bass runs (hopefully)
  * 🔲 Ability to specify "voicing" to use (capo position, drop-D or standard tuning, etc.)
  * 🔲 More mandolin strumming patterns to match the guitar offerings
  * 🔲 Bass options, including a walking bass
  * 🔲 Configurable song endings
  * 🔲 More jazz/swing chords
  * 🔲 ...and more, depending on what folks want to see!

  **Head on over to the <a href="https://community.strummachine.com/t/620" target="_blank">forum thread for this update</a> to talk about it with me and the rest of the Strum Machine community!** Or just write me directly through the Help menu in the top-right corner if you have any feedback. Thanks!
`,
});

Version.history.push({
  version: '373-375',
  summary: '',
  details: `
  Versions 373-375 were released to [the beta site](https://beta.strummachine.com), where anyone can try out the latest version of Strum Machine that is available for public beta testing... as well as the NEW "SM Beta" iOS app, which you can [install through TestFlight](https://testflight.apple.com/join/eabHfWMa) (Apple's method for installing beta apps) if you want to use the latest cutting-edge features on your iPhone or iPad.
`,
});

Version.history.push({
  version: '371',
  summary: '',
  details: `
  This was the strumming patterns update that was briefly released on the main Strum Machine website/app; it had to be pulled from there due to some issues that were resolved with version 376.
`,
});

Version.history.push({
  version: '361-370',
  summary: '',
  details: `
  Versions 361-370 were released to [the beta site](https://beta.strummachine.com), where anyone can try out the latest version of Strum Machine that is available for public beta testing.
`,
});

Version.history.push({
  version: '360',
  summary: 'Small bug fixes',
  details: `
  Small update with various bug fixes for issues that were showing up here and there, plus low-level library updates to stay compatible with the latest browsers.
`,
});

Version.history.push({
  version: '359',
  summary: 'Subscriptions in Euro and GBP',
  details: `
  The time has come where Strum Machine has to start collecting sales tax in various parts of the world. The problem is, sales taxes in the US and Canada are generally added onto the cost of the product, whereas they are *included* in the price in many other countries that use a VAT system, such as basically all of Europe. Plus, while US sales taxes are pretty low (under 10%), European VAT rates are often 20% or higher.

  To address this, Strum Machine is now offering subscriptions billable in Euro (€5/mo) and GBP (£4.29/mo). If you're in Europe, you'll save money by switching currencies since VAT is included in those prices... not to mention you won't pay exchange fees or see the price fluctuate with the exchange rate. [Click here to learn how to switch to Euro or GBP](https://strummachine.com/app/switch-currency?currency=eur).

  (We'll be sending out notices to customers who are affected by the new tax charges a week prior to their subscription renewal.)
  `,
});

Version.history.push({
  version: '358',
  summary: 'iOS app fixes (mostly)',
  details: `
  This first release for 2022 aims to improve a few niggling bugs that have been affecting users of the iOS app. Issues that prevented playback from working after switching from a different audio-related app should be fixed now.

  More importantly, the "white screen of death", in which the app only shows a white screen until [restarted](/help/restart-ios), may or may not be helped by this update -- time will tell. (That particular bug is affecting a lot of apps right now, and nobody can seem to figure out why.)
  `,
});

Version.history.push({
  version: '357',
  summary: 'New audio engine for iOS',
  details: `
  This update includes the first version of a **brand-new audio engine for the iOS app!** (Release announcement [here](https://community.strummachine.com/t/new-ios-app-version-with-audio-fixes/474).) The objective was to fix the bugs introduced by Apple in iOS 15. To use the new audio engine, make sure you're updated to the latest version of the app *in the App Store* (1.357.0). You can verify that the new engine ("AudioKit") is running in your [Advanced Settings](?modal=advanced-settings).

  With the new audio engine enabled, Strum Machine is also able to continue playing if you switch away from the app or turn off your screen, which is a nice bonus. 👍

  Since this was such a sweeping change to how audio is generated in the Strum Machine iOS app, I definitely want to hear about any new problems you may notice with it, so that I can fix them!

  * 🐜 (In v357.3) Fixed beginning of count-in getting chopped off sometimes.
  * 🐜 (In v357.3) Fixed various glitches with the new audio system.
  `,
});

Version.history.push({
  version: '356',
  summary: 'Lots of bug fixes and small tweaks',
  details: `
  Lots of small tweaks, bug fixes, and improvements in this release, including (but not limited to):

  * ⏯ The <kbd>Enter</kbd> key now plays/stops playback in edit mode.
  * 📋 Added cut/copy/paste buttons so that you can use the clipboard on touch-only devices like the iPad.
  * 🎵 The mandolin "A" chord was changed to a "no-third" chord, for better compatibility with modal tunes until Strum Machine gains the option to specify chord voicing preferences for each song.
  * 🍏 Laying groundwork for the upcoming iOS app update, which will fix the audio issues there (see previous release's notes).
  * 🐜 Fixed a bug where sometimes new songs wouldn't appear in the song list until you made a change to the search query.
  * 🐜 Auto-Speedup and Auto-Finish now reset when you hit restart, and don't get thrown off by pausing and resuming.
  * 🐜 ~~Playing a paused song skips the count-in now~~ reverted as it was causing issues.
  * 🐜 Fixed keyboard play/stop shortcuts on Firefox.
  * 🐜 Fixed a couple "edge cases" with playing back certain chord progressions.
  `,
});

Version.history.push({
  version: '355',
  summary: 'Workarounds for Safari/iOS bugs',
  details: `
  With the release of iOS 15 and Safari 15, Apple introduced many new bugs in the Web Audio system that Strum Machine uses. I've been able to circumvent some of these bugs, but there are at least two that only Apple can fix. This update adds code to detect these issues, and instructions for how to work around them (plus a one-tap button to reload Strum Machine in cases where that helps).

  In the long run, there are two solutions to these issues:

  1. Apple releases an update that fixes the bugs. They've actually identified the issue (and the fix) in their code already, but it could be awhile before that fix finds its way to our devices.

  2. Strum Machine ditches Web Audio on Apple devices, and uses a more low-level "native" audio interface. This involves a lot of coding that is outside my realm of expertise, which is why I haven't done it sooner. I've decided to hire an iOS/Mac developer with lots of experience building music apps, who should be able to build this much faster than I could.

  Either solution will do the trick, but I'm optimistic that I will be able to pull off the second option long before Apple gets around to releasing their bug fix. 🧑‍💻

  Thank you for your patience, and please continue to feel free to write me if you're having issues of any kind with Strum Machine.
  `,
});

Version.history.push({
  version: '354',
  summary: 'BIG UPDATE with medleys, playlists, and more!',
  details: `
  This is a **big** update, many months in the making. Huge thanks to everyone who [beta-tested](#beta) the new features and offered their feedback!

  There are three big NEW features available in this release:

  * ⭐ **Medleys!** Multiple songs can be combined into a set and Strum Machine will seamlessly play through them one after another. Songs can be in different keys and at different tempos if desired. [Watch the overview of how medleys work](#medleys), or just load up a song and then go to "Medley" in the right-hand sidebar to get started.

  * ⭐ **New "playlist" features!** Go to any song *through* a list and you'll see a list navigation bar at the top of the screen, with *Auto-Advance* and *shuffle* features available. [Learn more about the new navigation bar](#list-nav).

  * ⭐ **Ending brackets!** These can be used to have a repeating section end differently on each repetition. [Learn more](#volta).

  Most of the changes in this update are actually invisible, "under the hood" improvements. Huge portions of the code that powers Strum Machine have been rewritten to pave the way for the next wave of new features (including the three new features in this release).

  Some smaller noteworthy changes in this release:

  * 📋 The song editor copy/paste functionality now works across browser windows/tabs, making it easier to copy part of one song into another.
  * ⏮ Changed behavior of the restart button (and Backspace key); it will now go to the beginning of the current *section*. You can press it twice to go back to the beginning of the song.
  * 🔨 Clicking Auto-Finish "rep markers" doesn't start immediately anymore. I'm consdering making this the behavior of the section rep markers as well.

  Also:

  * 🍏 Apple introduced several "show-stopping" bugs in version 15 of Safari and iOS that affect the audio system that Strum Machine uses. Some bugs (such as broken MP3 decoding) could be worked around, while others (like the [bug](https://bugs.webkit.org/show_bug.cgi?id=232728) that sometimes halts playback altogether) only Apple can fix. I'm still looking into what bugs are causing which problems, though. If you encounter any issues with audio playback, please write [support@strummachine.com](mailto:support@strummachine.com) and describe the issue you're facing – or better yet, send me a video!
`,
});

Version.history.push({
  version: '345-353',
  summary: 'Beta-only releases',
  details: `
  Beta-only versions that were deployed at [beta.strummachine.com](https://beta.strummachine.com) in September, October, and November of 2021.
`,
});

Version.history.push({
  version: '344',
  summary: 'Audio issues on Safari 15',
  details: `
  * 🐜 Safari 15 isn't loading audio files properly on some Mac computers. This is a bug in the new version of Safari, as far as I can tell. A fix is being tested on the [beta site](/switch-to/beta).
  * 🐜 Safari 15 and iOS 15 have a serious bug that prevents audio playback. I've filed a [bug report with Apple](https://bugs.webkit.org/show_bug.cgi?id=232728); let's hope they fix it soon.

`,
});

Version.history.push({
  version: '343',
  summary: 'Count-in volume adjustment',
  details: `
  * ⭐ Count-in volume can now be adjusted from the Count-ins panel.
  * 🔨 Navigating the song index with the arrow keys works better now.
  * 🔨 Looping behavior was improved: now any sections that are fully incorporated into a loop will repeat themselves normally.
`,
});

Version.history.push({
  version: '342',
  summary: 'Tap-tempo feature',
  details: `
  * ⭐ New "tap tempo" feature which lets you set the tempo by tapping a button or your spacebar. Find it by clickingTapping on the song's BPM display.
  * 🔨 Minor user interface improvements.
`,
});

Version.history.push({
  version: '341',
  summary: 'Removed Google Analytics',
  details: `
  * 🔨 Removed Google Analytics tracking code from the site because a) they track users across the web (which we don't like) and b) we weren't getting much insight out of it anyway. We still gather usage analytics with a product called Amplitude, but there aren't any privacy concerns there.
  * 🔨 Updated the [privacy overview page](/privacy) to be shorter and easier to understand, and removed references to a couple third-party services that are no longer being used.
`,
});

Version.history.push({
  version: '340',
  summary: 'Auto-Modulate (new) + Auto-Speedup (improved)',
  details: `
This is the official non-beta release for the changes introduced in beta versions 337, 338, and 339. In summary:

  * ⭐ There's a new Auto-Modulate feature, which you can use to easily practice a given tune in multiple keys. Find it under the key menu.
  * ⭐ The [Advanced Settings](/app?modal=advanced-settings) panel now includes options to have Auto-Speedup and Auto-Modulate reset the tempo or key after stopping the song.
  * ⭐ Auto-Speedup now accepts negative BPMs (to automatically slow down the song) and 0.5 BPM (to increase the tempo by half a BPM each time).
  * 🔨 Increased performance for lists with hundreds of songs on them (like [Tater Joe's old-time tune lists](http://taterjoes.com/my-page/StrumMachine.html), for example).
  * 🔨 Made the auto-scroller also bring the next line after the current one into view.
  * 🐜 Turns out the bass and mandolin timing adjustment hasn't been working for awhile now. They are now, though!
`,
});

Version.history.push({
  version: '339',
  summary: 'Reset options for Auto-Speedup and Auto-Modulate',
  details: `
  * ⭐ Added an option under "Advanced Settings" (top-left menu) to have Auto-Speedup automatically reset the tempo to the starting BPM value after the song is stopped.
  * ⭐ Added a similar option (in the same menu) to have the original key restored when Auto-Modulate is enabled and the song is stopped.
  * 🔨 Increased performance for lists with hundreds of songs on them.
  * 🐜 Fixed a bug in the auto-scrolling feature -- hopefully it *always* works now...
`,
});

Version.history.push({
  version: '338',
  summary: 'Auto-Modulate feature for practicing in many keys',
  details: `
  * ⭐ Added a new feature, Auto-Modulate, which automatically changes the key by shifting up or down a step, a fourth, or a fifth. It can also cycle through an arbitrary set of keys you type into it. Enable it through the key menu.
`,
});

Version.history.push({
  version: '337',
  summary: 'Further improvements to Auto-Speedup and Auto-Finish',
  details: `
  * ⭐ Improved UI for Auto-Speedup and Auto-Finish; in particular, these tools now automatically enable themselves when you open them.
  * ⭐ Auto-Speedup now accepts negative BPM values, which makes it *subtract* BPMs (slow down) with every rep. Just tap the BPM value and enter a negative number.
  * ⭐ You can now type 0.5 into Auto-Speedup and it will increase the speed by just half of one BPM each time, for even more gradial tempo increases.
  * ⭐ Editor: The chart view now scrolls itself when moving the cursor around with the arrow keys.
`,
});

Version.history.push({
  version: '336',
  summary: '"Memory" for Auto-Speedup/Auto-Finish',
  details: `
  * ⭐ Auto-Speedup and Auto-Finish will now have their settings remembered even when they are turned off. That is, if you set Auto-Finish to play a song 3 times, then later turn Auto-Finish off for a bit, when you turn it back on it will still be set to play the song 3 times.
  * ✨ The ${Meteor.isCordova ? 'beta version (beta.strummachine.com)' : '[beta version](/switch-to/beta)'} has a few more improvements I'm testing out: negative Auto-Speedup (to slow down a song with each repetition), fractional BPMs for Auto-Speedup (bump the tempo up by half a BPM each time), and a more polished UI. A new "Auto-Modulate" feature is going to enter testing on the beta site soon as well.
`,
});

Version.history.push({
  version: '335',
  summary: 'Capoed chord voicing for keys of A, Bb, and B',
  details: `
  * ⭐ Songs in the keys of A, Bb, and B, will be played with capoed guitar chord sounds. Besides being a better match for what you'd hear a real guitar play most of the time, there's one big advantage to this: "modal" songs (with more edgy/lonesome melodies that aren't quite major and aren't quite minor) will sound better with the capoed G chord than with the open A chord you'd hear before.
  * ℹ Note: if you prefer the old sounds for those keys, you can disable the "Guitar: use capoed G" preference in the Instrument settings panel while in the key of A, Bb, or B. This is just an interm solution; more options for chord voicings, more sophisticated handling of modal tunes, and per-song settings are coming in a future update.)
  * 🐜 Guitar bass notes were sometimes being cut off a bit too early; this has been fixed.
  * 🐜 Shared songs used to default to the song creator's current key/speed settings every time the song was opened. Now your own settings will be remembered.
  * 🐜 Song subtitles weren't printing; they do now.
  * 🐜 When clicking a chord during song playback, there was a short delay before the playback cursor moved; it's now nearly instantaneous.
`,
});

Version.history.push({
  version: '334',
  summary: 'Bug fixes',
  details: `
  * ⭐ This update will soon be followed by an update to the iOS mobile app, which will fix a few issues with that platform.
  * 🐜 Fix some small issues with menus not closing when they should.
`,
});

Version.history.push({
  version: '333',
  summary: 'New chords: m6, 9, and m7b5',
  details: `
  * ⭐ **New chords: m6, 9, m7b5.** By popular demand, these chords are now available!
  * 🐜 Fixed an offline syncing issue with songs on shared lists.
  * 🐜 Fixed an issue with naming lists on an iPad in landscape mode.
  * 🐜 Fixed a few other minor issues.
`,
});

Version.history.push({
  version: '332',
  summary: 'Under-the-hood maintenance',
  details: `
  This update is just to bring some third-party modules up to date in preparation for working on new features.
`,
});

Version.history.push({
  version: '331',
  summary: 'Massive update to the song editor',
  details: `
  This is an extra-special update, nearly five months in the making. It features a **major overhaul of the song editor**, with many new features and improvements:
  * ⭐ **New chords**: 6, maj7, sus2, sus4, and augmented now available! (Other chords to be added by popular demand.)
  * ⭐ **Slash chords** (where you can set the bass note independently of the strummed chord) are now available.
  * ⭐ You can **subdivide the beat** into individual sub-beats (quarter notes, to be exact) and assign a different chord to each one.
  * ⭐ **Line length** can now be set for each line, for when the phrasing of a song doesn't divide evenly into groups of 4 measures.
  * ⭐ New **Chord, Chart, and FX menus** to access all these new features.
  * ⭐ You can now **multi-select** (click-and-drag or <kbd>Shift</kbd>+arrow-keys) in the song editor to make edits to many beats at once.
  * ⭐ **Cut/copy/paste** functionality is now available in the song editor. Comes in handy for quickly duplicating phrases within a section, for example.
  * ⭐ **New keyboard shortcuts**; click the Shortcuts button in the editor to see the keyboard commands available.
  * ⭐ **Hear as you edit** feature; listen to chords as you type them in and move around the chart you're editing.
  * ⭐ Redesigned section editing interface -- no more popup window to edit the section label or rep count.
  * 🔨 Lots of rewritten modules under the hood: key/chord logic, chart-rendering engine, dropdown renderer, etc. Fixed some long-standing bugs in the process.

  Other improvements in this version:
  * ⭐ You can now **record the output of Strum Machine** and save it to an MP3 file on your computer. You can then use it in, for example, an audio-recording program. To enable this feature, open [Advanced Settings](/app?modal=advanced-settings), then enable “Backing track recorder”.
  * ⭐ Updated help docs, including new tutorial videos for adding or editing songs with the new editor.
  * 🔨 Too many little tweaks and fixes to enumerate!

  Many thanks to everyone who beta tested the changes as I went along. If you want to use new features like this before they're released, consider [becoming a beta tester](/help/beta) too! I've got lots of exciting new stuff planned for 2021! 😁
  `,
});

Version.history.push({
  version: '325-330',
  details: `
  Beta-only releases.
  `,
});

Version.history.push({
  version: '324',
  summary: 'New-user-experience improvements',
  details: `
  * ⭐ New users now get an automated email with some tips about useful Strum Machine features. (Didn't get the email? Just check out <a href="/help">the guides</a> to learn everything that you would've received, and more.)
  * ⭐ Some iOS users who stumbled on the app without seeing much information on the app were met with a very stark login page... and not much else. Whoops! Added a small "demo mode" so that iOS users can kick the tires before signing up.
  * 🔨 Random (and inconsequential) transaction-related error messages should be hidden now.
  * 🔨 Improvement to the way the “B” key is handled in the song editor.
  `,
});

Version.history.push({
  version: '323',
  summary: 'Mobile apps released!',
  details: `
  * ⭐ At last, mobile apps for iOS and Android are in the app stores! It's the same Strum Machine you know and love, with a few improvements detailed on the <a href="/app/install">app downloads page</a>.
  `,
});

Version.history.push({
  version: '322',
  summary: 'Infrastructure updates',
  details: `
  * 🔨 Add code for Apple In-App Purchase billing so that we can get the app approved in the App Store.
  `,
});

Version.history.push({
  version: '321',
  summary: 'Tweaks to Auto-Finish & Auto-Speedup',
  details: `
  * ⭐ Improved visual display of Auto-Finish/Auto-Speedup status info.
  * ⭐ Rep markers for Auto-Finish and repeated sections are now clickable.
  `,
});

Version.history.push({
  version: '320',
  summary: 'Bug fixes and small improvements',
  details: `
  * 🐜 Previously, a bug in offline syncing meant that song edits made on device A wouldn't always show up on device B without reloading Strum Machine. This has been fixed.
  * 🐜 Fixed a bug that prevented lists from being made publicly editable. (Don't worry, this is still an opt-in feature!)
  * 🐜 Fixed song-setting help tooltips on mobile devices.
  * 🔨 Made some other tiny tweaks and bugfixes too small to mention.
  `,
});

Version.history.push({
  version: '319',
  summary: 'Community forum integration',
  details: `
  * ⭐ Launched a new [user forum](https://community.strummachine.com/) and linked it up in the Help menu (for now).
  * 🔨 Added code so that linking to a Strum Machine page on the forum shows the song name or the list of songs without having to click the link.
  * 🐜 Fixed a very rare bug that affected the deletion of songs that were on multiple lists.
  * 🐜 Fixed printing of song pages on Firefox.
  `,
});

Version.history.push({
  version: '318',
  summary: 'Updates to beta mobile apps',
  details: `
  * 🔨 Deployed a few fixes related to the new iOS and Android mobile apps, which will be coming out of beta in the near future.
  `,
});

Version.history.push({
  version: '317',
  summary: 'Mobile app infrastructure',
  details: `
  * 📳 The coding for the mobile (iOS & Android) versions of the app is (in theory) complete! Next I'll have them tested by my small group of beta testers. My hope is to have the app tested and in the app stores (as a free download) by the end of May if not sooner.
  `,
});

Version.history.push({
  version: '316',
  summary: 'Small fixes and improvements',
  details: `
  * ⭐ Disable "mute switch bypass" on iPads by default, since they don't have a physical ringer mute switch and this setting can cause problems with certain Bluetooth speakers. The caveat is that now Strum Machine won't work if Silent Mode is enabled on your iPad, unless you turn on Mute Switch Bypassing in the <a href="/app?modal=advanced-settings">Advanced Settings</a> (or disable Silent Mode in the iPad's control center).
  * 🔨 Improved display of various pop-up windows on certain device sizes.
  * 🐜 Fixed search on the new ${Meteor.isCordova ? '' : '<a href="https://strummachine.com/help">'}standalone help site${Meteor.isCordova ? '' : '</a>'}. (This site has the same content as the in-app help; it mostly exists so that I can link to help articles in customer support emails.)
  * 📝 *Side note: I'm trying to move away from bigger mega-releases and lots of "point" releases, and back to my earlier intention of numbering each substantial release with a new whole number. I'm also going to try to be more transparent about all the various changes, fixes, and improvements that tend to sneak in with new version releases.* 😉
  `,
});

Version.history.push({
  version: '315',
  summary: 'Rewritten, searchable help docs',
  details: `
  * ⭐ Help docs are now searchable, and have been expanded and updated. (Planning to expand even further in the near future.)
  * 🔨 New users get more helpful (I hope!) emails during their free trial.
  `,
});

Version.history.push({
  version: '314',
  summary: 'Mobile app infrastructure changes',
  details: `
  * 🔨 Various infrastructure changes were made to prepare for the release of bona-fide *mobile apps*. (Yup, that's right... they're coming... let me know if you want to help beta test them.)
  * 🔨 The "notch" on the iPhone X and iPhone 11 should be better supported now.
  * 🐜 Fixed various tiny bugs that probably nobody noticed but are better off fixed anyway.
  `,
});

Version.history.push({
  version: '313',
  summary: '',
  details: `
  * 🔨 Improvements to screen reader support for visually impaired users.
  * 🐜 Bugs relating to the new audio engine should be fixed. In particular, I added a workaround for a rare "periodic muted beats" iPhone/iPad bug under Advanced Settings.
  `,
});

Version.history.push({
  version: '312',
  summary: 'New audio engine (fixes some playback glitches)',
  details: `
  * 🔨 Strum Machine's internal audio engine has been completely rewritten, fixing several rare but longstanding issues with audio playback. (It behaved very well in beta testing, but if you notice any audio glitches with this new version, *please* let me know. Thanks!)
  * 🔨 Officially dropping support for iOS 9 devices, by necessity. ${Meteor.isCordova ? '' : 'The last version compatible with iOS 9 will be kept at <a href="https://strummachine.com/switch-to/old">old.strummachine.com</a> for as long as possible.'}
  `,
});

Version.history.push({
  version: '311',
  summary: 'Song list navigation and custom ordering',
  details: `
  * ⭐ You now have the option of reordering songs in a list by hand instead of them being sorted alphabetically.
  * ⭐ There's an experimental "list navigation bar" available, giving you easy access to the next and previous songs in a list right from the song page. To enable this experimental feature, head to the new "Advanced Settings" page which is in the main menu (top-left corner of index page).
  * 🔨 Various improvements to list-related UI.
  * 🐜 Fixed a bug where Auto-Speedup and Auto-Finish settings would be temporarily reset after editing a song.
  * 🐜 Fixed a bug in which new/edited songs weren't properly beat-highlighted until a refresh.
  `,
});

Version.history.push({
  version: '310',
  summary: '',
  details: `
  * ⭐ Long-overdue refresh for the landing page – not very interesting for existing users, but hopefully an improvement for new visitors to the site.
  * ⭐ Going to Strum Machine's home page now puts you right in the app if you're logged in, saving you a click.
  * 🐜 Fixed some errors that were being logged – although I don't think anyone would've noticed them.
  * 🔨 Tweaks to improve first-load speed.
  `,
});

Version.history.push({
  version: '309',
  summary: '',
  details: `
  * ⭐ There's now a self-serve subscription cancellation form; no need to send me an email.
  * 🐜 Help quirkiness on certain Android phones was resolved.
  * 🔨 Other improvements to accounts/subscription code.
  `,
});

Version.history.push({
  version: '308',
  summary: 'Bug fixes & offline improvements',
  details: `
  * ⭐ Added a status bar to the index page when Strum Machine is offline.
  * 🐜 Bug fixes for offline mode – more reliable BPM-preference syncing.
  * 🐜 Other miscellaneous small bug fixes and improvements.
  * 🔨 Song search is a bit more forgiving of alternate spellings now.
  `,
});

Version.history.push({
  version: '307',
  summary: 'Redesigned home screen and performance improvements',
  details: `
  * ⭐ The song search bar is now always visible at the top of the song index page—where it should be!
  * ⭐ Strum Machine loads much faster now, as much as 10× faster for some folks!
  * ⭐ Song search results load faster now.
  * 🐜 Double-length count-ins for jigs work correctly again.
  `,
});

Version.history.push({
  version: '306',
  summary: 'Song references and personal notes',
  details: `
  * ⭐ New "Notepad" feature: store your personal notes along with any song! Jot down reminders, target BPMs, paste in notes or lyrics from elsewhere... whatever you want!
  * ⭐ New "Reference" menu: add links to recordings and have them play in an embedded player. (Works with videos on YouTube and Vimeo, tracks on Spotify and SoundCloud, MP3s in Dropbox, and more.) Or add links to lyrics or whatever else you like. Your references are private, but I'll also be adding public ones.
  * 🐜 Sections marked as "intros" weren't behaving correctly in edit mode. This has been fixed.
  `,
});

Version.history.push({
  version: '305',
  summary: 'Internal release',
  details: `
  * 🔨 Infrastructure improvements to pave the way for many more songs to be added to the library soon!
`,
});

Version.history.push({
  version: '304',
  summary: 'Bug fixes',
  details: `
  * 🐜 Fixed the "can't see to the bottom of the song" bug that was affecting certain browsers.
  * 🐜 Fixed various graphical/UI glitches with song page menus.
  * 🔨 Removed some old code that wasn't doing anything anymore. Spring cleaning, bit by bit!
  * 🐜 (In 304.7) Auto-scrolling is working again.
  * 🔨 (In 304.8) Database schema changes to stay compatible with the new features in beta.
`,
});

Version.history.push({
  version: '303',
  summary: 'Fix for iOS Bluetooth speaker issues',
  details: `
  * 🐜 Some iPhone/iPad users were having issues with their Bluetooth speakers (no audio). Hopefully this is fixed now, but please write in if it's still broken for you!
`,
});

Version.history.push({
  version: '302',
  summary: 'Accessibility improvements for use with screen readers',
  details: `
* ⭐ Strum Machine now works better when used with a screen reader (for visually-impaired users). It's not perfect yet but it's a start!
* 🐜 Song sidebar open/close state wasn't being remembered. This is now fixed.
`,
});

Version.history.push({
  version: '301',
  summary: 'Redesigned song page menu, plus many bug fixes',
  details: `
* ⭐ Menu actions on song pages has been moved to a sidebar menu. This makes certain functions more visible and also paves the way for new features that will be coming in the near future!
* ⭐ Song search bar now enables a "Search" button in the iOS keyboard.
* ⭐ Started publishing release notes, i.e., what you're reading right now!
* 🐜 Songs weren't able to be deleted in certain cases. This has been fixed.
* 🐜 Fixed a bug where, in some cases, when logging out and logging in, all of the songs one created seemed to have disappeared from the "Mine" list.
* 🐜 Fixed the "endlessly repeating outro" bug that affected newly-created songs with outros.
* 🐜 Fixed a broken logout link.
* 🔨 Logging out works offline now. Not sure if anyone cares, but it was an easy fix.
* 🔨 Help pages are now stored in code, versus the database; reduces load times and bandwidth consumption.
`,
});

Version.history.push({
  version: '300',
  summary: 'Start of version history',
  details: `
First officially numbered version (April 2019). Strum Machine has been in development for over three years, with 1200+ internally-versioned iterations, so I didn't want to start back at "Version 1". I picked "300" as a nice round number to start numbering from.

Why so high? To keep things simple, I am counting *every* update as a new version (versus point releases like "3.4" or "3.7.12"). It's all arbitrary anyway, and bigger numbers are more fun... as evidenced by the fact that every kid who plays around with Strum Machine tries to see how high the BPM can go. 🙂

**Addendum (2024)**: Well, that didn't quite go according to plan, because I didn't count every update as a new version – far from it. Oh well. It's still all arbitrary anyway so I'm not stressing out about it.
`,
});

Version.current = Version.current || Version.history[0].version;
Version.currentSummary = Version.history[0].summary;

if (typeof GIT_TAG_VERSION == 'string') {
  const version = (/[0-9.]+/.exec(GIT_TAG_VERSION) || [])[0]; // eslint-disable-line no-undef
  if (version) Version.current = version;
}

Version.currentInteger = parseInt(Version.current, 10);

export default Version;
