import { MissedAudioDeadlineTracker } from '@/audio/MissedAudioDeadlineTracker';

Template.glitchNotice.onRendered(() => {
  const instance = Template.instance();
  instance.autorun(() => {
    if (MissedAudioDeadlineTracker.glitchCount() > 3) {
      setTimeout(() => {
        instance.$('.js-glitchNotice').slideDown();
      }, 1000);
    }
  });
});

Template.glitchNotice.helpers({
  glitchCount() {
    return MissedAudioDeadlineTracker.glitchCount();
  },
});
