import { Chord } from '@/music/Chord';

export const autoModulateOptionsForMajorKeys = [
  {
    value: 'a',
    caption: 'all 12 keys',
    label: 'All 12 keys',
  },
  {
    value: 'n',
    caption: 'the natural keys',
    label: 'Natural keys (C D E F G A B)',
  },
  {
    value: 'n+',
    caption: `the natural keys + ${new Chord('Bb').toHtml()}`,
    label: `Natural keys + ${new Chord('Bb').toHtml()}`,
  },
  {
    value: 'c',
    caption: 'a custom sequence:',
    label: 'Custom sequence',
  },
];

export const autoModulateOptionsForMinorKeys = [
  {
    value: 'a',
    caption: 'all 12 keys',
    label: 'All 12 keys',
  },
  {
    value: 'n',
    caption: 'the natural keys',
    label: 'Natural keys (Am Bm Cm...Gm)',
  },
  {
    value: 'n+',
    caption: `the natural keys + ${new Chord('F#m').toHtml()}`,
    label: `Natural keys + ${new Chord('F#m').toHtml()}`,
  },
  {
    value: 'c',
    caption: 'a custom sequence:',
    label: 'Custom sequence',
  },
];

export const autoModulateOptionsForInc = [
  { value: 1, label: 'up a key' },
  { value: -1, label: 'down a key' },
  { value: 5, label: 'to the relative fourth' },
  { value: 7, label: 'to the relative fifth' },
];

const firstRepOption = { value: 1, label: 'every repetition' };

export const autoModulateOptionsForReps = [firstRepOption].concat(
  [2, 3, 4, 5, 6, 7, 8, 9].map((i) => {
    return { value: i, label: `every ${i} reps` };
  })
);
