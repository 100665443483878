import { AutoTool } from '@/auto-tools/AutoTool';
import type { SUARecord } from '@/collections/SUACollection';
import { Chord } from '@/music/Chord';

// If you make any changes to the schema, remember to update SUACompressor.js!

export class AutoModulateSettings extends AutoTool {
  protected prefix = 'am';

  init(settings: Partial<SUARecord>): void {
    super.init(settings);
    if ('amKeys' in settings) {
      this.settings.set('amKeys', this.normalizeKeys(settings['amKeys'] as string));
    }
    if ('amSeq' in settings) {
      this.settings.set('amSeq', this.normalizeSeq(settings['amSeq'] as string));
    }
    if ('amInc' in settings && typeof settings['amInc'] === 'number') {
      this.settings.set('amInc', this.normalizeInc(settings['amInc']));
    }
    if ('amReps' in settings && typeof settings['amReps'] === 'number') {
      this.settings.set('amReps', this.normalizeReps(settings['amReps']));
    }
  }

  //#region === Keys (preset) ===

  keys(): string {
    return (this.settings.get('amKeys') as string) || 'a';
  }

  setKeys(value: string): void {
    this.setSetting('amKeys', this.normalizeKeys(value));
  }

  private normalizeKeys = (value: string): string | null => {
    return /^a|n|n+|c$/.test(value) ? value : null;
  };

  //#endregion ^ Keys

  //#region === Seq (custom sequence) ===

  seq(): string | undefined {
    return this.settings.get('amSeq') as string | undefined;
  }

  setSeq(value: string): void {
    this.setSetting('amSeq', this.normalizeSeq(value));
  }

  private normalizeSeq = (value: string): string => {
    const keys = ('' + value)
      .split(/[ ,]/)
      .map((k) => {
        k = k.charAt(0).toUpperCase() + k.slice(1);
        try {
          return new Chord(k).rootNote;
        } catch (e) {
          return null;
        }
      })
      .filter((k) => k);
    return keys.join(' ');
  };

  //#endregion ^ Seq

  //#region === Inc ()===

  inc(): number {
    return (this.settings.get('amInc') as number) ?? 1;
  }

  setInc(value: number | string): void {
    this.setSetting('amInc', this.normalizeInc(value));
  }

  private normalizeInc = (value: number | string): number => {
    value = Number(value) || 1;
    return Math.min(Math.max(Math.floor(value), -11), 11);
  };

  //#endregion ^ Seq

  //#region === Reps ===

  reps(): number {
    return (this.settings.get('amReps') as number) ?? 1;
  }

  setReps(value: number | string): void {
    this.setSetting('amReps', this.normalizeReps(value));
  }

  private normalizeReps = (value: number | string): number => {
    value = Number(value) || 1;
    return Math.min(Math.max(Math.floor(value), 1), 9);
  };

  //#endregion ^ Reps
}
