import type { EQBand } from '@/audio/engine/base/EQ';
import { EQ } from '@/audio/engine/base/EQ';
import { FMBCordovaPlugin } from '@/audio/engine/fmb/FMBCordovaPlugin';

export class FMBEQ extends EQ {
  _channelId: string;
  constructor(channelId: string, eqBands?: EQBand[]) {
    super(eqBands);
    this._channelId = channelId;
  }

  _set(bands: EQBand[]) {
    this._bands = bands;
    void FMBCordovaPlugin.setChannelEQ({ channel: this._channelId, bands: this.bands });
  }
}
