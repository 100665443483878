<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { throttle } from 'underscore';
  import CircularRange from '@/band/ui/components/CircularRange.svelte';
  import { UserProfile } from '@/user/UserProfile';

  const dispatch = createEventDispatcher<{ change: [r: number, theta: number] }>();

  interface Props {
    tweenThumb?: boolean;
    labels: { top: string; bottom: string; left: string; right: string };
    snapThreshold?: number;
    polarCoordinates: [r: number, theta: number];
    snapToCenter?: boolean;
    snapToEdges?: boolean;
    snapToPoles?: boolean;
    poleSnapCount?: number;
  }

  let {
    tweenThumb = false,
    labels,
    snapThreshold = 0.07,
    polarCoordinates,
    snapToCenter = true,
    snapToEdges = false,
    snapToPoles = false,
    poleSnapCount = 8,
  }: Props = $props();

  function snap(
    [r, theta]: [r: number, theta: number],
    threshold = snapThreshold
  ): [r: number, theta: number] {
    if (snapToCenter && r < threshold) return [0, 0];
    if (
      snapToPoles &&
      r > 1 - threshold &&
      Math.abs(theta - Math.round(theta * poleSnapCount) / poleSnapCount) < threshold / 3.14159
    )
      return [1, Math.round(theta * poleSnapCount) / poleSnapCount];
    if (snapToEdges && r > 1 - threshold) return [1, theta];
    return [r, theta];
  }

  let hasDragged = UserProfile.hasMilestone('CIRCULAR_SPATIAL_UI_DRAG');

  function handleRadialUpdate(e: CustomEvent<{ r: number; theta: number }>) {
    const [r, theta] = snap([e.detail.r, (e.detail.theta + 1) % 1]);
    polarCoordinates = [r, theta];
    throttledRadialUpdate();
    throttledMilestoneUpdate();
  }

  const throttledRadialUpdate = throttle(() => {
    dispatch('change', polarCoordinates);
  }, 44);

  const throttledMilestoneUpdate = throttle(() => {
    if (hasDragged) return;
    UserProfile.recordMilestone('CIRCULAR_SPATIAL_UI_DRAG');
    hasDragged = true;
  }, 1000);
</script>

<div class="flex justify-center">
  <div class="GBS_grid grid grid-cols-3 grid-rows-3 place-items-center">
    <div class="GBS_axisLabel -rotate-90" style="grid-column: 1; grid-row: 2;">{labels.left}</div>
    <div class="GBS_axisLabel rotate-90" style="grid-column: 3; grid-row: 2;">{labels.right}</div>
    <div class="GBS_axisLabel" style="grid-column: 2; grid-row: 1;">{labels.top}</div>
    <div class="GBS_axisLabel" style="grid-column: 2; grid-row: 3;">{labels.bottom}</div>
    <div class="col-span-1 col-start-2 row-span-1 row-start-2">
      <CircularRange
        radialCoordinates={polarCoordinates}
        size={window.innerWidth > 370 ? 260 : 200}
        on:update={handleRadialUpdate}
        {tweenThumb}
      />
    </div>
  </div>
</div>

<style>
  .GBS_grid {
    grid-template: 3em auto 3em / 3em auto 3em;
  }

  .GBS_axisLabel {
    font-size: 0.75em;
    font-weight: bold;
    color: var(--gray-400);
    text-transform: uppercase;
    z-index: 10;
    letter-spacing: 0.1125em;
    white-space: nowrap;
  }
</style>
