import * as Sentry from '@sentry/browser';
import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { Crnt } from '@/Crnt';
import DupeChecker from '@/library/DupeChecker';
import { MusicLibrary } from '@/library/MusicLibrary';
import { putArticleAtEnd } from '@/library/putArticleAtEnd';
import { Roles } from '@/Roles';
import { rpcNominateSong } from '@/server/methods/nominations/rpcNominateSong';
import { rpcMakeSongPublic } from '@/server/methods/songs/rpcMakeSongPublic';

Template.songSubmitPage.onCreated(() => {
  const instance = Template.instance();

  instance.submitting = new ReactiveVar();

  instance.data.setTitle('Song Nomination Form');

  instance.autorun(async () => {
    if (Meteor.userId() && !Meteor.user('roles')) return; // still logging in; roles aren't ready
    if (!Roles.userHasPermission(Meteor.userId(), 'nominations.insert'))
      return FlowRouter.go('song-index');
    const songId = FlowRouter.getParam('songId');
    if (!songId) return; // in-between routes
    if (Crnt.song()?.id() == songId) return; // already loaded
    try {
      Crnt.loadSong(await MusicLibrary.songs.fetchFull(songId));
    } catch (err) {
      FlowRouter.go('song-index');
      Bert.alert(err.reason || err.message || err.toString(), 'warning');
      Sentry.captureException(err);
    }
  });

  instance.dupeChecker = new DupeChecker();
  instance.autorun(() => {
    if (!Crnt.song()) return;
    instance.dupeChecker.setSongId(Crnt.song()?.id());
    instance.dupeChecker.setDesiredName(Crnt.song()?.name());
  });
});

Template.songSubmitPage.onRendered(() => {});

Template.songSubmitPage.onDestroyed(() => {});

Template.songSubmitPage.helpers({
  ready() {
    return Crnt.song() && !Template.instance().submitting.get();
  },

  showDuplicatesText(...ids) {
    const results = Template.instance().dupeChecker.results();
    if (!results.length) return ids.includes('no-duplicates');
    if (results[0].score < 0.02) return ids.includes('definite');
    if (results[0].score < 0.4) return ids.includes('likely');
    return ids.includes('possible');
  },
  duplicateSongs() {
    let results = Template.instance().dupeChecker.results() || [];
    if (results[0].score < 0.2) {
      results = results.filter((r) => r.score < 0.5);
    }
    return results.map((r) => Object.assign(r.item, { score: r.score }));
  },
  multipleDuplicates() {
    return Template.instance().dupeChecker.results().length > 1;
  },
  dupS() {
    return Template.instance().dupeChecker.results().length > 1 ? 's' : '';
  },

  aboutText() {
    return Crnt.song()?.aboutText();
  },

  noReferenceRecording() {
    return !Crnt.references()?.filter({ type: 'r' }).length;
  },
  singleReferenceArtist() {
    return Crnt.references()?.filter({ type: 'r' }).length == 1;
  },
  singleReferenceArtistName() {
    return Crnt.references()?.filter({ type: 'r' })?.artist;
  },
  songIndexPreviewName() {
    const name = Crnt.song()?.name();
    if (!name) return undefined;
    return putArticleAtEnd(name.replace(/ +\[.+\]$/, ''));
  },
  songIndexPreviewLabel() {
    var labelMatch = Crnt.song()
      ?.name()
      .match(/ \[(.+)\]$/);
    return labelMatch ? labelMatch[1] : null;
  },

  alreadyNominated() {
    return Meteor.status().connected && Crnt.nominationLoaded() && Crnt.nomination();
  },
  canMakePublic() {
    return (
      Meteor.status().connected &&
      Crnt.song() &&
      Roles.userHasPermission(Meteor.userId(), 'songs.insertPublic') &&
      Crnt.song()?.userId()
    );
  },
});

Template.songSubmitPage.events({
  'click .js-submit': function (event, instance) {
    event.preventDefault();
    bootbox.confirm({
      message: '<div style="text-align: center;">Ready to submit?</div>',
      closeButton: false,
      className: 'center',
      buttons: {
        confirm: {
          label: 'Yes, I’m ready!',
          className: 'btn-success',
        },
        cancel: {
          label: 'No, not yet',
        },
      },
      async callback(confirmed) {
        if (!confirmed) return;
        instance.submitting.set(true);
        try {
          await rpcNominateSong({ songId: Crnt.song()?.id() });
          Bert.alert('Nomination submitted. Thank you!', 'success');
        } catch (err) {
          Sentry.captureException(err);
          Bert.alert(err.reason, 'warning');
        } finally {
          Meteor.setTimeout(() => instance.submitting.set(false), 600);
          FlowRouter.go('song-index');
        }
      },
    });
  },

  'click .js-addReference': function (event, instance) {
    event.preventDefault();
    Modal.show('addReferenceModal', {});
  },

  'click .js-editSongName': function (event, instance) {
    event.preventDefault();
    bootbox.prompt({
      title: 'Editing Song Name &amp; Label',
      message:
        '<p>To add a descriptive label for the song index, put it at the end surrounded by square brackets. For example:</p><p><span style="display: inline-block; border: solid 1px #ccc; padding: 1px 8px; margin: 0 3px; border-radius: 3px;">Rawhide [Bill Monroe]</span> becomes <span style="display: inline-block; border: solid 1px #ccc; padding: 1px 8px; margin: 0 3px; border-radius: 3px;"><strong style="color: #052e4e">Rawhide</strong> <span style="color: #999; font-size: 90%;">&ndash; Bill Monroe</span></span> </p><p>(A label is required if another version of this song is already in the library.)</p>',
      value: Crnt.song()?.name(),
      callback(result) {
        if (result !== null) {
          Crnt.song().setName(result.trim());
          MusicLibrary.songs.update(Crnt.song());
        }
      },
    });
  },

  'click .js-editAboutText': function (event, instance) {
    event.preventDefault();
    bootbox.prompt({
      title: 'About this song:',
      message:
        "<p>Enter brief notes about this song's source, authorship, arrangement, transcription, etc... (max 140 characters)</p>",
      value: Crnt.song()?.aboutText(),
      maxlength: 140,
      callback(result) {
        if (result !== null) {
          Crnt.song()?.setAboutText(result);
          MusicLibrary.songs.update(Crnt.song());
        }
      },
    });
  },

  'click .js-makePublic': async function (event, instance) {
    try {
      const songId = Crnt.song()?.id();
      await rpcMakeSongPublic({ songId });
      Bert.alert(`“${Crnt.song()?.name()}” is now public.`, 'success');
      FlowRouter.go('nominations-admin');
    } catch (error) {
      Sentry.captureException(error);
      Bert.alert(error.message, 'danger');
    }
  },
});
