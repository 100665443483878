import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { eventTracker } from '@/browser/analytics/eventTracker';
import { Conductor } from '@/Conductor';
import { Crnt } from '@/Crnt';
import { mainIndexSearcher } from '@/library/mainIndexSearcher';
import { MusicLibrary } from '@/library/MusicLibrary';
import { saveCurrentSong } from '@/library/saveCurrentSong';
import SongMenuManager from '@/ui/SongMenuManager';

Template.musicPageHeader.onCreated(() => {
  const instance = Template.instance();

  instance.beingSaved = new ReactiveVar();

  instance.autorun(() => {
    var route = FlowRouter.getRouteName();
    if (route == 'new-song') instance.beingSaved.set('Song');
    if (route == 'copy-song') instance.beingSaved.set('This Copy');
    if (route == 'edit-song') instance.beingSaved.set('Changes');
  });
});

Template.musicPageHeader.helpers({
  onEditPage() {
    return /(new|edit|copy)-song/.test(FlowRouter.getRouteName());
  },
  beingSaved() {
    return Template.instance().beingSaved.get();
  },
  readyToSave() {
    return Crnt.song()?.editMode() && Crnt.song()?.key();
  },
  sidebarEnabled() {
    return !SongMenuManager.mobileMenu();
  },
  sidebarOpen() {
    return SongMenuManager.sidebarVisible();
  },
  backToMedley() {
    return !!FlowRouter.getQueryParam('fromMedley');
  },
});

Template.musicPageHeader.events({
  'click .js-backAction': function (event) {
    if (FlowRouter.getQueryParam('fromMedley')) {
      MusicLibrary.medleys.block();
      FlowRouter.go(
        'show-medley',
        {
          medleyId: FlowRouter.getQueryParam('fromMedley'),
        },
        {
          list: FlowRouter.getQueryParam('onList'),
        }
      );
    } else {
      FlowRouter.go('song-index', {}, mainIndexSearcher.getQueryParams());
    }
  },

  'click .js-discardAction': function (event) {
    Conductor.stop();
    const songId = Crnt.song()?.id();
    if (songId) {
      MusicLibrary.songs.block();
      eventTracker.editorDiscardSong();
      FlowRouter.go('show-song', { songId });
    } else {
      eventTracker.editorDiscardNewSong();
      FlowRouter.go('song-index');
    }
  },

  'click .js-saveAction': function (event) {
    Conductor.stop();
    const song = Crnt.song();
    if (!song.editMode() || song.sections.length === 0) return;
    if (song.sections.every((s) => s.type == 'i' || s.type == 'o')) {
      const problemStatement =
        song.sections.length > 1
          ? 'Every section in your song is'
          : 'Your song consists of only one section which is';
      const whatItIs = song.sections.every((s) => s.type == 'i')
        ? 'set to play "First time only"'
        : song.sections.every((s) => s.type == 'o')
          ? 'set to play "Last time only"'
          : 'set as either "First time only" or "Last time only"';
      const whatHappens = 'This will lead to unexpected behavior in playback.';
      const recommendation = `We strongly recommend that you change ${song.sections.length > 1 ? 'at least one section' : 'the section'} to play "Every time", using the gear icon in the section heading.`;
      bootbox.confirm({
        message: `${problemStatement} ${whatItIs}. ${whatHappens}<br><br>${recommendation}`,
        closeButton: false,
        buttons: {
          cancel: {
            label: 'Save song anyway',
            className: 'btn-danger',
          },
          confirm: {
            label: 'Keep editing to fix the issue',
            className: 'btn-raised',
          },
        },
        callback(result) {
          if (!result) saveCurrentSong();
        },
      });
    } else {
      saveCurrentSong();
    }
  },

  'click .js-toggleSidebar': function (event, instance) {
    event.preventDefault();
    SongMenuManager.toggleSidebar();
    event.currentTarget?.blur();
  },

  'click .js-openMenu': function () {
    Modal.show('sidePanel', { template: 'musicPageMenu', class: 'sidePanel--musicPageMenu' }, {});
  },
});
