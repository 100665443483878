import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { eventTracker } from '@/browser/analytics/eventTracker';
import { HelpLibrary } from '@/help/HelpLibrary';
import ReloadableReactiveVar from '@/lib/ReloadableReactiveVar';
import { UserProfile } from '@/user/UserProfile';

const HelpWindow = {};

const pageStackMemory = new ReloadableReactiveVar('help-page-stack', []);
var pageStack = pageStackMemory.get();
const pageStackDep = new Tracker.Dependency();
const visible = new ReloadableReactiveVar('help-window-visible', false);
const searchQuery = new ReloadableReactiveVar('help-search-query');

const shouldUseFullScreen = () => window.matchMedia('(max-width: 580px)').matches;

Meteor.startup(() => {
  Tracker.autorun(() => {
    const page = FlowRouter.getQueryParam('help');
    if (page) {
      HelpWindow.navigate(page);
    } else if (shouldUseFullScreen()) {
      HelpWindow.hide();
    }
  });

  Tracker.autorun(() => {
    pageStackDep.depend();
    pageStackMemory.set(pageStack);
  });
});

HelpWindow.show = (page = 'index') => {
  pageStack = []; // clear page stack history
  HelpWindow.navigate(page, {
    useQueryParam: shouldUseFullScreen(),
  });
};

HelpWindow.search = (query = '') => {
  visible.set(true);
  searchQuery.set(query);
  HelpWindow.navigate('search', {
    useQueryParam: shouldUseFullScreen(),
  });
};

HelpWindow.hide = () => {
  visible.set(false);
  FlowRouter.setQueryParams({ help: undefined });
  pageStack = [];
  pageStackDep.changed();
};

HelpWindow.goBack = () => {
  pageStack.pop();
  pageStackDep.changed();
  if (pageStack.length == 0) {
    HelpWindow.navigate('index');
  } else if (FlowRouter.getQueryParam('help')) {
    history.back();
  }
};

HelpWindow.canGoBack = () => {
  pageStackDep.depend();
  return pageStack.length > 1 || pageStack[0] !== 'index';
};

HelpWindow.pageStack = () => {
  pageStackDep.depend();
  return pageStack;
};

HelpWindow.navigate = (page, args = {}) => {
  if (page !== _.last(pageStack)) {
    pageStack.push(page);
    pageStackDep.changed();
    if (args.useQueryParam || Tracker.nonreactive(() => FlowRouter.getQueryParam('help'))) {
      FlowRouter.setQueryParams({ 'help': page });
    }
    if (!visible.get()) {
      visible.set(true);
    }
    UserProfile.recordMilestone('HELP_OPEN');
  }
};

HelpWindow.currentPageId = () => {
  pageStackDep.depend();
  return _.last(pageStack);
};

HelpWindow.currentPageBody = () => {
  const page = HelpLibrary.fetch(HelpWindow.currentPageId());
  if (!page) {
    throw new Error(`Help page not found. \nPage stack: ${HelpWindow.pageStack().join(', ')}`);
  }
  eventTracker.helpPageOpen({ pageId: HelpWindow.currentPageId() });
  return page.html;
};

HelpWindow.visible = () => visible.get();

HelpWindow.searchQuery = () => searchQuery.get();

export default HelpWindow;
