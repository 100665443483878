import { Crnt } from '@/Crnt';
import { Roles } from '@/Roles';

Template.songDateEditor.helpers({
  showDateEditor() {
    return (
      Crnt.song()?.editMode() &&
      Roles.userHasPermission(Meteor.userId(), 'songs.insertPublic') &&
      Crnt.song()?.key()
    );
  },
  creationDate() {
    if (Crnt.song()?.createdAt()) {
      const date = new Date(Crnt.song()?.createdAt());
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      if (m < 10) m = '0' + m;
      var d = date.getDate();
      if (d < 10) d = '0' + d;
      return y + '-' + m + '-' + d;
    }
  },
});

Template.songDateEditor.events({
  'change #songCreationDate': function (event, instance) {
    event.preventDefault();
    const dateText = $(event.target).val();
    const ymd = dateText.match(/(\d+)-(\d+)-(\d+)/)?.slice(1);
    if (ymd) {
      const [y, m, d] = ymd;
      const date = new Date(+y, +m - 1, +d, 9, 0, 0);
      Crnt.song()?.setCreatedAt(date);
    }
  },
});
