export default function wrapMeteorDebug(Sentry) {
  const originalMeteorDebug = Meteor._debug;
  Meteor._debug = function (m, s) {
    // We need to assign variables like this. Otherwise, we can't see proper error messages.
    // See: https://github.com/meteorhacks/kadira/issues/193
    var [message, stack] = [m, s];

    // We hate Meteor._debug (no single usage pattern)
    if (message instanceof Error) {
      Sentry.captureException(message);
    } else if (stack instanceof Error) {
      Sentry.captureException(stack);
    } else if (typeof message === 'string') {
      if (typeof stack === 'string') {
        const error = new Error(message);
        const [details, stackLines] = stack.split('\n').reduce(
          (acc, line) => {
            acc[/^ + at /.test(line) ? 1 : 0].push(line);
            return acc;
          },
          [[], []]
        );
        error.stack = stackLines.join('\n');
        Sentry.captureException(error, {
          extra: { details },
          level: 'error',
        });
      } else {
        const error = new Error(message);
        error.stack = stack;
        Sentry.captureException(error);
      }

      return originalMeteorDebug.apply(this, arguments);
    }
  };
}
