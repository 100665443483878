type Interval = `${'b' | '#' | ''}${1 | 2 | 3 | 4 | 5 | 6 | 7 | 9 | 11 | 13}`;

export const chordTypeDefinitions = {
  // Major

  '': {
    name: 'major',
    ariaLabel: '',
    steps: ['1', '3', '5'],
    intervals: [0, 4, 7],
  },

  // Major with added tones

  '6': {
    name: 'major 6',
    ariaLabel: 'six',
    steps: ['1', '3', '5', '6'],
    intervals: [0, 4, 7, 9],
  },
  '69': {
    name: 'major 6/9',
    altName: '6add9',
    ariaLabel: 'six nine',
    steps: ['1', '3', '5', '6', '9'],
    intervals: [0, 2, 4, 7, 9],
  },

  // Major sevenths

  '^': {
    name: 'major 7th',
    ariaLabel: 'major seven',
    steps: ['1', '3', '5', '7'],
    intervals: [0, 4, 7, 11],
  },
  '^9': {
    name: 'major 9th',
    ariaLabel: 'major nine',
    steps: ['1', '3', '5', '7', '9'],
    intervals: [0, 2, 4, 7, 11],
  },
  '^13': {
    name: 'major 13th',
    ariaLabel: 'major thirteen',
    steps: ['1', '(3)', '5', '7', '9', '13'],
    intervals: [0, 2, 5, 7, 11],
  },

  // Dominant sevenths

  '7': {
    name: 'dominant 7th',
    ariaLabel: 'seven',
    steps: ['1', '3', '5', 'b7'],
    intervals: [0, 4, 7, 10],
  },
  '9': {
    name: 'dominant 9th',
    ariaLabel: 'nine',
    steps: ['1', '(3)', '5', 'b7', '9'],
    intervals: [0, 2, 7, 10],
  },
  '11': {
    name: 'dominant 11th',
    ariaLabel: 'eleven',
    steps: ['1', '(3)', '5', 'b7', '9', '11'],
    intervals: [0, 2, 5, 7, 10],
  },
  '13': {
    name: 'dominant 13th',
    ariaLabel: 'thirteen',
    steps: ['1', '(3)', '5', 'b7', '9', '(11)', '13'],
    intervals: [0, 2, 5, 7, 9, 10],
  },

  // Suspended dominants

  '7s': {
    name: 'dominant 7th suspended 4th',
    ariaLabel: 'seven suss',
    steps: ['1', '4', '5', 'b7'],
    intervals: [0, 5, 7, 10],
  },
  '9s': {
    name: 'dominant 9th suspended 4th',
    ariaLabel: 'nine suss',
    steps: ['1', '4', '5', 'b7', '9'],
    intervals: [0, 2, 5, 7, 10],
  },
  '13s': {
    name: 'dominant 13th suspended 4th',
    ariaLabel: 'thirteen suss',
    steps: ['1', '4', '5', 'b7', '9', '13'],
    intervals: [0, 2, 5, 7, 10],
  },

  // Minor

  'm': {
    name: 'minor',
    ariaLabel: 'minor',
    steps: ['1', 'b3', '5'],
    intervals: [0, 3, 7],
  },

  // Minor with added tones

  'm6': {
    name: 'minor 6',
    ariaLabel: 'minor six',
    steps: ['1', 'b3', '5', '6'],
    intervals: [0, 3, 7, 9],
  },
  'm69': {
    name: 'minor 6/9',
    altName: 'm6add9',
    ariaLabel: 'minor six nine',
    steps: ['1', 'b3', '5', '6', '9'],
    intervals: [0, 2, 3, 7, 9],
  },
  'mb6': {
    // ??? It is used in iReal (1.5%)...
    name: 'minor flat 6',
    ariaLabel: 'minor flat 6',
    steps: ['1', 'b3', '5', 'b6'],
    intervals: [0, 3, 7, 8],
  },

  // Minor sevenths
  // No m13; not in iReal Pro and not common - Google "The Minor Chord You Never Use"

  'm7': {
    name: 'minor 7th',
    ariaLabel: 'minor seven',
    steps: ['1', 'b3', '5', 'b7'],
    intervals: [0, 3, 7, 10],
  },
  'm9': {
    name: 'minor 9th',
    ariaLabel: 'minor nine',
    steps: ['1', 'b3', '5', 'b7', '9'],
    intervals: [0, 2, 3, 7, 10],
  },
  'm11': {
    name: 'minor 11th',
    ariaLabel: 'minor eleven',
    steps: ['1', 'b3', '5', 'b7', '9', '11'],
    intervals: [0, 3, 5, 7, 10],
  },

  // Minor major-sevenths
  // mMaj9 is not commonly used in iReal corpus, but it's the 007 chord!

  'm^': {
    name: 'minor major 7th',
    ariaLabel: 'minor major seven',
    steps: ['1', 'b3', '5', '7'],
    intervals: [0, 3, 7, 11],
  },
  'm^9': {
    name: 'minor major 9th',
    ariaLabel: 'minor major nine',
    steps: ['1', 'b3', '5', '7', '9'],
    intervals: [0, 2, 3, 7, 11],
  },

  // Diminished
  // dim^7 used in like one song in iReal corpus
  // dim9 doesn't appear *at all*

  'o': {
    name: 'diminished 7th',
    ariaLabel: 'diminished',
    steps: ['1', 'b3', 'b5', '6'],
    intervals: [0, 3, 6, 9],
  },
  '0': {
    name: 'minor 7th flat 5',
    altName: 'half-diminished',
    ariaLabel: 'minor seven flat five',
    steps: ['1', 'b3', 'b5', 'b7'],
    intervals: [0, 3, 6, 10],
  },
  'o^': {
    name: 'diminished major 7th',
    ariaLabel: 'diminished major seven',
    steps: ['1', 'b3', 'b5', '7'],
    intervals: [0, 3, 6, 11],
  },
  'o9': {
    name: 'diminished 9th',
    ariaLabel: 'diminished nine',
    steps: ['1', 'b3', 'b5', 'b7', '9'],
    intervals: [0, 2, 3, 6, 10],
  },

  // Augmented
  // aug7 = 7#5, so maybe since they're so rare we should just use 7#5?
  // OTOH, there's nothing else I'd put in that expanded dim/aug row...

  '+': {
    name: 'augmented',
    ariaLabel: 'augmented',
    steps: ['1', '3', '#5'],
    intervals: [0, 4, 8],
  },
  '+7': {
    name: 'augmented 7th',
    ariaLabel: 'augmented seventh',
    steps: ['1', '3', '#5', 'b7'],
    intervals: [0, 4, 8, 10],
  },

  // Power & sus

  '5': {
    name: 'fifth (power chord)',
    ariaLabel: 'five',
    steps: ['1', '5'],
    intervals: [0, 7],
  },
  '4': {
    name: 'suspended 4th',
    ariaLabel: 'suss four',
    steps: ['1', '4', '5'],
    intervals: [0, 5, 7],
  },
  '2': {
    name: 'suspended 2nd',
    ariaLabel: 'suss two',
    steps: ['1', '2', '5'],
    intervals: [0, 2, 7],
  },
  // 'add9': { name: 'add 9', ariaLabel: 'add nine' },
  // 'madd9': { name: 'minor add 9', ariaLabel: 'minor add nine' },

  // Basic altered dominants

  '7b9': {
    name: 'dominant 7th flat 9th',
    ariaLabel: 'seven flat nine',
    steps: ['1', '3', '5', 'b7', 'b9'],
    intervals: [0, 1, 4, 7, 10],
  },

  '7#9': {
    name: 'dominant 7th sharp 9th',
    altName: 'Hendrix chord',
    ariaLabel: 'seven sharp nine',
    steps: ['1', '3', '5', 'b7', '#9'],
    intervals: [0, 3, 4, 7, 10],
  },

  '7b5': {
    name: 'dominant 7th flat 5th',
    ariaLabel: 'seven flat five',
    steps: ['1', '3', 'b5', 'b7'],
    intervals: [0, 3, 6, 10],
  },

  '7#11': {
    name: 'dominant 7th sharp 11th',
    ariaLabel: 'seven sharp eleven',
    steps: ['1', '3', '(5)', 'b7', '(9)', '#11'],
    intervals: [0, 4, 6, 10],
  },

  '7#5': {
    name: 'dominant 7th sharp 5th',
    ariaLabel: 'seven sharp five',
    steps: ['1', '3', '#5', 'b7'],
    intervals: [0, 4, 8, 10],
  },

  '7b13': {
    name: 'dominant 7th flat 13th',
    ariaLabel: 'seven flat thirteen',
    steps: ['1', '3', '5', 'b7', 'b13'],
    intervals: [0, 4, 7, 8, 10],
  },

  // TODO: add these chord descriptions

  // '7b9s': {},

  // Altered extended dominants

  // '9#11': {},

  // '13b9': {},

  // '13#11': {},

  // Altered major sevenths

  // '^7#11': {
  //   name: 'major 7th sharp 11th',
  //   altName: 'Lydian chord',
  //   ariaLabel: 'major seven sharp eleven',
  //   steps: ['1', '3', '(5)', '7', '#11'],
  // },

  // '^7#5': {},

  // TODO: Update steps for all descriptions based on what's actually played
} as const satisfies Record<
  string,
  {
    name: string;
    altName?: string;
    ariaLabel: string;
    steps: (Interval | `(${Interval})`)[];
    intervals: number[];
  }
>;

/*
    7alt

 X  ^7#11 - "Lydian chord", rather common (9%)
 X  ^7#5 - pretty uncommon (1.7%)
    ^9#11 - quite uncommon (0.5%)

 X  7b9sus - 1.1%

    9b5 - uncommon (0.2%), H2V, same as #11
    9#5 - uncommon (0.5%), H2V
 X  9#11 - somewhat uncommon (1.6%)

 X  13b9 - somewhat uncommon (2.5%, 10% in Brazilian)
 ?  13#11 - uncommon (0.9%, 4.7% in Brazilian)
    13#9 - very uncommon (1 total), don't bother

 X  7b9b5 - 1%, 3% in Brazilian
 X  7b9#5 - 1.8%, 10% in Brazilian
 X  7#9#5 - 3%, 8% in Brazilian  (_32344 for C)
 ?  7#9b5 - 0.4%  (what about 7#9b13?)

    7b9b13 - 0.6%, 4% in Brazilian, same as 7b9#5, right?
    7b9#11 - 0.6%, 1.3% in Brazilian, same as 7b9b5
    7#9#11 - 0.4%
    7b9#9 - 0.1%

*/
