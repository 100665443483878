<script lang="ts">
  import { Tracker } from 'meteor/tracker';
  import { createEventDispatcher } from 'svelte';
  import type { GuitarOpenVoicingsSetting } from '@/band/instruments/guitar/settings/GuitarOpenVoicingsSetting';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';
  import { slideFade } from '@/ui/svelte-transitions';
  import SimpleListbox from '@/unused/SimpleListbox.svelte';

  const dispatch = createEventDispatcher<{ change: { interval?: number } }>();

  const bandMenu = getBandMenuState();

  let setting: GuitarOpenVoicingsSetting = $derived($bandMenu.guitarSettings.openVoicings);

  interface Props {
    shape: string;
    shortenLabels?: boolean;
  }

  let { shape, shortenLabels = false }: Props = $props();

  function setGVoicing(value: GuitarOpenVoicingsSetting['voicingForG']) {
    $bandMenu.guitar.setSetting('openVoicings', setting.withVoicingForG(value));
    Tracker.afterFlush(() => dispatch('change', {}));
  }

  function setDVoicing(value: GuitarOpenVoicingsSetting['voicingForD']) {
    $bandMenu.guitar.setSetting('openVoicings', setting.withVoicingForD(value));
    Tracker.afterFlush(() => dispatch('change', {}));
  }

  function setDropD(value: boolean) {
    $bandMenu.guitar.setSetting('openVoicings', setting.withDropDSetTo(value));
    Tracker.afterFlush(() => dispatch('change', {}));
  }

  function setB7(value: boolean) {
    $bandMenu.guitar.setSetting('openVoicings', setting.withB7SetTo(value));
    Tracker.afterFlush(() => dispatch('change', { interval: 7 }));
  }

  function setCadd9(value: boolean) {
    $bandMenu.guitar.setSetting('openVoicings', setting.withCadd9SetTo(value));
    Tracker.afterFlush(() => dispatch('change', { interval: 5 }));
  }

  const optionSets = {
    'G': [
      { name: shortenLabels ? 'Power G (no third)' : 'Use power G (no third)', value: 'G5' },
      { name: shortenLabels ? 'G w/open B string' : 'Use G with open B string', value: 'G' },
    ],
    'D': [
      { name: shortenLabels ? 'Normal D shape' : 'Use normal D shape', value: 'D' },
      { name: shortenLabels ? 'Power D (no third)' : 'Use power D (no third)', value: 'D5' },
    ],
    'D_tuning': [
      { name: 'Standard tuning', value: false },
      { name: 'Drop D tuning', value: true },
    ],
    'B7': [
      { name: 'Use normal B chord', value: false },
      { name: 'Play B7 instead of B', value: true },
    ],
    'C': [
      { name: 'Use normal C chord', value: false },
      { name: shortenLabels ? 'Substitute C w/Cadd9' : 'Substitute C with Cadd9', value: true },
    ],
  } as const;

  // TODO: Someday, it would be really great to add chord diagrams to these dropdown menus.
</script>

<div class="flex flex-col items-stretch [&>div]:pt-2">
  {#if shape == 'G'}
    <div transition:slideFade>
      <SimpleListbox
        options={optionSets.G}
        value={setting.voicingForG}
        fontWeight="semibold"
        fontSize="sm"
        on:change={(e) => setGVoicing(e.detail)}
      />
    </div>
    <div transition:slideFade>
      <SimpleListbox
        options={optionSets.C}
        value={!!setting.useCadd9}
        fontWeight="semibold"
        fontSize="sm"
        on:change={(e) => setCadd9(e.detail)}
      />
    </div>
  {:else if shape == 'D'}
    <div transition:slideFade>
      <SimpleListbox
        options={optionSets.D}
        value={setting.voicingForD}
        fontWeight="semibold"
        fontSize="sm"
        on:change={(e) => setDVoicing(e.detail)}
      />
    </div>
    <div transition:slideFade>
      <SimpleListbox
        options={optionSets.D_tuning}
        value={!!setting.dropD}
        fontWeight="semibold"
        fontSize="sm"
        on:change={(e) => setDropD(e.detail)}
      />
    </div>
  {:else if shape == 'E'}
    <div transition:slideFade>
      <SimpleListbox
        options={optionSets.B7}
        value={setting.useB7}
        fontWeight="semibold"
        fontSize="sm"
        on:change={(e) => setB7(e.detail)}
      />
    </div>
  {/if}
</div>
