import { mainIndexSearcher } from '@/library/mainIndexSearcher';
import Version from '@/Version';

Template.indexPageHeader.onRendered(() => {
  const instance = Template.instance();
  instance.autorun(function indexPageHeaderShowHide(comp) {
    const $header = instance.$('.indexPageHeader');
    const $headerContents = instance.$('.indexPageHeader-container');
    this.height = $header.outerHeight();
    if (Template.indexSearchBar.searchBoxFocused.get() && mainIndexSearcher.queryText()) {
      if (
        !this.hiding &&
        (/iP(hone|od)|Android/i.test(navigator.userAgent) ||
          (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
        window.innerWidth < 850 &&
        window.innerHeight < 700
      ) {
        $header.velocity('stop', true).velocity(
          {
            marginTop: [-this.height, 0],
          },
          {
            duration: 350,
            easing: [0.1, 0, 0.5, 1],
            complete: () => $header.hide(),
          }
        );
        $headerContents.velocity('stop', true).velocity(
          {
            opacity: [0, 1],
          },
          {
            duration: 350,
            easing: [0.7, 0, 0.5, 1],
          }
        );
        this.hiding = true;
      }
    } else {
      if (this.hiding) {
        $header.velocity('stop', true).velocity(
          {
            marginTop: [0, -this.height],
          },
          {
            duration: 350,
            easing: [0.2, 0, 0.4, 1],
            begin: () => $header.show(),
          }
        );
        $headerContents.velocity('stop', true).velocity(
          {
            opacity: [1, 0],
          },
          {
            duration: 350,
            easing: [0.3, 0, 0.3, 1],
          }
        );
        this.hiding = false;
      }
    }
  });
});

Template.indexPageHeader.helpers({
  beta() {
    return window.location.href.includes('beta') || Version.current.includes('beta');
  },
  old() {
    return window.location.href.includes('old.');
  },
  version() {
    return `v${Version.current}`;
  },
});

Template.indexPageHeader.events({
  'click .js-openMenu': function () {
    Modal.show('sidePanel', { template: 'indexPageMenu', class: 'sidePanel--mainMenu' }, {});
  },
});
