<script lang="ts">
  import PanelHeading from '@/band/ui/components/PanelHeading.svelte';
  import PanelOpenerChevron from '@/band/ui/components/PanelOpenerChevron.svelte';
  import PanelSettingSection from '@/band/ui/components/PanelSettingSection.svelte';
  import EditorGlobalMixerPanel from '@/band/ui/panels/EditorGlobalMixerPanel.svelte';
  import PanelModalLayout from '@/band/ui/panels/PanelModalLayout.svelte';
  import { renderChordAsHtml } from '@/chart/ui/renderChordAsHtml';
  import { crntPreferences } from '@/client/crntPreferences.svelte';
  import { Crnt } from '@/Crnt';
  import EditorSettingsChordDisplay from '@/editor/ui/EditorSettingsChordDisplay.svelte';
  import EditorSettingsCountIn from '@/editor/ui/EditorSettingsCountIn.svelte';
  import { Chord } from '@/music/Chord';
  import HelpCircleTooltip from '@/ui/HelpCircleTooltip.svelte';
  import { MultiPanelModalController } from '@/ui/settings/MultiPanelModalController.svelte';
  import ToggleSwitch from '@/ui/ToggleSwitch.svelte';
  import UserPreferences from '@/user/UserPreferences';
  import { capitalize } from '@/utilities/capitalize';

  const modalController = MultiPanelModalController.current();

  let { chordSystem, haye: hayeEnabled, countInEnabled } = $derived(crntPreferences());

  const exampleFoursome: Chord[] = [
    new Chord('C', 'C'),
    new Chord('Am', 'C'),
    new Chord('F', 'C'),
    new Chord('G', 'C'),
  ].map((chord) => chord.transpose(Crnt.song()?.key() ?? 'C'));

  function toggleHAYE(value: boolean) {
    UserPreferences.set('haye', value);
  }
</script>

<PanelModalLayout>
  {#snippet header()}
    <header>
      <PanelHeading title="Editor Settings" padX={6}>
        {#snippet text()}
          Most of these settings apply in both edit and playback modes.
        {/snippet}
      </PanelHeading>
    </header>
  {/snippet}

  {#snippet main()}
    <div class="relative">
      <div class="px-3 pb-3 pt-4">
        <PanelSettingSection title="Chart Display">
          <div class="group">
            <button
              class="colors-panelOpener relative block w-full pb-3 pt-2 text-left leading-[1.35] group-first:rounded-t-lg group-last:rounded-b-lg"
              onclick={() => modalController.zoomInto(EditorSettingsChordDisplay)}
            >
              <div class="clearfix mb-2 w-full px-3.5" inert>
                {#each exampleFoursome as chord, i}
                  <div
                    class="ChartCell whole"
                    class:bar-start={i % 2 == 0}
                    class:bar-end={i % 2 == 1}
                    class:line-end={i == 3}
                    style="width: 24.9%; --chord-font-size: 1.75rem;"
                  >
                    <div class="ChartCell__walls">
                      <div class="ChartCell__beat">
                        <div class="flex items-center justify-center">
                          <span class="ChartChord"
                            >{@html renderChordAsHtml(chord, { system: chordSystem })}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                {/each}
              </div>
              <div class="relative pl-3 pr-9">
                <span class="text-primary-600"> Chord System: </span>
                {capitalize(chordSystem)}
                <PanelOpenerChevron direction="right" />
              </div>
            </button>
          </div>
        </PanelSettingSection>

        <div class="mb-4"></div>

        <PanelSettingSection title="Count-Ins">
          <div class="group">
            <button
              class="colors-panelOpener relative block w-full py-3 pl-3 pr-9 text-left leading-[1.35] group-first:rounded-t-lg group-last:rounded-b-lg"
              onclick={() => modalController.zoomInto(EditorSettingsCountIn)}
            >
              Count-ins {countInEnabled ? 'enabled' : 'disabled'}
              <PanelOpenerChevron direction="right" />
            </button>
          </div>
        </PanelSettingSection>

        <div class="mb-4"></div>

        <PanelSettingSection title="Global Audio/Mix Settings">
          <div class="group">
            <button
              class="colors-panelOpener relative block w-full py-3 pl-3 pr-9 text-left leading-[1.35] group-first:rounded-t-lg group-last:rounded-b-lg"
              onclick={() => modalController.zoomInto(EditorGlobalMixerPanel)}
            >
              Audio/Mix Settings
              <PanelOpenerChevron direction="right" />
            </button>
          </div>
        </PanelSettingSection>

        <div class="mb-4"></div>

        <PanelSettingSection title="Editor Chord Previews">
          <div class="flex px-4 py-3">
            <div class="mr-2">
              <!-- prettier-ignore -->
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" style="fill: var(--gray-500); vertical-align:-4px;" viewBox="0 0 20 20"><path d="M2.21262 3.05049c4.65-6.6375 15.19998-1.4125 12.66248 6.35-.5 1.62501-1.5125 3.20001-3.0875 4.07501-1.3125.725-2.39998 1.6875-2.99998 3.0375-.3375.65-.5625 1.45-1.1625 2.2-1.2375 1.3875-3.55 1.8125-4.925.5-.65-.6375-.975-1.4375-1.2-2.225-.1-.3625.4375-.525.5625-.1875.275.7.6375 1.3875 1.1625 1.825.9875.9 2.85.3625 3.55-.575.375-.5125.575-1.275.9125-2.0375.65-1.5875 2-2.875 3.42498-3.7 1.2125-.675 2.05-1.9375 2.475-3.31251 2.1625-6.6875-6.64998-10.9625-10.98748-5.675-.175.25-.5625-.025-.3875-.275Z"/><path d="M8.7126 3.18788c-3.4625-.55-6.3125 2.6-4.8625 5.8625.0375.0875.15.1375.2375.1.05-.0125.1125-.0375.1625-.05.1-.0375.15-.1375.125-.2375-.1-.3375-.1625-.675-.175-1.0125 0-.0375.025-.075.075-.075 1.0875-.0375 2.3375.5 1.9 1.6875-.425 1.16252-2.3875 1.55002-3.2625 2.46252a.15844.15844 0 0 0 0 .225c.0375.05.0875.1125.125.1625.05.075.1625.0875.2375.0375 1.3-.8125 3.0125-.925 3.7875-2.45002.8375-1.8375-.9625-3.125-2.7-2.875-.05 0-.0875-.0375-.075-.0875.1875-1.0125.8125-1.9125 1.8625-2.3375 3.2375-1.575 6.175 1.95 3.825 4.7-1.4375 1.58752-3.175 3.06252-3.7375 5.06252-.025.0875.025.1875.125.225l.175.05c.0875.025.1875-.025.2125-.1125.7125-1.7875 1.2-2.2375 3.9125-4.425 2.7875-2.25002 1.3-6.56252-1.925-6.93752l-.025.025Z"/></svg>
            </div>
            <div class="flex-1 text-gray-700">
              Hear As You Edit
              <HelpCircleTooltip
                text="When enabled, Strum Machine will play a strum when you change chords while editing."
              />
            </div>
            <div>
              <ToggleSwitch
                checked={hayeEnabled}
                on:change={({ detail: value }) => toggleHAYE(value)}
              />
            </div>
          </div>
        </PanelSettingSection>
      </div>
    </div>
  {/snippet}
</PanelModalLayout>
