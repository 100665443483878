import * as Sentry from '@sentry/browser';
import { Meteor } from 'meteor/meteor';
import { Mongo } from 'meteor/mongo';
import { ReactiveVar } from 'meteor/reactive-var';
import { Tracker } from 'meteor/tracker';
import _ from 'underscore';
import type { MedleySongRecord } from '@/collections/MedleysCollection';
import type { SongListItemRecord, SongListRecord } from '@/collections/SongListsCollection';
import { SongListsCollection } from '@/collections/SongListsCollection';
import { makeListNameForSorting } from '@/library/makeListNameForSorting';
import { sortList } from '@/library/sortList';
import { checkOfflineStorageAvailable } from '@/local-db/checkOfflineStorageAvailable';
import { GroundedCollection } from '@/local-db/GroundedCollection';
import localStorageDBNames from '@/local-db/localStorageDBNames';
import waitUntilReactive from '@/utilities/waitUntilReactive';

const noStateFieldProjection = {
  'name': 1,
  'songs': 1,
  'ownerId': 1,
  'ownerName': 1,
  'sortMethod': 1,
  'publiclyEditable': 1,
  'createdAt': 1,
};

function listPreviewCount() {
  const user = Meteor.user('listPreviews');
  if (!user) return -1;
  const lp = user.listPreviews;
  if (!lp) return 0;
  const lpKeys = Object.keys(lp) as (keyof typeof lp)[];
  return lpKeys.reduce((total, p) => total + (lp[p]?.length ?? 0), 0);
}

export class ListBackend {
  private listsSubscription?: Meteor.SubscriptionHandle;
  /*semi-private*/ groundedLists?: GroundedCollection<SongListRecord>;
  private liveCollection = SongListsCollection;

  async load(): Promise<void> {
    this.listsSubscription = Meteor.subscribe('songLists');

    const offlineAvailable = await checkOfflineStorageAvailable();
    if (offlineAvailable) {
      this.groundedLists = new GroundedCollection(localStorageDBNames.songLists, {});
      this.groundData();
      this.setOfflineReadyWhenLoaded();
    }

    await waitUntilReactive(
      () =>
        (this.groundedLists || this.liveCollection).find().count() == listPreviewCount() ||
        this._forceReady.get()
    );
    this.startItemCountingAutorun();
    this.initMusicOnlyInListsCollection();
    this._ready.set(true);
  }

  private _forceReady = new ReactiveVar(false);
  forceReady(): void {
    this._forceReady.set(true);
  }

  // This is a separate async function because we don't want it holding up live access
  private async groundData(): Promise<void> {
    await this.groundedLists?.waitUntilLoaded();
    await waitUntilReactive(() => this.listsSubscription?.ready());
    this.groundedLists?.observeSource(this.liveCollection.find());
    this.groundedLists?.keep(this.liveCollection.find());
  }

  // This is a separate async function because it should be, OK?
  private async setOfflineReadyWhenLoaded(): Promise<void> {
    await this.groundedLists?.waitUntilLoaded();
    await waitUntilReactive(
      () =>
        this.groundedLists?.pendingWrites.isZero() &&
        this.groundedLists.find().count() == listPreviewCount()
    );
    this._offlineReady.set(true);
  }

  initMusicOnlyInListsCollection(): Meteor.LiveQueryHandle {
    let lastSOILMap = new Map<string, SongListItemRecord>();
    let lastMOILMap = new Map<string, SongListItemRecord>();
    return Tracker.autorun(() => {
      const newSOILMap = new Map<string, SongListItemRecord>();
      const newMOILMap = new Map<string, SongListItemRecord>();
      this.liveCollection
        .find()
        .fetch()
        .flatMap(
          // The assumption is that if the song isn't public or the user's, it must be shared list-only
          (list: SongListRecord) =>
            list.songs.filter(
              (songOrMedley: SongListItemRecord) =>
                (songOrMedley.userId && songOrMedley.userId != Meteor.userId()) ||
                songOrMedley.medleySongs
            )
        )
        .forEach((songOrMedley: SongListItemRecord) => {
          if (songOrMedley.medleySongs) {
            if (songOrMedley.userId && songOrMedley.userId != Meteor.userId()) {
              newMOILMap.set(songOrMedley._id, songOrMedley);
            }
            songOrMedley.medleySongs
              .filter((song: SongListItemRecord) => song.userId && song.userId != Meteor.userId())
              .forEach((song: MedleySongRecord) => newSOILMap.set(song._id, song));
          } else {
            newSOILMap.set(songOrMedley._id, songOrMedley);
          }
        });

      newSOILMap.forEach((value, key) => {
        if (!lastSOILMap.has(key)) {
          this.songsOnlyInLists.insert(value);
        } else if (!_.isEqual(value, lastSOILMap.get(key))) {
          this.songsOnlyInLists.upsert(key, value);
        }
      });

      lastSOILMap.forEach((_value, key) => {
        if (!newSOILMap.has(key)) {
          this.songsOnlyInLists.remove(key);
        }
      });

      newMOILMap.forEach((value, key) => {
        if (!lastMOILMap.has(key)) {
          this.medleysOnlyInLists.insert(value);
        } else if (!_.isEqual(value, lastMOILMap.get(key))) {
          this.medleysOnlyInLists.upsert(key, value);
        }
      });

      lastMOILMap.forEach((_value, key) => {
        if (!newMOILMap.has(key)) {
          this.medleysOnlyInLists.remove(key);
        }
      });

      lastSOILMap = newSOILMap;
      lastMOILMap = newMOILMap;
    });
  }

  songsOnlyInLists = new Mongo.Collection<SongListItemRecord>(null);
  medleysOnlyInLists = new Mongo.Collection<SongListItemRecord>(null);

  private customFind(
    options: { own?: boolean; noState?: boolean } = {}
  ): Mongo.Cursor<SongListRecord> {
    const userId = Meteor.userId();
    const query: Mongo.Query<SongListRecord> = {};
    if (userId && typeof options.own == 'boolean') {
      query.ownerId = options.own ? userId : { $nin: [userId] };
    }
    const projection = options.noState === true ? { fields: noStateFieldProjection } : {};
    return (this.groundedLists || this.liveCollection).find(query, projection);
  }

  findAndFetch(
    options: { own?: boolean; noState?: boolean; sort?: boolean } = {}
  ): SongListRecord[] {
    const results = this.customFind({ own: options.own, noState: options.noState }).fetch();
    if (options.sort === true) {
      // if you change this sorting implementation, be sure to edit updateListPreviews too.
      results.sort((a, b) =>
        makeListNameForSorting(a.name).localeCompare(makeListNameForSorting(b.name))
      );
    }
    results.forEach((list: SongListRecord) => {
      this.sortListCarefully(list);
    });
    return results;
  }

  findOne(options = {}): SongListRecord | undefined {
    const result = (this.groundedLists || this.liveCollection).findOne(options);
    if (result) this.sortListCarefully(result);
    return result;
  }

  count(options: { own?: boolean } = {}): number {
    return this.customFind(options).count();
  }

  countWithItem(itemId: string): number {
    this.listItemCountsDep.depend();
    return this.listItemCounts.get(itemId) || 0;
  }

  private sortListCarefully(list: SongListRecord) {
    try {
      sortList(list);
    } catch (e) {
      Sentry.withScope((scope) => {
        scope.setExtra('list._id', list._id);
        scope.setExtra('list.songs', JSON.stringify(list.songs));
        Sentry.captureMessage('Error while sorting list');
      });
    }
  }

  private _ready = new ReactiveVar(false);
  ready(): boolean {
    return this._ready.get();
  }

  private _offlineReady = new ReactiveVar(false);
  offlineReady(): boolean {
    return this._offlineReady.get();
  }

  private listItemCounts = new Map<string, number>();
  private listItemCountsDep = new Tracker.Dependency();
  startItemCountingAutorun(): void {
    Tracker.nonreactive(() =>
      Tracker.autorun(() => {
        this.listItemCounts.clear();
        const lists = this.findAndFetch({ noState: true });
        lists.forEach((list: SongListRecord) => {
          if (!list.songs) return;
          list.songs.forEach((item: { _id: string }) => {
            const existingCount = this.listItemCounts.get(item._id) || 0;
            this.listItemCounts.set(item._id, existingCount + 1);
          });
        });
        this.listItemCountsDep.changed();
      })
    );
  }
}
