import { Blaze } from 'meteor/blaze';
import { Meteor } from 'meteor/meteor';

Meteor.startup(() => {
  Blaze.render(
    Blaze._getTemplate('bertAlert', () => null),
    document.body
  );
});

class BertAlert {
  constructor() {
    this.styles = [
      'fixed-top',
      'fixed-bottom',
      'growl-top-left',
      'growl-top-right',
      'growl-bottom-left',
      'growl-bottom-right',
    ];

    this.types = ['default', 'success', 'info', 'warning', 'danger'];

    this.icons = {
      default: 'chevron-right',
      success: 'check',
      info: 'help-circle',
      warning: 'alert-circle',
      danger: 'alert-circle',
    };

    this.defaults = {
      hideDelay: 6000,
      style: 'fixed-bottom',
      type: 'default',
    };
  }

  alert(...args) {
    if (this.isVisible()) {
      this.hide();
      setTimeout(() => {
        this.handleAlert(args);
      }, 300);
    } else {
      this.handleAlert(args);
    }
  }

  isVisible() {
    return $('.bert-alert').hasClass('show');
  }

  handleAlert(alert) {
    this.registerClickHandler();
    this.setBertOnSession(alert);
    setTimeout(() => {
      this.show();
    }, 20);
    setTimeout(() => {
      this.bertTimer(Math.min(2000 + Session.get('bertAlert').message.length * 50, 7000));
    }, 0);
  }

  registerClickHandler() {
    $('.bert-alert').off('click');
    $('.bert-alert').on('click', () => {
      this.hide();
    });
  }

  bertTimer(delay) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.hide();
    }, delay);
    return this.timer;
  }

  show() {
    $('.bert-alert')
      .addClass('show')
      .delay(25)
      .queue(() => {
        $('.bert-alert').addClass('animate').dequeue();
      });
  }

  hide() {
    $('.bert-alert').removeClass('animate');
    setTimeout(() => {
      $('.bert-alert').removeClass('show');
      Session.set('bertAlert', null);
    }, 300);
  }

  setBertOnSession(alert) {
    if (typeof alert[0] === 'object') {
      const type = alert[0].type || this.defaults.type;

      Session.set('bertAlert', {
        title: alert[0].title || '',
        message: alert[0].message || '',
        type,
        style: alert[0].style || this.defaults.style,
        icon: alert[0].icon || this.icons[type],
      });
    } else {
      const type = alert[1] || this.defaults.type;

      Session.set('bertAlert', {
        message: alert[0] || '',
        type,
        style: alert[2] || this.defaults.style,
        icon: alert[3] || this.icons[type],
      });
    }
  }
}

window.Bert = new BertAlert();
