import { strict as assert } from 'assert';
import { bounded } from '@/utilities/bounded';
import { lerp } from '@/utilities/lerp';
import { threeWayLerp } from '@/utilities/threeWayLerp';

export function interpolateBetweenPolesInPolarChart<NumberArray extends number[]>(
  centerPolePowers: NumberArray,
  poles: readonly { theta: number; fullPowers: NumberArray; halfPowers?: NumberArray }[],
  r: number,
  theta: number
): NumberArray {
  const minPoleTheta = poles.reduce((min, pole) => Math.min(min, pole.theta), 1);
  const maxPoleTheta = poles.reduce((max, pole) => Math.max(max, pole.theta), 0);
  const polesWithDistances = poles.map((pole) => {
    const newPoleTheta =
      theta < minPoleTheta
        ? ((pole.theta + 0.5) % 1) - 0.5
        : theta > maxPoleTheta
          ? ((pole.theta - 0.5) % 1) + 0.5
          : pole.theta;
    return {
      ...pole,
      theta: newPoleTheta,
      distance: Math.min(Math.abs(newPoleTheta - theta), Math.abs(newPoleTheta - theta + 1)),
    };
  });
  polesWithDistances.sort((a, b) => a.distance - b.distance);
  const [pole1, pole2] = polesWithDistances;
  assert(pole1 && pole2, 'Could not find lower and upper poles');

  const thetaLerpValue = pole1.distance / (pole1.distance + pole2.distance);

  const halfR = 0.666;

  return centerPolePowers.map((centerPower, index) => {
    const pole1FullPower = pole1.fullPowers[index] ?? 0;
    const pole1HalfPower = pole1.halfPowers?.[index] ?? (pole1FullPower + centerPower) / 2;
    const pole2FullPower = pole2.fullPowers[index] ?? 0;
    const pole2HalfPower = pole2.halfPowers?.[index] ?? (pole2FullPower + centerPower) / 2;
    const value1 = threeWayLerp(centerPower, pole1HalfPower, pole1FullPower, r, halfR);
    const value2 = threeWayLerp(centerPower, pole2HalfPower, pole2FullPower, r, halfR);
    const interpolatedValue = lerp(value1, value2, thetaLerpValue);
    return bounded(interpolatedValue, 0, 1);
  }) as NumberArray;
}
