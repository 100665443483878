import type { BuiltInPresetDefinition } from '@/band/presets/BuiltInPresetDefinition';

export const bluegrassPresetDefinitions: Partial<Record<TimeSignature, BuiltInPresetDefinition[]>> =
  {
    // --- 4/4 ---

    '4/4': [
      {
        id: 'bg-s',
        name: 'Bluegrass - Simple', // Standard, Simple, All-Purpose, Generic
        genreId: 'bg',
        genreCategoryId: 'bg-s',
        description: 'All-purpose basic bluegrass backup with just a bit of swing.',
        band: {
          'sw': '5:4',
          'g_c': 'bg',
          'g_ov': { 'mj': 'gcd' },
        },
      },

      {
        id: 'bg-midt',
        name: 'Bluegrass – Standard', // Standard, Classic, Generic
        genreId: 'bg',
        genreCategoryId: 'bg-n',
        description: 'Somewhat swingy strum for mid-tempo bluegrass standards.',
        band: {
          'sw': '3:2',
          'g_c': 'bg',
          'g_bcbr': { 'v': 9 / 14 },
          'g_ss': { 'r': 0.33, 't': 0.75 },
          'g_br': { 'e': 0.25, 'qeb': 0.75 },
          'g_ov': { 'mj': 'gcd' },
          'm_ca': { 'r': 0, 't': 0 },
        },
      },

      {
        id: 'bg-ut',
        name: 'Bluegrass – Streamlined', // Fast, Driving, Straight-Ahead, Streamlined, Fast Lane, High Gear
        genreId: 'bg',
        genreCategoryId: 'bg-n',
        description: 'Straight-ahead strum optimized for faster tempos and instrumentals.',
        band: {
          'sw': '5:4',
          'g_c': 'bg',
          'g_bcbr': { 'v': 8 / 14 },
          'g_ss': { 'r': 0.25, 't': 0.125 },
          'g_bn': { 'l': 0.25 },
          'g_br': { 'e': 0.25 },
          'g_ov': { 'mj': 'gd' },
          'm_cd': { 'i': 1, 's': 0 },
          'm_ca': { 'r': 0.75, 't': 0.125 },
        },
      },

      {
        id: 'bg-jm',
        name: 'Bluegrass – Swingy', // Swingy, Shuffling, Loping, Bouncy, Lilting
        genreId: 'bg',
        genreCategoryId: 'bg-n',
        description: 'Big strums. Lots of upstroke swing. Jimmy Martin vibes.',
        band: {
          'sw': '7:4',
          'g_c': 'bg',
          'g_bcbr': { 'v': 11 / 14 },
          'g_ss': { 'r': 0.575, 't': 0.875 },
          'g_br': { 'e': 0.25, 'qeb': 0.75 },
          'm_cd': { 'i': 1, 's': 1 },
          'm_ca': { 'r': 0.5, 't': 0 },
        },
      },

      {
        id: 'bg-mlw',
        name: 'Bluegrass – Soft', // Soft, Gentle, Dialed-Back, Delicate, Tender, Subdued
        genreId: 'bg',
        genreCategoryId: 'bg-n',
        description: 'Gentler accompaniment with sweeter chord voicings.',
        band: {
          'sw': '3:2',
          'g_c': 'bg',
          'g_bcbr': { 'v': 3 / 14 },
          'g_bn': { 'l': 0.25 },
          'g_ss': { 'r': 0.325, 't': 0.625 },
          'g_ov': { 'mj': 'gc', 'gv': 'G' },
          'g_br': { 'e': 0.25, 'qeb': 0.5, 'jf': 'sm' },
          'm_cd': { 'i': 0, 's': 2 },
          'm_ca': { 'r': 0.5, 't': 0.25 },
        },
      },

      {
        id: 'bg-midtm',
        name: 'Bluegrass – Modal', // Bluesy, Lonesome
        genreId: 'bg',
        genreCategoryId: 'bg-m',
        description: 'Somewhat swingy strum for mid-tempo songs. Bluesy scale in fills.',
        band: {
          'sw': '3:2',
          'g_c': 'bg',
          'g_bcbr': { 'v': 9 / 14 },
          'g_ss': { 'r': 0.33, 't': 0.75 },
          'g_br': { 'e': 0.25, 'qeb': 0.75, 'jf': 'b' },
          'g_ov': { 'mj': 'gd' },
          'm_ca': { 'r': 0.5, 't': 0.5 },
        },
      },

      {
        id: 'bg-utm',
        name: 'Bluegrass – Modal, Streamlined',
        genreId: 'bg',
        genreCategoryId: 'bg-m',
        description: 'Straight-ahead strum with fewer thirds in chord voicings & fills.',
        band: {
          'sw': '5:4',
          'g_c': 'bg',
          'g_bcbr': { 'v': 8 / 14 },
          'g_ss': { 'r': 0.25, 't': 0.125 },
          'g_br': { 'e': 0.25, 'jf': 'b' },
          'g_ov': { 'mj': 'gd' },
          'm_cd': { 'i': 1, 's': 0 },
          'm_ca': { 'r': 0.75, 't': 0.325 },
        },
      },

      {
        id: 'bg-msh',
        name: 'Bluegrass – Mash', // Mash, Modal & Driving, Driving & Bluesy, [Straight] Lonesome, Modern Edge, Modern Drive, Progressive, Power Drive
        genreId: 'bg',
        genreCategoryId: 'bg-m',
        description: 'Big strums. Bluesy scale and chord voicings. A more modern "mash" feel.',
        band: {
          'sw': '5:4',
          'g_c': 'bg',
          'g_bcbr': { 'v': 11 / 14 },
          'g_br': { 'e': 0.25, 'qeb': 1, 'jf': 'b' },
          'g_ss': { 'r': 0.325, 't': 0.75 },
          'g_ov': { 'mj': 'gd', 'cadd9': true },
          'b_on': { 'ao': 'l' },
          'm_cd': { 'i': 2, 's': 0 },
          'm_ca': { 'r': 0.6, 't': 0.375 },
        },
      },

      // {
      //   id: 'bg-tr',
      //   name: 'Bluegrass – First-Gen',
      //   genreId: 'bg',
      //   genreCategoryId: 'bg-n',
      //   description: 'Seeks to mimic the playing of “first-generation” bluegrass guitarists like Lester Flatt & Carter Stanley.',
      //   band: {
      //     'sw': '3:2',
      //     'g_c': 'bg',
      //     'g_bcbr': { 'v': 9 / 14 },
      //     'g_ss': { 'r': 0.33, 't': 0.75 },
      //     'g_br': { 'e': 0.25, 'qeb': 0.25 },
      //     'g_ov': { 'mj': 'gcd', 'gv': 'G' },
      //     'm_ca': { 'r': 0.5, 't': 0.25 },
      //     'm_cd': { 'i': 1, 's': 2 },
      //   },
      // },
    ],

    // --- 3/4 ---

    '3/4': [
      {
        id: 'bgw-s',
        name: 'Bluegrass - Simple Waltz',
        genreId: 'bg',
        genreCategoryId: 'bg-w',
        description:
          'All-purpose bluegrass waltz accompaniment. Some upstroke swing. Nothing fancy.',
        band: {
          'sw': '3:2',
          'g_c': 'bgw',
          'g_ov': { 'mj': 'gcd' },
          'g_ss': { 'r': 0.33, 't': 0.25 },
        },
      },

      {
        id: 'bgw-prty',
        name: 'Bluegrass - Pretty Waltz',
        genreId: 'bg',
        genreCategoryId: 'bg-w',
        description: 'Gentler strums. Sweeter chord voicings. Some fills. Better at slower tempos.',
        band: {
          'sw': '7:4',
          'g_c': 'bgw',
          'g_bcbr': { 'v': 5 / 14 },
          'g_ss': { 'r': 0.625, 't': 0.375 },
          'g_br': { 'e': 0.5, 'qeb': 1, 'jf': 'sm' },
          'g_ov': { 'mj': 'gc', gv: 'G' },
          'g_bn': { 'l': 0.5 },
          'b_on': { 'm': 'r' },
          'm_cd': { 'i': 0, 's': 2 },
        },
      },

      {
        id: 'bgw-pw',
        name: 'Bluegrass - Power Waltz',
        genreId: 'bg',
        genreCategoryId: 'bg-w',
        description: 'More intense strums and playing. Often used at faster tempos.',
        band: {
          'sw': '3:2',
          'g_c': 'bgw',
          'g_ss': { 'r': 0.333, 't': 0.25 },
          'g_bcbr': { 'v': 10 / 14 },
          'g_ov': { 'mj': 'gd' },
          'b_osw': { 'v': 0.917 },
          'm_cd': { 'i': 1, 's': 1 },
        },
      },
    ],
  };
