import * as Sentry from '@sentry/browser';
import { Meteor } from 'meteor/meteor';
import { Random } from 'meteor/random';
import { ReactiveVar } from 'meteor/reactive-var';
import { Tracker } from 'meteor/tracker';
import { eventTracker } from '@/browser/analytics/eventTracker';
import { DemoMode } from '@/client/DemoMode';
import { Crnt } from '@/Crnt';
import { mainIndexSearcher } from '@/library/mainIndexSearcher';
import { makeListNameForSorting } from '@/library/makeListNameForSorting';
import { MusicLibrary } from '@/library/MusicLibrary';
import { Roles } from '@/Roles';
import { rpcSetListsForSongOrMedley } from '@/server/methods/lists/rpcSetListsForSongOrMedley';
import { UserProfile } from '@/user/UserProfile';
import waitUntilReactive from '@/utilities/waitUntilReactive';

const keyboardVisibility = new ReactiveVar(false);

Meteor.startup(() => {
  document.addEventListener(
    'deviceready',
    function listenToKeyboardOpen() {
      if ('Keyboard' in window) {
        window.addEventListener('keyboardDidShow', () => keyboardVisibility.set(true));
        window.addEventListener('keyboardDidHide', () => keyboardVisibility.set(false));
      }
    },
    false
  );
});

// type SongListRecordForSong = SongListRecord & { hasSong?: boolean; readOnly?: boolean };

Template.listsMenu.onCreated(() => {
  const instance = Template.instance();
  instance.typingNewName = new ReactiveVar(false);
  instance.choosingNewList = new ReactiveVar(false);
  instance.listsDep = new Tracker.Dependency();
  instance.lists = [];
  instance.listsWithSong = [];
  instance.listsWithoutSong = [];
  waitUntilReactive(() => MusicLibrary.lists.ready()).then(() =>
    instance.autorun(() => {
      const itemId =
        instance.data.songId || Crnt.song()?.id() || instance.data.medleyId || Crnt.medley()?.id();
      if (!itemId) return;
      instance.lists = MusicLibrary.lists.findAndFetch({ noState: true });
      instance.lists.forEach((list) => {
        if (list.songs.find(({ _id }) => _id === itemId)) {
          list.hasSong = true;
        }
        if (!Roles.userHasPermission(Meteor.userId(), 'lists.update', list)) {
          list.readOnly = true;
        }
      });
      instance.listsWithSong = instance.lists.filter((list) => list.hasSong);
      instance.listsWithoutSong = instance.lists.filter((list) => !list.hasSong && !list.readOnly);
      instance.listsDep.changed();
    })
  );
  UserProfile.recordMilestone('LISTS_MENU_OPEN');
});

Template.listsMenu.onRendered(() => {
  const instance = Template.instance();
  instance.autorun(() => {
    // had to duplicate this code in the click handler to get focus working on mobile
    const typing = instance.typingNewName.get();
    // instance.$('.modal-footer')[typing ? 'slideUp' : 'slideDown']({duration: 250});
    instance.$('.js-newListLink')[typing ? 'hide' : 'show']();
    instance.$('.js-newListForm')[typing ? 'show' : 'hide']();
    // instance.$(typing ? '.js-newListInput' : '.modal').focus();
    if (typing) {
      instance.$('.js-newListInput').focus().select();
    }
  });
  instance.autorun(() => {
    if (keyboardVisibility.get()) {
      // dirty hack to scroll lists modal to bottom when typing in a new list name
      instance.$('.js-newListInput').closest('.modal-content').scrollTop(9999);
      instance.$('.js-newListInput').closest('.js-global-menuScrollParent').scrollTop(9999);
    }
  });
  if (instance.lists.length > 0 && instance.data.newSong) {
    instance.choosingNewList.set(true);
  }
  instance.autorun((comp) => {
    const adding = instance.choosingNewList.get();
    const toggleVis = ($el, show) => {
      const method = comp.firstRun ? (show ? 'show' : 'hide') : show ? 'slideDown' : 'slideUp';
      const args = comp.firstRun ? undefined : { duration: 250 };
      $el[method](args);
    };
    if (!instance.data.newSong) {
      toggleVis(instance.$('.js-promptForSelectingList'), !adding);
    }
    toggleVis(instance.$('.js-showListSelectionButton'), !adding);
    toggleVis(instance.$('.js-listSelectionForm'), adding);
    instance.$('.js-closeAddToList')[adding ? 'show' : 'hide'](); // so that it's hidden during the closing animation
    instance.$(adding ? '.js-listSelectionForm' : '.listsMenu').focus();
    if (adding && instance.listsWithoutSong.length === 0) {
      instance.$('.js-newListLink').click();
    }
  });
});

Template.listsMenu.helpers({
  userHasZeroLists() {
    Template.instance().listsDep.depend();
    return Template.instance().lists.length === 0;
  },

  isOnZeroLists() {
    Template.instance().listsDep.depend();
    return Template.instance().listsWithSong.length === 0;
  },

  isOnOneList() {
    Template.instance().listsDep.depend();
    return Template.instance().listsWithSong.length === 1;
  },

  isOnMoreThanZeroLists() {
    Template.instance().listsDep.depend();
    return Template.instance().listsWithSong.length > 0;
  },

  isOnMoreThanOneList() {
    Template.instance().listsDep.depend();
    return Template.instance().listsWithSong.length > 1;
  },

  isNotOnMoreThanZeroLists() {
    Template.instance().listsDep.depend();
    return Template.instance().listsWithoutSong.length > 0;
  },

  listsSongIsOn() {
    Template.instance().listsDep.depend();
    return Template.instance().listsWithSong.sort((a, b) =>
      makeListNameForSorting(a.name).localeCompare(makeListNameForSorting(b.name))
    );
  },

  listsSongIsNotOn() {
    Template.instance().listsDep.depend();
    return Template.instance().listsWithoutSong.sort((a, b) =>
      makeListNameForSorting(a.name).localeCompare(makeListNameForSorting(b.name))
    );
  },

  notMyList(list) {
    return list.ownerId && list.ownerName && list.ownerId != Meteor.userId();
  },

  showOfflineMessage() {
    return !DemoMode.active() && !/^connect/.test(Meteor.status().status);
  },

  showLoadingMessage() {
    const { songId, medleyId } = Template.currentData();
    return (
      !DemoMode.active() &&
      Meteor.status().connected &&
      (!MusicLibrary.lists.ready() || !(Crnt.song() || Crnt.medley() || songId || medleyId))
    );
  },

  songOrMedley() {
    return Crnt.medley() ? 'medley' : 'song';
  },

  ready() {
    const { songId, medleyId } = Template.currentData();
    return (
      !DemoMode.active() &&
      Meteor.status().connected &&
      MusicLibrary.lists.ready() &&
      (Crnt.song() || Crnt.medley() || songId || medleyId)
    );
  },
});

Template.listsMenu.events({
  'click .js-listsMenuAddMenu': function (_event, _instance) {
    return false;
  },

  'click .js-openAddToList': function (_event, instance) {
    instance.choosingNewList.set(true);
    return false;
  },

  'click .js-closeAddToList': function (_event, instance) {
    instance.choosingNewList.set(false);
    instance.typingNewName.set(false);
    return false;
  },

  'click .js-addToList': function (event, instance) {
    instance.choosingNewList.set(false);
    const listId = $(event.currentTarget).data('list-id');
    const songId = instance.data.songId || Crnt.song()?.id();
    const medleyId = instance.data.medleyId || Crnt.medley()?.id();
    const list = instance.lists.find((l) => l._id == listId);
    const indexLWS = instance.listsWithoutSong.indexOf(list);
    if (indexLWS > -1) {
      instance.listsWithoutSong.splice(indexLWS, 1);
    }
    list.hasSong = true;
    instance.listsWithSong.push(list);
    instance.listsDep.changed();
    rpcSetListsForSongOrMedley({ songId, medleyId, addTo: [listId] })
      .then(() => {
        eventTracker.listAddSong();
        UserProfile.recordMilestone('ADD_TO_LIST');
        mainIndexSearcher.refreshResults();
      })
      .catch((err) => {
        Bert.alert('Error saving song list.');
        console.error(err);
        Sentry.captureException(err);
      });
    return false;
  },

  'click .js-removeFromList': function (event, instance) {
    const listId = $(event.currentTarget).data('list-id');
    const songId = instance.data.songId || Crnt.song()?.id();
    const medleyId = instance.data.medleyId || Crnt.medley()?.id();
    const list = instance.lists.find((l) => l._id == listId);
    const indexLWS = instance.listsWithSong.indexOf(list);
    if (indexLWS > -1) {
      instance.listsWithSong.splice(indexLWS, 1);
    }
    list.hasSong = false;
    instance.listsWithoutSong.push(list);
    instance.listsDep.changed();
    rpcSetListsForSongOrMedley({ songId, medleyId, removeFrom: [listId] })
      .then(() => {
        eventTracker.listRemoveSong();
        mainIndexSearcher.refreshResults();
      })
      .catch((err) => {
        Bert.alert('Error saving song list.');
        console.error(err);
        Sentry.captureException(err);
      });
    return false;
  },

  'keyup .js-newListInput': function (event, instance) {
    if (event.key == 'Enter' && instance.$('.js-newListInput').val()) {
      instance.$('.js-newListOK').click();
      return false;
    }
    if (event.key == 'Escape') {
      instance.$('.js-newListCancel').click();
      return false;
    }
    return;
  },

  'click .js-openListModal': function (event, _instance) {
    Modal.show('songListModal', { listId: $(event.currentTarget).data('list-id') });
    return false;
  },

  'click .js-newListLink': function (event, instance) {
    const defaultName = instance.lists.length > 0 ? '' : 'Saved songs';
    instance.$('.js-newListInput').val(defaultName);
    instance.typingNewName.set(true);
    // duplicate code in autorun to fix focusing on mobile
    instance.$('.js-newListLink').hide();
    instance.$('.js-newListForm').show();
    instance.$('.js-newListInput').focus().select();
    setTimeout(
      () => $(event.target).closest('.js-global-menuScrollParentmenuScrollParent').scrollTop(9999),
      50
    );
    setTimeout(
      () => $(event.target).closest('.js-global-menuScrollParentmenuScrollParent').scrollTop(9999),
      400
    ); // delayed for iOS keyboard to appear
    return false;
  },

  'click .js-newListOK': function (_event, instance) {
    const listName = instance.$('.js-newListInput').val().trim();
    if (listName) {
      const songId = instance.data.songId || Crnt.song()?.id();
      const medleyId = instance.data.medleyId || Crnt.medley()?.id();
      const list = {
        _id: Random.id(),
        name: listName,
        hasSong: true,
        newList: true,
      };
      instance.lists.push(list);
      instance.listsWithSong.push(list);
      instance.listsDep.changed();
      instance.choosingNewList.set(false);
      rpcSetListsForSongOrMedley({ songId, medleyId, newLists: [list] })
        .then(() => {
          eventTracker.listCreate();
          UserProfile.recordMilestone('NEW_LIST');
          mainIndexSearcher.refreshResults();
        })
        .catch((err) => {
          Bert.alert('Error saving song list.');
          console.error(err);
          Sentry.captureException(err);
        });
    }
    instance.typingNewName.set(false);
  },

  'click .js-newListCancel': function (_event, instance) {
    instance.typingNewName.set(false);
    if (instance.listsWithoutSong.length === 0) {
      instance.choosingNewList.set(false);
    }
  },
});
