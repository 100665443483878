import { applyBassIntentsToMeasure } from '@/band/instruments/bass/applyBassIntentsToMeasure';
import { BasicBassCore } from '@/band/instruments/bass/BasicBassCore';
import { BasicJigBassCore } from '@/band/instruments/bass/BasicJigBassCore';
import { BasicWaltzBassCore } from '@/band/instruments/bass/BasicWaltzBassCore';
import type { BassCore } from '@/band/instruments/bass/BassCore';
import { bassCores } from '@/band/instruments/bass/bassCores';
import { bassSettingDefinitions } from '@/band/instruments/bass/bassSettingDefinitions';
import type { BassSettingsHash } from '@/band/instruments/bass/BassSettingsHash';
import { distributeBassIntentsToMeasure } from '@/band/instruments/bass/distributeBassIntentsToMeasure';
import { initializeInstrumentSettings } from '@/band/instruments/initializeInstrumentSettings';
import { Instrument } from '@/band/instruments/Instrument';
import type { InstrumentPresetSettings } from '@/band/instruments/InstrumentPresetSettings';
import type { LinearizedMeasure } from '@/chart/LinearizedMeasure';
import { safeCallback } from '@/utilities/safeCallback';
import { safeExecute } from '@/utilities/safeExecute';

export class Bass extends Instrument<BassSettingsHash, BassCore> {
  readonly id = 'bass';
  readonly name = 'Bass';
  readonly shortCode = 'b';

  readonly cores = bassCores;
  readonly fallbackCores: Record<TimeSignature, BassCore> = {
    '4/4': BasicBassCore,
    '3/4': BasicWaltzBassCore,
    '6/8': BasicJigBassCore,
    '9/8': BasicJigBassCore,
  };

  readonly settingDefinitions = bassSettingDefinitions;
  protected _settings = initializeInstrumentSettings<BassSettingsHash>(this.settingDefinitions);

  loadCoreAndPreset(core: BassCore, presetSettings: InstrumentPresetSettings<BassSettingsHash>) {
    this.emit('updatedData', {
      [`${this.shortCode}_c`]: core.id,
      // ...this.irrelevantSettingsResetHash(core),
      ...this.serializePresetSettings(presetSettings),
    });
  }

  processMeasures(
    measures: readonly LinearizedMeasure[],
    core: BassCore,
    settings: Readonly<BassSettingsHash>,
    context: Readonly<{ swing: SwingOption; tpm: number }>
  ) {
    const swing = context.swing.formula(context.tpm);
    const beatDuration = 60 / context.tpm;
    const timingTweaks = settings.timing.beatAdjustments;

    safeExecute(() => core.measurePreprocessing?.(measures, settings));

    measures.forEach(
      safeCallback((measure) => {
        const intents = core.processMeasure(measure, settings, { swing, beatDuration });
        distributeBassIntentsToMeasure(intents, measure);
        // overlayMeasureEffectsForBass(measure);
      })
    );

    safeExecute(() => core.measurePostprocessing?.(measures, settings));

    measures.forEach(
      safeCallback((measure) => {
        applyBassIntentsToMeasure(measure, swing, timingTweaks);
      })
    );
  }
}
