<script lang="ts">
  import { RadioGroup } from 'bits-ui';
  import type { Snippet } from 'svelte';
  import RadiolistRadio from '@/ui/icons/RadiolistRadio.svelte';

  interface Props {
    value: string;
    yellowBg?: boolean;
    doubleClickHandler?: () => void;
    children?: Snippet<[{ checked: boolean }]>;
  }

  let { value, yellowBg = false, children: outerChildren, doubleClickHandler }: Props = $props();
</script>

<RadioGroup.Item
  class="BandRadioRow btn-base relative flex w-full items-center whitespace-normal py-4 pl-5 pr-4 text-left font-medium leading-5 text-gray-700 transition-colors sm-band:py-3 sm-band:pl-4 sm-band:pr-3 {yellowBg
    ? 'yellowBg'
    : ''}"
  ondblclick={doubleClickHandler}
  {value}
>
  {#snippet children({ checked })}
    <div class="flex-auto">
      {@render outerChildren?.({ checked })}
    </div>

    <span class="pl-3">
      <RadiolistRadio {checked} size={20} />
    </span>
  {/snippet}
</RadioGroup.Item>

<style>
  :global(.BandRadioRow) {
    color: var(--gray-700);
    background-color: white;
    &::before,
    &::after {
      content: '';
      position: absolute;
      right: 0;
      left: 0;
      height: 0;
      z-index: 1;
      border-top: solid 1px transparent;
    }
    &::before {
      top: -1px;
    }
    &::after {
      bottom: -1px;
    }
    &:hover {
      &::before,
      &::after {
        z-index: 2;
      }
    }
  }
  :global(.BandRadioRow.yellowBg) {
    background-color: var(--yellow-50);
  }
  :global(.BandRadioRow[aria-checked='true']) {
    &,
    &:hover {
      z-index: 5;
      color: black;
      background-color: var(--primary-100);
      &::before,
      &::after {
        border-color: var(--muted-200);
      }
    }
  }
  :global(.BandRadioRow.yellowBg[aria-checked='true']) {
    &,
    &:hover {
      background-color: var(--yellow-100);
      &::before,
      &::after {
        border-color: var(--gray-150);
      }
    }
  }
  @media not all and (hover: none), (min--moz-device-pixel-ratio: 1) {
    :global(.BandRadioRow:hover) {
      z-index: 10;
      background-color: var(--primary-50);
      &::before,
      &::after {
        border-color: var(--primary-200);
      }
    }
    :global(.BandRadioRow.yellowBg:hover) {
      background-color: var(--yellow-100);
      &::before,
      &::after {
        border-color: var(--muted-200);
      }
    }
  }
</style>
