import { eventTracker } from '@/browser/analytics/eventTracker';
import DropdownWatcher from '@/ui/dropdowns/DropdownWatcher';
import UserPreferences from '@/user/UserPreferences';

Template.optionDropdown.onRendered(() => {
  const instance = Template.instance();
  instance.autorun(
    DropdownWatcher({
      element: instance.$('.optionDropdown'),
      getValue: () => UserPreferences.get(instance.data.key),
    })
  );
});

Template.optionDropdown.events({
  'click a': function (event, instance) {
    const [key, value] = [instance.data.key, $(event.currentTarget).data('value')];
    UserPreferences.set(key, value);
    eventTracker.preferenceSet({ key, value });
    event.preventDefault();
  },
});

Template.optionDropdown.helpers({
  label() {
    const rawLabel = this.label || (this.key && UserPreferences.settings[this.key]?.label);
    if (rawLabel) return rawLabel;
  },
  dropupClass() {
    return this.dropup == 'auto' ? 'dropup-auto' : this.dropup ? 'dropup' : '';
  },
  align() {
    return this.align ?? 'right';
  },
});
