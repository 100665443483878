const grabberDataSVG = `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="31 30 19 19"><path style="fill: hsla(206deg 50% 60% / 80%)" d="M34 31c.6 0 1 .4 1 1v14a1 1 0 1 1-2 0V32c0-.6.4-1 1-1Zm6 0c.6 0 1 .4 1 1v14a1 1 0 1 1-2 0V32c0-.6.4-1 1-1Zm6 0c.6 0 1 .4 1 1v14a1 1 0 1 1-2 0V32c0-.6.4-1 1-1Z"/></svg>')`;

export function panSliderCSS(size: 'sm' | 'md' | 'lg'): {
  thumbDiameter: number;
  thumbBorderWidth: number;
  trackHeight: number;
  tickWidth: number;
  rangeCSSVariables: string;
} {
  const thumbDiameter = size == 'sm' ? 1.5 : size == 'md' ? 2 : 2.75;
  const thumbBorderWidth = size == 'sm' ? 1.25 : size == 'md' ? 1.75 : 3;
  const tickWidth = size == 'sm' ? 0.125 : size == 'md' ? 0.1875 : 0.225;
  const trackHeight = size == 'sm' ? 0.5 : size == 'md' ? 0.625 : 0.75;
  const grabberSize = thumbDiameter / 2.5;
  return {
    thumbDiameter,
    thumbBorderWidth,
    trackHeight,
    tickWidth,
    rangeCSSVariables: `
      --thumb-width: ${thumbDiameter * 0.6}rem;
      --thumb-height: ${thumbDiameter}rem;
      --thumb-border-radius: 4px;
      --track-height: ${trackHeight}rem;
      --track-color: var(--muted-150);
      --track-shadow: inset 0px -1px 1.5px rgba(255, 255, 255, 0.3),
          inset 0px 1px 2px rgba(0, 0, 0, 0.2);
      --fill-color: var(--primary-500);
      --thumb-border: ${thumbBorderWidth}px solid var(--muted-300);
      --thumb-background: white ${grabberDataSVG} 50% 50% / ${grabberSize}rem ${grabberSize}rem no-repeat;
      --thumb-focused-border: ${thumbBorderWidth}px solid var(--primary-400);
      --thumb-focused-background: var(--primary-50) ${grabberDataSVG} 50% 50% / ${grabberSize}rem ${grabberSize}rem no-repeat;
      --thumb-hover-border: ${thumbBorderWidth}px solid var(--primary-300);
      --thumb-hover-background: var(--primary-50) ${grabberDataSVG} 50% 50% / ${grabberSize}rem ${grabberSize}rem no-repeat;
      --thumb-shadow: 0px 1px ${thumbDiameter * 4}px rgba(0, 0, 0, 0.2);
      `,
  };
}
