import type { BandPreset } from '@/band/presets/BandPreset';
import { migrateSerializedBandSettings } from '@/band/settings/migrateSerializedBandSettings';

export function createCustomizedDefaultPreset(
  record: SerializedBandPreset,
  originalDefault: BandPreset
): BandPreset {
  return Object.freeze({
    ...originalDefault,
    overrideRecordId: record._id,
    customizedDefault: true,
    band: migrateSerializedBandSettings(record.band ?? {}),
  } as BandPreset);
}
