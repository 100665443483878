import type { SampleDefinition } from '@/audio/engine/base/Sample';

function definePackage(packageId: string, ids: string[], preroll = 0.02): SampleDefinition[] {
  return ids.map((id) => {
    return { id, packageId, preroll };
  });
}

function splitFileList(files: string): string[] {
  return files.split('\n').filter((line) => line.length > 0);
}

export default [
  ...definePackage(
    'click_2024-06',
    splitFileList(`
blockD
blockU
boopD
boopU
clapD
clapU
claveD
claveU
drumstickD
drumstickU
mechanical1
mechanical2
mechanical3
mechanical4
quartzD
quartzU
snapD
snapU
`),
    0.0
  ),

  // that's all!
];
