import { assignGuitarBassRunsAndLeadingNotes } from '@/band/instruments/guitar/assignGuitarBassRunsAndLeadingNotes';
import { boomChuckCell } from '@/band/instruments/guitar/blocks/boomChuckCell';
import { boomChuckEndingMeasure } from '@/band/instruments/guitar/blocks/boomChuckEndingMeasure';
import type { GuitarCore } from '@/band/instruments/guitar/GuitarCore';
import { swingOptions } from '@/band/swingOptions';

export const BoomChuckGuitarCore: GuitarCore = {
  id: 'bc',
  title: 'Boom chuck',

  timeSignatures: ['4/4'],

  linkedSettingsKeys: ['boomChuckBalance'],
  otherSettingsKeys: ['bassNotes', 'bassRuns', 'openVoicings', 'timing'],

  chordStyle: 'cowboy',

  swingCategory: 'none',
  defaultSwing: swingOptions['5:4'],

  presets: [
    {
      name: 'Basic boom chuck',
      settings: {
        'boomChuckBalance': {},
      },
    },
  ],

  measurePreprocessing(measures, settings, flags) {
    if (!flags?.noEmbellishments) {
      assignGuitarBassRunsAndLeadingNotes({ measures, settings, core: BoomChuckGuitarCore });
    }
  },

  processMeasure(measure, settings) {
    if (measure.endOfSong) {
      return boomChuckEndingMeasure(measure, settings);
    }

    return measure.cells.flatMap((cell) => boomChuckCell(cell, settings));
  },

  generateViz(settings) {
    const { boomPower, chuckPower } = settings.boomChuckBalance;
    return [
      { dir: 'd', biasY: 0, tail: 0, thickness: boomPower },
      null,
      { dir: 'd', biasY: 1, tail: 0.5 + chuckPower * 0.2, thickness: chuckPower },
      null,
      { dir: 'd', biasY: 0, tail: 0, thickness: boomPower },
      null,
      { dir: 'd', biasY: 1, tail: 0.5 + chuckPower * 0.2, thickness: chuckPower },
      null,
    ];
  },
};
