import { Meteor } from 'meteor/meteor';
import { ReactiveVar } from 'meteor/reactive-var';
import { Tracker } from 'meteor/tracker';
import { getLocalStorageSafe, setLocalStorageSafe } from '@/utilities/safe-local-storage';

const options: MuteSwitchBypassOptions[] = ['off', 'mild', 'aggressive'];
type MuteSwitchBypassOptions = 'off' | 'mild' | 'aggressive';

const setting = new ReactiveVar<MuteSwitchBypassOptions>(
  /iPhone|iPad/i.test(navigator.userAgent) ? 'aggressive' : 'off'
);

export const MuteSwitchBypassSetting = {
  set(value: MuteSwitchBypassOptions): void {
    if (options.includes(value)) {
      setting.set(value);
    }
  },
  get(): MuteSwitchBypassOptions {
    return setting.get();
  },
};

Meteor.startup(function initMuteSwitchBypassSetting() {
  const storedSetting = getLocalStorageSafe('strum_machine_mute_switch_bypassing');
  if (storedSetting && options.includes(storedSetting as MuteSwitchBypassOptions)) {
    setting.set(storedSetting as MuteSwitchBypassOptions);
  }
});

Meteor.startup(function storeMuteSwitchBypassSetting() {
  Tracker.autorun(() => {
    setting.get();
    setLocalStorageSafe('strum_machine_mute_switch_bypassing', setting.get());
  });
});
