import type { FeetTimingSetting } from '@/band/instruments/feet/settings/FeetTimingSetting';
import type { InstrumentCustomizationUI } from '@/band/instruments/InstrumentCustomizationUI';
import { timingRowStatusGetter } from '@/band/instruments/timingRowStatusGetter';
import InstrumentTimingPanel from '@/band/ui/panels/InstrumentTimingPanel.svelte';

export const feetCustomizationUI = {
  timing: <InstrumentCustomizationUI<FeetTimingSetting>>{
    panel: { component: InstrumentTimingPanel, props: { instId: 'feet' }, title: 'Timing Tweaks' },
    getRowStatus: timingRowStatusGetter('feet'),
  },
} as const;
