import * as Sentry from '@sentry/browser';
import { Meteor } from 'meteor/meteor';
import { SilentTagAudioPlayer } from '@/audio/engine/web/SilentTagAudioPlayer';
import { showAudioIssueNotice } from '@/ui/showAudioIssueNotice';

const desiredSampleRate = 44100;

const ContextClass =
  'webkitAudioContext' in window
    ? (window.webkitAudioContext as { new (): AudioContext })
    : window.AudioContext;

const isIOS =
  /iP(hone|ad|od)/i.test(navigator.userAgent) ||
  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

export default class WebAudioContextManager {
  __context: AudioContext;

  private constructor() {
    this.__context = new ContextClass();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    ((window as any).dev ||= {}).audioContext = this.context;
  }

  static async create(): Promise<WebAudioContextManager> {
    const manager = new WebAudioContextManager();
    await manager._applySafariSampleRateFix();
    manager._applySamsungA32Fix();
    isIOS && SilentTagAudioPlayer.init();
    return manager;
  }

  get context(): AudioContext {
    return this.__context;
  }

  __scratchBuffer?: AudioBuffer; // lazily created
  get scratchBuffer(): AudioBuffer {
    if (!this.__scratchBuffer) {
      // Scratch buffer for enabling iOS to dispose of web audio buffers correctly
      this.__scratchBuffer = this.context.createBuffer(1, 1, desiredSampleRate);
    }
    return this.__scratchBuffer;
  }

  async startEngine(): Promise<void> {
    Sentry.addBreadcrumb({
      category: 'audio',
      message: `gonnaPlay called (currentTime: ${this.context.currentTime})`,
      level: 'debug',
    });
    if ('mediaSession' in navigator) navigator.mediaSession.playbackState = 'playing';
    SilentTagAudioPlayer.setActivelyPlaying(true);
    await this._resumeContext();
    this._unlockWAA();
    void this._checkForStuckContext();
  }

  async stopEngine(): Promise<void> {
    Sentry.addBreadcrumb({
      category: 'audio',
      message: `gonnaStop called (currentTime: ${this.context.currentTime})`,
      level: 'debug',
    });
    if ('mediaSession' in navigator) navigator.mediaSession.playbackState = 'paused';
    SilentTagAudioPlayer.setActivelyPlaying(false);
    await this._suspendContext();
  }

  /**
   *    Private methods:
   */

  private async _applySafariSampleRateFix(): Promise<void> {
    // Bugs in the browser (especially Mobile Safari) can cause the sampleRate to change.
    // If that's the case, we use this hack to create a new AudioContext with the correct sampleRate.
    if (isIOS && this.context.sampleRate !== desiredSampleRate) {
      Sentry.addBreadcrumb({
        category: 'audio',
        message: `Fixing iOS sample rate (was ${this.context.sampleRate})`,
        level: 'info',
      });
      const dummy = this.context.createBufferSource();
      dummy.buffer = this.context.createBuffer(1, 1, desiredSampleRate);
      dummy.connect(this.context.destination);
      dummy.start(0);
      dummy.disconnect();
      await this.context.close().catch(() => {
        Sentry.captureMessage('Failed to close old context while fixing sample rate');
      }); // dispose old context
      this.__context = new ContextClass();
    }
  }

  private _applySamsungA32Fix(): void {
    // Samsung A32 has a bug where the AudioContext doesn't work right
    // This doesn't fix that, but it does make it so that the app can detect that the context is stuck
    if (navigator.userAgent.includes('SM-A32')) {
      Sentry.addBreadcrumb({
        category: 'audio',
        message: 'Patching Samsung A32 AudioContext suspend bug',
        level: 'info',
      });
      this.__context.suspend = () => Promise.resolve();
      // this.__context.resume = () => Promise.resolve();
    }
  }

  private _audioUnlocked = false;
  private _unlockWAA() {
    if (this._audioUnlocked || !this.context) {
      return;
    }

    // create and play a buffer
    const source = this.context.createBufferSource();
    source.buffer = this.scratchBuffer;
    source.connect(this.context.destination);

    // Setup a timeout to check that we are unlocked on the next event loop.
    source.onended = () => {
      Sentry.addBreadcrumb({
        category: 'audio',
        message: 'WAA context unlocked',
        level: 'info',
      });
      source.disconnect(0);

      // Update the unlocked state and prevent this check from happening again.
      this._audioUnlocked = true;
    };

    // Why is this called *again*? I don't know,
    // but it doesn't hurt so I'm leaving it... better safe than sorry.
    Sentry.addBreadcrumb({
      category: 'audio',
      message: 'Resuming context (second time)',
      level: 'debug',
    });
    this.context.resume().catch((err) => Sentry.captureException(err));

    Sentry.addBreadcrumb({
      category: 'audio',
      message: 'Playing buffer to unlock WAA audio',
      level: 'debug',
    });
    source.start(0);
  }

  async _checkForStuckContext(): Promise<void> {
    // Sometimes the context's currentTime won't be incrementing. Let's check for that.
    const sleep = (ms: number) => new Promise<void>((res) => setTimeout(() => res(), ms));
    await sleep(50);
    const time1 = this.context.currentTime;
    await sleep(500);
    if (time1 != this.context.currentTime) return;
    if (this.context.state != 'running') return;
    Sentry.addBreadcrumb({
      category: 'audio',
      message: `Restarting stuck context (stuck at ${time1})`,
      level: 'info',
    });
    await this.context
      .suspend()
      .then(this._resumeContext.bind(this))
      .catch(this._resumeContext.bind(this));
    await sleep(100);
    const time2 = this.context.currentTime;
    await sleep(2000);
    if (time2 != this.context.currentTime) return;
    if (this.context.state != 'running') return;
    const isApple =
      /iP(hone|ad|od)/i.test(navigator.userAgent) || navigator.vendor.startsWith('Apple');
    if (isApple) {
      Sentry.captureMessage('Audio context is stuck (iOS)');
      const isMac = /^Mac/i.test(navigator.platform) && !(navigator.maxTouchPoints > 1);
      showAudioIssueNotice({
        message: isMac
          ? `Unable to start the Web Audio engine due a bug in Safari/macOS. We're working on a native desktop app that will circumvent the issue, in case Apple takes a long time to fix their bug. In the meantime, reloading Strum Machine should get things working again, and you can use Chrome or Firefox to avoid this issue altogether.`
          : Meteor.isCordova
            ? `Unable to start the Web Audio engine due to a bug in iOS. We recommend enabling "Native Audio" in the Advanced Settings (from the top-left menu of Strum Machine's main screen) which should resolve this issue.`
            : `Unable to start the Web Audio engine due to a bug in iOS. We recommend <a href="https://apps.apple.com/us/app/strum-machine/id1509158553">downloading the Strum Machine app</a> from the App Store, which now uses a different audio-engine built especially for iOS that isn't affected by this bug.`,
        action: 'reload',
      });
    } else {
      Sentry.captureMessage('Audio context is stuck (not iOS)');
      showAudioIssueNotice({
        message:
          `Unable to start the Web Audio engine. ` +
          (navigator.userAgent.includes('SM-A32')
            ? `Currently, the only Android device with this issue is the Samsung A32, which has a bug around Web Audio API playback. Unfortunately there is no solution at this time. We've been attempting to build a native audio workaround but it is not working yet. Sorry! If you are unable to use Strum Machine due to this issue, please contact us to cancel your subscription and receive a prorated refund.`
            : `Reloading Strum Machine should resolve this issue for now. (If it doesn't, please restart your device.)`),
        action: 'reload',
      });
    }
  }

  /**
   * Automatically suspend the Web Audio AudioContext after no sound has played for 8 seconds.
   * This saves processing/energy and fixes various browser-specific bugs with audio getting stuck.
   */
  _suspending = false;
  _resumeAfterSuspend = false;
  private async _suspendContext(): Promise<void> {
    this._suspending = true;
    const handleSuspension = async () => {
      Sentry.addBreadcrumb({
        category: 'audio',
        message: 'AudioContext suspended',
        level: 'info',
      });
      this._suspending = false;
      if (this._resumeAfterSuspend) {
        this._resumeAfterSuspend = false;
        await this._resumeContext();
      }
    };
    await this.context.suspend().then(handleSuspension.bind(this), handleSuspension.bind(this));
  }

  private async _resumeContext(): Promise<void> {
    if (!this.context) return;
    if (this.context.state === 'closed') return;

    if (this._suspending) {
      this._resumeAfterSuspend = true;
      return;
    }

    if (this.context.state != 'running') {
      await this.context.resume();
      Sentry.addBreadcrumb({
        category: 'audio',
        message: 'AudioContext resumed',
        level: 'info',
      });
    }
  }
}
