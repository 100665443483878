import SidePanelSwiper from '@/ui/SidePanelSwiper';

Template.sidePanel.onCreated(() => {
  Template.instance().title = new ReactiveVar();
});

Template.sidePanel.onRendered(() => {
  const instance = Template.instance();
  Tracker.afterFlush(() => {
    instance.sidePanelSwiper = new SidePanelSwiper();
  });
});

Template.sidePanel.helpers({
  subTemplateData() {
    var instance = Template.instance();
    return {
      setTitle(value) {
        instance.title.set(value);
      },
    };
  },
  title() {
    return Template.instance().title.get();
  },
});

Template.sidePanel.events({
  'click .js-btnClose': function (event, instance) {
    Modal.hide('sidePanel');
    event.preventDefault();
  },
  'scroll .sidePanel .modal-body': _.throttle((event, instance) => {
    var showShadow = $(event.target).scrollTop() > 0;
    instance
      .$('.js-headerShadow')
      .velocity('stop')
      .velocity({ opacity: showShadow ? 1 : 0 }, { duration: 100 });
  }, 120),
});
