import type { Song } from '@/chart/Song';

export function autoSpeedupNewRep(song: Song): void {
  if (song.prefs.autoSpeedup.enabled()) {
    song.state.autoSpeedupState.incrementCurrentRep();
    if (song.state.autoSpeedupState.currentRep() >= song.prefs.autoSpeedup.reps()) {
      song.prefs.setTpm(
        Math.min(
          song.tpm() + song.prefs.autoSpeedup.tpmDelta(),
          song.prefs.autoSpeedup.maxTpm() || 1000
        )
      );
      song.state.autoSpeedupState.setCurrentRep(0);
    }
  }
}
