import * as Sentry from '@sentry/browser';
import { Channel } from '@/audio/engine/base/Channel';
import type { EQBand } from '@/audio/engine/base/EQ';
import { WebAudioEQ } from '@/audio/engine/web/WebAudioEQ';
import type { WebAudioPlayback } from '@/audio/engine/web/WebAudioPlayback';

export class WebAudioChannel extends Channel {
  readonly inputNode: AudioNode;
  readonly outputNode: GainNode;
  readonly gainNode: GainNode;
  readonly muteNode: GainNode;
  readonly attenuateNode: GainNode;
  readonly panNode?: StereoPannerNode;
  readonly eq: WebAudioEQ;

  // input/gain -> pan -> attenuate -> output/mute

  constructor(context: AudioContext, id: string, eqBands?: EQBand[]) {
    super(id);

    this.eq = new WebAudioEQ(context, eqBands);

    this.inputNode = this.eq.inputNode;
    this.gainNode = context.createGain();
    this.eq.outputNode.connect(this.gainNode);
    this.outputNode = this.muteNode = context.createGain();
    this.attenuateNode = context.createGain();
    this.attenuateNode.connect(this.muteNode);
    try {
      this.panNode = context.createStereoPanner();
      this.gainNode.connect(this.panNode);
      this.panNode.connect(this.attenuateNode);
    } catch (err) {
      // StereoPannerNode is not implemented in Safari < 14
      this.gainNode.connect(this.attenuateNode);
    }

    // Needed to fix a weird bug when lots of samples being played
    [this.gainNode, this.muteNode, this.panNode].forEach((node) => {
      if (!node) return;
      node.channelCount = 2;
      node.channelCountMode = 'explicit';
      node.channelInterpretation = 'speakers';
    });
  }

  private get currentTime(): number {
    const currentTime = this.outputNode.context.currentTime;
    if (typeof currentTime === 'number' && isFinite(currentTime)) return currentTime;
    Sentry.captureMessage(`currentTime is ${currentTime}`);
    return 0;
  }

  set volume(val: number) {
    // Do a quick fade to prevent popping
    this.gainNode.gain.cancelScheduledValues(this.currentTime);
    this.gainNode.gain.setTargetAtTime(val, this.currentTime, 0.05);
    this.gainNode.gain.setValueAtTime(val, this.currentTime + 0.4);
  }

  get volume(): number {
    return this.gainNode.gain.value;
  }

  set muted(val: boolean) {
    // Do a quick fade to prevent popping
    this.muteNode.gain.cancelScheduledValues(this.currentTime);
    this.muteNode.gain.setTargetAtTime(val ? 0 : 1, this.currentTime, 0.06);
    this.muteNode.gain.setValueAtTime(val ? 0 : 1, this.currentTime + 0.4);
  }

  get muted(): boolean {
    return this.muteNode.gain.value == 0;
  }

  get attenuate(): boolean {
    return this.attenuateNode.gain.value < 1;
  }

  set attentuate(val: boolean) {
    // Do a quick fade to prevent popping
    this.attenuateNode.gain.cancelScheduledValues(this.currentTime);
    this.attenuateNode.gain.setTargetAtTime(val ? 0.15 : 1, this.currentTime, 0.12);
    this.attenuateNode.gain.setValueAtTime(val ? 0.15 : 1, this.currentTime + 0.8);
  }

  set pan(val: number) {
    if (!this.panNode) return;
    val = Math.min(1, Math.max(-1, val));
    // Do a quick fade to prevent popping
    this.panNode.pan.cancelScheduledValues(this.currentTime);
    this.panNode.pan.setTargetAtTime(val, this.currentTime, 0.05);
    this.panNode.pan.setValueAtTime(val, this.currentTime + 0.4);
  }

  get pan(): number {
    return this.panNode?.pan.value || 0;
  }

  canPan(): boolean {
    return !!this.panNode;
  }

  protected _linkPlaybackToChannel(playback: WebAudioPlayback): void {
    void playback.outputNodePromise.then((node) => node.connect(this.inputNode));
  }
}
