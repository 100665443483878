import AudioManager from '@/audio/engine/AudioManager';
import { decodeMP3 } from '@/audio/engine/web/decodeMP3';
import { nativeDecode } from '@/audio/engine/web/nativeDecode';
import AudioPackageLoader from '@/audio/samples/AudioPackageLoader';

const resolverMap = new Map<string, Promise<AudioBuffer>>();

export default class WebAudioBufferLoader {
  static getBufferForSample(id: string): Promise<AudioBuffer> {
    const existingPromise = resolverMap.get(id);
    const promise =
      existingPromise ??
      AudioPackageLoader.getFileForSample(id).then(({ buffer, format }) => {
        return format == 'mp3'
          ? decodeMP3(AudioManager.webAudioContext, buffer)
          : nativeDecode(AudioManager.webAudioContext, buffer);
      });
    if (!existingPromise) resolverMap.set(id, promise);
    return promise;
  }
}
