import { assignGuitarBassRunsAndLeadingNotes } from '@/band/instruments/guitar/assignGuitarBassRunsAndLeadingNotes';
import { boomChuckWaltzCell } from '@/band/instruments/guitar/blocks/boomChuckWaltzCell';
import { strumDown } from '@/band/instruments/guitar/GuitarActions';
import type { GuitarCore } from '@/band/instruments/guitar/GuitarCore';
import { swingOptions } from '@/band/swingOptions';
import { lerp } from '@/utilities/lerp';

export const BoomChuckWaltzGuitarCore: GuitarCore = {
  id: 'bcw',
  title: 'Boom chuck',

  timeSignatures: ['3/4'],

  linkedSettingsKeys: ['boomChuckWaltzBalance'],
  otherSettingsKeys: ['bassNotes', 'bassRuns', 'openVoicings', 'timing'],

  chordStyle: 'cowboy',

  swingCategory: 'none',
  defaultSwing: swingOptions['5:4'],

  presets: [
    {
      name: 'Basic boom chuck',
      settings: {
        'boomChuckWaltzBalance': {},
      },
    },
  ],

  measurePreprocessing(measures, settings, flags) {
    if (!flags?.noEmbellishments) {
      assignGuitarBassRunsAndLeadingNotes({ measures, settings, core: BoomChuckWaltzGuitarCore });
    }
  },

  processMeasure(measure, settings) {
    const intents = measure.cells.flatMap((cell) => boomChuckWaltzCell(cell, settings));
    if (measure.endOfSong) {
      const { boomPower, chuck2Power } = settings.boomChuckWaltzBalance;
      return [
        ...intents.slice(0, (measure.cells.length - 1) * 6),
        strumDown('root', {
          spread: lerp(3.5, 5, Math.max(boomPower, chuck2Power)),
          db: lerp(-4, 2, Math.max(boomPower, chuck2Power)),
        }),
      ];
    }
    return intents;
  },

  generateViz(settings) {
    const { boomPower, chuck1Power, chuck2Power } = settings.boomChuckWaltzBalance;
    return [
      { dir: 'd', biasY: 0, tail: 0, thickness: boomPower },
      null,
      { dir: 'd', biasY: 1, tail: 0.5 + chuck1Power * 0.2, thickness: chuck1Power },
      null,
      { dir: 'd', biasY: 1, tail: 0.5 + chuck2Power * 0.25, thickness: chuck2Power },
      null,
    ];
  },
};
