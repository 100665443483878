export default [
  {
    '_id': '7xk4MNLAA6Aum8ZTF',
    'name': 'Ashokan Farewell',
  },
  {
    '_id': 'k8xGR4Bvx87KBt75x',
    'name': 'Beaumont Rag',
  },
  {
    '_id': 'BztZG8cARpATvXpSg',
    'name': 'Cripple Creek',
  },
  {
    '_id': 'jcnt3lsg2Y9baTqT',
    'name': "I'll Fly Away",
  },
  {
    '_id': 'mygd3lhfgY9baTqT',
    'name': "Soldier's Joy",
  },
  {
    '_id': 'Zfy6EKR7XtPGWLkPj',
    'name': 'Wayfaring Stranger',
  },
  {
    '_id': 'J44KsxPJ7HruDFNcp',
    'name': 'Will the Circle Be Unbroken',
  },
] as { _id: string; name: string }[];
