<script lang="ts">
  import PanelOpenerChevron from '@/band/ui/components/PanelOpenerChevron.svelte';
  import GuitarOpenVoicingsAllKeysPanel from '@/band/ui/panels/GuitarOpenVoicingsAllKeysPanel.svelte';
  import GuitarOpenVoicingsMajorKeySettings from '@/band/ui/panels/GuitarOpenVoicingsMajorKeySettings.svelte';
  import PanelModalLayout from '@/band/ui/panels/PanelModalLayout.svelte';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import { Chord } from '@/music/Chord';
  import { MultiPanelModalController } from '@/ui/settings/MultiPanelModalController.svelte';

  const modalController = MultiPanelModalController.current();
  const bandMenu = getBandMenuState();

  let currentKey: Chord['key'] | undefined = $state();
  trackerEffect(() => (currentKey = $bandMenu.band.currentKey()));

  let minor = $state(false);
  trackerEffect(() => (minor = !!currentKey && new Chord(currentKey).minor));

  let setting = $derived($bandMenu.guitarSettings.openVoicings);
</script>

<PanelModalLayout>
  {#snippet header()}
    <header class:panelHeader={!minor}>
      {#if !minor}
        Here you can choose which root chord shape, or&nbsp;<span class="font-medium">position</span
        >,
        <span class="whitespace-nowrap">to use for</span>
        the key of {currentKey}.
        <!-- <a href="#" class="font-medium">Learn more</a>. -->
      {/if}
    </header>
  {/snippet}

  {#snippet main()}
    <div>
      <div class="pb-4 pt-5">
        {#if currentKey}
          {#if !minor}
            <GuitarOpenVoicingsMajorKeySettings {currentKey} {setting} />
          {:else}
            <div class="text-balance px-9 text-center text-gray-500">
              These settings are not yet available for songs in minor keys.
            </div>
          {/if}
        {/if}
      </div>

      <div class="border-t border-panelWellBorder"></div>
      <button
        class="colors-panelOpener relative flex w-full items-center justify-center rounded-b-lg py-3 pl-4 pr-0 text-left hover:text-primary-700"
        onclick={() =>
          modalController.zoomInto({
            component: GuitarOpenVoicingsAllKeysPanel,
            title: 'Voicings in Other Keys',
          })}
      >
        <div>
          {#if !minor}
            How does this apply to other keys?
          {:else}
            View configuration for major keys
          {/if}
        </div>
        <div class="relative w-8">
          <PanelOpenerChevron direction="right" />
        </div>
      </button>
    </div>
  {/snippet}
</PanelModalLayout>
