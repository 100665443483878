import { Chord } from '@/music/Chord';
import { majorKeys, minorKeys } from '@/music/music-knowledge';

Template.keyChooserButtonGrid.onCreated(() => {
  const instance = Template.instance();
  instance.minor = new ReactiveVar(false);
  instance.autorun(() => {
    const minor = Template.currentData().minor;
    instance.minor.set(
      typeof minor == 'boolean' ? minor : Template.currentData().currentKey?.includes('m')
    );
  });
});

Template.keyChooserButtonGrid.helpers({
  keyChoices() {
    var minor = Template.instance().minor.get();
    return minor ? minorKeys : majorKeys;
  },
  tonicOf(key) {
    return new Chord(key).rootNote;
  },
  ariaLabelForKey(key) {
    return new Chord(key).toAria();
  },
  keySelected(key) {
    return key == Template.currentData().currentKey ? 'selected' : '';
  },
  minor() {
    return Template.instance().minor.get();
  },
});

Template.keyChooserButtonGrid.events({
  'click .js-chooseKey': function (event, instance) {
    event.preventDefault();
    var newKey = $(event.currentTarget).data('key');
    instance.data.setKeyFunc(newKey);
  },
});
