export class WebAudioClicker {
  readonly oscillator: OscillatorNode;
  readonly gainNode: GainNode;
  constructor(context: AudioContext) {
    this.oscillator = context.createOscillator();
    this.gainNode = context.createGain();
    this.oscillator.connect(this.gainNode);
    this.oscillator.frequency.value = 660;
    this.gainNode.connect(context.destination);
    this.gainNode.gain.value = 0;
    this.oscillator.start();
  }

  click(atTime: number) {
    this.gainNode.gain.setValueAtTime(0.5, atTime - 0.001);
    this.gainNode.gain.setTargetAtTime(0, atTime + 0.001, 0.005);
  }
}
