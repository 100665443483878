<script lang="ts">
  import { onMount } from 'svelte';
  import { slide } from 'svelte/transition';
  import EditorClipboardButton from '@/editor/ui/EditorClipboardButton.svelte';
  import EditorHAYEButton from '@/editor/ui/EditorHAYEButton.svelte';
  import EditorMenuIsland from '@/editor/ui/EditorMenuIsland.svelte';
  import EditorPreviewPlayButton from '@/editor/ui/EditorPreviewPlayButton.svelte';
  import EditorShortcutsReference from '@/editor/ui/EditorShortcutsReference.svelte';
  import type { EditorMenuId } from '@/editor/ui/EditorToolsState';
  import { desiredMenu, EditorToolsState } from '@/editor/ui/EditorToolsState';
  import EditorUndoRedoButton from '@/editor/ui/EditorUndoRedoButton.svelte';
  import BpmAdjuster from '@/ui/BPMAdjuster.svelte';
  import Icon from '@/ui/icons/Icon.svelte';
  import { bounded } from '@/utilities/bounded';
  import wait from '@/utilities/wait';

  // This component is the root of the menu, so it creates the context.
  const editorMenu = EditorToolsState.create();

  const menus: {
    label: string;
    menuIds: EditorMenuId[];
    portalContainer?: HTMLElement;
    buttonElement?: HTMLElement;
  }[] = $state([
    { label: 'Chords', menuIds: ['chords', 'bass'] },
    { label: 'Effects', menuIds: ['effects'] },
    // { label: 'Band', menuIds: ['band'] },
    { label: 'Chart', menuIds: ['chart'] },
  ]);

  let menuComponent: HTMLElement;
  let limboContainer: HTMLElement | undefined = $state();

  function menuCenterForElement(element: HTMLElement | undefined) {
    if (!element) return 0;
    const windowWidth = window.innerWidth;
    const rect = element.getBoundingClientRect();
    const elementCenter = rect.left + rect.width / 2;
    const menuId = $editorMenu.activeMenu;
    const menuWidth = typeof menuId == 'string' ? editorMenu.menuWidths[menuId] : 0;
    const padding = window.innerWidth > 350 ? 8 : 2;
    const boundedCenter = bounded(
      elementCenter,
      menuWidth / 2 + padding,
      windowWidth - menuWidth / 2 - padding
    );
    const menuCenter = boundedCenter - windowWidth / 2;
    return menuCenter;
  }

  let container: HTMLElement;
  onMount(() => {
    const resizeObserver = new ResizeObserver(() => {
      const activeButton = menus.find((m) =>
        m.menuIds.some((m) => m == $desiredMenu)
      )?.buttonElement;
      if (activeButton) editorMenu.setMenuCenter(menuCenterForElement(activeButton));
    });
    resizeObserver.observe(container);
    return () => resizeObserver.unobserve(container);
  });

  $effect(() => {
    const menu = menus.find((m) => $desiredMenu && m.menuIds.includes($desiredMenu));
    const targetContainer = menu?.portalContainer ?? limboContainer;
    if (targetContainer && !targetContainer.hasChildNodes()) {
      targetContainer.appendChild(menuComponent);
    }
    void wait(0).then(() => {
      editorMenu._setActiveMenu($desiredMenu);
      editorMenu.setMenuCenter(menuCenterForElement(menu?.buttonElement));
    });
  });
</script>

<div class="EditorToolbar" role="toolbar" aria-label="song editing" bind:this={container}>
  <div style="display: contents;" bind:this={limboContainer}>
    <div style="display: contents;" bind:this={menuComponent}>
      <EditorMenuIsland />
    </div>
  </div>
  <div class="EditorToolbar-grid">
    <div class="EditorToolbar__clipboardButtons">
      <EditorClipboardButton command="cut" />
      <div class="__separator h-7 border-l border-editToolbarSeparator"></div>
      <EditorClipboardButton command="copy" />
      <div class="__separator h-7 border-l border-editToolbarSeparator"></div>
      <EditorClipboardButton command="paste" />
    </div>
    <div class="EditorToolbar__undoRedo">
      <EditorUndoRedoButton command="undo" />
      <div class="__separator h-7 border-l border-editToolbarSeparator"></div>
      <EditorUndoRedoButton command="redo" />
    </div>
    <div class="EditorToolbar__menuOpeners">
      <div>
        <span></span>
        {#each menus as menu (menu.label)}
          {@const active = menu.menuIds.some((m) => m == $editorMenu.activeMenu)}
          <div class="EditorToolbar__menuOpener">
            <button
              class="colors-button rounded-md font-medium"
              aria-pressed={active}
              bind:this={menu.buttonElement}
              onclick={() => {
                editorMenu.setMenu(active ? undefined : menu.menuIds[0]);
              }}
            >
              {menu.label}
              <span class="inline-block transition-all" class:rotate-180={active}>
                <Icon id="caret-up" size={9} verticalShift={2} />
              </span>
            </button>
            <div style="display: contents;" bind:this={menu.portalContainer}></div>
          </div>
        {/each}
        <span></span>
      </div>
    </div>
    <div class="EditorToolbar__shortcutsAndSettings">
      <div>
        <div class="spacer"></div>
        <div class="shortcuts-cell">
          <button
            class="colors-button js-global-editorShortcutsButton group rounded-md font-medium"
            aria-pressed={$editorMenu.showShortcuts}
            onclick={() => editorMenu.toggleShortcuts()}
          >
            <div class="grid items-center justify-center">
              <div
                class="col-start-1 row-start-1 flex size-4 items-center justify-center transition will-change-transform group-aria-pressed:group-hover:opacity-0"
              >
                <Icon id="keyboard-icon" size={16} />
              </div>
              <div
                class="col-start-1 row-start-1 flex size-4 items-center justify-center rounded-full bg-current opacity-0 transition will-change-transform group-aria-pressed:group-hover:opacity-100"
              >
                <Icon id="close" size={9.5} color="#fff" />
              </div>
            </div>

            Shortcuts
          </button>
        </div>
        <span class="__separator h-7 border-l border-editToolbarSeparator"></span>
        <div class="settings-cell">
          <button
            class="colors-button rounded-md font-medium"
            onclick={() => Modal.show('editorSettingsModal')}
          >
            <Icon id="gear-icon" size={14} />
            <span>Settings</span>
          </button>
        </div>
        <div class="spacer"></div>
      </div>
    </div>
    <div class="EditorToolbar__bpm">
      <BpmAdjuster />
    </div>
    <div class="EditorToolbar__playback">
      <EditorHAYEButton />
      <EditorPreviewPlayButton />
    </div>
  </div>

  {#if $editorMenu.showShortcuts}
    <div class="EditorToolbar-shortcutsPanel">
      <div
        class="w-full border-t border-editToolbarSeparator"
        transition:slide={{ duration: 250 }}
      ></div>
      <div transition:slide={{ duration: 250 }}>
        <EditorShortcutsReference on:close={() => editorMenu.toggleShortcuts()} />
      </div>
    </div>
  {/if}
</div>

<style>
  .EditorToolbar {
    background: var(--editToolbarBg);
    z-index: 500;
    padding: 0 var(--safeAreaLeft) var(--safeAreaBottom) var(--safeAreaRight);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
  }

  @media print {
    .EditorToolbar {
      display: none;
    }
  }
  :global(.full-screen-help) .EditorToolbar {
    display: none;
  }

  .EditorToolbar-grid {
    display: grid;
    width: 100%;

    > * {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-color: var(--editToolbarSeparator);
    }

    @media (width < 28rem) {
      grid-template-columns: auto 1fr 9.0625rem;
      grid-template:
        'openers openers openers openers' 1fr
        'shortcuts-and-settings clipboard bpm playback' 1fr
        / auto auto auto 1fr;
    }

    @media (28rem <= width < 35rem) {
      grid-template-columns: auto 1fr 9.0625rem;
      grid-template:
        'openers openers openers bpm' 1fr
        'clipboard undo-redo shortcuts-and-settings playback' 1fr
        / auto auto 1fr 9.0625rem;
    }
    @media (35rem <= width < 38rem) {
      grid-template:
        'clipboard openers bpm' 1fr
        'undo-redo shortcuts-and-settings playback' 1fr
        / auto 1fr 9.0625rem;
    }
    @media (width >= 38rem) {
      grid-template:
        'clipboard openers bpm' 1fr
        'undo-redo shortcuts-and-settings playback' 1fr
        / 9.0625rem 1fr 9.0625rem;
      max-width: 801px;
      margin-right: 0.5px;
    }
  }

  .EditorToolbar__menuOpeners button,
  .EditorToolbar__shortcutsAndSettings button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.375rem;
  }

  .EditorToolbar__clipboardButtons {
    grid-area: clipboard;
  }

  .EditorToolbar__undoRedo {
    grid-area: undo-redo;
    @media (width < 28rem) {
      display: none;
    }
  }

  .EditorToolbar__clipboardButtons,
  .EditorToolbar__undoRedo {
    justify-content: space-evenly;
    :global(button) {
      flex-grow: 1;
      height: 100%;
    }
    @media (width >= 35rem) {
      border-width: 0 1px;
      padding: 0.375rem;
    }
    @media (width < 35rem) {
      border-width: 0 1px 0 0;
      padding: 0.125rem;
    }
    @media (width < 28rem) {
      border-width: 0 0 0 1px;
    }
    @media (width < 38rem) {
      .__separator {
        display: none;
      }
    }
    @media (width >= 38rem) {
      gap: 0.375rem;
    }
  }

  .EditorToolbar__menuOpeners {
    grid-area: openers;
    border-width: 0 0 1px;
    > div {
      margin: 0 auto;
      width: 100%;
      max-width: 30rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div {
        padding: 0.375rem 0;
        @media (width < 35rem) {
          padding: 0.25rem;
        }
      }
    }
    button {
      padding: 0.25rem 0.625rem;
      min-width: 5.75rem;
      @media (width < 30rem) {
        min-width: auto;
      }
      @media (width >= 41rem) {
        min-width: 7rem;
      }
    }
  }
  .EditorToolbar__shortcutsAndSettings {
    grid-area: shortcuts-and-settings;
    > div {
      margin: 0 auto;
      width: 100%;
      max-width: 30rem;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      > div {
        padding: 0.375rem;
        @media (width < 35rem) {
          padding: 0.25rem;
        }
      }
    }
    button {
      padding: 0.25rem 0.625rem;
      @media (width < 35rem) {
        font-weight: normal;
      }
      @media (width >= 35rem) {
        padding: 0.25rem 1rem;
      }
      @media (width >= 41rem) {
        padding: 0.25rem 1.25rem;
      }
    }
    .__separator {
      display: none;
    }
    /*
    @media (32rem < width < 35rem) {
      :global(svg) {
        display: none;
      }
    }
    */
    @media (width < 35rem) {
      .spacer {
        display: none;
      }
      .__separator {
        display: block;
      }
    }
    @media (width < 35rem) {
      .shortcuts-cell,
      .__separator {
        display: none;
      }
    }
    button {
      @media (width < 28rem) {
        padding: 0.625rem;
        :global(svg) {
          display: block;
          scale: 1.25;
        }
        :global(span) {
          display: none;
        }
      }
    }
  }
  .EditorToolbar__bpm {
    grid-area: bpm;
    border-width: 0 1px;
    padding: 0.375rem 0.125rem 0.175rem;
    @media (width < 35rem) {
      border-width: 0 0 0 1px;
      padding: 0.25rem 0.25rem 0.125rem;
    }
  }
  .EditorToolbar__playback {
    grid-area: playback;
    border-width: 0 1px;
    align-items: stretch;
    display: flex;
    gap: 0.5rem;
    padding: 0.25rem 0.75rem 0.5rem 0.375rem;
    @media (width < 35rem) {
      padding: 0.25rem 0.75rem 0.25rem 0.25rem;
    }
    @media (width < 28rem) {
      padding: 0.25rem 0.375rem;
    }
  }

  @media (width < 35rem) {
    .EditorToolbar-shortcutsPanel {
      display: none;
    }
  }

  /* @media (max-width: 400px) {
    .EditorToolbar__menuOpeners {
    }
  } */

  /* @media (max-width: 600px) {
    .EditorToolbar__menuOpeners {
      > div {
        justify-content: stretch;
      }
      > div > div {
        flex-grow: 1;
        border-left: solid 1px var(--muted-100);
      }
    }
  } */

  /* .EditorToolbar-grid {
    > * {
      box-shadow: inset 0 0 2px rgba(205, 0, 0, 0.5);
      > * {
        box-shadow: inset 0 0 2px rgba(0, 160, 0, 0.7);
        > * {
          box-shadow: inset 0 0 2px rgba(0, 0, 255, 0.4);
        }
      }
    }
  } */

  :global(.EditorToolbar__bpm .scalarInputWithTrimmers-center) {
    height: 33px;
    width: 4.875rem;
    padding: 0;
    margin: 0 -0.125rem;
  }
  :global(.EditorToolbar__bpm .scalarInputWithTrimmers-centerTextBox.btn) {
    height: 100%;
  }
  :global(.EditorToolbar__bpm .scalarInputWithTrimmers-trimmer.btn) {
    padding: 0;
    height: 30px;
    width: 30px;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: -0.25rem;
      left: -0.25rem;
      bottom: -0.25rem;
      right: -0.125rem;
      border-radius: 0.75rem;
    }
    z-index: 2;
  }
  @media (max-width: 23rem) {
    /* below this point, bpm trimmers are hidden */
    :global(.EditorToolbar__bpm .scalarInputWithTrimmers-center.not-editing) {
      position: relative;
      left: 0.45rem;
      width: 5.875rem;
      margin: 0 -1.125rem 0 -0.125rem;
    }
    :global(.EditorToolbar__bpm .scalarInputWithTrimmers-trimmer:first-child) {
      display: none;
    }
    :global(.EditorToolbar__bpm .scalarInputWithTrimmers-trimmer:last-child) {
      visibility: hidden;
    }
  }
  @media (max-width: 21rem) {
    /* below this point, checkmark button is hidden */
    :global(.EditorToolbar__bpm .scalarInputWithTrimmers-center.not-editing) {
      left: 0;
      width: 4.875rem;
      margin: 0 -0.125rem;
    }
    :global(.EditorToolbar__bpm .scalarInputWithTrimmers-trimmer) {
      display: none;
    }
    :global(.EditorToolbar__bpm .scalarInputWithTrimmers-okButton) {
      display: none;
    }
  }
</style>
