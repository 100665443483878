<script lang="ts">
  import {
    generateDampeningLines,
    generatePathForCross,
    generatePathForHead,
    generatePathForTail,
  } from '@/band/ui/components/rhythm-viz-paths';
  import { vizDimensionsFromCommand } from '@/band/ui/components/vizDimensionsFromCommand';

  interface Props {
    // export let variation: undefined | 'voiced-chop' | 'dry-chop' = undefined;
    instId?: InstrumentId | undefined;
    value: number;
    firstDownstroke: RhythmPatternVisualizationCommand | null | undefined;
    upstroke: RhythmPatternVisualizationCommand | null | undefined;
    secondDownstroke: RhythmPatternVisualizationCommand | null | undefined;
  }

  let { instId = undefined, value, firstDownstroke, upstroke, secondDownstroke }: Props = $props();

  const strokeParams: RhythmPatternVisualizationCommand[] = [
    {
      ...firstDownstroke,
      dir: 'd',
      thickness: 0.7,
      tail: Math.max(firstDownstroke?.tail ?? 0.6, 0.2),
      biasY: 0.4,
    },
    {
      ...upstroke,
      dir: 'u',
      thickness: 0.6,
      tail: Math.max(upstroke?.tail ?? 0.4, 0.2),
      biasY: 0.3,
    },
    {
      ...secondDownstroke,
      dir: 'd',
      thickness: 0.8,
      tail: Math.max(secondDownstroke?.tail ?? 0.8, 0.2),
      biasY: 0.6,
    },
  ];

  const strokes = strokeParams.map((params) => ({
    params,
    dimensions: vizDimensionsFromCommand(params, {
      topYPadded: 0,
      paddedHeight: 38,
    }),
  }));

  const xPos = 8;
</script>

<div class="relative mx-auto h-[84px] w-[220px]">
  {#each strokes as { params, dimensions }, i (i)}
    <div
      class="absolute inset-y-0 w-[32px] -translate-x-1/2"
      style="left: {[0, 50 + 25 * (value > 0.1 ? value : 0), 100][i]}%;"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 40"
        class="absolute left-1/2 h-full w-[30px] -translate-x-1/2"
      >
        <path
          fill="url(#grad_tail)"
          stroke="url(#grad_tail)"
          stroke-width="1px"
          style="opacity: {params.tailOpacity ?? 1};"
          d={`M${xPos},${dimensions.tipY - dimensions.headY}` +
            ((params.sustain ?? 1) < 1
              ? generateDampeningLines({
                  direction: i % 2 == 0 ? 'd' : 'u',
                  stemWidth: dimensions.stemWidth,
                  height: dimensions.tailHeight,
                  chick: params.chick,
                })
              : generatePathForTail({
                  direction: i % 2 == 0 ? 'd' : 'u',
                  stemWidth: dimensions.stemWidth,
                  height: dimensions.tailHeight,
                }))}
        />

        <path
          fill={(params.sustain ?? 1) < 1 && !params.chick ? 'transparent' : 'url(#grad_head)'}
          stroke="url(#grad_head)"
          stroke-width="1px"
          d={`M${xPos},${dimensions.tipY}` +
            (params.chick
              ? generatePathForCross({
                  size: dimensions.headWidth * 0.9,
                })
              : generatePathForHead({
                  direction: i % 2 == 0 ? 'd' : 'u',
                  headWidth: dimensions.headWidth,
                }))}
        />

        {#if (params.sustain ?? 1) > 0 && !params.chick}
          <path
            fill="url(#grad_tail)"
            stroke="url(#grad_tail)"
            stroke-width="1px"
            style="opacity: {(params.tailOpacity ?? 1) * (params.sustain ?? 1)};"
            d={`M${xPos},${dimensions.tipY - dimensions.headY}` +
              generatePathForTail({
                direction: i % 2 == 0 ? 'd' : 'u',
                stemWidth: dimensions.stemWidth,
                height: dimensions.tailHeight,
              })}
          />
          <path
            fill="url(#grad_head)"
            stroke="url(#grad_head)"
            stroke-width="1px"
            opacity={params.sustain}
            d={`M${xPos},${dimensions.tipY}` +
              generatePathForHead({
                direction: i % 2 == 0 ? 'd' : 'u',
                headWidth: dimensions.headWidth,
              })}
          />
        {/if}
      </svg>
      <div
        class="label-colors absolute left-1/2 -translate-x-1/2 text-center text-sm leading-4
               {['-top-2', 'bottom-2', '-top-2'][i]}"
      >
        {#if i == 2 && instId == 'mandolin'}
          Chop
        {:else if i % 2 == 0}
          Down<br />stroke
        {:else}
          Up<br />stroke
        {/if}
      </div>
    </div>
    {#if i == 0 && value > 0.1}
      <div class="absolute inset-y-0 w-0 -translate-x-1/2" style="left: 50%">
        <svg xmlns="http://www.w3.org/2000/svg">
          <path d="M0,3 V60" class="ghost-line" />
        </svg>
      </div>
    {/if}
  {/each}
</div>

<style>
  .label-colors {
    color: hsl(0, 10%, 55%);
    background-color: hsla(206deg 100% 100% / 85%);
    box-shadow: 0 0 5px 5px hsla(206deg 100% 100% / 85%);
    text-shadow: 0 0 2px white;
  }

  .ghost-line {
    fill: none;
    stroke: hsl(0, 10%, 75%);
    stroke-width: 1.5px;
    stroke-dasharray: 2 4;
    stroke-linecap: round;
    stroke-miterlimit: 0;
  }
</style>
