<script lang="ts">
  import { Meteor } from 'meteor/meteor';
  import type { PresetEditorModes } from '@/band/Band';
  import PanelSettingSection from '@/band/ui/components/PanelSettingSection.svelte';
  import PresetDeleteModeHeader from '@/band/ui/components/PresetDeleteModeHeader.svelte';
  import PresetDisplayHeader from '@/band/ui/components/PresetDisplayHeader.svelte';
  import PresetEditModeMainHeader from '@/band/ui/components/PresetEditModeMainHeader.svelte';
  import BandHelpPanel from '@/band/ui/panels/BandHelpPanel.svelte';
  import BandMenuMixAlerts from '@/band/ui/panels/BandMenuMixAlerts.svelte';
  import InstrumentLineup from '@/band/ui/panels/InstrumentLineup.svelte';
  import InstrumentLineupBottomCap from '@/band/ui/panels/InstrumentLineupBottomCap.svelte';
  import PanelModalLayout from '@/band/ui/panels/PanelModalLayout.svelte';
  import PresetCategoriesList from '@/band/ui/presets/PresetCategoriesList.svelte';
  import PresetRadioRowForDefaultBand from '@/band/ui/presets/PresetRadioRowForDefaultBand.svelte';
  import PresetRadioRowForSharedSettings from '@/band/ui/presets/PresetRadioRowForSharedSettings.svelte';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';
  import { Crnt } from '@/Crnt';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import HintArrow from '@/ui/HintArrow.svelte';
  import HintText from '@/ui/HintText.svelte';
  import { MultiPanelModalController } from '@/ui/settings/MultiPanelModalController.svelte';
  import { slideFade } from '@/ui/svelte-transitions';
  import { UserProfile } from '@/user/UserProfile';

  const modalController = MultiPanelModalController.current();

  const bandMenu = getBandMenuState();

  let timeSignature = $derived($bandMenu.timeSignature);

  let editingInstrumentLineup = $derived(modalController.subview == 'instruments');

  let presetMode: PresetEditorModes = $state();
  trackerEffect(() => (presetMode = $bandMenu.band.presetMode()));

  let medleyBelongsToAnotherUser = $state(false);
  trackerEffect(() => {
    const medleyUserId = Crnt.medley()?.userId();
    medleyBelongsToAnotherUser = !!medleyUserId && medleyUserId != Meteor.userId();
  });

  const showPresetsHint =
    UserProfile.notMilestone('PRESETS_CATEGORY_OPEN') &&
    !($bandMenu.band.preset() && !$bandMenu.band.preset()?.default);

  let band = $derived($bandMenu.band);
  let canRestorePrevious = $state(false);
  trackerEffect(
    () =>
      (canRestorePrevious =
        !!(band.previousPreset() || band.previousCustomSettings()) && !band.presetMode())
  );

  let showHistoryHint = $derived(
    canRestorePrevious &&
      UserProfile.hasMilestone('PRESETS_CATEGORY_OPEN') &&
      UserProfile.notMilestone('BAND_HISTORY_SWAP')
  );
</script>

<PanelModalLayout
  showDoneButton={editingInstrumentLineup}
  showAltHeader={editingInstrumentLineup}
  headerBg={!modalController.subview
    ? presetMode == 'delete'
      ? 'red'
      : presetMode
        ? 'yellow'
        : 'panel'
    : 'white'}
>
  {#snippet header()}
    <header>
      {#if presetMode == 'edit'}
        <div transition:slideFade>
          <PresetEditModeMainHeader />
        </div>
      {/if}
      {#if presetMode == 'delete'}
        <div transition:slideFade>
          <PresetDeleteModeHeader />
        </div>
      {/if}
    </header>
  {/snippet}

  {#snippet altHeader()}
    <header>
      <div class="px-5 py-4 text-center">
        <h1 class="pb-1 text-xl font-medium text-gray-800">Add/Remove Instruments</h1>
        <div class="pb-0.5 text-sm text-gray-500">
          {#if presetMode}
            The selected instruments will be used in the band preset you are editing.
          {:else}
            Your preferences will be saved with
            <span class="whitespace-nowrap">the current song</span>.
          {/if}
        </div>
      </div>
    </header>
  {/snippet}

  {#snippet main()}
    <div class="relative">
      {#if medleyBelongsToAnotherUser}
        <div class="border-b border-panelWellBorder bg-yellow-50 px-6 py-4">
          <strong>Note:</strong> Because this medley belongs to someone else, changes to these
          settings will be <b class="font-medium">temporarily applied</b> but not be saved to the database.
        </div>
      {/if}
      {#if !modalController.subview}
        <div class="relative z-30 transition-all" transition:slideFade>
          <!--
          {#if !presetMode}
            <div
              class="mb-2 pt-3 text-sm font-medium text-gray-500 transition-all px-7"
              transition:slideFade
            >
              Current band settings
              <HelpCircleTooltip
                text="Click any instrument to make changes to the band for this song. You can optionally save your custom band as a preset for use in other songs. Double-click this help icon to learn more."
                on:dblclick={() =>
                  modalController.zoomInto({ component: BandHelpPanel, title: 'Help' })}
              />
            </div>
          {:else}
            <div class="pb-2.5" transition:slideFade />
          {/if}
          -->
          <div class="pb-3.5 transition-all"></div>
          <PresetDisplayHeader />
        </div>
      {/if}
      <div
        class="px-4 transition-all"
        inert={presetMode == 'rename' || presetMode == 'delete'}
        class:saturate-0={presetMode == 'rename' || presetMode == 'delete'}
      >
        <InstrumentLineup toggleMode={editingInstrumentLineup} />
        {#if !editingInstrumentLineup && presetMode != 'delete' && presetMode != 'rename'}
          <div transition:slideFade>
            <InstrumentLineupBottomCap {showHistoryHint} />
          </div>
        {/if}
      </div>
      {#if !editingInstrumentLineup}
        <div transition:slideFade class="pt-2">
          <BandMenuMixAlerts />
        </div>
      {/if}
      {#if !editingInstrumentLineup && !presetMode}
        <div transition:slideFade class="px-4 pb-2 pt-3 transition-all">
          {#if $bandMenu.band.authorPreset}
            <PanelSettingSection
              title="Shared Settings"
              helpText="This lets you use the band settings that the person who created this chart in Strum Machine were using for this song."
            >
              <PresetRadioRowForSharedSettings authorPreset={$bandMenu.band.authorPreset} />
            </PanelSettingSection>
            <div class="pb-4"></div>
          {/if}
          {#if showPresetsHint}
            <div class="pointer-events-none relative pt-2">
              <div class="absolute right-0 top-0 z-10 -rotate-3">
                <HintText
                  text="Try loading a preset!"
                  typingDuration={500}
                  delayBeforeTyping={2000}
                />
              </div>
              <div class="absolute right-12 top-6 z-10">
                <HintArrow
                  fromPoint={{ x: 0, y: 0 }}
                  toPoint={{ x: -20, y: 75 }}
                  controlPoint={{ x: 20, y: 30 }}
                  drawDuration={400}
                  drawDelay={2600}
                />
              </div>
            </div>
          {/if}
          <PanelSettingSection
            title="Preset Library {timeSignature == '4/4' || showHistoryHint || showPresetsHint
              ? ''
              : `(${timeSignature} time)`}"
            helpText={timeSignature == '4/4' || showHistoryHint || showPresetsHint
              ? ''
              : 'Each time signature has its own set of presets, because they utilize strumming patterns and rhythms that are specific to that time signature.'}
          >
            <PresetCategoriesList />
          </PanelSettingSection>
          <div class="pb-3"></div>
          <PanelSettingSection>
            <PresetRadioRowForDefaultBand />
          </PanelSettingSection>
        </div>
      {/if}

      {#if !editingInstrumentLineup && !presetMode}
        <footer
          transition:slideFade
          class="rounded-b-lg px-2 pb-2 text-center text-sm text-gray-500"
        >
          <a
            href="/help/band"
            class="btn btn-block btn-sm whitespace-nowrap rounded-md font-medium"
            onclick={(e) => {
              e.preventDefault();
              modalController.zoomInto({
                component: BandHelpPanel,
                title: 'Help',
              });
            }}
          >
            Learn about band settings and presets</a
          >
        </footer>
      {:else}
        <div transition:slideFade class="pb-2"></div>
      {/if}
    </div>
  {/snippet}
</PanelModalLayout>
