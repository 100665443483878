import tippyJs from 'tippy.js';
import closeNearestTippy from '@/ui/dropdowns/closeNearestTippy';

const tippyDefaults = {
  interactive: true,
  theme: 'light',
  trigger: 'click',
  duration: [300, 150],
  offset: [0, 0],
  placement: 'bottom',
  popperOptions: {
    modifiers: [
      {
        name: 'flip',
        options: {
          // fallbackPlacements: ['top'],
          padding: 22,
        },
      },
    ],
  },
  role: '',
  animation: 'scale-subtle',
};

const prepareTippySettings = (settings, instance) => {
  const defaults = JSON.parse(JSON.stringify(tippyDefaults));
  settings = Object.assign(defaults, settings || {});

  if (!settings.parent) {
    settings.parent = instance.find(`[data-tippy-dropdown-id="${settings.id}"]`);
    if (!settings.parent) {
      throw new Error(`Couldn't find dropdown for Tippy (${settings.id})`);
    }
  }

  if (!settings.target) {
    settings.target =
      typeof settings.parent == 'string'
        ? instance.find(`${settings.parent} > :first-child`)
        : settings.parent.firstElementChild;
  }

  if (!settings.content && settings.parent) {
    settings.content =
      typeof settings.parent == 'string'
        ? instance.find(`${settings.parent} > :nth-child(2)`)
        : settings.parent.firstElementChild.nextElementSibling;
  } else if (!settings.content && settings.target) {
    settings.content =
      typeof settings.target == 'string'
        ? instance.find(`${settings.target} + *`)
        : settings.target.nextElementSibling;
  }

  return settings;
};

const extractTippyArgs = (args) => {
  const target = args.target;
  const settings = { ...args };
  delete settings.target;
  delete settings.parent;
  delete settings.id;
  return [target, settings];
};

export default function defineTippyDropdown(template, args) {
  const id = args.id || args.parent || args.target;

  template.onCreated(() => {
    const instance = Template.instance();
    instance.tippyVisibleDropdowns = new ReactiveDict();
  });

  template.onRendered(() => {
    const instance = Template.instance();

    const setEscapeHandler = (tippy) => {
      clearEscapeHandler();
      instance.tippyDropdownEscapeHandler = function onKeyDown(event) {
        if (event.keyCode === 27) tippy.hide();
      };
      document.addEventListener('keydown', instance.tippyDropdownEscapeHandler);
    };

    const clearEscapeHandler = (tippy) => {
      if (instance.tippyDropdownEscapeHandler)
        document.removeEventListener('keydown', instance.tippyDropdownEscapeHandler);
      instance.tippyDropdownEscapeHandler = null;
    };

    const settings = prepareTippySettings(args, instance);

    settings.target.setAttribute('aria-haspopup', true);

    settings.onTrigger = (tippyInstance) => {
      instance.tippyVisibleDropdowns.set(id, true);
      Tracker.flush();
      if (tippyInstance.popper.querySelector('.dropdown-tippy-close')) {
        tippyInstance.popper.querySelector('.tippy-box').style.borderTopRightRadius = 0;
      }
      if (args.onTrigger) args.onTrigger(tippyInstance, instance);
    };

    settings.onShow = (tippyInstance) => {
      setEscapeHandler(tippyInstance);
      requestAnimationFrame(() => setTippyTransformOriginBasedOnArrow(tippyInstance));
      if (args.onShow) return args.onShow(tippyInstance, instance);
    };

    settings.onHide = (tippyInstance) => {
      clearEscapeHandler();
      if (args.onHide) return args.onHide(tippyInstance, instance);
    };

    settings.onHidden = (tippyInstance) => {
      instance.tippyVisibleDropdowns.set(id, false);
      Tracker.flush();
      if (args.onHidden) return args.onHidden(tippyInstance, instance);
    };

    'onMount onShown'.split(' ').forEach((event) => {
      if (args[event]) settings[event] = (t) => args[event](t, instance);
    });

    const tippyInstance = tippyJs(...extractTippyArgs(settings));
    settings.parent._tippy = tippyInstance; // it's automatically set on the target, but let's set it on the parent too
  });

  template.helpers({
    tippyDropdownOpen(dropdownId) {
      const instance = Template.instance();
      return instance.tippyVisibleDropdowns.get(dropdownId);
    },
  });

  template.events({
    'click .dropdown-tippy-close': function (event, instance) {
      closeNearestTippy(event.currentTarget);
    },
  });
}

const setTippyTransformOriginBasedOnArrow = (tippyInstance) => {
  const box = tippyInstance.popper.firstElementChild;
  if (!tippyInstance.popperInstance) return;
  const x = tippyInstance.popperInstance.state.modifiersData.arrow.x + 8;
  const y = tippyInstance.popperInstance.state.placement == 'top' ? '100%' : '0%';
  box.style.transformOrigin = `${x.toFixed(1)}px ${y}`;
};
