import { ChopMandolinCore } from '@/band/instruments/mandolin/cores/ChopMandolinCore';
import { HalfTimeChopMandolinCore } from '@/band/instruments/mandolin/cores/HalfTimeChopMandolinCore';
import { JigChopMandolinCore } from '@/band/instruments/mandolin/cores/JigChopMandolinCore';
import { SlipJigChopMandolinCore } from '@/band/instruments/mandolin/cores/SlipJigChopMandolinCore';
import { SwingMandolinCore } from '@/band/instruments/mandolin/cores/SwingMandolinCore';
import { WaltzChopMandolinCore } from '@/band/instruments/mandolin/cores/WaltzChopMandolinCore';

// These only get shown in the UI according to mandolinPatternLineup.ts

export const mandolinCores = {
  [ChopMandolinCore.id]: ChopMandolinCore,
  [SwingMandolinCore.id]: SwingMandolinCore,
  [HalfTimeChopMandolinCore.id]: HalfTimeChopMandolinCore,
  [WaltzChopMandolinCore.id]: WaltzChopMandolinCore,
  [JigChopMandolinCore.id]: JigChopMandolinCore,
  [SlipJigChopMandolinCore.id]: SlipJigChopMandolinCore,
} as const;
