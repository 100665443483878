import { strict as assert } from 'assert';
import type { CircularStrumLandscape } from '@/band/instruments/CircularStrumLandscape';
import { bluegrassPatternLandscape } from '@/band/instruments/guitar/bluegrassPatternLandscape';
import { bluegrassWaltzPatternLandscape } from '@/band/instruments/guitar/bluegrassWaltzPatternLandscape';
import { BluegrassGuitarCore } from '@/band/instruments/guitar/cores/BluegrassGuitarCore';
import { BluegrassWaltzGuitarCore } from '@/band/instruments/guitar/cores/BluegrassWaltzGuitarCore';
import { SyncopatedBluegrassGuitarCore } from '@/band/instruments/guitar/cores/SyncopatedBluegrassGuitarCore';
import { syncopatedPatternLandscape } from '@/band/instruments/guitar/syncopatedPatternLandscape';
import type { InstrumentCore } from '@/band/instruments/InstrumentCore';

export function patternLandscapeForGuitarCore(core: InstrumentCore): CircularStrumLandscape {
  const landscape =
    core == SyncopatedBluegrassGuitarCore
      ? syncopatedPatternLandscape
      : core == BluegrassWaltzGuitarCore
        ? bluegrassWaltzPatternLandscape
        : core == BluegrassGuitarCore
          ? bluegrassPatternLandscape
          : undefined;
  assert(landscape, `No pattern landscape for core ${core.id}`);
  return landscape;
}
