import * as Sentry from '@sentry/browser';
import type { MandolinChord } from '@/band/instruments/mandolin/MandolinChord';
import { MandolinChordLibrary } from '@/band/instruments/mandolin/MandolinChordLibrary';
import type { LinearizedBeat } from '@/chart/LinearizedBeat';
import type { LinearizedMeasure } from '@/chart/LinearizedMeasure';

export function assignMandolinChords({
  measures,
  chordStyle,
}: {
  measures: readonly LinearizedMeasure[];
  chordStyle: 'bluegrass';
}): Map<LinearizedBeat, MandolinChord> {
  const beatChordMap = new Map<LinearizedBeat, MandolinChord>();
  let lastChosenChord: MandolinChord | undefined;

  for (const beat of measures.flatMap((measure) => measure.beats)) {
    if (beat.chordChanged || !lastChosenChord) {
      const mandolinChord = MandolinChordLibrary.pickChord(beat.chord, { style: chordStyle });
      if (!mandolinChord) {
        const err = new Error(
          `Couldn't find mandolin chord definition for ${beat.chord.toString()}`
        );
        Sentry.captureException(err);
        console.error(err);
      }
      lastChosenChord = mandolinChord;
    }
    (beat.plans.mandolin ??= {}).chord = lastChosenChord;
  }

  return beatChordMap;
}
