<script lang="ts">
  import type { BandPreset } from '@/band/presets/BandPreset';
  import { BandPresets } from '@/band/presets/BandPresets';
  import { presetGenres, presetGenresById } from '@/band/presets/presetGenres';
  import PanelOpenerChevron from '@/band/ui/components/PanelOpenerChevron.svelte';
  import PresetsCategoryPanel from '@/band/ui/presets/PresetsCategoryPanel.svelte';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import Icon from '@/ui/icons/Icon.svelte';
  import { MultiPanelModalController } from '@/ui/settings/MultiPanelModalController.svelte';

  const modalController = MultiPanelModalController.current();
  const bandMenu = getBandMenuState();
  let band = $derived($bandMenu.band);

  let customPresets: BandPreset[] = $state([]);
  trackerEffect(() => (customPresets = BandPresets.custom(band.timeSignature())));

  let allPresets: BandPreset[] = $state([]);
  trackerEffect(() => (allPresets = BandPresets.all(band.timeSignature())));
  let availablePresetGenres = $derived(
    presetGenres.filter((g) => allPresets.some((p) => p.genreId == g.id))
  );

  let presetCategories = $derived([
    { type: 'user', label: 'Your Presets', genreId: undefined },
    ...availablePresetGenres.map((genre) => ({
      type: 'built-in',
      label: genre.name,
      genreId: genre.id,
    })),
  ]);
</script>

{#each presetCategories as presetCategory (presetCategory.label)}
  <div class="group">
    <button
      class="colors-panelOpener relative flex w-full items-center gap-3 py-3 pl-3 pr-9 text-left leading-[1.35] group-first:rounded-t-lg group-last:rounded-b-lg"
      onclick={() =>
        modalController.zoomInto({
          component: PresetsCategoryPanel,
          props: {
            userPresets: presetCategory.type == 'user',
            genre: presetCategory.genreId ? presetGenresById[presetCategory.genreId] : undefined,
          },
          title: `${presetCategory.label.replace(/ (styles|presets)$/i, '').replace(/raditional/i, 'rad.')} Presets`,
        })}
    >
      {#if presetCategory.type == 'user'}
        <Icon id="preset-user" size={24} />
      {:else if presetCategory.type == 'built-in'}
        <Icon id="preset-list" size={24} />
      {/if}
      <div>
        {presetCategory.label}
        {#if presetCategory.type == 'user'}
          <span class="font-normal text-gray-500">
            ({customPresets.length || 'none saved yet'})
          </span>
        {/if}
      </div>
      <PanelOpenerChevron direction="right" />
    </button>
  </div>
{/each}
