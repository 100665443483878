import { Bass } from '@/band/instruments/bass/Bass';
import { Feet } from '@/band/instruments/feet/Feet';
import { Guitar } from '@/band/instruments/guitar/Guitar';
import { Mandolin } from '@/band/instruments/mandolin/Mandolin';

export const staticInstruments = {
  guitar: new Guitar(),
  bass: new Bass(),
  mandolin: new Mandolin(),
  feet: new Feet(),
} as const;
