<script lang="ts" generics="OptionValue extends unknown">
  import type { Snippet } from 'svelte';
  import { createEventDispatcher } from 'svelte';
  import { quintOut } from 'svelte/easing';
  import { crossfade } from 'svelte/transition';
  import { quickRandomId } from '@/utilities/quickRandomId';

  type Option = Readonly<{
    caption: string;
    meta?: unknown;
    value: OptionValue;
  }>;

  interface Props {
    options?: Readonly<Option[]>;
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    selectedValue?: Option['value'] | undefined;
    noBackground?: boolean;
    juicyShadows?: boolean;
    children?: Snippet<[{ option: Option; selected: boolean }]>;
  }

  let {
    options = [],
    selectedValue = $bindable(),
    noBackground = false,
    juicyShadows = false,
    children,
  }: Props = $props();

  const dispatch = createEventDispatcher<{ select: OptionValue }>();

  const randomId = quickRandomId();

  const [send, receive] = crossfade({
    duration: 400,
    easing: quintOut,
  });

  const key = quickRandomId();
</script>

<fieldset
  class="flex gap-x-2 sm-band:gap-x-1.5 {noBackground
    ? ''
    : 'rounded-lg p-2 pb-[9px] sm-band:p-1.5'}"
  class:fieldsetBackground={!noBackground && !juicyShadows}
  class:juicyShadows
>
  {#each options as option (option.value)}
    {@const selected = selectedValue == option.value}
    <div class="relative w-full flex-grow basis-1/3">
      {#if selected}
        <div
          in:send={{ key }}
          out:receive={{ key }}
          class="checkedShadow absolute inset-0 rounded bg-white will-change-transform"
        ></div>
      {/if}
      <input
        id={`${randomId}_${option.value}`}
        value={option.value}
        class="peer absolute h-0 w-0 opacity-0"
        type="radio"
        bind:group={selectedValue}
        name={`${randomId}_${option.value}`}
        onchange={() => dispatch('select', option.value)}
      />
      <label
        for={`${randomId}_${option.value}`}
        class="btn-base relative flex w-full items-center justify-center rounded p-2 font-normal leading-5 outline-primary-500 transition-colors duration-500 will-change-transform peer-focus-visible:outline
        {selected ? 'text-black duration-75' : 'text-gray-800'}
        {selected ? '' : noBackground ? 'hover:bg-primary-100' : 'hover:bg-muted-200'}"
      >
        {#if children}
          {@render children?.({ option, selected })}
        {:else}
          {option.caption}
        {/if}
      </label>
    </div>
  {/each}
</fieldset>

<style>
  .fieldsetBackground {
    box-shadow:
      -0.5px -1px 1px 0px #fff inset,
      0.5px 1px 2px 0px rgba(0, 0, 0, 0.06) inset,
      0px -1px 2px 0px rgba(255, 255, 255, 0.5);
    background-color: var(--muted-100);
  }

  .juicyShadows {
    box-shadow:
      0px -1px 0px 0px rgba(0, 0, 0, 0.03),
      8px 8px 16px 4px rgba(0, 0, 0, 0.05),
      -8px -8px 16px 4px #fff,
      0px 4px 8px 0px rgba(0, 0, 0, 0.05),
      0px -1px 2px 0px rgba(255, 255, 255, 0.5),
      0px 1px 2px 0px rgba(0, 0, 0, 0.05) inset,
      0px 0px 7px 0px rgba(0, 0, 0, 0.15) inset;
    background-color: var(--muted-100);
  }

  .checkedShadow {
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  }
</style>
