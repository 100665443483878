<script lang="ts">
  interface Props {
    checked?: boolean;
    size?: number;
    useCurrentColor?: boolean;
    rounding?: number;
  }

  let { checked = false, size = 24, useCurrentColor = false, rounding = 1 }: Props = $props();
</script>

<svg viewBox="0 0 24 24" fill="none" style="width: {size}px; height: {size}px;">
  <rect
    x="1"
    y="1"
    rx={rounding * 12}
    ry={rounding * 12}
    width="22"
    height="22"
    fill={useCurrentColor ? 'currentColor' : checked ? 'hsl(206, 80%, 50%)' : 'hsl(206, 50%, 100%)'}
    stroke={useCurrentColor
      ? 'currentColor'
      : checked
        ? 'hsl(206, 80%, 50%)'
        : 'hsl(206, 20%, 70%)'}
    stroke-width="1"
    class="transition-all"
  /><path
    d="M7 13l3 3 7-7"
    stroke="white"
    stroke-width="3"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
