import {
  bassNote,
  crosspick,
  pickSpecified,
  strumDown,
  strumUp,
} from '@/band/instruments/guitar/GuitarActions';
import type { GuitarIntent, GuitarIntentStrum } from '@/band/instruments/guitar/GuitarIntent';
import type { GuitarBrushinessSetting } from '@/band/instruments/guitar/settings/GuitarBrushinessSetting';
import type { GuitarCircularStrumShapeSetting } from '@/band/instruments/guitar/settings/GuitarCircularStrumShapeSetting';
import type { GuitarOpenVoicingsSetting } from '@/band/instruments/guitar/settings/GuitarOpenVoicingsSetting';
import { syncopatedSpreadGradients } from '@/band/instruments/guitar/settings/syncopatedSpreadGradients';
import { syncopatedPatternLandscape } from '@/band/instruments/guitar/syncopatedPatternLandscape';
import type { LinearizedCell } from '@/chart/LinearizedCell';
import { lerp } from '@/utilities/lerp';
import { randomPlusMinus } from '@/utilities/randomPlusMinus';

const zeroThroughSeven = [0, 1, 2, 3, 4, 5, 6, 7] as const;
type BeatAction = 'strum' | 'pick' | 'interval';

export function syncopatedBluegrassMeasure(
  cells: [LinearizedCell, LinearizedCell],
  {
    circularStrumShape,
    brushiness,
    openVoicings,
  }: {
    circularStrumShape: GuitarCircularStrumShapeSetting;
    brushiness: GuitarBrushinessSetting;
    openVoicings: GuitarOpenVoicingsSetting;
  }
): (GuitarIntent | null)[] {
  const beatPowers = circularStrumShape
    .powersForLandscape(syncopatedPatternLandscape)
    .map((p) => p + randomPlusMinus(0.04)) as EightNumbers;

  const beatSpreads = brushiness.spreadsForGradients(syncopatedSpreadGradients).map((spread, i) => {
    spread *= lerp(0.5, 1, beatPowers[i] ?? NaN);
    spread += randomPlusMinus(i % 4 > 0 ? 0.3 : 0.0);
    return spread;
  }) as EightNumbers;

  // Ensure upstroke is not an isolated single note
  beatSpreads[3] = Math.max(2.2 - (beatPowers[2] + beatPowers[4]), beatSpreads[3]);

  const beatBiases: EightOf<GuitarIntentStrum['bias']> = [
    'root',
    'afterRoot',
    'chuck1',
    'afterChuck1',
    'fifth', // not played
    'afterFifth',
    'chuck2',
    'afterChuck2',
  ];

  const abovePowerThreshold = (power: number): boolean =>
    power > 0.05 && power - 0.05 > Math.random() * 0.12;
  const beatActions: (BeatAction | undefined)[] = [
    cells[0].plans.guitar?.boomStrum ? 'strum' : 'pick',
    ...([1, 2, 3, 4, 5, 6, 7] as const).map((i) =>
      abovePowerThreshold(beatPowers[i]) ? (beatSpreads[i] > 1.3 ? 'strum' : 'pick') : undefined
    ),
  ];

  // Bass run handling

  const beatIntervals: (number | null | undefined)[] = [null, null, null, null];
  const bassRun = cells[1].plans.guitar?.bassRun;
  if (bassRun?.quarters) {
    beatIntervals.push(bassRun.intervals[0], null, bassRun.intervals[2], null);
    beatPowers[3] = Math.min(0.5, beatPowers[3]);
    beatPowers[4] = 1.5 * beatPowers[0];
    beatActions[4] = undefined;
    beatPowers[5] = Math.min(Math.random() * 0.5 - 0.3, beatPowers[5]);
    beatPowers[6] = 1.4 * beatPowers[0];
    beatPowers[7] = Math.min(Math.random() * 0.5 - 0.2, beatPowers[7]);
  } else if (bassRun?.eighths) {
    beatIntervals.push(null, bassRun.intervals[0], bassRun.intervals[2], bassRun.intervals[3]);
    if (typeof beatIntervals[5] == 'number') {
      beatPowers[5] = 1.1 * beatPowers[0];
    }
    beatPowers[6] = 1.4 * beatPowers[0];
    beatPowers[7] = 1.4 * beatPowers[0];
  }
  zeroThroughSeven.forEach((i) => {
    if (typeof beatIntervals[i] == 'number') beatActions[i] = 'interval';
  });

  // Calculate dBs

  const beatDbs = [
    beatActions[0] == 'pick'
      ? lerp(-5, 6, beatPowers[0]) - lerp(3, 0, Math.min(1, brushiness.value * 2))
      : lerp(-9, 3, beatPowers[0]),
    lerp(-15, -3, beatPowers[1]),
    lerp(-10, 3, beatPowers[2]),
    lerp(-8, 5, beatPowers[3]),
    lerp(-10, 2, beatPowers[4]),
    lerp(-15, -3, beatPowers[5]),
    lerp(-10, 3, beatPowers[6]),
    lerp(-15, -3, beatPowers[7]),
  ]
    .map((db, i) => db + (i % 4 == 0 ? 0 : randomPlusMinus(0.5)))
    .map((db, i) =>
      beatActions[i] === 'interval' ? Math.max(-2 - 2 * (i % 2), db) : db
    ) as EightNumbers;

  // Put it all together!

  return zeroThroughSeven.map((i) => {
    if (beatActions[i] === 'pick') {
      return i === 0
        ? bassNote('root', { db: beatDbs[i] })
        : crosspick({
            series: 'std',
            noteInSeries: ([1, 2, 3, 4, 5, 6, 7, 8] as const)[i],
            db: beatDbs[i],
          });
    }

    if (beatActions[i] === 'strum') {
      return (i % 2 == 0 ? strumDown : strumUp)(beatBiases[i], {
        spread: beatSpreads[i],
        db: beatDbs[i],
      });
    }

    if (beatActions[i] === 'interval') {
      const interval = beatIntervals[i];
      if (typeof interval != 'number') return null;
      const stringFret = openVoicings.intervalStringFretInKey(cells[0].measure.key, interval);
      return pickSpecified(stringFret, { db: beatDbs[i] });
    }

    return null;
  });
}
