import { getContext, setContext } from 'svelte';
import { writable } from 'svelte/store';
import wait from '@/utilities/wait';

const contextKey = Symbol('editor-menu-context');

export type EditorMenuId = 'chords' | 'bass' | 'effects' | 'band' | 'chart';

type State = {
  activeMenu: EditorMenuId | undefined;
  menuCenter: number;
  showShortcuts: boolean;
};

function createStateStore() {
  const { subscribe, update } = writable<State>({
    activeMenu: undefined,
    menuCenter: 0,
    showShortcuts: false,
  });

  return {
    subscribe,

    _setActiveMenu: (menu: EditorMenuId | undefined) =>
      update((state) => {
        return { ...state, activeMenu: menu };
      }),

    setMenu: (menu: EditorMenuId | undefined) => desiredMenu.set(menu),

    setMenuCenter: (x: number) => {
      void wait(0).then(() =>
        update((state) => {
          return {
            ...state,
            menuCenter: x,
          };
        })
      );
    },

    toggleShortcuts: () =>
      update((state) => {
        return { ...state, showShortcuts: !state.showShortcuts };
      }),

    menuWidths: {
      chords: 21 * 16,
      bass: 21 * 16,
      effects: 17.5 * 16,
      chart: 17.5 * 16,
      band: 21 * 16,
    },

    menuHeights: {
      chords: 25 * 16,
      bass: 25 * 16,
      effects: 15 * 16,
      chart: 16 * 16,
      band: 21 * 16,
    },
  };
}

export const desiredMenu = writable<EditorMenuId | undefined>(undefined);

export const EditorToolsState = {
  get(): ReturnType<typeof createStateStore> {
    return getContext(contextKey);
  },

  create(): ReturnType<typeof createStateStore> {
    setContext(contextKey, createStateStore());
    return EditorToolsState.get();
  },
};
