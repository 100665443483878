import { z } from 'zod';
import type { Gradient } from '@/band/instruments/guitar/settings/interpolateAcrossGradient';
import { interpolateAcrossGradient } from '@/band/instruments/guitar/settings/interpolateAcrossGradient';
import type { InstrumentSetting } from '@/band/instruments/InstrumentSetting';
import { bounded } from '@/utilities/bounded';
import cloneJSON from '@/utilities/cloneJSON';
import { rounded } from '@/utilities/rounded';

const serializedSchema = z.object({
  v: z.number().min(-1).max(1).optional(),
});

type Serialization = z.infer<typeof serializedSchema>;

const presets = [
  { value: 13 / 14, caption: 'Heavy strums' },
  { value: 10 / 14, caption: 'Mostly strums' },
  { value: 7 / 14, caption: 'Strums with bass notes' },
  { value: 4 / 14, caption: 'Picks and strums' },
  { value: 1 / 14, caption: 'Mostly picks' },
];

const defaultValue = 7 / 14;

export class GuitarBrushinessSetting implements InstrumentSetting<Serialization> {
  /**
   * 0 = totally picky, 1 = totally brushy
   */
  readonly value: number;
  readonly boomStrumProbability: number;
  readonly boomStrumSpread: number;

  constructor(input: unknown = {}) {
    const data = serializedSchema.catch({}).parse(input);
    this.value = bounded(data.v ?? defaultValue, 0, 1);
    this.boomStrumProbability = interpolateAcrossGradient(this.value, [
      [7 / 14, 0],
      [11 / 14, 1],
    ]);
    this.boomStrumSpread = interpolateAcrossGradient(this.value, [
      [8 / 14, 0],
      [14 / 14, 1],
    ]);
  }

  serialize(): Serialization {
    return cloneJSON({
      'v': this.value !== defaultValue ? rounded(this.value, 3) : undefined,
    });
  }

  closeTo(serialized?: Serialization) {
    const other = new GuitarBrushinessSetting(serialized);
    return Math.abs(other.value - this.value) <= 1.1 / 14;
  }

  withBrushiness(value: number) {
    return new GuitarBrushinessSetting({ 'v': rounded(value, 3) });
  }

  private _spreadsForGradients = new Map<Readonly<Gradient[]>, number[]>();
  spreadsForGradients(gradients: Readonly<Gradient[]>) {
    if (!this._spreadsForGradients.has(gradients)) {
      const spreads = gradients.map((gradient) => interpolateAcrossGradient(this.value, gradient));
      this._spreadsForGradients.set(gradients, spreads);
    }
    return this._spreadsForGradients.get(gradients) as number[];
  }

  static readonly presets = presets;

  get closestPreset() {
    return presets.reduce((closest, preset) =>
      Math.abs(preset.value - this.value) < Math.abs(closest.value - this.value) ? preset : closest
    );
  }
}
