import * as Sentry from '@sentry/browser';
import { Meteor } from 'meteor/meteor';
import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { rpcCalculateProration } from '@/server/methods/accounts/rpcCalculateProration';
import { rpcCancelSubscription } from '@/server/methods/accounts/rpcCancelSubscription';
import { rpcGetUserPaymentSource } from '@/server/methods/accounts/rpcGetUserPaymentSource';
import { formatCurrencyAmount } from '@/utilities/formatCurrencyAmount';
import waitUntilReactive from '@/utilities/waitUntilReactive';

Template.cancelAccountPage.onCreated(() => {
  const instance = Template.instance();

  if (!Meteor.userId()) {
    FlowRouter.go('song-index');
  }

  instance.card = new ReactiveVar();
  instance.refundAmount = new ReactiveVar();
  instance.refundFormatted = new ReactiveVar();
  instance.refundChoice = new ReactiveVar();
  instance.submitting = new ReactiveVar();

  waitUntilReactive(() => Meteor.user()?.roles && Meteor.user()?.subscription).then(async () => {
    if (
      !Meteor.user('roles').roles.includes('paid-member') &&
      !Meteor.user('subscription').subscription?.subscriptionId
    ) {
      FlowRouter.go('account');
      bootbox.alert('Your subscription is not active, so there’s nothing to cancel.');
      return;
    }

    if (Meteor.user('subscription').subscription?.cancelAtPeriodEnd) {
      FlowRouter.go('account');
      bootbox.alert(
        'Your subscription is already set to expire at the end of your current payment period.'
      );
      return;
    }

    if (Meteor.user('subscription').subscription?.status == 'trialing') {
      instance.refundAmount.set(0);
      instance.refundChoice.set('use-up');
    } else {
      try {
        const card = await rpcGetUserPaymentSource({ userId: Meteor.userId() });
        instance.card.set(card);
        const { amount, currency } = await rpcCalculateProration();
        instance.refundAmount.set(amount);
        if (amount > 0) {
          instance.refundFormatted.set(formatCurrencyAmount(currency, amount));
        } else {
          instance.refundChoice.set('refund');
        }
      } catch (err) {
        abortForm(err);
      }
    }
  });

  instance.data.setTitle('Account Cancellation');
});

Template.cancelAccountPage.helpers({
  loading() {
    return !(
      Meteor.user('subscription')?.subscription &&
      typeof Template.instance().refundAmount.get() == 'number'
    );
  },

  trialing() {
    return Meteor.user('subscription')?.subscription?.status == 'trialing';
  },

  canGetRefund() {
    return (
      Meteor.user('subscription')?.subscription?.status == 'active' &&
      Template.instance().refundAmount.get() > 0
    );
  },

  setRefundChoice() {
    const instance = Template.instance();
    return (choice) => {
      instance.refundChoice.set(choice);
    };
  },

  refundFormatted() {
    return Template.instance().refundFormatted.get();
  },

  card() {
    return Template.instance().card.get();
  },

  readyToCancel() {
    return !!Template.instance().refundChoice.get();
  },

  postponing() {
    return Template.instance().refundChoice.get() == 'use-up';
  },

  submitting() {
    return Template.instance().submitting.get();
  },
});

Template.cancelAccountPage.events({
  'click .js-submitCancellation': async function (event, instance) {
    event.preventDefault();
    instance.submitting.set(true);
    try {
      await rpcCancelSubscription({
        expectedRefund: instance.refundAmount.get(),
        action: instance.refundChoice.get(),
      });
      FlowRouter.go(
        'cancelled',
        {},
        {
          finishTerm: instance.refundChoice.get() == 'use-up' ? 1 : undefined,
          refund:
            instance.refundChoice.get() == 'refund' && instance.refundAmount.get() > 0
              ? instance.refundFormatted.get()
              : undefined,
          donated: instance.refundChoice.get() == 'donate' ? 1 : undefined,
        }
      );
    } catch (err) {
      abortForm(err);
    }
  },
});

const abortForm = (err) => {
  Sentry.captureException(err);
  console.error(err);
  Meteor.setTimeout(
    () =>
      bootbox.alert(
        `Sorry, something went wrong with the self-serve cancellation form. Please <b><a href="mailto:support@strummachine.com?subject=Cancel%20my%20subscription&amp;body=Subscription%20ID%3A%20${Meteor.user().subscription.subscriptionId}" target="_blank">email us</a></b> and we’ll cancel your subscription manually.`
      ),
    400
  );
  FlowRouter.go('/app/account');
};
