import type { FeetCore } from '@/band/instruments/feet/FeetCore';
import { tap } from '@/band/instruments/feet/FootActions';

export const CanadianReelFeetCore = {
  id: 'cr',
  title: 'One and-a two and-a...',

  timeSignatures: ['4/4'],

  linkedSettingsKeys: [],
  otherSettingsKeys: ['timing'],

  // TODO: disable swing for this core?
  // Need to track if swing was explicitly set, I think...
  // defaultSwing: swingOptions['0'],

  presets: [
    {
      name: 'One and-a two and-a...',
      settings: {},
    },
  ],

  processMeasure(measure, _settings, context) {
    const everyBeat = false;
    const upstrokeBeatOffset = 0.5 + 0.25 * context.swing;

    if (measure.lastInRep) {
      measure.beats[0].playerInstructions.feet = [tap('bigflam-02', 1)];
      measure.beats[1].playerInstructions.feet = [tap('bigflam-01', 3)];
      if (measure.beats[2] && measure.beats[3]) {
        measure.beats[2].playerInstructions.feet = [tap('toeL', -3, upstrokeBeatOffset)];
        measure.beats[3].playerInstructions.feet = [tap('flam', 0)];
      }
    } else {
      measure.beats[0].playerInstructions.feet =
        measure.firstInRep || (measure.position.cells[0] === 0 && Math.random() < 0.8)
          ? [tap('bigflam-01', 2), ...(everyBeat ? [tap('toeL', -1, upstrokeBeatOffset)] : [])]
          : [tap('flam', 0)];
      measure.beats[1].playerInstructions.feet = [
        tap('toeR', 0),
        tap('toeL', 0, upstrokeBeatOffset),
      ];
      if (measure.beats[2] && measure.beats[3]) {
        measure.beats[2].playerInstructions.feet = [tap('flam', -2)];
        measure.beats[3].playerInstructions.feet = [
          tap('toeR', 0),
          tap('toeL', -1, upstrokeBeatOffset),
        ];
      }
    }
    return []; // Not using Intents for now
  },

  generateViz(settings) {
    settings;
    return [];
  },
} as const satisfies FeetCore;
