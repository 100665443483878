import { AnnouncementsCollection } from '@/collections/AnnouncementsCollection';
import { ReactiveTimer } from '@/lib/ReactiveTimer';
import { UserProfile } from '@/user/UserProfile';
import Version from '@/Version';

Template.announcements.onCreated(() => {
  Template.instance().announcementTimer = new ReactiveTimer(60); // once a minute
  Template.instance().subscribe('announcements');
});

Template.announcements.onRendered(() => {
  Template.instance().find('.announcements')._uihooks = {
    removeElement(node) {
      $(node)
        .slideUp()
        .promise()
        .done(() => {
          $(node).remove();
        });
    },
  };
  Template.instance().autorun(() => {
    var read = UserProfile.announcementsRead() || [];
    var fullScreenAnnouncements = AnnouncementsCollection.find({
      _id: { $nin: read },
      fullScreen: true,
    });
    var announcement = fullScreenAnnouncements.fetch()[0];
    if (announcement) {
      setTimeout(() => {
        bootbox.alert({
          title: announcement.title,
          message: announcement.message,
          className: 'modal-announcement',
          closeButton: false,
          buttons: {
            ok: {
              label: 'Got it',
              className: 'btn-lower',
            },
          },
          callback() {
            UserProfile.readAnnouncement(announcement._id);
          },
        });
      }, 2000);
    }
  });
});

Template.announcements.onDestroyed(() => {
  if (Template.instance().announcementTimer) Template.instance().announcementTimer.stop();
});

Template.announcements.helpers({
  announcements() {
    Template.instance().announcementTimer.watch();
    var now = new Date();
    var user = Meteor.user('createdAt', 'playedMinutes', 'milestones'); // watch out: change this if you want to reference other user properties!
    var currentVersionNumber = Number(Version.current.split('.')[0]);
    if (!user) return;
    var query = {
      $where() {
        if (this.showAt && this.showAt > now) return false;
        if (this.hideAt && this.hideAt < now) return false;
        if (this.joinedBefore && (!user || this.joinedBefore < user.createdAt)) return false;
        if (this.joinedAfter && (!user || this.joinedAfter > user.createdAt)) return false;
        if (this.minVersion && this.minVersion > currentVersionNumber) return false;
        if (this.maxVersion && this.maxVersion < currentVersionNumber) return false;
        if (this.hasMilestones && !this.hasMilestones.every((m) => user.milestones?.includes(m)))
          return false;
        if (this.notMilestones && this.notMilestones.some((m) => user.milestones?.includes(m)))
          return false;
        if (
          this.minPlayedMinutes &&
          (!user.playedMinutes || this.minPlayedMinutes > user.playedMinutes)
        )
          return false;
        if (
          this.maxPlayedMinutes &&
          (!user.playedMinutes || this.maxPlayedMinutes < user.playedMinutes)
        )
          return false;
        return true;
      },
    };
    if (UserProfile.announcementsRead()) query._id = { $nin: UserProfile.announcementsRead() };
    var exclusions = [];
    exclusions.push(window.location.href.includes('beta') ? 'not-beta' : 'only-beta');
    exclusions.push(window.location.href.includes('alpha') ? 'not-alpha' : 'only-alpha');
    exclusions.push(
      /iP(hone|ad|od)/i.test(navigator.userAgent) || navigator.vendor.startsWith('Apple')
        ? 'not-apple'
        : 'only-apple'
    );
    exclusions.push(
      !/iP(hone|ad|od)/i.test(navigator.userAgent) &&
        !(navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) &&
        navigator.vendor.startsWith('Apple')
        ? 'not-safari'
        : 'only-safari'
    );
    exclusions.push(
      /iP(hone|ad|od)/i.test(navigator.userAgent) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
        ? 'not-ios'
        : 'only-ios'
    );
    exclusions.push(/Android/i.test(navigator.userAgent) ? 'not-android' : 'only-android');
    exclusions.push(
      /Android|iP(hone|ad|od)/i.test(navigator.userAgent) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
        ? 'not-mobile'
        : 'only-mobile'
    );
    exclusions.push(/Firefox/i.test(navigator.userAgent) ? 'not-firefox' : 'only-firefox');
    exclusions.push(/Silk\//i.test(navigator.userAgent) ? 'not-silk' : 'only-silk');
    exclusions.push(Meteor.isCordova ? 'not-cordova' : 'only-cordova');
    exclusions.push(window.todesktop ? 'not-desktop' : 'only-desktop');
    exclusions.push(
      'serviceWorker' in navigator && navigator.serviceWorker.controller ? 'not-sw' : 'only-sw'
    );
    query.filters = { $nin: exclusions };
    query.fullScreen = { $ne: true };
    if (Meteor.gitCommitHash) {
      query.excludeRelease = { $not: Meteor.gitCommitHash };
      query.onlyRelease = { $in: [null, undefined, Meteor.gitCommitHash] };
    }

    return AnnouncementsCollection.find(query, {
      sort: { 'postedAt': 1 },
    });
  },

  formattedMessage(message) {
    message = message.replace(
      /\{email\}/g,
      encodeURIComponent(Meteor.user('emails')?.emails[0].address)
    );
    message = message.replace(
      /\{firstName\}/g,
      encodeURIComponent(Meteor.user('profile')?.profile.firstName)
    );
    message = message.replace(
      /\{lastName\}/g,
      encodeURIComponent(Meteor.user('profile')?.profile.lastName)
    );
    message = message.replace(/\{userId\}/g, encodeURIComponent(Meteor.userId()));
    message = message.replace(/<!--\s*more\s*-->/i, '|||');
    const [aboveFold, belowFold] = message.split('|||');
    if (!belowFold) return aboveFold;
    return `${aboveFold} <a href="#" class="js-showMore">Read More</a> <span style="display: none;" class="js-moreArea">${belowFold}</span>`;
  },
});

Template.announcements.events({
  'click .close, click .js-close': function (event, instance) {
    event.preventDefault();
    UserProfile.readAnnouncement(
      $(event.currentTarget).closest('.announcement').data('announcementId')
    );
  },
  'click .js-showMore': function (event, instance) {
    event.preventDefault();
    $(event.currentTarget).hide().siblings('.js-moreArea').fadeIn({ duration: 250 });
  },
  'click .js-openTypeformSurvey': function (event, instance) {
    event.preventDefault();
    window.open(
      '/typeform-survey.html?' +
        new URLSearchParams({
          email: Meteor.user('emails')?.emails[0].address,
          firstName: Meteor.user('profile')?.profile.firstName,
          userId: Meteor.userId(),
          tfid: event.currentTarget.dataset?.tfid,
        }).toString(),
      'sm-typeform-survey'
    );
  },
});
