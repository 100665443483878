import { ClientManager } from '@/browser/ClientManager';

Template.updateNotice.helpers({
  showUpdateNotice() {
    return ClientManager.updateReady();
  },
});

Template.updateNotice.events({
  'click .js-reloadPageLink': function (event) {
    ClientManager.update();
    event.preventDefault();
  },
});
