function SidePanelSwiper(config = {}) {
  // variables set upon initialization:
  var elModal, elDialog, elBackdrop;
  var modalTransitionDuration;
  var backdropTransitionDuration;
  var backdropOpacity;
  var panelWidth;
  var isRight;

  // variables for event handlers
  var touchstartFirstX;
  var touchstartOffset;
  var wouldCloseIfTouchEnded;

  // config variables
  var minSwipeDistance = 50;

  var initialized = false;
  this.init = function () {
    if (initialized) return;
    initialized = true;

    var $elModal = $('.modal.sidePanel');
    elModal = $elModal[0];
    elDialog = $('.modal-dialog')[0];
    elBackdrop = $('.modal-backdrop')[0];

    $elModal.on('shown.bs.modal', modalShownHandler);
    $elModal.on('hide.bs.modal', modalHideHandler);
  };

  function modalShownHandler() {
    modalTransitionDuration = getComputedStyle(elModal).transitionDuration;
    backdropTransitionDuration = getComputedStyle(elBackdrop).transitionDuration;
    backdropOpacity = +getComputedStyle(elBackdrop).opacity;

    elModal.addEventListener('touchstart', touchstartHandler, false);
    elModal.addEventListener('touchmove', touchmoveHandler, false);
    elModal.addEventListener('touchend', touchendHandler, false);
  }

  function modalHideHandler() {
    $(elModal).off('shown.bs.modal', modalShownHandler);
    $(elModal).off('hide.bs.modal', modalHideHandler);
    elModal.removeEventListener('touchstart', touchstartHandler, false);
    elModal.removeEventListener('touchmove', touchmoveHandler, false);
    elModal.removeEventListener('touchend', touchendHandler, false);
    if (elBackdrop.style.opacity) {
      elBackdrop.style.transitionDuration = backdropTransitionDuration;
      elBackdrop.style.opacity = 0;
    }
  }

  function touchstartHandler(event) {
    panelWidth = elDialog.clientWidth;
    isRight = elDialog.offsetLeft > 0;
    elModal.style.transitionDuration = '0s';
    elBackdrop.style.transitionDuration = '0s';
    touchstartFirstX = event.changedTouches[0].clientX;

    // if swipe started over backdrop, make it only have effect once finger is over panel
    touchstartOffset = isRight
      ? -Math.max(0, window.innerWidth - panelWidth - touchstartFirstX)
      : Math.max(0, touchstartFirstX - panelWidth);
  }

  function touchmoveHandler(event) {
    var touchmoveLastX = event.changedTouches[0].clientX;
    var fingerOffset = touchmoveLastX - touchstartFirstX + touchstartOffset;
    var translateX = isRight ? Math.max(0, fingerOffset) : Math.min(0, fingerOffset);
    elModal.style.transform = 'translateX(' + translateX + 'px)';
    wouldCloseIfTouchEnded = Math.abs(translateX) > minSwipeDistance;
    var elBgOpacity = ((panelWidth - Math.abs(translateX)) / panelWidth) * backdropOpacity;
    elBackdrop.style.opacity = Math.max(0, Math.min(backdropOpacity, elBgOpacity));
  }

  function touchendHandler(event) {
    elModal.style.transitionDuration = modalTransitionDuration;
    elBackdrop.style.transitionDuration = backdropTransitionDuration;
    wouldCloseIfTouchEnded ? closePanel() : openPanel();
  }

  function openPanel() {
    elBackdrop.style.opacity = backdropOpacity;
    elModal.style.transitionDuration = modalTransitionDuration;
    elModal.style.transform = 'translateX(0px)';
  }

  function closePanel() {
    Modal.hide();
  }

  this.init();
}

export default SidePanelSwiper;
