<script lang="ts">
  import { BandPresets } from '@/band/presets/BandPresets';
  import PanelModalLayout from '@/band/ui/panels/PanelModalLayout.svelte';
  import PresetNameEditor from '@/band/ui/presets/PresetNameEditor.svelte';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import Icon from '@/ui/icons/Icon.svelte';
  import { MultiPanelModalController } from '@/ui/settings/MultiPanelModalController.svelte';
  import { clickTap } from '@/ui-helpers/click-tap';

  const modalController = MultiPanelModalController.current();

  const bandMenu = getBandMenuState();

  let band = $derived($bandMenu.band);

  let nameOfNewPreset: string = $state('');

  let failedBecauseNoName = $state(false);
  let failedBecauseSameName = $state(false);
  let existingPresetName: string | undefined = $state();

  let showErrorRing = $derived(
    (failedBecauseNoName && nameOfNewPreset.trim().length === 0) || failedBecauseSameName
  );

  let bandHasInstruments: boolean = $state(true);
  trackerEffect(() => (bandHasInstruments = band.activeInstruments().length > 0));

  function savePreset() {
    if (nameOfNewPreset.trim().length === 0) {
      failedBecauseNoName = true;
    } else {
      const existingPreset = BandPresets.custom().find(
        (p) => p.name.toLocaleLowerCase() === nameOfNewPreset.trim().toLocaleLowerCase()
      );
      if (existingPreset) {
        failedBecauseSameName = true;
        existingPresetName = existingPreset.name;
      } else {
        band.saveAsNewPreset(nameOfNewPreset);
        modalController.zoomOut();
      }
    }
  }
</script>

<PanelModalLayout>
  {#snippet header()}
    <header class="panelHeader pl-9 pr-4 text-left">
      When you make a Custom Band, it is saved <i>automatically</i>, but only for this song. By
      saving it as a preset, you can use it in any song.
    </header>
  {/snippet}

  {#snippet main()}
    <div class="relative">
      <div class="px-4 py-4">
        <div class="mx-[1px] mb-2 px-5 text-sm font-medium text-gray-500">New preset name</div>
        <div
          class="rounded-lg border border-panelWellBorder bg-white py-3 pl-5 pr-3 transition-all duration-300"
          style="box-shadow: 0 3px 4px -2px hsl(210deg 18.92% 29.02% / 27.06%);"
        >
          <PresetNameEditor
            value={nameOfNewPreset}
            on:input={(e) => {
              nameOfNewPreset = e.detail;
              failedBecauseSameName = false;
            }}
            on:submit={savePreset}
            placeholder="Enter a preset name..."
            universalClasses="-ml-2 w-full rounded p-2 pr-6 text-lg font-medium leading-6 text-gray-800 transition-all"
            textareaClasses="bg-white"
            displayClasses="underline decoration-gray-400 decoration-dashed underline-offset-4 dsktp:hover:bg-primary-50 dsktp:hover:ring-1
              {showErrorRing ? 'ring-2 ring-red-400' : ''}"
          />
        </div>
        <div class="pb-6"></div>
        {#if failedBecauseSameName}
          <div class="pb-6 text-center">
            <div class="px-5 pb-3 text-gray-800">
              <div class="pb-3">
                You already have a preset called <span class="whitespace-nowrap font-medium">
                  “{existingPresetName}”.
                </span>
              </div>
              <div class="text-balance">
                If you want to make changes to preset, go back to Your Presets and load it, then {clickTap}
                the three-dots menu in the top-right corner to edit it.
              </div>
            </div>
          </div>
        {:else}
          <div class="pb-6 text-center">
            <div class="px-5 pb-3 text-sm text-gray-600">
              Your new preset will be available under
              <span class="whitespace-nowrap">“Your Presets”</span>
              after you save it.
            </div>
            <div
              class="relative mx-auto flex w-2/3 scale-90 cursor-default items-center gap-3 rounded-lg border border-panelWellBorder bg-white py-3 pl-3 pr-9 text-left leading-[1.35]"
            >
              <Icon id="preset-user" size={24} />
              <span class="font-normal text-gray-600"> Your Presets </span>
              <span class="relative">
                <svg
                  width="37"
                  height="39"
                  viewBox="0 0 37 39"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  class="absolute -bottom-2 left-0 text-gray-600"
                >
                  <path
                    d="M1.24 30.7a1 1 0 0 0-.04 1.41l6.22 6.51a1 1 0 0 0 1.45-1.38l-5.53-5.79 5.79-5.52a1 1 0 1 0-1.38-1.45L1.24 30.7ZM33.35.8a1 1 0 0 0-1.9.63l1.9-.63ZM1.9 32.42c6.51.15 12.28 0 17.13-.74s8.87-2.09 11.8-4.4c2.98-2.34 4.76-5.61 5.2-10 .43-4.33-.44-9.75-2.68-16.48l-1.9.63c2.2 6.6 2.98 11.72 2.58 15.66-.39 3.9-1.92 6.65-4.43 8.62-2.55 2-6.18 3.27-10.87 4-4.68.7-10.31.86-16.78.71l-.05 2Z"
                  />
                </svg>
              </span>
            </div>
          </div>
        {/if}

        <div class="flex flex-col gap-2 px-5">
          <button
            class="btn-base rounded bg-green-600 px-4 py-1 text-white hover:bg-green-700 disabled:cursor-default disabled:bg-gray-400"
            style="box-shadow: none;"
            disabled={!bandHasInstruments ||
              nameOfNewPreset.trim().length === 0 ||
              failedBecauseSameName}
            onclick={savePreset}
          >
            Save New Preset
          </button>
          <button class="btn btn-sm px-4 py-1" onclick={() => modalController.zoomOut()}>
            Make more changes to the band
          </button>
        </div>
      </div>
    </div>
  {/snippet}
</PanelModalLayout>
