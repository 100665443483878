import {
  autoModulateOptionsForInc,
  autoModulateOptionsForMajorKeys,
  autoModulateOptionsForMinorKeys,
  autoModulateOptionsForReps,
} from '@/auto-tools/auto-modulate-options';
import { eventTracker } from '@/browser/analytics/eventTracker';
import { Crnt } from '@/Crnt';

Template.autoModulateSettings.onCreated(() => {});

Template.autoModulateSettings.onRendered(() => {});

Template.autoModulateSettings.helpers({
  amKeysSettingsList() {
    return /m/.test(Crnt.song()?.keyHeard())
      ? autoModulateOptionsForMinorKeys
      : autoModulateOptionsForMajorKeys;
  },
  amIncSettingsList() {
    return autoModulateOptionsForInc;
  },
  amRepsSettingsList() {
    return autoModulateOptionsForReps;
  },
  amKeysCaption() {
    const value = Crnt.song()?.prefs.autoModulate.keys();
    const options = /m/.test(Crnt.song()?.keyHeard())
      ? autoModulateOptionsForMinorKeys
      : autoModulateOptionsForMajorKeys;
    const current = options.find((opt) => opt.value == value);
    return current.caption || current.label;
  },
  customSequence() {
    return Crnt.song()?.prefs.autoModulate.keys() == 'c'; // && Crnt.song()?.prefs.autoModulate.seq();
  },
  amSeq() {
    return Crnt.song()?.prefs.autoModulate.seq();
  },
  amIncCaption() {
    const value = Crnt.song()?.prefs.autoModulate.inc();
    const current = autoModulateOptionsForInc.find((opt) => opt.value == value);
    return current.caption || current.label;
  },
  amRepsCaption() {
    const value = Crnt.song()?.prefs.autoModulate.reps();
    const current = autoModulateOptionsForReps.find((opt) => opt.value == value);
    return current.caption || current.label;
  },
});

Template.autoModulateSettings.events({
  'click .js-keysDropdown a': function (event, instance) {
    Crnt.song()?.prefs.autoModulate.setKeys($(event.currentTarget).data('value'));
    eventTracker.autoModulateSetKeys({ to: Crnt.song()?.prefs.autoModulate.keys() });
  },
  'click .js-incDropdown a': function (event, instance) {
    Crnt.song()?.prefs.autoModulate.setInc($(event.currentTarget).data('value'));
    eventTracker.autoModulateSetInc({ to: Crnt.song()?.prefs.autoModulate.inc() });
  },
  'click .js-repsDropdown a': function (event, instance) {
    Crnt.song()?.prefs.autoModulate.setReps($(event.currentTarget).data('value'));
    eventTracker.autoModulateSetReps({ to: Crnt.song()?.prefs.autoModulate.reps() });
  },
  'keypress .js-seqInput': function (event, instance) {
    if (event.key == 'Enter' || event.key == 'NumpadEnter' || event.key == 'Escape') {
      instance.$('.js-seqInput').blur();
    }
  },
  'blur .js-seqInput': function (event, instance) {
    const text = event.currentTarget.value.trim();
    Meteor.setTimeout(() => {
      Crnt.song()?.prefs.autoModulate.setSeq(text);
    }, 100);
    eventTracker.autoModulateSetSeq({ to: Crnt.song()?.prefs.autoModulate.seq() });
  },
});
