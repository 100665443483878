<script lang="ts">
  import { isUndefined } from '@sindresorhus/is';
  import { fade, fly } from 'svelte/transition';
  import type { PresetEditorModes } from '@/band/Band';
  import type { BandPreset } from '@/band/presets/BandPreset';
  import { presetGenresById } from '@/band/presets/presetGenres';
  import AddNewPresetPanel from '@/band/ui/panels/AddNewPresetPanel.svelte';
  import PresetHeaderRenameForm from '@/band/ui/panels/PresetHeaderRenameForm.svelte';
  import ActionsMenuForDefaultPreset from '@/band/ui/presets/ActionsMenuForDefaultPreset.svelte';
  import ActionsMenuForSharedPreset from '@/band/ui/presets/ActionsMenuForSharedPreset.svelte';
  import ActionsMenuForUserPreset from '@/band/ui/presets/ActionsMenuForUserPreset.svelte';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import { rpcCountSongsUsingBandPreset } from '@/server/methods/band/rpcCountSongsUsingBandPreset';
  import Icon from '@/ui/icons/Icon.svelte';
  import { MultiPanelModalController } from '@/ui/settings/MultiPanelModalController.svelte';
  import { slideFade } from '@/ui/svelte-transitions';

  const modalController = MultiPanelModalController.current();

  const bandMenu = getBandMenuState();

  let band = $derived($bandMenu.band);

  let preset: BandPreset | undefined = $state();
  trackerEffect(() => (preset = band.preset()));

  let presetMode: PresetEditorModes = $state();
  trackerEffect(() => (presetMode = band.presetMode()));

  let songsWithPreset: undefined | number = $state();
  trackerEffect(() => {
    if (preset && !preset.default && presetMode == 'delete' && isUndefined(songsWithPreset)) {
      void rpcCountSongsUsingBandPreset({ presetId: preset.id }).then(
        // @ts-expect-error This is a bug with zodern:relay that will be fixed in a later version
        (count) => (songsWithPreset = count)
      );
    } else {
      songsWithPreset = undefined;
    }
  });

  let bandHasInstruments: boolean = $state(true);
  trackerEffect(() => (bandHasInstruments = band.activeInstruments().length > 0));

  let genre = $derived(preset?.genreId ? presetGenresById[preset.genreId] : undefined);

  let presetNameWithoutGenre = $derived(
    genre ? preset?.name.match(new RegExp(`^${genre.name}\\s?\\W\\s+(.+)$`))?.[1] || '' : ''
  );

  let timeSignature = $derived($bandMenu.timeSignature);
  let friendlyTimeSignature = $derived(
    timeSignature == '4/4' ? 'standard time' : `${timeSignature} time`
  );
</script>

<div class="relative z-10 px-4 transition-all">
  <div
    class="flex min-h-[5rem] items-center rounded-t-3xl border border-panelWellBorder bg-white
      py-3 pl-3.5 pr-3 transition-all"
  >
    {#if preset && presetMode == 'rename'}
      <div class="flex-1">
        <PresetHeaderRenameForm {preset} />
      </div>
    {:else}
      <div class="flex-0 grid grid-cols-1 grid-rows-1 pr-2 [&_svg]:block">
        {#key preset?.id ?? ''}
          <div
            in:fly={{ y: 30, duration: 400 }}
            out:fly={{ y: -15, duration: 300 }}
            class="col-span-full row-span-full"
          >
            {#if !preset}
              <Icon id="preset-song-specific" size={36} />
            {:else if preset.default}
              <Icon id="preset-default" size={36} />
            {:else if preset.settingsFromAuthor}
              <Icon id="preset-shared-single" size={36} />
            {:else if preset.builtIn}
              <Icon id="preset-builtin" size={36} />
            {:else}
              <Icon id="preset-user-single" size={36} />
            {/if}
          </div>
        {/key}
      </div>
      <div class="flex-1">
        {#key presetMode == 'rename' ? 'rename' : (preset?.id ?? '')}
          <h1
            transition:slideFade
            class="text-balance pb-0.5 text-lg font-medium leading-6 text-gray-800"
          >
            {#if preset?.default}
              <span class="whitespace-nowrap">
                Default Band
                {#if presetMode != 'edit' && preset?.customizedDefault}
                  <span class="whitespace-nowrap text-base font-normal leading-5 text-gray-500">
                    (edited)
                  </span>
                {/if}
              </span>
              {#if presetMode == 'edit'}
                <div class="text-sm font-normal text-gray-500" transition:slideFade>
                  (for songs in {friendlyTimeSignature})
                </div>
              {:else if timeSignature != '4/4'}
                <div class="text-sm font-normal text-gray-500" transition:slideFade>
                  for {friendlyTimeSignature}
                </div>
              {/if}
            {:else if preset}
              {#if presetNameWithoutGenre && genre}
                <div class="pt-1 text-sm leading-4 text-gray-600">
                  {genre.name}:
                </div>
                {presetNameWithoutGenre}
              {:else}
                {preset.name}
              {/if}
            {:else}
              <span class="whitespace-nowrap">Custom Band</span>
              <div class="whitespace-nowrap text-sm font-normal leading-5 text-gray-500">
                Saved with this song
              </div>
            {/if}
          </h1>
        {/key}
      </div>
    {/if}

    {#if preset && !preset.builtIn && !presetMode}
      <div class="flex shrink-0 justify-end pl-3">
        {#if preset.default}
          <ActionsMenuForDefaultPreset {preset} />
        {:else if preset.settingsFromAuthor}
          <ActionsMenuForSharedPreset />
        {:else}
          <ActionsMenuForUserPreset {preset} />
        {/if}
      </div>
    {/if}

    {#if !preset && !presetMode}
      <div transition:fade class="relative flex shrink-0 justify-end">
        <button
          class="btn-base absolute right-0 top-1/2 mr-1 flex -translate-y-1/2 items-center gap-1 rounded-md px-3 py-2 pl-2 text-sm font-medium leading-4 {bandHasInstruments
            ? 'bg-primary-50 text-primary-600 dsktp:hover:bg-primary-100'
            : 'bg-gray-50 text-gray-400'}"
          disabled={!bandHasInstruments}
          onclick={() => {
            modalController.zoomInto({
              component: AddNewPresetPanel,
              title: 'Add Preset to Library',
            });
          }}
        >
          <Icon id="add-button-icon" size={16} />
          Preset
        </button>
      </div>
    {/if}

    {#if presetMode == 'delete' && songsWithPreset}
      <div in:fade class="relative flex shrink-0 justify-end">
        <div class="absolute right-0 top-1/2 mr-1 -translate-y-1/2">
          <div class="text-center leading-5 text-gray-700">
            <div class="whitespace-nowrap">Used in</div>
            <div class="whitespace-nowrap">
              <span class="text-lg font-bold leading-4 text-red-900">{songsWithPreset}</span>
              {songsWithPreset == 1 ? 'song' : 'songs'}
            </div>
          </div>
        </div>
      </div>
    {/if}
  </div>
</div>
