import * as Sentry from '@sentry/browser';

var $soloModal = null; // Used when allowMultiple = false.
var hiding = false;
var nextShowArgs = null;
var modalCount = new ReactiveVar(0);
var modalObjects = [];

window.Modal = {
  allowMultiple: false,

  visible() {
    return modalCount.get() > 0;
  },

  show(templateName, data, options) {
    if (!($soloModal == null || this.allowMultiple)) {
      if (hiding) nextShowArgs = [templateName, data, options];
      return;
    }
    var view = Blaze.renderWithData(Template[templateName], data, document.body);
    var domRange = view._domrange; // TODO: Don't violate against the public API.
    var $modal = domRange.$('.modal');
    $modal.on('shown.bs.modal', (event) => {
      $modal.find('[autofocus]').focus();
    });
    $modal.on('hidden.bs.modal', (event) => {
      Blaze.remove(view);
      $soloModal = null;
      modalCount.set(modalCount.get() - 1);
      modalObjects[templateName] = null;
      hiding = false;
      if (nextShowArgs) {
        // prevent nextShowArgs from being closure-d
        const args = nextShowArgs;
        nextShowArgs = null;
        this.show(...args);
      }
    });
    modalObjects[templateName] = $modal;
    $soloModal = $modal;
    $modal.modal(options || {});
    modalCount.set(modalCount.get() + 1);
    Sentry.addBreadcrumb({
      category: 'ui.modal',
      message: `Opened "${templateName}" modal`,
      data,
      level: 'info',
      type: 'user',
    });
  },

  hide(template) {
    hiding = true;
    if (template instanceof Blaze.TemplateInstance) {
      template.$('.modal').modal('hide');
    } else if (modalObjects[template]) {
      modalObjects[template].modal('hide');
    } else if ($soloModal != null) {
      $soloModal.modal('hide');
    } else {
      return; // so as not to add the breadcrumb
    }
    Sentry.addBreadcrumb({
      category: 'ui.modal',
      message: 'Closed modal',
      level: 'info',
      type: 'user',
    });
  },
};
