const brandsInStripe = {
  'amex': 'American Express',
  'diners': 'Diners Club',
  'discover': 'Discover',
  'jcb': 'JCB',
  'mastercard': 'MasterCard',
  'unionpay': 'UnionPay',
  'visa': 'VISA',
  'unknown': 'Unknown',
};

export function stripeCardBrandMadeFriendly(card: string): string {
  return card in brandsInStripe ? brandsInStripe[card as keyof typeof brandsInStripe] : card;
}
