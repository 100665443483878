import { strict as assert } from 'assert';
import type { BassIntent } from '@/band/instruments/bass/BassIntent';
import type { LinearizedMeasure } from '@/chart/LinearizedMeasure';

export function distributeBassIntentsToMeasure(
  intents: (BassIntent | null)[],
  measure: LinearizedMeasure
) {
  for (let i = 0; i < measure.beats.length; i++) {
    const beat = measure.beats[i];
    assert(beat);
    beat.plans.bass ??= {};

    if (/8$/.test(measure.timeSignature)) {
      beat.plans.bass.onbeatIntent = intents[i] ?? undefined;
    } else {
      beat.plans.bass.onbeatIntent = intents[i * 2] ?? undefined;
      beat.plans.bass.offbeatIntent = intents[i * 2 + 1] ?? undefined;
    }
  }
}
