import type { Cell } from '@/chart/Cell.svelte';
import type { Song } from '@/chart/Song';
import { Conductor } from '@/Conductor';

export function reactiveDragSelectClasses({ cell, song }: { cell: Cell; song: Song }): string[] {
  const [firstCell, lastCell] = [song.dragSelection.firstCell(), song.dragSelection.lastCell()];
  if (!firstCell) return [];
  if (!song.dragSelection.hasCell(cell)) return [];
  const dragSelectClasses = [];
  if (song.dragSelection.spansMultipleCells()) {
    dragSelectClasses.push('dragSelect');
    if (firstCell == cell) {
      dragSelectClasses.push('dragSelect-start');
    }
    if (lastCell == cell) {
      dragSelectClasses.push('dragSelect-end');
    }
  } else if (firstCell == cell) {
    dragSelectClasses.push('dragSelect-only');
  }
  return dragSelectClasses;
}

export function reactiveFocusClasses({ cell, song }: { cell: Cell; song: Song }): string[] {
  if (!song.editMode()) return [];
  if (song.dragSelection.exists()) return [];
  if (!song.focus.hasCell(cell)) return [];
  const focusClasses = [];
  focusClasses.push('focused');
  if (song.focus.spansMultipleCells()) {
    focusClasses.push('focused-range');
    if (song.focus.firstCell() == cell) {
      focusClasses.push('focused-start');
    }
    if (song.focus.lastCell() == cell) {
      focusClasses.push('focused-end');
    }
  } else {
    const beat = song.focus.focusedBeat();
    if (typeof beat === 'number') {
      focusClasses.push('focused-beat');
      focusClasses.push('focused-beat-' + beat);
    } else {
      focusClasses.push('focused-single');
    }
  }
  return focusClasses;
}

export function reactiveLoopClasses({ cell, song }: { cell: Cell; song: Song }): string[] {
  if (!song) return [];
  if (song.editMode()) return [];
  if (song.dragSelection.spansMultipleCells()) return [];
  if (!song.loop.exists()) return [];
  const loopClasses = [];
  if (song.loop.hasCell(cell)) {
    loopClasses.push('loop');
    if (song.loop.firstCell() == cell) {
      loopClasses.push('loop-start');
    }
    if (song.loop.lastCell() == cell) {
      loopClasses.push('loop-end');
    }
  } else {
    loopClasses.push('not-loop');
  }
  return loopClasses;
}

export function reactivePlaybackClasses({ cell, song }: { cell: Cell; song: Song }): string[] {
  const thisPos = [cell.layout.sectionIndex, cell.layout.cellIndex].join(',');

  const startPos = Conductor.displayState.startingPosition();
  if (startPos && startPos.song() === song.index()) {
    const current = `${startPos.section()},${startPos.cell()}`;
    return thisPos == current ? ['starting-here'] : [];
  }

  const pos = Conductor.displayState.position();
  if (pos && pos.song() === song.index()) {
    const current = `${pos.section()},${pos.cell()}`;
    if (thisPos == current) {
      return pos.beat() > 0 ? ['current', 'off-beat'] : ['current'];
    }
  }

  return [];
}

export function reactiveStartAnimationDuration(cell: Cell): string | undefined {
  const startPos = Conductor.displayState.startingPosition();
  if (!startPos) return;
  const thisPos = [cell.layout.sectionIndex, cell.layout.cellIndex].join(',');
  const current = `${startPos.section()},${startPos.cell()}`;
  if (current != thisPos) return;
  return Conductor.beatDuration().toFixed(6);
}
