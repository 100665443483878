import { z } from 'zod';
import type { InstrumentSetting } from '@/band/instruments/InstrumentSetting';
import { bounded } from '@/utilities/bounded';
import cloneJSON from '@/utilities/cloneJSON';

const serializedSchema = z.object({
  s: z.number().min(0).max(1).optional(),
});

type Serialization = z.infer<typeof serializedSchema>;

export class GuitarDampenedOnbeatsSetting implements InstrumentSetting<Serialization> {
  readonly spread: number;
  readonly singleNote: boolean;
  readonly shortStrum: boolean;
  readonly fullStrum: boolean;

  constructor(input: unknown = {}) {
    const data = serializedSchema.catch({}).parse(input);
    this.spread = data.s ?? 1;
    this.singleNote = data.s === 0;
    this.shortStrum = this.spread > 0 && this.spread <= 0.75;
    this.fullStrum = this.spread > 0.75;
  }

  closeTo(serialized?: Serialization) {
    const other = new GuitarDampenedOnbeatsSetting(serialized);
    return Math.abs(other.spread - this.spread) <= 0.01;
  }

  serialize(): Serialization {
    return cloneJSON({ s: this.spread });
  }

  withSpread(value: number) {
    return new GuitarDampenedOnbeatsSetting({
      's': bounded(value, 0, 1),
    });
  }
}
