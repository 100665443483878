import type { BuiltInPresetDefinition } from '@/band/presets/BuiltInPresetDefinition';

export const jazzSwingPresetDefinitions: Partial<Record<TimeSignature, BuiltInPresetDefinition[]>> =
  {
    // --- 4/4 ---

    '4/4': [
      {
        id: 'sw-b',
        name: 'Swing',
        genreId: 'jzsw',
        genreCategoryId: 'jzsw-n',
        description: 'Basic all-downstrokes swing rhythm with walking bass.',
        band: {
          'sw': '2:1',
          'g_c': 'd',
          'g_dot': { 's': 1 },
          'g_dss': { 'b1': 1, 'b2': 0 },
          'g_df': { 'e': 4 / 12 },
          'b_c': 'wb',
          'm_c': 'd',
          'm_ds': { 'b1': 1 / 3, 'b2': 1 / 6 },
          'm_db': { 'v': 1 / 2 },
        },
      },

      // {
      //   id: 'sw-bl',
      //   name: 'Swing - Ballad',
      //   genreId: 'jzsw',
      //   genreCategoryId: 'jzsw-n',
      //   description: 'Basic all-downstrokes swing rhythm.',
      //   band: {
      //     'sw': '2:1',
      //     'g_c': 'd',
      //     'g_dot': { 's': 1 },
      //     'g_dss': { 'b1': 1, 'b2': 1 / 3 },
      //     'g_db': { 'v': 1 / 6 },
      //     'b_c': 'wb',
      //     'm_c': 'd',
      //     'm_ds': { 'b1': 2 / 3, 'b2': 1 / 3 },
      //     'm_db': { 'v': 1 / 6 },
      //   },
      // },

      {
        id: 'ws-b',
        name: 'Western Swing',
        genreId: 'jzsw',
        genreCategoryId: 'jzsw-ws',
        description:
          'Single bass notes and dampened strums. Walking bass lines and 6th chord voicings must be defined in the song editor.',
        band: {
          'sw': '2:1',
          'g_c': 'd',
          'g_dot': { 's': 0 },
          'g_dss': { 'b1': 1, 'b2': 0 },
          'g_db': { 'v': 0 },
          'g_df': { 'e': 4 / 12 },
          'm_e': false,
          'm_c': 'd',
          'm_ds': { 'b1': 1, 'b2': 0 },
          'm_db': { 'v': 1 / 6 },
        },
      },

      // {
      //   id: 'ws-m',
      //   name: 'Western Swing - Mid-Tempo',
      //   genreId: 'jzsw',
      //   genreCategoryId: 'jzsw-ws',
      //   description:
      //     'As above, but with standup bass notes on every beat and more guitar upstroke fills.',
      //   band: {
      //     'sw': '2:1',
      //     'g_c': 'd',
      //     'g_dot': { 's': 0 },
      //     'g_dss': { 'b1': 1, 'b2': 0 },
      //     'g_db': { 'v': 0 },
      //     'g_df': { 'v': 0.5 },
      //     'b_c': 'wb',
      //     'm_e': false,
      //     'm_c': 'd',
      //     'm_ds': { 'b1': 1, 'b2': 1 / 3 },
      //     'm_db': {},
      //   },
      // },

      // {
      //   id: 'ws-sl',
      //   name: 'Western Swing - Slow',
      //   genreId: 'jzsw',
      //   genreCategoryId: 'jzsw-ws',
      //   description: 'Slower ballad strum with walking bass.',
      //   band: {
      //     'sw': '2:1',
      //     'g_c': 'd',
      //     'g_dot': { 's': 0.5 },
      //     'g_dss': { 'b1': 2 / 3, 'b2': 2 / 3 },
      //     'g_db': { 'v': 1 / 6 },
      //     'g_df': { 'e': 0.5 },
      //     'b_c': 'wb',
      //     'm_e': false,
      //     'm_c': 'd',
      //     'm_ds': { 'b1': 2 / 3, 'b2': 2 / 3 },
      //     'm_db': { 'v': 1 / 6 },
      //   },
      // },

      {
        id: 'gj-b',
        name: 'Gypsy Jazz',
        genreId: 'jzsw',
        genreCategoryId: 'jzsw-g',
        description: 'Something approaching a "La Pompe" style rhythm.',
        band: {
          'sw': '5:2',
          'g_c': 'd',
          'g_dot': { 's': 0.5 },
          'g_dss': { 'b1': 2 / 12, 'b2': 0 },
          'g_dup': { 'p': 'lp' },
          'g_df': { 'e': 0.375 },
          'b_c': 'wb',
          'm_c': 'd',
          'm_ds': { 'b1': 1 / 3, 'b2': 1 / 12 },
          'm_db': { 'v': 2 / 3 },
        },
      },
    ],

    // --- 3/4 ---

    '3/4': [
      {
        id: 'sw-w',
        name: 'Swing Waltz',
        genreId: 'jzsw',
        genreCategoryId: 'jzsw-n',
        description: 'Basic "boom chuck chuck" pattern with dampened closed chords.',
        band: {
          'sw': '2:1',
          'g_c': 'dw',
          'g_dot': { 's': 0 },
          'g_dss': { 'b1': 1, 'b2': 0.08 },
          'g_db': { 'v': 0 },
          'm_c': 'cw',
          'm_cd': { 'i': 0, 's': 1 },
          'm_ca': { 'r': 1, 'theta': 0.25 },
        },
      },
    ],
  };
