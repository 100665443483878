import { FlowRouter } from 'meteor/ostrio:flow-router-extra';

export function updateRouteMusicId(oldId: string, newId: string): void {
  if (FlowRouter.getParam('songId') == oldId) {
    FlowRouter.withReplaceState(() => {
      FlowRouter.setParams({ songId: newId });
    });
  } else if (FlowRouter.getParam('medleyId') == oldId) {
    FlowRouter.withReplaceState(() => {
      FlowRouter.setParams({ medleyId: newId });
    });
  }
}
