import { cubicOut } from 'svelte/easing';
import type { TransitionConfig } from 'svelte/transition';

export function slideFade(
  node: Element,
  { delay = 0, duration = 400, easing = cubicOut, axis = 'y', opacity = 0 } = {}
): TransitionConfig {
  const style = getComputedStyle(node);

  const target_opacity = +style.opacity;
  const od = target_opacity * (1 - opacity);
  const primary_property = axis === 'y' ? 'height' : 'width';
  const primary_property_value = parseFloat(style[primary_property]);
  const secondary_properties =
    axis === 'y' ? (['top', 'bottom'] as const) : (['left', 'right'] as const);
  const capitalized_secondary_properties =
    axis === 'y' ? (['Top', 'Bottom'] as const) : (['Left', 'Right'] as const);
  const padding_start_value = parseFloat(style[`padding${capitalized_secondary_properties[0]}`]);
  const padding_end_value = parseFloat(style[`padding${capitalized_secondary_properties[1]}`]);
  const margin_start_value = parseFloat(style[`margin${capitalized_secondary_properties[0]}`]);
  const margin_end_value = parseFloat(style[`margin${capitalized_secondary_properties[1]}`]);
  const border_width_start_value = parseFloat(
    style[`border${capitalized_secondary_properties[0]}Width`]
  );
  const border_width_end_value = parseFloat(
    style[`border${capitalized_secondary_properties[1]}Width`]
  );
  return {
    delay,
    duration,
    easing,
    css: (t, u) =>
      'overflow: hidden;' +
      `opacity: ${target_opacity - od * u};` +
      `${primary_property}: ${t * primary_property_value}px;` +
      `padding-${secondary_properties[0]}: ${t * padding_start_value}px;` +
      `padding-${secondary_properties[1]}: ${t * padding_end_value}px;` +
      `margin-${secondary_properties[0]}: ${t * margin_start_value}px;` +
      `margin-${secondary_properties[1]}: ${t * margin_end_value}px;` +
      `border-${secondary_properties[0]}-width: ${t * border_width_start_value}px;` +
      `border-${secondary_properties[1]}-width: ${t * border_width_end_value}px;` +
      `min-${primary_property}: 0`,
  };
}

export function slideFadeHorizontal(
  node: Element,
  {
    delay = 0,
    duration = 400,
    easing = cubicOut,
    opacity = 0,
  }: {
    delay?: number;
    duration?: number;
    easing?: (t: number) => number;
    opacity?: number;
  } = {}
): TransitionConfig {
  return slideFade(node, { delay, duration, easing, axis: 'x', opacity });
}
