import { Conductor } from '@/Conductor';
import { Crnt } from '@/Crnt';

export function setMediaSession({ songName }: { songName: string }): void {
  if (!('mediaSession' in navigator)) return;
  navigator.mediaSession.setActionHandler('play', () => void Conductor.play());
  navigator.mediaSession.setActionHandler('pause', () => Conductor.stop());
  navigator.mediaSession.setActionHandler('seekbackward', () => Crnt.song()?.prefs.adjustBpm(-5));
  navigator.mediaSession.setActionHandler('seekforward', () => Crnt.song()?.prefs.adjustBpm(5));
  // navigator.mediaSession.setActionHandler('previoustrack', function() {});
  // navigator.mediaSession.setActionHandler('nexttrack', function() {});
  navigator.mediaSession.metadata = songName
    ? new MediaMetadata({
        title: songName,
        artist: 'Strum Machine',
        album: 'backing track',
        artwork: [
          {
            src: '/meta-resources/pwa-icon-192x192-1de7e971.png',
            sizes: '192x192',
            type: 'image/png',
          },
          {
            src: '/meta-resources/pwa-icon-512x512-1de7e971.png',
            sizes: '512x512',
            type: 'image/png',
          },
        ],
      })
    : null;
}
