<script lang="ts">
  import { assertTruthy } from '@sindresorhus/is';
  import { RadioGroup } from 'bits-ui';
  import { onMount } from 'svelte';
  import type { BandPreset } from '@/band/presets/BandPreset';
  import { BandPresets } from '@/band/presets/BandPresets';
  import { legacyPresetsById } from '@/band/presets/legacyPresetsById';
  import type { presetGenres } from '@/band/presets/presetGenres';
  import BandRadioRow from '@/band/ui/components/BandRadioRow.svelte';
  import PanelModalLayout from '@/band/ui/panels/PanelModalLayout.svelte';
  import PresetCategoryBetaFooter from '@/band/ui/presets/PresetCategoryBetaFooter.svelte';
  import PresetPanelInstructionsForAdding from '@/band/ui/presets/PresetPanelInstructionsForAdding.svelte';
  import PresetPanelInstructionsForEditing from '@/band/ui/presets/PresetPanelInstructionsForEditing.svelte';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';
  import { eventTracker } from '@/browser/analytics/eventTracker';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import HelpCircleTooltip from '@/ui/HelpCircleTooltip.svelte';
  import Icon from '@/ui/icons/Icon.svelte';
  import { MultiPanelModalController } from '@/ui/settings/MultiPanelModalController.svelte';
  import { ClickTap } from '@/ui-helpers/click-tap';
  import { UserProfile } from '@/user/UserProfile';

  const modalController = MultiPanelModalController.current();

  const bandMenu = getBandMenuState();

  interface Props {
    genre?: (typeof presetGenres)[number] | undefined;
    userPresets?: boolean;
  }

  let { genre = undefined, userPresets = false }: Props = $props();

  let timeSignature = $derived($bandMenu.timeSignature);
  let friendlyTimeSignature = $derived(timeSignature == '4/4' ? 'standard' : timeSignature);

  let currentPreset: BandPreset | undefined = $state();
  trackerEffect(() => (currentPreset = $bandMenu.band.preset()));

  const showEditInstructions = false;

  let presets: BandPreset[] = $state([]);
  trackerEffect(
    () =>
      (presets = userPresets
        ? BandPresets.custom(timeSignature)
        : BandPresets.all(timeSignature).filter((p) => p.genreId == genre?.id))
  );

  let categories = $derived(
    [
      {
        id: 'user',
        label: genre
          ? `Your custom ${genre?.name} presets`
          : `Your saved presets in ${friendlyTimeSignature} time`,
        helpText: '',
        presets: presets.filter((p) => !p.builtIn && !p.default),
      },
      ...(genre
        ? genre.categories.map((c) => ({
            id: c.id,
            label: c.label,
            helpText: c.helpText,
            presets: presets.filter((p) => p.genreCategoryId == c.id),
          }))
        : []),
    ].filter((c) => c.presets.length > 0)
  );

  let optionsContainer: HTMLElement | null = $state(null);

  let elementToBringIntoView: Element | undefined = $state();
  onMount(() =>
    setTimeout(function scrollCurrentStyleIntoView() {
      elementToBringIntoView = optionsContainer?.querySelector(':checked') || undefined;
    }, 150)
  );

  onMount(() => UserProfile.recordMilestone('PRESETS_CATEGORY_OPEN'));

  let migratedLegacyPresets: BandPreset[] = $state([]);
  trackerEffect(
    () =>
      (migratedLegacyPresets = BandPresets.custom(timeSignature).filter(
        (p) => !!p.originalPresetId
      ))
  );

  // not reactive so it doesn't immediately disappear
  const hasSeenMessage =
    UserProfile.hasMilestone('LEGACY_PRESET_CATEGORY_MSG_SEEN') &&
    UserProfile.hasMilestone('LEGACY_PRESET_YP_MSG_SEEN');

  let showLegacyPresetMovedMessage = $state(false);
  $effect(() => {
    if (
      !hasSeenMessage &&
      (userPresets
        ? migratedLegacyPresets.length > 0
        : migratedLegacyPresets.some((p) => {
            const lp = legacyPresetsById[p.originalPresetId ?? ''];
            return !!lp && presets.some((p) => p.id == lp?.newPresetId);
          }))
    ) {
      showLegacyPresetMovedMessage = true;
      UserProfile.recordMilestone(
        userPresets ? 'LEGACY_PRESET_YP_MSG_SEEN' : 'LEGACY_PRESET_CATEGORY_MSG_SEEN'
      );
    }
  });

  // not using $derived because we're binding to this below
  let currentPresetId = $state('');
  $effect(() => {
    currentPresetId = currentPreset?.id ?? '';
  });

  function setPresetById(presetId: string) {
    const preset = presets.find((p) => p.id == presetId);
    assertTruthy(preset);
    $bandMenu.band.setPreset(preset);
    UserProfile.recordMilestone('PRESET_SET');
    eventTracker.bandPresetSet({
      presetId: preset.id,
      name: preset.name,
      isDefault: preset.default,
      isBuiltIn: preset.builtIn,
      isCustomizedDefault: preset.customizedDefault,
    });
  }
</script>

<PanelModalLayout
  showDoneButton={false}
  bottomFogHeight={50}
  overflowHidden={true}
  scrollMilestone="SCROLL_PRESETS"
  scrollHintLabel="Scroll for more presets"
  doneZoomsToRoot={true}
  {elementToBringIntoView}
>
  {#snippet header()}
    <header class="panelHeader pl-6 text-left">
      {#if categories.length > 0}
        Choose a preset to use for this song.
        {ClickTap} “Play” above to preview.
      {:else}
        <div class="px-3 pt-1">
          <div class="mb-2 text-lg font-medium text-gray-900">Add Your Own Presets</div>
          <div class="mb-3">
            When you turn a set of band customizations into a “preset”, you can easily apply those
            settings <span class="whitespace-nowrap">to any song.</span>
          </div>
          <ol class="ml-6 list-decimal marker:font-bold marker:text-gray-500">
            <li class="mb-2 pl-1">Adjust the band to your liking.</li>
            <li class="mb-2 pl-1">
              Go back to the band settings main menu and tap the “<Icon
                id="add-button-icon"
                size={12}
              /> Preset” button:
            </li>
          </ol>
        </div>
      {/if}

      {#if showLegacyPresetMovedMessage}
        <div class="-mx-2.5 mt-3 rounded-lg border border-yellow-200 bg-yellow-50 px-2.5 py-2">
          {#if userPresets}
            Your customized {migratedLegacyPresets.length > 1
              ? 'versions of any built-in presets have'
              : `version of the built-in ${legacyPresetsById[migratedLegacyPresets[0]?.originalPresetId ?? '']?.name} preset has`}
            been moved here, as built-in presets are now read-only.
          {:else}
            Built-in presets are now read-only. Your customized {migratedLegacyPresets.length > 1
              ? 'versions of any built-in presets have'
              : `version of the built-in ${legacyPresetsById[migratedLegacyPresets[0]?.originalPresetId ?? '']?.name} preset has`}
            been moved to <strong class="font-medium">Your Presets</strong>.
          {/if}
        </div>
      {/if}
    </header>
  {/snippet}

  {#snippet main()}
    <div>
      {#if categories.length > 0}
        <RadioGroup.Root
          bind:value={() => currentPresetId, setPresetById}
          bind:ref={optionsContainer}
          class="divide-y divide-panelWellBorder border-b border-panelWellBorder"
        >
          {#each categories as category (category.id)}
            <div class="px-6 pb-2 pt-3 text-sm font-medium text-gray-600">
              {category.label}
              {#if category.helpText}
                <HelpCircleTooltip text={category.helpText} />
              {/if}
            </div>
            {#each category.presets as preset (preset.id)}
              <BandRadioRow
                value={preset.id}
                doubleClickHandler={() => modalController.zoomOutToRoot()}
              >
                {#snippet children({ checked })}
                  <div class="flex flex-col py-0.5 pl-1" class:selected={checked}>
                    <div class="font-medium leading-4 text-gray-900">
                      {preset.name.replace(/-/g, '‑')}
                    </div>
                    {#if preset.description}
                      {@const textColor = checked ? 'text-gray-600' : 'text-gray-500'}
                      <div class="mr-2 mt-2 text-balance text-sm font-normal leading-5 {textColor}">
                        {preset.description}
                      </div>
                    {/if}
                  </div>
                {/snippet}
              </BandRadioRow>
            {/each}
          {/each}
        </RadioGroup.Root>
        <div class="pb-6"></div>
      {/if}
      {#if genre}
        <PresetCategoryBetaFooter {genre} {timeSignature}></PresetCategoryBetaFooter>
      {/if}
      {#if categories.length > 0 && userPresets && showEditInstructions}
        <div class="px-6 pt-2">
          <PresetPanelInstructionsForEditing />
        </div>
      {/if}
      {#if categories.length === 0 && userPresets}
        <div class="px-9 pt-2">
          <PresetPanelInstructionsForAdding />
        </div>
      {/if}
    </div>
  {/snippet}
</PanelModalLayout>
