import { Meteor } from 'meteor/meteor';
import { Tracker } from 'meteor/tracker';

export class ReactiveTimer {
  _dependency = new Tracker.Dependency();
  _intervalId?: number | null;

  constructor(interval?: number) {
    this._intervalId = null;

    if (interval) this.start(interval);
  }

  start(interval: number) {
    this.stop();
    this._intervalId = Meteor.setInterval(() => {
      this._dependency.changed();
    }, 1000 * interval);
  }

  stop() {
    if (this._intervalId) Meteor.clearInterval(this._intervalId);
    this._intervalId = null;
  }

  watch() {
    this._dependency.depend();
  }
}
