import { FMBCordovaPlugin } from '@/audio/engine/fmb/FMBCordovaPlugin';
import AudioPackageLoader from '@/audio/samples/AudioPackageLoader';

const resolverMap = new Map<string, Promise<void>>();

export default class FMBSampleLoader {
  static storeSample(args: { sampleId: string; packageId: string }): Promise<void> {
    const existingPromise = resolverMap.get(args.sampleId);
    const promise =
      existingPromise ??
      (async () => {
        const duration = await AudioPackageLoader.getDurationOfSample(args.sampleId);
        const file = await AudioPackageLoader.getFileForSample(args.sampleId);
        return FMBCordovaPlugin.storeSample({
          packageId: args.packageId,
          sampleId: args.sampleId,
          audioData: file.buffer,
          duration: duration,
        });
      })();
    if (!existingPromise) resolverMap.set(args.sampleId, promise);
    return promise;
  }
}
