import { MusicLibrary } from '@/library/MusicLibrary';

Template.medleySongPicker.helpers({
  ready() {
    return MusicLibrary.index.readyToSearch();
  },

  results() {
    const instance = Template.instance();
    return (
      instance.data.searcher.queryPresent() &&
      instance.data.searcher.results().filter((result) => !result.medley)
    );
  },

  queryText() {
    const instance = Template.instance();
    return instance.data.searcher.queryText();
  },
});

Template.medleySongPicker.events({
  'click a': async (event, instance) => {
    event.preventDefault();
    const songId = event.currentTarget.parentElement?.dataset.id;
    if (!songId) return;
    instance.data.songPickedFunc(songId);
  },
});
