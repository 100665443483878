import { ReactiveVar } from 'meteor/reactive-var';
import { eventTracker } from '@/browser/analytics/eventTracker';
import { Crnt } from '@/Crnt';
import { MusicLibrary } from '@/library/MusicLibrary';
import { UserProfile } from '@/user/UserProfile';

const bpmPhraseRegex = new RegExp(
  '\\b(\\d\\d\\d?)\\s*bpm\\b' + //  Match bpm phrase
    '(?!' + // only if it's not followed by...
    '[^[]*' + // any number of characters except opening bracket
    '\\]' + // followed by a closing bracket
    ')', // end of lookahead
  'ig'
);

const keyPhraseRegex = new RegExp(
  '\\bkey\\s*(?:of|:|=)\\s+([a-g][b#]?)' + //  Match key phrase
    '(?!' + // only if it's not followed by...
    '[^[]*' + // any number of characters except opening bracket
    '\\]' + // followed by a closing bracket
    ')', // end of lookahead
  'ig'
);

export class Notepad {
  constructor({ notes, visible }: { notes?: string; visible?: boolean } = {}) {
    this._notes.set(notes || undefined);
    this._visible.set(!!visible);
  }

  _notes = new ReactiveVar<string | undefined>(undefined);
  notes(): string | undefined {
    return this._notes.get();
  }

  linkedNotes(): string | undefined {
    return this.notes()
      ?.replace(bpmPhraseRegex, '[$&](#bpm=$1 "Set speed to $1 BPM")')
      .replace(keyPhraseRegex, (phrase, key) => `[${phrase}](#key=${key} "Set key to ${key}")`);
  }

  _visible = new ReactiveVar(false);
  visible(): boolean {
    return this._visible.get();
  }

  setNotes(notes: string): void {
    this._notes.set(notes);
    const songId = Crnt.song()?.id();
    if (songId) {
      void MusicLibrary.songs.saveAttributes(songId, { notes });
      UserProfile.recordMilestone('NOTEPAD_SAVE');
      eventTracker.notepadSave();
    }
  }

  setVisibility(value: boolean): void {
    this._visible.set(value);
    const songId = Crnt.song()?.id();
    if (songId) {
      void MusicLibrary.songs.saveAttributes(songId, { notesVisible: value ? true : null });
      UserProfile.recordMilestone(`NOTEPAD_${value ? 'SHOW' : 'HIDE'}`);
      value ? eventTracker.notepadShow() : eventTracker.notepadHide();
    }
  }
}
