import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import SongRecorder from '@/audio/recording/SongRecorder';
import { Conductor } from '@/Conductor';
import { Crnt } from '@/Crnt';
import { Roles } from '@/Roles';
import { UserProfile } from '@/user/UserProfile';

Template.playControlButton.onCreated(() => {
  Template.instance().trackFirstPlay = _.once(() => {
    clientModules.analytics.track(
      FlowRouter.getRouteName() == 'landing-page' ? 'LandingPageDemo.FirstPlay' : 'Song.FirstPlay',
      { songName: Crnt.song()?.name() }
    );
  });
});

Template.playControlButton.helpers({
  playControlButtonAttributes() {
    const attrs = {};
    const buttonClasses = ['playControlButton'];
    buttonClasses.push(Conductor.ready() ? 'ready' : 'not-ready');
    if (Conductor.ready()) {
      buttonClasses.push(
        Conductor.displayState.countIn()?.label != null ? 'counting' : 'not-counting'
      );
      buttonClasses.push(Conductor.playing() ? 'playing' : 'not-playing');
      buttonClasses.push(Conductor.paused() ? 'paused' : 'not-paused');
      buttonClasses.push(Conductor.stopped() ? 'stopped' : 'not-stopped');
      buttonClasses.push(Conductor.delayingStart() ? 'delaying' : 'not-delaying');
    }
    if (Conductor.playing()) {
      Template.instance().clicked = true;
    }
    buttonClasses.push(Template.instance().clicked ? 'clicked' : 'not-clicked');
    buttonClasses.push(SongRecorder.armed() ? 'recording' : 'not-recording');
    attrs.class = buttonClasses.join(' ');
    return attrs;
  },
  bigButtonDisabledAttr() {
    return !(Conductor.ready() && Roles.currentUserCanPlaySong(Crnt.song() || Crnt.medley()))
      ? 'disabled'
      : '';
  },
  bigButtonTitleAttr() {
    if (Roles.currentUserCanPlaySong(Crnt.song() || Crnt.medley()) && Conductor.ready()) {
      var title = Conductor.playing() ? 'Pause' : 'Play';
      if (!Crnt.song()?.editMode()) {
        title += Conductor.playing() ? ' (enter)' : ' (spacebar)';
      }
      return { title };
    }
  },
  sideButtonDisabledAttr() {
    return !Conductor.ready() || Conductor.stopped() || SongRecorder.armed() ? 'disabled' : '';
  },
  sideButtonsVisible() {
    return !Crnt.song()?.editMode() && !SongRecorder.armed();
  },
  countLabel() {
    return Conductor.displayState.countIn()?.label;
  },
  titleWhenPlayable(text) {
    if (Roles.currentUserCanPlaySong(Crnt.song() || Crnt.medley()) && Conductor.ready()) {
      return { title: text };
    }
  },
});

Template.playControlButton.events({
  'click .playControlBigButton': function (event, instance) {
    event.preventDefault();
    if (Roles.currentUserCanPlaySong(Crnt.song() || Crnt.medley())) {
      if (Conductor.ready()) {
        if (instance.$('.js-bigStopIcon').is(':visible')) {
          clientModules.analytics.track(`Song.${Conductor.playing() ? 'Stop' : 'Play'}`, {
            songName: Crnt.song()?.name(),
            method: 'button',
          });
          Conductor.playing() ? Conductor.stop() : Conductor.play();
        } else {
          clientModules.analytics.track(
            `Song.${Conductor.playing() ? (Conductor.displayState.countIn()?.index !== null ? 'Stop' : 'Pause') : 'Play'}`,
            { songName: Crnt.song()?.name(), method: 'button' }
          );
          Conductor.playing() ? Conductor.pause() : Conductor.play();
          if (Conductor.playing() && instance.$('.playControlBigButton').is(':focus')) {
            Meteor.setTimeout(() => instance.$('.playControlStopButton').focus(), 50);
          }
        }
        instance.trackFirstPlay();
        UserProfile.recordMilestone('PLAY');
      }
    } else {
      // Bootstrap popover will toggle itself
    }
  },
  'click .playControlPauseButton': function (event, instance) {
    event.preventDefault();
    clientModules.analytics.track('Song.Pause', {
      songName: Crnt.song()?.name(),
      method: 'button',
    });
    Conductor.pause();
  },
  'click .playControlStopButton': function (event, instance) {
    event.preventDefault();
    clientModules.analytics.track('Song.Stop', { songName: Crnt.song()?.name(), method: 'button' });
    Conductor.stop();
    if (Conductor.stopped() && instance.$('.playControlStopButton').is(':focus')) {
      Meteor.setTimeout(() => instance.$('.playControlBigButton').focus(), 20);
    }
  },
  'click .playControlRestartButton': function (event, instance) {
    event.preventDefault();
    clientModules.analytics.track('Song.Restart', {
      songName: Crnt.song()?.name(),
      method: 'button',
    });
    Conductor.goToBeginningOfSectionOrSong();
    instance.$('.playControlRestartButton').addClass('spin-back');
    Meteor.setTimeout(() => {
      instance.$('.playControlRestartButton').removeClass('spin-back');
    }, 800);
    if (instance.$('.playControlRestartButton').is(':focus')) {
      Meteor.setTimeout(() => instance.$('.playControlStopButton').focus(), 20);
    }
  },
  'keydown': function (event, instance) {
    if (event.key == 'Enter' || event.key == 'NumpadEnter') {
      clientModules.analytics.track(
        `Song.${
          Conductor.playing() ? (Conductor.displayState.countIn() ? 'Stop' : 'Pause') : 'Play'
        }`,
        { songName: Crnt.musicTitle(), method: 'Enter' }
      );
      Conductor.playing() ? Conductor.pause() : Conductor.play();
      return false;
    }
    Meteor.setTimeout(() => {
      if (
        Conductor.stopped() &&
        instance.$('.playControlStopButton, .playControlRestartButton').is(':focus')
      ) {
        Meteor.setTimeout(() => instance.$('.playControlBigButton').focus(), 20);
      }
    }, 50);
  },
  /*
  'touchstart .playControlBigButton, touchstart .playControlPauseButton, touchstart .playControlStopButton, touchstart .playControlRestartButton' ( event, instance ) {
    if ( Conductor.ready() ) {
      event.preventDefault();
      $(event.currentTarget).click();
    }
  }
  */
});
