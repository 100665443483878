import AudioManager from '@/audio/engine/AudioManager';
import type { MandolinChord } from '@/band/instruments/mandolin/MandolinChord';
import type { MandolinIntent } from '@/band/instruments/mandolin/MandolinIntent';
import type { PlayerInstruction } from '@/band/instruments/PlayerInstruction';
import { PlayerInstructionsArray } from '@/band/instruments/PlayerInstructionsArray';
import { randomPlusMinus } from '@/utilities/randomPlusMinus';

type ZeroThroughThree = 0 | 1 | 2 | 3;

type StringToVoice = [
  stringIndex: ZeroThroughThree,
  playingOrder: number,
  stringFret: string,
  stringAttentuation: number,
];

export function makeInstructionsFromMandolinIntent(
  intent: MandolinIntent,
  mandolinChord: MandolinChord
): PlayerInstruction[] {
  const instructions = new PlayerInstructionsArray('mandolin');

  const sustain = intent.chordDuration ?? (intent.action == 'chop' ? 0 : 10);
  const chordDb = intent.db + (intent.chordAttenuation ?? 0);

  const stringsToVoice: StringToVoice[] =
    sustain > 0
      ? calculateStringsToVoice({
          strings: mandolinChord.strings,
          spread: intent.chordSpread,
          bias: intent.action == 'upstroke' ? intent.bias || 'high' : 'low',
          direction: intent.action == 'upstroke' ? 'U' : 'D',
        })
      : [];

  if (intent.action == 'downstroke' || intent.action == 'upstroke') {
    instructions.muteUnmatchedStrings(mandolinChord.strings, { fadeDuration: 0.12 });

    const stringToStringDelay = 0.007;

    for (const stringToVoice of stringsToVoice) {
      const [stringIndex, playingOrder, stringFret, stringAttenuation] = stringToVoice;
      const offset = playingOrder * stringToStringDelay + randomPlusMinus(0.001);
      let sample = `m${singleNoteMask(4 - stringIndex, stringFret)} Z`;
      sample = randomAlt(sample);
      instructions.playSample(sample, {
        db: chordDb + stringAttenuation,
        offset,
      });
      if (sustain < 2) {
        instructions.muteSample(sample, {
          offset: offset + sustain,
          fadeDuration: Math.min(sustain * 2, 0.04),
        });
      } else if (intent.chordBeatDuration) {
        instructions.muteSample(sample, {
          beatOffset: intent.chordBeatDuration,
          fadeDuration: Math.min(sustain * 2, 0.04),
        });
      }
    }
    const foleyId =
      intent.action == 'upstroke'
        ? 'U' + (intent.bias == 'high' ? 't' : 'm') + 'v' + (intent.velocity ?? 2)
        : 'Dv' + (intent.velocity ?? 2);
    instructions.playSample(foleySample(foleyId), { db: intent.db + 10 });
  }

  if (intent.action == 'chop') {
    if (sustain > 0.05) {
      instructions.muteUnmatchedStrings('----', { fadeDuration: 0.06 });
    } else {
      instructions.muteUnmatchedStrings('----', { fadeDuration: 0.08, offset: -0.02 });
    }

    const stringToStringDelay = /* intent.opts?.stringToStringDelay ?? */ 0.0015;

    for (const stringToVoice of stringsToVoice) {
      const [stringIndex, playingOrder, stringFret, stringAttenuation] = stringToVoice;
      const frets = singleNoteMask(4 - stringIndex, stringFret);
      const offset = playingOrder * stringToStringDelay;

      if (sustain > 0.0) {
        instructions.playSample(`m${frets} C`, {
          db: chordDb + stringAttenuation,
          offset: offset,
        });
        instructions.muteSample(`m${frets} C`, {
          offset: offset + sustain,
          fadeDuration: sustain * 4,
        });
      }
    }

    const foleyId = 'C' + (intent.attack ?? 'normal')[0];
    instructions.playSample(foleySample(foleyId), { db: intent.db + 10 });
  }

  // Volume adjustment
  instructions.forEach((inst) => {
    if ('db' in inst && typeof inst.db == 'number') inst.db += 0;
  });

  return instructions;
}

const biasStringMappings = {
  low: [1, 2, 3, 4],
  midLow: [2, 1, 3, 4],
  midHigh: [3, 2, 1, 4],
  high: [4, 2, 1, 3],
} as const;

function calculateStringsToVoice({
  strings,
  spread,
  bias,
  direction,
}: {
  strings: string;
  spread: number;
  bias: 'low' | 'midLow' | 'midHigh' | 'high';
  direction?: 'D' | 'U';
}): StringToVoice[] {
  const up = direction == 'U';
  const stringsToVoice: StringToVoice[] = [];
  const stringBiases = biasStringMappings[bias];
  for (let strumIndex = 0, i = up ? 3 : 0; up ? i >= 0 : i <= 3; up ? i-- : i++, strumIndex++) {
    const fret = strings[i];
    if (!fret || fret == '_') continue;
    const attentuation = calculateStringAttentuation(stringBiases[i] as number, spread);
    if (isNaN(attentuation)) continue;
    stringsToVoice.push([i as ZeroThroughThree, strumIndex, fret, attentuation]);
  }
  return stringsToVoice;
}

function calculateStringAttentuation(stringBias: number, spread: number): number {
  if (stringBias > Math.ceil(spread)) {
    return NaN;
  } else if (stringBias > Math.floor(spread)) {
    if (spread % 1 < 0.25) return NaN;
    return -26 * (1 - Math.log10(1 + 9 * (spread % 1)));
  } else {
    return 0;
  }
}

function singleNoteMask(string: number, fret: string | number) {
  return `${'+'.repeat(4 - string)}${fret}${'+'.repeat(string - 1)}`;
}

function randomAlt(sample: string): string {
  if (sample.endsWith('Z') && AudioManager.sampleLibrary.has(sample + '^')) {
    const twoAlts = AudioManager.sampleLibrary.has(sample + '^^');
    sample =
      sample +
      (Math.random() > (twoAlts ? 0.666 : 0.5)
        ? twoAlts && Math.random() > 0.5
          ? '^^'
          : '^'
        : '');
  }
  return sample;
}

const foleySamplesAvailable = new Map<string, string[]>();
function foleySample(id: string): string {
  const availableSamples =
    foleySamplesAvailable.get(id) ||
    (() => {
      const sampleBase = `mF${id}`;
      const availableSamples = [];
      while (availableSamples.length < 15) {
        const suffix: string = String(availableSamples.length + 1).padStart(3, '0');
        const potentialSample = `${sampleBase}-${suffix}`;
        if (!AudioManager.sampleLibrary.has(potentialSample)) break;
        availableSamples.push(potentialSample);
      }
      foleySamplesAvailable.set(id, availableSamples);
      return availableSamples;
    })();
  return (
    availableSamples[Math.floor(Math.random() * availableSamples.length)] ?? 'UNKNOWN_FOLEY_SAMPLE'
  );
}
