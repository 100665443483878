import { MusicLibrary } from '@/library/MusicLibrary';
import waitUntilReactive from '@/utilities/waitUntilReactive';

const stripSongName = (name) =>
  name &&
  name
    .replace(/^(?:(?:The|A|An) )?(.+?)(?:Reel|Jig|Waltz|Hornpipe)?(?: +\[.+\])?$/i, '$1')
    .replace(/[^a-z0-9 ]/gi, '')
    .replace(/[- ]+/, ' ');

const getSongLabel = (name) => {
  const labelMatch = name && name.match(/ \[(.+)\]$/);
  return labelMatch ? labelMatch[1] : undefined;
};

export default class DupeChecker {
  constructor() {
    this.songId = new ReactiveVar(null);
    this.strippedSongName = new ReactiveVar('');
    this.resultSet = new ReactiveVar();

    waitUntilReactive(() => MusicLibrary.songs.ready())
      .then(() => {
        this.songArray = MusicLibrary.songs.getAllSongNamesForDupeCheck().map((song) => {
          return {
            _id: song._id,
            name: stripSongName(song.name),
            label: getSongLabel(song.name),
          };
        });
      })
      .then(() => import('fuse.js'))
      .then(({ default: Fuse }) => {
        this.fuse = new Fuse(this.songArray, {
          keys: ['name'],
          includeScore: true,
          distance: 0,
          threshold: 0.7,
        });

        Tracker.autorun(() => {
          const targetName = this.strippedSongName.get();
          if (!targetName) {
            this.resultSet.set(null);
            return;
          }

          const results = this.fuse.search(targetName.substring(0, 31));
          const targetLength = targetName.length;
          const targetFirstLetter = targetName[0].toLowerCase();

          this.resultSet.set(
            results
              .filter((r) => {
                // filter and permute items at the same time
                if (r.item._id == this.songId.get()) return false;
                if (targetFirstLetter !== r.item.name[0].toLowerCase()) return false;
                r.score +=
                  (0.3 * Math.max(Math.abs(r.item.name.length - targetLength) - 2, 0)) /
                  Math.max(r.item.name.length, targetLength);
                r.score *= 2.45;
                if (r.score >= 1.35) return false; // changed from >= 1 in Jan 2021
                r.item = MusicLibrary.index.getIndexRecord(r.item._id);
                return true;
              })
              .sort((a, b) => a.score - b.score)
          );
        });
      });
  }

  setSongId(id) {
    if (id != this.songId.curValue) {
      this.songId.set(id);
    }
  }

  setDesiredName(name) {
    name = stripSongName(name);
    if (name != this.strippedSongName.curValue) {
      this.strippedSongName.set(name);
    }
  }

  results() {
    return this.resultSet.get() || [];
  }
}
