import { Mongo } from 'meteor/mongo';

export const SUACollection = new Mongo.Collection<SUARecord>('songUserAttributes');
SUACollection.deny({ insert: () => true, update: () => true, remove: () => true });

// If you add to this, be sure to update GroundedSUACompressor.ts!
export interface SUARecord {
  _id: string;
  userId: string;
  songId: string;
  updatedAt: Date;
  tpm?: number;
  key?: string;
  capoFret?: number;
  notes?: string;
  notesVisible?: boolean;
  asDeltaT?: number;
  asReps?: number;
  asMaxT?: number;
  afReps?: number;
  amKeys?: 'a' | 'n' | 'n+' | 'c';
  amInc?: number;
  amSeq?: string;
  amReps?: number;
  band?: Partial<SerializedBandSettings>;
  presetId?: string | null;
}
