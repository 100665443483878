import { Tracker } from 'meteor/tracker';
import { updateRouteMusicId } from '@/browser/updateRouteMusicId';
import type { MedleyRecord } from '@/collections/MedleysCollection';
import { GroundedMedleysCompressor } from '@/library/GroundedMedleysCompressor';
import { OfflineMusicStorageBackend } from '@/library/OfflineMusicStorageBackend';
import type { OfflineSongBackend } from '@/library/OfflineSongBackend';
import localStorageDBNames from '@/local-db/localStorageDBNames';
import { callServerMethodWithoutRetry } from '@/utilities/callServerMethodWithoutRetry';

export class OfflineMedleyBackend extends OfflineMusicStorageBackend<any> {
  compressor = GroundedMedleysCompressor;
  constructor(offlineSongBackend: OfflineSongBackend) {
    super({
      compressor: GroundedMedleysCompressor,
      indexDBName: localStorageDBNames.medleysIndex,
      fullDataDBName: localStorageDBNames.medleysData,
      insertionsDBName: localStorageDBNames.medleysInserted,
      updatesDBname: localStorageDBNames.medleysUpdated,
      removalsDBName: localStorageDBNames.medleysRemoved,
    });

    // Don't allow sync while songs are unsynced
    Tracker.autorun(() => {
      this.preventSync.set(offlineSongBackend.pendingChanges() > 0);
    });
  }

  reviseSongId(oldId: string, newId: string): void {
    // We update records one at a time due to GroundedCollection limitation
    this.recordsToInsert.find({ 'medleySongs._id': oldId }).forEach((record: MedleyRecord) => {
      record.medleySongs.filter((song) => song._id == oldId).forEach((song) => (song._id = newId));
      this.recordsToInsert.update(record._id, record);
    });
    this.recordsToUpdate.find({ 'medleySongs._id': oldId }).forEach((record: MedleyRecord) => {
      record.medleySongs.filter((song) => song._id == oldId).forEach((song) => (song._id = newId));
      this.recordsToUpdate.update(record._id, record);
    });
  }

  protected async doServerInsert(record: MedleyRecord): Promise<string> {
    const oldId = record._id;
    const newId = await callServerMethodWithoutRetry('medleys.insert', record);
    if (oldId && oldId !== newId) {
      updateRouteMusicId(oldId, newId);
      this.groundedIndex.remove(oldId); // this will be resynced automatically from the publication
      this.fullDataStorage.delete(oldId);
    }
    return newId;
  }

  protected async doServerUpdate(record: MedleyRecord): Promise<void> {
    return void (await callServerMethodWithoutRetry('medleys.update', record));
  }

  protected async doServerRemove({ _id: medleyId }: { _id: string }): Promise<void> {
    return void (await callServerMethodWithoutRetry('medleys.delete', medleyId));
  }

  protected async getRecordsFromServer(recordIds: string[]): Promise<MedleyRecord[]> {
    if (recordIds.length === 0) return [];
    const result = await callServerMethodWithoutRetry('medleys.fetchMultiple', recordIds);
    return result.medleys;
  }
}
