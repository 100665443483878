import { z } from 'zod';
import type { InstrumentSetting } from '@/band/instruments/InstrumentSetting';
import cloneJSON from '@/utilities/cloneJSON';
import { rounded } from '@/utilities/rounded';

const serializedSchema = z.object({
  b1: z.number().min(0).max(1).optional(),
  b2: z.number().min(0).max(1).optional(),
});

type Serialization = z.infer<typeof serializedSchema>;

const presets = [
  { value: 0 / 12, caption: 'Super short' },
  { value: 1 / 12, caption: 'Short' },
  { value: 2 / 12, caption: 'Short-ish' },
  { value: 3 / 12, caption: '16th note' },
  { value: 4 / 12, caption: 'Triplet eighth' },
  { value: 5 / 12, caption: 'Almost eighth' },
  { value: 6 / 12, caption: 'Eighth note' },
  { value: 7 / 12, caption: 'Eighth plus' },
  { value: 8 / 12, caption: 'Triplet quarter' },
  { value: 9 / 12, caption: 'Dotted eighth' },
  { value: 10 / 12, caption: 'Almost full' },
  { value: 11 / 12, caption: 'Basically full' },
  { value: 12 / 12, caption: 'Full' },
];

const combinedPresets: { value: { b1: number; b2: number }; caption: string }[] = [
  { value: { b1: 0, b2: 0 }, caption: 'Super short (all beats)' },
  { value: { b1: 1 / 3, b2: 1 / 3 }, caption: 'Triplet eighths (all beats)' },
  { value: { b1: 2 / 3, b2: 2 / 3 }, caption: 'Triplet quarters (all beats)' },
  { value: { b1: 1, b2: 0 }, caption: 'Full / Super short' },
  { value: { b1: 1, b2: 1 / 12 }, caption: 'Full / Short' },
];

export class GuitarDampenedSustainSetting implements InstrumentSetting<Serialization> {
  readonly onbeatSustain: number;
  readonly offbeatSustain: number;

  constructor(input: unknown = {}) {
    const data = serializedSchema.catch({}).parse(input);
    // set properties from the serialized data here
    this.onbeatSustain = data.b1 ?? 1;
    this.offbeatSustain = data.b2 ?? 0;
  }

  closeTo(serialized?: Serialization) {
    const other = new GuitarDampenedSustainSetting(serialized);
    return (
      Math.abs(other.onbeatSustain - this.onbeatSustain) <= 0.05 &&
      Math.abs(other.offbeatSustain - this.offbeatSustain) <= 0.05
    );
  }

  serialize(): Serialization {
    return cloneJSON({
      b1: rounded(this.onbeatSustain, 2),
      b2: rounded(this.offbeatSustain, 2),
    });
  }

  withOnbeatSustain(value: number) {
    return new GuitarDampenedSustainSetting({
      'b1': value,
      'b2': this.offbeatSustain,
    });
  }

  withOffbeatSustain(value: number) {
    return new GuitarDampenedSustainSetting({
      'b1': this.onbeatSustain,
      'b2': value,
    });
  }

  get closestOnbeatPreset() {
    return presets.reduce((closest, preset) =>
      Math.abs(preset.value - this.onbeatSustain) < Math.abs(closest.value - this.onbeatSustain)
        ? preset
        : closest
    );
  }

  get closestOffbeatPreset() {
    return presets.reduce((closest, preset) =>
      Math.abs(preset.value - this.offbeatSustain) < Math.abs(closest.value - this.offbeatSustain)
        ? preset
        : closest
    );
  }

  get closestCombinedPreset() {
    return combinedPresets.reduce((closest, preset) =>
      Math.abs(preset.value.b1 - this.onbeatSustain) +
        Math.abs(preset.value.b2 - this.offbeatSustain) <
      Math.abs(closest.value.b1 - this.onbeatSustain) +
        Math.abs(closest.value.b2 - this.offbeatSustain)
        ? preset
        : closest
    );
  }

  static readonly presets = presets;
  static readonly combinedPresets = combinedPresets;
}
