import { eventTracker } from '@/browser/analytics/eventTracker';
import ToggleSwitch from '@/ui/ToggleSwitch';
import UserPreferences from '@/user/UserPreferences';

Template.optionToggle.onRendered(() => {
  var instance = Template.instance();
  instance.autorun(() => {
    instance.find('input[type=checkbox]').checked = UserPreferences.get(instance.data.key);
  });
  instance.toggleSwitch = new ToggleSwitch({ element: instance.find('.js-switch') });
});

Template.optionToggle.onDestroyed(() => {
  if (Template.instance().toggleSwitch) Template.instance().toggleSwitch.destroy();
});

Template.optionToggle.events({
  'change': function (event, instance) {
    const [key, value] = [instance.data.key, instance.find('input[type=checkbox]').checked];
    UserPreferences.set(key, value);
    eventTracker.preferenceSet({ key, value });
  },
});

Template.optionToggle.helpers({
  label() {
    const rawLabel = this.label || (this.key && UserPreferences.settings[this.key]?.label);
    if (rawLabel) return rawLabel;
  },
});
