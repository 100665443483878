import { assignWalkingBassLines } from '@/band/instruments/bass/assignWalkingBassLines';
import { ghost, pluck } from '@/band/instruments/bass/BassActions';
import type { BassCore } from '@/band/instruments/bass/BassCore';
import { bassNoteForBeat } from '@/band/instruments/bass/bassNoteForBeat';
import { bassNoteFromChromaIndex } from '@/band/instruments/bass/bassNoteFromChromaIndex';
import { swingOptions } from '@/band/swingOptions';
import { randomPlusMinus } from '@/utilities/randomPlusMinus';

export const WalkingBassCore: BassCore = {
  id: 'wb',
  title: 'Walking bass',

  timeSignatures: ['4/4'],
  equivilantCoreFor(timeSignature) {
    return undefined;
  },

  linkedSettingsKeys: [],
  otherSettingsKeys: [],

  swingCategory: 'jazz',
  defaultSwing: swingOptions['2:1'],

  presets: [
    {
      name: 'Walking bass',
      settings: {
        'onbeatNotes': {},
        'onbeatSustain': {
          'v': 1,
        },
      },
    },
  ],

  measurePreprocessing(measures, settings, _flags) {
    assignWalkingBassLines({ measures, settings });
  },

  processMeasure(measure, _settings) {
    const ghostThisMeasure = measure.onlyOneChord ? Math.random() < 0.25 : Math.random() < 0.5;
    let ghostBeat = Math.floor(Math.random() * 4);
    if (ghostBeat == 1) ghostBeat = 0;
    return measure.cells.flatMap((cell, cellIndex) => {
      if (cell.plans.bass?.walkingBassChromaIndices && !cell.effect && !measure.endOfSong) {
        return cell.plans.bass.walkingBassChromaIndices.flatMap((chromaIndex, beatIndex) => {
          const bassNote = bassNoteFromChromaIndex(chromaIndex);
          return [
            pluck(bassNote, 0.98, { db: 0 }),
            ghostThisMeasure && ghostBeat == beatIndex + cellIndex * 2
              ? ghost(chromaIndex > 1 ? 'a' : 'd', {
                  db: 0,
                  omitAboveTpm: 250 + randomPlusMinus(50),
                  omitBelowTpm: 90,
                })
              : null,
          ];
        });
      } else {
        const beat1ChromaIndex = measure.endOfSong
          ? undefined
          : cell.plans.bass?.walkingBassChromaIndices?.[0];
        return cell.beats.flatMap((beat, beatIndex) => {
          if (beat.rest) return [null, null];
          if (beat.beatInCell > 0 && !beat.chordChanged) return [null, null];
          const stringFret =
            beatIndex === 0 && typeof beat1ChromaIndex == 'number'
              ? bassNoteFromChromaIndex(beat1ChromaIndex)
              : bassNoteForBeat(beat, true, 1);
          const beatDuration = beat.stop
            ? 0.425
            : beat.diamond || (measure.endOfSong && cell.layout.barEnd)
              ? 0 // Infinite sustain
              : 2;
          return [pluck(stringFret, beatDuration, { db: 0 }), null];
        });
      }
    });
  },

  generateViz(settings) {
    return [
      { biasY: 0.9, thickness: 0.8 },
      null,
      { biasY: 0.7, thickness: 0.8 },
      null,
      { biasY: 0.5, thickness: 0.8 },
      null,
      { biasY: 0.4, thickness: 0.8 },
      null,
      { biasY: 0.2, thickness: 0.8 },
      null,
      { biasY: 0.3, thickness: 0.8 },
      null,
      { biasY: 0.4, thickness: 0.8 },
      null,
      { biasY: 0.5, thickness: 0.8 },
      null,
    ];
  },
};
