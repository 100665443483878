import { ClientManager } from '@/browser/ClientManager';

export async function checkOfflineStorageAvailable(): Promise<boolean> {
  if (!('indexedDB' in window)) return false;
  if ('cordova' in window) return true;
  if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
    try {
      await ClientManager.checkForSpace();
      return true;
    } catch (err) {
      return false;
    }
  }
  return false;
}
