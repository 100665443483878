import { z } from 'zod';
import type { InstrumentSetting } from '@/band/instruments/InstrumentSetting';
import { bounded } from '@/utilities/bounded';
import cloneJSON from '@/utilities/cloneJSON';
import { rounded } from '@/utilities/rounded';

const serializedSchema = z.object({
  v: z.number().min(-1).max(1).optional(),
});

type Serialization = z.infer<typeof serializedSchema>;

const presets = [
  { value: -3 / 3, caption: 'Booms much louder' },
  { value: -2 / 3, caption: 'Booms louder' },
  { value: -1 / 3, caption: 'Booms slightly louder' },
  { value: 0 / 3, caption: 'Equal booms & chucks' },
  { value: 1 / 3, caption: 'Chucks slightly louder' },
  { value: 2 / 3, caption: 'Chucks louder' },
  { value: 3 / 3, caption: 'Chucks much louder' },
];

export class GuitarBoomChuckBalanceSetting implements InstrumentSetting<Serialization> {
  /**
   * -1 = all boom, 0 = equal, 1 = all chuck
   */
  readonly value: number;

  readonly boomPower: number;
  readonly chuckPower: number;

  constructor(input: unknown = {}) {
    const data = serializedSchema.catch({}).parse(input);
    this.value = bounded(data.v ?? 0, -1, 1);
    this.boomPower = 0.75 - (this.value < 0 ? 0.25 * this.value : 0.5 * this.value);
    this.chuckPower = 0.75 + (this.value > 0 ? 0.25 * this.value : 0.5 * this.value);
  }

  serialize(): Serialization {
    return cloneJSON({
      v: rounded(this.value, 2) || undefined,
    });
  }

  withBalance(value: number) {
    return new GuitarBoomChuckBalanceSetting({
      ...this.serialize(),
      'v': value,
    });
  }

  closeTo(serialized?: Serialization) {
    const other = new GuitarBoomChuckBalanceSetting(serialized);
    return Math.abs(this.value - other.value) < 0.1;
  }

  get closestPreset() {
    return presets.reduce((closest, preset) =>
      Math.abs(preset.value - this.value) < Math.abs(closest.value - this.value) ? preset : closest
    );
  }

  static readonly presets = presets;
}
