export type JsonValue =
  | number
  | string
  | boolean
  | null
  | JsonValue[]
  | { [key: string]: JsonValue | undefined };

/**
 * Deep clone a JSON value; like JSON.parse(JSON.stringify(x)), but faster and typed better.
 */
export default function cloneJSON<T extends JsonValue>(x: T): T {
  if (typeof x !== 'object' || x === null) {
    return x;
  } else if (Array.isArray(x)) {
    //@ts-expect-error - TS is wrong here
    return x.map((e) => (typeof e !== 'object' || e === null ? e : cloneJSON(e)));
  } else {
    const ret: { [key: string]: JsonValue } = {};
    for (const k in x) {
      const v = x[k];
      if (typeof v == 'undefined') continue;
      ret[k] = typeof v !== 'object' || v === null ? v : cloneJSON(v);
    }
    return ret as T;
  }
}
