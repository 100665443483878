import { eventTracker } from '@/browser/analytics/eventTracker';
import { Crnt } from '@/Crnt';
import closeNearestTippy from '@/ui/dropdowns/closeNearestTippy';
import defineTippyDropdown from '@/ui/dropdowns/defineTippyDropdown';
import { UserProfile } from '@/user/UserProfile';

defineTippyDropdown(Template.listRingBar, {
  id: 'auto-menu',
  onShow() {
    eventTracker.autoAdvanceMenuOpen({ listId: Crnt.list()?._id });
    if (!Crnt.list().autoAdvanceEnabled()) {
      Crnt.list()?.enableAutoAdvance();
      eventTracker.autoAdvanceEnable();
    }
    UserProfile.recordMilestone('AUTO_ADVANCE_OPEN');
  },
});

Template.listRingBar.onCreated(() => {});

Template.listRingBar.helpers({
  currentList() {
    return Crnt.list();
  },

  nameWithoutLabel(name) {
    return name?.replace(/ +\[.+\]$/, '');
  },

  linkTo(item) {
    const listId = Crnt.list()?._id;
    return item && listId
      ? `/app/${item.medleySongs ? 'medleys' : 'songs'}/${item._id}?list=${listId}`
      : null;
  },

  getAutoPlay() {
    return Crnt.list()?.autoAdvanceAutoPlay();
  },

  setAutoPlay() {
    return (value) => {
      Crnt.list()?.setAutoPlay(value);
      value ? eventTracker.autoAdvancePlayEnable() : eventTracker.autoAdvancePlayDisable();
    };
  },
});

Template.listRingBar.events({
  'click .js-listModal': function (_event, _instance) {
    Modal.show('songListModal', { listId: Crnt.list()?._id });
    eventTracker.listNavOpenList({ listName: Crnt.list()?.name, listId: Crnt.list()?._id });
    return false;
  },

  'click .js-neighboringSongLink': function (event, _instance) {
    $('.musicPage-overlay').css('opacity', 1).show();
    eventTracker.listNavGoToNeighbor();
    event.currentTarget?.blur();
  },

  'click .js-toggleShuffle': function (_event, _instance) {
    Crnt.list()?.toggleShuffle();
    eventTracker.listNavToggleShuffle({ shuffling: Crnt.list()?.shuffling() });
  },

  'click .js-disable': function (event, _instance) {
    Crnt.list()?.disableAutoAdvance();
    closeNearestTippy(event.currentTarget);
    eventTracker.autoAdvanceDisable();
  },

  'click .js-reshuffle': function (event, _instance) {
    event.preventDefault();
    Crnt.list()?.reshuffle();
    eventTracker.listNavReshuffle();
  },
});
