import * as Sentry from '@sentry/browser';
import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { eventTracker } from '@/browser/analytics/eventTracker';
import { Crnt } from '@/Crnt';
import ReferenceEmbedDisplay from '@/references/ReferenceEmbedDisplay';
import SongReferencePlatforms from '@/references/SongReferencePlatforms';
import { Roles } from '@/Roles';
import closeNearestTippy from '@/ui/dropdowns/closeNearestTippy';
import { UserProfile } from '@/user/UserProfile';

const getFriendlyNameForDomain = (url) => {
  const domain = url.split('/')[2].replace('www.', '');
  if (/toneway\.com/i.test(domain)) return 'The ToneWay Project';
  if (/bluegrasslyrics\.com/i.test(domain)) return 'BluegrassLyrics.com';
  if (/bluegrassnet\.com/i.test(domain)) return 'BluegrassNet';
  if (/dropbox\.com/i.test(domain)) return 'Dropbox';
  if (/docs\.google\.com/i.test(domain)) return 'Google Docs';
  return domain;
};

Template.songMetadataReferences.onRendered(() => {
  const instance = Template.instance();

  const uihooks = {
    insertElement(node, next) {
      $(node).insertBefore(next).hide().slideDown({ duration: 200 });
    },
    removeElement(node) {
      $(node)
        .slideUp({ duration: 200 })
        .promise()
        .done(() => $(node).remove());
    },
  };
  instance.find('.songMetadataReferences')._uihooks = uihooks;

  UserProfile.recordMilestone('REFERENCES_OPEN');
  eventTracker.referencesMenuOpen();
});

Template.songMetadataReferences.helpers({
  titleFor(item) {
    if (item.type == 'w') {
      return getFriendlyNameForDomain(item.url);
    }
    if (item.type == 'l') {
      return `${item.artist} - ${item.instrument}`;
    }
    if (/[rl]/.test(item.type)) {
      return item.artist;
    }
    if (item.type == 'o' && /\/\/tunearch\.org\/wiki\/\w/i.test(item.url)) {
      return 'Traditional Tune Archive Entry';
    }
    if (item.type == 'o') {
      return item.title;
    }
  },
  providerFor(item) {
    if (item.type == 'w') return;
    if (SongReferencePlatforms.find((p) => p.urlRegex.test(item.url))?.svgIcon) return;
    if (item.type == 'o' && /\/\/tunearch\.org\/wiki\/\w/i.test(item.url)) {
      return item.title;
    }
    return getFriendlyNameForDomain(item.url);
  },
  svgIconFor(url) {
    return SongReferencePlatforms.find((p) => p.urlRegex.test(url))?.svgIcon;
  },
  isExternal(url) {
    return !ReferenceEmbedDisplay.supportsUrl(url);
  },
  categorizedReferences() {
    if (!Crnt.references()?.loaded()) return;
    return [
      {
        heading: 'Recordings',
        items: Crnt.references()
          ?.filter({ type: 'r' })
          .sort((a, b) =>
            a.artist
              .replace(/^(The|A|An) /i, '')
              .localeCompare(b.artist.replace(/^(The|A|An) /i, ''))
          ),
      },
      {
        heading: 'Lessons',
        items: Crnt.references()?.filter({ type: 'l' }),
      },
      {
        heading: 'Lyrics',
        isLyrics: true,
        items: Crnt.references()?.filter({ type: 'w' }),
      },
      {
        heading: 'Other',
        items: Crnt.references()?.filter({ type: 'o' }),
      },
    ].filter((cat) => cat.items.length > 0);
  },
  showMenuFor(item) {
    return item;
  },
  canMakePublic(item) {
    return (
      item && item.userId && Roles.userHasPermission(Meteor.userId(), 'references.makePublic', item)
    );
  },
  showPublicStatus(item) {
    return item && Roles.userHasPermission(Meteor.userId(), 'references.makePublic', item);
  },
  belongsToOtherUser(item) {
    return item && item.userId && item.userId !== Meteor.userId();
  },
  canHide(item) {
    return item && item.songId && item.userId !== Meteor.userId();
  },
  canDelete(item) {
    return item && Roles.userHasPermission(Meteor.userId(), 'references.remove', item);
  },
  ownSong() {
    return Crnt.song()?.userId() == Meteor.userId();
  },
  submittingSong() {
    return FlowRouter.getRouteName() == 'submit-song';
  },
});

Template.songMetadataReferences.events({
  'click .js-referenceLink': function (event, instance) {
    closeNearestTippy(event.currentTarget);
    if (ReferenceEmbedDisplay.supportsUrl(event.currentTarget.href)) {
      ReferenceEmbedDisplay.show(event.currentTarget.href);
      event.preventDefault();
      event.stopPropagation(); // else target="_system" handler will catch it
    }
  },
  'click .js-refMenuShow': function (event, instance) {
    const $parent = $(event.currentTarget).closest('.js-item');
    $parent.find('.js-refMenuShow').fadeOut({ duration: 200 });
    $parent.find('.js-refMenuActions').slideDown({ duration: 200 });
    return false;
  },
  'click .js-refMenuHide': function (event, instance) {
    const $parent = $(event.currentTarget).closest('.js-item');
    $parent.find('.js-refMenuShow').fadeIn({ duration: 200 });
    $parent.find('.js-refMenuActions').slideUp({ duration: 200 });
    return false;
  },
  'click .js-addReference': function (event, instance) {
    Modal.show('addReferenceModal', {});
    closeNearestTippy(event.currentTarget);
  },
  'click .js-makePublic': function (event, instance) {
    closeNearestTippy(event.currentTarget);
    const execute = () => {
      const referenceId = $(event.currentTarget)
        .closest('[data-reference-id]')
        .data('reference-id');
      Crnt.references()
        ?.makePublic(referenceId)
        .catch((err) => {
          Sentry.captureException(err);
          Bert.alert(err.reason, 'warning');
        });
    };
    if (FlowRouter.getRouteName() == 'new-song') {
      execute();
    } else {
      bootbox.confirm('Make public? Are you sure?', (result) => {
        if (result) execute();
      });
    }
  },
  'click .js-deleteReference': function (event, instance) {
    closeNearestTippy(event.currentTarget);
    const referenceId = $(event.currentTarget).closest('[data-reference-id]').data('reference-id');
    bootbox.confirm({
      message: 'Are you sure you want to delete this reference?',
      closeButton: false,
      className: 'center',
      buttons: {
        confirm: {
          label: 'Yes, Delete It',
          className: 'btn-danger',
        },
      },
      callback(result) {
        if (!result) return;
        Crnt.references()
          ?.delete(referenceId)
          .catch((err) => {
            Sentry.captureException(err);
            Bert.alert(err.reason, 'warning');
          });
      },
    });
  },
  'click .js-hideReference': function (event, instance) {
    closeNearestTippy(event.currentTarget);
    const referenceId = $(event.currentTarget).closest('[data-reference-id]').data('reference-id');
    bootbox.confirm({
      size: 'small',
      message: "Are you sure you want to hide this reference? You won't be able to unhide it.",
      closeButton: false,
      className: 'center',
      buttons: {
        confirm: {
          label: 'Yes, Hide It',
          className: 'btn-danger',
        },
      },
      callback(result) {
        if (!result) return;
        Crnt.references()
          ?.hide(referenceId)
          .catch((err) => {
            Sentry.captureException(err);
            Bert.alert(err.reason, 'warning');
          });
      },
    });
  },
});
