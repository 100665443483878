<script lang="ts">
  import PanelHeading from '@/band/ui/components/PanelHeading.svelte';
  import PanelSettingSection from '@/band/ui/components/PanelSettingSection.svelte';
  import PanelModalLayout from '@/band/ui/panels/PanelModalLayout.svelte';
  import { crntPreferences } from '@/client/crntPreferences.svelte';
  import HelpCircleTooltip from '@/ui/HelpCircleTooltip.svelte';
  import ToggleSwitch from '@/ui/ToggleSwitch.svelte';
  import UserPreferences from '@/user/UserPreferences';

  let settings: {
    countInEnabled: boolean;
    countInEveryTime: boolean;
    countInDouble: boolean;
    countInSpeak: boolean;
    countInDampenedStrum: boolean;
    countInVolume: number;
  } = $derived(crntPreferences());
</script>

<PanelModalLayout>
  {#snippet header()}
    <header>
      <PanelHeading title="Count-In Settings" padX={6}>
        {#snippet text()}
          These settings will apply to all songs.
        {/snippet}
      </PanelHeading>
    </header>
  {/snippet}

  {#snippet main()}
    <div class="relative">
      <div class="px-3 pb-3 pt-4">
        <PanelSettingSection>
          <div class="flex px-4 py-3">
            <div class="flex-1 text-gray-700">
              Enable count-ins
              <HelpCircleTooltip
                text="Toggles the “1, 2, 3, 4” count-in you hear when you first hit play."
              />
            </div>
            <div>
              <ToggleSwitch
                checked={settings.countInEnabled}
                on:change={({ detail: value }) => UserPreferences.set('countInEnabled', value)}
              />
            </div>
          </div>
        </PanelSettingSection>

        <div class="mb-4"></div>

        <div class:opacity-50={!settings.countInEnabled} inert={!settings.countInEnabled}>
          <PanelSettingSection title="When & How to Count">
            <div class="flex px-4 py-3">
              <div class="flex-1 text-gray-700">
                Count every repetition
                <HelpCircleTooltip
                  text="Do a count-in every time the song or looped section repeats."
                />
              </div>
              <div>
                <ToggleSwitch
                  checked={settings.countInEveryTime}
                  on:change={({ detail: value }) => UserPreferences.set('countInEveryTime', value)}
                />
              </div>
            </div>
            <div class="flex px-4 py-3">
              <div class="flex-1 text-gray-700">
                Double-length count-in
                <HelpCircleTooltip
                  text="Extend the count-in so that you have more time to get ready before the strumming starts."
                />
              </div>
              <div>
                <ToggleSwitch
                  checked={settings.countInDouble}
                  on:change={({ detail: value }) => UserPreferences.set('countInDouble', value)}
                />
              </div>
            </div>
          </PanelSettingSection>

          <div class="mb-4"></div>

          <PanelSettingSection title="What You Hear">
            <div class="flex px-4 py-3">
              <div class="flex-1 text-gray-700">
                Spoken count
                <HelpCircleTooltip text="E.g., “1, 2, 3, 4...”" />
              </div>
              <div>
                <ToggleSwitch
                  checked={settings.countInSpeak}
                  on:change={({ detail: value }) => UserPreferences.set('countInSpeak', value)}
                />
              </div>
            </div>
            <div class="flex px-4 py-3">
              <div class="flex-1 text-gray-700">
                Dampened strum
                <HelpCircleTooltip text="Strum with strings dampened during the count-in." />
              </div>
              <div>
                <ToggleSwitch
                  checked={settings.countInDampenedStrum}
                  on:change={({ detail: value }) =>
                    UserPreferences.set('countInDampenedStrum', value)}
                />
              </div>
            </div>
          </PanelSettingSection>
        </div>
      </div>
    </div>
  {/snippet}
</PanelModalLayout>
