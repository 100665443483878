import { rpcTouchLastVersion } from '@/server/methods/user/rpcTouchLastVersion';
import { UserProfile } from '@/user/UserProfile';
import Version from '@/Version';

const closeParentNotice = (parent) => {
  var $notice = $(parent).closest('.js-versionUpdateNotice');
  if ($notice) {
    $notice.find('.js-close').blur();
    $notice.slideUp();
  }
};

const initialLastSeenVersion = new ReactiveVar();

Meteor.startup(() => {
  Tracker.autorun((comp) => {
    if (Meteor.user('profile')?.profile) comp.stop();
    const lastSeenVersion = parseInt(Meteor.user('profile')?.profile?.lastSeenVersion, 10) || 0;
    initialLastSeenVersion.set(lastSeenVersion);
  });
});

const shouldShowNotice = () => {
  if (/support\+/.test(Meteor.user('emails')?.emails[0]?.address)) return; // exclude test accounts
  const currentVersion = Version.currentInteger;
  return (
    initialLastSeenVersion.get() &&
    currentVersion > initialLastSeenVersion.get() &&
    UserProfile.hasMilestone('PLAY')
  );
};

Template.indexPageVersionUpdateNotice.onRendered(() => {
  Meteor.setTimeout(() => {
    if (shouldShowNotice()) {
      rpcTouchLastVersion({ version: Version.current });
    }
  }, 2000);
});

Template.indexPageVersionUpdateNotice.events({
  'click .js-closeVersionUpdateNotice': function (event) {
    closeParentNotice(event.currentTarget);
  },
  'click .js-global-openAbout': function (event) {
    closeParentNotice(event.currentTarget);
    rpcTouchLastVersion({ version: Version.current });
  },
});

Template.indexPageVersionUpdateNotice.helpers({
  showNotice() {
    return shouldShowNotice();
  },
  currentVersion() {
    return Version.current;
  },
  currentVersionSummary() {
    if (/\w$/.test(Version.currentSummary)) return Version.currentSummary + '.';
    return Version.currentSummary;
  },
});
