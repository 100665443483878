<script lang="ts">
  import { Conductor } from '@/Conductor';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';

  interface Props {
    songIndex: number;
    reps: number;
  }

  let { songIndex, reps }: Props = $props();

  let isCurrentSong: boolean = $state(false);
  trackerEffect(
    () =>
      (isCurrentSong =
        (Conductor.displayState.position() || Conductor.displayState.startingPosition())?.song() ===
        songIndex)
  );

  let startingHereRound: number | undefined = $state();
  trackerEffect(() => (startingHereRound = Conductor.displayState.startingPosition()?.round()));

  let currentRound: number | undefined = $state();
  trackerEffect(() => (currentRound = Conductor.displayState.position()?.round()));

  let animationDurationStyle: string = $state('');
  trackerEffect(
    () => (animationDurationStyle = `animation-duration: ${Conductor.beatDuration()}s`)
  );

  function handleClick(_event: Event, { round }: { round: number }) {
    Conductor.playFrom({
      song: songIndex,
      round,
      section: 0,
      rep: 0,
      cell: 0,
      beat: 0,
    });
  }
</script>

{#each Array(reps) as _, round (round)}
  <span
    class="round round-{round}"
    class:starting-here={isCurrentSong && startingHereRound === round}
    class:current={isCurrentSong && currentRound === round}
    onclick={(e) => handleClick(e, { round })}
    data-round={round}
    style={animationDurationStyle}
    aria-hidden="true"
    title="Round #{round + 1}">{round + 1}</span
  >
{/each}
