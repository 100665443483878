import GlobalInstrumentsMixer from '@/audio/mixer/ui/GlobalInstrumentsMixer.svelte';
import MasterMixer from '@/audio/mixer/ui/MasterMixer.svelte';
import { UserProfile } from '@/user/UserProfile';

Template.mixerMenu.onRendered(() => {
  UserProfile.recordMilestone('INSTRUMENTS_MENU_OPEN');
});

Template.mixerMenu.helpers({
  GlobalInstrumentsMixer: () => GlobalInstrumentsMixer,
  MasterMixer: () => MasterMixer,
});
