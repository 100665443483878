<script lang="ts">
  import type { Cell } from '@/chart/Cell.svelte.ts';
  import type { Song } from '@/chart/Song';
  import { Crnt } from '@/Crnt';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import HelpCircleTooltip from '@/ui/HelpCircleTooltip.svelte';
  import ScalarInputWithTrimmers from '@/ui/ScalarInputWithTrimmers.svelte';
  import ToggleSwitch from '@/ui/ToggleSwitch.svelte';

  let song: Song | undefined = $state();
  trackerEffect(() => (song = Crnt.song()));

  let focusedCells: Cell[] | undefined = $state();
  trackerEffect(() => (focusedCells = song?.focus.cells()));

  let focusedCellsAreSplit: boolean | undefined = $derived(
    focusedCells?.every((c) => c.split == focusedCells?.[0]?.split)
      ? focusedCells[0]?.split
      : undefined
  );

  let focusedCellsAreHalf: boolean | undefined = $derived(
    focusedCells?.every((c) => c.half == focusedCells?.[0]?.half)
      ? focusedCells[0]?.half
      : undefined
  );

  const canHalf = true;
  let canSubdivide = $state(false);
  trackerEffect(() => (canSubdivide = !!song?.timeSignature().endsWith('4')));

  let sectionReps = $derived(focusedCells?.[0]?.section?.repetitions ?? 0);
  let activeVolta = $derived(focusedCells?.[0]?.volta ?? []);
  let value = $state(0);
  trackerEffect(() => {
    song?.sections.reactive();
    value = song?.withFocused().getLineLength() ?? 4;
  });

  let lineLengthControlData = $derived({
    value,
    unit: 'bar',
    min: 1,
    max: (() => (song?.timeSignature() == '9/8' ? 4 : 6))(),
    setValueFunc: (value: number) => song?.withFocused().setLineLength(value),
    adjustFunc: (value: number) => {
      song?.withFocused().setLineLength((song?.withFocused().getLineLength() || 4) + value);
    },
  });
</script>

<div class="flex w-full flex-col divide-y divide-gray-100 border-t border-gray-100">
  {#if canHalf}
    <div class="flex px-4 py-3">
      <div class="flex-1">
        Half-measure
        <HelpCircleTooltip
          text="Surrounds selected beat with barlines, and makes the next beat the start of a new bar. Used to control alternating bass notes. Shortcut: “H” key."
        />
      </div>
      <div class="">
        <ToggleSwitch
          checked={!!focusedCellsAreHalf}
          on:change={(e) => song?.withFocused().setHalf(e.detail)}
        />
      </div>
    </div>
  {/if}

  {#if canSubdivide}
    <div class="flex px-4 py-3">
      <div class="flex-1">
        Subdivide beat
        <HelpCircleTooltip text="Split selected beat into multiple chords (rarely needed)." />
      </div>
      <div class="">
        <ToggleSwitch
          checked={!!focusedCellsAreSplit}
          on:change={(e) =>
            e.detail ? song?.withFocused().split() : song?.withFocused().unsplit()}
        />
      </div>
    </div>
  {/if}

  <div class="flex px-4 py-3" hidden>
    <div class="flex-1">
      Time signature
      <HelpCircleTooltip text="" />
    </div>
    <div class="">
      <button
        class="btn"
        aria-pressed={false}
        onclick={() => song?.withFocused().setTimeSignature('4/4')}>4/4</button
      >
      <button
        class="btn"
        aria-pressed={false}
        onclick={() => song?.withFocused().setTimeSignature('3/4')}>3/4</button
      >
    </div>
  </div>

  <div class="flex items-center justify-between px-4 py-2">
    <div class="whitespace-nowrap">
      {#if sectionReps > 3}
        Endings
      {:else}
        Ending brackets
      {/if}
      <HelpCircleTooltip
        text="Also called ending variations or volta brackets, this allows you to have parts of a section only played the first time through, or second time through, and so on. {sectionReps <
        2
          ? 'You must make the section repeat to use this feature.'
          : ''}"
      />
    </div>
    <div class="">
      {#if sectionReps > 1}
        <div
          class="flex flex-wrap items-center justify-end gap-1 {sectionReps > 4
            ? 'max-w-[9rem]'
            : ''}"
        >
          {#each { length: sectionReps }, i}
            {@const voltaRep = i + 1}
            <button
              class="__voltaButton group rounded border border-gray-100 px-1.5 py-1.5 text-gray-600 outline-offset-2 transition-colors duration-150 hover:border-primary-200 hover:bg-primary-50 focus-visible:outline-2"
              aria-pressed={activeVolta.includes(voltaRep)}
              onclick={() =>
                activeVolta.includes(voltaRep)
                  ? song?.withFocused().removeVolta(voltaRep)
                  : song?.withFocused().addVolta(voltaRep)}
            >
              <div
                class="border-l border-t border-gray-300 px-1 text-sm leading-5 group-aria-pressed:border-gray-600"
              >
                {voltaRep}
              </div>
            </button>
          {/each}
        </div>
      {:else}
        <div class="p-0.5 text-center text-sm leading-4 text-gray-500">
          only available for repeated&nbsp;sections
        </div>
      {/if}
    </div>
  </div>

  <div class="flex items-center justify-between px-4 py-2">
    <div class="whitespace-nowrap">
      Line length
      <HelpCircleTooltip
        text="Set the maximum number of bars to display in the selected line(s). Change the overall line length by first selecting all chords. Shortcut: “L” key."
      />
    </div>
    <div class="">
      <ScalarInputWithTrimmers {...lineLengthControlData} />
    </div>
  </div>
</div>

<style>
  .__voltaButton[aria-pressed='true'] {
    box-shadow: inset 0 -13px 0 -10px var(--primary-600);
    color: var(--gray-900);
    border-color: var(--primary-600);
    font-weight: bold;
  }
</style>
