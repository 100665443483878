import { Blaze } from 'meteor/blaze';
import type { EJSONableProperty } from 'meteor/ejson';
import { Meteor } from 'meteor/meteor';
import { ReactiveDict } from 'meteor/reactive-dict';
import type { Tracker } from 'meteor/tracker';

type DOMRange = unknown;

let currentLayoutName: string | undefined = undefined;
let currentLayout:
  | (Blaze.View & { _domrange?: DOMRange; dataVar: { dep: Tracker.Dependency } })
  | undefined = undefined;
let currentRegions = new ReactiveDict();
let currentData: Record<string, unknown> = {};
let _isReady = false;
let _root: HTMLElement | undefined = undefined;

// Add declarations for internal Blaze APIs
declare module 'meteor/blaze' {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace Blaze {
    interface View {
      _templateInstance?: TemplateInstance;
      originalParentView?: View;
    }

    interface _DOMBackend {
      parseHTML(html: string): HTMLElement[];
      findBySelector(selector: string, context: Document): HTMLElement[];
    }

    const _DOMBackend: _DOMBackend;

    function _getTemplate(
      name: string,
      templateInstanceFunc: () => TemplateInstance | null
    ): Template;

    function _TemplateWith(
      data: Record<string, unknown>,
      contentFunc: () => Template
    ): View & { _domrange?: unknown; dataVar: { dep: Tracker.Dependency } };
  }
}

declare global {
  const Spacebars: {
    include(template: Blaze.Template | null | undefined): Blaze.Template;
  };
}

export const BlazeLayout = {
  setRoot,
  render,
  reset,
};

function setRoot(root: HTMLElement) {
  _root = root;
}

function render(layout: string, regions?: Record<string, EJSONableProperty>) {
  Meteor.startup(() => {
    // To make sure dom is loaded before we do rendering layout.
    // Related to issue #25
    if (!_isReady) {
      Meteor.defer(() => {
        _isReady = true;
        _render(layout, regions ?? {});
      });
    } else {
      _render(layout, regions ?? {});
    }
  });
}

function reset() {
  const layout = currentLayout;
  if (layout) {
    if (layout._domrange) {
      // if it's rendered let's remove it right away
      Blaze.remove(layout);
    } else {
      // if not let's remove it when it rendered
      layout.onViewReady(() => {
        Blaze.remove(layout);
      });
    }

    currentLayout = undefined;
    currentLayoutName = undefined;
    currentRegions = new ReactiveDict();
  }
}

function _regionsToData(
  regions: Record<string, EJSONableProperty>,
  data?: Record<string, unknown>
) {
  data ??= {};
  Object.entries(regions).forEach(([key, value]) => {
    currentRegions.set(key, value);
    data[key] = _buildRegionGetter(key);
  });

  return data;
}

function _updateRegions(regions: Record<string, EJSONableProperty>) {
  let needsRerender = false;
  // unset removed regions from the exiting data
  Object.keys(currentData).forEach((key) => {
    if (regions[key] === undefined) {
      currentRegions.set(key, undefined);
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete currentData[key];
    }
  });

  Object.entries(regions).forEach(([key, value]) => {
    // if this key does not yet exist then blaze
    // has no idea about this key and it won't get the value of this key
    // so, we need to force a re-render
    if (currentData && currentData[key] === undefined) {
      needsRerender = true;
      // and, add the data function for this new key
      currentData[key] = _buildRegionGetter(key);
    }
    currentRegions.set(key, value);
  });

  // force re-render if we need to
  if (currentLayout && needsRerender) {
    currentLayout.dataVar.dep.changed();
  }
}

function _getRootDomNode() {
  let root = _root;
  if (!root) {
    root = Blaze._DOMBackend.parseHTML('<div id="__blaze-root"></div>')[0] as HTMLElement;
    document.body.appendChild(root);
    setRoot(root);
  } else if (typeof root === 'string') {
    root = Blaze._DOMBackend.findBySelector(root, document)[0] as HTMLElement;
  }

  if (!root) {
    throw new Error('Root element does not exist');
  }

  return root;
}

function _buildRegionGetter(key: string) {
  return function () {
    return currentRegions.get(key);
  };
}

function _getTemplate(layout: string, rootDomNode: HTMLElement) {
  // using Blaze._getTemplate instead of directly accessing Template allows
  // packages like Blaze Components to hook into the process
  return Blaze._getTemplate(layout, () => {
    let view = Blaze.getView(rootDomNode);
    // find the closest view with a template instance
    while (view && !view._templateInstance) {
      view = view.originalParentView || view.parentView;
    }
    // return found template instance, or undefined
    return (view && view._templateInstance) || null;
  });
}

function _render(layout: string, regions: Record<string, EJSONableProperty>) {
  const rootDomNode = _getRootDomNode();
  if (currentLayoutName != layout) {
    // remove old view
    reset();
    currentData = _regionsToData(regions);

    currentLayout = Blaze._TemplateWith(currentData, () => {
      const template = _getTemplate(layout, rootDomNode);

      // 'layout' should be null/undefined (to render nothing) or an existing template name
      if (layout !== null && layout !== undefined && !template)
        console.warn('BlazeLayout warning: unknown template "' + layout + '"');

      return Spacebars.include(template);
    });

    Blaze.render(currentLayout, rootDomNode, undefined, Blaze.getView(rootDomNode));
    currentLayoutName = layout;
  } else {
    _updateRegions(regions);
  }
}
