export class LazyMap<K, V> extends Map<K, V> {
  promises = {};
  private resolverMap = new Map<
    K,
    { resolve: (value: V | PromiseLike<V>) => void; reject: (err: any) => void }[]
  >();

  getLazy(key: K): Promise<V> {
    const existing = this.get(key);
    if (existing) return Promise.resolve(existing);
    return new Promise((resolve, reject) => {
      const array = this.resolverMap.get(key) || [];
      array.push({ resolve, reject });
      this.resolverMap.set(key, array);
    });
  }

  set(key: K, value: V): this {
    Map.prototype.set.call(this, key, value);
    const resolvers = this.resolverMap.get(key) || [];
    resolvers.forEach(({ resolve }) => resolve(value));
    this.resolverMap.set(key, []);
    return this;
  }
}
