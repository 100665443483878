import type { SampleDefinition } from '@/audio/engine/base/Sample';

const bassSampleDefinitions: Array<SampleDefinition> = [
  { id: 'bass-g0' },
  { id: 'bass-g1' },
  { id: 'bass-g2' },
  { id: 'bass-g3' },
  { id: 'bass-g4' },
  { id: 'bass-g5' },
  { id: 'bass-d0' },
  { id: 'bass-d1' },
  { id: 'bass-d2' },
  { id: 'bass-d3' },
  { id: 'bass-d4' },
  { id: 'bass-d5' },
  { id: 'bass-a0' },
  { id: 'bass-a1' },
  { id: 'bass-a2' },
  { id: 'bass-a3' },
  { id: 'bass-a4' },
  { id: 'bass-a5' },
  { id: 'bass-e0' },
  { id: 'bass-e1' },
  { id: 'bass-e2' },
  { id: 'bass-e3' },
  { id: 'bass-e4' },
  { id: 'bass-e5' },
  { id: 'bass-g6' },
  { id: 'bass-g7' },
  { id: 'bass-drop-d' },
  { id: 'bass-stop-g' },
  { id: 'bass-stop-d' },
  { id: 'bass-stop-a' },
  { id: 'bass-stop-e' },
  { id: 'bass-stop-1' },
  { id: 'bass-stop-2' },
]
  .map((s) => {
    return { ...s, packageId: 'bass_2020-02', preroll: 0.065 };
  })
  .concat(
    ['g', 'd', 'a', 'e'].flatMap((letter) =>
      [1, 2, 3].flatMap((num) => ({
        id: `bass-ghost-${letter}-${num}`,
        packageId: 'bass-ghost_2024-11',
        preroll: 0,
      }))
    )
  );

export default bassSampleDefinitions;
