<script lang="ts" module>
  const savedBody = '';
</script>

<script lang="ts">
  import * as Sentry from '@sentry/browser';
  import { UAParser } from '@ua-parser-js/pro-business';
  import { Meteor } from 'meteor/meteor';
  import { onMount } from 'svelte';
  import { slide } from 'svelte/transition';
  import PanelModalLayout from '@/band/ui/panels/PanelModalLayout.svelte';
  import { rpcSendMessageToSupport } from '@/server/methods/contact/rpcSendMessageToSupport';
  import Version from '@/Version';

  let submitting = $state(false);
  let sent = $state(false);

  interface Props {
    instructions?: string;
  }

  let { instructions = '' }: Props = $props();

  let body = $state(savedBody);

  let diagnosticInfo: Record<string, string | number | boolean> = {};

  onMount(async () => {
    const parsedUa = await (await UAParser().withFeatureCheck()).withClientHints();
    let device = '';
    if (
      !parsedUa.device.vendor &&
      /^Mac/i.test(navigator.platform) &&
      navigator.maxTouchPoints > 1
    ) {
      device = 'Apple iPad';
    } else {
      device = `${parsedUa.device.vendor || ''} ${parsedUa.device.model || ''}`.trim();
      // if (parsedUa.device.type) device += ` (${parsedUa.device.type})`;
      device = device.trim();
    }
    diagnosticInfo = {
      browser: `${parsedUa.browser.name || ''} ${parsedUa.browser.version || ''}`.trim(),
      os: `${parsedUa.os.name || ''} ${parsedUa.os.version || ''}`.trim(),
      cordova: 'cordova' in window,
      android: /Android/i.test(navigator.userAgent),
      smVersion: Version.current,
      device,
    };

    if (Meteor.isCordova) {
      document.addEventListener(
        'deviceready',
        // eslint-disable-next-line
        async () => {
          cordova.plugins.SMExtras.getBuildInfo?.((info) => {
            diagnosticInfo.appVersion = info?.version;
          });

          if ('device' in window && (window.device as any).isiOSAppOnMac)
            diagnosticInfo.device = 'macOS (Apple Silicon)';
        },
        false
      );
    }
  });

  function submit() {
    if (!body) return;
    submitting = true;
    (async () => {
      try {
        const subject = 'Band Settings 3.0 feedback';
        await rpcSendMessageToSupport({ subject, body, diagnosticInfo });
        Sentry.captureMessage('Band Settings feedback form submitted');
        body = '';
        sent = true;
      } catch (err) {
        Bert.alert(err.reason, 'warning');
      } finally {
        setTimeout(() => (submitting = false), 400);
      }
    })();
  }
</script>

<PanelModalLayout showAltHeader={sent}>
  {#snippet header()}
    <header class="panelHeader px-5 text-left text-base text-gray-800">
      {#if instructions}
        {instructions}
      {/if}
    </header>
  {/snippet}

  {#snippet altHeader()}
    <header class="panelHeader px-5 text-left">
      <h1 class="pb-1.5 pt-0.5 text-xl font-medium leading-6 text-gray-800">
        Thank you for your feedback!
      </h1>
      Your feedback has been emailed to us, and we’ll be reading it soon. Thanks for helping us make
      Strum Machine even better!
    </header>
  {/snippet}

  {#snippet main()}
    <div>
      {#if !sent}
        <div class="p-5" transition:slide>
          <textarea
            class="contactForm-textarea form-control"
            bind:value={body}
            placeholder="Enter your message here..."
            disabled={submitting}
          ></textarea>
          <div class="pb-3 text-center text-sm text-gray-600">
            Want to share a screenshot or attachment?
            <span class="whitespace-nowrap">
              Please email
              <a
                href="mailto:support@strummachine.com?subject=Band%20Settings%20update"
                target="_blank">support@strummachine.com</a
              >.
            </span>
          </div>
          <button
            type="submit"
            class="btn btn-raised btn-primary btn-block"
            disabled={submitting || !body}
            onclick={(e) => {
              e.preventDefault();
              submit();
            }}
          >
            {#if submitting}
              Please wait...
            {:else}
              Send Message
            {/if}
          </button>
        </div>
      {:else}
        <div class="p-5">
          <button
            type="button"
            class="btn btn-raised btn-primary btn-block"
            onclick={(e) => {
              e.preventDefault();
              sent = false;
            }}
          >
            Send more feedback
          </button>
        </div>
      {/if}
    </div>
  {/snippet}
</PanelModalLayout>
