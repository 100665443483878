import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { ReactiveVar } from 'meteor/reactive-var';
import { Tracker } from 'meteor/tracker';
import type { SongListItemRecord, SongListRecord } from '@/collections/SongListsCollection';
import { Conductor } from '@/Conductor';
import { Crnt } from '@/Crnt';
import { sortList } from '@/library/sortList';
import { getLocalStorageSafe, setLocalStorageSafe } from '@/utilities/safe-local-storage';
import wait from '@/utilities/wait';
import waitUntilReactive from '@/utilities/waitUntilReactive';

// This class is recreated by Crnt anytime a list is modified
export class CurrentList {
  constructor(list: SongListRecord) {
    sortList(list);
    this.list = list;
    this.itemsById = Object.fromEntries(list.songs.map((item) => [item._id, item]));

    Tracker.autorun(() => {
      const songOrMedleyId = FlowRouter.getParam('songId') || FlowRouter.getParam('medleyId');
      this._currentItem.set(songOrMedleyId ? this.itemsById[songOrMedleyId] : undefined);
    });

    this._shuffle.set(getLocalStorageSafe(`sm.list[${list._id}].shuffle`) == 'true');

    this._shuffledIds = getLocalStorageSafe(`sm.list[${list._id}].shuffledIds`)?.split(',') || [];
    if (this._shuffledIds.length === 0 && this._shuffle.get()) {
      this.makeShuffledIdList();
    } else {
      this.revalidateShuffledList();
    }

    this._autoAdvanceEnabled.set(getLocalStorageSafe(`sm.list[${list._id}].autoAdvance`) == 'true');
    this._autoAdvanceAutoPlay.set(getLocalStorageSafe(`sm.list[${list._id}].autoPlay`) == 'true');

    Tracker.autorun(() => {
      const songOrMedleyId = this._currentItem.get()?._id;
      this._currentIndex.set(
        songOrMedleyId
          ? this._shuffle.get()
            ? this._shuffledIds.findIndex((id) => id == songOrMedleyId)
            : list.songs.findIndex((s) => s._id == songOrMedleyId)
          : -1
      );
    });
  }

  get _id(): string {
    return this.list._id;
  }
  private list: SongListRecord;
  private itemsById: Record<string, SongListItemRecord>;

  orderedItems(): SongListItemRecord[] {
    this._shuffleDep.depend();
    return this._shuffle.get() ? this._shuffledItems : this.list.songs;
  }

  private _currentIndex = new ReactiveVar<number>(-1);
  currentIndex(): number {
    return this._currentIndex.get();
  }
  nextIndex(): number | undefined {
    const current = this._currentIndex.get();
    return current >= this.list.songs.length - 1 ? undefined : current + 1;
  }
  prevIndex(): number | undefined {
    const current = this._currentIndex.get();
    return current <= 0 ? undefined : current - 1;
  }

  private _currentItem = new ReactiveVar<SongListItemRecord | undefined>(undefined);
  currentItem(): SongListItemRecord | undefined {
    return this._currentItem.get();
  }
  nextItem(): SongListItemRecord | undefined {
    const index = this.nextIndex();
    return typeof index == 'number' ? this.orderedItems()[index] : undefined;
  }
  prevItem(): SongListItemRecord | undefined {
    const index = this.prevIndex();
    return typeof index == 'number' ? this.orderedItems()[index] : undefined;
  }

  private _shuffle = new ReactiveVar(false);
  private _shuffledIds: string[] = [];
  private _shuffledItems: SongListItemRecord[] = [];
  private _shuffleDep = new Tracker.Dependency();
  shuffling(): boolean {
    return this._shuffle.get();
  }
  toggleShuffle(): void {
    this._shuffle.set(!this._shuffle.get());
    setLocalStorageSafe(`sm.list[${this.list._id}].shuffle`, this._shuffle.get().toString());
    if (this._shuffle.get()) {
      this.makeShuffledIdList();
    }
  }
  reshuffle(): void {
    this.makeShuffledIdList();
    this._currentIndex.set(0); // current song is always first in shuffled list
  }

  private _autoAdvanceEnabled = new ReactiveVar(false);
  autoAdvanceEnabled(): boolean {
    return this._autoAdvanceEnabled.get();
  }
  enableAutoAdvance(): void {
    if (!this._autoAdvanceEnabled.get()) this.toggleAutoAdvance();
  }
  disableAutoAdvance(): void {
    if (this._autoAdvanceEnabled.get()) this.toggleAutoAdvance();
  }
  toggleAutoAdvance(): void {
    this._autoAdvanceEnabled.set(!this._autoAdvanceEnabled.get());
    setLocalStorageSafe(
      `sm.list[${this.list._id}].autoAdvance`,
      this._autoAdvanceEnabled.get().toString()
    );
  }

  private _autoAdvanceAutoPlay = new ReactiveVar(false);
  autoAdvanceAutoPlay(): boolean {
    return this._autoAdvanceAutoPlay.get();
  }
  setAutoPlay(value: boolean): void {
    this._autoAdvanceAutoPlay.set(value);
    setLocalStorageSafe(`sm.list[${this.list._id}].autoPlay`, value ? 'true' : '');
  }

  doAutoAdvance(): void {
    if (!this.autoAdvanceEnabled()) return;
    const nextId = this.nextItem()?._id;
    if (!nextId) return;
    (document.querySelector('.js-global-nextItemInList') as HTMLAnchorElement | undefined)?.click();
    if (this.autoAdvanceAutoPlay()) {
      wait(300)
        .then(() => waitUntilReactive(() => Crnt.song() || Crnt.medley()))
        .then(() => Conductor.play());
    }
  }

  private makeShuffledIdList(): string[] {
    const currentId = this.currentItem()?._id;
    const array = this.list.songs.map((item) => item._id).filter((id) => id !== currentId);
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j]!, array[i]!];
    }
    if (currentId) array.unshift(currentId);
    this._shuffledIds = array;
    setLocalStorageSafe(`sm.list[${this.list._id}].shuffledIds`, array.join(','));
    this._shuffledItems = this._shuffledIds.map((id) => this.itemsById[id]!);
    this._shuffleDep.changed();
    return array;
  }

  private revalidateShuffledList() {
    const listItemIds = this.list.songs.map((item) => item._id);
    let changed = this.list.songs.length != this._shuffledIds.length;

    // Remove items absent from current list
    this._shuffledIds = this._shuffledIds.filter((id) => listItemIds.includes(id));

    changed ||= this.list.songs.length != this._shuffledIds.length;

    // Add items absent from shuffled list
    listItemIds
      .filter((id) => !this._shuffledIds.find((shuffledId) => id == shuffledId))
      .forEach((idToAdd) => {
        this._shuffledIds.splice(Math.floor(Math.random() * this._shuffledIds.length), 0, idToAdd);
      });

    if (changed) {
      setLocalStorageSafe(`sm.list[${this.list._id}].shuffledIds`, this._shuffledIds.join(','));
    }

    this._shuffledItems = this._shuffledIds.map((id) => this.itemsById[id]!);
  }

  name(): string {
    return this.list.name;
  }

  itemCount(): number {
    return this.list.songs.length;
  }
}
