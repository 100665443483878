<script lang="ts">
  import type { BandPreset } from '@/band/presets/BandPreset';
  import { BandPresets } from '@/band/presets/BandPresets';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';
  import { eventTracker } from '@/browser/analytics/eventTracker';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import Icon from '@/ui/icons/Icon.svelte';

  const bandMenu = getBandMenuState();

  let band = $derived($bandMenu.band);

  let preset: BandPreset | undefined = $state();
  trackerEffect(() => (preset = band.preset()));

  let friendlyTimeSignature = $derived(
    $bandMenu.timeSignature == '4/4' ? 'standard time' : `${$bandMenu.timeSignature} time`
  );

  function deletePreset() {
    const band = $bandMenu.band;
    if (!preset) return;
    BandPresets.delete(preset);
    eventTracker.bandPresetDelete({ presetId: preset.id, name: preset.name });
    if (preset.id == band.preset()?.id) {
      band.currentPresetWasDeleted();
    }
    const builtInPreset = BandPresets.findById(preset.id); // if the preset was a built-in preset, it will be restored
    if (builtInPreset) band.setPreset(builtInPreset);
    band.enterNormalPresetMode();
  }

  function cancelPresetMode() {
    band.enterNormalPresetMode();
  }
</script>

<div class="px-6 pb-5 pt-4 sm-band:pl-4">
  <div class="flex">
    <div class="flex-1">
      <h1 class="pb-1.5 pt-0.5 text-xl font-medium leading-6 text-gray-900">
        {#if preset?.default}
          Are you sure?
        {:else}
          Are you sure you want to
          <span class="whitespace-nowrap">delete your preset?</span>
        {/if}
      </h1>

      <div class="text-balance pb-3 text-sm text-gray-600">
        {#if preset?.default}
          Strum Machine will remove the customizations you’ve made to the default band settings for {friendlyTimeSignature}.
          There is no undo.
        {:else}
          There is no undo. Any songs that were using this preset will switch to using the same
          settings for the instruments as a per-song “Custom Band”.
        {/if}
      </div>
      <div class="flex gap-2">
        <button
          class="btn-base flex items-center gap-2 rounded bg-red-600 px-4 py-1 text-white hover:bg-red-700"
          onclick={deletePreset}
        >
          {#if preset?.default}
            Revert Default Band
          {:else}
            <Icon id="trash" size={14} />
            Delete Preset
          {/if}
        </button>
        <button
          class="btn-base rounded bg-gray-500 px-4 py-1 text-white hover:bg-gray-700"
          onclick={cancelPresetMode}
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
