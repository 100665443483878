import ReferenceEmbedDisplay from '@/references/ReferenceEmbedDisplay';

Template.referenceEmbedWindow.onCreated(() => {
  const instance = Template.instance();
  instance.autorun(() => {});
});

Template.referenceEmbedWindow.helpers({
  floatingWindowDataContext() {
    const instance = Template.instance();
    return {
      class: () =>
        `embed ${ReferenceEmbedDisplay.embedData(instance.data.contentType).providerId} ${ReferenceEmbedDisplay.embedData(instance.data.contentType).isText ? 'text' : ''}`,
      horizontallyResizable: true,
      fixedBottom: ReferenceEmbedDisplay.embedData(instance.data.contentType).isText,
      moveableMinWidth: '400px',
      minFromBottom: 150,
      shouldBeVisible() {
        return ReferenceEmbedDisplay.visible(instance.data.contentType);
      },
      closeClickHandler() {
        ReferenceEmbedDisplay.hide(instance.data.contentType);
      },
      title() {
        return ReferenceEmbedDisplay.embedData(instance.data.contentType).providerName;
      },
      popoutUrl() {
        return ReferenceEmbedDisplay.embedData(instance.data.contentType).url;
      },
    };
  },
  embedCode() {
    return ReferenceEmbedDisplay.embedData(Template.currentData().contentType).embedCode;
  },
});

Template.referenceEmbedWindow.events({});
