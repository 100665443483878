import type { Channel } from '@/audio/engine/base/Channel';
import type { MasterChannel } from '@/audio/engine/base/MasterChannel';
import type { Playback } from '@/audio/engine/base/Playback';

export abstract class Mixer {
  readonly masterChannel!: MasterChannel;
  readonly channels = new Map<string, Channel>();

  channel(channelId: string): Channel | undefined {
    return this.channels.get(channelId);
  }

  createChannels(channelDefinitions: { id: string }[]) {
    channelDefinitions.forEach((def) => this.createChannel(def.id));
  }

  createChannel(channelId: string): Channel {
    if (this.channels.has(channelId)) throw new Error(`Channel exists: ${channelId}`);
    const channel = this._createChannel(channelId);
    this.channels.set(channelId, channel);
    return channel;
  }

  protected abstract _createChannel(channelId: string): Channel;

  async stopAllPlaybacks(): Promise<void> {
    await Promise.all([...this.channels.values()].map((channel) => channel.stopAll()));
  }

  allPlaybacks(): Playback[] {
    return [...this.channels.values()].flatMap((c) => c.playbacks);
  }
}
