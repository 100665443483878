<script lang="ts">
  import type { Section } from '@/chart/Section.svelte';
  import RepMarkers from '@/chart/ui/RepMarkers.svelte';
  import { Crnt } from '@/Crnt';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';

  interface Props {
    section: Section;
    sectionIndex: number;
  }

  let { section, sectionIndex }: Props = $props();

  let hiddenByDefaultInMedley = $state(false);
  trackerEffect(() => {
    const medley = Crnt.medley();
    hiddenByDefaultInMedley =
      !!medley &&
      !!section.song.medleyPrefs &&
      ((section.type == 'i' && section.song.index() > 0) ||
        (section.type == 'o' && section.song.index() < medley.songs.reactive().length - 1));
  });

  let hiddenInMedley = $state(false);
  trackerEffect(() => {
    hiddenInMedley =
      !!section.song.medleyPrefs &&
      ((section.type == 'i' && section.song.medleyPrefs.skippingIntros()) ||
        (section.type == 'o' && section.song.medleyPrefs.skippingOutros()));
  });

  let specialSectionInMedley = $derived(!!section.song.medleyPrefs && !!section.type);

  let loopContainsEntireSection = $state(false);
  trackerEffect(() => {
    loopContainsEntireSection = section.song.loop.containsEntireSection(section);
  });

  let sectionTypeWord = $derived(
    section.type == 'i' ? 'intro' : section.type == 'o' ? 'outro' : 'section'
  );

  let wontPlayOutro = $state(false);
  trackerEffect(() => {
    wontPlayOutro = !section.song.prefs.autoFinish.enabled() && !Crnt.list()?.autoAdvanceEnabled();
  });

  function handleMedleyUnskip() {
    if (section.type == 'i') section.song.medleyPrefs?.setSkipIntros(false);
    if (section.type == 'o') section.song.medleyPrefs?.setSkipOutros(false);
  }

  function handleMedleySkip() {
    if (section.type == 'i') section.song.medleyPrefs?.setSkipIntros(true);
    if (section.type == 'o') section.song.medleyPrefs?.setSkipOutros(true);
  }
</script>

<header
  class={[
    'songSectionHeading',
    section.name ? 'not-blank' : 'blank',
    section.repetitions > 1 ? 'repeated' : 'not-repeated',
    loopContainsEntireSection ? 'loop-contains-section' : 'loop-not-contains-section',
  ]}
  aria-roledescription="section heading"
>
  {#if !hiddenInMedley}
    <span class="songSectionHeading-name" aria-describedby="sectiondesc_{section._id}">
      {section.name}
    </span>
  {/if}

  {#if specialSectionInMedley}
    <span class="songSectionHeading-medleySkipStatus">
      {#if hiddenByDefaultInMedley}
        {#if hiddenInMedley}
          Mid-medley {sectionTypeWord}
          {#if section.name}
            "<i>{section.name}</i>"
          {/if}
          skipped
          <button class="btn btn-sm" onclick={handleMedleyUnskip} data-atcl="Medley.SectionUnskip">
            Unskip
          </button>
        {:else}
          <button class="btn btn-sm" onclick={handleMedleySkip} data-atcl="Medley.SectionSkip">
            Skip mid-medley {sectionTypeWord}
          </button>
        {/if}
      {:else if hiddenInMedley}
        Skipping {sectionTypeWord}
        {#if section.name}
          "<i>{section.name}</i>"
        {/if}
        <button class="btn btn-sm" onclick={handleMedleyUnskip} data-atcl="Medley.SectionUnskip">
          Unskip
        </button>
      {:else}
        <button class="btn btn-sm" onclick={handleMedleySkip} data-atcl="Medley.SectionSkip">
          Skip {sectionTypeWord}
        </button>
      {/if}
    </span>
  {/if}

  {#if !hiddenInMedley}
    <span
      class="songSectionHeading-reps reps-{section.repetitions}"
      data-repetitions={section.repetitions}
      aria-hidden="true"
    >
      <RepMarkers {section} {sectionIndex} />
    </span>
    <span
      class="songSectionHeading-printReps reps-{section.repetitions}"
      id="sectiondesc_{section._id}"
    >
      (&times;{section.repetitions})
    </span>
    <span class="songSectionHeading-line"></span>
    {#if section.type == 'i'}
      <span class="songSectionHeading-attrLabel">first time only</span>
    {/if}
    {#if section.type == 'o'}
      {#if wontPlayOutro}
        <span class="songSectionHeading-attrLabel">outro plays only if Auto-Finish is enabled</span>
      {:else}
        <span class="songSectionHeading-attrLabel">last time only</span>
      {/if}
    {/if}
  {/if}
</header>

<style>
  .songSectionHeading {
    white-space: nowrap;
    display: flex;
    align-items: center;
    color: #333;
    line-height: 1;
    min-height: 1.25em;
    cursor: default;

    &.blank {
      color: rgba(0, 0, 0, 0.6);
    }

    &.blank.not-repeated {
      display: none;
    }
  }

  .songSectionHeading-name {
    color: #888;
    padding: 0 8px 0 10px;
    @media (max-width: 600px) {
      font-size: calc(13px + 0.5vw);
    }
  }

  .songSectionHeading-medleySkipStatus {
    display: inline-flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.5);
    font-size: 13px;

    > .btn {
      font-size: 14px;
      font-weight: normal;
      padding: 2px 4px;
      margin-left: 5px;
    }
  }

  .songSectionHeading-reps {
    transition: opacity 0.15s ease;
    padding: 0 8px 0 0;
  }
  .songSectionHeading-printReps,
  .songSectionHeading-reps.reps-1 {
    display: none;
  }
  .looping .loop-not-contains-section .songSectionHeading-reps {
    opacity: 0;
  }
  @media print {
    .songSectionHeading-printReps {
      display: inline;
    }
    .songSectionHeading-printReps.reps-1,
    .songSectionHeading-reps {
      display: none;
    }
    .songSectionHeading-name {
      font-size: 100%;
    }
  }

  .songSectionHeading-line {
    position: relative;
    flex-grow: 1;

    &::before {
      background: #eee;
      content: '';
      display: block;
      height: 3px;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;

      .medleySong &,
      .songSectionHeading.blank.not-repeated & {
        display: none;
      }
    }
  }

  .songSectionHeading-attrLabel {
    color: rgba(0, 0, 0, 0.5);
    font-style: italic;
    font-size: 13px;
    padding-left: 16px;
  }
</style>
