import Cookies from 'js-cookie';

Template.newAccountPage.onRendered(() => {
  document.scrollingElement.scrollTop = 0;
});

Template.newAccountPage.helpers({
  extendedTrialLength() {
    if (/90$/i.test(Cookies.get('offer'))) return 90;
    if (/60$/i.test(Cookies.get('offer'))) return 60;
    if (/45$/i.test(Cookies.get('offer')) || /^(sbjc)$/i.test(Cookies.get('offer'))) return 45;
    if (/30$/i.test(Cookies.get('offer'))) return 30;
    if (/^(lukecard)$/i.test(Cookies.get('offer'))) return 30;
  },
});
