import { strict as assert } from 'assert';
import { ReactiveVar } from 'meteor/reactive-var';
import { Cell } from '@/chart/Cell.svelte';
import type { CellBeat } from '@/chart/CellBeat.svelte';
import { Section } from '@/chart/Section.svelte';
import type { Song } from '@/chart/Song';
import type { SongSelection } from '@/chart/SongSelection';
import { sectionRemovalInProgress } from '@/chart/ui/sectionRemovalInProgress';
import { Chord } from '@/music/Chord';
import { chordTogglePairs } from '@/music/chordTogglePairs';
import { UserProfile } from '@/user/UserProfile';
import { getLocalStorageSafe, setLocalStorageSafe } from '@/utilities/safe-local-storage';
import Version from '@/Version';

export class SelectionEditor {
  private selection: SongSelection;
  private song: Song;

  constructor(selection: SongSelection) {
    this.selection = selection;
    this.song = this.selection.song;
    this._canPaste.set(this.getClipboard().length > 0);
  }

  _wrapChange(func: () => void): void {
    this.song.history.takeSnapshot();
    func();
    this.song.processChanges();
  }

  //#region === Changing Cells ===

  private _forEachCellOrBeat(func: (object: Cell | CellBeat) => void) {
    if (!this.selection.spansMultipleCells()) {
      const cell = this.selection.startCell();
      const object = cell?.subdividedBeats?.[this.selection.focusedBeat() ?? 0] || cell;
      if (object) func(object);
    } else {
      for (const cell of this.selection.cells()) {
        func(cell);
      }
    }
  }

  setChord(chordOrString: string | Chord): void {
    UserProfile.recordMilestone('CHORD_SET');
    const chord = typeof chordOrString == 'string' ? new Chord(chordOrString) : chordOrString;
    this._wrapChange(() => {
      this._forEachCellOrBeat((object) => {
        object.chord = chord;
      });
    });
  }

  setChordType(type: string): void {
    UserProfile.recordMilestone('CHORD_SET');
    this._wrapChange(() => {
      if (!this.selection.spansMultipleCells()) {
        const cell = this.selection.startCell();
        const object = cell?.subdividedBeats?.[this.selection.focusedBeat() ?? 0] || cell;
        const newChord = this.getFirstFocusedChord()?.withNewType(type);
        if (object && newChord) object.chord = newChord;
      } else {
        this._forEachCellOrBeat((object) => {
          if (object.chord) object.chord = object.chord.withNewType(type);
        });
      }
    });
  }

  setBassNote(root: Chord['bassNote']): void {
    this._wrapChange(() => {
      if (!this.selection.spansMultipleCells()) {
        const cell = this.selection.startCell();
        const object = cell?.subdividedBeats?.[this.selection.focusedBeat() ?? 0] || cell;
        const newChord = this.getFirstFocusedChord()?.withNewBassNote(root);
        if (object && newChord) object.chord = newChord;
      } else {
        this._forEachCellOrBeat((object) => {
          if (object.chord) object.chord = object.chord.withNewBassNote(root);
        });
      }
    });
  }

  private getFirstFocusedChord(): Chord | undefined {
    const cell = this.selection.firstCell();
    if (!cell) return;
    const beatIndex = this.selection.focusedBeat() ?? 0;
    return (
      (beatIndex >= 2 && cell.subdividedBeats?.[2]?.chordHeard) ||
      (beatIndex >= 1 && cell.subdividedBeats?.[1]?.chordHeard) ||
      cell.chordHeard
    );
  }

  toggleChordAttribute(attr: string): void {
    const baseChord = this.getFirstFocusedChord();
    if (!baseChord) return;
    const pairs = chordTogglePairs[attr];
    if (!pairs) return;
    const matchingPair =
      pairs.find(([a, b]) => a == baseChord.type || b == baseChord.type) ?? pairs[0];
    const curValue = matchingPair[1] == baseChord.type;
    this._wrapChange(() => {
      this._forEachCellOrBeat((object) => {
        if (object.chord) {
          const matchingPair =
            pairs.find(([a, b]) => a == object.chord?.type || b == object.chord?.type) ?? pairs[0];
          const newType = curValue ? matchingPair[0] : matchingPair[1];
          object.chord = new Chord(object.chord).withNewType(newType);
        }
      });
    });
  }

  setAccidental(attr: 'sharp' | 'flat' | 'bassSharp' | 'bassFlat'): void {
    const curValue = this.getFirstFocusedChord()?.[attr];
    this._wrapChange(() => {
      this._forEachCellOrBeat((object) => {
        if (object.chord) object.chord = new Chord(object.chord).withNewAccidental(attr, !curValue);
      });
    });
  }

  shiftSemitones(semitones: number, key: string): void {
    this._wrapChange(() => {
      this._forEachCellOrBeat((object) => {
        if (object.chord) object.chord = new Chord(object.chord, key).shiftRoot(semitones);
      });
    });
  }

  shiftBassNoteSemitones(semitones: number, key: string): void {
    this._wrapChange(() => {
      this._forEachCellOrBeat((object) => {
        if (object.chord) object.chord = new Chord(object.chord, key).shiftBassNote(semitones);
      });
    });
  }

  toggleSharp = (): void => this.setAccidental('sharp');
  toggleFlat = (): void => this.setAccidental('flat');
  toggleBassNoteSharp = (): void => this.setAccidental('bassSharp');
  toggleBassNoteFlat = (): void => this.setAccidental('bassFlat');
  toggleMinor = (): void => this.toggleChordAttribute('minor');
  toggleSeven = (): void => this.toggleChordAttribute('seven');
  toggleSix = (): void => this.toggleChordAttribute('six');
  toggleFive = (): void => this.toggleChordAttribute('five');
  toggleNine = (): void => this.toggleChordAttribute('nine');
  toggleSus2 = (): void => this.toggleChordAttribute('sus2');
  toggleSus4 = (): void => this.toggleChordAttribute('sus4');
  toggleDiminished = (): void => this.toggleChordAttribute('diminished');
  toggleHalfDiminished = (): void => this.toggleChordAttribute('halfDiminished');
  toggleSuspended = (): void => this.toggleChordAttribute('suspended');
  toggleAugmented = (): void => this.toggleChordAttribute('augmented');
  toggleMaj7 = (): void => this.toggleChordAttribute('maj7');

  cycleEffect(): void {
    const firstCell = this.selection.firstCell();
    if (!firstCell) return;

    // Find next effect in the effect-cycle array.
    // Going past bounds will wrap around to undefined.
    const effectCycle = [undefined, 'rest', 'stop', 'diamond'] as const;
    const newEffect = effectCycle[effectCycle.indexOf(firstCell.effect || undefined) + 1];

    this.setEffect(newEffect);
  }

  setEffect(effect?: 'rest' | 'stop' | 'diamond'): void {
    this._wrapChange(() => {
      // for now, cells only - replace with commented below later
      for (const cell of this.selection.cells()) {
        cell.effect = effect;
      }
      // this._forEachCellOrBeat((object) => {
      //   object.effect = effect;
      // });
    });
  }

  toggleHalfMeasure(): void {
    const value = !this.selection.firstCell()?.half;
    this._wrapChange(() => {
      this.selection.cells().forEach((cell) => (cell.half = value));
    });
  }

  setHalf(value: boolean): void {
    this._wrapChange(() => {
      this.selection.cells().forEach((cell) => (cell.half = value));
    });
  }

  split(): void {
    this._wrapChange(() => {
      this.selection.cells().forEach((cell) => (cell.split = true));
      this.selection.revalidate();
    });
  }

  unsplit(): void {
    this._wrapChange(() => {
      this.selection.cells().forEach((cell) => (cell.split = false));
      this.selection.revalidate();
    });
  }

  setLineLength(length: number): void {
    const maxLL = this.song.timeSignature() == '9/8' ? 4 : 6;
    length = Math.min(maxLL, Math.max(1, length));
    this._wrapChange(() => {
      this.selection.cells().forEach((cell) => cell.setLengthOfLine(length));
    });
  }

  addVolta(value: number): void {
    this._wrapChange(() => {
      // First, remove all occurences of this volta from selected cells;
      // we can't have more than one desigation per rep per section
      const affectedSections = new Set(this.selection.cells().map((cell) => cell.section));
      affectedSections.forEach((section) => {
        section?.cells.forEach((cell) => cell.removeVolta(value));
      });

      this.selection.firstCell()?.addVolta(value);
    });
  }

  removeVolta(value: number): void {
    this._wrapChange(() => {
      // Assume this function will only be called if highlighting a given volta area,
      // so assume we need to remove all from section(s)
      const affectedSections = new Set(this.selection.cells().map((cell) => cell.section));
      affectedSections.forEach((section) => {
        section?.cells.forEach((cell) => cell.removeVolta(value));
      });
    });
  }

  setTimeSignature(timeSig: TimeSignature | undefined): void {
    this._wrapChange(() => {
      const followingCell = this.selection.lastCell()?.next;
      const existingTimeSig = followingCell?.effectiveTimeSignature;
      this.selection.cells().forEach((cell) => (cell.timeSig = timeSig));
      if (followingCell && existingTimeSig) followingCell.timeSig = existingTimeSig;
    });
  }

  //#endregion ^ Changing Cells

  //#region === Adding/Removing Cells ===

  duplicate(): Cell[] {
    const lastCellInSelection = this.selection.lastCell();
    if (!lastCellInSelection) return [];

    this.song.history.takeSnapshot();

    this.song.sections.removeImpliedFlags();

    const duplicated = this.selection.cells().map((cell) => {
      return new Cell(cell.serialize({ preserveIds: false }));
    });
    if (duplicated.length == 1) {
      const duplicatedCell = duplicated[0]!;
      duplicatedCell.implied = true;
      duplicatedCell.half = false;
      if (duplicatedCell.split) duplicatedCell.chord = new Chord(this.song.key()!);
      // if ((duplicatedCell.effect == 'stop' || duplicatedCell.effect == 'diamond')) {
      //   duplicatedCell.effect = 'rest';
      // }
    }

    const section = lastCellInSelection.section;
    section!.cells.splice(lastCellInSelection.layout.cellIndex + 1, 0, ...duplicated);

    this.song.sections.fillInMissingChords();
    this.song.processChanges();
    this.selection.setCells(
      {
        start: duplicated[0],
        end: duplicated[duplicated.length - 1],
      },
      { noCallback: true }
    );

    UserProfile.recordMilestone('ADD_REMOVE_CELL_2024');

    return duplicated;
  }

  delete({ focusForward = false } = {}): void {
    const startPos = this.selection.normalizedBounds()?.start;
    if (!startPos) return;
    this.song.history.takeSnapshot();

    this.selection.cells().forEach((cell) => {
      if (cell.section?.cells.length == 1) {
        cell.section.cells.length = 0;
        if (this.song.sections.length == 1) {
          const key = this.song.key();
          assert(key, 'key for new section');
          cell.section.cells.push(new Cell({ chord: key }));
        }
      } else cell.section?.cells.splice(cell.section.cells.indexOf(cell), 1);
    });

    this.song.sections.removeEmptySections();
    this.song.processChanges();

    UserProfile.recordMilestone('ADD_REMOVE_CELL_2024');

    if (!focusForward) startPos[1]--;
    this.selection.setToBounds({ start: startPos, end: startPos });
  }

  insertSectionBreak(): void {
    const firstCell = this.selection.firstCell();
    if (!firstCell || !firstCell.section) return;
    this.song.history.takeSnapshot();
    this.song.sections.removeImpliedFlags();
    const prependSection = firstCell.layout.cellIndex === 0 && firstCell.section.cells.length > 0;
    const insertionIndex = firstCell.layout.sectionIndex + (prependSection ? 0 : 1);
    if (prependSection) {
      sectionRemovalInProgress.set(true);
      setTimeout(() => sectionRemovalInProgress.set(false), 500);
    }
    const newSection = Section.blank(this.song);
    if (
      firstCell.layout.cellIndex > 0 &&
      firstCell.layout.cellIndex < firstCell.section.cells.length - 1
    ) {
      const startOfNewSection = firstCell.layout.cellIndex + (firstCell.layout.barStart ? 0 : 1);
      const followingCells = firstCell.section.cells
        .slice(startOfNewSection)
        .map((cell) => cell.serialize({ preserveIds: false }));
      firstCell.section.cells.length = startOfNewSection; // remove cells from original section
      newSection.cells.length = 0;
      newSection.cells.push(...followingCells.map((data) => new Cell(data)));
    }
    this.song.sections.splice(insertionIndex, 0, newSection);
    this.song.sections.fillInMissingChords();
    this.song.processChanges();
    this.selection.setToSingleCell(newSection.cells[0]);
  }

  //#endregion ^ Add/Remove

  //#region === Clipboard ===

  getClipboard(): SerializedCell[] {
    try {
      const clipboardJSON = getLocalStorageSafe('sm.clipboard');
      if (!clipboardJSON) return [];
      const clipboardData = JSON.parse(clipboardJSON) as unknown;
      if (
        typeof clipboardData == 'object' &&
        clipboardData &&
        'majorVersion' in clipboardData &&
        clipboardData.majorVersion == Version.current.split('.')[0] &&
        'cells' in clipboardData
      ) {
        return clipboardData.cells as SerializedCell[]; // TODO: type checking?
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  }

  setClipboard(cells: SerializedCell[]): void {
    const clipboard = {
      majorVersion: Version.current.split('.')[0],
      cells,
    };
    setLocalStorageSafe('sm.clipboard', JSON.stringify(clipboard));
    this._canPaste.set(true);
  }

  copy(): void {
    this.song.sections.removeImpliedFlags();
    this.setClipboard(this.selection.cells().map((cell) => cell.serialize({ preserveIds: false })));
  }

  cut(): void {
    this.song.sections.removeImpliedFlags();
    this.setClipboard(this.selection.cells().map((cell) => cell.serialize({ preserveIds: false })));
    this.delete();
  }

  private _canPaste = new ReactiveVar(false);
  canPaste(): boolean {
    return this._canPaste.get();
  }

  /**
   * Paste the contents of the clipboard at the current position.
   * @returns Array of newly-pasted Cells, if any.
   */
  paste(): Cell[] {
    const clipboard = this.getClipboard();
    if (clipboard.length === 0) return [];
    const endOfSelection = this.selection.lastCell();
    if (!endOfSelection) return [];

    this.song.history.takeSnapshot();

    this.song.sections.removeImpliedFlags();
    const section = endOfSelection.section;
    if (section?.cells.length == 1) section.cells.length = 0; // Only one cell in the section? Replace it.
    const oneIfNeeded =
      endOfSelection.layout.barStart && !endOfSelection.layout.barEnd && clipboard.length > 1
        ? 0
        : 1;
    const newCells = clipboard.map((data) => new Cell(data));

    if (newCells.length > 0) {
      section?.cells.splice(endOfSelection.layout.cellIndex + oneIfNeeded, 0, ...newCells);
      this.song.sections.fillInMissingChords();
      this.song.processChanges();
      this.selection.setCells({ start: newCells[0], end: newCells[newCells.length - 1] });
    }

    return newCells;
  }

  pasteInPlace(): Cell[] {
    const clipboard = this.getClipboard();
    const newCells = clipboard.map((data) => new Cell(data));
    if (newCells.length === 0) return [];
    const firstSelectedCell = this.selection.firstCell();
    if (!firstSelectedCell) return [];
    this.song.history.takeSnapshot();
    this.song.sections.removeImpliedFlags();
    firstSelectedCell.section?.cells.splice(
      firstSelectedCell.layout.cellIndex,
      this.selection.cells().length,
      ...newCells
    );
    this.song.sections.fillInMissingChords();
    this.song.processChanges();
    this.selection.setCells({ start: newCells[0], end: newCells[newCells.length - 1] });
    return newCells;
  }

  //#endregion ^ Clipboard

  //#region === Getters for cell properties ===

  getMeasureProperty(prop: keyof Cell): any {
    const aggSet = new Set();
    this.selection.cells().forEach((cell) => {
      aggSet.add(cell[prop]);
    });
    const aggArray = Array.from(aggSet);
    if (aggArray.length <= 1) return aggArray[0];
    return aggArray;
  }

  /**
   * @returns Length of line, or undefined if cells are on lines of varying length.
   */
  getLineLength(): number | undefined {
    let result;
    for (const cell of this.selection.cells()) {
      const lineLength = cell.section?.lineLengths[cell.layout.lineNumber];
      if (result && lineLength != result) return undefined; // indeterminate
      result = lineLength;
    }
    return result;
  }

  //#endregion ^ Getters
}
