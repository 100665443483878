export class PlayProps {
  volume = 1;
  delay = 0;
  offset = 0;
  pitchShift = 0;
  fadeInDuration = 0;
  atTime?: number;
  playDuration?: number; // not currently used... probably never will be
  channel?: string;
  sampleId?: string;

  constructor(obj = {}) {
    this.assign(obj);
  }

  assign(obj: Partial<PlayProps>): void {
    if (!obj) {
      return;
    }

    const numberProps: (keyof PlayProps)[] = [
      'volume',
      'delay',
      'offset',
      'pitchShift',
      'atTime',
      'playDuration',
      'fadeInDuration',
    ];
    numberProps.forEach((prop) => {
      //@ts-expect-error It doesn't know which props are numerical
      if (obj[prop] != null) this[prop] = Number(obj[prop]);
    });

    if (obj.channel != null) this.channel = obj.channel;
  }

  static assign(defaults: PlayProps, obj: PlayProps): PlayProps {
    const pp = new PlayProps(defaults);
    pp.assign(obj);
    return pp;
  }
}
