<script lang="ts">
  import { innerWidth } from 'svelte/reactivity/window';
  import { MultiPanelModalController } from '@/ui/settings/MultiPanelModalController.svelte';
  import { slideFadeHorizontal } from '@/ui/svelte-transitions';

  const modalController = MultiPanelModalController.current();

  let canGoBack = $derived(modalController.activeScreenIndex > 0 || !!modalController.subview);

  let showClose = $derived((innerWidth.current ?? 800) < 550 || !canGoBack);

  function onClickBack(event: MouseEvent & { currentTarget: HTMLButtonElement }) {
    event.currentTarget.blur();
    modalController.zoomOut();
  }

  function onClickClose() {
    Modal.hide();
  }
</script>

<nav class="rounded-t-[10px] bg-primary-700">
  <div class="nav-buttons flex items-stretch">
    {#if showClose}
      <button
        class="btn close-btn"
        class:canGoBack
        onclick={onClickClose}
        transition:slideFadeHorizontal={{ duration: 250 }}
      >
        <div class="flex items-center">
          <svg class="h-3.5 w-3.5 opacity-80"><use xlink:href="#close-big" /></svg>
          {#if !canGoBack}
            <span
              class="inline-block overflow-hidden pl-2.5"
              transition:slideFadeHorizontal={{ duration: 250 }}>Close</span
            >
          {/if}
        </div>
      </button>
    {/if}
    {#if canGoBack}
      <button
        class="btn back-btn"
        onclick={onClickBack}
        transition:slideFadeHorizontal={{ duration: 250 }}
      >
        <div class="flex items-center">
          <svg class="h-4 w-3 opacity-80"><use xlink:href="#back-chevron" /></svg>
          <span class="pl-2">Back</span>
        </div>
      </button>
    {/if}
    <div class="flex-1"></div>
  </div>
</nav>

<style>
  .nav-buttons {
    .btn {
      color: white;
      letter-spacing: 0.3px;
      border-radius: 0;

      &:hover {
        background-color: var(--primary-600);
      }

      &:active {
        box-shadow: inset 0 0 2rem var(--primary-500);
      }

      &:focus-visible {
        box-shadow: inset 0 0 0 0.2rem var(--primary-300);
      }

      &:first-child {
        border-radius: 10px 0 0 0;
      }

      &:last-child {
        border-radius: 0 10px 0 0;
      }

      svg {
        transition: all 200ms ease;
      }
    }

    .btn.close-btn {
      padding: 0.75rem 1rem 0.75rem 1.125rem;
      @media (--sm-band-menu) {
        padding: 0.5rem 0.75rem 0.625rem;
      }

      &.canGoBack {
        transition-duration: 250ms;
        background-color: var(--primary-900);
        &:hover {
          background-color: var(--primary-800);
        }
      }
    }

    .btn.back-btn {
      padding: 0.75rem 1.375rem 0.75rem 1rem;
      @media (--sm-band-menu) {
        padding: 0.5rem 1rem 0.5rem 0.875rem;
      }

      &:active {
        svg {
          transform: translateX(-1.5px);
        }
      }
    }

    .btn.listen-btn {
      padding: 0.75rem 1.25rem 0.825rem 1rem;
      @media (--sm-band-menu) {
        padding: 0.5rem 1rem 0.6rem 0.75rem;
      }

      &.pulsate {
        animation: pulsate 4s 4s ease-in-out infinite;
      }
    }
  }

  @keyframes pulsate {
    0% {
      background-color: transparent;
    }
    10% {
      background-color: var(--primary-500);
    }
    30% {
      background-color: transparent;
    }
    100% {
    }
  }
</style>
