type Item<T> = T & {
  prev?: Item<T>;
  next?: Item<T>;
};

export function setLinkedListPrevAndNext(items: Iterable<Item<unknown>>): void {
  let prev: Item<unknown> | undefined = undefined;
  for (const item of items) {
    item.prev = prev;
    if (prev) prev.next = item;
    item.next = undefined; // reset this in case we're at the end
    prev = item;
  }
}
