import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { DemoMode } from '@/client/DemoMode';

var activeScreen = new ReactiveVar();
var activeChildTemplate = new ReactiveVar();
var activeModal = new ReactiveVar();
var fullyVisibleScreen = new ReactiveVar();
var previousScreen;
var screensToRender = new ReactiveDict();
var unauthenticatedTemplate = new ReactiveVar();

var transitionDefinitions = {
  slideInFromLeft: {
    translateX: [0, '-100%'],
    translateY: [0, 0],
  },
  slideInFromRight: {
    translateX: [0, '100%'],
    translateY: [0, 0],
  },
  slideToLeft: {
    translateX: ['-100%', 0],
    translateY: [0, 0],
  },
  slideToRight: {
    translateX: ['100%', 0],
    translateY: [0, 0],
  },
  none: {
    translateX: [0, 0],
    translateY: [0, 0],
  },
};

var pageTransitionTree = {
  'index': {
    'music': {
      active: transitionDefinitions.slideInFromRight,
      inactive: transitionDefinitions.slideToLeft,
    },
    'side': { active: transitionDefinitions.slideInFromLeft, inactive: transitionDefinitions.none },
  },
  'music': {
    'index': {
      active: transitionDefinitions.slideInFromLeft,
      inactive: transitionDefinitions.slideToRight,
    },
    'side': {
      active: transitionDefinitions.slideInFromLeft,
      inactive: transitionDefinitions.slideToRight,
    },
  },
  'side': {
    'music': {
      active: transitionDefinitions.slideInFromRight,
      inactive: transitionDefinitions.slideToRight,
    },
    'index': { active: transitionDefinitions.none, inactive: transitionDefinitions.slideToLeft },
  },
};

// Here's how to zoom song page in from SongIndexItem... ($p is page, $li is item, must set transform-origin to "0 0")
// $p.velocity({translateY: $li.offset().top, translateX: $li.offset().left, scaleX: $li.width() / $(window).width(), scaleY: $li.height() / $(window).height()})

Template.appContainer.onCreated(() => {
  const instance = Template.instance();
  instance.autorun(() => {
    const data = Template.currentData();
    const screen = data.screen && data.screen();
    const childTemplate = data.template && data.template();
    const modal = FlowRouter.getQueryParam('modal');
    const publicPage = data.publicPage && data.publicPage();
    if (Meteor.userId() || DemoMode.active()) {
      unauthenticatedTemplate.set(null);
      activeScreen.set(screen);
      activeChildTemplate.set(childTemplate);
      activeModal.set(modal);
      screensToRender.set(screen, true);
      if (screen == 'side') {
        Bert.hide();
      }
    } else {
      const appMode =
        window.todesktop ||
        window.navigator.standalone == true ||
        window.matchMedia('(display-mode: standalone)').matches;
      if (Meteor.isCordova) {
        FlowRouter.go('/');
      } else if (appMode) {
        FlowRouter.go('login');
      } else {
        if (publicPage) {
          unauthenticatedTemplate.set(publicPage);
        } else {
          FlowRouter.go('/app');
        }
      }
    }
  });
});

Template.appContainer.onRendered(() => {
  var instance = Template.instance();
  instance.autorun(() => {
    if (!previousScreen || previousScreen == activeScreen.get()) {
      instance
        .$(`.appContainer-screen.${activeScreen.get()}-page`)
        .css({ display: 'block', opacity: 1 });
      fullyVisibleScreen.set(activeScreen.get());
    } else if (previousScreen != activeScreen.get()) {
      var currentTransition = pageTransitionTree[previousScreen][activeScreen.get()];
      if (!currentTransition) return; // going out of appContainer

      instance
        .$(`.appContainer-screen.${previousScreen}-page`)
        .velocity(currentTransition.inactive, {
          display: 'none',
          duration: 300,
          easing: [0.4, 0, 0.4, 1],
          complete() {
            if (activeScreen.get() !== 'sidePage') screensToRender.set('sidePage', false);
          },
        });
      instance
        .$(`.appContainer-screen.${activeScreen.get()}-page`)
        .velocity(currentTransition.active, {
          display: 'block',
          duration: 300,
          easing: [0.4, 0, 0.4, 1],
          complete() {
            fullyVisibleScreen.set(activeScreen.get());
          },
        });
    }
    previousScreen = activeScreen.get();
  });

  instance.autorun(() => {
    if (activeModal.get() == 'advanced-settings') {
      AppContainerModal.show('simpleModalContainer', {
        template: 'advancedSettingsPanel',
        title: 'Advanced Settings',
        class: 'advancedSettingsModal',
      });
    } else if (activeModal.get() == 'offline-settings') {
      AppContainerModal.show('simpleModalContainer', {
        template: 'offlineSettingsPanel',
        title: 'Offline Mode',
        class: 'offlineSettingsModal',
      });
    } else if (activeModal.get() == 'typeform-survey') {
      AppContainerModal.show('simpleModalContainer', {
        template: 'typeformSurveyContainer',
        // title: 'Offline Mode',
        class: 'typeformSurveyModal',
      });
    } else {
      AppContainerModal.hide();
    }
  });

  // update status bar color on iOS
  if ('StatusBar' in window && window.cordova?.platformId == 'ios') {
    instance.autorun(() => {
      if (Template.appContainer.activeScreen() == 'index') {
        setTimeout(() => StatusBar.styleLightContent(), 200);
      } else {
        setTimeout(() => StatusBar.styleDefault(), 200);
      }
    });
  }

  // update body background color
  if (!Meteor.isCordova && !window.todesktop) {
    instance.autorun(() => {
      if (Template.appContainer.activeScreen() == 'index') {
        setTimeout(() => {
          document.body.style.backgroundColor = '#265C86';
        }, 200);
      } else if (Template.appContainer.activeScreen() == 'music') {
        setTimeout(() => {
          document.body.style.backgroundColor = 'white';
        }, 200);
      } else {
        setTimeout(() => {
          document.body.style.backgroundColor = '';
        }, 200);
      }
    });
  }
});

Template.appContainer.onDestroyed(() => {
  setTimeout(() => {
    if (!Meteor.isCordova) document.body.style.backgroundColor = '';
    if ('StatusBar' in window && window.cordova?.platformId == 'ios') StatusBar.styleDefault();
    activeScreen.set('');
    activeChildTemplate.set('');
    activeModal.set('');
    fullyVisibleScreen.set('');
    unauthenticatedTemplate.set('');
    screensToRender.clear();
  }, 200);
});

Template.appContainer.helpers({
  hideAppScreens() {
    Meteor.user('createdAt'); // will force reload when offline is ready
    return (
      !(Meteor.userId() || DemoMode.active()) ||
      (Meteor.loggingIn() && !Meteor.user('createdAt')) ||
      Meteor.loggingOut()
    );
  },

  showLoggingInOut() {
    Meteor.user('createdAt'); // will force reload when offline is ready
    return (Meteor.loggingIn() && !Meteor.user('createdAt')) || Meteor.loggingOut();
  },

  loggingInOutMessage() {
    return Meteor.loggingOut() ? 'Logging out...' : '';
  },

  renderScreen(screen) {
    return screensToRender.get(screen);
  },

  childTemplate() {
    return activeChildTemplate.get();
  },

  modalTemplate() {
    return activeModal.get();
  },

  unauthenticatedTemplate() {
    return unauthenticatedTemplate.get();
  },

  impersonatedUserAttribute() {
    Meteor.userId(); // for reactivity
    if (!window.userIsBeingImpersonated) return {};
    const user = Meteor.user();
    if (!user) return {};
    const userName = `${user.profile.firstName} ${user.profile.lastName}`;
    return { 'data-impersonated-user': `${userName} (${user.emails?.[0].address})` };
  },
});

Template.appContainer.activeScreen = () => activeScreen.get();
Template.appContainer.fullyVisibleScreen = () => fullyVisibleScreen.get();

var $currentModal;
const AppContainerModal = {
  allowMultiple: false,
  show(templateName, data, options) {
    if ($currentModal) {
      Blaze.remove($currentModal[0].$blaze_range.view);
      $currentModal = null;
      const oldBackdrop = $('body > .modal-backdrop');
      oldBackdrop.fadeOut(150);
      setTimeout(() => oldBackdrop.remove(), 200);
    }
    var view = Blaze.renderWithData(Template[templateName], data, document.body);
    var domRange = view._domrange; // TODO: Don't violate against the public API.
    $currentModal = domRange.$('.modal');
    $currentModal.on({
      'shown.bs.modal': function (event) {
        $currentModal.find('[autofocus]').focus();
      },
      'hide.bs.modal': function (event) {
        if (FlowRouter.getQueryParam('modal')) {
          FlowRouter.setQueryParams({ 'modal': null });
          return false;
        }
      },
      'hidden.bs.modal': function (event) {
        Blaze.remove(view);
        $currentModal = null;
      },
    });
    $currentModal.modal(options || {});
  },

  hide() {
    if ($currentModal) {
      $currentModal.modal('hide');
    }
  },
};
