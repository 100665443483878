import { Meteor } from 'meteor/meteor';

export default function getSentryEnvironment() {
  if (!Meteor.isProduction) return 'dev';
  const url = Meteor.absoluteUrl();
  if (url == 'strummachine.com') return 'production';
  if (url == 'sandbox.strummachine.com') return 'sandbox';
  if (url == 'staging.strummachine.com') return 'staging';
  if (url == 'qa.strummachine.com') return 'qa';
  if (url.includes('alpha')) return 'alpha';
  if (url.includes('beta')) return 'beta';
  if (url == 'old.strummachine.com') return 'old';
  return 'unknown';
}
