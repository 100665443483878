<script lang="ts">
  import { onMount } from 'svelte';
  import AudioManager from '@/audio/engine/AudioManager';
  import type { WebAudioChannel } from '@/audio/engine/web/WebAudioChannel';
  import type { WebAudioMasterChannel } from '@/audio/engine/web/WebAudioMasterChannel';
  import WebAudioMeteringChannel from '@/audio/metering/ui/WebAudioMeteringChannel.svelte';
  import { Conductor } from '@/Conductor';
  import waitUntilReactive from '@/utilities/waitUntilReactive';

  let channels = $state<{ id: string; gainNode: GainNode }[]>([]);

  let masterGainNode: GainNode | undefined = $state();
  let masterCompressorNode: DynamicsCompressorNode | undefined = $state();

  onMount(async () => {
    await waitUntilReactive(() => Conductor.ready());

    channels = [
      ...[...(AudioManager.mixer?.channels.values() ?? [])].map((channel) => ({
        id: channel.id,
        gainNode: (channel as WebAudioChannel).outputNode,
      })),
    ];

    masterGainNode = (AudioManager.mixer?.masterChannel as WebAudioMasterChannel).finalOutputNode;
    masterCompressorNode = (AudioManager.mixer?.masterChannel as WebAudioMasterChannel)
      .compressorNode;
  });
</script>

<div class="meters-across">
  {#each channels as channel (channel.id)}
    <div class="column">
      <div class="meter">
        <WebAudioMeteringChannel gainNode={channel.gainNode} compressorNode={undefined} />
      </div>
      <div class="label">{channel.id}</div>
    </div>
  {/each}
  {#if masterGainNode}
    <div class="column">
      <div class="meter">
        <WebAudioMeteringChannel gainNode={masterGainNode} compressorNode={masterCompressorNode} />
      </div>
      <div class="label">Master</div>
    </div>
  {/if}
</div>

<style>
  .meters-across {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: stretch;
    width: 100%;
    height: 100%;

    > .column {
      display: flex;
      flex-direction: column;
      align-items: center;
      > .meter {
        flex: 1 1 100%;
      }
      > .label {
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        color: var(--muted-400);
      }
    }
  }
</style>
