import { ReactiveVar } from 'meteor/reactive-var';

class LBR {
  _value = $state<string | undefined>();
  _reactiveVar = new ReactiveVar<string | undefined>(undefined);
  get value() {
    return this._value;
  }
  unset() {
    this._value = undefined;
    this._reactiveVar.set(this._value);
  }
  set(id: string) {
    this._value = id;
    this._reactiveVar.set(this._value);
  }
  reactive() {
    return this._reactiveVar.get();
  }
}

export const ListBeingReordered = new LBR();
