import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { Analytics } from '@/browser/analytics/Analytics';
import HelpWindow from '@/help/HelpWindow';

if (typeof window.clientModules == 'undefined') window.clientModules = {};

clientModules.globalEventHandlers = {
  attach() {
    globalEventsToHandle.forEach((thing) => {
      $(document.body).on(thing.event, thing.selector, thing.handler);
    });
  },
};

const globalEventsToHandle = [
  {
    event: 'click',
    selector: '[data-atcl]',
    handler(event) {
      if (event.currentTarget?.disabled) return;
      const analyticsClickEvent = event.currentTarget?.dataset?.atcl;
      if (!analyticsClickEvent) return;
      const label = event.currentTarget?.dataset?.albl;
      Analytics.trackEvent(`${analyticsClickEvent}.Click`, label ? { label } : undefined);
    },
  },
  {
    event: 'click',
    selector: '.js-global-openHelpWindow',
    handler(event) {
      event.preventDefault();
      HelpWindow.show($(event.currentTarget).data('help-page'));
      $('.js-global-seeHelp').fadeOut();
    },
  },
  {
    event: 'click',
    selector: '.js-global-openContactWindow',
    handler(event) {
      event.preventDefault();
      HelpWindow.hide();
      if (window.innerWidth < 500) {
        FlowRouter.go('contact');
      } else {
        Session.set('showContactWindow', Date.now());
      }
    },
  },
  {
    event: 'click',
    selector: '.js-global-openInstallApp',
    handler(event) {
      event.preventDefault();
      FlowRouter.go('install');
    },
  },
  {
    event: 'click',
    selector: '.js-global-openAbout',
    handler(event) {
      event.preventDefault();
      HelpWindow.show('about');
    },
  },
  {
    event: 'click',
    selector: '.js-global-openPolicy',
    handler(event) {
      event.preventDefault();
      Modal.show('policyModal', {
        policy: $(event.currentTarget).data('policy'),
      });
    },
  },
  {
    event: 'click',
    selector: 'a[href^="http"][target="_blank"], a[href^="Http"][target="_blank"]',
    handler(event) {
      if (Meteor.isCordova) {
        event.preventDefault();
        const iabOptions = [
          'toolbarcolor=#265C86',
          'shouldPauseOnSuspend=yes',
          'presentationstyle=fullscreen',
          'useWideViewPort=no',
          'zoom=no',
          'allowInlineMediaPlayback=yes',
          'navigationbuttoncolor=#ffffff',
          'closebuttoncolor=#ffffff',
        ];
        window.open(event.currentTarget.href, '_blank', iabOptions.join(','));
      }
    },
  },
  {
    event: 'click',
    selector: 'a[href^="http"][target="_system"], a[href^="Http"][target="_system"]',
    handler(event) {
      if (Meteor.isCordova) {
        event.preventDefault();
        window.open(event.currentTarget.href, '_system', 'location=no');
      }
    },
  },
];

const isIOS =
  /iP(hone|ad|od)/i.test(navigator.userAgent) ||
  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

if (isIOS) {
  globalEventsToHandle.push({
    event: 'blur',
    selector: 'input',
    handler(event) {
      var maxScrollTop = Math.max(
        0,
        document.scrollingElement.scrollHeight - document.scrollingElement.clientHeight
      );
      if (
        !(event.relatedTarget?.nodeName == 'INPUT') &&
        document.scrollingElement.scrollTop > maxScrollTop
      ) {
        document.scrollingElement.scrollTop = maxScrollTop;
      }
    },
  });
}
