import type { SampleDefinition } from '@/audio/engine/base/Sample';

const countSampleDefinitions: Array<SampleDefinition> = [
  { id: 'count-1' },
  { id: 'count-2' },
  { id: 'count-3' },
  { id: 'count-4' },
  { id: 'count-1-slow' },
  { id: 'count-2-slow' },
  { id: 'count-3-slow' },
  { id: 'count-4-slow' },
  { id: 'count-boom' },
  { id: 'count-chuck' },
  { id: 'count-primer' },
].map((def) => {
  return { ...def, packageId: 'count_2022-11' };
});

export default countSampleDefinitions;
