import { strict as assert } from 'assert';
import { z } from 'zod';
import { shiftStringFret } from '@/band/instruments/guitar/shiftStringFret';
import type { InstrumentSetting } from '@/band/instruments/InstrumentSetting';
import { Chord } from '@/music/Chord';
import chromaForNote from '@/music/chromaForNote';
import cloneJSON from '@/utilities/cloneJSON';

const serializedSchema = z.object({
  mj: z.enum(['gacde', 'gcde', 'gcd', 'gc', 'gd', 'ea']).optional(),
  mi: z.enum([/*'o',*/ 'ead', 'ad' /*'gd5'*/]).optional(),
  gv: z.enum(['G5', 'G']).optional(),
  dv: z.enum(['D', 'D2', 'D5']).optional(),
  dd: z.boolean().optional(),
  b7: z.boolean().optional(),
  cadd9: z.boolean().optional(),
  // ev: z.enum(['e', 'e5']),
  // mjo: z.record(z.enum(['G', 'A', 'C', 'D', 'E']), z.boolean()),
  // mjo: z.array(z.enum(['G', 'A', 'C', 'D', 'E'])),
});

type Serialization = z.infer<typeof serializedSchema>;

// The order of this hash is important, because the first matching one will be used when selecting a chord shape
const majorChordShapesHash = {
  'gcde': ['G', 'C', 'D', 'E'],
  'gacde': ['G', 'A', 'C', 'D', 'E'],
  'gcd': ['G', 'C', 'D'],
  'gd': ['G', 'D'],
  'gc': ['G', 'C'],
  'ea': ['E', 'A'],
} as const;

const minorChordShapesHash = {
  'ead': ['Em', 'Am', 'Dm'],
  'ad': ['Am', 'Dm'],
  // 'gd5': ['G5', 'D5'],
} as const;

const allMajorPositions = ['G', 'A', 'C', 'D', 'E'] as const;
const allMinorPositions = ['Em', 'Am', 'Dm' /*'G5', 'D5'*/] as const;

type AllPositions = (typeof allMajorPositions)[number] | (typeof allMinorPositions)[number];

const maxCapoForShapes: Partial<Record<AllPositions, number>> = {
  'G': 6,
  'A': 6,
  'C': 6,
  'D': 4,
  'E': 4,
  'Am': 4,
  'Em': 4 + 2,
  'Dm': 4,
  // 'G5': 6,
  // 'D5': 4,
};

const defaultSetting: Serialization = {
  'mj': 'gcde',
  'mi': 'ead',
  'gv': 'G5',
  'dv': 'D',
  'dd': false,
  'b7': true,
  'cadd9': false,
};

export class GuitarOpenVoicingsSetting implements InstrumentSetting<Serialization> {
  readonly majorRootShapes: NonNullable<Serialization['mj']>;
  readonly minorRootShapes: NonNullable<Serialization['mi']>;
  readonly voicingForG: NonNullable<Serialization['gv']>;
  readonly voicingForD: NonNullable<Serialization['dv']>;
  readonly dropD: boolean;
  readonly useB7: boolean;
  readonly useCadd9: boolean;

  // calculated properties
  readonly majorChordShapesChosen: Readonly<Chord['letter'][]>;
  readonly minorChordShapesChosen: Readonly<`${Chord['letter']}${'m' | '5' | ''}`[]>;
  readonly powerG: boolean;

  constructor(input: unknown = {}) {
    const data = serializedSchema.catch(defaultSetting).parse(input);
    this.majorRootShapes = data.mj ?? 'gcde';
    this.minorRootShapes = data.mi ?? 'ead';
    this.voicingForG = data.gv ?? 'G5';
    this.voicingForD = data.dv ?? 'D';
    this.dropD = data.dd ?? false;
    this.useB7 = data.b7 ?? true;
    this.useCadd9 = data.cadd9 ?? false;
    // and now the calculated properties
    this.powerG = data.gv === 'G5';
    this.majorChordShapesChosen =
      majorChordShapesHash[this.majorRootShapes] ?? majorChordShapesHash['gcde'];
    this.minorChordShapesChosen =
      minorChordShapesHash[this.minorRootShapes] ?? minorChordShapesHash['ead'];
  }

  serialize(): Serialization {
    return cloneJSON({
      mj: this.majorRootShapes,
      // mi: this.minorRootShapes,
      gv: this.voicingForG == 'G5' ? undefined : this.voicingForG,
      dv: this.voicingForD == 'D' ? undefined : this.voicingForD,
      dd: this.dropD || undefined,
      b7: this.useB7 ? undefined : false,
      cadd9: this.useCadd9 || undefined,
    });
  }

  closeTo(serialized?: Serialization): boolean {
    const other = new GuitarOpenVoicingsSetting(serialized);
    return (
      other.majorRootShapes === this.majorRootShapes &&
      other.minorRootShapes === this.minorRootShapes &&
      other.voicingForG === this.voicingForG &&
      other.voicingForD === this.voicingForD &&
      other.dropD === this.dropD &&
      other.useB7 === this.useB7 &&
      other.useCadd9 === this.useCadd9
    );
  }

  withMajorRootShapes(majorRootShapes: typeof this.majorRootShapes) {
    return new GuitarOpenVoicingsSetting({
      ...this.serialize(),
      mj: majorRootShapes,
    });
  }

  withMinorRootShapes(minorRootShapes: typeof this.minorRootShapes) {
    return new GuitarOpenVoicingsSetting({
      ...this.serialize(),
      mi: minorRootShapes,
    });
  }

  withMajorRootShapeInKey(key: NonNullable<Chord['key']>, shape: AllPositions) {
    const possibilities = this.positionSetsWithRootShapeInKey(
      key,
      shape
    ) as (typeof this.majorRootShapes)[];
    if (possibilities.includes(this.majorRootShapes)) return this;
    return new GuitarOpenVoicingsSetting({
      ...this.serialize(),
      mj: possibilities[0],
    });
  }

  withMinorRootShapeInKey(key: NonNullable<Chord['key']>, shape: AllPositions) {
    const possibilities = this.positionSetsWithRootShapeInKey(
      key,
      shape
    ) as (typeof this.minorRootShapes)[];
    if (possibilities.includes(this.minorRootShapes)) return this;
    return new GuitarOpenVoicingsSetting({
      ...this.serialize(),
      mi: possibilities[0],
    });
  }

  withVoicingForG(voicing: Serialization['gv']) {
    return new GuitarOpenVoicingsSetting({
      ...this.serialize(),
      gv: voicing,
    });
  }

  withVoicingForD(voicing: Serialization['dv']) {
    return new GuitarOpenVoicingsSetting({
      ...this.serialize(),
      dv: voicing,
    });
  }

  withDropDSetTo(dd: boolean) {
    return new GuitarOpenVoicingsSetting({
      ...this.serialize(),
      dd,
    });
  }

  withB7SetTo(b7: boolean) {
    return new GuitarOpenVoicingsSetting({
      ...this.serialize(),
      b7,
    });
  }

  withCadd9SetTo(cadd9: boolean) {
    return new GuitarOpenVoicingsSetting({
      ...this.serialize(),
      cadd9,
    });
  }

  positionSetsWithRootShapeInKey(
    key: NonNullable<Chord['key']>,
    shape: AllPositions
  ): (Serialization['mi'] | Serialization['mj'])[] {
    const availablePositions = GuitarOpenVoicingsSetting.positionsInKey(key);
    const indexOfDesiredShape = availablePositions.findIndex((pos) => pos.shape === shape);
    const positionsToExclude =
      indexOfDesiredShape > 0 ? availablePositions.slice(0, indexOfDesiredShape) : [];
    const minor = new Chord(key).minor;
    const chordShapesHash = minor ? minorChordShapesHash : majorChordShapesHash;
    const possibilities = Object.entries(chordShapesHash)
      .filter(([_, shapes]: [unknown, string]) => {
        return (
          !positionsToExclude.some((pos) => shapes.includes(pos.shape)) && shapes.includes(shape)
        );
      })
      .map(([id]) => id);
    return possibilities as (keyof typeof chordShapesHash)[];
  }

  positionToUseForKey(key: NonNullable<Chord['key']>): {
    shape: AllPositions;
    capo: number;
  } {
    const positions = GuitarOpenVoicingsSetting.positionsInKey(key);
    const minor = new Chord(key).minor;
    const chordShapesChosen = minor ? this.minorChordShapesChosen : this.majorChordShapesChosen;
    const positionToUse = positions.find((pos) => chordShapesChosen.includes(pos.shape));
    assert(positionToUse, `No position found for ${key}`);
    return positionToUse;
  }

  rootNoteStringFretCapoInKey(key: NonNullable<Chord['key']>): {
    string: number;
    fret: number;
    capo: number;
  } {
    const position = this.positionToUseForKey(key);
    const { string, fret } = (
      {
        'G': { string: 6, fret: 3 },
        'A': { string: 5, fret: 0 },
        'C': { string: 5, fret: 3 },
        'D': { string: 4, fret: 0 },
        'dD': { string: 6, fret: -2 }, // drop D
        'E': { string: 6, fret: 0 },
        'Am': { string: 5, fret: 0 },
        'Dm': { string: 4, fret: 0 },
        'Em': { string: 6, fret: 0 },
      } as const
    )[position.shape.startsWith('D') && this.dropD ? 'dD' : position.shape];
    return {
      string: string,
      fret: fret + position.capo,
      capo: position.capo,
    };
  }

  intervalStringFretInKey(
    key: NonNullable<Chord['key']>,
    interval: number
  ): {
    string: number;
    fret: number;
    capo: number;
  } {
    const position = this.positionToUseForKey(key);
    const baseStringFret = (
      {
        'G': { string: 6, fret: 3 },
        'A': { string: 5, fret: 0 },
        'C': { string: 5, fret: 3 },
        'D': { string: 4, fret: 0 },
        'dD': { string: 6, fret: -2 }, // drop D
        'E': { string: 6, fret: 0 },
        'Am': { string: 5, fret: 0 },
        'Dm': { string: 4, fret: 0 },
        'Em': { string: 6, fret: 0 },
      } as const
    )[position.shape.startsWith('D') && this.dropD ? 'dD' : position.shape];
    const shiftedStringFret = shiftStringFret(
      { string: baseStringFret.string, fret: baseStringFret.fret + position.capo },
      interval,
      {
        capo: position.capo,
        dropD: this.dropD && position.shape.startsWith('D'),
      }
    );
    return {
      ...shiftedStringFret,
      capo: position.capo,
    };
  }

  static positionsInKey(key: NonNullable<Chord['key']>): { shape: AllPositions; capo: number }[] {
    const keyTonic = new Chord(key).rootNote;
    const minor = new Chord(key).minor;
    return (minor ? allMinorPositions : allMajorPositions)
      .map((shape) => {
        return {
          shape,
          capo:
            (chromaForNote(keyTonic) - chromaForNote(shape.charAt(0) as Chord['letter']) + 12) % 12,
        };
      })
      .filter(({ capo, shape }) => capo >= 0 && capo <= (maxCapoForShapes[shape] ?? 6))
      .sort((a, b) => a.capo - b.capo);
  }
}
