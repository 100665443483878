import { MasterChannel } from '@/audio/engine/base/MasterChannel';

export class WebAudioMasterChannel extends MasterChannel {
  readonly compressorNode;
  readonly gainNode;
  readonly finalOutputNode;

  constructor(context: AudioContext) {
    super();
    this.gainNode = context.createGain();

    this.compressorNode = context.createDynamicsCompressor();
    this.compressorNode.threshold.value = -4;
    this.compressorNode.knee.value = 8;
    this.compressorNode.ratio.value = 6;
    this.compressorNode.attack.value = 0.05;
    this.compressorNode.release.value = 0.15;

    this.finalOutputNode = context.createGain();

    // explicitly set these, just in case...
    [this.gainNode, this.compressorNode, this.finalOutputNode].forEach((node) => {
      node.channelCount = 2;
      node.channelCountMode = 'explicit';
      node.channelInterpretation = 'speakers';
    });

    this.gainNode.connect(this.compressorNode);
    this.compressorNode.connect(this.finalOutputNode);
    this.finalOutputNode.connect(context.destination);
  }

  connectToOutput(node: AudioNode): void {
    node.connect(this.gainNode);
  }

  disconnectFromOutput(node: AudioNode): void {
    node.disconnect(this.gainNode);
  }

  set volume(val: number) {
    // Do a quick fade to prevent popping
    this.gainNode.gain.cancelScheduledValues(this.gainNode.context.currentTime);
    this.gainNode.gain.setTargetAtTime(val, this.gainNode.context.currentTime, 0.05);
    this.gainNode.gain.setValueAtTime(val, this.gainNode.context.currentTime + 0.4);
  }

  get volume(): number {
    return this.gainNode.gain.value;
  }

  private _pitchShift = 0;
  private _masterPlaybackRate = 1;
  set pitchShift(cents: number) {
    this._pitchShift = typeof cents == 'number' ? Math.max(-100, Math.min(100, cents)) : 0;
    this._masterPlaybackRate =
      this._pitchShift == 0 ? 1.0 : Math.pow(1.059463094659, this._pitchShift / 100.0);
  }

  get pitchShift(): number {
    return this._pitchShift;
  }

  get masterPlaybackRate(): number {
    return this._masterPlaybackRate;
  }

  // TODO: Master channel EQ
  // TODO: Master channel reverb
}
