import type { Cell } from '@/chart/Cell.svelte';
import { LinearizedMeasure } from '@/chart/LinearizedMeasure';
import type { LinearizedSong } from '@/chart/LinearizedSong';
import type { Section } from '@/chart/Section.svelte';
import type { Song } from '@/chart/Song';
import type { SongSelection } from '@/chart/SongSelection';

export function getLinearizedMeasuresForSong({
  linearization,
  song,
  loop,
}: {
  linearization: LinearizedSong;
  song: Song;
  loop?: SongSelection;
}): readonly LinearizedMeasure[] {
  if (!song.sections) return [];
  const timeSignature = song.timeSignature() || '4/4';
  const measures: LinearizedMeasure[] = [];
  for (const section of song.sections) {
    const playWholeSection = !loop?.spansMultipleCells() || loop?.containsEntireSection(section);
    const loopEndsInThisSection = loop?.spansMultipleCells() && loop.lastCell()?.section == section;
    const repsFrom = playWholeSection || loopEndsInThisSection ? 0 : section.repetitions - 1;
    const repsTo = playWholeSection ? section.repetitions - 1 : repsFrom;
    for (let rep = repsFrom; rep <= repsTo; rep++) {
      measures.push(
        ...getMeasuresForSectionRep({
          linearization,
          section,
          rep,
          loop,
          timeSignature,
        })
      );
    }
  }
  return measures;
}

function getMeasuresForSectionRep({
  linearization,
  section,
  rep,
  loop,
  timeSignature,
}: {
  linearization: LinearizedSong;
  section: Section;
  rep: number;
  loop?: SongSelection;
  timeSignature: TimeSignature;
}): LinearizedMeasure[] {
  const measures: LinearizedMeasure[] = [];
  const cellsInMeasure: Cell[] = [];
  for (const cell of section.cells) {
    // First we skip over any cells where there's a volta that doesn't apply.
    // Then we skip over any cells that aren't in the loop.
    const inNonMatchingVolta = cell.repConstraint && !cell.repConstraint.includes(rep + 1);
    const outsideOfLoop = loop?.spansMultipleCells() && !loop?.hasCell(cell);

    if (!inNonMatchingVolta && !outsideOfLoop) {
      cellsInMeasure.push(cell);
    }

    if (
      inNonMatchingVolta ||
      outsideOfLoop ||
      cell.layout.barEnd ||
      cell.layout.cellIndex == section.cells.length - 1
    ) {
      if (cellsInMeasure.length > 0) {
        measures.push(
          new LinearizedMeasure({
            song: linearization,
            timeSignature: cellsInMeasure[0]?.effectiveTimeSignature || timeSignature,
            rep,
            cells: cellsInMeasure as [Cell, ...Cell[]],
          })
        );
        cellsInMeasure.length = 0;
      }
    }
  }

  return measures;
}
