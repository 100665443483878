<script lang="ts">
  import { onMount } from 'svelte';
  import type { Instance as TippyInstance } from 'tippy.js';
  import tippyJs from 'tippy.js';
  import type { Song } from '@/chart/Song';
  import { Crnt } from '@/Crnt';
  import { showClipboardToast } from '@/editor/ui/clipboard-toasts';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import Icon from '@/ui/icons/Icon.svelte';
  import { capitalize } from '@/utilities/capitalize';

  interface Props {
    command: 'cut' | 'copy' | 'paste';
  }

  let { command }: Props = $props();

  let song: Song | undefined = $state();
  trackerEffect(() => (song = Crnt.song()));

  let canPaste: boolean = $state(false);
  trackerEffect(() => (canPaste = !!song?.withFocused().canPaste()));

  const cmdCtrlKeyPrefix =
    /iP(hone|ad|od)/i.test(navigator.userAgent) || navigator.platform === 'MacIntel'
      ? '⌘'
      : 'Ctrl+';

  let shortcutKey = $derived(cmdCtrlKeyPrefix + { cut: 'X', copy: 'C', paste: 'V' }[command]);

  let buttonElement: HTMLElement;
  let tooltipTippy: TippyInstance | undefined = $state();
  onMount(() => {
    tooltipTippy = tippyJs(buttonElement, {
      placement: 'top',
      delay: [400, 50],
      hideOnClick: false,
      allowHTML: true,
      content: `${capitalize(command)} (${shortcutKey})`,
    });
    return () => tooltipTippy?.destroy();
  });

  let clipboardToast: { hide: () => void } | undefined = $state();
</script>

<button
  class="colors-button flex items-center justify-center p-2.5 _{command}"
  style="border-radius: var(--editor-clipboard-button-radius, 0.375rem);"
  class:colors-disabled-button={command == 'paste' && !canPaste}
  aria-label={capitalize(command)}
  onfocus={() => tooltipTippy?.enable()}
  onpointerenter={() => {
    tooltipTippy?.enable();
    clipboardToast?.hide();
  }}
  onclick={() => {
    tooltipTippy?.hide();
    tooltipTippy?.disable();
    if (command == 'cut') {
      song?.withFocused().cut();
      clipboardToast = showClipboardToast(buttonElement, command);
    } else if (command == 'copy') {
      song?.withFocused().copy();
      clipboardToast = showClipboardToast(buttonElement, command);
    } else if (command == 'paste' && canPaste) {
      const success = song?.withFocused().paste();
      if (success) clipboardToast = showClipboardToast(buttonElement, command);
    } else if (command == 'paste' && !canPaste) {
      clipboardToast = showClipboardToast(buttonElement, 'pasteboard-empty');
    }
  }}
  bind:this={buttonElement}
>
  <Icon id={`${command}-icon`} size={15} />
</button>

<style>
  ._cut {
    @media (width < 26rem) {
      display: none;
    }
  }
</style>
