import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import logout from '@/user/logout';

Template.appEntryPage.onCreated(() => {
  Template.instance().redirect = new URLSearchParams(window.location.search).get('redirect');
});

Template.appEntryPage.onRendered(() => {
  const instance = Template.instance();
  instance.find('.js-loginFormSubPages')._uihooks = {
    insertElement(node, next) {
      const dir = /^(login|signup)$/.test(FlowRouter.getRouteName()) ? 'right' : 'left';
      $(node).addClass(`in-${dir}`).insertBefore(next);
    },
    removeElement(node) {
      const dir = /^(login|signup)$/.test(FlowRouter.getRouteName()) ? 'right' : 'left';
      $(node).removeClass(['in-left', 'in-right']).addClass(`out-${dir}`);
      setTimeout(() => $(node).remove(), 300);
    },
  };
});

Template.appEntryPage.events({
  'click .js-logout': function (event, instance) {
    event.preventDefault();
    logout();
  },

  'click .js-contactEmailConfirmation': function (event, instance) {
    event.preventDefault();
    bootbox.alert({
      title: 'Opening your email app...',
      message:
        "If it doesn't open automatically, the address to write is <b>support@strummachine.com</b>",
    });
    setTimeout(() => {
      window.open('mailto:support@strummachine.com', '_system');
    }, 2000);
  },
});

const routeTemplates = {
  'login': 'appEntryEmailPasswordForm',
  'signup': 'appEntryEmailPasswordForm',
  'login-code': 'appEntryCodeForm',
  'login-name': 'appEntryNameForm',
  'mobile-subscribe': 'appEntryMobileSubscribeForm',
  'forgot-password': 'forgotPasswordForm',
  'forgot-password-sent': 'forgotPasswordSent',
  'reset-password': 'resetPasswordForm',
  'desktop-login': 'desktopLogin',
  'desktop-sso-complete': 'desktopSSOComplete',
};
const routeTemplateData = {
  'signup': { signup: true },
};

Template.appEntryPage.helpers({
  currentUserEmail() {
    return Meteor.user()?.emails[0].address;
  },
  routeName() {
    return FlowRouter.getRouteName();
  },
  templateName() {
    return routeTemplates[FlowRouter.getRouteName()];
  },
  templateData() {
    return {
      redirect: Template.instance().redirect,
      ...routeTemplateData[FlowRouter.getRouteName()],
    };
  },
});
