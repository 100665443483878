<script lang="ts">
  import { createBubbler } from 'svelte/legacy';
  import type { Placement } from 'tippy.js';
  import { tooltip } from '@/ui/tooltip';

  const bubble = createBubbler();

  interface Props {
    text?: string;
    html?: string;
    placement?: Placement;
    delay?: number;
  }

  let { text = '', html = '', placement = 'top', delay = 150 }: Props = $props();
</script>

{#if text || html}
  <button
    use:tooltip={{
      allowHTML: !!html,
      delay: [delay, 50],
      placement: placement,
    }}
    data-tippy-content={html || text}
    class="px-0.5 text-gray-500 transition-colors duration-200 dsktp:hover:text-muted-700"
    onclick={bubble('click')}
    ondblclick={bubble('dblclick')}
    aria-label="Contextual help"
  >
    <i class="smi smi-help-circle-outline" aria-hidden="true"></i>
  </button>
{/if}
