import { Meteor } from 'meteor/meteor';
import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { Roles } from '@/Roles';

Template.subscribePage.onCreated(() => {
  Template.instance().data.setTitle('How to Subscribe');

  if (!Meteor.userId()) {
    FlowRouter.go('login', {}, { redirect: 'subscribe' });
  }

  if (
    Meteor.userId() &&
    Roles.userHasRole(Meteor.userId(), { $in: ['paid-member', 'lifetime-member'] }) &&
    !Roles.userHasRole(Meteor.userId(), 'admin')
  ) {
    FlowRouter.go('account');
    Bert.alert('You are subscribed!', 'success');
  }
});
