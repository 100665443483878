import { strict as assert } from 'assert';
import type { GuitarChord } from '@/band/instruments/guitar/GuitarChord';
import { guitarChordDefinitionsByType } from '@/band/instruments/guitar/guitarChordDefinitionsByType';
import { guitarChordStrumSpecs } from '@/band/instruments/guitar/guitarChordStrumSpecs';
import { Chord } from '@/music/Chord';

const fretStringToNumber = (s: string) => {
  return /[0-9abc]/.test(s) ? parseInt(s, 16) : s == 'D' ? -2 : NaN;
};

const fretNumberToString = (fret: number) => {
  if (fret == -2) return 'D';
  if (fret < 0 || fret > 13) throw new Error(`Invalid fret number ${fret}`);
  return fret.toString(16);
};

function loadChords() {
  function raiseStrings(strings: string, by: number): string {
    return strings
      .split('')
      .map((s) => {
        const fretNumber = fretStringToNumber(s);
        if (isNaN(fretNumber)) return s;
        return fretNumberToString(fretNumber + by);
      })
      .join('');
  }

  Object.values(guitarChordDefinitionsByType)
    .flat()
    .forEach(([strings, name, generatorName]) => {
      const baseChord = new Chord(name);
      for (let raisedBy = 0; raisedBy < 9; raisedBy++) {
        const chord = baseChord.shift(raisedBy, baseChord.rootNote).withoutKey();
        if (strings.includes('D') && raisedBy == 1) continue; // don't use drop D up 1
        const raisedStrings = raiseStrings(strings, raisedBy);
        const strumSpec = guitarChordStrumSpecs[generatorName];
        assert(strumSpec, `No strum spec for ${generatorName}`);
        if (raisedStrings.includes('d')) break; // no chords go beyond fret 12!
        const rootNoteFret = raisedStrings[6 - strumSpec.rootFifthSixth[0]] ?? '_';
        const bassNotePosition = {
          string: strumSpec.rootFifthSixth[0],
          fret: fretStringToNumber(rootNoteFret),
        };
        GuitarChordLibrary.addChord({
          chord,
          strings: raisedStrings,
          shapeStrings: strings,
          shapeChord: baseChord,
          bassNotePosition,
          capo: raisedBy,
          strumSpec,
          closed: strumSpec.closed,
          droning: strumSpec.droning,
          dropD: strumSpec.dropD,
        });
      }
    });
  GuitarChordLibrary.sort();
}

function makeSlashGuitarChord(guitarChord: GuitarChord, chord: Chord) {
  const bassNotePosition = findLowestCapoedNoteWithChroma({
    minFret: guitarChord.capo,
    chroma: chord.bassChroma ?? chord.chroma,
  });
  return guitarChordWithSpecifiedBassNote(guitarChord, bassNotePosition, chord);
}

function guitarChordWithSpecifiedBassNote(
  guitarChord: GuitarChord,
  bassNotePosition: { string: number; fret: number },
  chord?: Chord
) {
  const { string, fret } = bassNotePosition;
  const result = <GuitarChord>{
    ...JSON.parse(JSON.stringify(guitarChord)),
    chord: chord ?? guitarChord.chord,
    bassNotePosition: { string, fret },
    strings: '_'.repeat(6 - string) + fret.toString(16) + guitarChord.strings.slice(6 - string + 1),
  };
  result.strumSpec.rootFifthSixth = [string, string, string];
  for (let i = 6; i > string; i--) {
    result.strumSpec.bias.bass[i] = 7;
  }
  result.strumSpec.bias.bass[6 - string] = 1;
  return result;
}

function findLowestCapoedNoteWithChroma({ minFret, chroma }: { minFret: number; chroma: number }): {
  string: number;
  fret: number;
} {
  for (let stringIndex = 0; stringIndex <= 2; stringIndex++) {
    for (let fret = minFret; fret < minFret + 5 && fret <= 12; fret++) {
      const thisChroma = 4 /* open E */ + stringIndex * 5 + fret;
      if (thisChroma % 12 == chroma) {
        return { string: 6 - stringIndex, fret };
      }
    }
  }
  throw new Error("Couldn't find bass note position for guitar chord");
}

export class GuitarChordLibrary {
  private static _chromaMaps: Map<string, GuitarChord[]>[] =
    // create an array of twelve Map objects
    Array.from({ length: 12 }).map(() => new Map<string, GuitarChord[]>());
  private static _unsorted = true;
  static size = 0;

  static getChords(chord: Chord): GuitarChord[] {
    if (this._unsorted) this.sort();
    return this._chromaMaps[chord.chroma]?.get(chord.type || 'major') || [];
  }

  static pickChord(
    chord: Chord | string,
    {
      capo = 0,
      closed = false,
      droning = false,
      useThirdlessG = true,
      useThirdlessD = false,
      useSusD = false,
      useCadd9 = false,
      dropD = false,
      useB7 = false,
    } = {}
  ): GuitarChord {
    if (typeof chord == 'string') chord = new Chord(chord);
    if (!chord.type && !closed) {
      if (useThirdlessG && (chord.chroma + 5) % 12 == capo) chord = chord.withNewType('5');
      if (useThirdlessD && (chord.chroma + 10) % 12 == capo) chord = chord.withNewType('5');
      if (useSusD && (chord.chroma + 10) % 12 == capo) chord = chord.withNewType('2');
      if (useB7 && (chord.chroma + 1) % 12 == capo) chord = chord.withNewType('7');
      if (useCadd9 && chord.chroma % 12 == capo) chord = chord.withNewType('2');
    }
    const availableChords = this.getChords(chord);

    const result =
      (closed ? availableChords.find((cd) => cd.closed) : undefined) ??
      (droning ? availableChords.find((cd) => cd.droning) : undefined) ??
      (dropD ? availableChords.find((cd) => cd.dropD && cd.capo == capo) : undefined) ??
      availableChords.find(
        (cd) => cd.capo >= capo && !!cd.closed == closed && !!cd.droning == droning && !cd.dropD
      ) ??
      availableChords.find((cd) => cd.capo >= capo && !!cd.droning == droning && !cd.dropD) ??
      availableChords.find((cd) => cd.capo >= capo && !cd.dropD) ??
      availableChords.find((cd) => !!cd.droning == droning && !cd.dropD) ??
      availableChords.slice(-1)[0];
    assert(result, `No GuitarChord found for ${chord.toString()}`);
    return chord.bassNote ? makeSlashGuitarChord(result, chord) : result;
  }

  static addChord(guitarChord: GuitarChord) {
    const mapForChroma = this._chromaMaps[guitarChord.chord.chroma];
    if (!mapForChroma) throw new RangeError('Chroma out of range');
    const entry = mapForChroma.get(guitarChord.chord.type || 'major');
    if (entry) {
      entry.push(guitarChord);
      this._unsorted = true;
    } else {
      mapForChroma.set(guitarChord.chord.type || 'major', [guitarChord]);
    }
  }

  static sort() {
    this.size = 0;
    for (const chromas of this._chromaMaps.values()) {
      for (const array of chromas.values()) {
        array.sort((a, b) => a.capo - b.capo);
        this.size += array.length;
      }
    }
    this._unsorted = false;
  }

  static applyBassNoteToGuitarChord(
    guitarChord: GuitarChord,
    bassNote: { string: number; fret: number }
  ) {
    return guitarChordWithSpecifiedBassNote(guitarChord, bassNote);
  }
}

loadChords();
