import * as Sentry from '@sentry/browser';
import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { ReactiveVar } from 'meteor/reactive-var';
import tippyJs from 'tippy.js';
import { currentTimeZone } from '@/browser/currentTimeZone';
import { rpcStartCheckoutSessionForSubscription } from '@/server/methods/stripe/rpcStartCheckoutSessionForSubscription';
import { formatCurrencyAmount } from '@/utilities/formatCurrencyAmount';

const subscriptionPrices = {
  'usd': { month: 500, year: 4900 },
  'eur': { month: 500, year: 4900 },
  'gbp': { month: 429, year: 4290 },
};

Template.subscribeForm.onCreated(() => {
  Template.instance().period = new ReactiveVar();
  Template.instance().currency = new ReactiveVar(
    FlowRouter.getQueryParam('currency') ||
      (currentTimeZone.inUK ? 'gbp' : currentTimeZone.inEurope ? 'eur' : 'usd')
  );
  Template.instance().pendingRedirect = new ReactiveVar(false);
});

Template.subscribeForm.onRendered(() => {
  const instance = Template.instance();

  [...instance.$('[data-tippy-content]')].forEach((el) => {
    tippyJs(el, {
      placement: 'bottom',
      delay: [300, 50],
      hideOnClick: false,
      maxWidth: 250,
    });
  });
});

Template.subscribeForm.helpers({
  currency() {
    return Template.instance().currency.get();
  },

  displayCurrency() {
    return Template.instance().currency.get().toUpperCase();
  },

  currencyIs(value) {
    return Template.instance().currency.get() == value;
  },

  vatIncluded() {
    return !['usd', 'cad'].includes(Template.instance().currency.get());
  },

  probablyInEurope() {
    return currentTimeZone.inEurope;
  },

  period() {
    return Template.instance().period.get();
  },

  periodIs(value) {
    return Template.instance().period.get() == value;
  },

  priceFor(period) {
    const currency = Template.instance().currency.get();
    return formatCurrencyAmount(currency, subscriptionPrices[currency][period]).replace('.00', '');
  },

  annualDiscount() {
    const currency = Template.instance().currency.get();
    return (
      Math.floor(
        100 -
          (100 * subscriptionPrices[currency]['year']) /
            (subscriptionPrices[currency]['month'] * 12)
      ) + '%'
    );
  },

  disableCheckoutButton() {
    const instance = Template.instance();
    return !instance.period.get() || instance.pendingRedirect.get();
  },

  falseIfNoPlan() {
    const instance = Template.instance();
    return instance.period.get() ? undefined : false;
  },

  disabledCaptionCheckoutButton() {
    const instance = Template.instance();
    return instance.period.get() ? undefined : 'Choose a plan above';
  },
});

Template.subscribeForm.events({
  'click [data-currency]': function (event, instance) {
    const value = $(event.currentTarget).attr('data-currency');
    instance.currency.set(value);
    event.preventDefault();
  },

  'click [data-period]': function (event, instance) {
    const value = $(event.currentTarget).attr('data-period');
    instance.period.set(value);
    event.preventDefault();
  },

  'click .js-checkout': async function (_event, instance) {
    instance.pendingRedirect.set(true);
    try {
      const { redirectUrl } = await rpcStartCheckoutSessionForSubscription({
        currency: instance.currency.get(),
        period: instance.period.get(),
      });
      window.location.href = redirectUrl;
    } catch (err) {
      Sentry.captureException(err);
      Bert.alert(err.reason || err.message || err, 'danger');
      instance.pendingRedirect.set(false);
    }
  },
});
