import { Meteor } from 'meteor/meteor';
import { ReactiveVar } from 'meteor/reactive-var';
import { Tracker } from 'meteor/tracker';
import Sortable from 'sortablejs';
import SongIndexItem from '@/ui/SongIndexItem';

Template.medleySongIndex.onCreated(() => {
  const instance = Template.instance();
  instance.reorderingChangesToSave = new ReactiveVar(false);
  Tracker.afterFlush(() => {
    instance.sortable = Sortable.create(instance.find('.songIndexList'), {
      draggable: '.songIndexItem',
      handle: '.songIndexItem-link',
      animation: 180,
      forceFallback: true,
      onEnd(_event) {
        const songIds = instance.sortable.toArray();
        instance.data.medleySongs.orderByIds(songIds);
      },
      scrollSensitivity: 50,
    });
  });
});

Template.medleySongIndex.onRendered(() => {
  const instance = Template.instance();
  instance.find('.medleySongIndex')._uihooks = {
    insertElement(node, next) {
      $(node)
        .insertBefore(next)
        .hide()
        .velocity('slideDown', {
          duration: 200,
          easing: [0.4, 0, 0.4, 1],
          queue: false,
          display: 'flex',
          begin: () => {
            $(node).find('.js-remove').hide();
            setTimeout(() => $(node).find('.js-remove').fadeIn(), 0);
          },
        });
    },
    removeElement(node) {
      $(node).velocity('slideUp', {
        duration: 300,
        easing: [0.4, 0, 0.4, 1],
        queue: false,
        complete: () => $(node).remove(),
      });
    },
  };
});

Template.medleySongIndex.onDestroyed(() => {
  try {
    Template.instance().sortable?.destroy();
  } catch (e) {} // eslint-disable-line
});

Template.medleySongIndex.events({
  'click .js-remove': (event, instance) => {
    const songIndex = event.currentTarget.parentElement?.dataset['index'];
    instance.data.medleySongs.remove(songIndex);
  },
});

Template.medleySongIndex.helpers({
  songIndexItemFor(song) {
    const userId = Meteor.userId();
    return SongIndexItem.renderHtml({
      song: song,
      isOwn: song.userId == userId,
      otherUsers: true,
      loggedIn: !!userId,
      noLinks: true,
      textToHighlight: undefined,
      listId: undefined,
      showNew: false,
    });
  },

  moreThanOneSong() {
    return Template.currentData().medleySongs?.length > 1;
  },

  songNameWithoutLabel(name) {
    return name.replace(/ +\[.+\]$/, '');
  },
});
