import { Meteor } from 'meteor/meteor';
import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { ReactiveVar } from 'meteor/reactive-var';
import { eventTracker } from '@/browser/analytics/eventTracker';
import { MusicLibrary } from '@/library/MusicLibrary';
import { MusicLibrarySearcher } from '@/library/MusicLibrarySearcher';
import { MedleySongIndexArray } from '@/medleys/MedleySongIndexArray';
import { UserProfile } from '@/user/UserProfile';

Template.newMedleyModal.onCreated(() => {
  const instance = Template.instance();
  instance.searcher = new MusicLibrarySearcher();
  instance.songsForNewMedley = new MedleySongIndexArray();
  instance.creatingMedley = new ReactiveVar(false);

  if (instance.data?.firstSongId) {
    instance.songsForNewMedley.push(MusicLibrary.index.getIndexRecord(instance.data.firstSongId));
  }

  MusicLibrary.load();
});

Template.newMedleyModal.events({
  'click .js-done': async (_event, instance) => {
    instance.creatingMedley.set(true);
    const newId = await MusicLibrary.medleys.createNewWithSongs(instance.songsForNewMedley);
    setTimeout(() => {
      Modal.hide();
      FlowRouter.go('show-medley', { medleyId: newId });
      eventTracker.medleyCreateDone();
      UserProfile.recordMilestone('NEW_MEDLEY');
      Meteor.defer(() => {
        const scrollContainer = document.querySelector('.js-global-songScrollContainer');
        if (scrollContainer) scrollContainer.scrollTop = 0;
        if (Meteor.status().connected) {
          Modal.show('listsMenuModal', {
            songId: newId,
            newSong: true,
          });
        }
      });
    }, 500); // for user experience, not necessity
  },

  'click .js-close': (_event, _instance) => {
    Modal.hide();
  },
});

Template.newMedleyModal.helpers({
  creationInProgress() {
    const instance = Template.instance();
    return instance.creatingMedley.get();
  },

  searcher() {
    const instance = Template.instance();
    return instance.searcher;
  },

  medleySongs() {
    return Template.instance().songsForNewMedley.reactive();
  },

  moreThanOneSong() {
    return Template.instance().songsForNewMedley.reactive().length > 1;
  },

  songPickedFunc() {
    const instance = Template.instance();
    return (songId) => {
      const newSong = MusicLibrary.index.getIndexRecord(songId);
      instance.songsForNewMedley.append(newSong);
      if (!instance.searcher.query()?.own) instance.searcher.setQuery(null);
    };
  },
});
