import { Chord } from '@/music/Chord';
import defineTippyDropdown from '@/ui/dropdowns/defineTippyDropdown';

defineTippyDropdown(Template.medleyKeyButton, {
  id: 'medley-key-button',
});

Template.medleyKeyButton.helpers({
  buttonText() {
    const song = Template.instance().data.song;
    const keyHeard = new Chord(song.keyHeard());
    // return `Key of <strong>${keyHeard.toHtml()}</strong>`;
    return keyHeard.toHtml();
  },
  ariaLabel() {
    const song = Template.instance().data.song;
    const keyHeard = new Chord(song.keyHeard());
    return `Key of ${keyHeard.toAria()}`;
  },
});
