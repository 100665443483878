import { strict as assert } from 'assert';
import { Tracker } from 'meteor/tracker';
import { getContext, setContext } from 'svelte';
import { writable } from 'svelte/store';
import type { Band } from '@/band/Band';

const contextKey = Symbol('band-menu-context');

type BandMenuNavState = {
  band: Band;
  timeSignature: TimeSignature;
  instruments: Band['instruments'];
  guitar: Band['instruments']['guitar'];
  mandolin: Band['instruments']['mandolin'];
  bass: Band['instruments']['bass'];
  feet: Band['instruments']['feet'];
  guitarSettings: ReturnType<Band['instruments']['guitar']['settings']>;
  mandolinSettings: ReturnType<Band['instruments']['mandolin']['settings']>;
  bassSettings: ReturnType<Band['instruments']['bass']['settings']>;
  feetSettings: ReturnType<Band['instruments']['feet']['settings']>;
};

function createBandMenuStateStore(band: Band) {
  const { subscribe, update } = writable<BandMenuNavState>({
    band,
    timeSignature: band.timeSignature(),
    instruments: band.instruments,
    ...band.instruments,
    guitarSettings: band.instruments.guitar.settings(),
    mandolinSettings: band.instruments.mandolin.settings(),
    bassSettings: band.instruments.bass.settings(),
    feetSettings: band.instruments.feet.settings(),
  });

  function reactiveStateUpdate(func: (state: BandMenuNavState) => void): Tracker.Computation {
    return Tracker.autorun(() => {
      update((state) => {
        func(state);
        return state;
      });
    });
  }

  const autorunComputations = [
    reactiveStateUpdate((state) => {
      state.instruments = band.reactiveInstruments();
      Object.assign(state, state.instruments);
    }),

    reactiveStateUpdate((state) => (state.guitarSettings = band.instruments.guitar.settings())),
    reactiveStateUpdate((state) => (state.mandolinSettings = band.instruments.mandolin.settings())),
    reactiveStateUpdate((state) => (state.bassSettings = band.instruments.bass.settings())),
    reactiveStateUpdate((state) => (state.feetSettings = band.instruments.feet.settings())),
    reactiveStateUpdate((state) => (state.timeSignature = band.timeSignature())),
  ];

  return {
    subscribe,

    destroy: () => {
      autorunComputations.forEach((comp) => comp.stop());
    },
  };
}
export const getBandMenuState = (): ReturnType<typeof createBandMenuStateStore> => {
  const context = getContext(contextKey);
  assert(context, 'Band menu context not found');
  return context;
};

export const inBandMenuContext = () => {
  const context = getContext(contextKey);
  return !!context;
};

export function createBandMenuContext(band: Band) {
  getContext(contextKey)?.destroy();
  setContext(contextKey, createBandMenuStateStore(band));
  return getBandMenuState();
}
