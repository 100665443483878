import { Tracker } from 'meteor/tracker';

/**
 * This class maintains a job counter, the total will
 * accumulate while increased and reset when pending hits 0
 * The api provides a `inc` and `dec` affecting the count.
 *
 * There are two reactive getters:
 * `progress` and `isDone`
 *
 */
export class PendingCounter {
  private _zeroDep = new Tracker.Dependency();
  private _valueDep = new Tracker.Dependency();
  _value = 0;
  _total = 0;

  inc(by = 1): void {
    this._value += by;
    this._total += by;
    this._valueDep.changed();
    if (this._value === by) this._zeroDep.changed();
  }

  dec(by = 1): void {
    this._value -= by;
    this._valueDep.changed();
    if (this._value === 0) {
      this._zeroDep.changed();
      // this._total = 0;
    }
  }

  isZero(): boolean {
    this._zeroDep.depend();
    return this._value === 0;
  }

  value(): number {
    this._valueDep.depend();
    return this._value;
  }

  total(): number {
    this._valueDep.depend();
    return this._total;
  }

  // progress(): number {
  //   this._valueDep.depend();
  //   if (this._total === 0) return 0;
  //   return this._value / this._total;
  // }
}
