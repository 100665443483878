import { strict as assert } from 'assert';
import type { InstrumentSetting } from '@/band/instruments/InstrumentSetting';
import type { InstrumentSettingDefinitions } from '@/band/instruments/InstrumentSettingDefinitions';

export function initializeInstrumentSettings<
  SettingsHash extends Record<string, InstrumentSetting<unknown>>,
>(settingDefinitions: InstrumentSettingDefinitions): SettingsHash {
  const keysAndObjects = Object.keys(settingDefinitions).map((key) => {
    const settingDefinition = settingDefinitions[key];
    assert(settingDefinition, 'Setting definition not found');
    return [key, new settingDefinition.class()];
  });
  return Object.fromEntries(keysAndObjects) as SettingsHash;
}
