import { bassNote, strumDown } from '@/band/instruments/guitar/GuitarActions';
import type { GuitarCore } from '@/band/instruments/guitar/GuitarCore';
import { swingOptions } from '@/band/swingOptions';

export const BoomChuckJigGuitarCore: GuitarCore = {
  id: 'bcj',
  title: 'Boom chuck',

  timeSignatures: ['6/8'],

  linkedSettingsKeys: [],
  otherSettingsKeys: [],

  chordStyle: 'cowboy',

  swingCategory: 'celtic',
  defaultSwing: swingOptions['0'],

  presets: [
    {
      name: 'Boom chuck jig',
      settings: {},
    },
  ],

  processMeasure(measure, _settings) {
    const emphasize = measure.beats[0].chordChanged || measure.cells[0].layout.column === 0;
    const noAltBass = measure.cells[1]?.beats[0]?.chordChanged;

    if (measure.endOfSong) {
      return [
        measure.onlyOneChord
          ? bassNote('root', { db: emphasize ? 4 : 2 })
          : strumDown('bass', { spread: 4, db: 0 }),
        null,
        null,
        strumDown('high', { spread: 4, db: 0 }),
      ];
    }

    return [
      bassNote('root', { db: emphasize ? 4 : 2 }),
      null,
      strumDown('high', { spread: 4, db: -1 }),
      bassNote(noAltBass ? 'root' : 'fifth', { db: 2 }),
      null,
      strumDown('high', { spread: 4, db: -1 }),
      bassNote(noAltBass ? 'root' : 'fifth', { db: 2 }),
      null,
      strumDown('high', { spread: 4, db: -1 }),
    ];
  },

  generateViz(settings) {
    const { boomPower, chuckPower } = { boomPower: 0.7, chuckPower: 0.7 };
    return [
      { dir: 'd', biasY: 0, tail: 0, thickness: boomPower },
      null,
      { dir: 'd', biasY: 1, tail: 0.5 + chuckPower * 0.2, thickness: chuckPower },
      { dir: 'd', biasY: 0, tail: 0, thickness: boomPower },
      null,
      { dir: 'd', biasY: 1, tail: 0.5 + chuckPower * 0.2, thickness: chuckPower },
    ];
  },
};
