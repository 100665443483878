import type { LinearizedBeat } from '@/chart/LinearizedBeat';
import type { LinearizedCell } from '@/chart/LinearizedCell';
import type { LinearizedMeasure } from '@/chart/LinearizedMeasure';
import type { Song } from '@/chart/Song';

export class PlayerPosition {
  readonly song: Song;
  round?: number;
  readonly measure: LinearizedMeasure;
  readonly cell: LinearizedCell;
  readonly beat: LinearizedBeat;
  readonly beatInMeasure: number;

  constructor({ song, round, beat }: { song: Song; round?: number; beat: LinearizedBeat }) {
    this.song = song;
    this.round = round;
    this.beat = beat;
    this.cell = beat.cell;
    this.measure = beat.measure;
    this.beatInMeasure = beat.beatInMeasure;
    this.position = {
      section: beat.measure.position.section,
      rep: beat.measure.position.rep,
      cell: beat.cell.layout.cellIndex,
      beat: beat.beatInCell,
    };
    if (typeof round == 'number') this.position.round = round;
  }

  position: ChartPositionWithinSong;

  toChartPosition(): ChartPosition {
    return {
      song: this.song.index(),
      round: this.round || 0,
      ...this.position,
    };
  }

  setRound(value: number): void {
    this.round = value;
    this.position.round = value;
  }
}
