import type { MandolinIntent } from '@/band/instruments/mandolin/MandolinIntent';
import type { LinearizedMeasure } from '@/chart/LinearizedMeasure';

export function overlayMeasureEffectsForMandolin(measure: LinearizedMeasure) {
  measure.beats.forEach((beat) => {
    if (!beat.cell.effect) return;

    const plan = (beat.plans.mandolin ??= {});

    plan.offbeatIntent = undefined;

    if (beat.beatInCell > 0) {
      plan.onbeatIntent = undefined;
    } else {
      if (beat.diamond) {
        plan.onbeatIntent = <MandolinIntent>{
          action: 'strum',
          direction: 'D',
          spread: 3,
          db: 0,
        };
      } else if (beat.stop) {
        plan.onbeatIntent = <MandolinIntent>{
          action: 'chop',
          spread: 3,
          db: 0,
          // duration: 'brief',
        };
      } else if (beat.rest) {
        plan.onbeatIntent = undefined;
      }
    }
  });
}
