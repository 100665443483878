import { z } from 'zod';
import type { InstrumentSetting } from '@/band/instruments/InstrumentSetting';
import cloneJSON from '@/utilities/cloneJSON';
import { rounded } from '@/utilities/rounded';

const serializedSchema = z.object({
  v: z.number().min(0).max(1).optional(),
});

type Serialization = z.infer<typeof serializedSchema>;

export class BassOnbeatWaltzSustainSetting implements InstrumentSetting<Serialization> {
  readonly sustain: number;

  constructor(input: unknown = {}) {
    const data = serializedSchema.catch({}).parse(input);
    // set properties from the serialized data here
    this.sustain = data.v ?? 0.666;
  }

  closeTo(serialized?: Serialization) {
    const other = new BassOnbeatWaltzSustainSetting(serialized);
    return Math.abs(other.sustain - this.sustain) <= 0.01;
  }

  serialize(): Serialization {
    return cloneJSON({
      v: Math.abs(this.sustain - 0.666) < 0.01 ? undefined : rounded(this.sustain, 3),
    });
  }

  withSustain(value: number) {
    return new BassOnbeatWaltzSustainSetting({
      ...this.serialize(),
      'v': value,
    });
  }
}
