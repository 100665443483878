import { strumDown, strumUp } from '@/band/instruments/guitar/GuitarActions';
import type { GuitarCore } from '@/band/instruments/guitar/GuitarCore';
import { swingOptions } from '@/band/swingOptions';

export const CelticReelGuitarCore: GuitarCore = {
  id: 'cr',
  title: 'Celtic-style strum',

  timeSignatures: ['4/4'],

  linkedSettingsKeys: [],
  otherSettingsKeys: ['timing'],

  chordStyle: 'celtic',

  swingCategory: 'celtic',
  defaultSwing: swingOptions['0'],

  presets: [
    {
      name: 'Celtic-style strum',
      settings: {},
    },
  ],

  processMeasure(measure, _settings) {
    if (measure.endOfSong) {
      return measure.cells.length === 1
        ? [strumDown('bass', { spread: Math.random() + 2, db: 2 })]
        : [
            strumDown('bass', { spread: Math.random() + 2, db: 2 }),
            strumUp('mid', { spread: Math.random() + 1, db: -8 }),
            strumDown('high', { spread: Math.random() + 3, db: 1 }),
            strumUp('high', { spread: Math.random() + 0.5, db: -9 }),
            strumDown('bass', { spread: Math.random() + 3, db: 0 }),
            null,
            null,
            null,
          ];
    }
    return [
      strumDown('bass', {
        spread: Math.random() + (measure.beats[0].chordChanged ? 2 : 1),
        db: 2,
      }),
      measure.beats[0].chordChanged && !measure.beats[2]?.chordChanged && Math.random() < 0.7
        ? null
        : strumUp('mid', { spread: Math.random() + 1, db: -8 }),
      measure.beats[0].chordChanged
        ? strumDown('high', { spread: Math.random() + 2, db: -2 })
        : strumDown('high', { spread: Math.random() + 3, db: 1 }),
      measure.beats[2]?.chordChanged
        ? strumUp('high', { spread: Math.random() + 0.5, db: -9 })
        : strumUp('high', { spread: Math.random() + 1, db: -5 }),
      measure.beats[2]?.chordChanged
        ? strumDown('bass', { spread: Math.random() + 2, db: 2 })
        : strumDown('bass', { spread: Math.random() + 1, db: -3 }),
      measure.beats[2]?.chordChanged ? null : strumUp('mid', { spread: Math.random() + 1, db: -5 }),
      measure.beats[2]?.chordChanged
        ? strumDown('high', { spread: Math.random() + 2, db: -2 })
        : strumDown('high', { spread: Math.random() + 3, db: 1 }),
      strumUp('mid', { spread: Math.random() + 1, db: -5 }),
    ];
  },

  generateViz(settings) {
    settings;
    return [
      { dir: 'd', biasY: 0.2, tail: 0.4, thickness: 0.9 },
      { dir: 'u', biasY: 0.5, tail: 0.3, thickness: 0.4, opacity: 0.7 },
      { dir: 'd', biasY: 1, tail: 0.6, thickness: 0.7 },
      { dir: 'u', biasY: 0.9, tail: 0.3, thickness: 0.6 },
      { dir: 'd', biasY: 0.2, tail: 0.4, thickness: 0.9 },
      { dir: 'u', biasY: 0.5, tail: 0.3, thickness: 0.4 },
      { dir: 'd', biasY: 1, tail: 0.6, thickness: 0.7 },
      { dir: 'u', biasY: 0.9, tail: 0.3, thickness: 0.6 },
    ];
  },
};
