import type { Cell } from '@/chart/Cell.svelte';
import { Crnt } from '@/Crnt';
import { bringCellIntoView } from '@/ui/auto-scroll/bringCellIntoView';

let lastFocusedCell: Cell | undefined;

export function autoScrollCellFocusWatcher() {
  if (!Crnt.song()?.editMode()) lastFocusedCell = undefined;
  const focusedCell = Crnt.song()?.focus.endCell();
  if (focusedCell) {
    requestAnimationFrame(() => {
      if (!focusedCell.layout.chartCell) return;
      if (lastFocusedCell == focusedCell || !focusedCell?.layout.chartCell) return;
      bringCellIntoView(focusedCell.layout.chartCell, focusedCell);
      lastFocusedCell = focusedCell;
    });
  }
}
