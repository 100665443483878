import * as Sentry from '@sentry/browser';
import { nativeWindow } from '@todesktop/client-core';
import { Meteor } from 'meteor/meteor';
import { ReactiveVar } from 'meteor/reactive-var';
import { Tracker } from 'meteor/tracker';
import { AudioSystem } from '@/audio/AudioSystem';
import AudioManager from '@/audio/engine/AudioManager';
import type { ScheduledEvent } from '@/audio/engine/clock/ScheduledEvent';
import { FMBSystem } from '@/audio/engine/fmb/FMBSystem';
import { startTickLoop } from '@/audio/engine/startTickLoop';
import { executePlayerInstructions } from '@/audio/executePlayerInstructions';
import { MissedAudioDeadlineTracker } from '@/audio/MissedAudioDeadlineTracker';
import SongRecorder from '@/audio/recording/SongRecorder';
import { autoModulateReset } from '@/auto-tools/autoModulateReset';
import { autoSpeedupReset } from '@/auto-tools/autoSpeedupReset';
import CountPlayer from '@/band/instruments/count/CountPlayer';
import type { Song } from '@/chart/Song';
import { Crnt } from '@/Crnt';
import { bpmToTpm } from '@/music/bpm-tpm-conversion';
import { PlayerDisplayState } from '@/playback/PlayerDisplayState';
import type { PlayerPosition } from '@/playback/PlayerPosition';
import { PlayerState } from '@/playback/PlayerState';
import { PlayerStateDelayedUpdater } from '@/playback/PlayerStateDelayedUpdater';
import { WebAudioTimeDilationWatcher } from '@/playback/WebAudioTimeDilationWatcher';
import { Roles } from '@/Roles';

const playerState = new PlayerState();
const delayedUpdater = new PlayerStateDelayedUpdater();

// #region === Song Loading ===

class ConductorSingleton {
  displayState = new PlayerDisplayState();
  tickScheduler?: ScheduledEvent;

  loadSong(song: Song) {
    this.reset();
    if (!Roles.currentUserCanPlaySong(song)) return;

    song.prefs.autoFinish.addListener('enabled', () => {
      this.setRound(0);
    });
    song.prefs.autoFinish.addListener('changedSettings', (settings: { reps?: number }) => {
      if (settings.reps) {
        this.setRound(Math.min(playerState.playbackPosition?.round || 0, settings.reps - 1));
      }
    });

    song.loop.addListener('set', (loop: { start?: number }) => {
      if (!loop.start) this.stop();
      if (this.stopped()) this.resetPosition();
    });

    // Setup audio; also called by index page, but that's OK
    void AudioSystem.initialize().then(() => this._audioReady.set(true));
  }

  loadMedley() {
    this.reset();
    const medley = Crnt.medley();
    if (!medley || !Roles.currentUserCanPlaySong(medley)) return;
    // Setup audio; also called by index page, but that's OK
    void AudioSystem.initialize().then(() => this._audioReady.set(true));
  }

  _beatDuration = 0.5;
  beatDuration() {
    return this._beatDuration;
  }

  recalculateBeatDuration() {
    const song = (playerState.playbackPosition || playerState.startingPosition)?.song;
    if (!song) {
      this._beatDuration = 0.5;
      return;
    }
    const medley = Crnt.medley();
    this._beatDuration =
      60 /
      (medley
        ? bpmToTpm({
            bpm: medley.bpm() * (song.medleyPrefs?.bpmX() || 1),
            timeSignature: song.timeSignature(),
            ignoreUserPreferences: true,
          })
        : song.tpm());
  }

  /**
   * Change current round; does not work if multiple songs are loaded.
   */
  setRound(value: number): void {
    if (Crnt.medley()) return;
    if (!playerState.playbackPosition && !playerState.startingPosition) return;

    const sequencerRound = (playerState.playbackPosition || playerState.startingPosition)?.round;
    const displayRound = (
      this.displayState.position() || this.displayState.startingPosition()
    )?.round();
    const discrepancy =
      typeof sequencerRound == 'number' && typeof displayRound == 'number'
        ? sequencerRound - displayRound
        : 0;

    playerState.setRound(value - discrepancy);
    this.displayState.setRound(value);
    delayedUpdater.updateRound({ from: displayRound ?? -1, to: value });
  }

  setFootOut(value: boolean): void {
    playerState.setFootOut(value);
    this.displayState.setFootOut(value);
    delayedUpdater.updateFootOut(value);
  }

  setRoundExplicitly(value: number): void {
    if (Crnt.medley()) return;

    if (!playerState.playbackPosition && !playerState.startingPosition) {
      playerState.startingPosition = Crnt.song()?.linearized.firstValidPosition();
      playerState.setRound(value);
      // this.displayState.replaceState(playerState.getInfoForDisplay());
      this.displayState.setPosition(
        (playerState.startingPosition as PlayerPosition).toChartPosition()
      );
    } else {
      this.setRound(value);
    }
  }

  private _audioReady = new ReactiveVar(false);
  ready = () =>
    (Crnt.song() || (Crnt.medley()?.songs.reactive().length || 0) > 0) && this._audioReady.get();

  playing = () => this.displayState.playing();
  paused = () => !this.displayState.playing() && !!this.displayState.position();
  stopped = () => !this.displayState.playing() && !this.displayState.position();
  delayingStart = () => this.playing() && false; // TODO: Implement delayed start

  async play({ skipCountIn = false } = {}) {
    const song = Tracker.nonreactive(() => Crnt.song() || Crnt.medley()?.songs[0]);
    if (!song) return;
    if (!Roles.currentUserCanPlaySong(song)) return; // don't play if not authorized
    if (!this.ready()) return; // don't play if not init'ed!
    if (playerState.playing) return;

    if (this.stopped()) {
      song.state.autoSpeedupState.setStartingBpm(song.bpm());
      song.state.autoModulateState.setStartingKey(song.keyHeard());
    }

    playerState.startingPosition ??= song.linearized.firstValidPosition();
    if (!skipCountIn) {
      playerState.countIn = { index: -1, label: '' };
    }
    playerState.playing = true;
    Sentry.addBreadcrumb({ category: 'audio', message: 'Conductor.play', level: 'debug' });

    if (this.tickScheduler) this.tickScheduler.clear();
    delayedUpdater.clear();

    try {
      await AudioManager.gonnaPlay();
    } catch (err) {
      if (Meteor.isDevelopment) console.error(err);
      Sentry.withScope((scope) => {
        scope.setExtra('error', err);
        Sentry.captureMessage('Failed to start AudioManager (first attempt)');
      });

      const alertUserAboutError = (err: any) => {
        Bert.alert(
          `Unable to start playback due to a problem with the audio system. We recommend that you ${
            Meteor.isCordova
              ? '<a href="/help/restart-ios" class="js-global-openHelpWindow" data-help-page="restart-ios">restart the app</a>'
              : '<a href="#" onclick="window.location.reload();">reload this page</a>'
          } to get it working again.<br><br>Error message: ${JSON.stringify(err)}`,
          'danger'
        );
      };

      const webAudioFallback = async () => {
        Sentry.captureMessage('Falling back to Web Audio API...');
        AudioManager.fallbackToWebAudio();
        try {
          await AudioSystem.restart({ pause: 0.3 });
          await AudioManager.gonnaPlay();
          if (FMBSystem.canTearDown) {
            Bert.alert(
              `We had to fall back to an older audio system due to a bug in iOS 17. If you don't hear any audio, please ensure your device is not in silent mode.`,
              'danger'
            );
          } else {
            bootbox.alert(
              `We had to fall back to an older audio system due to an Apple bug in iOS 17. The latest version of the iOS app includes a better workaround for this issue. Please <a href="https://apps.apple.com/us/app/strum-machine/id1509158553" target="_system">update the app</a> to get the best experience.`
            );
          }
          return true;
        } catch (err) {
          if (Meteor.isDevelopment) console.error(err);
          Sentry.withScope((scope) => {
            scope.setExtra('error', err);
            Sentry.captureMessage('Unable to fall back to Web Audio API');
          });
          alertUserAboutError(err);
          return false;
        }
      };

      if (/Code=(51|-50)/.test(JSON.stringify(err))) {
        if (FMBSystem.canTearDown) {
          Sentry.withScope((scope) => {
            scope.setExtra('error', err);
            Sentry.captureMessage('Restarting audio system due to iOS 17 bug...');
          });
          try {
            await AudioSystem.restart({ pause: 0.3 });
            await AudioManager.gonnaPlay();
          } catch (err) {
            if (Meteor.isDevelopment) console.error(err);
            Sentry.withScope((scope) => {
              scope.setExtra('error', err);
              Sentry.captureMessage('Failed to restart FMOD');
            });
            const webAudioFallbackSuccess = await webAudioFallback();
            if (!webAudioFallbackSuccess) {
              this.stop();
              return;
            }
          }
        } else {
          const webAudioFallbackSuccess = await webAudioFallback();
          if (!webAudioFallbackSuccess) {
            this.stop();
            return;
          }
        }
      }
    }

    if (!SongRecorder.armed()) {
      AudioManager.playSample({ id: 'count-primer', volume: 0.2 });
    }

    MissedAudioDeadlineTracker.reset();

    this.displayState.replaceState(playerState.getInfoForDisplay());
    if (playerState.startingPosition?.song) {
      CountPlayer.update(playerState.startingPosition.song);
    } else {
      Sentry.withScope((scope) => {
        scope.setExtra('playerState', JSON.stringify(playerState));
        scope.setExtra('startingPosition', typeof playerState.startingPosition);
        scope.setExtra('playbackPosition', typeof playerState.playbackPosition);
        Sentry.captureMessage('Conductor.play: playerState.startingPosition.song is undefined');
      });
    }

    this.recalculateBeatDuration();

    try {
      this.tickScheduler = await startTickLoop({
        tickCallback: scheduleNextBeat,
        interval: this.beatDuration(),
        bailOutCheck: () => !playerState.playing, // in case pause was rapidly hit
      });
      if (AudioManager.mode == 'web-audio') {
        WebAudioTimeDilationWatcher.start(AudioManager.webAudioContext);
      }
    } catch (err) {
      this.stop(); // just to reset everything
      Bert.alert(
        'Sorry, it looks like your browser is preventing playback. Try reloading the page. If you continue to see this error, please get in touch.',
        'warning'
      );
      console.error(err);
      Sentry.captureException(err);
    }
  }

  async playFrom(pos: ChartPositionWithinSong & { song?: number }, { skipCountIn = false } = {}) {
    this._stopPlaybackImmediately();
    playerState.setStartingPosition(pos);
    await this.play({ skipCountIn });
  }

  pause() {
    const counting = Tracker.nonreactive(() => this.displayState.countIn());
    const delaying = false; // Tracker.nonreactive(() => this.displayState.delaying());
    this._stopPlaybackImmediately();
    if (counting || delaying) {
      this.resetPosition();
    } else {
      this.displayState.setPlaying(false);
      this.displayState.setBeat(0);
      const displayPosition = this.displayState.position()?.toChartPosition();
      if (displayPosition) {
        playerState.setStartingPosition(displayPosition);
      }
      this.setFootOut(false);
    }
  }

  stop() {
    this._stopPlaybackImmediately();
    this.resetPosition();
    autoSpeedupReset();
    autoModulateReset();
  }

  _lastRestartTime = 0;
  goToBeginningOfSectionOrSong() {
    if (this.playing()) this._stopPlaybackImmediately();
    if (
      this._lastRestartTime < Date.now() - 2500 &&
      playerState.playbackPosition &&
      playerState.playbackPosition.position.section > 0
    ) {
      const startOfSection = {
        ...playerState.playbackPosition.toChartPosition(),
        rep: 0,
        cell: 0,
        beat: 0,
      };
      this.resetPosition();
      playerState.setStartingPosition(startOfSection);
    } else {
      this.resetPosition();
      autoSpeedupReset();
      autoModulateReset();
    }
    delayedUpdater.clear();
    void this.play();
    this._lastRestartTime = Date.now();
  }

  reset() {
    // Stop only if playing; that way, Auto-Advance preserves tails
    if (Conductor.playing()) this._stopPlaybackImmediately();
    delayedUpdater.clear();
    this.resetPosition();
    autoSpeedupReset();
    autoModulateReset();
  }

  _stopPlaybackImmediately() {
    if (this.tickScheduler) this.tickScheduler.clear();
    WebAudioTimeDilationWatcher.stop();
    void AudioManager.gonnaStop();
    void AudioManager.mixer?.stopAllPlaybacks();
    delayedUpdater.clear();
    playerState.playing = false;
  }

  _stopPlaybackAtTime(time: number) {
    if (this.tickScheduler) this.tickScheduler.clear();
    WebAudioTimeDilationWatcher.stop();
    void AudioManager.gonnaStop();
    delayedUpdater.addCallback({
      at: time,
      callback: () => {
        this.resetPosition();
        autoSpeedupReset();
        autoModulateReset();
        Crnt.list()?.doAutoAdvance();
      },
    });
  }

  private resetPosition(): void {
    Tracker.nonreactive(() => {
      Crnt.song()?.state.autoSpeedupState.setCurrentRep(0);
      Crnt.song()?.recalculateSectionsToSkip({});
      playerState.reset();
      delayedUpdater.clear();
      this.displayState.replaceState(playerState.getInfoForDisplay());
    });
  }
}

const isIOS =
  /iP(hone|ad|od)/i.test(navigator.userAgent) ||
  // eslint-disable-next-line @typescript-eslint/no-deprecated
  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

document.addEventListener('visibilitychange', function increaseBufferWhenInBackground() {
  if (document.hidden) {
    if (AudioManager.mode == 'web-audio' && !isIOS && Conductor.tickScheduler) {
      Conductor.tickScheduler.tolerance({ early: 2.5 });
    }
    if (isIOS && AudioManager.mode == 'web-audio') {
      Conductor.pause();
    }
    void AudioManager.gonnaStop(true); // if playing, this will wait until playback finishes
  } else {
    if (Conductor.tickScheduler)
      Conductor.tickScheduler.tolerance({ early: AudioManager.clock.toleranceEarly });
  }
});
document.addEventListener('resume', function resetBufferDurationOnResume() {
  if (Conductor.tickScheduler)
    Conductor.tickScheduler.tolerance({ early: AudioManager.clock.toleranceEarly });
});

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
window.todesktop &&
  void nativeWindow.on('close', () => {
    Conductor.stop();
    void AudioManager.gonnaStop(true);
  });

/**
 * Called by WAAClock ahead of every "tick".
 * It's its own function because otherwise I'm not sure
 * if "this" would be set properly.
 */
function scheduleNextBeat(event: ScheduledEvent) {
  // const secondsToSpare = event.deadline - AudioManager.currentTime();
  // Meteor.defer(() => _trackEventTolerancePerformance(secondsToSpare));

  if (
    (Crnt.song() && Crnt.song()?.sections.length === 0) ||
    (Crnt.medley() && Crnt.medley()?.songs.length === 0)
  ) {
    Conductor.stop();
    return;
  }

  playerState.advance();

  Conductor.recalculateBeatDuration();
  if (Conductor.tickScheduler && Conductor.tickScheduler.repeatTime != Conductor.beatDuration()) {
    Conductor.tickScheduler.repeatTime = Conductor.beatDuration();
  }

  if (playerState.songOver) {
    playerState.reset();
    Conductor._stopPlaybackAtTime(event.deadline);
    return;
  }

  if (Meteor.isDevelopment && event.deadline - AudioManager.currentTime() < 0) {
    console.warn(`Missed audio deadline by ${event.deadline - AudioManager.currentTime()} seconds`);
  }
  if (event.deadline - AudioManager.currentTime() < -0.05) {
    MissedAudioDeadlineTracker.handleMissedDeadline(event, event.toleranceEarly);
  } else {
    if (playerState.countIn) {
      CountPlayer.playCountAtIndex({
        countIndex: playerState.countIn.index,
        deadline: event.deadline,
        beatDuration: Conductor.beatDuration(),
      });
    } else if (playerState.playbackPosition) {
      Object.values(playerState.playbackPosition.beat.playerInstructions).forEach((instructions) =>
        executePlayerInstructions(instructions, event.deadline, Conductor.beatDuration())
      );
    }
  }

  delayedUpdater.addStateUpdate({
    at: event.deadline - 0.05, // 50ms is for transition
    state: playerState.getInfoForDisplay(),
    callback(state: PlayerStateInfo) {
      Conductor.displayState.replaceState(state);
    },
  });
}

export const Conductor = new ConductorSingleton();

if (Meteor.isDevelopment) {
  const dev: Record<string, any> = ((window as unknown as Record<string, unknown>).dev ||= {});
  dev.Conductor = Conductor;
  dev.playerState = playerState;
  dev.AudioManager = AudioManager;
}
