import { assignBassOctaves } from '@/band/instruments/bass/assignBassOctaves';
import { BasicWaltzBassCore } from '@/band/instruments/bass/BasicWaltzBassCore';
import { pluck } from '@/band/instruments/bass/BassActions';
import type { BassCore } from '@/band/instruments/bass/BassCore';
import { halfTimeBassNoteForBeat } from '@/band/instruments/bass/halfTimeBassNoteForBeat';

export const HalfTimeBassCore: BassCore = {
  id: 'ht',
  title: 'Half-time bass',

  timeSignatures: ['4/4'],
  equivilantCoreFor(timeSignature) {
    return timeSignature === '3/4' ? BasicWaltzBassCore : undefined;
  },

  linkedSettingsKeys: [],
  otherSettingsKeys: ['timing'],

  presets: [
    {
      name: 'Half-time bass',
      settings: {},
    },
  ],

  measurePreprocessing(measures, settings, _flags) {
    assignBassOctaves({ measures, settings });
  },

  processMeasure(measure, settings, { beatDuration, swing }) {
    const swungBeatDuration = (0.5 + 0.25 * swing) * beatDuration;

    const secondInMeasurePair = (measure.cells[0].layout.column ?? 0) % 4 == 2;
    const nextCellChordChange = measure.next?.beats[0]?.chordRootChanged;
    const firstBeat = measure.cells[0].beats[0];
    const lastBeat = measure.cells[1]?.beats[1];
    if (measure.endOfSong) {
      return [
        pluck(halfTimeBassNoteForBeat(firstBeat, 'root'), 1, { db: 0 }),
        null,
        null,
        null,
        pluck(halfTimeBassNoteForBeat(firstBeat, 'root'), 0, { db: 0 }),
      ];
    }
    if (!secondInMeasurePair) {
      return [
        firstBeat.effect == 'rest'
          ? null
          : pluck(halfTimeBassNoteForBeat(firstBeat, 'root'), 0, { db: 0 }),
        null,
        null,
        null,
        null,
        null,
        null,
        lastBeat && lastBeat.effect != 'rest'
          ? pluck(halfTimeBassNoteForBeat(lastBeat, nextCellChordChange ? 'ghost' : 'root'), 0, {
              db: -5,
            })
          : null,
      ];
    } else {
      return [
        firstBeat.effect == 'rest'
          ? null
          : pluck(halfTimeBassNoteForBeat(firstBeat, 'root'), swungBeatDuration, { db: 0 }),
        null,
        firstBeat.effect ? null : pluck(halfTimeBassNoteForBeat(firstBeat, 'root'), 0, { db: 0 }),
        null,
        null,
        lastBeat && lastBeat.effect != 'rest'
          ? pluck(halfTimeBassNoteForBeat(lastBeat, 'ghost'), 0, { db: -2 })
          : null,
        lastBeat && lastBeat.effect == 'rest'
          ? null
          : pluck(halfTimeBassNoteForBeat(firstBeat, 'root'), 0, { db: -1 }),
        null,
      ];
    }
  },

  generateViz(settings) {
    return [
      {
        biasY: 0.6,
        thickness: 0.8,
      },
      null,
      null,
      null,
      null,
      null,
      null,
      {
        biasY: 0.3,
        thickness: 0.5,
      },
      {
        biasY: 0.6,
        thickness: 0.8,
      },
      null,
      {
        biasY: 0.6,
        thickness: 0.8,
      },
      null,
      null,
      {
        biasY: 0.3,
        thickness: 0.5,
      },
      {
        biasY: 0.6,
        thickness: 0.8,
      },
      null,
    ];
  },
};
