import { eventTracker } from '@/browser/analytics/eventTracker';
import { MusicLibrary } from '@/library/MusicLibrary';
import closeNearestTippy from '@/ui/dropdowns/closeNearestTippy';

Template.alphabeticalIndexMenu.onRendered(() => {
  const instance = Template.instance();
  instance.searcher = Template.currentData().searcher;
  const buttons = [];
  for (var i = 65; i <= 90; i++) {
    const letter = String.fromCharCode(i);
    buttons.push(
      `<button type="button" class="btn letter" data-letter="${letter}" aria-label="${letter}">${letter}</button>`
    );
  }
  buttons.push(
    '<button type="button" class="btn numbers" aria-label="songs beginning with a number">123...</button>'
  );
  instance.$('.js-azIndex').html(buttons.join('\n'));

  Meteor.defer(() => {
    instance.autorun(() => {
      var query = instance.searcher.query() || {}; // not in "if" statement because it needs to be reactive
      instance.$('.btn').removeClass('selected');
      if (query.firstLetter) {
        instance.$(`[data-letter="${query.firstLetter}"]`).addClass('selected');
      }
      if (query.numbers) {
        instance.$('.numbers').addClass('selected');
      }
      if (query.all) {
        instance.$('.js-viewAll').addClass('selected');
      }
      if (query.allSongs) {
        instance.$('.js-viewAllSongs').addClass('selected');
      }
      if (query.allMedleys) {
        instance.$('.js-viewAllMedleys').addClass('selected');
      }
    });
  });
});

Template.alphabeticalIndexMenu.helpers({
  anyMedleys() {
    return MusicLibrary.index.medleyCount() > 0 && !this.noMedleys;
  },
});

Template.alphabeticalIndexMenu.events({
  'click .js-azIndex > .letter': (event, instance) => {
    const letter = $(event.target).data('letter');
    eventTracker.indexByLetter({ letter });
    instance.searcher.setQuery({ firstLetter: letter });
    closeNearestTippy(event.currentTarget);
    return false;
  },

  'click .js-azIndex > .numbers': (event, instance) => {
    eventTracker.indexByLetter({ letter: '#' });
    instance.searcher.setQuery({ numbers: true });
    closeNearestTippy(event.currentTarget);
    return false;
  },

  'click .js-viewAll': (event, instance) => {
    eventTracker.indexViewAll();
    instance.searcher.setQuery({ all: true });
    closeNearestTippy(event.currentTarget);
    return false;
  },

  'click .js-viewAllSongs': (event, instance) => {
    eventTracker.indexViewAll();
    instance.searcher.setQuery({ allSongs: true });
    closeNearestTippy(event.currentTarget);
    return false;
  },

  'click .js-viewAllMedleys': (event, instance) => {
    eventTracker.indexViewAllMedleys();
    instance.searcher.setQuery({ allMedleys: true });
    closeNearestTippy(event.currentTarget);
    return false;
  },
});
