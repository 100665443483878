const accentCheckers: [string, RegExp][] = [
  ['a', new RegExp('Á|À|Ã|Â|Ä|á|à|ã|â|ä', 'g')],
  ['e', new RegExp('É|È|Ê|Ë|é|è|ê|ë', 'g')],
  ['i', new RegExp('Í|Ì|Î|Ï|í|ì|î|ï', 'g')],
  ['o', new RegExp('Ó|Ò|Ô|Õ|Ö|ó|ò|ô|õ|ö', 'g')],
  ['u', new RegExp('Ú|Ù|Û|Ü|ú|ù|û|ü', 'g')],
  ['c', new RegExp('Ç|ç', 'g')],
  ['n', new RegExp('Ñ|ñ', 'g')],
];

export const stringToLowercaseAlpha = (text: string): string => {
  accentCheckers.forEach(([letter, regex]: [string, RegExp]) => {
    text = text.replace(regex, letter);
  });
  text = text.toLowerCase();
  text = text.replace(/-|  +/g, ' ');
  text = text.replace(/[^a-z0-9 ]/g, '');
  return text;
};
