import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { eventTracker } from '@/browser/analytics/eventTracker';
import { Conductor } from '@/Conductor';
import { Crnt } from '@/Crnt';
import { editModeKeyDownHandler } from '@/keyboard/editModeKeyDownHandler';
import { mainIndexSearcher } from '@/library/mainIndexSearcher';
import UserPreferences from '@/user/UserPreferences';

var attached = false;

export const musicPageKeys = {
  attach() {
    if (!attached) $(window).on('keydown', keyDownHandler);
    attached = true;
  },

  detach() {
    $(window).off('keydown', keyDownHandler);
    attached = false;
  },
};

var keyDownHandler = (event) => {
  if (Crnt.song()?.editMode()) return editModeKeyDownHandler(event);
  if (Modal.visible()) return;

  const key = event.key;

  const noModsExceptMaybeShift = !event.ctrlKey && !event.metaKey && !event.altKey;
  const noMods = !event.ctrlKey && !event.metaKey && !event.altKey && !event.shiftKey;

  if (!Crnt.song() && !Crnt.medley()) return true;

  if (
    event.target instanceof HTMLInputElement ||
    event.target instanceof HTMLSelectElement ||
    event.target instanceof HTMLTextAreaElement
  )
    return true;

  if (key == ' ' || key == 'MediaPlay') {
    const details = {
      songName: Crnt.musicTitle(),
      method: key.replace(' ', 'Spacebar'),
    };
    if (Conductor.playing()) {
      if (UserPreferences.get('footOutShortcut')) {
        if (!Conductor.displayState.footOut()) {
          Conductor.setFootOut(true);
          Bert.alert('Finishing the song at the end of this rep!', 'success');
          return false;
        } else {
          Bert.hide();
        }
      }
      Conductor.stop();
      eventTracker.playbackStop(details);
    } else {
      Conductor.play();
      eventTracker.playbackStart(details);
    }
    return false;
  }

  if (key == 'MediaStop') {
    Conductor.stop();
    eventTracker.playbackStop({ songName: Crnt.musicTitle(), method: key });
    return false;
  }

  if ((key == '+' || key == '=') && noModsExceptMaybeShift) {
    // Checking for shiftKey handles numpad OK
    (Crnt.song()?.prefs || Crnt.medley()).adjustBpm(event.shiftKey ? 5 : 1);
    eventTracker.bpmAdjust({ to: (Crnt.song()?.prefs || Crnt.medley()).bpm, usingKeyboard: true });
    return false;
  }
  if ((key == '-' || key == '_') && noModsExceptMaybeShift) {
    (Crnt.song()?.prefs || Crnt.medley()).adjustBpm(event.shiftKey ? -5 : -1);
    eventTracker.bpmAdjust({ to: (Crnt.song()?.prefs || Crnt.medley()).bpm, usingKeyboard: true });
    return false;
  }

  if (key == 'Backspace' && noModsExceptMaybeShift) {
    Conductor.goToBeginningOfSectionOrSong();
    eventTracker.playbackRestart({ songName: Crnt.musicTitle(), method: key });
    return false;
  }

  if (key == 'Escape' && noModsExceptMaybeShift && Crnt.song()?.loop.spansMultipleCells()) {
    Crnt.song()?.loop.reset();
    return false;
  }

  if (key == '/' && noModsExceptMaybeShift) {
    FlowRouter.go('song-index', {}, mainIndexSearcher.getQueryParams());
    Meteor.setTimeout(() => {
      $('.js-songSearchBoxInput').focus().trigger('input').trigger('select');
      $('.indexPage-main').animate({ scrollTop: 0 });
    }, 300);
    return false;
  }

  if (key == 'ArrowLeft' && Meteor.isCordova && event.metaKey) {
    FlowRouter.go('song-index');
    return false;
  }

  if ((key == 'Enter' || key == 'NumpadEnter' || key == 'MediaPlayPause') && noMods) {
    if (event.target instanceof HTMLAnchorElement || event.target instanceof HTMLButtonElement)
      return true;
    const command = Conductor.playing()
      ? Conductor.displayState.countIn()
        ? 'stop'
        : 'pause'
      : 'play';
    const details = { songName: Crnt.musicTitle(), method: 'Enter' };
    if (command == 'stop') eventTracker.playbackStop(details);
    else if (command == 'pause') eventTracker.playbackPause(details);
    else eventTracker.playbackStart(details);
    Conductor.playing() ? Conductor.pause() : Conductor.play();
    return false;
  }
};
