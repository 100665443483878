<script lang="ts">
  import { Meteor } from 'meteor/meteor';
  import { onDestroy, onMount } from 'svelte';
  import AudioManager from '@/audio/engine/AudioManager';
  import type { Band } from '@/band/Band';
  import BandSettingsRootScreenPanel from '@/band/ui/BandSettingsRootScreenPanel.svelte';
  import GuitarBoomChuckBalancePanel from '@/band/ui/panels/GuitarBoomChuckBalancePanel.svelte';
  import GuitarBoomChuckWaltzBalancePanel from '@/band/ui/panels/GuitarBoomChuckWaltzBalancePanel.svelte';
  import GuitarBrushinessPanel from '@/band/ui/panels/GuitarBrushinessPanel.svelte';
  import GuitarCircularStrumShapePanel from '@/band/ui/panels/GuitarCircularStrumShapePanel.svelte';
  import GuitarDampenedOnbeatsPanel from '@/band/ui/panels/GuitarDampenedOnbeatsPanel.svelte';
  import GuitarDampenedSustainPanel from '@/band/ui/panels/GuitarDampenedSustainPanel.svelte';
  import InstrumentStylePanel from '@/band/ui/panels/InstrumentStylePanel.svelte';
  import InstrumentVolumePanPanel from '@/band/ui/panels/InstrumentVolumePanPanel.svelte';
  import SwingPanel from '@/band/ui/panels/SwingPanel.svelte';
  import { createBandMenuContext } from '@/band/ui/shell/band-menu-state';
  import BandSettingsTopNavWithTitle from '@/band/ui/shell/BandSettingsTopNavWithTitle.svelte';
  import { eventTracker } from '@/browser/analytics/eventTracker';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import MultiPanelModal from '@/ui/settings/MultiPanelModal.svelte';
  import { MultiPanelModalController } from '@/ui/settings/MultiPanelModalController.svelte';

  interface Props {
    band: Band;
  }

  let { band }: Props = $props();

  let presetModeActive = $state(false);
  trackerEffect(() => (presetModeActive = !!band.presetMode()));

  // This component is the root of the menu, so it creates the context.
  const modalController = MultiPanelModalController.create({
    component: BandSettingsRootScreenPanel,
    title: 'Band Settings',
  });

  createBandMenuContext(band);

  function soloChannel(channelId: string | null | undefined) {
    const channelIterator = AudioManager.mixer?.channels.values();
    if (channelIterator) {
      const channelArray = [...channelIterator];
      channelArray.forEach((channel) => {
        channel.attentuate = channelId ? channel.id !== channelId : false;
      });
    }
  }

  let zoomedPanelTitle = $derived(
    modalController.screens[modalController.activeScreenIndex]?.title
  );

  $effect(() => {
    const zoomedInstrument = modalController.screens[1]?.props?.instId as InstrumentId | undefined;
    const zoomedPanelName = modalController.screens[2]?.component.name;
    soloChannel(
      modalController.activeScreenIndex > 1 &&
        !zoomedPanelName?.includes(InstrumentVolumePanPanel.name) &&
        !zoomedPanelName?.includes(SwingPanel.name)
        ? zoomedInstrument
        : null
    );
    if (
      modalController.activeScreenIndex > 1 &&
      zoomedInstrument &&
      [
        InstrumentStylePanel.name,
        GuitarBoomChuckBalancePanel.name,
        GuitarBoomChuckWaltzBalancePanel.name,
        GuitarCircularStrumShapePanel.name,
        GuitarBrushinessPanel.name,
        GuitarDampenedOnbeatsPanel.name,
        GuitarDampenedSustainPanel.name,
      ].some((name) => zoomedPanelName?.includes(name))
    ) {
      band.instruments[zoomedInstrument]?.toggleEmbellishments(false);
    } else {
      Object.values(band.instruments).forEach((inst) => inst.toggleEmbellishments(true));
    }
  });

  $effect(() => {
    const zoomedInstrument =
      modalController.activeScreenIndex >= 1
        ? (modalController.screens[1]?.props?.instId as InstrumentId | undefined)
        : undefined;
    const currentPanelName =
      modalController.screens[modalController.activeScreenIndex]?.component.name;
    const panel = currentPanelName?.replace(/^Proxy<(.+)>$/, '$1');
    if (panel) {
      eventTracker.bandPanelOpen({ instrument: zoomedInstrument, panel });
    }
  });

  let wasInSubview = $state(false);
  $effect(() => {
    const currentPanel = modalController.screens[modalController.screens.length - 2];
    const currentPanelName = currentPanel?.component.name;
    const panel = currentPanelName?.replace(/^Proxy<(.+)>$/, '$1');
    if (panel && modalController.subview) {
      eventTracker.bandPanelOpenSubview({ panel, subview: modalController.subview });
      wasInSubview = true;
    } else if (panel && wasInSubview) {
      eventTracker.bandPanelCloseSubview({ panel });
      wasInSubview = false;
    }
  });

  AudioManager.setHighlyResponsive(true);

  onMount(() => {
    eventTracker.bandMenuOpen();
  });

  onDestroy(() => {
    soloChannel(null);
    AudioManager.setHighlyResponsive(false);
    Object.values(band.instruments).forEach((inst) => inst.toggleEmbellishments(true));
    eventTracker.bandMenuClose();
  });
</script>

<svelte:document
  onkeydown={(event: KeyboardEvent) => {
    if (event.key == 'M' && event.ctrlKey) {
      Meteor.call('user.milestones.resetAll');
      return false;
    }
    return;
  }}
/>

<MultiPanelModal
  {modalController}
  blockEscapeToClose={presetModeActive}
  --panelMinWidth="19rem"
  --panelWidth="22rem"
>
  {#snippet topnav()}
    <BandSettingsTopNavWithTitle title={zoomedPanelTitle} {band} />
  {/snippet}
</MultiPanelModal>
