import { CanadianJigFeetCore } from '@/band/instruments/feet/cores/CanadianJigFeetCore';
import { CanadianReelFeetCore } from '@/band/instruments/feet/cores/CanadianReelFeetCore';
import { CanadianSlipJigFeetCore } from '@/band/instruments/feet/cores/CanadianSlipJigFeetCore';
import { DownbeatFeetCore } from '@/band/instruments/feet/cores/DownbeatFeetCore';
import type { FeetCore } from '@/band/instruments/feet/FeetCore';

export const feetPatternLineup: Readonly<
  Record<TimeSignature, { heading: string; description?: string; core: FeetCore }[]>
> = {
  '4/4': [
    {
      heading: DownbeatFeetCore.title,
      description: 'Basic foot taps',
      core: DownbeatFeetCore,
    },

    {
      heading: CanadianReelFeetCore.title,
      description: 'Canadian-style foot tapping',
      core: CanadianReelFeetCore,
    },
  ],

  '3/4': [
    {
      heading: DownbeatFeetCore.title,
      description: 'Basic foot taps',
      core: DownbeatFeetCore,
    },
  ],

  '6/8': [
    {
      heading: CanadianJigFeetCore.title,
      description: '',
      core: CanadianJigFeetCore,
    },
  ],

  '9/8': [
    {
      heading: CanadianSlipJigFeetCore.title,
      description: '',
      core: CanadianSlipJigFeetCore,
    },
  ],
};
