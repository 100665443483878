<script lang="ts">
  import {
    generateDampeningLines,
    generatePathForCross,
    generatePathForHead,
    generatePathForTail,
  } from '@/band/ui/components/rhythm-viz-paths';
  import { vizDimensionsFromCommand } from '@/band/ui/components/vizDimensionsFromCommand';
  import { Conductor } from '@/Conductor';
  import { Crnt } from '@/Crnt';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';

  interface Props {
    actions?: (RhythmPatternVisualizationCommand | null)[];
    timeSignature: TimeSignature;
    selected?: boolean;
    scale?: number;
    showBeatNumbers?: boolean;
    // Beat highlighting during playback
    highlightCurrentBeat?: boolean;
  }

  let {
    actions = [],
    timeSignature,
    selected = false,
    scale = 1,
    showBeatNumbers = false,
    highlightCurrentBeat = false,
  }: Props = $props();

  let subbeatsPerBeat: number = $derived(/^6|^9/.test(timeSignature) ? 3 : 2);
  const subbeatWidth: number = 18;

  let beatToHighlight: number | undefined = $state();

  let beatsPerMeasure = $derived({ '4/4': 4, '3/4': 3, '6/8': 2, '9/8': 3 }[timeSignature]);
  let measureCount = $derived(Math.ceil(actions.length / (beatsPerMeasure * subbeatsPerBeat)));
  let headerHeight = $derived(showBeatNumbers ? 12 : 0);

  let positions: {
    measure: number;
    beat: number;
    subbeat: number;
    x: number;
    xStart: number;
    index: number;
    direction: 'd' | 'u';
  }[] = $derived.by(() => {
    const positions = [];
    for (let measure = 0; measure < measureCount; measure++) {
      for (let beat = 0; beat < beatsPerMeasure; beat++) {
        for (let subbeat = 0; subbeat < subbeatsPerBeat; subbeat++) {
          const startOfZone =
            (measure * beatsPerMeasure * subbeatsPerBeat + beat * subbeatsPerBeat + subbeat) *
            subbeatWidth;
          const direction: 'd' | 'u' = subbeat % 2 == 0 ? 'd' : 'u';
          const adjustment = 1.5 * ((subbeatsPerBeat - 1 - subbeat * 2) / subbeatsPerBeat);
          positions.push({
            measure,
            beat,
            subbeat,
            xStart: startOfZone,
            x: startOfZone + subbeatWidth / 2 + adjustment,
            index: positions.length,
            direction,
          });
        }
      }
    }
    return positions;
  });

  trackerEffect(() => {
    beatToHighlight = undefined;
    const pos = Conductor.displayState.position();
    if (pos && Conductor.playing() && highlightCurrentBeat) {
      const song = Crnt.songs[pos.song()];
      const cell = song?.sections[pos.section()]?.cells[pos.cell()];
      if (cell) {
        beatToHighlight =
          timeSignature == '3/4'
            ? (positions.length > 6 ? 3 * cell.layout.barPos : 0) + pos.beat()
            : timeSignature == '6/8' || timeSignature == '9/8'
              ? cell.layout.barPos
              : 2 * cell.layout.barPos + pos.beat();
        if (timeSignature == '4/4' && (positions[positions.length - 1]?.measure ?? 0) > 0) {
          const secondInMeasurePair = (cell.layout.column ?? 0) % 4 >= 2;
          beatToHighlight += secondInMeasurePair ? beatsPerMeasure : 0;
        }
      }
    }
  });

  let containerWidth = $derived(subbeatWidth * positions.length);
  let containerHeight = $derived(42);
  let topYPadded = $derived(4);
  let bottomYPadded = $derived(containerHeight - 4);
  let paddedHeight = $derived(bottomYPadded - topYPadded);
</script>

<svg
  viewBox="0 {-headerHeight} {containerWidth} {headerHeight + containerHeight}"
  version="1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  xml:space="preserve"
  class="pattern"
  class:selected
  style="width: {containerWidth * scale}px;
    max-height: {(containerHeight + headerHeight) * scale}px;"
>
  {#each positions as pos (pos.x)}
    {#if pos.subbeat == 0}
      {#if pos.beat === 0 && pos.measure > 0}
        <line x1={pos.xStart} y1="0" x2={pos.xStart} y2={containerHeight} class="measure-line" />
      {/if}
      <rect
        x={pos.xStart}
        y={-headerHeight}
        width={subbeatWidth * subbeatsPerBeat}
        height={headerHeight + containerHeight}
        class="beat-bg {beatToHighlight === beatsPerMeasure * pos.measure + pos.beat
          ? 'current-beat'
          : pos.beat % 2 == 0
            ? 'even'
            : 'odd'}"
      />
      {#if showBeatNumbers}
        <text
          x={pos.xStart + subbeatWidth / 2}
          y={-headerHeight / 2.75}
          text-anchor="middle"
          dominant-baseline="middle"
          font-size="9"
          fill="var(--muted-400)"
        >
          {pos.beat + 1}
        </text>
      {/if}
    {/if}
  {/each}

  {#each positions as pos (pos.x)}
    {@const params = actions[pos.index]}

    {#if params}
      {@const dimensions = vizDimensionsFromCommand(params, { topYPadded, paddedHeight })}
      <g style="opacity: {params.opacity ?? 1}; transition: opacity 0.2s ease">
        {#if params.dir}
          <path
            fill="url(#grad_tail)"
            stroke="url(#grad_tail)"
            stroke-width="1px"
            style="opacity: {params.tailOpacity ?? 1};"
            d={`M${pos.x},${dimensions.tipY - dimensions.headY}` +
              ((params.sustain ?? 1) < 1
                ? generateDampeningLines({
                    direction: params.dir,
                    stemWidth: dimensions.stemWidth,
                    height: dimensions.tailHeight,
                    chick: params.chick,
                  })
                : generatePathForTail({
                    direction: params.dir,
                    stemWidth: dimensions.stemWidth,
                    height: dimensions.tailHeight,
                  }))}
          />

          <path
            fill={(params.sustain ?? 1) < 1 && !params.chick ? 'transparent' : 'url(#grad_head)'}
            stroke="url(#grad_head)"
            stroke-width="1px"
            d={`M${pos.x},${dimensions.tipY}` +
              (params.chick
                ? generatePathForCross({
                    size: dimensions.headWidth * 0.9,
                  })
                : generatePathForHead({
                    direction: params.dir,
                    headWidth: dimensions.headWidth,
                  }))}
          />

          {#if (params.sustain ?? 1) > 0 && !params.chick}
            <path
              fill="url(#grad_tail)"
              stroke="url(#grad_tail)"
              stroke-width="1px"
              style="opacity: {(params.tailOpacity ?? 1) * (params.sustain ?? 1)};"
              d={`M${pos.x},${dimensions.tipY - dimensions.headY}` +
                generatePathForTail({
                  direction: params.dir,
                  stemWidth: dimensions.stemWidth,
                  height: dimensions.tailHeight,
                })}
            />
            <path
              fill="url(#grad_head)"
              stroke="url(#grad_head)"
              stroke-width="1px"
              opacity={params.sustain}
              d={`M${pos.x},${dimensions.tipY}` +
                generatePathForHead({
                  direction: params.dir,
                  headWidth: dimensions.headWidth,
                })}
            />
          {/if}
        {:else}
          <circle
            fill={(params.sustain ?? 1) < 1 && !params.chick ? 'white' : 'url(#grad_circle)'}
            cx={pos.x}
            cy={topYPadded + 5 + (paddedHeight - 10) * params.biasY}
            r={2 + params.thickness * 4}
          />
        {/if}
      </g>
    {/if}
  {/each}
</svg>

<style>
  svg {
    display: block;
    max-width: 100%;
  }

  svg.pattern {
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 1.5;
    background-color: hsl(206, 5%, 98%);
    border-radius: 5px;
    transition: background-color 0.1s ease;
  }

  .measure-line {
    stroke: var(--gray-300);
    stroke-width: 1px;
    opacity: 0.85;
    z-index: 1;
  }

  /* svg.selected {
    background-color: hsl(206, 50%, 90%);
    .measure-line {
      stroke: var(--muted-400);
    }
  } */

  .beat-bg {
    fill: hsla(206, 0%, 100%, 0.25);
    transition: all 60ms linear;

    &.odd {
      fill: hsla(206, 50%, 75%, 0.2);
    }
    &.current-beat {
      fill: hsla(60, 100%, 80%, 1);
    }
  }
</style>
