<script lang="ts">
  import { Crnt } from '@/Crnt';
  import type { DevKnob } from '@/lib/dev-knobs';
  import { allDevKnobs } from '@/lib/dev-knobs';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';

  let knobs: DevKnob[] = $state([]);
  trackerEffect(() => {
    knobs = allDevKnobs.get();
    // knobs.forEach((knob) => knob()); // to make reactive in Svelte
  });

  const rangeChangeHandler = (e: Event & { currentTarget: HTMLInputElement }, devKnob: DevKnob) => {
    devKnob(+e.currentTarget.value);
    Crnt.song()?.band.regeneratePlans();
  };

  let tucked = $state(false);
</script>

<div
  class="fixed bottom-[56px] right-0 z-[1000] justify-end rounded-l-md border-2 border-r-0 border-gray-800 border-opacity-10 bg-gray-400 bg-transparent bg-opacity-10 p-3 backdrop-blur transition-transform duration-500"
  class:translate-x-full={tucked}
  hidden={!knobs.length}
>
  <!-- svelte-ignore a11y_consider_explicit_label -->
  <button
    onclick={() => (tucked = !tucked)}
    class="absolute right-full top-1 mr-[2px] flex h-10 w-10 items-center justify-center rounded-l-md bg-gray-300 bg-opacity-50 text-gray-600"
  >
    <i class="smi smi-chevron-left scale-125" class:rotate-180={!tucked} aria-hidden="true"></i>
  </button>

  {#each knobs as devKnob (devKnob.id)}
    <div class="mb-1 flex justify-end">
      <div class="pr-2 text-muted-500">
        {devKnob.id}
      </div>
      <input
        type="range"
        class="mx-2 w-32"
        min={devKnob.min}
        max={devKnob.max}
        step={devKnob.step}
        value={devKnob()}
        oninput={(e) => rangeChangeHandler(e, devKnob)}
        onchange={(e) => rangeChangeHandler(e, devKnob)}
      />
      <input
        type="number"
        class="w-20 rounded border border-gray-200 px-1 py-0 text-right"
        min={devKnob.min}
        max={devKnob.max}
        step={devKnob.step}
        value={devKnob()}
        oninput={(e) => rangeChangeHandler(e, devKnob)}
        onchange={(e) => rangeChangeHandler(e, devKnob)}
      />
    </div>
  {/each}
</div>
