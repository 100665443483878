import { MasterPitch } from '@/audio/mixer/MasterPitch';
import SongRecorder from '@/audio/recording/SongRecorder';
import { upcomingKeysForAutoModulate } from '@/auto-tools/upcomingKeysForAutoModulate';
import { Conductor } from '@/Conductor';
import { Crnt } from '@/Crnt';
import { convertNumberToFractionString } from '@/utilities/convertNumberToFractionString';

Template.controlBarStatusText.onRendered(() => {
  const instance = Template.instance();
  setTimeout(() => {
    if (instance.view.isDestroyed) return;
    instance.find('.controlBarStatusText')._uihooks = {
      insertElement(node, next) {
        $(node)
          .insertBefore(next)
          .hide()
          .velocity(
            {
              opacity: [1, 0],
              marginTop: [0, '-1.5em'],
              translateY: [0, '1em'],
            },
            {
              display: 'flex',
              duration: 300,
              easing: [0.5, 0, 0.5, 1],
            }
          );
      },
      removeElement(node) {
        $(node).velocity(
          {
            opacity: [0, 1],
            marginTop: ['-1.5em', 0],
            translateY: ['1em', 0],
          },
          {
            display: 'none',
            duration: 300,
            easing: [0.5, 0, 0.5, 1],
            complete: () => $(node).remove(),
          }
        );
      },
    };
  }, 200);
});

function autoModulateStatus() {
  const song = Crnt.song();
  if (!song?.prefs.autoModulate.enabled()) return;
  let status;
  status = 'Auto-Modulate: ';
  // status += '<div class="autoModulate-currentKey">';
  // status += `<span class="chord">${new Chord(song.keyHeard())}</span>`;
  // status += '</div>';
  if (song.prefs.autoModulate.reps() > 1) {
    status += '<div class="autoRepProgress autoRepProgress--autoModulate">';
    for (let i = 0; i < song.prefs.autoModulate.reps(); i++) {
      status += `<span class="${
        !Conductor.stopped() && i <= song.state.autoModulateState.currentRep() ? 'filled' : ''
      }"></span>`;
    }
    status += '</div>';
  } else status += '→';
  status += '<div class="autoModulate-keyLineup">';
  const lineup = upcomingKeysForAutoModulate({ song });
  if (lineup.length > 1) {
    status += lineup.map((c) => `<span class="chord">${c.toHtml()}</span>`).join(' ');
  } else {
    status += '<span class="no-sequence">No sequence entered</span>';
  }
  status += '</div>';
  return status;
}

function autoSpeedupStatus() {
  const song = Crnt.song();
  if (!song?.prefs.autoSpeedup.enabled()) return;
  let status;
  status = 'Auto-Speedup: ';
  status += `${song.prefs.autoSpeedup.bpmDelta() > 0 ? '+' : ''}${convertNumberToFractionString(
    song.prefs.autoSpeedup.bpmDelta()
  ).replace('-', '–')} BPM `;
  status += window.matchMedia('(max-width: 380px)').matches ? '/ ' : 'every ';
  status += song.prefs.autoSpeedup.reps() > 1 ? `${song.prefs.autoSpeedup.reps()} reps` : 'rep';
  const maxBpm = song.prefs.autoSpeedup.maxBpm();
  if (song.prefs.autoSpeedup.reps() > 1) {
    status += '<div class="autoRepProgress autoRepProgress--autoSpeedup">';
    for (let i = 0; i < song.prefs.autoSpeedup.reps(); i++) {
      status += `<span class="${
        !Conductor.stopped() && i <= song.state.autoSpeedupState.currentRep() ? 'filled' : ''
      }"></span>`;
    }
    status += '</div>';
  } else if (maxBpm) status += ', ';
  if (maxBpm) {
    status += `up to ${convertNumberToFractionString(maxBpm)}`;
    status +=
      song.prefs.autoSpeedup.reps() > 1 && window.matchMedia('(max-width: 400px)').matches
        ? ''
        : ' BPM';
  }
  return status;
}

function autoFinishStatus() {
  const song = Crnt.song();
  if (!song?.prefs.autoFinish.enabled() && (!song || !Crnt.list()?.autoAdvanceEnabled())) return;
  const afReps = song.prefs.autoFinish.reps();
  const afCurrentRep = (
    Conductor.displayState.position() || Conductor.displayState.startingPosition()
  )?.round();
  let status = 'Auto-Finish: ';
  let textStatus;
  if (afReps == 1) {
    textStatus = ' play once';
  } else {
    if (Conductor.stopped() || typeof afCurrentRep != 'number') {
      textStatus = ` play ${afReps} times`;
    } else {
      textStatus = ` playing rep #${afCurrentRep + 1} of ${afReps}`;
    }
  }
  if (afReps > 1 && afReps <= (window.matchMedia('(min-width: 375px)').matches ? 16 : 12)) {
    status += `<span class="sr-only">${textStatus}</span>`;
    status += `<div class="autoRepProgress autoRepProgress--autoFinish ${
      afReps < 10 ? 'few' : ''
    }" aria-hidden="true">`;
    for (let i = 0; i < afReps; i++) {
      let spanClass = '';
      if (!Conductor.stopped() && typeof afCurrentRep == 'number' && i <= afCurrentRep) {
        spanClass = i == afCurrentRep ? 'current' : 'past';
      }
      status += `<span class="js-setAFRep ${spanClass}" data-rep="${i}">${i + 1}</span>`;
    }
    status += '</div>';
  } else {
    status += textStatus;
  }
  return status;
}

function recorderStatus() {
  if (!SongRecorder.armed()) return;
  let status;
  if (Conductor.stopped()) {
    status = 'Will record output to MP3 file.';
  } else {
    let recSecs = Math.ceil(SongRecorder.recordingTime() % 60);
    let recMins = Math.floor(SongRecorder.recordingTime() / 60);
    if (recMins < 10) recMins = `0${recMins}`;
    if (recSecs < 10) recSecs = `0${recSecs}`;
    status = `Recording (${recMins}:${recSecs})`;
  }
  return status;
}

function pitchShiftStatus() {
  if (!MasterPitch.shifted()) return;
  return `Pitch shifted ${Math.abs(Math.round(MasterPitch.getCents()))} cents ${
    MasterPitch.get() < 440 ? 'down' : 'up'
  } (A=${MasterPitch.get()}Hz)`;
}

function footOutStatus() {
  if (Conductor.displayState.footOut()) {
    return '<b>Foot out!</b>&nbsp; Stopping at the end of this rep.';
  }
}

Template.controlBarStatusText.helpers({
  anyStatus() {
    const song = Crnt.song();
    return (
      (song &&
        (song.prefs.autoSpeedup.enabled() ||
          song.prefs.autoFinish.enabled() ||
          Crnt.list()?.autoAdvanceEnabled() ||
          song.prefs.autoModulate.enabled() ||
          Conductor.displayState.footOut())) ||
      SongRecorder.armed()
    );
  },

  autoModulateStatus() {
    return autoModulateStatus();
  },

  autoSpeedupStatus() {
    return autoSpeedupStatus();
  },

  autoFinishStatus() {
    return autoFinishStatus();
  },

  recorderStatus() {
    return recorderStatus();
  },

  pitchShiftStatus() {
    return pitchShiftStatus();
  },

  footOutStatus() {
    return footOutStatus();
  },
});

Template.controlBarStatusText.events({
  'click .js-setAFRep': function (event, _instance) {
    event.preventDefault();
    const round = +event.currentTarget.dataset.rep;
    Conductor.setRoundExplicitly(round);
  },
});
