import Dexie from 'dexie';
import { ReactiveVar } from 'meteor/reactive-var';

type StoredDevKnob = {
  id: string;
  value: number;
};

class DevKnobDatabase extends Dexie {
  devKnobs: Dexie.Table<StoredDevKnob, string>;

  constructor() {
    super('DevKnobDatabase');
    this.version(1).stores({
      devKnobs: '&id',
    });
    this.devKnobs = this.table('devKnobs');
  }
}

const db = new DevKnobDatabase();

export const allDevKnobs = new ReactiveVar<DevKnob[]>([]);

export type DevKnob = {
  (newValue?: number): number;
  id: string;
  name: string;
  min: number;
  max: number;
  step: number;
};

export function createDevKnob(
  id: string,
  initialValue: number,
  min: number,
  max: number,
  step: number
): DevKnob {
  const value = new ReactiveVar(initialValue);

  void db.devKnobs.get(id).then((savedValue: StoredDevKnob | undefined) => {
    value.set(savedValue?.value ?? initialValue);
  });

  const devKnob = function (newValue?: number) {
    if (newValue !== undefined) {
      value.set(newValue);
      void db.devKnobs.put({ id, value: newValue });
    }
    return value.get();
  } as DevKnob;

  Object.defineProperties(devKnob, {
    id: { value: id, writable: false },
    min: { value: min, writable: false },
    max: { value: max, writable: false },
    step: { value: step, writable: false },
  });

  allDevKnobs.set([...allDevKnobs.get(), devKnob]);
  return devKnob;
}
