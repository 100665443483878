import type {
  MandolinIntentChop,
  MandolinIntentDownstroke,
  MandolinIntentUpstroke,
} from '@/band/instruments/mandolin/MandolinIntent';

export function chop(options: {
  db?: number;
  attack?: MandolinIntentChop['attack'];
  chordDuration?: number;
  chordSpread: number;
  chordAttenuation?: number;
}) {
  return <MandolinIntentChop>{ action: 'chop', ...options };
}

export function downstroke(options: {
  db?: number;
  velocity?: MandolinIntentDownstroke['velocity'];
  chordDuration?: number;
  chordBeatDuration?: number;
  chordSpread: number;
  chordAttenuation?: number;
}) {
  return <MandolinIntentDownstroke>{ action: 'downstroke', ...options };
}

export function upstroke(options: {
  db?: number;
  velocity?: MandolinIntentUpstroke['velocity'];
  bias?: MandolinIntentUpstroke['bias'];
  chordDuration?: number;
  chordBeatDuration?: number;
  chordSpread: number;
  chordAttenuation?: number;
}) {
  return <MandolinIntentUpstroke>{ action: 'upstroke', ...options };
}
