<script lang="ts">
  import type { Section } from '@/chart/Section.svelte';
  import RepMarkers from '@/chart/ui/RepMarkers.svelte';
  import TippyDropdown from '@/ui/TippyDropdown.svelte';

  interface Props {
    section: Section;
    sectionIndex: number;
  }

  let { section, sectionIndex }: Props = $props();
</script>

<TippyDropdown placement="bottom-start">
  {#snippet button()}
    <button class="colors-button flex items-center rounded-md px-1.5 py-1">
      <span class="mr-1 text-[13px] text-gray-500">Reps:</span>
      <RepMarkers {section} {sectionIndex} />
    </button>
  {/snippet}
  {#snippet menu({ tippyInstance })}
    <div class="w-40 p-2">
      <div class="mb-1 mt-1 text-center text-sm text-gray-500">Play this section:</div>
      <div class="flex flex-wrap items-center justify-around">
        {#each [1, 2, 3, 4, 5, 6, 7, 8] as rep (rep)}
          <button
            class="colors-button rounded p-1.5 font-medium {rep < 3 ? 'basis-1/2' : 'basis-1/3'}"
            onclick={() => {
              section.setReps(rep);
              tippyInstance.hide();
            }}
          >
            {rep == 1 ? 'once' : rep == 2 ? 'twice' : `${rep}×`}
          </button>
        {/each}
      </div>
    </div>
  {/snippet}
</TippyDropdown>
