<script lang="ts">
  import type { Snippet } from 'svelte';

  interface Props {
    title: string;
    align?: 'left' | 'center';
    headingLevel?: 1 | 2 | 3 | 4 | 5 | 6;
    padX?: number | undefined;
    text?: Snippet;
  }

  let { title, align = 'left', headingLevel = 1, padX = undefined, text }: Props = $props();
</script>

<div
  class="px-5 py-4 sm-band:px-4"
  style={padX ? `padding-left: ${padX / 4}rem;` : ''}
  class:text-center={align == 'center'}
>
  {#if title}
    <svelte:element
      this={`h${headingLevel}`}
      class="pb-1.5 pt-0.5 text-xl font-medium leading-6 text-gray-800"
    >
      {title}
    </svelte:element>
  {/if}
  <div class="pb-0.5 text-sm text-gray-600">
    {@render text?.()}
  </div>
</div>
