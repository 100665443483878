import { upcomingKeysForAutoModulate } from '@/auto-tools/upcomingKeysForAutoModulate';
import type { Song } from '@/chart/Song';

export function autoModulateNewRep(song: Song): void {
  if (song.prefs.autoModulate.enabled()) {
    song.state.autoModulateState.incrementCurrentRep();
    if (song.state.autoModulateState.currentRep() >= song.prefs.autoModulate.reps()) {
      const lineup = upcomingKeysForAutoModulate({ song });
      if (lineup.length > 0) {
        song.setKey(lineup[0]!);
        song.state.autoModulateState.setPosInSeq(
          (song.state.autoModulateState.posInSeq() + 1) % lineup.length
        );
        song.state.autoModulateState.setCurrentRep(0);
        // song.band.regeneratePlans(); // This needs to happen immediately, in this event loop
      }
    }
  }
}
