import { BassOnbeatNotesSetting } from '@/band/instruments/bass/settings/BassOnbeatNotesSetting';
import { BassOnbeatSustainSetting } from '@/band/instruments/bass/settings/BassOnbeatSustainSetting';
import { BassOnbeatWaltzSustainSetting } from '@/band/instruments/bass/settings/BassOnbeatWaltzSustainSetting';
import { BassTimingSetting } from '@/band/instruments/bass/settings/BassTimingSetting';
import type { InstrumentSettingDefinitions } from '@/band/instruments/InstrumentSettingDefinitions';

export const bassSettingDefinitions = {
  onbeatNotes: {
    class: BassOnbeatNotesSetting,
    serializationKey: 'b_on',
  },
  onbeatSustain: {
    class: BassOnbeatSustainSetting,
    serializationKey: 'b_os',
  },
  onbeatWaltzSustain: {
    class: BassOnbeatWaltzSustainSetting,
    serializationKey: 'b_osw',
  },
  timing: {
    class: BassTimingSetting,
    serializationKey: 'b_bt',
  },
} as const satisfies InstrumentSettingDefinitions;
