import { z } from 'zod';
import type { InstrumentSetting } from '@/band/instruments/InstrumentSetting';
import cloneJSON from '@/utilities/cloneJSON';
import { rounded } from '@/utilities/rounded';

const serializedSchema = z.object({
  v: z.number().min(0).max(1).optional(),
});

type Serialization = z.infer<typeof serializedSchema>;

const presets = [
  { value: 0 / 6, caption: 'None' },
  { value: 1 / 6, caption: 'Very light' },
  { value: 2 / 6, caption: 'Light' },
  { value: 3 / 6, caption: 'Medium' },
  { value: 4 / 6, caption: 'Heavy' },
  { value: 5 / 6, caption: 'Very heavy' },
  { value: 6 / 6, caption: '100% (no onbeat at all)' },
];

export class GuitarDampenedBackbeatSetting implements InstrumentSetting<Serialization> {
  readonly value: number;
  readonly onbeatDbReduction: number;
  readonly offbeatDbBoost: number;

  constructor(input: unknown = {}) {
    const data = serializedSchema.catch({}).parse(input);
    this.value = data.v ?? 0.333;
    this.onbeatDbReduction = data.v == 1 ? Infinity : this.value * 15;
    this.offbeatDbBoost = this.value * 4;
  }

  closeTo(serialized?: Serialization) {
    const other = new GuitarDampenedBackbeatSetting(serialized);
    return Math.abs(other.value - this.value) <= 0.05;
  }

  serialize(): Serialization {
    return cloneJSON({
      v: rounded(this.value, 2),
    });
  }

  withValue(value: number) {
    return new GuitarDampenedBackbeatSetting({
      ...this.serialize(),
      'v': value,
    });
  }

  get closestPreset() {
    return presets.reduce((closest, preset) =>
      Math.abs(preset.value - this.value) < Math.abs(closest.value - this.value) ? preset : closest
    );
  }

  static readonly presets = presets;
}
