import { differenceInDays } from 'date-fns';
import { FlowRouter } from 'meteor/ostrio:flow-router-extra';

Template.cancelledPage.helpers({
  daysLeft() {
    const date = Meteor.user('subscription')?.subscription?.currentPeriodEnd;
    if (!date) return '__ days';
    const days = differenceInDays(date, Date.now());
    return days == 1 ? 'day' : `${days} days`;
  },

  waitingToExpire() {
    return FlowRouter.getQueryParam('finishTerm');
  },

  refund() {
    return FlowRouter.getQueryParam('refund');
  },

  donated() {
    return FlowRouter.getQueryParam('donated');
  },

  name() {
    const user = Meteor.user();
    return `${user?.profile.firstName} ${user?.profile.lastName}`.trim();
  },

  email() {
    const user = Meteor.user();
    return user?.emails[0]?.address;
  },
});
