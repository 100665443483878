<script lang="ts">
  import type { BandPreset } from '@/band/presets/BandPreset';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';
  import { eventTracker } from '@/browser/analytics/eventTracker';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import Icon from '@/ui/icons/Icon.svelte';
  import RadiolistRadio from '@/ui/icons/RadiolistRadio.svelte';

  const bandMenu = getBandMenuState();

  interface Props {
    authorPreset: BandPreset;
  }

  let { authorPreset }: Props = $props();

  let currentPreset: BandPreset | undefined = $state();
  trackerEffect(() => (currentPreset = $bandMenu.band.preset()));

  let checked = $derived(currentPreset?.settingsFromAuthor);

  function setAuthorPreset() {
    $bandMenu.band.setPreset(authorPreset);
    eventTracker.bandPresetSet({
      presetId: authorPreset.id,
      name: authorPreset.name,
      isDefault: true,
    });
  }
</script>

<button
  class="btn-base relative flex w-full items-center gap-3 whitespace-normal rounded-[7px] py-3 pl-3 pr-4 text-left font-normal leading-5 transition-colors dsktp:hover:bg-primary-50"
  aria-checked={checked}
  role="radio"
  onclick={() => setAuthorPreset()}
>
  <Icon id="preset-shared-single" size={24} />
  <div class="flex-auto">{authorPreset.name}</div>
  <RadiolistRadio {checked} size={20} />
</button>
