import type { ScheduledEventCallback } from '@/audio/engine/clock/ScheduledEvent';
import { ScheduledEvent } from '@/audio/engine/clock/ScheduledEvent';

/**
 * Standalone class that uses a Web Worker to check the time of an external clock source
 * (such as an AudioContext clock) and execute scheduled events in advance of their deadline.
 */
export class SchedulingClock {
  _started = false;
  _events: ScheduledEvent[] = [];
  _worker?: Worker;
  timeFunction: () => number;
  toleranceEarly: number;
  toleranceLate: number;
  tickInterval: number;

  constructor(opts: {
    timeFunction: () => number;
    toleranceEarly: number;
    toleranceLate: number;
    tickInterval: number;
  }) {
    this.timeFunction = opts.timeFunction;
    this.toleranceEarly = opts.toleranceEarly;
    this.toleranceLate = opts.toleranceLate;
    this.tickInterval = opts.tickInterval;
  }

  /**
   * Removes all scheduled events and starts the clock
   */
  start(): void {
    if (this._started) return;
    this._started = true;
    this._events = [];

    const workerBlob = new Blob(
      [`setInterval(function() { postMessage('tick'); }, ${this.tickInterval * 1000});`], // no ES6+ here!
      { type: 'text/javascript' }
    );
    const workerBlobUrl = URL.createObjectURL(workerBlob);
    this._worker = new Worker(workerBlobUrl);
    this._worker.onmessage = this._tick.bind(this);
  }

  /**
   * Stops the clock
   */
  stop(): void {
    if (!this._started) return;
    this._started = false;
    this._events = [];
    if (this._worker) this._worker.terminate();
  }

  /**
   * Schedules `func` to run after `delay` seconds
   */
  setTimeout(func: ScheduledEventCallback, delay: number): ScheduledEvent {
    const deadline = this.timeFunction() + delay;
    return new ScheduledEvent(this, deadline, func);
  }

  /**
   * Schedules `func` to run before `deadline`.
   */
  callbackAtTime(func: ScheduledEventCallback, deadline: number): ScheduledEvent {
    return new ScheduledEvent(this, deadline, func);
  }

  /**
   * Stretches `deadline` and `repeat` of all scheduled `events` by `ratio`, keeping
   * their relative distance to `tRef`. In fact this is equivalent to changing the tempo.
   */
  timeStretch(tRef: number, events: ScheduledEvent[], ratio: number): ScheduledEvent[] {
    events.forEach((event) => event.timeStretch(tRef, ratio));
    return events;
  }

  // ---------- Private ---------- //

  // This function is ran periodically, and at each tick it executes
  // events for which `currentTime` is included in their tolerance interval.
  private _tick(): void {
    if (this._events.length === 0) return; // to prevent calling timeFunction if not ready yet
    let event = this._events.shift();
    const currentTime = this.timeFunction();

    while (event && (!event._earliestTime || event._earliestTime <= currentTime)) {
      event._execute();
      event = this._events.shift();
    }

    // Put back the last event
    if (event) this._events.unshift(event);
  }

  // Inserts an event to the list
  _insertEvent(event: ScheduledEvent): void {
    if (typeof event._earliestTime != 'number')
      throw new Error(`_earliestTime is ${typeof event._earliestTime}`);
    this._events.splice(this._indexByTime(event._earliestTime), 0, event);
  }

  // Removes an event from the list
  _removeEvent(event: ScheduledEvent): void {
    const ind = this._events.indexOf(event);
    if (ind !== -1) this._events.splice(ind, 1);
  }

  // Update event only if already in list
  _updateEvent(event: ScheduledEvent): void {
    if (typeof event._earliestTime != 'number')
      throw new Error(`_earliestTime is ${typeof event._earliestTime}`);
    const ind = this._events.indexOf(event);
    if (ind !== -1) {
      this._events.splice(ind, 1);
      this._events.splice(this._indexByTime(event._earliestTime), 0, event);
    }
  }

  // Insert or update event
  _upsertEvent(event: ScheduledEvent): void {
    this._removeEvent(event); // may or may not be anything to remove
    this._insertEvent(event);
  }

  // Returns true if `event` is in queue, false otherwise
  _hasEvent(event: ScheduledEvent): boolean {
    return this._events.includes(event);
  }

  // Returns the index of the first event whose deadline is >= to `deadline`
  private _indexByTime(deadline: number): number {
    // performs a binary search
    let low = 0,
      high = this._events.length,
      mid;
    while (low < high) {
      mid = Math.floor((low + high) / 2);
      //@ts-expect-error It doesn't know that this index must exist
      if (this._events[mid]._earliestTime < deadline) low = mid + 1;
      else high = mid;
    }
    return low;
  }
}
