import { CellBeat } from '@/chart/CellBeat.svelte';
import type { CellLayout } from '@/chart/CellLayout';
import type { Section } from '@/chart/Section.svelte';
import { Chord } from '@/music/Chord';
import cloneJSON from '@/utilities/cloneJSON';
import { quickRandomId } from '@/utilities/quickRandomId';

export class Cell {
  readonly _id: string;

  constructor(data: SerializedCell) {
    this._id = data._id || quickRandomId();
    this.#firstBeat = new CellBeat({ chord: new Chord(data.chord), effect: data.effect });
    this.half = !!data.half;
    this.volta = data.volta;
    this.timeSig = data.timeSig;
    if (data.split) {
      this.subdividedBeats = [
        this.#firstBeat,
        new CellBeat(data.beat2 ?? {}),
        new CellBeat(data.beat3 ?? {}),
      ];
    }
  }

  //#region === Serialization ===

  serialize({ preserveIds }: { preserveIds?: boolean }): SerializedCell {
    const data: SerializedCell = cloneJSON({
      _id: preserveIds && this._id ? this._id : undefined,
      chord: this.chord.toString(),
      effect: this.effect,
      volta: this.volta?.length ? this.volta : undefined,
      timeSig: this.timeSig,
      half: this.half || undefined,
      split: this.split || undefined,
      beat2:
        this.subdividedBeats?.[1]?.chord || this.subdividedBeats?.[1]?.effect
          ? cloneJSON({
              chord: this.subdividedBeats[1].chord?.toString(),
              effect: this.subdividedBeats[1].effect,
            })
          : undefined,
      beat3:
        this.subdividedBeats?.[2]?.chord || this.subdividedBeats?.[2]?.effect
          ? cloneJSON({
              chord: this.subdividedBeats[2].chord?.toString(),
              effect: this.subdividedBeats[2].effect,
            })
          : undefined,
    });
    return data;
  }

  //#endregion ^ Serialization

  //#region === Beats ===

  #firstBeat = $state() as CellBeat; // set in constructor
  subdividedBeats: [CellBeat, ...CellBeat[]] | undefined = $state();

  get split(): boolean {
    return !!this.subdividedBeats;
  }
  set split(value: boolean) {
    if (!!this.subdividedBeats === value) return;
    if (value) {
      this.#firstBeat.effect = undefined;
      this.subdividedBeats = [this.#firstBeat, new CellBeat({}), new CellBeat({})];
    } else {
      delete this.subdividedBeats;
    }
  }

  //#endregion ^ Beats

  //#region === Proxies ===

  get chord(): Chord {
    return this.#firstBeat.chord as Chord;
  }
  set chord(chord: Chord) {
    this.split = false;
    this.#firstBeat.chord = chord;
    this.implied = undefined;
  }

  get chordHeard(): Chord {
    return this.#firstBeat.chordHeard as Chord;
  }

  get chordShown(): Chord {
    return this.#firstBeat.chordShown as Chord;
  }

  get effect(): SerializedCell['effect'] {
    return this.#firstBeat.effect;
  }
  set effect(effect: this['effect']) {
    this.split = false;
    this.#firstBeat.effect = effect;
  }

  //#endregion ^ Proxies

  // props set by parent array:
  section: Section | undefined = $state();
  index: number | undefined = $state();
  negativeIndex: number | undefined = $state();
  prev: Cell | undefined = $state();
  next: Cell | undefined = $state();
  layout = $state() as CellLayout;

  // props that can be publicly set
  lineLengthOverride?: number;
  implied?: boolean;

  setLengthOfLine(length: number): void {
    this.lineLengthOverride = length;
  }

  //#region === Calculated values ===

  private _repeated?: boolean;
  get repeated(): boolean {
    return !!this._repeated;
  }
  updateRepeated(): void {
    this._repeated =
      this.chord.equals(this.prev?.chord) &&
      this.prev?.effect != 'rest' &&
      !this.split &&
      !this.prev?.split;
  }

  updateChordHeardAndShown(keyInfo: { keyCharted: Key; keyHeard: Key; keyShown: Key }): void {
    if (this.subdividedBeats) {
      this.subdividedBeats.forEach((beat) => beat.updateChordHeardAndShown(keyInfo));
    } else {
      this.#firstBeat.updateChordHeardAndShown(keyInfo);
    }
  }

  //#endregion ^ Calculated

  //#region === Time signatures ===

  half = $state(false);

  timeSig: TimeSignature | undefined = $state(); // start of new time sig range
  effectiveTimeSignature: TimeSignature = $state('4/4');
  effectiveChangeToTimeSignature: TimeSignature | undefined = $state(); // dictated by previous/current cell(s)
  get beatCount(): 2 | 3 {
    const ts = this.effectiveTimeSignature;
    return ts == '3/4' || ts == '6/8' || ts == '9/8' ? 3 : 2;
  }

  //#endregion ^ Time signatures

  //#region === Volta ===

  volta: number[] | undefined = $state(); // start of new rep contraint range

  addVolta(value: number): void {
    if (!this.volta) this.volta = [];
    this.volta.push(value);
    this.volta.sort();
  }

  removeVolta(value: number): void {
    if (this.volta) {
      this.volta = this.volta.filter((v) => v !== value);
    }
  }

  private _repConstraint: number[] | undefined = $state(); // rep constraint dictated by previous/current cell(s)
  get repConstraint(): number[] | undefined {
    return this._repConstraint;
  }
  updateRepConstraint(): void {
    this._repConstraint = this.volta ? this.volta.slice() : this.prev?.repConstraint;
  }

  //#endregion ^ Volta
}
