import * as Sentry from '@sentry/browser';
import _ from 'underscore';
import { Analytics } from '@/browser/analytics/Analytics';

const t = (name: string, details?: Record<string, unknown>) => Analytics.trackEvent(name, details);

function wrapWithTry<Args extends unknown[]>(fn: (...args: Args) => void): (...args: Args) => void {
  return (...args) => {
    try {
      fn(...args);
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
    }
  };
}

function track<Details extends Record<string, unknown>>(
  name: string,
  debounceMs?: number,
  detailProcessor?: (details: Details) => Record<string, unknown>
) {
  if (debounceMs) {
    return _.debounce(
      wrapWithTry((details?: Details) =>
        Analytics.trackEvent(
          name,
          typeof details != 'undefined' && detailProcessor ? detailProcessor(details) : details
        )
      ),
      debounceMs
    );
  }
  return wrapWithTry((details?: Details) =>
    Analytics.trackEvent(
      name,
      typeof details != 'undefined' && detailProcessor ? detailProcessor(details) : details
    )
  );
}

function formatKeyValuePairs(serialized: unknown) {
  return serialized && typeof serialized === 'object'
    ? Object.entries(serialized)
        .map(([k, v]) => {
          const formatted =
            typeof v == 'object' || (typeof v == 'string' && /[ "']/.test(v))
              ? JSON.stringify(v)
              : JSON.stringify(v).replace(/^"(.+)"$/g, '$1');
          return `${k}=${formatted}`;
        })
        .join(' ')
    : JSON.stringify(serialized);
}

const bandSettingSetDebounceMap = new Map<string, ReturnType<typeof setTimeout>>();
const bandSettingSetImmediate = track<{ instrument: string; key: string; value: string }>(
  'Band.SettingSet',
  0
);

export const eventTracker = {
  trialStart: track('NewAccount.TrialStarted'),
  newAccountVerificationSent: track('NewAccount.VerificationSent'),
  signupFormFirstNameEntered: track('NewAccount.EnterName'),
  signupFormEmailEntered: track('NewAccount.EnterEmail'),
  signupFormPasswordEntered: track('NewAccount.EnterPassword'),
  newAccountError: track<{ label: string }>('NewAccount.Error'),

  loginFailed: track<{ label: string }>('Login.LoginFailed'),

  passwordResetSent: track('ForgotPassword.EmailSent'),
  passwordResetEmailNotFound: track('ForgotPassword.EmailNotFound'),
  passwordResetSendError: track<{ label: string }>('ForgotPassword.Error'),

  bpmSet: track<{ to: number }>('BPM.Set'),
  bpmAdjust: track<{ to: number; usingKeyboard?: boolean }>('BPM.Adjust'),
  bpmTap: track<{ bpm: number }>('BPM.Tap'),

  landingPageDemoFirstPlay: track<{ songName: string }>('LandingPageDemo.FirstPlay'),

  playbackFirstStart: track<{ songName: string }>('Song.FirstPlay'),
  playbackStart: track<{ songName: string; method: string }>('Song.Play'),
  playbackPause: track<{ songName: string; method: string }>('Song.Pause'),
  playbackStop: track<{ songName: string; method: string }>('Song.Stop'),
  playbackRestart: track<{ songName: string; method: string }>('Song.Restart'),

  helpPageOpen: track<{ pageId: string }>('Help.ViewPage', 0, (details) => ({
    label: details.pageId,
  })),
  helpMenuOpen: track<{ page: string }>('Help.OpenMenu'),
  helpSearch: track<{ query: string; instantResult?: boolean }>('Help.Search'),
  helpMenuSearch: track<{ query: string; instantResult?: boolean }>('HelpMenu.Search'),

  indexByLetter: track<{ letter: string }>('SongIndex.ByLetter', 0, (details) => ({
    label: details.letter,
  })),
  indexViewAll: track('SongIndex.ViewAll'),
  indexViewAllMedleys: track('SongIndex.ViewAllMedleys'),
  indexListsMenu: track<{ songName: string }>('SongIndex.OpenListsMenu'),
  indexMineList: track('SongIndex.Mine'),

  keySet: track<{ key: string; editMode?: boolean; withKeyboard?: boolean }>('Key.Set'),
  keyMenuOpen: track('Key.OpenMenu'),
  capoEnable: track('CapoedChords.Enable'),
  capoSet: track<{ fret: number }>('CapoedChords.Set'),
  capoDisable: track('CapoedChords.Disable'),
  capoShowAll: track('CapoedChords.ShowAll'),

  listCreate: track('SongList.NewList'),
  listAddSong: track('SongList.AddSong'),
  listRemoveSong: track('SongList.RemoveSong'),
  listRename: track('SongList.Rename'),
  listDelete: track('SongList.Delete'),
  listExpand: track<{ listId: string }>('SongList.Expand'),
  listCollapse: track<{ listId: string }>('SongList.Collapse'),
  listEditingHelp: track('SongList.EditingHelp'),

  bandMenuOpen: track('Band.OpenMenu'),
  bandMenuClose: track('Band.CloseMenu'),
  bandPanelOpen: track<{ instrument?: string; panel: string }>('Band.OpenPanel'),
  bandPanelOpenSubview: track<{ panel: string; subview: string }>('Band.OpenSubview'),
  bandPanelCloseSubview: track<{ panel: string }>('Band.CloseSubview'),
  bandPresetSet: track<{
    presetId: string;
    name: string;
    isDefault?: boolean;
    isBuiltIn?: boolean;
    isCustomizedDefault?: boolean;
  }>('Band.PresetSet'),
  bandPresetModeNew: track('Band.PresetModeNew'),
  bandPresetModeEdit: track<{ presetId: string; name: string }>('Band.PresetModeEdit'),
  bandPresetModeNormal: track('Band.PresetModeNormal'),
  bandPresetSave: track<{ presetId: string; name: string }>('Band.PresetSave'),
  bandPresetCreate: track<{ presetId: string; name: string }>('Band.PresetCreate'),
  bandPresetDelete: track<{ presetId: string; name: string }>('Band.PresetDelete'),
  bandPresetGoneView: track('Band.PresetGoneView'),
  bandPresetGoneDismiss: track('Band.PresetGoneDismiss'),
  bandInstrumentToggle: track<{ instrument: string; enabled: boolean }>('Band.InstrumentToggle'),
  bandCustomVolumeSet: track<{ instrument: string; volume?: number }>('Band.CustomVolumeSet', 800),
  bandCustomPanSet: track<{ instrument: string; pan?: number }>('Band.CustomPanSet', 800),
  bandGlobalVolumeSet: track<{ volume: number }>('Band.GlobalVolumeSet', 800),
  bandGlobalPanSet: track<{ pan: number }>('Band.GlobalPanSet', 800),
  bandGlobalMuteSet: track<{ mute: boolean }>('Band.GlobalMuteSet', 800),
  bandSwingSet: track<{ swing: string }>('Band.SwingSet', 800),

  bandSettingSet: ({
    instrument,
    key,
    serialized,
  }: {
    instrument: string;
    key: string;
    serialized: unknown;
  }) => {
    const uniqueKey = `${instrument}_${key}`;
    const existingTimeout = bandSettingSetDebounceMap.get(uniqueKey);
    if (existingTimeout) clearTimeout(existingTimeout);
    const timeout = setTimeout(() => {
      bandSettingSetImmediate({ instrument, key, value: formatKeyValuePairs(serialized) });
      bandSettingSetDebounceMap.delete(uniqueKey);
    }, 1000);
    bandSettingSetDebounceMap.set(uniqueKey, timeout);
  },
  bandInstrumentPresetSet: track<{ instrument: string; core: string; preset: string }>(
    'Band.InstrumentPresetSet'
  ),
  bandInstrumentStyleReverted: track<{ instrument: string }>('Band.InstrumentStyleReverted'),

  bandListenStart: track('Band.ListenStart'),
  bandListenStop: track('Band.ListenStop'),

  medleyEditDone: track('Song.Medley.Edit'),
  medleyCreateDone: track('Song.Medley.New'),

  referencesMenuOpen: track('References.OpenMenu'),

  editorDiscardNewSong: track('Song.New.Discard'),
  editorDiscardSong: track('Song.Edit.Discard'),
  editorSaveNewSong: track<{ label: string; songId: string }>('Song.New.Save'),
  editorSaveSong: track<{ label: string; songId: string }>('Song.Edit.Save'),

  listNavOpenList: track<{ listName: string; listId: string }>('ListNav.OpenList'),
  listNavGoToNeighbor: track('ListNav.Neighbor'),
  listNavToggleShuffle: track<{ shuffling: boolean }>('ListNav.ToggleShuffle'),
  listNavReshuffle: track('ListNav.Reshuffle'),

  autoAdvanceEnable: track<{ listId: string }>('AutoAdvance.Enable'),
  autoAdvanceDisable: track<{ listId: string; fromAutoFinish?: boolean }>('AutoAdvance.Disable'),
  autoAdvancePlayEnable: track<{ listId: string }>('AutoAdvance.PlayEnable'),
  autoAdvancePlayDisable: track<{ listId: string }>('AutoAdvance.PlayDisable'),
  autoAdvanceMenuOpen: track<{ listId: string }>('AutoAdvance.OpenMenu'),

  copySongToLibrary: track<{ label: string; songId: string }>('Song.CopyToLibrary'),
  copyMedleyToLibrary: track<{ label: string; medleyId: string }>('Medley.CopyToLibrary'),

  preferenceSet: track<{ key: string; value: string }>('Preference.Set'),

  songDeleteFromEditMenu: track<{ label: string }>('Song.Edit.Delete'),
  medleyDeleteFromEditMenu: track<{ label: string }>('Song.Medley.Delete'),

  autoFinishEnable: track('AutoFinish.Enable'),
  autoFinishDisable: track('AutoFinish.Disable'),
  autoFinishOpenMenu: track('AutoFinish.OpenMenu'),
  autoFinishSetReps: track<{ to: number }>('AutoFinish.Set'),
  autoFinishAdjustReps: track<{ to: number }>('AutoFinish.Adjust'),

  autoModulateEnable: track('AutoModulate.Enable'),
  autoModulateDisable: track('AutoModulate.Disable'),
  autoModulateSetKeys: track<{ to: number }>('AutoModulate.SetKeys'),
  autoModulateSetInc: track<{ to: number }>('AutoModulate.SetInc'),
  autoModulateSetReps: track<{ to: number }>('AutoModulate.SetReps'),
  autoModulateSetSeq: track<{ to: number }>('AutoModulate.SetSeq'),

  autoSpeedupOpenMenu: track('AutoSpeedup.OpenMenu'),
  autoSpeedupEnable: track('AutoSpeedup.Enable'),
  autoSpeedupDisable: track('AutoSpeedup.Disable'),
  autoSpeedupSetDelta: track<{ to: number }>('AutoSpeedup.Set'),
  autoSpeedupAdjustDelta: track<{ to: number }>('AutoSpeedup.Adjust'),
  autoSpeedupSetReps: track<{ to: number }>('AutoSpeedup.SetReps'),
  autoSpeedupSetMaxBPM: track<{ to: number }>('AutoSpeedup.SetMaximum'),
  autoSpeedupEnableMaxBPM: track('AutoSpeedup.EnableMaximum'),
  autoSpeedupDisableMaxBPM: track('AutoSpeedup.RemoveMaximum'),

  missedScheduledBeat: track<{ value: number; toleranceEarly: number }>('Audio.MissedBeat'),
  audioSuspendFixFailed: track('Audio.SuspendFixFailed'),

  loopSet: track('Loop.Set'),
  loopClear: track('Loop.Cancel'),

  notepadShow: track('Notepad.Show'),
  notepadHide: track('Notepad.Hide'),
  notepadSave: track('Notepad.SaveNotes'),

  sidebarMenuShow: track('SongMenu.ShowSidebar'),
  sidebarMenuHide: track('SongMenu.HideSidebar'),
  // SongMenu.OpenEditSongMenu
  // SongMenu.OpenEditMedleyMenu
  // SongMenu.OpenListsMenu
  // SongMenu.OpenSharingMenu
  // SongMenu.OpenMedleyMenu
  // SongMenu.OpenCountInSettingsMenu
  // SongMenu.OpenMixerMenu
  // SongMenu.OpenRecorderMenu
  // SongMenu.OpenChordDisplaySettingsMenu

  // LandingPage.FreeTrialButton { label: string }
  // LandingPage.ViewFullSongList
  // LandingPage.ScrollToDemo
  // LandingPage.ScrollToPitch
};
