export function migrateOldKeyStyle(key: string): Key {
  if (/^[A-G]{2}/.test(key)) {
    const minor = key.includes('m');
    const letter = key.charAt(minor ? 0 : 1).toUpperCase();
    const accidental = minor ? '#' : 'b';
    return `${letter}${accidental}${key.slice(2).toLowerCase()}` as Key;
  } else {
    return key.replace(/^([A-G])-(.*)$/i, '$1b$2') as Key;
  }
}
