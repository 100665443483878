<script lang="ts" module>
  const definitions: {
    beatCounts: Record<InstrumentId, number | Partial<Record<TimeSignature, number>>>;
    labels: Record<InstrumentId, Partial<Record<TimeSignature, string[]>>>;
  } = {
    beatCounts: {
      guitar: {
        '3/4': 3,
        '4/4': 2,
      },
      mandolin: {
        '3/4': 3,
        '4/4': 2,
      },
      bass: 1,
      feet: 1,
    },
    labels: {
      guitar: {
        '3/4': ['Beat 1', 'Beat 2', 'Beat 3'],
        '4/4': ['Onbeats (boom)', 'Offbeats (chuck)'],
      },
      'mandolin': {
        '3/4': ['Beat 1', 'Beat 2', 'Beat 3'],
        '4/4': ['Onbeats (boom)', 'Offbeats (chuck)'],
      },
      'bass': {
        '3/4': ['Beat 1'],
        '4/4': ['Onbeats'],
      },
      'feet': {
        '3/4': ['Beat 1'],
        '4/4': ['Onbeats'],
      },
    },
  };
</script>

<script lang="ts">
  import type { InstrumentCore } from '@/band/instruments/InstrumentCore';
  import type { InstrumentTimingSetting } from '@/band/instruments/InstrumentTimingSetting';
  import InstrumentTimingSection from '@/band/ui/components/InstrumentTimingSection.svelte';
  import PanelModalLayout from '@/band/ui/panels/PanelModalLayout.svelte';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import { clickingTapping } from '@/ui-helpers/click-tap';

  const bandMenu = getBandMenuState();

  interface Props {
    instId: InstrumentId;
  }

  let { instId }: Props = $props();

  let timeSignature = $derived($bandMenu.timeSignature);

  let instrument = $derived($bandMenu.instruments[instId]);

  let instrumentCore: InstrumentCore | undefined = $state();
  trackerEffect(() => (instrumentCore = instrument.core()));

  let noSwing = $derived(
    instrumentCore && 'swingCategory' in instrumentCore
      ? !instrumentCore.swingCategory ||
          instrumentCore.swingCategory == 'none' ||
          instrumentCore.swingCategory == 'jazz'
      : true
  );

  let timing: InstrumentTimingSetting = $state() as InstrumentTimingSetting;
  trackerEffect(() => (timing = instrument.settings().timing));

  let beatCount: number = $derived.by(() => {
    const def = definitions.beatCounts[instId];
    return typeof def == 'number' ? def : def[timeSignature] || 0;
  });

  let labels = $derived(definitions.labels[instId][timeSignature] ?? []);

  let rows = $derived(
    Array.from({ length: beatCount }, (_, i) => ({
      i,
      beatAdjustment: timing.beatAdjustments[i] ?? 0,
      label: labels[i] ?? `Beat ${i + 1}`,
    }))
  );
  $inspect(rows);

  function setToValueAtIndex(value: number, index: number) {
    instrument.setSetting('timing', timing.withAdjustedBeat(index, value));
  }
</script>

<PanelModalLayout>
  {#snippet header()}
    <header class="panelHeader text-balance">
      You can adjust how the {instrument.name.toLowerCase()} plays relative to the beat. In general,
      we recommend leaving everything "right on the beat".
      <!-- <a href="#" class="font-medium">Learn more</a>. -->
    </header>
  {/snippet}

  {#snippet main()}
    <div class="p-3 pb-0">
      {#each rows as { i, beatAdjustment, label } (i)}
        {#if !(i === 0 && instId == 'mandolin')}
          <div class="pb-6">
            <InstrumentTimingSection
              {label}
              value={beatAdjustment}
              on:change={(e) => setToValueAtIndex(e.detail, i)}
            />
          </div>
        {/if}
      {/each}

      {#if !noSwing}
        <div class="border-t border-panelWellBorder p-6 text-center text-sm italic text-gray-500">
          The timing of upstrokes can be adjusted by
          <span class="whitespace-nowrap">{clickingTapping} Back</span>
          and {clickingTapping} on Swing.
        </div>
      {/if}
    </div>
  {/snippet}
</PanelModalLayout>
