import Cookies from 'js-cookie';
import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { eventTracker } from '@/browser/analytics/eventTracker';
import { currentTimeZone } from '@/browser/currentTimeZone';
import { rpcCreateUserAccount } from '@/server/methods/accounts/rpcCreateUserAccount';

Template.newAccountForm.onCreated(() => {
  Template.instance().errorMessage = new ReactiveVar();
  Template.instance().submitting = new ReactiveVar();
  Template.instance().emailSuggestion = new ReactiveVar();
});

Template.newAccountForm.onRendered(() => {
  Template.instance().$('input[name="firstName"]').focus();
  import('@zootools/email-spell-checker'); // to preload
});

Template.newAccountForm.helpers({
  errorMessage() {
    return Template.instance().errorMessage.get();
  },
  submitting() {
    return Template.instance().submitting.get();
  },
  emailSuggestion() {
    return Template.instance().emailSuggestion.get();
  },
});

const transferFocusOnEnter = (target) => {
  return (event, instance) => {
    if (event.key == 'Enter' && event.target.validity?.valid) {
      instance.$(target).focus();
      return false;
    }
  };
};

Template.newAccountForm.events({
  'keydown input[name="firstName"]': transferFocusOnEnter('input[name="lastName"]'),
  'keydown input[name="lastName"]': transferFocusOnEnter('input[name="email"]'),
  'keydown input[name="email"]': transferFocusOnEnter('input[name="password"]'),
  'submit form': async function (event, instance) {
    event.preventDefault();
    instance.submitting.set(true);

    const userDetails = {
      firstName: instance.$('[name="firstName"]').val(),
      lastName: instance.$('[name="lastName"]').val(),
      email: instance.$('[name="email"]').val().trim(),
      password: instance.$('[name="password"]').val(),
      offer: Cookies.get('offer'),
      utcOffset: currentTimeZone.utcOffset,
      timeZone: currentTimeZone.name,
      utm: _.object(
        _.map(['campaign', 'medium', 'source', 'term', 'content'], (key) => [
          key,
          Cookies.get(`utm_${key}`),
        ])
      ),
    };
    try {
      await rpcCreateUserAccount(userDetails);
      eventTracker.newAccountVerificationSent();
      Session.set('email-inbox-to-open', userDetails.email);
      FlowRouter.go('signup-verify');
    } catch (err) {
      instance.submitting.set(false);
      if (err && err.error == 'userSignup.emailExists') {
        eventTracker.newAccountError({ label: 'account-exists' });
        instance.errorMessage.set(
          'Whoops, that email is already registered! Go and <a href="/login">log&nbsp;in</a>, or use the <a href="/forgot-password">forgot password page</a> if needed.'
        );
      } else if (err && err.error == 'userSignup.fakeEmail') {
        eventTracker.newAccountError({ label: 'fake-email' });
        instance.errorMessage.set(
          'Sorry, we don\'t accept disposable email addresses. But don’t worry, we won’t spam you or share your email, it’s just for sending you emails related to your Strum Machine account. (If this <i>is</i> your real email address, please write <a href="mailto:support@strummachine.com">support@strummachine.com</a> and we\'ll get you signed up.)'
        );
      } else {
        eventTracker.newAccountError({ label: err.reason });
        instance.errorMessage.set(err.reason);
      }
    }
  },
  'input [name="firstName"]': _.once(() => {
    eventTracker.signupFormFirstNameEntered();
  }),
  'input [name="email"]': _.once(() => {
    eventTracker.signupFormEmailEntered();
  }),
  'input [name="password"]': _.once(() => {
    eventTracker.signupFormPasswordEntered();
  }),
  'blur [name="email"]': function (event, instance) {
    var email = instance.$('[name="email"]').val().trim();
    if (!/^.+@.+\./.test(email)) return;
    import('@zootools/email-spell-checker').then(({ default: emailSpellChecker }) => {
      emailSpellChecker.run({
        email,
        suggested(suggestion) {
          instance.emailSuggestion.set(suggestion);
        },
        empty() {
          instance.emailSuggestion.set(null);
        },
      });
    });
  },
  'click .js-emailSuggestion': function (event, instance) {
    instance.$('[name="email"]').val(Template.instance().emailSuggestion.get().full);
    Template.instance().emailSuggestion.set(null);
    return false;
  },
});
