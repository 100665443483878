<script lang="ts">
  interface Props {
    checked?: boolean;
    size?: number;
    useCurrentColor?: boolean;
  }

  let { checked = false, size = 20, useCurrentColor = false }: Props = $props();
</script>

<svg
  viewBox="0 0 {size} {size}"
  fill="none"
  style="width: {size}px; height: {size}px; display: block;"
>
  <circle
    cx={size / 2}
    cy={size / 2}
    r={size / 2 - 1}
    stroke={useCurrentColor ? 'currentColor' : checked ? 'var(--primary-500)' : 'var(--gray-400)'}
    stroke-width="2"
    class="transition-all"
  />
  {#if checked}
    <circle
      cx={size / 2}
      cy={size / 2}
      r={size / 2 - 4}
      fill={useCurrentColor ? 'currentColor' : 'var(--primary-500)'}
      class="transition-all"
    />
  {/if}
</svg>
