import type { AutoModulateSettings } from '@/auto-tools/AutoModulateSettings';
import type { AutoModulateState } from '@/auto-tools/AutoModulateState';
import type { Song } from '@/chart/Song';
import { Chord } from '@/music/Chord';

export function upcomingKeysForAutoModulate({
  song,
  settings,
  state,
}: {
  song?: Song;
  settings?: AutoModulateSettings;
  state?: AutoModulateState;
} = {}): Chord[] {
  let lineup: Chord[] = [];
  if (!song) return [];
  if (!settings) settings = song.prefs.autoModulate;
  if (!state) state = song.state.autoModulateState;
  const keySet = settings.keys();
  if (!keySet) return [];
  const keyHeard = song.keyHeard();
  if (!keyHeard) return [];
  let key = new Chord(keyHeard);
  if (keySet == 'c') {
    lineup =
      settings
        .seq()
        ?.split(' ')
        .filter((k) => k)
        .map((k) => new Chord(k)) || [];
    if (lineup.length > 1) {
      let currentIndex = state.posInSeq();
      if (currentIndex > lineup.length - 1 || lineup[currentIndex]?.rootNote != key.rootNote) {
        currentIndex = lineup.findIndex((k) => k.rootNote == key.rootNote);
        if (currentIndex == -1) currentIndex = 0;
      }
      const current = lineup[currentIndex];
      if (current?.rootNote == key.rootNote) {
        lineup = lineup.slice(currentIndex + 1).concat(lineup.slice(0, currentIndex + 1));
      }
    }
  } else {
    const minor = key.minor;
    do {
      key = key.shift(settings.inc(), minor ? 'Am' : 'C');
      if (lineup.length > 1 && key.rootNote == lineup[0]!.rootNote) break;
      if (
        keySet == 'a' ||
        (keySet.startsWith('n') && !key.sharp && !key.flat) ||
        (keySet == 'n+' && key.rootNote == (key.minor ? 'F#' : 'Bb'))
      ) {
        lineup.push(key);
      }
    } while (lineup.length < 99);
  }
  return lineup;
}
