import { ArpeggiatedGuitarCore } from '@/band/instruments/guitar/cores/ArpeggiatedGuitarCore';
import { ArpeggiatedWaltzGuitarCore } from '@/band/instruments/guitar/cores/ArpeggiatedWaltzGuitarCore';
import { BluegrassGuitarCore } from '@/band/instruments/guitar/cores/BluegrassGuitarCore';
import { BluegrassWaltzGuitarCore } from '@/band/instruments/guitar/cores/BluegrassWaltzGuitarCore';
import { BoomChuckGuitarCore } from '@/band/instruments/guitar/cores/BoomChuckGuitarCore';
import { BoomChuckJigGuitarCore } from '@/band/instruments/guitar/cores/BoomChuckJigGuitarCore';
import { BoomChuckSlipJigGuitarCore } from '@/band/instruments/guitar/cores/BoomChuckSlipJigGuitarCore';
import { CelticJigGuitarCore } from '@/band/instruments/guitar/cores/CelticJigGuitarCore';
import { CelticReelGuitarCore } from '@/band/instruments/guitar/cores/CelticReelGuitarCore';
import { CelticSlipJigGuitarCore } from '@/band/instruments/guitar/cores/CelticSlipJigGuitarCore';
import { DampenedGuitarCore } from '@/band/instruments/guitar/cores/DampenedGuitarCore';
import { DampenedWaltzGuitarCore } from '@/band/instruments/guitar/cores/DampenedWaltzGuitarCore';
import { HalfTimeGuitarCore } from '@/band/instruments/guitar/cores/HalfTimeGuitarCore';
import { LatinGuitarCore } from '@/band/instruments/guitar/cores/LatinGuitarCore';
import { SyncopatedBluegrassGuitarCore } from '@/band/instruments/guitar/cores/SyncopatedBluegrassGuitarCore';
import type { GuitarCore } from '@/band/instruments/guitar/GuitarCore';

// These only get shown in the UI according to guitarPatternLineup.ts

export const guitarCores = {
  [ArpeggiatedGuitarCore.id]: ArpeggiatedGuitarCore,
  [ArpeggiatedWaltzGuitarCore.id]: ArpeggiatedWaltzGuitarCore,
  [BluegrassGuitarCore.id]: BluegrassGuitarCore,
  [BluegrassWaltzGuitarCore.id]: BluegrassWaltzGuitarCore,
  [BoomChuckGuitarCore.id]: BoomChuckGuitarCore,
  [BoomChuckJigGuitarCore.id]: BoomChuckJigGuitarCore,
  [BoomChuckSlipJigGuitarCore.id]: BoomChuckSlipJigGuitarCore,
  [CelticJigGuitarCore.id]: CelticJigGuitarCore,
  [CelticReelGuitarCore.id]: CelticReelGuitarCore,
  [CelticSlipJigGuitarCore.id]: CelticSlipJigGuitarCore,
  [DampenedGuitarCore.id]: DampenedGuitarCore,
  [DampenedWaltzGuitarCore.id]: DampenedWaltzGuitarCore,
  [HalfTimeGuitarCore.id]: HalfTimeGuitarCore,
  [LatinGuitarCore.id]: LatinGuitarCore,
  [SyncopatedBluegrassGuitarCore.id]: SyncopatedBluegrassGuitarCore,
} as const satisfies Record<string, GuitarCore>;
