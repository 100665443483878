import { eventTracker } from '@/browser/analytics/eventTracker';
import { Crnt } from '@/Crnt';
import { smartDefaultCapoForKey } from '@/music/capo-knowledge';
import { UserProfile } from '@/user/UserProfile';

Template.keySpecialFeaturesSettings.onRendered(() => {
  const instance = Template.instance();

  instance.find('.js-specialFeatureToggles')._uihooks = {
    insertElement(node, next) {
      if (node.nodeName != 'BUTTON') {
        $(node).insertBefore(next);
        return;
      }
      instance.find('.js-specialFeatureToggles').classList.remove('enabling-something');
      $(node).insertBefore(next);
      const animateProp = node.nextElementSibling ? 'marginLeft' : 'marginRight';
      $(node).velocity(
        {
          [animateProp]: [0, '-50%'],
          'flexBasis': ['100%', '50%'],
        },
        {
          duration: 300,
          easing: [0.4, 0, 0.4, 1],
        }
      );
    },
    removeElement(node) {
      if (node.nodeName != 'BUTTON') {
        $(node).remove();
        return;
      }
      instance.find('.js-specialFeatureToggles').classList.add('enabling-something');
      const animateProp = node.nextElementSibling ? 'marginLeft' : 'marginRight';
      $(node)
        .attr('disabled', true)
        .velocity(
          {
            [animateProp]: ['-50%', 0],
            'flexBasis': ['50%', '100%'],
          },
          {
            duration: 300,
            easing: [0.4, 0, 0.4, 1],
            complete() {
              $(node).remove();
            },
          }
        );
    },
  };

  instance.find('.js-specialFeatureContainer')._uihooks = {
    insertElement(node, next) {
      $(node)
        .insertBefore(next)
        .hide()
        .focus()
        .velocity(
          { opacity: [1, 0] },
          {
            duration: 300,
            easing: [0.4, 0, 0.4, 1],
          }
        )
        .velocity('slideDown', {
          duration: 300,
          easing: [0.4, 0, 0.4, 1],
          queue: false,
        });
    },
    removeElement(node) {
      $(node)
        .velocity(
          { opacity: [0, 1] },
          {
            duration: 300,
            easing: [0.4, 0, 0.4, 1],
          }
        )
        .velocity('slideUp', {
          duration: 300,
          easing: [0.4, 0, 0.4, 1],
          queue: false,
          complete: () => $(node).remove(),
        });
    },
  };
});

Template.keySpecialFeaturesSettings.helpers({
  capoEnabled() {
    return capoEnabled();
  },
  autoModulateEnabled() {
    return Crnt.song()?.prefs.autoModulate.enabled();
  },
  neitherEnabled() {
    return !capoEnabled() && !Crnt.song()?.prefs.autoModulate.enabled();
  },
});

Template.keySpecialFeaturesSettings.events({
  'click .js-capoToggle': function (event, instance) {
    if (capoEnabled()) {
      Crnt.song()?.prefs.setCapo(0);
      eventTracker.capoDisable();
    } else {
      Crnt.song()?.prefs.setCapo(smartDefaultCapoForKey(Crnt.song()?.keyHeard()));
      eventTracker.capoEnable();
      UserProfile.recordMilestone('CAPO_SET');
    }
  },
  'click .js-autoModulateToggle': function (event, instance) {
    Crnt.song()?.prefs.autoModulate.toggle();
    if (Crnt.song()?.prefs.autoModulate.enabled()) {
      eventTracker.autoModulateEnable();
      UserProfile.recordMilestone('AUTO_MODULATE');
    } else {
      eventTracker.autoModulateDisable();
    }
  },
});

const capoEnabled = () => Crnt.song()?.prefs.capo() > 0;
