import { eventTracker } from '@/browser/analytics/eventTracker';
import { Crnt } from '@/Crnt';
import ScalarInputWithTrimmers from '@/ui/ScalarInputWithTrimmers.svelte';
import { UserProfile } from '@/user/UserProfile';

Template.autoFinishSettings.onRendered(() => {
  UserProfile.recordMilestone('AUTO_FINISH_OPEN');
  eventTracker.autoFinishOpenMenu();
});

Template.autoFinishSettings.helpers({
  autoAdvanceEnabled() {
    return Crnt.list()?.autoAdvanceEnabled();
  },

  currentValue() {
    return Crnt.song()?.prefs.autoFinish.reps();
  },

  adjustFunc() {
    return function (increment) {
      Crnt.song()?.prefs.autoFinish.adjustReps(increment);
      eventTracker.autoFinishAdjustReps({ to: Crnt.song()?.prefs.autoFinish.reps() });
    };
  },

  setValueFunc() {
    return function (value) {
      if (value > 0) Crnt.song()?.prefs.autoFinish.setReps(value);
      eventTracker.autoFinishSetReps({ to: Crnt.song()?.prefs.autoFinish.reps() });
    };
  },

  ScalarInputWithTrimmers() {
    return ScalarInputWithTrimmers;
  },
});
