import { Mongo } from 'meteor/mongo';

export interface NominationRecord {
  songId: string;
  userId?: string;
  userFirst?: string;
  userLast?: string;
  status?: string;
  createdAt: Date;
  updatedAt?: Date;
  finalizedAt?: Date;
}

export const NominationsCollection = new Mongo.Collection('nominations');
NominationsCollection.deny({ insert: () => true, update: () => true, remove: () => true });
