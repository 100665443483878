import { chop, strumDown } from '@/band/instruments/guitar/GuitarActions';
import { guitarChordStrumSpecs } from '@/band/instruments/guitar/guitarChordStrumSpecs';
import type { LinearizedMeasure } from '@/chart/LinearizedMeasure';

export function overlayMeasureEffectsForGuitar(measure: LinearizedMeasure) {
  measure.beats.forEach((beat) => {
    if (!beat.cell.effect) return;

    const plan = (beat.plans.guitar ??= {});

    plan.offbeatIntent = undefined;

    if (beat.beatInCell > 0) {
      plan.onbeatIntent = undefined;
    } else {
      const spread =
        plan.chord?.strumSpec == guitarChordStrumSpecs.g ||
        plan.chord?.strumSpec == guitarChordStrumSpecs.e
          ? 6
          : 5;
      if (beat.diamond) {
        plan.onbeatIntent = strumDown('strum', { spread, db: 0 });
      } else if (beat.stop) {
        plan.onbeatIntent = chop(0.3, 'strum', { spread, db: 0 });
      } else if (beat.rest) {
        plan.onbeatIntent = undefined;
        // If more than two rests in a row, play thumps
        if (
          beat.beatInCell === 0 &&
          (beat.cell.prev?.effect == 'rest' || beat.cell.next?.effect == 'rest')
        ) {
          // TODO: play thump (to be implemented)
        }
      }
    }
  });
}
