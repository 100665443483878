import { ReactiveVar } from 'meteor/reactive-var';
import { ReactiveChartPosition } from '@/playback/ReactiveChartPosition';

export class PlayerDisplayState {
  replaceState(state: PlayerStateInfo): void {
    this._countIn.set(state.countIn);
    this._playing.set(state.playing);
    this._footOut.set(!!state.footOut);
    this._startingPosition.set(
      state.startingPosition ? new ReactiveChartPosition(state.startingPosition) : undefined
    );
    this._position.set(state.position ? new ReactiveChartPosition(state.position) : undefined);
  }

  get(): PlayerStateInfo {
    return {
      playing: this.playing(),
      footOut: this.footOut(),
      countIn: this.countIn(),
      position: this.position()?.toChartPosition(),
      startingPosition: this.startingPosition()?.toChartPosition(),
    };
  }

  private _playing = new ReactiveVar(false);
  playing(): boolean {
    return this._playing.get();
  }
  setPlaying(value: boolean): void {
    this._playing.set(value);
  }

  private _footOut = new ReactiveVar(false);
  footOut(): boolean {
    return this._footOut.get();
  }
  setFootOut(value: boolean): void {
    this._footOut.set(value);
  }

  private _countIn = new ReactiveVar<CountInState | undefined>(undefined);
  countIn(): CountInState | undefined {
    return this._countIn.get();
  }

  private _startingPosition = new ReactiveVar<ReactiveChartPosition | undefined>(undefined);
  startingPosition(): ReactiveChartPosition | undefined {
    return this._startingPosition.get();
  }
  setStartingPosition(position: ChartPosition): void {
    this._startingPosition.set(position ? new ReactiveChartPosition(position) : undefined);
  }

  private _position = new ReactiveVar<ReactiveChartPosition | undefined>(undefined);
  position(): ReactiveChartPosition | undefined {
    return this._position.get();
  }
  setPosition(pos: ChartPosition): void {
    this._position.set(new ReactiveChartPosition(pos));
  }

  setRound(round: number): void {
    (this._startingPosition.get() || this._position.get())?._round.set(round);
  }

  setBeat(beat: number): void {
    (this._startingPosition.get() || this._position.get())?._beat.set(beat);
  }
}
