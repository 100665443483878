import type { Instance as TippyInstance } from 'tippy.js';
import tippyJs from 'tippy.js';

let hideTimer: ReturnType<typeof setTimeout>;
let tippy: TippyInstance | undefined;

export function showClipboardToast(
  element: HTMLElement,
  command: 'cut' | 'copy' | 'paste' | 'pasteboard-empty'
) {
  clearTimeout(hideTimer);
  tippy?.destroy();

  const text =
    command == 'cut'
      ? 'Cut to clipboard'
      : command == 'copy'
        ? 'Copied to clipboard'
        : command == 'pasteboard-empty'
          ? 'Nothing pasted; cut or copy first'
          : command == 'paste'
            ? 'Pasted from clipboard'
            : '';

  const icon = `
    <div class="clipboard-toast-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12">
        <path d="M13.5455 2.18182L4.81823 12L0.45459 7.63636L2.63641 5.45455L4.81823 7.63636L11.3637 0L13.5455 2.18182Z" fill="white" />
      </svg>
    </div>`;

  const content = command == 'pasteboard-empty' ? text : `${icon} ${text}`;

  tippy = tippyJs(element, {
    placement: 'top',
    arrow: false,
    delay: [400, 50],
    hideOnClick: false,
    allowHTML: true,
    trigger: 'manual',
    theme: 'clipboard-toast',
    content: content,
    onMount(instance) {
      const box = instance.popper.firstElementChild;
      requestAnimationFrame(() => box?.classList.add('clipboard-toast'));
      clearTimeout(hideTimer);
      hideTimer = setTimeout(() => {
        tippy?.destroy();
        tippy = undefined;
      }, 2200);
    },
    onHidden(instance) {
      const box = instance.popper.firstElementChild;
      box?.classList.remove('clipboard-toast');
    },
  });

  tippy.show();

  return {
    hide: () => {
      tippy?.hide();
    },
  };
}

export function hideClipboardToast() {
  tippy?.hide();
}
