import AudioManager from '@/audio/engine/AudioManager';
import { Playback } from '@/audio/engine/base/Playback';
import type { PlayProps } from '@/audio/engine/base/PlayProps';
import type { FMBChannel } from '@/audio/engine/fmb/FMBChannel';
import { FMBCordovaPlugin } from '@/audio/engine/fmb/FMBCordovaPlugin';
import type { FMBSample } from '@/audio/engine/fmb/FMBSample';
import { Conductor } from '@/Conductor';

export class FMBPlayback extends Playback {
  readonly channel: FMBChannel;
  private readonly playbackIdPromise: Promise<string | undefined>;

  // new Playbacks should only be constructed by the Sample class
  constructor({
    sample,
    channel,
    playProps,
  }: {
    sample: FMBSample;
    channel: FMBChannel;
    playProps: PlayProps;
  }) {
    super({ sample, playProps, currentTime: AudioManager.currentTime() });

    let resolvePlaybackIdPromise: (playbackId?: string) => void;
    this.playbackIdPromise = new Promise((res) => (resolvePlaybackIdPromise = res));

    void sample.load().then(() => {
      FMBCordovaPlugin.playSample({
        sampleId: sample.id,
        channel: channel.id,
        atTime: this.startTime,
        volume: playProps.volume,
        offset: playProps.offset,
        playbackRate: playProps.pitchShift ? 1.059463 ** playProps.pitchShift : 1,
        fadeInDuration: playProps.fadeInDuration,
      })
        .then(({ playbackId }: { playbackId?: string }) => {
          if (!playbackId) {
            void this.destroy();
          } else {
            const secondsUntilEndOfPlayback =
              this.playDuration + (this.startTime - AudioManager.currentTime());
            setTimeout(() => void this.destroy(), 1000 * (secondsUntilEndOfPlayback + 0.25));
          }
          resolvePlaybackIdPromise(playbackId);
        })
        .catch((error) => {
          console.error(error);
          if (Conductor.playing()) Conductor.pause();
          void this.destroy();
        });
    });

    this.channel = channel;
    channel.addPlayback(this);
  }

  async fade({ to, at, duration }: { to: number; at: number; duration: number }): Promise<void> {
    const playbackId = await this.playbackIdPromise;
    if (!playbackId || this.destroyed) return;
    const atTime = at ?? AudioManager.currentTime();
    FMBCordovaPlugin.fadePlayback({
      playbackId,
      atTime,
      level: to / this.volume,
      fadeDuration: duration,
    }).catch(() => undefined);
  }

  async stop({
    atTime,
    fadeDuration = 0.01,
  }: { atTime?: number; fadeDuration?: number } = {}): Promise<void> {
    const playbackId = await this.playbackIdPromise;
    if (!playbackId || this.destroyed) return;
    atTime ??= AudioManager.currentTime();
    if (this.scheduledStopTime && this.scheduledStopTime <= atTime) return;
    this.scheduledStopTime = atTime;
    FMBCordovaPlugin.stopPlayback({
      playbackId,
      atTime,
      fadeDuration,
    }).catch(() => undefined);

    const timeoutSeconds = atTime + fadeDuration * 1.8 - AudioManager.currentTime();
    window.setTimeout(() => {
      this.emit('stopped');
      void this.destroy(); // an "end" event will be dispatched in the destroy function
    }, timeoutSeconds * 1000);
  }

  _destroy(): void {
    this.emit('end');
  }
}
