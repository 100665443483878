import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { Crnt } from '@/Crnt';
import SongMenuManager from '@/ui/SongMenuManager';
import UserPreferences from '@/user/UserPreferences';

Template.musicPageMenu.onRendered(() => {
  const instance = Template.instance();
  instance.autorun(() => {
    if (!Template.currentData().visible) {
      SongMenuManager.showMenu(null);
    }
  });
  instance.autorun(() => {
    const menuId = SongMenuManager.activeMenu();
    instance.$('.js-menuToggle.menu-open').removeClass('menu-open');
    instance.$(`.js-menuToggle[data-menu-id="${menuId}"]`).addClass('menu-open');
  });
});

Template.musicPageMenu.helpers({
  showRecorder() {
    return UserPreferences.get('recorder') && !Meteor.isCordova;
  },
  showClickTrack() {
    return UserPreferences.get('clickFeaturePreview');
  },
  onMedley() {
    return FlowRouter.getRouteName().includes('medley') || Crnt.medley();
  },
});

Template.musicPageMenu.events({
  'click .js-menuToggle': function (event, instance) {
    const menuId = $(event.currentTarget).data('menu-id');
    if (SongMenuManager.mobileMenu()) {
      Modal.hide('sidePanel');
    }
    SongMenuManager.toggleMenu(menuId);
  },
});
