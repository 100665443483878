import * as Sentry from '@sentry/browser';
import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import AudioManager from '@/audio/engine/AudioManager';
import { MuteSwitchBypassSetting } from '@/audio/engine/web/MuteSwitchBypassSetting';
import { eventTracker } from '@/browser/analytics/eventTracker';
import { Crnt } from '@/Crnt';
import { MusicLibrary } from '@/library/MusicLibrary';
import { Roles } from '@/Roles';
import { UserProfile } from '@/user/UserProfile';

Template.musicPageNotices.onCreated(() => {
  const instance = Template.instance();
  instance.muteSwitchOn = new ReactiveVar();
  if (Meteor.isCordova) {
    document.addEventListener(
      'deviceready',
      () => {
        instance.autorun(() => {
          if (
            Template.appContainer.activeScreen() == 'music' &&
            MuteSwitchBypassSetting.get() == 'off'
          ) {
            cordova.plugins.SMExtras.detectMuteSwitch((muteSwitchOn) =>
              instance.muteSwitchOn.set(muteSwitchOn)
            );
          }
        });
      },
      false
    );
  }
});

Template.musicPageNotices.helpers({
  showCopyToLibraryNotice() {
    const song = Crnt.song();
    if (!song) return false;
    if (song.editMode()) return false; // for admins
    if (!song.userId() || song.userId() == Meteor.userId()) return false;
    if (!MusicLibrary.lists.countWithItem(song.id())) return true;
  },

  showMedleyCopyNotice() {
    const medley = Crnt.medley();
    if (!medley) return false;
    if (!medley.userId() || medley.userId() == Meteor.userId()) return false;
    return true;
  },

  showSubscriptionRequiredNotice() {
    if (!Meteor.user('roles')) return false;
    const songOrMedley = Crnt.song() || Crnt.medley();
    if (songOrMedley && !Roles.currentUserCanPlaySong(songOrMedley)) return true;
  },

  showMuteSwitchNotice() {
    return (
      Template.instance().muteSwitchOn.get() &&
      MuteSwitchBypassSetting.get() == 'off' &&
      AudioManager.mode == 'web-audio' &&
      !UserProfile.isMessageHidden('ios-muted')
    );
  },
});

Template.musicPageNotices.events({
  'click .js-copyToLibrary': async (event, _instance) => {
    event.preventDefault();
    try {
      const duplicate = Crnt.song().serialize({ duplicating: true });
      const newSongId = await MusicLibrary.songs.add(duplicate);
      FlowRouter.go('show-song', { songId: newSongId });
      if (Meteor.status().connected) {
        Meteor.defer(() =>
          Modal.show('listsMenuModal', {
            songId: newSongId,
            newSong: true,
          })
        );
      }
      eventTracker.copySongToLibrary({
        label: duplicate.name,
        songId: newSongId,
      });
    } catch (error) {
      Sentry.captureException(error);
      Bert.alert(error.message, 'danger');
    }
  },

  'click .js-copyMedleyToLibrary': async (event, _instance) => {
    event.preventDefault();
    try {
      const duplicate = Crnt.medley().serialize({ duplicating: true });
      const newMedleyId = await MusicLibrary.medleys.add(duplicate);
      FlowRouter.go('show-medley', { medleyId: newMedleyId });
      if (Meteor.status().connected) {
        Meteor.defer(() =>
          Modal.show('listsMenuModal', {
            medleyId: newMedleyId,
            newSong: true,
          })
        );
      }
      eventTracker.copyMedleyToLibrary({
        label: duplicate.name,
        medleyId: newMedleyId,
      });
    } catch (error) {
      Sentry.captureException(error);
      Bert.alert(error.message, 'danger');
    }
  },

  'click .js-addToList': function (event, _instance) {
    if (!Meteor.status().connected) {
      bootbox.alert({
        title: 'Lists are read-only when offline',
        message: 'Sorry, you need to be connected to the internet to make changes to song lists.',
      });
      return;
    }
    const modalData = Crnt.medley()
      ? {
          medleyId: Crnt.medley()?.id(),
        }
      : {
          songId: Crnt.song()?.id(),
          songName: Crnt.musicTitle(),
        };
    Modal.show('listsMenuModal', modalData);
    return false;
  },

  'click .js-close': function (event) {
    var $notice = $(event.currentTarget).closest('[data-notice-id]');
    if (!$notice) return;
    UserProfile.hideMessage($notice.attr('data-notice-id'));
    $notice.find('.js-close').blur();
    $notice.slideUp();
  },

  'click .js-closeOnce': function (event) {
    var $notice = $(event.currentTarget).closest('[data-notice-id]');
    if (!$notice) return;
    $notice.find('.js-closeOnce').blur();
    $notice.slideUp();
  },
});
