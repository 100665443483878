<script lang="ts">
  import type { BandPreset } from '@/band/presets/BandPreset';
  import { BandPresets } from '@/band/presets/BandPresets';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';
  import { autosizingTextarea } from '@/ui/autosizingTextarea';
  import { MultiPanelModalController } from '@/ui/settings/MultiPanelModalController.svelte';

  const modalController = MultiPanelModalController.current();

  const bandMenu = getBandMenuState();

  interface Props {
    preset: BandPreset;
  }

  let { preset }: Props = $props();

  let inputName: string = $state(preset.name);

  let failedBecauseSameName = $state(false);
  let existingPresetName: string | undefined = $state();
  let showErrorRing = $derived(failedBecauseSameName);

  function saveName() {
    if (!inputName.trim()) return;
    const existingPreset = BandPresets.custom().find(
      (p) =>
        p.name.toLocaleLowerCase() === inputName.trim().toLocaleLowerCase() && p.id !== preset.id
    );
    if (existingPreset) {
      failedBecauseSameName = true;
      existingPresetName = existingPreset.name;
    } else {
      $bandMenu.band.updatePresetName(inputName.trim());
      modalController.zoomOut();
    }
  }

  function cancelRename() {
    $bandMenu.band.enterNormalPresetMode();
  }

  function handleTextInputKeyDown(e: KeyboardEvent & { currentTarget: HTMLTextAreaElement }) {
    if (e.key == 'Enter') {
      const text = e.currentTarget.value.replace(/\r?\n/g, ' ').slice(0, 80);
      inputName = text;
      saveName();
      e.preventDefault();
    }
    if (e.key == 'Escape') {
      textarea.blur();
      e.stopPropagation();
    }
  }

  let textarea: HTMLTextAreaElement;
</script>

<textarea
  use:autosizingTextarea
  bind:value={inputName}
  placeholder="Enter a preset name..."
  class="mt-2 block w-full resize-none rounded-lg border border-gray-300 bg-white py-3 pl-4 pr-6 text-lg font-medium leading-6 text-gray-800 outline-none transition-all hover:border-primary-200 hover:bg-primary-50 hover:ring-2 hover:ring-primary-50 focus:border-primary-200 focus:bg-white focus:ring-2 focus:ring-primary-50
  {showErrorRing ? 'ring-2 ring-red-400' : ''}"
  rows="1"
  maxlength="80"
  autofocus={true}
  onkeydown={handleTextInputKeyDown}
  required={true}
  bind:this={textarea}
></textarea>

<div class="pb-4"></div>

{#if failedBecauseSameName}
  <div class="px-5 pb-3 text-center text-gray-800">
    You already have a preset called <span class="whitespace-nowrap font-medium">
      “{existingPresetName}”.
    </span>
  </div>
{/if}

<div class="flex justify-center gap-2 pb-3">
  <button
    class="btn-base rounded bg-gray-500 px-4 py-1 text-white hover:bg-gray-700"
    onclick={cancelRename}
  >
    Cancel
  </button>
  <button
    class="btn-base rounded bg-green-600 px-4 py-1 text-white hover:bg-green-700"
    disabled={inputName.trim().length === 0 || failedBecauseSameName}
    onclick={saveName}
  >
    Save Changes
  </button>
</div>
