import { Mongo } from 'meteor/mongo';

const collection = new Mongo.Collection<SongListRecord>('songLists');
collection.deny({ insert: () => true, update: () => true, remove: () => true });

export const SongListsCollection = collection;

export interface SongListRecord {
  _id: string;
  ownerId: string;
  ownerName: {
    first: string;
    last?: string;
  };
  name: string;

  songs: SongListItemRecord[];
  users: {
    userId: string;
    collapsed?: boolean;
  }[];
  createdAt: Date;
  publiclyEditable?: boolean;
  sortMethod?: 'a' | 'z' | 'm';
}

export interface SongListItemRecord {
  _id: string;
  name: string;
  userId?: string;
  userFirst?: string;
  userLast?: string;
  updatedAt?: Date;
  medleySongs?: Omit<SongListItemRecord, 'medleySongs' | 'listCount'>[];

  /** Number of lists this song is on; added as needed */
  listCount?: number;
}
