<script lang="ts">
  import PrettySlider from '@/band/ui/components/PrettySlider.svelte';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import { UserProfile } from '@/user/UserProfile';

  const bandMenu = getBandMenuState();

  let setting = $derived($bandMenu.guitarSettings.bassRuns);

  const balanceDivisor = 4;

  function updateQuarterEighthBalance(value: number) {
    $bandMenu.guitar.setSetting('bassRuns', setting.withQuarterEighthBalance(value));
    UserProfile.recordMilestone('BASS_RUN_TYPE_ADJUSTED');
  }

  let neverAdjustedBassRunType = false;
  trackerEffect(
    () =>
      (neverAdjustedBassRunType =
        !!UserProfile.notMilestone('BASS_RUN_TYPE_ADJUSTED') && setting.quarterEighthBalance === 0)
  );
</script>

<div class="">
  <!--
  {#if neverAdjustedBassRunType}
    <img
      src="/images/song-page/bass-run-drag-hint.2023-05-06.svg"
      style="position: absolute; left: -10px; top: 16px; pointer-events: none; display: block; z-index: 100;"
      height="40"
      alt="Drag handle to adjust run preference"
      class=""
      out:fade
    />
  {/if}
  -->
  <div class="grid grid-cols-2 justify-items-center gap-4 p-4">
    <div class="pole-container" style="--weighting: {setting.quarterWeight}">
      <div class="pole-label from-left">
        Quarter<br />Notes
      </div>
    </div>
    <div class="pole-container" style="--weighting: {setting.eighthWeight}">
      <div class="pole-label from-right">
        Eighth<br />Notes
      </div>
    </div>
    <div class="flex h-32 w-24 items-center justify-center">
      <div class="h-0 w-0 -rotate-90">
        <div class="w-32 -translate-x-1/2 -translate-y-1/2">
          <PrettySlider
            min={0}
            max={balanceDivisor}
            step={1}
            value={setting.quarterWeight * balanceDivisor}
            thumbDiameter={2}
            tickHeight={0.625}
            tickCount={balanceDivisor + 1}
            tickSqueezes={Array.from(
              { length: balanceDivisor + 1 },
              (_, i) => 1 - i / balanceDivisor
            )}
            ariaLabel="proportion of quarter note runs"
            on:change={({ detail }) => updateQuarterEighthBalance(1 - detail / balanceDivisor)}
          />
        </div>
      </div>
    </div>
    <div class="flex h-32 w-24 items-center justify-center">
      <div class="h-0 w-0 -rotate-90">
        <div class="w-32 -translate-x-1/2 -translate-y-1/2">
          <PrettySlider
            min={0}
            max={balanceDivisor}
            step={1}
            value={setting.eighthWeight * balanceDivisor}
            thumbDiameter={2}
            tickHeight={0.625}
            tickCount={balanceDivisor + 1}
            tickSqueezes={Array.from(
              { length: balanceDivisor + 1 },
              (_, i) => 1 - i / balanceDivisor
            )}
            ariaLabel="proportion of eighth note runs"
            on:change={({ detail }) => updateQuarterEighthBalance(detail / balanceDivisor)}
          />
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  :root {
    --hsl-full-strength-bass-run-bg: 206deg 40% 75%;
  }

  .pole-container {
    position: relative;
    display: inline-block;

    > .pole-label {
      background: hsl(var(--hsl-full-strength-bass-run-bg) / calc(0.3 + 0.7 * var(--weighting)));
      color: hsl(206deg 0% 0% / calc(0.6 + 0.4 * var(--weighting)));
      border-radius: 0.25rem;
      padding: 0.375rem 0.75rem 0.625rem;
      font-size: 14px;
      font-weight: 500;
      line-height: 19px;
      width: 6.75rem;
      white-space: nowrap;
      text-align: center;
      /* opacity: calc(0.65 + 0.35 * var(--weighting)); */
      transition: all 300ms ease-in-out;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        height: 5px;
        left: calc(50% - 50% * var(--weighting));
        right: calc(50% - 50% * var(--weighting));
        background-color: var(--primary-500);
        border-radius: 0.25rem;
        transition: all 300ms ease-in-out;
        opacity: min(1, calc(4 * var(--weighting)));
      }
    }
  }
</style>
