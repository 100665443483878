<script lang="ts">
  import { cubicOut } from 'svelte/easing';
  import type { FadeParams, TransitionConfig } from 'svelte/transition';

  interface Props {
    showRedo?: boolean;
    size?: number;
  }

  let { showRedo = false, size = 16 }: Props = $props();

  export function spinFade(
    node: Element,
    {
      delay = 0,
      duration = 400,
      easing = cubicOut,
      degrees = 180,
    }: FadeParams & { degrees?: number } = {}
  ): TransitionConfig {
    const style = getComputedStyle(node);
    const opacity = +style.opacity;

    return {
      delay,
      duration,
      easing,
      css: (t, u) =>
        'overflow: hidden;' + `opacity: ${t * opacity};` + `rotate: ${u * degrees}deg;`,
    };
  }
</script>

<svg
  class="size-5"
  aria-hidden="true"
  viewBox="-10 -10 20 20"
  style="width: {size * 1.25}px; height: {size * 1.25}px;"
>
  <g class="translate-y-[0.5px]">
    <rect
      class="fill-current duration-500"
      style="rotate: {showRedo ? -360 : 0}deg;"
      x="-1"
      y="-4"
      width="2"
      height="5"
      rx="1"
    />
    <rect
      class="fill-current duration-500"
      style="rotate: {showRedo ? -20 : 0}deg;"
      x="-1"
      y="-3"
      width="2"
      height="4"
      rx="1"
      transform="rotate(140 0 0)"
    />
  </g>
  {#key showRedo}
    <path
      in:spinFade={{ duration: 600, delay: 600, degrees: showRedo ? -40 : 40 }}
      out:spinFade={{ duration: 400, degrees: showRedo ? -30 : 30 }}
      class="origin-top-left fill-current"
      class:-scale-x-100={showRedo}
      d="M8 -0.02a8.02 8.02 0 0 1-13.02 6.25.77.77 0 0 1-.06-1.15l.37-.36a.78.78 0 0 1 1.03-.07 5.93 5.93 0 1 0-.38-8.98l1.63 1.64c.33.33.1.88-.36.88h-4.71a.52.52 0 0 1-.5-.51v-4.7c0-.44.55-.68.88-.35l1.6 1.6A8 8 0 0 1 8 -0.01Z"
    />
  {/key}
</svg>
