<script lang="ts">
  const defaultTickFade = 0.75;

  interface Props {
    tickCount?: number;
    tickValues?: number[];
    min: number;
    max: number;
    value: number;
    tickMode?: 'even' | 'flared' | 'swing' | 'increasing';
    tickSqueezes?: number[];
    tickFades?: number[];
    /** Diameter of slider thumb, in rems */
    thumbDiameter: number;
    /** Width of tick marks, in rems */
    tickWidth: number;
    /** Vertical space between tick marks, in rems */
    gapInMiddle: number;
    /** Amount of max tick reduction, in rems */
    tickHeight?: number;
    /** Where to render ticks: above, below, or in both places */
    aboveBelow?: 'above' | 'below' | 'both';
    /** Ticks that will be rotated 90 degrees will have different shadows. */
    vertical?: boolean;
  }

  let {
    tickCount = 0,
    tickValues = [],
    min,
    max,
    value,
    tickMode = 'even',
    tickSqueezes = [],
    tickFades = [],
    thumbDiameter,
    tickWidth,
    gapInMiddle,
    tickHeight = 0,
    aboveBelow = 'both',
    vertical = false,
  }: Props = $props();

  $effect(() => {
    if (!tickCount && tickValues.length > 0) {
      tickCount = tickValues.length;
    }
    if (tickCount && tickValues.length == 0) {
      tickValues = Array.from(
        { length: tickCount },
        (_, i) => min + ((max - min) * i) / (tickCount - 1)
      );
    }
  });

  let tickRatios = $derived(tickValues.map((tickValue) => (tickValue - min) / (max - min)));
  let currentValueTickIndex = $derived(
    tickValues.length > 0
      ? tickValues.reduce(
          (bestI, tickValue, i) =>
            Math.abs(tickValue - value) < Math.abs((tickValues[bestI] ?? Infinity) - value)
              ? i
              : bestI,
          0
        )
      : undefined
  );

  $effect(() => {
    if (tickSqueezes.length == 0) {
      tickSqueezes =
        tickMode == 'increasing'
          ? Array.from({ length: tickCount }, (_, i) => 1 - i / (tickCount - 1))
          : tickMode == 'flared'
            ? Array.from(
                { length: tickCount },
                (_, i) => 1 - Math.abs(i - (tickCount - 1) / 2) / (tickCount / 2)
              )
            : [];
    }
  });
</script>

<!-- This component expects a "position: relative" style in the parent. -->
<div
  class="pointer-events-none absolute -inset-y-0 z-10"
  style="left: {thumbDiameter / 2}rem; right: {thumbDiameter / 2}rem;"
>
  {#each { length: tickCount }, i}
    {@const current = i == currentValueTickIndex}
    {@const opacity = current ? 1 : (tickFades[i] ?? defaultTickFade)}
    <div
      class="absolute inset-y-0 flex -translate-x-1/2 flex-col justify-between"
      style="gap: {gapInMiddle}rem;
             padding: {tickHeight * (tickSqueezes[i] ?? 0)}rem 0;
             left: {(tickRatios[i] ?? 0) * 100}%;
             opacity: {opacity};
           "
    >
      {#each { length: 2 }, tickI}
        {@const invisible =
          (aboveBelow == 'below' && tickI == 0) || (aboveBelow == 'above' && tickI == 1)}
        <div
          class="tick-inner-shadow flex-1 rounded
              {current ? 'bg-primary-400' : 'bg-gray-200'}
              {current ? '' : 'transition-colors duration-150'}
              "
          style="width: {tickWidth}rem;"
          class:invisible
          class:vertical
        ></div>
      {/each}
    </div>
  {/each}
</div>

<style>
  .tick-inner-shadow {
    box-shadow:
      inset 0.2px 1px 1.5px rgb(0, 0, 0, 0.1),
      inset -0.2px 0.7px 1.5px rgba(255, 255, 255, 0.5);
  }
  .vertical.tick-inner-shadow {
    box-shadow:
      inset -0.8px 0.8px 1.5px rgb(0, 0, 0, 0.1),
      inset -0.5px 0.5px 1.5px rgba(255, 255, 255, 0.5);
  }
</style>
