import type { Mongo } from 'meteor/mongo';
import type { GroundedCollection } from '@/local-db/GroundedCollection';
import waitUntilReactive from '@/utilities/waitUntilReactive';

export async function removeFromCollectionWhenSafe(
  collection: GroundedCollection<any> | Mongo.Collection<any>,
  selector?: string | Mongo.Selector<unknown>
): Promise<void> {
  if (!selector) return;
  if ('pendingWrites' in collection) {
    await waitUntilReactive(() => collection.pendingWrites.isZero());
  }
  collection.remove(selector);
}
