import { strict as assert } from 'assert';
import { z } from 'zod';
import type { CircularStrumLandscape } from '@/band/instruments/CircularStrumLandscape';
import type { InstrumentSetting } from '@/band/instruments/InstrumentSetting';
import { interpolateBetweenPolesInPolarChart } from '@/band/instruments/interpolateBetweenPolesInPolarChart';
import { bounded } from '@/utilities/bounded';
import cloneJSON from '@/utilities/cloneJSON';

const serializedSchema = z.object({
  r: z.number().min(0).max(1).optional(),
  t: z.number().min(0).max(1).optional(),
});

type Serialization = z.infer<typeof serializedSchema>;

export class MandolinChopAccentsSetting implements InstrumentSetting<Serialization> {
  readonly polarR: number;
  readonly polarTheta: number;

  constructor(input: unknown = {}) {
    const data = serializedSchema.catch({}).parse(input);

    this.polarR = bounded(data.r ?? 1, 0, 1);
    this.polarTheta = bounded(data.t ?? 0.25, 0, 1);
  }

  serialize() {
    return this.polarR == 1 && this.polarTheta == 0.25
      ? {}
      : cloneJSON({
          r: this.polarR,
          t: this.polarTheta,
        });
  }

  closeTo(serialized?: Serialization) {
    const other = new MandolinChopAccentsSetting(serialized);
    const thisX = this.polarR * Math.cos(this.polarTheta * Math.PI * 2);
    const thisY = this.polarR * Math.sin(this.polarTheta * Math.PI * 2);
    const otherX = other.polarR * Math.cos(other.polarTheta * Math.PI * 2);
    const otherY = other.polarR * Math.sin(other.polarTheta * Math.PI * 2);
    return Math.hypot(thisX - otherX, thisY - otherY) <= 0.2;
  }

  withPolarCoordinates(r: number, theta: number): MandolinChopAccentsSetting {
    return new MandolinChopAccentsSetting({
      ...this.serialize(),
      r: Math.round(r * 1000) / 1000,
      t: Math.round(theta * 1000) / 1000,
    });
  }

  _powersForLandscapeMap = new Map<CircularStrumLandscape, number[]>();
  powersForLandscape<T extends number[]>(landscape: CircularStrumLandscape<T>) {
    if (!this._powersForLandscapeMap.has(landscape)) {
      this._powersForLandscapeMap.set(
        landscape,
        interpolateBetweenPolesInPolarChart(
          landscape.centerPowers,
          landscape.poles,
          this.polarR,
          this.polarTheta
        )
      );
    }
    return this._powersForLandscapeMap.get(landscape) as T;
  }

  closestPresetForLandscape<T extends number[]>(
    landscape: CircularStrumLandscape<T>
  ): CircularStrumLandscape<T>['presets'][number] {
    const presets = landscape.presets;
    const thisXY = polarToCartesian(this.polarR, this.polarTheta);
    const closestPreset = presets.reduce(
      (closestPreset, preset) => {
        const presetXY = polarToCartesian(...preset.position);
        const distance = Math.hypot(thisXY[0] - presetXY[0], thisXY[1] - presetXY[1]);
        return closestPreset.distance > distance
          ? { distance: distance, preset, presetXY }
          : closestPreset;
      },
      { distance: Infinity, preset: presets[0], presetXY: [0, 0] }
    );
    assert(closestPreset.preset, 'Could not find closest preset');
    return closestPreset.preset;
  }
}

function polarToCartesian(r: number, theta: number): [number, number] {
  return [r * Math.cos(theta * Math.PI * 2), r * Math.sin(theta * Math.PI * 2)];
}
