import { Meteor } from 'meteor/meteor';
import { Tracker } from 'meteor/tracker';
import { autoModulateNewRep } from '@/auto-tools/autoModulateNewRep';
import { autoSpeedupNewRep } from '@/auto-tools/autoSpeedupNewRep';
import CountPlayer from '@/band/instruments/count/CountPlayer';
import { Crnt } from '@/Crnt';
import type { PlayerPosition } from '@/playback/PlayerPosition';
import UserPreferences from '@/user/UserPreferences';

export class PlayerState {
  // playbackState: PlaybackState = 'loading';
  playing = false;
  delayedStartAt?: number;
  countIn?: CountInState;
  startingPosition?: PlayerPosition;
  playbackPosition?: PlayerPosition;

  // experimental
  footOut?: boolean;

  // briefly-set control variables
  songOver?: boolean;
  startOfAnotherRep?: boolean;

  // other state variables
  maxRound?: number;

  reset(): void {
    this.playing = false;
    this.delayedStartAt = undefined;
    this.countIn = undefined;
    this.startingPosition = undefined;
    this.playbackPosition = undefined;
    this.songOver = undefined;
    this.startOfAnotherRep = undefined;
    this.footOut = undefined;
  }

  getRound(): number | undefined {
    return this.playbackPosition?.round || this.startingPosition?.round;
  }

  setRound(value: number): void {
    const pos = this.startingPosition || this.playbackPosition;
    if (pos) {
      pos.setRound(value);
      pos.song.recalculateSectionsToSkip({
        currentSection: pos.measure.position.section,
        currentRound: pos.round || 0,
        forceLastTime: this.footOut,
      });
    }
  }

  setFootOut(footOut: boolean): void {
    this.footOut = footOut;
    const pos = this.startingPosition || this.playbackPosition;
    if (pos) {
      pos.song.recalculateSectionsToSkip({
        currentSection: pos.measure.position.section,
        currentRound: pos.round || this.maxRound || 1,
        forceLastTime: this.footOut,
      });
      pos.song.band.regeneratePlans();
    }
  }

  setPositionWithChartPosition(pos?: ChartPosition): void {
    Tracker.nonreactive(() => {
      this.reset();
      if (!pos) return;
      const song = Crnt.songs[pos.song];
      if (!song) return;
      this.playbackPosition = song.linearized.lookupByChartPosition(pos);
    });
  }

  setStartingPosition(pos: ChartPositionWithinSong & { song?: number }): void {
    const oldRound = this.playbackPosition?.round ?? this.startingPosition?.round;
    const oldSongIndex = this.playbackPosition?.song.index();
    this.reset();
    const song = Crnt.songs[pos.song || 0];
    if (!song) throw new Error('Tried to set starting position with out-of-bounds songIndex');
    song.linearized.unskipSection(pos.section);
    this.startingPosition = song.linearized.lookupByChartPosition(pos);
    const sameSongIndex = typeof oldSongIndex == 'number' && oldSongIndex === pos.song;
    this.startingPosition.round ??= (sameSongIndex && oldRound) || 0;
    // recalculate STS so starting section can be included if needed
    song.recalculateSectionsToSkip({
      currentSection: this.startingPosition.measure.position.section,
      currentRound: this.startingPosition.round || 0,
    });
  }

  advance(): void {
    if (!this.playing) return;

    this.startOfAnotherRep = false;

    if (this.countIn) {
      this.countIn.index++;
      if (this.countIn.index <= CountPlayer.beatCount() - 1) {
        const label = CountPlayer.labelForBeat(this.countIn.index);
        if (label) this.countIn.label = label;
        return;
      } else {
        this.countIn = undefined;
        // and move on to startingPosition check
      }
    }

    if (this.startingPosition) {
      this.playbackPosition = this.startingPosition.song.linearized.getValidSequencerPosition(
        this.startingPosition
      );
      this.playbackPosition.round ??= 0;
      this.startingPosition = undefined;
      if (!Meteor.isDevelopment) {
        this.playbackPosition.song.band.regeneratePlans(); // Mix it up... REQUIRED for auto-modulation to work
      }
      return;
    }

    if (this.playbackPosition) {
      const song = this.playbackPosition.song;
      const afReps =
        (song.prefs.autoFinish.enabled() || Crnt.list()?.autoAdvanceEnabled()) &&
        song.prefs.autoFinish.reps();
      const medleyReps = song.medleyPrefs?.reps();
      this.maxRound =
        typeof medleyReps == 'number'
          ? medleyReps - 1
          : typeof afReps == 'number'
            ? afReps - 1
            : undefined;
      if (this.playbackPosition.beatInMeasure == this.playbackPosition.measure.beats.length - 1) {
        song.recalculateSectionsToSkip({
          currentRound: this.playbackPosition.round,
          currentSection: this.playbackPosition.measure.position.section,
          forceLastTime: this.footOut,
        });
      }
      const nextPosition = song.linearized.getSequencerPositionAdvancedByOneBeat(
        this.playbackPosition
      );
      if (nextPosition) {
        this.playbackPosition = nextPosition;
      } else {
        const nextRound = (this.playbackPosition.round || 0) + 1;
        if ((typeof this.maxRound == 'number' && nextRound > this.maxRound) || this.footOut) {
          if (medleyReps && song.index() < Crnt.songs.length - 1) {
            const nextSong = Crnt.songs[song.index() + 1];
            nextSong?.recalculateSectionsToSkip({ currentRound: 0 });
            this.playbackPosition = nextSong?.linearized.firstValidPosition({ round: 0 });
          } else {
            this.songOver = true;
            this.playbackPosition = undefined;
          }
          this.footOut = false;
        } else {
          song.recalculateSectionsToSkip({
            currentRound: nextRound,
          });
          if (song.linearized.skippedSections.length == song.sections.length) {
            this.songOver = true;
            this.playbackPosition = undefined;
          } else {
            this.startOfAnotherRep = true;
            autoSpeedupNewRep(song);
            autoModulateNewRep(song);
            song.band.regeneratePlans(); // Mix it up... REQUIRED for auto-modulation to work
            const nextRepStart = song.linearized.firstValidPosition({
              round: nextRound,
            });
            if (UserPreferences.get('countInEnabled') && UserPreferences.get('countInEveryTime')) {
              this.countIn = { index: 0, label: '' };
              this.startingPosition = nextRepStart;
              this.playbackPosition = undefined;
            } else {
              this.playbackPosition = nextRepStart;
            }
          }
        }
      }
    }
  }

  getInfoForDisplay(): PlayerStateInfo {
    return {
      playing: this.playing,
      footOut: this.footOut,
      countIn: this.countIn
        ? (JSON.parse(JSON.stringify(this.countIn)) as typeof this.countIn)
        : undefined,
      startingPosition: this.startingPosition?.toChartPosition(),
      position: this.playbackPosition?.toChartPosition(),
    };
  }
}
