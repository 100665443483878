import { strict as assert } from 'assert';
import AudioManager from '@/audio/engine/AudioManager';
import type { GuitarChord } from '@/band/instruments/guitar/GuitarChord';
import type { GuitarIntent } from '@/band/instruments/guitar/GuitarIntent';
import type { PlayerInstruction } from '@/band/instruments/PlayerInstruction';
import { PlayerInstructionsArray } from '@/band/instruments/PlayerInstructionsArray';

type ZeroThroughFive = 0 | 1 | 2 | 3 | 4 | 5;

export function makeInstructionsFromGuitarIntent(
  intent: GuitarIntent,
  guitarChord: GuitarChord
): PlayerInstruction[] {
  const instructions = new PlayerInstructionsArray('guitar');

  if (intent.action == 'pick') {
    if ('note' in intent) {
      instructions.muteUnmatchedStrings(guitarChord.strings, { fadeDuration: 0.12 });
      const string = guitarChord.strumSpec.rootFifthSixth[intent.note == 'fifth' ? 1 : 0];
      const fret = guitarChord.strings[6 - string];
      if (fret && fret != '_') {
        const sample = `g${singleNoteMask(string, fret)} S`;
        instructions.playSample(sample, { db: intent.db });
      }
    } else {
      const { string, fret } = intent;
      assert(string >= 1 && string <= 6);
      const fretsArray = guitarChord.strings.split('');
      fretsArray[6 - string] = '_';
      const mask = fretsArray.join('');
      instructions.muteUnmatchedStrings(mask, { offset: -0.02, fadeDuration: 0.15 });
      const sample = `g${singleNoteMask(string, fret.toString(16))} S`;
      instructions.playSample(sample, { db: intent.db });
    }
  }

  if (intent.action == 'crosspick') {
    const string = guitarChord.strumSpec.crossPickPattern[intent.noteInSeries - 1] || NaN;
    if (!isNaN(string)) {
      instructions.muteUnmatchedStrings(guitarChord.strings, { fadeDuration: 0.12 });
      const fret = guitarChord.strings[6 - string];
      if (fret && fret != '_') {
        const sample = `g${singleNoteMask(string, fret)} S`;
        instructions.playSample(sample, { db: intent.db });
      }
    }
  }

  if (
    intent.action == 'strum' ||
    intent.action == 'chop' ||
    intent.action == 'muted-strum' ||
    intent.action == 'chick'
  ) {
    const stringDbs = guitarChord.strumSpec.stringVolumes.slice() as SixNumbers;

    const bias =
      guitarChord.strumSpec.bias[intent.bias] ??
      (intent.bias == 'jazzD'
        ? guitarChord.strumSpec.bias['bass']
        : intent.bias == 'jazzU'
          ? guitarChord.strumSpec.bias['mid']
          : undefined);
    assert(bias, `No bias found for ${intent.bias}, shape ${guitarChord.shapeStrings}`);

    for (let i = <ZeroThroughFive>0; i < 6; i++) {
      if (isNaN(stringDbs[i])) continue;
      if (isNaN(bias[i])) {
        stringDbs[i] = NaN;
      } else if (bias[i] > Math.ceil(intent.spread)) {
        stringDbs[i] = NaN;
      } else if (bias[i] > Math.floor(intent.spread)) {
        stringDbs[i] =
          intent.spread % 1 > 0.25
            ? stringDbs[i] - 26 * (1 - Math.log10(1 + 9 * (intent.spread % 1)))
            : NaN;
      }
    }

    if (intent.action == 'strum' || intent.action == 'muted-strum') {
      // calculate strings to use
      const direction = intent.action == 'muted-strum' ? 'D' : intent.direction;
      const strings = guitarChord.strings;

      instructions.muteUnmatchedStrings(
        intent.action == 'muted-strum' ? '------' : guitarChord.strings,
        {
          fadeDuration: intent.action == 'muted-strum' ? 0.2 : 0.12,
          omitAboveTpm: intent.omitAboveTpm,
        }
      );
      let first = NaN;
      let last = NaN;
      for (let i = <ZeroThroughFive>0; i < 6; i++) {
        if (!isNaN(stringDbs[i])) {
          if (isNaN(first)) first = i;
          last = i;
        }
      }

      const stringToStringDelay = intent.stringToStringDelay ?? 0.003;

      for (
        let strumIndex = 0, i = (direction == 'U' ? last : first) as ZeroThroughFive;
        direction == 'U' ? i >= first : i <= last;
        direction == 'U' ? i-- : i++, strumIndex++
      ) {
        if (isNaN(stringDbs[i])) continue;
        if (strings[i] == '_' && (i == first || i == last)) continue;

        const offsets = {
          offset:
            strumIndex * stringToStringDelay +
            ((direction == 'D' ? first == 0 : last == 5) && strumIndex > 0
              ? Math.min(stringToStringDelay, 0.01)
              : 0) +
            Math.random() * 0.001,
          beatOffset: 0,
        };

        let sample =
          strings[i] == '_'
            ? `g${singleNoteMask(6 - i, 'F')} F`
            : `g${singleNoteMask(6 - i, strings.charAt(i))} Z`;
        if (intent.action == 'muted-strum') sample = sample.replace(/Z/g, 'M');
        sample = randomAlt(sample);
        instructions.playSample(sample, {
          db: stringDbs[i] + intent.db + (intent.action == 'muted-strum' ? -2 : 1),
          ...offsets,
          omitAboveTpm: intent.omitAboveTpm,
        });
      }
    }

    if (intent.action == 'chop') {
      const strings = guitarChord.strings;
      const mask = stringDbs.map((s) => (isNaN(s) ? '+' : 'X')).join('');
      instructions.muteUnmatchedStrings('------', { fadeDuration: 0.15 });
      const [first, last] = [mask.indexOf('X'), mask.lastIndexOf('X')];
      const stringToStringDelay =
        intent.stringToStringDelay ?? 0.001 + Math.min(0.001, intent.duration * 0.005);
      for (let strumIndex = 0, i = <ZeroThroughFive>first; i <= last; i++, strumIndex++) {
        if (isNaN(stringDbs[i])) continue;
        const offset =
          strumIndex * stringToStringDelay +
          (first == 0 && strumIndex > 0 ? stringToStringDelay : 0);
        const beatOffset =
          intent.duration > 0 ? (-last + strumIndex) * Math.min(0.002, intent.duration * 0.02) : 0;
        const thisDb = stringDbs[i] + intent.db;
        if (strings[i] == '_') {
          instructions.playSample(`g${singleNoteMask(6 - i, 'F')} F`, {
            db: thisDb,
            offset,
            beatOffset,
          });
        } else {
          const frets = singleNoteMask(6 - i, strings.charAt(i));
          if (intent.duration > 0) {
            const zedSample = randomAlt(`g${frets} Z`);
            instructions.playSample(zedSample, {
              db: thisDb,
              offset,
              beatOffset,
            });
            const dampenOffset = intent.duration < 0.1 ? 0.03 : 0;
            instructions.muteSample(zedSample, {
              offset: dampenOffset,
              beatOffset: intent.duration,
              fadeDuration: 0.03,
            });
            instructions.playSample(`g${frets} L`, {
              db: thisDb - 7,
              offset: dampenOffset - 0.01,
              beatOffset: intent.duration,
              attentuateBasedOnBeatOffset: true,
            });
          } else {
            instructions.playSample(`g${frets} C`, {
              db: intent.db - 2,
              offset: strumIndex * 0.001 + (first == 0 && strumIndex > 0 ? 0.001 : 0),
            });
          }
        }
        strumIndex += 1;
      }
      if (intent.duration == 0) {
        instructions.push(foley(mask, 'D', intent.db * 2 + 8));
      }
    }

    if (intent.action == 'chick') {
      const mask = stringDbs.map((s) => (isNaN(s) ? '+' : 'X')).join('');
      instructions.muteUnmatchedStrings('------', { fadeDuration: 0.15 });
      instructions.push(foley(mask, 'D', intent.db + 12));
    }
  }

  // Volume adjustment
  instructions.forEach((inst) => {
    if ('db' in inst && typeof inst.db == 'number') inst.db -= 2; // 70%
  });

  return instructions;
}

function singleNoteMask(string: number, fret: string | number) {
  return `${'+'.repeat(6 - string)}${fret}${'+'.repeat(string - 1)}`;
}

function randomAlt(sample: string): string {
  if (sample.endsWith('Z') && AudioManager.sampleLibrary.has(sample + '^')) {
    const twoAlts = AudioManager.sampleLibrary.has(sample + '^^');
    sample =
      sample +
      (Math.random() > (twoAlts ? 0.666 : 0.5)
        ? twoAlts && Math.random() > 0.5
          ? '^^'
          : '^'
        : '');
  }
  return sample;
}

function foley(mask: string, direction: string, db: number, offset = 0): PlayerInstruction {
  const foley = mask
    .replace('XXXXXX', '+XXXX+')
    .replace('+XXXXX', '+XXXX+')
    .replace('XXXXX+', 'XXXX++')
    .replace('+XX+++', 'XX++++')
    .replace('X+X', 'XXX')
    .replace(/X/g, mask.indexOf('X') == mask.lastIndexOf('X') ? 'F' : direction);
  return {
    type: 'play',
    sample: `g${foley} F`,
    channel: 'guitar',
    db: db - 4,
    offset,
    beatOffset: 0,
  };
}
