import { lerp } from '@/utilities/lerp';

export type GradientPoint = Readonly<[at: number, value: number]>;
export type Gradient = Readonly<[GradientPoint, GradientPoint, ...GradientPoint[]]>;

/**
 * Gradient interpolation
 * @param t A value within the range of the gradient
 * @param gradientPoints A list of gradient points, each of which is a tuple of [at, value]
 * @returns The interpolated value
 */
export function interpolateAcrossGradient(t: number, gradientPoints: Gradient) {
  const firstPoint = gradientPoints[0];
  const lastPoint = gradientPoints[gradientPoints.length - 1] as GradientPoint;
  // If t is 0 or 1, the value is the first or last gradient point's value
  if (t <= firstPoint[0]) {
    return firstPoint[1];
  } else if (t >= lastPoint[0]) {
    return lastPoint[1];
  }

  // Find the two gradient points that t is between
  let leftPoint = firstPoint;
  let rightPoint = lastPoint;
  for (let i = 1; i < gradientPoints.length; i++) {
    const point = gradientPoints[i] as GradientPoint;
    if (point[0] >= t) {
      rightPoint = point;
      break;
    }
    leftPoint = point;
  }

  // Calculate the value between those two gradient points
  return lerp(leftPoint[1], rightPoint[1], (t - leftPoint[0]) / (rightPoint[0] - leftPoint[0]));
}
