import { FlowRouter } from 'meteor/ostrio:flow-router-extra';

Template.refundsPage.events({
  'click .js-closeWindow': function (event, instance) {
    window.close();
  },
});

Template.refundsPage.helpers({
  inCheckout() {
    return !!FlowRouter.getQueryParam('checkout');
  },
});
