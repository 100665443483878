import type { Band } from '@/band/Band';
import type { PlayerInstruction } from '@/band/instruments/PlayerInstruction';
import type { LinearizedMeasure } from '@/chart/LinearizedMeasure';
import UserPreferences from '@/user/UserPreferences';

let soundCounter = 0;

export class ClickTrack {
  readonly band: Band;

  constructor(band: Band) {
    this.band = band;
  }

  enabled() {
    return (
      UserPreferences.get('clickFeaturePreview') &&
      UserPreferences.get('clickEnabled') &&
      !this.band.song.editMode()
    );
  }

  subdivisions(timeSignature: TimeSignature) {
    const prefKey = prefKeysPerTimeSignature[timeSignature];
    return UserPreferences.get(prefKey).toString();
  }

  volume() {
    return UserPreferences.get('clickVolume');
  }

  sound() {
    return UserPreferences.get('clickSound');
  }

  mutingBand() {
    return UserPreferences.get('clickMuteBand');
  }

  depend() {
    void this.enabled();
    void this.subdivisions('3/4');
    void this.subdivisions('4/4');
    void this.subdivisions('6/8');
    void this.subdivisions('9/8');
    void this.volume();
    void this.sound();
    void this.mutingBand();
  }

  addClickToMeasures(
    measures: readonly LinearizedMeasure[],
    context: { tpm: number; swing: SwingOption }
  ) {
    if (!this.enabled()) return;

    const swing = context.swing.formula(context.tpm);
    soundCounter = 0;
    measures.forEach((measure) => {
      const instructions = this.generateClickInstructionsToApplyToMeasure({
        songTimeSignature: this.band.timeSignature(),
        measureTimeSignature: measure.timeSignature,
        swing,
      });
      instructions.forEach((inst, i) => {
        const beat = measure.beats[i];
        if (!beat) return;
        beat.playerInstructions.click = inst || [];
      });
    });
  }

  generateClickInstructionsToApplyToMeasure({
    songTimeSignature,
    measureTimeSignature,
    swing,
  }: {
    songTimeSignature: TimeSignature;
    measureTimeSignature: TimeSignature;
    swing?: number;
  }) {
    swing ??= this.band.swing().formula(this.band.song.tpm());
    const baseSample = this.sound();
    const subdivisions = this.subdivisions(songTimeSignature);
    const [beatsToClick, playEighths, swingEighths] =
      clickFreqDefinitions[measureTimeSignature][subdivisions] ??
      clickFreqDefaults[measureTimeSignature];
    const result = Array.from({ length: measureBeatLengths[measureTimeSignature] });
    for (const beatIndex of beatsToClick) {
      const firstBeatInMeasure =
        measureTimeSignature == '3/4' ? beatIndex % 3 === 0 : beatIndex == 0;
      result[beatIndex] = [
        clickInstruction(clickSample({ soundCounter, firstBeatInMeasure, baseSample }), {
          db: 8,
        }),
      ];
      if (playEighths) {
        result[beatIndex]?.push(
          clickInstruction(
            clickSample({ soundCounter, firstBeatInMeasure, baseSample, upstroke: true }),
            {
              db: 6,
              beatOffset: swingEighths ? 0.5 + 0.25 * swing : 0.5,
            }
          )
        );
      }
    }
    return result;
  }
}

const prefKeysPerTimeSignature = {
  '4/4': 'clickFreqStd',
  '3/4': 'clickFreqWaltz',
  '6/8': 'clickFreqJig',
  '9/8': 'clickFreqSlipJig',
} as const;

const measureBeatLengths = {
  '4/4': 4,
  '3/4': 6,
  '6/8': 6,
  '9/8': 9,
};

const clickFreqDefinitions: Record<
  TimeSignature,
  Record<string, [beats: number[], eighths?: boolean, swing?: boolean]>
> = {
  '4/4': {
    '1': [[0]],
    '2': [[0, 2]],
    '2o': [[1, 3]],
    '4': [[0, 1, 2, 3]],
    '8': [[0, 1, 2, 3], true],
    '8s': [[0, 1, 2, 3], true, true],
  },
  '3/4': {
    '1': [[0, 3]],
    '2e': [[0, 2, 3, 5]],
    '2o': [[1, 2, 4, 5]],
    '3': [[0, 1, 2, 3, 4, 5]],
    '6': [[0, 1, 2, 3, 4, 5], true],
    '6s': [[0, 1, 2, 3, 4, 5], true, true],
  },
  '6/8': {
    '1': [[0]],
    '2': [[0, 3]],
    '3': [[0, 2, 4]],
    '6': [[0, 1, 2, 3, 4, 5]],
  },
  '9/8': {
    '1': [[0]],
    '3': [[0, 3, 6]],
    '9': [[0, 1, 2, 3, 4, 5, 6, 7, 8]],
  },
};

const clickFreqDefaults: Record<TimeSignature, [number[]]> = {
  '4/4': [[0, 4]],
  '3/4': [[0, 2, 4]],
  '6/8': [[0, 3]],
  '9/8': [[0, 3, 6]],
};

function clickSample({
  soundCounter,
  firstBeatInMeasure,
  baseSample,
  upstroke,
}: {
  soundCounter: number;
  baseSample: string;
  firstBeatInMeasure: boolean;
  upstroke?: boolean;
}) {
  if (baseSample == 'mechanical') {
    baseSample = `mechanical${(soundCounter % 4) + 1}`;
    soundCounter++;
  } else if (baseSample == 'quartz' || baseSample == 'boop') {
    baseSample = `${baseSample}${firstBeatInMeasure && !upstroke ? 'D' : 'U'}`;
  } else {
    baseSample = `${baseSample}U`;
  }
  return baseSample;
}

function clickInstruction(
  sample: string,
  {
    db,
    beatOffset,
  }: {
    db: number;
    beatOffset?: number;
  }
): PlayerInstruction {
  return {
    type: 'play',
    channel: 'click',
    sample,
    db,
    beatOffset,
  };
}
