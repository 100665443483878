import * as Sentry from '@sentry/browser';
import { strict as assert } from 'assert';
import { Meteor } from 'meteor/meteor';
import { Mongo } from 'meteor/mongo';
import { ReactiveVar } from 'meteor/reactive-var';
import { Tracker } from 'meteor/tracker';
import type { BandPreset } from '@/band/presets/BandPreset';
import { checkOfflineStorageAvailable } from '@/local-db/checkOfflineStorageAvailable';
import { GroundedCollection } from '@/local-db/GroundedCollection';
import localStorageDBNames from '@/local-db/localStorageDBNames';
import { callServerMethodWithoutRetry } from '@/utilities/callServerMethodWithoutRetry';
import { unique } from '@/utilities/unique';
import waitUntilReactive from '@/utilities/waitUntilReactive';

type PendingUserBandPresetRecord = Partial<SerializedBandPreset> & {
  _id: string; // matches preset _id in user record
  userId: string;
  delete?: true;
};

let PendingBandPresetRecords:
  | Mongo.Collection<PendingUserBandPresetRecord>
  | GroundedCollection<PendingUserBandPresetRecord> = new Mongo.Collection(null); // this *must* be var, not let!

export class UserBandPresetManager {
  static getPresetRecords(): SerializedBandPreset[] {
    const userId = Meteor.userId();
    if (userId) {
      const liveRecords = Meteor.user('bandPresets')?.bandPresets || [];
      const pendingRecords = PendingBandPresetRecords.find({ userId }).fetch();
      const recordIdsPendingDeletion = pendingRecords.filter((r) => r.delete).map((r) => r._id);
      const consolidatedRecordIds = unique(
        [...liveRecords, ...pendingRecords.filter((r) => !r.delete)]
          .map((r) => r._id)
          .filter((id) => !recordIdsPendingDeletion.includes(id))
      );
      const consolidatedRecords = consolidatedRecordIds.map(
        (id) =>
          <SerializedBandPreset>{
            ...liveRecords.find((p) => p._id == id),
            ...pendingRecords.find((p) => p._id == id),
          }
      );
      return consolidatedRecords;
    } else {
      return [];
    }
  }

  static findById(id: string) {
    return this.getPresetRecords().find((r) => r._id == id);
  }

  static createPreset({
    name,
    timeSignature,
    band,
  }: {
    name: string;
    timeSignature: TimeSignature;
    band: Partial<SerializedBandSettings>;
  }): string {
    assert(/\S/.test(name), 'Non-whitespace preset name');
    const userId = Tracker.nonreactive(() => Meteor.userId());
    assert(userId, "Can't create new preset if not logged in!");
    const newPresetId = PendingBandPresetRecords.insert({ userId, name, timeSignature, band });
    return newPresetId;
  }

  static deletePreset(presetId: string) {
    const userId = Tracker.nonreactive(() => Meteor.userId());
    assert(userId, "Can't delete preset if not logged in!");
    PendingBandPresetRecords.upsert({ _id: presetId }, { _id: presetId, userId, delete: true });
  }

  static updatePreset(
    preset: BandPreset,
    properties: { band?: SerializedBandPreset['band']; name?: string }
  ) {
    const userId = Tracker.nonreactive(() => Meteor.userId());
    assert(userId, "Can't update preset if not logged in!");
    const record = {
      userId,
      _id: preset.overrideRecordId ?? preset.id,
      builtInId: preset.default ? preset.id : undefined,
    };
    const selector = JSON.parse(JSON.stringify(record)) as typeof record;
    PendingBandPresetRecords.upsert(selector, {
      $set: {
        ...selector,
        timeSignature: preset.timeSignature,
        ...properties,
      },
    });
  }
}

void checkOfflineStorageAvailable().then((available) => {
  if (available) {
    const livePUC = PendingBandPresetRecords;
    PendingBandPresetRecords = new GroundedCollection(localStorageDBNames.usersBandPresets, {
      collection: livePUC,
    });
  } else {
    // No offline support? No problem.
  }
});

Meteor.defer(function startOfflineUpdateSyncWhenReady() {
  const checkReady = () => {
    if ('loaded' in PendingBandPresetRecords && !PendingBandPresetRecords.loaded()) return false;
    return PendingBandPresetRecords.find().count() > 0;
  };
  void waitUntilReactive(checkReady).then(startOfflineUpdateSync);
});

const startOfflineUpdateSync = () =>
  Tracker.nonreactive(() => {
    // otherwise it gets shut down by Tracker further up the chain
    const syncInProgress = new ReactiveVar(false);
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    Tracker.autorun(async function songLibraryUpdateAutorun() {
      if (syncInProgress.get()) return;
      if (!Meteor.status().connected) return; // can't do this offline!
      const userId = Meteor.userId();
      if (!userId) return; // don't do this if not logged in
      const updateRecords = PendingBandPresetRecords.find({ userId }).fetch();
      if (updateRecords.length === 0) return;
      syncInProgress.set(true);
      try {
        await Promise.all(
          updateRecords.map(async (record) => {
            if (record.delete) {
              await callServerMethodWithoutRetry('user.bandPresets.delete', {
                presetId: record._id,
              });
            } else {
              const strippedRecord = JSON.parse(
                JSON.stringify({ ...record, userId: undefined })
              ) as typeof record;
              await callServerMethodWithoutRetry('user.bandPresets.upsert', strippedRecord);
            }
            PendingBandPresetRecords.remove(record); // will only remove if all fields (still) match
          })
        );
        Meteor.setTimeout(() => syncInProgress.set(false), 2000);
      } catch (error) {
        Meteor.setTimeout(() => syncInProgress.set(false), 20000);
        console.error(error);
        Sentry.captureException(error);
        Bert.alert('Error while syncing user data to the cloud. Will try again in 20 seconds.');
      }
    });
  });
