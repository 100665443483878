import * as Sentry from '@sentry/browser';
import { strict as assert } from 'assert';
import { Meteor } from 'meteor/meteor';
import type { Mixer } from '@/audio/engine/base/Mixer';
import type { Playback } from '@/audio/engine/base/Playback';
import type { PlayProps } from '@/audio/engine/base/PlayProps';
import type { SampleDefinition } from '@/audio/engine/base/Sample';
import type { SampleLibrary } from '@/audio/engine/base/SampleLibrary';
import { SchedulingClock } from '@/audio/engine/clock/SchedulingClock';
import { FMBMixer } from '@/audio/engine/fmb/FMBMixer';
import { FMBSampleLibrary } from '@/audio/engine/fmb/FMBSampleLibrary';
import { FMBSystem } from '@/audio/engine/fmb/FMBSystem';
import { getCordovaReportedLatency } from '@/audio/engine/getCordovaReportedLatency';
import { WebAudioClicker } from '@/audio/engine/web/WebAudioClicker';
import WebAudioContextManager from '@/audio/engine/web/WebAudioContextManager';
import { WebAudioMixer } from '@/audio/engine/web/WebAudioMixer';
import { WebAudioSampleLibrary } from '@/audio/engine/web/WebAudioSampleLibrary';
import { getLocalStorageSafe } from '@/utilities/safe-local-storage';
import wait from '@/utilities/wait';

export type ChannelDefinition = {
  id: string;
  polyphonyLimit: number;
};

const webAudioPrefSet = getLocalStorageSafe('sm.audio-mode') == 'web-audio-2';
const fmbPrefSet = getLocalStorageSafe('sm.audio-mode') == 'fmb';
const iPadOnMac = navigator.platform === 'MacIntel' && navigator.maxTouchPoints === 0;
const defaultMode =
  Meteor.isCordova &&
  !/Android/i.test(navigator.userAgent) &&
  !/OS 1[1-3]_/.test(navigator.userAgent) &&
  !iPadOnMac
    ? 'cordova'
    : 'web-audio';

class AudioManager {
  _mode: 'web-audio' | 'cordova' =
    /Android/i.test(navigator.userAgent) || iPadOnMac
      ? 'web-audio'
      : fmbPrefSet
        ? 'cordova'
        : webAudioPrefSet
          ? 'web-audio'
          : defaultMode;
  get mode(): 'web-audio' | 'cordova' {
    return this._mode;
  }
  private set mode(value: 'web-audio' | 'cordova') {
    this._mode = value;
    Sentry.getCurrentScope().setTag('audio-mode', this.mode);
  }

  fallbackToWebAudio() {
    if (this.mode == 'cordova') {
      this.mode = 'web-audio';
    }
  }

  private _audioContextManager?: WebAudioContextManager;
  private _fmbSystem?: FMBSystem;
  private _sampleLibrary?: SampleLibrary;
  private _mixer?: Mixer;
  private _clock?: SchedulingClock;

  private channelDefinitions: ChannelDefinition[] = [];
  defineChannels(channels: ChannelDefinition[]): void {
    this.channelDefinitions = channels;
  }

  private sampleDefinitions: SampleDefinition[] = [];
  defineSamples(samples: SampleDefinition[]): void {
    this.sampleDefinitions = samples;
  }

  async initialize(): Promise<void> {
    Sentry.getCurrentScope().setTag('audio-mode', this.mode);
    Sentry.addBreadcrumb({
      category: 'audio',
      message: `Initializing AudioManager with mode = ${this.mode}`,
    });
    assert(this.channelDefinitions.length > 0, 'Channels not defined before AudioManager init');
    assert(this.sampleDefinitions.length > 0, 'Samples not defined before AudioManager init');
    if (this.mode == 'cordova') {
      await new Promise<void>((resolve) => {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        document.addEventListener('deviceready', async () => {
          try {
            const available = await FMBSystem.availablePromise;
            if (!available) throw new Error('FMB audio engine unavailable');
            this._fmbSystem = new FMBSystem();
            await this._fmbSystem.initialize({ channels: this.channelDefinitions });
            this._mixer = new FMBMixer();
            this._mixer.createChannels(this.channelDefinitions);
            this._sampleLibrary = new FMBSampleLibrary();
            await this._sampleLibrary.prepare(this.sampleDefinitions);
          } catch (err) {
            console.error(err);
            console.debug(`Falling back to Web Audio API; no Cordova plugin available`);
            this.mode = 'web-audio';
          }
          resolve();
        });
      });
    }
    if (this.mode == 'web-audio') {
      this._audioContextManager = await WebAudioContextManager.create(); // initialize AudioContext
      this._mixer = new WebAudioMixer(this._audioContextManager.context);
      this._mixer.createChannels(this.channelDefinitions);
      this._sampleLibrary = new WebAudioSampleLibrary();
      await this._sampleLibrary.prepare(this.sampleDefinitions);
    }
  }

  async teardown(): Promise<void> {
    await Promise.all(this._mixer?.allPlaybacks().map((p) => p.destroy()) ?? []);
    await this._fmbSystem?.teardown();
    this._fmbSystem = undefined;
    await this._audioContextManager?.stopEngine();
    await this._audioContextManager?.context.close();
    this._audioContextManager = undefined;
    this._mixer = undefined;
    this._sampleLibrary = undefined;
  }

  get mixer(): Mixer | undefined {
    return this._mixer;
  }

  get sampleLibrary(): SampleLibrary {
    assert(this._sampleLibrary, 'AudioManager.sampleLibrary undefined');
    return this._sampleLibrary;
  }

  async gonnaPlay(): Promise<void> {
    this.cancelWaitForPlaybackToFinish();
    this.currentState = 'starting';
    this.clock.start();
    try {
      if (this.mode == 'cordova') {
        await (this._fmbSystem ?? this._audioContextManager)?.startEngine();
        this.currentState = 'running';
      } else {
        if (this._audioContextManager?.context.state !== 'running') {
          await this._audioContextManager?.startEngine();
        } else {
          // Do a background check for non-running context, just in case
          const time1 = this._audioContextManager.context.currentTime;
          void wait(50).then(() => {
            if (time1 != this._audioContextManager?.context.currentTime) {
              Sentry.addBreadcrumb({
                category: 'audio',
                message: `AudioContext resumed in background`,
                level: 'debug',
              });
              void this._audioContextManager?.startEngine();
            }
          });
        }
        this.currentState = 'running';
      }
    } catch (err) {
      this.currentState = 'stopped';
      console.error(err);
      throw err;
    }
  }

  async gonnaStop(goingToBackground = false): Promise<void> {
    if (this.mode == 'cordova' && !goingToBackground) return; // We don't stop the FMOD engine while app is active
    if (this.currentState != 'running') return;
    this.currentState = 'stop-pending';
    const shouldContinue = await this.waitForPlaybackToFinish();
    if (!shouldContinue) return;
    if (this.currentState != 'stop-pending') return;
    this.currentState = 'stopping';
    if (this.clock) this.clock.stop();
    void (this._fmbSystem ?? this._audioContextManager)?.stopEngine();
    this.currentState = 'stopped';
  }

  private currentState: 'starting' | 'stop-pending' | 'stopping' | 'running' | 'stopped' =
    'stopped';
  private waitForPlaybackToFinishPromise?: Promise<boolean>;
  private waitForPlaybackToFinishResolver?: (value: boolean | PromiseLike<boolean>) => any;
  private waitForPlaybackToFinishTimeout?: number;
  waitForPlaybackToFinish(): Promise<boolean> {
    return (this.waitForPlaybackToFinishPromise ??= new Promise((resolve) => {
      this.waitForPlaybackToFinishResolver = resolve;
      if (!this.mixer) {
        resolve(true);
        return;
      }
      this.waitForPlaybackToFinishTimeout = window.setInterval(() => {
        if (this.mixer?.allPlaybacks().length === 0) {
          window.clearInterval(this.waitForPlaybackToFinishTimeout);
          this.waitForPlaybackToFinishTimeout = window.setTimeout(() => resolve(true), 1000);
        }
      }, 500);
    }));
  }

  cancelWaitForPlaybackToFinish() {
    this.waitForPlaybackToFinishResolver?.(false);
    this.waitForPlaybackToFinishPromise = undefined;
    window.clearInterval(this.waitForPlaybackToFinishTimeout);
    window.clearTimeout(this.waitForPlaybackToFinishTimeout);
  }

  playSample(args: Partial<PlayProps> & { id: string }): Playback {
    // purely for convenience
    assert(this._sampleLibrary, 'AudioManager.sampleLibrary undefined');
    return this._sampleLibrary.playSample(args);
  }

  currentTime(): number {
    return this._fmbSystem?.currentTime ?? this._audioContextManager?.context.currentTime ?? -1;
  }

  get latency(): number {
    if (this.mode == 'cordova') return getCordovaReportedLatency() || 0;
    const ctx = this._audioContextManager?.__context;
    if (!ctx) return 0;
    if (!/Firefox/i.test(navigator.userAgent)) {
      if ((('outputLatency' in ctx) as unknown) && ctx.outputLatency < 0.75)
        return ctx.outputLatency;
      const outputTime = (('getOutputTimestamp' in ctx) as unknown)
        ? ctx.getOutputTimestamp().contextTime
        : undefined;
      if ((outputTime || 0) > 0.01) {
        const latency = ctx.currentTime - outputTime!;
        if (latency < 0.5) return latency;
      }
    }
    return ctx.baseLatency || 0;
  }

  get clock(): SchedulingClock {
    if (!this._clock) {
      this._clock = new SchedulingClock({
        timeFunction: () => this.currentTime(),
        toleranceEarly: 0.8,
        toleranceLate: 1.0, // not that the sounds have to actually be *played*...
        tickInterval: 0.2,
      });
    }
    return this._clock;
  }

  setHighlyResponsive(value: boolean) {
    this.clock.toleranceEarly = value ? 0.3 : 0.8;
    this.clock.tickInterval = value ? 0.06 : 0.2;
  }

  /* Web Audio objects needed by other classes */
  get webAudioContext(): AudioContext {
    assert(this._audioContextManager, 'AudioManager._audioContextManager undefined');
    return this._audioContextManager.context;
  }
  get webAudioScratchBuffer(): AudioBuffer {
    assert(this._audioContextManager, 'AudioManager._audioContextManager undefined (scratch)');
    return this._audioContextManager.scratchBuffer;
  }
  get webAudioRecoderTap(): AudioNode {
    return (this.mixer as WebAudioMixer).masterChannel.compressorNode;
  }

  private _webAudioClicker?: WebAudioClicker;
  get webAudioClicker(): WebAudioClicker {
    return (this._webAudioClicker ??= new WebAudioClicker(this.webAudioContext));
  }
}

export default new AudioManager();
