import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import MedleySongRoundMarkers from '@/chart/ui/MedleySongRoundMarkers.svelte';
import { Crnt } from '@/Crnt';
import defineTippyDropdown from '@/ui/dropdowns/defineTippyDropdown';
import SongMenuManager from '@/ui/SongMenuManager';

defineTippyDropdown(Template.medleyHeader, {
  id: 'song-title-menu',
});

Template.medleyHeader.events({
  'click .js-openSong': function (event, instance) {
    event.preventDefault();
    FlowRouter.go(
      'show-song',
      { songId: instance.data.song._id },
      { fromMedley: Crnt.medley()?.id(), onList: FlowRouter.getQueryParam('list') }
    );
  },

  'click .js-bandButton': function (_event, instance) {
    SongMenuManager.showMenu(undefined);
    Modal.show(
      'bandSettingsModal',
      { band: instance.data.song.band },
      { backdrop: 'unless-tippy-or-presetMode' }
    );
  },
});

Template.medleyHeader.helpers({
  medleyId() {
    return Crnt.medley()?.id();
  },

  songTitle() {
    return this.song.name().replace(/ +\[.+\]$/, '');
  },

  MedleySongRoundMarkers() {
    return MedleySongRoundMarkers;
  },

  band() {
    return this.song?.band;
  },

  bandButtonText() {
    if (!this.song?.band) return 'Band settings';
    const preset = this.song.band.preset();
    if (!preset) return 'Custom band settings';
    return (
      (preset.default ? 'Default band settings' : preset.name) +
      (this.song.band.presetMode() ? ' (editing)' : '')
    );
  },

  bandButtonAriaLabel() {
    if (!this.song?.band) return 'Band settings';
    const preset = this.song.band.preset();
    return preset?.default
      ? 'Default band settings'
      : preset
        ? `Current band preset is "${preset?.name}"`
        : 'Custom band settings';
  },
});
