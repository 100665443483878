export function convertNumberToFractionString(value: number | string): string {
  return (value ?? '')
    .toString()
    .replace(/(^0)?\.250*$/, '¼')
    .replace(/(^0)?\.3333.+/, '⅓')
    .replace(/(^0)?\.50*$/, '½')
    .replace(/(^0)?\.6666.+/, '⅔')
    .replace(/(^0)?\.750*$/, '¾')
    .replace(/^-0/, '-');
}
