<script lang="ts">
  import type { Snippet } from 'svelte';
  import HelpCircleTooltip from '@/ui/HelpCircleTooltip.svelte';

  interface Props {
    title?: string;
    helpText?: string;
    helpHTML?: string;
    whiteBg?: boolean;
    children?: Snippet;
  }

  let { title = '', helpText = '', helpHTML = '', whiteBg = true, children }: Props = $props();
</script>

{#if title}
  <div class="mx-[1px] px-3 pb-2 text-sm font-medium text-gray-500">
    <span>{title}</span>
    <HelpCircleTooltip text={helpText} html={helpHTML} />
  </div>
{/if}

<div
  class={whiteBg
    ? 'divide-y divide-panelWellBorder rounded-lg border border-panelWellBorder bg-white'
    : ''}
>
  {@render children?.()}
</div>
