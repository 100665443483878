<script lang="ts">
  import type { Section } from '@/chart/Section.svelte';
  import { sectionTypes } from '@/chart/sectionTypes';
  import ChecklistCheck from '@/ui/icons/ChecklistCheck.svelte';
  import TippyDropdown from '@/ui/TippyDropdown.svelte';

  interface Props {
    section: Section;
  }

  let { section }: Props = $props();

  let typeLabel = $derived(sectionTypes.find((type) => type.id === section.type)?.label || '');
</script>

{#if typeLabel}
  <TippyDropdown>
    {#snippet button()}
      <button
        class="colors-button mr-2 whitespace-nowrap rounded px-2.5 py-1 text-[13px] italic text-gray-500"
      >
        {@html typeLabel}
        {#if section.type && section.type != 'i'}
          (not applied in edit mode)
        {/if}
      </button>
    {/snippet}
    {#snippet menu({ tippyInstance })}
      <div class="p-2">
        {#each sectionTypes as type (type.id)}
          <div
            class="group border-x border-t border-panelWellBorder first:rounded-t-lg last:rounded-b-lg last:border-b"
          >
            <button
              class="colors-panelOpener relative block w-full py-2.5 pl-2.5 pr-12 text-left group-first:rounded-t-lg group-last:rounded-b-lg"
              onclick={() => {
                tippyInstance.hide();
                section.setType(type.id || undefined);
              }}
            >
              <div class="pl-1 sm-band:pl-0">
                <div class="flex items-center">
                  <div class="flex-1 font-medium leading-6 text-gray-900 transition-colors">
                    {type.name}
                  </div>
                </div>
              </div>
              <div
                class="absolute right-4 top-1/2 flex -translate-y-1/2 scale-125 items-center justify-center"
              >
                <ChecklistCheck size={16} checked={type.id == (section.type ?? '')} />
              </div>
            </button>
          </div>
        {/each}
      </div>
    {/snippet}
  </TippyDropdown>
{/if}
