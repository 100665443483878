import { Crnt } from '@/Crnt';
import UserPreferences from '@/user/UserPreferences';
import { getLocalStorageSafe, setLocalStorageSafe } from '@/utilities/safe-local-storage';

const NotesInterface = {};

const displayPosition = new ReactiveVar('bottom');

// HACK: Fixing notes display to bottom of page
NotesInterface.displayPosition = () => 'bottom';
// NotesInterface.displayPosition = () => Crnt.song()?.editMode() ? 'bottom' : displayPosition.get();

NotesInterface.setDisplayPosition = (position) => {
  displayPosition.set(position);
  savePosition();
};

NotesInterface.visible = () => Crnt.notepad()?.visible();
NotesInterface.show = () => Crnt.notepad()?.setVisibility(true);
NotesInterface.hide = () => Crnt.notepad()?.setVisibility(false);
NotesInterface.toggle = () => Crnt.notepad()?.setVisibility(!Crnt.notepad()?.visible());

Meteor.startup(() => {
  // need to run these after clientModules.songSidebar is loaded
  loadSavedPosition();
  // Tracker.autorun(showHideNotesSidebarAutorun);
});

// function showHideNotesSidebarAutorun() {
//   const showOrHide = Crnt.notepad()?.visible() && displayPosition.get() == 'side' ? 'show' : 'hide';
//   clientModules.songSidebar[showOrHide]('notesArea');
// }

function loadSavedPosition() {
  const savedPosition = getLocalStorageSafe('strum_machine_notes_position');
  if (['top', 'side', 'bottom'].includes(savedPosition)) {
    displayPosition.set(savedPosition);
    Meteor.startup(() => UserPreferences.set('notesPosition', savedPosition)); // HACK: to collect data on notes position
  }
}

function savePosition() {
  const position = displayPosition.get();
  if (['top', 'side', 'bottom'].includes(position)) {
    UserPreferences.set('notesPosition', position); // HACK: to collect data on notes position
    setLocalStorageSafe('strum_machine_notes_position', position);
  }
}

export default NotesInterface;
