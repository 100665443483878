import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { clog } from '@/browser/clog';
import { rpcCheckLoginCode } from '@/server/methods/accounts/rpcCheckLoginCode';

const CODE_LENGTH = 4;

Template.loginCodeInput.onCreated(() => {
  const instance = Template.instance();
  instance.checking = new ReactiveVar();
  instance.impotentCode = null; // prevent immediate re-send of same code
  if (!Session.get('loginCode.email')) FlowRouter.go('login');
});

Template.loginCodeInput.onRendered(() => {
  const instance = Template.instance();
  setTimeout(() => {
    instance.$('.js-codeInput').focus();
  }, 50);
  instance.checkCode = async () => {
    const code = instance
      .$('input')
      .val()
      .replace(/[^0-9]/g, '')
      .substring(0, CODE_LENGTH);
    const email = Session.get('loginCode.email');
    if (code.length == CODE_LENGTH && code != instance.impotentCode && !instance.checking.get()) {
      instance.checking.set(true);
      instance.impotentCode = code;
      try {
        const userId = (await rpcCheckLoginCode({ email, code }))?.userId;
        if (userId) {
          Accounts.callLoginMethod({
            methodArguments: [{ email, code }],
            userCallback: () => {
              Session.set('loginCode.lastUse', new Date());
              FlowRouter.withReplaceState(() => {
                FlowRouter.go('/app', {}, {});
              });
            },
          });
        } else {
          Session.set('loginCode.codeForNewUser', code);
          FlowRouter.go('login-name', {}, {});
        }
      } catch (err) {
        instance.checking.set(false);
        clog.error(err);
        alert(err.reason || err.message);
        setTimeout(() => {
          instance.$('input').focus();
        }, 50);
      }
    }
  };
});

Template.loginCodeInput.helpers({
  checking() {
    return Template.instance().checking.get();
  },
  disabledAttr() {
    return Template.instance().checking.get() ? 'disabled' : null;
  },
});

Template.loginCodeInput.events({
  'focus input': function (event, instance) {
    instance.$('input').select();
  },
  'paste input': function (event, instance) {
    event.preventDefault();

    var clipboardData = (event.originalEvent || event).clipboardData || window.clipboardData;
    var pastedData = clipboardData.getData('text/plain');
    pastedData = pastedData.replace(/[^0-9]/g, '').substring(0, CODE_LENGTH);

    instance.$('input').val(pastedData);

    instance.checkCode();
  },
  'keydown input': function (event, instance) {
    if (
      !(
        (
          event.ctrlKey ||
          event.altKey ||
          event.metaKey ||
          event.keyCode == 8 || // backspace key
          event.keyCode == 9 || // tab key
          event.keyCode == 46 || // delete key
          (event.keyCode >= 48 && event.keyCode <= 57) || // numbers on keyboard
          (event.keyCode >= 96 && event.keyCode <= 105) || // number on keypad
          (event.keyCode >= 35 && event.keyCode <= 40)
        ) // home, end, arrows
      )
    ) {
      event.preventDefault(); // Prevent character input
      event.stopPropagation();
    }
  },
  'keyup input': function (event, instance) {
    if (
      (event.keyCode >= 48 && event.keyCode <= 57) || // numbers on keyboard
      (event.keyCode >= 96 && event.keyCode <= 105) // number on keypad
    )
      instance.impotentCode = null;
    instance.checkCode();
  },
});
