import { ReactiveVar } from 'meteor/reactive-var';
import { getLocalStorageSafe, setLocalStorageSafe } from '@/utilities/safe-local-storage';

export class MasterPitch {
  private static reactiveValue = new ReactiveVar<number | undefined>(undefined);

  static get(): number {
    if (typeof this.reactiveValue.get() !== 'number') {
      const storedValue = getLocalStorageSafe(`sm.masterChannel.pitch`);
      const storedNumber = +(storedValue ?? 440);
      const boundedNumber = Math.max(
        MasterPitch.minHertz,
        Math.min(MasterPitch.maxHertz, storedNumber)
      );
      this.reactiveValue.set(isNaN(boundedNumber) ? 440 : boundedNumber);
    }
    return <number>this.reactiveValue.get();
  }

  static getCents(): number {
    const hz = this.get();
    return Math.log2(hz / 440) * 1200;
  }

  static shifted(): boolean {
    return 440 != this.get();
  }

  static set(value: number) {
    value =
      typeof value == 'number'
        ? Math.max(MasterPitch.minHertz, Math.min(MasterPitch.maxHertz, value))
        : 440;
    if (this.reactiveValue.get() != value) {
      setLocalStorageSafe(`sm.masterChannel.pitch`, value.toString());
    }
    this.reactiveValue.set(value);
  }

  static maxHertz = 466;
  static minHertz = 415;
}
