import type { Channel } from '@/audio/engine/base/Channel';
import type { PlayProps } from '@/audio/engine/base/PlayProps';
import type { SampleDefinition } from '@/audio/engine/base/Sample';
import { Sample } from '@/audio/engine/base/Sample';
import type { FMBChannel } from '@/audio/engine/fmb/FMBChannel';
import { FMBPlayback } from '@/audio/engine/fmb/FMBPlayback';
import FMBSampleLoader from '@/audio/engine/fmb/FMBSampleLoader';

export class FMBSample extends Sample {
  constructor(sampleDefinition: SampleDefinition) {
    super(sampleDefinition);
  }

  private _loadPromise?: Promise<void>;
  protected _load(): Promise<void> {
    return (this._loadPromise ??= FMBSampleLoader.storeSample({
      sampleId: this.id,
      packageId: this.packageId,
    }).then(() => {
      this.loaded = true;
    }));
  }

  protected _createPlayback(playProps: PlayProps, channel: Channel): FMBPlayback {
    return new FMBPlayback({
      sample: this,
      channel: channel as FMBChannel,
      playProps,
    });
  }

  _destroy(): void {
    // Not needed for FMB
  }
}
