import { differenceInDays } from 'date-fns';
import { stripeCardBrandMadeFriendly } from '@/ui-helpers/stripeCardBrandMadeFriendly';

// arguments: setRefundChoice, refundAmount, card, deleting

Template.refundChoice.helpers({
  daysLeft() {
    const date = Meteor.user('subscription')?.subscription?.currentPeriodEnd;
    if (!date) return '__ days';
    const days = differenceInDays(date, Date.now());
    return days == 1 ? 'day' : `${days} days`;
  },

  periodEndDate() {
    const date = Meteor.user('subscription')?.subscription?.currentPeriodEnd;
    if (!date) return '';
    // prettier-ignore
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
  },

  canGetFullRefund() {
    const planAmount = Meteor.user('subscription')?.subscription?.plan?.amount;
    const amount = Template.currentData().refundAmount;
    return amount === planAmount;
  },

  tooLateForRefund() {
    return Template.currentData().refundAmount === 0;
  },

  cardLabel() {
    const card = Template.currentData().card;
    if (!card) return 'card';
    const brand = stripeCardBrandMadeFriendly(card.brand)
      .replace('American Express', 'AmEx')
      .replace('Unknown', '');
    const funding = card.funding == 'unknown' ? '' : card.funding;
    if (brand && funding) return `${brand} ${funding} card`;
    return `${brand || funding || ''} card`;
  },

  cardLast4() {
    const card = Template.currentData().card;
    if (!card) return;
    return card.last4;
  },
});

Template.refundChoice.events({
  'input/change input[name="refundOption"]': function (event, instance) {
    const choice = event.currentTarget.value;
    instance.data.setRefundChoice(choice);
  },

  'click .js-mapHelp': function (event) {
    bootbox.alert({
      title: 'What is MAP International?',
      message:
        '<a href="https://www.map.org/" target="_blank">MAP International</a> is a top-rated charity that provides medicine and healthcare services to disaster areas, third-world countries, and other people in need. 99% of each donation goes directly toward funding their programs, and donations make a big impact: each dollar donated provides $60 worth of medicine to people around the world who need it most.<br><br>Strum Machine makes an aggregate gift of all donated refunds to MAP International at least twice a year.',
    });
    return false;
  },
});
