import { strict as assert } from 'assert';
import type { LinearizedBeat } from '@/chart/LinearizedBeat';

// prettier-ignore
const rootFifthGhosts = {
  "a":    [ "a0", "e0", "e0" ],
  "ab":   [ "a1", "e1", "e1" ],
  "b":    [ "a2", "e2", "e2" ],
  "c":    [ "a3", "e3", "e3" ],
  "cd":   [ "a4", "e4", "e4" ],
  "d":    [ "d0", "a0", "a0" ],
  "de":   [ "d1", "a1", "a1" ],
  "e":    [ "d2", "a2", "a2" ],
  "f":    [ "d3", "a3", "a3" ],
  "fg":   [ "d4", "a4", "a4" ],
  "g":    [ "e3", "d0", "e3" ],
  "ga":   [ "e4", "d1", "e4" ]
} as const;

// prettier-ignore
const guitarBassNoteArray = [ 'g0', 'g1', 'd0', 'd1', 'e0', 'e1', 'e2', 'e3', 'e4', 'a0', 'a1', 'a2' ] as const;
const guitarBassNoteFromRootChroma = (chroma: number) => {
  const result = guitarBassNoteArray[chroma];
  assert(result);
  return result;
};

export function halfTimeBassNoteForBeat(beat: LinearizedBeat, note: 'root' | 'fifth' | 'ghost') {
  const chordLetterCode = beat.chord.letterCode();
  const arrayIndex = note == 'ghost' ? 2 : note == 'fifth' ? 1 : 0;
  return typeof beat.chord.bassChroma == 'number'
    ? guitarBassNoteFromRootChroma(beat.chord.bassChroma)
    : rootFifthGhosts[chordLetterCode][arrayIndex];
}
