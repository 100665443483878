import type { BuiltInPresetDefinition } from '@/band/presets/BuiltInPresetDefinition';

export const otherTraditionalPresetDefinitions: Partial<
  Record<TimeSignature, BuiltInPresetDefinition[]>
> = {
  // --- 4/4 ---

  '4/4': [
    {
      id: 'ca-qbr',
      name: 'Québécois Reel',
      genreId: 'of',
      genreCategoryId: 'of-ca',
      description: 'Guitar and bass with Québécois-style foot tapping.',
      band: {
        'sw': '0',
        'g_c': 'bg',
        'g_bn': { 'm': 'r' },
        'g_ss': { 'r': 0.325, 't': 0.575 },
        'g_bcbr': { 'v': 11 / 14 },
        'g_ov': { 'mj': 'gacde', 'gv': 'G' },
        'm_e': false,
        'f_e': true,
        'f_c': 'cr',
      },
    },

    {
      id: 'cj-tso',
      name: 'Cajun Two-Step (Open)',
      genreId: 'of',
      genreCategoryId: 'of-cj',
      description:
        'Strummy guitar in a Cajun style – triangle to be added later. Sometimes called a “Balfa-style” rhythm.',
      band: {
        'sw': '5:4',
        'g_c': 'bg',
        'g_bn': { 'm': 'r' },
        'g_ss': { 'r': 1, 't': 0.75 },
        'g_bcbr': { 'v': 7 / 14 },
        'g_br': { 'e': 0.25, 'qeb': 0, 'jf': 'sm' },
        'g_ov': { 'mj': 'gacde', 'gv': 'G' },
        'm_e': false,
        'b_e': true,
      },
    },

    {
      id: 'cj-tsb',
      name: 'Cajun Two-Step (Barred)',
      genreId: 'of',
      genreCategoryId: 'of-cj',
      description: 'Closed chords and muted strings.',
      band: {
        'sw': '5:4',
        'g_c': 'd',
        'g_dot': { 's': 0.5 },
        'g_dss': { 'b1': 1, 'b2': 0 },
        'g_db': { 'v': 1 / 6 },
        'm_e': false,
        'b_e': true,
      },
    },
  ],

  // --- 3/4 ---

  '3/4': [
    {
      id: 'cj-w',
      name: 'Cajun Waltz',
      genreId: 'of',
      genreCategoryId: 'of-cj',
      description: 'Guitar and bass waltz rhythm – triangle to be added in the future.',
      band: {
        'sw': '2:1',
        'g_c': 'bgw',
        'g_bn': { 'm': 'r' },
        'g_ss': { 'r': 0.5, 't': 0.625 },
        'g_bcbr': { 'v': 7 / 14 },
        'g_br': { 'e': 0.5, 'qeb': 0, 'jf': 'sm' },
        'g_ov': { 'mj': 'gacde', 'gv': 'G' },
        'm_e': false,
        'b_e': true,
      },
    },
  ],

  // --- 6/8 ---

  '6/8': [
    {
      id: 'eu-plska',
      name: 'Polska',
      genreId: 'of',
      genreCategoryId: 'of-eu',
      description: 'Polska accompaniment.',
      band: {
        'g_c': 'bcj',
      },
    },
  ],
};
