import { GuitarBassNotesSetting } from '@/band/instruments/guitar/settings/GuitarBassNotesSetting';
import { GuitarBassRunsSetting } from '@/band/instruments/guitar/settings/GuitarBassRunsSetting';
import { GuitarBoomChuckBalanceSetting } from '@/band/instruments/guitar/settings/GuitarBoomChuckBalanceSetting';
import { GuitarBoomChuckWaltzBalanceSetting } from '@/band/instruments/guitar/settings/GuitarBoomChuckWaltzBalanceSetting';
import { GuitarBrushinessSetting } from '@/band/instruments/guitar/settings/GuitarBrushinessSetting';
import { GuitarCircularStrumShapeSetting } from '@/band/instruments/guitar/settings/GuitarCircularStrumShapeSetting';
import { GuitarDampenedBackbeatSetting } from '@/band/instruments/guitar/settings/GuitarDampenedBackbeatSetting';
import { GuitarDampenedFillsSetting } from '@/band/instruments/guitar/settings/GuitarDampenedFillsSetting';
import { GuitarDampenedOnbeatsSetting } from '@/band/instruments/guitar/settings/GuitarDampenedOnbeatsSetting';
import { GuitarDampenedSustainSetting } from '@/band/instruments/guitar/settings/GuitarDampenedSustainSetting';
import { GuitarDampenedUpstrokePatternSetting } from '@/band/instruments/guitar/settings/GuitarDampenedUpstrokePatternSetting';
import { GuitarOpenVoicingsSetting } from '@/band/instruments/guitar/settings/GuitarOpenVoicingsSetting';
import { GuitarSyncopatedLeadingNotesSetting } from '@/band/instruments/guitar/settings/GuitarSyncopatedLeadingNotesSetting';
import { GuitarTimingSetting } from '@/band/instruments/guitar/settings/GuitarTimingSetting';
import type { InstrumentSettingDefinitions } from '@/band/instruments/InstrumentSettingDefinitions';

// depreciated: g_bcbn, g_sb3, g_do, g_ds, g_du

export const guitarSettingDefinitions = {
  bassRuns: { class: GuitarBassRunsSetting, serializationKey: 'g_br' },
  circularStrumShape: { class: GuitarCircularStrumShapeSetting, serializationKey: 'g_ss' },
  openVoicings: { class: GuitarOpenVoicingsSetting, serializationKey: 'g_ov' },
  bassNotes: { class: GuitarBassNotesSetting, serializationKey: 'g_bn' },
  syncopatedLeadingNotes: { class: GuitarSyncopatedLeadingNotesSetting, serializationKey: 'g_sln' },
  boomChuckBalance: { class: GuitarBoomChuckBalanceSetting, serializationKey: 'g_bcb' },
  boomChuckWaltzBalance: { class: GuitarBoomChuckWaltzBalanceSetting, serializationKey: 'g_bcwb' },
  brushiness: { class: GuitarBrushinessSetting, serializationKey: 'g_bcbr' },
  dampenedOnbeats: { class: GuitarDampenedOnbeatsSetting, serializationKey: 'g_dot' },
  dampenedSustain: { class: GuitarDampenedSustainSetting, serializationKey: 'g_dss' },
  dampenedBackbeat: { class: GuitarDampenedBackbeatSetting, serializationKey: 'g_db' },
  // prettier-ignore
  dampenedUpstrokePattern: { class: GuitarDampenedUpstrokePatternSetting, serializationKey: 'g_dup', },
  dampenedFills: { class: GuitarDampenedFillsSetting, serializationKey: 'g_df' },
  timing: { class: GuitarTimingSetting, serializationKey: 'g_bt' },
} as const satisfies InstrumentSettingDefinitions;
