Template.installPage.onCreated(() => {
  var instance = Template.instance();
  instance.data.setTitle('Download the App');
});

Template.installPage.helpers({
  isChrome() {
    return (
      /Chrome/.test(navigator.userAgent) &&
      /Google Inc/.test(navigator.vendor) &&
      !/iP(ad|od|hone)|Android/i.test(navigator.userAgent)
    );
  },
});
