import { Crnt } from '@/Crnt';
import { HearAsYouEdit } from '@/editor/HearAsYouEdit';
import { showClipboardToast } from '@/editor/ui/clipboard-toasts';

export const chartEditingCommands: {
  [key: string]: {
    bindings: string[];
    conditions?: string[];
    action: () => void;
  };
} = {
  showSlashChordPicker: {
    bindings: ['/'],
    action: () => {
      Crnt.song()?.withFocused().setBassNote(undefined);
      clientModules.slashChordPicker.toggle();
      clientModules.lineLengthPicker.hide();
    },
  },

  showLineLengthPicker: {
    bindings: ['l', 'L'],
    action: () => {
      clientModules.lineLengthPicker.toggle();
      clientModules.slashChordPicker.hide();
    },
  },

  showKeyboardShortcuts: {
    bindings: ['?'],
    action: () => {
      (
        document.querySelector('.js-global-editorShortcutsButton') as HTMLButtonElement | undefined
      )?.click();
    },
  },

  duplicate: {
    bindings: ['Spacebar', 'Shift+Spacebar'],
    action: () => {
      Crnt.song()?.withFocused().duplicate();
    },
  },

  deleteBackward: {
    bindings: ['Backspace', 'Shift+Backspace'],
    action: () => {
      Crnt.song()?.withFocused().delete();
    },
  },

  deleteForward: {
    bindings: ['Delete', 'Shift+Delete'],
    action: () => {
      Crnt.song()?.withFocused().delete({ focusForward: true });
    },
  },

  sectionBreak: {
    bindings: ['CmdCtrl+Enter'],
    action: () => {
      Crnt.song()?.withFocused().insertSectionBreak();
    },
  },

  cut: {
    bindings: ['CmdCtrl+X'],
    action: () => {
      Crnt.song()?.withFocused().cut();
    },
  },

  copy: {
    bindings: ['CmdCtrl+C'],
    action: () => {
      Crnt.song()?.withFocused().copy();
      const cellEl = Crnt.song()?.focus.firstCell()?.layout.chartCell;
      if (cellEl) showClipboardToast(cellEl, 'copy');
    },
  },

  paste: {
    bindings: ['CmdCtrl+V'],
    action: () => {
      if (!Crnt.song()?.withFocused().canPaste()) {
        const cellEl = Crnt.song()?.focus.lastCell()?.layout.chartCell;
        if (cellEl) showClipboardToast(cellEl, 'pasteboard-empty');
      } else {
        Crnt.song()?.withFocused().paste();
        requestAnimationFrame(() => {
          const cellEl = Crnt.song()?.focus.lastCell()?.layout.chartCell;
          if (cellEl) showClipboardToast(cellEl, 'paste');
        });
      }
    },
  },

  pasteInPlace: {
    bindings: ['CmdCtrl+Shift+V'],
    action: () => {
      if (!Crnt.song()?.withFocused().canPaste()) {
        const cellEl = Crnt.song()?.focus.lastCell()?.layout.chartCell;
        if (cellEl) showClipboardToast(cellEl, 'pasteboard-empty');
      } else {
        Crnt.song()?.withFocused().pasteInPlace();
        requestAnimationFrame(() => {
          const cellEl = Crnt.song()?.focus.lastCell()?.layout.chartCell;
          if (cellEl) showClipboardToast(cellEl, 'paste');
        });
      }
    },
  },

  undo: {
    bindings: ['CmdCtrl+Z'],
    action: () => {
      Crnt.song()?.history.undo();
    },
  },

  redo: {
    bindings: ['CmdCtrl+Y', 'CmdCtrl+Shift+Z'],
    action: () => {
      Crnt.song()?.history.redo();
    },
  },

  selectAll: {
    bindings: ['CmdCtrl+A'],
    action: () => {
      Crnt.song()?.focus.selectAll();
    },
  },

  saveAndExit: {
    bindings: ['CmdCtrl+S'],
    action: () => {
      (document.querySelector('.js-global-saveChanges') as HTMLButtonElement | undefined)?.click();
    },
  },

  cycleEffect: {
    bindings: ['x', 'X'],
    action: () => {
      Crnt.song()?.withFocused().cycleEffect();
    },
  },

  halfMeasure: {
    bindings: ['h', 'H'],
    action: () => {
      Crnt.song()?.withFocused().toggleHalfMeasure();
    },
  },

  moveLeft: {
    bindings: ['ArrowLeft'],
    action: () => {
      Crnt.song()?.focus.move(-1);
      HearAsYouEdit.trigger();
    },
  },
  moveRight: {
    bindings: ['ArrowRight'],
    action: () => {
      Crnt.song()?.focus.move(1);
      HearAsYouEdit.trigger();
    },
  },
  moveToLineStart: {
    bindings: ['Cmd+ArrowLeft', 'Home'],
    action: () => {
      Crnt.song()?.focus.moveToEndOfLine(-1);
      HearAsYouEdit.trigger();
    },
  },
  moveToLineEnd: {
    bindings: ['Cmd+ArrowRight', 'End'],
    action: () => {
      Crnt.song()?.focus.moveToEndOfLine(1);
      HearAsYouEdit.trigger();
    },
  },

  extendLeft: { bindings: ['Shift+ArrowLeft'], action: () => Crnt.song()?.focus.extendBy(-1) },
  extendRight: { bindings: ['Shift+ArrowRight'], action: () => Crnt.song()?.focus.extendBy(1) },
  extendToLineStart: {
    bindings: ['Cmd+Shift+ArrowLeft', 'Shift+Home'],
    action: () => Crnt.song()?.focus.extendToEndOfLine(-1),
  },
  extendToLineEnd: {
    bindings: ['Cmd+Shift+ArrowRight', 'Shift+End'],
    action: () => Crnt.song()?.focus.extendToEndOfLine(1),
  },

  moveUp: { bindings: ['ArrowUp'], action: () => Crnt.song()?.focus.moveLines(-1) },
  moveDown: { bindings: ['ArrowDown'], action: () => Crnt.song()?.focus.moveLines(1) },
  extendUp: { bindings: ['Shift+ArrowUp'], action: () => Crnt.song()?.focus.extendByLines(-1) },
  extendDown: { bindings: ['Shift+ArrowDown'], action: () => Crnt.song()?.focus.extendByLines(1) },

  moveToStart: { bindings: ['Cmd+ArrowUp'], action: () => Crnt.song()?.focus.move(-9999) },
  moveToEnd: { bindings: ['Cmd+ArrowDown'], action: () => Crnt.song()?.focus.move(9999) },
  extendToStart: {
    bindings: ['Cmd+Shift+ArrowUp'],
    action: () => Crnt.song()?.focus.extendBy(-9999),
  },
  extendToEnd: {
    bindings: ['Cmd+Shift+ArrowDown'],
    action: () => Crnt.song()?.focus.extendBy(9999),
  },
};
