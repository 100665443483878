import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { rpcSetPasswordlessFlag } from '@/server/methods/accounts/rpcSetPasswordlessFlag';

Template.resetPasswordForm.onCreated(() => {
  const instance = Template.instance();
  instance.errorMessage = new ReactiveVar();
  instance.submitting = new ReactiveVar();
});

Template.resetPasswordForm.onRendered(() => {
  const instance = Template.instance();
  setTimeout(() => {
    instance.$('input[name="password"]').focus();
  }, 50);
});

Template.resetPasswordForm.helpers({
  errorMessage() {
    return Template.instance().errorMessage.get();
  },
  submitting() {
    return Template.instance().submitting.get();
  },
});

Template.resetPasswordForm.events({
  'submit form': function (event, instance) {
    event.preventDefault();
    instance.submitting.set(true);
    Accounts.resetPassword(
      FlowRouter.getParam('token'),
      instance.$('[name="password"]').val(),
      (err) => {
        if (!err) {
          rpcSetPasswordlessFlag({ passwordless: false });
          Bert.alert("Your password has been changed, and you're now signed in.", 'success');
          FlowRouter.go('song-index');
        } else {
          if (err.reason == 'Token expired') {
            instance.errorMessage.set(
              'Sorry, this link has expired. You can go <a href="/forgot-password">request a new password reset link</a>, though.'
            );
          } else {
            instance.errorMessage.set(err.reason);
          }
          instance.submitting.set(false);
        }
      }
    );
  },
});
