<script lang="ts">
  import { onMount } from 'svelte';
  import type { BassCore } from '@/band/instruments/bass/BassCore';
  import type { FeetCore } from '@/band/instruments/feet/FeetCore';
  import type { GuitarCore } from '@/band/instruments/guitar/GuitarCore';
  import type { MandolinCore } from '@/band/instruments/mandolin/MandolinCore';
  import BandPanelOpenerForSetting from '@/band/ui/components/BandPanelOpenerForSetting.svelte';
  import InstrumentCustomizationsArea from '@/band/ui/components/InstrumentCustomizationsArea.svelte';
  import InstrumentPanelMuteNotice from '@/band/ui/components/InstrumentPanelMuteNotice.svelte';
  import InstrumentStyleOpener from '@/band/ui/components/InstrumentStyleOpener.svelte';
  import PanelSettingSection from '@/band/ui/components/PanelSettingSection.svelte';
  import PresetEditModeSubpageHeader from '@/band/ui/components/PresetEditModeSubpageHeader.svelte';
  import PanelModalLayout from '@/band/ui/panels/PanelModalLayout.svelte';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import { MultiPanelModalController } from '@/ui/settings/MultiPanelModalController.svelte';

  interface Props {
    instId: InstrumentId;
  }

  let { instId }: Props = $props();

  const bandMenu = getBandMenuState();
  let instrument = $derived($bandMenu.instruments[instId]);
  let timeSignature = $derived($bandMenu.timeSignature);

  let core: GuitarCore | BassCore | MandolinCore | FeetCore = $state() as typeof core;
  trackerEffect(() => (core = instrument.coreOrFallbackForTimeSignature(timeSignature)));

  let showSwing = $derived(
    'swingCategory' in core &&
      core?.swingCategory != 'none' &&
      !(instId == 'guitar' && core?.swingCategory == 'jazz') &&
      timeSignature.endsWith('4')
  );

  onMount(() => {
    if (instId == 'guitar') {
      new Image().src = '/images/instruments/guitar-fretboard-vertical-9frets.20230223.png';
    }
  });
</script>

<PanelModalLayout>
  {#snippet header()}
    <header>
      <PresetEditModeSubpageHeader />
    </header>
  {/snippet}

  {#snippet main()}
    <div>
      <div class="px-3">
        <InstrumentPanelMuteNotice {instId} />
      </div>
      <div class="p-4">
        <div class="overflow-hidden rounded-3xl border border-panelWellBorder">
          <InstrumentStyleOpener {instrument} {timeSignature} class="rounded-3xl pb-1.5" />
        </div>
        {#if core.linkedSettingsKeys.length > 0 || showSwing}
          <div class="pt-4"></div>
          <PanelSettingSection title="Pattern customizations">
            {#each core.linkedSettingsKeys as settingKey (settingKey)}
              <div class="group">
                <BandPanelOpenerForSetting {instId} {settingKey} blueLabel={true} />
              </div>
            {/each}
            {#if showSwing}
              <div class="group">
                <BandPanelOpenerForSetting {instId} settingKey="swing" blueLabel={true} />
              </div>
            {/if}
          </PanelSettingSection>
        {/if}
        <InstrumentCustomizationsArea {instId} />
      </div>
    </div>
  {/snippet}
</PanelModalLayout>
