<script lang="ts">
  import GlobalMixerPanel from '@/band/ui/panels/GlobalMixerPanel.svelte';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import { MultiPanelModalController } from '@/ui/settings/MultiPanelModalController.svelte';
  import { slideFade } from '@/ui/svelte-transitions';

  const modalController = MultiPanelModalController.current();

  const bandMenu = getBandMenuState();

  interface Props {
    instId: InstrumentId;
  }

  let { instId }: Props = $props();

  let muted: boolean = $state(false);
  trackerEffect(() => (muted = $bandMenu.instruments[instId].globalMute()));

  function openMixerPanel() {
    modalController.zoomInto({ component: GlobalMixerPanel, title: 'Global Mixer' });
  }

  function unmute() {
    $bandMenu.instruments[instId].setGlobalMute(false);
  }
</script>

{#if muted}
  <div
    class="mt-3 grid auto-rows-min grid-cols-2 gap-1 rounded-md border border-red-300 bg-red-50 p-1 pt-3 text-center text-red-900"
    transition:slideFade
  >
    <div class="col-span-2">Muted across all songs.</div>
    <button class="btn row-start-2 py-1.5" onclick={openMixerPanel}>View settings</button>
    <button class="btn row-start-2 py-1.5" onclick={unmute}>Unmute</button>
  </div>
{/if}
