import * as Sentry from '@sentry/browser';
import { Roles } from '@/Roles';
import { rpcReactivateSubscription } from '@/server/methods/accounts/rpcReactivateSubscription';
import { rpcStartCheckoutSessionForUpdatingPayment } from '@/server/methods/stripe/rpcStartCheckoutSessionForUpdatingPayment';
import defineTippyDropdown from '@/ui/dropdowns/defineTippyDropdown';
import { stripeCardBrandMadeFriendly } from '@/ui-helpers/stripeCardBrandMadeFriendly';
import { formatCurrencyAmount } from '@/utilities/formatCurrencyAmount';

defineTippyDropdown(Template.stripeSubscriptionInfo, {
  id: 'switch-billing-cycle',
});

Template.stripeSubscriptionInfo.onCreated(() => {
  const instance = Template.instance();
  instance.billingInfoReady = new ReactiveVar();
  instance.reactivating = new ReactiveVar();
  instance.deletingSource = new ReactiveVar();
  instance.updatingSource = new ReactiveVar();
  if (!Meteor.isCordova) {
    instance.subscribe('userBillingInfo', {
      onReady: () => instance.billingInfoReady.set(true),
    });
  }
  instance.data.setTitle('Account & Subscription');
});

Template.stripeSubscriptionInfo.helpers({
  subscriptionDescription() {
    const plan = Meteor.user('subscription')?.subscription?.plan;
    if (!plan) return undefined;
    const cost = formatCurrencyAmount(plan.currency, plan.amount);
    const name =
      plan.amount === 0
        ? 'Sponsored'
        : plan.period == 'year'
          ? 'Annual'
          : plan.period == 'month'
            ? 'Monthly'
            : undefined;
    return `${name} (${cost}/${plan.period})`;
  },
  isMonthly() {
    const plan = Meteor.user('subscription')?.subscription?.plan;
    return plan?.period == 'month';
  },
  subscriptionStatus() {
    return {
      'trialing': 'Free Trial',
      'active': 'Active',
      'past_due': 'Past Due',
      'canceled': 'Inactive',
      'unpaid': 'Unpaid',
    }[Meteor.user('subscription')?.subscription?.status];
  },
  activeRenewingSubscription() {
    return (
      Roles.userHasRole(Meteor.userId(), 'paid-member') &&
      !Meteor.user('subscription').subscription.cancelAtPeriodEnd
    );
  },
  activeRenewingSubscriptionOrPaidTrial() {
    return (
      (Roles.userHasRole(Meteor.userId(), 'paid-member') &&
        !Meteor.user('subscription').subscription.cancelAtPeriodEnd) ||
      (Roles.userHasRole(Meteor.userId(), 'trial-member') &&
        Meteor.user('subscription').subscription.subscriptionId)
    );
  },
  activeEndingSubscription() {
    return (
      Meteor.user('subscription')?.subscription?.subscriptionId &&
      /active|trialing|past_due/.test(Meteor.user('subscription').subscription.status) &&
      Meteor.user('subscription').subscription.cancelAtPeriodEnd &&
      !Template.instance().reactivating.get()
    );
  },
  reactivating() {
    return Template.instance().reactivating.get();
  },
  updatingSource() {
    return Template.instance().updatingSource.get();
  },
  deletingSource() {
    return Template.instance().deletingSource.get();
  },
  subscriptionStatusIs(status) {
    return Meteor.user('subscription')?.subscription?.status == status;
  },
  card() {
    return Meteor.user('paymentSource').paymentSource;
  },
  cardBrand() {
    const card = Meteor.user('paymentSource').paymentSource;
    return card?.brand ? stripeCardBrandMadeFriendly(card.brand) : null;
  },
  canSwitchPlan() {
    return (
      (Meteor.user('subscription')?.subscription?.plan?.amount > 0 &&
        Roles.userHasRole(Meteor.userId(), 'paid-member') &&
        !Meteor.user('subscription').subscription.cancelAtPeriodEnd) ||
      (Roles.userHasRole(Meteor.userId(), 'trial-member') &&
        Meteor.user('subscription').subscription.subscriptionId)
    );
  },
  sponsoredPlan() {
    return Meteor.user('subscription')?.subscription?.plan?.amount === 0;
  },
  sponsorName() {
    if (Meteor.user('sponsor').sponsor == 'etsu') return 'ETSU';
  },
});

Template.stripeSubscriptionInfo.events({
  'click .js-updateSource': async function (event, instance) {
    instance.updatingSource.set(true);
    try {
      const { redirectUrl } = await rpcStartCheckoutSessionForUpdatingPayment();
      window.location.href = redirectUrl;
    } catch (err) {
      Sentry.captureException(err);
      Bert.alert(err.reason || err.message || err, 'danger');
      instance.updatingSource.set(false);
    }
  },

  // Why not allow folks to delete their payment info?
  // Not until you can guarantee that there won't be a payment source lingering
  // which will be charged upon renewing a subscription.
  //
  // 'click .js-deleteSource': async function (event, instance) {
  //   bootbox.confirm({
  //     message: 'If you delete your payment method without adding another, your subscription will expire at the end of the billing period.',
  //     callback(result) {
  //       if (!result) return;
  //       instance.deletingSource.set(true);
  //       Meteor.call('detachDefaultPaymentMethod', (error, data) => {
  //         instance.deletingSource.set(false);
  //         if (error) {
  //           Sentry.captureException(error);
  //           Bert.alert(error.reason || error.message || error, 'danger');
  //         } else {
  //           Bert.alert('Your billing information has been removed from your account.', 'success');
  //         }
  //       });
  //     },
  //   });
  //   event.preventDefault();
  // },

  'click .js-reactivateSubscription': async function (event, instance) {
    event.preventDefault();
    // prettier-ignore
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const date = Meteor.user().subscription.currentPeriodEnd;
    const formattedDate = `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
    bootbox.confirm({
      message: `Reactivate your subscription and continue billing you as before, starting ${formattedDate}?`,
      async callback(result) {
        if (!result) return;
        instance.reactivating.set(true);
        try {
          await rpcReactivateSubscription();
          Bert.alert(
            'Your subscription has been reactivated! Good to have you back. 😊',
            'success'
          );
        } catch (error) {
          instance.reactivating.set(false);
          Sentry.captureException(error);
          Bert.alert(error.reason, 'warning');
        }
      },
      buttons: {
        confirm: {
          label: 'Yes please!',
          className: 'btn-success',
        },
        cancel: {
          label: 'Never mind',
        },
      },
    });
    event.preventDefault();
  },
});
