import { pluck } from '@/band/instruments/bass/BassActions';
import type { BassCore } from '@/band/instruments/bass/BassCore';
import { bassNoteForBeat } from '@/band/instruments/bass/bassNoteForBeat';

export const BasicJigBassCore: BassCore = {
  id: 'bj',
  title: 'Basic jig bass',

  timeSignatures: ['6/8'],

  linkedSettingsKeys: [],
  otherSettingsKeys: [],

  presets: [
    {
      name: 'Root-fifth jig bass',
      settings: {},
    },
  ],

  processMeasure(measure, _settings) {
    return measure.cells.flatMap((cell, cellIndex) => {
      return cell.beats.flatMap((beat) => {
        if (beat.rest) return [null];
        if (beat.beatInCell > 0 && !beat.chordChanged) return [null];
        if (cellIndex > 0 && !beat.chordChanged) return [null];
        const beatDuration = 0;
        return [pluck(bassNoteForBeat(beat, true), beatDuration, { db: 0 })];
      });
    });
  },

  generateViz(settings) {
    settings;
    return [];
  },
};
