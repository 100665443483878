import { bassNote, pickSpecified, strumDown } from '@/band/instruments/guitar/GuitarActions';
import type { GuitarIntent } from '@/band/instruments/guitar/GuitarIntent';
import type { GuitarBassNotesSetting } from '@/band/instruments/guitar/settings/GuitarBassNotesSetting';
import type { GuitarBoomChuckBalanceSetting } from '@/band/instruments/guitar/settings/GuitarBoomChuckBalanceSetting';
import type { GuitarOpenVoicingsSetting } from '@/band/instruments/guitar/settings/GuitarOpenVoicingsSetting';
import type { LinearizedCell } from '@/chart/LinearizedCell';
import { lerp } from '@/utilities/lerp';
import { randomPlusMinus } from '@/utilities/randomPlusMinus';

// TODO: fine-tune values here
export function boomChuckCell(
  cell: LinearizedCell,
  {
    boomChuckBalance,
    bassNotes,
    openVoicings,
  }: {
    boomChuckBalance: GuitarBoomChuckBalanceSetting;
    bassNotes: GuitarBassNotesSetting;
    openVoicings: GuitarOpenVoicingsSetting;
  }
): (GuitarIntent | null)[] {
  const { boomPower, chuckPower } = boomChuckBalance;
  const chordChange = cell.beats[0].chordChanged;
  const rootOrFifth = bassNotes.altBass && !cell.layout.barStart && !chordChange ? 'fifth' : 'root';

  if (cell.split) {
    const averagePower = (boomPower + chuckPower) / 2;
    return cell.beats.flatMap(() => [
      strumDown('root', {
        spread: lerp(2.25, 3.75, averagePower),
        db: lerp(-9, 3, averagePower),
      }),
      null,
    ]);
  }

  const runIntervals = cell.plans.guitar?.bassRun?.intervals ?? [];
  const notePower = Math.max(boomPower, 0.5);

  return [
    typeof runIntervals[0] == 'number'
      ? pickSpecified(openVoicings.intervalStringFretInKey(cell.measure.key, runIntervals[0]), {
          db: lerp(-7, 5, notePower) + (typeof runIntervals[3] == 'number' ? -4 : -2),
        })
      : bassNote(rootOrFifth, {
          db:
            typeof runIntervals[2] == 'number' ? lerp(-12, -2, boomPower) : lerp(-5, 6, boomPower),
        }),

    typeof runIntervals[1] == 'number'
      ? pickSpecified(openVoicings.intervalStringFretInKey(cell.measure.key, runIntervals[1]), {
          db: lerp(-7, 5, notePower) + (typeof runIntervals[2] == 'number' ? -3 : 0),
        })
      : null,

    typeof runIntervals[2] == 'number'
      ? pickSpecified(openVoicings.intervalStringFretInKey(cell.measure.key, runIntervals[2]), {
          db: lerp(-7, 5, notePower) + -2,
        })
      : strumDown('strum', {
          spread: lerp(2, 4.6, chuckPower) + randomPlusMinus(0.4),
          db: lerp(-5, 2, chuckPower) + randomPlusMinus(0.4),
        }),

    typeof runIntervals[3] == 'number'
      ? pickSpecified(openVoicings.intervalStringFretInKey(cell.measure.key, runIntervals[3]), {
          db: lerp(-7, 5, notePower) + -1,
        })
      : null,
  ];
}
