import { AudioPackage } from '@/audio/samples/AudioPackage';
import wait from '@/utilities/wait';

async function safeFetch(url: string, options: RequestInit): Promise<Response | undefined> {
  try {
    return await fetch(url, options);
  } catch (e) {
    return undefined;
  }
}

async function createAudioPackageFromFetchResult(result: Response, url: string) {
  const buffer = await result.arrayBuffer();
  const format = url.match(/_(opus|ogg|mp3)/)?.[1] || 'unknown';
  return new AudioPackage(buffer, format);
}

export async function fetchAudioPackageFromUrl(url: string): Promise<AudioPackage> {
  const fetchResult1 = await safeFetch(url, { credentials: 'same-origin' });

  if (fetchResult1?.ok) {
    return await createAudioPackageFromFetchResult(fetchResult1, url);
  }

  // wait and then try again, this time from Meteor server
  await wait(2000);
  const otherUrl = url.startsWith('/audio/')
    ? url.replace('/audio/', 'https://storage.googleapis.com/sm-audio/packages/')
    : url.replace('https://storage.googleapis.com/sm-audio/packages/', '/audio/');
  const fetchResult2 = await safeFetch(otherUrl, { credentials: 'same-origin' });

  if (fetchResult2?.ok) {
    return await createAudioPackageFromFetchResult(fetchResult2, otherUrl);
  }

  // third time's a charm?
  await wait(3000);
  const fetchResult3 = await safeFetch(url, { credentials: 'same-origin' });

  if (fetchResult3?.ok) {
    return await createAudioPackageFromFetchResult(fetchResult3, url);
  }

  const natureOfError = fetchResult3
    ? `${fetchResult3.status} ${fetchResult3.statusText}`
    : 'Network error';
  throw new Error(`${natureOfError} while fetching ${url}`);
}
