import { Crnt } from '@/Crnt';

if (typeof window.clientModules == 'undefined') window.clientModules = {};

var _visible = new ReactiveVar(false);

clientModules.lineLengthPicker = {
  show() {
    _visible.set(true);
  },
  hide() {
    _visible.set(false);
  },
  toggle() {
    _visible.set(!_visible.get());
  },
  visible() {
    return _visible.get();
  },
};

Meteor.startup(() => {
  Tracker.autorun(() => {
    Crnt.song()?.focus.depend();
    clientModules.lineLengthPicker.hide();
  });
});
