import type { InstrumentTimingSetting } from '@/band/instruments/InstrumentTimingSetting';
import { capitalize } from '@/utilities/capitalize';

const timingFormatter = (value?: number) =>
  value == 0 || value == undefined
    ? 'right on the beat'
    : value > 0
      ? `behind ${Math.round(Math.abs(value) * 100)}%`
      : `ahead ${Math.round(Math.abs(value) * 100)}%`;

export function generateBandInstrumentTimingSummary({
  setting,
  timeSignature,
  instId,
}: {
  setting: InstrumentTimingSetting;
  timeSignature?: TimeSignature;
  instId: InstrumentId;
}): string[] {
  return (setting.beatAdjustments || [])
    .map((value, beatIndex) => {
      if (!value) return '';
      if (instId == 'bass') {
        return capitalize(timingFormatter(value));
      }
      if (timeSignature == '3/4') {
        return `Beat ${beatIndex + 1} ${timingFormatter(value)}`;
      }
      if (timeSignature == '4/4') {
        return `${beatIndex == 0 ? 'Onbeats' : 'Offbeats'} ${timingFormatter(value)}`;
      }
      return '';
    })
    .filter((a) => a);
}
