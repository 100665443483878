import type { GuitarSettingsHash } from '@/band/instruments/guitar/GuitarSettingsHash';
import type { LinearizedMeasure } from '@/chart/LinearizedMeasure';

// TODO: Make this reflect probability setting a bit closer
export function assignGuitarBoomStrums({
  measures,
  settings,
}: {
  measures: readonly LinearizedMeasure[];
  settings: GuitarSettingsHash;
}) {
  const probability = settings.brushiness.boomStrumProbability;
  if (!probability) return;

  const eligibleCells = measures
    .flatMap((m) => m.cells)
    .filter((c) => (c.prev?.plans.guitar?.bassRun ?? { single: true }).single);
  if (probability == 1) {
    eligibleCells.forEach((cell) => ((cell.plans.guitar ??= {}).boomStrum = true));
  }
  if (probability < 1 && probability > 0.5) {
    eligibleCells
      .filter((c) => {
        const guitarChord = c.beats[0].plans.guitar?.chord;
        if (!guitarChord) return false;
        if (c.layout.cellIndex === 0) return true;
        if (c.repeated && c.layout.barPos > 0)
          return c.measure.cells[0].plans.guitar?.boomStrum && Math.random() < 0.7;
        switch (guitarChord.shapeChord.rootNote) {
          case 'G':
            return c.repeated ? Math.random() < 0.7 : true;
            return c.repeated ? Math.random() < (c.layout.column === 0 ? 0.9 : 0.6) : true;
          case 'A':
            return c.repeated ? Math.random() < (c.layout.column === 0 ? 0.8 : 0.5) : true;
          case 'C':
            return c.repeated ? Math.random() < (c.layout.column === 0 ? 0.4 : 0.3) : true;
          case 'D':
            return c.repeated ? false : c.layout.column === 0 ? Math.random() < 0.2 : false;
          case 'E':
          case 'F':
            return c.repeated ? Math.random() < 0.7 : true;
            return c.repeated ? Math.random() < (c.layout.column === 0 ? 0.8 : 0.4) : true;
          default:
            return false;
        }
      })
      .forEach((cell) => ((cell.plans.guitar ??= {}).boomStrum = true));
  }
  if (probability < 0.5 && probability > 0) {
    eligibleCells
      .filter((c) => {
        const guitarChord = c.beats[0].plans.guitar?.chord;
        if (!guitarChord) return false;
        if (c.repeated && c.layout.cellIndex > 0) return false;
        if (c.repeated && c.layout.barPos > 0)
          return c.measure.cells[0].plans.guitar?.boomStrum && Math.random() < 0.5;
        switch (guitarChord.shapeChord.rootNote) {
          case 'G':
          case 'E':
          case 'F':
            return c.layout.column === 0 || Math.random() < 0.6;
          case 'A':
          case 'C':
            return c.layout.column === 0 || Math.random() < 0.4;
          case 'D':
            return false;
          default:
            return false;
        }
      })
      .forEach((cell) => ((cell.plans.guitar ??= {}).boomStrum = true));
  }
}
