import { GodMode } from '@/library/GodMode';
import { Roles } from '@/Roles';

var attached = false;
var useKeyDown = /Firefox|AFT[A-X]/i.test(navigator.userAgent);
var isFireTV = /AFT[A-Z]/i.test(navigator.userAgent);

export const indexPageKeys = {
  attach() {
    if (!attached) {
      $(window).on(useKeyDown ? 'keydown' : 'keypress', indexPageKeyHandler);
      $(window).on('keydown', indexPageContainerKeyHandler);
      $('.indexPage-main').on('keydown', indexPageContainerKeyHandler);
    }
    attached = true;
  },

  detach() {
    $(window).off(useKeyDown ? 'keydown' : 'keypress', indexPageKeyHandler);
    $(window).off('keydown', indexPageContainerKeyHandler);
    $('.indexPage-main').off('keydown', indexPageContainerKeyHandler);
    attached = false;
  },
};

function indexPageContainerKeyHandler(event) {
  if (Modal.visible()) return;
  const key = event.key;
  if (!key) return true;

  if (key == 'ArrowUp' || key == 'ArrowDown') {
    const down = key == 'ArrowDown';

    const $arrowable = $(
      '.js-songSearchBoxInput, .songIndexItem-link:visible, .js-songListTitle, .js-reloadPageLink'
    );
    if (!document.activeElement || document.activeElement == document.body) {
      $arrowable.first().focus();
      return false;
    }

    const arrowableIdx = $arrowable.index(document.activeElement);
    if (arrowableIdx !== -1) {
      $arrowable
        .eq(Math.min($arrowable.length - 1, Math.max(0, arrowableIdx + (down ? 1 : -1))))
        .focus();
      return false;
    }

    const $tabbable = $('input:visible, a[href]:visible, button:visible, [tabindex=0]');
    const tabbableIdx = $tabbable.index(document.activeElement);
    if (tabbableIdx !== -1) {
      $tabbable
        .eq(Math.min($tabbable.length - 1, Math.max(0, tabbableIdx + (down ? 1 : -1))))
        .focus();
      return false;
    }
  }

  if (key == 'ArrowLeft' || key == 'ArrowRight') {
    if ($(event.target).is('input, select, textarea')) return true;
    const down = key == 'ArrowRight';

    const $tabbable = $('input:visible, a[href]:visible, button:visible, [tabindex=0]');
    const tabbableIdx = $tabbable.index(document.activeElement);
    if (tabbableIdx !== -1) {
      $tabbable
        .eq(Math.min($tabbable.length - 1, Math.max(0, tabbableIdx + (down ? 1 : -1))))
        .focus();
      return false;
    }
  }
}

function indexPageKeyHandler(event) {
  if (Modal.visible()) return;
  if (
    isFireTV &&
    (event.originalEvent.code === 'ArrowUp' || event.originalEvent.keyIdentifier === 'Up')
  ) {
    $('.indexPage-main').scrollTop($('.indexPage-main').scrollTop() - 60);
    return false;
  }
  if (
    isFireTV &&
    (event.originalEvent.code === 'ArrowDown' || event.originalEvent.keyIdentifier === 'Down')
  ) {
    $('.indexPage-main').scrollTop($('.indexPage-main').scrollTop() + 60);
    return false;
  }
  const key = event.key;

  /* Don't capture keystrokes in text boxes */
  if ($(event.target).is('input, select, textarea')) return true;

  // if ( key == 'spacebar' ) {
  //   event.preventDefault();
  // }

  if ((key == 'Escape' || key == '/') && !event.ctrlKey && !event.altKey && !event.metaKey) {
    $('.js-songSearchBoxInput').focus().trigger('input').trigger('select');
    scrollToTop();
    event.preventDefault();
  }

  if (key == 'G' && Roles.userHasPermission(Meteor.userId(), 'songs.indexAllUsersSongs')) {
    GodMode.enable();
    return false;
  }

  if (
    key == 'M' &&
    event.ctrlKey &&
    /(^stegalldesigns|@strummachine.com|@playingbyear.com|iigenta|hayes$)/.test(
      Meteor.user().emails[0].address
    )
  ) {
    Meteor.call('user.milestones.resetAll');
    return false;
  }

  if (/^[a-z#*]$/i.test(key) && !event.ctrlKey && !event.altKey && !event.metaKey) {
    // put it in the search box
    $('.js-songSearchBoxInput')
      .focus()
      .val($('.js-songSearchBoxInput').val() + key)
      .trigger('input');
    scrollToTop();
    event.preventDefault();
  }
}

function scrollToTop() {
  $('.indexPage-main').animate({ scrollTop: 0 }, { duration: 300 });
}
