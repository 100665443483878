import { Crnt } from '@/Crnt';
import UserPreferences from '@/user/UserPreferences';
import { UserProfile } from '@/user/UserProfile';

Template.clickTrackMenu.onRendered(() => {
  const instance = Template.instance();
  instance.autorun(() => {
    const clickDisabled = !UserPreferences.get('clickEnabled');
    instance.$('li').not('.js-clickEnabled-container').toggleClass('disabled', clickDisabled);
    instance.$('.songOptionHeading').toggleClass('disabled', clickDisabled);
    instance.$('.js-toggleBand').toggleClass('disabled', clickDisabled);
  });
  UserProfile.recordMilestone('CLICK_TRACK_MENU_OPEN');
});

Template.clickTrackMenu.helpers({
  mutingBand() {
    return UserPreferences.get('clickMuteBand');
  },
  timeSigIs(sig) {
    return (Crnt.song()?.timeSignature() ?? '4/4') == sig;
  },
});

Template.clickTrackMenu.events({
  'click .js-toggleBand': function () {
    UserPreferences.set('clickMuteBand', !UserPreferences.get('clickMuteBand'));
  },
});
