import { Tracker } from 'meteor/tracker';
import { Crnt } from '@/Crnt';
import UserPreferences from '@/user/UserPreferences';

export function autoSpeedupReset(): void {
  Tracker.nonreactive(() => {
    const song = Crnt.song();
    if (!song) return;
    const startingBpm = song.state.autoSpeedupState.startingBpm();
    if (UserPreferences.get('asReset') && song.prefs.autoSpeedup.enabled() && startingBpm) {
      // Idea: don't save this preference if it's an auto thing.
      song.prefs.setBpm(startingBpm);
    }
    song.state.autoSpeedupState.setStartingBpm(null);
  });
}
