<script lang="ts">
  import PointerTracker from 'pointer-tracker';
  import { createEventDispatcher, onDestroy, onMount } from 'svelte';

  const dispatch = createEventDispatcher<{
    update: { x: number; y: number; r: number; theta: number };
  }>();

  interface Props {
    size?: number;
    radialCoordinates?: [number, number];
    poleTurns?: number[];
    tweenThumb?: boolean;
  }

  let {
    size = 200,
    radialCoordinates = [0, 0],
    poleTurns = [],
    tweenThumb = false,
  }: Props = $props();

  let innerPaddingPercent = $derived(100 * (16 / size));

  const [r, theta] = $derived(radialCoordinates);
  let x = $derived(0.5 + 0.5 * r * Math.cos(2 * Math.PI * theta));
  let y = $derived(0.5 + 0.5 * r * Math.sin(2 * Math.PI * theta));

  let container: HTMLElement;
  let thumb: HTMLElement;

  function thumbMovedTo(newX: number, newY: number) {
    const x = 2 * (newX - 0.5);
    const y = 2 * (newY - 0.5);
    const r = Math.min(1, Math.sqrt(x * x + y * y));
    const theta = Math.atan2(y, x) / (2 * Math.PI);
    dispatch('update', { x, y, r, theta });
  }

  let pointerTracker: PointerTracker;
  onMount(() => {
    let viewAnchor: [number, number, number, number] = [0, 0, 0, 0];
    pointerTracker = new PointerTracker(container, {
      start: (_, event: TouchEvent | MouseEvent) => {
        event.preventDefault();
        const rect = container.getBoundingClientRect();
        viewAnchor = [rect.left || rect.x, rect.top || rect.y, rect.width, rect.height];
        const [x, y, w, h] = viewAnchor;
        const clientX = 'clientX' in event ? event.clientX : event.touches[0]?.clientX;
        const clientY = 'clientY' in event ? event.clientY : event.touches[0]?.clientY;
        if (clientX && clientY) {
          const newX = w ? (clientX - x) / w : 0;
          const newY = h ? (clientY - y) / h : 0;
          if (newX !== x || newY !== y) thumbMovedTo(newX, newY);
        }
        return true;
      },
      move: (_, changedPointers) => {
        const pointer = changedPointers[0];
        if (pointer) {
          const [x, y, w, h] = viewAnchor;
          const newX = w ? (pointer.clientX - x) / w : 0;
          const newY = h ? (pointer.clientY - y) / h : 0;
          if (newX !== x || newY !== y) thumbMovedTo(newX, newY);
        }
      },
    });
  });

  onDestroy(() => pointerTracker?.stop());
</script>

<div
  bind:this={container}
  class="fancy-shadow group relative touch-none select-none rounded-full border-[3px] border-primary-500 bg-muted-50"
  style="width: {size}px; height: {size}px;"
>
  <div
    class="absolute left-1/2 top-1/2 h-[17%] w-[17%] -translate-x-1/2 -translate-y-1/2 rounded-full border-2 border-primary-100"
  ></div>
  <div
    class="absolute left-1/2 top-1/2 h-[38%] w-[38%] -translate-x-1/2 -translate-y-1/2 rounded-full border-2 border-primary-100"
  ></div>
  <div
    class="absolute left-1/2 top-1/2 h-[59%] w-[59%] -translate-x-1/2 -translate-y-1/2 rounded-full border-2 border-primary-100"
  ></div>
  <div
    class="absolute left-1/2 top-1/2 h-[80%] w-[80%] -translate-x-1/2 -translate-y-1/2 rounded-full border-2 border-primary-100"
  ></div>

  {#each poleTurns as turn, index (index)}
    <div
      class="absolute left-1/2 top-1/2"
      style="transform: translate(-50%, -50%) rotate({turn + 0.25}turn);"
    >
      <div class="h-6 w-[2px] -translate-y-[60px] bg-muted-200"></div>
    </div>
  {/each}

  <div
    bind:this={thumb}
    class="pointer-events-auto absolute isolate h-12 w-12 -translate-x-1/2 -translate-y-1/2 cursor-pointer touch-none rounded-full border-[3px] border-primary-500 bg-primary-300 shadow duration-150 group-hover:transition-colors dsktp:hover:border-primary-600 dsktp:hover:bg-primary-400"
    class:transition-none={!tweenThumb}
    class:transition-all={tweenThumb}
    style="left: {x * (100 - innerPaddingPercent) + innerPaddingPercent / 2}%;
           top: {y * (100 - innerPaddingPercent) + innerPaddingPercent / 2}%;"
  ></div>
</div>

<style>
  .fancy-shadow {
    box-shadow:
      0px 0px 20px 4px rgba(15, 79, 128, 0.25) inset,
      0px -1px 2px 0px rgba(0, 0, 0, 0.15),
      0px 1px 2px 0px rgba(255, 255, 255, 0.3),
      0px 12px 16px 0px rgba(0, 0, 0, 0.17),
      0px -2px 16px 13px #fff;
  }
</style>
