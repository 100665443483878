import { strict as assert } from 'assert';
import type { CircularStrumLandscape } from '@/band/instruments/CircularStrumLandscape';
import type { InstrumentCore } from '@/band/instruments/InstrumentCore';
import { ChopMandolinCore } from '@/band/instruments/mandolin/cores/ChopMandolinCore';
import { standardChopPatternLandscape } from '@/band/instruments/mandolin/standardChopPatternLandscape';

export function patternLandscapeForMandolinCore(core: InstrumentCore): CircularStrumLandscape {
  const landscape = core == ChopMandolinCore ? standardChopPatternLandscape : undefined;
  assert(landscape, `No pattern landscape for core ${core.id}`);
  return landscape;
}
