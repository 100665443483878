import { strict as assert } from 'assert';
import type { BandPreset } from '@/band/presets/BandPreset';
import { migrateSerializedBandSettings } from '@/band/settings/migrateSerializedBandSettings';

export function createUserPreset(record: SerializedBandPreset): BandPreset {
  assert(record.timeSignature, 'Null time signature on BandPreset instantiation');
  return Object.freeze({
    id: record._id,
    name: record.name ?? 'Untitled Preset',
    timeSignature: record.timeSignature,
    band: migrateSerializedBandSettings(record.band ?? {}),
  } as BandPreset);
}
