<script lang="ts">
  import { onMount } from 'svelte';
  import type { Instance as TippyInstance } from 'tippy.js';
  import tippyJs from 'tippy.js';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import UserPreferences from '@/user/UserPreferences';

  let enabled: boolean = $state(false);
  trackerEffect(() => (enabled = UserPreferences.get('haye')));

  let buttonElement: HTMLElement;
  let tippyContentElement: HTMLElement;
  let tooltipTippy: TippyInstance | undefined = $state();
  let statusTippy: TippyInstance | undefined = $state();
  let statusTippyTimeout: number;

  onMount(() => {
    tooltipTippy = tippyJs(buttonElement, {
      placement: 'top',
      delay: [400, 50],
      hideOnClick: false,
      allowHTML: true,
      content: `Hear As You Edit`,
    });

    statusTippy = tippyJs(buttonElement, {
      animation: 'scale-subtle',
      content: tippyContentElement,
      duration: [300, 150],
      hideOnClick: false,
      interactive: true,
      // offset: [0, 0],
      placement: 'top-end',
      role: '',
      trigger: 'manual',
    });
    return () => {
      tooltipTippy?.destroy();
      statusTippy?.destroy();
    };
  });
</script>

<button
  class="btn _button flex size-8 items-center justify-center"
  aria-label="Hear As You Edit"
  aria-pressed={enabled}
  bind:this={buttonElement}
  onfocus={() => tooltipTippy?.enable()}
  onpointerenter={() => {
    tooltipTippy?.enable();
  }}
  onclick={() => {
    tooltipTippy?.hide();
    tooltipTippy?.disable();
    UserPreferences.set('haye', !enabled);
    statusTippy?.show();
    clearTimeout(statusTippyTimeout);
    statusTippyTimeout = window.setTimeout(
      () => statusTippy?.hide(),
      UserPreferences.get('haye') ? 4000 : 1500
    );
  }}
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="currentColor"
    viewBox="0 0 20 20"
  >
    {#if enabled}
      <path
        d="M2.21262 3.05049c4.65-6.6375 15.19998-1.4125 12.66248 6.35-.5 1.62501-1.5125 3.20001-3.0875 4.07501-1.3125.725-2.39998 1.6875-2.99998 3.0375-.3375.65-.5625 1.45-1.1625 2.2-1.2375 1.3875-3.55 1.8125-4.925.5-.65-.6375-.975-1.4375-1.2-2.225-.1-.3625.4375-.525.5625-.1875.275.7.6375 1.3875 1.1625 1.825.9875.9 2.85.3625 3.55-.575.375-.5125.575-1.275.9125-2.0375.65-1.5875 2-2.875 3.42498-3.7 1.2125-.675 2.05-1.9375 2.475-3.31251 2.1625-6.6875-6.64998-10.9625-10.98748-5.675-.175.25-.5625-.025-.3875-.275Z"
      />
      <path
        d="M8.71248 3.18788c-3.4625-.55-6.3125 2.6-4.8625 5.8625.0375.0875.15.1375.2375.1.05-.0125.1125-.0375.1625-.05.1-.0375.15-.1375.125-.2375-.1-.3375-.1625-.675-.175-1.0125 0-.0375.025-.075.075-.075 1.0875-.0375 2.3375.5 1.9 1.6875-.425 1.16252-2.3875 1.55002-3.2625 2.46252a.15844.15844 0 0 0 0 .225c.0375.05.0875.1125.125.1625.05.075.1625.0875.2375.0375 1.3-.8125 3.0125-.925 3.7875-2.45002.8375-1.8375-.9625-3.125-2.7-2.875-.05 0-.0875-.0375-.075-.0875.1875-1.0125.8125-1.9125 1.8625-2.3375 3.2375-1.575 6.17502 1.95 3.825 4.7-1.4375 1.58752-3.175 3.06252-3.7375 5.06252-.025.0875.025.1875.125.225l.175.05c.0875.025.1875-.025.2125-.1125.7125-1.7875 1.2-2.2375 3.91252-4.425 2.7875-2.25002 1.3-6.56252-1.92502-6.93752l-.025.025ZM12.1375 17.6127c2.7625-.6625 4.7625-2.65 5.4375-5.4125.025-.0875.1125-.1375.1875-.1125.825 2.975-2.575 6.325-5.6 5.95-.2375-.0125-.2625-.375-.0375-.425h.0125Z"
      />
      <path
        d="M12.025 15.6504c1.7875-.5125 3.0625-1.7875 3.5625-3.6125.025-.0875.1125-.1375.2-.1125.7375 2.0125-1.65 4.4-3.725 4.1125-.2125-.0125-.2375-.3375-.0375-.3875Zm.4125 3.9869c3.6375-.725 6.375-3.45 7.1125-7.0875.0375-.175.3-.15.3125.025.3375 3.9375-3.4625 7.725-7.4 7.375-.1625 0-.1875-.2625-.025-.3v-.0125Z"
      />
    {:else}
      <path
        d="m3.86255 1.33692.4875.475c4.5625-2.7 11.14995 1.3 9.24995 7.2-.1375.4625-.35.89999-.5875 1.32498l.975.9625c.375-.6.6875-1.2375.8875-1.88748 2.2625-6.9625-5.93745-11.8625-11.01245-8.075ZM10.2626 12.9004c-1.08753.8-2.05003 1.85-2.56253 3.125-.3375.775-.525 1.5375-.9125 2.0375-.7.9375-2.5625 1.475-3.55.575-.525-.45-.8875-1.125-1.1625-1.825-.125-.3375-.6625-.175-.5625.1875.225.7875.55 1.5875 1.2 2.225 1.3625 1.3125 3.6875.9 4.925-.5.6-.75.8125-1.55 1.1625-2.2.5125-1.1375 1.38753-2 2.43753-2.675l-.9625-.9375-.0125-.0125ZM1.03748.62446c4.275 3.7875 8.325 7.8125 12.31252 11.88754 1.3375 1.3625 2.6375 2.7375 3.9375 4.1375.1375.1375.125.375-.0125.5s-.35.125-.475 0c-2.8375-2.5375-5.5875-5.175-8.31252-7.82504-2.725-2.65-5.4125-5.35-7.975-8.1625-.3125-.3375.1875-.85.525-.5375Z"
      />
      <path
        d="M8.71245 3.18755c-.95-.15-1.8625-.0125-2.6375.325l.85.8375c2.4125-.5875 4.40005 1.5 3.75005 3.6875l.95.9375c1.3-2.325-.175-5.45-2.91255-5.775v-.0125ZM4.27493 7.78789c.3 0 .6125.025.9.1l-1.575-1.55c-.2125.8375-.1625 1.775.2625 2.725.0375.0875.15.1375.2375.1.05-.0125.1125-.0375.1625-.05.1-.0375.15-.1375.125-.2375-.1-.3375-.1625-.675-.175-1.0125 0-.0375.025-.075.075-.075h-.0125ZM8.36238 11.012c-.9375 1.025-1.7875 2.0875-2.1375 3.375-.025.0875.025.1875.125.225l.175.05c.0875.025.1875-.025.2125-.1125.525-1.3125.925-1.9125 2.1875-3l-.55-.5375h-.0125ZM6.2501 8.94995c0 .1625 0 .325-.075.525-.425 1.16255-2.3875 1.55005-3.2625 2.46255a.15844.15844 0 0 0 0 .225c.0375.05.0875.1125.125.1625.05.075.1625.0875.2375.0375 1.3-.8125 3.0125-.925 3.7875-2.45005.025-.05.025-.0875.0375-.1375l-.8375-.825h-.0125Z"
      />
    {/if}
  </svg>
</button>
<div hidden>
  <div class="max-w-[10em] text-center" bind:this={tippyContentElement}>
    Hear As You Edit <span class="whitespace-nowrap">is now</span>
    <strong>{enabled ? 'enabled' : 'disabled'}</strong>.
    {#if enabled}
      <div class="mt-2 border-t border-gray-500 pt-2 text-[13px] opacity-70">
        Chords will be strummed when selected or changed.
      </div>
    {/if}
  </div>
</div>

<style>
  ._button {
    padding: 0;
    color: var(--red-500);

    &:hover {
      color: var(--red-600);
      /* background-color: hsla(206, 20%, 70%, 0.2); */
    }
    &:active {
      color: var(--red-700);
    }
    &[aria-pressed='true'] {
      color: hsla(122, 90%, 35%, 1);
      &:hover {
        color: hsla(122, 100%, 30%, 1);
        /* background-color: hsla(122, 60%, 75%, 0.3); */
      }
      &:hover {
        color: hsla(122, 100%, 25%, 1);
        /* background-color: hsla(122, 60%, 75%, 0.4); */
      }
    }
  }

  svg {
    display: block;
  }
</style>
