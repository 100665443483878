Template.userTable.onRendered(() => {
  const instance = Template.instance();
  Meteor.setTimeout(() => {
    instance.$('table').stupidtable();
  }, 1000);
});

Template.userTable.helpers({
  emailForUser(user) {
    return user.emails[0].address;
  },
  integerDate(date) {
    return +date;
  },
  userStatus(user) {
    return user.subscription.status;
  },
});
