import { Crnt } from '@/Crnt';
import { MusicLibrary } from '@/library/MusicLibrary';
import { Roles } from '@/Roles';

Template.songMetadataAbout.onCreated(() => {
  const instance = Template.instance();
  instance.editing = new ReactiveVar();
});

Template.songMetadataAbout.onRendered(() => {
  const instance = Template.instance();
  Tracker.afterFlush(() => {
    instance.autorun(() => {
      if (instance.editing.get()) {
        Tracker.afterFlush(() => {
          instance.$('.js-editBox').val(Crnt.song()?.aboutText()).focus();
        });
      }
    });
  });
  instance.autorun(() => {
    Crnt.song()?.editMode(); // reactive hook
    instance.editing.set(!Tracker.nonreactive(() => Crnt.song()?.aboutText()));
  });
});

Template.songMetadataAbout.helpers({
  isPublicSong() {
    return Crnt.song() && !Crnt.song()?.userId();
  },
  isOwnSong() {
    return Meteor.userId() && Crnt.song()?.userId() == Meteor.userId();
  },
  editable() {
    return (
      Meteor.userId() &&
      (Crnt.song()?.userId() == Meteor.userId() ||
        Roles.userHasPermission(Meteor.userId(), 'songs.updatePublic'))
    );
  },
  editing() {
    return Template.instance().editing.get();
  },
  aboutText() {
    return Crnt.song()?.aboutText();
  },
  maxLengthStatus() {
    const length = (Crnt.song()?.aboutText() || '').length;
    if (length > 50) return `${length}/140`;
  },
});

Template.songMetadataAbout.events({
  'keydown .js-editBox': function (event, instance) {
    if (event.key == 'Enter' || event.key == 'Escape') {
      event.preventDefault(); // disable newline
      instance.editing.set(false);
    }
  },
  'click .js-enterEditMode': function (event, instance) {
    instance.editing.set(true);
    return false;
  },
  'input .js-editBox': function (event, instance) {
    Crnt.song()?.setAboutText(instance.find('textarea').value);
  },
  'blur .js-editBox': function (event, instance) {
    if (!Crnt.song()?.editMode()) {
      MusicLibrary.songs.update(Crnt.song());
    }
  },
});
