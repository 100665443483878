<script lang="ts">
  import { findMatchingInstrumentPreset } from '@/band/instruments/findMatchingInstrumentPreset';
  import type { Instrument } from '@/band/instruments/Instrument';
  import type { InstrumentCore } from '@/band/instruments/InstrumentCore';
  import PanelOpenerChevron from '@/band/ui/components/PanelOpenerChevron.svelte';
  import RhythmPatternVisualization from '@/band/ui/components/RhythmPatternVisualization.svelte';
  import InstrumentStylePanel from '@/band/ui/panels/InstrumentStylePanel.svelte';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import { MultiPanelModalController } from '@/ui/settings/MultiPanelModalController.svelte';
  import { decapitalize } from '@/utilities/decapitalize';

  interface Props {
    instrument: Instrument<Record<string, never>, never>;
    timeSignature: TimeSignature;
  }

  const { instrument, timeSignature }: Props = $props();

  const modalController = MultiPanelModalController.current();

  let core: InstrumentCore = $state() as InstrumentCore;
  trackerEffect(() => (core = instrument.coreOrFallbackForTimeSignature(timeSignature)));

  let settings: ReturnType<typeof instrument.settings> = $state({});
  trackerEffect(() => (settings = instrument.settings()));

  const builtInPreset = $derived(findMatchingInstrumentPreset(core, settings)?.name);
  const styleName = $derived(builtInPreset ?? `Custom ${decapitalize(core.title)}`);

  let viz: (RhythmPatternVisualizationCommand | null)[] = $state([]);
  trackerEffect(() => {
    viz = instrument
      .coreOrFallbackForTimeSignature(timeSignature)
      //@ts-expect-error This does exist... need to revamp these types someday
      ?.generateViz(instrument.settings());
  });

  function openStylePanel() {
    modalController.zoomInto({
      component: InstrumentStylePanel,
      props: { instId: instrument.id },
      title: 'Rhythm Styles',
    });
  }
</script>

<button
  class="colors-panelOpener relative block w-full text-left leading-[1.35]"
  onclick={openStylePanel}
>
  {#if styleName}
    <div class="px-6 pb-1 pt-4 text-center font-medium leading-5 text-primary-600">
      {styleName}
    </div>
  {/if}
  {#if viz && viz.length > 0}
    <div class="flex w-full justify-center px-4 py-2">
      <div
        class="rounded-[7px] border-2 border-muted-200"
        style="box-shadow: 1px 2px 10px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.05);"
      >
        <RhythmPatternVisualization actions={viz} scale={1.75} {timeSignature} />
      </div>
    </div>
  {/if}

  <div class="relative py-[0.625rem] pl-3 pr-6">
    <span class="mr-1 text-primary-600">Pattern:</span>
    {core.title.replace('with ', 'w/')}
    <PanelOpenerChevron direction="right" />
  </div>
</button>
