import { FlowRouter } from 'meteor/ostrio:flow-router-extra';

Template.webEmailButton.onCreated(() => {
  Template.instance().autorun(() => {
    const emailMatch = (Session.get('email-inbox-to-open') || '').match(/@(.+)$/);
    if (emailMatch) {
      let friendlyName, accessUrl;
      const domain = emailMatch[1].toLowerCase();
      accessUrl = 'https://' + domain;
      const domainFirstPart = domain.match(/^(.+?)\./)[1];
      // TODO: Switch from manual parsing to ZeroBounce; see https://growth.design/sniper-link#implementation-email-validation-service
      if (/gmail|gmx|googlemail|google/.test(domainFirstPart)) {
        friendlyName = 'Gmail';
        // Gmail's magic URL broke on May 28, 2020, but is working as of Oct 1, 2023.
        // TODO: Re-check Gmail's search URL to ensure that it still loads
        accessUrl =
          'https://mail.google.com/#search/from%3A(support%40strummachine.com)+in%3Aanywhere';
      }
      if (/aol|wow|love|ygm/.test(domainFirstPart)) {
        friendlyName = 'AOL Mail';
        accessUrl = 'https://mail.aol.com/';
      }
      if (/comcast/.test(domainFirstPart)) {
        friendlyName = 'Comcast Mail';
        accessUrl = 'https://connect.xfinity.com/appsuite/';
      }
      if (/yahoo|ymail|rocketmail/.test(domainFirstPart)) {
        friendlyName = 'Yahoo! Mail';
        accessUrl = 'https://mail.yahoo.com/d/search/keyword=from%3Asupport%40strummachine.com';
      }
      if (/hotmail/.test(domainFirstPart)) {
        friendlyName = 'Hotmail';
      }
      if (/live/.test(domainFirstPart)) {
        friendlyName = 'Live.com Webmail';
        accessUrl = 'https://live.com/';
      }
      if (/outlook|msn/.test(domainFirstPart)) {
        friendlyName = 'Outlook Webmail';
        accessUrl = 'https://outlook.com/';
      }
      Template.instance().friendlyName = friendlyName;
      Template.instance().accessUrl = accessUrl;
    }
    Template.instance().clicked = new ReactiveVar(false);
  });
});

Template.webEmailButton.events({
  'click .js-openWebEmail': function () {
    Template.instance().clicked.set(true);
  },
});

Template.webEmailButton.helpers({
  friendlyName() {
    return Template.instance().friendlyName;
  },
  accessUrl() {
    return Template.instance().accessUrl;
  },
  showPostVerify() {
    return FlowRouter.getRouteName() == 'signup-verify' && Template.instance().clicked.get();
  },
});
