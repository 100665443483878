import { strict as assert } from 'assert';
import type { InstrumentCustomizationUI } from '@/band/instruments/InstrumentCustomizationUI';
import { patternLandscapeForMandolinCore } from '@/band/instruments/mandolin/patternLandscapeForMandolinCore';
import type { MandolinChopAccentsSetting } from '@/band/instruments/mandolin/settings/MandolinChopAccentsSetting';
import type { MandolinChopDynamicsSetting } from '@/band/instruments/mandolin/settings/MandolinChopDynamicsSetting';
import type { MandolinSwingBackbeatSetting } from '@/band/instruments/mandolin/settings/MandolinSwingBackbeatSetting';
import type { MandolinSwingSustainSetting } from '@/band/instruments/mandolin/settings/MandolinSwingSustainSetting';
import type { MandolinTimingSetting } from '@/band/instruments/mandolin/settings/MandolinTimingSetting';
import { timingRowStatusGetter } from '@/band/instruments/timingRowStatusGetter';
import InstrumentTimingPanel from '@/band/ui/panels/InstrumentTimingPanel.svelte';
import MandolinChopAccentsPanel from '@/band/ui/panels/MandolinChopAccentsPanel.svelte';
import MandolinChopDynamicsPanel from '@/band/ui/panels/MandolinChopDynamicsPanel.svelte';
import MandolinSwingBackbeatPanel from '@/band/ui/panels/MandolinSwingBackbeatPanel.svelte';
import MandolinSwingSustainPanel from '@/band/ui/panels/MandolinSwingSustainPanel.svelte';
import { capitalize } from '@/utilities/capitalize';

export const mandolinCustomizationUI = {
  chopDynamics: <InstrumentCustomizationUI<MandolinChopDynamicsSetting>>{
    panel: { component: MandolinChopDynamicsPanel, title: 'Chop Preferences' },
    getRowStatus({ setting }) {
      const intensityText = ['soft', undefined, 'loud'][setting.intensityIndex];
      const sustainText = ['dry', 'mostly dry', 'voiced'][setting.sustainIndex];
      return {
        prefix: 'Chop:',
        text: capitalize([intensityText, sustainText].filter((a) => a).join(' and ')),
      };
    },
  },

  chopAccents: <InstrumentCustomizationUI<MandolinChopAccentsSetting>>{
    panel: { component: MandolinChopAccentsPanel, title: 'Rhythm Accents' },
    getRowStatus({ setting, core }) {
      assert(core, "Core required for chopAccents's getRowStatus");
      const landscape = patternLandscapeForMandolinCore(core);
      const text = setting.closestPresetForLandscape(landscape).name.replace('with ', 'w/');
      return { prefix: 'Accents:', text };
    },
  },

  swingSustain: <InstrumentCustomizationUI<MandolinSwingSustainSetting>>{
    panel: { component: MandolinSwingSustainPanel, title: 'Sustain' },
    getRowStatus({ setting }) {
      const same = setting.closestOnbeatPreset.value == setting.closestOffbeatPreset.value;
      return {
        prefix: 'Sustain:',
        text: same
          ? `${setting.closestOnbeatPreset.caption} (all beats)`
          : `${setting.closestOnbeatPreset.caption} / ${setting.closestOffbeatPreset.caption}`,
      };
    },
  },

  swingBackbeat: <InstrumentCustomizationUI<MandolinSwingBackbeatSetting>>{
    panel: { component: MandolinSwingBackbeatPanel, title: 'Backbeat' },
    getRowStatus({ setting }) {
      return {
        prefix: 'Backbeat:',
        text: setting.closestPreset.caption,
      };
    },
  },

  timing: <InstrumentCustomizationUI<MandolinTimingSetting>>{
    panel: {
      component: InstrumentTimingPanel,
      props: { instId: 'mandolin' },
      title: 'Timing Tweaks',
    },
    getRowStatus: timingRowStatusGetter('mandolin'),
  },
} as const;
