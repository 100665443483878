import AudioManager from '@/audio/engine/AudioManager';
import { FMBSystem } from '@/audio/engine/fmb/FMBSystem';
import { MuteSwitchBypassSetting } from '@/audio/engine/web/MuteSwitchBypassSetting';
import DropdownWatcher from '@/ui/dropdowns/DropdownWatcher';
import { setLocalStorageSafe } from '@/utilities/safe-local-storage';

const isIOS =
  /iP(hone|ad|od)/i.test(navigator.userAgent) ||
  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

Template.advancedSettingsPanel.onRendered(() => {
  const instance = Template.instance();
  instance.autorun(
    DropdownWatcher({
      element: instance.$('.js-muteSwitchBypassingDropdown'),
      getValue: () => MuteSwitchBypassSetting.get(),
    })
  );
});

Template.advancedSettingsPanel.events({
  'click .js-muteSwitchBypassingDropdown a': function (event, instance) {
    MuteSwitchBypassSetting.set($(event.currentTarget).data('value'));
    event.preventDefault();
  },
  'click .js-toggleNativeAudio': function (event, instance) {
    const nativeSystem = 'fmb';
    setLocalStorageSafe(
      'sm.audio-mode',
      AudioManager.mode == 'cordova' ? 'web-audio-2' : nativeSystem
    );
    clientModules.splashScreen.show();
    window.location.reload();
    event.preventDefault();
  },
});

Template.advancedSettingsPanel.helpers({
  offlineMode() {
    return 'serviceWorker' in navigator && navigator.serviceWorker.controller;
  },
  audioTweaksAvailable() {
    const iPadOnMac = navigator.platform === 'MacIntel' && navigator.maxTouchPoints === 0;
    return isIOS && !iPadOnMac;
  },
  usingWebAudio() {
    return AudioManager.mode == 'web-audio';
  },
  usingFMOD() {
    return Meteor.isCordova && AudioManager.mode == 'cordova' && FMBSystem.available();
  },
  updateNeededForFMOD() {
    return !FMBSystem.available();
  },
  internalTestingUser() {
    return /(^stegalldesigns|@strummachine.com|@playingbyear.com|iigenta|hayes$)/.test(
      Meteor.user().emails[0].address
    );
  },
});
