<script lang="ts">
  import type { Section } from '@/chart/Section.svelte';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import Icon from '@/ui/icons/Icon.svelte';
  import TippyDropdown from '@/ui/TippyDropdown.svelte';

  interface Props {
    section: Section;
    sectionIndex: number;
  }

  let { section, sectionIndex }: Props = $props();

  let songSections: Section[] = $state([]);
  trackerEffect(() => (songSections = section.song.sections.reactive()));

  function deleteSection() {
    section.song.sections.delete(sectionIndex);
  }
</script>

<TippyDropdown>
  {#snippet button()}
    <button
      class="colors-button-danger flex rounded-full p-1.5"
      aria-label="{songSections.length > 1 ? 'Delete' : 'Clear'} section"
    >
      <Icon id="close" size={14} />
    </button>
  {/snippet}
  {#snippet menu({ tippyInstance })}
    <div>
      <button
        class="colors-button-danger rounded-[10px] px-4 py-2"
        onclick={() => {
          tippyInstance.hide();
          deleteSection();
        }}
      >
        {songSections.length > 1 ? 'Delete' : 'Clear'} section
      </button>
    </div>
  {/snippet}
</TippyDropdown>
