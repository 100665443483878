import type { BassOnbeatNotesSetting } from '@/band/instruments/bass/settings/BassOnbeatNotesSetting';
import type { BassOnbeatSustainSetting } from '@/band/instruments/bass/settings/BassOnbeatSustainSetting';
import type { BassOnbeatWaltzSustainSetting } from '@/band/instruments/bass/settings/BassOnbeatWaltzSustainSetting';
import type { BassTimingSetting } from '@/band/instruments/bass/settings/BassTimingSetting';
import type { InstrumentCustomizationUI } from '@/band/instruments/InstrumentCustomizationUI';
import { timingRowStatusGetter } from '@/band/instruments/timingRowStatusGetter';
import BassOnbeatNotesPanel from '@/band/ui/panels/BassOnbeatNotesPanel.svelte';
import BassOnbeatSustainPanel from '@/band/ui/panels/BassOnbeatSustainPanel.svelte';
import BassOnbeatWaltzSustainPanel from '@/band/ui/panels/BassOnbeatWaltzSustainPanel.svelte';
import InstrumentTimingPanel from '@/band/ui/panels/InstrumentTimingPanel.svelte';

export const bassCustomizationUI = {
  onbeatNotes: <InstrumentCustomizationUI<BassOnbeatNotesSetting>>{
    panel: { component: BassOnbeatNotesPanel, title: 'Bass Notes' },
    getRowStatus({ setting }) {
      return {
        prefix: 'Notes:',
        text: setting.rootOnly
          ? setting.rootOctaves == 'high'
            ? 'Root only (high octave)'
            : setting.rootOctaves == 'alternating'
              ? 'Roots (alternating octaves)'
              : 'Root only'
          : setting.walking
            ? 'Slow walk'
            : setting.altBass
              ? setting.leadingNoteFrequency
                ? `Root–Fifth (plus leading notes)`
                : setting.alternatingOctaves == 'high'
                  ? 'Root–Fifth (high octave)'
                  : setting.alternatingOctaves == 'low'
                    ? 'Root–Fifth (low octave)'
                    : setting.alternatingOctaves == 'random'
                      ? 'Root–Fifth (mix of octaves)'
                      : 'Root–Fifth (alternating)'
              : 'UNKNOWN',
      };
    },
  },

  onbeatSustain: <InstrumentCustomizationUI<BassOnbeatSustainSetting>>{
    panel: { component: BassOnbeatSustainPanel, title: 'Sustain' },
    getRowStatus({ setting }) {
      let text = `${(100 * setting.sustain).toFixed(0)}%`;
      if (setting.sustain == 0.5) text += ' (standard)';
      if (setting.sustain == 1) text += ' (full sustain)';
      return { prefix: 'Sustain:', text };
    },
  },

  onbeatWaltzSustain: <InstrumentCustomizationUI<BassOnbeatWaltzSustainSetting>>{
    panel: { component: BassOnbeatWaltzSustainPanel, title: 'Sustain' },
    getRowStatus({ setting }) {
      // let text = `${(100 * setting.sustain).toFixed(0)}%`;
      let text = !setting.sustain ? 'Almost none' : `${(3 * setting.sustain).toFixed(2)} beats`;
      if (Math.abs(setting.sustain - 0.666) < 0.01) text += ' (standard)';
      if (setting.sustain == 1) text += ' (full sustain)';
      return { prefix: 'Sustain:', text };
    },
  },

  timing: <InstrumentCustomizationUI<BassTimingSetting>>(<unknown>{
    panel: { component: InstrumentTimingPanel, props: { instId: 'bass' }, title: 'Timing Tweaks' },
    getRowStatus: timingRowStatusGetter('bass'),
  }),
} as const;
