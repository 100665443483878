import * as Sentry from '@sentry/browser';
import { strict as assert } from 'assert';
import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { eventTracker } from '@/browser/analytics/eventTracker';
import { Crnt } from '@/Crnt';
import { MusicLibrary } from '@/library/MusicLibrary';
import { Roles } from '@/Roles';
import { rpcMakeSongPublic } from '@/server/methods/songs/rpcMakeSongPublic';
import SongMenuManager from '@/ui/SongMenuManager';

Template.editSongMenu.helpers({
  songBelongsToCurrentUser() {
    const songUserId = Crnt.song()?.userId();
    return songUserId && songUserId == Meteor.userId();
  },

  songBelongsToAnotherUser() {
    const songUserId = Crnt.song()?.userId();
    return songUserId && songUserId != Meteor.userId();
  },

  songIsPublic() {
    const song = Crnt.song();
    return song && !song.userId();
  },

  showEdit() {
    return Crnt.song() && Roles.userHasPermission(Meteor.userId(), 'songs.update', Crnt.song());
  },

  showCopyOrNew() {
    return Crnt.song() && Roles.userHasPermission(Meteor.userId(), 'songs.insert');
  },

  showDelete() {
    return (
      Crnt.song()?.id() && Roles.userHasPermission(Meteor.userId(), 'songs.remove', Crnt.song())
    );
  },

  showMakePublic() {
    const song = Crnt.song();
    return (
      song &&
      Meteor.status().connected &&
      Roles.userHasPermission(Meteor.userId(), 'songs.insertPublic') &&
      song.userId() &&
      !song.editMode()
    );
  },
});

Template.editSongMenu.events({
  'click .js-editAction': function (event) {
    event.preventDefault();
    Meteor.defer(() => FlowRouter.go('edit-song', { songId: Crnt.song()?.id() })); // defer is needed to hide menu
  },

  'click .js-copyAction': function (event) {
    event.preventDefault();
    Meteor.defer(() => FlowRouter.go('copy-song', { songId: Crnt.song()?.id() })); // defer is needed to hide menu
  },

  'click .js-deleteAction': function (event) {
    event.preventDefault();
    bootbox.confirm({
      size: 'small',
      message: 'Are you sure you want to delete this song? There is no undo!',
      closeButton: false,
      buttons: {
        confirm: {
          label: 'Delete',
          className: 'btn-danger',
        },
      },
      async callback(result) {
        if (result) {
          const songId = Crnt.song()?.id();
          const name = Crnt.song()?.name();
          try {
            await MusicLibrary.songs.delete(songId);
            FlowRouter.go('song-index');
            Bert.alert(`“${name}” has been deleted.`, 'success');
            eventTracker.songDeleteFromEditMenu({ label: name });
          } catch (error) {
            Sentry.captureException(error);
            Bert.alert(error.message, 'danger');
          }
        }
      },
    });
  },

  'click .js-newAction': function (event) {
    event.preventDefault();
    Meteor.defer(() => FlowRouter.go('new-song')); // defer is needed to hide menu
  },

  'click .js-showNominationForm': function (event) {
    SongMenuManager.showMenu(null);
    FlowRouter.go('submit-song', { songId: Crnt.song()?.id() });
    return false;
  },

  'click .js-makePublicAction': async function (event) {
    event.preventDefault();
    const song = Crnt.song();
    assert(song, "Trying to make song public that isn't loaded?");
    var message =
      song.userId() && song.userId() == Meteor.userId()
        ? 'Make this song public?'
        : `Make ${`${song.userFirst()} ${song.userLast()}`.trim()}'s song public?`;
    if (window.confirm(message)) {
      try {
        await rpcMakeSongPublic({ songId: song._id });
        Bert.alert(`“${Crnt.song()?.name()}” is now public.`, 'success');
        Crnt.loadSong(await MusicLibrary.songs.fetchFull(song._id));
      } catch (error) {
        Sentry.captureException(error);
        Bert.alert(error.message, 'danger');
      }
    }
  },
});
