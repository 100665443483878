import { Crnt } from '@/Crnt';
import defineTippyDropdown from '@/ui/dropdowns/defineTippyDropdown';
import ScalarInputWithTrimmers from '@/ui/ScalarInputWithTrimmers.svelte';

defineTippyDropdown(Template.medleyBpmXMenu, {
  id: 'medley-bpm-menu',
});

Template.medleyBpmXMenu.helpers({
  bpm() {
    const song = Template.currentData().song;
    return Math.round(Crnt.medley().bpm() * song.medleyPrefs.bpmX());
  },

  bpmX() {
    const song = Template.currentData().song;
    return Math.floor(song.medleyPrefs.bpmX() * 100);
  },

  setValueFunc() {
    const song = Template.currentData().song;
    return (value) => {
      if (!value) value = 100;
      song.medleyPrefs.setBpmX(+value / 100);
    };
  },

  adjustFunc() {
    const song = Template.currentData().song;
    return (value) => {
      song.medleyPrefs.adjustBpmX(+value / 100);
    };
  },

  ScalarInputWithTrimmers() {
    return ScalarInputWithTrimmers;
  },
});
