<script lang="ts">
  import PrettySlider from '@/band/ui/components/PrettySlider.svelte';
  import { generatePathForHead, generatePathForTail } from '@/band/ui/components/rhythm-viz-paths';
  import RhythmPatternVisualization from '@/band/ui/components/RhythmPatternVisualization.svelte';
  import { vizDimensionsFromCommand } from '@/band/ui/components/vizDimensionsFromCommand';
  import PanelModalLayout from '@/band/ui/panels/PanelModalLayout.svelte';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';

  const bandMenu = getBandMenuState();

  let setting = $derived($bandMenu.guitarSettings.dampenedOnbeats);
  let sustain = $derived($bandMenu.guitarSettings.dampenedSustain.onbeatSustain);

  function beatPreviewSVG(_sustain: number, spread: number) {
    const params = {
      dir: 'd',
      biasY: 0.15 - 0.15 * spread,
      tail: spread * 0.6,
      thickness: 1,
    } as const satisfies RhythmPatternVisualizationCommand;
    const dimensions = vizDimensionsFromCommand(params, { topYPadded: 2, paddedHeight: 42 });
    return `<svg viewBox="0 0 18 36" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="1.5" width="30" height="50">
      <path
        fill="#999"
        stroke="#999"
        stroke-width="1px"
        d="M9,${dimensions.tipY - dimensions.headY}
          ${
            !spread
              ? ''
              : generatePathForTail({
                  direction: params.dir,
                  stemWidth: dimensions.stemWidth,
                  height: dimensions.tailHeight,
                })
          }"
      />
      <path
        fill="#999"
        stroke="#999"
        stroke-width="1px"
        d="M9,${dimensions.tipY}
          ${generatePathForHead({
            direction: params.dir,
            headWidth: dimensions.headWidth,
          })}"
      />
    </svg>`;
  }
</script>

<PanelModalLayout>
  {#snippet header()}
    <header class="panelHeader">
      This changes what the guitar plays on <span class="whitespace-nowrap">beats 1 and 3</span>
      of the measure.
    </header>
  {/snippet}

  {#snippet main()}
    <div>
      <div class="mb-8 mt-8 flex justify-center">
        <div
          style="box-shadow: 4px 6px 12px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.05), -4px -6px 12px 0px rgba(255, 255, 255, 0.95); border-radius: 12px; overflow: hidden; border: solid 2px var(--gray-100);"
        >
          <RhythmPatternVisualization
            actions={$bandMenu.guitar
              .coreOrFallbackForTimeSignature($bandMenu.timeSignature)
              .generateViz($bandMenu.guitarSettings)}
            timeSignature={$bandMenu.timeSignature}
            scale={1.7}
            highlightCurrentBeat={true}
            showBeatNumbers={false}
          />
        </div>
      </div>

      <div class="pt-4">
        <div class="mx-auto w-[14rem]">
          <PrettySlider
            min={0}
            max={2}
            step={1}
            value={setting.spread * 2}
            tickCount={3}
            tickAboveBelow="above"
            thumbDiameter={2.75}
            tickHeight={0.3}
            on:change={({ detail: value }) => {
              $bandMenu.guitar.setSetting('dampenedOnbeats', setting.withSpread(value / 2));
            }}
          />
        </div>

        <div
          class="relative z-10 mx-auto grid w-[16.9rem] justify-around text-center text-xs font-extrabold uppercase tracking-widest text-gray-300 saturate-0"
          style="grid-template-columns: repeat(3, 1fr);"
        >
          <div class="flex flex-col items-center">
            <!-- svelte-ignore a11y_click_events_have_key_events -->
            <!-- svelte-ignore a11y_no_static_element_interactions -->
            <div
              class="flex cursor-pointer items-center justify-center {setting.spread != 0
                ? 'opacity-50 hover:opacity-75'
                : ''}"
              onclick={() => $bandMenu.guitar.setSetting('dampenedOnbeats', setting.withSpread(0))}
            >
              {@html beatPreviewSVG(sustain, 0)}
            </div>
            <div class="bg-panelBg px-2" class:text-gray-600={setting.spread == 0}>
              Bass<br />Note
            </div>
          </div>

          <div class="flex flex-col items-center">
            <!-- svelte-ignore a11y_click_events_have_key_events -->
            <!-- svelte-ignore a11y_no_static_element_interactions -->
            <div
              class="flex cursor-pointer items-center justify-center {setting.spread != 0.5
                ? 'opacity-50 hover:opacity-75'
                : ''}"
              onclick={() =>
                $bandMenu.guitar.setSetting('dampenedOnbeats', setting.withSpread(0.5))}
            >
              {@html beatPreviewSVG(sustain, 0.5)}
            </div>
            <div class="bg-panelBg px-2" class:text-gray-600={setting.spread == 0.5}>
              Short<br />Strum
            </div>
          </div>
          <div class="flex flex-col items-center">
            <!-- svelte-ignore a11y_click_events_have_key_events -->
            <!-- svelte-ignore a11y_no_static_element_interactions -->
            <div
              class="flex cursor-pointer items-center justify-center {setting.spread != 1
                ? 'opacity-50 hover:opacity-75'
                : ''}"
              onclick={() => $bandMenu.guitar.setSetting('dampenedOnbeats', setting.withSpread(1))}
            >
              {@html beatPreviewSVG(sustain, 1)}
            </div>
            <div class="bg-panelBg px-2" class:text-gray-600={setting.spread == 1}>
              Full<br />Strum
            </div>
          </div>
        </div>
        <div class="relative -top-4 border-y border-b-white border-t-gray-200"></div>
        <div class="pb-6"></div>
      </div>
    </div>
  {/snippet}
</PanelModalLayout>
