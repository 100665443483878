<script lang="ts">
  import BandPanelOpenerForSetting from '@/band/ui/components/BandPanelOpenerForSetting.svelte';
  import PanelSettingSection from '@/band/ui/components/PanelSettingSection.svelte';
  import { instrumentCustomizationSections } from '@/band/ui/instrumentCustomizationSections';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';

  const bandMenu = getBandMenuState();

  interface Props {
    instId: InstrumentId;
  }

  let { instId }: Props = $props();

  let instrument = $derived($bandMenu.instruments[instId]);
  let timeSignature = $derived($bandMenu.timeSignature);

  let settingsKeysToShow: string[] = $state([]);
  trackerEffect(
    () => (settingsKeysToShow = ['mix', ...instrument.relevantSettingsKeys(timeSignature)])
  );

  let sectionsInUse = $derived(
    [instrumentCustomizationSections[instId]]
      .flat()
      .map((section) => ({
        ...section,
        activeSettingKeys: section.settingKeys.filter((settingsKey) =>
          settingsKeysToShow.some((key) => key == settingsKey)
        ),
      }))
      .filter((section) => section.activeSettingKeys.length > 0)
  );
</script>

{#if settingsKeysToShow.length < 3}
  <div class="mx-auto max-w-[18em] pb-1 pt-4 text-center text-sm text-gray-600">
    We will be adding more options to this playing style in the future!
  </div>
{/if}

{#each sectionsInUse as section (section.heading)}
  <div class="pt-4"></div>
  <PanelSettingSection title={section.heading} helpText={section.helpText} whiteBg={false}>
    <div
      class="[&>*:first-child]:rounded-t-lg [&>*:last-child]:rounded-b-lg [&>*]:border [&>*]:border-panelWellBorder [&>:nth-child(n+2)]:border-t-0"
    >
      {#each section.activeSettingKeys as settingKey (settingKey)}
        <div class="group">
          <BandPanelOpenerForSetting {instId} {settingKey} />
        </div>
      {/each}
    </div>
  </PanelSettingSection>
{/each}
