<script lang="ts">
  import { onDestroy } from 'svelte';
  import { fly } from 'svelte/transition';
  import { Crnt } from '@/Crnt';
  import { HearAsYouEdit } from '@/editor/HearAsYouEdit';
  import EditorAddRemoveButton from '@/editor/ui/EditorAddRemoveButton.svelte';
  import EditorBassNotes from '@/editor/ui/EditorBassNotes.svelte';
  import EditorChartMenu from '@/editor/ui/EditorChartMenu.svelte';
  import EditorChordHeading from '@/editor/ui/EditorChordHeading.svelte';
  import EditorChordTypes from '@/editor/ui/EditorChordTypes.svelte';
  import EditorEffectsMenu from '@/editor/ui/EditorEffectsMenu.svelte';
  import EditorQuickChords from '@/editor/ui/EditorQuickChords.svelte';
  import EditorRootNotes from '@/editor/ui/EditorRootNotes.svelte';
  import { EditorToolsState } from '@/editor/ui/EditorToolsState';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import type { Chord } from '@/music/Chord';
  import { bringCellIntoView } from '@/ui/auto-scroll/bringCellIntoView';
  import { MusicPageScroller } from '@/ui/auto-scroll/MusicPageScroller';
  import SmallTextTabs from '@/ui/SmallTextTabs.svelte';
  import { slideFade } from '@/ui/svelte-transitions';
  import UserPreferences from '@/user/UserPreferences';
  import { capitalize } from '@/utilities/capitalize';
  import wait from '@/utilities/wait';

  let outerContainer: HTMLDivElement;

  const editorMenu = EditorToolsState.get();

  let menu = $derived($editorMenu.activeMenu);

  let key: string | undefined = $state();
  trackerEffect(() => (key = Crnt.song()?.key()));

  let selectedChords: Chord[] | undefined = $state();
  trackerEffect(
    () =>
      (selectedChords = Crnt.song()
        ?.focus.selectedChords()
        .map((c) => c.inKey(key)))
  );

  let singleSelectedChord: Chord | undefined = $derived(
    selectedChords?.every((c) => c.equals(selectedChords?.[0])) ? selectedChords[0] : undefined
  );

  let alteredChordsExpanded = $state(false);
  trackerEffect(
    () => (alteredChordsExpanded = UserPreferences.get('expandedChordTypeGroups').includes('alt'))
  );

  $effect(() => {
    if (alteredChordsExpanded) {
      setTimeout(() => {
        // can't use bind:this because of recursive reactivity
        const el = outerContainer.querySelector('.js-chordTypesContainer');
        const animationStartTime = performance.now();
        function scrollAnimationTick() {
          if (performance.now() - animationStartTime > 300) return;
          if (el) el.scrollTop = 9999;
          requestAnimationFrame(scrollAnimationTick);
        }
        requestAnimationFrame(scrollAnimationTick);
      }, 0);
    }
  });

  let numberOfCellsToDuplicate: number = $state(1);
  trackerEffect(() => (numberOfCellsToDuplicate = Crnt.song()?.focus.cells().length ?? 1));

  function onKeyDown(event: KeyboardEvent) {
    if (
      event.key == 'Escape' &&
      !(event.altKey || event.metaKey || event.shiftKey || event.ctrlKey)
    ) {
      event.preventDefault();
      editorMenu.setMenu(undefined);
    }
  }

  const isTouchDevice =
    /Android|iPhone|iPad|iPod/.test(navigator.userAgent) || navigator.maxTouchPoints > 0;

  let windowWidth = $state(0);
  let quickChordsMaxWidth = $derived(isTouchDevice ? 650 : Math.min(700, windowWidth - 140));
  let quickChordsDesiredWidth = $state(0);

  let width = $derived(
    $editorMenu.activeMenu
      ? editorMenu.menuWidths[$editorMenu.activeMenu]
      : Math.min(128 + quickChordsDesiredWidth, windowWidth - 20)
  );

  $effect(() => {
    const menuId = $editorMenu.activeMenu;
    const centerX = $editorMenu.menuCenter;
    const width = menuId ? editorMenu.menuWidths[menuId] : windowWidth;
    const height = menuId ? editorMenu.menuHeights[menuId] : 44;
    MusicPageScroller.updateEditorMenuPosition({ centerX, width, height });
  });

  $effect(() => {
    void $editorMenu.activeMenu; // trigger reactive dependency
    requestAnimationFrame(() => {
      const focusedCell = Crnt.song()?.focus.endCell();
      if (!focusedCell?.layout.chartCell) return;
      bringCellIntoView(focusedCell.layout.chartCell, focusedCell);
    });
  });

  onDestroy(() => {
    MusicPageScroller.updateEditorMenuPosition(undefined);
  });
</script>

<svelte:window onkeydown={onKeyDown} bind:innerWidth={windowWidth} />

<div
  class="js-global-editorMenu pointer-events-none absolute bottom-full left-1/2 z-10 flex max-w-max px-4 transition-all duration-200"
  style="transform: translate(calc(-50% + {$editorMenu.menuCenter}px), -0.5rem);"
  bind:this={outerContainer}
>
  <div
    class="__surface pointer-events-auto max-w-[100vw] flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 transition-all duration-200"
    style="width: {width}px;"
  >
    {#if menu}
      <div transition:slideFade={{ duration: 300 }}>
        <div class="flex w-full items-center">
          <div class="w-12"></div>
          <div class="flex min-h-[3.2rem] flex-1 items-center justify-center">
            {#if menu == 'chords' || menu == 'bass'}
              <EditorChordHeading chord={singleSelectedChord} {selectedChords} />
            {:else}
              <div class="text-2xl text-gray-600">
                {capitalize(menu)}
              </div>
            {/if}
          </div>
          <div class="w-12">
            <button
              class="rounded-full p-4 text-gray-500 transition-colors dsktp:hover:bg-gray-100"
              onclick={() => {
                editorMenu.setMenu(undefined);
                void wait(100).then(() => editorMenu.setMenuCenter(0));
              }}
            >
              <div class="flex items-center">
                <svg class="h-3.5 w-3.5 text-gray-600"><use xlink:href="#close-big" /></svg>
              </div>
            </button>
          </div>
        </div>
        {#if key && (menu == 'chords' || menu == 'bass')}
          <div class="grid h-72 border-y border-editToolbarSeparator">
            {#if menu == 'chords'}
              <div
                transition:fly={{ duration: 300, x: '-100%' }}
                class="col-start-1 row-start-1 flex"
              >
                <div class="border-r border-editToolbarSeparator p-4">
                  <EditorRootNotes
                    {key}
                    {selectedChords}
                    on:select={(e) => {
                      Crnt.song()?.withFocused().setChord(e.detail);
                      HearAsYouEdit.trigger();
                    }}
                  />
                </div>
                <div class="js-chordTypesContainer h-72 flex-1 overflow-y-auto p-4 pb-2">
                  <EditorChordTypes
                    {selectedChords}
                    on:select={(e) => {
                      Crnt.song()?.withFocused().setChordType(e.detail);
                      if (Crnt.song()?.focus.homogenousChordSelection()) HearAsYouEdit.trigger();
                    }}
                  />
                </div>
              </div>
            {/if}
            {#if menu == 'bass'}
              <div
                transition:fly={{ duration: 300, x: '100%' }}
                class="col-start-1 row-start-1 flex"
              >
                <EditorBassNotes
                  {key}
                  {selectedChords}
                  on:select={(e) => {
                    Crnt.song()?.withFocused().setBassNote(e.detail);
                    if (Crnt.song()?.focus.homogenousChordSelection()) HearAsYouEdit.trigger();
                  }}
                />
              </div>
            {/if}
          </div>
        {/if}
        {#if menu == 'effects'}
          <div class="border-t border-editToolbarSeparator">
            <EditorEffectsMenu />
          </div>
        {/if}
        {#if menu == 'chart'}
          <EditorChartMenu />
        {/if}
      </div>
    {/if}
    {#if menu == 'chords' || menu == 'bass' || !menu}
      <div
        class="flex w-full items-center transition-all duration-300"
        class:p-2={menu}
        transition:slideFade={{ duration: 300 }}
      >
        <div class="my-1.5 ml-1.5">
          <EditorAddRemoveButton button="remove" />
        </div>
        <div class="mx-2 h-8 border-l border-editToolbarSeparator"></div>
        <div class="grid flex-1">
          {#if menu == 'chords' || menu == 'bass'}
            <div
              transition:fly={{ duration: 400, y: 100 }}
              class="col-start-1 row-start-1 flex justify-center overflow-hidden"
            >
              <div class="flex-1">
                <SmallTextTabs
                  options={[
                    {
                      caption: 'Root & Type',
                      value: 'chords',
                    },
                    {
                      caption: 'Bass Note',
                      value: 'bass',
                    },
                  ]}
                  selectedValue={menu}
                  on:select={(e) => {
                    editorMenu.setMenu(e.detail == 'bass' ? 'bass' : 'chords');
                  }}
                />
              </div>
            </div>
          {/if}
          {#if !menu}
            <div
              transition:fly={{ duration: 350, y: -100 }}
              class="__no-scrollbar col-start-1 row-start-1 snap-x overflow-auto"
            >
              <EditorQuickChords
                bind:desiredWidth={quickChordsDesiredWidth}
                maxWidth={quickChordsMaxWidth}
              />
            </div>
          {/if}
        </div>
        <div class="mx-2 h-8 border-l border-muted-100"></div>
        <div class="my-1.5 mr-1.5">
          <EditorAddRemoveButton button="add" cellsToDuplicate={numberOfCellsToDuplicate} />
        </div>
      </div>
    {/if}
  </div>
</div>

<style>
  .__no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .__surface {
    box-shadow:
      0 0 4px rgba(0, 0, 0, 0.07),
      0 2px 20px rgba(0, 0, 0, 0.17);
  }
</style>
