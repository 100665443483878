import { Meteor } from 'meteor/meteor';
import { throttle } from 'underscore';
import defineTippyDropdown from '@/ui/dropdowns/defineTippyDropdown';

defineTippyDropdown(Template.medleySongSearch, {
  id: 'medley-az',
});

Template.medleySongSearch.onCreated(() => {
  const instance = Template.instance();
  instance.searcher = Template.currentData().searcher;
});

Template.medleySongSearch.onRendered(() => {
  const instance = Template.instance();
  Meteor.defer(() => {
    instance.autorun(() => {
      const query = instance.searcher.queryText(); // not in "if" statement because it needs to be reactive
      if (!instance.typingQuery) {
        instance.$('.js-songSearchBoxInput').val(query);
      }
    });
  });
});

Template.medleySongSearch.helpers({
  autofocusOnDesktop() {
    return /iP(hone|ad|od)|Android/i.test(navigator.userAgent) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
      ? {}
      : { autofocus: true };
  },

  azSelected() {
    const instance = Template.instance();
    const query = instance.searcher.query() || {};
    return query.all || query.allSongs || query.allMedleys || query.firstLetter || query.numbers;
  },

  ownSelected() {
    const instance = Template.instance();
    return instance.searcher.query()?.own;
  },

  searcher() {
    const instance = Template.instance();
    return instance.searcher;
  },
});

Template.medleySongSearch.events({
  'focus .js-songSearchBoxInput': (_event, instance) => {
    if (instance.$('.js-songSearchBoxInput').val()) {
      // only select if placeholder is gone (iOS bug)
      instance.$('.js-songSearchBoxInput').select();
      instance.$('.js-songSearchBoxInput')[0].setSelectionRange(0, 9999);
    }
  },

  'input .js-songSearchBoxInput': throttle(
    (_event, instance) => {
      const query = instance.$('.js-songSearchBoxInput').val();
      instance.typingQuery = true;
      instance.searcher.setQuery(query);
      Meteor.defer(() => {
        instance.typingQuery = false;
      });
    },
    200,
    { leading: false }
  ),

  'keydown form': (event, instance) => {
    if (event.key == '/') {
      return false;
    }
    if (event.key == ' ') {
      if (!instance.$('.js-songSearchBoxInput').val()) {
        event.preventDefault();
      }
    }
    if (event.key == 'Escape') {
      instance.$('.js-clear').click();
    }
  },

  'submit form': (event, instance) => {
    instance.$('.js-songSearchBoxInput').blur();
    event.preventDefault();
  },

  'click .js-viewAll': (_event, instance) => {
    instance.searcher.setQuery({ all: true });
    return false;
  },

  'click .js-viewOwn': (_event, instance) => {
    if (instance.searcher.query()?.own) {
      instance.searcher.setQuery(null);
    } else {
      instance.searcher.setQuery({ own: true });
    }
  },
});
