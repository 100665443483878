import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { eventTracker } from '@/browser/analytics/eventTracker';
import { HelpLibrary } from '@/help/HelpLibrary';

Template.docsPage.onCreated(() => {
  const instance = Template.instance();
  instance.pageId = new ReactiveVar();
  instance.pageBody = new ReactiveVar();

  instance.autorun(() => {
    instance.pageId.set(FlowRouter.getParam('page') || 'index');
    if (FlowRouter.getQueryParam('search')) {
      instance.pageId.set('search');
    }
  });

  instance.autorun(() => {
    if (instance.pageId.get() == 'contact') {
      FlowRouter.go('/app/contact');
    }
  });
});

Template.docsPage.onRendered(() => {
  const instance = Template.instance();
  instance.autorun(() => {
    const pageId = instance.pageId.get();
    instance.pageBody.set(null);
    if (!['contact', 'search'].includes(pageId)) {
      document.scrollingElement.scrollTop = 0;
      instance.$('.docsPage').toggleClass('video', pageId.includes('video'));
      const page = HelpLibrary.fetch(pageId);
      if (!page) {
        // FlowRouter.go("docs-index");
      } else {
        eventTracker.helpPageOpen({ pageId });
        instance.pageBody.set(page?.html);
      }
    }
  });
});

Template.docsPage.helpers({
  currentPageContent() {
    return Template.instance().pageBody.get();
  },
  pageId() {
    return Template.instance().pageId.get();
  },
  onSearchPage() {
    return Template.instance().pageId.get() == 'search';
  },
  onHelpPage() {
    return !['contact', 'search'].includes(Template.instance().pageId.get());
  },
});

Template.docsPage.events({
  'click .js-captureHelpLinks a': function (event, instance) {
    const href = event.currentTarget.href;
    if (href.includes('/help')) {
      event.preventDefault();
      const pageId = href.substring(href.indexOf('/help') + 6) || 'index';
      FlowRouter.go('docs-page', { page: pageId == 'index' ? null : pageId });
    }
    if (href.includes('/app/contact')) {
      event.preventDefault();
      FlowRouter.go('contact');
    }
  },
});
