import * as amplitude from '@amplitude/analytics-browser';
import * as Sentry from '@sentry/browser';
import { Meteor } from 'meteor/meteor';
import Version from '@/Version';

export class Analytics {
  static #initedPromise: Promise<void> | undefined;

  static async init() {
    if (this.#initedPromise) return this.#initedPromise;
    // if you change anything here, remember to change browser policy too!
    this.#initedPromise = this.initAmplitude();
    await this.#initedPromise;
  }

  static async initAmplitude() {
    try {
      await amplitude.init(Meteor.settings.public.amplitudeKey, {
        appVersion: Version.current,
        serverUrl: 'https://tpi.strummachine.com/a2',
        flushIntervalMillis: 2000,
        defaultTracking: {
          formInteractions: false,
          pageViews: false,
        },
      }).promise;

      // Ensure batched events get sent when page is unloaded
      window.addEventListener('pagehide', () => {
        amplitude.setTransport('beacon');
        amplitude.flush();
      });
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
    }
  }

  static setUserId(userId: string | undefined) {
    void Analytics.init()
      .then(() => {
        amplitude.setUserId(userId);
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
  }

  static setUserProperties(properties: Record<string, any>) {
    const identifyEvent = new amplitude.Identify();
    Object.entries(properties).forEach(([key, value]) => identifyEvent.set(key, value));

    void Analytics.init()
      .then(() => {
        amplitude.identify(identifyEvent);
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
  }

  static trackEvent(name: string, details: Record<string, any> = {}) {
    if (window.userIsBeingImpersonated) {
      amplitude.setOptOut(true);
      return;
    }

    if (Meteor.isDevelopment) {
      console.debug(
        `%c[Event] ${name} %s`,
        'color: #3c7',
        JSON.stringify(details ?? '')
          .replace(/([{,])"(\w+)":/g, '$1 $2: ')
          .replace(/\}$/g, ' }')
      );
    }

    void Analytics.init()
      .then(() => {
        amplitude.track(name, details);
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
  }
}
