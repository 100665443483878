import type { FeetCore } from '@/band/instruments/feet/FeetCore';
import { tap } from '@/band/instruments/feet/FootActions';

export const CanadianSlipJigFeetCore = {
  id: 'csj',
  title: 'Taps on every beat',

  timeSignatures: ['9/8'],

  linkedSettingsKeys: [],
  otherSettingsKeys: [],

  presets: [
    {
      name: 'Tap on every beat',
      settings: {},
    },
  ],

  processMeasure(measure, _settings, context) {
    for (let triple = 0; triple < measure.beats.length / 3; triple++) {
      measure.beats[triple * 3 + 0]!.playerInstructions.feet =
        measure.firstInRep || (measure.position.cells[0] === 0 && Math.random() < 0.8)
          ? triple === 0
            ? [tap('toeR', 2)]
            : [tap('bigflam-01', 2)]
          : [tap('flam', 0)];
      measure.beats[triple * 3 + 1]!.playerInstructions.feet = [tap('toeL', 0)];
      measure.beats[triple * 3 + 2]!.playerInstructions.feet = [tap('toeR', -1)];
    }
    return []; // Not using Intents for now
  },

  generateViz(settings) {
    settings;
    return [];
  },
} as const satisfies FeetCore;
