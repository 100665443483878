import * as Sentry from '@sentry/browser';
import { Meteor } from 'meteor/meteor';
import AudioManager from '@/audio/engine/AudioManager';
import type { ScheduledEvent } from '@/audio/engine/clock/ScheduledEvent';
import { eventTracker } from '@/browser/analytics/eventTracker';

let starterTimeoutHandle: number;
let starterEvent: ScheduledEvent;

export function startTickLoop({
  tickCallback,
  interval,
  bailOutCheck,
}: {
  tickCallback: (event: ScheduledEvent) => void;
  interval: number;
  bailOutCheck: () => boolean;
}): Promise<ScheduledEvent> {
  if (AudioManager.mode != 'web-audio') {
    return Promise.resolve(AudioManager.clock.setTimeout(tickCallback, 0.3).repeat(interval));
  } else {
    return new Promise((resolve, reject) => {
      if (starterTimeoutHandle) Meteor.clearTimeout(starterTimeoutHandle);
      if (starterEvent) starterEvent.clear();

      Sentry.addBreadcrumb({
        category: 'audio',
        message: 'attemptToStartPlayTickLoop',
        level: 'debug',
      });

      const starterFunction = () => {
        if (starterTimeoutHandle) Meteor.clearTimeout(starterTimeoutHandle);
        if (bailOutCheck && bailOutCheck()) return;
        Sentry.addBreadcrumb({
          category: 'audio',
          message: 'Starting Conductor.tickScheduler',
          level: 'debug',
        });
        resolve(AudioManager.clock.setTimeout(tickCallback, 0.2).repeat(interval));
      };

      starterTimeoutHandle = Meteor.setTimeout(() => {
        if (starterEvent) starterEvent.clear();

        console.error("Playback didn't start because context never started running.");
        Sentry.captureMessage("Playback didn't start because context never started running.");
        eventTracker.audioSuspendFixFailed();
        reject();
      }, 3000);

      starterEvent = AudioManager.clock
        .setTimeout(starterFunction, 0.005)
        .tolerance({ late: 3.0, early: 0 });
    });
  }
}
