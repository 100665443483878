<script lang="ts">
  import type { Instance as TippyInstance } from 'tippy.js';
  import type { BandPreset } from '@/band/presets/BandPreset';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';
  import { makeTippyMenu } from '@/ui/dropdowns/makeTippyMenu';
  import Icon from '@/ui/icons/Icon.svelte';
  import { MultiPanelModalController } from '@/ui/settings/MultiPanelModalController.svelte';

  const modalController = MultiPanelModalController.current();
  const bandMenu = getBandMenuState();
  let band = $derived($bandMenu.band);

  interface Props {
    preset: BandPreset;
  }

  let { preset }: Props = $props();

  let tippyInstance: TippyInstance | undefined;
  function tippyMenu(node: HTMLElement, params: Record<string, unknown> = {}) {
    tippyInstance = makeTippyMenu(node, params, { keysThatShow: [' ', 'Enter'] });
    return { destroy: () => tippyInstance?.destroy() };
  }

  function editPreset() {
    tippyInstance?.hide();
    band.enterEditPresetMode(preset);
  }

  function renamePreset() {
    tippyInstance?.hide();
    band.enterRenamePresetMode();
  }

  function deletePreset() {
    tippyInstance?.hide();
    band.enterDeletePresetMode();
  }
</script>

<div use:tippyMenu={{ 'theme': 'light', 'placement': 'bottom-end' }}>
  <button class="btn btn-round scale-125 px-1.5 py-[3px] font-normal" aria-label="Preset options">
    <i class="smi smi-dots-vertical" aria-hidden="true"></i>
  </button>
  <div
    role="menu"
    class="max-w-[15rem] p-1.5 [&_.btn]:flex [&_.btn]:items-start [&_.btn]:px-3 [&_.btn]:py-1.5 [&_.btn]:text-left [&_.btn]:font-normal [&_button]:w-full"
    tabindex="-1"
  >
    <button class="btn" onclick={editPreset}>
      <span class="w-6"><Icon id="pen" size={14} /></span>
      {preset?.builtIn ? 'Edit this built-in' : 'Edit your'} preset
    </button>
    <button class="btn flex items-center" onclick={renamePreset}>
      <span class="w-6 translate-y-[1px]"><Icon id="text-caret" size={14} /></span>
      Rename preset
    </button>
    <button class="btn btn-danger" onclick={deletePreset}>
      <span class="w-6"><Icon id="trash" size={14} /></span>
      Delete preset
    </button>
  </div>
</div>
