import type { Instance as TippyInstance, Props as TippyProps } from 'tippy.js';
import tippyJs from 'tippy.js';
import { quickRandomId } from '@/utilities/quickRandomId';

function setTippyTransformOriginBasedOnArrow(tippyInstance: any) {
  const box = tippyInstance.popper.firstElementChild;
  if (!tippyInstance.popperInstance) return;
  const x = tippyInstance.popperInstance.state.modifiersData.arrow.x + 8;
  const y = tippyInstance.popperInstance.state.placement == 'top' ? '100%' : '0%';
  box.style.transformOrigin = `${x.toFixed(1)}px ${y}`;
}

export function makeTippyMenu(
  container: HTMLElement,
  params: Partial<TippyProps> = {},
  extra: { keysThatShow?: string[] } = { keysThatShow: [' ', 'Enter'] }
) {
  // eslint-disable-next-line prefer-const
  let tippyInstance: TippyInstance;

  const button = <HTMLElement>(
    (container.querySelector('button') || container.querySelector('[role="button"]'))
  );
  const menu = <HTMLElement>container.querySelector('[role="menu"]');

  const escapeKeyHandler = (event: KeyboardEvent) => {
    if (event.code == 'Escape') tippyInstance.hide();
  };

  if (!button || !menu) throw new Error('Unable to create Tippy dropdown');

  container.setAttribute('data-tippy-dropdown-id', quickRandomId());
  button.setAttribute('aria-haspopup', 'true');
  menu.classList.add('dropdown-tippy');

  if (extra.keysThatShow) {
    button.addEventListener('keydown', (event) => {
      if (extra.keysThatShow?.includes(event.code) && tippyInstance) {
        event.preventDefault();
        event.stopPropagation();
        tippyInstance.show();
      }
    });
  }

  tippyInstance = tippyJs(button, {
    ...tippyDefaults,
    content: menu,
    ...params,
    onShow: (tippyInstance) => {
      params.onShow?.(tippyInstance);
      // menu.style.minWidth = Math.max(150, button.getBoundingClientRect().width) + 'px';
      // menu.style.maxWidth = Math.min(200, button.getBoundingClientRect().width) + 'px';
      requestAnimationFrame(() => setTippyTransformOriginBasedOnArrow(tippyInstance));
    },
    onCreate: () => {
      params.onCreate?.(tippyInstance);
      document.addEventListener('keydown', escapeKeyHandler);
    },
    onDestroy: () => {
      params.onDestroy?.(tippyInstance);
      document.removeEventListener('keydown', escapeKeyHandler);
    },
  });

  return tippyInstance;
}

const tippyDefaults: Partial<TippyProps> = {
  interactive: true,
  theme: 'light',
  trigger: 'click',
  duration: [300, 150],
  offset: [0, 0],
  placement: 'bottom',
  popperOptions: {
    modifiers: [
      {
        name: 'flip',
        options: {
          // fallbackPlacements: ['top'],
          padding: 22,
        },
      },
    ],
  },
  role: '',
  animation: 'scale-subtle',
  ignoreAttributes: true, // ignore data-tippy-* attrs for faster init
};
