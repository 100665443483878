export type ObjectPropertyKeyTransformerOptions<DecompressedType, CompressedType> = {
  date?: boolean;
  compress?: (decompressed: DecompressedType) => CompressedType;
  decompress?: (compressed: CompressedType) => DecompressedType;
};

// Tried to get rid of <any, any>, but in vain...

export class ObjectPropertyKeyTransformer<
  Type extends Record<string, any>,
  AbbreviatedType extends Record<string, any> = Record<string, any>,
> {
  private shortHash: Record<keyof AbbreviatedType, keyof Type>;
  private longHash: Record<keyof Type, keyof AbbreviatedType>;
  private shortOptsHash: Record<string, ObjectPropertyKeyTransformerOptions<any, any> | undefined>;
  private longOptsHash: Record<string, ObjectPropertyKeyTransformerOptions<any, any> | undefined>;
  constructor(
    abbreviations: [
      keyof AbbreviatedType,
      keyof Type,
      ObjectPropertyKeyTransformerOptions<any, any>?,
    ][]
  ) {
    this.shortHash = Object.fromEntries(
      abbreviations.map(([short, long, _opts]) => [short, long])
    ) as typeof this.shortHash;
    this.longHash = Object.fromEntries(
      abbreviations.map(([short, long, _opts]) => [long, short])
    ) as typeof this.longHash;
    this.shortOptsHash = Object.fromEntries(
      abbreviations.map(([short, _long, opts]) => [short, opts])
    );
    this.longOptsHash = Object.fromEntries(
      abbreviations.map(([_short, long, opts]) => [long, opts])
    );
  }

  abbreviate(object: Type): AbbreviatedType {
    return Object.fromEntries(
      Object.entries(object).map(([key, value]) => [
        this.longHash[key] || key,
        this.longOptsHash[key]?.date
          ? +(value as Date)
          : this.longOptsHash[key]?.compress
            ? this.longOptsHash[key]?.compress?.(value)
            : value,
      ])
    ) as AbbreviatedType;
  }

  unabbreviate(object: AbbreviatedType): Type {
    return Object.fromEntries(
      Object.entries(object).map(([key, value]) => [
        this.shortHash[key] || key,
        this.shortOptsHash[key]?.date
          ? new Date(value as number)
          : this.shortOptsHash[key]?.decompress
            ? this.shortOptsHash[key]?.decompress?.(value)
            : value,
      ])
    ) as Type;
  }
}
