import { strict as assert } from 'assert';
import type { BassIntent } from '@/band/instruments/bass/BassIntent';
import type { PlayerInstruction } from '@/band/instruments/PlayerInstruction';
import { PlayerInstructionsArray } from '@/band/instruments/PlayerInstructionsArray';

export function makeInstructionsFromBassIntent(intent: BassIntent): PlayerInstruction[] {
  const instructions = new PlayerInstructionsArray('bass');

  if (intent.action == 'note') {
    instructions.muteUnmatchedStrings('----', { offset: 0, fadeDuration: 0.2 });
    const { string, fret } = intent;
    assert(string >= 1 && string <= 6);
    const stringLetter = ['', 'e', 'a', 'd', 'g'][string] ?? 'XXX';
    const sample = fret == -2 ? `bass-drop-d` : `bass-${stringLetter}${fret}`;
    instructions.playSample(sample, { db: intent.db });
    if (intent.beatDuration) {
      instructions.muteSample(sample, { beatOffset: intent.beatDuration, fadeDuration: 0.2 });
      instructions.playSample(`bass-stop-${stringLetter}`, {
        db: intent.db * 1.35,
        beatOffset: intent.beatDuration,
      });
    }
  }

  if (intent.action == 'ghost') {
    instructions.muteUnmatchedStrings('----', {
      offset: 0,
      fadeDuration: 0.2,
      omitBelowTpm: intent.omitBelowTpm,
    });
    const { string } = intent;
    const sample = `bass-ghost-${string}-${Math.ceil(Math.random() * 3)}`;
    instructions.playSample(sample, {
      db: intent.db,
      omitAboveTpm: intent.omitAboveTpm,
      omitBelowTpm: intent.omitBelowTpm,
    });
  }

  // Volume adjustment
  instructions.forEach((inst) => {
    if ('db' in inst && typeof inst.db == 'number') inst.db += 4;
  });

  return instructions;
}
