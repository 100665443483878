import { Meteor } from 'meteor/meteor';
import { ReactiveVar } from 'meteor/reactive-var';
import { DemoMode } from '@/client/DemoMode';
import { Crnt } from '@/Crnt';
import { MusicLibrary } from '@/library/MusicLibrary';
import waitUntilReactive from '@/utilities/waitUntilReactive';

Template.medleyMenu.onCreated(() => {
  const instance = Template.instance();
  instance.medleysWithSong = new ReactiveVar([]);
  const songId = Crnt.song()?.id();
  waitUntilReactive(() => MusicLibrary.medleys.ready()).then(() =>
    instance.autorun(() => {
      if (songId) {
        instance.medleysWithSong.set(MusicLibrary.index.medleysWithSong(songId));
      }
    })
  );
  // UserProfile.recordMilestone('LISTS_MENU_OPEN');
});

Template.medleyMenu.helpers({
  ready() {
    return !DemoMode.active() && MusicLibrary.index.readyToSearch();
  },

  showLoadingMessage() {
    return !DemoMode.active() && !MusicLibrary.index.readyToSearch();
  },

  userHasZeroMedleys() {
    return MusicLibrary.index.ownMedleyCount() === 0;
  },

  isOnZeroMedleys() {
    const instance = Template.instance();
    return instance.medleysWithSong.get().length === 0;
  },

  isOnMoreThanOneMedley() {
    const instance = Template.instance();
    return instance.medleysWithSong.get().length > 1;
  },

  notMyMedley(medley) {
    return medley.userId && medley.userId != Meteor.userId();
  },

  medleysSongIsOn() {
    const instance = Template.instance();
    return instance.medleysWithSong.get();
  },
});

Template.medleyMenu.events({
  'click .js-newMedley': function (_event, _instance) {
    Modal.show('newMedleyModal', {
      firstSongId: Crnt.song()?.id(),
    });
    return false;
  },
});
