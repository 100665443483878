import { BasicBassCore } from '@/band/instruments/bass/BasicBassCore';
import { BasicJigBassCore } from '@/band/instruments/bass/BasicJigBassCore';
import { BasicWaltzBassCore } from '@/band/instruments/bass/BasicWaltzBassCore';
import { HalfTimeBassCore } from '@/band/instruments/bass/HalfTimeBassCore';
import { WalkingBassCore } from '@/band/instruments/bass/WalkingBassCore';

// These only get shown in the UI according to bassPatternLineup.ts

export const bassCores = {
  [BasicBassCore.id]: BasicBassCore,
  [WalkingBassCore.id]: WalkingBassCore,
  [HalfTimeBassCore.id]: HalfTimeBassCore,
  [BasicWaltzBassCore.id]: BasicWaltzBassCore,
  [BasicJigBassCore.id]: BasicJigBassCore,
} as const;
