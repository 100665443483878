import * as Sentry from '@sentry/browser';

// The safeCallback function takes a callback function with an arbitrary set of parameters, and a fallback value. It returns a new callback function with the same parameters (typed correctly) that will return the fallback value if the original callback function throws an error.

export function safeCallback<Args extends unknown[], Return, Fallback>(
  callback: (...args: Args) => Return,
  fallback?: Fallback
): (...args: Args) => Return | Fallback | undefined {
  return (...args: Args) => {
    try {
      return callback(...args);
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
      return fallback;
    }
  };
}
