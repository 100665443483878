import { Mongo } from 'meteor/mongo';
import type { z } from 'zod';
import type { MedleySchema, MedleySongSchema } from '@/collections/schemas/MedleySchema';

// Watch out for changes here and make sure they're compatible with both beta and prod
// and OfflineMedleysCollection needs to match this too!
// And for any index fields, ensure they're put in song lists too!

// ATTENTION! Don't remove any fields from this in the beta and keep them in prod!
// Rather, set them to "optional" so that they aren't cleaned from existing records.

export type MedleyRecord = { _id: string } & z.infer<typeof MedleySchema> & { unsynced?: boolean };

export type MedleySongRecord = z.infer<typeof MedleySongSchema>;

export const MedleysCollection = new Mongo.Collection<MedleyRecord>('medleys');
MedleysCollection.deny({ insert: () => true, update: () => true, remove: () => true });
