import * as Sentry from '@sentry/browser';
import { isError, isNonEmptyString, isPlainObject } from '@sindresorhus/is';
import { createConsola } from 'consola';

// Can't use Axe on the client, unfortunately.
// TODO: Look into using pino for client-side logging (and maybe server-side too)
//       Ultimate goal is to pipe logs to Papertrail... maybe... at least on the server

const consola = createConsola({
  level: 999,
});

const sentryLevelMap = {
  'fatal': 'fatal',
  'error': 'error',
  'warn': 'warning',
  'info': 'info',
  'debug': 'debug',
  'trace': 'debug',
} as const satisfies Record<string, Sentry.SeverityLevel>;

const wrapConsolaAndSentry = (
  level: keyof typeof sentryLevelMap,
  messageOrError: unknown,
  meta?: unknown
): void => {
  consola[level](messageOrError, meta);
  const [err, message] = isError(messageOrError)
    ? [messageOrError, undefined]
    : [undefined, messageOrError];
  if (err || (message && ['warn', 'error', 'fatal'].includes(level))) {
    Sentry.withScope((scope) => {
      if (isPlainObject(meta) && Object.keys(meta).length > 1 /* for level */) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { level: _, ...extraData } = meta;
        if (Object.keys(extraData).length > 0) scope.setContext('meta', extraData);
      }
      scope.setLevel(sentryLevelMap[level] ?? 'error');
      if (err) Sentry.captureException(err);
      else if (isNonEmptyString(message)) Sentry.captureMessage(message);
    });
  }
};

export const clog = {
  trace: (messageOrError: unknown, meta?: unknown) => consola.trace(messageOrError, meta),
  debug: (messageOrError: unknown, meta?: unknown) => consola.debug(messageOrError, meta),
  info: (messageOrError: unknown, meta?: unknown) => consola.info(messageOrError, meta),
  warn: (messageOrError: unknown, meta?: unknown) =>
    wrapConsolaAndSentry('warn', messageOrError, meta),
  error: (messageOrError: unknown, meta?: unknown) =>
    wrapConsolaAndSentry('error', messageOrError, meta),
  fatal: (messageOrError: unknown, meta?: unknown) =>
    wrapConsolaAndSentry('fatal', messageOrError, meta),
};
