import { Tracker } from 'meteor/tracker';
import type { SongRecord } from '@/collections/SongsCollection';

export class MedleySongIndexArray extends Array<SongRecord> {
  private reactiveDep = new Tracker.Dependency();
  reactive(): this {
    this.reactiveDep.depend();
    return this;
  }
  depend(): void {
    this.reactiveDep.depend();
  }
  changed(): void {
    this.reactiveDep.changed();
  }

  append(song: SongRecord): void {
    this.push(song);
    this.reactiveDep.changed();
  }

  remove(index: number): void {
    this.splice(index, 1);
    this.reactiveDep.changed();
  }

  orderByIds(songIds: string[]) {
    this.sort((a: SongRecord, b: SongRecord) => {
      const aIndex = songIds.findIndex((id: string) => id == a._id);
      const bIndex = songIds.findIndex((id: string) => id == b._id);
      return aIndex > bIndex ? 1 : -1;
    });
    this.reactiveDep.changed();
  }
}
