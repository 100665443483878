<script lang="ts">
  import { Meteor } from 'meteor/meteor';
  import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
  import type { Instance as TippyInstance } from 'tippy.js';
  import { eventTracker } from '@/browser/analytics/eventTracker';
  import type { SongListRecord } from '@/collections/SongListsCollection';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import { Roles } from '@/Roles';
  import { makeTippyMenu } from '@/ui/dropdowns/makeTippyMenu';

  interface Props {
    list: SongListRecord;
    deleteList: () => void | Promise<void>;
    removeList: () => void | Promise<void>;
    renameList: (newListName: string) => void | Promise<void>;
    beginManualReordering: () => void;
    sortAZ: () => void;
  }
  let { list, deleteList, removeList, renameList, beginManualReordering, sortAZ }: Props = $props();

  let listId = $derived(list._id);

  const canShare = list.ownerId == Meteor.userId();
  const canUpdate = list && Roles.userHasPermission(Meteor.userId(), 'lists.update', list);
  const canRename = list && Roles.userHasPermission(Meteor.userId(), 'lists.rename', list);
  const canDelete = list && Roles.userHasPermission(Meteor.userId(), 'lists.remove', list);

  let canSort = $state(false);
  trackerEffect(
    () =>
      (canSort =
        list &&
        Roles.userHasPermission(Meteor.userId(), 'lists.update', list) &&
        list.songs.length > 1)
  );

  let sortMethod = $derived(list.sortMethod || 'a');

  function clickDelete() {
    tippyInstance?.hide();
    if (!Meteor.status().connected) {
      showOfflineNotice();
      return;
    }
    bootbox.confirm({
      size: 'small',
      message: 'Are you sure you want to delete this list? There is no undo!',
      closeButton: false,
      buttons: {
        confirm: {
          label: 'Delete',
          className: 'btn-danger',
        },
      },
      callback(confirmed) {
        if (!confirmed) return;
        void deleteList();
      },
    });
  }

  function clickRemove() {
    tippyInstance?.hide();
    if (!Meteor.status().connected) {
      showOfflineNotice();
      return;
    }
    bootbox.confirm({
      size: 'small',
      message: 'Are you sure you want to remove this list from your account?',
      closeButton: false,
      buttons: {
        confirm: {
          label: 'Remove',
          className: 'btn-danger',
        },
      },
      callback(confirmed) {
        if (!confirmed) return;
        void removeList();
      },
    });
  }

  function clickRename() {
    tippyInstance?.hide();
    if (!Meteor.status().connected) {
      showOfflineNotice();
      return;
    }
    bootbox.prompt({
      size: 'small',
      title: 'New list name:',
      value: list.name,
      callback(listName) {
        if (!listName) return;
        void renameList(listName);
      },
    });
  }

  function clickShare() {
    tippyInstance?.hide();
    if (!Meteor.status().connected) {
      showOfflineNotice();
      return;
    }
    FlowRouter.go('song-list', { listId });
  }

  function clickHelp() {
    tippyInstance?.hide();
    bootbox.alert({
      size: 'small',
      title: 'How to add/remove songs on a list',
      message: `<p>To the right of each song on a list is a link that shows how many lists the song is on:</p>
        <div style="position: relative; margin-bottom: 15px;">
          <img src="/images/help/list-withonesong.20200403.png" style="max-width: 100%;" alt="">
          <img src="/images/help/list-circle-overlay.20200403.png" alt="" style="position: absolute; margin: 0; right: 11%; top: 52%; width: 13%;">
        </div>
        <p>After clicking this link, you can remove the song from the list and/or add it to a different list.</p>`,
    });
    eventTracker.listEditingHelp();
  }

  function clickReorder() {
    tippyInstance?.hide();
    if (!Meteor.status().connected) {
      showOfflineNotice();
      return;
    }
    beginManualReordering();
  }

  function clickSortAZ() {
    tippyInstance?.hide();
    if (!Meteor.status().connected) {
      showOfflineNotice();
      return;
    }
    sortAZ();
  }

  function showOfflineNotice() {
    bootbox.alert({
      title: 'Lists are read-only when offline',
      message: 'Sorry, you need to be connected to the internet to make changes to song lists.',
    });
  }

  let tippyInstance: TippyInstance | undefined;
  function tippyMenu(node: HTMLElement, params: Record<string, unknown> = {}) {
    tippyInstance = makeTippyMenu(node, params, { keysThatShow: [' ', 'Enter'] });
    return { destroy: () => tippyInstance?.destroy() };
  }
</script>

{#snippet button({
  icon,
  label,
  onclick,
  ariaLabel,
}: {
  icon: string;
  label: string;
  onclick: () => void | Promise<void>;
  ariaLabel: string;
})}
  <button
    class="btn flex flex-1 flex-col items-center py-1.5 font-normal"
    {onclick}
    aria-label={ariaLabel}
  >
    <i class="smi {icon}" aria-hidden="true"></i>
    {label}
  </button>
{/snippet}

<div use:tippyMenu={{ 'theme': 'light', 'placement': 'bottom' }}>
  <button
    class="btn flex size-[38px] items-center justify-center text-lg"
    aria-label="List menu for {list.name}"
  >
    <i class="smi smi-dots-vertical" aria-hidden="true"></i>
  </button>
  <div
    role="menu"
    class="rounded-lg bg-white p-2.5 [&_.btn]:text-sm [&_.btn]:leading-5 [&_button]:font-normal"
  >
    <div class="flex items-stretch justify-evenly">
      {#if canShare}
        {@render button({
          icon: 'smi-share-variant',
          label: 'Share',
          onclick: clickShare,
          ariaLabel: 'Share list with another user',
        })}
      {/if}
      {#if canRename}
        {@render button({
          icon: 'smi-keyboard',
          label: 'Rename',
          onclick: clickRename,
          ariaLabel: 'Rename list',
        })}
      {/if}
      {#if canDelete}
        {@render button({
          icon: 'smi-delete',
          label: 'Delete',
          onclick: clickDelete,
          ariaLabel: 'Delete list',
        })}
      {:else}
        {@render button({
          icon: 'smi-close',
          label: 'Remove list',
          onclick: clickRemove,
          ariaLabel: 'Remove list from account',
        })}
      {/if}
    </div>
    {#if canSort}
      <hr style="margin: 8px 0;" />
      <li class="py-1 text-center text-sm text-gray-400" aria-hidden="true">
        {#if sortMethod == 'a'}
          Sorted alphabetically
        {:else if sortMethod == 'z'}
          Sorted alphabetically (Z-A)
        {:else if sortMethod == 'm'}
          Sorted manually
        {/if}
      </li>
      <div class="flex items-stretch justify-evenly">
        <!--<button class="btn js-sortShuffle">
                <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M505 400l-79.2 72.9c-15.1 15.1-41.8 4.4-41.8-17v-40h-31c-3.3 0-6.5-1.4-8.8-3.9l-89.8-97.2 38.1-41.3 79.8 86.3H384v-48c0-21.4 26.7-32.1 41.8-17l79.2 71c9.3 9.6 9.3 24.8 0 34.2zM12 152h91.8l79.8 86.3 38.1-41.3-89.8-97.2c-2.3-2.5-5.5-3.9-8.8-3.9H12c-6.6 0-12 5.4-12 12v32c0 6.7 5.4 12.1 12 12.1zm493-41.9l-79.2-71C410.7 24 384 34.7 384 56v40h-31c-3.3 0-6.5 1.4-8.8 3.9L103.8 360H12c-6.6 0-12 5.4-12 12v32c0 6.6 5.4 12 12 12h111c3.3 0 6.5-1.4 8.8-3.9L372.2 152H384v48c0 21.4 26.7 32.1 41.8 17l79.2-73c9.3-9.4 9.3-24.6 0-33.9z"></path></svg>
                Shuffle
              </button>-->
        <button
          class="btn"
          onclick={clickReorder}
          aria-label="Reorder list alphabetically; this is not possible with the keyboard so please do not press this button."
        >
          <svg
            class="mx-auto mb-1 block size-4"
            aria-hidden="true"
            focusable="false"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
          >
            <path
              fill="currentColor"
              d="M164 384h-44V48a16 16 0 0 0-16-16H88a16 16 0 0 0-16 16v336H28a12 12 0 0 0-8.73 20.24l68 72a12 12 0 0 0 17.44 0l68-72A12 12 0 0 0 164 384zm200.72-276.24l-68-72a12 12 0 0 0-17.44 0l-68 72A12 12 0 0 0 220 128h44v336a16 16 0 0 0 16 16h16a16 16 0 0 0 16-16V128h44a12 12 0 0 0 8.72-20.24z"
            ></path>
          </svg>
          Reorder
        </button>
        <button
          class="btn"
          class:disabled={sortMethod == 'a'}
          class:btn-gray={sortMethod == 'a'}
          onclick={clickSortAZ}
          aria-label="Sort list alphabetically"
        >
          <svg
            class="mx-auto mb-1 block size-4"
            aria-hidden="true"
            focusable="false"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              fill="currentColor"
              d="M447.17 202.94l-61.05-160A16 16 0 0 0 371 32h-38a15.92 15.92 0 0 0-15.1 10.94l-61.06 160a16 16 0 0 0 15.1 21.06h16.78a15.93 15.93 0 0 0 15.11-10.94L314.35 184h75.3l10.52 29.06A15.93 15.93 0 0 0 415.28 224h16.78a16 16 0 0 0 15.11-21.06zM331.73 136L352 80l20.27 56zM416 288H288a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h77.11L281 422.69a32 32 0 0 0-9 22.31v19a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16v-16a16 16 0 0 0-16-16h-77.11L423 345.29a32 32 0 0 0 9-22.29v-19a16 16 0 0 0-16-16zM28 128h44v336a16 16 0 0 0 16 16h16a16 16 0 0 0 16-16V128h44a12 12 0 0 0 8.73-20.24l-68-72a12 12 0 0 0-17.44 0l-68 72A12 12 0 0 0 28 128z"
            ></path>
          </svg>
          Sort A-Z
        </button>
      </div>
    {/if}
    {#if canUpdate}
      <hr style="margin: 8px 0;" />
      <a
        href="/help/lists"
        class="block whitespace-nowrap text-center text-sm !no-underline"
        onclick={(e) => {
          e.preventDefault();
          clickHelp();
        }}
        aria-hidden="true"
      >
        <i class="smi smi-help-circle" aria-hidden="true"></i> How do I add/remove songs?
      </a>
    {/if}
  </div>
</div>
