Template.contactWindow.helpers({
  floatingWindowDataContext() {
    return {
      class: 'contact',
      moveableMinWidth: '500px',
      minFromBottom: 420,
      shouldBeVisible() {
        return !!Session.get('showContactWindow');
      },
      closeClickHandler() {
        Session.set('showContactWindow', false);
      },
      title() {
        return 'Contact Support';
      },
    };
  },
});

Template.contactWindow.helpers({
  visible() {
    return !!Session.get('showContactWindow');
  },
});
