import { rpcUpdateUserName } from '@/server/methods/user/rpcUpdateUserName';

Template.changeNameModal.onCreated(() => {
  Template.instance().errorMessage = new ReactiveVar();
  Template.instance().submitting = new ReactiveVar();
});

Template.changeNameModal.onRendered(() => {
  const template = Template.instance();
  template.$('input[name="firstName"]').val(Meteor.user().profile.firstName);
  template.$('input[name="lastName"]').val(Meteor.user().profile.lastName || '');
});

Template.changeNameModal.helpers({
  errorMessage() {
    return Template.instance().errorMessage.get();
  },
  submitting() {
    return Template.instance().submitting.get();
  },
});

Template.changeNameModal.events({
  'submit form': async function (event, instance) {
    event.preventDefault();
    instance.submitting.set(true);

    try {
      await rpcUpdateUserName({
        firstName: instance.$('[name="firstName"]').val(),
        lastName: instance.$('[name="lastName"]').val(),
      });
      Modal.hide('changeNameModal');
      Bert.alert('Your name has been changed.', 'success');
    } catch (err) {
      instance.errorMessage.set(err.reason);
      instance.submitting.set(false);
    }
  },
});
