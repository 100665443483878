const optsPassive = { passive: true } as EventListenerOptions;

const holds = new Set();
let lastHoldIndex = 0;

let scrollContainerEl: HTMLElement | undefined;
let editorMenuPosition: { centerX: number; width: number; height: number } | undefined;

export const MusicPageScroller = {
  attach() {
    this.detach();
    scrollContainerEl = document.querySelector('.js-global-songScrollContainer') as
      | HTMLElement
      | undefined;
    window.requestAnimationFrame(() => {
      if (!scrollContainerEl) return;
      scrollContainerEl.addEventListener('scroll', handleScrollEvent, optsPassive);
    });
  },

  detach() {
    scrollContainerEl?.removeEventListener('scroll', handleScrollEvent, optsPassive);
    scrollContainerEl = undefined;
    editorMenuPosition = undefined;
    holds.clear();
  },

  hold(): { releaseHold: () => void } {
    const index = lastHoldIndex++;
    holds.add(index);
    return {
      releaseHold: () => holds.delete(index),
    };
  },

  holdFor(ms: number): { releaseHold: () => void } {
    const hold = this.hold();
    setTimeout(hold.releaseHold, ms);
    return hold;
  },

  holding() {
    return holds.size > 0;
  },

  scrollTo(top: number) {
    animateScroll({ scrollTop: top });
  },

  updateEditorMenuPosition(pos: typeof editorMenuPosition) {
    editorMenuPosition = pos;
  },

  get editorMenuPosition() {
    return editorMenuPosition;
  },

  scrollHoldDelay: 1000,
};

let targetScrollTop = 0;
let currentScrollTop: number | undefined;
let scrollAnimationInProgress = false;
let oldTimestamp = 0;

const animateScroll = ({ scrollTop }: { scrollTop: number }) => {
  if (!scrollContainerEl) return;
  const delta = scrollTop - scrollContainerEl.scrollTop;
  if (Math.abs(delta) < 3) return;
  const direction = Math.sign(delta);
  targetScrollTop = scrollTop + direction * 10; // animation stops 10px before target so this cancels out

  if (scrollAnimationInProgress || MusicPageScroller.holding() || !scrollContainerEl) {
    return;
  }
  scrollAnimationInProgress = true;

  oldTimestamp = (document.timeline?.currentTime as number | undefined) ?? performance.now();
  requestAnimationFrame(scrollAnimationTick);
};

function scrollAnimationTick(newTimestamp: number) {
  if (!scrollContainerEl) {
    // we've probably detached this and gone back to the index
    scrollAnimationInProgress = false;
    return;
  }

  currentScrollTop ??= scrollContainerEl.scrollTop;
  if (Math.abs(currentScrollTop - scrollContainerEl.scrollTop) > 2) {
    // user scrolled the element; stop animating
    MusicPageScroller.holdFor(MusicPageScroller.scrollHoldDelay);
  }

  const dt = (newTimestamp - oldTimestamp) / 1000;
  oldTimestamp = newTimestamp;

  const pixelsRemaining = targetScrollTop - currentScrollTop;

  if (!MusicPageScroller.holding() && Math.abs(pixelsRemaining) > 5) {
    // Based on https://lisyarus.github.io/blog/programming/2023/02/21/exponential-smoothing.html
    const scrollDeltaForThisTick = pixelsRemaining * Math.max(0, 1 - Math.exp(-dt * 8));
    currentScrollTop = Math.max(0, currentScrollTop + scrollDeltaForThisTick);
    scrollContainerEl.scrollTop = currentScrollTop;
    requestAnimationFrame(scrollAnimationTick);
  } else {
    scrollAnimationInProgress = false;
    currentScrollTop = undefined;
  }
}

function handleScrollEvent() {
  if (scrollAnimationInProgress) return;
  // Why not check to see if this event was called by code?
  // Nuh uh. I removed that code 'cause it don't work.
  MusicPageScroller.holdFor(MusicPageScroller.scrollHoldDelay);
}
