export const permissionNames = [
  'metrics.view',

  'songs.indexAllUsersSongs',
  'songs.insert',
  'songs.insertPublic',
  'songs.update',
  'songs.updatePrivate',
  'songs.updatePublic',
  'songs.remove',
  'songs.removePublic',
  'songs.play',

  'medleys.insert',
  'medleys.update',
  'medleys.remove',
  'medleys.admin',

  'lists.insert',
  'lists.update',
  'lists.rename',
  'lists.remove',

  'references.insert',
  'references.makePublic',
  'references.remove',

  'nominations.insert',
  'nominations.approve',

  'help.admin',

  'impersonate',
] as const;

export type PermissionName = (typeof permissionNames)[number];
