import type { SampleDefinition } from '@/audio/engine/base/Sample';

function definePackage(packageId: string, ids: string[], preroll = 0.05): SampleDefinition[] {
  return ids.map((id) => {
    return { id, packageId, preroll };
  });
}

function splitFileList(files: string): string[] {
  return files.split('\n').filter((line) => line.length > 0);
}

const allStrings = [];
for (let stringIndex = 0; stringIndex < 4; stringIndex++) {
  for (let fret = 0; fret < 16; fret++) {
    allStrings.push('+'.repeat(stringIndex) + fret.toString(16) + '+'.repeat(3 - stringIndex));
  }
}

// Package definitions with the same packageId will be merged
export default [
  ...definePackage(
    'newmando-zed', // being overwritten below
    allStrings.map((str) => `m${str} Z`),
    0.05
  ),

  ...definePackage(
    'newmando-chops', // being overwritten below
    allStrings.map((str) => `m${str} C`),
    0.05
  ),

  ...definePackage(
    'newmando-lifts', // being overwritten below
    allStrings.map((str) => `m${str} L`),
    0.0
  ),

  ...definePackage(
    'newmando-foley_2024-09',
    splitFileList(`
mFCh-001
mFCh-002
mFCh-003
mFCh-004
mFCh-005
mFCh-006
mFCh-007
mFCh-008
mFCh-009
mFCn-001
mFCn-002
mFCn-003
mFCn-004
mFCn-005
mFCn-006
mFCn-007
mFCn-008
mFCs-001
mFCs-002
mFCs-003
mFCs-004
mFCs-005
mFCs-006
mFCs-007
mFCs-008
mFDv0-001
mFDv0-002
mFDv0-003
mFDv1-001
mFDv1-002
mFDv1-003
mFDv1-004
mFDv1-005
mFDv1-006
mFDv1-007
mFDv1-008
mFDv1-009
mFDv1-010
mFDv1-011
mFDv1-012
mFDv1-013
mFDv1-014
mFDv2-001
mFDv2-002
mFDv2-003
mFDv2-004
mFDv2-005
mFDv2-006
mFDv3-001
mFDv3-002
mFDv3-003
mFDv3-004
mFDv3-005
mFDv3-006
mFDv3-007
mFDv3-008
mFDv4-001
mFDv4-002
mFDv4-003
mFDv4-004
mFDv4-005
mFDv4-006
mFDv4-007
mFDv4-008
mFDv5-001
mFDv5-002
mFDv5-003
mFDv5-004
mFDv5-005
mFUmv1-001
mFUmv1-002
mFUmv1-003
mFUmv1-004
mFUmv2-001
mFUmv2-002
mFUmv2-003
mFUmv2-004
mFUmv3-001
mFUmv3-002
mFUmv3-003
mFUmv3-004
mFUmv3-005
mFUmv3-006
mFUmv4-001
mFUmv4-002
mFUmv4-003
mFUmv4-004
mFUmv4-005
mFUmv4-006
mFUmv4-007
mFUtv1-001
mFUtv1-002
mFUtv1-003
mFUtv1-004
mFUtv1-005
mFUtv1-006
mFUtv2-001
mFUtv2-002
mFUtv2-003
mFUtv2-004
mFUtv3-001
mFUtv3-002
mFUtv3-003
mFUtv3-004
mFUtv3-005
mFUtv4-001
mFUtv4-002
mFUtv4-003
mFUtv4-004
mFUtv4-005
`),
    0.03
  ),

  // that's all!
].map((def) => (def.packageId.includes('_20') ? def : { ...def, packageId: 'newmando_2022-11' }));
