class ToggleSwitch {
  constructor({ element, onLabel = '', offLabel = '' }) {
    this._bindInstanceMethods(
      '_touchstart',
      '_touchmove',
      '_touchend',
      '_mousedown',
      '_mousemove',
      '_mouseup',
      '_click'
    );

    this.el = this.el instanceof jQuery ? element.get(0) : element;

    var spanToAppend = document.createElement('SPAN');
    spanToAppend.innerHTML = `
      <span>${offLabel}</span>
      <span>${onLabel}</span>
      <a></a>
    `;
    this.el.appendChild(spanToAppend);

    this.inputEl = this.el.getElementsByTagName('INPUT')[0];
    this.trackEl = this.el.getElementsByTagName('SPAN')[0];
    this.handleEl = this.el.getElementsByTagName('A')[0];

    this.el.addEventListener('click', this._click);
    this.trackEl.addEventListener('touchstart', this._touchstart);
    this.trackEl.addEventListener('mousedown', this._mousedown);
    this.trackEl.addEventListener('dragstart', this._dragstart);
  }

  _bindInstanceMethods(...methods) {
    methods.forEach((method) => (this[method] = this[method].bind(this)));
  }

  _setValue(value) {
    var changed = this.inputEl.checked != value;
    this.inputEl.checked = value;
    if (changed) {
      var evt = document.createEvent('HTMLEvents');
      evt.initEvent('change', true, true);
      this.inputEl.dispatchEvent(evt);
    }
  }

  _click(event) {
    if (this._ignoreNextClick) {
      event.preventDefault(); // we handle click detection on mouseup / touchend
      setTimeout(() => (this._ignoreNextClick = false), 50);
    } else {
      // probably a keypress; let it do its thing
    }
  }

  _mousedown(event) {
    this._ignoreNextClick = true;
    this._swipeStart(event.pageX);
    document.addEventListener('mousemove', this._mousemove, false);
    document.addEventListener('mouseup', this._mouseup, false);
  }

  _touchstart(event) {
    this._ignoreNextClick = true;
    this._swipeStart(event.touches[0].pageX);
    document.addEventListener('touchmove', this._touchmove, false);
    document.addEventListener('touchend', this._touchend, false);
    event.preventDefault(); // Prevent scrolling of the window.
  }

  _mousemove(event) {
    if (event.buttons == 1) {
      this._swipeAt(event.pageX);
      event.preventDefault(); // Prevent text selection.
    } else {
      this._swipeEnd();
    }
  }

  _touchmove(event) {
    if (event.touches[0]) {
      this._swipeAt(event.touches[0].pageX);
    } else {
      this._swipeEnd();
    }
  }

  _mouseup() {
    if (!this._isDragging) this._setValue(!this.inputEl.checked);
    this._swipeEnd();
  }

  _touchend(event) {
    if (!this._isDragging) this._setValue(!this.inputEl.checked);
    this._swipeEnd();
  }

  _dragstart(event) {
    event.preventDefault();
  }

  _swipeStart(pageX) {
    this._isDragging = false; // until movement is detected...
    this._startX = pageX;
    this._maxRight = this.trackEl.clientWidth - this.handleEl.offsetWidth;
    this._startRight =
      this.trackEl.clientWidth - this.handleEl.offsetLeft - this.handleEl.offsetWidth;
    this.el.className = (this.el.className || '') + ' no-transition';
  }

  _swipeAt(pageX) {
    if (this._isDragging || this._startX !== pageX) {
      this._isDragging = true;
      var right = Math.min(this._maxRight, Math.max(1, this._startRight + this._startX - pageX));
      this.handleEl.style.right = right + 'px';
      this.handleEl.style.backgroundColor = `hsl(128, 53%, ${48 + (50 * right) / this._maxRight}%)`;
      this.trackEl.style.backgroundColor = `hsl(128, ${50 - (50 * right) / this._maxRight}%, 80%)`;
    }
  }

  _swipeEnd() {
    // Restore transitions first in case checked property changes
    this.el.className = (this.el.className || '')
      .replace(new RegExp('(\\bno-transition\\b)'), '')
      .trim();

    // Remove any document-bound listeners
    document.removeEventListener('mousemove', this._mousemove, false);
    document.removeEventListener('mouseup', this._mouseup, false);
    document.removeEventListener('touchmove', this._touchmove, false);
    document.removeEventListener('touchend', this._touchend, false);

    // If handle has moved at least 1/3 of the way over, switch state
    if (this._isDragging) {
      var threshold = this.inputEl.checked ? 0.666 : 0.333;
      this._setValue(this.handleEl.offsetLeft > this._maxRight * threshold);
    } else {
      // focus hidden checkbox to trigger the ripple effect
      this.inputEl.focus();
    }
    this.handleEl.style.right = null;
    this.handleEl.style.backgroundColor = null;
    this.trackEl.style.backgroundColor = null;
    this._isDragging = false;
  }

  destroy() {
    this.handleEl.removeEventListener('click', this._click);
    this.trackEl.removeEventListener('click', this._click);
    this.trackEl.removeEventListener('touchstart', this._touchstart);
    this.trackEl.removeEventListener('mousedown', this._mousedown);
    this.trackEl.removeEventListener('dragstart', this._dragstart);
    this._swipeEnd(); // to remove other event listeners
    this.el = null;
    this.trackEl = null;
    this.handleEl = null;
    this.inputEl = null;
  }
}

export default ToggleSwitch;
