import { ArpeggiatedWaltzGuitarCore } from '@/band/instruments/guitar/cores/ArpeggiatedWaltzGuitarCore';
import { BluegrassGuitarCore } from '@/band/instruments/guitar/cores/BluegrassGuitarCore';
import { BluegrassWaltzGuitarCore } from '@/band/instruments/guitar/cores/BluegrassWaltzGuitarCore';
import { BoomChuckGuitarCore } from '@/band/instruments/guitar/cores/BoomChuckGuitarCore';
import { BoomChuckJigGuitarCore } from '@/band/instruments/guitar/cores/BoomChuckJigGuitarCore';
import { BoomChuckSlipJigGuitarCore } from '@/band/instruments/guitar/cores/BoomChuckSlipJigGuitarCore';
import { BoomChuckWaltzGuitarCore } from '@/band/instruments/guitar/cores/BoomChuckWaltzGuitarCore';
import { CelticJigGuitarCore } from '@/band/instruments/guitar/cores/CelticJigGuitarCore';
import { CelticReelGuitarCore } from '@/band/instruments/guitar/cores/CelticReelGuitarCore';
import { CelticSlipJigGuitarCore } from '@/band/instruments/guitar/cores/CelticSlipJigGuitarCore';
import { DampenedGuitarCore } from '@/band/instruments/guitar/cores/DampenedGuitarCore';
import { DampenedWaltzGuitarCore } from '@/band/instruments/guitar/cores/DampenedWaltzGuitarCore';
import { HalfTimeGuitarCore } from '@/band/instruments/guitar/cores/HalfTimeGuitarCore';
import { SyncopatedBluegrassGuitarCore } from '@/band/instruments/guitar/cores/SyncopatedBluegrassGuitarCore';
import type { GuitarCore } from '@/band/instruments/guitar/GuitarCore';

export const guitarPatternLineup: Readonly<
  Record<TimeSignature, { heading: string; description?: string; core: GuitarCore }[]>
> = {
  '4/4': [
    {
      heading: BoomChuckGuitarCore.title,
      description:
        'Simple no-frills strum, especially good for beginners as well as old-time tunes, etc.',
      core: BoomChuckGuitarCore,
    },

    {
      heading: BluegrassGuitarCore.title,
      description: 'Highly adaptable pattern used in bluegrass, country, folk, and more.',
      core: BluegrassGuitarCore,
    },

    {
      heading: SyncopatedBluegrassGuitarCore.title,
      description:
        'Mix up the rhythm with a big syncopated upstrum. Sometimes referred to as “D DU UDU”.',
      core: SyncopatedBluegrassGuitarCore,
    },

    {
      heading: 'Dampened strum (closed chords)',
      description:
        'Steady “four to the bar” downstrums, commonly used across jazz and swing genres.',
      core: DampenedGuitarCore,
    },

    {
      heading: CelticReelGuitarCore.title,
      description:
        'Emulates common strumming patterns used in Celtic music. (We’re still working on this one!)',
      core: CelticReelGuitarCore,
    },

    {
      heading: HalfTimeGuitarCore.title,
      description:
        'Slow “spread out” strum, sometimes used in pop, folk, and more. (We’ll expand this soon!)',
      core: HalfTimeGuitarCore,
    },
  ],

  '3/4': [
    {
      heading: BoomChuckWaltzGuitarCore.title,
      description: 'Simple boom-chuck strum for waltzes.',
      core: BoomChuckWaltzGuitarCore,
    },
    {
      heading: BluegrassWaltzGuitarCore.title,
      description: 'Highly adaptable pattern used in bluegrass, country, folk, and more.',
      core: BluegrassWaltzGuitarCore,
    },
    {
      heading: ArpeggiatedWaltzGuitarCore.title,
      description: 'Cross-picking patterns for waltzes. (We’re still working on this one!)',
      core: ArpeggiatedWaltzGuitarCore,
    },
    {
      heading: DampenedWaltzGuitarCore.title,
      description: 'Dampened strums on each beat in 3/4 time.',
      core: DampenedWaltzGuitarCore,
    },
  ],

  '6/8': [
    {
      heading: CelticJigGuitarCore.title,
      description:
        'Emulates common jig strumming patterns used in Celtic music. (We’re still working on this one!)',
      core: CelticJigGuitarCore,
    },
    {
      heading: BoomChuckJigGuitarCore.title,
      description: 'Very simple boom-chuck strum for jigs.',
      core: BoomChuckJigGuitarCore,
    },
  ],

  '9/8': [
    {
      heading: CelticSlipJigGuitarCore.title,
      description:
        'Emulates common slip jig strumming patterns used in Celtic music. (We’re still working on this!)',
      core: CelticSlipJigGuitarCore,
    },
    {
      heading: BoomChuckSlipJigGuitarCore.title,
      description: 'Very simple boom-chuck strum for (slip) jigs.',
      core: BoomChuckSlipJigGuitarCore,
    },
  ],
};
