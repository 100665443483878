import { z } from 'zod';

export const SongReferenceSchema = z.object({
  'songId': z.string(),
  'userId': z.string().optional(),
  'originalUserId': z.string().optional(),
  'url': z.string().url(),
  'type': z.string().regex(/^[rlwo]$/i),
  'alternates': z.array(z.string().url()).optional(),
  'title': z.string().optional(),
  'artist': z.string().optional(),
  'instrument': z.string().optional(),
  'description': z.string().optional(),
});

export const providerInfo = {
  'youtube': {
    order: 0,
    name: 'YouTube',
  },
  'spotify': {
    order: 1,
    name: 'Spotify',
  },
  'itunes': {
    order: 3,
    name: 'iTunes',
  },
  'appleMusic': {
    order: 2,
    name: 'Apple Music',
  },
  'google': {
    order: 4,
    name: 'Google Play Music',
  },
  'soundcloud': {
    order: 5,
    name: 'SoundCloud',
  },
  'amazonMusic': {
    order: 6,
    name: 'Amazon Music',
  },
};

export const refTypeInfo = [
  {
    id: 'r',
    forVideo: true,
    name: 'Recording',
  },
  {
    id: 'l',
    forVideo: true,
    name: 'Lesson',
  },
  {
    id: 'w',
    name: 'Lyrics',
  },
  {
    id: 'o',
    forVideo: true,
    name: 'Other',
  },
];
