import type { InstrumentSetting } from '@/band/instruments/InstrumentSetting';
import type { InstrumentStylePreset } from '@/band/instruments/InstrumentStylePreset';

export function findMatchingInstrumentPreset<
  SettingsHash extends Record<string, InstrumentSetting<unknown>>,
  Core extends {
    readonly presets: Readonly<InstrumentStylePreset<SettingsHash>[]>;
    readonly linkedSettingsKeys: Readonly<string[]>;
  },
>(core: Core, settings: SettingsHash) {
  return core.presets.find((preset) =>
    core.linkedSettingsKeys.every((key) => settings[key]?.closeTo(preset.settings[key]))
  );
}
