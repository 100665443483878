import * as Sentry from '@sentry/browser';

/**
 * Safely execute a function, catching any errors and logging them to Sentry.
 * @param func The function to execute.
 * @returns - true if the function executed successfully, false if it threw an error.
 */
export function safeExecute<Func extends () => void>(func: Func): boolean {
  try {
    func();
    return true;
  } catch (err) {
    Sentry.captureException(err);
    console.error(err);
    return false;
  }
}
