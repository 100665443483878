<script lang="ts">
  import type { BandPreset } from '@/band/presets/BandPreset';
  import { BandPresets } from '@/band/presets/BandPresets';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';
  import { eventTracker } from '@/browser/analytics/eventTracker';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import Icon from '@/ui/icons/Icon.svelte';
  import RadiolistRadio from '@/ui/icons/RadiolistRadio.svelte';

  const bandMenu = getBandMenuState();
  let band = $derived($bandMenu.band);

  let currentPreset: BandPreset | undefined = $state();
  trackerEffect(() => (currentPreset = $bandMenu.band.preset()));

  let checked = $derived(currentPreset?.default);

  function setDefaultPreset() {
    const defaultPreset = BandPresets.defaultForTimeSignature(band.timeSignature());
    band.setPreset(defaultPreset);
    eventTracker.bandPresetSet({
      presetId: defaultPreset.id,
      name: defaultPreset.name,
      isDefault: true,
    });
  }
</script>

<button
  class="btn-base relative flex w-full items-center gap-3 whitespace-normal rounded-[7px] py-3 pl-3 pr-4 text-left font-normal leading-5 transition-colors dsktp:hover:bg-primary-50"
  aria-checked={checked}
  role="radio"
  onclick={() => setDefaultPreset()}
>
  <Icon id="preset-default" size={24} />
  <div class="flex-auto">Default Band</div>
  <RadiolistRadio {checked} size={20} />
</button>
