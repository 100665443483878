<script lang="ts">
  import type { PresetEditorModes } from '@/band/Band';
  import InstrumentPanelOpener from '@/band/ui/components/InstrumentPanelOpener.svelte';
  import InstrumentPanel from '@/band/ui/panels/InstrumentPanel.svelte';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import { MultiPanelModalController } from '@/ui/settings/MultiPanelModalController.svelte';
  import { slideFade } from '@/ui/svelte-transitions';
  import { capitalize } from '@/utilities/capitalize';

  const modalController = MultiPanelModalController.current();

  const bandMenu = getBandMenuState();

  interface Props {
    toggleMode?: boolean;
  }

  let { toggleMode = false }: Props = $props();

  let presetMode: PresetEditorModes = $state();
  trackerEffect(() => (presetMode = $bandMenu.band.presetMode()));

  let enabledInstruments: InstrumentId[] = $state([]);
  trackerEffect(() => (enabledInstruments = $bandMenu.band.activeInstruments()));

  const instrumentCategoryArray: { name: string; instruments: InstrumentId[] }[] = [
    { name: 'Stringed Instruments', instruments: ['guitar', 'bass', 'mandolin'] },
    { name: 'Percussion', instruments: ['feet'] },
  ];

  let rows: (
    | { type: 'heading'; name: string }
    | { type: 'instrument'; id: InstrumentId; categoryBegin: boolean; categoryEnd: boolean }
  )[] = $derived(
    instrumentCategoryArray
      .map((category) =>
        ((toggleMode ? [{ type: 'heading', name: category.name }] : []) as typeof rows).concat(
          category.instruments
            .filter((instId) => toggleMode || enabledInstruments.includes(instId))
            .map((instId, instIndex, instArray) => ({
              type: 'instrument',
              id: instId,
              categoryBegin: instIndex === 0,
              categoryEnd: instIndex === instArray.length - 1,
            }))
        )
      )
      .filter((categoryRows) => toggleMode || categoryRows.length > 0)
      .flat()
  );

  function toggleInstrument(instId: InstrumentId) {
    $bandMenu.instruments[instId].setEnabled(!$bandMenu.instruments[instId].enabled());
  }
</script>

{#each rows as row ('id' in row ? row.id : row.name)}
  <div transition:slideFade>
    {#if row.type == 'heading'}
      <div class="ml-[1px] pb-2 pl-4 pt-4 sm-band:pl-3">
        <h3 class="text-sm font-medium text-gray-500">{row.name}</h3>
      </div>
    {/if}

    {#if row.type == 'instrument'}
      {@const instId = row.id}
      {@const first = toggleMode ? row.categoryBegin : false}
      {@const last = toggleMode ? row.categoryEnd : false}
      {@const roundingClasses = `${first ? 'rounded-t-lg' : ''} ${last ? 'rounded-b-lg' : ''}`}
      {@const borderClasses = first ? 'border' : 'border-x border-b'}
      <div class="{roundingClasses} {borderClasses} border-panelWellBorder transition-all">
        <InstrumentPanelOpener
          {instId}
          {toggleMode}
          noOpenMode={presetMode == 'rename' || presetMode == 'delete'}
          checked={enabledInstruments.includes(instId)}
          class={roundingClasses}
          on:click={() =>
            toggleMode
              ? toggleInstrument(instId)
              : modalController.zoomInto({
                  component: InstrumentPanel,
                  props: { instId },
                  title: `${capitalize(instId)} Settings`,
                })}
        />
      </div>
    {/if}
  </div>
{/each}

{#if !toggleMode && enabledInstruments.length === 0}
  <div
    class="border-x border-b border-panelWellBorder bg-white px-6 pb-4 pt-5 text-center"
    transition:slideFade
  >
    <span class="font-medium">No instruments in the band.</span>
    Add&nbsp;some!
  </div>
{/if}
