import { Crnt } from '@/Crnt';

Template.registerHelper('editMode', () => {
  return Crnt.song()?.editMode();
});

Template.registerHelper('currentSongOwnerFullName', () => {
  const songOrMedley = Crnt.song() || Crnt.medley();
  if (!songOrMedley) return;
  return `${songOrMedley.userFirst()} ${songOrMedley.userLast() || ''}`.trim() || null;
});
