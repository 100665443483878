import { NominationsCollection } from '@/collections/NominationsCollection';
import { SongsCollection } from '@/collections/SongsCollection';
import { Roles } from '@/Roles';

Template.nominationsAdminPage.onCreated(() => {
  const instance = Template.instance();
  instance.readyToLoad = new ReactiveVar(false);
  instance.autorun(() => {
    if (Roles.userHasPermission(Meteor.userId(), 'nominations.approve')) {
      instance.readyToLoad.set(true);
      instance.subscribe('nominations');
    }
  });
});

Template.nominationsAdminPage.onRendered(() => {});

Template.nominationsAdminPage.helpers({
  ready() {
    return Template.instance().readyToLoad.get() && Template.instance().subscriptionsReady();
  },
  hueForUserId(uid) {
    let hash = 7;
    for (let i = 3; i >= 0; i--) {
      hash = hash * 31 + uid.charCodeAt(i);
    }
    const hue = hash % 360;
    return hue;
  },
  nominations() {
    return NominationsCollection.find();
  },
  nominatedSongs() {
    return fetchNominations({ status: { $nin: ['public'] } });
  },
  publicSongs() {
    return fetchNominations({ status: 'public' });
  },
  countOf(thing) {
    return thing.count ? thing.count() : thing.length;
  },
  canApproveNominations() {
    return Roles.userHasPermission(Meteor.userId(), 'nominations.approve');
  },
});

const fetchNominations = (filters = {}) => {
  return NominationsCollection.find(filters)
    .map((nomination) => {
      const song = SongsCollection.findOne(nomination.songId);
      song.indexName = song.name.replace(/ +\[.+\]$/, '').replace(/^(The|A|An) (.+)$/i, '$2, $1');
      const labelMatch = song.name.match(/ \[(.+)\]$/);
      song.indexLabel = labelMatch ? labelMatch[1] : undefined;
      song.nomination = nomination;
      return song;
    })
    .sort((a, b) => a.nomination.createdAt - b.nomination.createdAt);
};
