import AudioManager from '@/audio/engine/AudioManager';
import { executePlayerInstructions } from '@/audio/executePlayerInstructions';
import CountSequencer from '@/band/instruments/count/CountSequencer';
import type { PlayerInstruction } from '@/band/instruments/PlayerInstruction';
import type { Song } from '@/chart/Song';
import { Crnt } from '@/Crnt';
import { bpmToTpm } from '@/music/bpm-tpm-conversion';
import UserPreferences from '@/user/UserPreferences';

let countInstructions: CountInstruction[] = [];

const sampleDelay = 0.05; // 50ms of silence before sample

export default {
  playFromInstruction(instruction: CountInstruction, deadline: number, beatDuration: number): void {
    if (!instruction) return;

    if (instruction.guitar == 'muted-boom') {
      AudioManager.playSample({
        id: 'count-boom',
        atTime: deadline - sampleDelay,
        volume: 1.0,
      });
    } else if (instruction.guitar == 'muted-chuck') {
      AudioManager.playSample({
        id: 'count-chuck',
        atTime: deadline - sampleDelay,
        volume: 0.7,
      });
    }

    if (instruction.voice) {
      const [song, medley] = [Crnt.song(), Crnt.medley()];
      const tpm = song
        ? song.tpm()
        : medley
          ? bpmToTpm({
              bpm: medley.bpm(),
              timeSignature: Crnt.songs[0]!.timeSignature(),
              ignoreUserPreferences: true,
            })
          : 99;
      AudioManager.playSample({
        id: instruction.voice + (tpm < 160 ? '-slow' : ''),
        atTime: deadline - sampleDelay,
        volume: instruction.softVoice ? 0.5 : 1,
      });
    }

    if (instruction.playerInstructions) {
      executePlayerInstructions(
        instruction.playerInstructions as PlayerInstruction[],
        deadline,
        beatDuration
      );
    }
  },

  update(song: Song): void {
    countInstructions = UserPreferences.get('countInEnabled')
      ? CountSequencer.getInstructions({
          timeSignature: song.timeSignature(),
          clickTrack: song.band.clickTrack,
        })
      : [];
  },

  beatCount(): number {
    return countInstructions.length;
  },

  labelForBeat(index: number): string | undefined {
    const label = countInstructions[index]?.label;
    return label ? '' + label : undefined;
  },

  playCountAtIndex({
    countIndex,
    deadline,
    beatDuration,
  }: {
    countIndex: number;
    deadline: number;
    beatDuration: number;
  }): void {
    this.playFromInstruction(countInstructions[countIndex] ?? {}, deadline, beatDuration);
  },
};
