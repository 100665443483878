/**
 * Iterates over a range of numbers between `min` and `max`, inclusive.
 * @param min - The minimum value to iterate over.
 * @param max - The maximum value to iterate over.
 * @param step - The step size to use. Defaults to 1.
 */
export function* range(min: number, max: number, step = 1): Generator<number> {
  if (min <= max) {
    yield min;
    yield* range(min + step, max, step);
  }
}
