import { chop, downstroke, upstroke } from '@/band/instruments/mandolin/MandolinActions';
import type { MandolinIntent } from '@/band/instruments/mandolin/MandolinIntent';
import type { MandolinChopDynamicsSetting } from '@/band/instruments/mandolin/settings/MandolinChopDynamicsSetting';
import type { LinearizedCell } from '@/chart/LinearizedCell';
import { lerp } from '@/utilities/lerp';
import { randomPlusMinus } from '@/utilities/randomPlusMinus';

export function standardChopMeasure(
  cells: [LinearizedCell, LinearizedCell?],
  { chopDynamics }: { chopDynamics: MandolinChopDynamicsSetting }
): (MandolinIntent | null)[] {
  const intensity = chopDynamics.intensityIndex;
  const chopSustain = chopDynamics.sustainIndex;

  return cells.flatMap((cell, i) => {
    const cellPlans = cell?.plans.mandolin ?? {};
    const isSecondCell = i === 1;
    return [
      cellPlans.downstroke
        ? downstroke({
            db: lerp(-2, isSecondCell ? -1 : 0, cellPlans.downstroke) - 5,

            velocity: Math.round(
              lerp(
                [0.4, 1.4, 2.4][intensity] as number,
                [3, 4, 5][intensity] as number,
                cellPlans.downstroke
              )
            ) as 0 | 1 | 2 | 3 | 4 | 5,

            chordSpread:
              lerp(
                [1.1, 1.4, 2.5][intensity] as number,
                [1.9, 2.4, 3.1][intensity] as number,
                cellPlans.downstroke
              ) + (isSecondCell ? -randomPlusMinus(0.2) : randomPlusMinus(0.3)),

            chordAttenuation: lerp(isSecondCell ? -15 : -13, 0, cellPlans.downstroke),
          })
        : null,

      cellPlans.upstrokeFill
        ? upstroke({
            db: lerp(-4, 0, cellPlans.upstrokeFill),

            bias:
              intensity > 0 && cellPlans.upstrokeFill > 0.6
                ? Math.random() < 0.7
                  ? 'high'
                  : 'midHigh'
                : Math.random() < 0.5
                  ? 'midLow'
                  : 'midHigh',

            velocity: Math.round(
              lerp(
                [1.0, 1.6, 2.8][intensity] as number,
                [2.4, 3.2, 4][intensity] as number,
                cellPlans.upstrokeFill
              )
            ) as 1 | 2 | 3 | 4,

            chordSpread:
              lerp(
                [1.1, 1.8, 2.5][intensity] as number,
                [1.9, 2.8, 3.5][intensity] as number,
                cellPlans.upstrokeFill
              ) + randomPlusMinus(0.5),

            chordAttenuation:
              lerp(-15, -4, cellPlans.upstrokeFill) + ([-4, -2, 0] as const)[chopSustain],
          })
        : null,

      chop({
        db: [1, 0, -4][chopSustain] as number,
        attack: ['soft', 'normal', 'hard'][intensity] as 'soft' | 'normal' | 'hard',
        chordSpread:
          0.75 * Math.random() +
          ([1.5, 2.25, 3.2] as const)[intensity] +
          ([0, -0.25, 0.5] as const)[chopSustain],
        chordDuration: [0, 0.025, 0.2][
          Math.max((cellPlans.upstrokeFill ?? 0) > 0.7 ? 1 : 0, chopSustain)
        ] as number,
        chordAttenuation: ([-3, -1, 0] as const)[intensity] + ([-9, -6, 4] as const)[chopSustain],
      }),

      cellPlans.secondUpstroke
        ? upstroke({
            db: lerp(-6, -2, cellPlans.secondUpstroke) + randomPlusMinus(2),

            bias: 'midHigh',

            velocity: Math.round(
              lerp(
                [1.0, 1.3, 1.8][intensity] as number,
                [2.2, 2.6, 3][intensity] as number,
                cellPlans.secondUpstroke
              )
            ) as 1 | 2 | 3 | 4,

            chordDuration: 0,
            chordSpread: 0,
          })
        : null,
    ];
  });
}

/*
    const playFirstBeat = Math.random() < firstBeatFreq();

    return [
      playFirstBeat
        ? strumDown({
            bias: 'low',
            spread: firstBeatSpread(),
            db: firstBeatVolume(),
          })
        : null,
      null,
      chop(sustain() / 1000, {
        spread: spread() * 0.8,
        db: 0,
        bias: (['low', 'mid', 'high'] as const)[bias() + 1],
      }),
      null,
      playFirstBeat && Math.random() < secondBeatFreq()
        ? strumDown({
            bias: 'low',
            spread: secondBeatSpread(),
            db: secondBeatVolume(),
          })
        : null,
      Math.random() < beat3UpFreq()
        ? strumUp({
            bias: 'mid',
            spread: 1.75 + randomPlusMinus(0.75),
            db: beat3UpIntensity() * 10 - 10,
          })
        : null,
      chop(sustain() / 1000, {
        spread: spread() * 1.0,
        db: 0,
        bias: (['low', 'mid', 'high'] as const)[bias() + 1],
      }),

      // Beat 4U is always either strumUp or chop(0)
      // And never if the chord is about to change
      // Maybe simpler to always have it be unvoiced
      Math.random() < beat4UpFreq()
        ? chop(beat4UpSustain(), {
            bias: 'mid',
            direction: 'U',
            spread: 1.75 + randomPlusMinus(0.75),
            db: beat4UpIntensity() * 10 - 10,
          })
        : null,
    ];
*/
