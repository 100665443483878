<script lang="ts">
  interface Props {
    id: string;
    size?: number;
    color?: string;
    verticalShift?: number;
    opacity?: number;
  }

  let { id, size = 16, color = 'currentColor', verticalShift = 0, opacity = 1 }: Props = $props();
</script>

<svg
  style="width: {size}px; height: {size}px; color: {color}; vertical-align: {verticalShift}px; opacity: {opacity}"
  ><use xlink:href="#{id}"></use></svg
>
