import type { InstrumentPresetSettings } from '@/band/instruments/InstrumentPresetSettings';
import type { InstrumentSettingDefinitions } from '@/band/instruments/InstrumentSettingDefinitions';

type SettingsHashFromDefinitions<SettingDefinitions extends InstrumentSettingDefinitions> = {
  [P in keyof SettingDefinitions]: InstanceType<SettingDefinitions[P]['class']>;
};

export function createSettingsHashWithPreset<
  SettingDefinitions extends InstrumentSettingDefinitions,
>(
  settingDefinitions: SettingDefinitions,
  presetSettings: InstrumentPresetSettings<SettingsHashFromDefinitions<SettingDefinitions>>
): SettingsHashFromDefinitions<SettingDefinitions> {
  return Object.fromEntries(
    Object.entries(settingDefinitions).map(([key, def]) => {
      const SettingsClass = def.class;
      const setting = new SettingsClass(presetSettings[key]);
      return [key, setting];
    })
  ) as SettingsHashFromDefinitions<SettingDefinitions>;
}
