import type {
  SongRecord,
  SongRecordMeasure,
  SongRecordSection,
} from '@/collections/SongsCollection';
import { ObjectPropertyKeyTransformer } from '@/lib/ObjectPropertyKeyTransformer';
import type { GroundedDocumentCompressor } from '@/local-db/GroundedCollection';

export const GroundedSongsCompressor: GroundedDocumentCompressor<SongRecord> = {
  compress(doc: SongRecord): Record<string, any> {
    if (!doc) return doc;
    return songTransformer.abbreviate(doc);
  },

  decompress(pckd: Record<string, any>): SongRecord {
    if (!pckd) return pckd;
    return songTransformer.unabbreviate(pckd);
  },
};

const songTransformer = new ObjectPropertyKeyTransformer<SongRecord>([
  ['_id', '_id'],
  ['n', 'name'],
  ['k', 'key'],
  ['t', 'tpm'],
  ['uAt', 'updatedAt', { date: true }],
  ['cAt', 'createdAt', { date: true }],
  ['ts', 'timeSignature'],
  ['uId', 'userId'],
  ['uF', 'userFirst'],
  ['uL', 'userLast'],
  ['f', 'free'],
  ['bd', 'band'],
  ['bpid', 'presetId'],
  ['pn', 'notes'],
  ['u', 'unsynced'],
  [
    's',
    'sections',
    {
      compress: (sections: SongRecordSection[]) =>
        sections.map((section) => sectionTransformer.abbreviate(section)),
      decompress: (s: Record<string, any>[]) =>
        s.map((sctn) => sectionTransformer.unabbreviate(sctn)),
    },
  ],
]);

const sectionTransformer = new ObjectPropertyKeyTransformer<SongRecordSection>([
  ['n', 'name'],
  ['t', 'type'],
  ['ll', 'lineLengths'],
  ['r', 'repetitions'],
  [
    'm',
    'measures',
    {
      compress: (measures: SongRecordMeasure[]) =>
        JSON.parse(
          JSON.stringify(
            measures.map((measure: SongRecordMeasure) => measureTransformer.abbreviate(measure))
          ).replace(/{"c":"([^"]+)"}/g, '"$1"') // shorthand for just-a-chord
        ),
      decompress: (m: Record<string, any>[]) =>
        m
          .map((measure: string | Record<string, any>) =>
            typeof measure == 'string' ? { c: measure } : measure
          )
          .map((measure: Record<string, any>) => measureTransformer.unabbreviate(measure)),
    },
  ],
]);

const measureTransformer = new ObjectPropertyKeyTransformer<SongRecordMeasure>([
  ['c', 'chord'],
  ['b2', 'beat2'],
  ['b3', 'beat3'],
  ['s', 'split'],
  ['x', 'effect'],
  ['h', 'half'],
  ['v', 'volta'],
  ['ts', 'timeSig'],
]);
