import { strict as assert } from 'assert';
import { chordAriaLabels } from '@/music/chordAriaLabels';
import type { ChordTypeCode } from '@/music/ChordTypeCode';
import chromaForNote from '@/music/chromaForNote';
import { chromaticScales } from '@/music/chromaticScale';
import { chromaticLetterCodes, majorIntervals } from '@/music/music-knowledge';

const chromaForKey = (key: Key) => {
  const note = /^[A-G][#b]?/.exec(key)?.[0] as LetterAndAccidental;
  return chromaForNote(note);
};

const chordParsingRegex = /^([A-G])([#b-])?(.*?)\/?([A-G])?([#b])?$/;

export class Chord {
  readonly letter!: ScaleLetters;
  readonly accidental?: '#' | 'b';
  readonly type!: ChordTypeCode;
  readonly bassLetter?: ScaleLetters;
  readonly bassAccidental?: '#' | 'b';
  readonly key?: Key;

  constructor(chord: Chord | string, key?: string) {
    if (!chord) throw new Error('Tried to instantiate null chord');
    if (typeof chord == 'object') {
      Object.assign(this, chord);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      [this.letter, this.accidental, this.type, this.bassLetter, this.bassAccidental] =
        (chordParsingRegex.exec(chord)?.slice(1) as any) || [];
      if (this.accidental == ('-' as any)) this.accidental = 'b'; // still 20k songs with "-" for flat
      if (!this.letter) throw new Error(`Bad chord: ${chord}`);
      if (this.type === undefined) this.type = ''; // major by default
    }
    if (key) this.key = key as Key;
  }

  get rootNote(): LetterAndAccidental {
    return `${this.letter}${this.accidental || ''}`;
  }

  get sharp(): boolean {
    return this.accidental == '#';
  }

  get flat(): boolean {
    return this.accidental == 'b';
  }

  get chroma(): ZeroThroughEleven {
    return chromaForNote(this.rootNote);
  }

  get relativeChroma(): ZeroThroughEleven {
    assert(this.keyNote, `Tried to get relative chroma of chord (${this.toString()}) without key`);
    const keyChroma = chromaForKey(this.keyNote);
    return ((this.chroma - keyChroma + 36) % 12) as ZeroThroughEleven;
  }

  get keyNote(): LetterAndAccidental | undefined {
    if (!this.key) return;
    return /^[A-G][#b]?/.exec(this.key)?.[0] as LetterAndAccidental;
  }

  get bassNote(): LetterAndAccidental | undefined {
    return this.bassLetter ? `${this.bassLetter}${this.bassAccidental || ''}` : undefined;
  }

  get bassSharp(): boolean {
    return this.bassAccidental == '#';
  }

  get bassFlat(): boolean {
    return this.bassAccidental == 'b';
  }

  get bassChroma(): ZeroThroughEleven | undefined {
    if (!this.bassNote) return;
    return chromaForNote(this.bassNote);
  }

  get bassRelativeChroma(): ZeroThroughEleven | undefined {
    const bassChroma = this.bassChroma;
    if (typeof bassChroma !== 'number') return;
    assert(this.keyNote, `Tried to get relative chroma of chord (${this.toString()}) without key`);
    const keyChroma = chromaForKey(this.keyNote);
    return ((bassChroma - keyChroma + 36) % 12) as ZeroThroughEleven;
  }

  shift(semitones: number, inKey: Key | Chord | undefined = this.key): Chord {
    if (!inKey) throw new Error(`Tried to shift chord (${this.toString()}) without setting key`);
    const keyString = inKey.toString();
    if (semitones == 0) return new Chord(this, keyString);
    const keyTonic = keyString.match(/^([A-G](#|b)?)/)?.[1] as LetterAndAccidental | undefined;
    if (!keyTonic) throw new Error(`Key (${keyString}) not valid for chord ${this.toString()}`);
    const newKeyChroma = new Chord(keyTonic).chroma;
    const newRootNote =
      chromaticScales[keyTonic][(this.chroma + semitones - newKeyChroma + 36) % 12];
    const newBassNote =
      typeof this.bassChroma == 'number'
        ? chromaticScales[keyTonic][(this.bassChroma + semitones - newKeyChroma + 36) % 12]
        : undefined;
    return new Chord(
      `${newRootNote}${this.type || ''}` + (newBassNote ? `/${newBassNote}` : ''),
      keyString
    );
  }

  shiftRoot(semitones: number, inKey: Key | Chord | undefined = this.key): Chord {
    if (!inKey)
      throw new Error(`Tried to shift chord root (${this.toString()}) without setting key`);
    const keyString = inKey.toString();
    if (semitones == 0) return new Chord(this, keyString);
    const keyTonic = keyString.match(/^([A-G](#|b)?)/)?.[1] as LetterAndAccidental | undefined;
    if (!keyTonic) throw new Error(`Key (${keyString}) not valid for chord ${this.toString()}`);
    const newKeyChroma = new Chord(inKey).chroma;
    const newRootNote =
      chromaticScales[keyTonic][(this.chroma + semitones - newKeyChroma + 36) % 12];
    return new Chord(
      `${newRootNote}${this.type || ''}` + (this.bassNote ? `/${this.bassNote}` : ''),
      inKey.toString()
    );
  }

  shiftBassNote(semitones: number, inKey: Key | Chord | undefined = this.key): Chord {
    if (!inKey)
      throw new Error(`Tried to shift chord bass (${this.toString()}) without setting key`);
    const keyString = inKey.toString();
    if (semitones == 0) return new Chord(this, keyString);
    const keyTonic = keyString.match(/^([A-G](#|b)?)/)?.[1] as LetterAndAccidental | undefined;
    if (!keyTonic) throw new Error(`Key (${keyString}) not valid for chord ${this.toString()}`);
    const newKeyChroma = new Chord(inKey).chroma;
    if (typeof this.bassChroma !== 'number') return new Chord(this, inKey.toString());
    const newBassNote =
      chromaticScales[keyTonic][(this.bassChroma + semitones - newKeyChroma + 36) % 12];
    return new Chord(
      `${this.rootNote}${this.type || ''}` + (newBassNote ? `/${newBassNote}` : ''),
      inKey.toString()
    );
  }

  transpose(newKey: Key): Chord {
    if (!this.key)
      throw new Error(
        `Tried to transpose chord (${this.toString()}) without setting the key first`
      );
    const shiftSteps = chromaForKey(newKey) - chromaForKey(this.key);
    return this.shift(shiftSteps, newKey);
  }

  withNewRootNote(newRootNote: LetterAndAccidental): Chord {
    return new Chord(
      `${newRootNote}${this.type || ''}` + (this.bassNote ? `/${this.bassNote}` : ''),
      this.key
    );
  }

  withNewType(newType: string): Chord {
    return new Chord(
      `${this.rootNote}${newType || ''}` + (this.bassNote ? `/${this.bassNote}` : ''),
      this.key
    );
  }

  withNewBassNote(newBassNote?: LetterAndAccidental): Chord {
    return new Chord(
      `${this.rootNote}${this.type || ''}` + (newBassNote ? `/${newBassNote}` : ''),
      this.key
    );
  }

  inKey(key: string | undefined): Chord {
    return new Chord(this, key);
  }

  withoutKey(): Chord {
    return new Chord({ ...this, key: undefined });
  }

  withNewAccidental(attr: string, value: boolean): Chord {
    const clone = JSON.parse(JSON.stringify(this)) as Record<keyof Chord, any>;
    if (attr == 'sharp') {
      clone.accidental = value ? '#' : undefined;
    }
    if (attr == 'flat') {
      clone.accidental = value ? 'b' : undefined;
    }
    if (attr == 'bassSharp') {
      clone.bassAccidental = value ? '#' : undefined;
    }
    if (attr == 'bassFlat') {
      clone.bassAccidental = value ? 'b' : undefined;
    }
    return new Chord(clone);
  }

  getInterval(): IntervalForChroma {
    return getIntervalForChroma(this.relativeChroma, this);
  }

  getBassInterval(): IntervalForChroma | undefined {
    if (typeof this.bassRelativeChroma !== 'number') return;
    return getIntervalForChroma(this.bassRelativeChroma, this);
  }

  get minor(): boolean {
    return this.type.includes('m');
  }
  get alteredFifth(): boolean {
    return ['o', '0', '+', '7#5', '7#11'].includes(this.type ?? '');
  }

  toHtml(system?: 'letters' | 'numbers' | 'roman'): string {
    if (system == 'numbers') return this.toNumberHtml();
    if (system == 'roman') return this.toRomanHtml();
    return (
      `${this.letter}${accidentalHtml(this)}${typeHtml(this)}` +
      (this.bassLetter
        ? `<span class="bass">/${this.bassLetter}${bassAccidentalHtml(
            this.bassAccidental || ''
          )}</span>`
        : '')
    );
  }

  toNumberHtml(): string {
    if (!this.key)
      throw new Error(
        `Tried to get number for chord (${this.toString()}) without setting the key first`
      );
    const interval = this.getInterval();
    const bassInterval = this.bassLetter && this.getBassInterval();
    return (
      `${interval.number}${accidentalHtml(interval)}${typeHtml(this)}` +
      (bassInterval
        ? `<span class="bass">/${bassInterval.number}${accidentalHtml(bassInterval)}</span>`
        : '')
    );
  }

  toRomanHtml(): string {
    if (!this.key)
      throw new Error(
        `Tried to get number for chord (${this.toString()}) without setting the key first`
      );
    const interval = this.getInterval();
    const roman = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII'][interval.number - 1]!;
    let html = accidentalHtml(interval);
    html += this.minor
      ? `<span class="romanMinor" aria-label="minor">${roman.toLowerCase()}</span>`
      : roman;
    html += typeHtml(this, { noMinor: true });
    return html;
  }

  letterCode() {
    return chromaticLetterCodes[this.chroma].toLowerCase() as Lowercase<
      (typeof chromaticLetterCodes)[number]
    >;
  }

  toAria(): string {
    let letter = this.letter.toLowerCase();
    if (letter == 'a') letter = 'a,'; // otherwise screen reader says a short "A" sound
    const accidental = this.sharp ? 'sharp' : this.flat ? 'flat' : '';
    return `${letter} ${accidental} ${chordAriaLabels[this.type]}`;
  }

  toString(): string {
    return `${this.rootNote}${this.type}` + (this.bassNote ? `/${this.bassNote}` : '');
  }

  toKeyString(): Key {
    return `${this.rootNote}${this.minor ? 'm' : ''}`;
  }

  equals(other?: Chord): boolean {
    return this.toString() == other?.toString();
  }
}

const sharpHtml = '<span class="sharp">&#9839;</span>';
const flatHtml = '<span class="flat">&#x266d;</span>';
const accidentalHtml = (chord: Chord | IntervalForChroma) =>
  chord.sharp ? sharpHtml : chord.flat ? flatHtml : '';
const bassAccidentalHtml = (acc?: '#' | 'b' | '') =>
  acc == '#' ? sharpHtml : acc == 'b' ? flatHtml : '';

const typeHtml = (chord: Chord, opts?: { noMinor?: boolean }) => {
  const minorHtml = opts?.noMinor ? '' : '<span class="minor">m</span>';
  const type = chord.type;
  if (type == '') return '';
  if (type == 'm') return minorHtml;

  if (type == '6') return '<span class="six">6</span>';
  if (type == '^') return '<span class="maj7">Δ</span><span class="seven">7</span>';
  if (type == '^9') return '<span class="maj7">Δ</span><span class="nine">9</span>';
  if (type == '^13') return '<span class="maj7">Δ</span><span class="thirteen">13</span>';
  if (type == '69') return '<span class="nine">6/9</span>';

  if (type == '7') return '<span class="seven">7</span>';
  if (type == '9') return '<span class="nine">9</span>';
  if (type == '13') return '<span class="thirteen">13</span>';
  if (type == '7s') return '<span class="seven">7sus</span>';
  if (type == '11') return '<span class="eleven">11</span>';
  if (type == '9s') return '<span class="nine">9sus</span>';
  if (type == '13s') return '<span class="thirteen">13sus</span>';

  if (type == 'm7') return `${minorHtml}<span class="seven">7</span>`;
  if (type == 'm6') return `${minorHtml}<span class="six">6</span>`;
  if (type == 'm^') return `${minorHtml}<span class="maj7">Δ</span><span class="seven">7</span>`;
  if (type == 'm9') return `${minorHtml}<span class="nine">9</span>`;
  if (type == 'm11') return `${minorHtml}<span class="eleven">11</span>`;
  if (type == 'mb6') return `${minorHtml}${flatHtml}<span class="six">six</span>`;
  if (type == 'm^9') return `${minorHtml}<span class="maj7">Δ</span><span class="nine">9</span>`;
  if (type == 'm69') return `${minorHtml}<span class="nine">6/9</span>`;
  if (type == 'o') return '<span class="diminished">&deg;</span><span class="seven">7</span>';
  if (type == '0') return '<span class="half-diminished">&oslash;</span>';
  if (type == '+') return '<span class="augmented">+</span>';
  if (type == 'o^')
    return '<span class="diminished">&deg;</span><span class="maj7">Δ</span><span class="seven">7</span>';
  if (type == 'o9') return '<span class="diminished">&deg;</span><span class="nine">9</span>';
  if (type == '+7') return '<span class="augmented">+</span><span class="seven">7</span>';

  if (type == '5') return '<span class="five">5</span>';
  if (type == '2')
    return '<span class="sus2"><span class="sus-word">sus</span><span class="sus-num">2</span></span>';
  if (type == '4')
    return '<span class="sus4"><span class="sus-word">sus</span><span class="sus-num">4</span></span>';

  return '<span class="unknown">?</span>';
};

type IntervalForChroma = {
  number: number;
  accidental?: '#' | 'b';
  sharp?: boolean;
  flat?: boolean;
};

const getIntervalForChroma = (chroma: ZeroThroughEleven, chord: Chord): IntervalForChroma => {
  const result = {
    number: majorIntervals[chroma],
  } as IntervalForChroma;
  if (result.number % 1 == 0.5) {
    const usuallySharp = chord.keyNote && chromaticScales[chord.keyNote][chroma]!.includes('#');
    if (chord.sharp || (!chord.flat && usuallySharp)) {
      result.number -= 0.5;
      result.accidental = '#';
      result.sharp = true;
    } else {
      result.number += 0.5;
      result.accidental = 'b';
      result.flat = true;
    }
  }
  return result;
};
