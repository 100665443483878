import tippyJs from 'tippy.js';
import ToggleSwitch from '@/ui/ToggleSwitch';

Template.menuToggleRow.onRendered(() => {
  const instance = Template.instance();
  var lastState = 'unset';
  instance.autorun(() => {
    const newState = Template.currentData().toggleOn;
    instance.find('.js-checkbox').checked = newState && newState != 'indeterminate';
    instance.find('.js-checkbox').indeterminate = newState == 'indeterminate';
    if (newState != lastState) {
      if (newState && newState != 'indeterminate') {
        instance.$('.js-detailsPane').slideDown();
      } else {
        instance.$('.js-detailsPane').slideUp();
      }
    }
    lastState = newState;
  });
  instance.toggleSwitch = new ToggleSwitch({
    element: instance.find('.js-switch'),
  });
  if (instance.data.help) {
    tippyJs(instance.find('[data-tippy-content]'), {
      placement: 'bottom',
      delay: [300, 50],
      hideOnClick: false,
      maxWidth: 250,
    });
  }
});

Template.menuToggleRow.onDestroyed(() => {
  Template.instance().toggleSwitch?.destroy();
});

Template.menuToggleRow.events({
  'change': function (event, instance) {
    instance.data.setValueFunc(instance.find('.js-checkbox').checked);
  },
});
