<script lang="ts">
  import PanelOpenerChevron from '@/band/ui/components/PanelOpenerChevron.svelte';
  import PanelSettingSection from '@/band/ui/components/PanelSettingSection.svelte';
  import PanSlider from '@/band/ui/components/PanSlider.svelte';
  import PrettySlider from '@/band/ui/components/PrettySlider.svelte';
  import GlobalMixerPanel from '@/band/ui/panels/GlobalMixerPanel.svelte';
  import PanelModalLayout from '@/band/ui/panels/PanelModalLayout.svelte';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import VolumeIcon from '@/ui/icons/VolumeIcon.svelte';
  import { MultiPanelModalController } from '@/ui/settings/MultiPanelModalController.svelte';
  import { slideFade } from '@/ui/svelte-transitions';

  interface Props {
    instId: InstrumentId;
  }

  let { instId }: Props = $props();

  const modalController = MultiPanelModalController.current();

  const bandMenu = getBandMenuState();
  let bandInstrument = $derived($bandMenu.instruments[instId]);

  let currentVolume: number = $state(1);
  let currentPan: number = $state(0);
  trackerEffect(() => (currentVolume = bandInstrument.volume()));
  trackerEffect(() => (currentPan = bandInstrument.pan()));

  let [biasL, biasR] = $derived([0.5 - currentPan * 0.5, 0.5 + currentPan * 0.5]);

  let usingCustomVolume = $state(false);
  trackerEffect(() => (usingCustomVolume = typeof bandInstrument.customVolume() == 'number'));
  let usingCustomPan = $state(false);
  trackerEffect(() => (usingCustomPan = typeof bandInstrument.customPan() == 'number'));

  let muted = $state(false);
  trackerEffect(() => (muted = bandInstrument.globalMute()));
</script>

<PanelModalLayout>
  {#snippet header()}
    <header class="panelHeader">
      These sliders override the <span class="whitespace-nowrap font-medium"
        >{bandInstrument.name} Volume/Pan</span
      >
      <span class="whitespace-nowrap">for the <i>current song only</i>.</span>
      <!-- <a href="#" class="font-medium">Learn more</a>. -->
    </header>
  {/snippet}

  {#snippet main()}
    <div>
      {#if muted}
        <div
          class="m-4 rounded-md border border-red-300 bg-red-50 p-1 px-4 py-2 text-center text-red-900"
        >
          <div class="col-span-2">
            The {instId} has been globally muted.
            <span class="whitespace-nowrap">To hear it again,</span>
            open the Global Volume/Pan Mixer below
            <span class="whitespace-nowrap">and unmute it</span>.
          </div>
        </div>
      {/if}

      <div class="m-4">
        <div
          class="flex items-center rounded-t-lg border border-panelWellBorder bg-white px-6 transition-all duration-300"
          class:rounded-b-lg={!usingCustomVolume}
        >
          <div class="flex-1 py-4 font-bold text-gray-900">Volume</div>
          <div
            class="flex items-center pr-2 transition-all"
            class:saturate-50={!usingCustomVolume}
            class:opacity-80={!usingCustomVolume}
          >
            <span class="mr-1 leading-none" aria-label="Volume">
              <VolumeIcon volume={currentVolume} color="var(--primary-500)" />
            </span>
            <PrettySlider
              min={0}
              max={100}
              step={1}
              value={currentVolume * 100}
              thumbDiameter={2}
              ariaLabel="Volume"
              ariaValues={{
                min: 0,
                max: 100,
                now: currentVolume * 100,
                text: `${(Math.round(currentVolume * 20) * 5).toFixed(0)} percent`,
              }}
              on:change={({ detail: value }) =>
                bandInstrument.setCustomVolume(value < 5 ? 0 : value / 100)}
            />
          </div>
        </div>
        {#if usingCustomVolume}
          <button
            class="btn-base block w-full rounded-b-lg border border-t-0 border-panelWellBorder bg-white px-4 py-2 text-center text-primary-600 hover:bg-primary-100 hover:text-primary-700"
            onclick={() => bandInstrument.setCustomVolume(undefined)}
            transition:slideFade
          >
            Reset to Global Settings
          </button>
        {/if}
      </div>

      <div class="m-4">
        <div
          class="flex items-center rounded-t-lg border border-panelWellBorder bg-white pl-6 pr-2.5 transition-all duration-300"
          class:rounded-b-lg={!usingCustomPan}
        >
          <div class="flex-1 py-4 font-bold text-gray-900">Pan</div>
          <div
            class="flex items-center gap-2 pr-2 transition-all"
            class:saturate-50={!usingCustomPan}
            class:opacity-80={!usingCustomPan}
          >
            <span style="color: hsl({!global ? 110 : 206}, {50 * biasL}%, {100 - 60 * biasL}%)"
              >L</span
            >
            <PanSlider
              value={currentPan}
              on:change={({ detail: value }) => bandInstrument.setCustomPan(value)}
            />
            <span style="color: hsl({!global ? 110 : 206}, {50 * biasR}%, {100 - 60 * biasR}%)"
              >R</span
            >
          </div>
        </div>
        {#if usingCustomPan}
          <button
            class="btn-base block w-full rounded-b-lg border border-t-0 border-panelWellBorder bg-white px-4 py-2 text-center text-primary-600 hover:bg-primary-100 hover:text-primary-700"
            onclick={() => bandInstrument.setCustomPan(undefined)}
            transition:slideFade
          >
            Reset to Global Settings
          </button>
        {/if}
      </div>
      <div class="px-7 pb-4 pt-0">
        <PanelSettingSection
          title="Global settings"
          helpText="The easiest way to get to these settings is through the Audio/Mix button in the right-side menu of any song page."
          whiteBg={false}
        >
          <div class="-mx-3 overflow-hidden rounded-lg border border-panelWellBorder bg-white">
            <button
              class="colors-panelOpener relative block w-full py-3 pl-2.5 pr-9 text-left text-primary-600 hover:text-primary-700 group-first:rounded-t-lg group-last:rounded-b-lg"
              onclick={() =>
                modalController.zoomInto({
                  component: GlobalMixerPanel,
                  title: 'Global Mixer',
                })}
            >
              <div class="px-3.5">Open Global Volume/Pan Mixer</div>
              <PanelOpenerChevron direction="right" />
            </button>
          </div>
        </PanelSettingSection>
      </div>
    </div>
  {/snippet}
</PanelModalLayout>
