import type { Cell } from '@/chart/Cell.svelte';
import { LinearizedBeat } from '@/chart/LinearizedBeat';
import type { LinearizedMeasure } from '@/chart/LinearizedMeasure';

interface CellPlanForGuitar {
  bassRun?: {
    // cell: LinearizedCell;
    // beatCount: number;
    intervals: (number | null)[];
    single?: boolean;
    quarters?: boolean;
    eighths?: boolean;
    syncopated?: boolean;
    extended?: boolean;
    descending?: boolean;
  };
  leadingNote?: {
    interval: number;
  };
  boomStrum?: boolean;
  upstrokeAccent?: boolean;
}

interface CellPlanForBass {
  octave?: 0 | 1 | 2;
  downbeatChromaIndex?: number;
  walkTargetChromaIndex?: number;
  walkPatternLength?: number;
  walkingBassChromaIndices?: number[];
}

interface CellPlanForMandolin {
  downstroke?: number;
  upstrokeFill?: number;
  secondUpstroke?: number;
}

export class LinearizedCell {
  readonly measure: LinearizedMeasure;
  readonly beats: [LinearizedBeat, ...LinearizedBeat[]];
  readonly originalCell: Cell;

  prev?: LinearizedCell;
  next?: LinearizedCell;

  plans: Partial<{
    guitar: CellPlanForGuitar;
    mandolin: CellPlanForMandolin;
    bass: CellPlanForBass;
    // feet: FeetPlans;
  }> = {};

  constructor(measure: LinearizedMeasure, cell: Cell) {
    this.measure = measure;
    this.originalCell = cell;

    const firstBeatInMeasure = cell.layout.barPos * cell.beatCount;
    this.beats = [] as unknown as typeof this.beats;
    for (let i = 0; i < cell.beatCount; i++) {
      const beatInMeasure = firstBeatInMeasure + i;
      this.beats.push(
        new LinearizedBeat({ measure, beatInMeasure, cell: this, beatNumber: i + 1 })
      );
    }
  }

  get chordHeard() {
    return this.originalCell.chordHeard;
  }
  get chordShown() {
    return this.originalCell.chordShown;
  }

  get effect() {
    return this.originalCell.effect;
  }
  get split() {
    return this.originalCell.split;
  }

  get negativeIndex() {
    // TODO: we can be smarter here because we've linearized the song
    return this.originalCell.negativeIndex;
  }

  get layout() {
    return this.originalCell.layout;
  }

  get repeated() {
    return this.originalCell.repeated;
  }
}
