import * as Sentry from '@sentry/browser';
import { strict as assert } from 'assert';
import { Meteor } from 'meteor/meteor';
import { Random } from 'meteor/random';
import { ReactiveVar } from 'meteor/reactive-var';
import { Tracker } from 'meteor/tracker';
import { rpcAddReference } from '@/server/methods/references/rpcAddReference';
import { rpcDeleteReference } from '@/server/methods/references/rpcDeleteReference';
import { rpcFetchReference } from '@/server/methods/references/rpcFetchReference';
import { rpcHideReference } from '@/server/methods/references/rpcHideReference';
import { rpcMakeReferencePublic } from '@/server/methods/references/rpcMakeReferencePublic';

type SongReference = Record<string, any>;

export class ReferenceManager {
  protected _refs: SongReference[] = [];
  protected _refsDep = new Tracker.Dependency();

  songId?: string;
  canHide = true;

  private _refsToHide: string[] = [];

  constructor(songId?: string) {
    this.songId = songId;
    if (this.songId) {
      this.fetchAndReplace();
    } else {
      this._loaded.set(true);
    }
  }

  protected _loaded = new ReactiveVar(false);

  loaded(): boolean {
    return this._loaded.get();
  }

  all({ includeHidden }: { includeHidden?: boolean } = {}): SongReference[] {
    this._refsDep.depend();
    return this._refs.filter((ref) => includeHidden || !this._refsToHide.includes(ref._id));
  }

  count({ includeHidden }: { includeHidden?: boolean } = {}): number {
    return this.all({ includeHidden }).length;
  }

  filter(
    query: Partial<SongReference>,
    { includeHidden }: { includeHidden?: boolean } = {}
  ): any[] {
    this._refsDep.depend();
    return this.all({ includeHidden }).filter((ref) => {
      return Object.keys(query).every((key) => ref[key] == query[key]);
    });
  }

  async fetchAndReplace(): Promise<void> {
    assert.ok(this.songId);
    const { references, hiddenReferenceIds } = await rpcFetchReference({ songId: this.songId });
    this._refs = references;
    this._refsToHide = hiddenReferenceIds;
    this._refsDep.changed();
    this._loaded.set(true);
  }

  async add(referenceData: SongReference): Promise<void> {
    const reference = Object.assign(referenceData, {
      songId: this.songId, // will be undefined for new songs
      userId: Meteor.userId(),
    });
    if (this.songId) {
      delete reference._id; // we want to replace the temporary one if it exists
      //@ts-expect-error Cutting corners here... need to update type
      await rpcAddReference({ reference });
      this.fetchAndReplace();
    } else {
      reference._id = Random.id();
      reference.unsynced = true;
      this._refs.push(reference);
      this._refsDep.changed();
    }
  }

  async delete(referenceId: string): Promise<void> {
    if (this.songId) {
      await rpcDeleteReference(referenceId);
      this.fetchAndReplace();
    } else {
      this._refs = this._refs.filter((ref) => ref._id != referenceId);
      this._refsDep.changed();
    }
  }

  async hide(referenceId: string): Promise<void> {
    if (!this.songId) throw new Error('Tried to hide reference for unsaved song');
    await rpcHideReference({ songId: this.songId, refId: referenceId });
    this._refsToHide.push(referenceId);
    this._refsDep.changed();
  }

  async makePublic(referenceId: string): Promise<void> {
    if (this.songId) {
      await rpcMakeReferencePublic(referenceId);
      this.fetchAndReplace();
    } else {
      const matchingReference = this._refs.find((ref) => ref._id == referenceId);
      if (matchingReference) {
        matchingReference.makePublic = true;
        matchingReference.userId = undefined;
      }
    }
  }

  /**
   * Set song ID and upload unsynced songs (only for new songs)
   */
  async uploadForNewSong(songId: string): Promise<void> {
    this.songId = songId;
    await Promise.all(
      this._refs
        .filter((ref) => ref.unsynced)
        .map(async (ref) => {
          const makePublic = ref.makePublic;
          delete ref.makePublic;
          delete ref.unsynced;
          delete ref._id;
          ref.songId = this.songId;
          try {
            const newRefId = await rpcAddReference(
              //@ts-expect-error Bad typing here, my bad... should fix eventually
              { reference: ref, makePublic }
            );
            ref._id = newRefId;
            delete ref.unsynced;
          } catch (err) {
            console.error(err);
            Sentry.captureException(err);
          }
        })
    );
  }
}
