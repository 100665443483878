import { Meteor } from 'meteor/meteor';
import _ from 'underscore';
import type { SUARecord } from '@/collections/SUACollection';
import { SUACollection } from '@/collections/SUACollection';
import { GroundedSUACompressor } from '@/library/GroundedSUACompressor';
import { UserAttributesBackend } from '@/library/UserAttributesBackend';
import localStorageDBNames from '@/local-db/localStorageDBNames';
import { callServerMethodWithoutRetry } from '@/utilities/callServerMethodWithoutRetry';

export class SongUserAttributesBackend extends UserAttributesBackend<SUARecord> {
  constructor() {
    super({
      liveCollection: SUACollection,
      idField: 'songId',
      publicationName: 'songUserAttributes',
      compressor: GroundedSUACompressor,
      storageDBName: localStorageDBNames.sua,
      updatesDBName: localStorageDBNames.suaUpdates,
    });
  }

  protected async doServerUpdate(record: SUARecord): Promise<void> {
    return void (await callServerMethodWithoutRetry('songUserAttributes.set', {
      userId: Meteor.userId(),
      songId: record.songId,
      attributes: _.omit(record, '_id', 'songId', 'updatedAt'),
      updatedAt: record.updatedAt,
    }));
  }
}
