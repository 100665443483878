<script lang="ts">
  import autosize from 'autosize';
  import { createEventDispatcher, tick } from 'svelte';
  import { autosizingTextarea } from '@/ui/autosizingTextarea';

  interface Props {
    value: '';
    placeholder: '';
    textareaClasses: '';
    displayClasses: '';
    universalClasses: '';
  }

  let { value, placeholder, textareaClasses, displayClasses, universalClasses }: Props = $props();

  let focused = $state(false);
  let textarea: HTMLTextAreaElement;

  const dispatch = createEventDispatcher<{ input: string; submit: string }>();

  function handleTextInputKeyDown(e: KeyboardEvent & { currentTarget: HTMLTextAreaElement }) {
    if (e.key == 'Enter') {
      const text = e.currentTarget.value.replace(/\r?\n/g, ' ').slice(0, 80);
      e.currentTarget.value = text;
      dispatch('submit', text);
      e.preventDefault();
    }
    if (e.key == 'Escape') {
      handleBlur();
      e.stopPropagation();
    }
  }

  function handleBlur() {
    focused = false;
  }

  function handleInput(e: Event & { currentTarget: HTMLTextAreaElement }) {
    const text = e.currentTarget.value.replace(/\r?\n/g, ' ').slice(0, 80);
    dispatch('input', text);
  }

  async function handleFocus() {
    focused = true;
    await tick();
    textarea.focus();
    autosize.update(textarea);
  }
</script>

<textarea
  use:autosizingTextarea
  {value}
  {placeholder}
  class={universalClasses + ' ' + textareaClasses}
  style="display: {focused ? 'block' : 'none'}; resize: none;"
  rows="1"
  maxlength="80"
  on:keydown={handleTextInputKeyDown}
  on:blur={handleBlur}
  on:input={handleInput}
  bind:this={textarea}
  required={true}
></textarea>

<div
  use:autosizingTextarea
  role="textbox"
  class={universalClasses + ' ' + displayClasses}
  style="cursor: text; display: {!focused ? 'block' : 'none'};"
  style:--tw-text-opacity={value ? '1' : '0.7'}
  tabindex="0"
  on:focus={handleFocus}
  aria-required="true"
>
  {value.trim() || placeholder}<i
    class="smi smi-pencil absolute inline-block pl-2 no-underline opacity-60"
    aria-hidden="true"
  />
</div>
