import * as Sentry from '@sentry/browser';
import { Meteor } from 'meteor/meteor';
import { showAudioIssueNotice } from '@/ui/showAudioIssueNotice';

let timeDilationWatcherHandle: number;
let reportFiled = false;

export class WebAudioTimeDilationWatcher {
  static start(context: AudioContext): void {
    let lastContextTime = context.currentTime;
    let lastBrowserTime = performance.now() / 1000;
    const discrepancies: number[] = [];
    if (timeDilationWatcherHandle) clearInterval(timeDilationWatcherHandle);
    reportFiled = false;
    timeDilationWatcherHandle = setInterval(() => {
      const timeElapsed = performance.now() / 1000 - lastBrowserTime;
      const contextTimeElapsed = context.currentTime - lastContextTime;
      lastContextTime = context.currentTime;
      lastBrowserTime = performance.now() / 1000;
      if (timeElapsed > 1.05) return;
      if (contextTimeElapsed < 0.8) return;
      discrepancies.unshift(timeElapsed - contextTimeElapsed);
      if (discrepancies.length > 20) discrepancies.length = 20;
      if (
        !reportFiled &&
        discrepancies.length >= 8 &&
        // Sum of last 8 discrepancies is at least 0.4 seconds (0.05s each)
        Math.abs(discrepancies.slice(0, 8).reduce((prev, cur) => prev + cur, 0)) > 0.4
      ) {
        console.error(
          'AudioContext.currentTime discrepancies: ' +
            discrepancies.map((d) => d.toFixed(3)).join(',')
        );

        const isApple =
          /iP(hone|ad|od)/i.test(navigator.userAgent) || navigator.vendor.startsWith('Apple');

        Sentry.withScope((scope) => {
          scope.setExtra('recentDiscrepancies', discrepancies.map((d) => d.toFixed(3)).join(','));
          scope.setExtra('contextTime', lastContextTime.toFixed(3));
          Sentry.captureMessage(
            `AudioContext.currentTime running at wrong rate (${isApple ? 'Apple' : 'not Apple'})`
          );
        });

        if (isApple) {
          const isMac = /^Mac/i.test(navigator.platform) && !(navigator.maxTouchPoints > 1);
          const safariOrIOs = isMac ? 'Safari' : 'iOS';
          showAudioIssueNotice({
            message:
              `Playback will cut out shortly (if it hasn't already) due to <a href="https://bugs.webkit.org/show_bug.cgi?id=232728" target="_blank">a bug</a> in ${safariOrIOs} 15. ` +
              (isMac
                ? `Apple will most likely fix the bug in a future release of Safari/macOS, but who knows when exactly. In the meantime, we recommend using another browser like Chrome or Firefox to avoid this issue. Reloading this page and/or restarting your Mac may fix it for now as well.`
                : Meteor.isCordova
                  ? `We recommend enabling "Native Audio" in the Advanced Settings (from the top-left menu of Strum Machine's main screen) which should resolve this issue.`
                  : `We recommend <a href="https://apps.apple.com/us/app/strum-machine/id1509158553">downloading the Strum Machine app</a> from the App Store, which now uses a different audio-engine built especially for iOS that isn't affected by this bug.`),
            action: isMac ? 'reload' : 'restart-help',
          });
        }
        reportFiled = true;
      }
    }, 1000) as unknown as number;
  }

  static stop(): void {
    if (timeDilationWatcherHandle) clearInterval(timeDilationWatcherHandle);
  }
}
