<script lang="ts">
  import type { Section } from '@/chart/Section.svelte';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import Icon from '@/ui/icons/Icon.svelte';
  import { SectionSwapAnimator } from '@/ui/SectionSwapAnimator';
  import { tooltip } from '@/ui/tooltip';

  interface Props {
    section: Section;
    sectionIndex: number;
  }

  let { section, sectionIndex }: Props = $props();

  let songSections: Section[] = $state([]);
  trackerEffect(() => (songSections = section.song.sections.reactive()));

  let disableMoveDown = $derived(sectionIndex === songSections.length - 1);
  let disableMoveUp = $derived(sectionIndex === 0);

  function moveSection(dir: -1 | 1) {
    SectionSwapAnimator.swap({
      selector: '.songSection',
      index: sectionIndex,
      direction: dir,
      duration: 400,
    });
    section.song.sections.move(sectionIndex, dir);
  }
</script>

<div class="flex">
  <button
    class="colors-button moveButton flex rounded-full p-1.5"
    use:tooltip
    data-tippy-content="Move section down"
    aria-label="Move section down"
    disabled={disableMoveDown}
    aria-hidden={disableMoveDown}
    onclick={() => moveSection(1)}
  >
    <Icon id="arrow-down" size={14} />
  </button>
  <button
    class="colors-button moveButton flex rounded-full p-1.5"
    use:tooltip
    data-tippy-content="Move section up"
    aria-label="Move section up"
    disabled={disableMoveUp}
    aria-hidden={disableMoveUp}
    onclick={() => moveSection(-1)}
  >
    <Icon id="arrow-up" size={14} />
  </button>
</div>

<style>
  @media (max-width: 380px) {
    .moveButton:disabled {
      display: none;
    }
  }
</style>
