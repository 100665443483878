import type { Meteor } from 'meteor/meteor';
import { permissionNames } from '@/roles/permissionNames';
import type { roleNames } from '@/roles/roleNames';

type RolePermissions = Partial<{
  [permissionName in (typeof permissionNames)[number]]:
    | boolean
    | ((doc?: Record<string, any>, user?: Meteor.User | null) => boolean);
}>;

export const standardRolePermissions: RolePermissions = {
  'songs.play': true,
  'songs.insert': true,
  'songs.updatePrivate': true,
  'songs.update': (doc, user) => {
    if (!doc) return false;
    // eslint-disable-next-line
    const songUserId = typeof doc.userId == 'function' ? doc.userId() : doc.userId;
    return songUserId === user?._id; // Users can update their own songs
  },
  'songs.remove': (doc, user) => {
    if (!doc) return false;
    // eslint-disable-next-line
    const songUserId = typeof doc.userId == 'function' ? doc.userId() : doc.userId;
    return songUserId === user?._id; // Users can delete their own songs
  },

  'medleys.insert': true,
  'medleys.update': (doc, user) => {
    if (!doc) return false;
    // eslint-disable-next-line
    const medleyUserId = typeof doc.userId == 'function' ? doc.userId() : doc.userId;
    return medleyUserId === user?._id; // Users can update their own medleys
  },
  'medleys.remove': (doc, user) => {
    if (!doc) return false;
    // eslint-disable-next-line
    const medleyUserId = typeof doc.userId == 'function' ? doc.userId() : doc.userId;
    return medleyUserId === user?._id; // Users can delete their own medleys
  },

  'lists.insert': true,
  'lists.update': (doc, user) => {
    // lists must be owned or publiclyEditable to be updated
    return !!doc && (doc.ownerId === user?._id || doc.publiclyEditable);
  },
  'lists.rename': (doc, user) => {
    return !!doc && doc.ownerId === user?._id; // can only rename own lists
  },
  'lists.remove': (doc, user) => {
    return !!doc && doc.ownerId === user?._id; // can only remove own lists
  },

  'references.insert': true,
  'references.remove': (doc, user) => {
    // users can remove their own song references
    return !!doc && doc.userId === user?._id;
  },
};

export const defaultRolePermissions: RolePermissions = {
  'songs.play': (doc) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    return !!doc && (typeof doc.free == 'function' ? doc.free() : doc.free);
  },
};

export const rolesAndPermissions: Record<(typeof roleNames)[number], RolePermissions> = {
  'admin':
    // Admins have all permissions
    Object.fromEntries(
      permissionNames.map((permissionName) => [permissionName, true])
    ) as RolePermissions,

  'lifetime-member': standardRolePermissions,
  'paid-member': standardRolePermissions,
  'trial-member': standardRolePermissions,
  'metrics': {
    'metrics.view': true,
  },
  'impersonator': {
    'impersonate': true,
  },
  'contributor': {
    'nominations.insert': true,
  },
  // These last four are used in the DB, but have no permissions attached
  // 'trial-ended': {},
  // 'canceled-member': {},
  // 'unverified': {},
  // 'verified': {},
} as const;
