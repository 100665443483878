import { Meteor } from 'meteor/meteor';
import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { Tracker } from 'meteor/tracker';
import { Conductor } from '@/Conductor';
import { Crnt } from '@/Crnt';
import { controlBarBelow } from '@/ui/controlBarBelow';

export function reactivelyAssignBodyClasses() {
  Tracker.autorun(update);
}

const isOldFirefox = /Firefox\/\d\d\./i.test(navigator.userAgent); // because Firefox 78 is having trouble with new chord font

function update() {
  const classes = [
    Meteor.isCordova ? 'cordova' : 'not-cordova',

    /Android/i.test(navigator.userAgent) ? 'android' : 'not-android',

    /iP(hone|ad|od)/i.test(navigator.userAgent) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
      ? 'ios'
      : 'not-ios',

    isOldFirefox ? 'old-firefox' : '',

    navigator.platform === 'MacIntel' && navigator.maxTouchPoints <= 1 ? 'mac' : 'not-mac',

    navigator.vendor.startsWith('Apple') ? 'safari' : 'not-safari',

    /iP(hone|od)/i.test(navigator.userAgent) ? 'iphone' : 'not-iphone',

    Meteor.userId() ? 'is-loggedIn' : 'not-loggedIn',

    Template.appContainer.activeScreen() ? 'in-app' : 'not-in-app',

    FlowRouter.getQueryParam('help') ? 'full-screen-help' : '',

    Crnt.song()?.editMode() ? 'is-editMode' : 'not-editMode',

    Conductor.ready() ? 'is-readyToStrum' : 'not-readyToStrum',

    controlBarBelow() ? 'control-bar-below' : 'control-bar-above',

    FlowRouter.getRouteName()?.startsWith('login') ? 'login-page-bg' : '',

    FlowRouter.getRouteName()?.startsWith('signup') ? 'signup-page-bg' : '',
  ].filter((className) => className);

  document.body.className = classes.join(' ');
}
