import type { Channel } from '@/audio/engine/base/Channel';
import type { PlayProps } from '@/audio/engine/base/PlayProps';
import type { SampleDefinition } from '@/audio/engine/base/Sample';
import { Sample } from '@/audio/engine/base/Sample';
import WebAudioBufferLoader from '@/audio/engine/web/WebAudioBufferLoader';
import type { WebAudioChannel } from '@/audio/engine/web/WebAudioChannel';
import { WebAudioPlayback } from '@/audio/engine/web/WebAudioPlayback';

export class WebAudioSample extends Sample {
  audioBuffer?: AudioBuffer;

  constructor(sampleDefinition: SampleDefinition) {
    super(sampleDefinition);
  }

  private _loadPromise?: Promise<void>;
  protected _load(): Promise<void> {
    return (this._loadPromise ??= WebAudioBufferLoader.getBufferForSample(this.id).then(
      (audioBuffer) => {
        this.audioBuffer = audioBuffer;
        this.loaded = true;
      }
    ));
  }

  protected _createPlayback(playProps: PlayProps, channel: Channel): WebAudioPlayback {
    return new WebAudioPlayback({
      sample: this,
      channel: channel as WebAudioChannel,
      playProps,
    });
  }

  _destroy(): void {
    this.audioBuffer = undefined;
  }
}
