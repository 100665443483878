import { assignGuitarBassRunsAndLeadingNotes } from '@/band/instruments/guitar/assignGuitarBassRunsAndLeadingNotes';
import { assignGuitarBoomStrums } from '@/band/instruments/guitar/assignGuitarBoomStrums';
import { bluegrassMeasure } from '@/band/instruments/guitar/blocks/bluegrassMeasure';
import { endingBoomChuckMeasure } from '@/band/instruments/guitar/blocks/endingBoomChuckMeasure';
import { splitCell } from '@/band/instruments/guitar/blocks/splitCell';
import { syncopatedBluegrassMeasure } from '@/band/instruments/guitar/blocks/syncopatedBluegrassMeasure';
import type { GuitarCore } from '@/band/instruments/guitar/GuitarCore';
import { syncopatedSpreadGradients } from '@/band/instruments/guitar/settings/syncopatedSpreadGradients';
import { syncopatedPatternLandscape } from '@/band/instruments/guitar/syncopatedPatternLandscape';
import { swingOptions } from '@/band/swingOptions';
import { lerp } from '@/utilities/lerp';

export const SyncopatedBluegrassGuitarCore: GuitarCore = {
  id: 'sb',
  title: 'Syncopated boom chuck strum',

  timeSignatures: ['4/4'],

  linkedSettingsKeys: ['brushiness', 'circularStrumShape'],
  otherSettingsKeys: ['bassRuns', 'syncopatedLeadingNotes', 'openVoicings', 'timing'],

  chordStyle: 'cowboy',

  swingCategory: 'normal',
  defaultSwing: swingOptions['5:4'],

  presets: [
    {
      name: 'Syncopated boom chuck',
      settings: {
        'circularStrumShape': { r: 0, t: 0 },
        'brushiness': { v: 8 / 14 },
      },
    },
    {
      name: 'Syncopated big strums',
      settings: {
        'circularStrumShape': { r: 0, t: 0 },
        'brushiness': { v: 14 / 14 },
      },
    },
  ],

  measurePreprocessing(measures, settings, flags) {
    if (!flags?.noEmbellishments) {
      assignGuitarBassRunsAndLeadingNotes({
        measures,
        settings,
        core: SyncopatedBluegrassGuitarCore,
      });
    }
    assignGuitarBoomStrums({ measures, settings });
  },

  processMeasure(measure, settings) {
    // TODO: Make this actually reflect current settings
    const dynamics = { boomPower: 0.85, chuckPower: 0.75, upstrokePower: 0.75 };

    if (measure.endOfSong) {
      return endingBoomChuckMeasure(measure, { ...dynamics });
    }

    const [firstCell, secondCell] = measure.cells;

    if (
      typeof secondCell !== 'undefined' &&
      measure.onlyOneChord &&
      !measure.cells.some(
        (cell) =>
          cell.effect == 'diamond' || cell.effect == 'stop' || cell.plans.guitar?.leadingNote
      )
    ) {
      return syncopatedBluegrassMeasure([firstCell, secondCell], settings);
    }

    if (
      !measure.cells.some((cell) => cell.effect == 'diamond' || cell.effect == 'stop' || cell.split)
    ) {
      return bluegrassMeasure([firstCell, secondCell], settings);
    }

    return [
      ...(firstCell.split
        ? splitCell(firstCell, dynamics)
        : bluegrassMeasure([firstCell], settings)),
      ...(!secondCell
        ? []
        : secondCell.split
          ? splitCell(secondCell, dynamics)
          : bluegrassMeasure([secondCell], settings)),
    ];
  },

  generateViz(settings) {
    const beatPowers = settings.circularStrumShape.powersForLandscape(syncopatedPatternLandscape);

    const beatBiasies = [0, 0.6, 1, 1, 0.2, 0.6, 1, 1] as EightNumbers;

    const beatThicknesses = beatPowers.map((power, i) =>
      lerp(0, i % 2 == 0 || i == 3 ? 1 : 0.7, power)
    ) as EightNumbers;

    const beatOpacities = beatPowers.map((power, i) => {
      const direction = i % 2 == 0 ? 'D' : 'U';
      const threshold = 0.05;
      const multiple: number = direction == 'U' ? 1 : 2;
      return power < threshold ? 0 : Math.min(1, threshold + power * multiple);
    }) as EightNumbers;

    const beatTails = settings.brushiness
      .spreadsForGradients(syncopatedSpreadGradients)
      .map((input, i) => {
        const spread = lerp(0.5 * input, input, beatPowers[i] ?? NaN);
        if (i % 4 == 2 || i == 3) {
          const previousPower = beatPowers[i - 1] as number;
          const nextPower = beatPowers[i + 1] as number;
          return Math.max(2.2 - (previousPower + nextPower), spread);
        }
        return spread;
      })
      .map((adjustedSpread) => Math.min(1, (adjustedSpread - 1) / 4)) as EightNumbers;

    const beatTailOpacities = beatTails.map((tail, i) =>
      tail < 0.2 ? tail * 5 : undefined
    ) as EightNumbers;

    return ([0, 1, 2, 3, 4, 5, 6, 7] as const).map((i) => ({
      dir: i % 2 == 0 ? 'd' : 'u',
      biasY: beatBiasies[i],
      thickness: beatThicknesses[i],
      opacity: beatOpacities[i],
      tail: beatTails[i],
      tailOpacity: beatTailOpacities[i],
    }));
  },
};
