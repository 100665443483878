import type { MandolinSettingsHash } from '@/band/instruments/mandolin/MandolinSettingsHash';
import { standardChopPatternLandscape } from '@/band/instruments/mandolin/standardChopPatternLandscape';
import type { LinearizedCell } from '@/chart/LinearizedCell';
import type { LinearizedMeasure } from '@/chart/LinearizedMeasure';
import { bounded } from '@/utilities/bounded';
import { shuffleArray } from '@/utilities/shuffleArray';

export function assignMandolinRhythmAccents({
  measures,
  settings,
}: {
  measures: readonly LinearizedMeasure[];
  settings: MandolinSettingsHash;
}) {
  void settings;

  const [
    isolatedDownstrokeRatio,
    isolatedSecondDownstrokeRatio,
    upstrokeRatio,
    secondUpstrokeRatio,
    downstrokePower,
    upstrokePower,
    preUpstrokeDownPower,
  ] = settings.chopAccents.powersForLandscape(standardChopPatternLandscape);

  const allCells = measures.flatMap((m) => m.cells);

  const upstrokeFillCells = shuffleArray(
    allCells.filter((c) => c.layout.barEnd && !c.next?.layout.barEnd)
  ).sort((a, b) => {
    if (a.layout.lineEnd !== b.layout.lineEnd) return a.layout.lineEnd ? -1 : 1;
    if (a.next?.beats[0].chordChanged !== b.next?.beats[0].chordChanged)
      return a.next?.beats[0].chordChanged ? -1 : 1;
    return 0;
  });
  upstrokeFillCells.length = Math.floor(upstrokeFillCells.length * bounded(upstrokeRatio, 0, 1));

  upstrokeFillCells.forEach((c) => {
    (c.plans.mandolin ??= {}).upstrokeFill = upstrokePower;
    if (Math.random() < secondUpstrokeRatio) {
      (c.plans.mandolin ??= {}).secondUpstroke = upstrokePower;
    }
    if (Math.random() < secondUpstrokeRatio && c.prev) {
      const rand = Math.random();
      (c.plans.mandolin ??= {}).downstroke = downstrokePower * rand;
      (c.prev.plans.mandolin ??= {}).secondUpstroke = upstrokePower * rand;
    }
    if (c.beats[0].chordChanged) {
      (c.plans.mandolin ??= {}).downstroke = downstrokePower;
    }
    if (c.next) (c.next.plans.mandolin ??= {}).downstroke ??= downstrokePower;
  });

  const isolatedDownstrokeCells = shuffleArray(
    allCells.filter((c) => c.beats[0].chordChanged || c.layout.barStart)
  );
  isolatedDownstrokeCells.sort((a, b) => {
    if ((a.layout.cellIndex === 0) !== (b.layout.cellIndex === 0))
      return a.layout.cellIndex === 0 ? -1 : 1;
    if (a.beats[0].chordChanged !== b.beats[0].chordChanged)
      return a.beats[0].chordChanged ? -1 : 1;
    if (a.layout.lineStart !== b.layout.lineStart) return a.layout.lineStart ? -1 : 1;
    if (a.layout.barStart !== b.layout.barStart) return a.layout.barStart ? -1 : 1;
    return 0;
  });

  isolatedDownstrokeCells.length = Math.floor(
    isolatedDownstrokeCells.length * bounded(isolatedDownstrokeRatio, 0, 1)
  );

  isolatedDownstrokeCells.forEach((c) => {
    const prominent = c.beats[0].chordChanged || c.layout.cellIndex === 0;
    (c.plans.mandolin ??= {}).downstroke =
      downstrokePower * (prominent ? 1 : 0.8 + Math.random() * 0.2);
  });

  const isolatedSecondDownstrokeCells = shuffleArray(
    isolatedDownstrokeCells
      .map((c) => c.next)
      .filter((c) => c && c.layout.barEnd && !c.layout.barStart)
  ) as LinearizedCell[];
  isolatedSecondDownstrokeCells.sort((a, b) => {
    if (a.beats[0].chordChanged !== b.beats[0].chordChanged)
      return a.beats[0].chordChanged ? -1 : 1;
    return 0;
  });
  isolatedSecondDownstrokeCells.length = Math.floor(
    isolatedSecondDownstrokeCells.length * bounded(isolatedSecondDownstrokeRatio, 0, 1)
  );

  isolatedSecondDownstrokeCells.forEach((c) => {
    const prominent = c.beats[0].chordChanged || c.layout.cellIndex === 0;
    (c.plans.mandolin ??= {}).downstroke =
      downstrokePower * (prominent ? 1 : 0.7 + Math.random() * 0.3);
  });
}
