import { differenceInDays, differenceInMinutes } from 'date-fns';
import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { mainIndexSearcher } from '@/library/mainIndexSearcher';
import { Roles } from '@/Roles';
import { rpcRestartFreeTrial } from '@/server/methods/accounts/rpcRestartFreeTrial';
import { UserProfile } from '@/user/UserProfile';
import wait from '@/utilities/wait';

function trialExtensionLength(user) {
  const daysSinceLastTrial = differenceInDays(new Date(), user?.subscription?.trialEnd);
  if (daysSinceLastTrial >= 60) return 14;
  if (daysSinceLastTrial >= 30) return 7;
  return 0;
}

Template.indexPageNotices.events({
  'click .js-close': function (event) {
    var $notice = $(event.currentTarget).closest('[data-notice-id]');
    if ($notice.length > 0) {
      UserProfile.hideMessage($notice.attr('data-notice-id'));
    } else {
      $notice = $(event.currentTarget).closest('div');
      setTimeout(() => FlowRouter.setQueryParams({ subscribed: null, rfl: null }), 500); // just in case subscribed=1
    }
    $notice.find('.js-close').blur();
    $notice.slideUp();
  },

  'click .js-restartTrial': async function (event) {
    const dialog = bootbox.dialog({
      message: '<p class="text-center text-lg">Please wait while we restart your free trial...</p>',
      closeButton: false,
    });
    await wait(2000);
    try {
      await rpcRestartFreeTrial({ lengthInDays: trialExtensionLength(Meteor.user()) });
      dialog.modal('hide');
      bootbox.alert({
        message: 'OK, your free trial has been extended. Have fun!',
      });
    } catch (err) {
      dialog.modal('hide');
      Bert.alert(err, 'danger');
    }
  },
});

Template.indexPageNotices.helpers({
  showNotice(notice) {
    Template.currentData().updateDep.depend();
    if (!Meteor.user('milestones')) return; // in case data isn't loaded yet
    if (UserProfile.notMilestone('PLAY')) {
      return notice == 'welcome' && !mainIndexSearcher.queryPresent();
    }
    const eligibleForTrialRestart =
      Meteor.user('subscription')?.subscription?.status == 'trial_expired' &&
      trialExtensionLength(Meteor.user('subscription')) > 0;
    if (eligibleForTrialRestart) {
      return notice == 'restart-trial';
    }
    if (FlowRouter.getQueryParam('subscribed') == '1') {
      return notice == 'subscribed';
    }
    return Tracker.nonreactive(() => {
      if (notice == 'android-app') {
        return (
          !UserProfile.isMessageHidden('android-app') &&
          !Meteor.isCordova &&
          Roles.currentUserHasFullAccess() &&
          UserProfile.hasMilestone('BPM_ADJUST') &&
          /Android/i.test(navigator.userAgent)
        );
      }
      if (notice == 'desktop-app') {
        return (
          !UserProfile.isMessageHidden('desktop-app') &&
          UserProfile.hasMilestone('BPM_ADJUST') &&
          Roles.currentUserHasFullAccess() &&
          !window.todesktop &&
          !/Android|iP(hone|ad|od)/i.test(navigator.userAgent) &&
          !window.matchMedia('(display-mode: standalone)').matches
        );
      }
      if (notice == 'sp') {
        return (
          !UserProfile.isMessageHidden('sp') &&
          Session.get('loginCode.lastUse') &&
          Meteor.user().createdAt &&
          Roles.currentUserHasFullAccess() &&
          differenceInDays(Session.get('loginCode.lastUse'), Meteor.user().createdAt) > 1 &&
          differenceInMinutes(new Date(), Session.get('loginCode.lastUse')) < 15
        );
      }
      return notice == 'announcements';
    });
  },

  trialEndDate() {
    return Meteor.user('subscription')?.subscription?.trialEnd;
  },

  trialExtensionLength() {
    const days = trialExtensionLength(Meteor.user('subscription'));
    if (days == 7) return 'week';
    if (days == 14) return 'two weeks';
    return `${days} days`;
  },
});
