import AudioManager from '@/audio/engine/AudioManager';
import TimerGroup from '@/lib/TimerGroup';

export class PlayerStateDelayedUpdater {
  private timerGroup = new TimerGroup();
  private states = new Set<PlayerStateInfo>();

  addStateUpdate({
    at,
    state,
    callback,
  }: {
    at: number;
    state: PlayerStateInfo;
    callback?: (state: PlayerStateInfo) => void;
  }): void {
    const delay = (at - AudioManager.currentTime() + AudioManager.latency) * 1000;

    this.states.add(state);

    this.timerGroup.schedule(() => {
      this.states.delete(state);
      if (callback) callback(state);
    }, delay);
  }

  addCallback({ at, callback }: { at: number; callback: () => void }): void {
    const delay = (at - AudioManager.currentTime() + AudioManager.latency) * 1000;

    this.timerGroup.schedule(() => {
      callback();
    }, delay);
  }

  /**
   * Updates the stored round value for all queued positions.
   */
  updateRound({ from, to }: { from: number; to: number }): void {
    this.states.forEach((state) => {
      if (state.position?.round === from) state.position.round = to;
      if (state.startingPosition?.round === from) state.startingPosition.round = to;
    });
  }

  /**
   * Updates value for all queued positions.
   */
  updateFootOut(value?: boolean): void {
    this.states.forEach((state) => {
      state.footOut = value;
    });
  }

  clear(): void {
    this.timerGroup.clear();
    this.states.clear();
  }
}
