import { strict as assert } from 'assert';
import EventEmitter from 'eventemitter3';
import { ReactiveVar } from 'meteor/reactive-var';
import { Tracker } from 'meteor/tracker';
import { formMeasureChains } from '@/band/formMeasureChains';
import type { InstrumentCore } from '@/band/instruments/InstrumentCore';
import type { InstrumentPresetSettings } from '@/band/instruments/InstrumentPresetSettings';
import type { InstrumentSetting } from '@/band/instruments/InstrumentSetting';
import type { InstrumentSettingDefinitions } from '@/band/instruments/InstrumentSettingDefinitions';
import { swingOptions } from '@/band/swingOptions';
import { eventTracker } from '@/browser/analytics/eventTracker';
import type { LinearizedMeasure } from '@/chart/LinearizedMeasure';
import UserPreferences from '@/user/UserPreferences';
import { bounded } from '@/utilities/bounded';

/**
 * Main control center for an instrument.
 * Persistent. Reactive getters and setters.
 */
export abstract class Instrument<
  SettingsHash extends Record<string, InstrumentSetting<unknown>>,
  Core extends InstrumentCore<SettingsHash>,
> extends EventEmitter {
  abstract readonly id: InstrumentId;
  abstract readonly name: string;
  abstract readonly shortCode: 'g' | 'm' | 'b' | 'f';

  abstract readonly cores: Readonly<Record<string, Core>>;
  abstract readonly fallbackCores: Record<TimeSignature, Core>;

  abstract readonly settingDefinitions: InstrumentSettingDefinitions;
  protected abstract _settings: SettingsHash;
  protected _settingsDep = new Tracker.Dependency();
  protected _customizedSettings = new Map<keyof SettingsHash, boolean>();

  //#region === Enabled, Volume, Pan, and Mute ===

  protected _enabled = new ReactiveVar<boolean | undefined>(undefined);
  enabled(): boolean | undefined {
    return this._enabled.get();
  }
  setEnabled(value: boolean) {
    this._enabled.set(value);
    this.emit('updatedData', { [`${this.shortCode}_e`]: value });
    eventTracker.bandInstrumentToggle({ instrument: this.id, enabled: value });
  }

  protected _customVolume = new ReactiveVar<number | undefined>(undefined);
  customVolume(): number | undefined {
    return this._customVolume.get();
  }
  setCustomVolume(value: number | undefined) {
    this._customVolume.set(typeof value == 'undefined' ? undefined : bounded(value, 0, 1));
    this.emit('updatedData', { [`${this.shortCode}_v`]: value });
    eventTracker.bandCustomVolumeSet({ instrument: this.id, volume: value });
  }

  protected _customPan = new ReactiveVar<number | undefined>(undefined);
  customPan(): number | undefined {
    return this._customPan.get();
  }
  setCustomPan(value: number | undefined) {
    this._customPan.set(typeof value == 'undefined' ? undefined : bounded(value, -1, 1));
    this.emit('updatedData', { [`${this.shortCode}_p`]: value });
    eventTracker.bandCustomPanSet({ instrument: this.id, pan: value });
  }

  globalVolume(): number {
    return Math.max(UserPreferences.get(`${this.id}Volume`), 0.001);
  }
  setGlobalVolume(value: number) {
    UserPreferences.set(`${this.id}Volume`, value);
    eventTracker.bandGlobalVolumeSet({ volume: value });
  }

  globalPan(): number {
    return UserPreferences.get(`${this.id}Pan`);
  }
  setGlobalPan(value: number) {
    UserPreferences.set(`${this.id}Pan`, value);
    eventTracker.bandGlobalPanSet({ pan: value });
  }

  globalMute(): boolean {
    return UserPreferences.get(`${this.id}Muted`);
  }
  setGlobalMute(value: boolean) {
    UserPreferences.set(`${this.id}Muted`, value);
    eventTracker.bandGlobalMuteSet({ mute: value });
  }

  volume(): number {
    return this.customVolume() ?? this.globalVolume();
  }

  pan(): number {
    return this.customPan() ?? this.globalPan();
  }

  //#endregion === Enabled, Volume, Pan, and Mute ===

  protected _swing = new ReactiveVar<SwingOption | undefined>(undefined);
  swing(): SwingOption {
    return this._swing.get() ?? swingOptions['5:4'];
  }
  setSwing(value: SwingOption) {
    this._swing.set(value);
    this.emit('updatedData', { 'sw': value.id });
    eventTracker.bandSwingSet({ swing: value.id });
  }

  protected _noEmbellishments = new ReactiveVar<boolean>(false);
  noEmbellishments(): boolean {
    return this._noEmbellishments.get();
  }
  toggleEmbellishments(value: boolean) {
    this._noEmbellishments.set(!value);
  }

  protected _core = new ReactiveVar<Core | undefined>(undefined);
  core() {
    return this._core.get();
  }

  coreOrFallbackForTimeSignature(timeSignature: TimeSignature): Core {
    const core = this.core();
    return core?.timeSignatures.includes(timeSignature)
      ? core
      : ((core?.equivilantCoreFor(timeSignature) as Core) ?? this.fallbackCores[timeSignature]);
  }

  relevantSettingsKeys(timeSignature?: TimeSignature): (keyof SettingsHash)[] {
    const core = timeSignature ? this.coreOrFallbackForTimeSignature(timeSignature) : this.core();
    if (!core) return [];
    return [...core.linkedSettingsKeys, ...core.otherSettingsKeys] as (keyof SettingsHash)[];
  }

  // abstract swingCategory(): SwingCategory;

  settings(): SettingsHash {
    this._settingsDep.depend();
    return this._settings;
  }

  setSetting<K extends keyof typeof this.settingDefinitions>(key: K, setting: SettingsHash[K]) {
    const serializationKey = this.settingDefinitions[key]?.serializationKey;
    assert(serializationKey, `Called ${this.name}.setSetting with invalid key "${key}"`);
    const serialized = setting.serialize();
    if (JSON.stringify(serialized) == JSON.stringify(this._settings[key]?.serialize())) return;
    this.emit('updatedData', { [serializationKey]: serialized });
    eventTracker.bandSettingSet({ instrument: this.id, key, serialized });
  }

  abstract loadCoreAndPreset(
    core: Core,
    presetSettings: InstrumentPresetSettings<SettingsHash>
  ): void;

  protected serializePresetSettings(presetSettings: InstrumentPresetSettings<SettingsHash>) {
    return Object.keys(presetSettings).reduce<Record<string, unknown>>((acc, key) => {
      const serializationKey = this.settingDefinitions[key]?.serializationKey;
      if (serializationKey) acc[serializationKey] = presetSettings[key];
      return acc;
    }, {});
  }

  protected irrelevantSettingsResetHash(core: Core): Record<string, unknown> {
    const relevantSettings = [...core.linkedSettingsKeys, ...core.otherSettingsKeys];
    return Object.keys(this.settingDefinitions).reduce<Record<string, unknown>>((acc, key) => {
      const serializationKey = this.settingDefinitions[key]?.serializationKey;
      if (serializationKey && !relevantSettings.includes(key)) acc[serializationKey] = null;
      return acc;
    }, {});
  }

  /**
   * Currently, this function is used only by the style panel to "undo" instrument preset selection.
   */
  setSavedData(settings: Record<string, unknown>) {
    this.emit('updatedData', {
      [`${this.shortCode}_c`]: settings[`${this.shortCode}_c`],
      ...Object.fromEntries(
        Object.values(this.settingDefinitions).map((def) => [
          def.serializationKey,
          settings[def.serializationKey],
        ])
      ),
      'sw': settings['sw'],
    });
  }

  loadSerializedData(data: Record<string, unknown>): void {
    this.loadTheBasics(data);
    this.loadCoreFromSerialized(data);
    this.loadSettingsFromSerialized(data);
    this._settingsDep.changed();
  }

  protected loadTheBasics(data: Record<string, unknown>) {
    this._enabled.set(data[`${this.shortCode}_e`] as boolean | undefined);
    this._customVolume.set(data[`${this.shortCode}_v`] as number | undefined);
    this._customPan.set(data[`${this.shortCode}_p`] as number | undefined);
    this._swing.set(
      (data['sw'] as string) in swingOptions
        ? swingOptions[data['sw'] as keyof typeof swingOptions]
        : undefined
    );
  }

  protected loadCoreFromSerialized(data: Record<string, unknown>) {
    const coreId = (data[`${this.shortCode}_c`] as string) ?? '';
    const core = this.cores[coreId];
    this._core.set(core);
  }

  protected loadSettingsFromSerialized(data: Record<string, unknown>) {
    Object.keys(this.settingDefinitions).forEach((key) => {
      const def = this.settingDefinitions[key];
      assert(def);
      const { serializationKey, class: SettingsClass } = def;
      const setting = new SettingsClass(data[serializationKey]);
      (this._settings as Record<string, InstrumentSetting<unknown>>)[key] = setting;
    });
  }

  serialize(): Record<`${typeof this.shortCode}_${string}`, unknown> {
    return {
      ...this.serializeTheBasics(),
      ...this.serializeCoreAndSettings(),
    };
  }

  protected serializeTheBasics(): Record<`${typeof this.shortCode}_${string}`, unknown> {
    return this._enabled.get()
      ? {
          [`${this.shortCode}_e` as const]: this._enabled.get(),
          [`${this.shortCode}_v` as const]: this._customVolume.get(),
          [`${this.shortCode}_p` as const]: this._customPan.get(),
        }
      : { [`${this.shortCode}_e` as const]: this._enabled.get() };
    // swing is not serialized unless explicitly changed at the band level
  }

  protected serializeCoreAndSettings(): Record<`${typeof this.shortCode}_${string}`, unknown> {
    const core = this.core();
    const relevantSettings = core
      ? [...core.linkedSettingsKeys, ...core.otherSettingsKeys]
      : Object.keys(this.settingDefinitions);

    const serializedSettings = Object.keys(this.settingDefinitions).reduce<Record<string, unknown>>(
      (acc, key) => {
        const serializationKey = this.settingDefinitions[key]?.serializationKey;
        if (serializationKey) {
          acc[serializationKey] = relevantSettings.includes(key)
            ? this._settings[key]?.serialize()
            : null;
        }
        return acc;
      },
      {}
    );
    return {
      ...(core ? { [`${this.shortCode}_c`]: core.id } : {}),
      ...serializedSettings,
    };
  }

  generatePlans(measures: readonly LinearizedMeasure[], { tpm }: { tpm: number }) {
    const chains = formMeasureChains(measures, (lastMeasure, measure) => {
      return measure.timeSignature == lastMeasure.timeSignature;
    });

    if (!this.enabled()) return;
    for (const chain of chains) {
      const core = this.coreOrFallbackForTimeSignature(chain.measures[0].timeSignature);
      this.processMeasures(chain.measures, core, this.settings(), { swing: this.swing(), tpm });
    }
  }

  /**
   * This is where the high-level planning of the guitar part happens.
   * It should not be re-run when minor changes are made to the
   * song preferences (like BPM)... but currently, it is.
   */
  protected abstract processMeasures(
    measures: readonly LinearizedMeasure[],
    core: Core,
    settings: Readonly<SettingsHash>,
    context: Readonly<{ swing: SwingOption; tpm: number }>
  ): void;
}
