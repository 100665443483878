import { Crnt } from '@/Crnt';
import closeNearestTippy from '@/ui/dropdowns/closeNearestTippy';
import { isTouchDevice } from '@/ui/isTouchDevice';
import { UserProfile } from '@/user/UserProfile';

Template.fxMenu.onCreated(() => {
  const instance = Template.instance();
  instance.currentEffect = new ReactiveVar();
  instance.autorun(() => {
    Crnt.song()?.sections.reactive(); // reactive binding
    instance.currentEffect.set(
      Crnt.song() && (Crnt.song()?.withFocused().getMeasureProperty('effect') || null)
    );
  });
});

Template.fxMenu.onRendered(() => {
  const instance = Template.instance();
  setTimeout(() => {
    instance.$('.fxMenu').focus();
  }, 10);
  UserProfile.recordMilestone('FX_MENU_OPEN');
});

Template.fxMenu.events({
  'click .js-selectionButtons button': function (event, instance) {
    const effect = $(event.currentTarget).data('effect') || null;
    Crnt.song()?.withFocused().setEffect(effect);
  },
  'keypress .btn, keypress': function (event, instance) {
    if (event.key == 'Escape') {
      closeNearestTippy(event.currentTarget);
    }
  },
});

Template.fxMenu.helpers({
  attrsForEffect(effect) {
    return {
      'data-effect': effect,
      'aria-pressed': Template.instance().currentEffect.get() == (effect || null),
    };
  },
  currentEffectIs(effect) {
    return Template.instance().currentEffect.get() == (effect || null);
  },
  touchMode() {
    return isTouchDevice();
  },
});
