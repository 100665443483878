import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import ResizeObserver from 'resize-observer-polyfill';
import _ from 'underscore';
import type { Song } from '@/chart/Song';
import { bounded } from '@/utilities/bounded';

const elements = new Set<HTMLElement>();
let containerEl: Element | undefined;

function doAutoFit() {
  if (!/-(song|medley)/.test(FlowRouter.getRouteName())) return;
  elements.forEach((node) => {
    node.style.fontSize = '';
    node.dataset.squeeze = '';
  });

  let widthConstraint = 999;
  for (const node of elements) {
    if (!node.parentElement) continue;
    // only checking first valid element; assuming all cells are same width
    widthConstraint = Math.ceil(innerWidth(node.parentElement));
    break;
  }

  const overflowRatios = new Map<HTMLElement, number>();
  for (const node of elements) {
    const ratio = node.offsetWidth / widthConstraint;
    overflowRatios.set(node, ratio);
  }

  const minSqueeze = Math.floor(
    bounded(
      [...overflowRatios.values()].reduce((acc, val) => acc + (val > 1 ? val - 0.9 : 0), 0) / 2,
      bounded(2 - (window.innerWidth - 300) / 100, 0, 2),
      6
    )
  );

  elements.forEach((node) => {
    const overflowRatio = overflowRatios.get(node) ?? 1;
    const squeezeAmount = Math.max(
      minSqueeze,
      overflowRatio > 1 ? bounded(Math.ceil((overflowRatio - 1) * 16), 1, 6) : 0
    );
    node.dataset.squeeze = squeezeAmount.toFixed(0);
    if (squeezeAmount > 0) {
      const ratio = node.offsetWidth / widthConstraint;
      overflowRatios.set(node, ratio);
    }
  });

  elements.forEach((node) => {
    const overflowRatio = overflowRatios.get(node) ?? 1;
    const relativeSize =
      (node.parentElement?.classList.contains('split') ? 0.75 : 1) / overflowRatio;
    node.style.fontSize = relativeSize < 1 ? `${relativeSize}em` : '';
  });
}

const throttledAutoFit = _.debounce(doAutoFit, 200);
const debouncedAutoFit = _.debounce(doAutoFit, 10);

const textFitResizeObserver = new ResizeObserver(() => requestAnimationFrame(throttledAutoFit));

export default function autoFitCellContents(node: HTMLElement, { song }: { song?: Song }) {
  const currentContainerEl = document.querySelector('.js-global-musicPageMainArea') || undefined;
  if (currentContainerEl !== containerEl) {
    textFitResizeObserver.disconnect();
    containerEl = currentContainerEl;
    if (containerEl) textFitResizeObserver.observe(containerEl);
  }

  elements.add(node);

  const getMaxLineLength = () => song?.cpl() ?? 4;
  let lastLineLength = getMaxLineLength();
  let lastContents: string;

  requestAnimationFrame(debouncedAutoFit);

  return {
    update() {
      if (lastContents == node.innerHTML && lastLineLength == getMaxLineLength()) return;
      lastContents = node.innerHTML;
      lastLineLength = getMaxLineLength();
      requestAnimationFrame(debouncedAutoFit);
    },
    destroy() {
      elements.delete(node);
    },
  };
}

// Calculate width without padding.
function innerWidth(el: HTMLElement) {
  const style = window.getComputedStyle(el, null);
  return (
    parseFloat(style.getPropertyValue('width')) -
    parseFloat(style.getPropertyValue('padding-left')) -
    parseFloat(style.getPropertyValue('padding-right'))
  );
}
