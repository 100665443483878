import type {
  GuitarIntentChick,
  GuitarIntentChop,
  GuitarIntentCrosspick,
  GuitarIntentMutedStrum,
  GuitarIntentPick,
  GuitarIntentPickExplicit,
  GuitarIntentStrum,
} from '@/band/instruments/guitar/GuitarIntent';

export function bassNote(
  note: 'root' | 'fifth',
  options: {
    db: number;
  }
) {
  return <GuitarIntentPick>{ action: 'pick', note, ...options };
}

export function pickSpecified(
  { string, fret }: { string: number; fret: number },
  options: {
    db: number;
  }
) {
  return <GuitarIntentPickExplicit>{ action: 'pick', string, fret, ...options };
}

export function strumDown(
  bias: GuitarIntentStrum['bias'],
  options: {
    spread: number;
    db: number;
    sixth?: boolean;
    omitAboveTpm?: number;
  }
): GuitarIntentStrum {
  return { action: 'strum', direction: 'D', bias, ...options };
}

export function strumUp(
  bias: GuitarIntentStrum['bias'],
  options: {
    spread: number;
    db: number;
    sixth?: boolean;
    omitAboveTpm?: number;
  }
): GuitarIntentStrum {
  return { action: 'strum', direction: 'U', bias, ...options };
}

export function mutedStrum(
  bias: GuitarIntentMutedStrum['bias'],
  options: {
    spread: number;
    db: number;
    sixth?: boolean;
    omitAboveTpm?: number;
  }
): GuitarIntentMutedStrum {
  return { action: 'muted-strum', bias, ...options };
}

export function chop(
  duration: number,
  bias: GuitarIntentChop['bias'],
  options: {
    spread: number;
    db: number;
  }
): GuitarIntentChop {
  return { action: 'chop', duration, bias, ...options };
}

export function chick(
  bias: GuitarIntentChop['bias'],
  options: {
    spread: number;
    db: number;
  }
): GuitarIntentChick {
  return { action: 'chick', bias, ...options };
}

export function crosspick(options: {
  series: 'std' | 'waltz';
  noteInSeries: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  db: number;
}): GuitarIntentCrosspick {
  return { action: 'crosspick', ...options };
}
