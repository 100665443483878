<script lang="ts">
  import { MasterPitch } from '@/audio/mixer/MasterPitch';
  import { MasterVolume } from '@/audio/mixer/MasterVolume';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import SongMenuManager from '@/ui/SongMenuManager';

  let masterVolume: number = $state(1);
  trackerEffect(() => (masterVolume = MasterVolume.get()));

  let masterPitch: number = $state(440);
  trackerEffect(() => (masterPitch = MasterPitch.get()));

  function openMixerToOutputPitch() {
    Modal.hide();
    SongMenuManager.toggleMenu('mixerMenu');
    setTimeout(
      () => (document.querySelector('.js-global-outputPitchButton') as HTMLButtonElement)?.click(),
      300
    );
  }

  function openMixerToOutputVolume() {
    Modal.hide();
    SongMenuManager.toggleMenu('mixerMenu');
    setTimeout(
      () => (document.querySelector('.js-global-outputVolumeButton') as HTMLButtonElement)?.click(),
      300
    );
  }
</script>

<div class="px-5 pb-3" class:hidden={!(masterVolume < 1 || masterPitch != 440)}>
  {#if masterVolume < 1}
    <button class="btn btn-info" onclick={openMixerToOutputVolume}>
      Output volume reduced to {(masterVolume * 100).toFixed(0)}%
    </button>
  {/if}
  {#if masterPitch != 440}
    <button class="btn btn-info" onclick={openMixerToOutputPitch}>
      Tuning adjusted to A = {masterPitch} Hz
    </button>
  {/if}
</div>

<style>
  .btn {
    display: block;
    width: 100%;
    padding: 3px 9px;
    border-radius: 6px;
    font-weight: normal;
    font-size: 14px;
  }
</style>
