import tippyJs from 'tippy.js';
import { Crnt } from '@/Crnt';
import { HearAsYouEdit } from '@/editor/HearAsYouEdit';
import { Chord } from '@/music/Chord';
import { majorScaleIntervals } from '@/music/music-knowledge';
import UserPreferences from '@/user/UserPreferences';

function keyDownHandler(event) {
  if (event.key == 'Tab') return;
  if (event.key == 'Shift') return;
  if (event.key.startsWith('Arrow')) {
    clientModules.slashChordPicker.hide();
    return;
  }
  if (event.metaKey || event.ctrlKey || event.altKey) return;
  const song = Crnt.song();
  if (!song) return;
  event.stopPropagation();
  event.preventDefault();
  const chordSystem = UserPreferences.get('chordSystem');
  if (chordSystem == 'letters') {
    if (event.key == '#') {
      song.withFocused().toggleBassNoteSharp();
      HearAsYouEdit.trigger();
      return;
    }
    if (event.key == 'b') {
      const currentBassNote = song.focus.selectedChords()[0]?.bassNote;
      if (currentBassNote && !currentBassNote.includes('b')) {
        song.withFocused().toggleBassNoteFlat();
        HearAsYouEdit.trigger();
        return;
      }
    }
    if (/^[a-g]$/i.test(event.key)) {
      song.withFocused().setBassNote(event.key.toUpperCase());
      HearAsYouEdit.trigger();
      return;
    }
  } else {
    const keyString = song?.key();
    if (!song || !keyString) return;
    if (event.key == '#') {
      song.withFocused().shiftBassNoteSemitones(1, keyString);
      HearAsYouEdit.trigger();
      return;
    }
    if (event.key == 'b') {
      song.withFocused().shiftBassNoteSemitones(-1, keyString);
      HearAsYouEdit.trigger();
      return;
    }
    if (['1', '2', '3', '4', '5', '6', '7'].includes(event.key)) {
      const keyChord = new Chord(keyString, keyString);
      const interval = majorScaleIntervals[+event.key - 1] ?? 0;
      song.withFocused().setBassNote(keyChord.shiftRoot(interval).rootNote);
      HearAsYouEdit.trigger();
      return;
    }
    if (/^[a-g]$/i.test(event.key)) {
      song.withFocused().setBassNote(event.key.toUpperCase());
      HearAsYouEdit.trigger();
      return;
    }
  }
  if (event.key == ' ') {
    song.withFocused().duplicate();
  }
  clientModules.slashChordPicker.hide();
}

function blurHandler() {
  clientModules.slashChordPicker.hide();
}

const tippyDefaults = {
  animation: 'scale-subtle',
  appendTo: () => document.getElementsByClassName('js-global-songScrollContainer')[0],
  duration: [300, 150],
  hideOnClick: false,
  interactive: true,
  offset: [0, 0],
  placement: 'bottom',
  role: '',
  showOnCreate: true,
  theme: 'light',
  trigger: 'manual',

  onShow: (instance) =>
    requestAnimationFrame(() => {
      instance.popper.querySelector('.js-focusTarget').focus();
      instance.popper.querySelector('.js-focusTarget').addEventListener('keydown', keyDownHandler);
      instance.popper.querySelector('.js-focusTarget').addEventListener('blur', blurHandler);
    }),

  onHide: (instance) => {
    instance.popper.querySelector('.js-focusTarget').removeEventListener('keydown', keyDownHandler);
    instance.popper.querySelector('.js-focusTarget').removeEventListener('blur', blurHandler);
  },

  onHidden: (instance) => instance.destroy(),
};

Template.slashChordPopup.onRendered(() => {
  const instance = Template.instance();

  instance.contentRef = instance.find('.slashChordPopup');
  instance.contentRef.parentNode.removeChild(instance.contentRef);

  instance.autorun(function updateTippyAutorun() {
    const song = Crnt.song();
    if (!song) return;
    const selectionInProgress = song.dragSelection.exists();
    const pickerVisible = clientModules.slashChordPicker.visible();
    Tracker.afterFlush(() =>
      Meteor.defer(() => {
        // wait for DOM to be updated first!
        instance.tippy?.hide();
        instance.tippy = null;
        const endCell = song.focus.endCell();
        const targetEl = endCell?.layout.chartCell;
        if (targetEl && !selectionInProgress && pickerVisible) {
          instance.tippy = tippyJs(
            targetEl,
            Object.assign(tippyDefaults, {
              content: instance.contentRef,
            })
          );
        }
      })
    );
  });
});
