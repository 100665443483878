import * as Sentry from '@sentry/browser';
import { UAParser } from '@ua-parser-js/pro-business';
import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import tippyJs, { delegate as delegateTippyJs } from 'tippy.js';
import HelpWindow from '@/help/HelpWindow';
import ReloadableReactiveVar from '@/lib/ReloadableReactiveVar';
import { rpcSendMessageToSupport } from '@/server/methods/contact/rpcSendMessageToSupport';
import Version from '@/Version';

const subjectMemory = new ReloadableReactiveVar('contact-form-subject', '');
const bodyMemory = new ReloadableReactiveVar('contact-form-body', '');

Template.contactForm.onCreated(async () => {
  const instance = Template.instance();
  instance.submitting = new ReactiveVar(false);
});

Template.contactForm.onRendered(async () => {
  const instance = Template.instance();
  const parsedUa = await UAParser().withFeatureCheck().withClientHints();
  let device = '';
  if (!parsedUa.device.vendor && /^Mac/i.test(navigator.platform) && navigator.maxTouchPoints > 1) {
    device = 'Apple iPad';
  } else {
    device = `${parsedUa.device.vendor || ''} ${parsedUa.device.model || ''}`.trim();
    // if (parsedUa.device.type) device += ` (${parsedUa.device.type})`;
    device = device.trim();
  }
  instance.diagInfo = {
    browser:
      `${parsedUa.browser.name || ''} ${parsedUa.browser.version.replace(/(\.0)+$/, '') || ''}`.trim(),
    os: `${parsedUa.os.name || ''} ${parsedUa.os.version.replace('10.15.7', '') || ''}`.trim(),
    cordova: 'cordova' in window,
    desktop: !!window.todesktop,
    android: /Android/i.test(navigator.userAgent),
    smVersion: Version.current,
    device,
  };

  if (Meteor.isCordova) {
    document.addEventListener(
      'deviceready',
      async () => {
        cordova.plugins.SMExtras.getBuildInfo?.((info) => {
          instance.diagInfo.appVersion = info?.version;
        });
        if ('device' in window && window.device.isiOSAppOnMac)
          instance.diagInfo.device = 'macOS (Apple Silicon)';
      },
      false
    );
  }

  Meteor.defer(() => {
    instance.$('#feedbackSubject').val(subjectMemory.get()).focus();
    instance.$('#feedbackBody').val(bodyMemory.get());
  });
  const d = instance.diagInfo;
  delegateTippyJs(instance.find('.js-avatars'), {
    target: 'img',
    content: (el) =>
      `<div style="text-align: center;">${el.dataset.name}</div><div style="text-align: center; opacity: 0.8;">${el.dataset.title}</div>`,
    allowHTML: true,
    placement: 'bottom',
    delay: [50, 100],
    hideOnClick: false,
    maxWidth: 250,
  });
  tippyJs(instance.find('.js-diagTippy'), {
    content: `Here's what will be included:<br>${[d.os, d.device, d.browser, `v${d.smVersion}`]
      .filter((a) => a)
      .join('<br>')}`,
    allowHTML: true,
    placement: 'top',
    delay: [200, 50],
    hideOnClick: false,
    maxWidth: 250,
  });
});

Template.contactForm.helpers({
  'submitting': () => Template.instance().submitting.get(),
  'disabledIfSubmitting': () =>
    Template.instance().submitting.get() ? { disabled: 'disabled' } : {},
});

Template.contactForm.events({
  'submit form': async function (event, instance) {
    event.preventDefault();
    if (!instance.$('#feedbackBody').val()) return;
    instance.submitting.set(true);
    try {
      await rpcSendMessageToSupport({
        subject: instance.$('#feedbackSubject').val(),
        body: instance.$('#feedbackBody').val(),
        diagnosticInfo: instance.diagInfo,
      });
      Sentry.captureException('Contact form submitted');
      Bert.alert(
        'Sent! You will get a reply within a few days, or one business day for urgent matters. Thanks!',
        'success'
      );
      subjectMemory.set(null);
      bodyMemory.set(null);
      if (FlowRouter.getRouteName() == 'contact') {
        FlowRouter.go('/app');
      } else if (Session.get('showContactWindow')) {
        Session.set('showContactWindow', false);
      } else {
        HelpWindow.hide();
      }
    } catch (err) {
      Bert.alert(err.reason, 'warning');
    } finally {
      instance.submitting.set(false);
    }
  },
  'input #feedbackSubject': _.throttle((event, instance) => {
    subjectMemory.set(instance.$('#feedbackSubject').val());
  }, 500),
  'input #feedbackBody': _.throttle((event, instance) => {
    bodyMemory.set(instance.$('#feedbackBody').val());
  }, 500),
  'keydown #feedbackBody': function (event, instance) {
    const cmdCtrl =
      /^Mac/.test(navigator.platform) || /iP(hone|ad|od)/.test(navigator.userAgent)
        ? !event.ctrlKey && event.metaKey
        : event.ctrlKey && !event.metaKey;
    if (event.keyCode == 13 && cmdCtrl && !event.shiftKey && !event.altKey) {
      event.preventDefault();
      instance.$('form').submit();
    }
  },
  'focus #feedbackBody': function (event, instance) {
    if (/Android|iP(hone|ad|od)/i.test(navigator.userAgent) && window.innerHeight < 900) {
      instance.$('.js-intro').slideUp();
    }
  },
});
