import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { DemoMode } from '@/client/DemoMode';

Template.registerHelper('pathFor', (...args) => {
  return FlowRouter.path(...args);
});

Template.registerHelper('disabledUnless', (value) => {
  return !value ? { disabled: true } : {};
});

Template.registerHelper('disabledIf', (value) => {
  return value ? { disabled: true } : {};
});

Template.registerHelper('requiredIf', (value) => {
  return value ? { required: true } : {};
});

Template.registerHelper('fallback', (...args) => {
  return args.find((v) => !(typeof v === 'undefined' || v === null));
});

Template.registerHelper('not', (condition) => !condition);

Template.registerHelper('demoMode', () => {
  return DemoMode.active();
});
