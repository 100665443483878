import type { Component } from 'svelte';
import { getContext, setContext } from 'svelte';
import type { ScreenPanel } from '@/ui/settings/ScreenPanel';

const contextKey = Symbol();

export class MultiPanelModalController {
  protected _activeScreenIndex = $state(0);
  protected _screens = $state<ScreenPanel[]>([]);
  protected _subview = $state<string | undefined>();
  protected _mostRecentZoomDirection = $state<number>(0);

  get activeScreenIndex() {
    return this._activeScreenIndex;
  }
  get screens() {
    return this._screens;
  }
  get subview() {
    return this._subview;
  }
  get mostRecentZoomDirection() {
    return this._mostRecentZoomDirection;
  }

  protected constructor(initialScreen: ScreenPanel) {
    this._screens = [initialScreen];
  }

  static create(initialScreen: ScreenPanel) {
    const controller = new MultiPanelModalController(initialScreen);
    setContext(contextKey, controller);
    return controller;
  }

  static current() {
    return getContext<MultiPanelModalController>(contextKey);
  }

  zoomInto(panel: Component | ScreenPanel) {
    this._screens = this._screens
      .slice(0, this._activeScreenIndex + 1)
      .concat('component' in panel ? panel : { component: panel });
    this._activeScreenIndex = this._screens.length - 1;
    this._mostRecentZoomDirection = 1;
  }

  zoomOut() {
    if (this._subview) {
      this._subview = undefined;
    } else {
      this._activeScreenIndex = Math.max(0, this._activeScreenIndex - 1);
    }
    this._mostRecentZoomDirection = -1;
  }

  zoomOutToRoot() {
    this._activeScreenIndex = 0;
    this._mostRecentZoomDirection = -1;
  }

  setSubview(subview: string | undefined) {
    this._subview = subview;
  }
}
