import { interpolateAcrossGradient } from '@/band/instruments/guitar/settings/interpolateAcrossGradient';
import { chop, downstroke } from '@/band/instruments/mandolin/MandolinActions';
import type { MandolinCore } from '@/band/instruments/mandolin/MandolinCore';
import { lerp } from '@/utilities/lerp';
import { randomPlusMinus } from '@/utilities/randomPlusMinus';

export const SwingMandolinCore: MandolinCore = {
  id: 'd',
  title: 'Swing-style dampening',

  timeSignatures: ['4/4'],

  linkedSettingsKeys: ['swingSustain', 'swingBackbeat'],
  otherSettingsKeys: ['timing'],

  chordStyle: 'bluegrass',

  // swingCategory: 'jazz',
  // defaultSwing: swingOptions['2:1'],

  presets: [
    {
      name: 'Strum, dry chop',
      settings: {
        'swingSustain': { b1: 1, b2: 0 },
        'swingBackbeat': {},
      },
    },
    {
      name: 'Strum, quick chop',
      settings: {
        'swingSustain': { b1: 1, b2: 1 / 12 },
        'swingBackbeat': {},
      },
    },
    {
      name: 'Strum, brief strum',
      settings: {
        'swingSustain': { b1: 1, b2: 1 / 3 },
        'swingBackbeat': {},
      },
    },
    {
      name: 'Longer triplet strums',
      settings: {
        'swingSustain': { b1: 2 / 3, b2: 2 / 3 },
        'swingBackbeat': {},
      },
    },
  ],

  processMeasure(measure, settings) {
    const beat1Spread =
      3 -
      interpolateAcrossGradient(settings.swingBackbeat.value, [
        [0, 0],
        [0.25, 0.75],
        [1, 1.5],
      ]);
    const beat2Spread = lerp(2.8, 3.3, settings.swingBackbeat.value);

    const downbeatAction =
      settings.swingSustain.onbeatSustain > 2.1 / 12
        ? downstroke({
            db: -settings.swingBackbeat.onbeatDbReduction - 4,
            velocity: Math.round(lerp(3, 2, settings.swingBackbeat.value)) as 3 | 2,
            chordSpread: beat1Spread + randomPlusMinus(0.2),
            chordBeatDuration: settings.swingSustain.onbeatSustain,
            chordAttenuation: lerp(-5, 0, settings.swingBackbeat.value),
          })
        : chop({
            db: -settings.swingBackbeat.onbeatDbReduction - 4,
            attack: 'normal',
            chordSpread: beat1Spread + randomPlusMinus(0.2),
            chordDuration: settings.swingSustain.onbeatSustain * 2 - 0.14,
            chordAttenuation: lerp(-5, 0, settings.swingBackbeat.value),
          });

    const upbeatAction =
      settings.swingSustain.offbeatSustain > 2.1 / 12
        ? downstroke({
            db: settings.swingBackbeat.offbeatDbBoost - 4,
            velocity: Math.round(lerp(2.8, 4, settings.swingBackbeat.value)) as 3 | 4,
            chordSpread: beat2Spread + randomPlusMinus(0.2),
            chordBeatDuration: settings.swingSustain.offbeatSustain,
          })
        : chop({
            db: settings.swingBackbeat.offbeatDbBoost - 4,
            attack: settings.swingBackbeat.value > 0.75 ? 'hard' : 'normal',
            chordSpread: beat2Spread + randomPlusMinus(0.2),
            chordDuration: settings.swingSustain.offbeatSustain * 2 - 0.14,
          });

    if (measure.endOfSong) {
      return [
        upbeatAction,
        null,
        null,
        null,
        downstroke({
          db: -4,
          velocity: 3,
          chordSpread: 3,
        }),
      ];
    }

    const standardCellPattern = [downbeatAction, null, upbeatAction, null];

    return [
      ...standardCellPattern,
      ...standardCellPattern,
      // ...(firstCell.split ? splitCell(settings) : standardCellPattern),
      // ...(secondCell?.split ? splitCell(settings) : standardCellPattern),
    ];
  },

  generateViz(settings) {
    const backbeat = settings.swingBackbeat.value;

    const onbeatViz: RhythmPatternVisualizationCommand | null =
      backbeat < 1
        ? {
            dir: 'd',
            biasY: 0.5 - backbeat * 0.3,
            tail: 0.7 - 0.35 * backbeat,
            thickness: 0.83 - backbeat, // max visible backbeat is 0.83
            sustain: settings.swingSustain.onbeatSustain,
            chick: settings.swingSustain.onbeatSustain === 0,
          }
        : null;
    const offbeatViz: RhythmPatternVisualizationCommand | null = {
      dir: 'd',
      biasY: 0.5,
      tail: 0.7 + 0.1 * backbeat,
      thickness: 0.83 + backbeat * 0.17,
      sustain: settings.swingSustain.offbeatSustain,
      chick: settings.swingSustain.offbeatSustain === 0,
    };
    return [onbeatViz, null, offbeatViz, null, onbeatViz, null, offbeatViz, null];
  },
};
