<script lang="ts">
  import type { Section } from '@/chart/Section.svelte';
  import { Conductor } from '@/Conductor';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';

  interface Props {
    section: Section;
    sectionIndex: number;
  }

  const { section, sectionIndex }: Props = $props();

  let isCurrentSong: boolean = $state(false);
  trackerEffect(
    () =>
      (isCurrentSong =
        (Conductor.displayState.position() || Conductor.displayState.startingPosition())?.song() ===
        section.song.index())
  );

  let startingHereRep: number | undefined = $state();
  trackerEffect(
    () =>
      (startingHereRep =
        sectionIndex === Conductor.displayState.startingPosition()?.section()
          ? Conductor.displayState.startingPosition()?.rep()
          : undefined)
  );

  let currentRep: number | undefined = $state();
  trackerEffect(
    () =>
      (currentRep =
        sectionIndex === Conductor.displayState.position()?.section()
          ? Conductor.displayState.position()?.rep()
          : undefined)
  );

  let editMode = $state(false);
  trackerEffect(() => (editMode = section.song.editMode()));

  let animationDurationStyle: string = $state('');
  trackerEffect(
    () => (animationDurationStyle = `animation-duration: ${Conductor.beatDuration()}s`)
  );

  function handleClick(_event: Event, { rep }: { rep: number }) {
    if (section.song.editMode()) return;
    void Conductor.playFrom({
      song: section.song.index(),
      section: sectionIndex,
      rep,
      cell: 0,
      beat: 0,
    });
  }
</script>

{#each Array(section.repetitions) as _, rep (rep)}
  <span
    class="rep rep-{rep}"
    class:interactive={!editMode}
    class:starting-here={isCurrentSong && startingHereRep === rep}
    class:current={isCurrentSong && currentRep === rep}
    onclick={(e) => handleClick(e, { rep })}
    data-rep={rep}
    style={animationDurationStyle}
    aria-hidden="true"
    title="Repetition #{rep + 1}">{rep + 1}</span
  >
{/each}

<style>
  .rep {
    background: rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    border: solid 0.9px rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.32);
    display: inline-block;
    transition: all 0.15s ease;
    font-size: 13px;
    font-weight: 600;
    line-height: 1;
    margin: 0 2px;
    padding: 3px 5px;
    vertical-align: 1px;

    &.interactive {
      pointer-events: auto;
      cursor: pointer;
      &:hover:not(.current) {
        border-color: hsl(41, 65%, 65%);
        background: hsla(41, 100%, 50%, 0.1);
      }
    }

    &.current {
      background: #ffc243;
      border-color: #ffc243;
      color: white;
    }

    &.starting-here {
      transition: none;
      animation-name: startingHerePulse;
      animation-duration: unset;
      animation-timing-function: cubic-bezier(0, 0.6, 0.5, 1);
      animation-delay: 150ms;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }
  }
</style>
