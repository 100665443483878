<script lang="ts">
  import { onMount } from 'svelte';
  import type { Cell } from '@/chart/Cell.svelte.ts';
  import type { Song } from '@/chart/Song';
  import { Crnt } from '@/Crnt';
  import { EditorToolsState } from '@/editor/ui/EditorToolsState';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import { UserProfile } from '@/user/UserProfile';

  const editorMenu = EditorToolsState.get();

  const effectDefs = [
    { label: 'None', effect: undefined },
    { label: 'Rest', effect: 'rest' },
    { label: 'Stop', effect: 'stop' },
    { label: 'Diamond', effect: 'diamond' },
  ] as const;

  let song: Song | undefined;
  trackerEffect(() => (song = Crnt.song()));

  let focusedCells: Cell[] | undefined = $state();
  trackerEffect(() => (focusedCells = song?.focus.cells()));

  let selectedEffect: string | undefined = $derived(
    focusedCells?.every((c) => c.effect == focusedCells?.[0]?.effect)
      ? focusedCells[0]?.effect
      : undefined
  );

  let splitCellFocused = $derived(focusedCells?.every((c) => c.split));

  onMount(() => {
    UserProfile.recordMilestone('FX_MENU_OPEN');
  });

  const touchMode =
    /Android|webOS|iP(hone|ad|od)/i.test(navigator.userAgent) ||
    window.matchMedia('(max-width: 480px)').matches ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
</script>

<div class="flex">
  <div class="flex flex-col gap-1.5 p-5">
    {#each effectDefs as effect (effect.label)}
      <button
        class="colors-button colors-effect-button w-20 rounded px-2 py-0.5 text-center font-medium"
        aria-pressed={selectedEffect == effect.effect && !splitCellFocused}
        onclick={() => {
          Crnt.song()?.withFocused().setEffect(effect.effect);
        }}
        disabled={splitCellFocused}
      >
        {effect.label}
      </button>
      {#if !effect.effect}
        <div class="border-b border-gray-100"></div>
      {/if}
    {/each}
  </div>
  <div class="w-40 min-w-40 border-l border-gray-100 p-5 text-sm text-gray-700">
    {#if splitCellFocused}
      Effects cannot be applied to subdivided cells...yet.<br /><br />(We plan to add this ability
      in the future!)
    {:else}
      {#if !selectedEffect}
        No effect applied to this beat.<br /><br />
        {#if !touchMode}
          Note: You can cycle through these effects with
          <span class="whitespace-nowrap">the <kbd>X</kbd> key</span>.
        {/if}
      {/if}
      {#if selectedEffect == 'rest'}
        Rest: do nothing on this beat.
      {/if}
      {#if selectedEffect == 'stop'}
        Stop: strum on the downbeat, then mute the strings.
      {/if}
      {#if selectedEffect == 'diamond'}
        Diamond: strum on the downbeat and let it ring. Usually followed by at least one rest beat.
      {/if}
    {/if}
  </div>
</div>

<style>
  .colors-effect-button {
    border: solid 1px var(--muted-200);

    &[aria-pressed='true'],
    &[aria-pressed='true']:hover,
    &[aria-pressed='true']:active {
      background-color: var(--primary-900);
      border-color: var(--primary-900);
      color: white;
    }

    @media not all and (hover: none), (min--moz-device-pixel-ratio: 1) {
      &:hover {
        border-color: var(--muted-300);
      }
      &:active {
        border-color: var(--muted-300);
      }
    }
  }
</style>
