import type { CircularStrumLandscape } from '@/band/instruments/CircularStrumLandscape';

function makePole(opts: {
  isolatedDownstrokes: number;
  doubleDownstrokes: number;
  upstrokes: number;
  doubleUpstrokes: number;
  downstrokePower: number;
  upstrokePower: number;
}): EightNumbers {
  return opts
    ? [
        opts.isolatedDownstrokes,
        opts.doubleDownstrokes,
        opts.upstrokes,
        opts.doubleUpstrokes,
        opts.downstrokePower,
        opts.upstrokePower,
        0,
        0,
      ]
    : [0, 0, 0, 0, 0, 0, 0, 0];
}

export const standardChopPatternLandscape: CircularStrumLandscape<EightNumbers> = {
  centerPowers: makePole({
    isolatedDownstrokes: 0.3,
    doubleDownstrokes: 0.05,
    upstrokes: 0.5,
    doubleUpstrokes: 0.3,
    downstrokePower: 0.5,
    upstrokePower: 0.4,
  }),

  poles: [
    {
      theta: 6 / 8, // NORTH
      halfPowers: makePole({
        isolatedDownstrokes: 0.7,
        doubleDownstrokes: 0.3,
        upstrokes: 0.8,
        doubleUpstrokes: 0.7,
        downstrokePower: 0.6,
        upstrokePower: 0.7,
      }),
      fullPowers: makePole({
        isolatedDownstrokes: 1,
        doubleDownstrokes: 1,
        upstrokes: 1,
        doubleUpstrokes: 1,
        downstrokePower: 0.7,
        upstrokePower: 0.6,
      }),
    },
    {
      theta: 7 / 8, // NE
      halfPowers: makePole({
        isolatedDownstrokes: 0.2,
        doubleDownstrokes: 0.1,
        upstrokes: 0.7,
        doubleUpstrokes: 0.7,
        downstrokePower: 0.5,
        upstrokePower: 0.9,
      }),
      fullPowers: makePole({
        isolatedDownstrokes: 0.5,
        doubleDownstrokes: 0.5,
        upstrokes: 1,
        doubleUpstrokes: 1,
        downstrokePower: 0.6,
        upstrokePower: 1.0,
      }),
    },
    {
      theta: 0 / 8, // EAST
      halfPowers: makePole({
        isolatedDownstrokes: 0.1,
        doubleDownstrokes: 0.05,
        upstrokes: 0.8,
        doubleUpstrokes: 0.5,
        downstrokePower: 0.4,
        upstrokePower: 0.6,
      }),
      fullPowers: makePole({
        isolatedDownstrokes: 0.1,
        doubleDownstrokes: 0.1,
        upstrokes: 1,
        doubleUpstrokes: 0.7,
        downstrokePower: 0.05,
        upstrokePower: 1.0,
      }),
    },
    {
      theta: 1 / 8, // SE
      halfPowers: makePole({
        isolatedDownstrokes: 0.2,
        doubleDownstrokes: 0.05,
        upstrokes: 0.5,
        doubleUpstrokes: 0.2,
        downstrokePower: 0.4,
        upstrokePower: 0.8,
      }),
      fullPowers: makePole({
        isolatedDownstrokes: 0.0,
        doubleDownstrokes: 0.0,
        upstrokes: 0.4,
        doubleUpstrokes: 0.4,
        downstrokePower: 0.1,
        upstrokePower: 0.9,
      }),
    },
    {
      theta: 2 / 8, // SOUTH
      halfPowers: makePole({
        isolatedDownstrokes: 0.1,
        doubleDownstrokes: 0.0,
        upstrokes: 0.25,
        doubleUpstrokes: 0.1,
        downstrokePower: 0.5,
        upstrokePower: 0.5,
      }),
      fullPowers: makePole({
        isolatedDownstrokes: 0,
        doubleDownstrokes: 0,
        upstrokes: 0,
        doubleUpstrokes: 0,
        downstrokePower: 0.4,
        upstrokePower: 0.5,
      }),
    },
    {
      theta: 3 / 8, // SW
      halfPowers: makePole({
        isolatedDownstrokes: 0.7,
        doubleDownstrokes: 0.1,
        upstrokes: 0.3,
        doubleUpstrokes: 0.2,
        downstrokePower: 0.7,
        upstrokePower: 0.5,
      }),
      fullPowers: makePole({
        isolatedDownstrokes: 0.8,
        doubleDownstrokes: 0.1,
        upstrokes: 0,
        doubleUpstrokes: 0,
        downstrokePower: 0.8,
        upstrokePower: 0.05,
      }),
    },
    {
      theta: 4 / 8, // WEST
      halfPowers: makePole({
        isolatedDownstrokes: 0.7,
        doubleDownstrokes: 0.3,
        upstrokes: 0.3,
        doubleUpstrokes: 0.2,
        downstrokePower: 0.7,
        upstrokePower: 0.5,
      }),
      fullPowers: makePole({
        isolatedDownstrokes: 1.1,
        doubleDownstrokes: 1,
        upstrokes: 0,
        doubleUpstrokes: 0,
        downstrokePower: 1.0,
        upstrokePower: 0.05,
      }),
    },
    {
      theta: 5 / 8, // NW
      halfPowers: makePole({
        isolatedDownstrokes: 0.7,
        doubleDownstrokes: 0.3,
        upstrokes: 0.7,
        doubleUpstrokes: 0.8,
        downstrokePower: 0.7,
        upstrokePower: 0.6,
      }),
      fullPowers: makePole({
        isolatedDownstrokes: 1,
        doubleDownstrokes: 1,
        upstrokes: 0.5,
        doubleUpstrokes: 0.5,
        downstrokePower: 0.7,
        upstrokePower: 0.6,
      }),
    },
  ],

  presets: [
    {
      name: 'None (offbeat chop only)',
      position: [1, 0.25],
    },
    {
      name: 'Few accents',
      position: [0.6, 0.25],
    },
    {
      name: 'Some downstrokes',
      position: [1, 0.375],
    },
    {
      name: 'Some upstrokes',
      position: [1, 0.125],
    },
    {
      name: 'Mix of accents',
      position: [0.0, 0.0],
    },
    {
      name: 'Mixed, mostly downs',
      position: [0.6, 0.5],
    },
    {
      name: 'Mixed, syncopated',
      position: [0.6, 0.0],
    },
    {
      name: 'Syncopated upstrokes',
      position: [1, 0.0],
    },
    {
      name: 'Lots, syncopated',
      position: [1, 0.875],
    },
    {
      name: 'Lots of accents',
      position: [1, 0.75],
    },
    {
      name: 'Lots, mostly downstrokes',
      position: [1, 0.625],
    },
    {
      name: 'Downstrokes (every time)',
      position: [1, 0.5],
    },
  ],
};
