import { EventEmitter } from 'eventemitter3';

const isIOS =
  /iP(hone|ad|od)/i.test(navigator.userAgent) ||
  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

class PageVisibilityWatcherSingleton extends EventEmitter {
  get currentlyVisible(): boolean {
    return iosPageFocused && pageNotHidden;
  }
  constructor() {
    super();
    createListeners();
  }
}

const PageVisibilityWatcher = new PageVisibilityWatcherSingleton();
export { PageVisibilityWatcher };

// Track page state
let pageNotHidden = true;
let iosPageFocused = true; // iOS has a buggy page visibility API, luckily it dispatches blur and focus events on the window when vis change events should fire.

function createListeners(): void {
  // Watch for page state changes and check initial page state
  document.addEventListener('visibilitychange', handleVisibilityChange, true);

  if (isIOS) {
    // iOS doesn't dispatch visibilitychange events properly,
    // so listen for focus/blur as well
    window.addEventListener('focus', handleFocusBlur, true);
    window.addEventListener('blur', handleFocusBlur, true);
  }
}

function handleVisibilityChange(): void {
  const wasVisible = PageVisibilityWatcher.currentlyVisible;
  pageNotHidden = !document.hidden;
  if (wasVisible !== PageVisibilityWatcher.currentlyVisible) {
    PageVisibilityWatcher.emit(PageVisibilityWatcher.currentlyVisible ? 'visible' : 'hidden');
  }
}

function handleFocusBlur(event?: Event): void {
  if (event?.target !== window) return;

  const wasVisible = PageVisibilityWatcher.currentlyVisible;
  iosPageFocused = document.hasFocus();
  if (wasVisible !== PageVisibilityWatcher.currentlyVisible) {
    PageVisibilityWatcher.emit(PageVisibilityWatcher.currentlyVisible ? 'visible' : 'hidden');
  }
}
