import { FlowRouter } from 'meteor/ostrio:flow-router-extra';

Template.privacyPage.onCreated(() => {
  var instance = Template.instance();
  instance.ready = new ReactiveVar();
  // we're no longer doing async import of the Blaze template, since meteor-vite doesn't support it
  instance.ready.set(true);
});

Template.privacyPage.events({
  'click .close': function (event, instance) {
    event.preventDefault();
    FlowRouter.go('/');
  },
  'click .js-showCompletePolicy': function (event, instance) {
    instance.ready.set(false);
    instance.ajaxContent = true;
    jQuery.getJSON(
      'https://www.iubenda.com/api/privacy-policy/7814360/no-markup',
      (json, textStatus) => {
        const html = Meteor.isCordova ? json.content.replace(/_blank/g, '_system') : json.content;
        instance.$('.js-policy').html(html);
        instance.ready.set(true);
      }
    );
    return false;
  },
});

Template.privacyPage.helpers({
  showTemplate() {
    return Template.instance().ready.get() && !Template.instance().ajaxContent;
  },
});
