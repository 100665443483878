import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
/* Adapted from flow-router-seo package; simplified by Luke */

export const FlowRouterSEO = function (config) {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  var self = this;

  self._updateDOM = function (settings) {
    // Fill in any gaps with the defaults
    // We use jQuery's extend as it is recursive (i.e. it performs a deep copy), unlike UnderscoreJS
    settings = $.extend(true, {}, self._defaults, settings);

    var tagsToAppend = [];

    // Set title if specified otherwise use the default empty string title
    if (Meteor.userId()) settings.title = settings.title.replace(/\s*Backing Track( on|$)/, '');
    document.title = settings.title;

    // Set the description before the other meta tags as other tags are generated from the meta description
    if (settings.description)
      tagsToAppend.push(
        `<meta name="description" content="${self._escapeHTML(settings.description)}" data-flow-router-seo="true" />`
      );

    // Set meta tags
    _.each(settings.meta, (value, key) => {
      if (typeof value === 'function') value = value(settings);
      if (value)
        tagsToAppend.push(
          `<meta ${key} content="${self._escapeHTML(value)}" data-flow-router-seo="true" />`
        );
    });

    if (settings.noindex) {
      tagsToAppend.push(
        '<meta name="robots" content="noindex, follow" data-flow-router-seo="true" />'
      );
    }

    // Remove all existing meta tags that this package has added
    var $head = $(document.head);
    $head.find('> meta, > link').filter('[data-flow-router-seo="true"]').remove();
    $head.append(tagsToAppend.join(''));
  };

  self.setCanonicalUrl = function (canonicalUrl) {
    $(document.head).find('> link[rel="canonical"]').remove();
    $(document.head).append(
      `<link rel="canonical" href="${canonicalUrl}" data-flow-router-seo="true" />`
    );
  };

  self._currentUrl = function () {
    var currentRoute = FlowRouter.current();
    var routeName = currentRoute.route.name;
    var path = routeName ? FlowRouter.path(routeName, currentRoute.params) : currentRoute.path;
    // Meteor.absoluteUrl() doesn't want a '/' at the beginning of the URL - that's why we've got the substring(1)
    return Meteor.absoluteUrl(path.substring(1));
  };

  self._currentTitle = function () {
    return document.title;
  };

  self._currentDescription = function (settings) {
    return settings.description || self._defaults.description;
  };

  // We need to escape any HTML within the title/meta tags
  self._escapeHTML = function (HTML) {
    return HTML.replace(/'/g, '&apos;').replace(/"/g, '&quot;');
  };

  // Internal function only called by flow router's enter trigger
  self._reset = function () {
    // We want to start with the defaults until the subscription is ready and we have the route specific settings
    self._updateDOM(self._defaults);
  };

  // The user can call this from the template's onCreated function
  self.set = function (settings) {
    if (settings) self._updateDOM(settings);
  };

  // The user can call this to initialise the router with defaults
  // The defaults are merged rather than overridden
  // For convenience, it can be called from a file both on the server & client
  // but it only does stuff if called from the client (plus jQuery isn't on the
  // server!)
  self.setDefaults = function (settings) {
    // We use jQuery's extend as it is recursive
    // (i.e. it performs a deep copy), unlike UnderscoreJS
    if (Meteor.isClient) self._defaults = $.extend(true, {}, self._defaults, settings);
  };

  // Default settings if nothing specified by the user
  self._defaults = {
    title: '',
    description: '',
    meta: {
      'name="twitter:title"': self._currentTitle,
      'name="twitter:url"': self._currentUrl,
      'name="twitter:description"': self._currentDescription,
      'property="og:title"': self._currentTitle,
      'property="og:url"': self._currentUrl,
      'property="og:description"': self._currentDescription,
    },
  };

  if (config && config.defaults) {
    self.setDefaults(config.defaults);
  }
};
