<script lang="ts">
  import type { presetGenres } from '@/band/presets/presetGenres';
  import BandFeedbackPanel from '@/band/ui/panels/BandFeedbackPanel.svelte';
  import { MultiPanelModalController } from '@/ui/settings/MultiPanelModalController.svelte';

  const modalController = MultiPanelModalController.current();

  interface Props {
    genre: (typeof presetGenres)[number];
    timeSignature: TimeSignature;
  }

  let { genre, timeSignature }: Props = $props();

  function onClickPresetFeedback(event: MouseEvent) {
    event.preventDefault();
    modalController.zoomInto({
      component: BandFeedbackPanel,
      props: {
        instructions: `Let me know what kind of music styles/presets you think should be in Strum Machine! If you can cite a recording that exemplifies that style, all the better.`,
      },
      title: 'Send Feedback',
    });
  }
</script>

<div class="px-6 pb-5 text-sm text-gray-600">
  Any feedback on these presets or suggestions for new ones?
  <a href="#" class="font-medium" onclick={onClickPresetFeedback}>Share your feedback</a>.

  {#if genre?.name == 'Celtic' && timeSignature == '6/8'}
    <div class="mt-3"></div>
    Note: The Celtic jig rhythm needs work, I know. Specifically, I think one needs to be able to adjust
    the level of syncopation and complexity in the strum, because sometimes you want a really funky syncopated
    groove while other times you want a really basic DUDDUD strum. I will be working on this in the future.
  {/if}

  {#if genre?.name == 'Country' && timeSignature == '4/4'}
    <div class="mt-3"></div>
    Note: Simple drums (i.e. snare with brushes) will come at some point as well.
  {/if}

  {#if genre?.name == 'Other Styles' && timeSignature == '6/8'}
    <div class="mt-3"></div>
    Note: This polska rhythm is more of a placeholder, I think... I don't know, I don't play polskas
    myself. If you play polskas, how well does it work for you? What would you like to hear instead?
    <a href="#" class="font-medium" onclick={onClickPresetFeedback}>Tell me</a>!
  {/if}
</div>
