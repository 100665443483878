import { Crnt } from '@/Crnt';
import { Roles } from '@/Roles';

Template.registerHelper('canCreateNewSong', () => {
  return Roles.userHasPermission(Meteor.userId(), 'songs.insert');
});

Template.registerHelper('canPlaySong', () => {
  return Roles.currentUserCanPlaySong(Crnt.song() || Crnt.medley());
});

Template.registerHelper('canEditSongs', () => {
  return Roles.userHasPermission(Meteor.userId(), 'songs.updatePrivate');
});

Template.registerHelper('canDeleteSong', () => {
  return Crnt.song()?.id() && Roles.userHasPermission(Meteor.userId(), 'songs.remove', Crnt.song());
});
