import { Meteor } from 'meteor/meteor';

declare global {
  interface Window {
    impersonateUser: (userId: string, callback?: () => void) => Promise<boolean>;
    userIsBeingImpersonated: boolean;
  }
}

window.impersonateUser = async function (userId) {
  try {
    await Meteor.callAsync('impersonateUser', userId);
    window.userIsBeingImpersonated = true; // set this first because it's not reactive
    //@ts-expect-error - Meteor.connection *does* exist
    // eslint-disable-next-line
    Meteor.connection.setUserId(userId);
    return true;
  } catch (err) {
    Bert.alert(err.reason || err.error || err, 'danger');
    return false;
  }
};
