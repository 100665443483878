<script lang="ts" module>
  let canvas: HTMLCanvasElement | undefined;
  function calculateTextWidth(text: string, font = 'normal 700 16px Lato') {
    canvas ??= document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) return 0;
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
  }

  const minNameWidth = 40;
  const minNameEditingWidth = 100;
  const nameEditIconShownBelow = window.innerWidth / 3;
  const nameEditIconPadding = 28;
</script>

<script lang="ts">
  import { onMount, tick } from 'svelte';
  import { innerWidth } from 'svelte/reactivity/window';
  import type { Instance as TippyInstance } from 'tippy.js';
  import tippyJs from 'tippy.js';
  import _ from 'underscore';
  import { clog } from '@/browser/clog';
  import type { Section } from '@/chart/Section.svelte';
  import Icon from '@/ui/icons/Icon.svelte';

  interface Props {
    section: Section;
  }

  let { section }: Props = $props();

  let quickSelectTippy: TippyInstance | undefined = $state();

  onMount(() => {
    quickSelectTippy = tippyJs(inputEl, {
      content: quickSelectMenuEl,
      placement: 'bottom-start',
      theme: 'light',
      trigger: 'manual',
      duration: [300, 150],
      interactive: true,
      hideOnClick: false,
      delay: [100, 50],
      role: '',
      animation: 'scale-subtle',
      arrow: false,
    });
    return () => quickSelectTippy?.destroy();
  });

  let tippyParentEl: HTMLElement;
  let inputEl: HTMLInputElement;
  let quickSelectMenuEl: HTMLElement;
  let iconEl: HTMLElement;

  let screenWidth = $derived(innerWidth.current || 800);

  function inputFocusHandler() {
    inputEl.placeholder = placeholderActive;
    inputEl.setSelectionRange(0, 9999);
    quickSelectTippy?.show();
    recalculateInputWidth();
    if (screenWidth < 500) {
      const editorToolbarEl = document.querySelector('.EditorToolbar') as HTMLElement | undefined;
      if (editorToolbarEl) editorToolbarEl.hidden = true;

      /// Not sure why this wasn't working...
      // const inputRect = inputEl.getBoundingClientRect();
      // if (inputRect.top > window.innerHeight / 2) {
      //   const scrollContainerEl = document.querySelector('.js-global-songScrollContainer') as | HTMLElement | undefined;
      //   scrollContainerEl?.scrollBy({ 'top': inputRect.top - window.innerHeight / 2, 'behavior': 'smooth', });
      // }
    }
  }

  const recalculateInputWidth = _.throttle(function recalculateInputWidth() {
    if (!inputEl) return;
    const name = inputEl.value || inputEl.placeholder;
    const nameWidth = Math.max(
      quickSelectTippy?.state.isVisible ? minNameEditingWidth : minNameWidth,
      calculateTextWidth(name)
    );
    inputEl.style.width = `${nameWidth + (nameWidth < nameEditIconShownBelow ? nameEditIconPadding : 0)}px`;
    iconEl.style.opacity = nameWidth < nameEditIconShownBelow ? '' : '0';
  }, 200);

  function inputBlurHandler() {
    section.setName(inputEl.value);
    recalculateInputWidth();
    blurCheck();
  }

  function blurCheck() {
    requestAnimationFrame(() => {
      if (tippyParentEl && !tippyParentEl.contains(document.activeElement)) {
        quickSelectTippy?.hide();
        inputEl.placeholder = placeholderInactive;
        recalculateInputWidth();
      }
    });
    const editorToolbarEl = document.querySelector('.EditorToolbar') as HTMLElement | undefined;
    if (editorToolbarEl) editorToolbarEl.hidden = false;
  }

  function inputKeydownHandler(e: KeyboardEvent) {
    if (e.key == 'Enter') {
      quickSelectTippy?.hide();
      inputEl.blur();
    }
  }

  function inputPointerUpHandler(e: PointerEvent) {
    if (inputEl == document.activeElement) return;
    inputEl.focus();
    e.preventDefault();
  }

  const sectionNamePresets = ['A Part', 'B Part', 'C Part', 'Intro', 'Verse', 'Chorus'];
  let placeholderActive = $derived(
    screenWidth > 400 ? 'Type in a section label...' : 'Type a label...'
  );
  let placeholderInactive = $derived(
    `${screenWidth > 400 ? 'Section' : ''} #${(section.index ?? NaN) + 1}`
  );
  $effect(() => {
    void section.name;
    void screenWidth;
    tick()
      .then(() => recalculateInputWidth())
      .catch(clog.error);
  });
</script>

<div class="relative" bind:this={tippyParentEl} draggable="false">
  <input
    type="text"
    bind:this={inputEl}
    value={section.name || ''}
    placeholder={placeholderInactive}
    class="form-control peer"
    class:no-name={!section.name}
    onfocus={inputFocusHandler}
    onblur={inputBlurHandler}
    oninput={recalculateInputWidth}
    onkeydown={inputKeydownHandler}
    onpointerup={inputPointerUpHandler}
    ondragstart={(e) => e.preventDefault()}
  />
  <div
    class="pointer-events-none absolute right-2 top-1/2 ml-2 -translate-y-1/2 text-muted-400 transition-all peer-hover:text-primary-500 peer-focus:opacity-0"
    bind:this={iconEl}
  >
    <Icon id="pen" size={13} />
  </div>
  <div
    bind:this={quickSelectMenuEl}
    class="flex w-56 flex-wrap justify-stretch p-1"
    aria-label="Section label presets"
    aria-hidden="true"
  >
    {#each sectionNamePresets as name (name)}
      <button
        class="colors-button flex-1 basis-1/3 rounded-md p-1.5 text-sm"
        aria-label={name.replace(/^A /, 'A, ')}
        onclick={() => {
          section.setName(name);
          quickSelectTippy?.hide();
        }}
        onblur={blurCheck}
      >
        {name}
      </button>
    {/each}
  </div>
</div>

<style>
  .form-control {
    padding: 0.25rem 0.25rem 0.25rem 0.4rem;
    height: auto;
    font-size: 1rem;
    line-height: 1.35;
    font-weight: bold;
    box-sizing: content-box; /* for js function */
    color: rgba(0, 0, 0, 0.75);
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 6px;
    transition:
      border-color 0.15s ease-in-out,
      background-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out,
      width 0.3s ease-out,
      padding-right 0.3s ease-out;

    &.no-name {
      font-weight: normal;
      color: rgba(0, 0, 0, 0.4);
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.5);
      border: 1px solid #ced4da;
    }
    &:focus {
      background-color: #fff;
      border: 1px solid #ced4da;
      font-weight: 500;
      color: rgba(0, 0, 0, 1);
    }
    &:not(:focus) {
      cursor: pointer;
    }
  }

  .songSectionEditName-editIcon {
    font-size: 14px;
    line-height: 0.1;
    position: absolute;
    right: 0.4rem;
    top: 50%;
    transform: translateY(-50%);
    color: hsl(206, 60%, 60%);
    pointer-events: none;
    transition:
      opacity 0.15s ease 0.25s,
      color 0.15s ease;

    input:hover + & {
      color: hsl(206, 90%, 40%);
    }
    input:focus + & {
      opacity: 0;
      transition: opacity 0.15s ease;
    }
  }

  .songSectionEditName-quickSelectMenu {
    width: 15em;
    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;
    padding: 0.25rem;

    > .btn {
      flex: 1 1 33%;
      padding: 0.4rem;
      margin: 0.25rem 0;
    }
  }
</style>
