import { strict as assert } from 'assert';
import { bounded } from '@/utilities/bounded';

/**
 * Svelte action for a range input that only allows certain values.
 * Be sure to listen for the 'set' event on the range input to get the actual value.
 */
export function rangeWithAllowedValues(
  range: HTMLInputElement,
  options: {
    allowedValues: number[];
    valueChangedCallback: (value: number) => void;
    initialValue?: number;
  }
) {
  const { allowedValues, valueChangedCallback, initialValue } = options;

  function getClosestAllowedIndex(to: number): number {
    const deltas = allowedValues.map((value) => Math.abs(value - to));
    return deltas.indexOf(Math.min(...deltas));
  }

  function rangeChangeHandler() {
    // e.stopImmediatePropagation();
    const closestValue = allowedValues[getClosestAllowedIndex(+range.value)];
    assert(typeof closestValue == 'number');
    range.value = closestValue.toFixed(3);
    valueChangedCallback(closestValue);
  }

  function rangeKeyDownHandler(e: KeyboardEvent) {
    const down = e.key == 'ArrowLeft' || e.key == 'ArrowDown';
    const up = e.key == 'ArrowRight' || e.key == 'ArrowUp';
    if (up || down) {
      const closestIndex = getClosestAllowedIndex(+range.value);
      const newIndex = bounded(closestIndex + (down ? -1 : 1), 0, allowedValues.length - 1);
      const newValue = allowedValues[newIndex];
      assert(typeof newValue == 'number', `rangeKeyDownHandler: newValue is undefined`);
      range.value = newValue.toString();
      valueChangedCallback(newValue);
      e.preventDefault();
    }
  }

  if (typeof initialValue === 'number') {
    range.value = (allowedValues[getClosestAllowedIndex(initialValue)] ?? 0).toFixed(5);
  }

  range.addEventListener('input', rangeChangeHandler);
  range.addEventListener('change', rangeChangeHandler);
  range.addEventListener('keydown', rangeKeyDownHandler);

  return {
    destroy() {
      range.removeEventListener('input', rangeChangeHandler);
      range.removeEventListener('change', rangeChangeHandler);
      range.removeEventListener('keydown', rangeKeyDownHandler);
    },
  };
}
