import type { BandPreset } from '@/band/presets/BandPreset';

export function createDefaultPresetForTimeSignature(timeSignature: TimeSignature): BandPreset {
  return Object.freeze({
    id: `d:${timeSignature}`, // this ID prefix is referenced elsewhere!
    default: true,
    name: 'Default Band',
    timeSignature: timeSignature,
    band: ['6/8', '9/8'].includes(timeSignature)
      ? { 'g_e': true }
      : { 'g_e': true, 'b_e': true, 'm_e': true },
  } as BandPreset);
}
