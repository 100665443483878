import { makeInstructionsFromBassIntent } from '@/band/instruments/bass/makeInstructionsFromBassIntent';
import type { PlayerInstruction } from '@/band/instruments/PlayerInstruction';
import type { LinearizedBeat } from '@/chart/LinearizedBeat';
import type { LinearizedMeasure } from '@/chart/LinearizedMeasure';

export function applyBassIntentsToMeasure(
  measure: LinearizedMeasure,
  swing = 0,
  timingTweaks: InstrumentTimingTweaks = []
) {
  const upstrokeBeatOffset = 0.5 + 0.25 * swing;

  for (let i = 0; i < measure.beats.length; i++) {
    const beat = measure.beats[i] as LinearizedBeat;
    // const bassChord = beat?.plans.bass?.chord;
    // if (!bassChord) {
    //   beat.playerInstructions.bass = [];
    //   continue;
    // }

    let downstrikeOffset = 0;
    if (measure.timeSignature == '4/4') downstrikeOffset = timingTweaks[i % 2] ?? 0;
    if (measure.timeSignature == '3/4') downstrikeOffset = timingTweaks[i % 3] ?? 0;

    const downstrokeIntent = beat.plans.bass?.onbeatIntent;
    const upstrokeIntent = beat.plans.bass?.offbeatIntent;
    beat.playerInstructions.bass = [
      ...(downstrokeIntent
        ? addBeatOffsetToInstructions(
            makeInstructionsFromBassIntent(downstrokeIntent),
            downstrikeOffset
          )
        : []),
      ...(upstrokeIntent
        ? addBeatOffsetToInstructions(
            makeInstructionsFromBassIntent(upstrokeIntent),
            upstrokeBeatOffset
          )
        : []),
    ];
  }
}

function addBeatOffsetToInstructions(
  instructions: PlayerInstruction[],
  beatOffset: number
): PlayerInstruction[] {
  instructions.forEach((inst) => {
    if (inst.type == 'play' || inst.type == 'mute')
      inst.beatOffset = (inst.beatOffset || 0) + beatOffset;
  });
  return instructions;
}
