Template.indexPageMenu.helpers({
  isSeasonOfGiving() {
    return new Date().getMonth() + 1 >= 11;
  },
  hideApps() {
    return Meteor.absoluteUrl().includes('old.') || Meteor.isCordova;
  },
});
Template.indexPageMenu.events({
  'click a': function (event, instance) {
    Modal.hide('sidePanel');
  },
});
