<script lang="ts">
  import BandPanelAlert from '@/band/ui/components/BandPanelAlert.svelte';
  import HorizontalRadioButtons from '@/band/ui/components/HorizontalRadioButtons.svelte';
  import PanelSettingSection from '@/band/ui/components/PanelSettingSection.svelte';
  import PrettySlider from '@/band/ui/components/PrettySlider.svelte';
  import PanelModalLayout from '@/band/ui/panels/PanelModalLayout.svelte';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';
  import { slideFade } from '@/ui/svelte-transitions';
  import { clickTap } from '@/ui-helpers/click-tap';

  const bandMenu = getBandMenuState();

  let setting = $derived($bandMenu.guitarSettings.bassNotes);

  let coreUsesBrushiness = $derived(
    $bandMenu.guitar
      .coreOrFallbackForTimeSignature($bandMenu.timeSignature)
      .linkedSettingsKeys.includes('brushiness')
  );

  let someBoomStrums = $derived(
    coreUsesBrushiness && $bandMenu.guitarSettings.brushiness.boomStrumProbability > 0.3
  );
  let allBoomStrums = $derived(
    coreUsesBrushiness && $bandMenu.guitarSettings.brushiness.boomStrumProbability == 1
  );

  const optionsForMode = [
    { value: 'r', caption: 'Root Only' },
    { value: 'a', caption: 'Alternating' },
    // { value: 'w', caption: 'Walking' },
  ];

  let currentMode = $derived(setting.rootOnly ? 'r' : setting.walking ? 'w' : 'a');

  function changeMode(mode: string) {
    if (mode == 'r') $bandMenu.guitar.setSetting('bassNotes', setting.withRootOnly());
    if (mode == 'a') $bandMenu.guitar.setSetting('bassNotes', setting.withAlternatingBass());
    if (mode == 'w') $bandMenu.guitar.setSetting('bassNotes', setting.withWalkingBass());
  }
</script>

<PanelModalLayout>
  {#snippet header()}
    <header class="panelHeader">
      This setting determines which bass notes the guitar plays on each downbeat (“boom”).
    </header>
  {/snippet}

  {#snippet main()}
    <div class="p-3">
      {#if someBoomStrums}
        {@const caption = $bandMenu.guitarSettings.brushiness.closestPreset.caption}
        <div class="pb-3">
          <BandPanelAlert>
            {#if allBoomStrums}
              <div>
                The Pick-Strum setting is making the guitar strum on every downbeat.
                <span class="whitespace-nowrap">No bass notes</span> are being played.
              </div>
              <div class="pt-3">
                To use the options below, {clickTap} Back, then open the Pick-Strum setting,
                <span class="whitespace-nowrap">then lower</span>
                the slider.
              </div>
            {:else}
              <div>
                Pick-Strum Balance is currently set to “{caption}”, so you may not hear as many
                individual bass notes.
              </div>
            {/if}
          </BandPanelAlert>
        </div>
      {/if}

      <div inert={allBoomStrums} class:opacity-30={allBoomStrums}>
        <HorizontalRadioButtons
          options={optionsForMode}
          selectedValue={currentMode}
          on:select={({ detail }) => changeMode(detail)}
        />

        <div class="px-3 pt-2 text-sm italic text-gray-500">
          {#if currentMode == 'r'}
            Only the root note of the chord will be played on each “boom”, with no variation.
          {:else if currentMode == 'a'}
            Bass notes will follow the classic country root‑fifth alternating bass note pattern.
          {:else if currentMode == 'w'}
            Bass notes will “walk around”, mostly hitting the 1, 3, 5, and 6 tones of the chord.
          {/if}
        </div>

        <div class="pb-2"></div>

        {#if currentMode == 'a' || (currentMode == 'r' && $bandMenu.timeSignature == '3/4')}
          <div transition:slideFade>
            <div class="pt-4">
              <PanelSettingSection
                title="Use leading notes"
                helpText="Leading notes are bass note that the guitar plays just before a chord change which “lead your ear” to the next chord."
              >
                <div class="flex items-center gap-5 px-4 py-2">
                  <div class="text-center text-muted-500">Never</div>
                  <div class="flex-1">
                    <PrettySlider
                      min={0}
                      max={4}
                      step={1}
                      value={4 * setting.leadingNoteFrequency}
                      thumbDiameter={1.5}
                      tickHeight={0.175}
                      tickCount={5}
                      on:change={({ detail }) =>
                        $bandMenu.guitar.setSetting(
                          'bassNotes',
                          setting.withLeadingNoteFrequency(detail / 4)
                        )}
                    />
                  </div>
                  <div class="text-center text-muted-500">Often</div>
                </div>
              </PanelSettingSection>
              {#if $bandMenu.guitarSettings.bassRuns.eagerness > 0.5 && $bandMenu.guitarSettings.bassRuns.eagerness + setting.leadingNoteFrequency > 1}
                <div class="px-4 pt-2 text-sm italic text-gray-500" transition:slideFade>
                  Bass notes take precidence over leading notes. Since your bass runs setting is
                  turned up, you may not hear as many leading notes.
                </div>
              {/if}
              {#if $bandMenu.guitarSettings.bassRuns.majorFeel == 'b' && $bandMenu.guitarSettings.bassRuns.eagerness > 0 && setting.leadingNoteFrequency > 0}
                <div class="px-4 pt-2 text-sm italic text-gray-500" transition:slideFade>
                  You’ve selected “bluesy” bass runs. Leading notes tend to sound more “major” than
                  you might want in a bluesy song, though not always.
                </div>
              {/if}
            </div>
          </div>
        {:else if currentMode == 'w'}
          <div transition:slideFade>
            <div class="px-4 text-center text-sm text-gray-500">
              Leading bass notes will be incorporated into walking bass lines whenever possible.
            </div>
          </div>
        {/if}
      </div>
    </div>
  {/snippet}
</PanelModalLayout>
