import { getLocalStorageSafe, setLocalStorageSafe } from '@/utilities/safe-local-storage';

const showSidebar = new ReactiveVar();

Meteor.startup(() => {
  showSidebar.set(getLocalStorageSafe('strum_machine_sidebar_hidden') != 1);
});

const bigEnough = new ReactiveVar();
const activeMenu = new ReactiveVar();

var refreshStatus = function () {
  const wasBigEnough = bigEnough.get();
  const tallEnough = window.innerHeight >= 480;
  const wideEnough = window.innerWidth >= 620;
  const mobileKeyboardVisible = Meteor.isCordova && window.Keyboard?.isVisible;
  bigEnough.set(wideEnough && (tallEnough || mobileKeyboardVisible));
  if (bigEnough.get() != wasBigEnough) {
    activeMenu.set(null);
  }
};

$(window).on('resize', _.debounce(refreshStatus, 200));
$(window).on('orientationchange', _.debounce(refreshStatus, 200));
Meteor.startup(() => refreshStatus());

const SongMenuManager = {
  sidebarVisible(templateName) {
    return showSidebar.get() && bigEnough.get();
  },
  toggleSidebar() {
    const value = !showSidebar.get();
    showSidebar.set(value);
    activeMenu.set(null);
    setLocalStorageSafe('strum_machine_sidebar_hidden', value ? 0 : 1);
    clientModules.analytics.track(`SongMenu.${value ? 'Show' : 'Hide'}Sidebar`);
  },
  activeMenu() {
    return activeMenu.get();
  },
  getTitleFor(menuId) {
    if (menuId == 'editSongMenu') return 'Edit';
    if (menuId == 'editMedleyMenu') return 'Edit';
    if (menuId == 'listsMenu') return 'Lists';
    if (menuId == 'sharingMenu') return 'Share This Song';
    if (menuId == 'medleyMenu') return 'Medley';
    if (menuId == 'countInSettingsMenu') return 'Count-in Settings';
    if (menuId == 'mixerMenu') return 'Audio/Mix Settings';
    if (menuId == 'recorderMenu') return 'Download';
    if (menuId == 'chordDisplaySettingsMenu') return 'Chord Display Settings';
    if (menuId == 'clickTrackMenu') return 'Metronome';
  },
  showMenu(menuId) {
    // this just gets called internally
    activeMenu.set(menuId);
  },
  toggleMenu(menuId) {
    // this gets called by click handler
    if (menuId == activeMenu.get()) menuId = null;
    if (menuId) {
      activeMenu.set(null);
      Tracker.afterFlush(() => activeMenu.set(menuId));
      clientModules.analytics.track(
        `SongMenu.Open${menuId.charAt(0).toUpperCase()}${menuId.substring(1)}`
      );
    } else {
      activeMenu.set(menuId);
    }
  },
  mobileMenu() {
    return !bigEnough.get();
  },
};

export default SongMenuManager;
