import * as Sentry from '@sentry/browser';
import Dexie from 'dexie';
import { Meteor } from 'meteor/meteor';
import type { Mongo } from 'meteor/mongo';
import { ReactiveVar } from 'meteor/reactive-var';
import { Tracker } from 'meteor/tracker';
import _ from 'underscore';
import { PendingCounter } from '@/lib/PendingCounter';
import type { GroundedDocumentCompressor } from '@/local-db/GroundedCollection';
import { GroundedCollection } from '@/local-db/GroundedCollection';
import waitUntilReactive from '@/utilities/waitUntilReactive';

interface OfflineSyncProperties {
  unsynced?: boolean | string;
  createdAt: Date;
  updatedAt: Date;
}

type StorableType = { _id: string } & OfflineSyncProperties;

export abstract class OfflineMusicStorageBackend<T extends StorableType> {
  groundedIndex: GroundedCollection<T>;

  compressor: GroundedDocumentCompressor<T> = {
    compress: (value: T) => value,
    decompress: (value) => value as T,
  };

  fullDataStorage: Dexie.Table;

  protected recordsToInsert: GroundedCollection<T>;
  protected recordsToUpdate: GroundedCollection<T>;
  protected recordsToRemove: GroundedCollection<{ _id: string }>;

  constructor({
    compressor,
    indexDBName,
    fullDataDBName,
    insertionsDBName,
    updatesDBname,
    removalsDBName,
  }: {
    compressor?: GroundedDocumentCompressor<T>;
    indexDBName: string;
    fullDataDBName: string;
    insertionsDBName: string;
    updatesDBname: string;
    removalsDBName: string;
  }) {
    if (compressor) this.compressor = compressor;

    this.groundedIndex = new GroundedCollection<T>(indexDBName, { compressor: compressor });
    this.recordsToInsert = new GroundedCollection<T>(insertionsDBName, {
      compressor: compressor,
    });
    this.recordsToUpdate = new GroundedCollection<T>(updatesDBname, { compressor: compressor });
    this.recordsToRemove = new GroundedCollection<{ _id: string }>(removalsDBName);

    Dexie.debug = 'dexie';
    const dexie = new Dexie(fullDataDBName);
    dexie.version(1).stores({ keyvaluepairs: '' /* outbound primary key */ });
    this.fullDataStorage = dexie.table('keyvaluepairs');
  }

  async groundWithLiveCollections(...collections: Mongo.Collection<T>[]): Promise<void> {
    await this.groundedIndex.waitUntilLoaded();
    collections.forEach((collection) => {
      const docs = collection.find().fetch();
      this.groundedIndex.saveBulkDocumentsToStorage(docs);
      this.fullDataUpdateQueue.push(...docs);
      this.fullDataQueuesNeedProcessing.set(true);
      this.skipAddInObserverCallbacks = true;
      collection.find().observe(this.observerCallbacks);
      this.skipAddInObserverCallbacks = false;
    });
    this.groundedIndex.keep(...collections.map((c) => c.find()));
    await Promise.all([
      this.recordsToInsert.waitUntilLoaded(),
      this.recordsToUpdate.waitUntilLoaded(),
      this.recordsToRemove.waitUntilLoaded(),
    ]);
    this.initUserChangesProcessingAutorun();
    this.initFullDataQueueProcessingAutorun();
    await waitUntilReactive(() => this.groundedIndex.pendingWrites.isZero());
    this._offlineReady.set(true);
  }

  private _offlineReady = new ReactiveVar(false);
  offlineReady(): boolean {
    return this._offlineReady.get();
  }

  // pendingOperations = new PendingCounterAggregator();

  pendingChanges(): number {
    return (
      this.recordsToInsert.find().count() +
      this.recordsToUpdate.find().count() +
      this.recordsToRemove.find().count()
    );
  }

  private fullDataUpdateQueue: T[] = [];
  private fullDataRemoveQueue: T[] = [];
  private fullDataQueuesNeedProcessing = new ReactiveVar(false);

  private skipAddInObserverCallbacks = true;
  private observerCallbacks = {
    added: this.handleAdded.bind(this),
    changed: this.handleChanged.bind(this),
    removed: this.handleRemoved.bind(this),
  };
  protected handleAdded(doc: T): void {
    this.groundedIndex.saveDocumentToMemory(doc);
    if (!this.skipAddInObserverCallbacks) {
      this.groundedIndex.saveDocumentToStorage(doc);
      this.fullDataUpdateQueue.push(doc);
      this.fullDataQueuesNeedProcessing.set(true);
    }
  }
  protected handleChanged(doc: T, _oldDoc: T): void {
    this.groundedIndex.saveDocumentToMemory(doc);
    this.groundedIndex.saveDocumentToStorage(doc);
    this.fullDataUpdateQueue.push(doc);
    this.fullDataQueuesNeedProcessing.set(true);
  }
  protected handleRemoved(doc: T): void {
    this.groundedIndex.removeDocumentFromMemory(doc);
    this.groundedIndex.removeDocumentFromStorage(doc);
    this.fullDataRemoveQueue.push(doc);
    this.fullDataQueuesNeedProcessing.set(true);
  }

  // private _dataGrounded = new ReactiveVar(false);
  // async groundWithLiveData(collection: Mongo.Collection<T>): Promise<undefined> {
  // this.groundedLists.observeSource(collections.lists.find());
  // this.groundedLists.keep(collections.lists.find());
  // reactiveSOILWatcher(lists.find(), songObserveHandlers);
  // this._dataGrounded.set(true);
  // return;
  // }

  async insert(record: Mongo.OptionalId<T>): Promise<string> {
    record.unsynced = 'new';
    record.createdAt = record.updatedAt = new Date();
    const newId = this.recordsToInsert.insert(record);
    const newRecord = this.recordsToInsert.findOne(newId);
    if (!newRecord) {
      throw new Error('Could not find record that was just inserted offline');
    }
    this.groundedIndex.insert(newRecord as Mongo.OptionalId<T>);
    const compressedRecord = _.omit(this.compressor.compress(newRecord), '_id');
    await this.fullDataStorage.add(compressedRecord, newId);
    return newId;
  }

  async update(record: T): Promise<T> {
    record.updatedAt = new Date();
    if (!record.unsynced) record.unsynced = true;
    const unsyncedRecord = this.recordsToInsert.findOne(record._id);
    if (unsyncedRecord) {
      this.recordsToInsert.upsert(record._id, record);
    } else {
      this.recordsToUpdate.upsert(record._id, record);
    }
    this.groundedIndex.upsert(record._id, record);
    const compressedRecord = _.omit(this.compressor.compress(record), '_id');
    await this.fullDataStorage.put(compressedRecord, record._id);
    return unsyncedRecord || record;
  }

  async remove(recordId: string): Promise<void> {
    if (this.recordsToInsert.findOne(recordId)) {
      this.recordsToInsert.remove(recordId);
    } else {
      this.recordsToRemove.upsert(recordId, { _id: recordId });
    }
    this.recordsToUpdate.remove(recordId);
    this.groundedIndex.remove(recordId);
    await this.fullDataStorage.delete(recordId);
  }

  async setFullRecord(recordId: string, record: T): Promise<T> {
    const compressedRecord = _.omit(this.compressor.compress(record), '_id');
    return (await this.fullDataStorage.put(compressedRecord, recordId)) as T;
  }

  async removeFullRecord(recordId: string): Promise<void> {
    await this.fullDataStorage.delete(recordId);
  }

  async fetchFullRecord(recordId: string): Promise<T | undefined> {
    const cachedRecord = await this.fullDataStorage.get(recordId);
    if (!cachedRecord) return;
    return {
      ...this.compressor.decompress(cachedRecord as Record<string, any>),
      _id: recordId,
    } as T;
  }

  protected preventSync = new ReactiveVar(false);

  private initUserChangesProcessingAutorun(): void {
    const syncInProgress = new ReactiveVar(false);
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    Tracker.autorun(async () => {
      if (syncInProgress.get()) return;
      if (this.preventSync.get()) return;
      if (!Meteor.status().connected) return; // can't do this offline!
      if (!Meteor.userId()) return; // don't do this if not logged in
      const toInsert = this.recordsToInsert.find().fetch();
      const toUpdate = this.recordsToUpdate.find().fetch();
      const toRemove = this.recordsToRemove.find().fetch();
      if ([toInsert, toUpdate, toRemove].every((array) => array.length === 0)) return;
      syncInProgress.set(true);
      try {
        await Promise.all([
          ...toInsert.map(async (record) => {
            await this.doServerInsert(record);
            this.recordsToInsert.remove(record._id);
          }),
          ...toUpdate.map(async (record) => {
            await this.doServerUpdate(record);
            this.recordsToUpdate.remove(record._id);
          }),
          ...toRemove.map(async (record) => {
            await this.doServerRemove(record);
            this.recordsToRemove.remove(record._id);
          }),
        ]);
        Meteor.setTimeout(() => syncInProgress.set(false), 1000);
      } catch (error) {
        console.error(error);
        Sentry.captureException(error);
        Bert.alert('Error while syncing data to the cloud. Will retry in 20 seconds.');
        Meteor.setTimeout(() => syncInProgress.set(false), 20000);
      }
    });
  }

  protected abstract doServerInsert(record: T): Promise<string>;
  protected abstract doServerUpdate(record: T): Promise<void>;
  protected abstract doServerRemove(record: { _id: string }): Promise<void>;
  protected abstract getRecordsFromServer(recordIds: string[]): Promise<T[]>;

  pendingFullDataWrites = new PendingCounter();

  private initFullDataQueueProcessingAutorun(): void {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    Tracker.autorun(async () => {
      if (!this.fullDataQueuesNeedProcessing.get() || !Meteor.userId()) return;
      this.fullDataQueuesNeedProcessing.set(false);

      // store and clear queues in case they're modified while processing
      const updateQueue = this.fullDataUpdateQueue;
      const removeQueue = this.fullDataRemoveQueue;
      this.fullDataRemoveQueue = [];
      this.fullDataUpdateQueue = [];

      await this.fullDataStorage.bulkDelete(removeQueue.map((record) => record._id));

      const storedRecords = await this.fullDataStorage.bulkGet(
        updateQueue.map((record) => record._id)
      );
      const recordIds = [];
      for (let i = 0; i < updateQueue.length; i++) {
        const recordToCheck = updateQueue[i]!;
        const storedRecord = storedRecords[i];
        if (storedRecord && !recordToCheck.updatedAt) continue; // for the original (2016-2018) songs
        const storedRecordDate = storedRecord?.uAt
          ? new Date(storedRecord.uAt)
          : storedRecord?.updatedAt;
        if (!storedRecordDate || storedRecordDate < recordToCheck.updatedAt)
          recordIds.push(recordToCheck._id);
      }
      if (recordIds.length === 0) return;
      this.pendingFullDataWrites.inc(recordIds.length);
      const records = await this.getRecordsFromServer(recordIds);
      await this.fullDataStorage.bulkPut(
        records.map((record) => _.omit(this.compressor.compress(record), '_id')),
        records.map((record) => record._id)
      );
      this.pendingFullDataWrites.dec(recordIds.length);
    });
  }
}
