import AudioManager from '@/audio/engine/AudioManager';
import { eventTracker } from '@/browser/analytics/eventTracker';
import { Crnt } from '@/Crnt';
import { Chord } from '@/music/Chord';
import { MusicPageScroller } from '@/ui/auto-scroll/MusicPageScroller';
import { controlBarBelow } from '@/ui/controlBarBelow';
import defineTippyDropdown from '@/ui/dropdowns/defineTippyDropdown';
import { UserProfile } from '@/user/UserProfile';

let releaseAutoScrollHold;
defineTippyDropdown(Template.keyButton, {
  id: 'key-button',
  onShow(_tippy, instance) {
    $('.js-global-chooseKeyHelp').hide(); // if it's out there...
    UserProfile.recordMilestone('KEY_OPEN');
    eventTracker.keyMenuOpen();
    releaseAutoScrollHold?.();
    releaseAutoScrollHold = MusicPageScroller.hold().releaseHold;
    AudioManager.setHighlyResponsive(true);
  },
  onHide(_tippy, instance) {
    $('.js-global-chooseKeyHelp').fadeIn(); // if it's out there...
    releaseAutoScrollHold?.();
    AudioManager.setHighlyResponsive(false);
  },
});

Template.keyButton.onRendered(() => {
  const instance = Template.instance();
  /*
  if ( Meteor.userId() && UserProfile.notMilestone('KEY_CHANGE') ) {
    instance.autorun(comp => {
      if ( Crnt.song()?.editMode() || !Meteor.userId() || $('.js-global-songScrollContainer').length === 0 ) return;
      var prereqs = ["PLAY", "BPM_ADJUST"];  // removed BPM_SET
      if ( prereqs.find(code => UserProfile.notMilestone(code)) ) return;
      if ( UserProfile.notMilestone('KEY_CHANGE') ) {
        Template.instance().$('.js-keyHint:hidden').velocity("fadeIn", { duration: 800, delay: 1000 });
      }
      if ( UserProfile.hasMilestone('KEY_CHANGE') ) {
        Template.instance().$('.js-keyHint:visible').velocity("fadeOut", { duration: 600 });
        Template.instance().$('.js-seeHelp:hidden').velocity("slideDown", { delay: 1000, duration: 600 });
        comp.stop();
      }
    });
  }
  */
});

Template.keyButton.helpers({
  buttonText() {
    const song = Crnt.song();
    if (!song?.key()) {
      return 'Choose a key';
    }
    const keyHeard = new Chord(song.keyHeard());
    const keyShown = new Chord(song.keyShown());
    if (keyHeard.toString() != keyShown.toString()) {
      const capo = song.prefs.capo();
      return `Key of <strong>${keyHeard.toHtml()}</strong> (${keyShown.toHtml()} capo ${capo})`;
    } else {
      return `Key of <strong>${keyHeard.toHtml()}</strong>`;
    }
  },
  ariaLabel() {
    const song = Crnt.song();
    if (!song?.key()) {
      return 'Choose a key to begin creating your song';
    }
    const keyHeard = new Chord(song.keyHeard());
    const keyShown = new Chord(song.keyShown());
    if (keyHeard.toString() != keyShown.toString()) {
      const capo = song.prefs.capo();
      return `Key of ${keyHeard.toAria()}, chords displayed with the capo on fret ${capo}`;
    } else {
      return `Key of ${keyHeard.toAria()}`;
    }
  },
  showHints() {
    // return !Crnt.song()?.editMode();
    return false;
  },
  showClose() {
    return Crnt.song()?.sections.reactive();
  },
  song() {
    return Crnt.song();
  },
  showSpecialFeatures() {
    return Meteor.userId() || Meteor.isCordova;
  },
  controlBarBelow() {
    return controlBarBelow();
  },
});

Template.keyButton.events({
  'keydown button': (event) => {
    const noModsExceptMaybeShift = !event.ctrlKey && !event.metaKey && !event.altKey;
    if (Crnt.song()?.sections.length === 0) {
      if (/^[a-g]$/i.test(event.key) && noModsExceptMaybeShift) {
        Crnt.song()?.setKey(event.key.toUpperCase());
        eventTracker.keySet({ key: event.key.toUpperCase(), withKeyboard: true });
        return false;
      }
    }
  },
});
