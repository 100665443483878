import { MasterChannel } from '@/audio/engine/base/MasterChannel';
import { FMBCordovaPlugin } from '@/audio/engine/fmb/FMBCordovaPlugin';

export class FMBMasterChannel extends MasterChannel {
  private _volume = 1;
  set volume(val: number) {
    this._volume = Math.max(0, Math.min(1, val));
    void FMBCordovaPlugin.setMasterVolume({
      volume: this._volume * 4,
    });
  }

  get volume(): number {
    return this._volume;
  }

  private _pitchShift = 0;
  set pitchShift(cents: number) {
    this._pitchShift = typeof cents == 'number' ? Math.max(-100, Math.min(100, cents)) : 0;
    void FMBCordovaPlugin.setMasterPitchShift({
      cents: this._pitchShift,
    });
  }

  get pitchShift(): number {
    return this._pitchShift;
  }

  // TODO: master EQ adjustments
  // TODO: master reverb adjustments
}
