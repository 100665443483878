Template.installPageChromePWASection.onCreated(() => {
  const instance = Template.instance();
  instance.prompting = new ReactiveVar();
});

Template.installPageChromePWASection.events({
  'click .js-triggerChromeInstallPrompt': function (event, instance) {
    event.preventDefault();
    clientModules.pwa.showDeferredChromePrompt();
    instance.prompting.set(true);
    bootbox.alert({
      title: 'Confirm Installation ↗',
      message:
        'Please press “Install” in the prompt above.\n\nThe installation will only take a fraction of a second.',
      buttons: {
        ok: {
          label: 'Cancel',
          className: 'btn-gray',
        },
      },
      callback() {
        instance.prompting.set(false);
      },
    });
  },
});

Template.installPageChromePWASection.helpers({
  serviceWorkerSupportedButNotInstalled() {
    return 'serviceWorker' in navigator && !navigator.serviceWorker.controller; // we don't want this to be reactive!
  },
  prompting() {
    return Template.instance().prompting.get();
  },
  deferredChromePrompt() {
    return clientModules.pwa.deferredChromePrompt() || Template.instance().prompting.get();
  },
  inPWA() {
    return window.matchMedia('(display-mode: standalone)').matches;
  },
});
