<script lang="ts">
  import type { Band } from '@/band/Band';
  import { Crnt } from '@/Crnt';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import { slideFade } from '@/ui/svelte-transitions';
  import UserPreferences from '@/user/UserPreferences';

  let band: Band | undefined;
  trackerEffect(() => (band = Crnt.song()?.band));

  let clickMuteBand: boolean = $state(false);
  trackerEffect(
    () => (clickMuteBand = !!band && band.clickTrack.enabled() && band.clickTrack.mutingBand())
  );
</script>

{#if clickMuteBand}
  <div class="mx-auto max-w-[30rem] pb-4" transition:slideFade>
    <div class="flex items-center rounded-lg border border-yellow-200 bg-yellow-50 px-4 py-2">
      <div>All instruments are currently being muted by the Metronome feature.</div>
      <div>
        <button class="btn" onclick={() => UserPreferences.set('clickMuteBand', false)}>
          Stop muting the band
        </button>
      </div>
    </div>
  </div>
{/if}
