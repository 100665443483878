import * as Sentry from '@sentry/browser';
import { generateChordPickingOptions } from '@/band/instruments/guitar/generateChordPickingOptions';
import type { GuitarChord } from '@/band/instruments/guitar/GuitarChord';
import { GuitarChordLibrary } from '@/band/instruments/guitar/GuitarChordLibrary';
import type { GuitarChordStyle } from '@/band/instruments/guitar/GuitarChordStyle';
import type { GuitarOpenVoicingsSetting } from '@/band/instruments/guitar/settings/GuitarOpenVoicingsSetting';
import type { LinearizedMeasure } from '@/chart/LinearizedMeasure';

export function assignGuitarChords({
  measures,
  openVoicings,
  chordStyle,
}: {
  measures: readonly LinearizedMeasure[];
  openVoicings: GuitarOpenVoicingsSetting;
  chordStyle: GuitarChordStyle;
}) {
  const chordPickingOptions = generateChordPickingOptions({
    key: measures[0]?.keyChord,
    openVoicings,
    chordStyle,
  });

  let lastChosenChord: GuitarChord | undefined;
  for (const beat of measures.flatMap((measure) => measure.beats)) {
    if (beat.chordChanged || !lastChosenChord) {
      try {
        lastChosenChord = GuitarChordLibrary.pickChord(beat.chord, chordPickingOptions);
      } catch (e) {
        const err = new Error(`Couldn't find guitar chord definition for ${beat.chord.toString()}`);
        Sentry.captureException(err);
        console.error(err);
        lastChosenChord = undefined;
      }
    }
    (beat.plans.guitar ??= {}).chord = lastChosenChord;
  }
}
