import type { LinearizedMeasure } from '@/chart/LinearizedMeasure';

export function formMeasureChains(
  measures: readonly LinearizedMeasure[],
  inSameChain: (measureA: LinearizedMeasure, measureB: LinearizedMeasure) => boolean
): { measures: readonly [LinearizedMeasure, ...LinearizedMeasure[]] }[] {
  // Assemble lists of contiguous measures that use the same style
  const measureChains = [];
  let currentChain: { measures: [LinearizedMeasure, ...LinearizedMeasure[]] } | undefined;
  let lastMeasure: LinearizedMeasure | undefined;
  for (let i = 0; i < measures.length; i++) {
    const measure = measures[i] as LinearizedMeasure;
    if (!currentChain || !lastMeasure || !inSameChain(lastMeasure, measure)) {
      currentChain = { measures: [measure] };
      measureChains.push(currentChain);
    } else {
      currentChain.measures.push(measure);
    }
    lastMeasure = measure;
  }
  return measureChains;
}
