import { assertString, assertTruthy } from '@sindresorhus/is';
import { Meteor } from 'meteor/meteor';
import { ReactiveVar } from 'meteor/reactive-var';
import { Tracker } from 'meteor/tracker';
import { AutoModulateState } from '@/auto-tools/AutoModulateState';
import { AutoSpeedupState } from '@/auto-tools/AutoSpeedupState';
import type { Band } from '@/band/Band';
import { eventTracker } from '@/browser/analytics/eventTracker';
import { LinearizedSong } from '@/chart/LinearizedSong';
import { SectionArray } from '@/chart/SectionArray';
import { SelectionEditor } from '@/chart/SelectionEditor';
import type { SongSnapshot } from '@/chart/SongEditHistory';
import { SongEditHistory } from '@/chart/SongEditHistory';
import { SongPrefs } from '@/chart/SongPrefs';
import { SongSelection } from '@/chart/SongSelection';
import type { SUARecord } from '@/collections/SUACollection';
import { Crnt } from '@/Crnt';
import { HearAsYouEdit } from '@/editor/HearAsYouEdit';
import type { Medley } from '@/medleys/Medley';
import { MedleySongPrefs } from '@/medleys/MedleySongPrefs';
import { tpmToBpm } from '@/music/bpm-tpm-conversion';
import { Chord } from '@/music/Chord';
import { migrateOldKeyStyle } from '@/music/migrateOldKeyStyle';
import { UserProfile } from '@/user/UserProfile';

export class Song {
  readonly prefs = new SongPrefs(this);
  readonly medleyPrefs?: MedleySongPrefs;

  readonly state = {
    autoSpeedupState: new AutoSpeedupState(),
    autoModulateState: new AutoModulateState(),
  };

  readonly sections = new SectionArray(this);

  readonly dragSelection = new SongSelection(this, { constrainToOneSectionInEditMode: true });

  readonly loop = new SongSelection(this);
  loopJustSet = false;

  readonly focus = new SongSelection(this);
  focusJustSet = false;

  linearized: LinearizedSong;
  private readonly _linearizedDep = new Tracker.Dependency();
  relinearize(): void {
    this.linearized = new LinearizedSong({
      song: this,
      loop: this._editMode ? this.focus : this.loop,
    });
    this._sectionsToSkip = [];
    this._lastHurrah = false;
    this._linearizedDep.changed();
  }
  reactiveLinearized(): LinearizedSong {
    this._linearizedDep.depend();
    return this.linearized;
  }

  //#region === Loading/Saving ===

  constructor({
    songData,
    songPrefs,
    editMode,
    useAsTemplate,
    medley,
  }: {
    songData?: SerializedSong;
    songPrefs?: Partial<SUARecord>;
    editMode?: boolean;
    useAsTemplate?: boolean;
    medley?: Medley;
  } = {}) {
    this._editMode = !!editMode;

    if (songData) {
      this._idInternal.set(useAsTemplate ? undefined : songData._id);

      const userProfile = Meteor.user()?.profile;

      const name = songData.name || '';
      this._name.set(
        useAsTemplate
          ? `${name.replace(/ +\[.+\]$/, '')} [${
              userProfile ? `${userProfile.firstName}'s` : 'custom'
            } version]`
          : name
      );

      this._userId.set(useAsTemplate ? Meteor.userId() || undefined : songData.userId);
      this._userFirst.set(useAsTemplate ? userProfile?.firstName || undefined : songData.userFirst);
      this._userLast.set(useAsTemplate ? userProfile?.lastName || undefined : songData.userLast);

      this._key.set(migrateOldKeyStyle(songData.key ?? 'C'));
      this._timeSignature.set(songData.timeSignature || '4/4');
      this._tpm.set(songData.tpm);

      this._aboutText.set(songData.notes);
      this._createdAt.set(songData.createdAt);
      this.updatedAt = songData.updatedAt;
      this.free = !!songData.free; // for demo in mobile apps

      // load preferences here since sections.load uses key in updateMeta()
      if (medley) {
        this.medleyPrefs = new MedleySongPrefs(this, medley);
      } else {
        this.prefs.load(songPrefs, songData);
      }

      this.sections.load(songData.sections);

      this.recalculateMaxCPL();

      if (this._editMode) {
        this.focus.setToSingleCell(this.sections[0]?.cells[0]);
      }
    } else {
      this._userId.set(Meteor.userId() || undefined);
      this.prefs.band.reset();
    }

    this.setupSelectionListeners();
    this.setupAutoToolListeners();

    this.linearized = new LinearizedSong({
      song: this,
      loop: this._editMode ? this.focus : this.loop,
    });
  }

  private setupAutoToolListeners(): void {
    this.prefs.autoSpeedup.addListener('enabled', () => {
      this.state.autoSpeedupState.setCurrentRep(0);
    });
    this.prefs.autoSpeedup.addListener('changedSettings', (settings: { reps?: number }) => {
      if (settings.reps) {
        this.state.autoSpeedupState.setCurrentRep(0);
      }
    });
  }

  private setupSelectionListeners(): void {
    let lastFocusCellCount = 1;
    this.focus.addListener('set', (focus: SongSelection) => {
      this.sections.fillInMissingChords();
      this.sections.removeImpliedFlags();
      this.sections.updateMeta();
      const newFocusCellCount = focus.cells().length;
      if (lastFocusCellCount > 1 || newFocusCellCount > 1) this.relinearize();
      lastFocusCellCount = newFocusCellCount;
      if (focus.exists()) {
        this.focusJustSet = true;
        Meteor.setTimeout(() => (this.focusJustSet = false), 200);
      }
    });

    this.loop.addListener('set', (loop: SongSelection) => {
      UserProfile.recordMilestone(loop.exists() ? 'LOOP_SET' : 'LOOP_CANCEL');
      (loop.exists() ? eventTracker.loopSet : eventTracker.loopClear)();
    });

    this.loop.addListener('set', (loop: SongSelection) => {
      if (loop.exists()) {
        this.loopJustSet = true;
        Meteor.setTimeout(() => (this.loopJustSet = false), 200);
      }
    });

    this.loop.addListener('set', () => this.relinearize());
  }

  serialize({ preserveIds = false, duplicating = false, saving = false } = {}): SerializedSong {
    const key = this.key();
    assertString(key, 'key for serializing song');
    const serializedBandSettings = this.band.serializeSettings();
    const tpm = this.tpm();
    const preset = this.band.preset();
    const serializedSong: SerializedSong = {
      _id: this.id(),
      name: this.name(),
      tpm: tpm,
      key: key,
      timeSignature: this.timeSignature(),
      sections: this.sections.serialize({ preserveIds }),
      band: Object.keys(serializedBandSettings).length > 0 ? serializedBandSettings : undefined,
      presetId: preset?.default || preset?.settingsFromAuthor ? undefined : preset?.id,
      userId: this.userId(),
      userFirst: this.userFirst(),
      userLast: this.userLast(),
      notes: this.aboutText() || undefined,
      createdAt: this.createdAt(),
      // updatedAt : this.updatedAt,
    };
    if (duplicating) {
      serializedSong._id = undefined;
      serializedSong.createdAt = undefined;
      serializedSong.updatedAt = undefined;
      serializedSong.userId = Meteor.userId() || undefined;
    }
    if (saving) {
      serializedSong.name ||= 'Untitled';
    }
    return structuredClone(serializedSong);
  }

  //#endregion ^ Loading/Saving

  //#region === Editing ===

  private _editMode = false;
  editMode = (): boolean => this._editMode;

  private _focusEditor = new SelectionEditor(this.focus);
  withFocused(): SelectionEditor {
    return this._focusEditor;
  }

  private _dirty = new ReactiveVar(false);
  dirty(): boolean {
    return this._dirty.get();
  }

  processChanges(): void {
    this._dirty.set(true);
    this.sections.updateMeta();
    this.recalculateMaxCPL();
    this.relinearize();
  }

  //#endregion ^ Editing

  //#region === History/Snapshots ===

  history = new SongEditHistory(this);

  getSnapshotData(): SongSnapshot | undefined {
    const key = this.key();
    if (!key) return;
    return {
      key,
      timeSignature: this.timeSignature() || '4/4',
      sections: this.sections.serialize({ preserveIds: true }),
      focus: this.focus.bounds(),
    };
  }

  loadSnapshot(snapshot: SongSnapshot): void {
    this._key.set(snapshot.key);
    this._timeSignature.set(snapshot.timeSignature);
    this.sections.load(snapshot.sections);

    // need small delay before setting focus so that Svelte can update chart
    setTimeout(() => {
      this.focus.setToBounds(snapshot.focus);
    }, 50);

    this.processChanges();
  }

  //#endregion ^ History

  //#region === Basic properties ===

  private _idInternal = new ReactiveVar<string | undefined>(undefined);
  id(): string | undefined {
    return this._idInternal.get();
  }
  get _id(): string | undefined {
    return this._idInternal.get(); // for Blaze interop
  }

  private _userId = new ReactiveVar<string | undefined>(undefined);
  userId(): string | undefined {
    return this._userId.get();
  }
  private _userFirst = new ReactiveVar<string | undefined>(undefined);
  userFirst(): string | undefined {
    return this._userFirst.get();
  }
  private _userLast = new ReactiveVar<string | undefined>(undefined);
  userLast(): string | undefined {
    return this._userLast.get();
  }

  private _createdAt = new ReactiveVar<Date | undefined>(undefined);
  setCreatedAt(date?: Date): void {
    this._createdAt.set(date);
    this._dirty.set(true);
  }
  createdAt(): Date | undefined {
    return this._createdAt.get();
  }

  // Adding this for medleys to use
  updatedAt?: Date;

  private _tpm = new ReactiveVar<number | undefined>(undefined);
  tpm(): number {
    return (
      this.prefs.tpm() ||
      this._tpm.get() ||
      {
        '3/4': 160,
        '4/4': 200,
        '6/8': 300,
        '9/8': 300,
      }[this.timeSignature()]
    );
  }

  bpm(): number {
    return tpmToBpm({ tpm: this.tpm(), timeSignature: this.timeSignature() });
  }

  get band(): Band {
    return this.medleyPrefs?.band || this.prefs.band;
  }
  // Ideas:
  // originalUserId?: string;
  // basedOnSongId?: string;

  //#endregion ^ Basic props

  //#region === Name ===

  private _name = new ReactiveVar('');
  name(): string {
    return this._name.get() || '';
  }
  setName(name = ''): void {
    this._name.set(/^ii/.test(name) ? name : name.slice(0, 1).toUpperCase() + name.slice(1));
    this._dirty.set(true);
  }

  //#endregion ^ Name

  //#region === About ===

  private _aboutText = new ReactiveVar<string | undefined>(undefined);
  aboutText(): string | undefined {
    return this._aboutText.get();
  }
  setAboutText(text: string): void {
    this._aboutText.set(text); // don't trim text here; only on saving
    this._dirty.set(true);
  }

  //#endregion ^ About

  //#region === Key ===

  private _key = new ReactiveVar<Key | undefined>(undefined);
  /**
   * @returns The *original* key of the song; what the chart is written in.
   */
  key(): Key | undefined {
    return this._key.get();
  }
  keyHeard(): Key | undefined {
    if (this.editMode()) {
      return this.key();
    } else {
      return this.medleyPrefs?.key() || this.prefs.key() || this.key();
    }
  }

  keyShown(): Key | undefined {
    if (this.editMode()) {
      return this.key();
    } else {
      const key = this.medleyPrefs?.key() || this.prefs.key() || this.key();
      if (!key) return;
      const capo = this.prefs.capo() || 0;
      return new Chord(key, key).shift(-capo).toKeyString();
    }
  }

  setKey(newKey: Key | Chord, { redefineChartKey }: { redefineChartKey?: boolean } = {}): void {
    if (newKey instanceof Chord) newKey = newKey.toKeyString();

    if (this.editMode()) {
      if (this.sections.length === 0) {
        this.setKeyAndInitializeChart(newKey);
      } else if (this.sections.length == 1 && this.sections[0]?.cells.length === 1) {
        this.setKeyOfSingleChordChart(newKey);
      } else if (redefineChartKey) {
        this.redefineKeyOfChart(newKey);
      } else {
        this.transposeChart(newKey);
      }
    } else {
      (this.medleyPrefs || this.prefs).setKey(newKey);
    }
  }

  private setKeyOfSingleChordChart(key: Key): void {
    const cell = this.sections[0]?.cells[0];
    assertTruthy(cell);
    cell.chord = new Chord(key);
    this._key.set(key);
    this.sections.updateMeta();
    this.relinearize();
  }

  private setKeyAndInitializeChart(key: Key): void {
    this._key.set(key);
    this.sections.append(); // calls processChanges()
    HearAsYouEdit.trigger();
  }

  private redefineKeyOfChart(key: Key): void {
    this.history.takeSnapshot();
    this._key.set(key);
    this._dirty.set(true);
  }

  private transposeChart(key: Key): void {
    this.history.takeSnapshot();
    const oldKey = this.key();
    if (!oldKey) return;
    this.sections.forEach((section) => {
      section.cells.forEach((cell) => {
        const beats = cell.subdividedBeats || [cell];
        beats.forEach((beat) => {
          const effect = beat.effect; // this gets reset when setting .chord
          if (beat.chord) {
            beat.chord = beat.chord.inKey(oldKey).transpose(key);
          }
          if (effect) beat.effect = effect;
        });
      });
    });
    this._key.set(key);
    this._dirty.set(true);
  }

  //#endregion ^ Key

  //#region === Time signatures ===

  private _timeSignature = new ReactiveVar<TimeSignature>('4/4');
  timeSignature(): TimeSignature {
    return this._timeSignature.get() || '4/4';
  }
  setTimeSignature(sig: TimeSignature): void {
    this.history.takeSnapshot();
    this._timeSignature.set(sig);
    if (/[69]\/8/.test(sig)) {
      this.sections.forEach((s) => s.cells.forEach((m) => (m.split = false)));
    }
    this.band.reset();
    this.processChanges();
  }

  get beatsPerMeasure(): number {
    const timeSig = Tracker.nonreactive(() => this.timeSignature());
    return /^(3|6|9)\//.test(timeSig) ? 3 : 2;
  }

  //#endregion ^ Time signatures

  //#region === Section skipping ===

  private _sectionsToSkip?: number[];
  private _lastHurrah?: boolean;
  recalculateSectionsToSkip({
    currentRound,
    currentSection,
    forceLastTime,
  }: {
    currentRound?: number;
    currentSection?: number;
    forceLastTime?: boolean;
  }): void {
    const round = currentRound ?? 0;

    const sectionsToSkip: number[] = [];
    const sections = this.sections;
    const firstRep = round === 0;
    const lastRep = round >= (this.maxRound() ?? 9999) || !!forceLastTime;
    const lastHurrah = lastRep && Crnt.songs[Crnt.songs.length - 1] == this;

    // TODO: apply endings to linearization

    if (sections && !this.loop.exists()) {
      sections.forEach((section, sectionIndex) => {
        if (
          currentSection === sectionIndex &&
          !(section.type == 'i' && this.medleyPrefs?.skippingIntros()) &&
          !(section.type == 'o' && this.medleyPrefs?.skippingOutros())
        )
          return;
        if (section.type == 'i' && (!firstRep || this.medleyPrefs?.skippingIntros()))
          sectionsToSkip.push(sectionIndex);
        if (section.type == 'sf' && !this.editMode() && firstRep) sectionsToSkip.push(sectionIndex);
        if (section.type == 'sl' && !this.editMode() && lastRep) sectionsToSkip.push(sectionIndex);
        if (
          section.type == 'o' &&
          !this.editMode() &&
          (!lastRep || this.medleyPrefs?.skippingOutros())
        )
          sectionsToSkip.push(sectionIndex);
      });
    }

    if (
      sectionsToSkip.join(',') !== this._sectionsToSkip?.join(',') ||
      lastHurrah !== this._lastHurrah
    ) {
      this.linearized.setSkippedSections(sectionsToSkip);
      this.linearized.setLastTimeThrough(lastHurrah);
      this._linearizedDep.changed();
      this._sectionsToSkip = sectionsToSkip;
      this._lastHurrah = lastHurrah;
    }
  }

  maxRound(): number | undefined {
    const afReps =
      (this.prefs.autoFinish.enabled() || Crnt.list()?.autoAdvanceEnabled()) &&
      this.prefs.autoFinish.reps();
    const medleyReps = this.medleyPrefs?.reps();
    return typeof medleyReps == 'number'
      ? medleyReps - 1
      : typeof afReps == 'number'
        ? afReps - 1
        : undefined;
  }

  //#endregion ^ Section skipping

  //#region === CPL ===

  private _cpl = new ReactiveVar<number>(8);
  cpl(): number {
    return this._cpl.get();
  }

  private recalculateMaxCPL() {
    const maxOfSparseArray = (a: number, b: number) => (b ? Math.max(a, b) : a);
    const maxBars = Math.max(
      ...this.sections.map((s) => s.lineLengths.reduce(maxOfSparseArray, 1))
    );
    const cellsPerBar = this.timeSignature() == '9/8' ? 3 : 2;
    const minCPL = this.timeSignature() == '9/8' ? 9 : 8;
    const maxCPL = Math.max(minCPL, maxBars * cellsPerBar);
    this._cpl.set(maxCPL);
  }

  //#endregion ^ CPL

  //#region === Index in medley ===

  private _index = new ReactiveVar<number>(0);
  index(): number {
    return this._index.get();
  }
  setIndex(index: number): void {
    this._index.set(index);
  }

  //#endregion ^ Index

  free = false;
}
