<script lang="ts">
  import { cubicOut } from 'svelte/easing';
  import type { Cell } from '@/chart/Cell.svelte.ts';
  import type { Song } from '@/chart/Song';
  import { renderChordAsHtml } from '@/chart/ui/renderChordAsHtml';
  import { crntPreferences } from '@/client/crntPreferences.svelte';
  import { Crnt } from '@/Crnt';
  import { HearAsYouEdit } from '@/editor/HearAsYouEdit';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import { Chord } from '@/music/Chord';
  import { slideFadeHorizontal } from '@/ui/svelte-transitions';

  let song: Song | undefined = $state();
  trackerEffect(() => (song = Crnt.song()));

  let key: string | undefined = $state();
  trackerEffect(() => (key = song?.key()));

  let songCells: Cell[] | undefined = $state();
  trackerEffect(() => (songCells = song?.sections.reactive().flatMap((section) => section.cells)));

  let focusedCells: Cell[] | undefined = $state();
  trackerEffect(() => (focusedCells = song?.focus.cells()));

  let chordSystem = $derived(crntPreferences().chordSystem);

  interface Props {
    maxWidth?: number; // input
    desiredWidth?: number; // output
  }

  let { maxWidth = 0, desiredWidth = $bindable(0) }: Props = $props();

  const buttonWidthCalculationElements: Record<string, HTMLElement> = $state({});
  const buttonWidthsByChord: Record<string, number | undefined> = {};

  let hovering = $state(false);

  // TODO: This reactive stuff could be optimized, for sure... but it works for now.
  let chordsByFrequency: Chord[] = $state([]);
  $effect(() => {
    if (key && songCells && !hovering) {
      const oneChord = new Chord(key).inKey(key);
      const chordFrequencyMap = songCells.reduce(
        (map, cell) => {
          (cell.subdividedBeats ?? [cell]).forEach((beat) => {
            const chord = beat.chord?.toString();
            if (chord) {
              map.set(chord, (map.get(chord) ?? 0) + 1);
            }
          });
          return map;
        },
        new Map<string, number>([
          [oneChord.toString(), 1],
          [oneChord.shift(5).withNewType('').toString(), 0.5],
          [oneChord.shift(7).withNewType('').toString(), 0.75],
          [oneChord.shift(9).withNewType('m').toString(), 0.25],
        ])
      );
      chordsByFrequency = [...chordFrequencyMap.entries()]
        .sort((a, b) => b[1] - a[1])
        .filter(([_, freq], _i, array) => freq >= 1 || freq >= (array[3]?.[1] ?? 99))
        .map((e) => new Chord(e[0]).inKey(key));
    }
  });

  let quickChords: Chord[] = $derived.by(() => {
    let desiredWidth = -8;
    const culledChords: Chord[] = [];
    for (const chord of chordsByFrequency) {
      const elementWidth: number =
        (buttonWidthsByChord[chord.toString()] ??=
          buttonWidthCalculationElements[chord.toString()]?.getBoundingClientRect().width) ?? 0;
      const addedWidth = 8 + elementWidth;
      if (desiredWidth + addedWidth > maxWidth) {
        break;
      }
      desiredWidth += addedWidth;
      culledChords.push(chord);
    }

    return culledChords.sort((a, b) =>
      a.relativeChroma === b.relativeChroma
        ? a.toString().localeCompare(b.toString())
        : a.relativeChroma - b.relativeChroma
    );
  });

  $effect(() => {
    desiredWidth = quickChords.reduce(
      (acc, chord) => acc + 8 + (buttonWidthsByChord[chord.toString()] ?? 48),
      -8
    );
  });

  let focusedQuickChords: Chord[] = $derived(
    quickChords.filter((chord) =>
      focusedCells?.some((cell) =>
        (cell.subdividedBeats ?? [cell]).some((c) => c.chord?.equals(chord))
      )
    )
  );
</script>

<div
  class="flex w-full justify-start"
  onpointerenter={() => (hovering = true)}
  onpointerleave={() => (hovering = false)}
>
  {#each quickChords as chord (chord.toString())}
    <button
      class="notranslate _quickChordButtonDimensions ml-2 flex-shrink-0 snap-start whitespace-nowrap rounded border border-muted-200 font-medium text-primary-600 transition-all first:ml-0 aria-pressed:bg-primary-50 dsktp:hover:border-muted-300 dsktp:hover:bg-primary-100 dsktp:hover:text-primary-700"
      aria-label={chord.toAria()}
      aria-pressed={focusedQuickChords.length == 1 && focusedQuickChords[0]?.equals(chord)}
      transition:slideFadeHorizontal={{ duration: 250, easing: cubicOut }}
      onclick={() => {
        song?.withFocused().setChord(chord);
        HearAsYouEdit.trigger();
      }}
    >
      {@html renderChordAsHtml(chord, { system: chordSystem })}
    </button>
  {/each}

  <div class="pointer-events-none absolute overflow-hidden opacity-0" style="clip:rect(0,0,0,0)">
    {#each chordsByFrequency as chord (chord.toString())}
      <button
        class="_quickChordButtonDimensions whitespace-nowrap border font-medium opacity-0"
        tabindex="-1"
        aria-hidden="true"
        bind:this={buttonWidthCalculationElements[chord.toString()]}
      >
        {@html renderChordAsHtml(chord, { system: chordSystem })}
      </button>
    {/each}
  </div>
</div>

<style>
  ._quickChordButtonDimensions {
    min-width: 2.0625em;
    min-height: 2.0625em;
    padding: 0.125rem 0.5rem;
    font-size: 1rem;
    border-width: 1px;
  }
</style>
