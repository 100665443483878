import { isFunction, isPlainObject } from '@sindresorhus/is';
import { Blaze } from 'meteor/blaze';
import type { Component, SvelteComponent } from 'svelte';
import { mount, unmount } from 'svelte';
import { asClassComponent } from 'svelte/legacy';
import { clog } from '@/browser/clog';

declare class DOMRange {
  constructor(nodeAndRangeArray?: Array<Node | DOMRange>);
  parentElement: HTMLElement | null;
  parentRange: DOMRange | null;
  view?: Blaze.View;
  firstNode(): HTMLElement; // was Node
  lastNode(): HTMLElement; // was Node
}

declare module 'meteor/blaze' {
  interface Blaze {
    _DOMRange: typeof DOMRange;
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace Blaze {
    interface View {
      _templateInstance?: TemplateInstance;
      templateContentBlock?: TemplateInstance | null;
      originalParentView?: View;
      _domrange: DOMRange;
    }
  }
}

/**
 * Blaze template that embeds a Svelte component.
 */

const svelteComponentTemplate = new Blaze.Template('Template.SvelteComponent', () => []);

//@ts-expect-error This is how it's supposed to be done
Blaze.Template.SvelteComponent = svelteComponentTemplate;

svelteComponentTemplate.onRendered(function onRendered() {
  const instance = this as Omit<
    Blaze.TemplateInstance<Component | { this: Component; [key: string]: unknown }>,
    'view'
  > & {
    view: Blaze.View;
    component: Record<string, unknown>;
  };
  const props = $state({}) as Record<string, unknown>;
  const options = {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    target: instance.view._domrange.parentElement!,
    anchor: instance.view._domrange.lastNode(),
    props,
  };

  if (instance.view.templateContentBlock) {
    options.props.$$slots = { default: [create_default_slot] };
    options.props.$$scope = { ctx: [instance.view.templateContentBlock] };
  }

  const component = isFunction(instance.data)
    ? // {{> SvelteComponent SomeComponent}}
      instance.data
    : // {{> SvelteComponent this=SomeComponent prop1="value" prop2="value"}}
      instance.data.this;

  if (isPlainObject(instance.data)) {
    Object.assign(options.props, withoutThis(instance.data));
  }

  instance.component = mount(component, options);

  if (isPlainObject(instance.data)) {
    instance.autorun(() => {
      const data = Blaze.getData(instance.view);
      Object.assign(options.props, withoutThis(data));
    });
  }
});

const withoutThis = (obj: object) =>
  Object.fromEntries(Object.entries(obj).filter(([key]) => key !== 'this'));

svelteComponentTemplate.onDestroyed(function onDestroyed() {
  const instance = this as Blaze.TemplateInstance & {
    component: Record<string, unknown>;
  };
  if (instance.component) unmount(instance.component).catch(clog.error);
});

function create_default_slot(ctx: [Blaze.Template]) {
  let blazeView: Blaze.View;
  return {
    c() {},
    m(target: HTMLElement, anchor: HTMLElement) {
      const templ = ctx[0];
      blazeView = Blaze.render(templ.constructView(), target, anchor);
    },
    // p(ctx) {
    //   // Add update logic if needed - this is a new recommended addition for Svelte 5
    // },
    d() {
      if (blazeView) Blaze.remove(blazeView);
    },
  };
}
