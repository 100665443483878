<script lang="ts">
  import type { InstrumentCore } from '@/band/instruments/InstrumentCore';
  import type { InstrumentSetting } from '@/band/instruments/InstrumentSetting';
  import BandRadioRow from '@/band/ui/components/BandRadioRow.svelte';
  import RhythmPatternVisualization from '@/band/ui/components/RhythmPatternVisualization.svelte';

  interface Props {
    name: string;
    settings: Record<string, InstrumentSetting<unknown>>;
    core: InstrumentCore;
    timeSignature: TimeSignature;
    customStyle?: boolean;
    value: string;
    doubleClickHandler?: () => void;
  }

  let {
    name,
    settings,
    core,
    timeSignature,
    customStyle = false,
    value,
    doubleClickHandler,
  }: Props = $props();

  let pattern = $derived(core.generateViz(settings));
  let longPattern = $derived((pattern?.length ?? 0) > 10);
</script>

<BandRadioRow {value} yellowBg={customStyle} {doubleClickHandler}>
  {#snippet children({ checked })}
    <div
      class="flex w-full {longPattern ? 'flex-col-reverse items-start' : 'items-center'}"
      class:selected={checked}
    >
      {#if pattern.length > 0}
        <div style="flex: 0 0 auto;" class="pr-2.5">
          <div
            class="overflow-hidden rounded-[6px] border {checked
              ? 'border-muted-300'
              : 'border-panelWellBorder'}"
          >
            <RhythmPatternVisualization
              actions={pattern}
              {timeSignature}
              highlightCurrentBeat={checked}
              scale={1}
              showBeatNumbers={false}
              selected={checked}
            />
          </div>
        </div>
      {/if}
      <div class="text-sm font-normal leading-4 {longPattern ? 'mb-1 w-full' : ''}">
        {name.replace(/-/g, '‑')}
      </div>
    </div>
  {/snippet}
</BandRadioRow>
