<script lang="ts">
  import { onMount } from 'svelte';
  import type { Band } from '@/band/Band';
  import { staticInstruments } from '@/band/staticInstruments';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';

  interface Props {
    instId: InstrumentId;
    band?: Band | undefined;
    global?: boolean;
  }

  let { instId, band = undefined, global = false }: Props = $props();

  let bandInstruments = $derived(band?.instruments ?? staticInstruments);

  let pan: number = $state(1);
  trackerEffect(
    () =>
      (pan =
        (!global ? bandInstruments[instId].customPan() : bandInstruments[instId].globalPan()) ??
        pan)
  );

  let biasL: number = $state(0),
    biasR: number = $state(0);
  trackerEffect(() => ([biasL, biasR] = [0.5 - pan * 0.5, 0.5 + pan * 0.5]));

  let panRange: HTMLInputElement;
  onMount(() => (panRange.value = pan.toString()));

  function setPan(value: number) {
    if (!global) {
      bandInstruments[instId].setCustomPan(value);
    } else {
      bandInstruments[instId].setGlobalPan(value);
    }
  }

  function rangeChangeHandler() {
    const value = +panRange.value;
    const snappedValue = +value - 0.1 * Math.sign(+value);
    setPan(snappedValue);
    if (snappedValue === 0) panRange.value = '0';
  }

  function rangeKeyDownHandler(e: KeyboardEvent) {
    const down = e.key == 'ArrowLeft' || e.key == 'ArrowDown';
    const up = e.key == 'ArrowRight' || e.key == 'ArrowUp';
    if ((up || down) && pan == 0) {
      panRange.value = up ? '0.2' : '-0.2';
      rangeChangeHandler();
      e.preventDefault();
    }
  }
</script>

<div class="pan-wrapper">
  <span style="color: hsl({!global ? 110 : 206}, {50 * biasL}%, {100 - 60 * biasL}%)">L</span>
  <input
    type="range"
    min={-1.1}
    max={1.1}
    aria-valuemin={-100}
    aria-valuemax={100}
    aria-valuenow={pan * 100}
    aria-valuetext={pan
      ? `${(Math.abs(pan) * 100).toFixed(0)} ${pan > 0 ? 'right' : 'left'}`
      : 'center'}
    bind:this={panRange}
    value={pan + 0.1 * Math.sign(pan)}
    step={0.1}
    style="--val: {pan + 0.1 * Math.sign(pan)}; --min: -1.1; --max: 1.1; --track-color: {!global
      ? 'hsl(206, 20%, 85%)'
      : 'hsl(110, 20%, 85%)'}"
    class="styled-range"
    aria-label="{instId} pan"
    oninput={rangeChangeHandler}
    onchange={rangeChangeHandler}
    onkeydown={rangeKeyDownHandler}
    ondblclick={() => setPan(0)}
  />
  <span style="color: hsl({!global ? 110 : 206}, {50 * biasR}%, {100 - 60 * biasR}%)">R</span>
</div>

<style>
  .pan-wrapper {
    display: flex;
    align-items: center;
    background: linear-gradient(
      90deg,
      transparent 0%,
      transparent calc(50% - 1px),
      hsl(206, 10%, 80%) 50%,
      transparent calc(50% + 1px),
      transparent 100%
    );

    > span {
      display: inline-block;
      color: hsl(206, 20%, 40%);
      font-weight: 500;
      text-align: center;
      width: 0.7em;
    }
  }

  input.styled-range {
    width: 4em;
    margin: 0 0.5rem;
    --track-height: 0.5rem;
    --thumb-height: 1.5rem;
    --thumb-width: 1rem;
    --thumb-border-radius: 2px;
    --track-color: hsl(206, 20%, 85%);
    --fill-color: transparent;
  }
</style>
