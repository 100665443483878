var highestZ = 401;

Template.floatingWindow.onCreated(() => {
  const instance = Template.instance();
  instance.hiding = new ReactiveVar(false);
  instance.render = new ReactiveVar(false);
  instance.zIndex = new ReactiveVar(100);
  instance.autorun(() => {
    if (Template.currentData().shouldBeVisible()) {
      if (!instance.render.get()) {
        Meteor.setTimeout(() => instance.$('.js-floatingWindow').focus(), 50);
      }
      instance.hiding.set(false);
      instance.render.set(true);
      instance.zIndex.set(++highestZ);
    } else {
      instance.hiding.set(true);
      Meteor.setTimeout(() => {
        instance.render.set(false);
        instance.hiding.set(false);
      }, instance.data.fadeOutTime || 250);
    }
  });
  var shouldBeFullScreen = () =>
    window.matchMedia(`(max-width: ${instance.data.moveableMinWidth || '580px'})`).matches;
  instance.fullScreen = new ReactiveVar(shouldBeFullScreen());
  instance.fullScreenChecker = _.throttle(() => instance.fullScreen.set(shouldBeFullScreen()), 500);
  $(window).on('resize', instance.fullScreenChecker);
  $(window).on('orientationchange', instance.fullScreenChecker);
});

Template.floatingWindow.onRendered(() => {
  const instance = Template.instance();
  instance.autorun(() => {
    instance.render.get(); // for reactivity
    instance.zIndex.get(); // for reactivity
    Tracker.afterFlush(() => instance.$('.floatingWindow').css({ zIndex: instance.zIndex.get() }));
  });
  instance.autorun(() => {
    if (instance.fullScreen.get() && instance.find('.floatingWindow')) {
      instance.find('.floatingWindow').style.top = null;
      instance.find('.floatingWindow').style.left = null;
    }
  });

  instance.autorun(() => {
    if (instance.render.get()) {
      Tracker.afterFlush(() => {
        if (instance.find('.js-headerToolbar')) {
          instance.find('.js-headerToolbar')._uihooks = {
            removeElement(node) {
              var $node = $(node);
              $node.addClass('disappear');
              Meteor.setTimeout(() => $node.remove(), 350);
            },
          };
        }
      });
    }
  });
});

Template.floatingWindow.onDestroyed(() => {
  const instance = Template.instance();
  $(window).off('resize', instance.fullScreenChecker);
  $(window).off('orientationchange', instance.fullScreenChecker);
});

Template.floatingWindow.helpers({
  hiding() {
    return Template.instance().hiding.get();
  },
  render() {
    return Template.instance().render.get();
  },
  zIndex() {
    return Template.instance().zIndex.get();
  },
  fullScreen() {
    return Template.instance().fullScreen.get();
  },
});

var _startCoords,
  $windowToMove,
  activeEdge,
  oldLeft,
  oldRight,
  oldWidth,
  currentWidth,
  windowHeight,
  stickToBottom,
  minFromBottom;

Template.floatingWindow.events({
  'mousedown': function (event, instance) {
    if (instance.zIndex.get() < highestZ) instance.zIndex.set(++highestZ);
  },
  'click .js-back': function (event, instance) {
    instance.data.backClickHandler();
  },
  'click .js-search': function (event, instance) {
    instance.data.searchClickHandler();
  },
  'click .js-close, click .js-overlay': function (event, instance) {
    instance.data.closeClickHandler();
  },
  'mousedown/touchstart .js-dragToMove': function (event, instance) {
    if (event.target.tagName.toUpperCase() == 'BUTTON') return;
    if (instance.fullScreen.get()) return;
    $windowToMove = instance.$('.floatingWindow');
    const [eventX, eventY] =
      event.type == 'touchstart'
        ? [
            event.originalEvent.changedTouches[0].clientX,
            event.originalEvent.changedTouches[0].clientY,
          ]
        : [event.pageX, event.pageY];
    _startCoords = {
      x: eventX - $windowToMove.offset().left,
      y: eventY - $windowToMove.offset().top,
    };
    currentWidth = $windowToMove.width();
    windowHeight = window.innerHeight;
    minFromBottom = instance.data.minFromBottom || 70;
    activeEdge = null;
    stickToBottom = instance.data.stickToBottom;
    $windowToMove.addClass('moving');
    document.body.addEventListener('mousemove', handleDragMove, { passive: false });
    document.body.addEventListener('touchmove', handleDragMove, { passive: false });
    $(document.body).on('mouseup touchend touchcancel', handleDragEnd);
  },
  'mousedown/touchstart .js-edge': function (event, instance) {
    if (instance.fullScreen.get()) return;
    $windowToMove = instance.$('.floatingWindow');
    const eventX =
      event.type == 'touchstart' ? event.originalEvent.changedTouches[0].clientX : event.pageX;
    _startCoords = {
      x: eventX - $windowToMove.offset().left,
      // y: eventY - $windowToMove.offset().top
    };
    oldLeft = $windowToMove.offset().left;
    oldWidth = $windowToMove.width();
    windowHeight = window.innerHeight;
    oldRight = oldLeft + oldWidth;
    activeEdge = $(event.target).data('edge');
    $windowToMove.addClass('resizing');
    document.body.addEventListener('mousemove', handleDragMove, { passive: false });
    document.body.addEventListener('touchmove', handleDragMove, { passive: false });
    $(document.body).on('mouseup touchend touchcancel', handleDragEnd);
  },
});

var minWidth = 320;

function handleDragMove(event) {
  const [eventX, eventY] =
    event.type == 'touchmove'
      ? [event.changedTouches[0].clientX, event.changedTouches[0].clientY]
      : [event.pageX, event.pageY];
  if (activeEdge == 'left') {
    const left = Math.min(
      Math.min($(document).width() - minWidth, oldRight - minWidth),
      Math.max(eventX - _startCoords.x, 0)
    );
    $windowToMove.offset({ left }).width(oldRight - left);
  } else if (activeEdge == 'right') {
    $windowToMove.width(
      Math.max(
        minWidth,
        oldWidth - oldLeft + Math.min($(document).width(), eventX - _startCoords.x)
      )
    );
  } else if (activeEdge == 'brCorner') {
    $windowToMove.width(
      Math.max(
        minWidth,
        oldWidth - oldLeft + Math.min($(document).width(), eventX - _startCoords.x)
      )
    );
  } else if (activeEdge == 'blCorner') {
    const left = Math.min(
      Math.min($(document).width() - minWidth, oldRight - minWidth),
      Math.max(eventX - _startCoords.x, 0)
    );
    $windowToMove.offset({ left }).width(oldRight - left);
  } else {
    const left = Math.min($(document).width() - currentWidth, Math.max(eventX - _startCoords.x, 0));
    const top = Math.min(
      $(document).height() - minFromBottom,
      Math.max(eventY - _startCoords.y, 0)
    );
    $windowToMove.offset({ left, top });
    if (stickToBottom) $windowToMove.height(Math.ceil(windowHeight - top));
  }
  window.getSelection().removeAllRanges();
  event.preventDefault();
}

function handleDragEnd(event) {
  document.body.removeEventListener('mousemove', handleDragMove, { passive: false });
  document.body.removeEventListener('touchmove', handleDragMove, { passive: false });
  $(document.body).off('mouseup touchend touchcancel', handleDragEnd);
  $('.floatingWindow').removeClass('moving resizing');
  window.getSelection().removeAllRanges();
}
