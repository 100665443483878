<script lang="ts">
  import { guitarCustomizationUI } from '@/band/instruments/guitar/guitarCustomizationUI';
  import PanelOpenerChevron from '@/band/ui/components/PanelOpenerChevron.svelte';
  import PanelSettingSection from '@/band/ui/components/PanelSettingSection.svelte';
  import PrettySlider from '@/band/ui/components/PrettySlider.svelte';
  import SliderRule from '@/band/ui/components/SliderRule.svelte';
  import PanelModalLayout from '@/band/ui/panels/PanelModalLayout.svelte';
  import SwingPanel from '@/band/ui/panels/SwingPanel.svelte';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import { MultiPanelModalController } from '@/ui/settings/MultiPanelModalController.svelte';

  const modalController = MultiPanelModalController.current();
  const bandMenu = getBandMenuState();

  const min = 0;
  const max = 12;

  let setting = $derived($bandMenu.guitarSettings.dampenedFills);
  let notch = $derived(setting.probability * 12);
  let caption = $derived(guitarCustomizationUI.dampenedFills.getRowStatus({ setting }).text);

  function setToNotch(notch: number) {
    $bandMenu.guitar.setSetting('dampenedFills', setting.withEagerness(notch / 12));
  }

  let swing: ReturnType<typeof $bandMenu.band.swing> = $state() as SwingOption;
  trackerEffect(() => (swing = $bandMenu.band.swing()));
</script>

<PanelModalLayout>
  {#snippet header()}
    <div class="panelHeader">
      Changes how often should the guitar play upstrokes toward the end of the measure.
      (This&nbsp;feature is under construction.)
    </div>
  {/snippet}

  {#snippet main()}
    <div>
      <div class="pb-2 pt-5 text-center text-lg {notch === 0 ? 'text-gray-500' : 'text-gray-700'}">
        {caption}
      </div>

      <div class="mx-6 py-2">
        <PrettySlider
          {min}
          {max}
          value={notch}
          step={1}
          tickCount={max - min + 1}
          thumbDiameter={2.75}
          tickHeight={1.25}
          tickSqueezes={Array.from(
            { length: max - min + 1 },
            (_, i) => 1 - (i + 1) / (max - min + 1)
          )}
          ariaLabel=""
          on:change={({ detail }) => setToNotch(detail)}
        />
      </div>
      <SliderRule lowerLabel="Never" upperLabel="All the time" />

      <div class="px-4 pb-4 pt-4">
        <PanelSettingSection
          title="Upstroke timing"
          helpText="Upstrokes will be played with the specified amount of swing feel."
          whiteBg={false}
        >
          <div style="border-radius: 6px; border: solid 1px var(--gray-150); overflow: hidden;">
            <button
              class="colors-panelOpener relative block w-full py-2.5 pl-3.5 pr-9 text-left group-first:rounded-t-lg group-last:rounded-b-lg"
              onclick={() =>
                modalController.zoomInto({ component: SwingPanel, title: 'Upstroke Swing' })}
            >
              {swing.name}
              <PanelOpenerChevron direction="right" />
            </button>
          </div>
        </PanelSettingSection>
      </div>
    </div>
  {/snippet}
</PanelModalLayout>
