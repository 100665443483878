import { Tracker } from 'meteor/tracker';
import { AutoTool } from '@/auto-tools/AutoTool';
import type { SUARecord } from '@/collections/SUACollection';
import { bpmToTpm, tpmToBpm } from '@/music/bpm-tpm-conversion';

export class AutoSpeedupSettings extends AutoTool {
  protected prefix = 'as';

  init(settings: Partial<SUARecord>): void {
    super.init(settings);

    if (typeof settings['asDeltaT'] === 'number')
      this.settings.set('asDeltaT', this.normalizeTpmDelta(settings['asDeltaT']));
    if (typeof settings['asReps'] == 'number')
      this.settings.set('asReps', this.normalizeReps(settings['asReps']));
    if (typeof settings['asMaxT'] === 'number')
      this.settings.set('asMaxT', this.normalizeMaxTpm(settings['asMaxT']));
  }

  // ============

  bpmDelta(): number {
    return tpmToBpm({ tpm: this.tpmDelta(), timeSignature: this.song.timeSignature() });
  }

  setBpmDelta(value: number | string): void {
    this.setTpmDelta(
      bpmToTpm({ bpm: this.normalizeBpmDelta(value), timeSignature: this.song.timeSignature() })
    );
  }

  adjustBpmDelta(amount: number): void {
    this.setBpmDelta(this.bpmDelta() + amount);
  }

  private normalizeBpmDelta = (value: number | string): number => {
    value = Math.round(Number(value) * 6) / 6; //  divisible by 3 and 2
    if (value === 0 || !Number.isFinite(value)) value = 1;
    return Math.min(Math.max(value, -20), 20);
  };

  // ============

  tpmDelta(): number {
    return (
      (this.settings.get('asDeltaT') as number) ??
      bpmToTpm({ bpm: 1, timeSignature: this.song.timeSignature() })
    );
  }

  setTpmDelta(value: number | string): void {
    const tpm = this.normalizeTpmDelta(value);
    this.setSetting('asDeltaT', tpm);
  }

  adjustTpmDelta(amount: number): void {
    this.setTpmDelta(this.tpmDelta() + amount);
  }

  private normalizeTpmDelta = (value: number | string): number => {
    value = Math.round(Number(value));
    if (value === 0 || !Number.isFinite(value))
      value = bpmToTpm({ bpm: 1, timeSignature: this.song.timeSignature() });
    return Math.min(Math.max(value, -60), 60);
  };

  // ============

  reps(): number {
    return (this.settings.get('asReps') as number) ?? 1;
  }

  setReps(value: number | string): void {
    this.setSetting('asReps', this.normalizeReps(value));
  }

  private normalizeReps = (value: number | string): number => {
    value = Number(value) || 0;
    return Math.min(Math.max(Math.floor(value), 1), 9);
  };

  // ============

  maxTpm(): number | null {
    return (this.settings.get('asMaxT') as number) ?? null;
  }

  setMaxTpm(value: number | string | null): void {
    const tpm = this.normalizeMaxTpm(value);
    this.setSetting('asMaxT', tpm || null);
  }

  ensureMaxTpmIsAtLeast(value: number): void {
    const currentValue = Tracker.nonreactive(() => this.maxTpm());
    if (currentValue && currentValue < value) {
      this.setMaxTpm(value);
    }
  }

  private normalizeMaxTpm = (value: number | string | null): number | null => {
    value = Number(value) || 0;
    const { min, max } = this.song.prefs.tpmBounds();
    return value ? Math.min(Math.max(Math.floor(value), min), max) : null;
  };

  // ============
  // Convenience methods:

  maxBpm(): number | null {
    const maxTpm = this.maxTpm();
    if (typeof maxTpm !== 'number') return null;
    return tpmToBpm({ tpm: maxTpm, timeSignature: this.song.timeSignature() });
  }

  setMaxBpm(value: number | string | null): void {
    value = this.normalizeMaxBpm(value);
    this.setMaxTpm(
      value ? bpmToTpm({ bpm: value, timeSignature: this.song.timeSignature() }) : null
    );
  }

  private normalizeMaxBpm = (value: number | string | null): number | null => {
    value = Number(value) || 0;
    const tpm = bpmToTpm({ bpm: value, timeSignature: this.song.timeSignature() });
    const normalizedTpm = this.normalizeMaxTpm(tpm);
    return normalizedTpm
      ? tpmToBpm({ tpm: normalizedTpm, timeSignature: this.song.timeSignature() })
      : null;
  };
}
