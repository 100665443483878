<script lang="ts">
  import { assertTruthy } from '@sindresorhus/is';
  import { Meteor } from 'meteor/meteor';
  import { onMount } from 'svelte';
  import { cubicOut } from 'svelte/easing';
  import { fade, slide } from 'svelte/transition';
  import { eventTracker } from '@/browser/analytics/eventTracker';
  import { clog } from '@/browser/clog';
  import { DemoMode } from '@/client/DemoMode';
  import type { SongListItemRecord, SongListRecord } from '@/collections/SongListsCollection';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import { MusicLibrary } from '@/library/MusicLibrary';
  import { rpcDeleteList } from '@/server/methods/lists/rpcDeleteList';
  import { rpcRemoveListFromAccount } from '@/server/methods/lists/rpcRemoveListFromAccount';
  import { rpcRenameList } from '@/server/methods/lists/rpcRenameList';
  import { rpcSetListCollapsedFlag } from '@/server/methods/lists/rpcSetListCollapsedFlag';
  import { rpcSortList } from '@/server/methods/lists/rpcSortList';
  import CollapsableSongListMenu from '@/ui/lists/CollapsableSongListMenu.svelte';
  import { ListBeingReordered } from '@/ui/lists/ListBeingReordered.svelte';
  import SortableSongList from '@/ui/lists/SortableSongList.svelte';
  import { createSongListTippyOnParent } from '@/ui/SongIndexItem';
  import { slideFade } from '@/ui/svelte-transitions';
  import { isMobile } from '@/ui-helpers/isMobile';

  interface Props {
    list: SongListRecord;
    forceOpen?: boolean;
  }
  let { list, forceOpen }: Props = $props();

  let userId = $state<string | null>(null);
  trackerEffect(() => (userId = Meteor.userId()));

  let ownerName = $derived(list.ownerId && list.ownerId !== userId ? list.ownerName.first : '');

  let collapsed = $state(!forceOpen && list.users.find((u) => u.userId == userId)?.collapsed);
  let expanded = $derived(!collapsed);
  let listId = $derived(list._id);
  let songList: SongListItemRecord[] = $state([]);
  let listBeingReordered = $derived(ListBeingReordered.value);
  let reordering = $derived(!!listBeingReordered && listBeingReordered == listId);
  let updateInProgress = $state(false);
  let orderedSongIds = $state<string[] | undefined>();
  let blurredOut = $derived(
    updateInProgress || (!!listBeingReordered && listBeingReordered != listId)
  );
  trackerEffect(() => {
    if (reordering) return;
    const lists = MusicLibrary.lists.findAndFetch({ noState: true });
    const liveList = lists.find((l) => l._id == listId) || (DemoMode.active() && list);
    if (!liveList) return; // list.songs is still reactive, thanks to the "findAndFetch" line above
    liveList.songs.forEach((song) => {
      song.listCount = MusicLibrary.lists.countWithItem(song._id);
    });
    if (orderedSongIds) {
      const songIds = orderedSongIds;
      liveList.songs.sort((a, b) => {
        const aIndex = songIds.findIndex((id) => id == a._id);
        const bIndex = songIds.findIndex((id) => id == b._id);
        return aIndex > bIndex ? 1 : -1;
      });
    }
    songList = liveList.songs;
  });

  let songCountText = $derived(`${songList.length} ${songList.length == 1 ? 'song' : 'songs'}`);

  trackerEffect(() => {
    if (Template.appContainer.fullyVisibleScreen() != 'index') {
      document.querySelector<HTMLButtonElement>('.js-cancelReordering')?.click();
    }
  });

  function toggleCollapsed(event: Event) {
    event.preventDefault();
    if (forceOpen || reordering) return;
    collapsed = !collapsed;
    rpcSetListCollapsedFlag({ listId: listId, collapsed }).catch(clog.error);
    if (collapsed) {
      eventTracker.listCollapse({ listId: listId });
    } else {
      eventTracker.listExpand({ listId: listId });
    }
  }

  async function deleteList() {
    try {
      updateInProgress = true;
      await rpcDeleteList({ listId });
      Bert.alert('Your list has been deleted.', 'success');
      eventTracker.listDelete();
    } catch (error) {
      clog.error(error);
      //@ts-expect-error Not bothering
      Bert.alert(error.reason, 'warning');
    } finally {
      updateInProgress = false;
    }
  }

  async function removeList() {
    try {
      updateInProgress = true;
      await rpcRemoveListFromAccount({ listId });
      Bert.alert('The list has been removed from your account.', 'success');
    } catch (error) {
      clog.error(error);
      //@ts-expect-error Not bothering
      Bert.alert(error.reason, 'warning');
    } finally {
      updateInProgress = false;
    }
  }

  async function renameList(newListName: string) {
    if (!newListName) return;
    try {
      updateInProgress = true;
      await rpcRenameList({ listId, name: newListName });
      eventTracker.listRename();
    } catch (error) {
      clog.error(error);
      //@ts-expect-error Not bothering
      Bert.alert(error.reason, 'warning');
    } finally {
      updateInProgress = false;
    }
  }

  async function sortAZ() {
    updateInProgress = true;
    orderedSongIds = undefined;
    await rpcSortList({
      listId,
      sortMethod: 'a',
    });
    updateInProgress = false;
  }

  function beginManualReordering() {
    ListBeingReordered.set(listId);
  }

  function cancelReordering() {
    ListBeingReordered.unset();
    orderedSongIds = undefined;
  }

  function orderChanged(newOrder: string[]) {
    orderedSongIds = newOrder;
  }

  async function saveReordering() {
    ListBeingReordered.unset();
    updateInProgress = true;
    if (orderedSongIds) {
      assertTruthy(orderedSongIds.length == songList.length);
      await rpcSortList({ listId, sortMethod: 'm', orderedSongIds });
    }
    updateInProgress = false;
    orderedSongIds = undefined; // reset after changes are saved
  }

  let parentEl: HTMLElement;
  onMount(() => {
    if (isMobile) return;
    const tippyInstance = createSongListTippyOnParent(parentEl);
    return () => tippyInstance.forEach((t) => t.destroy());
  });
</script>

<section
  class={[
    'CollapsableSongList',
    'mx-auto flex max-w-[600px] flex-col contain-layout contain-style',
    'relative has-[.tippy-box]:z-10', // needed to use contain-layout
    collapsed && 'mb-1.5 mt-0',
    expanded && 'mb-5 mt-4',
    blurredOut && 'opacity-40 grayscale',
  ]}
  style="transition: opacity 300ms ease-in-out, margin 200ms ease-out;"
  aria-roledescription="song list"
  inert={blurredOut}
  bind:this={parentEl}
>
  <header
    class={[
      'relative flex items-center bg-white transition-all duration-200 ease-out',
      collapsed && 'rounded-md delay-100',
      expanded && '-mx-2.5 rounded-xl py-[3px] delay-0 [@media(max-width:449.9px)]:-mx-1',
    ]}
    style:box-shadow={collapsed
      ? '0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.12)'
      : '0 6px 9px 0 rgba(0, 0, 0, 0.14), 0 3px 5px -1px rgba(0, 0, 0, 0.2)'}
    role="heading"
    aria-level="3"
    aria-label={list.name}
    inert={reordering}
  >
    <a
      class={[
        'js-songListTitle',
        'flex flex-auto py-1 text-black !no-underline dsktp:hover:text-primary-600',
        'focus-visible:bg-[linear-gradient(90deg, hsl(206, 100%, 96%) 0%, hsl(206, 100%, 98%) 80%, transparent 100%)]',
        'transition-all duration-200 ease-out',
        collapsed && 'rounded-md px-3.5 delay-100',
        expanded && 'rounded-xl px-6 delay-0',
        forceOpen && 'pointer-events-none text-gray-900',
      ]}
      id="listHeading_{listId}"
      href={`/app/lists/${listId}`}
      aria-expanded={expanded}
      aria-controls="listContents_{listId}"
      role="button"
      onclick={toggleCollapsed}
      onkeydown={(e) => {
        if (e.key === ' ' && !e.ctrlKey && !e.altKey && !e.shiftKey && !e.metaKey) {
          toggleCollapsed(e);
        }
      }}
    >
      <div
        class={[
          'mr-[13px] flex items-center [@media(max-width:360px)]:hidden',
          'text-gray-400 transition-all duration-300 ease-out',
          collapsed && '-rotate-90 text-gray-500 delay-100',
          forceOpen && 'invisible',
          reordering && 'invisible',
        ]}
      >
        <i class="smi smi-chevron-down" aria-hidden="true"></i>
      </div>
      <div class="py-1">
        <strong class="notranslate mr-1.5 font-medium [@media(min-width:500px)]:text-lg">
          {list.name}
        </strong>
        <span
          class="whitespace-nowrap text-sm text-gray-300"
          aria-label="{ownerName ? `${ownerName}’s` : 'Your'} list with {songCountText}"
          aria-hidden="true"
        >
          {#if ownerName}{ownerName}’s list of{/if}
          {songCountText}
        </span>
      </div>
    </a>
    {#if expanded && !blurredOut && !forceOpen && !reordering}
      <div class="px-1.5" in:fade={{ duration: 200, delay: 200 }} out:fade={{ duration: 100 }}>
        <!-- Menu -->
        <CollapsableSongListMenu
          {list}
          {deleteList}
          {removeList}
          {renameList}
          {beginManualReordering}
          {sortAZ}
        />
      </div>
    {/if}
  </header>
  {#if expanded}
    <div
      id="listContents_{listId}"
      class={[
        'js-tooltipContainer',
        'relative mx-2.5 my-0 rounded-b-md outline-none [@media(min-width:450px)]:mx-9',
        'transition-all duration-300',
        reordering && 'p-2',
      ]}
      style:background-color={reordering ? 'hsl(126, 100%, 96%)' : 'white'}
      style:background-image="linear-gradient(to bottom, #ccc 0%, rgba(255, 255, 255, 0) 100%)"
      style:background-position="left top"
      style:background-repeat="repeat-x"
      style:background-size="100% 3px;"
      style:box-shadow="0 2px 4px 0 rgba(0, 0, 0, 0.17), 0 2px 7px 0 rgba(0, 0, 0, 0.13);"
      style:clip-path="inset(0px -200px -200px -200px)"
      aria-labelledby="listHeading_{listId}"
      transition:slide={{ easing: cubicOut, duration: 300 }}
    >
      {#if songList.length}
        {#if reordering}
          <div
            class="flex items-center gap-2 pb-2.5 pr-0.5 pt-0.5"
            transition:slideFade={{ duration: 300 }}
          >
            <div class="flex-auto pl-2 text-sm text-gray-700">
              {#if isMobile}
                Drag songs to reorder.
              {:else}
                Click-and-drag <span class="[@media(max-width:520px)]:hidden">songs</span> to
                <span>reorder<span class="[@media(max-width:560px)]:hidden">&nbsp;list</span>.</span
                >
              {/if}
            </div>
            <button class="btn btn-danger py-1.5" onclick={cancelReordering}> Cancel </button>
            <button class="btn btn-raised btn-success py-1.5" onclick={saveReordering}>
              Save <span class="[@media(max-width:600px)]:hidden">Order</span>
            </button>
          </div>
        {/if}
        <div
          class={[
            'transition-all duration-300',
            reordering ? 'rounded bg-white py-1' : 'pb-1 pt-[3px]',
          ]}
          style:box-shadow={reordering
            ? 'inset 0 0 2px rgba(0, 0, 0, 0.12), inset 0 0 4px rgba(0, 0, 0, 0.15)'
            : ''}
        >
          <SortableSongList {songList} {reordering} {listId} {orderChanged} {forceOpen} />
        </div>
      {:else}
        <div class="px-4 pb-2.5 pt-3 text-sm text-gray-500">
          List is empty.
          <a
            href="/help/list-song-add"
            class="js-global-openHelpWindow"
            data-help-page="list-song-add"
          >
            Learn how to add
            <span>songs<span class="[@media(max-width:360px)]:hidden">&nbsp;to it</span></span>
          </a>.
        </div>
      {/if}
    </div>
  {/if}
</section>
