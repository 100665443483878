import * as Sentry from '@sentry/browser';
import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import SongProgression from '@/chart/ui/SongProgression.svelte';
import { DemoMode } from '@/client/DemoMode';
import { Conductor } from '@/Conductor';
import { Crnt } from '@/Crnt';
import EditorToolbar from '@/editor/ui/EditorToolbar.svelte';
import { musicPageKeys } from '@/keyboard/musicPageKeys';
import { BlazeLayout } from '@/lib/BlazeLayout';
import { mainIndexSearcher } from '@/library/mainIndexSearcher';
import { MusicLibrary } from '@/library/MusicLibrary';
import { Chord } from '@/music/Chord';
import { Roles } from '@/Roles';
import AllDevKnobs from '@/ui/AllDevKnobs.svelte';
import ClickMuteNotice from '@/ui/ClickMuteNotice.svelte';
import { controlBarBelow } from '@/ui/controlBarBelow';
import SongMenuManager from '@/ui/SongMenuManager';
import { UserProfile } from '@/user/UserProfile';
import waitUntilReactive from '@/utilities/waitUntilReactive';

Template.musicPage.onCreated(() => {
  MusicLibrary.load();
  const instance = Template.instance();
  clientModules.splashScreen.hold();
  waitUntilReactive(() => Crnt.song() || Crnt.medley()).then(() =>
    clientModules.splashScreen.hide()
  );
  Meteor.setTimeout(() => clientModules.splashScreen.hide(), 3000); // escape hatch in case it doesn't load

  instance.autorun(async () => {
    try {
      if (FlowRouter.getRouteName() == 'show-song') {
        const songId = FlowRouter.getParam('songId');
        Crnt.preload(songId);
        Crnt.loadSong(await MusicLibrary.songs.fetchFull(songId));
      }
      if (FlowRouter.getRouteName() == 'show-medley') {
        const medleyId = FlowRouter.getParam('medleyId');
        Crnt.preload(medleyId);
        Crnt.loadMedley(await MusicLibrary.medleys.fetch(medleyId));
      }
      if (FlowRouter.getQueryParam('bpm')) {
        const bpm = +FlowRouter.getQueryParam('bpm');
        Crnt.song()?.prefs.setBpm(bpm);
        Crnt.medley()?.setBpm(bpm);
        FlowRouter.setQueryParams({ bpm: undefined });
      }
      if (FlowRouter.getQueryParam('key')) {
        try {
          const key = FlowRouter.getQueryParam('key');
          const keyChord = new Chord(key.charAt(0).toUpperCase() + key.slice(1));
          Crnt.song()?.setKey(keyChord.rootNote + (keyChord.minor ? 'm' : ''));
          FlowRouter.setQueryParams({ key: undefined });
        } catch (err) {
          console.error(err);
        }
      }
    } catch (err) {
      handleLoadingError(err);
    }
  });

  instance.autorun(async () => {
    if (MusicLibrary.lists.ready()) {
      const listId = FlowRouter.getQueryParam('list');
      Crnt.setListId(listId);
      Tracker.afterFlush(() => {
        if (!Crnt.list()) {
          FlowRouter.setQueryParams({ list: null });
        }
      });
    }
  });

  instance.autorun(async () => {
    const routeName = FlowRouter.getRouteName();
    if (/(new|edit|copy)-song/.test(routeName)) {
      if (DemoMode.active()) {
        FlowRouter.go('/app');
        return;
      }
      if (Meteor.userId() && !Meteor.user('roles')) {
        return; // still logging in; roles aren't ready
      }
      if (!Roles.userHasPermission(Meteor.userId(), 'songs.updatePrivate')) {
        FlowRouter.go('subscribe');
        return;
      }
    }

    try {
      if (routeName == 'new-song') {
        Crnt.unloadMusic();
        Meteor.setTimeout(() => {
          Crnt.loadNewSong();
          UserProfile.recordMilestone('NEW_SONG_OPEN');
        }, 50); // dunno if delay is still needed, but it doesn't hurt
      }

      if (routeName == 'copy-song') {
        const songId = FlowRouter.getParam('songId');
        Crnt.preload(songId);
        MusicLibrary.songs.block();
        const results = await MusicLibrary.songs.fetchFull(songId);
        Crnt.loadSong({ ...results, duplicateAndEdit: true });
      }

      if (routeName == 'edit-song') {
        const songId = FlowRouter.getParam('songId');
        Crnt.preload(songId);
        MusicLibrary.songs.block();
        const results = await MusicLibrary.songs.fetchFull(songId);
        Crnt.loadSong({ ...results, editMode: true });
      }
    } catch (err) {
      handleLoadingError(err);
    }
  });

  instance.autorun(function resetSearchQueryOnCreateNew() {
    if (FlowRouter.getRouteName().startsWith('new-')) {
      mainIndexSearcher.setQuery(null);
    }
  });

  // lock screen on mobile
  if (Meteor.isCordova) {
    document.addEventListener(
      'deviceready',
      () => {
        instance.autorun(() => {
          if (Template.appContainer.activeScreen() == 'music') {
            cordova.plugins.SMExtras.disableIdleTimeout();
          } else {
            cordova.plugins.SMExtras.enableIdleTimeout();
          }
        });
      },
      false
    );
  }

  instance.renderSidebar = new ReactiveVar();
  instance.autorun(() => instance.renderSidebar.set(shouldRenderSidebar()));
  instance.sidebarVisible = new ReactiveVar(shouldRenderSidebar() && shouldShowSidebar());
  instance.autorun(() => {
    if (shouldShowSidebar() && shouldRenderSidebar()) {
      Meteor.clearTimeout(instance.hideSidebarTimeout);
      instance.renderSidebar.set(true);
      Meteor.defer(() => instance.sidebarVisible.set(true));
    } else if (!shouldShowSidebar()) {
      instance.sidebarVisible.set(false);
      instance.hideSidebarTimeout = Meteor.setTimeout(() => instance.renderSidebar.set(false), 350);
    }
  });
});

const shouldRenderSidebar = () =>
  !SongMenuManager.mobileMenu() &&
  !/(new|edit|copy)-song/.test(FlowRouter.getRouteName()) &&
  !Crnt.song()?.editMode();
const shouldShowSidebar = () => SongMenuManager.sidebarVisible();

Template.musicPage.onRendered(() => {
  const instance = Template.instance();

  instance.find('.js-menuWrapperContainer')._uihooks = {
    removeElement(node) {
      $(node)
        .fadeOut({
          duration: shouldRenderSidebar() ? 200 : 0,
        })
        .promise()
        .done(() => $(node).remove());
    },
  };

  instance.find('.js-notesAreaBottomWrap')._uihooks = {
    insertElement(node, next) {
      $(node).insertBefore(next).hide().fadeIn({ duration: 200 });
    },
    removeElement(node) {
      $(node)
        .slideUp({ duration: 200 })
        .promise()
        .done(() => $(node).remove());
    },
  };

  instance.autorun(() => {
    if (Template.appContainer.activeScreen() !== 'music') {
      Conductor.stop();
    }
  });

  instance.autorun(() => {
    if (Template.appContainer.fullyVisibleScreen() == 'music') {
      musicPageKeys.attach();
      if (Crnt.song() || Crnt.medley()) {
        if (MusicLibrary.blockingOperationInProgress()) {
          instance
            .$('.musicPage-overlay')
            .velocity({ opacity: 0.7 }, { display: 'block', duration: 300 });
        } else {
          requestAnimationFrame(() =>
            instance.$('.musicPage-overlay').velocity('fadeOut', { duration: 200 })
          );
        }
      }
    } else {
      instance.$('.musicPage-overlay').css('opacity', 1).show();
      musicPageKeys.detach();
      Conductor.stop();
      if (
        Template.appContainer.activeScreen() == 'index' &&
        Template.appContainer.fullyVisibleScreen() == 'index'
      ) {
        Crnt.unloadMusic();
      }
    }
  });
});

Template.musicPage.onDestroyed(() => {
  musicPageKeys.detach();
  Conductor.stop();
  Crnt.unloadMusic();
});

Template.musicPage.helpers({
  saving() {
    return (
      Template.appContainer.fullyVisibleScreen() == 'music' &&
      MusicLibrary.blockingOperationInProgress()
    );
  },
  sidebarVisible() {
    return Template.instance().sidebarVisible.get();
  },
  renderSidebar() {
    return Template.instance().renderSidebar.get();
  },
  activeMenuTemplate() {
    return SongMenuManager.activeMenu();
  },
  showControlBarFooter() {
    return controlBarBelow() && (!Crnt.song()?.editMode() || Crnt.song()?.key() || Crnt.medley());
  },
  showSongMetadata() {
    return (
      Meteor.userId() &&
      Crnt.song()?.key() &&
      (!Crnt.song()?.editMode() || UserProfile.hasMilestone('NEW_SONG'))
    );
  },
  showLoadingMessage() {
    return !Crnt.song() && !Crnt.medley();
  },
  currentList() {
    return Crnt.list();
  },
  newSongPage() {
    return FlowRouter.getRouteName() == 'new-song';
  },
  song() {
    return (
      (Template.appContainer.fullyVisibleScreen() == 'music' ||
        !FlowRouter.getRouteName().includes('song') ||
        !Meteor.userId()) &&
      Crnt.song()
    );
  },
  songHasKey() {
    return !!Crnt.song()?.key();
  },
  medleyPage() {
    return FlowRouter.getRouteName().includes('medley');
  },
  controlBarBelow() {
    return controlBarBelow();
  },
  AllDevKnobs() {
    return AllDevKnobs;
  },
  EditorToolbar() {
    return EditorToolbar;
  },
  ClickMuteNotice() {
    return ClickMuteNotice;
  },
  SongProgression() {
    return SongProgression;
  },
});

Template.musicPage.events({
  'click': function (event, instance) {
    const song = Crnt.song();
    if (!song) return;
    const classNames = [
      'songDetailsEditor',
      'musicPage-mainArea',
      'musicPage-mainAreaMainColumn',
      'musicPage-belowName',
      'songMetadata',
      'songSectionAddBar',
    ];
    if (!classNames.some((c) => event.target?.classList.contains(c))) return;
    if (song.loop.spansMultipleCells() && !song.loopJustSet) {
      song.loop.reset();
    }
    if (song.focus.spansMultipleCells() && !song.focusJustSet) {
      song.focus.setToSingleCell(song.focus.endCell());
    }
  },
});

function handleLoadingError(err) {
  console.error(err);
  const medley = FlowRouter.getRouteName().includes('medley');
  if (err.error == 'document-not-found') {
    BlazeLayout.render('notFound');
    Bert.alert(`Sorry, that ${medley ? 'medley' : 'song'} couldn't be found.`, 'warning');
  } else {
    Bert.alert(err.reason || err.message || err.toString(), 'warning');
    Sentry.captureException(err);
  }
}
