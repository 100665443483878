import * as Sentry from '@sentry/browser';
import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { Analytics } from '@/browser/analytics/Analytics';
import { MusicLibrary } from '@/library/MusicLibrary';
import cloneJSON from '@/utilities/cloneJSON';

let lastTrackedRoutePath: string | undefined;

export function trackPageView() {
  if (window.userIsBeingImpersonated) return;

  const current = FlowRouter.current() as unknown as {
    path: string;
    params: Record<string, string>;
    queryParams: Record<string, string>;
    route: { options: { enterEvent?: string | ((...args: any) => string) } };
  };

  const enterEventName =
    typeof current.route.options.enterEvent == 'function'
      ? current.route.options.enterEvent(current.params, current.queryParams)
      : current.route.options.enterEvent;
  if (enterEventName) {
    let viewName;
    const songOrMedleyId =
      FlowRouter.getQueryParam('songId') || FlowRouter.getQueryParam('medleyId');
    if (songOrMedleyId) {
      viewName = MusicLibrary.index.getIndexRecord(songOrMedleyId)?.name;
    }

    const eventDetails = cloneJSON({
      path: current.path,
      referrer: lastTrackedRoutePath ? window.location.href : document.referrer,
      title: viewName,
      query: enterEventName == 'SongIndex.Search' ? FlowRouter.getQueryParam('search') : undefined,
    });

    try {
      Analytics.trackEvent(enterEventName, eventDetails);
    } catch (e) {
      Sentry.captureException(e);
    }

    lastTrackedRoutePath = window.location.href;
  }
}
