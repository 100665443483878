<script lang="ts">
  import type { AnimationPlaybackControls } from 'motion';
  import { animate } from 'motion/mini';

  let button: HTMLElement;

  // Props
  interface Props {
    increment: number;
    altIncrement: number;
    pluralUnit?: string;
    disabled?: boolean;
    hidden?: boolean;
    flashIteration?: number;
    flashDuration?: number;
    startStepping: (amount: number) => void;
    stopStepping: () => void;
  }

  let {
    increment,
    altIncrement,
    pluralUnit,
    disabled,
    hidden,
    flashIteration,
    flashDuration,
    startStepping,
    stopStepping,
  }: Props = $props();

  let increase = $derived(increment > 0);

  function onPointerDown(event: PointerEvent) {
    if (disabled) return;
    if (event.button > 0) return; // don't respond to middle/right button
    button.setPointerCapture(event.pointerId);
    startStepping(event.ctrlKey || event.shiftKey ? altIncrement : increment);
    return false;
  }

  function onPointerUp(event: PointerEvent) {
    button.releasePointerCapture(event.pointerId);
    stopStepping?.();
    return false;
  }

  let runningAnimation: AnimationPlaybackControls | undefined;

  $effect(() => {
    if (flashIteration && flashDuration) {
      runningAnimation?.stop();
      button.style.transition = 'none';
      runningAnimation = animate(
        button,
        { backgroundColor: ['#c4e5ff', '#e2eff9'] }, // end state is hover color
        { duration: flashDuration / 1000 }
      );
      void runningAnimation.then(() => {
        button.style.backgroundColor = '';
        button.style.transition = '';
      });
    }
  });
</script>

<button
  class="scalarInputWithTrimmers-trimmer btn"
  class:disabled-hidden={hidden}
  class:disabled
  aria-label="{increase ? 'Increase' : 'Decrease'} {pluralUnit}"
  onpointerdown={onPointerDown}
  onpointerup={onPointerUp}
  bind:this={button}
>
  {#if increase}
    <i class="smi smi-plus" aria-hidden="true"></i>
  {:else}
    <i class="smi smi-minus" aria-hidden="true"></i>
  {/if}
</button>

<style>
  :global(.ios),
  :global(.android) {
    .scalarInputWithTrimmers-trimmer .smi::before {
      transform: scale(1.2);
    }
  }

  .btn {
    transform: translate3d(0px, 0px, 0px);
    border-radius: 50%;
    height: 38px;
    width: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn.disabled {
    color: #ccc !important;
    background: transparent !important;
    cursor: default;
  }

  .btn.disabled-hidden {
    opacity: 0;
    pointer-events: none;
  }
</style>
