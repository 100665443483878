<script lang="ts">
  interface Props {
    pan: number;
    overridden?: boolean;
    inButton?: boolean;
  }

  let { pan, overridden = false, inButton = false }: Props = $props();

  let arrowCount: number = $derived(Math.ceil(Math.abs(pan) * 3));

  let dir: 'L' | 'R' | undefined = $derived(pan < 0 ? 'L' : pan > 0 ? 'R' : undefined);

  let absPan: number = $derived(Math.abs(pan));

  let styleVars: string = $derived(
    [
      `--pan-bias-color: hsl(206, ${10 + 20 * absPan}%, ${80 - 25 * absPan}%)`,
      `--pan-arrows-color: hsl(206, ${10 + 20 * absPan}%, ${85 - 20 * absPan}%)`,
      `--pan-bias-override-color: hsl(206, ${30 + 50 * absPan}%, ${50 - 40 * absPan}%)`,
      `--pan-arrows-override-color: hsl(206, ${30 + 50 * absPan}%, ${60 - 30 * absPan}%)`,
    ].join('; ')
  );
</script>

<div class="SmallPanIndicator" class:overridden class:inButton style={styleVars}>
  {#if pan == 0}
    {#if inButton || overridden}
      <span
        style="color: {overridden
          ? 'hsl(206, 50%, 40%)'
          : 'var(--primary-500)'}; font-weight: normal; font-size: 13px;">center</span
      >
    {/if}
  {:else}
    {#if dir == 'L'}
      <span class="label-l">L</span>
    {/if}
    <span class="SmallPanIndicator-arrows">
      {#each Array(arrowCount) as _, i (i)}
        <i class="smi smi-chevron-{dir == 'L' ? 'left' : 'right'}" aria-hidden="true"></i>
      {/each}
    </span>
    {#if dir == 'R'}
      <span class="label-r">R</span>
    {/if}
  {/if}
</div>

<style>
  .SmallPanIndicator {
    display: inline-flex;
    align-items: center;

    > .label-l,
    > .label-r {
      font-size: 14px;
    }

    &.inButton {
      > .label-l,
      > .label-r {
        font-size: 16px;
      }
    }

    .SmallPanIndicator-arrows {
      margin: 0 0.25em;
      > .smi::before {
        margin: 0 -0.375em;
      }
    }

    &.inButton {
      color: var(--muted-400);
      &.overridden {
        color: hsl(110, 42%, 45%);
      }
    }

    &:not(.inButton) {
      color: var(--pan-arrows-color);
      &.overridden {
        color: var(--pan-arrows-override-color);
      }

      > .label-l,
      > .label-r {
        color: var(--pan-bias-color);
      }
      &.overridden > .label-l,
      &.overridden > .label-r {
        color: var(--pan-bias-override-color);
      }
    }
  }
</style>
