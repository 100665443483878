type PatternDefinition = [
  intervals: [number, ...number[]],
  chordMustContain: number[],
  nextChordMustContain?: number[],
  recommendedGhostNoteBeats?: number[],
];

// Parameters:
// - Quality of the chord (e.g. major, minor, diminished, etc.)
// - Destination interval (e.g. 1, 4, 5, b7, etc.)
// - Destination chord quality (e.g. major, minor, diminished, etc.) -- MAYBE
// - Stepwise vs jumping
// Detectable:
// - Beat 1 root note vs indirect resolution
// - Minor or major (or does it matter?)
// - Diminished triad (or does it matter?)

// prettier-ignore
export const rawWalkingBassPatternDefinitions: PatternDefinition[] =
[
  [[0, 0, 1], []],
  [[0, -12, -7], [], [], [1]],
  [[0, 12, 5], [], [], [1]],
  [[0, -12, -6], []],
  [[0, 12, 6], []],
  [[0, 12, 10], []],
  [[0, 1, 2], []],
  [[0, 2, 3], []],
  [[0, 2, 4], []],
  [[0, 2, 7], []],
  [[0, 3, 0], [3]],
  [[0, 3, 1], [3]],
  [[0, 3, 2], [3]],
  [[0, 3, 5], [3]],
  [[0, -9, -7], [3]],
  [[0, 3, 6], [3]],
  [[0, 3, 7], [3]],
  [[0, -9, -5], [3]],
  [[0, 3, 8], [3]],
  [[0, 3, 10], [3]],
  [[0, 4, 0], [4]],
  [[0, 4, 1], [4]],
  [[0, 4, 2], [4]],
  [[0, 4, 5], [4]],
  [[0, -8, -7], [4]],
  [[0, -8, -6], [4]],
  [[0, 4, 7], [4]],
  [[0, -8, -5], [4]],
  [[0, 4, 9], [4]],
  [[0, 4, 9], [4]],
  [[0, 5, 7], [5]],
  [[0, 6, 0], [6]],
  [[0, 6, 3], [6]],
  [[0, 6, 7], [6]],
  [[0, -6, -5], [6]],
  [[0, -5, -12], [7]],
  [[0, 7, 12], [7]],
  [[0, -5, 0], [7]],
  [[0, 7, 1], [7]],
  [[0, -5, -12], [7]],
  [[0, -5, -9], [7]],
  [[0, 7, 4], [7]],
  [[0, 7, 5], [7]],
  [[0, -5, -7], [7]],
  [[0, 7, 6], [7]],
  [[0, 7, 8], [7]],
  [[0, 7, 9], [7]],
  [[0, -5, -3], [7]],
  [[0, 7, 10], [7]],
  [[0, 7, -2], [7]],
  [[0, -4, -12], [8]],
  [[0, 8, 12], [8]],
  [[0, -4, -5], [3]],
  [[0, -3, -10], [9]],
  [[0, -3, -5], [4]],
  [[0, -3, -2], [4]],
  [[0, -2, -9], []],
  [[0, -2, 3], []],
  [[0, -2, -7], [10]],
  [[0, -2, -5], [10]],
  [[0, -2, -3], [10]],
  [[0, -1, -3], [4]],
  [[0, -1, -2], []],
  [[0, 0, 2, 3, 4], [], [], [1]],
  [[0, 2, 3, 4, 5], [], [], [1]],
  [[0, -10, -9, -8, -7], [], [], [1]],
  [[0, 2, 3, 6, 0], [3, 6]],
  [[0, 2, 3, 7, 0], [3, 7]],
  [[0, 2, 3, 7, 12], [3, 7]],
  [[0, 2, 3, 7, 2], [3, 7]],
  [[0, 2, 3, 7, 5], [3, 7]],
  [[0, 2, 3, 7, 8], [3, 7]],
  [[0, 2, 3, -2, -4], [3, 7, 10]],
  [[0, 2, 3, -1, -2], [3, 7]],
  [[0, 2, 4, 7, 2], [4, 7]],
  [[0, 2, 4, 7, 5], [4, 7], [], [3]],
  [[0, 2, 4, 7, 9], [4, 7]],
  [[0, 3, 5, 6, 7], [3, 7]],
  [[0, 3, 6, 2, 3], [3, 6]],
  [[0, 3, 6, 3, 0], [3, 6]],
  [[0, -9, -6, -5, -4], [3, 7, 10]],
  [[0, 3, 6, 7, 8], [3, 7, 10]],
  [[0, 3, 7, 0, -2], [3, 7]],
  [[0, 3, 7, 2, 3], [3, 7]],
  [[0, 3, 7, 3, 0], [3, 7]],
  [[0, 3, 7, 3, 2], [3, 6]],
  [[0, 3, 7, 6, 5], [3, 7]],
  [[0, 3, 7, 8, 9], [3, 7]],
  [[0, -9, -5, -4, -3], [3, 7]],
  [[0, 3, 7, 9, 2], [3, 7]],
  [[0, 3, 7, 9, 10], [3, 7]],
  [[0, 3, 7, 10, 8], [3, 7, 10]],
  [[0, 3, 7, 11, 12], [3, 11]],
  [[0, 4, 5, 6, 7], [4]],
  [[0, -8, -7, -6, -5], [4]],
  [[0, 4, 7, 0, -2], [4, 7]],
  [[0, 4, 7, 4, 0], [4, 7]],
  [[0, 4, 7, 4, 2], [4, 7]],
  [[0, 4, 7, 4, -2], [4, 7]],
  [[0, 4, 7, 6, 5], [4, 7]],
  [[0, 4, 7, 8, 9], [4, 7]],
  [[0, -8, -5, -4, -3], [4, 7]],
  [[0, 4, 7, 9, 12], [4, 7]],
  [[0, -8, -5, -3, 0], [4, 7]],
  [[0, 4, 7, 9, 2], [4, 7]],
  [[0, 4, 7, 9, 10], [4, 7]],
  [[0, 4, 7, 10, 9], [4, 7, 10]],
  [[0, 4, 8, 4, 0], [4, 8]],
  [[0, 4, 9, 7, 5], [4, 7]],
  [[0, -8, -3, -5, -7], [4, 7]],
  [[0, 4, 9, 8, 7], [4, 7]],
  [[0, -8, -3, -4, -5], [4, 7]],
  [[0, 4, 10, 9, 7], [4, 10]],
  [[0, -5, 0, 2, 3], [7]],
  [[0, 7, 0, 2, 3], [7]],
  [[0, -5, 0, 2, 4], [7]],
  [[0, -5, 0, -2, 3], [7]],
  [[0, 7, 3, 0, 1], [3, 7]],
  [[0, 7, 3, 2, 0], [3, 7]],
  [[0, 7, 3, 4, 5], [7]],
  [[0, -5, -9, -8, -7], [7]],
  [[0, 7, 4, 0, 1], [4, 7]],
  [[0, 7, 4, 3, 2], [4, 7]],
  [[0, 7, 4, 5, 7], [4, 7], [10]],
  [[0, 7, 4, 6, 7], [4, 7], [11]],
  [[0, -5, -8, -6, -5], [4, 7], [11]],
  [[0, 7, 9, 4, 7], [4, 7]],
  [[0, -5, -3, -8, -5], [4, 7]],
  [[0, 7, 9, 10, 11], [7]],
  [[0, -5, -2, -3, -7], [7, 10], [4]],
  [[0, -5, -1, 0, -12], [7, 11]],
  [[0, 9, 4, 5, 7], [4, 7], [10]],
  [[0, -3, -7, -6, -5], [4, 7]],
  [[0, 9, 7, 4, 0], [4, 7]],
  [[0, -3, -5, -8, -12], [4, 7]],
  [[0, 9, 7, 6, 5], [4, 7, 10], [4]],
  [[0, -3, -5, -6, -7], [4, 7, 10], [4]],
  [[0, -3, -5, -3, 0], [4, 7]],
  [[0, -2, -9, -7, -5], [3, 7, 10]],
  [[0, -2, -9, -5, -4], [3, 7, 10]],
  [[0, -2, -5, -9, -12], [3, 7, 10]],
  [[0, 10, 7, 4, 0], [4, 7, 10]],
  [[0, -2, -5, -8, -12], [4, 7, 10]],
  [[0, -2, -5, -6, -7], [7, 10]],
  [[0, -2, -4, -5, -7], [10, 7], [3]],
  [[0, -2, -3, -5, -7], [10, 7], [4]],
  [[0, -1, 0, -12, 2], []],
  [[0, -1, 0, 2, 4], []],
  [[0, -1, -2, -5, -7], [10, 7]],
  [[3, 0, -5, -3, 0], [3, 7]],
  [[3, 0, -4, -5, -7], [3, 7]],
  [[4, 0, 2, 4, 5], [4]],
  [[4, 0, 3, 4, 5], [4]],
  [[4, 0, 4, 5, 7], [4, 7]],
  [[4, 0, 5, 6, 7], [4]],
  [[4, 0, 7, 9, 12], [4, 7]],
  [[4, 0, -5, -3, 0], [4, 7]],
  [[4, 0, -3, -5, 0], [4, 7]],
  [[4, 0, -3, -5, -7], [4, 7]],
  [[4, 0, -3, -4, -5], [4, 7]],
  [[4, 0, 10, 9, 7], [4, 7]],
  [[0, 3, 7, 12, 10, 7, 3, 4, 5], [3, 7, 10]],
  [[0, 3, 7, 3, 0, 3, 7, 3, 0], [3, 7]],
  [[0, 3, 7, 11, 0, 7, 3, 2, 0], [3, 7]],
  [[0, 4, 7, 12, 10, 9, 7, 4, 5], [4, 7, 10]],
  [[0, 4, 7, 4, 0, 4, 7, 4, 0], [4, 7]],
  [[-12, -8, -5, -3, 0, 2, 3, 4, 5], [4, 7]],
  [[0, 4, 7, 9, 12, 9, 7, 4, 0], [4, 7]],
  [[0, -8, -5, -3, 0, -3, -5, -8, -12], [4, 7]],
  [[0, 4, 7, 9, 12, 10, 9, 7, 5], [4, 7]],
  [[0, 4, 7, 9, 10, 9, 7, 4, 0], [4, 7, 10]],
  [[0, 4, 7, 9, 10, 9, 7, 4, 5], [4, 7, 10]],
  [[0, -3, -5, -8, -12, -10, -9, -8, -7], [4, 7]],
  [[0, -3, -5, -3, 0, 2, 3, 4, 5], [4, 7]]
];
