import * as Sentry from '@sentry/browser';
import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import SongProgression from '@/chart/ui/SongProgression.svelte';
import { Crnt } from '@/Crnt';
import { BlazeLayout } from '@/lib/BlazeLayout';
import { MusicLibrary } from '@/library/MusicLibrary';

function handleSongLoadError(err) {
  console.error(err);
  if (err.error == 'document-not-found') {
    Meteor.defer(() => {
      $('head').append(
        '<meta name="robots" content="noindex, follow" data-flow-router-seo="true" />'
      );
    });
    BlazeLayout.render('notFound');
    Bert.alert("Sorry, that song couldn't be found.", 'warning');
  } else {
    Bert.alert(err.reason || err.message || err.toString(), 'warning');
    Sentry.captureException(err);
  }
}

Template.publicSongPage.onCreated(() => {
  Template.instance().autorun(async () => {
    const songId = FlowRouter.getParam('songId');
    if (songId) {
      try {
        Crnt.preload(songId);
        const results = await MusicLibrary.songs.fetchFull(songId);
        Crnt.loadSong(results);
      } catch (err) {
        handleSongLoadError(err);
      }
    }
  });
  window.prerenderReady = false;
});

Template.publicSongPage.onRendered(() => {
  $(document.scrollingElement).animate(
    {
      scrollTop: 0,
    },
    0
  );
  Template.instance().autorun(() => {
    if (Crnt.song()) {
      Meteor.setTimeout(() => {
        window.prerenderReady = true;
      }, 500);
    }
  });
});

Template.publicSongPage.onDestroyed(() => {
  Crnt.unloadMusic();
});

Template.publicSongPage.helpers({
  song() {
    return Crnt.song();
  },
  songName() {
    var name = Crnt.song()?.name() || '';
    return name
      .replace(/ +\[.+\]$/, '')
      .replace(/(^|[-\u2014\s(["])'/g, '$1\u2018')
      .replace(/'/g, '\u2019');
  },
  songLabel() {
    const name = Crnt.song()?.name() || '';
    const match = name.match(/ \[(.+)\]$/);
    return match ? match[1] : null;
  },
  songSections() {
    return (
      (Template.appContainer.fullyVisibleScreen() == 'song' ||
        !FlowRouter.getRouteName().includes('song') ||
        !Meteor.userId()) &&
      Crnt.song()?.sections.reactive()
    );
  },
  SongProgression() {
    return SongProgression;
  },
});

Template.publicSongPage.events({});
