import { Channel } from '@/audio/engine/base/Channel';
import type { EQBand } from '@/audio/engine/base/EQ';
import { FMBCordovaPlugin } from '@/audio/engine/fmb/FMBCordovaPlugin';
import { FMBEQ } from '@/audio/engine/fmb/FMBEQ';
import type { FMBPlayback } from '@/audio/engine/fmb/FMBPlayback';

// Assumption: channels are already set up on engine initialization

export class FMBChannel extends Channel {
  readonly eq: FMBEQ;
  constructor(id: string, eqBands?: EQBand[]) {
    super(id);
    this.eq = new FMBEQ(id, eqBands);
  }

  private _volume = 1;
  set volume(val: number) {
    this._volume = Math.max(0, Math.min(1, val));
    void FMBCordovaPlugin.setChannelVolume({
      channel: this.id,
      volume: this._volume * (this._attenuated ? 0.15 : 1),
    });
  }

  get volume(): number {
    return this._volume;
  }

  private _attenuated = false;
  set attenuate(val: boolean) {
    this._attenuated = !!val;
    void FMBCordovaPlugin.setChannelVolume({
      channel: this.id,
      volume: this._volume * (this._attenuated ? 0.15 : 1),
    });
  }

  get attentuate(): boolean {
    return this._attenuated;
  }

  private _muted = false;
  set muted(val: boolean) {
    this._muted = !!val;
    void FMBCordovaPlugin.setChannelMuted({
      channel: this.id,
      muted: this._muted,
    });
  }

  get muted(): boolean {
    return this._muted;
  }

  private _pan = 0;
  set pan(val: number) {
    this._pan = Math.max(-1, Math.min(1, val));
    void FMBCordovaPlugin.setChannelPan({
      channel: this.id,
      pan: this._pan,
    });
  }

  get pan(): number {
    return this._pan;
  }

  canPan(): boolean {
    return true;
  }

  protected _linkPlaybackToChannel(_playback: FMBPlayback): void {
    // not needed for Cordova
  }
}
