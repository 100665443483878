import UserPreferences from '@/user/UserPreferences';

function getTicksPerBeat(timeSignature: TimeSignature, ignoreUserPreferences?: boolean): number {
  if (timeSignature == '6/8' || timeSignature == '9/8') {
    return 3;
  } else if (timeSignature == '3/4') {
    return ignoreUserPreferences ? 1 : (UserPreferences.get('tpbWaltzes') as number);
  } else if (timeSignature == '4/4') {
    return ignoreUserPreferences ? 2 : (UserPreferences.get('tpbStandard') as number);
  }
  throw new Error(`Invalid time signature: ${timeSignature as any}`);
}

export function bpmToTpm({
  bpm,
  timeSignature,
  ignoreUserPreferences,
}: {
  bpm: number;
  timeSignature: TimeSignature;
  ignoreUserPreferences?: boolean;
}): number {
  return getTicksPerBeat(timeSignature, ignoreUserPreferences) * bpm;
}

export function tpmToBpm({
  tpm,
  timeSignature,
  ignoreUserPreferences,
}: {
  tpm: number;
  timeSignature: TimeSignature;
  ignoreUserPreferences?: boolean;
}): number {
  const tpb = getTicksPerBeat(timeSignature, ignoreUserPreferences);
  const bpm = Math.round(tpm) / tpb;
  return /[69]\/8/.test(timeSignature) ? Math.round(bpm) : bpm;
}
