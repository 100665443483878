import { assignGuitarBassRunsAndLeadingNotes } from '@/band/instruments/guitar/assignGuitarBassRunsAndLeadingNotes';
import { assignGuitarBoomStrums } from '@/band/instruments/guitar/assignGuitarBoomStrums';
import { bluegrassMeasure } from '@/band/instruments/guitar/blocks/bluegrassMeasure';
import { endingBoomChuckMeasure } from '@/band/instruments/guitar/blocks/endingBoomChuckMeasure';
import { splitCell } from '@/band/instruments/guitar/blocks/splitCell';
import { bluegrassPatternLandscape } from '@/band/instruments/guitar/bluegrassPatternLandscape';
import type { GuitarCore } from '@/band/instruments/guitar/GuitarCore';
import { bluegrassSpreadGradients } from '@/band/instruments/guitar/settings/bluegrassSpreadGradients';
import { swingOptions } from '@/band/swingOptions';
import { lerp } from '@/utilities/lerp';

type EightNumbers = [number, number, number, number, number, number, number, number];

export const BluegrassGuitarCore: GuitarCore = {
  id: 'bg',
  title: 'Boom chuck with upstrokes',

  timeSignatures: ['4/4'],

  linkedSettingsKeys: ['brushiness', 'circularStrumShape'],
  otherSettingsKeys: ['bassNotes', 'bassRuns', 'openVoicings', 'timing'],

  chordStyle: 'cowboy',

  swingCategory: 'normal',
  defaultSwing: swingOptions['5:4'],

  presets: [
    {
      name: 'Boom chuck boom-a-chuck-a',
      settings: {
        'circularStrumShape': { r: 0, t: 0 },
        'brushiness': { v: 7 / 14 },
      },
    },
    {
      name: 'Heavy boom-a-chuck-a',
      settings: {
        'circularStrumShape': { r: 0.75, t: 0.75 },
        'brushiness': { v: 12 / 14 },
      },
    },
    {
      name: 'Boom chuck with crosspicking',
      settings: {
        'circularStrumShape': { r: 0.2, t: 0.75 },
        'brushiness': { v: 1 / 14 },
      },
    },
    {
      name: 'Boom chuck, a-chuck-a',
      settings: {
        'circularStrumShape': { r: 0.8, t: 0.125 },
        'brushiness': { v: 10 / 14 },
      },
    },
  ],

  measurePreprocessing(measures, settings, flags) {
    if (!flags?.noEmbellishments) {
      assignGuitarBassRunsAndLeadingNotes({ measures, settings, core: BluegrassGuitarCore });
    }
    assignGuitarBoomStrums({ measures, settings });
  },

  processMeasure(measure, settings) {
    // TODO: Make this actually reflect current settings
    const dynamics = { boomPower: 0.85, chuckPower: 0.75, upstrokePower: 0.75 };

    if (measure.endOfSong) {
      return endingBoomChuckMeasure(measure, { ...dynamics });
    }

    const [firstCell, secondCell] = measure.cells;

    if (
      !measure.cells.some((cell) => cell.effect == 'diamond' || cell.effect == 'stop' || cell.split)
    ) {
      return bluegrassMeasure([firstCell, secondCell], settings);
    }

    return [
      ...(firstCell.split
        ? splitCell(firstCell, dynamics)
        : bluegrassMeasure([firstCell], settings)),
      ...(!secondCell
        ? []
        : secondCell.split
          ? splitCell(secondCell, dynamics)
          : bluegrassMeasure([secondCell], settings)),
    ];
  },

  generateViz(settings) {
    const strokePowers = settings.circularStrumShape.powersForLandscape(bluegrassPatternLandscape);

    const beatThicknesses = strokePowers.map((power, i) =>
      lerp(0, i % 2 == 0 ? 1 : 0.7, power)
    ) as EightNumbers;

    const beatOpacities = strokePowers.map((power, i) => {
      const direction = i % 2 == 0 ? 'D' : 'U';
      const threshold: number = direction == 'U' ? 0.2 : 0.1;
      const multiple: number = direction == 'U' ? 1 : 2;
      return power < threshold ? 0 : Math.min(1, threshold + power * multiple);
    }) as EightNumbers;

    const beatTails = settings.brushiness
      .spreadsForGradients(bluegrassSpreadGradients)
      .map((input, i) => {
        const spread = lerp(0.5 * input, input, strokePowers[i] ?? NaN);
        if (i % 4 == 2) {
          const previousPower = strokePowers[i - 1] as number;
          const nextPower = strokePowers[i + 1] as number;
          return Math.max(2.2 - (previousPower + nextPower), spread);
        }
        return spread;
      })
      .map((adjustedSpread) => Math.min(1, (adjustedSpread - 1) / 4)) as EightNumbers;

    const beatTailOpacities = beatTails.map((tail, i) =>
      tail < 0.2 ? tail * 5 : undefined
    ) as EightNumbers;

    const altBass = settings.bassNotes.altBass;
    const beatBiasies = [0, 0.6, 1, 1, altBass ? 0.2 : 0, 0.6, 1, 1] as EightNumbers;

    return ([0, 1, 2, 3, 4, 5, 6, 7] as const).map((i) => ({
      dir: i % 2 == 0 ? 'd' : 'u',
      biasY: beatBiasies[i],
      thickness: beatThicknesses[i],
      opacity: beatOpacities[i],
      tail: beatTails[i],
      tailOpacity: beatTailOpacities[i],
    }));
  },
};
