import { Chord } from '@/music/Chord';
import { htmlTimeSig } from '@/ui-helpers/htmlTimeSig';

Template.registerHelper('htmlChord', (chord) => {
  if (!chord) return;
  return chord.toHtml ? chord.toHtml() : new Chord(chord).toHtml();
});

Template.registerHelper('htmlTimeSig', (sig) => {
  return htmlTimeSig(sig);
});
