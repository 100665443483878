import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import tippyJs from 'tippy.js';
import { eventTracker } from '@/browser/analytics/eventTracker';
import { Crnt } from '@/Crnt';
import SongMenuManager from '@/ui/SongMenuManager';
import { UserProfile } from '@/user/UserProfile';

Template.helpButtonAndMenu.onRendered(() => {
  const instance = Template.instance();
  instance.menuOpened = new ReactiveVar();
  instance.autorun(() => {
    if (instance.data.parentPage != 'song') return;
    if (
      ((FlowRouter.getRouteName() == 'new-song' && UserProfile.notMilestone('NEW_SONG')) ||
        (/copy-song|edit-song/.test(FlowRouter.getRouteName()) &&
          UserProfile.notMilestone('EDIT_SONG'))) &&
      !(Crnt.song()?.key() && Crnt.song()?.sections.reactive()[0]?.cells.length > 8) &&
      !instance.menuOpened.get()
    ) {
      if (!instance.newSongHelpTippy) {
        instance.newSongHelpTippy = tippyJs(instance.find('.js-menuOpenButton'), {
          placement: 'bottom',
          content: 'New to this? Watch the tutorial!',
          offset: [-4, 0],
          hideOnClick: false,
          maxWidth: 250,
          duration: [500, 300],
          trigger: 'manual',
          onHidden(tippy) {
            tippy.destroy();
          },
        });
        setTimeout(() => instance.newSongHelpTippy?.show(), 700);
      }
    } else {
      instance.newSongHelpTippy?.hide();
      instance.newSongHelpTippy = null;
    }
  });
});

Template.helpButtonAndMenu.helpers({
  newToMakingSongs() {
    return FlowRouter.getRouteName() == 'new-song' && UserProfile.notMilestone('NEW_SONG');
  },
  newToEditingSongs() {
    return (
      /edit-song|copy-song/.test(FlowRouter.getRouteName()) && UserProfile.notMilestone('EDIT_SONG')
    );
  },
});

Template.helpButtonAndMenu.events({
  'show.bs.dropdown': function (event, instance) {
    SongMenuManager.showMenu(null);
    instance.menuOpened.set(true);
  },
  'shown.bs.dropdown': function (event, instance) {
    instance.$('input[type="search"]').val('');
    if (!(/Android|iP(hone|ad|od)/i.test(navigator.userAgent) && window.innerHeight > 680)) {
      instance.$('input[type="search"]').focus();
    }
    UserProfile.recordMilestone('HELP_MENU_OPEN');
    eventTracker.helpMenuOpen({ page: FlowRouter.getRouteName() });
  },
  'click .js-closeMenu': (event, instance) => {
    $.fn.dropdown.clearMenus();
  },
  'submit form': (event, instance) => {
    $.fn.dropdown.clearMenus();
  },
});
