<script lang="ts">
  import { MandolinSwingSustainSetting } from '@/band/instruments/mandolin/settings/MandolinSwingSustainSetting';
  import CycleThroughDropdown from '@/band/ui/components/CycleThroughDropdown.svelte';
  import PrettySlider from '@/band/ui/components/PrettySlider.svelte';
  import RhythmPatternVisualization from '@/band/ui/components/RhythmPatternVisualization.svelte';
  import SliderRule from '@/band/ui/components/SliderRule.svelte';
  import PanelModalLayout from '@/band/ui/panels/PanelModalLayout.svelte';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';

  const bandMenu = getBandMenuState();

  let setting = $derived($bandMenu.mandolinSettings.swingSustain);
</script>

<PanelModalLayout>
  {#snippet header()}
    <header class="panelHeader">
      These two sliders change how long the chord is held for onbeats and offbeats, respectively.
    </header>
  {/snippet}

  {#snippet main()}
    <div>
      <div class="mb-7 mt-6 flex justify-center">
        <div
          style="box-shadow: 4px 6px 12px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.05), -4px -6px 12px 0px rgba(255, 255, 255, 0.95); border-radius: 12px; overflow: hidden; border: solid 2px var(--gray-100);"
        >
          <RhythmPatternVisualization
            actions={$bandMenu.mandolin
              .coreOrFallbackForTimeSignature($bandMenu.timeSignature)
              .generateViz($bandMenu.mandolinSettings)}
            timeSignature={$bandMenu.timeSignature}
            scale={1.9}
            highlightCurrentBeat={true}
            showBeatNumbers={false}
          />
        </div>
      </div>

      <div class="cycle-shadow mx-4 mb-3 rounded-lg bg-muted-100 p-1 pb-[5px]">
        <CycleThroughDropdown
          options={MandolinSwingSustainSetting.combinedPresets}
          selectedValue={setting.closestCombinedPreset.value}
          caption="{setting.closestOnbeatPreset.caption} / {setting.closestOffbeatPreset.caption}"
          on:select={({ detail: data }) => {
            $bandMenu.mandolin.setSetting('swingSustain', new MandolinSwingSustainSetting(data));
          }}
          wrapAround={true}
          captureLeftRightKeys={true}
        />
      </div>

      <SliderRule centerLabel="Onbeat Sustain" />

      <div class="mx-6 py-2">
        <PrettySlider
          min={0}
          max={12}
          value={setting.closestOnbeatPreset.value * 12}
          step={1}
          tickCount={13}
          thumbDiameter={2.5}
          tickHeight={0.625}
          tickSqueezes={[0, 0.5, 0.5, 0.5, 0, 0.5, 0, 0.5, 0, 0.5, 0.5, 0.5, 0]}
          allowedValues={MandolinSwingSustainSetting.presets.map((preset) => preset.value * 12)}
          ariaLabel="sustain"
          on:change={({ detail: value }) => {
            $bandMenu.mandolin.setSetting('swingSustain', setting.withOnbeatSustain(value / 12));
          }}
        />
      </div>

      <SliderRule />

      <div class="mx-6 py-2">
        <PrettySlider
          min={0}
          max={12}
          value={setting.closestOffbeatPreset.value * 12}
          step={1}
          tickCount={13}
          thumbDiameter={2.5}
          tickHeight={0.625}
          tickSqueezes={[0, 0.5, 0.5, 0.5, 0, 0.5, 0, 0.5, 0, 0.5, 0.5, 0.5, 0]}
          allowedValues={MandolinSwingSustainSetting.presets.map((preset) => preset.value * 12)}
          ariaLabel="sustain"
          on:change={({ detail: value }) => {
            $bandMenu.mandolin.setSetting('swingSustain', setting.withOffbeatSustain(value / 12));
          }}
        />
      </div>

      <SliderRule centerLabel="Offbeat Sustain" />
      <div class="mb-3"></div>
    </div>
  {/snippet}
</PanelModalLayout>
