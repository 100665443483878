import tippyJs from 'tippy.js';
import { Crnt } from '@/Crnt';

function keyDownHandler(event) {
  if (event.key == 'Tab') return;
  if (event.key == 'Shift') return;
  if (event.key.startsWith('Arrow')) {
    clientModules.lineLengthPicker.hide();
    return;
  }
  if (event.metaKey || event.ctrlKey || event.altKey) return;
  event.stopPropagation();
  event.preventDefault();
  if (/^[1-6]$/.test(event.key)) {
    Crnt.song()?.withFocused().setLineLength(parseInt(event.key, 10));
  }
  clientModules.lineLengthPicker.hide();
}

function blurHandler() {
  clientModules.lineLengthPicker.hide();
}

const tippyDefaults = {
  animation: 'scale-subtle',
  appendTo: () => document.getElementsByClassName('js-global-songScrollContainer')[0],
  duration: [300, 150],
  hideOnClick: false,
  interactive: true,
  offset: [0, 0],
  placement: 'bottom',
  role: '',
  showOnCreate: true,
  theme: 'light',
  trigger: 'manual',

  onShow: (instance) =>
    requestAnimationFrame(() => {
      instance.popper.querySelector('.js-focusTarget').focus();
      instance.popper.querySelector('.js-focusTarget').addEventListener('keydown', keyDownHandler);
      instance.popper.querySelector('.js-focusTarget').addEventListener('blur', blurHandler);
    }),

  onHide: (instance) => {
    instance.popper.querySelector('.js-focusTarget').removeEventListener('keydown', keyDownHandler);
    instance.popper.querySelector('.js-focusTarget').removeEventListener('blur', blurHandler);
  },

  onHidden: (instance) => instance.destroy(),
};

Template.lineLengthPopup.onRendered(() => {
  const instance = Template.instance();

  instance.contentRef = instance.find('.lineLengthPopup');
  instance.contentRef.parentNode.removeChild(instance.contentRef);

  instance.autorun(function updateTippyAutorun() {
    const song = Crnt.song();
    if (!song) return;
    const endCell = song.focus.endCell();
    const selectionInProgress = song.dragSelection.exists();
    const pickerVisible = clientModules.lineLengthPicker.visible();
    Tracker.afterFlush(() =>
      Meteor.defer(() => {
        // wait for DOM to be updated first!
        instance.tippy?.hide();
        instance.tippy = null;
        const targetEl = endCell?.layout.chartCell;
        if (targetEl && !selectionInProgress && pickerVisible) {
          instance.tippy = tippyJs(
            targetEl,
            Object.assign(tippyDefaults, {
              content: instance.contentRef,
            })
          );
        }
      })
    );
  });
});

Template.lineLengthPopup.helpers({
  isSlipJig() {
    return Crnt.song()?.timeSignature() == '9/8';
  },
  isWaltz() {
    return Crnt.song()?.timeSignature() == '3/4';
  },
});
