import { chop, downstroke } from '@/band/instruments/mandolin/MandolinActions';
import type { MandolinCore } from '@/band/instruments/mandolin/MandolinCore';
import { swingOptions } from '@/band/swingOptions';

export const WaltzChopMandolinCore: MandolinCore = {
  id: 'cw',
  title: 'Offbeat chop',

  timeSignatures: ['3/4'],

  linkedSettingsKeys: ['chopDynamics'],
  otherSettingsKeys: ['timing'],

  chordStyle: 'bluegrass',

  swingCategory: 'none',
  defaultSwing: swingOptions['3:2'],

  presets: [
    {
      name: 'Basic chop',
      settings: {
        'chopDynamics': { i: 1, s: 1 },
        'chopAccents': { r: 1, theta: 0.25 },
      },
    },
    {
      name: 'Soft voiced chop',
      settings: {
        'chopDynamics': { i: 0, s: 2 },
        'chopAccents': { r: 1, theta: 0.25 },
      },
    },
  ],

  // measurePreprocessing(measures, settings, _flags) {
  //   assignMandolinRhythmAccents({ measures, settings });
  // },

  processMeasure(measure, settings, _state) {
    const intensity = settings.chopDynamics.intensityIndex;
    const chopSustain = settings.chopDynamics.sustainIndex;

    const theChop = chop({
      db: [1, 0, -4][chopSustain] as number,
      attack: ['soft', 'normal', 'hard'][intensity] as 'soft' | 'normal' | 'hard',
      chordSpread:
        0.75 * Math.random() +
        ([1.5, 2.25, 3.2] as const)[intensity] +
        ([0, -0.25, 0.5] as const)[chopSustain],
      chordDuration: [0, 0.03, 0.2][chopSustain] as number,
      chordAttenuation: ([-3, -1, 0] as const)[intensity] + ([-9, -4, 4] as const)[chopSustain],
    });

    const intents = measure.cells.flatMap(() => {
      return [null, null, theChop, null, theChop, null];
    });

    if (measure.endOfSong) {
      return [
        ...intents.slice(0, (measure.cells.length - 1) * 6),
        downstroke({
          db: 0,
          velocity: 3,
          chordSpread: 3,
        }),
      ];
    }

    return intents;
  },

  generateViz(settings) {
    const chick = ([true, 'partial', false] as const)[settings.chopDynamics.sustainIndex];
    const intensity = settings.chopDynamics.intensityIndex;
    const chopThickness = ([0.65, 0.8, 0.95] as const)[intensity];
    const chopTail = ([0.6, 0.8, 1.0] as const)[intensity];

    return [
      null,
      null,
      {
        dir: 'd',
        biasY: 0.3,
        tail: chopTail,
        thickness: chopThickness,
        sustain: 0,
        chick: chick,
      },
      null,
      {
        dir: 'd',
        biasY: 0.3,
        tail: chopTail,
        thickness: chopThickness,
        sustain: 0,
        chick: chick,
      },
      null,
    ];
  },
};
