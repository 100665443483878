import type { BandPreset } from '@/band/presets/BandPreset';
import { migrateSerializedBandSettings } from '@/band/settings/migrateSerializedBandSettings';

export function createAuthorPreset({
  band,
  timeSignature,
  name,
}: {
  band: SerializedBandPreset;
  timeSignature: TimeSignature;
  name: string;
}): BandPreset {
  return Object.freeze({
    id: `author_settings`,
    settingsFromAuthor: true,
    name: `${name}'s settings`,
    timeSignature: timeSignature,
    band: migrateSerializedBandSettings(band ?? {}),
  } as BandPreset);
}
