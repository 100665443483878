import { Mixer } from '@/audio/engine/base/Mixer';
import { FMBChannel } from '@/audio/engine/fmb/FMBChannel';
import { FMBMasterChannel } from '@/audio/engine/fmb/FMBMasterChannel';

export class FMBMixer extends Mixer {
  readonly masterChannel: FMBMasterChannel;

  constructor() {
    super();
    this.masterChannel = new FMBMasterChannel();
  }

  protected _createChannel(channelId: string): FMBChannel {
    return new FMBChannel(channelId);
  }
}
