import { ReactiveVar } from 'meteor/reactive-var';
import { rpcSendPartingFeedback } from '@/server/methods/contact/rpcSendPartingFeedback';

Template.partingFeedbackForm.onCreated(() => {
  const instance = Template.instance();
  instance.submitting = new ReactiveVar(false);
  instance.submitted = new ReactiveVar(false);
});

Template.partingFeedbackForm.helpers({
  submittingFeedback() {
    return Template.instance().submitting.get();
  },
  feedbackSubmitted() {
    return Template.instance().submitted.get();
  },
});

Template.partingFeedbackForm.events({
  'submit form': async function (event, instance) {
    event.preventDefault();
    instance.submitting.set(true);
    try {
      await rpcSendPartingFeedback({
        name: instance.data.name,
        email: instance.data.email,
        message: instance.find('#feedback').value,
      });
      instance.submitted.set(true);
    } catch (err) {
      bootbox.alert(
        'Unable to send feedback. Please copy your message and paste it into an email addressed to support@strummachine.com. Sorry for the inconvenience!'
      );
    }
    instance.submitting.set(false);
  },
});
