import * as Sentry from '@sentry/browser';
import { Meteor } from 'meteor/meteor';
import { Mongo } from 'meteor/mongo';
import { ReactiveDict } from 'meteor/reactive-dict';
import { ReactiveVar } from 'meteor/reactive-var';
import { Tracker } from 'meteor/tracker';
import { DemoMode } from '@/client/DemoMode';
import { checkOfflineStorageAvailable } from '@/local-db/checkOfflineStorageAvailable';
import { GroundedCollection } from '@/local-db/GroundedCollection';
import localStorageDBNames from '@/local-db/localStorageDBNames';
import MilestoneCodes from '@/user/MilestoneCodes';
import type { UserPreference, UserPreferenceKey as PrefKey } from '@/user/UserPreferences';
import UserPreferences from '@/user/UserPreferences';
import { callServerMethodWithoutRetry } from '@/utilities/callServerMethodWithoutRetry';
import waitUntilReactive from '@/utilities/waitUntilReactive';

type PendingUserChangeRecord = {
  _id: string;
  userId: string;
  type: 'record-milestone' | 'read-announcement' | 'hide-message' | 'set-preference';
  code?: string;
  key?: string;
  value?: string | number | boolean | null | undefined;
};

let PendingUserChanges:
  | Mongo.Collection<PendingUserChangeRecord>
  | GroundedCollection<PendingUserChangeRecord> = new Mongo.Collection(null); // this *must* be var, not let!

void checkOfflineStorageAvailable().then((available) => {
  if (available) {
    const livePUC = PendingUserChanges;
    PendingUserChanges = new GroundedCollection(localStorageDBNames.usersUpdates, {
      // @ts-expect-error GroundedCollection doesn't have async methods yet
      collection: livePUC,
    });
  } else {
    // No offline support? No problem.
  }
});

/**
 ***  Milestones
 * */

// UserProfile.milestones = () => Tracker.nonreactive(() => {
//   const live = Meteor.user("milestones")?.milestones || [];
//   const pending = PendingUserChanges.find({ userId: Meteor.userId(), type: 'record-milestone' }).map(r => r.code);
//   return _.union(live, pending);
// });

const anonymousMilestones: (typeof MilestoneCodes)[keyof typeof MilestoneCodes][] = [];
const anonymousMilestonesDep = new Tracker.Dependency();

function milestoneIs(code: keyof typeof MilestoneCodes, value: boolean) {
  if (DemoMode.active()) {
    anonymousMilestonesDep.depend();
    return anonymousMilestones.includes(MilestoneCodes[code]) == value;
  }
  const user = Meteor.user('milestones');
  if (!user) return false; // was undefined, but really this means that "no, it's not == value"
  const inUserObject = user.milestones?.includes(MilestoneCodes[code]);
  const willBeSynced = !!PendingUserChanges.find({
    userId: user._id,
    type: 'record-milestone',
    code: MilestoneCodes[code],
  }).fetch()[0];
  return (inUserObject || willBeSynced) == value;
}

class UserProfileSingleton {
  hasMilestone = (code: keyof typeof MilestoneCodes) => milestoneIs(code, true);
  notMilestone = (code: keyof typeof MilestoneCodes) => milestoneIs(code, false);

  recordMilestone = (code: keyof typeof MilestoneCodes) =>
    Tracker.nonreactive(() => {
      if (this.notMilestone(code)) {
        if (DemoMode.active()) {
          anonymousMilestones.push(MilestoneCodes[code] ?? '');
          anonymousMilestonesDep.changed();
        } else {
          const userId = Meteor.userId();
          if (userId) {
            PendingUserChanges.insert({
              userId,
              type: 'record-milestone',
              code: MilestoneCodes[code],
            });
          }
        }
      }
    });

  /**
   ***  Announcement memory
   * */

  announcementsRead() {
    const live = Meteor.user('profile.announcementsRead')?.profile?.announcementsRead || [];
    const userId = Meteor.userId();
    const pending = userId
      ? PendingUserChanges.find({
          userId,
          type: 'read-announcement',
        }).map((r) => r.code)
      : [];
    return [...new Set([...live, ...pending])];
  }

  readAnnouncement(code: string) {
    Tracker.nonreactive(() => {
      const userId = Meteor.userId();
      if (userId && !this.announcementsRead().includes(code)) {
        PendingUserChanges.insert({ userId, type: 'read-announcement', code });
      }
    });
  }

  /**
   ***  Message hiding/showing
   * */

  isMessageHidden(code: string) {
    const userId = Meteor.userId();
    return (
      userId &&
      (Meteor.user('profile.hiddenMessages')?.profile?.hiddenMessages?.includes(code) ||
        !!PendingUserChanges.findOne({ userId, type: 'hide-message', code }))
    );
  }

  hideMessage(code: string) {
    Tracker.nonreactive(() => {
      const userId = Meteor.userId();
      if (userId && !this.isMessageHidden(code)) {
        PendingUserChanges.insert({ userId, type: 'hide-message', code });
      }
    });
  }

  /**
   ***  Preferences
   * */

  anonymousPreferences = new ReactiveDict();

  preferences() {
    const userId = Meteor.userId();
    if (DemoMode.active()) {
      return this.anonymousPreferences.all();
    } else if (userId) {
      const live = Meteor.user('profile.preferences')?.profile?.preferences || {};
      const pendingChangesArray = PendingUserChanges.find({
        userId,
        type: 'set-preference',
      }).fetch();
      const pending = pendingChangesArray.reduce<Record<string, any>>((obj, item) => {
        if (item.key) obj[item.key] = item.value;
        return obj;
      }, {});
      return { ...live, ...pending };
    }
    return undefined; // Should this be an empty object instead? Who knows? Gotta check all refs but I'm too lazy right now.
  }

  setPreference(key: PrefKey, value: UserPreference<PrefKey>['default']) {
    Tracker.nonreactive(() => {
      const userId = Meteor.userId();
      if (typeof UserPreferences.settings[key]?.default === 'number' && typeof value == 'string')
        value = +value; // coerce numbers
      if (DemoMode.active()) {
        this.anonymousPreferences.set(key, value);
      } else if (userId) {
        PendingUserChanges.upsert(
          { userId, type: 'set-preference', key },
          { userId, type: 'set-preference', key, value }
        );
      }
    });
  }

  getPreference(key: PrefKey): UserPreference<PrefKey>['default'] {
    let value;
    if (DemoMode.active()) {
      value = this.anonymousPreferences.get(key) as UserPreference<PrefKey>['default'];
    } else if (Meteor.userId()) {
      const user = Meteor.user(`profile.preferences.${key}`);
      if (user?.profile) {
        const existingValue = user.profile.preferences?.[key];
        const pendingChange = PendingUserChanges.find({
          userId: user._id,
          type: 'set-preference',
          key,
        }).fetch()[0];
        value = pendingChange ? pendingChange.value : existingValue;
      }
    }
    const setting = UserPreferences.settings[key];
    return typeof value === 'undefined'
      ? 'defaultFunc' in setting
        ? setting.defaultFunc()
        : setting.default
      : value;
  }
}

Meteor.defer(function startOfflineUpdateSyncWhenReady() {
  const checkReady = () => {
    if ('loaded' in PendingUserChanges && !PendingUserChanges.loaded()) return false;
    return PendingUserChanges.find().count() > 0;
  };
  void waitUntilReactive(checkReady).then(startOfflineUpdateSync);
});

const updateTypesToMethodNames = {
  'record-milestone': 'user.milestones.record',
  'hide-message': 'user.hiddenMessages.add',
  'set-preference': 'user.preferences.set',
  'read-announcement': 'user.announcements.read',
};

const startOfflineUpdateSync = () =>
  Tracker.nonreactive(() => {
    // otherwise it gets shut down by Tracker further up the chain
    const syncInProgress = new ReactiveVar(false);
    Tracker.autorun(function songLibraryUpdateAutorun() {
      if (syncInProgress.get()) return;
      if (!Meteor.status().connected) return; // can't do this offline!
      const userId = Meteor.userId();
      if (!userId) return; // don't do this if not logged in
      const updateRecords = PendingUserChanges.find({ userId }).fetch();
      if (updateRecords.length === 0) return;
      syncInProgress.set(true);
      void (async function syncUser() {
        try {
          await Promise.all(
            updateRecords.map(async (record) => {
              const methodName = updateTypesToMethodNames[record.type];
              const methodArgs =
                record.type == 'set-preference'
                  ? [{ key: record.key, value: record.value }]
                  : [record.code];
              await callServerMethodWithoutRetry(methodName, ...methodArgs);
              PendingUserChanges.remove(record); // will only remove if all fields (still) match
            })
          );
          Meteor.setTimeout(() => syncInProgress.set(false), 2000);
        } catch (error) {
          Meteor.setTimeout(() => syncInProgress.set(false), 20000);
          console.error(error);
          Sentry.captureException(error);
          Bert.alert('Error while syncing user data to the cloud. Will try again in 20 seconds.');
        }
      })();
    });
  });

export const UserProfile = new UserProfileSingleton();
