import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { Tracker } from 'meteor/tracker';
import { FlowRouterSEO } from '@/browser/FlowRouterSEO';
import { Crnt } from '@/Crnt';

const flowRouterSEO = new FlowRouterSEO({
  defaults: {
    title: 'Strum Machine',
  },
});

const transformRouteString = (string: string, name?: string) => {
  return name ? string.replace('{songName}', name) : string.replace(/\{songName\}( . )?/, '');
};

export function setPageTitleAndMeta(): void {
  const settings = {
    title: FlowRouter.current().route.options.title || 'Strum Machine',
    description: FlowRouter.current().route.options.description || '',
    noindex: FlowRouter.current().route.options.noindex ? true : undefined,
  };

  const musicIdRequested = FlowRouter.getParam('songId') || FlowRouter.getParam('medleyId');
  if (musicIdRequested) {
    Tracker.autorun((comp) => {
      const currentMusicId = FlowRouter.getParam('songId') || FlowRouter.getParam('medleyId');
      if (currentMusicId != musicIdRequested) {
        return void comp.stop();
      }
      const songName = Crnt.musicTitle();
      if (songName) comp.stop();
      flowRouterSEO.set({
        title: transformRouteString(settings.title, songName),
        description: transformRouteString(
          settings.description,
          (songName || '').replace(/ +\[.+\]$/, '')
        ),
        noindex: settings.noindex,
      });
    });
  } else {
    flowRouterSEO.set(settings);
  }
}
