import { Chord } from '@/music/Chord';

export class CellBeat {
  constructor({ chord, effect }: { chord?: Chord | string; effect?: 'rest' | 'stop' | 'diamond' }) {
    if (typeof chord == 'string') chord = new Chord(chord);
    this.#chord = chord;
    this.#effect = effect;
    this.#chordHeard = this.#chordShown = this.#chord;
  }

  #chord: Chord | undefined = $state();
  // eslint-disable-next-line @typescript-eslint/related-getter-setter-pairs
  get chord(): Chord | undefined {
    return this.#chord;
  }
  set chord(chord: Chord) {
    this.#chord = chord;
    this.#effect = undefined;
  }

  #effect: 'rest' | 'stop' | 'diamond' | undefined = $state();
  get effect(): SerializedCell['effect'] {
    return this.#effect;
  }
  set effect(effect: this['effect']) {
    this.#effect = effect;
  }

  #chordHeard: Chord | undefined = $state();
  get chordHeard(): Chord | undefined {
    return this.#chordHeard;
  }
  #chordShown: Chord | undefined = $state();
  get chordShown(): Chord | undefined {
    return this.#chordShown;
  }

  updateChordHeardAndShown({
    keyCharted,
    keyHeard,
    keyShown,
  }: {
    keyCharted: Key;
    keyHeard: Key;
    keyShown: Key;
  }): void {
    if (!this.#chord) return;
    const noCapo = keyHeard == keyShown;
    this.#chordHeard = new Chord(this.#chord, keyCharted).transpose(keyHeard);
    this.#chordShown = noCapo ? this.#chordHeard : this.#chordHeard.transpose(keyShown);
  }
}
