import { eventTracker } from '@/browser/analytics/eventTracker';
import { Crnt } from '@/Crnt';
import { UserProfile } from '@/user/UserProfile';

Template.autoToolsStacked.onRendered(() => {
  const instance = Template.instance();
  instance.find('.autoToolsStacked')._uihooks = {
    insertElement(node, next) {
      $(node)
        .insertBefore(next)
        .hide()
        .velocity(
          { opacity: [1, 0] },
          {
            duration: 300,
            easing: [0.4, 0, 0.4, 1],
          }
        )
        .velocity('slideDown', {
          duration: 300,
          easing: [0.4, 0, 0.4, 1],
          queue: false,
        });
    },
    removeElement(node) {
      $(node)
        .velocity(
          { opacity: [0, 1] },
          {
            duration: 300,
            easing: [0.4, 0, 0.4, 1],
          }
        )
        .velocity('slideUp', {
          duration: 300,
          easing: [0.4, 0, 0.4, 1],
          queue: false,
          complete: () => $(node).remove(),
        });
    },
  };
});

Template.autoToolsStacked.helpers({
  autoSpeedupEnabled() {
    return Crnt.song()?.prefs.autoSpeedup.enabled();
  },

  autoFinishEnabled() {
    return Crnt.song()?.prefs.autoFinish.enabled() || Crnt.list()?.autoAdvanceEnabled();
  },

  autoAdvanceEnabled() {
    return Crnt.list()?.autoAdvanceEnabled();
  },
});

Template.autoToolsStacked.events({
  'click .js-toggleAF': function (event, instance) {
    if (Crnt.list()?.autoAdvanceEnabled()) {
      Crnt.list()?.disableAutoAdvance();
      eventTracker.autoAdvanceDisable({ fromAutoFinish: true });
      return;
    }
    if (Crnt.song()?.prefs.autoFinish.enabled()) {
      Crnt.song()?.prefs.autoFinish.disable();
      eventTracker.autoFinishDisable();
    } else {
      Crnt.song()?.prefs.autoFinish.enable();
      UserProfile.recordMilestone('AUTO_FINISH_SET');
      eventTracker.autoFinishEnable();
    }
  },

  'click .js-toggleAS': function (event, instance) {
    if (Crnt.song()?.prefs.autoSpeedup.enabled()) {
      Crnt.song()?.prefs.autoSpeedup.disable();
      eventTracker.autoSpeedupDisable();
    } else {
      Crnt.song()?.prefs.autoSpeedup.enable();
      eventTracker.autoSpeedupEnable();
      UserProfile.recordMilestone('AUTO_SPEEDUP_SET');
    }
  },
});
