import type { EQ, EQBand } from '@/audio/engine/base/EQ';
import type { Playback } from '@/audio/engine/base/Playback';

export abstract class Channel {
  readonly id: string;
  readonly playbacks: Playback[] = [];
  readonly eq!: EQ;

  constructor(id: string, _eqBands?: EQBand[]) {
    this.id = id;
  }

  abstract set volume(val: number);
  abstract get volume(): number;

  abstract set pan(val: number);
  abstract get pan(): number;

  abstract set muted(val: boolean);
  abstract get muted(): boolean;

  abstract set attentuate(val: boolean);
  abstract get attentuate(): boolean;

  async stopAll(): Promise<void> {
    await Promise.all(this.playbacks.map((pb) => pb.stop()));
  }

  async destroyAll(): Promise<void> {
    await Promise.all(this.playbacks.map((pb) => pb.destroy()));
  }

  addPlayback(playback: Playback): void {
    this._linkPlaybackToChannel(playback);
    this.playbacks.push(playback);
    playback.addListener('destroyed', this._handlePlaybackDestroyed, this);
  }

  protected abstract _linkPlaybackToChannel(playback: Playback): void;

  private _handlePlaybackDestroyed(e: Record<string, any>): void {
    const index = this.playbacks.indexOf(e.target);
    if (index > -1) {
      this.playbacks.splice(index, 1);
    }
  }
}
