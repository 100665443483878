import type { SelectionEditor } from '@/chart/SelectionEditor';
import { Crnt } from '@/Crnt';
import { HearAsYouEdit } from '@/editor/HearAsYouEdit';
import { chartEditingCommands } from '@/keyboard/chartEditingCommands';
import { Chord } from '@/music/Chord';
import { majorScaleIntervals } from '@/music/music-knowledge';
import UserPreferences from '@/user/UserPreferences';

const bindingsMap = Object.values(chartEditingCommands).reduce((map, command) => {
  command.bindings.forEach((binding) => map.set(binding, command.action));
  return map;
}, new Map<string, (typeof chartEditingCommands)[number]['action']>());

const chordTypeToggles = {
  'm': 'toggleMinor',
  '^': 'toggleMaj7',
  '0': 'toggleHalfDiminished',
  'o': 'toggleDiminished',
  'O': 'toggleDiminished',
  // '+': 'toggleAugmented',
  's': 'toggleSuspended',
} as const satisfies Record<string, keyof SelectionEditor>;

const chordTypeTogglesInLettersMode = {
  '#': 'toggleSharp',
  '9': 'toggleNine',
  '7': 'toggleSeven',
  '6': 'toggleSix',
  '2': 'toggleSus2',
  '4': 'toggleSus4',
  '5': 'toggleFive',
} as const satisfies Record<string, keyof SelectionEditor>;

function handleLetterPress(letter: string) {
  const song = Crnt.song();
  if (!song) return;
  if (letter == 'b') {
    const implied = song.focus.firstCell()?.implied;
    const selectedChord = song.focus.firstSelectedChordNoSplitFallback();
    if (!implied && selectedChord && !selectedChord.flat) {
      song.withFocused().toggleFlat();
      if (song.focus.homogenousChordSelection()) HearAsYouEdit.trigger();
      return;
    }
  }
  if (/^[a-g]$/i.test(letter)) {
    song.withFocused().setChord(letter.toUpperCase());
    HearAsYouEdit.trigger();
  }
}

function handleNumberPress(number: string) {
  const song = Crnt.song();
  const keyString = song?.key();
  if (!song || !keyString) return;
  if (['1', '2', '3', '4', '5', '6', '7'].includes(number)) {
    const keyChord = new Chord(keyString, keyString);
    const interval = majorScaleIntervals[+number - 1] ?? 0;
    song.withFocused().setChord(keyChord.shift(interval));
    HearAsYouEdit.trigger();
  }
}

export function editModeKeyDownHandler(event: KeyboardEvent) {
  if (Modal.visible()) return true;
  const rawKey = event.key == ' ' ? 'Spacebar' : event.key;

  const noModsOrOnlyShift = !event.ctrlKey && !event.metaKey && !event.altKey;
  const noMods = !event.ctrlKey && !event.metaKey && !event.altKey && !event.shiftKey;

  const cmdCtrl =
    /^Mac/.test(navigator.platform) || /iP(hone|ad|od)/.test(navigator.userAgent)
      ? !event.ctrlKey && event.metaKey
      : event.ctrlKey && !event.metaKey;
  const cmdOnApple =
    /^Mac/.test(navigator.platform) || /iP(hone|ad|od)/.test(navigator.userAgent)
      ? event.metaKey
      : false;

  const key =
    noModsOrOnlyShift && rawKey.length == 1
      ? rawKey
      : [
          cmdCtrl ? 'CmdCtrl' : '',
          // event.metaKey ? 'Meta' : '',
          // event.ctrlKey ? 'Ctrl' : '',
          event.shiftKey ? 'Shift' : '',
          event.altKey ? 'Alt' : '',
          rawKey.length > 1 ? rawKey : rawKey.toUpperCase(),
        ]
          .filter(Boolean)
          .join('+');

  const chordSystem = UserPreferences.get('chordSystem');

  const song = Crnt.song();
  if (!song) return true;

  if (
    event.target instanceof HTMLInputElement ||
    event.target instanceof HTMLSelectElement ||
    event.target instanceof HTMLTextAreaElement
  )
    return true;

  const mainFocus = song.focus.firstCell();
  if (mainFocus) {
    const action =
      bindingsMap.get(key) ??
      (cmdOnApple ? bindingsMap.get(key.replace('CmdCtrl', 'Cmd')) : undefined);
    if (action) {
      action();
      return false;
    }

    if (/^[a-g]$/i.test(key) && chordSystem == 'letters') {
      handleLetterPress(key);
      return false;
    }

    if (/^[1-7]$/i.test(key) && chordSystem != 'letters') {
      handleNumberPress(key);
      return false;
    }

    if (
      (key == 'b' || key == '#') &&
      chordSystem != 'letters' &&
      song.focus.firstSelectedChordNoSplitFallback() // guarding against split-beat selection
    ) {
      song.withFocused().shiftSemitones(key == 'b' ? -1 : 1, song.key() ?? '');
      if (song.focus.homogenousChordSelection()) HearAsYouEdit.trigger();
      return false;
    }

    // after handling lowercase b
    if (/^[a-g]$/i.test(key) && chordSystem != 'letters') {
      handleLetterPress(key);
      return false;
    }

    const toggleCommand =
      key in chordTypeToggles
        ? chordTypeToggles[key as keyof typeof chordTypeToggles]
        : key in chordTypeTogglesInLettersMode && chordSystem == 'letters'
          ? chordTypeTogglesInLettersMode[key as keyof typeof chordTypeTogglesInLettersMode]
          : undefined;

    if (toggleCommand) {
      song.withFocused()[toggleCommand]();
      if (song.focus.homogenousChordSelection()) HearAsYouEdit.trigger();
      return false;
    }
  }

  if ((key == 'Enter' || key == 'NumpadEnter' || key == 'MediaPlayPause') && noMods) {
    if (event.target instanceof HTMLAnchorElement || event.target instanceof HTMLButtonElement)
      return true;
    $('.js-global-editPreviewPlayStop').click();
    return false;
  }

  return true;
}
