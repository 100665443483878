<script lang="ts">
  import { renderChordAsHtml } from '@/chart/ui/renderChordAsHtml';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import type { Chord } from '@/music/Chord';
  import UserPreferences from '@/user/UserPreferences';

  interface Props {
    chord: Chord | undefined;
    selectedChords?: Chord[];
  }

  let { chord, selectedChords = [] }: Props = $props();

  let chordSystem = $state() as 'letters' | 'numbers' | 'roman';
  trackerEffect(() => (chordSystem = UserPreferences.get('chordSystem')));
</script>

{#if selectedChords.length > 1 && selectedChords.some((c) => !c.equals(selectedChords[0]))}
  <div class="text-lg text-gray-500">Multiple chords selected</div>
{:else if chord}
  <div class="flex items-baseline gap-3">
    <div class="text-3xl">
      {@html renderChordAsHtml(chord, { system: chordSystem })}
    </div>
    {#if chordSystem !== 'letters'}
      <div class="text-3xl opacity-60">
        <span class="inline-block origin-center scale-x-75 scale-y-110 opacity-80">(</span
        >{@html renderChordAsHtml(chord, {
          system: 'letters',
        })}<span class="ml-[1px] inline-block origin-center scale-x-75 scale-y-110 opacity-80"
          >)</span
        >
      </div>
    {/if}
  </div>
{:else}
  <div class="font-sm text-gray-700">Select a cell to edit first.</div>
{/if}
