import { ClientManager } from '@/browser/ClientManager';
import { MusicLibrary } from '@/library/MusicLibrary';

Template.offlineModeButton.onCreated(() => {
  const instance = Template.instance();

  if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
    // Make sure data is loaded right after offline mode is enabled:
    MusicLibrary.load();
  }

  instance.spaceKnown = new ReactiveVar(false);
  ClientManager.getStorageSpace().then((space) => {
    instance.spaceKnown.set(true);
    instance.space = space;
    if (!space.quota || space.quota > 40000000) {
      instance.hasSufficientSpace = true;
    }
  });

  instance.persistPrompt = new ReactiveVar();
  if (ClientManager.askForPersistentStorage() == 'prompt') {
    instance.persistPrompt.set(true);
  }
});

const isFirefox = /Firefox\//i.test(navigator.userAgent);

Template.offlineModeButton.events({
  'click .js-swEnable': function (event, instance) {
    if (instance.persistPrompt.get() && isFirefox) {
      bootbox.alert({
        title: 'Permission needed',
        message:
          'Strum Machine needs a relatively small amount of persistent storage (under 50 MB) to store its data and audio. Please click "Allow" in the Firefox prompt to continue.',
      });
    }
    if (navigator.storage && navigator.storage.persist && isFirefox) {
      navigator.storage
        .persist()
        .then(() => ClientManager.installServiceWorker())
        .catch(() => ClientManager.installServiceWorker());
    } else {
      ClientManager.installServiceWorker();
    }
    event.preventDefault();
  },
  'click .js-swDisable': function (event, instance) {
    navigator.serviceWorker.controller.postMessage('selfDestruct');
    clientModules.splashScreen.showUninstallMessage();
    event.preventDefault();
  },
});

Template.offlineModeButton.helpers({
  serviceWorkerSupportedButNotInstalled() {
    return 'serviceWorker' in navigator && !navigator.serviceWorker.controller; // we don't want this to be reactive!
  },
  serviceWorkerActive() {
    return 'serviceWorker' in navigator && navigator.serviceWorker.controller; // we don't want this to be reactive!
  },

  showCheckingForSpace() {
    return !Template.instance().spaceKnown.get();
  },
  showNotEnoughSpace() {
    return Template.instance().spaceKnown.get() && !Template.instance().hasSufficientSpace;
  },
  quotaMB() {
    return (
      Template.instance().spaceKnown.get() &&
      `${(Template.instance().space.quota / 1000000).toFixed(1)} MB`
    );
  },
  showEnableButton() {
    return Template.instance().spaceKnown.get() && Template.instance().hasSufficientSpace;
  },
  serviceWorkerRegistrationStarted() {
    return ClientManager.serviceWorkerRegistrationStarted() || ClientManager.serviceWorkerActive();
  },

  persistPrompt() {
    return Template.instance().persistPrompt.get();
  },

  groundedDataReady() {
    const dataGrounded = () => MusicLibrary.offlineReady();
    /* HelpLibrary.offlineReady() && Meteor.users.offlineReady() */
    return dataGrounded();
  },
  deviceName() {
    if (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) {
      return 'iPad';
    }
    if (/iP(ad|od|hone)/i.test(navigator.userAgent)) {
      return /iP(ad|od|hone)/i.exec(navigator.userAgent)[0];
    }
    if (/^Win/i.test(window.navigator.platform)) return 'computer';
    if (/^Mac/i.test(window.navigator.platform)) return 'Mac';
    return 'device';
  },
});
