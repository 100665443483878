<script lang="ts">
  import GlobalInstrumentMixerRow from '@/audio/mixer/ui/GlobalInstrumentMixerRow.svelte';
  import type { Band } from '@/band/Band';
  import { Crnt } from '@/Crnt';
  import HelpWindow from '@/help/HelpWindow';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import { slideFade } from '@/ui/svelte-transitions';
  import UserPreferences from '@/user/UserPreferences';
  import { UserProfile } from '@/user/UserProfile';

  let band: Band | undefined = $state();
  trackerEffect(() => (band = Crnt.song()?.band));

  const allInstrumentIds = ['guitar', 'bass', 'mandolin', 'feet'] as InstrumentId[];

  let clickMuteBand: boolean = $state(false);
  trackerEffect(
    () => (clickMuteBand = !!band && band.clickTrack.enabled() && band.clickTrack.mutingBand())
  );

  let activeInstrumentIds: InstrumentId[] = $state([]);
  trackerEffect(
    () =>
      (activeInstrumentIds = clickMuteBand
        ? []
        : band
          ? band.activeInstruments()
          : allInstrumentIds)
  );

  let inactiveInstrumentIds: InstrumentId[] = $state([]);
  trackerEffect(
    () =>
      (inactiveInstrumentIds = clickMuteBand
        ? allInstrumentIds
        : band
          ? band.inactiveInstruments()
          : [])
  );

  let showDefaultBandNotice: boolean | undefined = $state();
  trackerEffect(() => (showDefaultBandNotice = !UserProfile.isMessageHidden('mixer-default-band')));
</script>

{#if clickMuteBand}
  <div
    class="mb-4 flex items-center rounded-lg border border-yellow-400 bg-yellow-100 p-4"
    transition:slideFade
  >
    <div>All instruments are currently being muted by the Metronome feature.</div>
    <div>
      <button class="btn" onclick={() => UserPreferences.set('clickMuteBand', false)}>
        Stop muting the band
      </button>
    </div>
  </div>
{/if}

<ul class="IM_list">
  {#each activeInstrumentIds as instId (instId)}
    <li>
      <GlobalInstrumentMixerRow {band} {instId} />
    </li>
  {/each}
</ul>

{#if inactiveInstrumentIds.length > 0 && !clickMuteBand}
  <h3 class="legacy-h3">Not used in the current band:</h3>
{/if}

<ul class="IM_list">
  {#each inactiveInstrumentIds as instId (instId)}
    <li>
      <GlobalInstrumentMixerRow {band} {instId} inactive={true} />
    </li>
  {/each}
</ul>

{#if showDefaultBandNotice}
  <div class="IM_defaultBandNotice">
    <div>
      Want to change the default instrumentation?
      <a
        href="/help/edit-default-band"
        onclick={() => {
          Modal.hide();
          setTimeout(() => HelpWindow.show('edit-default-band'), 200);
          return false;
        }}
        style="font-weight: 500">Here’s&nbsp;how</a
      >.
    </div>
    <button
      onclick={() => {
        UserProfile.hideMessage('mixer-default-band');
      }}
      type="button"
      class="close"
      aria-label="Close message"><i class="smi smi-close" aria-hidden="true"></i></button
    >
  </div>
{/if}

<style>
  h3 {
    margin: 1.25rem 0 0.75rem;
    color: var(--gray-600);
    font-size: 16px;
    text-align: center;
    font-weight: 400;
  }

  .IM_list {
    > li + li {
      margin-top: 0.75rem;
    }
  }

  .IM_defaultBandNotice {
    font-size: 14px;
    color: hsl(50, 10%, 40%);
    background: hsl(60, 50%, 95%);
    border: solid 1px hsl(60, 60%, 75%);
    padding: 0.75rem 1.25rem;
    margin: 1rem 0;
    border-radius: 0.5rem;
    text-align: center;
    display: flex;
    align-items: center;
    > :first-child {
      flex: 1 1 auto;
    }
    > .close {
      font-size: 1.26rem;
    }
  }
</style>
