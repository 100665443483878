import SongMenuManager from '@/ui/SongMenuManager';

const viewportResizeDep = new Tracker.Dependency();
$(window).on(
  'resize',
  _.throttle(() => viewportResizeDep.changed(), 150)
);
$(window).on(
  'orientationchange',
  _.throttle(() => viewportResizeDep.changed(), 300)
);

Template.musicPageSidebarMenuWrapper.onCreated(() => {
  const instance = Template.instance();
  instance.lastShownTemplate = new ReactiveVar();
});

Template.musicPageSidebarMenuWrapper.onRendered(() => {
  const instance = Template.instance();
  instance.autorun(() => {
    const currentTemplate = Template.currentData().template;
    const wrapperEl = instance.find('.js-wrapper');

    // temporarily reset properties
    wrapperEl.style.maxHeight = '100vh';
    wrapperEl.style.top = '80px';

    Meteor.setTimeout(() => instance.lastShownTemplate.set(currentTemplate), 300);

    Tracker.afterFlush(() => {
      instance.$('.js-initialFocus').trigger('focus');
      const bufferRoom =
        /iP(hone|ad|od)/i.test(navigator.userAgent) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
          ? 80
          : 25;
      const openerButton = $(`.js-menuToggle[data-menu-id="${currentTemplate}"]`);
      const top = openerButton.length > 0 ? openerButton.offset().top : 80;
      const adjustedTop = Math.max(
        Math.min(top, window.innerHeight - wrapperEl.scrollHeight - bufferRoom),
        60
      );
      wrapperEl.style.top = `${adjustedTop}px`;
      wrapperEl.style.maxHeight = `${window.innerHeight - adjustedTop - 5}px`;
    });
  });
  instance.autorun(() => {
    viewportResizeDep.depend();
    const wrapperEl = instance.find('.js-wrapper');
    wrapperEl.style.maxHeight = `${window.innerHeight - parseInt(wrapperEl.style.top, 10) - 5}px`;
  });
});

Template.musicPageSidebarMenuWrapper.helpers({
  animating() {
    return Template.instance().lastShownTemplate.get() != this.template;
  },
  title() {
    return this.title || SongMenuManager.getTitleFor(this.template);
  },
  sidebarVisible() {
    return SongMenuManager.sidebarVisible();
  },
});

Template.musicPageSidebarMenuWrapper.events({
  'click .js-close': function (event, instance) {
    SongMenuManager.showMenu(null);
  },
  sidebarVisible() {
    return SongMenuManager.sidebarVisible();
  },
});
