<script lang="ts">
  import type { Instance as TippyInstance } from 'tippy.js';
  import type { BandPreset } from '@/band/presets/BandPreset';
  import BandHistorySwapIcon from '@/band/ui/panels/BandHistorySwapIcon.svelte';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import { makeTippyMenu } from '@/ui/dropdowns/makeTippyMenu';
  import Icon from '@/ui/icons/Icon.svelte';
  import { ClickTap } from '@/ui-helpers/click-tap';

  const bandMenu = getBandMenuState();
  let band = $derived($bandMenu.band);

  interface Props {
    preset: BandPreset;
  }

  let { preset }: Props = $props();

  let tippyInstance: TippyInstance | undefined;
  function tippyMenu(node: HTMLElement, params: Record<string, unknown> = {}) {
    tippyInstance = makeTippyMenu(node, params, { keysThatShow: [' ', 'Enter'] });
    return { destroy: () => tippyInstance?.destroy() };
  }

  function customizeDefaultPreset() {
    tippyInstance?.hide();
    if (!preset) return;
    band.enterEditPresetMode(preset);
  }

  function revertDefaultPresetPrompt() {
    tippyInstance?.hide();
    band.enterDeletePresetMode();
  }

  let canRestoreCustomBand = $state(false);
  trackerEffect(() => (canRestoreCustomBand = !!$bandMenu.band.previousCustomSettings()));

  let showRedo = $state(false);
  trackerEffect(() => (showRedo = $bandMenu.band.didUndo()));

  function restoreCustomBand() {
    const band = $bandMenu.band;
    band.loadPreviousCustomBand();
  }
</script>

<div use:tippyMenu={{ 'theme': 'light', 'placement': 'bottom-end' }}>
  <button class="btn btn-round scale-125 px-1.5 py-[3px] font-normal" aria-label="Preset options">
    <i class="smi smi-dots-vertical" aria-hidden="true"></i>
  </button>
  <div
    role="menu"
    class="max-w-[16rem] p-1.5 [&_.btn]:flex [&_.btn]:items-center [&_.btn]:px-3 [&_.btn]:py-1.5 [&_.btn]:text-left [&_.btn]:font-normal [&_button]:w-full"
    tabindex="-1"
  >
    <div class="mx-3 mb-3 mt-2 flex">
      <div class="mt-0.5 text-wrap text-sm text-gray-500">
        <div class="font-medium">Two ways to customize the band:</div>
        <ol class="mt-2 list-decimal space-y-1 pl-6 [&_li]:pl-1.5">
          <li>Choose from one of the presets in the Preset Library.</li>
          <li>{ClickTap} on any instrument to customize it directly.</li>
        </ol>
        <div class="mt-2">
          For more help, click the “Learn” link at the bottom of Band Settings.
        </div>
      </div>
    </div>
    {#if canRestoreCustomBand}
      <div class="mx-1 my-1.5 border-t border-panelWellBorder"></div>
      <button class="btn" onclick={restoreCustomBand}>
        <span class="flex w-6 items-center"><BandHistorySwapIcon size={14} {showRedo} /></span>
        Restore last Custom Band
      </button>
    {/if}
    <div class="mx-1 my-1.5 border-t border-panelWellBorder"></div>
    <button class="btn" onclick={customizeDefaultPreset}>
      <span class="w-6"><Icon id="pen" size={14} /></span>
      Edit default band
    </button>
    {#if preset?.customizedDefault}
      <button class="btn btn-danger" onclick={revertDefaultPresetPrompt}>
        <span class="w-6"><Icon id="undo-icon" size={14} /></span>
        Reset default band
      </button>
    {/if}
  </div>
</div>
