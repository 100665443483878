import { strict as assert } from 'assert';
import { getPositionForNoteRelativeToRoot } from '@/band/instruments/guitar/getPositionForNoteRelativeToRoot';
import type { BassRunResult } from '@/band/instruments/guitar/GuitarBassRunLibrary';
import { GuitarBassRunLibrary } from '@/band/instruments/guitar/GuitarBassRunLibrary';
import { GuitarChordLibrary } from '@/band/instruments/guitar/GuitarChordLibrary';
import type { GuitarCore } from '@/band/instruments/guitar/GuitarCore';
import type { GuitarSettingsHash } from '@/band/instruments/guitar/GuitarSettingsHash';
import type { LinearizedCell } from '@/chart/LinearizedCell';
import type { LinearizedMeasure } from '@/chart/LinearizedMeasure';

export function assignGuitarBassRunsAndLeadingNotes({
  measures,
  settings,
  core,
}: {
  measures: readonly LinearizedMeasure[];
  settings: GuitarSettingsHash;
  core: GuitarCore;
}) {
  const playBassRuns =
    core.otherSettingsKeys.includes('bassRuns') && settings.bassRuns.eagerness > 0;
  const playLeadingNotes = core.otherSettingsKeys.includes('bassNotes')
    ? settings.bassNotes.leadingNoteFrequency > 0 &&
      (!core.linkedSettingsKeys.includes('brushiness') ||
        settings.brushiness.boomStrumProbability < 1) &&
      (settings.bassNotes.altBass ||
        (settings.bassNotes.rootOnly && measures[0]?.timeSignature == '3/4'))
    : core.otherSettingsKeys.includes('syncopatedLeadingNotes')
      ? settings.syncopatedLeadingNotes.frequency > 0 &&
        (!core.linkedSettingsKeys.includes('brushiness') ||
          settings.brushiness.boomStrumProbability < 1)
      : false;

  if (!playBassRuns && !playLeadingNotes) return;

  const cellsWithPossibleRuns = getCellsWithPossibleBassRuns(measures);
  cellsWithPossibleRuns.sort(() => Math.random() - 0.5);

  const cellsAndOptions = cellsWithPossibleRuns.reduce(
    (acc, cell) => {
      const fromChord = cell.beats[0].plans.guitar?.chord;
      const toChord = cell.next?.beats[0].plans.guitar?.chord;
      if (!fromChord || !toChord) return acc;

      const sharedOptions = {
        keyChord: cell.measure.keyChord,
        fromChord,
        toChord,
        voicing: settings.openVoicings,
        scale: settings.bassRuns.majorFeel,
      };

      const leadingNoteResults =
        playLeadingNotes &&
        (settings.bassNotes.altBass ||
          (settings.bassNotes.rootOnly && cell.measure.timeSignature == '3/4'))
          ? GuitarBassRunLibrary.getLeadingNotes({
              ...sharedOptions,
            }).filter((result) => result.keyWeighting > 0.3 && result.scaleWeighting > 0.5)
          : [];

      const bassRunResults = playBassRuns
        ? GuitarBassRunLibrary.getRuns({
            ...sharedOptions,
            timeSignature: cell.measure.timeSignature,
            filters: {
              single: false,
              quarters:
                settings.bassRuns.quarterWeight > 0 && !cell.layout.barStart ? undefined : false,
              eighths: settings.bassRuns.eighthWeight > 0 ? undefined : false,
              descending: settings.bassRuns.useDescendingRuns ? undefined : false,
              syncopated: false,
              extended: undefined,
            },
          }).filter((result) => result.keyWeighting > 0.3 && result.scaleWeighting > 0.5)
        : [];

      acc.set(cell, { notes: leadingNoteResults, runs: bassRunResults });
      return acc;
    },
    new Map<
      LinearizedCell,
      {
        notes: ReturnType<(typeof GuitarBassRunLibrary)['getLeadingNotes']>;
        runs: ReturnType<(typeof GuitarBassRunLibrary)['getRuns']>;
      }
    >()
  );

  const maxNumberOfRunsDesired = settings.bassRuns.runsPerMeasure * measures.length;
  const maxNumberOfQuarterRunsDesired =
    settings.bassRuns.runsPerMeasure * settings.bassRuns.quarterWeight * measures.length;
  const maxNumberOfEighthRunsDesired =
    settings.bassRuns.runsPerMeasure * settings.bassRuns.eighthWeight * measures.length;
  const maxNumberOfLeadingNotesDesired =
    measures.length *
    (core.otherSettingsKeys.includes('syncopatedLeadingNotes')
      ? settings.syncopatedLeadingNotes.leadingNotesPerMeasure
      : settings.bassNotes.leadingNotesPerMeasure);

  // TODO: last non-1 chord of section should usually have a run

  let leadingNotesAssigned = 0;
  let quarterRunsAssigned = 0;
  let eighthRunsAssigned = 0;
  cellsAndOptions.forEach(({ notes, runs }, cell) => {
    const fromChord = cell.beats[0].plans.guitar?.chord;
    const toChord = cell.next?.beats[0]?.plans.guitar?.chord;
    if (!fromChord || !toChord) return;

    if (quarterRunsAssigned + eighthRunsAssigned < maxNumberOfRunsDesired && runs.length > 0) {
      const bassRunOptions = [
        (run: BassRunResult) =>
          (run.attrs.eighths && eighthRunsAssigned < maxNumberOfEighthRunsDesired) ||
          (!run.attrs.eighths && quarterRunsAssigned < maxNumberOfQuarterRunsDesired),
        (run: BassRunResult) =>
          (run.attrs.eighths && maxNumberOfEighthRunsDesired > 0) ||
          (!run.attrs.eighths && maxNumberOfQuarterRunsDesired > 0),
      ].reduce<BassRunResult[]>(
        (filteredItems, filter) => (filteredItems.length ? filteredItems : runs.filter(filter)),
        []
      );

      if (bassRunOptions.length === 0) return;
      const chosenBassRun = bassRunOptions[Math.floor(Math.random() * bassRunOptions.length)];
      assert(chosenBassRun, 'Chosen bass run is undefined');
      (cell.plans.guitar ??= {}).bassRun = {
        intervals: chosenBassRun.intervals,
        ...chosenBassRun.attrs,
      };

      if (chosenBassRun.attrs.eighths) eighthRunsAssigned++;
      else quarterRunsAssigned++;

      let lastInterval: number | undefined;
      cell.beats.forEach((beat, i) => {
        const downstrokeInterval = chosenBassRun.intervals[i * 2 + 0];
        const upstrokeInterval = chosenBassRun.intervals[i * 2 + 1];
        if (typeof downstrokeInterval == 'number' || typeof upstrokeInterval == 'number') {
          lastInterval = (upstrokeInterval ?? downstrokeInterval) as number;
        }
        if (typeof lastInterval == 'number' && !(downstrokeInterval && upstrokeInterval)) {
          const plan = (beat.plans.guitar ??= {});
          const bassNote = settings.openVoicings.intervalStringFretInKey(
            cell.measure.key,
            lastInterval
          );
          plan.chord = GuitarChordLibrary.applyBassNoteToGuitarChord(fromChord, bassNote);
        }
      });

      // Check for runs that end on a higher octave note (e.g. D string for an E chord) and ensure the run resolves
      const nextCellFirstBeat = cell.next?.beats[0];
      if (nextCellFirstBeat) {
        const nextCellGuitarPlans = (nextCellFirstBeat.plans.guitar ??= {});
        if (
          !chosenBassRun.attrs.descending &&
          !chosenBassRun.attrs.single &&
          /^(D00|02[20]|13[31]|24[42])/.test(toChord.shapeStrings ?? '')
        ) {
          const bassNote = getPositionForNoteRelativeToRoot({
            guitarChord: toChord,
            stepsToWalk: 12,
          });
          nextCellGuitarPlans.chord = GuitarChordLibrary.applyBassNoteToGuitarChord(
            toChord,
            bassNote
          );
        }
      }
    } else if (leadingNotesAssigned < maxNumberOfLeadingNotesDesired && notes.length > 0) {
      const chosenLeadingNote = notes[Math.floor(Math.random() * notes.length)];
      assert(chosenLeadingNote, 'Chosen leading note is undefined');
      const interval = chosenLeadingNote.interval;
      (cell.plans.guitar ??= {}).leadingNote = { interval };

      const bassNote = settings.openVoicings.intervalStringFretInKey(cell.measure.key, interval);
      const guitarChordWithRootNote = GuitarChordLibrary.applyBassNoteToGuitarChord(
        fromChord,
        bassNote
      );

      cell.beats.forEach((beat, i) => {
        const plan = (beat.plans.guitar ??= {});
        plan.chord = guitarChordWithRootNote;
      });

      // Check for leading notes that should resolve to a higher octave note
      // (e.g. D string for an E chord) and ensure the run resolves
      const nextCellFirstBeat = cell.next?.beats[0];
      if (nextCellFirstBeat) {
        const nextCellGuitarPlans = (nextCellFirstBeat.plans.guitar ??= {});
        if (/^(D00|02[20]|13[31]|24[42])/.test(toChord.shapeStrings ?? '') && interval >= 10) {
          const bassNote = getPositionForNoteRelativeToRoot({
            guitarChord: toChord,
            stepsToWalk: 12,
          });
          nextCellGuitarPlans.chord = GuitarChordLibrary.applyBassNoteToGuitarChord(
            toChord,
            bassNote
          );
        }
      }
      leadingNotesAssigned++;
    }
  });
}

function getCellsWithPossibleBassRuns(measures: readonly LinearizedMeasure[]) {
  const cellsWithPossibleRuns = measures.flatMap((m) => m.cells).filter(isPotentialBassRunCell);

  //// TODO: Favor bass runs toward the end of a section?
  // cellsWithPossibleRuns.forEach((cell, i) => {
  //   const oneRunAhead = cellsWithPossibleRuns[ i + 1 ];
  //   const twoRunsAhead = cellsWithPossibleRuns[ i + 2 ];
  //   if (twoRunsAhead?.layout.sectionIndex !== cell.layout.sectionIndex) {
  //     cellsWithPossibleRuns.penultimateChordChange = true;
  //   }
  // });

  return cellsWithPossibleRuns as (LinearizedCell &
    Required<Pick<LinearizedCell, 'next' | 'prev'>>)[];
}

function isPotentialBassRunCell(cell: LinearizedCell) {
  if (!cell.prev || !cell.next) return false;
  if (!cell.repeated) return false;
  if (cell.effect || cell.next.effect) return false;
  if (cell.prev.split || cell.split || cell.next.split) return false;
  if (cell.chordHeard.rootNote == cell.next.chordHeard.rootNote) return false;
  if (cell.chordHeard.bassNote && cell.chordHeard.bassNote != cell.chordHeard.rootNote)
    return false;
  if (
    cell.next.chordHeard.bassNote &&
    cell.next.chordHeard.bassNote != cell.next.chordHeard.rootNote
  )
    return false;
  return true;
}

//// TODO: Look for opportunities to do a 1-6-5-3 or 1-3-6-5 or other bass fill...
// function isPotentialBassFillerMeasure(measure: LinearizedMeasure) {
//   if (!measure.onlyOneChord) return false;
//   if (!measure.next?.onlyOneChord || !measure.next?.beats[0]?.chordChanged) return false;
//   // ...
//   return true;
// }
