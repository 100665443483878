export class TapTempoCalculator {
  tapIntervals: number[] = []; // most recent tap at front
  lastTapTime = 0;
  bpm = $state<number | null>(null);

  tap() {
    const thisTapTime = performance.now() / 1000;
    if (this.lastTapTime) {
      const thisInterval = thisTapTime - this.lastTapTime;
      if (
        (this.tapIntervals.length > 0 && thisInterval > this.tapIntervals[0] * 2) ||
        thisInterval > 3
      ) {
        this.tapIntervals.length = 0;
        this.bpm = null;
      } else {
        this.tapIntervals.unshift(thisInterval);
      }
      if (this.tapIntervals.length > 8) this.tapIntervals.length = 8;
      if (this.tapIntervals.length > 1) {
        let weightedSum = 0;
        let sumOfWeights = 0;
        this.tapIntervals.forEach((interval, index) => {
          const weight = 8 - index;
          sumOfWeights += weight;
          weightedSum += interval * weight;
        });
        this.bpm = 60 / (weightedSum / sumOfWeights);
      }
    }
    this.lastTapTime = thisTapTime;
  }

  reset() {
    this.tapIntervals.length = 0;
  }

  get calculatedBpm() {
    return this.bpm;
  }
}
