import { strict as assert } from 'assert';
import EventEmitter from 'eventemitter3';
import AudioManager from '@/audio/engine/AudioManager';
import type { Channel } from '@/audio/engine/base/Channel';
import type { Playback } from '@/audio/engine/base/Playback';
import { PlayProps } from '@/audio/engine/base/PlayProps';

export type SampleDefinition = {
  id: string;
  packageId: string;
  duration?: number;
  preroll?: number;
} & Partial<PlayProps>;

export abstract class Sample extends EventEmitter {
  readonly id: string;
  readonly packageId: string;
  readonly duration: number;
  readonly preroll: number = 0;
  readonly defaultPlayProps: PlayProps;
  readonly playbacks: Playback[] = [];
  loaded = false;

  constructor(sampleDefinition: SampleDefinition) {
    super();
    assert(
      sampleDefinition.duration,
      `Tried creating sample with duration ${sampleDefinition.duration ?? 'undefined'}`
    );
    this.id = sampleDefinition.id;
    this.packageId = sampleDefinition.packageId;
    this.duration = sampleDefinition.duration;
    this.preroll = sampleDefinition.preroll ?? 0;
    this.defaultPlayProps = new PlayProps(sampleDefinition);
  }

  async load(): Promise<void> {
    if (!this.loaded) await this._load();
    this.emit('ready');
  }

  protected abstract _load(): Promise<void>;

  play(playProps: PlayProps): Playback {
    if (this.destroyed) {
      throw new Error('Cannot play Sample after it has been destroyed.');
    }

    playProps = PlayProps.assign(this.defaultPlayProps, playProps);

    if (playProps.atTime) playProps.atTime -= this.preroll;

    assert(playProps.channel, 'Tried to play sample without specifying channel');
    const channel = AudioManager.mixer?.channel(playProps.channel);
    assert(channel, `Channel ${playProps.channel} has not been set up.`);

    const playback = this._createPlayback(playProps, channel);
    this.playbacks.push(playback);

    playback.addListener('end', this._handlePlaybackEnd.bind(this));
    playback.addListener('stop', this._handlePlaybackStopped.bind(this));
    playback.addListener('destroyed', this._handlePlaybackDestroyed.bind(this));

    return playback;
  }

  protected abstract _createPlayback(playProps: PlayProps, channel: Channel): Playback;

  // Stop all playbacks of this sample, destroying them.
  stop(args: { atTime?: number; fadeDuration?: number } = {}): void {
    this.playbacks.slice().forEach((pb) => void pb.stop(args));
  }

  /**
   * Destroy this sample. Halts and destroys all playbacks, and
   * dispatches a destruction event, which should be reacted
   * to by anything referencing this sample (e.g. channels)
   * by removing it from their lists so it can be garbage collected.
   */
  destroyed = false;
  destroy(): void {
    this.stop();
    this.destroyed = true;
    this._destroy();
    this.emit('destroyed');
    this.removeAllListeners();
  }

  protected abstract _destroy(): void;

  private _handlePlaybackEnd(): void {
    this.emit('playbackEnd');
  }

  private _handlePlaybackStopped(): void {
    this.emit('playbackStop');
  }

  private _handlePlaybackDestroyed(e: Record<string, any>): void {
    const index = this.playbacks.indexOf(e.target);
    if (index > -1) {
      this.playbacks.splice(index, 1);
    }
    this.emit('playbackDestroyed');
  }
}
