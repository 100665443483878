import type { BuiltInPresetDefinition } from '@/band/presets/BuiltInPresetDefinition';

export const celticPresetDefinitions: Partial<Record<TimeSignature, BuiltInPresetDefinition[]>> = {
  // --- 4/4 ---

  '4/4': [
    {
      id: 'ce-l',
      name: 'Celtic Reel',
      genreId: 'ce',
      genreCategoryId: 'ce-cl',
      description: 'Full guitar strum in drop-D tuning for Celtic reels. Guitar-only for now.',
      band: {
        'g_c': 'cr',
        'b_e': false,
        'm_e': false,
      },
    },

    {
      id: 'ce-h',
      name: 'Celtic Hornpipe',
      genreId: 'ce',
      genreCategoryId: 'ce-cl',
      description: 'Swinging guitar strum in drop-D tuning for hornpipes. Guitar-only for now.',
      band: {
        'sw': '2:1',
        'g_c': 'bg',
        'g_ss': { 'r': 1, 't': 0.75 },
        'g_ov': { 'mj': 'gacde' },
        'b_e': false,
        'm_e': false,
      },
    },

    {
      id: 'ce-p',
      name: 'Celtic Polka',
      genreId: 'ce',
      genreCategoryId: 'ce-cl',
      description: 'One-two polka accompaniment.',
      band: {
        'sw': '5:4',
        'g_c': 'bc',
        'g_ov': { 'mj': 'gacde' },
        'g_bn': { 'l': 0.5 },
        'b_e': true,
        'm_e': false,
      },
    },
  ],

  // --- 3/4 ---

  '3/4': [
    {
      id: 'ce-wst',
      name: 'Celtic Waltz',
      genreId: 'ce',
      genreCategoryId: 'ce-cl',
      description: 'Big waltz strum with no upstroke swing.',
      band: {
        'sw': '0',
        'g_c': 'bgw',
        'g_ss': { 'r': 0.625, 't': 0.5 },
        'g_bcbr': { 'v': 9 / 14 },
        'g_br': {},
        'g_ov': { 'mj': 'gcde', 'dd': true, 'b7': false },
        'g_bn': { 'l': 1 / 4 },
        'm_e': false,
      },
    },

    {
      id: 'ce-wsw',
      name: 'Celtic Waltz w/Swing',
      genreId: 'ce',
      genreCategoryId: 'ce-cl',
      description: 'Big waltz strum with upstroke swing.',
      band: {
        'sw': '7:4',
        'g_c': 'bgw',
        'g_ss': { 'r': 0.625, 't': 0.5 },
        'g_bcbr': { 'v': 9 / 14 },
        'g_br': {},
        'g_ov': { 'mj': 'gcde', 'dd': true, 'b7': false },
        'g_bn': { 'l': 1 / 4 },
        'm_e': false,
      },
    },

    {
      id: 'ce-wa',
      name: 'Celtic Arpeggiated Waltz',
      genreId: 'ce',
      genreCategoryId: 'ce-cl',
      description: 'Arpeggios of single strings.',
      band: {
        'sw': '5:4',
        'g_c': 'aw',
        'm_e': false,
      },
    },
  ],

  // --- 6/8 ---

  '6/8': [
    {
      id: 'cj-l',
      name: 'Celtic Jig',
      genreId: 'ce',
      genreCategoryId: 'ce-cl',
      description: 'Celtic-style jig strum.',
      band: {
        'g_c': 'cj',
      },
    },
  ],

  // --- 9/8 ---

  '9/8': [
    {
      id: 'csj-l',
      name: 'Celtic Slip Jig',
      genreId: 'ce',
      genreCategoryId: 'ce-cl',
      description: 'Celtic-style jig strum.',
      band: {
        'g_c': 'csj',
      },
    },
  ],
};
