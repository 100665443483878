import { AutoTool } from '@/auto-tools/AutoTool';
import type { SUARecord } from '@/collections/SUACollection';

// If you make any changes to the schema, remember to update SUACompressor.js!

/**
 * Updates legacy Auto-Finish properties to the new schema
 * @returns Updated settings record
 */
function makeBackwardsCompatible(suaRecord: Record<string, unknown>): Partial<SUARecord> {
  // TODO: Remove this function when all SUA records have been converted
  const sua = JSON.parse(JSON.stringify(suaRecord)) as Record<string, unknown> & {
    autoFinishReps: number;
  };
  if (!('afReps' in sua) && sua.autoFinishReps > 0) {
    sua.afOn = true;
    sua.afReps = sua.autoFinishReps;
  }
  return sua as Partial<SUARecord>;
}

export class AutoFinishSettings extends AutoTool {
  protected prefix = 'af';

  init(settings: Partial<SUARecord>): void {
    settings = makeBackwardsCompatible(settings);
    super.init(settings);
    if ('afReps' in settings && typeof settings['afReps'] === 'number') {
      this.settings.set('afReps', this.normalizeReps(settings['afReps']));
    }
  }

  //#region === Reps ===

  reps(): number {
    return (this.settings.get('afReps') as number) ?? 1;
  }

  setReps(value: number | string): void {
    this.setSetting('afReps', this.normalizeReps(value));
  }

  adjustReps(amount: number): void {
    this.setReps(this.reps() + amount);
  }

  private normalizeReps = (value: number | string): number => {
    value = Number(value) || 1;
    return Math.min(Math.max(Math.floor(value), 1), 99);
  };

  //#endregion ^ Reps
}
