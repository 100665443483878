<script lang="ts">
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';

  interface Props {
    size?: 'base' | 'sm';
  }

  let { size = 'base' }: Props = $props();

  const bandMenu = getBandMenuState();
  let band = $derived($bandMenu.band);

  let bandHasInstruments: boolean = $state(false);
  trackerEffect(() => (bandHasInstruments = band.activeInstruments().length > 0));

  function savePreset() {
    band.savePreset();
  }

  function cancelPresetMode() {
    band.enterNormalPresetMode();
  }
</script>

<div class="flex gap-2">
  <button
    class="btn-base rounded bg-green-600 px-4 py-1 text-white hover:bg-green-700"
    class:text-sm={size == 'sm'}
    disabled={!bandHasInstruments}
    onclick={savePreset}
  >
    Save Changes
  </button>

  <button
    class="btn-base rounded bg-gray-500 px-4 py-1 text-white hover:bg-gray-700"
    class:text-sm={size == 'sm'}
    onclick={cancelPresetMode}
  >
    Cancel
  </button>
</div>
