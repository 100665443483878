import type { GuitarChordStyle } from '@/band/instruments/guitar/GuitarChordStyle';
import type { GuitarOpenVoicingsSetting } from '@/band/instruments/guitar/settings/GuitarOpenVoicingsSetting';
import type { Chord } from '@/music/Chord';

export function generateChordPickingOptions({
  key,
  openVoicings,
  chordStyle,
}: {
  key?: Chord | string;
  openVoicings: GuitarOpenVoicingsSetting;
  chordStyle?: GuitarChordStyle;
}) {
  chordStyle ??= 'cowboy';
  const position =
    chordStyle == 'cowboy' && key && !key.toString().includes('m')
      ? openVoicings.positionToUseForKey(
          (typeof key == 'string' ? key : key.toString()) as NonNullable<Chord['key']>
        )
      : undefined;
  const capo = position?.capo ?? 0;
  const inG = !!position?.shape.startsWith('G');
  const inC = !!position?.shape.startsWith('C');
  const inD = !!position?.shape.startsWith('D');
  const inE = !!position?.shape.startsWith('E');

  return {
    'barred': { closed: true },
    'celtic': { capo, droning: true },
    'cowboy': {
      capo,
      dropD: inD && openVoicings.dropD,
      useB7: inE && openVoicings.useB7,
      useCadd9: (inG || inD) && openVoicings.useCadd9,
      useThirdlessG:
        !((inG || inD || inC) && openVoicings.voicingForG == 'G') ||
        (inD && openVoicings.voicingForD == 'D5') ||
        (inD && openVoicings.dropD),
      useThirdlessD: inD && openVoicings.voicingForD == 'D5',
      useSusD: inD && openVoicings.voicingForD == 'D2',
    },
  }[chordStyle];
}
