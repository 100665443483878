<script lang="ts">
  import ResizeObserver from 'resize-observer-polyfill';
  import type { Snippet } from 'svelte';
  import { onMount } from 'svelte';
  import { fade } from 'svelte/transition';
  import { MultiPanelModalController } from '@/ui/settings/MultiPanelModalController.svelte';
  import { slideFade } from '@/ui/svelte-transitions';
  import type milestoneCodes from '@/user/MilestoneCodes';
  import { UserProfile } from '@/user/UserProfile';

  interface Props {
    showDoneButton?: boolean;
    doneButtonCaption?: string;
    doneZoomsToRoot?: boolean;
    bottomFogHeight?: number;
    headerBg?: 'yellow' | 'red' | 'white' | 'panel';
    mainBg?: 'panel' | 'white';
    showAltHeader?: boolean;
    scrollMilestone?: keyof typeof milestoneCodes;
    scrollHintLabel?: string;
    overflowHidden?: boolean;
    header?: Snippet;
    altHeader?: Snippet;
    main: Snippet;
    footer?: Snippet;
    elementToBringIntoView?: Element;
  }

  let {
    showDoneButton = false,
    doneButtonCaption = 'Done',
    doneZoomsToRoot = false,
    bottomFogHeight = 0,
    headerBg = 'white',
    mainBg = 'panel',
    showAltHeader = false,
    scrollMilestone = undefined,
    scrollHintLabel = '',
    overflowHidden = false,
    header = undefined,
    altHeader = undefined,
    main,
    footer = undefined,
    elementToBringIntoView = undefined,
  }: Props = $props();

  const modalController = MultiPanelModalController.current();

  let topShadowAmount = $state(0);
  let bottomShadowAmount = $state(0);
  let hasOverflow = $state(false);
  let tallEnoughToOverflow = $state(true);

  let elTopOfMain: HTMLElement;
  let elBottomOfMain: HTMLElement;
  let elScrollContainer: HTMLElement | undefined;
  let elOuterContainer: HTMLElement | undefined;

  let oldScreenIndex = 0;
  let oldSubview = modalController.subview;
  $effect(() => {
    if (oldScreenIndex !== modalController.activeScreenIndex) {
      oldScreenIndex = modalController.activeScreenIndex;
    } else if (oldSubview !== modalController.subview) {
      oldSubview = modalController.subview;
      elScrollContainer?.scrollTo({ top: 0, behavior: 'smooth' });
    }
  });

  $effect(() => {
    if (!elementToBringIntoView) return;
    const bottom = elementToBringIntoView.getBoundingClientRect().bottom;
    if (bottom + 30 > containerScrollBottom) {
      const px = bottom + 120;
      elScrollContainer?.scrollTo({ top: px - elScrollContainer.clientHeight, behavior: 'smooth' });
    }
  });

  let containerScrollBottom = $derived(
    elScrollContainer ? elScrollContainer.scrollTop + elScrollContainer.clientHeight : 0
  );

  // This is not reactive until component is loaded the next time
  // svelte-ignore non_reactive_update
  let scrollHintShown = scrollMilestone && !!UserProfile.notMilestone(scrollMilestone);
  $effect(() => {
    if (scrollMilestone && scrollHintShown && topShadowAmount == 1) {
      UserProfile.recordMilestone(scrollMilestone);
    }
  });

  onMount(() => {
    const topObserver = new IntersectionObserver(
      ([entry]) => {
        topShadowAmount = 1 - (entry?.intersectionRatio ?? 1);
      },
      { threshold: [0, 0.2, 0.4, 0.6, 0.8, 1] }
    );

    const bottomObserver = new IntersectionObserver(
      ([entry]) => {
        bottomShadowAmount = Math.max(0, 1 - (entry?.intersectionRatio ?? 1) * 1.25);
        if (scrollHintShown && bottomShadowAmount < 1) scrollHintShown = false;
      },
      { threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1] }
    );

    const scrollContainerResizeObserver = new ResizeObserver(() =>
      requestAnimationFrame(() => {
        const scrollRect = elOuterContainer?.getBoundingClientRect();
        if (!scrollRect) return;
        tallEnoughToOverflow = scrollRect.y + scrollRect.height > window.innerHeight - 60;

        requestAnimationFrame(() => {
          hasOverflow =
            !!elScrollContainer &&
            elScrollContainer?.offsetHeight < elScrollContainer?.scrollHeight;
        });
      })
    );

    setTimeout(() => {
      topObserver.observe(elTopOfMain);
      bottomObserver.observe(elBottomOfMain);
      if (elScrollContainer) scrollContainerResizeObserver.observe(elScrollContainer);
    }, 300);

    return () => {
      topObserver.disconnect();
      bottomObserver.disconnect();
      scrollContainerResizeObserver.disconnect();
    };
  });
</script>

<div
  class="flex max-h-full min-h-0 flex-col rounded-b-[10px] bg-panelBg"
  class:overflow-hidden={overflowHidden}
  style={mainBg == 'white' ? '--hsl-panelBg: 206 0% 100%;' : ''}
  bind:this={elOuterContainer}
>
  <div class="relative flex max-h-full min-h-0 flex-col">
    <div
      class="below-header-gradient"
      style="opacity: {topShadowAmount}; transform: scaleY({topShadowAmount})"
    />
    <div
      class="scroll-overflow-gradient"
      style:--panel-modal-layout-bottom-fog-height={`${bottomFogHeight}px`}
      style:--panel-modal-layout-bottom-shadow-amount={bottomShadowAmount}
      style:--panel-modal-layout-bottom-shadow-radius={showDoneButton ? '0px' : '9px'}
    />
    {#if scrollHintShown}
      <div
        class="scroll-hint pointer-events-none absolute inset-x-0 bottom-3 select-none text-center"
        transition:fade={{ duration: 300 }}
      >
        <div
          class="backdrop-blur-xs inline-block rounded-full bg-gray-900 bg-opacity-40 px-4 py-1 text-sm text-white"
        >
          {scrollHintLabel}
        </div>
      </div>
    {/if}
    <div
      class="relative {tallEnoughToOverflow ? 'overflow-y-auto' : 'overflow-visible'}"
      bind:this={elScrollContainer}
    >
      <div bind:this={elTopOfMain} class="pointer-events-none absolute top-0 h-4 w-full" />
      {#if header}
        <div
          class="relative transition-colors {headerBg == 'red'
            ? 'border-b border-red-300'
            : headerBg == mainBg
              ? ''
              : 'border-b border-panelWellBorder'}
               {headerBg == 'yellow'
            ? 'bg-yellow-100'
            : headerBg == 'red'
              ? 'bg-red-100'
              : headerBg == 'white'
                ? 'bg-white'
                : ''}"
        >
          {#if !showAltHeader}
            <div transition:slideFade class="col-start-1 row-start-1">
              {@render header()}
            </div>
          {/if}
          {#if showAltHeader && altHeader}
            <div transition:slideFade class="col-start-1 row-start-1">
              {@render altHeader()}
            </div>
          {/if}
        </div>
      {/if}
      <div class="relative max-h-full">
        {@render main?.()}
      </div>
      <div
        bind:this={elBottomOfMain}
        class="pointer-events-none bottom-0 w-full"
        style="height: {hasOverflow ? bottomFogHeight / 2 : 0}px; margin-top: -{hasOverflow
          ? bottomFogHeight / 2
          : 0}px"
      />
    </div>
  </div>
  {#if showDoneButton}
    <div class="z-20 p-2" transition:slideFade>
      <button
        class="btn btn-raised btn-block p-1.5"
        style="box-shadow: none;"
        on:click={() =>
          doneZoomsToRoot ? modalController.zoomOutToRoot() : modalController.zoomOut()}
      >
        {doneButtonCaption}
      </button>
    </div>
  {/if}
  {#if footer}
    <div class="rounded-b-[10px] border-t border-gray-200 bg-gray-150" transition:slideFade>
      {@render footer?.()}
    </div>
  {/if}
</div>

<style>
  .below-header-gradient {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 10px;
    transform-origin: top left;
    transition: all 50ms linear;
    z-index: 1000;
    pointer-events: none;
    background: transparent
      linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.1) 25%,
        rgba(0, 0, 0, 0) 100%
      )
      bottom left;
  }

  .scroll-overflow-gradient {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: var(--panel-modal-layout-bottom-fog-height);
    transform-origin: bottom left;
    transform: scaleY(var(--panel-modal-layout-bottom-shadow-amount));
    opacity: var(--panel-modal-layout-bottom-shadow-amount);
    border-radius: 0 0 var(--panel-modal-layout-bottom-shadow-radius)
      var(--panel-modal-layout-bottom-shadow-radius);
    transition: all 50ms linear;
    z-index: 100;
    pointer-events: none;
    background: transparent
      linear-gradient(to top, hsl(0deg 0 40% / 0.7) 0%, hsla(0deg 0 0 / 0) 90%) bottom left;
  }

  .scroll-hint {
    z-index: 200;
  }
</style>
