import { ClientManager } from '@/browser/ClientManager';
import { MusicLibrary } from '@/library/MusicLibrary';

Template.indexPageOfflineNotice.onCreated(() => {
  const instance = Template.instance();
  instance.offline = new ReactiveVar();
  var offlineTimeout, onlineTimeout;
  instance.autorun(() => {
    if (Meteor.status().connected) {
      Meteor.clearTimeout(onlineTimeout);
      Meteor.clearTimeout(offlineTimeout);
      onlineTimeout = Meteor.setTimeout(() => instance.offline.set(false), 300);
    } else {
      Meteor.clearTimeout(onlineTimeout);
      Meteor.clearTimeout(offlineTimeout);
      offlineTimeout = Meteor.setTimeout(() => instance.offline.set(true), 3000);
    }
  });
});

Template.indexPageOfflineNotice.helpers({
  offlineForReal() {
    return Template.instance().offline.get();
  },

  offlineEnabled() {
    return (
      'cordova' in window || ClientManager.serviceWorkerActive() || MusicLibrary.offlineReady()
    );
  },

  unsyncedChanges() {
    if (
      !('cordova' in window) &&
      !ClientManager.serviceWorkerActive() &&
      !MusicLibrary.offlineReady()
    )
      return;
    if (!MusicLibrary.ready()) return;
    if (!MusicLibrary.songs.offlineSongBackend || !MusicLibrary.medleys.offlineMedleyBackend)
      return;
    const words = [];
    const songChanges = MusicLibrary.songs.offlineSongBackend.pendingChanges();
    if (songChanges > 0) {
      words.push(songChanges == 1 ? 'one song' : `${songChanges} songs`);
    }
    const medleyChanges = MusicLibrary.medleys.offlineMedleyBackend.pendingChanges();
    if (medleyChanges > 0) {
      words.push(medleyChanges == 1 ? 'one medley' : `${medleyChanges} medleys`);
    }
    return words.join(' and ');
  },
});
