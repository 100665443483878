import Dexie from 'dexie';
import localStorageDBNames from '@/local-db/localStorageDBNames';

export default function cleanLocalStorage() {
  return Promise.all(
    localStorageDBNames.outdated.map(async (name) => {
      try {
        await Dexie.delete(name);
      } catch (e) {} //eslint-disable-line
    })
  );
}
