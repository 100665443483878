import type { GuitarChord } from '@/band/instruments/guitar/GuitarChord';

export function getPositionForNoteRelativeToRoot({
  guitarChord,
  stepsToWalk,
}: {
  guitarChord: GuitarChord;
  stepsToWalk: number;
}): { string: number; fret: number } {
  let { string, fret } = guitarChord.bassNotePosition;
  // remember, string 6 is the lowest string
  for (let i = 0; i < Math.abs(stepsToWalk); i++) {
    fret += Math.sign(stepsToWalk);
    if (fret >= guitarChord.capo + 5) {
      fret -= 5;
      string--;
    } else if (fret < guitarChord.capo && string < 6) {
      fret += 5;
      string++;
    }
    if (fret < guitarChord.capo - (guitarChord.dropD ? 2 : 0) && string == 6) {
      fret += 2;
      string -= 2;
    }
  }
  return { string, fret };
}
