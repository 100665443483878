import { z } from 'zod';
import type { InstrumentSetting } from '@/band/instruments/InstrumentSetting';

const fallbackToZero = z.preprocess((val) => val ?? 0, z.number().min(-0.1).max(0.1));
export const serializedTimingSettingSchema = z.array(fallbackToZero);

type TimingSerialization = z.infer<typeof serializedTimingSettingSchema>;

export interface InstrumentTimingSetting extends InstrumentSetting<TimingSerialization> {
  readonly beatAdjustments: TimingSerialization;
  withAdjustedBeat(index: number, value: number): InstrumentTimingSetting;
}
