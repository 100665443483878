import { bluegrassPatternLandscape } from '@/band/instruments/guitar/bluegrassPatternLandscape';
import {
  bassNote,
  crosspick,
  pickSpecified,
  strumDown,
  strumUp,
} from '@/band/instruments/guitar/GuitarActions';
import type { GuitarIntent, GuitarIntentStrum } from '@/band/instruments/guitar/GuitarIntent';
import { bluegrassSpreadGradients } from '@/band/instruments/guitar/settings/bluegrassSpreadGradients';
import type { GuitarBassNotesSetting } from '@/band/instruments/guitar/settings/GuitarBassNotesSetting';
import type { GuitarBrushinessSetting } from '@/band/instruments/guitar/settings/GuitarBrushinessSetting';
import type { GuitarCircularStrumShapeSetting } from '@/band/instruments/guitar/settings/GuitarCircularStrumShapeSetting';
import type { GuitarOpenVoicingsSetting } from '@/band/instruments/guitar/settings/GuitarOpenVoicingsSetting';
import type { LinearizedCell } from '@/chart/LinearizedCell';
import { lerp } from '@/utilities/lerp';
import { randomPlusMinus } from '@/utilities/randomPlusMinus';

const zeroThroughSeven = [0, 1, 2, 3, 4, 5, 6, 7] as const;
type BeatAction = 'strum' | 'pick' | 'interval';

export function bluegrassMeasure(
  cells: [LinearizedCell, LinearizedCell?],
  {
    circularStrumShape,
    bassNotes,
    brushiness,
    openVoicings,
  }: {
    circularStrumShape: GuitarCircularStrumShapeSetting;
    bassNotes: GuitarBassNotesSetting;
    brushiness: GuitarBrushinessSetting;
    openVoicings: GuitarOpenVoicingsSetting;
  }
): (GuitarIntent | null)[] {
  // const chordChange = cell.beats[0].chordChanged;
  // const leadingNote = cell.plans.guitar?.leadingNote?.interval;

  const beatPowers = circularStrumShape
    .powersForLandscape(bluegrassPatternLandscape)
    .map((power) => power + randomPlusMinus(0.04)) as EightNumbers;

  // Ensure leading notes are heard
  if (cells[0].plans.guitar?.leadingNote) beatPowers[0] = lerp(0.7, 1.0, beatPowers[0]);
  if (cells[1]?.plans.guitar?.leadingNote) beatPowers[4] = lerp(0.8, 1.1, beatPowers[4]);
  if (cells[0].plans.guitar?.bassRun) beatPowers[4] = lerp(0.7, 1.0, beatPowers[4]);

  const beatSpreads = brushiness.spreadsForGradients(bluegrassSpreadGradients).map((spread, i) => {
    spread *= lerp(0.5, 1, beatPowers[i] ?? NaN);
    spread += randomPlusMinus(i % 4 > 0 ? 0.3 : 0.0);
    return spread;
  }) as EightNumbers;

  // Handle half measures
  if (!cells[1]) {
    beatPowers[2] = beatPowers[6];
    beatSpreads[2] = beatSpreads[6];
    beatPowers[3] = beatPowers[7];
    beatSpreads[3] = beatSpreads[7];
  }

  // Ensure "chucks" are not isolated single notes
  beatSpreads[2] = Math.max(2.2 - (beatPowers[1] + beatPowers[3]), beatSpreads[2]);
  beatSpreads[6] = Math.max(2.2 - (beatPowers[5] + beatPowers[7]), beatSpreads[6]);

  const secondDownbeatRootOrFifth =
    bassNotes.altBass && !cells[1]?.beats[0].chordChanged ? 'fifth' : 'root';

  const beatBiases: EightOf<GuitarIntentStrum['bias']> = [
    'root',
    'afterRoot',
    'chuck1',
    'afterChuck1',
    secondDownbeatRootOrFifth,
    'afterFifth',
    // : bassNote(bassNotes.altBass && !chordChange ? 'fifth' : 'root', {
    'chuck2',
    'afterChuck2',
  ];

  // Adapt for mid-measure chord changes
  if (cells[1]?.beats[0].chordRootChanged) {
    beatBiases[4] = 'root';
    beatPowers[4] = (beatPowers[4] + 3 * beatPowers[0]) / 4;
    beatSpreads[4] = (beatSpreads[4] + 3 * beatSpreads[0]) / 4;
  }

  // Bass run handling

  const beatIntervals: (number | null | undefined)[] = cells
    .map((cell) => cell?.plans.guitar?.bassRun?.intervals || [null, null, null, null])
    .flat();

  cells.forEach((cell, cellIndex) => {
    if (!cell) return;
    const bassRun = cell.plans.guitar?.bassRun;
    if (!bassRun) return;
    const i0 = cellIndex * 4;
    const basePower = 0.5 + 0.5 * (beatPowers[(i0 + 4) % 8] ?? 1);
    if (bassRun.quarters) {
      beatPowers[i0] = basePower * 0.85;
      beatPowers[i0 + 1] = Math.min(lerp(-0.3, 1.0, Math.random()), beatPowers[5]);
      beatBiases[i0 + 1] = 'afterFifth';
      beatSpreads[i0 + 1] = Math.min(2, beatSpreads[5]);
      beatPowers[i0 + 2] = basePower * 0.93;
      beatPowers[i0 + 3] = Math.min(lerp(-0.5, 0.6, Math.random()), beatPowers[7]);
      beatSpreads[i0 + 3] = Math.min(2, beatSpreads[7]);
      beatBiases[i0 + 3] = 'afterFifth';
    } else if (bassRun.eighths) {
      if (typeof beatIntervals[i0] == 'number') {
        beatPowers[i0] = 0.8 * basePower;
      }
      if (typeof beatIntervals[i0 + 1] == 'number') {
        beatPowers[i0 + 1] = 0.7 * basePower;
      }
      beatPowers[i0 + 2] = 0.9 * basePower;
      beatPowers[i0 + 3] = 0.85 * basePower;
    }
  });

  // Assign beat actions

  const abovePowerThreshold = (power: number) => power > 0.05 && power - 0.05 > Math.random() * 0.3;

  const beatActions: (BeatAction | undefined)[] = [
    cells[0].plans.guitar?.boomStrum ? 'strum' : 'pick',
    ...([1, 2, 3] as const).map((i) =>
      abovePowerThreshold(beatPowers[i]) ? (beatSpreads[i] > 1.3 ? 'strum' : 'pick') : undefined
    ),
    cells[1]?.plans.guitar?.boomStrum ? 'strum' : 'pick',
    ...([5, 6, 7] as const).map((i) =>
      abovePowerThreshold(beatPowers[i]) ? (beatSpreads[i] > 1.3 ? 'strum' : 'pick') : undefined
    ),
  ];
  zeroThroughSeven.forEach((i) => {
    if (typeof beatIntervals[i] == 'number') beatActions[i] = 'interval';
  });

  // Calculate beat dBs

  const beatDbs = [
    beatActions[0] == 'pick'
      ? lerp(-5, 6, beatPowers[0]) + lerp(-3, 0, Math.min(1, brushiness.value * 2))
      : lerp(-10, 2, beatPowers[0]),
    lerp(-15, -3, beatPowers[1]),
    lerp(-10, 3, beatPowers[2]),
    lerp(-15, -3, beatPowers[3]),

    beatActions[4] == 'pick'
      ? lerp(-7, 4, beatPowers[4]) +
        randomPlusMinus(1.5) +
        lerp(-3, 0, Math.min(1, brushiness.value * 2))
      : lerp(-10, 2, beatPowers[4]),
    lerp(-15, -3, beatPowers[5]),
    lerp(-10, 3, beatPowers[6]),
    lerp(-15, -3, beatPowers[7]),
  ]
    .map((db, i) => db + (i % 4 == 0 ? 0 : randomPlusMinus(0.5)))
    .map((db, i) =>
      beatActions[i] === 'interval'
        ? lerp(-7, 4, beatPowers[i]!) + lerp(-3, 0, Math.min(1, brushiness.value * 2))
        : db
    ) as EightNumbers;

  // Put it all together!

  return zeroThroughSeven.map((i) => {
    if (beatActions[i] === 'pick') {
      return i === 0
        ? bassNote('root', { db: beatDbs[i] })
        : i === 4
          ? bassNote(secondDownbeatRootOrFifth, { db: beatDbs[i] })
          : crosspick({
              series: 'std',
              noteInSeries: ([1, 2, 3, 4, 5, 6, 7, 8] as const)[i],
              db: beatDbs[i] - (i % 2),
            });
    }

    if (beatActions[i] === 'strum') {
      return (i % 2 == 0 ? strumDown : strumUp)(beatBiases[i], {
        spread: beatSpreads[i],
        db: beatDbs[i],
        omitAboveTpm: i == 1 ? 260 : i == 3 ? 280 : i == 5 ? 360 : i == 7 ? 270 : undefined,
      });
    }

    if (beatActions[i] === 'interval') {
      const interval = beatIntervals[i];
      if (typeof interval != 'number') return null;
      const stringFret = openVoicings.intervalStringFretInKey(cells[0].measure.key, interval);
      return pickSpecified(stringFret, { db: beatDbs[i] });
    }

    return null;
  });
}
