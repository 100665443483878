import type { SchedulingClock } from '@/audio/engine/clock/SchedulingClock';

export type ScheduledEventCallback = (event: ScheduledEvent) => void;

export class ScheduledEvent {
  _cleared = false; // Flag used to clear an event inside callback
  _latestTime: number;
  _earliestTime: number;
  clock: SchedulingClock;
  func: ScheduledEventCallback;
  deadline: number;
  toleranceLate: number;
  toleranceEarly: number;
  repeatTime?: number;
  onexpired?: (...args: any) => void;

  constructor(clock: SchedulingClock, deadline: number, func: ScheduledEventCallback) {
    this.clock = clock;
    this.func = func;

    this.toleranceLate = clock.toleranceLate;
    this.toleranceEarly = clock.toleranceEarly;

    // Rest of constructor is inlined this.schedule(deadline)
    this.deadline = deadline;
    this._latestTime = this.deadline + this.toleranceLate;
    this._earliestTime = this.deadline - this.toleranceEarly;

    if (this.clock.timeFunction() >= this._earliestTime) {
      this._execute();
    } else {
      this.clock._upsertEvent(this);
    }
  }

  /**
   * Schedules the event to be ran before `deadline`.
   * If the time is within the event tolerance, we handle the event immediately.
   * If the event was already scheduled at a different time, it is rescheduled.
   */
  schedule(deadline: number): void {
    this._cleared = false;
    this.deadline = deadline;
    this._latestTime = this.deadline + this.toleranceLate;
    this._earliestTime = this.deadline - this.toleranceEarly;

    if (this.clock.timeFunction() >= this._earliestTime) {
      this._execute();
    } else {
      this.clock._upsertEvent(this);
    }
  }

  clear(): this {
    this.clock._removeEvent(this);
    this._cleared = true;
    return this;
  }

  /**
   * Sets the event to repeat every `time` seconds.
   */
  repeat(time: number): this {
    if (time === 0) {
      throw new Error('delay cannot be 0');
    }
    this.repeatTime = time;
    if (!this.clock._hasEvent(this)) {
      this.schedule(this.deadline + this.repeatTime);
    }
    return this;
  }

  /**
   * Sets the time tolerance of the event.
   * The event will be executed in the interval `[deadline - early, deadline + late]`.
   * If the clock fails to execute the event in time, the event will be dropped.
   */
  tolerance(values: { late?: number; early?: number }): this {
    if (typeof values.late === 'number') this.toleranceLate = values.late;
    if (typeof values.early === 'number') this.toleranceEarly = values.early;
    this._latestTime = this.deadline + this.toleranceLate;
    this._earliestTime = this.deadline - this.toleranceEarly;
    this.clock._updateEvent(this);
    return this;
  }

  isRepeated(): boolean {
    return this.repeatTime !== undefined;
  }

  timeStretch(tRef: number, ratio: number): void {
    if (typeof this.repeatTime == 'number') {
      this.repeatTime *= ratio;
    }

    let deadline = tRef + ratio * (this.deadline - tRef);
    // If the deadline is too close or past, and the event has a repeat,
    // we calculate the next repeat possible in the stretched space.
    if (typeof this.repeatTime == 'number') {
      while (this.clock.timeFunction() >= deadline - this.toleranceEarly) {
        deadline += this.repeatTime;
      }
    }
    this.schedule(deadline);
  }

  on(eventName: string, callback: (...args: any) => void): this {
    if (eventName == 'expired') {
      this.onexpired = callback;
    }
    return this;
  }

  _execute(): void {
    if (!this.clock._started) return;
    this.clock._removeEvent(this);

    if (this.clock.timeFunction() < this._latestTime) {
      this.func(this);
    } else {
      if (this.onexpired) this.onexpired(this);
    }
    // In the case `schedule` is called inside `func`, we need to avoid
    // overwriting with yet another `schedule`.
    if (!this.clock._hasEvent(this) && typeof this.repeatTime == 'number' && !this._cleared) {
      this.schedule(this.deadline + this.repeatTime);
    }
  }
}
