import { Crnt } from '@/Crnt';
import closeNearestTippy from '@/ui/dropdowns/closeNearestTippy';
import defineTippyDropdown from '@/ui/dropdowns/defineTippyDropdown';

defineTippyDropdown(Template.songSectionAddBar, {
  id: 'duplicate-menu',
});

Template.songSectionAddBar.helpers({
  songSections() {
    return Crnt.song()?.sections.reactive();
  },
  sectionName(section) {
    return section.name ? section.name : '<span class="unnamed">unlabeled</span>';
  },
  addOneTo(sectionIndex) {
    return sectionIndex + 1;
  },
});

const scrollAndFocusLastSection = function () {
  var $firstMeasureOfLastSection = $('.songSection').last().find('.ChartCell').first();
  $firstMeasureOfLastSection.trigger('click');
  if ($('.songSection').length > 1) {
    $firstMeasureOfLastSection.velocity('scroll', {
      container: $('.js-global-songScrollContainer'),
      offset: 100,
    });
  }
};

Template.songSectionAddBar.events({
  'click .js-addSection': (event, instance) => {
    Crnt.song()?.sections.append();
    $(event.target).trigger('blur');
    Tracker.afterFlush(scrollAndFocusLastSection);
  },
  'click .js-copySection': (event, instance) => {
    Crnt.song()?.sections.duplicate($(event.currentTarget).data('sectionindex'));
    event.preventDefault();
    closeNearestTippy(event.currentTarget);
    Tracker.afterFlush(scrollAndFocusLastSection);
  },
});
