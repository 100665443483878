import { MusicLibrary } from '@/library/MusicLibrary';

const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
const isIOS =
  /iP(ad|od|hone)/i.test(navigator.userAgent) ||
  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
const isFirefox = /Firefox\//i.test(navigator.userAgent);
const isEdge = /Edge\//i.test(navigator.userAgent);
const isIOSSafari =
  isIOS &&
  /WebKit/i.test(navigator.userAgent) &&
  !/Chrome|CriOS|OPiOS|FxiOS|mercury/i.test(navigator.userAgent);

Template.offlineSettingsPanel.helpers({
  serviceWorkerSupported() {
    return 'serviceWorker' in navigator;
  },
  serviceWorkerSupportedButNotInstalled() {
    return 'serviceWorker' in navigator && !navigator.serviceWorker.controller; // we don't want this to be reactive!
  },
  serviceWorkerActive() {
    return 'serviceWorker' in navigator && navigator.serviceWorker.controller; // we don't want this to be reactive!
  },

  showPWALink() {
    const dataGrounded = () => MusicLibrary.offlineReady();
    /* HelpLibrary.offlineReady() && Meteor.users.offlineReady() */
    return (
      'serviceWorker' in navigator &&
      navigator.serviceWorker.controller &&
      dataGrounded() &&
      isChrome &&
      !/iP(ad|od|hone)|Android/i.test(navigator.userAgent)
    );
  },
  isComputer() {
    return (
      /^(Win|Mac|Linux)/i.test(window.navigator.platform) &&
      !/iP(ad|od|hone)|Android/i.test(navigator.userAgent)
    );
  },

  chromeVersion() {
    const versionMatch = /Chrome\/(\d+)/i.exec(window.navigator.userAgent);
    return isChrome && versionMatch && Number(versionMatch[1]);
  },
  firefoxVersionSufficient() {
    const versionMatch = /Firefox\/(\d+)/i.exec(window.navigator.userAgent);
    return isFirefox && versionMatch && Number(versionMatch[1]) >= 61;
  },
  firefoxVersion() {
    const versionMatch = /Firefox\/(\d+)/i.exec(window.navigator.userAgent);
    return isFirefox && versionMatch && Number(versionMatch[1]);
  },
  edgeVersion() {
    const versionMatch = /Edge\/(\d+)/i.exec(window.navigator.userAgent);
    return isEdge && versionMatch && Number(versionMatch[1]);
  },
});
