<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { rangeWithAllowedValues } from '@/band/ui/actions/rangeWithAllowedValues';
  import { fancySliderCSS } from '@/band/ui/components/fancySliderCSS';
  import SliderTicks from '@/band/ui/components/SliderTicks.svelte';

  const dispatch = createEventDispatcher<{ 'change': number }>();

  interface Props {
    min: number;
    max: number;
    step?: number; // WARNING: Things get wonky if this is less than 1!
    value: number;
    fillFromCenter?: boolean;
    centerDot?: boolean;
    noFill?: boolean;
    vertical?: boolean;
    ariaLabel?: string;
    ariaValues?: { min: number; max: number; now: number; text: string } | undefined;
    tickAboveBelow?: 'above' | 'below' | 'both';
    tickCount?: number;
    tickSqueezes?: number[];
    tickFades?: number[];
    allowedValues?: number[];
    // $: thumbDiameter = size == 'sm' ? 1.5 : size == 'md' ? 2 : 2.75;
    thumbDiameter: number;
    trackHeight?: any;
    tickHeight?: number;
  }

  let {
    min,
    max,
    step = 1,
    value,
    fillFromCenter = false,
    centerDot = false,
    noFill = false,
    vertical = false,
    ariaLabel = '',
    ariaValues = undefined,
    tickAboveBelow = 'both',
    tickCount = 0,
    tickSqueezes = [],
    tickFades = [],
    allowedValues = [],
    thumbDiameter,
    // TODO: Is this broken now?
    trackHeight = thumbDiameter * 0.35 - 1 / 16,
    tickHeight = 0,
  }: Props = $props();

  const maybeAllowedValuesAction = allowedValues.length > 0 ? rangeWithAllowedValues : () => {};

  function rangeChangeHandler(e: Event & { currentTarget: HTMLInputElement }) {
    if (allowedValues.length > 0) return; // handled by action
    const val = +e.currentTarget.value;
    dispatch('change', val);
  }
  let ariaValueProps = $derived(
    ariaValues
      ? {
          'aria-valuemin': ariaValues.min,
          'aria-valuemax': ariaValues.max,
          'aria-valuenow': ariaValues.now,
          'aria-valuetext': ariaValues.text,
        }
      : {}
  );
  let rangeCSSVariables = $derived(fancySliderCSS({ thumbDiameter, trackHeight, vertical }));
</script>

<div class="relative touch-none" class:vertical style="padding: {tickHeight}rem 0;">
  <input
    type="range"
    {min}
    {max}
    {value}
    {step}
    style="--val: {value}; --min: {min}; --max: {max};
      {rangeCSSVariables};
      {noFill ? '--fill-color: transparent;' : ''}"
    class="styled-range relative z-20"
    class:from-center={fillFromCenter}
    aria-label={ariaLabel}
    {...ariaValueProps}
    use:maybeAllowedValuesAction={{
      allowedValues,
      initialValue: value,
      valueChangedCallback: (newValue) => {
        dispatch('change', newValue);
      },
    }}
    oninput={rangeChangeHandler}
    onchange={rangeChangeHandler}
  />

  {#if centerDot}
    {@const awayFromCenter = Math.abs(value - (min + (max - min) / 2)) > step * 0.8}
    {#if awayFromCenter}
      <div
        class="pointer-events-none absolute left-1/2 top-1/2 z-20 -translate-x-1/2 -translate-y-1/2 rounded-full border-2 border-primary-500 bg-primary-400"
        style="height: calc({trackHeight}rem - 1px); width: calc({trackHeight}rem - 1px);"
      ></div>
    {/if}
  {/if}

  {#if tickHeight > 0}
    <SliderTicks
      {tickCount}
      tickValues={allowedValues}
      {value}
      {min}
      {max}
      tickWidth={Math.max(0.125, 0.08 * thumbDiameter)}
      {tickHeight}
      {thumbDiameter}
      gapInMiddle={trackHeight * 2.25 - 0.2}
      aboveBelow={tickAboveBelow}
      {tickSqueezes}
      {tickFades}
      {vertical}
    />
  {/if}
</div>
