import { z } from 'zod';
import type { InstrumentSetting } from '@/band/instruments/InstrumentSetting';
import { bounded } from '@/utilities/bounded';
import cloneJSON from '@/utilities/cloneJSON';
import { rounded } from '@/utilities/rounded';

const serializedSchema = z.object({
  m: z.enum(['r', 'a', 'w']).optional(), // root-only, alternating, walking
  l: z.number().optional(), // leading note frequency (0-1)
});

type Serialization = z.infer<typeof serializedSchema>;

export class GuitarBassNotesSetting implements InstrumentSetting<Serialization> {
  readonly rootOnly: boolean;
  readonly altBass: boolean;
  readonly walking: boolean;
  readonly leadingNoteFrequency: number;
  private readonly mode: 'r' | 'a' | 'w';

  get leadingNotesPerMeasure() {
    if (this.leadingNoteFrequency === 0) return 0;
    return Math.pow(2, this.leadingNoteFrequency * 4 - 1) / 8;
  }

  constructor(input: unknown = {}) {
    const data = serializedSchema.catch({}).parse(input);
    this.rootOnly = data.m === 'r';
    this.walking = data.m === 'w';
    this.altBass = data.m !== 'r';
    this.mode = this.rootOnly ? 'r' : this.walking ? 'w' : 'a';
    this.leadingNoteFrequency = bounded(data.l ?? 0, 0, 1);
  }

  serialize(): Serialization {
    return cloneJSON({
      m: this.mode != 'a' ? this.mode : undefined,
      l: rounded(this.leadingNoteFrequency, 3) || undefined,
    });
  }

  closeTo(serialized?: Serialization): boolean {
    const other = new GuitarBassNotesSetting(serialized);
    return this.mode === other.mode && this.leadingNoteFrequency === other.leadingNoteFrequency;
  }

  withRootOnly() {
    return new GuitarBassNotesSetting({ ...this.serialize(), m: 'r' });
  }

  withAlternatingBass() {
    return new GuitarBassNotesSetting({ ...this.serialize(), m: 'a' });
  }

  withWalkingBass() {
    return new GuitarBassNotesSetting({ ...this.serialize(), m: 'w' });
  }

  withLeadingNoteFrequency(value: number) {
    return new GuitarBassNotesSetting({ ...this.serialize(), l: value });
  }
}
