import { Meteor } from 'meteor/meteor';
import { checkForDuplicateIds } from '@/utilities/checkForDuplicateIds';

function swingFormula(baseSwing: number, rolloffStart: number, rolloffEnd: number) {
  return (bpm: number) =>
    bpm < rolloffStart
      ? baseSwing
      : bpm > rolloffEnd
        ? 0
        : baseSwing *
          Math.cos((Math.PI / 2) * ((bpm - rolloffStart) / (rolloffEnd - rolloffStart)));
}

function ratioToNumber(whatToOne: number): number {
  const x = whatToOne;
  return -(2 - 2 * x) / (1 + x);
}

export const swingOptions = Object.freeze({
  '0': {
    id: '0',
    index: 0,
    value: ratioToNumber(1.05),
    formula: swingFormula(ratioToNumber(1.05), 200, 280),
    name: 'Straight (no swing)',
  },

  '5:4': {
    id: '5:4', // 22.22%
    index: 1,
    value: ratioToNumber(1.25),
    formula: swingFormula(ratioToNumber(1.25), 200, 280),
    name: 'Slight swing (1¼:1)',
  },

  '3:2': {
    id: '3:2', // 40%
    index: 2,
    value: ratioToNumber(1.5),
    formula: swingFormula(ratioToNumber(1.5), 210, 280),
    name: 'Soft swing (1½:1)',
  },

  '7:4': {
    id: '7:4', // 54.5%
    index: 3,
    value: ratioToNumber(1.75),
    formula: swingFormula(ratioToNumber(1.75), 220, 280),
    name: 'Moderate swing (1¾:1)',
  },

  '2:1': {
    id: '2:1', // 66.66%
    index: 4,
    value: ratioToNumber(2),
    formula: swingFormula(ratioToNumber(2), 220, 290),
    name: 'Triplet swing (2:1)',
  },

  '5:2': {
    id: '5:2', // 85.7%
    index: 5,
    value: ratioToNumber(2.5),
    formula: swingFormula(ratioToNumber(2.5), 230, 300),
    name: 'Hard swing (2½:1)',
  },

  '3:1': {
    id: '3:1', // 100%
    index: 6,
    value: ratioToNumber(3),
    formula: swingFormula(ratioToNumber(3), 240, 320),
    name: 'Very hard swing (3:1)',
  },
} as const satisfies Record<string, SwingOption>);

if (Meteor.isDevelopment) checkForDuplicateIds(Object.values(swingOptions));
