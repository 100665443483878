import { z } from 'zod';
import type { InstrumentSetting } from '@/band/instruments/InstrumentSetting';
import cloneJSON from '@/utilities/cloneJSON';

const serializedSchema = z.object({
  // don't use 'v', since that's used for backwards compatibility with the old "fills" upstrokes setting
  p: z.enum(['n', 'm', 'lp']).optional(),
});

type Serialization = z.infer<typeof serializedSchema>;

export class GuitarDampenedUpstrokePatternSetting implements InstrumentSetting<Serialization> {
  readonly pattern: NonNullable<Serialization['p']>;

  constructor(input: unknown = {}) {
    const data = serializedSchema.catch({}).parse(input);
    this.pattern = data.p ?? 'n';
  }

  closeTo(serialized?: Serialization) {
    const other = new GuitarDampenedUpstrokePatternSetting(serialized);
    return other.pattern == this.pattern;
  }

  serialize(): Serialization {
    return cloneJSON({
      p: this.pattern == 'n' ? undefined : this.pattern,
    });
  }

  withUpstrokePattern(value: Serialization['p']) {
    return new GuitarDampenedUpstrokePatternSetting({
      ...this.serialize(),
      p: value,
    });
  }
}
