import HelpWindow from '@/help/HelpWindow';

Template.helpWindow.onCreated(() => {
  const instance = Template.instance();
  instance.pageBody = new ReactiveVar();
});

Template.helpWindow.onRendered(() => {
  const instance = Template.instance();
  instance.autorun(() => {
    if (!HelpWindow.visible()) return;
    instance.pageBody.set(null);
    if (HelpWindow.currentPageId() == 'contact') return;
    if (HelpWindow.currentPageId() == 'search') return;
    instance.pageBody.set(HelpWindow.currentPageBody());
    instance.$('.js-helpPage')?.scrollTop(0);
  });
});

Template.helpWindow.helpers({
  floatingWindowDataContext() {
    return {
      class: 'help',
      horizontallyResizable: true,
      fixedBottom: true,
      moveableMinWidth: '580px',
      shouldBeVisible() {
        return HelpWindow.visible();
      },
      closeClickHandler() {
        HelpWindow.hide();
      },
      backClickHandler() {
        HelpWindow.goBack();
      },
      searchClickHandler() {
        HelpWindow.search();
      },
      title() {
        if (HelpWindow.currentPageId() == 'contact') return 'Contact Support';
        if (HelpWindow.currentPageId() == 'search') return 'Search Help';
        return 'Help';
      },
      showBack() {
        return HelpWindow.canGoBack();
      },
      showSearch() {
        return !['search', 'contact'].includes(HelpWindow.currentPageId());
      },
    };
  },
  currentPageContent() {
    return Template.instance().pageBody.get();
  },
  pageId() {
    return HelpWindow.currentPageId();
  },
  onSearchPage() {
    return HelpWindow.currentPageId() == 'search';
  },
  onContactForm() {
    return HelpWindow.currentPageId() == 'contact';
  },
  onHelpPage() {
    return !['contact', 'search'].includes(HelpWindow.currentPageId());
  },
});

Template.helpWindow.events({
  'click .js-captureHelpLinks a': function (event, instance) {
    const href = event.currentTarget.href;
    if (href.includes('/help')) {
      event.preventDefault();
      const pageId = href.substring(href.indexOf('/help') + 6) || 'index';
      HelpWindow.navigate(pageId);
    }
    if (href.includes('/app/contact')) {
      event.preventDefault();
      HelpWindow.navigate('contact');
    }
  },
});
