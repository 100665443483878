import { eventTracker } from '@/browser/analytics/eventTracker';
import { Crnt } from '@/Crnt';
import closeNearestTippy from '@/ui/dropdowns/closeNearestTippy';
import defineTippyDropdown from '@/ui/dropdowns/defineTippyDropdown';
import { UserProfile } from '@/user/UserProfile';

defineTippyDropdown(Template.controlBarAutoFinish, {
  id: 'auto-finish-menu',
  onShow() {
    UserProfile.recordMilestone('TOOLS_OPEN');
    if (!Crnt.song().prefs.autoFinish.enabled()) {
      Crnt.song()?.prefs.autoFinish.enable();
      eventTracker.autoFinishEnable();
    }
  },
});

Template.controlBarAutoFinish.helpers({
  autoFinishEnabled() {
    return Crnt.song()?.prefs.autoFinish.enabled() || Crnt.list()?.autoAdvanceEnabled();
  },

  autoAdvanceEnabled() {
    return Crnt.list()?.autoAdvanceEnabled();
  },
});

Template.controlBarAutoFinish.events({
  'click .js-disable': function (event, instance) {
    if (Crnt.list()?.autoAdvanceEnabled()) {
      Crnt.list()?.disableAutoAdvance();
      eventTracker.autoAdvanceDisable({ fromAutoFinish: true });
    } else {
      Crnt.song()?.prefs.autoFinish.disable();
      eventTracker.autoFinishDisable();
    }
    if (!Crnt.song()?.prefs.autoFinish.enabled()) {
      closeNearestTippy(event.currentTarget);
    }
  },
});
