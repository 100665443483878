import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { Roles } from '@/Roles';

const subscribeInProgress = new ReactiveVar();
const restoreInProgress = new ReactiveVar();

Template.appEntryMobileSubscribeForm.onCreated(() => {
  const instance = Template.instance();
  if (!Meteor.isCordova) {
    FlowRouter.go('/app');
    return;
  }
  instance.autorun(() => {
    if (Roles.userHasRole(Meteor.userId(), 'paid-member')) FlowRouter.go('/app');
    if (!Meteor.userId()) FlowRouter.go('/login');
  });
  if (!Roles.userHasRole(Meteor.userId(), 'paid-member')) IAPBilling.updateProducts();
});

// Template.appEntryMobileSubscribeForm.onDestroyed( () => {
// });

Template.appEntryMobileSubscribeForm.helpers({
  showLoadingMessage() {
    return (
      !IAPBilling.monthlySub()?.price ||
      /requested|initiated|approved|finished|owned/.test(IAPBilling.monthlySub()?.state)
    );
  },
  purchaseInProgress() {
    return /approved/.test(IAPBilling.monthlySub()?.state) && !IAPBilling.monthlySub()?.owned;
  },
  waitingForServer() {
    return /finished|owned/.test(IAPBilling.monthlySub()?.state) || IAPBilling.monthlySub()?.owned;
  },
  notLoaded() {
    return !IAPBilling.monthlySub()?.price;
  },
  disableButtons() {
    return (
      subscribeInProgress.get() || restoreInProgress.get() || !IAPBilling.monthlySub()?.canPurchase
    );
  },
  product() {
    return IAPBilling.monthlySub();
  },
});

Template.appEntryMobileSubscribeForm.events({
  'click .js-subscribe': function (event, instance) {
    event.preventDefault();
    event.stopPropagation();
    subscribeInProgress.set(true);
    store.once(store.PAID_SUBSCRIPTION).error((err) => {
      setTimeout(() => subscribeInProgress.set(false), 2000);
    });
    store.once(store.PAID_SUBSCRIPTION).cancelled((err) => {
      setTimeout(() => subscribeInProgress.set(false), 2000);
    });
    IAPBilling.orderMonthlySub();
  },

  'click .js-restore': function (event, instance) {
    event.preventDefault();
    event.stopPropagation();
    restoreInProgress.set(true);
    store.once(store.PAID_SUBSCRIPTION).approved(() => {
      store.once(store.PAID_SUBSCRIPTION).valid(() => {
        window.alert('No active Strum Machine subscription is connected with your Apple ID.');
      });
    });
    store.refresh().finished(() => {
      setTimeout(() => restoreInProgress.set(false), 2000);
    });
    store.error((err) => {
      setTimeout(() => restoreInProgress.set(false), 2000);
    });
    setTimeout(() => restoreInProgress.set(false), 20000);
  },
});

if (Meteor.isCordova) {
  document.addEventListener(
    'resume',
    function cordovaResumeResetPurchaseFlowHandler() {
      subscribeInProgress.set(false);
      restoreInProgress.set(false);
    },
    false
  );
}
