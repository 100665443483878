import { Meteor } from 'meteor/meteor';
import { ReactiveVar } from 'meteor/reactive-var';
import { Tracker } from 'meteor/tracker';
import { AutoFinishSettings } from '@/auto-tools/AutoFinishSettings';
import { AutoModulateSettings } from '@/auto-tools/AutoModulateSettings';
import { AutoSpeedupSettings } from '@/auto-tools/AutoSpeedupSettings';
import { Band } from '@/band/Band';
import { BandPresets } from '@/band/presets/BandPresets';
import { createAuthorPreset } from '@/band/presets/createAuthorPreset';
import type { Song } from '@/chart/Song';
import type { SUARecord } from '@/collections/SUACollection';
import { MusicLibrary } from '@/library/MusicLibrary';
import { bpmToTpm, tpmToBpm } from '@/music/bpm-tpm-conversion';
import { Chord } from '@/music/Chord';
import { migrateOldKeyStyle } from '@/music/migrateOldKeyStyle';

export class SongPrefs {
  private song: Song;

  autoSpeedup: AutoSpeedupSettings;
  autoFinish: AutoFinishSettings;
  autoModulate: AutoModulateSettings;
  band: Band;

  constructor(song: Song) {
    this.song = song;
    this.autoSpeedup = new AutoSpeedupSettings(song);
    this.autoFinish = new AutoFinishSettings(song);
    this.autoModulate = new AutoModulateSettings(song);

    const autoTools = [this.autoFinish, this.autoSpeedup, this.autoModulate];
    autoTools.forEach((tool) =>
      tool.addListener('persistPlease', (changes: Record<string, unknown>) => {
        const id = this.song.id();
        if (!id || this.song.editMode()) return;
        void MusicLibrary.songs.saveAttributes(id, changes);
      })
    );

    this.band = new Band(song);
    this.band.addListener('persistPlease', (changes: Record<string, unknown>) => {
      const id = this.song.id();
      if (!id || this.song.editMode()) return;
      void MusicLibrary.songs.saveAttributes(id, changes);
    });
  }

  load(songPrefs: Partial<SUARecord> = {}, songData: SerializedSong): void {
    this._key.set(songPrefs.key ? migrateOldKeyStyle(songPrefs.key) : undefined);
    this._tpm.set(songPrefs.tpm);
    this._capo.set(songPrefs.capoFret || 0);

    this.autoSpeedup.init(songPrefs);
    this.autoFinish.init(songPrefs);
    this.autoModulate.init(songPrefs);

    if (
      songData.band &&
      Object.keys(songData.band).length > 0 &&
      songData.userId &&
      songData.userId != Meteor.userId()
    ) {
      this.band.authorPreset = createAuthorPreset({
        band: songData.band as SerializedBandPreset,
        timeSignature: this.song.timeSignature(),
        name: songData.userFirst ?? 'Author',
      });
    }

    const preset = BandPresets.findById(
      songPrefs.presetId || (songPrefs.band ? undefined : songData.presetId) || ''
    );
    if (preset) {
      this.band.loadPreset(preset);
    } else if (!songPrefs.band && this.band.authorPreset) {
      this.band.loadPreset(this.band.authorPreset);
    } else {
      this.band.loadSettings(songPrefs.band || songData.band);
    }
  }

  //#region === Capo ===

  private _capo = new ReactiveVar(0);
  capo(): number {
    return this._capo.get();
  }

  setCapo(fret: number): void {
    if (typeof fret == 'string') fret = +fret;
    this._capo.set(Math.min(Math.max(fret, 0), 11));
    this.song.sections.updateMeta();
    this.song.relinearize();
    this.persist('capo');
  }

  //#endregion ^ Capo

  //#region === Key ===

  private _key = new ReactiveVar<Key | undefined>(undefined);
  key(): Key | undefined {
    return this._key.get();
  }

  setKey(key: Key): void {
    const oldKey = this.song.keyHeard();
    this._key.set(key);
    this.persist('key');
    // In 2017, I decided I didn't want the capo to reset when the key changed. But I changed my mind in 2020.
    // Then in 2024 I decided to be a little smarter about it.
    const oldCapoPosition = this._capo.get();
    if (oldCapoPosition > 0 && oldKey) {
      const chromaDifference = new Chord(key).chroma - new Chord(oldKey).chroma;
      const newCapoPosition = oldCapoPosition + chromaDifference;
      if (newCapoPosition > 0 && newCapoPosition <= 5) {
        this._capo.set(newCapoPosition);
      } else {
        this._capo.set(0);
      }
      this.persist('capo');
    }
    this.song.sections.updateMeta();
    this.song.relinearize();
  }

  //#endregion ^ Key

  //#region === BPM ===

  private _tpm = new ReactiveVar<number | undefined>(undefined);
  tpm(): number | undefined {
    return this._tpm.get();
  }

  bpm(): number | undefined {
    const tpm = this._tpm.get();
    return tpm ? tpmToBpm({ tpm, timeSignature: this.song.timeSignature() }) : undefined;
  }

  setBpm(bpm: number | string): void {
    if (typeof bpm == 'string') bpm = +bpm;
    if (typeof bpm == 'undefined') {
      console.error('BPM was undefined in SongPrefs.setBpm()');
      bpm = 80;
    }
    const tpm = bpmToTpm({ bpm, timeSignature: this.song.timeSignature() });
    this.setTpm(tpm);
  }

  tpmBounds() {
    const min = bpmToTpm({
      bpm: this.song.timeSignature() == '3/4' ? 30 : 20,
      timeSignature: this.song.timeSignature(),
      ignoreUserPreferences: true,
    });
    const max = bpmToTpm({
      bpm: this.song.timeSignature() == '3/4' ? 300 : 200,
      timeSignature: this.song.timeSignature(),
      ignoreUserPreferences: true,
    });
    return { min, max };
  }

  setTpm(tpm: number): void {
    const { min, max } = this.tpmBounds();
    this._tpm.set(Math.min(Math.max(tpm, min), max));
    this.autoSpeedup.ensureMaxTpmIsAtLeast(tpm);
    this.persist('tpm');
  }

  adjustBpm(amount: number): void {
    this.setBpm((this.bpm() || this.song.bpm()) + amount);
  }

  //#endregion ^ BPM

  private persist(attr: 'key' | 'tpm' | 'capo'): void {
    Tracker.nonreactive(() => {
      const id = this.song.id();
      if (!id || this.song.editMode()) return;
      const attributes: Record<string, string | number | undefined> = {};
      if (attr == 'key') attributes['key'] = this.key();
      if (attr == 'tpm') {
        const tpm = this.tpm();
        attributes['tpm'] = tpm;
      }
      if (attr == 'capo') attributes['capoFret'] = this.capo();
      void MusicLibrary.songs.saveAttributes(id, attributes);
    });
  }
}
