import { strict as assert } from 'assert';
import type { Chord } from '@/music/Chord';

type ChordRenderingPreferences = {
  'm': 'm' | '-';
  '+': '+' | 'aug';
  'o': 'o' | 'o7' | 'dim' | 'dim7';
  '0': 'ø' | 'ø7' | 'm7b5';
  '^': 'Δ' | 'Δ7' | 'maj7' | 'M7';
  '4': 'sus' | 'sus4';
};

const defaultRenderingPreferences: ChordRenderingPreferences = {
  'm': 'm',
  '+': '+',
  'o': 'o7',
  '0': 'ø7',
  '^': 'Δ7',
  '4': 'sus',
};

type TypeSymbolDefinitions = Record<Chord['type'], string[]>;

const typeSymbolDefs: Partial<TypeSymbolDefinitions> = {
  '': [],
  '6': ['6'],
  '7': ['7'],
  '9': ['9'],
  '69': ['6', '/', '9'],
  '13': ['13'],
  '7s': ['7', 'sus'],
  '11': ['11'],
  '9s': ['9', 'sus'],
  '13s': ['13', 'sus'],
  '5': ['5'],
  '2': ['sus', '2'],
  '4': ['sus', '4'],
  '7b9': ['7', 'b', '9'],
  '7#11': ['7', '#', '11'],
  '7#9': ['7', '#', '9'],
  '7#5': ['7', '#', '5'],
  '7b13': ['7', 'b', '13'],
  '7alt': ['7', 'alt'],
  '7b5': ['7', 'b', '5'],
  '7#9#5': ['7', '#', '9', '#', '5'],
  '13b9': ['13', 'b', '9'],
  '7b9#5': ['7', 'b', '9', '#', '5'],
  '9#11': ['9', '#', '11'],
  '7b9s': ['7', 'b', '9', 'sus'],
  '7b9b5': ['7', 'b', '9', 'b', '5'],
  '9#5': ['9', '#', '5'],
  '13#11': ['13', '#', '11'],
  '7b9b13': ['7', 'b', '9', 'b', '13'],
  '7b9#11': ['7', 'b', '9', '#', '11'],
  '7#9b5': ['7', '#', '9', 'b', '5'],
  '7#9#11': ['7', '#', '9', '#', '11'],
  '7b9#9': ['7', 'b', '9', '#', '9'],
  '9b5': ['9', 'b', '5'],
  '13#9': ['13', '#', '9'],
};
// | '7b9'
// | '7#11'
// | '7#9'
// | '7#5'
// | '7b13'
// | '7alt'
// | '^7#11'
// | '7b5'
// | '7#9#5'
// | '13b9'
// | '7b9#5'
// | '^7#5'
// | '9#11'
// | '7b9s' //7b9sus
// | '7b9b5'
// | '9#5'
// | '13#11'
// | '7b9b13'
// | '7b9#11'
// | '^9#11'
// | '7#9b5'
// | '7#9#11'
// | '7b9#9'
// | '9b5'
// | '13#9';

const maj7Defs = {
  'Δ': {
    '^': ['Δ'],
    '^9': ['Δ', '9'],
    '^13': ['Δ', '13'],
    '^7#11': ['Δ', '7', '#', '11'],
    '^9#11': ['Δ', '9', '#', '11'],
    '^7#5': ['Δ', '7', '#', '5'],
  },
  'Δ7': {
    '^': ['Δ', '7'],
    '^9': ['Δ', '9'],
    '^13': ['Δ', '13'],
    '^7#11': ['Δ', '7', '#', '11'],
    '^9#11': ['Δ', '9', '#', '11'],
    '^7#5': ['Δ', '7', '#', '5'],
  },
  'maj7': {
    '^': ['maj', '7'],
    '^9': ['maj', '9'],
    '^13': ['maj', '13'],
    '^7#11': ['maj', '7', '#', '11'],
    '^9#11': ['maj', '9', '#', '11'],
    '^7#5': ['maj', '7', '#', '5'],
  },
  'M7': {
    '^': ['M', '7'],
    '^9': ['M', '9'],
    '^13': ['M', '13'],
    '^7#11': ['M', '7', '#', '11'],
    '^9#11': ['M', '9', '#', '11'],
    '^7#5': ['M', '7', '#', '5'],
  },
};

const minorDefs = {
  'm': {
    'm': ['m'],
    'm7': ['m', '7'],
    'm6': ['m', '6'],
    'm9': ['m', '9'],
    'm11': ['m', '11'],
    'mb6': ['m', 'b', '6'],
    'm69': ['m', '6', '/', '9'],
  },
  '-': {
    'm': ['-'],
    'm7': ['-', '7'],
    'm6': ['-', '6'],
    'm9': ['-', '9'],
    'm11': ['-', '11'],
    'mb6': ['-', 'b', '6'],
    'm69': ['-', '6', '/', '9'],
  },
};

const minorMajorDefs = {
  'm': {
    'Δ': {
      'm^': ['m', 'Δ'],
      'm^9': ['m', 'Δ', '9'],
    },
    'Δ7': {
      'm^': ['m', 'Δ', '7'],
      'm^9': ['m', 'Δ', '9'],
    },
    'maj7': {
      'm^': ['mMaj', '7'],
      'm^9': ['mMaj', '9'],
    },
    'M7': {
      'm^': ['mM', '7'],
      'm^9': ['mM', '9'],
    },
  },
  '-': {
    'Δ': {
      'm^': ['-', 'Δ'],
      'm^9': ['-', 'Δ', '9'],
    },
    'Δ7': {
      'm^': ['-', 'Δ', '7'],
      'm^9': ['-', 'Δ', '9'],
    },
    'maj7': {
      '-^': ['-', 'maj', '7'],
      'm^9': ['-', 'maj', '9'],
    },
    'M7': {
      'm^': ['mM', '7'],
      'm^9': ['mM', '9'],
    },
  },
};

const diminishedDefs = {
  'o': {
    'o': ['o'],
    'o9': ['o', '9'],
  },
  'o7': {
    'o': ['o', '7'],
    'o9': ['o', '9'],
  },
  'dim': {
    'o': ['dim'],
    'o9': ['dim', '9'],
  },
  'dim7': {
    'o': ['dim', '7'],
    'o9': ['dim', '9'],
  },
};

const halfDiminishedDefs = {
  'ø': {
    '0': ['ø'],
  },
  'ø7': {
    '0': ['ø', '7'],
  },
  'm7b5': {
    '0': ['m', '7', 'b', '5'],
  },
};

const augmentedDefs = {
  '+': {
    '+': ['+'],
    '+7': ['+', '7'],
  },
  'aug': {
    '+': ['aug'],
    '+7': ['aug', '7'],
  },
};

const dimMaj7WithCircle = {
  'Δ': { 'o^': ['o', 'Δ'] },
  'Δ7': { 'o^': ['o', 'Δ', '7'] },
  'maj7': { 'o^': ['o', 'maj', '7'] },
  'M7': { 'o^': ['o', 'M', '7'] },
};
const dimMaj7WithWord = {
  'Δ': { 'o^': ['dim', 'Δ'] },
  'Δ7': { 'o^': ['dim', 'Δ', '7'] },
  'maj7': { 'o^': ['dim', 'maj', '7'] },
  'M7': { 'o^': ['dim', 'M', '7'] },
};
const diminishedMajorDefs = {
  'o': dimMaj7WithCircle,
  'o7': dimMaj7WithCircle,
  'dim': dimMaj7WithWord,
  'dim7': dimMaj7WithWord,
};

function symbolsToRenderForType(type: Chord['type'], crp: ChordRenderingPreferences): string[] {
  const basicType = typeSymbolDefs[type];
  if (basicType) return basicType;
  const defs = type.startsWith('m^')
    ? minorMajorDefs[crp['m']][crp['^']]
    : type.startsWith('m')
      ? minorDefs[crp['m']]
      : type.startsWith('^')
        ? maj7Defs[crp['^']]
        : type.startsWith('o^')
          ? diminishedMajorDefs[crp['o']][crp['^']]
          : type.startsWith('o')
            ? diminishedDefs[crp['o']]
            : type.startsWith('0')
              ? halfDiminishedDefs[crp['0']]
              : type.startsWith('+')
                ? augmentedDefs[crp['+']]
                : {};
  if (type in defs) return defs[type as keyof typeof defs];
  return ['🤷'];
}

function accidentalSvg(accidental: Chord['accidental']): string {
  if (!accidental) return '';
  const sharpOrFlat = accidental == '#' ? 'sharp' : 'flat';
  return `<svg class="_${sharpOrFlat}"><use xlink:href="#${sharpOrFlat}"></use></svg>`;
}

export function renderChordAsHtml(
  chord: Chord,
  opts?: {
    system?: 'letters' | 'numbers' | 'roman';
    renderingPreferences?: ChordRenderingPreferences;
  }
) {
  const system = opts?.system || 'letters';
  const blocks: string[] = [];

  if (system == 'numbers' || system == 'roman') {
    const interval = chord.getInterval();
    if (system == 'numbers') {
      blocks.push(`<span class="_rootNumber _${interval.number}">${interval.number}</span>`);
      blocks.push(accidentalSvg(interval.accidental));
    }
    if (system == 'roman') {
      blocks.push(accidentalSvg(interval.accidental));
      let roman = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII'][interval.number - 1] ?? '?';
      if (chord.minor) roman = roman.toLowerCase();
      blocks.push(`<span class="_rootRoman _${roman}">${roman}</span>`);
    }
  } else {
    blocks.push(`<span class="_rootLetter _${chord.letter}">${chord.letter}</span>`);
    blocks.push(accidentalSvg(chord.accidental));
  }

  const symbols = symbolsToRenderForType(chord.type, defaultRenderingPreferences);
  symbols.forEach((symbol, i) => {
    if (symbol == '#' || symbol == 'b') {
      blocks.push(accidentalSvg(symbol));
    } else {
      if (system == 'roman' && (symbol == 'm' || symbol == '-') && i == 0) return;
      const className = (/^[0-9]+$/.test(symbol) ? `_extension ` : '') + `_${symbol}`;
      blocks.push(`<span class="${className}">${symbol}</span>`);
    }
  });

  if (chord.bassLetter) {
    blocks.push(`<span class="_bassSlash">/</span>`);
    if (system == 'numbers' || system == 'roman') {
      const interval = chord.getBassInterval();
      assert(interval, `Tried to get bass interval for chord ${chord.toString()}`);
      blocks.push(`<span class="_bassNumber _${interval.number}">${interval.number}</span>`);
      blocks.push(accidentalSvg(interval.accidental));
    } else {
      blocks.push(`<span class="_bassLetter _${chord.bassLetter}">${chord.bassLetter}</span>`);
      blocks.push(accidentalSvg(chord.bassAccidental));
    }
  }

  return `<span class="ChartChord">${blocks.join('')}</span>`;
}
