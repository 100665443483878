import { Meteor } from 'meteor/meteor';
import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { ReactiveVar } from 'meteor/reactive-var';
import { Tracker } from 'meteor/tracker';
import { debounce, isEmpty } from 'underscore';
import { Crnt } from '@/Crnt';
import { MusicLibrary } from '@/library/MusicLibrary';
import SongMenuManager from '@/ui/SongMenuManager';

const cbb = new ReactiveVar<boolean | undefined>(undefined);

export function controlBarBelow(): boolean {
  return !!cbb.get();
}

Meteor.startup(() => {
  Tracker.autorun(recalculateCBB);
  const debounced = debounce(recalculateCBB, 300);
  window.addEventListener('resize', debounced);
  window.addEventListener('orientationchange', debounced);
});

function recalculateCBB(): void {
  const insetSidebarCollapsePoint = 768; // from $bp-song-sidebar in scss
  const sidebarIsOnTheSide = false;
  // !isEmpty(clientModules.songSidebar.templatesRight()) &&
  // window.innerWidth >= insetSidebarCollapsePoint;
  const mainAreaWidth =
    window.innerWidth -
    (sidebarIsOnTheSide ? 360 : 0) -
    (SongMenuManager.sidebarVisible() ? 120 : 0);
  cbb.set(
    FlowRouter.getRouteName() != 'landing-page' &&
      !MusicLibrary.blockingOperationInProgress() &&
      (Crnt.song()?.editMode() || mainAreaWidth < 500)
  );
}
