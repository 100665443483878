import { platform } from '@todesktop/client-core';
import wait from '@/utilities/wait';

Template.desktopLogin.onCreated(() => {
  const instance = Template.instance();
  instance.submitting = new ReactiveVar();
});

Template.desktopLogin.onRendered(() => {
  const instance = Template.instance();
});

Template.desktopLogin.helpers({
  submitting() {
    return Template.instance().submitting.get();
  },
});

Template.desktopLogin.events({
  'click .js-login': function (event, instance) {
    instance.submitting.set(true);
    wait(3000).then(() => instance.submitting.set(false));
    platform.os.openURL(Meteor.absoluteUrl() + 'log-into-desktop');
  },
});
