Template.indexSearchStatus.onCreated(() => {
  const instance = Template.instance();
  instance.searcher = Template.currentData().searcher;
});

Template.indexSearchStatus.helpers({
  queryPresent() {
    const instance = Template.instance();
    return instance.searcher.queryPresent();
  },

  statusLine() {
    const instance = Template.instance();
    const query = instance.searcher.query();
    if (query) {
      let num = instance.searcher.resultsCount();
      if (num == 0) num = 'No';
      const noun = num == 1 ? 'song' : 'songs';
      let srh;
      if (/^[a-z]$/i.test(query.text) || query.firstLetter) {
        srh = `${num} ${noun} start with “${(query.text || query.firstLetter).toUpperCase()}”`;
      } else if (query.numbers) {
        srh = `${num} ${noun} start with a number`;
      } else if (query.own) {
        srh = `${num} ${noun} added by you`;
      } else if (query.all || query.allSongs) {
        srh = `${num} ${noun} in your library`;
      } else if (query.allMedleys) {
        const medleyNoun = num == 1 ? 'medley' : 'medleys';
        srh = `${num} ${medleyNoun} in your library`;
      } else {
        srh = `${num} ${noun} match “${query.text}”`;
      }
      instance.lastSearchResultsHeading = srh;
      return srh;
    } else {
      return instance.lastSearchResultsHeading;
    }
  },
});

Template.indexSearchStatus.events({
  'click .js-clear': (event, instance) => {
    instance.searcher.setQuery(null);
    event.preventDefault();
  },
});
