import '@/browser/route-change-confirmation';
import * as Sentry from '@sentry/browser';
import { assertNonEmptyString, assertString } from '@sindresorhus/is';
import { Accounts } from 'meteor/accounts-base';
import { Meteor } from 'meteor/meteor';
import type { Param, QueryParam, Router } from 'meteor/ostrio:flow-router-extra';
import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { Tracker } from 'meteor/tracker';
import { eventTracker } from '@/browser/analytics/eventTracker';
import { ClientManager } from '@/browser/ClientManager';
import { clog } from '@/browser/clog';
import { setPageTitleAndMeta } from '@/browser/setPageTitleAndMeta';
import HelpWindow from '@/help/HelpWindow';
import { BlazeLayout } from '@/lib/BlazeLayout';
import ReferenceEmbedDisplay from '@/references/ReferenceEmbedDisplay';
import { Roles } from '@/Roles';
import { rpcGetMagicLoginToken } from '@/server/methods/accounts/rpcGetMagicLoginToken';
import { rpcRestartFreeTrial } from '@/server/methods/accounts/rpcRestartFreeTrial';
import { rpcStartTrial } from '@/server/methods/accounts/rpcStartTrial';
import { rpcStartCheckoutSessionForUpdatingPayment } from '@/server/methods/stripe/rpcStartCheckoutSessionForUpdatingPayment';
import { rpcDiscourseSSOLogin } from '@/server/methods/user/rpcDiscourseSSOLogin';
import SongMenuManager from '@/ui/SongMenuManager';
import wait from '@/utilities/wait';
import waitUntilReactive from '@/utilities/waitUntilReactive';

Meteor.startup(() => {
  Tracker.autorun(() => {
    if (FlowRouter.getRouteName() == 'mobile-subscribe') return;
    if (
      Roles.userHasRole(Meteor.userId(), {
        $in: ['paid-member', 'lifetime-member', 'trial-member'],
      })
    )
      return;
    if (Meteor.user('subscription') && !Meteor.user('subscription')?.subscription) {
      if (Meteor.isCordova) {
        FlowRouter.withReplaceState(() => FlowRouter.go('mobile-subscribe'));
      }
    }
  });
});

function redirectToAppIfSignedIn(_context: unknown, redirect: Router['go'], stop: () => void) {
  if (Meteor.userId()) {
    FlowRouter.withReplaceState(() => redirect('/app'));
    Meteor.setTimeout(() => setPageTitleAndMeta(), 100);
    stop();
  }
}

function redirectToLPIfLoggedOutCordova(
  _context: unknown,
  redirect: Router['go'],
  stop: () => void
) {
  if (!Meteor.userId()) {
    if (Meteor.isCordova) {
      FlowRouter.withReplaceState(() => redirect('/app'));
      stop();
    }
  }
}

function redirectToLPIfLoggedOutDesktop(
  _context: unknown,
  redirect: Router['go'],
  stop: () => void
) {
  if (!Meteor.userId()) {
    if (window.todesktop) {
      FlowRouter.withReplaceState(() =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        redirect(window.todesktop.os.platform == 'linux' ? '/login' : '/desktop-login')
      );
      stop();
    }
  }
}

function betaSSOLoginRedirect(
  current: ReturnType<Router['current']>,
  _redirect: Router['go'],
  stop: () => void
) {
  if (
    !Meteor.userId() &&
    /alpha|beta/.test(window.location.hostname) &&
    !window.todesktop &&
    !current.path.includes('subdomain-sso')
  ) {
    const subdomain = window.location.hostname.split('.')[0] ?? 'beta';
    window.location.href = `https://strummachine.com/switch-to/${subdomain}?path=${encodeURIComponent(window.location.pathname + window.location.search)}`;
    stop();
  }
}

let lastRoutePath: string | undefined;
function updateClientOnRouteChange(
  current: ReturnType<Router['current']>,
  _redirect: Router['go'],
  stop: () => void
) {
  const canReloadNow = !lastRoutePath || lastRoutePath != current.context.pathname;
  lastRoutePath = current.context.pathname;
  if (current.context.pathname == '/app' && ClientManager.updateReady() && canReloadNow) {
    ClientManager.update();
    stop();
  }
}

function hideFullScreenHelpWindow(
  _current: ReturnType<Router['current']>,
  _redirect: Router['go'],
  _stop: () => void
) {
  if ($('.floatingWindow--help.full-screen').is(':visible') && !FlowRouter.getQueryParam('help')) {
    HelpWindow.hide();
  }
}

FlowRouter.triggers.enter([
  updateClientOnRouteChange,
  hideFullScreenHelpWindow,
  betaSSOLoginRedirect,
]);
FlowRouter.triggers.exit([
  (_context, _redirect, _stop) => {
    Modal.hide();
    bootbox.hideAll();
    SongMenuManager.showMenu(null);
    ReferenceEmbedDisplay.hide();
  },
]);

FlowRouter.route('/', {
  name: 'landing-page',
  title: window.todesktop
    ? 'Strum Machine'
    : 'Strum Machine: customizable backing tracks made from real instruments',
  description:
    'Choose from over 1000 bluegrass/old-time/etc songs, OR type in your own chords, and play along in any key, at any speed. It’s the easiest way to practice with backing tracks.',
  triggersEnter: [
    (context, redirect, stop) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const appMode =
        window.todesktop ||
        Meteor.isCordova ||
        // @ts-expect-error Standalone *does* exist... sometimes
        window.navigator.standalone == true ||
        window.matchMedia('(display-mode: standalone)').matches;
      if (appMode || Meteor.userId()) {
        FlowRouter.withReplaceState(() => redirect('/app'));
        Meteor.setTimeout(() => setPageTitleAndMeta(), 100);
      } else {
        if (context.oldRoute) {
          // we're navigating to the home page, so show it (since we're not logged in)
          window.location.href = '/';
        } else {
          // Server would only load this instead of static if cookie was set, so show login page
          window.location.href = '/login';
        }
      }
    },
  ],
});

/**
 **  Account routes
 */

FlowRouter.route('/signup', {
  name: 'signup',
  title: 'Start your free trial - Strum Machine',
  enterEvent: 'NewAccount.View',
  noindex: true,
  triggersEnter: [
    redirectToAppIfSignedIn,
    redirectToLPIfLoggedOutDesktop,
    (_context, redirect, stop) => {
      if (Meteor.isCordova && /Android/i.test(navigator.userAgent)) {
        bootbox.alert({
          title: 'Need an account?',
          message:
            'You can’t sign up for Strum Machine in the app. Sorry, we know it’s a hassle. Go to <a href="https://strummachine.com" target="_system" class="font-medium">strummachine.com</a>, sign up for a free trial account, then log into this app with your new account to start practicing with Strum Machine.',
        });
        FlowRouter.withReplaceState(() => redirect('/app'));
        stop();
      }
    },
  ],
  action: () => BlazeLayout.render('appEntryPage'),
});

FlowRouter.route('/login', {
  name: 'login',
  title: 'Log In to Strum Machine',
  enterEvent: 'Login.ViewPage',
  noindex: true,
  triggersEnter: [redirectToAppIfSignedIn],
  action: () => BlazeLayout.render('appEntryPage'),
});

FlowRouter.route('/login-code', {
  name: 'login-code',
  title: 'Enter Your Login Code',
  enterEvent: 'LoginCode.ViewPage',
  noindex: true,
  triggersEnter: [redirectToAppIfSignedIn],
  action: () => BlazeLayout.render('appEntryPage'),
});

FlowRouter.route('/login-name', {
  name: 'login-name',
  title: 'What’s your name?',
  enterEvent: 'LoginName.ViewPage',
  noindex: true,
  triggersEnter: [redirectToAppIfSignedIn],
  action: () => BlazeLayout.render('appEntryPage'),
});

if (Meteor.isCordova) {
  FlowRouter.route('/mobile-subscribe', {
    name: 'mobile-subscribe',
    title: 'Strum Machine Subscription Signup',
    enterEvent: 'MobileSubscribe.ViewPage',
    noindex: true,
    action: () => BlazeLayout.render('appEntryPage'),
  });
}

FlowRouter.route('/forgot-password', {
  name: 'forgot-password',
  enterEvent: 'ForgotPassword.View',
  title: 'Forgot Your Password - Strum Machine',
  noindex: true,
  action: () => BlazeLayout.render('appEntryPage'),
});

FlowRouter.route('/forgot-password-sent', {
  name: 'forgot-password-sent',
  noindex: true,
  action: () => BlazeLayout.render('appEntryPage'),
});

FlowRouter.route('/reset-password/:token', {
  name: 'reset-password',
  enterEvent: 'Password.Reset.View',
  noindex: true,
  action: () => BlazeLayout.render('appEntryPage'),
});

FlowRouter.route('/app/extend-trial', {
  name: 'extend-trial',
  noindex: true,
  async action() {
    if (Meteor.userId()) {
      Bert.alert('Extending your free trial...', 'success');
      try {
        await rpcRestartFreeTrial({ lengthInDays: 7 });
        FlowRouter.withReplaceState(() => FlowRouter.go('song-index', {}, {}));
        Bert.hide();
        bootbox.alert({
          message: 'OK, your free trial has been extended. Have fun!',
        });
      } catch (err) {
        FlowRouter.withReplaceState(() => FlowRouter.go('song-index', {}, {}));
        Bert.hide();
        // @ts-expect-error Not gonna bother typing this
        if (err?.error == 'ineligible-for-trial-extension') {
          bootbox.alert({
            message:
              "Sorry, that URL is invalid. Perhaps you've already extended your free trial? Write support@strummachine.com if you need assistance.",
          });
        } else if (err) {
          // @ts-expect-error Not gonna bother typing this
          Bert.alert(err, 'danger');
        }
      }
    } else {
      FlowRouter.go('login', {}, { redirect: '/app/extend-trial' });
    }
  },
});

FlowRouter.route('/app/delete-account', {
  name: 'delete-account',
  noindex: true,
  action: () =>
    BlazeLayout.render('appContainer', { screen: 'side', template: 'deleteAccountPage' }),
});

if (!Meteor.isCordova) {
  FlowRouter.route('/signup-verify', {
    name: 'signup-verify',
    title: 'Verify your email',
    triggersEnter: [redirectToAppIfSignedIn],
    noindex: true,
    action: () => BlazeLayout.render('signupVerifyPage'),
  });

  FlowRouter.route('/verify-email/:token', {
    name: 'verify-email',
    noindex: true,
    action() {
      const token = FlowRouter.getParam('token');
      void Accounts.verifyEmail(token, (err) => {
        FlowRouter.withReplaceState(() => {
          // @ts-expect-error Not gonna bother typing this
          if (err && err.reason == 'Verify email link expired') {
            if (Meteor.userId()) {
              FlowRouter.go('song-index');
            } else {
              Bert.alert('Sorry, that link has expired.', 'warning');
              FlowRouter.go('landing-page');
            }
          } else if (err) {
            // @ts-expect-error Not gonna bother typing this
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            Bert.alert(err.reason, 'warning');
            FlowRouter.go('landing-page');
          } else {
            void rpcStartTrial().then(() => {
              eventTracker.trialStart();
              FlowRouter.go('signup-finish');
            });
          }
        });
      });
    },
  });

  FlowRouter.route('/change-email/:token', {
    name: 'change-email',
    noindex: true,
    action() {
      const token = FlowRouter.getParam('token');
      Accounts.callLoginMethod({
        methodArguments: [
          {
            newEmailVerificationToken: token,
          },
        ],
        userCallback(err) {
          FlowRouter.withReplaceState(() => {
            FlowRouter.go(Meteor.userId() ? 'account' : 'landing-page');
          });
          setTimeout(() => {
            if (err && err.reason.includes('expired')) {
              Bert.alert('Sorry, that link has expired or been used already.', 'warning');
            } else if (err) {
              Bert.alert(err.reason, 'warning');
            } else {
              Bert.alert('Your email address has been updated! 🎉', 'success');
            }
          }, 500);
        },
      });
    },
  });

  FlowRouter.route('/account-deleted', {
    name: 'account-deleted',
    title: 'Account Has Been Deleted',
    noindex: true,
    enterEvent: 'AccountDeletedPage.View',
    action: () => BlazeLayout.render('accountDeletedPage'),
  });

  FlowRouter.route('/signup-finish', {
    name: 'signup-finish',
    noindex: true,
    action: () => BlazeLayout.render('signupFinishPage'),
  });

  FlowRouter.route('/desktop-login', {
    name: 'desktop-login',
    title: 'Strum Machine',
    enterEvent: 'DesktopLogin.ViewPage',
    noindex: true,
    triggersEnter: [redirectToAppIfSignedIn],
    action: () => BlazeLayout.render('appEntryPage'),
  });

  FlowRouter.route('/desktop-sso-complete', {
    name: 'desktop-sso-complete',
    title: 'Strum Machine',
    noindex: true,
    action: () => BlazeLayout.render('appEntryPage'),
  });

  const magicLoginRedirect = function magicLoginRedirect(
    route: string,
    queryParams?: Record<string, string | undefined>
  ) {
    const token = FlowRouter.getParam('token');
    Accounts.callLoginMethod({
      methodArguments: [
        {
          magicCode: token,
        },
      ],
      userCallback(_errorClass) {
        FlowRouter.withReplaceState(() => {
          FlowRouter.go(
            route,
            {},
            queryParams ? (JSON.parse(JSON.stringify(queryParams)) as Record<string, string>) : {}
          );
        });
      },
    });
  };

  FlowRouter.route('/magic/:token', {
    name: 'magic-login',
    noindex: true,
    action() {
      magicLoginRedirect('song-index');
    },
  });

  FlowRouter.route('/account/:token', {
    name: 'account-magic',
    noindex: true,
    action() {
      magicLoginRedirect('account', {});
    },
  });

  FlowRouter.route('/update-payment/:token', {
    name: 'update-payment-magic',
    noindex: true,
    action() {
      magicLoginRedirect('update-payment', {
        /* overdue: 1 */
      });
    },
  });

  FlowRouter.route('/switch-currency/:currency/:token', {
    name: 'switch-currency-magic',
    noindex: true,
    action(params) {
      magicLoginRedirect('switch-currency', { currency: params.currency });
    },
  });

  FlowRouter.route('/subscribe/:token', {
    name: 'subscribe-magic',
    noindex: true,
    action() {
      magicLoginRedirect('subscribe');
    },
  });

  FlowRouter.route('/extend-trial/:token', {
    name: 'extend-trial-magic',
    noindex: true,
    action() {
      magicLoginRedirect('extend-trial');
    },
  });

  FlowRouter.route('/cancel-account/:token', {
    name: 'cancel-account-magic',
    noindex: true,
    action() {
      magicLoginRedirect('cancel-account');
    },
  });

  FlowRouter.route('/delete-account/:token', {
    name: 'delete-account-magic',
    noindex: true,
    action() {
      magicLoginRedirect('delete-account');
    },
  });

  FlowRouter.route('/switch-to/:subdomain', {
    name: 'switch-subdomain',
    noindex: true,
    async action() {
      const subdomain = FlowRouter.getParam('subdomain').replace(/[^a-z]+/, '');
      // Can't use FlowRouter.getQueryParam - it's buggy
      const path = new URLSearchParams(window.location.search).get('path') || '';
      const newServer = /prod|main|www/.test(subdomain)
        ? 'https://strummachine.com'
        : `https://${subdomain}.strummachine.com`;
      if (Meteor.userId()) {
        const magicToken = await rpcGetMagicLoginToken({ userId: Meteor.userId() });
        if (magicToken) {
          window.location.href =
            `${newServer}/subdomain-sso/${magicToken}` +
            (path ? `?path=${encodeURIComponent(path)}` : '');
        } else {
          window.location.href = newServer + path;
        }
      } else {
        FlowRouter.go('login', {}, { redirect: window.location.pathname + window.location.search });
      }
    },
  });

  FlowRouter.route('/subdomain-sso/:token', {
    name: 'subdomain-sso-magic',
    noindex: true,
    action() {
      const token = FlowRouter.getParam('token');
      Accounts.callLoginMethod({
        methodArguments: [
          {
            magicCode: token,
          },
        ],
        userCallback(_errorClass) {
          // Can't use FlowRouter.getQueryParam - it's buggy
          const path = new URLSearchParams(window.location.search).get('path') || '';
          FlowRouter.withReplaceState(() => {
            FlowRouter.go(path || '/app');
          });
        },
      });
    },
  });

  FlowRouter.route('/log-into-desktop', {
    name: 'log-into-desktop',
    noindex: true,
    async action() {
      // Can't use FlowRouter.getQueryParam - it's buggy
      const path = new URLSearchParams(window.location.search).get('path') || '';
      if (Meteor.userId()) {
        const magicToken = await rpcGetMagicLoginToken({ userId: Meteor.userId() });
        const redirectUrl = magicToken
          ? (window.location.href =
              `strummachine://subdomain-sso/${magicToken}` +
              (path ? `?path=${encodeURIComponent(path)}` : ''))
          : 'strummachine://' + path;
        FlowRouter.withReplaceState(() => {
          FlowRouter.go('desktop-sso-complete', {}, { redirectUrl });
        });
        wait(300).then(() => (window.location.href = redirectUrl));
      } else {
        FlowRouter.go('login', {}, { redirect: window.location.pathname + window.location.search });
      }
    },
  });
}

FlowRouter.route('/privacy', {
  name: 'privacy',
  title: 'Strum Machine Privacy Policy',
  action: () => BlazeLayout.render('privacyPage'),
});

FlowRouter.route('/terms', {
  name: 'terms',
  title: 'Strum Machine Terms of Use',
  action: () => BlazeLayout.render('termsPage'),
});

FlowRouter.route('/refunds', {
  name: 'refunds',
  title: 'Strum Machine Refunds Policy',
  action: () => BlazeLayout.render('refundsPage'),
});

FlowRouter.route('/help', {
  name: 'docs-index',
  title: 'Strum Machine Help Guides',
  action: () => BlazeLayout.render('docsPage', { page: 'index' }),
});

FlowRouter.route('/help/:page', {
  name: 'docs-page',
  title: 'Strum Machine Help Guides',
  action: () => BlazeLayout.render('docsPage'),
});

/**
 **  App routes
 */

FlowRouter.route('/app', {
  name: 'song-index',
  title: 'Strum Machine',
  enterEvent: (_params: Param, queryParams: QueryParam) => {
    if (queryParams.help) return 'HelpWindow.View';
    if (queryParams.modal == 'advanced-settings') return 'Settings.ViewAdvanced';
    if (queryParams.search) return 'SongIndex.Search';
    return 'SongIndex.View';
  },
  triggersEnter: [redirectToLPIfLoggedOutDesktop],
  action: () =>
    BlazeLayout.render('appContainer', { screen: 'index', publicPage: 'publicIndexPage' }),
});

FlowRouter.route('/app/songs', {
  triggersEnter: [
    redirectToLPIfLoggedOutDesktop,
    function (_context: unknown, redirect: (typeof FlowRouter)['go']) {
      redirect(FlowRouter.path('song-index'));
    },
  ],
});

FlowRouter.route('/app/songs/new', {
  name: 'new-song',
  title: 'New song • Strum Machine',
  enterEvent: 'Song.New',
  noindex: true,
  triggersEnter: [redirectToLPIfLoggedOutDesktop, redirectToLPIfLoggedOutCordova],
  action: () =>
    BlazeLayout.render('appContainer', { screen: 'music', publicPage: 'publicSongPage' }),
});

FlowRouter.route('/app/songs/new-copy/:songId', {
  name: 'copy-song',
  title: 'Editing a copy of {songName} • Strum Machine',
  enterEvent: 'Song.New.Copy',
  noindex: true,
  triggersEnter: [redirectToLPIfLoggedOutDesktop, redirectToLPIfLoggedOutCordova],
  action: () =>
    BlazeLayout.render('appContainer', { screen: 'music', publicPage: 'publicSongPage' }),
});

FlowRouter.route('/app/songs/:songId', {
  name: 'show-song',
  title: '{songName} • Backing Track on Strum Machine', // SEO module has code to remove this...
  description:
    'Real rhythm instruments back you up in any key at any speed. Pick from 1,000+ bluegrass/old-time songs (including {songName}) or add your own song.',
  enterEvent: 'Song.View',
  action(params) {
    if (params.songId?.includes('<')) {
      params.songId = params.songId.replace(/<.*$/, '');
      FlowRouter.go('show-song', params);
      return;
    }
    BlazeLayout.render('appContainer', { screen: 'music', publicPage: 'publicSongPage' });
  },
});

FlowRouter.route('/app/songs/:songId/edit', {
  name: 'edit-song',
  title: 'Editing {songName} • Strum Machine',
  enterEvent: 'Song.Edit',
  noindex: true,
  triggersEnter: [redirectToLPIfLoggedOutDesktop, redirectToLPIfLoggedOutCordova],
  action: () =>
    BlazeLayout.render('appContainer', { screen: 'music', publicPage: 'publicSongPage' }),
});

FlowRouter.route('/app/songs/:songId/submit', {
  name: 'submit-song',
  title: 'Nomination Form for {songName} • Strum Machine',
  enterEvent: 'Song.Submit',
  noindex: true,
  action: () => BlazeLayout.render('appContainer', { screen: 'side', template: 'songSubmitPage' }),
});

FlowRouter.route('/app/medleys/new', {
  name: 'new-medley',
  title: 'New medley • Strum Machine',
  enterEvent: 'Medley.New',
  noindex: true,
  triggersEnter: [redirectToLPIfLoggedOutDesktop, redirectToLPIfLoggedOutCordova],
  action: () =>
    BlazeLayout.render('appContainer', { screen: 'music', publicPage: 'publicMedleyPage' }),
});

FlowRouter.route('/app/medleys/:medleyId', {
  name: 'show-medley',
  title: '{songName} • Strum Machine',
  enterEvent: 'Medley.View',
  noindex: true,
  triggersEnter: [redirectToLPIfLoggedOutDesktop, redirectToLPIfLoggedOutCordova],
  action(params) {
    if (params.medleyId?.includes('<')) {
      params.medleyId = params.medleyId.replace(/<.*$/, '');
      FlowRouter.go('show-medley', params);
      return;
    }
    BlazeLayout.render('appContainer', { screen: 'music', publicPage: 'publicMedleyPage' });
  },
});

FlowRouter.route('/app/lists/:listId', {
  name: 'song-list',
  title: 'Strum Machine song list',
  enterEvent: 'SongList.View',
  noindex: true,
  triggersEnter: [redirectToLPIfLoggedOutDesktop, redirectToLPIfLoggedOutCordova],
  action(params) {
    if (params.listId?.includes('<')) {
      params.listId = params.listId.replace(/<.*$/, '');
      FlowRouter.go('song-list', params);
      return;
    }
    BlazeLayout.render('appContainer', {
      screen: 'side',
      template: 'songListPage',
      publicPage: 'publicSongListPage',
    });
  },
});

FlowRouter.route('/app/account', {
  name: 'account',
  title: 'Account - Strum Machine',
  enterEvent: 'AccountPage.View',
  triggersEnter: [redirectToLPIfLoggedOutDesktop, redirectToLPIfLoggedOutCordova],
  action: () => BlazeLayout.render('appContainer', { screen: 'side', template: 'accountPage' }),
});

FlowRouter.route('/app/subscribe', {
  name: 'subscribe',
  title: 'Activate Your Subscription to Strum Machine',
  enterEvent: 'SubscribePage.View',
  action: () => {
    if (Meteor.isCordova) {
      (async () => {
        const magicToken = await rpcGetMagicLoginToken({ userId: Meteor.userId() });
        const url = magicToken
          ? `https://strummachine.com/subscribe/${magicToken}`
          : 'https://strummachine.com/app/subscribe';
        window.open(url, '_system', 'location=no');
        setTimeout(() => FlowRouter.go('/app'), 500);
      })();
    } else {
      BlazeLayout.render('appContainer', { screen: 'side', template: 'subscribePage' });
    }
  },
});

FlowRouter.route('/app/cancel-account', {
  name: 'cancel-account',
  title: 'Account Cancellation - Strum Machine',
  noindex: true,
  enterEvent: 'CancelAccountPage.View',
  action: () => {
    if (Meteor.isCordova) {
      (async () => {
        const magicToken = await rpcGetMagicLoginToken({ userId: Meteor.userId() });
        const url = magicToken
          ? `https://strummachine.com/cancel-account/${magicToken}`
          : 'https://strummachine.com/app/cancel-account';
        window.open(url, '_system', 'location=no');
        setTimeout(() => FlowRouter.go('/app'), 500);
      })();
    } else {
      BlazeLayout.render('appContainer', { screen: 'side', template: 'cancelAccountPage' });
    }
  },
});

FlowRouter.route('/app/switch-currency', {
  name: 'switch-currency',
  title: 'Strum Machine - Switch Currencies',
  enterEvent: 'SwitchCurrencyPage.View',
  action: () =>
    BlazeLayout.render('appContainer', { screen: 'side', template: 'switchCurrencyPage' }),
});

FlowRouter.route('/app/account-cancelled', {
  name: 'cancelled',
  title: 'Account Has Been Cancelled',
  noindex: true,
  enterEvent: 'AccountCancelledPage.View',
  action: () => BlazeLayout.render('appContainer', { screen: 'side', template: 'cancelledPage' }),
});

FlowRouter.route('/app/update-payment', {
  name: 'update-payment',
  noindex: true,
  enterEvent: 'UpdatePayment.View',
  title: 'Update Billing Info on Strum Machine',
  action: () => {
    if (Meteor.userId()) {
      (async () => {
        try {
          const { redirectUrl } = await rpcStartCheckoutSessionForUpdatingPayment();
          assertNonEmptyString(redirectUrl);
          window.location.href = redirectUrl;
        } catch (err) {
          Sentry.captureException(err);
          window.location.href = '/app/account';
        }
      })();
    } else {
      FlowRouter.go('login', {}, { redirect: window.location.pathname + window.location.search });
    }
  },
});

FlowRouter.route('/app/gift', {
  name: 'gift',
  title: 'Give the Gift of Strum Machine',
  enterEvent: 'GiftPage.View',
  action: () => BlazeLayout.render('appContainer', { screen: 'side', template: 'giftPage' }),
});

FlowRouter.route('/app/contact', {
  name: 'contact',
  title: 'Contact Strum Machine',
  enterEvent: 'ContactPage.View',
  triggersEnter: [redirectToLPIfLoggedOutDesktop, redirectToLPIfLoggedOutCordova],
  action: () => BlazeLayout.render('appContainer', { screen: 'side', template: 'contactPage' }),
});

FlowRouter.route('/app/install', {
  name: 'install',
  title: 'Strum Machine - Apps & Offline',
  enterEvent: 'InstallPage.View',
  triggersEnter: [
    (_context: unknown, redirect: (typeof FlowRouter)['go'], stop: () => void) => {
      if (Meteor.isCordova || window.todesktop) {
        Bert.alert(
          'You’ve already got the app installed, with offline mode enabled! 👍',
          'success'
        );
        FlowRouter.withReplaceState(() => redirect('/app'));
        stop();
      }
    },
  ],
  action: () => BlazeLayout.render('appContainer', { screen: 'side', template: 'installPage' }),
});

FlowRouter.route('/discourse-sso', {
  name: 'discourse-sso',
  title: 'Signing into the Strum Machine Community',
  noindex: true,
  async action(_params, queryParams) {
    // we start with this in a waitUntilReactive in case the user gets logged out
    void waitUntilReactive(() => !Meteor.userId()).then(() => {
      clientModules.splashScreen.hide();
      FlowRouter.go('login', {}, { redirect: window.location.pathname + window.location.search });
    });

    if (Meteor.userId()) {
      clientModules.splashScreen.show();
      clientModules.splashScreen.hold();

      try {
        const { sso: ssoQueryParam, sig: sigQueryParam } = queryParams;
        assertString(ssoQueryParam);
        assertString(sigQueryParam);
        const { redirectUrl } = await rpcDiscourseSSOLogin({ ssoQueryParam, sigQueryParam });
        window.location.replace(redirectUrl);
      } catch (err) {
        clog.error(err);
        const splashScreenMessage = document.getElementById('splashScreenMessage');
        if (err instanceof Meteor.Error && splashScreenMessage) {
          splashScreenMessage.innerHTML =
            err.reason ??
            'Unable to log you into the forum. Please contact support@strummachine.com.';
        }
      }
    }
  },
});

/* Internal admin pages */

FlowRouter.route('/nominations', {
  name: 'nominations-admin',
  title: 'Strum Machine Song Nominations Admin',
  noindex: true,
  action() {
    BlazeLayout.render('nominationsAdminPage');
  },
});

FlowRouter.route('/metrics', {
  name: 'metrics',
  title: 'Strum Machine Metrics',
  noindex: true,
  action() {
    BlazeLayout.render('metricsPage');
  },
});

FlowRouter.route('/impersonate', {
  name: 'impersonate',
  title: 'Loading...',
  noindex: true,
  async action(_params, queryParams) {
    const currentUser = await waitUntilReactive(() => Meteor.user());
    const originalUserId = currentUser._id;
    const userIdToImpersonate = queryParams.userId;
    if (!userIdToImpersonate) return;
    const success = await window.impersonateUser(userIdToImpersonate);
    if (!success) return;
    await waitUntilReactive(() => Meteor.userId() != originalUserId);
    const newUser = await waitUntilReactive(() => Meteor.user());
    Bert.alert(
      `Now impersonating ${newUser.profile?.firstName} ${newUser.profile?.lastName} (${newUser.emails?.[0]?.address})`,
      'success'
    );
    setTimeout(() => FlowRouter.go('song-index'), 400);
  },
});

/* 404s... */
FlowRouter.route('/*', {
  name: 'not-found',
  title: '404 Not Found',
  enterEvent: 'Error.404',
  noindex: true,
  triggersEnter: [redirectToLPIfLoggedOutDesktop, redirectToLPIfLoggedOutCordova],
  action: () => BlazeLayout.render('notFound'),
});

if (Meteor.isDevelopment) {
  (window.dev ??= {}).FlowRouter = FlowRouter;
}
