<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { bounded } from '@/utilities/bounded';

  interface Props {
    checked: boolean;
    indeterminate?: boolean;
    name?: string;
    ariaLabel?: string;
  }

  let { checked = false, indeterminate = false, name, ariaLabel }: Props = $props();

  const dispatch = createEventDispatcher<{ 'change': boolean }>();

  let inputEl: HTMLInputElement;
  let handleContainerEl: HTMLElement;
  let handleEl: HTMLElement;

  let capturedPointerId: number | undefined;
  let dragInProgress = false;

  let pointerDownOffset = 0;
  let leftBound = 0;
  let rightBound = 0;

  function handleClick(event: MouseEvent) {
    if (dragInProgress) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      checked = !checked;
    }
    capturedPointerId = undefined;
    dragInProgress = false;
  }

  function handlePointerDown(event: PointerEvent) {
    if (event.buttons != 1) return;
    handleEl.setPointerCapture(event.pointerId);
    capturedPointerId = event.pointerId;
    leftBound = handleContainerEl.getBoundingClientRect().left;
    rightBound = handleContainerEl.getBoundingClientRect().right;
    const center = (leftBound + rightBound) / 2;
    pointerDownOffset =
      event.pageX - (inputEl.indeterminate ? center : inputEl.checked ? rightBound : leftBound);
  }

  function handlePointerMove(event: PointerEvent) {
    if (capturedPointerId != event.pointerId) return;
    if (event.buttons != 1) return;
    if (!event.movementX) return;
    const boundWidth = rightBound - leftBound;
    const currentX = event.pageX - pointerDownOffset;
    const pointerBias = bounded((currentX - leftBound) / boundWidth, 0, 1);
    if (inputEl.indeterminate && pointerBias > 0.4 && pointerBias < 0.6) return;
    const shouldBeChecked = pointerBias > 0.5;
    if (inputEl.checked !== shouldBeChecked) {
      dragInProgress = true;
      checked = shouldBeChecked;
      dispatch('change', checked);
      inputEl.indeterminate = false;
    }
  }
</script>

<label class="ToggleSwitch" draggable="false">
  <input
    type="checkbox"
    {name}
    aria-label={ariaLabel}
    bind:checked
    bind:indeterminate
    on:click|stopPropagation={handleClick}
    on:change={() => dispatch('change', checked)}
    bind:this={inputEl}
  />
  <span class="ToggleSwitch__track">
    <div class="ToggleSwitch__handleContainer" bind:this={handleContainerEl}>
      <span
        class="ToggleSwitch__handle"
        bind:this={handleEl}
        on:pointerdown={handlePointerDown}
        on:pointermove={handlePointerMove}
      />
    </div>
  </span>
</label>

<style>
  :root {
    --track-bg-off: var(--muted-100);
    --track-bg-off-hover: var(--muted-150);
    --track-bg-on: hsl(120, 100%, 35%);
    --track-bg-on-hover: hsl(120, 100%, 30%);
    --track-bg-indeterminate: hsl(120, 20%, 50%);
    --track-bg-indeterminate-gradient: linear-gradient(
      90deg,
      hsl(128, 35%, 65%) 40%,
      hsl(128, 10%, 75%) 60%
    );
    --handle-fill: white;
    --handle-border: var(--gray-400);
    --handle-size: 1.5rem;
    --track-handle-ratio: 1.85;
    --handle-outset: 2px;
  }

  /* don't hide the input from screen-readers and keyboard access */
  input {
    position: absolute;
    overflow: visible;
    opacity: 0;
    z-index: 3;
    pointer-events: none;
  }

  label {
    position: relative;
    display: block;
    padding: var(--handle-outset);
    cursor: pointer;
    user-select: none;
    touch-action: pan-y;
    border-radius: 1em; /* for outline */
  }

  * {
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .ToggleSwitch__track {
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    overflow: visible;
    position: relative;
    width: calc(var(--handle-size) * var(--track-handle-ratio) - (var(--handle-outset) * 2));
    height: calc(var(--handle-size) - var(--handle-outset) * 2);
    padding: 0 calc(var(--handle-size) / var(--track-handle-ratio) - (var(--handle-outset) * 2));
    border-radius: var(--handle-size);
    background: var(--track-bg-off);
    box-shadow:
      inset 0px 0 2px 1px rgba(0, 0, 0, 0.1),
      0px -1px 2px 0px rgba(255, 255, 255, 0.5);

    @media not all and (hover: none), (min--moz-device-pixel-ratio: 1) {
      &:hover {
        background: var(--track-bg-off-hover);
      }
    }

    input:checked ~ & {
      background: var(--track-bg-on);
      box-shadow:
        inset 0px 0 2px 1px rgba(0, 0, 0, 0.05),
        0px -1px 2px 0px rgba(255, 255, 255, 0.5);
      @media not all and (hover: none), (min--moz-device-pixel-ratio: 1) {
        &:hover {
          background: var(--track-bg-on-hover);
        }
      }
    }

    input:indeterminate ~ & {
      background: var(--track-bg-indeterminate) var(--track-bg-indeterminate-gradient);
    }
  }

  .ToggleSwitch__handleContainer {
    position: relative;
    flex: 1 1 auto;
  }

  .ToggleSwitch__handle {
    display: block;
    position: absolute;
    left: 0%;
    top: 50%;
    width: var(--handle-size);
    height: var(--handle-size);
    border-radius: calc(var(--handle-size) / 2);
    background: var(--handle-fill);
    border: solid 1px var(--handle-border);
    box-shadow:
      0px 0px 1px 0px rgba(0, 0, 0, 0.3),
      0px 1px 6px 0px rgba(0, 0, 0, 0.2);
    transform: translate(-50%, -50%);

    label:active & {
      left: 15%;
      width: calc(var(--handle-size) * 1.1);
    }

    input:focus-visible ~ * & {
      outline: solid 2px var(--primary-500);
      outline-offset: 1px;
    }

    input:checked ~ * & {
      left: 100%;
      border-color: var(--track-bg-on);
      label:active & {
        left: 85%;
      }
    }

    input:indeterminate ~ * & {
      left: 50%;
      border-color: var(--track-bg-indeterminate);
    }
  }
</style>
