import { MuteSwitchBypassSetting } from '@/audio/engine/web/MuteSwitchBypassSetting';
import { PageVisibilityWatcher } from '@/browser/PageVisibilityWatcher';

let tag: HTMLAudioElement;
let tagPendingChange = false;

let activelyPlaying = false;
let pageInBackground = false;
const tagShouldBeRunning = () => !pageInBackground || activelyPlaying;

const audioUnlockingEvents: string[] =
  'click contextmenu auxclick dblclick mousedown mouseup touchend keydown keyup'.split(' ');

export const SilentTagAudioPlayer = {
  init(): void {
    initTag();
    tagStateCheck(); // try to play right off the bat
  },

  setActivelyPlaying(value: boolean): void {
    activelyPlaying = value;
    tagStateCheck();
  },

  setPageInBackground(value: boolean): void {
    pageInBackground = value;
    tagStateCheck();
  },
};

PageVisibilityWatcher.on('visible', () => SilentTagAudioPlayer.setPageInBackground(false));
PageVisibilityWatcher.on('hidden', () => SilentTagAudioPlayer.setPageInBackground(true));

function initTag(): void {
  // NOTE: media widget / airplay MUST be disabled with this super gross hack to create the audio tag; setting the attribute in js doesn't work
  const tmp: HTMLDivElement = document.createElement('div');
  tmp.innerHTML = "<audio x-webkit-airplay='deny'></audio>";
  tag = tmp.children.item(0) as HTMLAudioElement;
  tag.controls = false;
  tag.disableRemotePlayback = true; // prevents casting of the tag for Airplay-like controls on other devices
  tag.preload = 'auto';
  tag.src = silent44100MP3ForUnlocking;
  tag.loop = true;
  tag.load();
}

function tagStateCheck(): void {
  if (!tag) return;
  if (tagPendingChange) return;

  disableInteractionUnlocking();
  if (tagShouldBeRunning() && tag.paused) {
    tagPendingChange = true;
    try {
      if (!tag.src) {
        tag.src = silent44100MP3ForUnlocking;
        tag.load();
      }
      if (MuteSwitchBypassSetting.get() == 'off') return;
      tag.loop = MuteSwitchBypassSetting.get() == 'aggressive';
      const p = tag.play();
      // Not all browsers give us a promise, so we need to check for old APIs
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      if (p) {
        p.then(handleTagPlayPromise, handleTagPlayPromise);
      } else {
        tag.addEventListener('playing', handleTagPlayPromise);
        tag.addEventListener('abort', handleTagPlayPromise);
        tag.addEventListener('error', handleTagPlayPromise);
      }
    } catch (err) {
      handleTagPlayPromise();
    }
  } else if (!tagShouldBeRunning()) {
    clearTimeout(replayHandle);
    if (!tag.paused) {
      tag.pause();
      tag.src = '';
      tag.load();
    }
  }
}

let interactionUnlockingEnabled = false;

function enableInteractionUnlocking(): void {
  if (interactionUnlockingEnabled) return;
  interactionUnlockingEnabled = true;
  for (const evt of audioUnlockingEvents) {
    window.addEventListener(evt, handleTagUnlockingInteraction, <any>{
      capture: true,
      passive: true,
    });
  }
}

function disableInteractionUnlocking(): void {
  if (!interactionUnlockingEnabled) return;
  interactionUnlockingEnabled = false;
  for (const evt of audioUnlockingEvents) {
    window.removeEventListener(evt, handleTagUnlockingInteraction, <any>{
      capture: true,
      passive: true,
    });
  }
}

let replayHandle: any;

function handleTagPlayPromise(): void {
  tag.removeEventListener('playing', handleTagPlayPromise);
  tag.removeEventListener('abort', handleTagPlayPromise);
  tag.removeEventListener('error', handleTagPlayPromise);

  tagPendingChange = false;

  if (tagShouldBeRunning() && tag.paused) {
    enableInteractionUnlocking();
  } else {
    disableInteractionUnlocking();
    if (!tag.paused && !tagShouldBeRunning()) tag.pause();
    if (!tag.paused && tagShouldBeRunning()) {
      if (MuteSwitchBypassSetting.get() == 'mild') {
        clearTimeout(replayHandle);
        replayHandle = setTimeout(() => {
          tag.currentTime = 0;
          const p = tag.play();
          // Not all browsers give us a promise, so we need to check for old APIs
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          if (p) {
            p.then(handleTagPlayPromise, handleTagPlayPromise);
          } else {
            tag.addEventListener('playing', handleTagPlayPromise);
            tag.addEventListener('abort', handleTagPlayPromise);
            tag.addEventListener('error', handleTagPlayPromise);
          }
        }, 30000);
      }
    }
  }
}

function handleTagUnlockingInteraction(): void {
  tagStateCheck();
}

// 3s seconds of silence - stereo 44100Hz VBR V0 MP3
// Needs to be high quality because Web Audio is mixed to match
// the bitrate of any <audio> element being played
const silent44100MP3ForUnlocking = (() => {
  const V33 = 'V'.repeat(33);
  const V34 = 'V'.repeat(34);
  const V43 = 'V'.repeat(43);
  const V44 = 'V'.repeat(44);
  const V90 = 'V'.repeat(90);
  const EGTdj = 'f/7EGTdj/AAAGkAAAAIAAANIAAAAQAAAaQAAAAgAAA0gAAABF';
  const sQZN = '//sQZN2P8AAAaQAAAAgAAA0gAAABAAABpAAAACAAADSAAAAE';
  const MQU = 'MQU1FMy45OS41';
  const TEFNR = 'TEFNRTMuOTkuN';
  const UxB = 'UxBTUUzLjk5Lj';
  const Bk3Y = 'X/+xBk3Y/wAABpAAAACAAADSAAAAEAAAGkAAAAIAAANIAAAAR';
  const repeatingBlock =
    V44 + TEFNR + V33 + Bk3Y + V44 + MQU + V33 + EGTdj + V43 + UxB + V34 + sQZN;
  return (
    'data:audio/mpeg;base64,' +
    '//uQZ' +
    'A'.repeat(43) +
    'WGluZwAAAA8AAAB0AAAwwQAEBggLDQ8TFhgaHB4jJScpLC4yNDc5Oz1CREZIS01PU1ZYWlxeY2VnaWxucnR3eXt9goSGiIuNj5OWmJqcnqOlp6msrrK0t7m7vcLExsjLzc/T1tja3N7j5efp7O7y9Pf5+/0AAABkTEFNRTMuOTlyBN0' +
    'A'.repeat(10) +
    'DUgJAL0TQAB9AAAMMHrcNs8' +
    'A'.repeat(300) +
    '//sQZAAP8AAAaQAAAAgAAA0gAAABAAABpAAAACAAADSAAAAETEFNRTMuOTkuN' +
    'V'.repeat(31) +
    TEFNR +
    V33 +
    'X/+xBkIg/wAABpAAAACAAADSAAAAEAAAGkAAAAIAAANIAAAAR' +
    V44 +
    MQU +
    V33 +
    'f/7EGRED/AAAGkAAAAIAAANIAAAAQAAAaQAAAAgAAA0gAAABF' +
    V43 +
    UxB +
    V34 +
    '//sQZGYP8AAAaQAAAAgAAA0gAAABAAABpAAAACAAADSAAAAE' +
    V44 +
    TEFNR +
    V33 +
    'X/+xBkiA/wAABpAAAACAAADSAAAAEAAAGkAAAAIAAANIAAAAR' +
    V44 +
    MQU +
    V33 +
    'f/7EGSqD/AAAGkAAAAIAAANIAAAAQAAAaQAAAAgAAA0gAAABF' +
    V43 +
    UxB +
    V34 +
    '//sQZMwP8AAAaQAAAAgAAA0gAAABAAABpAAAACAAADSAAAAE' +
    repeatingBlock.repeat(34) +
    V44 +
    TEFNR +
    V33 +
    Bk3Y +
    V90 +
    EGTdj +
    V90 +
    sQZN +
    V90 +
    Bk3Y +
    V90 +
    EGTdj +
    V90 +
    sQZN +
    V90 +
    Bk3Y +
    V90 +
    'Q=='
  );
})();
