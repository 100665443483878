import { strumDown } from '@/band/instruments/guitar/GuitarActions';
import type { GuitarIntent } from '@/band/instruments/guitar/GuitarIntent';
import type { LinearizedCell } from '@/chart/LinearizedCell';
import { lerp } from '@/utilities/lerp';

export function splitCell(
  cell: LinearizedCell,
  _options: Record<string, unknown>
): (GuitarIntent | null)[] {
  const averagePower = 0.75;
  return cell.beats.flatMap(() => [
    strumDown('bass', {
      spread: lerp(2.25, 3.75, averagePower),
      db: lerp(-9, 3, averagePower),
    }),

    null,
  ]);
}
