import { strict as assert } from 'assert';
import { Meteor } from 'meteor/meteor';
import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { eventTracker } from '@/browser/analytics/eventTracker';
import type { Song } from '@/chart/Song';
import { Crnt } from '@/Crnt';
import { mainIndexSearcher } from '@/library/mainIndexSearcher';
import { MusicLibrary } from '@/library/MusicLibrary';
import { UserProfile } from '@/user/UserProfile';

declare const Modal: any;
declare const bootbox: any;

export async function saveCurrentSong(): Promise<void> {
  const song = Crnt.song();
  assert(song);
  await (song._id ? saveExistingSong(song) : saveNewSong(song));
}

async function saveExistingSong(song: Song): Promise<void> {
  const songId = song.id() as string;
  const songName = song.name();
  const songData = song.serialize({ saving: true });
  // Note: userAttribute erasure disabled, see Harvey's 2/1/2018 email.

  try {
    await MusicLibrary.songs.update(songData);
  } catch (error: any) {
    if ('message' in error) {
      Bert.alert(error.message, 'danger');
    }
    throw error;
  }

  song.band.once('persistPlease', (changes: Record<string, any>) => {
    void MusicLibrary.songs.saveAttributes(songId, changes);
  });
  song.band.persist();

  eventTracker.editorSaveSong({ label: songName, songId });

  UserProfile.recordMilestone('EDIT_SONG');

  Crnt.unloadMusic();

  FlowRouter.go('show-song', { songId: songId });

  mainIndexSearcher.refreshResults();

  if (Meteor.status().connected) {
    Bert.alert('Your changes have been saved.', 'success');
  } else {
    Bert.alert(
      "Changes saved to this device. We'll sync them to the cloud the next time you go online.",
      'success'
    );
  }
}

async function saveNewSong(song: Song): Promise<string> {
  assert(song);
  const songData = song.serialize({ saving: true });
  let newSongId: string;

  try {
    newSongId = await MusicLibrary.songs.add(songData);
  } catch (error: any) {
    if ('message' in error) {
      Bert.alert(error.message, 'danger');
    }
    throw error;
  }

  if (Crnt.notepad()?.notes()) {
    MusicLibrary.songs.saveAttributes(songData._id || newSongId, {
      notes: Crnt.notepad()?.notes(),
      notesVisible: true,
    });
  }
  if (Crnt.references()?.count()) {
    Crnt.references()?.uploadForNewSong(newSongId);
  }

  eventTracker.editorSaveNewSong({ label: song.name(), songId: newSongId });

  UserProfile.recordMilestone('NEW_SONG');

  Crnt.unloadMusic();

  FlowRouter.go('show-song', { songId: newSongId });

  mainIndexSearcher.refreshResults();

  if (Meteor.status().connected) {
    Meteor.defer(() =>
      Modal.show('listsMenuModal', {
        songId: newSongId,
        newSong: true,
      })
    );
  } else {
    bootbox.alert({
      title: 'Offline Mode',
      message:
        "Your song has been saved to this device only because you're currently offline. The song will be synced to the cloud the next time you're online.",
      buttons: {
        ok: {
          label: 'Got it',
          className: 'btn-lower',
        },
      },
    });
  }

  return newSongId;
}
