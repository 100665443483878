import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { Crnt } from '@/Crnt';
import { Roles } from '@/Roles';
import SongMenuManager from '@/ui/SongMenuManager';

const shareUrl = () => {
  const songId = FlowRouter.getParam('songId');
  if (songId) return FlowRouter.url('show-song', { songId });
  const medleyId = FlowRouter.getParam('medleyId');
  if (medleyId) return FlowRouter.url('show-medley', { medleyId });
};

Template.sharingMenu.onCreated(() => {
  Template.instance().linkCopySuccess = new ReactiveVar(false);
});

Template.sharingMenu.onRendered(() => {
  Template.instance().find('.sharingMenu')._uihooks = {
    insertElement(node, next) {
      $(node)
        .insertBefore(next)
        .hide()
        .velocity('slideDown', {
          duration: 300,
          easing: [0.2, 0, 0.2, 1],
        });
    },
  };
});
Template.sharingMenu.helpers({
  showNominationArea() {
    return (
      Meteor.status().connected &&
      Roles.userHasPermission(Meteor.userId(), 'nominations.insert') &&
      Crnt.song() &&
      Crnt.song()?.userId() == Meteor.userId() &&
      Crnt.nominationLoaded()
    );
  },
  alreadyNominated() {
    return Meteor.status().connected && Crnt.nominationLoaded() && Crnt.nomination();
  },
  shareUrl() {
    return shareUrl();
  },
  linkCopySuccess() {
    return Template.instance().linkCopySuccess.get();
  },
  shareAPI() {
    return 'share' in navigator || (Meteor.isCordova && /android/i.test(navigator.userAgent));
  },
  emailUrl() {
    const url = shareUrl();
    let subject, body;
    const name = Crnt.musicTitle();
    if (name) {
      subject = encodeURIComponent(`${name} on Strum Machine`);
      body = encodeURIComponent(`Here's a link to "${name}" on Strum Machine:\n\n ${url} \n`);
    } else {
      const songOrMedley = Crnt.medley() ? 'medley' : 'song';
      subject = encodeURIComponent(`Sharing a ${songOrMedley} on Strum Machine`);
      body = encodeURIComponent(
        `Here's a link to a ${songOrMedley} on Strum Machine I'd like to share with you:\n\n ${url} \n `
      );
    }
    return `mailto:?&subject=${subject}&body=${body}`;
  },
  facebookUrl() {
    const url = encodeURIComponent(shareUrl());
    // let quote = encodeURIComponent(`${Crnt.song()?.name()} on Strum Machine`);
    return `https://www.facebook.com/dialog/share?app_id=826129324200128&display=popup&href=${url}`;
  },
});

Template.sharingMenu.events({
  'click .js-copyUrl': function (event, instance) {
    event.preventDefault();
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(instance.find('.js-songUrl'));
    selection.removeAllRanges();
    selection.addRange(range);

    var success = false;
    try {
      success = document.execCommand('copy');
    } finally {
      if (success) {
        Template.instance().linkCopySuccess.set(true);
      } else {
        Bert.alert(
          "Whoops, something went wrong when trying to copy the URL to your clipboard. Please copy the URL from your browser's address bar.",
          'warning'
        );
      }
    }
  },
  'click .js-triggerShareAPI': function (event) {
    const name = Crnt.musicTitle();
    if ('share' in navigator) {
      navigator
        .share({
          text: `${name} on Strum Machine`,
          url: shareUrl(),
        })
        .catch((err) => {
          if (err.name !== 'AbortError') throw err;
        });
    } else if (Meteor.isCordova && /android/i.test(navigator.userAgent)) {
      cordova.plugins.SMExtras.share({
        text: `Here's “${name}” on Strum Machine:\n${shareUrl()}`,
        title: `${name} on Strum Machine`,
      });
    }
    Meteor.setTimeout(() => SongMenuManager.showMenu(null), 600);
    event.preventDefault();
  },
  'click .js-facebookButton': function (event) {
    event.preventDefault();
    window.open(
      event.currentTarget.href,
      'fbShareWindow',
      'height=450, width=750, top=' +
        ($(window).height() / 2 - 275) +
        ', left=' +
        ($(window).width() / 2 - 325) +
        ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0'
    );
    Meteor.setTimeout(() => SongMenuManager.showMenu(null), 600);
    return false;
  },
  'click .js-printButton': function (event) {
    SongMenuManager.showMenu(null);
    if (Meteor.isCordova) {
      Meteor.setTimeout(() => cordova.plugins.printer.print(), 250);
    } else {
      Meteor.setTimeout(() => window.print(), 250);
    }
    return false;
  },
  'click .js-contribute': function (event) {
    SongMenuManager.showMenu(null);
    FlowRouter.go('submit-song', { songId: Crnt.song()?.id() });
    return false;
  },
});
