import { BasicBassCore } from '@/band/instruments/bass/BasicBassCore';
import type { BassCore } from '@/band/instruments/bass/BassCore';
import { HalfTimeBassCore } from '@/band/instruments/bass/HalfTimeBassCore';
import { WalkingBassCore } from '@/band/instruments/bass/WalkingBassCore';

export const bassPatternLineup: Readonly<
  Record<TimeSignature, { heading: string; description?: string; core: BassCore }[]>
> = {
  '4/4': [
    {
      heading: BasicBassCore.title,
      description: 'Bass notes twice in a 4/4 measure, alternating root-fifth by default.',
      core: BasicBassCore,
    },

    {
      heading: WalkingBassCore.title,
      description: '',
      core: WalkingBassCore,
    },

    {
      heading: HalfTimeBassCore.title,
      description: 'Experimental half-time bass pattern',
      core: HalfTimeBassCore,
    },
  ],

  '3/4': [],

  '6/8': [],

  '9/8': [],
};
