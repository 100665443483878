import type { BandPreset } from '@/band/presets/BandPreset';
import { migrateSerializedBandSettings } from '@/band/settings/migrateSerializedBandSettings';

export function createUserPresetLegacyCustomization(
  record: SerializedBandPreset,
  legacyPreset: { id: string; name: string; timeSignature: TimeSignature }
): BandPreset {
  return Object.freeze({
    id: record._id,
    originalPresetId: legacyPreset.id,
    name: record.name ?? legacyPreset.name,
    timeSignature: legacyPreset.timeSignature,
    wasCustomizedBuiltIn: true,
    band: migrateSerializedBandSettings(record.band ?? {}),
  } as BandPreset);
}
