import * as Sentry from '@sentry/browser';
import { MPEGDecoderWebWorker } from 'mpg123-decoder';
import { canNativelyDecode } from '@/audio/engine/web/canNativelyDecode';
import { DecoderPool } from '@/audio/engine/web/DecoderPool';
import { nativeDecode } from '@/audio/engine/web/nativeDecode';

export const MP3DecoderPool = new DecoderPool(MPEGDecoderWebWorker, 2);

export async function decodeMP3(
  context: AudioContext,
  arrayBuffer: ArrayBuffer
): Promise<AudioBuffer> {
  if (await canNativelyDecode({ context, format: 'mp3' })) {
    return nativeDecode(context, arrayBuffer).catch((err: unknown) => {
      Sentry.captureException(err);
      return MP3DecoderPool.decode(arrayBuffer);
    });
  } else {
    return MP3DecoderPool.decode(arrayBuffer);
  }
}
