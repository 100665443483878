import { Meteor } from 'meteor/meteor';
import { Tracker } from 'meteor/tracker';
import type { Song } from '@/chart/Song';
import type { MedleyRecord } from '@/collections/MedleysCollection';
import type { SongRecord } from '@/collections/SongsCollection';
import type { Medley } from '@/medleys/Medley';
import type { PermissionName } from '@/roles/permissionNames';
import { defaultRolePermissions, rolesAndPermissions } from '@/roles/role-permissions';
import { roleNames } from '@/roles/roleNames';

// utility function and TypeScript guard to check if a string is a valid role name
function isRoleName(name: string): name is (typeof roleNames)[number] {
  return roleNames.includes(name as (typeof roleNames)[number]);
}

// function that takes either userId or user object and returns a user object in either case
const getUserObject = (user: string | Meteor.User | null | undefined) =>
  typeof user === 'string' ? Tracker.nonreactive(() => Meteor.users.findOne(user)) : user;

export const Roles = {
  roles: rolesAndPermissions,
  defaultPermissions: defaultRolePermissions,

  userHasPermission: function (
    userOrUserId: Meteor.User | string | null | undefined,
    permission: PermissionName,
    doc?: Record<string, any>
  ): boolean {
    const userObject = getUserObject(userOrUserId);
    if (!userObject) {
      const defaultPermission = this.defaultPermissions[permission];
      return typeof defaultPermission === 'function'
        ? defaultPermission(doc, userObject)
        : !!defaultPermission;
    }
    const userRoleNames = userObject.roles || [];
    return [
      ...userRoleNames.filter(isRoleName).map((name) => this.roles[name]),
      this.defaultPermissions,
    ]
      .map((rolePermissions) => rolePermissions[permission])
      .some((condition) =>
        typeof condition === 'function' ? condition(doc, userObject) : !!condition
      );
  },

  checkPermission: function (
    user: Meteor.User | string | null | undefined,
    permission: PermissionName,
    doc?: Record<string, any>
  ) {
    if (!Roles.userHasPermission(user, permission, doc)) {
      throw new Meteor.Error('unauthorized', 'The user has no permission to perform this action');
    }
  },

  userHasRole: function (
    userOrUserId: Meteor.User | string | null | undefined,
    role: (typeof roleNames)[number] | { $in: (typeof roleNames)[number][] }
  ): boolean {
    const userObject = getUserObject(userOrUserId);
    if (!userObject) return false;
    if (typeof role === 'string') {
      return !!userObject.roles?.includes(role);
    } else {
      return !!userObject.roles?.some((userRole) => (role.$in as string[]).includes(userRole));
    }
  },

  currentUserCanPlaySong: function (
    song: SongRecord | MedleyRecord | SerializedSong | SerializedMedley | Song | Medley
  ): boolean {
    return Roles.userHasPermission(Meteor.userId(), 'songs.play', song);
  },

  currentUserHasFullAccess: function (): boolean {
    return Roles.userHasRole(Meteor.userId(), {
      $in: ['trial-member', 'paid-member', 'lifetime-member'],
    });
  },
};
