<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import { Chord } from '@/music/Chord';
  import HelpCircleTooltip from '@/ui/HelpCircleTooltip.svelte';
  import UserPreferences from '@/user/UserPreferences';
  import { range } from '@/utilities/range';

  const dispatch = createEventDispatcher<{ select: Chord['bassNote'] }>();

  // $: keyTonic = key.replace('m', '') as keyof typeof chromaticScales;

  interface Props {
    key: string;
    // $: scaleTonics = chromaticScales[keyTonic];
    selectedChords: Chord[] | undefined;
  }

  let { key, selectedChords }: Props = $props();

  let chordSystem = $state() as 'letters' | 'numbers' | 'roman';
  trackerEffect(() => (chordSystem = UserPreferences.get('chordSystem')));
  let minorKey = $derived(key.includes('m'));
  let firstChord = $derived(selectedChords?.[0]);
  let singleSelectedRoot = $derived(
    selectedChords?.every((c) => c.rootNote == firstChord?.rootNote && c.minor == firstChord.minor)
      ? firstChord?.rootNote
      : undefined
  );
  let singleSelectedBassNote = $derived(
    selectedChords?.every((c) => c.bassNote === firstChord?.bassNote) ? firstChord?.bassNote : false
  );
</script>

<div class="flex h-72">
  <div class="flex flex-1 flex-col leading-5 text-gray-500">
    <div class="flex-1 rounded-br-xl border-b border-r border-editToolbarSeparator py-4 pl-4 pr-3">
      <div class="mb-3">
        This specifies the bass note for
        {singleSelectedRoot ? 'this chord' : 'these chords'}.
      </div>
      <div class="">
        (Also known as a “slash&nbsp;chord,” or an “inversion” when using the third or fifth.)
      </div>
    </div>
    <div class="flex flex-1 items-center gap-1 py-4 pl-4">
      <button
        class="btn _automatic"
        aria-pressed={!selectedChords?.some((c) => c.bassNote)}
        onclick={() => dispatch('select', undefined)}
      >
        Automatic
      </button>
      <HelpCircleTooltip
        text="“Automatic” means you’ll hear the root note of the chord in the bass, except where the instrument is alternating between root and fifth notes, as in the default band style."
      />
    </div>
  </div>
  <div class="basis-48 pl-16 pr-16 pt-4">
    <div class="letterGrid vertical {minorKey ? 'minor' : 'major'} notranslate">
      {#each [...range(0, 11)] as _, index (index)}
        {@const chord = new Chord(
          `${key}/${key.replace('m', '')}`,
          singleSelectedRoot ?? key
        ).shiftBassNote(index)}
        {@const letterOrNumber =
          chordSystem !== 'letters' ? chord.getBassInterval()?.number : chord.bassLetter}
        {@const accidental =
          chordSystem !== 'letters' ? chord.getBassInterval()?.accidental : chord.bassAccidental}
        {@const rootThirdFifth = singleSelectedRoot
          ? chord.bassRelativeChroma === 0
            ? 'root'
            : chord.bassRelativeChroma === (firstChord?.minor ? 3 : 4)
              ? 'third'
              : chord.bassRelativeChroma === 7
                ? 'fifth'
                : ''
          : ''}
        <button
          class="btn step{index} inline-flex items-center justify-center"
          class:rootThirdFifth={!!rootThirdFifth}
          class:bg-muted-100={!singleSelectedBassNote &&
            selectedChords?.some((c) => c.bassNote == chord.bassNote)}
          aria-pressed={singleSelectedBassNote == chord.bassNote}
          aria-label={chord.toAria()}
          onclick={() => dispatch('select', chord.bassNote)}
        >
          {letterOrNumber}
          {#if accidental}
            {@const sharpOrFlat = accidental == '#' ? 'sharp' : 'flat'}
            <svg class="_{sharpOrFlat}"><use xlink:href="#{sharpOrFlat}"></use></svg>
          {/if}
          {#if rootThirdFifth}
            <div class="_chordToneLabel" aria-hidden="true">
              {rootThirdFifth}
            </div>
          {/if}
        </button>
      {/each}
    </div>
  </div>
</div>

<style>
  .btn {
    position: relative;
    margin: 2px;
    padding: 3px 6px;
    min-width: 2.25rem;
    text-transform: none;
    border: solid 1px var(--muted-200);
    &.rootThirdFifth {
      border-color: var(--muted-400);
    }
    &._automatic {
      padding: 3px 12px;
    }
    &[aria-pressed='true'],
    &.rootThirdFifth[aria-pressed='true'] {
      border-color: var(--primary-900);
      color: white;
      background-color: var(--primary-900);
    }
  }

  ._chordToneLabel {
    position: absolute;
    pointer-events: none;
    top: 0;
    bottom: 0;
    padding: 0 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--gray-300) !important;
    text-transform: uppercase;
    font-size: 13px;
  }

  ._sharp {
    width: 0.38em;
    height: 1em;
  }
  ._flat {
    width: 0.36em;
    height: 1em;
  }

  .letterGrid {
    display: grid;

    &.vertical {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(15, 1fr);
    }

    /* prettier-ignore */
    &.major.vertical {
      .step0 { grid-area: 1 / 1 / 3 / 2; }
      .step2 { grid-area: 3 / 1 / 5 / 2; }
      .step4 { grid-area: 5 / 1 / 7 / 2; }
      .step5 { grid-area: 7 / 1 / 9 / 2; }
      .step7 { grid-area: 9 / 1 / 11 / 2; }
      .step9 { grid-area: 11 / 1 / 13 / 2; }
      .step11 { grid-area: 13 / 1 / 15 / 2; }
      .step1 { grid-area: 2 / 2 / 4 / 3; }
      .step3 { grid-area: 4 / 2 / 6 / 3; }
      .step6 { grid-area: 8 / 2 / 10 / 3; }
      .step8 { grid-area: 10 / 2 / 12 / 3; }
      .step10 { grid-area: 12 / 2 / 14 / 3; }
      .step0, .step2, .step4, .step5, .step7, .step9, .step11 {
        ._chordToneLabel { right: 100%; }
      }
      .step1, .step3, .step6, .step8, .step10 {
        ._chordToneLabel { left: 100%; }
      }
    }
    /* prettier-ignore */
    &.minor.vertical {
      .step0 { grid-area: 1 / 1 / 3 / 2; }
      .step2 { grid-area: 3 / 1 / 5 / 2; }
      .step3 { grid-area: 5 / 1 / 7 / 2; }
      .step5 { grid-area: 7 / 1 / 9 / 2; }
      .step7 { grid-area: 9 / 1 / 11 / 2; }
      .step8 { grid-area: 11 / 1 / 13 / 2; }
      .step10 { grid-area: 13 / 1 / 15 / 2; }
      .step1 { grid-area: 2 / 2 / 4 / 3; }
      .step4 { grid-area: 6 / 2 / 8 / 3; }
      .step6 { grid-area: 8 / 2 / 10 / 3; }
      .step9 { grid-area: 12 / 2 / 14 / 3; }
      .step11 { grid-area: 14 / 2 / 16 / 3; }
      .step0, .step2, .step3, .step5, .step7, .step8, .step10 {
        ._chordToneLabel { right: 100%; }
      }
      .step1, .step4, .step6, .step9, .step11 {
        ._chordToneLabel { left: 100%; }
      }
    }

    &.horizontal {
      grid-template-columns: repeat(15, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }

    /* prettier-ignore */
    &.major.horizontal {
      .step0 { grid-area: 1 / 1 / 2 / 3; }
      .step1 { grid-area: 2 / 2 / 3 / 4; }
      .step2 { grid-area: 1 / 3 / 2 / 5; }
      .step3 { grid-area: 2 / 4 / 3 / 6; }
      .step4 { grid-area: 1 / 5 / 2 / 7; }
      .step5 { grid-area: 1 / 7 / 2 / 9; }
      .step6 { grid-area: 2 / 8 / 3 / 10; }
      .step7 { grid-area: 1 / 9 / 2 / 11; }
      .step8 { grid-area: 2 / 10 / 3 / 12; }
      .step9 { grid-area: 1 / 11 / 2 / 13; }
      .step10 { grid-area: 2 / 12 / 3 / 14; }
      .step11 { grid-area: 1 / 13 / 2 / 15; }
    }
    /* prettier-ignore */
    &.minor.horizontal {
      .step0 { grid-area: 1 / 1 / 2 / 3; }
      .step2 { grid-area: 1 / 3 / 2 / 5; }
      .step3 { grid-area: 1 / 5 / 2 / 7; }
      .step5 { grid-area: 1 / 7 / 2 / 9; }
      .step7 { grid-area: 1 / 9 / 2 / 11; }
      .step8 { grid-area: 1 / 11 / 2 / 13; }
      .step10 { grid-area: 1 / 13 / 2 / 15; }
      .step1 { grid-area: 2 / 2 / 3 / 4; }
      .step4 { grid-area: 2 / 6 / 3 / 8; }
      .step6 { grid-area: 2 / 8 / 3 / 10; }
      .step9 { grid-area: 2 / 12 / 3 / 14; }
      .step11 { grid-area: 2 / 14 / 3 / 16; }
    }
  }
</style>
