<script lang="ts">
  import { onMount } from 'svelte';
  import type { Band } from '@/band/Band';
  import { staticInstruments } from '@/band/staticInstruments';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import VolumeIcon from '@/ui/icons/VolumeIcon.svelte';

  interface Props {
    instId: InstrumentId;
    band?: Band | undefined;
    noIcon?: boolean;
    global?: boolean;
  }

  let { instId, band = undefined, noIcon = false, global = false }: Props = $props();

  let bandInstruments = $derived(band?.instruments ?? staticInstruments);

  let volume: number = $state(1);
  trackerEffect(
    () =>
      (volume =
        (!global
          ? bandInstruments[instId].customVolume()
          : bandInstruments[instId].globalVolume()) ?? volume)
  );

  let volumeRange: HTMLInputElement;
  onMount(() => (volumeRange.value = volume.toString()));

  // If you ever want to add current-value bubbles to the range,
  // see https://css-tricks.com/value-bubbles-for-range-inputs/

  function volumeRangeChangeHandler() {
    if (+volumeRange.value < 0.05) volumeRange.value = '0.05';
    const val = +volumeRange.value;
    if (!global) {
      bandInstruments[instId].setCustomVolume(val);
    } else {
      bandInstruments[instId].setGlobalVolume(val);
    }
  }

  let volumeRangeStyle = $derived(`
    width: 7.125em;
    --val: ${volume};
    --min: 0.0;
    --max: 1.0;
    --track-height: 0.75rem;
    --thumb-size: 2rem;
    --fill-color: ${!global ? 'hsl(110, 42%, 45%)' : 'var(--muted-400)'}
  `);
</script>

<div class="flex items-center">
  {#if !noIcon}
    <span class="mr-1 leading-none" aria-label="Volume">
      <VolumeIcon {volume} color="hsl(206, 20%, 50%)" />
    </span>
  {/if}

  <input
    type="range"
    min={0}
    aria-valuemin={0}
    max={1}
    aria-valuemax={100}
    bind:this={volumeRange}
    value={volume}
    aria-valuenow={volume * 100}
    aria-valuetext={`${(volume * 100).toFixed(0)} percent`}
    step={0.01}
    class="styled-range"
    style={volumeRangeStyle}
    aria-label="{instId} volume"
    oninput={volumeRangeChangeHandler}
    onchange={volumeRangeChangeHandler}
  />
</div>
