import { Mixer } from '@/audio/engine/base/Mixer';
import { WebAudioChannel } from '@/audio/engine/web/WebAudioChannel';
import { WebAudioMasterChannel } from '@/audio/engine/web/WebAudioMasterChannel';

export class WebAudioMixer extends Mixer {
  readonly context: AudioContext;
  readonly masterChannel: WebAudioMasterChannel;

  constructor(context: AudioContext) {
    super();
    this.context = context;
    this.masterChannel = new WebAudioMasterChannel(context);
  }

  protected _createChannel(channelId: string): WebAudioChannel {
    const channel = new WebAudioChannel(this.context, channelId);
    this.masterChannel.connectToOutput(channel.outputNode);
    return channel;
  }
}
