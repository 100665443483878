<script lang="ts">
  import type { Instance as TippyInstance, Props as TippyProps } from 'tippy.js';
  import { MasterVolume } from '@/audio/mixer/MasterVolume';
  import MasterVolumeControl from '@/audio/mixer/ui/MasterVolumeControl.svelte';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import { makeTippyMenu } from '@/ui/dropdowns/makeTippyMenu';

  let currentValue: number = $state(1);
  let useCurrentValueInCaption = $state(false);

  trackerEffect(() => {
    currentValue = MasterVolume.get();
    // Once shown, we want to always show current value until destroyed
    if (currentValue < 1) useCurrentValueInCaption = true;
  });

  let tippyInstance: TippyInstance | undefined;
  function tippyMenu(node: HTMLElement, params: Partial<TippyProps> = {}) {
    tippyInstance = makeTippyMenu(node, params, { keysThatShow: [' ', 'Enter'] });
    return { destroy: () => tippyInstance?.destroy() };
  }
</script>

<div use:tippyMenu={{ 'theme': 'light', 'placement': 'bottom' }}>
  <button class="btn OVB_button js-global-outputVolumeButton">
    {#if !useCurrentValueInCaption}
      Reduce output volume
    {:else}
      Output:
      {(currentValue * 100).toFixed(0)}%
    {/if}
  </button>
  <div role="menu" class="OVB_menu">
    <div class="OVB_heading">Master output volume:</div>
    <div class="OVB_slider">
      <MasterVolumeControl />
    </div>
    <div class="OVB_text">
      We recommend that you <b>leave this at 100%</b> and use the volume control on your device or speaker
      to adjust the overall volume of Strum Machine. Only reduce this volume setting if you need to balance
      the volume level with other apps, such as when sharing your audio over Zoom, etc.
    </div>
  </div>
</div>

<style>
  .OVB_button {
    font-size: 14px;
    padding: 0.5rem 1rem 0.5rem;
  }

  .OVB_heading {
    text-align: center;
    padding-top: 0.5rem;
    padding-bottom: 0.75rem;
    font-size: 16px;
    color: var(--gray-800);
  }

  .OVB_menu {
    width: 300px;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
  }

  .OVB_text {
    text-align: center;
    padding: 0.5rem;
    font-size: 14px;
    color: var(--gray-600);
  }

  .OVB_slider {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.75rem;
  }
</style>
