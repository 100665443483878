import { DownbeatFeetCore } from '@/band/instruments/feet/cores/DownbeatFeetCore';
import type { FeetCore } from '@/band/instruments/feet/FeetCore';
import { feetCores } from '@/band/instruments/feet/feetCores';
import { feetSettingDefinitions } from '@/band/instruments/feet/feetSettingDefinitions';
import type { FeetSettingsHash } from '@/band/instruments/feet/FeetSettingsHash';
import { initializeInstrumentSettings } from '@/band/instruments/initializeInstrumentSettings';
import { Instrument } from '@/band/instruments/Instrument';
import type { InstrumentPresetSettings } from '@/band/instruments/InstrumentPresetSettings';
import type { LinearizedMeasure } from '@/chart/LinearizedMeasure';
import { safeCallback } from '@/utilities/safeCallback';

export class Feet extends Instrument<FeetSettingsHash, FeetCore> {
  readonly id = 'feet';
  readonly name = 'Feet';
  readonly shortCode = 'f';

  readonly cores = feetCores;
  readonly fallbackCores: Record<TimeSignature, FeetCore> = {
    '4/4': DownbeatFeetCore,
    '3/4': DownbeatFeetCore,
    '6/8': DownbeatFeetCore,
    '9/8': DownbeatFeetCore,
  };

  readonly settingDefinitions = feetSettingDefinitions;
  protected _settings = initializeInstrumentSettings<FeetSettingsHash>(this.settingDefinitions);

  loadCoreAndPreset(core: FeetCore, presetSettings: InstrumentPresetSettings<FeetSettingsHash>) {
    this.emit('updatedData', {
      [`${this.shortCode}_c`]: core.id,
      // ...this.irrelevantSettingsResetHash(core),
      ...this.serializePresetSettings(presetSettings),
    });
  }

  processMeasures(
    measures: readonly LinearizedMeasure[],
    core: FeetCore,
    settings: Readonly<FeetSettingsHash>,
    state: { tpm: number }
  ) {
    const swing = this.swing().formula(state.tpm);
    const beatDuration = 60 / state.tpm;

    measures.forEach(
      safeCallback((measure) => {
        // Unlike other cores, FeetCore.processMeasure() actually mutates the measure and applies FeetIntents to it.
        core.processMeasure(measure, settings, { swing, beatDuration });
      })
    );
  }
}
