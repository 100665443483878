import type { Cell } from '@/chart/Cell.svelte';
import { Conductor } from '@/Conductor';
import { Crnt } from '@/Crnt';
import { bringCellIntoView } from '@/ui/auto-scroll/bringCellIntoView';

let lastPlayedCell: Cell | undefined;

export function autoScrollCellPlaybackWatcher() {
  if (!Conductor.playing()) lastPlayedCell = undefined;

  const startPos = Conductor.displayState.startingPosition();
  if (startPos) {
    const currentCell =
      Crnt.reactiveSongs()[startPos.song()]?.sections.reactive()[startPos.section()]?.cells[
        startPos.cell()
      ];
    if (lastPlayedCell == currentCell || !currentCell?.layout.chartCell) return;
    lastPlayedCell = currentCell;
    bringCellIntoView(currentCell.layout.chartCell, currentCell);
    return;
  }

  const pos = Conductor.displayState.position();
  if (pos) {
    const currentCell =
      Crnt.reactiveSongs()[pos.song()]?.sections.reactive()[pos.section()]?.cells[pos.cell()];
    if (lastPlayedCell == currentCell || !currentCell?.layout.chartCell) return;
    if (currentCell.layout.lineStart) {
      lastPlayedCell = currentCell;
      bringCellIntoView(currentCell.layout.chartCell, currentCell);
    }
    return;
  }
}
