import { rawWalkingBassPatternDefinitions } from '@/band/instruments/bass/rawWalkingBassPatternDefinitions';
import type { LinearizedCell } from '@/chart/LinearizedCell';
import { chordTypeDefinitions } from '@/music/chordTypeDefinitions';

export function getWalkingBassPatterns({
  cells,
  startingRelativeChroma,
  highestInterval,
  lowestInterval,
}: {
  cells: [LinearizedCell, ...LinearizedCell[]];
  startingRelativeChroma: number;
  highestInterval: number;
  lowestInterval: number;
}) {
  const cellCount = cells.length;
  const chord = cells[0].beats[0].chord;
  const chordIntervals = chordTypeDefinitions[chord.type].intervals as number[];

  return (
    patternsByRunLengthAndStartingChroma[cellCount * 2]?.[startingRelativeChroma] ?? []
  ).filter(
    (pattern) =>
      pattern.lowestInterval >= lowestInterval &&
      pattern.highestInterval <= highestInterval &&
      pattern.chordMustContain.every((n) =>
        n < 0 ? !chordIntervals.includes(-n) : chordIntervals.includes(n)
      )
  );
}

export type WalkingBassPattern = {
  runLength: number;
  chordMustContain: number[];
  nextChordMustContain?: number[];
  intervals: [number, ...number[]];
  biggestJump: number;
  highestInterval: number;
  lowestInterval: number;
  endingRelativeChroma: number;
  startingRelativeChroma: number;
};

const patternsByRunLengthAndStartingChroma: Record<
  number,
  Record<number, WalkingBassPattern[]>
> = {};

rawWalkingBassPatternDefinitions.forEach(
  ([intervals, chordMustContain, nextChordMustContain, recommendedGhostNoteBeats]) => {
    const runLength = intervals.length - 1;
    const highestInterval = Math.max(...intervals);
    const lowestInterval = Math.min(...intervals);
    const startingRelativeChroma = (intervals[0] + 12) % 12;
    const endingRelativeChroma = (<number>intervals[intervals.length - 1] + 12) % 12;
    const biggestJump = Math.max(
      ...intervals.map((n, i) => (i > 0 ? Math.abs(n - (intervals[i - 1] ?? n)) : 0))
    );
    const patternDef: WalkingBassPattern = {
      runLength,
      chordMustContain,
      nextChordMustContain,
      intervals,
      highestInterval,
      lowestInterval,
      startingRelativeChroma,
      endingRelativeChroma,
      biggestJump,
    };

    const patternsForRunLength = (patternsByRunLengthAndStartingChroma[runLength] ??= {});
    const patternsForStartingChroma = (patternsForRunLength[startingRelativeChroma] ??= []);
    patternsForStartingChroma.push(patternDef);
  }
);
