<script lang="ts">
  interface Props {
    direction?: 'right' | 'down' | 'left' | null;
    hover?: boolean;
    unavailable?: boolean;
  }

  let { direction = 'right', hover = false, unavailable = false }: Props = $props();
</script>

<div class="absolute right-0 top-1/2 -translate-y-1/2">
  <div
    class="container {direction} inline-flex h-8 w-8 items-center justify-center transition-all duration-200"
    class:hover
    class:unavailable
  >
    <svg class="h-4 w-3" aria-hidden="true"><use xlink:href="#forward-chevron" /></svg>
  </div>
</div>

<style>
  .container {
    color: var(--primary-500);
  }
  :global(button:hover) .container {
    color: var(--primary-600);
  }
  .down {
    transform: translateY(-1px);
  }

  :global(button:hover) .right {
    transform: translateX(2px);
  }
  :global(button:hover) .left {
    transform: translateX(-2px);
  }
  :global(button:hover) .down {
    transform: translateY(0.5px);
  }

  .unavailable {
    color: hsl(206, 40%, 70%);
    opacity: 0.5;
  }
</style>
