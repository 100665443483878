<!-- @migration-task Error while migrating Svelte code: $$props is used together with named props in a way that cannot be automatically migrated. -->
<script lang="ts">
  import { fade } from 'svelte/transition';
  import { findMatchingInstrumentPreset } from '@/band/instruments/findMatchingInstrumentPreset';
  import InstrumentStatusSummary from '@/band/ui/components/InstrumentStatusSummary.svelte';
  import PanelOpenerChevron from '@/band/ui/components/PanelOpenerChevron.svelte';
  import SmallPanIndicator from '@/band/ui/components/SmallPanIndicator.svelte';
  import SmallVolumeIndicator from '@/band/ui/components/SmallVolumeIndicator.svelte';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';
  import ChecklistCheck from '@/ui/icons/ChecklistCheck.svelte';
  import { slideFade } from '@/ui/svelte-transitions';

  const bandMenu = getBandMenuState();

  type ThisInstrumentId = $$Generic<InstrumentId>;

  export let instId: ThisInstrumentId;
  export let toggleMode = false;
  export let noOpenMode = false;
  export let checked = false;

  $: label = $bandMenu.instruments[instId].name;
  $: volume = $bandMenu.instruments[instId].volume();
  $: pan = $bandMenu.instruments[instId].pan();
  $: muted = $bandMenu.instruments[instId].globalMute();
  $: volumeOverridden = typeof $bandMenu.instruments[instId].customVolume() == 'number';
  $: panOverridden = typeof $bandMenu.instruments[instId].customPan() == 'number';
  $: core = $bandMenu.instruments[instId].coreOrFallbackForTimeSignature($bandMenu.timeSignature);
  $: settings = $bandMenu.instruments[instId].settings();
  $: descriptiveTitle =
    findMatchingInstrumentPreset(core as any, settings)?.name.replace('with ', 'w/') ??
    `${core.title.replace('with ', 'w/')} (custom)`;
</script>

<button
  class="{$$props.class} colors-panelOpener relative block w-full py-3 pl-2.5 pr-9 text-left leading-[1.35]"
  on:click
>
  <div class="pl-1 sm-band:pl-0">
    <div class="flex items-center">
      <div
        class="flex-1 font-medium leading-5 transition-colors {toggleMode
          ? 'text-gray-900'
          : 'text-primary-600'}"
      >
        {label}
      </div>
      {#if !toggleMode || muted}
        <span class="ml-4 mr-0.5 flex items-center" transition:slideFade>
          {#if muted}
            <span class="__muted-badge">Muted</span>
          {:else}
            <SmallPanIndicator {pan} overridden={panOverridden} />
            <span style="display: inline-block; width: 1em;" />
            <SmallVolumeIndicator {volume} overridden={volumeOverridden} />
          {/if}
        </span>
      {/if}
    </div>
    {#if !toggleMode}
      <div
        class="__bottom-row whitespace-normal pb-[1px] pt-0.5 text-sm leading-5"
        transition:slideFade={{ duration: 350 }}
      >
        <div>
          {descriptiveTitle}
        </div>
        <InstrumentStatusSummary {instId} />
      </div>
    {/if}
  </div>
  {#if toggleMode}
    <div
      in:fade={{ duration: 250 }}
      out:fade={{ duration: 75 }}
      class="absolute right-4 top-1/2 flex -translate-y-1/2 scale-125 items-center justify-center"
    >
      <ChecklistCheck size={16} {checked} />
    </div>
  {:else if !noOpenMode}
    <div style="display: contents;" in:fade={{ duration: 250 }} out:fade={{ duration: 75 }}>
      <PanelOpenerChevron direction="right" />
    </div>
  {/if}
</button>

<style>
  .__muted-badge {
    background-color: hsl(0, 100%, 94%);
    color: hsl(0, 90%, 40%);
    font-size: 14px;
    border-radius: 5px;
    border: solid 0.9px hsl(0, 55%, 80%);
    padding: 0.05em 0.3em 0.05em;
    margin: 0 0 0 0.2em;
    vertical-align: 0.1em;
  }

  .__bottom-row {
    color: hsl(206, 20%, 40%);
    :global(button:hover) & {
      color: hsl(206, 40%, 50%);
    }
  }
</style>
