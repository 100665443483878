import { z } from 'zod';
import type { InstrumentSetting } from '@/band/instruments/InstrumentSetting';
import cloneJSON from '@/utilities/cloneJSON';

const serializedSchema = z.object({
  s: z.number().min(0).max(2).optional(),
  i: z.number().min(0).max(2).optional(),
});

type Serialization = z.infer<typeof serializedSchema>;

const presets = [
  { value: { s: 0, i: 0 }, caption: 'Soft and dry' },
  { value: { s: 1, i: 0 }, caption: 'Soft and mostly dry' },
  { value: { s: 2, i: 0 }, caption: 'Soft and voiced' },
  { value: { s: 0, i: 1 }, caption: 'Dry' },
  { value: { s: 1, i: 1 }, caption: 'Mostly dry' },
  { value: { s: 2, i: 1 }, caption: 'Voiced' },
  { value: { s: 0, i: 2 }, caption: 'Loud and dry' },
  { value: { s: 1, i: 2 }, caption: 'Loud and mostly dry' },
  { value: { s: 2, i: 2 }, caption: 'Loud and voiced' },
] as const;

export class MandolinChopDynamicsSetting implements InstrumentSetting<Serialization> {
  readonly sustainIndex: 0 | 1 | 2;
  readonly intensityIndex: 0 | 1 | 2;

  constructor(input: unknown = {}) {
    const data = serializedSchema.catch({}).parse(input);
    // set properties from the serialized data here
    this.sustainIndex = data.s ?? 1;
    this.intensityIndex = data.i ?? 1;
  }

  closeTo(serialized?: Serialization) {
    const other = new MandolinChopDynamicsSetting(serialized);
    return other.sustainIndex == this.sustainIndex && other.intensityIndex == this.intensityIndex;
  }

  serialize(): Serialization {
    return cloneJSON({
      i: this.intensityIndex,
      s: this.sustainIndex,
    });
  }

  withSustain(value: 0 | 1 | 2) {
    return new MandolinChopDynamicsSetting({
      'i': this.intensityIndex,
      's': value,
    });
  }

  withIntensity(value: 0 | 1 | 2) {
    return new MandolinChopDynamicsSetting({
      'i': value,
      's': this.sustainIndex,
    });
  }

  get closestPreset() {
    return (
      presets.find(
        (preset) => preset.value.s == this.sustainIndex && preset.value.i == this.intensityIndex
      ) ?? presets[4]
    );
  }

  static readonly presets = presets;
}
