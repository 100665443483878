import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { HelpLibrary } from '@/help/HelpLibrary';
import HelpWindow from '@/help/HelpWindow';

const getSearchQuery = () =>
  (window.location.pathname.startsWith('/help') && FlowRouter.getQueryParam('search')) ||
  HelpWindow.searchQuery();

Template.helpSearchPage.onCreated(() => {
  const instance = Template.instance();
  instance.searchResults = new ReactiveVar([]);
  instance.autorun(() => {
    HelpLibrary.search(getSearchQuery()).then((results) => {
      instance.searchResults.set(results);
    });
  });
});

Template.helpSearchPage.onRendered(() => {
  const instance = Template.instance();
  Tracker.afterFlush(() => instance.$('input[type="search"]')?.val(getSearchQuery()).focus());
});

Template.helpSearchPage.helpers({
  searchQuery() {
    return getSearchQuery();
  },
  searchResults() {
    return getSearchQuery() && Template.instance().searchResults.get();
  },
  helpSearchResultsHeading() {
    const results = Template.instance().searchResults.get();
    if (results?.length > 0) {
      return `${results.length} result${results.length == 1 ? '' : 's'} for “${getSearchQuery()}”:`;
    }
  },
});
