import { createPopper } from '@popperjs/core';
import ResizeObserver from 'resize-observer-polyfill';
import tippyJs from 'tippy.js';
import { Crnt } from '@/Crnt';

const buttonPopperOptions = {
  placement: 'bottom-end',
  modifiers: [
    { name: 'flip', enabled: false },
    { name: 'preventOverflow', enabled: false },
    {
      name: 'eventListeners',
      options: {
        scroll: false,
      },
    },
  ],
};

Template.clearSelectionButton.onRendered(() => {
  const instance = Template.instance();

  instance.resizeObserver = new ResizeObserver(() => {
    requestAnimationFrame(() => instance.buttonPopper?.update());
  });
  instance.resizeObserver.observe(document.querySelector('.js-global-musicPageMainArea'));

  tippyJs(instance.find('button'), {
    placement: 'bottom',
    touch: false,
    delay: [400, 50],
    offset: [0, 2],
  });

  const button = instance.find('button');
  instance.autorun(function updateButtonPositionAutorun() {
    const song = Crnt.song();
    if (!song) return;
    const endCell = song.loop.endCell();
    const selectionInProgress = song.dragSelection.exists();
    Tracker.afterFlush(() =>
      requestAnimationFrame(() => {
        // wait for DOM to be updated first!
        const oldPopper = instance.buttonPopper;
        const targetEl = endCell?.layout.chartCell;
        if (targetEl && !selectionInProgress) {
          instance.buttonPopper = createPopper(targetEl, button, buttonPopperOptions);
          $(button).css('z-index', (endCell.section?.cells.length ?? 99) + 4);
          $(button).show();
        } else {
          $(button).hide();
          button._tippy?.hide();
        }
        oldPopper?.destroy();
      })
    );
  });
});

Template.clearSelectionButton.onDestroyed(() => {
  const instance = Template.instance();
  instance.resizeObserver?.disconnect();
});

Template.clearSelectionButton.events({
  'click .js-clearButton': function (event, instance) {
    Crnt.song()?.loop.reset();
    $(event.currentTarget).trigger('blur');
    return false;
  },
});
