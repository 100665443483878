import { FlowRouter } from 'meteor/ostrio:flow-router-extra';

Template.landingPageNav.onCreated(() => {});

Template.landingPageNav.onRendered(() => {
  const instance = Template.instance();
  instance.autorun(function headerButtonShowHide(comp) {
    const headerButton = instance.$('.js-global-landingPageHeaderSignup');
    this.width = headerButton.outerWidth();
    if (typeof Template.currentData().showSignup == 'undefined') {
      headerButton.show();
      return;
    }
    if (Template.currentData().showSignup != this.signupShown) {
      this.signupShown = Template.currentData().showSignup;
      if (this.signupShown) {
        headerButton.velocity(
          {
            marginRight: [0, -this.width],
            marginLeft: ['8px', 0],
            translateX: [0, '20px'],
            opacity: [1, 'easeOutCubic', 0],
          },
          {
            duration: document.scrollingElement.scrollTop === 0 ? 0 : 400,
            begin: () => headerButton.show(),
          }
        );
      } else {
        headerButton.velocity(
          {
            marginRight: [-this.width, 0],
            marginLeft: [0, '8px'],
            translateX: ['20px', 0],
            opacity: [0, 'easeInCubic', 1],
          },
          {
            duration: 400,
            begin: () => !comp.firstRun && headerButton.show(),
            complete: () => headerButton.hide(),
          }
        );
      }
    }
  });
});

Template.landingPageNav.onDestroyed(() => {});

Template.landingPageNav.events({
  'click .js-homeLink': function (event, instance) {
    if (FlowRouter.getQueryParam('checkout')) {
      return false;
    }
    if (FlowRouter.getRouteName() == 'landing-page') {
      return false;
    }
  },
  'click .js-backLink': function (event, instance) {
    if (FlowRouter.getQueryParam('checkout')) {
      window.close();
      return false;
    }
    if (FlowRouter.getRouteName() == 'discourse-sso') {
      window.history.back();
      return false;
    }
  },
});

Template.landingPageNav.helpers({
  onLandingPage() {
    return /landing-page/.test(FlowRouter.getRouteName());
  },
  showLoginSignup() {
    return (
      !Meteor.isCordova &&
      /landing-page|song-index|show-song|song-list/.test(FlowRouter.getRouteName())
    );
  },
  noBackButton() {
    return /reset-password/.test(FlowRouter.getRouteName());
  },
  backUrl() {
    if (FlowRouter.getRouteName() == 'discourse-sso') return '#';
    if (FlowRouter.getRouteName() == 'forgot-password') return '/login';
    if (FlowRouter.getRouteName() == 'show-song') return '/app';
    return '/';
  },
  loginUrl() {
    if (/show-song|song-list/.test(FlowRouter.getRouteName())) {
      return FlowRouter.path(
        'login',
        {},
        { redirect: `${window.location.pathname}${window.location.search}` }
      );
    }
    return '/login';
  },
});
