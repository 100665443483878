export interface EQBand {
  type?: 'highshelf' | 'lowshelf' | 'peak';
  freq: number;
  q: number;
  gain: number;
}

const defaultBands: EQBand[] = [
  {
    type: 'lowshelf',
    freq: 60,
    q: 2,
    gain: 0,
  },
  {
    freq: 120,
    q: 2,
    gain: 0,
  },
  {
    freq: 400,
    q: 2,
    gain: 0,
  },
  {
    freq: 900,
    q: 2,
    gain: 0,
  },
  {
    type: 'highshelf',
    freq: 12000,
    q: 2,
    gain: 0,
  },
];

export abstract class EQ {
  constructor(eqBands?: EQBand[]) {
    this._bands = eqBands ?? defaultBands;
  }

  protected _bands: EQBand[];
  get bands() {
    return this._bands;
  }

  set(bands: EQBand[]): void {
    if (bands.length != 5) {
      throw new Error(`Tried to set EQ with ${bands.length} bands`);
    }
    this._set(bands);
  }

  setBandAt(index: number, band: EQBand): void {
    const newBands = this.bands.slice();
    newBands[index] = { ...newBands[index], ...band };
    this._set(newBands);
  }

  protected abstract _set(bands: EQBand[]): void;
}
