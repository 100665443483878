import * as Sentry from '@sentry/browser';
import { hideAll as hideAllTippys } from 'tippy.js';
import waitUntilReactive from '@/utilities/waitUntilReactive';

if (typeof window.clientModules == 'undefined') window.clientModules = {};

const splashScreenModule = {
  hold() {
    this.held = true;
  },
  hide() {
    if (this.update) return;
    this.held = false;
    (Meteor.loggingIn()
      ? waitUntilReactive(() => Meteor.user() || !Meteor.userId())
      : Promise.resolve()
    ).then(() => {
      if (Meteor.isCordova) {
        navigator.splashscreen.hide();
        if ('StatusBar' in window && window.cordova?.platformId == 'ios') {
          StatusBar.show();
        }
        setTimeout(() => window.dispatchEvent(new Event('orientationchange')), 2000);
        this._hidden = true;
      } else {
        $('#splashScreen').hide();
        $('#initialResponseScript').remove();
      }
    });
  },
  show() {
    if (Meteor.isCordova) {
      navigator.splashscreen.show();
      if ('StatusBar' in window && window.cordova?.platformId == 'ios') StatusBar.styleDefault();
      this._hidden = false;
    } else {
      $('#splashScreen').velocity('fadeIn', { display: 'flex', duration: 300 });
    }
    Modal.hide();
    hideAllTippys();
  },
  visible() {
    return Meteor.isCordova ? !this._hidden : $('#splashScreen').is(':visible');
  },
  updateMode() {
    this.update = true;
    if (!Meteor.isCordova) {
      try {
        document.getElementById('splashScreenMessage').innerHTML =
          'Updating Strum Machine<br>to the latest version...';
        Meteor.setTimeout(() => {
          Sentry.captureMessage('Update taking longer than 10 seconds.', 'warning');
          document.getElementById('splashScreenMessage').innerHTML =
            'The update process is taking a long time, and may be stuck. ' +
            ('serviceWorker' in navigator && navigator.serviceWorker.controller && !window.todesktop
              ? 'Please try closing Strum Machine and opening it again. You may have to <a href="/disablesw">temporarily disable offline mode</a> to get things working again.'
              : 'You may want to <a href="#" onclick="window.location.reload();">refresh the page</a>, or close and open it again.');
        }, 10000);
        window.sessionStorage.setItem('strum_machine_version_update', true);
      } catch (e) {
        console.error(e);
      }
    }
    try {
      if (!this.visible()) {
        clientModules.splashScreen.show();
      }
    } catch (e) {
      console.error(e);
    }
  },
  showUninstallMessage() {
    try {
      document.getElementById('splashScreenMessage').innerHTML =
        'Please wait while stored data is cleared...';
      clientModules.splashScreen.show();
    } catch (e) {
      console.error(e);
    }
  },
};

clientModules.splashScreen = splashScreenModule;

Meteor.startup(() => {
  Meteor.setTimeout(() => {
    if (!clientModules.splashScreen.held) {
      clientModules.splashScreen.hide();
    }
  }, 50);
});
