import { strict as assert } from 'assert';

// prettier-ignore
const bassScale = ['e0', 'e1', 'e2', 'e3', 'e4', 'a0', 'a1', 'a2', 'a3', 'a4', 'd0', 'd1', 'd2', 'd3', 'd4', 'g0', 'g1', 'g2', 'g3', 'g4', 'g5', 'g6'] as const;

export function bassNoteFromChromaIndex(chromaIndex: number) {
  const note = bassScale[chromaIndex + 8];
  assert(note, `No bass note found for chroma index ${chromaIndex}`);
  return note;
}
