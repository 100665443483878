import type {
  BassIntentGhostNote,
  BassIntentNoteExplicit,
} from '@/band/instruments/bass/BassIntent';

export function pluck(
  { string, fret }: { string: number; fret: number },
  beatDuration: number,
  options: {
    db: number;
  }
): BassIntentNoteExplicit;

export function pluck(
  stringFret: `${'g' | 'd' | 'a' | 'e'}${0 | 1 | 2 | 3 | 4 | 5 | 6 | 7}` | 'drop-d',
  beatDuration: number,
  options: {
    db: number;
  }
): BassIntentNoteExplicit;

export function pluck(
  stringFret:
    | { string: number; fret: number }
    | `${'g' | 'd' | 'a' | 'e'}${0 | 1 | 2 | 3 | 4 | 5 | 6 | 7}`
    | 'drop-d',
  beatDuration: number,
  options: {
    db: number;
  }
): BassIntentNoteExplicit {
  const { string, fret } =
    typeof stringFret === 'string'
      ? {
          string: { g: 4, d: 3, a: 2, e: 1 }[stringFret.charAt(0)],
          fret: stringFret.charAt(1) == 'D' || stringFret == 'drop-d' ? -2 : +stringFret.charAt(1),
        }
      : stringFret;
  return <BassIntentNoteExplicit>{
    action: 'note',
    string,
    fret,
    beatDuration,
    ...options,
  };
}

export function ghost(
  string: 'g' | 'd' | 'a' | 'e',
  options: {
    db: number;
    omitAboveTpm?: number;
    omitBelowTpm?: number;
  }
): BassIntentGhostNote {
  return <BassIntentGhostNote>{
    action: 'ghost',
    string,
    ...options,
  };
}
