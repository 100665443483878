import AudioManager from '@/audio/engine/AudioManager';
import type { PlayerInstruction } from '@/band/instruments/PlayerInstruction';
import { randomPlusMinus } from '@/utilities/randomPlusMinus';

/**
 * @param seconds - number of seconds since the start of the note
 * @returns dB attentuation (positive number)
 */
function stringDecayAttentuation(seconds: number): number {
  return 10 * (1 - Math.pow(2, -Math.max(0, seconds)));
}

export function executePlayerInstructions(
  instructions: PlayerInstruction[],
  deadline: number,
  beatDuration = 0.125
): void {
  const tpm = 60 / beatDuration;
  const tpmLimit = tpm + randomPlusMinus(20);
  instructions.forEach((inst) => {
    if (inst.type == 'play') {
      if (inst.omitBelowTpm && inst.omitBelowTpm > tpm) return;
      if (inst.omitAboveTpm && inst.omitAboveTpm < tpmLimit) return;

      const timeOffset = beatDuration * (inst.beatOffset || 0) + (inst.offset || 0);
      const attentuation = inst.attentuateBasedOnBeatOffset
        ? stringDecayAttentuation(timeOffset)
        : 0;
      const db = (inst.db ?? 0) - attentuation;
      AudioManager.mixer
        ?.channel(inst.channel)
        ?.playbacks.filter((pb) => {
          if (pb.sampleId.replace(/\^+$/, '') == inst.sample.replace(/\^+$/, '')) {
            const playbackTime = deadline - (pb.startTime - pb.offset);
            return (
              pb.db - stringDecayAttentuation(playbackTime) <
              db + (/g .?[0-9_]/.test(pb.sampleId) ? 9 : 6)
            );
          }
        })
        .forEach((pb) => {
          void pb.stop({
            atTime: deadline + beatDuration * (inst.beatOffset || 0) + (inst.offset || 0),
            fadeDuration: 0.05,
          });
        });
      AudioManager.playSample({
        id: inst.sample,
        channel: inst.channel,
        atTime: deadline + timeOffset,
        volume: Math.pow(10, db / 20),
      });
    }

    if (inst.type == 'mute') {
      if (inst.omitBelowTpm && inst.omitBelowTpm > tpm) return;
      if (inst.omitAboveTpm && inst.omitAboveTpm < tpmLimit) return;
      AudioManager.mixer?.channel(inst.channel)?.playbacks.forEach((pb) => {
        if (inst.sample && pb.sampleId != inst.sample) return;
        if (inst.allowedStrings) {
          const pbStrings = /[0-9abcD+_]+/.exec(pb.sampleId)?.[0]?.split('');
          if (pbStrings?.every((char, i) => char == '+' || inst.allowedStrings?.[i] == char))
            return;
        }
        // Note: Playback.stop() is inert if atTime is later than prescheduled stop time
        void pb.stop({
          atTime: deadline + beatDuration * (inst.beatOffset || 0) + (inst.offset || 0),
          fadeDuration: inst.fadeDuration,
        });
      });
    }
  });
}
