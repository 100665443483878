import { eventTracker } from '@/browser/analytics/eventTracker';
import { Crnt } from '@/Crnt';
import closeNearestTippy from '@/ui/dropdowns/closeNearestTippy';
import defineTippyDropdown from '@/ui/dropdowns/defineTippyDropdown';
import { UserProfile } from '@/user/UserProfile';

defineTippyDropdown(Template.controlBarAutoSpeedup, {
  id: 'auto-speedup-menu',
  onShow() {
    UserProfile.recordMilestone('TOOLS_OPEN');
    if (!Crnt.song().prefs.autoSpeedup.enabled()) {
      Crnt.song()?.prefs.autoSpeedup.enable();
      eventTracker.autoSpeedupEnable();
    }
  },
});

Template.controlBarAutoSpeedup.helpers({
  autoSpeedupEnabled() {
    return Crnt.song()?.prefs.autoSpeedup.enabled();
  },
});

Template.controlBarAutoSpeedup.events({
  'click .js-disable': function (event, instance) {
    Crnt.song()?.prefs.autoSpeedup.disable();
    eventTracker.autoSpeedupDisable();
    closeNearestTippy(event.currentTarget);
  },
});
