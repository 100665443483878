import * as Sentry from '@sentry/browser';
import { Meteor } from 'meteor/meteor';
import { MusicLibrary } from '@/library/MusicLibrary';
import { rpcUpdateListPreviews } from '@/server/methods/lists/rpcUpdateListPreviews';
import wait from '@/utilities/wait';

function reportListPreviewDiscrepancy() {
  try {
    const listPreviews = [
      ...(Meteor.user('listPreviews')?.listPreviews?.own?.map((list) => {
        return { name: list.name, songCount: list.songs.length };
      }) ?? []),
      ...(Meteor.user('listPreviews')?.listPreviews?.shared?.map((list) => {
        return { name: list.name, songCount: list.songs.length };
      }) ?? []),
    ];
    const lists = MusicLibrary.lists.findAndFetch().map((list) => {
      return {
        _id: list._id,
        name: list.name,
        ownerId: list.ownerId,
        songCount: list.songs.length,
      };
    });
    console.warn("Had to update previews because lists weren't loading.");
    console.warn({ lists, listPreviews });
    Sentry.withScope((scope) => {
      scope.setExtra('lists', lists);
      scope.setExtra('listPreviews', listPreviews);
      Sentry.captureMessage("List count doesn't match user's listPreviews. Updating...");
    });
  } catch (err) {
    Sentry.captureException(err);
  }
}

export function guardAgainstListPreviewDiscrepancy(): void {
  void wait(6000).then(async () => {
    if (Meteor.user() && MusicLibrary.songs.ready() && !MusicLibrary.lists.ready()) {
      reportListPreviewDiscrepancy();
      await rpcUpdateListPreviews();
      setTimeout(() => MusicLibrary.lists.forceReady(), 1000);
    }
  });
}
