<script lang="ts">
  import { Meteor } from 'meteor/meteor';
  import Sortable from 'sortablejs';
  import type { SongListItemRecord } from '@/collections/SongListsCollection';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import SongIndexItem from '@/ui/SongIndexItem';
  import { isMobile } from '@/ui-helpers/isMobile';

  interface Props {
    songList: SongListItemRecord[];
    forceOpen?: boolean;
    reordering?: boolean;
    listId?: string;
    orderChanged?: (newOrder: string[]) => void;
  }
  let { songList, forceOpen, reordering, listId, orderChanged }: Props = $props();

  let userId = $state<string | null>(null);
  trackerEffect(() => (userId = Meteor.userId()));

  let justDroppedId = $state<string | undefined>();
  let dragInProgress = $state(false);

  function sortableList(element: HTMLElement) {
    const sortable = new Sortable(element, {
      draggable: '.songIndexItem',
      handle: '.songIndexItem-link',
      animation: 120,
      delay: 100,
      delayOnTouchOnly: true,
      forceFallback: isMobile,
      store: {
        get() {
          return songList.map((s) => s._id);
        },
        set(sortable) {
          const songIds = sortable.toArray();
          orderChanged?.(songIds);
        },
      },
      onStart() {
        dragInProgress = true;
      },
      onEnd(event) {
        dragInProgress = false;
        const droppedId = event.item.dataset.id as string;
        justDroppedId = droppedId;
        setTimeout(() => {
          if (justDroppedId == droppedId) justDroppedId = undefined;
        }, 800);
      },
      scrollSensitivity: 50,
    });

    return { destroy: () => sortable.destroy() };
  }
</script>

{#if reordering}
  <ul class="songIndexList" use:sortableList>
    {#each songList as song (song._id)}
      <li
        class="songIndexItem cursor-grab"
        class:SortableSongList-dropFlash={justDroppedId == song._id}
        class:dragInProgress
        data-id={song._id}
      >
        {@html SongIndexItem.renderHtml({
          song,
          isOwn: song.userId == userId,
          otherUsers: true,
          loggedIn: false, // hides list links
          listId,
          noLinks: true,
          noLIWrapper: true,
        })}
      </li>
    {/each}
  </ul>
{:else}
  <ul class="songIndexList">
    {#each songList as song (song._id)}
      {@html SongIndexItem.renderHtml({
        song,
        isOwn: song.userId == userId,
        otherUsers: true,
        loggedIn: !!userId && !forceOpen,
        listId,
      })}
    {/each}
  </ul>
{/if}

<style>
  .SortableSongList-dropFlash {
    animation: SortableSongList-dropFlash 500ms cubic-bezier(0, 0, 0.4, 1) both;
  }

  .dragInProgress {
    cursor: grab;
    cursor: grabbing;
  }

  @keyframes SortableSongList-dropFlash {
    0% {
      background-color: var(--primary-50);
      box-shadow: 0 0 0 var(--primary-300);
    }
    100% {
      box-shadow: 0 0 20px transparent;
    }
  }
</style>
