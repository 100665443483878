// prettier-ignore
type ScaleDegree = '1' | '1#' | '2b' | '2' | '2#' | '3b' | '3' | '4' | '4#' | '5' | '5#' | '6b' | '6' | '7b' | '7';
type BassRunInterval = number | null;
type BassRunTag = 'E' | 'S' | 'Q' | 's' | 'x' | 'D';
type BassRunTarget = `${ScaleDegree}${'' | 'm'}`;

type BassRunSourceDefinition = [
  fromNNS: BassRunTarget,
  toNNS: BassRunTarget,
  // interval: number,
  tags: BassRunTag[],
  // prettier-ignore
  run: [BassRunInterval, BassRunInterval, BassRunInterval, BassRunInterval, BassRunInterval, BassRunInterval, BassRunInterval, BassRunInterval],
  majorScaleWeightings: {
    sm?: number;
    mm?: number;
    b?: number;
  },
  keyShapeWeightings: {
    G?: number;
    A?: number;
    C?: number;
    D?: number;
    dD?: number;
    E?: number;
  },
];

// prettier-ignore
export const rawGuitarBassRunDefinitions: BassRunSourceDefinition[] =
[
  ['1', '2', ['E'], [null, null, null, null, null, null, 0, 1], { mm: 6, b: 6 }, { G: 4, A: 5, C: 4, D: 4, dD: 1, E: 1 }],
  ['1', '2', ['Q'], [null, null, null, null, 0, null, 1, null], { sm: 0, mm: 7, b: 7 }, { G: 5, A: 5, C: 4, D: 5, dD: 3, E: 2 }],
  ['1', '2', ['E'], [null, null, null, null, null, null, 11, 12], { sm: 3, mm: 4, b: 2 }, { G: 5, A: 3, C: 4, D: 2, dD: 2, E: 0 }],
  ['1', '2', ['Q'], [null, null, null, null, 11, null, 12, null], { sm: 4, mm: 5, b: 2 }, { G: 5, A: 5, C: 5, D: 3, dD: 3, E: 1 }],
  ['1', '2', ['E'], [null, null, null, null, 11, null, 12, 13], { mm: 7, b: 2 }, { G: 4, A: 5, C: 2, D: 3, dD: 3, E: 0 }],
  ['1', '2', ['S', 'D'], [null, null, null, null, 4, null, null, null], { sm: 7, mm: 4, b: 0 }, { G: 5, A: 0, C: 0, D: 3, dD: 0, E: 0 }],
  ['1', '2', ['E', 'D'], [null, null, null, null, null, null, 4, 3], { mm: 3, b: 1 }, { G: 2, A: 0, C: 3, D: 5, dD: 1, E: 0 }],
  ['1', '2', ['Q', 'D'], [null, null, null, null, 4, null, 3, null], { mm: 5, b: 3 }, { G: 5, A: 2, C: 5, D: 5, dD: 2, E: 1 }],
  ['1', '2', ['E', 'D'], [null, null, null, null, null, null, 5, 4], { sm: 7, mm: 4, b: 5 }, { G: 5, A: 0, C: 3, D: 2, dD: 1, E: 0 }],
  ['1', '2', ['Q', 'D'], [null, null, null, null, 5, null, 4, null], { sm: 7, mm: 5, b: 5 }, { G: 5, A: 3, C: 3, D: 3, dD: 2, E: 3 }],
  ['1', '2', ['E', 'D'], [null, null, null, null, 5, null, 4, 3], { mm: 3, b: 3 }, { G: 5, A: 1, C: 2, D: 1, dD: 0, E: 0 }],
  ['1', '2m', ['E'], [null, null, null, null, null, null, 0, 1], { mm: 3, b: 3 }, { G: 4, A: 5, C: 4, D: 5, dD: 1, E: 1 }],
  ['1', '2m', ['Q'], [null, null, null, null, 0, null, 1, null], { sm: 0, mm: 7, b: 7 }, { G: 5, A: 5, C: 4, D: 5, dD: 3, E: 2 }],
  ['1', '2m', ['E'], [null, null, null, null, null, null, 11, 12], { sm: 3, mm: 4, b: 2 }, { G: 5, A: 3, C: 4, D: 2, dD: 2, E: 0 }],
  ['1', '2m', ['Q'], [null, null, null, null, 11, null, 12, null], { sm: 4, mm: 5, b: 2 }, { G: 5, A: 5, C: 5, D: 3, dD: 3, E: 1 }],
  ['1', '2m', ['E'], [null, null, null, null, 11, null, 12, 13], { mm: 3, b: 2 }, { G: 4, A: 3, C: 2, D: 3, dD: 3, E: 0 }],
  ['1', '2m', ['S', 'D'], [null, null, null, null, 4, null, null, null], { sm: 7, mm: 4, b: 0 }, { G: 5, A: 0, C: 0, D: 3, dD: 0, E: 0 }],
  ['1', '2m', ['E', 'D'], [null, null, null, null, null, null, 4, 3], { mm: 3, b: 1 }, { G: 2, A: 0, C: 3, D: 5, dD: 1, E: 0 }],
  ['1', '2m', ['Q', 'D'], [null, null, null, null, 4, null, 3, null], { mm: 5, b: 3 }, { G: 5, A: 2, C: 5, D: 5, dD: 2, E: 1 }],
  ['1', '2m', ['E', 'D'], [null, null, null, null, null, null, 5, 4], { sm: 7, mm: 4, b: 5 }, { G: 5, A: 0, C: 3, D: 2, dD: 1, E: 0 }],
  ['1', '2m', ['Q', 'D'], [null, null, null, null, 5, null, 4, null], { sm: 7, mm: 5, b: 5 }, { G: 5, A: 3, C: 3, D: 3, dD: 2, E: 3 }],
  ['1', '2m', ['E', 'D'], [null, null, null, null, 5, null, 4, 3], { mm: 3, b: 3 }, { G: 5, A: 1, C: 2, D: 1, dD: 0, E: 0 }],
  ['1', '4', ['S', 'D'], [null, null, null, null, 10, null, null, null], { sm: 0, mm: 0, b: 2 }, { G: 0, A: 5, C: 0, D: 4, dD: 4, E: 3 }],
  ['1', '4', ['E'], [null, null, null, null, 2, null, 3, 4], { sm: 0, mm: 6, b: 0 }, { G: 5, A: 4, C: 4, D: 3, dD: 0, E: 3 }],
  ['1', '4', ['E'], [null, null, null, null, null, null, 2, 4], { sm: 9, mm: 9, b: 0 }, { G: 5, A: 5, C: 5, D: 5, dD: 1, E: 5 }],
  ['1', '4', ['Q'], [null, null, null, null, 2, null, 4, null], { sm: 9, mm: 9, b: 0 }, { G: 5, A: 4, C: 5, D: 5, dD: 5, E: 5 }],
  ['1', '4', ['E'], [null, null, null, null, null, null, 3, 4], { sm: 0, mm: 5, b: 7 }, { G: 5, A: 5, C: 2, D: 4, dD: 4, E: 4 }],
  ['1', '4', ['Q'], [null, null, null, null, 3, null, 4, null], { sm: 0, mm: 0, b: 7 }, { G: 5, A: 5, C: 1, D: 4, dD: 4, E: 4 }],
  ['1', '4', ['S'], [null, null, null, null, 4, null, null, null], { sm: 9, mm: 9, b: 0 }, { G: 4, A: 2, C: 4, D: 5, dD: 2, E: 0 }],
  ['1', '4', ['Q', 'D'], [null, null, null, null, 7, null, 6, null], { sm: 0, mm: 0, b: 2 }, { G: 2, A: 1, C: 5, D: 3, dD: 1, E: 3 }],
  ['1', '4', ['E', 'D'], [null, null, null, null, null, null, 8, 7], { sm: 0, mm: 0, b: 4 }, { G: 5, A: 0, C: 3, D: 3, dD: 2, E: 5 }],
  ['1', '4', ['S', 'D'], [null, null, null, null, 9, null, null, null], { sm: 4, mm: 0, b: 0 }, { G: 0, A: 0, C: 3, D: 4, dD: 1, E: 0 }],
  ['1', '4', ['Q', 'D'], [null, null, null, null, 9, null, 7, null], { sm: 7, mm: 6, b: 0 }, { G: 2, A: 0, C: 5, D: 4, dD: 4, E: 1 }],
  ['1', '4', ['E', 'D'], [null, null, null, null, 9, null, 8, 7], { sm: 0, mm: 3, b: 3 }, { G: 3, A: 0, C: 2, D: 1, dD: 1, E: 0 }],
  ['1', '5', ['S', 'D'], [null, null, null, null, 10, null, null, null], { sm: 0, mm: 0, b: 6 }, { G: 0, A: 3, C: 0, D: 2, dD: 3, E: 5 }],
  ['1', '5', ['E', 'D'], [null, null, null, null, null, null, 10, 9], { sm: 0, mm: 0, b: 5 }, { G: 2, A: 5, C: 4, D: 4, dD: 4, E: 0 }],
  ['1', '5', ['Q', 'D'], [null, null, null, null, 10, null, 9, null], { sm: 0, mm: 0, b: 9 }, { G: 5, A: 5, C: 4, D: 5, dD: 5, E: 2 }],
  ['1', '5', ['E', 'D'], [null, null, null, null, null, null, 11, 9], { sm: 7, mm: 0, b: 0 }, { G: 0, A: 4, C: 5, D: 3, dD: 3, E: 0 }],
  ['1', '5', ['Q', 'D'], [null, null, null, null, 11, null, 9, null], { sm: 7, mm: 0, b: 0 }, { G: 1, A: 5, C: 4, D: 4, dD: 4, E: 1 }],
  ['1', '5', ['S'], [null, null, null, null, 4, null, null, null], { sm: 9, mm: 8, b: 0 }, { G: 5, A: 1, C: 3, D: 5, dD: 0, E: 0 }],
  ['1', '5', ['E'], [null, null, null, null, null, null, 4, 5], { sm: 8, mm: 8, b: 0 }, { G: 4, A: 3, C: 2, D: 5, dD: 3, E: 1 }],
  ['1', '5', ['Q'], [null, null, null, null, 4, null, 5, null], { sm: 9, mm: 9, b: 0 }, { G: 4, A: 4, C: 3, D: 5, dD: 4, E: 3 }],
  ['1', '5', ['E'], [null, null, null, null, 4, null, 5, 6], { sm: 0, mm: 8, b: 5 }, { G: 4, A: 5, C: 4, D: 4, dD: 2, E: 3 }],
  ['1', '5', ['E'], [null, null, null, null, null, null, 5, 6], { sm: 0, mm: 7, b: 7 }, { G: 3, A: 5, C: 4, D: 2, dD: 1, E: 5 }],
  ['1', '5', ['Q'], [null, null, null, null, 5, null, 6, null], { sm: 0, mm: 0, b: 8 }, { G: 4, A: 5, C: 3, D: 4, dD: 2, E: 5 }],
  ['1', '5', ['S', 'D'], [null, null, null, null, 9, null, null, null], { sm: 5, mm: 5, b: 0 }, { G: 1, A: 5, C: 5, D: 3, dD: 4, E: 0 }],
  ['1', '6', ['S', 'D'], [null, null, null, null, 11, null, null, null], { sm: 5, mm: 3 }, { G: 3, A: 0, C: 1, D: 0, dD: 0, E: 0 }],
  ['1', '6', ['E', 'D'], [null, null, null, null, null, null, 11, 10], { mm: 4, b: 6 }, { G: 2, A: 0, C: 4, D: 1, dD: 0, E: 3 }],
  ['1', '6', ['Q', 'D'], [null, null, null, null, 11, null, 10, null], { mm: 5, b: 7 }, { G: 4, A: 3, C: 2, D: 3, dD: 1, E: 5 }],
  ['1', '6', ['Q', 'D'], [null, null, null, null, 12, null, 11, null], { sm: 9, mm: 9, b: 6 }, { G: 5, A: 3, C: 5, D: 3, dD: 1, E: 4 }],
  ['1', '6', ['E'], [null, null, null, null, 4, null, 7, 8], { mm: 7 }, { G: 3, A: 0, C: 0, D: 2, dD: 2, E: 0 }],
  ['1', '6', ['E'], [null, null, null, null, null, null, 7, 8], { mm: 7, b: 8 }, { G: 3, A: 1, C: 3, D: 4, dD: 4, E: 4 }],
  ['1', '6', ['Q'], [null, null, null, null, 7, null, 8, null], { mm: 2, b: 6 }, { G: 2, A: 5, C: 4, D: 5, dD: 5, E: 5 }],
  ['1', '6m', ['S', 'D'], [null, null, null, null, 11, null, null, null], { sm: 5, mm: 3 }, { G: 0, A: 0, C: 1, D: 0, dD: 0, E: 0 }],
  ['1', '6m', ['E', 'D'], [null, null, null, null, null, null, 11, 10], { mm: 4, b: 6 }, { G: 2, A: 0, C: 4, D: 1, dD: 0, E: 3 }],
  ['1', '6m', ['Q', 'D'], [null, null, null, null, 11, null, 10, null], { mm: 5, b: 7 }, { G: 4, A: 3, C: 2, D: 3, dD: 1, E: 5 }],
  ['1', '6m', ['E', 'D'], [null, null, null, null, null, null, 12, 11], { sm: 6, mm: 6, b: 4 }, { G: 5, A: 3, C: 5, D: 3, dD: 1, E: 4 }],
  ['1', '6m', ['Q', 'D'], [null, null, null, null, 12, null, 11, null], { sm: 9, mm: 9, b: 6 }, { G: 5, A: 3, C: 5, D: 3, dD: 1, E: 4 }],
  ['1', '6m', ['E'], [null, null, null, null, 4, null, 7, 8], { mm: 7 }, { G: 3, A: 0, C: 0, D: 2, dD: 2, E: 0 }],
  ['1', '6m', ['E'], [null, null, null, null, null, null, 7, 8], { mm: 7, b: 8 }, { G: 3, A: 1, C: 3, D: 4, dD: 4, E: 4 }],
  ['1', '6m', ['Q'], [null, null, null, null, 7, null, 8, null], { mm: 2, b: 6 }, { G: 2, A: 5, C: 4, D: 5, dD: 5, E: 5 }],
  ['1', '7b', ['E', 'D'], [null, null, null, null, null, null, 12, 11], { sm: 6, mm: 8, b: 9 }, { G: 5, A: 2, C: 3, D: 2, dD: 2, E: 5 }],
  ['1', '7b', ['Q', 'D'], [null, null, null, null, 12, null, 11, null], { sm: 6, mm: 9, b: 9 }, { G: 5, A: 3, C: 5, D: 4, dD: 4, E: 5 }],
  ['1', '7b', ['Q', 'D'], [null, null, null, null, 12, null, 9, null], { sm: 6, mm: 3, b: 4 }, { G: 3, A: 2, C: 5, D: 4, dD: 2, E: 0 }],
  ['1', '7b', ['E', 'D'], [null, null, null, null, null, null, 2, 0], { sm: 4, mm: 2, b: 1 }, { G: 4, A: 5, C: 4, D: 2, dD: 0, E: 0 }],
  ['1', '7b', ['Q', 'D'], [null, null, null, null, 2, null, 0, null], { sm: 4, mm: 2, b: 1 }, { G: 5, A: 4, C: 4, D: 3, dD: 0, E: 1 }],
  ['1', '7b', ['E'], [null, null, null, null, 7, null, 8, 9], { mm: 8, b: 9 }, { G: 3, A: 3, C: 3, D: 5, dD: 5, E: 3 }],
  ['1', '7b', ['E'], [null, null, null, null, null, null, 7, 9], { sm: 9, mm: 8, b: 8 }, { G: 3, A: 5, C: 5, D: 5, dD: 5, E: 5 }],
  ['1', '7b', ['Q'], [null, null, null, null, 7, null, 9, null], { sm: 9, mm: 7, b: 8 }, { G: 4, A: 5, C: 5, D: 5, dD: 5, E: 5 }],
  ['1', '7b', ['S'], [null, null, null, null, 9, null, null, null], { sm: 6, mm: 4, b: 3 }, { G: 1, A: 3, C: 5, D: 4, dD: 3, E: 0 }],
  ['2', '4', ['E', 'D'], [null, null, null, null, null, null, 9, 7], { sm: 2, mm: 1 }, { G: 5, A: 3, C: 2, D: 3, dD: 5, E: 4 }],
  ['2', '4', ['Q', 'D'], [null, null, null, null, 9, null, 7, null], { sm: 3, mm: 2 }, { G: 5, A: 4, C: 5, D: 5, dD: 5, E: 5 }],
  ['2', '4', ['E', 'D'], [null, null, null, null, 9, null, 8, 7], { mm: 3, b: 4 }, { G: 5, A: 4, C: 2, D: 3, dD: 5, E: 4 }],
  ['2', '5', ['S', 'D'], [null, null, null, null, 0, null, null, null], { sm: 5, mm: 7, b: 7 }, { G: 2, A: 1, C: 1, D: 2, dD: 0, E: 3 }],
  ['2', '5', ['E', 'D'], [null, null, null, null, null, null, 10, 9], { mm: 7, b: 9 }, { G: 4, A: 5, C: 4, D: 5, dD: 5, E: 2 }],
  ['2', '5', ['Q', 'D'], [null, null, null, null, 10, null, 9, null], { mm: 5, b: 8 }, { G: 4, A: 5, C: 3, D: 5, dD: 5, E: 3 }],
  ['2', '5', ['E', 'D'], [null, null, null, null, 11, null, 10, 9], { mm: 7, b: 6 }, { G: 4, A: 5, C: 5, D: 5, dD: 5, E: 1 }],
  ['2', '5', ['E', 'D'], [null, null, null, null, null, null, 11, 9], { mm: 5 }, { G: 3, A: 3, C: 5, D: 4, dD: 4, E: 0 }],
  ['2', '5', ['Q', 'D'], [null, null, null, null, 11, null, 9, null], { sm: 6, mm: 5 }, { G: 4, A: 5, C: 4, D: 5, dD: 5, E: 1 }],
  ['2', '5', ['E'], [null, null, null, null, 4, null, 5, 6], { mm: 9, b: 5 }, { G: 3, A: 1, C: 2, D: 4, dD: 1, E: 4 }],
  ['2', '5', ['Q'], [null, null, null, null, 4, null, 6, null], { sm: 7, mm: 5, b: 2 }, { G: 5, A: 4, C: 5, D: 5, dD: 2, E: 5 }],
  ['2', '5', ['E'], [null, null, null, null, null, null, 5, 6], { mm: 8, b: 7 }, { G: 5, A: 4, C: 3, D: 5, dD: 1, E: 4 }],
  ['2', '5', ['Q'], [null, null, null, null, 5, null, 6, null], { mm: 6, b: 6 }, { G: 5, A: 4, C: 4, D: 5, dD: 1, E: 4 }],
  ['2', '5', ['S'], [null, null, null, null, 6, null, null, null], { sm: 9, mm: 7, b: 5 }, { G: 3, A: 5, C: 5, D: 3, dD: 0, E: 0 }],
  ['2m', '4', ['E'], [null, null, null, null, null, null, 3, 4], { mm: 7, b: 7 }, { G: 5, A: 4, C: 5, D: 4, dD: 3, E: 5 }],
  ['2m', '4', ['Q'], [null, null, null, null, 3, null, 4, null], { mm: 2, b: 3 }, { G: 5, A: 4, C: 4, D: 5, dD: 4, E: 5 }],
  ['2m', '4', ['E', 'D'], [null, null, null, null, null, null, 9, 7], { sm: 2, mm: 1 }, { G: 5, A: 3, C: 2, D: 3, dD: 5, E: 4 }],
  ['2m', '4', ['Q', 'D'], [null, null, null, null, 9, null, 7, null], { sm: 3, mm: 2 }, { G: 5, A: 4, C: 5, D: 5, dD: 5, E: 5 }],
  ['2m', '4', ['E', 'D'], [null, null, null, null, 9, null, 8, 7], { mm: 3, b: 4 }, { G: 5, A: 4, C: 2, D: 3, dD: 5, E: 4 }],
  ['2m', '5', ['S', 'D'], [null, null, null, null, 0, null, null, null], { sm: 5, mm: 7, b: 7 }, { G: 2, A: 1, C: 1, D: 2, dD: 0, E: 3 }],
  ['2m', '5', ['E', 'D'], [null, null, null, null, null, null, 10, 9], { mm: 7, b: 9 }, { G: 4, A: 5, C: 4, D: 5, dD: 5, E: 2 }],
  ['2m', '5', ['Q', 'D'], [null, null, null, null, 10, null, 9, null], { mm: 5, b: 8 }, { G: 4, A: 5, C: 3, D: 5, dD: 5, E: 3 }],
  ['2m', '5', ['E', 'D'], [null, null, null, null, 11, null, 10, 9], { mm: 7, b: 6 }, { G: 4, A: 5, C: 5, D: 5, dD: 5, E: 1 }],
  ['2m', '5', ['E', 'D'], [null, null, null, null, null, null, 11, 9], { mm: 5 }, { G: 3, A: 3, C: 5, D: 4, dD: 4, E: 0 }],
  ['2m', '5', ['Q', 'D'], [null, null, null, null, 11, null, 9, null], { sm: 6, mm: 5 }, { G: 4, A: 5, C: 4, D: 5, dD: 5, E: 1 }],
  ['2m', '5', ['E'], [null, null, null, null, null, null, 4, 5], { sm: 9, mm: 5, b: 2 }, { G: 5, A: 3, C: 2, D: 5, dD: 4, E: 4 }],
  ['2m', '5', ['Q'], [null, null, null, null, 4, null, 5, null], { sm: 9, mm: 5, b: 2 }, { G: 5, A: 5, C: 2, D: 5, dD: 4, E: 5 }],
  ['2m', '5', ['E'], [null, null, null, null, 4, null, 5, 6], { mm: 9, b: 5 }, { G: 3, A: 1, C: 2, D: 4, dD: 1, E: 4 }],
  ['2m', '5', ['S'], [null, null, null, null, 5, null, null, null], { sm: 7, mm: 5, b: 5 }, { G: 5, A: 2, C: 1, D: 3, dD: 0, E: 3 }],
  ['2m', '5', ['E'], [null, null, null, null, null, null, 5, 6], { mm: 8, b: 7 }, { G: 5, A: 4, C: 3, D: 5, dD: 1, E: 4 }],
  ['2m', '5', ['Q'], [null, null, null, null, 5, null, 6, null], { mm: 6, b: 6 }, { G: 5, A: 4, C: 4, D: 5, dD: 1, E: 4 }],
  ['4', '1', ['S'], [null, null, null, null, 10, null, null, null], { b: 2 }, { G: 2, A: 4, C: 0, D: 1, dD: 0, E: 0 }],
  ['4', '1', ['E'], [null, null, null, null, null, null, 10, 11], { mm: 5, b: 9 }, { G: 4, A: 4, C: 4, D: 4, dD: 1, E: 3 }],
  ['4', '1', ['Q'], [null, null, null, null, 10, null, 11, null], { b: 7 }, { G: 3, A: 5, C: 2, D: 3, dD: 1, E: 2 }],
  ['4', '1', ['E', 'D'], [null, null, null, null, null, null, 3, 2], { b: 6 }, { G: 2, A: 5, C: 4, D: 0, dD: 3, E: 5 }],
  ['4', '1', ['Q', 'D'], [null, null, null, null, 3, null, 2, null], { b: 5 }, { G: 2, A: 5, C: 4, D: 0, dD: 5, E: 5 }],
  ['4', '1', ['E', 'D'], [null, null, null, null, null, null, 4, 2], { sm: 4, mm: 2 }, { G: 4, A: 2, C: 4, D: 0, dD: 2, E: 4 }],
  ['4', '1', ['Q', 'D'], [null, null, null, null, 4, null, 2, null], { sm: 4, mm: 1 }, { G: 5, A: 4, C: 4, D: 0, dD: 3, E: 5 }],
  ['4', '1', ['E', 'D'], [null, null, null, null, 4, null, 3, 2], { mm: 4 }, { G: 4, A: 3, C: 3, D: 0, dD: 2, E: 3 }],
  ['4', '1', ['E'], [null, null, null, null, null, null, 7, 10], { b: 6 }, { G: 0, A: 4, C: 2, D: 5, dD: 3, E: 3 }],
  ['4', '1', ['Q'], [null, null, null, null, 7, null, 10, null], { b: 4 }, { G: 0, A: 5, C: 3, D: 5, dD: 3, E: 3 }],
  ['4', '1', ['E'], [null, null, null, null, 7, null, 10, 11], { b: 6 }, { G: 0, A: 4, C: 4, D: 5, dD: 3, E: 3 }],
  ['4', '1', ['S'], [null, null, null, null, 9, null, null, null], { sm: 9, mm: 7 }, { G: 2, A: 5, C: 3, D: 5, dD: 1, E: 1 }],
  ['4', '1', ['E'], [null, null, null, null, null, null, 9, 10], { b: 7 }, { G: 1, A: 4, C: 2, D: 4, dD: 1, E: 0 }],
  ['4', '1', ['E'], [null, null, null, null, 9, null, 10, 11], { mm: 9 }, { G: 4, A: 5, C: 5, D: 4, dD: 1, E: 3 }],
  ['4', '1', ['E'], [null, null, null, null, null, null, 9, 11], { sm: 9, mm: 8 }, { G: 5, A: 3, C: 4, D: 4, dD: 1, E: 0 }],
  ['4', '1', ['Q'], [null, null, null, null, 9, null, 11, null], { sm: 9, mm: 6 }, { G: 5, A: 4, C: 4, D: 4, dD: 1, E: 1 }],
  ['4', '5', ['E', 'D'], [null, null, null, null, null, null, 10, 9], { mm: 3, b: 7 }, { G: 3, A: 2, C: 2, D: 3, dD: 1, E: 0 }],
  ['4', '5', ['Q', 'D'], [null, null, null, null, 10, null, 9, null], { b: 6 }, { G: 4, A: 2, C: 2, D: 4, dD: 2, E: 2 }],
  ['4', '5', ['E'], [null, null, null, null, null, null, 4, 5], { sm: 7, mm: 9, b: 4 }, { G: 5, A: 2, C: 4, D: 5, dD: 5, E: 3 }],
  ['4', '5', ['Q'], [null, null, null, null, 4, null, 5, null], { sm: 9, mm: 9, b: 2 }, { G: 5, A: 3, C: 5, D: 5, dD: 5, E: 5 }],
  ['4', '5', ['E'], [null, null, null, null, 4, null, 5, 6], { mm: 9, b: 8 }, { G: 5, A: 3, C: 5, D: 5, dD: 4, E: 5 }],
  ['4', '5', ['E'], [null, null, null, null, null, null, 5, 6], { mm: 1, b: 2 }, { G: 5, A: 3, C: 5, D: 5, dD: 5, E: 5 }],
  ['4', '5', ['Q'], [null, null, null, null, 5, null, 6, null], { mm: 6, b: 8 }, { G: 5, A: 3, C: 5, D: 5, dD: 5, E: 5 }],
  ['4', '5', ['S', 'D'], [null, null, null, null, 9, null, null, null], { sm: 8, mm: 7, b: 3 }, { G: 3, A: 5, C: 3, D: 5, dD: 3, E: 2 }],
  ['4', '5', ['E', 'D'], [null, null, null, null, null, null, 9, 8], { mm: 6, b: 1 }, { G: 4, A: 5, C: 2, D: 3, dD: 3, E: 2 }],
  ['4', '5', ['Q', 'D'], [null, null, null, null, 9, null, 8, null], { mm: 5, b: 3 }, { G: 4, A: 5, C: 3, D: 5, dD: 4, E: 4 }],
  ['5', '1', ['S'], [null, null, null, null, 10, null, null, null], {  }, { G: 5, A: 3, C: 2, D: 0, dD: 0, E: 2 }],
  ['5', '1', ['E'], [null, null, null, null, null, null, 10, 11], { b: 9 }, { G: 5, A: 4, C: 5, D: 3, dD: 3, E: 5 }],
  ['5', '1', ['Q'], [null, null, null, null, 10, null, 11, null], { b: 9 }, { G: 5, A: 5, C: 4, D: 3, dD: 3, E: 4 }],
  ['5', '1', ['S'], [null, null, null, null, 11, null, null, null], { sm: 9, mm: 8 }, { G: 5, A: 1, C: 5, D: 3, dD: 3, E: 4 }],
  ['5', '1', ['E', 'D'], [null, null, null, null, null, null, 3, 2], { b: 7 }, { G: 3, A: 3, C: 3, D: 1, dD: 4, E: 5 }],
  ['5', '1', ['Q', 'D'], [null, null, null, null, 3, null, 2, null], { b: 4 }, { G: 2, A: 3, C: 2, D: 1, dD: 5, E: 5 }],
  ['5', '1', ['S', 'D'], [null, null, null, null, 4, null, null, null], { sm: 2, mm: 0 }, { G: 4, A: 0, C: 0, D: 2, dD: 2, E: 0 }],
  ['5', '1', ['E', 'D'], [null, null, null, null, null, null, 4, 2], { sm: 4, mm: 2 }, { G: 5, A: 1, C: 2, D: 0, dD: 3, E: 1 }],
  ['5', '1', ['Q', 'D'], [null, null, null, null, 4, null, 2, null], { sm: 4, mm: 2 }, { G: 5, A: 2, C: 1, D: 0, dD: 4, E: 5 }],
  ['5', '1', ['E', 'D'], [null, null, null, null, 4, null, 3, 2], { mm: 4 }, { G: 5, A: 2, C: 2, D: 1, dD: 4, E: 3 }],
  ['5', '1', ['S', 'D'], [null, null, null, null, 5, null, null, null], { sm: 0, mm: 1, b: 3 }, { G: 3, A: 4, C: 0, D: 4, dD: 4, E: 4 }],
  ['5', '1', ['E', 'D'], [null, null, null, null, 5, null, 3, 2], { b: 3 }, { G: 2, A: 3, C: 1, D: 0, dD: 2, E: 2 }],
  ['5', '1', ['E', 'D'], [null, null, null, null, null, null, 5, 4], { sm: 1 }, { G: 3, A: 2, C: 0, D: 0, dD: 0, E: 0 }],
  ['5', '1', ['Q', 'D'], [null, null, null, null, 5, null, 4, null], { sm: 1 }, { G: 4, A: 4, C: 0, D: 0, dD: 2, E: 2 }],
  ['5', '1', ['E', 'D'], [null, null, null, null, 5, null, 4, 2], { sm: 2 }, { G: 4, A: 3, C: 1, D: 0, dD: 0, E: 1 }],
  ['5', '1', ['E'], [null, null, null, null, 9, null, 10, 11], { mm: 9, b: 9 }, { G: 5, A: 5, C: 5, D: 4, dD: 4, E: 3 }],
  ['5', '1', ['E'], [null, null, null, null, null, null, 9, 11], { sm: 9, mm: 7 }, { G: 5, A: 3, C: 5, D: 4, dD: 4, E: 1 }],
  ['5', '1', ['Q'], [null, null, null, null, 9, null, 11, null], { sm: 9, mm: 7 }, { G: 5, A: 4, C: 5, D: 5, dD: 5, E: 2 }],
  ['5', '4', ['E'], [null, null, null, null, 2, null, 3, 4], { mm: 7, b: 8 }, { G: 5, A: 4, C: 3, D: 5, dD: 1, E: 2 }],
  ['5', '4', ['E'], [null, null, null, null, null, null, 2, 4], { sm: 9, mm: 9, b: 6 }, { G: 5, A: 2, C: 2, D: 5, dD: 1, E: 1 }],
  ['5', '4', ['Q'], [null, null, null, null, 2, null, 4, null], { sm: 9, mm: 9, b: 4 }, { G: 5, A: 5, C: 2, D: 5, dD: 4, E: 5 }],
  ['5', '4', ['E'], [null, null, null, null, null, null, 3, 4], { b: 7 }, { G: 5, A: 3, C: 1, D: 3, dD: 3, E: 2 }],
  ['5', '4', ['Q'], [null, null, null, null, 3, null, 4, null], { b: 4 }, { G: 3, A: 3, C: 1, D: 2, dD: 4, E: 3 }],
  ['5', '4', ['S'], [null, null, null, null, 4, null, null, null], { sm: 5, mm: 5 }, { G: 4, A: 0, C: 1, D: 2, dD: 0, E: 0 }],
  ['5', '4', ['Q', 'D'], [null, null, null, null, 7, null, 6, null], { mm: 5, b: 7 }, { G: 3, A: 3, C: 5, D: 3, dD: 2, E: 5 }],
  ['5', '4', ['E', 'D'], [null, null, null, null, null, null, 8, 7], { mm: 3, b: 2 }, { G: 3, A: 1, C: 0, D: 5, dD: 5, E: 4 }],
  ['5', '4', ['S', 'D'], [null, null, null, null, 9, null, null, null], { sm: 2, mm: 1 }, { G: 1, A: 0, C: 5, D: 1, dD: 1, E: 1 }],
  ['5', '4', ['Q', 'D'], [null, null, null, null, 9, null, 7, null], { sm: 7, mm: 4 }, { G: 4, A: 1, C: 5, D: 5, dD: 5, E: 3 }],
  ['5', '4', ['E', 'D'], [null, null, null, null, 9, null, 8, 7], { mm: 6, b: 2 }, { G: 3, A: 1, C: 1, D: 3, dD: 3, E: 3 }],
  ['7b', '1', ['E'], [null, null, null, null, null, null, 10, 11], { mm: 9, b: 8 }, { G: 5, A: 4, C: 4, D: 5, dD: 5, E: 4 }],
  ['7b', '1', ['Q'], [null, null, null, null, 10, null, 11, null], { mm: 9, b: 8 }, { G: 5, A: 5, C: 5, D: 5, dD: 5, E: 4 }],
  ['7b', '1', ['S'], [null, null, null, null, 7, null, null, null], { sm: 6, mm: 4, b: 6 }, { G: 0, A: 3, C: 0, D: 4, dD: 4, E: 2 }],
  ['7b', '1', ['Q'], [null, null, null, null, 7, null, 10, null], { sm: 9, mm: 7, b: 8 }, { G: 0, A: 5, C: 0, D: 5, dD: 5, E: 2 }],
  ['7b', '1', ['E'], [null, null, null, null, 7, null, 10, 11], { mm: 7, b: 8 }, { G: 0, A: 5, C: 0, D: 5, dD: 5, E: 3 }]
];
