export const sectionTypes = [
  {
    id: '',
    name: 'Every time',
    label: '',
    description: 'Strum Machine will always play this section.',
  },
  {
    id: 'i',
    name: 'First time only',
    label: 'first time only',
    description: 'This section will only be played the first time through the song, like an intro.',
  },
  {
    id: 'o',
    name: 'Last time only *',
    label: 'last time only',
    description:
      'This section will only be played the last time through the song, like an outro, IF Auto-Finish is enabled and is ending the song.',
  },
  /*
  {
    id: 'sf',
    name: 'Skip first time',
    label: 'skip first time',
    description:
      'This section will be skipped the first time through the song, and played every time after that.',
  },
  {
    id: 'sl',
    name: 'Skip last time *',
    label: 'skip last time',
    description:
      'This section will be played every time <i>except</i> the last time through the song, IF Auto-Finish is enabled and is ending the song.',
  },
  */
] as const;
