import { rounded } from '@/utilities/rounded';

export function migrateSerializedBandSettings(src: Record<string, unknown>) {
  const result: Record<string, unknown> = { ...src };
  migrateSingleNumberBassTiming(result);
  migrateSeparateBeatTimings(result);
  migrateLegacyGuitarStyleToCore(result);
  migrateLegacyFeetStyle(result);
  return result;
}

const legacyGuitarStyleMigrations: Record<string, Record<string, unknown>> = {
  'bc': {
    'g_c': 'bc',
  },
  'bgm': {
    'g_c': 'bg',
  },
  'bgh': {
    'g_c': 'bg',
    'g_ss': { r: 0.75, t: 0.75 },
    'g_bcbr': { v: rounded(12 / 14, 3) },
  },
  'bgs': {
    'g_c': 'bg',
    'g_ss': { r: 0.2, t: 0.75 },
    'g_bcbr': { v: rounded(1 / 14, 3) },
  },
  'bca': {
    'g_c': 'bg',
    'g_ss': { r: 0.75, t: 0.625 },
  },
  'bck': {
    'g_c': 'sb',
    'g_bcbr': { v: rounded(10 / 14, 3) },
  },
  'd_du_udu': {
    'g_c': 'sb',
    'g_bcbr': { v: rounded(13 / 14, 3) },
  },
  'cr': {
    'g_c': 'cr',
  },
  'ssds': {
    'g_c': 'd',
    'g_dot': { 's': 1 },
    'g_dss': { 'b1': 1, 'b2': 0 },
  },
  'sbds': {
    'g_c': 'd',
    'g_dot': { 's': 0 },
    'g_dss': { 'b1': 1, 'b2': 0 },
  },
  'sds': {
    'g_c': 'd',
    'g_dot': { 's': 1 },
    'g_dss': { 'b1': 1 / 12, 'b2': 0 },
  },
  'sdbds': {
    'g_c': 'd',
    'g_dot': { 's': 0 },
    'g_dss': { 'b1': 1 / 12, 'b2': 0 },
  },
  'sgb': {
    // no gypsy bossa yet
    'g_c': 'd',
    'g_dot': { 's': 1 },
    'g_dss': { 'b1': 1 / 12, 'b2': 0 },
  },
  'htf': {
    'g_c': 'ht',
  },

  'bcw': {
    'g_c': 'bcw',
  },
  'bgmw': {
    'g_c': 'bgw',
  },
  'bghw': {
    'g_c': 'bgw',
    'g_bcbr': { v: rounded(12 / 14, 3) },
    'g_ss': { r: 0.75, t: 0.75 },
  },
  'bgsw': {
    'g_c': 'aw',
  },
  'ssdsw': {
    'g_c': 'dw',
    'g_dot': { 's': 1 },
    'g_dss': { 'b1': 1, 'b2': 0 },
  },
  'sbdsw': {
    'g_c': 'dw',
    'g_dot': { 's': 0 },
    'g_dss': { 'b1': 1, 'b2': 0 },
  },
  'sdsw': {
    'g_c': 'dw',
    'g_dot': { 's': 1 },
    'g_dss': { 'b1': 1 / 12, 'b2': 0 },
  },
  'sdbdsw': {
    'g_c': 'dw',
    'g_dot': { 's': 0 },
    'g_dss': { 'b1': 1 / 12, 'b2': 0 },
  },

  'bcj': {
    'g_c': 'bcj',
  },
  'cj': {
    'g_c': 'cj',
  },
  'bcsj': {
    'g_c': 'bcsj',
  },
  'csj': {
    'g_c': 'csj',
  },
};

function migrateLegacyGuitarStyleToCore(record: Record<string, unknown>) {
  const oldDampenedOnbeats = record.g_do as { d?: boolean; s?: number } | undefined;
  const oldDampenedSustain = record.g_ds as { v?: boolean } | undefined;
  if (oldDampenedOnbeats || oldDampenedSustain) {
    record.g_dot = { s: oldDampenedOnbeats?.s ?? 0 };
    record.g_dss = {
      b1: oldDampenedOnbeats?.d ? (oldDampenedSustain?.v ?? 0) : 1,
      b2: oldDampenedSustain?.v ?? 0,
    };
    delete record.g_do;
    delete record.g_ds;
  }

  if (typeof record.g_s == 'string' && typeof record.g_c != 'string') {
    Object.assign(record, legacyGuitarStyleMigrations[record.g_s]);

    const oldDampenedUpstrokes = record.g_du as { v?: number } | undefined;
    if (typeof oldDampenedUpstrokes?.v == 'number') {
      record.g_df = { e: oldDampenedUpstrokes.v / 1.2 };
      delete record.g_du;
    } else if (record.g_c == 'd') {
      record.g_df = { e: 0.5 };
    }

    const skipBeat3 = (record.g_sb3 as { e?: number })?.e ?? 0;
    if (skipBeat3 > 0) {
      if (record.g_s == 'bgh') {
        record.g_ss = {
          r: rounded(0.75 + 0.25 * skipBeat3, 3),
          t: rounded(0.75 + 0.15 * skipBeat3, 3),
        };
      } else if (record.g_s == 'bgm') {
        record.g_ss = { r: rounded(skipBeat3, 3), t: 0.125 };
      }
    }

    const oldBassNotes = {
      ...(record.g_bcbn ?? {}),
    } as { p?: boolean; sb?: 'n' | 's' | 'm' | 'a' } | undefined;
    const oldBassRuns = { ...(record.g_br ?? {}) } as {
      e?: number;
      wq?: number;
      we?: number;
      ws?: number;
    };

    if (oldBassNotes?.p) {
      record.g_bn = { m: 'r' };
    }

    if (oldBassNotes?.sb && ['bgm', 'bgh', 'bca', 'bck'].includes(record.g_s)) {
      record.g_bcbr = {
        'n': { v: rounded(7 / 14, 3) },
        's': { v: rounded(9 / 14, 3) },
        'm': { v: rounded(11 / 14, 3) },
        'a': { v: rounded(12 / 14, 3) },
      }[oldBassNotes.sb];
    }

    if (oldBassRuns.e) {
      const weights = {
        wq: oldBassRuns.wq ?? 1,
        we: oldBassRuns.we ?? 0,
        ws: oldBassRuns.ws ?? 0,
      };
      const totalWeight = weights.wq + weights.we + weights.ws;
      if (weights.wq || weights.we) {
        record.g_br = {
          e: oldBassRuns.e * ((weights.wq + weights.we) / totalWeight),
          qeb: weights.we / (weights.wq + weights.we),
        };
      } else {
        delete record.g_br; // because leading notes are in g_bn now
      }
      if (weights.ws) {
        ((record.g_bn ??= {}) as Record<string, unknown>).l = oldBassRuns.e * weights.ws;
      }
    }
  }
}

const legacyFeetStyleMigrations: Record<string, Record<string, unknown>> = {
  'dt': {
    'f_c': 'dt',
  },
  'dtw': {
    'f_c': 'dtw',
  },
  'dtj': {
    'f_c': 'dtj',
  },
  'dtsj': {
    'f_c': 'dtsj',
  },
  'qr': {
    'f_c': 'cr',
  },
  'qj': {
    'f_c': 'cj',
  },
  'qsj': {
    'f_c': 'csj',
  },
};

function migrateLegacyFeetStyle(record: Record<string, unknown>) {
  if (typeof record.f_s == 'string' && typeof record.f_c != 'string') {
    Object.assign(record, legacyFeetStyleMigrations[record.f_s]);
  }
}

/**
 * Migrate single-number bass timing.
 * Bass timing used to be one number, but now it's a single-element array for future-proofing.
 * @param record - the record, which will be mutated
 */
function migrateSingleNumberBassTiming(record: Record<string, unknown>) {
  if (typeof record['b_bt'] == 'number') record['b_bt'] = [record.b_bt];
}

/**
 * Migrate separate beat 1/2/3 timings into a single array
 * @param record - the record, which will be mutated
 */
function migrateSeparateBeatTimings(record: Record<string, unknown>) {
  (['g', 'm'] as ('g' | 'm')[]).forEach((inst) => {
    if (`${inst}_b1t` in record || `${inst}_b2t` in record) {
      record[`${inst}_bt`] = [record[`${inst}_b1t`] || 0, record[`${inst}_b2t`] || 0];
      delete record[`${inst}_b1t`];
      delete record[`${inst}_b2t`];
    }
    if (`${inst}_b1wt` in record || `${inst}_b2wt` in record || `${inst}_b3wt` in record) {
      record[`${inst}_bt`] = [
        record[`${inst}_b1wt`] || 0,
        record[`${inst}_b2wt`] || 0,
        record[`${inst}_b3wt`] || 0,
      ];
      delete record[`${inst}_b1wt`];
      delete record[`${inst}_b2wt`];
      delete record[`${inst}_b3wt`];
    }
  });
}
