/* global Chart */
import { subDays } from 'date-fns';
import { Roles } from '@/Roles';
import { rpcGetAllUserData } from '@/server/methods/admin/rpcGetAllUserData';
import { rpcGetMonthlyActiveUsers } from '@/server/methods/admin/rpcGetMonthlyActiveUsers';
import wait from '@/utilities/wait';
import waitUntilReactive from '@/utilities/waitUntilReactive';

var userData;
var periodicData = {};
const filteredUsers = {
  trialing: [],
  trialExpired: [],
  subscribed: [],
  pastDue: [],
  canceled: [],
  iapActive: [],
  iapLapsing: [],
  iapCancelled: [],
};
var dataReady = new ReactiveVar();
var dataReadyPromise = waitUntilReactive(() => dataReady.get());

Template.metricsPage.onCreated(async () => {
  console.info('Waiting for permissions');
  await wait(500);
  await waitUntilReactive(() => Roles.userHasPermission(Meteor.userId(), 'metrics.view'));
  console.info('Loading scripts');
  $.when(
    $.getScript('https://cdnjs.cloudflare.com/ajax/libs/stupidtable/1.1.3/stupidtable.min.js'),
    $.getScript('https://cdnjs.cloudflare.com/ajax/libs/Chart.js/2.9.4/Chart.bundle.js'),
    $.Deferred((d) => $(d.resolve))
  ).done(async () => {
    try {
      console.info('Getting user data');
      const data = await rpcGetAllUserData();
      console.info(`Got ${data.length} users`);
      userData = data;
    } catch (err) {
      console.error(err);
      return;
    }
    for (const user of userData) {
      if (
        user.subscription?.status == 'trialing' &&
        !user.paymentSource &&
        user.subscription?.trialEnd > now
      )
        filteredUsers.trialing.push(user);

      if (
        !user.roles?.includes('lifetime-member') &&
        user.subscription?.status != 'active' &&
        user.subscription?.trialEnd &&
        user.subscription?.trialEnd < now
      )
        filteredUsers.trialExpired.push(user);

      if (
        user.subscription?.status == 'active' ||
        (user.subscription?.status == 'trialing' && user.paymentSource)
      )
        filteredUsers.subscribed.push(user);

      if (user.subscription?.status == 'past_due') filteredUsers.pastDue.push(user);

      if (user.subscription?.status == 'canceled') filteredUsers.canceled.push(user);

      if (
        user.roles?.includes('paid-member') &&
        (user.inAppPurchases ?? []).find((iap) => !iap.isExpired)?.renewalIntent == 'Renew'
      )
        filteredUsers.iapActive.push(user);

      if (
        user.roles?.includes('paid-member') &&
        (user.inAppPurchases ?? []).find((iap) => !iap.isExpired)?.renewalIntent == 'Lapse'
      )
        filteredUsers.iapLapsing.push(user);

      if (user.roles?.includes('canceled-member') && user.inAppPurchases && !user.subscription)
        filteredUsers.iapCancelled.push(user);
    }

    dataReady.set(true);
  });
});

var now = new Date();
const periodLabelForDate = (date) => {
  if (now.getFullYear() != date.getFullYear()) {
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear() - 2000}`;
  } else {
    return `${date.getMonth() + 1}/${date.getDate()}`;
  }
};

const recalculateSubscriberStats = ({ periodLength = 14, periods = 52 } = {}) => {
  periodicData = {
    periodLabels: new Array(periods),
    totalSubscribedUsers: new Array(periods),
    totalSubscribedUserIncome: new Array(periods),
    totalPayingIAPs: new Array(periods),
    trialsInProgress: new Array(periods),
    trialsBegun: new Array(periods),
    trialsConverted: new Array(periods),
    trialsUnconverted: new Array(periods),
    cancellations: new Array(periods),
    conversionRate: new Array(periods),
  };

  for (let i = 0; i < periods; i++) {
    const cutoffDate = subDays(new Date(), (periods - 1 - i) * periodLength);
    const nextCutoffDate = subDays(cutoffDate, periodLength);

    periodicData.periodLabels[i] = periodLabelForDate(cutoffDate);

    let totalSubscribedUsers = 0;
    let totalPayingIAPs = 0;
    let totalSubscribedUserIncome = 0;
    let trialsInProgressCount = 0;
    let trialsBegun = 0;
    let trialsConverted = 0;
    let trialsUnconverted = 0;
    let cancellations = 0;
    const conversionRate = 0;

    for (const user of userData) {
      const payingWithStripe =
        user.roles?.includes('paid-member') &&
        user.subscription?.start &&
        user.subscription?.start <= cutoffDate &&
        (!user.subscription?.endedAt || user.subscription?.endedAt > cutoffDate);

      if (payingWithStripe) totalSubscribedUsers++;

      if (payingWithStripe) {
        totalSubscribedUserIncome +=
          (((user.subscription?.plan?.amount || 0) / 100 - 0.3) /
            (user.subscription?.plan?.period == 'year' ? 12 : 1)) *
          0.981; /* sans that 2.9% */
      }

      if (
        user.roles?.includes('paid-member') &&
        (user.inAppPurchases ?? []).find(
          (iap) =>
            !iap.isExpired &&
            !iap.isTrialPeriod &&
            iap.purchaseDate &&
            new Date(iap.purchaseDate) <= cutoffDate &&
            (!iap.expirationDate || new Date(iap.expirationDate) > cutoffDate)
        )
      )
        totalPayingIAPs++;

      const trialInProgress =
        user.subscription?.trialStart &&
        user.subscription?.trialEnd &&
        user.subscription?.trialEnd > cutoffDate &&
        user.subscription?.trialStart < cutoffDate;
      if (trialInProgress) trialsInProgressCount++;

      const trialBegun =
        user.subscription?.trialStart &&
        user.subscription?.trialStart <= cutoffDate &&
        user.subscription?.trialStart > nextCutoffDate;
      if (trialBegun) {
        trialsBegun++;
        if (user.paymentSource) trialsConverted++;
        if (!user.paymentSource) trialsUnconverted++;
        if (user.subscription?.canceledAt) cancellations++;
      }
    }

    periodicData.totalSubscribedUsers[i] = totalSubscribedUsers;
    periodicData.totalSubscribedUserIncome[i] = totalSubscribedUserIncome.toFixed(0);
    periodicData.totalPayingIAPs[i] = totalPayingIAPs;
    periodicData.trialsInProgress[i] = trialsInProgressCount;
    periodicData.trialsBegun[i] = trialsBegun;
    periodicData.trialsConverted[i] = trialsConverted;
    periodicData.trialsUnconverted[i] = trialsUnconverted;
    periodicData.cancellations[i] = cancellations;
    periodicData.conversionRate[i] = (trialsBegun ? trialsConverted / trialsBegun : 0).toFixed(2);
  }
};

Template.metricsPage.onRendered(() => {
  const instance = Template.instance();
  dataReadyPromise.then(() => {
    instance.$('.js-fadeIn').each(function () {
      $(this).css('opacity', 0).animate(
        {
          opacity: 1,
        },
        {
          duration: 2000,
          queue: false,
          easing: 'swing',
        }
      );
    });
    instance.$('.js-fancyIncrement').each(function () {
      $(this)
        .prop('Counter', 0)
        .animate(
          {
            Counter: $(this).text(),
          },
          {
            duration: 2000,
            queue: false,
            easing: 'swing',
            step(now) {
              $(this).text(
                Math.ceil(now)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              );
            },
          }
        );
    });
  });

  dataReadyPromise.then(() => {
    Chart.defaults.global.maintainAspectRatio = false;
    // Chart.defaults.global.legend.position = 'right';
    Chart.defaults.global.legend.reverse = true;
    Chart.defaults.global.legend.labels.boxWidth = 20;
    Chart.defaults.global.defaultFontSize = 10;
    Chart.defaults.global.legend.labels.fontSize = 14;

    instance.subscribersChart = new Chart(instance.$('#subscribersChart'), {
      type: 'line',
      data: {},
      options: {
        scales: {
          xAxes: [{ stacked: true }],
          yAxes: [{ stacked: true }],
        },
      },
    });

    instance.revenueChart = new Chart(instance.$('#revenueChart'), {
      type: 'line',
      data: {},
    });

    instance.crChart = new Chart(instance.$('#crChart'), {
      type: 'line',
      data: {},
      options: {
        scales: {
          yAxes: [{ ticks: { max: 1 } }],
        },
      },
    });

    instance.trialsChart = new Chart(instance.$('#trialsChart'), {
      type: 'bar',
      data: {},
      options: {
        scales: {
          xAxes: [{ stacked: true }],
          yAxes: [{ stacked: true }],
        },
      },
    });

    instance.updateCharts = () => {
      instance.subscribersChart.data.labels = periodicData.periodLabels;
      instance.subscribersChart.data.datasets = [
        {
          label: 'Active users',
          backgroundColor: 'hsla(130, 30%, 45%, 0.4)',
          borderColor: 'hsla(130, 40%, 45%, 0.8)',
          data: periodicData.totalSubscribedUsers,
        },
        {
          label: 'IAP Users',
          backgroundColor: 'hsla(220, 70%, 45%, 0.2)',
          borderColor: 'hsla(220, 70%, 45%, 0.3)',
          data: periodicData.totalPayingIAPs,
        },
        {
          label: 'Trialing users',
          backgroundColor: 'hsla(185, 70%, 45%, 0.4)',
          borderColor: 'hsla(185, 70%, 45%, 0.4)',
          data: periodicData.trialsInProgress,
        },
      ];
      instance.subscribersChart.update();

      instance.revenueChart.data.labels = periodicData.periodLabels;
      instance.revenueChart.data.datasets = [
        {
          label: 'Calculated MRR',
          backgroundColor: 'hsla(145, 30%, 45%, 0.4)',
          borderColor: 'hsla(145, 30%, 45%, 0.2)',
          data: periodicData.totalSubscribedUserIncome,
        },
      ];
      // instance.subscribedUsersChart.data.datasets = [{
      //   label: '$29/year',
      //   backgroundColor: "hsla(130, 30%, 45%, 0.4)",
      //   borderColor: "hsla(130, 50%, 45%, 0.2)",
      //   data: periodicData.subscribedUsersByPlan.annual29
      // },{
      //   label: '$2.90/mo',
      //   backgroundColor: "hsla(145, 30%, 45%, 0.4)",
      //   borderColor: "hsla(145, 30%, 45%, 0.2)",
      //   data: periodicData.subscribedUsersByPlan.monthly290
      // },{
      //   label: '$49/year',
      //   backgroundColor: "hsla(185, 70%, 45%, 0.4)",
      //   borderColor: "hsla(185, 70%, 45%, 0.4)",
      //   data: periodicData.subscribedUsersByPlan.annual49
      // },{
      //   label: '$5/mo',
      //   backgroundColor: "hsla(200, 70%, 45%, 0.4)",
      //   borderColor: "hsla(200, 70%, 45%, 0.4)",
      //   data: periodicData.subscribedUsersByPlan.monthly500
      // }];
      instance.revenueChart.update();

      instance.trialsChart.data.labels = periodicData.periodLabels;
      instance.trialsChart.data.datasets = [
        {
          label: 'Cancelled',
          backgroundColor: 'rgba(208,46,23,0.6)',
          borderColor: 'rgba(208,46,23,1)',
          data: periodicData.cancellations,
        },
        {
          label: 'Converted trials',
          backgroundColor: 'rgba(120,190,100,0.8)',
          borderColor: 'rgba(120,190,100,0.8)',
          data: periodicData.trialsConverted,
        },
        {
          label: 'Unconverted trials',
          backgroundColor: 'rgba(120,150,100,0.4)',
          borderColor: 'rgba(120,150,100,0.4)',
          data: periodicData.trialsUnconverted,
        },
      ];
      instance.trialsChart.update();

      instance.crChart.data.labels = periodicData.periodLabels;
      instance.crChart.data.datasets = [
        {
          label: 'Conversion Ratio',
          backgroundColor: 'rgba(40,250,20,0.5)',
          borderColor: 'rgba(40,250,20,1)',
          data: periodicData.conversionRate,
          type: 'line',
          fill: true,
        },
      ];
      instance.crChart.update();
    };

    instance.$('.js-update').click();
  });
});

Template.metricsPage.events({
  'click .js-loadMAU': async function (event, instance) {
    instance.$('.js-loadMAU').hide();
    const mau = await rpcGetMonthlyActiveUsers();
    instance.$('#activeUsersChart').fadeIn();
    new Chart(instance.$('#activeUsersChart'), {
      type: 'line',
      data: {
        labels: mau.map((r) => `${r.year}-${r.month}`),
        datasets: [
          {
            label: 'Strum Machine active users during the calendar month',
            backgroundColor: 'hsla(185, 70%, 45%, 0.5)',
            borderColor: 'hsla(185, 70%, 45%, 0.8)',
            data: mau.map((r) => r.activeUsers),
          },
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
              },
            },
          ],
        },
      },
    });
  },

  'change #periodLength, input #periods': function (event, instance) {
    $('#periods_output').val(+$('#periods').val() * +instance.$('#periodLength').val());
  },
  'click .js-update': function (event, instance) {
    recalculateSubscriberStats({
      periodLength: +instance.$('#periodLength').val(),
      periods: +instance.$('#periods').val(),
    });
    instance.updateCharts();
  },
});

Template.metricsPage.helpers({
  trialingUsers() {
    return filteredUsers.trialing;
  },
  trialExpiredUsers() {
    return filteredUsers.trialExpired;
  },
  subscribedUsers() {
    return filteredUsers.subscribed;
  },
  pastDueUsers() {
    return filteredUsers.pastDue;
  },
  canceledUsers() {
    return filteredUsers.canceled;
  },
  iapActive() {
    return filteredUsers.iapActive;
  },
  iapLapsing() {
    return filteredUsers.iapLapsing;
  },
  iapCancelled() {
    return filteredUsers.iapCancelled;
  },
  iapMRR() {
    return (filteredUsers.iapActive.length * 3.5).toFixed(0);
  },
  count(list) {
    return list.length;
  },
  mrr() {
    return filteredUsers.subscribed
      .reduce(
        (total, user) =>
          total +
          (((user.subscription?.plan?.amount || 0) / 100 - 0.3) /
            (user.subscription?.plan?.period == 'year' ? 12 : 1)) *
            0.981 /* sans that 2.9% */,
        0
      )
      .toFixed(0);
  },
  ready() {
    return dataReady.get();
  },
  canViewMetrics() {
    return Roles.userHasPermission(Meteor.userId(), 'metrics.view');
  },
});
