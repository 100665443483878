export const syncopatedSpreadGradients = [
  // Beat 1
  [
    [7 / 14, 1],
    [14 / 14, 4.5],
  ],
  // upstroke 1
  [
    [5 / 14, 1],
    [8 / 14, 1.5],
    [14 / 14, 4],
  ],
  // Beat 2
  [
    [0 / 14, 1],
    [14 / 14, 3.5],
  ],
  // upstroke 2
  [
    [0 / 14, 1],
    [2 / 14, 2],
    [7 / 14, 4],
    [14 / 14, 4.5],
  ],
  //
  // Beat 3
  [
    [8 / 14, 1],
    [10 / 14, 2],
    [14 / 14, 3.5],
  ],
  // upstroke 3
  [
    [4 / 14, 1],
    [7 / 14, 1.5],
    [14 / 14, 4],
  ],
  // Beat 4
  [
    [0 / 14, 1],
    [1 / 14, 1.6],
    [7 / 14, 4],
    [14 / 14, 4.5],
  ],
  // upstroke 4
  [
    [2 / 14, 1],
    [7 / 14, 2],
    [14 / 14, 4],
  ],
] as const;
