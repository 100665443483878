<script lang="ts">
  import type { Instance as TippyInstance, Props as TippyProps } from 'tippy.js';
  import { MasterPitch } from '@/audio/mixer/MasterPitch';
  import MasterPitchControl from '@/audio/mixer/ui/MasterPitchControl.svelte';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import { makeTippyMenu } from '@/ui/dropdowns/makeTippyMenu';

  let currentValue: number = $state(440);
  let currentCents: number = $state(0);
  let useCurrentValueInCaption = $state(false);

  trackerEffect(() => {
    currentValue = MasterPitch.get();
    currentCents = MasterPitch.getCents();
    // Once shown, we want to always show current value until destroyed
    if (currentValue != 440) useCurrentValueInCaption = true;
  });

  let tippyInstance: TippyInstance;
  function tippyMenu(node: HTMLElement, params: Partial<TippyProps> = {}) {
    tippyInstance = makeTippyMenu(node, params, { keysThatShow: [' ', 'Enter'] });
    return { destroy: () => tippyInstance?.destroy() };
  }
</script>

<div use:tippyMenu={{ 'theme': 'light', 'placement': 'bottom' }}>
  <button class="btn OVB_button js-global-outputPitchButton">
    {#if !useCurrentValueInCaption}
      Adjust pitch
    {:else}
      A = {currentValue} Hz
    {/if}
  </button>
  <div role="menu" class="OVB_menu">
    <div class="OVB_heading">Instrument pitch/tuning:</div>
    <div class="OVB_controlWrap">
      <MasterPitchControl />
      <div class="OVB_currentCents">
        {#if currentValue != 440}
          ({Math.abs(Math.round(currentCents))} cents {currentValue < 440 ? 'below' : 'above'} A440)
        {:else}
          (standard pitch)
        {/if}
      </div>
    </div>
    <button
      class="btn btn-sm OVB_resetButton"
      disabled={currentValue == 440}
      style="visibility: {currentValue == 440 ? 'hidden' : 'visible'}"
      onclick={() => {
        MasterPitch.set(440);
        tippyInstance?.hide();
      }}
    >
      Reset to standard pitch
    </button>
  </div>
</div>

<style>
  .OVB_button {
    font-size: 14px;
    padding: 0.5rem 1rem 0.5rem;
  }

  .OVB_heading {
    text-align: center;
    padding-top: 0.5rem;
    padding-bottom: 0.75rem;
    font-size: 16px;
    color: var(--gray-800);
  }

  .OVB_menu {
    width: 300px;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
  }

  .OVB_text {
    text-align: center;
    padding: 0.5rem;
    font-size: 14px;
    color: var(--gray-600);
  }

  .OVB_resetButton {
    margin: 0 auto 0.5rem;
  }

  .OVB_controlWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
  }
  .OVB_currentCents {
    text-align: center;
    padding: 0rem;
    font-size: 14px;
    color: var(--gray-400);
  }
</style>
