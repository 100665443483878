import type { BassIntent } from '@/band/instruments/bass/BassIntent';
import type { GuitarChord } from '@/band/instruments/guitar/GuitarChord';
import type { GuitarIntent } from '@/band/instruments/guitar/GuitarIntent';
import type { MandolinChord } from '@/band/instruments/mandolin/MandolinChord';
import type { MandolinIntent } from '@/band/instruments/mandolin/MandolinIntent';
import type { PlayerInstruction } from '@/band/instruments/PlayerInstruction';
import type { LinearizedCell } from '@/chart/LinearizedCell';
import type { LinearizedMeasure } from '@/chart/LinearizedMeasure';
import type { Chord } from '@/music/Chord';

interface BeatPlanForGuitar {
  chord?: GuitarChord;
  onbeatIntent?: GuitarIntent;
  offbeatIntent?: GuitarIntent;
}

interface BeatPlanForMandolin {
  chord?: MandolinChord;
  onbeatIntent?: MandolinIntent;
  offbeatIntent?: MandolinIntent;
}

interface BeatPlanForBass {
  onbeatIntent?: BassIntent;
  offbeatIntent?: BassIntent;
}

/**
 * Represents quarter notes in 4/4 and 3/4 time, and eighth notes in 6/8 time
 */
export class LinearizedBeat {
  readonly chord: Chord; // this is the chord *heard* only
  chordChanged?: boolean;
  chordRootChanged?: boolean;

  readonly measure: LinearizedMeasure;
  readonly beatInMeasure: number;
  readonly cell: LinearizedCell;
  readonly beatInCell: number;
  readonly effect: LinearizedCell['effect'];

  prev?: LinearizedBeat;
  next?: LinearizedBeat;

  playerInstructions: Partial<Record<InstrumentId | 'click', PlayerInstruction[]>> = {};

  plans: Partial<{
    guitar: BeatPlanForGuitar;
    mandolin: BeatPlanForMandolin;
    bass: BeatPlanForBass;
    // feet: FeetPlans;
  }> = {};

  constructor({
    measure,
    beatInMeasure,
    cell,
    beatNumber,
  }: {
    measure: LinearizedMeasure;
    beatInMeasure: number;
    cell: LinearizedCell;
    beatNumber: number; // 1, 2, 3
  }) {
    this.chord =
      (beatNumber >= 3 && cell.originalCell.subdividedBeats?.[2]?.chordHeard) ||
      (beatNumber >= 2 && cell.originalCell.subdividedBeats?.[1]?.chordHeard) ||
      cell.chordHeard;
    this.cell = cell;
    this.beatInCell = beatNumber - 1;
    this.beatInMeasure = beatInMeasure;
    this.measure = measure;
    this.effect = cell.originalCell.subdividedBeats
      ? cell.originalCell.subdividedBeats[this.beatInCell]?.effect
      : cell.effect;
  }

  get rest(): boolean {
    return this.effect == 'rest';
  }

  get stop(): boolean {
    return this.effect == 'stop';
  }

  get diamond(): boolean {
    return this.effect == 'diamond';
  }
}
