<script lang="ts">
  import type { PresetEditorModes } from '@/band/Band';
  import type { BandPreset } from '@/band/presets/BandPreset';
  import PresetEditModeHeaderSaveCancelButtons from '@/band/ui/components/PresetEditModeHeaderSaveCancelButtons.svelte';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';

  const bandMenu = getBandMenuState();

  let band = $derived($bandMenu.band);

  let presetMode: PresetEditorModes = $state();
  trackerEffect(() => (presetMode = band.presetMode()));

  let preset: BandPreset | undefined = $state();
  trackerEffect(() => (preset = band.preset()));
</script>

{#if presetMode == 'edit'}
  <div class="bg-yellow-100 pb-4 pl-5 pr-3 pt-3">
    <div class="pb-2">
      <div class="font-bold leading-5 text-gray-900">
        {#if preset?.default}
          Editing Default Band
        {:else if preset?.builtIn}
          <div class="text-sm font-normal text-gray-700">Editing built-in preset:</div>
          {preset.name}
        {:else if preset}
          <div class="text-sm font-normal text-gray-700">Editing your preset:</div>
          {preset.name}
        {/if}
      </div>
    </div>
    <PresetEditModeHeaderSaveCancelButtons />
  </div>
{/if}
