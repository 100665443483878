import { bluegrassWaltzPatternLandscape } from '@/band/instruments/guitar/bluegrassWaltzPatternLandscape';
import {
  bassNote,
  crosspick,
  pickSpecified,
  strumDown,
  strumUp,
} from '@/band/instruments/guitar/GuitarActions';
import type { GuitarIntent, GuitarIntentStrum } from '@/band/instruments/guitar/GuitarIntent';
import { bluegrassWaltzSpreadGradients } from '@/band/instruments/guitar/settings/bluegrassWaltzSpreadGradients';
import type { GuitarBassNotesSetting } from '@/band/instruments/guitar/settings/GuitarBassNotesSetting';
import type { GuitarBrushinessSetting } from '@/band/instruments/guitar/settings/GuitarBrushinessSetting';
import type { GuitarCircularStrumShapeSetting } from '@/band/instruments/guitar/settings/GuitarCircularStrumShapeSetting';
import type { GuitarOpenVoicingsSetting } from '@/band/instruments/guitar/settings/GuitarOpenVoicingsSetting';
import type { LinearizedCell } from '@/chart/LinearizedCell';
import { lerp } from '@/utilities/lerp';
import { randomPlusMinus } from '@/utilities/randomPlusMinus';

const zeroThroughFive = [0, 1, 2, 3, 4, 5] as const;
type BeatAction = 'strum' | 'pick' | 'interval';

export function bluegrassWaltzCell(
  cell: LinearizedCell,
  {
    circularStrumShape,
    bassNotes,
    brushiness,
    openVoicings,
  }: {
    circularStrumShape: GuitarCircularStrumShapeSetting;
    bassNotes: GuitarBassNotesSetting;
    brushiness: GuitarBrushinessSetting;
    openVoicings: GuitarOpenVoicingsSetting;
  }
): (GuitarIntent | null)[] {
  const beatPowers = circularStrumShape
    .powersForLandscape(bluegrassWaltzPatternLandscape)
    .map((p) => p + randomPlusMinus(0.04)) as SixNumbers;

  const beatSpreads = brushiness
    .spreadsForGradients(bluegrassWaltzSpreadGradients)
    .map((spread, i) => {
      spread *= lerp(0.5, 1, beatPowers[i] ?? NaN);
      spread += randomPlusMinus(i % 4 > 0 ? 0.3 : 0.0);
      return spread;
    }) as SixNumbers;

  // Ensure "chucks" are not isolated single notes
  beatSpreads[2] = Math.max(2.2 - (beatPowers[1] + beatPowers[3]), beatSpreads[2]);
  beatSpreads[4] = Math.max(2.2 - (beatPowers[3] + beatPowers[5]), beatSpreads[4]);

  const beat1RootOrFifth =
    bassNotes.altBass && !cell.layout.barStart && !cell.beats[0].chordChanged ? 'fifth' : 'root';

  const beatBiases: SixOf<GuitarIntentStrum['bias']> = [
    beat1RootOrFifth,
    'afterRoot',
    'chuck1',
    'afterChuck1',
    'chuck2',
    'afterChuck2',
  ];

  const abovePowerThreshold = (power: number) => power > 0.05 && power - 0.05 > Math.random() * 0.3;
  const beatActions: (BeatAction | undefined)[] = [
    cell.plans.guitar?.boomStrum ? 'strum' : 'pick',
    ...([1, 2, 3, 4, 5] as const).map((i) =>
      abovePowerThreshold(beatPowers[i]) ? (beatSpreads[i] > 1.3 ? 'strum' : 'pick') : undefined
    ),
  ];

  // Bass run handling

  zeroThroughFive.forEach((i) => {
    if (typeof cell.plans.guitar?.bassRun?.intervals[i] == 'number') beatActions[i] = 'interval';
  });
  if (beatActions[2] === 'interval') {
    if (beatActions[3] !== 'interval' && Math.random() < 0.6) beatPowers[3] -= Math.random() * 1.4;
    else beatSpreads[3] = Math.min(2, beatSpreads[3]);
  }
  if (beatActions[4] === 'interval') {
    if (beatActions[5] !== 'interval' && Math.random() < 0.4) beatPowers[5] -= Math.random() * 1.2;
    else beatSpreads[5] = Math.min(2, beatSpreads[5]);
  }

  // Split cell handling

  if (cell.beats[1]?.chordChanged) {
    beatActions[0] = 'strum';
    beatPowers[0] = Math.max(0.8, beatPowers[0]);
    beatSpreads[0] = Math.max(3, beatSpreads[0]);
    beatPowers[1] = 0;
    beatPowers[2] = Math.max(0.8, beatPowers[2]);
    beatSpreads[2] = Math.max(3.5, beatSpreads[2]);
    beatBiases[2] = 'root';
    beatPowers[3] = 0;
  }

  if (cell.beats[2]?.chordChanged) {
    beatPowers[2] = Math.max(0.3, beatPowers[2]);
    beatPowers[3] = 0;
    beatPowers[4] = Math.max(0.8, beatPowers[4]);
    beatSpreads[4] = Math.max(3.5, beatSpreads[4]);
    beatBiases[4] = 'root';
    beatPowers[5] = 0;
  }

  // Calculate dBs

  const beatDbs = [
    beatActions[0] == 'pick'
      ? lerp(-5, 6, beatPowers[0]) - lerp(3, 0, Math.min(1, brushiness.value * 2))
      : lerp(-10, 2, beatPowers[0]),
    lerp(-13, 0, beatPowers[1]) + (beatActions[1] == 'pick' ? 1 : 0),
    lerp(-10, 3, beatPowers[2]),
    lerp(-15, -3, beatPowers[3]) + (beatActions[1] == 'pick' ? 1 : 0),
    lerp(-10, 2, beatPowers[4]),
    lerp(-15, -3, beatPowers[5]) + (beatActions[1] == 'pick' ? 1 : 0),
  ]
    .map((db, i) => db + randomPlusMinus(i % 4 > 0 ? 0.5 : 0))
    .map((db, i) =>
      beatActions[i] === 'interval' ? Math.max(-2 - 2 * (i % 2), db) : db
    ) as SixNumbers;

  // Put it all together!

  return zeroThroughFive.map((i) => {
    if (beatActions[i] === 'pick') {
      return i === 0
        ? bassNote(beat1RootOrFifth, { db: beatDbs[i] })
        : crosspick({
            series: 'std',
            noteInSeries: ([1, 2, 3, 4, 7, 6] as const)[i],
            db: beatDbs[i],
          });
    }

    if (beatActions[i] === 'strum') {
      return (i % 2 == 0 ? strumDown : strumUp)(beatBiases[i], {
        spread: beatSpreads[i],
        db: beatDbs[i],
      });
    }

    if (beatActions[i] === 'interval') {
      const interval = cell.plans.guitar?.bassRun?.intervals[i];
      if (typeof interval != 'number') return null;
      const stringFret = openVoicings.intervalStringFretInKey(cell.measure.key, interval);
      return pickSpecified(stringFret, { db: beatDbs[i] });
    }

    return null;
  });
}
