export function generatePathForHead({
  headWidth,
  direction,
}: {
  headWidth: number;
  direction: 'd' | 'u';
}) {
  const dirSign = direction == 'u' ? -1 : 1;
  const headY = dirSign * (headWidth * (3 / 4));
  const insetY = (dirSign * headWidth) / 6;
  return `
      m${-headWidth / 2},${-headY}
      l${headWidth / 2},${headY} l${headWidth / 2},${-headY}
      l${-headWidth / 2},${insetY}
      z`;
}

export function generatePathForCross({ size }: { size: number }) {
  const half = size / 2;
  return `
      m${-half},${-size}
      l${half - 1},${half}  l${-half + 1},${half}  l${half},${-half + 1} l${half},${half - 1}
      l${-half + 1},${-half} l${half - 1},${-half} l${-half},${half - 1} l${-half},${-half + 1}
      z`;
}

export function generatePathForTail({
  stemWidth,
  height,
  direction,
}: {
  stemWidth: number;
  height: number;
  direction: 'd' | 'u';
}) {
  const extraStem = height > 0 ? 1.0 : 0;
  const dirSign = direction == 'u' ? -1 : 1;
  const heightWithSign = dirSign * height + dirSign * extraStem;
  return `
      m${stemWidth / 2},${dirSign * extraStem}
      l${-stemWidth / 2},${-heightWithSign}
      l${-stemWidth / 2},${heightWithSign}
      z`;
}

export function generateDampeningLines({
  direction,
  stemWidth,
  height,
  chick,
}: {
  direction: 'd' | 'u';
  stemWidth: number;
  height: number;
  chick?: boolean | 'partial';
}) {
  const lines: string[] = [];
  const lineSpacing = 2.5;
  const lineCount = Math.round(height / lineSpacing);
  const dirSign = direction == 'u' ? -1 : 1;
  for (let i = 0; i < lineCount; i += 1) {
    const width =
      stemWidth *
      (chick === true ? 0.2 + 0.2 * (1 - i / lineCount) : 1 - i / lineCount) *
      (chick && i === 0 ? 0.4 : 1);
    lines.push(`m${-width / 2},${-dirSign * lineSpacing} l${width},0 m${-width / 2},0`);
  }
  return `m0,${dirSign * 0.6} ${lines.join(' ')}`;
}
