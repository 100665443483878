<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import PanelSettingSection from '@/band/ui/components/PanelSettingSection.svelte';
  import PrettySlider from '@/band/ui/components/PrettySlider.svelte';
  import { range } from '@/utilities/range';

  const dispatch = createEventDispatcher<{ 'change': number }>();

  const max = 10; // percent

  const notchWidth: number = 1;
  const step: number = 1;
  const allowedValues = [
    ...range(-max - notchWidth, -notchWidth - step, step),
    0,
    ...range(notchWidth + step, max + notchWidth, step),
  ];

  interface Props {
    value: number;
    label: string;
  }

  let { value, label }: Props = $props();

  let adjustedValue = $derived(value * 100 + notchWidth * Math.sign(value));

  let desc = $derived(
    value == 0
      ? 'Right on the beat'
      : `${Math.round(Math.abs(value) * 100)}% of beat ${value > 0 ? 'late' : 'early'}`
  );

  let ariaValues = $derived({
    'min': -10,
    'max': +10,
    'now': value * 100,
    'text': !value
      ? 'right on the beat'
      : `${value * 100} percent of the beat ${value < 0 ? 'early' : 'late'}`,
  });
</script>

<PanelSettingSection title={label} whiteBg={true}>
  <div class="flex items-center gap-4 px-4 py-2">
    <div class="basis-10 text-center text-gray-600">Early</div>
    <div class="flex-1">
      <PrettySlider
        min={-max - notchWidth}
        max={max + notchWidth}
        {step}
        value={adjustedValue}
        thumbDiameter={2}
        {allowedValues}
        {ariaValues}
        ariaLabel={label}
        fillFromCenter={true}
        on:change={({ detail: value }) => {
          const trueValue = (value - Math.sign(value) * notchWidth) / 100;
          dispatch('change', trueValue);
        }}
      />
    </div>
    <div class="basis-10 text-center text-gray-600">Late</div>
  </div>
</PanelSettingSection>
<div class="px-3 pt-2 text-center text-sm italic text-gray-500">
  {desc}
</div>
