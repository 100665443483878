import type { BuiltInPresetDefinition } from '@/band/presets/BuiltInPresetDefinition';

export const countryPresetDefinitions: Partial<Record<TimeSignature, BuiltInPresetDefinition[]>> = {
  // --- 4/4 ---

  '4/4': [
    {
      id: 'cn-sh',
      name: 'Country - Shuffle',
      description: 'Down-up guitar strum from 40s/50s/60s country with alternating bass.',
      genreId: 'cn',
      genreCategoryId: 'cn-n',
      band: {
        'sw': '2:1',
        'g_c': 'bg',
        'g_br': { 'e': 0.5, 'qeb': 0.25 },
        'g_ss': { 'r': 0.425, 't': 0.75 },
        'g_bcbr': { 'v': 9 / 14 },
        'g_ov': { 'mj': 'gacde' },
        'm_c': 'c',
        'm_cd': { 'i': 1, 's': 2 },
        'm_ca': { 'r': 1, 't': 0.684 },
      },
    },

    {
      id: 'cn-hsh',
      name: 'Country - Semi-Shuffle',
      description: 'As above, but with less upstroke swing for a more "straight-ahead" sound.',
      genreId: 'cn',
      genreCategoryId: 'cn-n',
      band: {
        'sw': '3:2',
        'g_c': 'bg',
        'g_br': { 'e': 0.5, 'qeb': 0.25 },
        'g_ss': { 'r': 0.425, 't': 0.75 },
        'g_bcbr': { 'v': 7 / 14 },
        'g_ov': { 'mj': 'gacde' },
        'm_c': 'c',
        'm_cd': { 'i': 1, 's': 2 },
        'm_ca': { 'r': 1, 't': 0.684 },
      },
    },

    {
      id: 'cn-wlksh',
      name: 'Country - Walking Shuffle',
      description: 'Walking bass with big down-up strums. Best at slower tempos.',
      genreId: 'cn',
      genreCategoryId: 'cn-n',
      band: {
        'sw': '2:1',
        'g_c': 'bg',
        'g_br': { 'e': 0.5, 'qeb': 1 },
        'g_ss': { 'r': 0.75, 't': 0.75 },
        'g_bcbr': { 'v': 12 / 14 },
        'g_ov': { 'mj': 'gacde' },
        'b_c': 'wb',
        'm_c': 'c',
        'm_cd': { 'i': 1, 's': 2 },
        'm_ca': { 'r': 1, 't': 0.684 },
      },
    },
  ],

  '3/4': [
    {
      id: 'cn-wz',
      name: 'Country Waltz',
      description: 'Country rhythm in 3/4 time.',
      genreId: 'cn',
      genreCategoryId: 'cn-n',
      band: {
        'sw': '7:4',
        'g_c': 'bgw',
        'g_ss': { 'r': 0.75, 't': 0.25 },
        'g_bcbr': { 'v': 8 / 14 },
        'g_br': { 'e': 0, 'qeb': 1, 'jf': 'sm' },
        'g_ov': { 'mj': 'gacde', 'gv': 'G' },
        'm_cd': { 'i': 0, 's': 2 },
      },
    },
  ],
};
