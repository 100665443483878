import { strict as assert } from 'assert';
import { EventEmitter } from 'eventemitter3';
import { ReactiveVar } from 'meteor/reactive-var';
import { Tracker } from 'meteor/tracker';
import _ from 'underscore';
import { ClickTrack } from '@/band/ClickTrack';
import { Bass } from '@/band/instruments/bass/Bass';
import { Feet } from '@/band/instruments/feet/Feet';
import { Guitar } from '@/band/instruments/guitar/Guitar';
import { Mandolin } from '@/band/instruments/mandolin/Mandolin';
import type { BandPreset } from '@/band/presets/BandPreset';
import { BandPresets } from '@/band/presets/BandPresets';
import { migrateSerializedBandSettings } from '@/band/settings/migrateSerializedBandSettings';
import { swingOptions } from '@/band/swingOptions';
import { eventTracker } from '@/browser/analytics/eventTracker';
import type { Song } from '@/chart/Song';
import { Conductor } from '@/Conductor';
import { allDevKnobs } from '@/lib/dev-knobs';
import { unique } from '@/utilities/unique';

export type PresetEditorModes = 'edit' | 'rename' | 'delete' | undefined;

function defaultInstrumentEnabledPropsForTimeSignature(
  timeSignature: TimeSignature
): Record<string, unknown> {
  const jig = ['6/8', '9/8'].includes(timeSignature);
  return {
    'g_e': true,
    'b_e': !jig,
    'm_e': !jig,
    'f_e': false,
  };
}

export class Band extends EventEmitter {
  readonly song: Song;

  constructor(song: Song) {
    super();
    this.song = song;
    Object.values(this.instruments).forEach((instrument) => {
      instrument.on('updatedData', this.updateSerialized.bind(this));
    });
  }

  readonly instruments = {
    guitar: new Guitar(),
    bass: new Bass(),
    mandolin: new Mandolin(),
    feet: new Feet(),
  } as const;

  reactiveInstruments() {
    this._settingsDep.depend();
    return this.instruments;
  }

  // _preset and _customSettings are mutually exclusive... UNLESS you're editing a preset
  private _preset?: BandPreset;
  private _previousPreset?: BandPreset;
  private _customSettings?: Record<string, unknown>;
  private _previousCustomSettings?: Record<string, unknown>;
  private _didUndo = false;
  private _settingsToRestoreOnEditCancel?: Record<string, unknown>;
  private _settingsDep = new Tracker.Dependency();
  private _presetEditorMode = new ReactiveVar<PresetEditorModes>(undefined);
  private _presetEditorName = new ReactiveVar<string>('');
  private _presetWasDiscarded = new ReactiveVar<boolean>(false);

  private _computedSettings: Record<string, unknown> = {};

  authorPreset?: BandPreset;

  reset() {
    this._presetEditorMode.set(undefined);
    this._preset = undefined;
    this._previousPreset = undefined;
    this._presetWasDiscarded.set(false);
    this._customSettings = undefined;
    this._previousCustomSettings = undefined;
    this._didUndo = false;
    this._settingsToRestoreOnEditCancel = undefined;
    this.processChanges();
  }

  loadPreset(preset: BandPreset) {
    this._presetEditorMode.set(undefined);
    this._preset = preset.timeSignature == this.timeSignature() ? preset : undefined;
    this._presetWasDiscarded.set(false);
    this._customSettings = undefined;
    this._settingsToRestoreOnEditCancel = undefined;
    this.processChanges();
  }

  loadSettings(serializedSettings?: Record<string, unknown>) {
    this._presetEditorMode.set(undefined);
    this._customSettings = serializedSettings
      ? migrateSerializedBandSettings(serializedSettings)
      : undefined;
    this._settingsToRestoreOnEditCancel = undefined;
    this._preset = undefined;
    this._presetWasDiscarded.set(false);
    // TODO: make sure settings are compatible with current time signature (maybe song owner changed it under your nose...)
    this.processChanges();
  }

  setPreset(preset: BandPreset) {
    if (this._customSettings) {
      this._previousCustomSettings = JSON.parse(JSON.stringify(this._customSettings)) as Record<
        string,
        unknown
      >;
    }
    this.loadPreset(preset);
    this._didUndo = false;
    this.persist();
  }

  loadPreviousCustomBand() {
    if (this._preset) this._previousPreset = this._preset;
    this._didUndo = !this._didUndo;
    this.loadSettings(this._previousCustomSettings);
    this.persist();
  }

  loadPreviousPreset() {
    const previousPreset = this.previousPreset();
    if (!previousPreset) return;
    if (this._customSettings) {
      this._previousCustomSettings = JSON.parse(JSON.stringify(this._customSettings)) as Record<
        string,
        unknown
      >;
    }
    this.loadPreset(previousPreset);
    this._didUndo = !this._didUndo;
    this.persist();
  }

  presetMode(): PresetEditorModes {
    return this._presetEditorMode.get();
  }

  editingPreviousPreset(): boolean {
    return this._presetEditorMode.get() == 'edit' && !!this._settingsToRestoreOnEditCancel;
  }

  didUndo(): boolean {
    this._settingsDep.depend();
    return this._didUndo;
  }

  enterNormalPresetMode() {
    this._presetEditorMode.set(undefined);
    if (this._settingsToRestoreOnEditCancel) {
      this._preset = undefined;
      this._customSettings = this._settingsToRestoreOnEditCancel;
    } else if (this._preset && this._customSettings) {
      this._customSettings = undefined;
    }
    this.processChanges();
    eventTracker.bandPresetModeNormal();
  }

  enterEditPresetMode(preset: BandPreset) {
    this.loadPreset(preset);
    this._presetEditorMode.set('edit');
    this._presetEditorName.set(preset.name);
    eventTracker.bandPresetModeEdit({ presetId: preset.id, name: preset.name });
  }

  enterEditPresetModeWithCurrentSettings(preset: BandPreset) {
    const settings = this.serializeSettings();
    this.loadPreset(preset);
    this._presetEditorMode.set('edit');
    this._presetEditorName.set(preset.name);
    this.updateSerialized(settings);
    this._settingsToRestoreOnEditCancel = settings;
    eventTracker.bandPresetModeEdit({ presetId: preset.id, name: preset.name });
  }

  enterDeletePresetMode() {
    this._presetEditorMode.set('delete');
    // eventTracker.bandPresetModeEdit({ presetId: preset.id, name: preset.name });
  }

  enterRenamePresetMode() {
    this._presetEditorMode.set('rename');
    this._presetEditorName.set(this.preset?.name);
    // eventTracker.bandPresetModeEdit({ presetId: preset.id, name: preset.name });
  }

  preset() {
    this._settingsDep.depend();
    return this._preset;
  }

  previousPreset() {
    this._settingsDep.depend();
    return this._previousPreset;
  }

  previousCustomSettings() {
    this._settingsDep.depend();
    return this._previousCustomSettings;
  }

  presetWasDiscarded() {
    return this._presetWasDiscarded.get();
  }

  timeSignature() {
    return this.song.timeSignature();
  }

  currentKey() {
    return this.song.keyHeard();
  }

  private fallBackToDefaultPresetIfNecessary() {
    // Set preset to default if no custom settings and no preset; we always want one or the other
    if (
      !this._preset &&
      Object.keys(this._customSettings ?? {}).length == 0 &&
      !this._presetEditorMode.get()
    ) {
      this._preset = BandPresets.defaultForTimeSignature(this.timeSignature());
      this._customSettings = undefined;
    }
  }

  private processChanges() {
    this.fallBackToDefaultPresetIfNecessary();

    const defaults = defaultInstrumentEnabledPropsForTimeSignature(this.timeSignature());

    const serializedKeys = unique([
      ...Object.keys(this._customSettings ?? {}),
      ...Object.keys(this._preset?.band ?? {}),
      ...Object.keys(defaults),
    ]);

    // Note: ...destructuring doesn't work because undefined values overwrite
    this._computedSettings = serializedKeys.reduce<Record<string, unknown>>((acc, key) => {
      acc[key] = this._customSettings?.[key] ?? this._preset?.band[key] ?? defaults[key];
      return acc;
    }, {});

    Object.values(this.instruments).forEach((instrument) =>
      instrument.loadSerializedData(this._computedSettings)
    );

    const swingId = this._computedSettings.sw as keyof typeof swingOptions | undefined;
    this._swing.set(swingId ? swingOptions[swingId] : undefined);

    this._settingsDep.changed();
  }

  //#region === Change settings ===

  updateSerialized(data: Record<string, unknown>) {
    this.prepareToEditCustomSettings();
    assert(this._customSettings, 'Something went very wrong in Band.updateSerialized');

    for (const key of Object.keys(data)) {
      this._customSettings[key] = data[key];

      if (typeof data[key] == 'undefined' || data[key] === null) delete this._customSettings[key];
    }

    this.processChanges();

    this._didUndo = false;

    if (!this._presetEditorMode.get()) this.persist();
  }

  prepareToEditCustomSettings() {
    if (!this._customSettings) {
      if (this._presetEditorMode.get() == 'edit') {
        this._customSettings = {};
      } else {
        if (this._preset && !this._preset.default) this._presetWasDiscarded.set(true);
        this._customSettings = { ...this._preset?.band };
        this._previousPreset = this._preset;
        this._preset = undefined;
      }
    }
  }

  currentPresetWasDeleted() {
    this._previousPreset = undefined;
    this._previousCustomSettings = undefined;
    // Clear preset but keep actual settings
    this.loadSettings({ ...this.serializeSettings() });
  }

  //#endregion

  //#region === Click track ===

  clickTrack = new ClickTrack(this);

  //#endregion

  protected _swing = new ReactiveVar<SwingOption | undefined>(undefined);
  swing() {
    return this._swing.get() ?? swingOptions['5:4'];
  }
  setSwing(value: SwingOption) {
    if (value.id == this._computedSettings.sw) return;
    this._swing.set(value);
    this.updateSerialized({ 'sw': value.id });
  }

  activeInstruments(): InstrumentId[] {
    return Object.values(this.instruments)
      .filter((inst) => inst.enabled())
      .map((inst) => inst.id);
  }

  inactiveInstruments(): InstrumentId[] {
    return Object.values(this.instruments)
      .filter((inst) => !inst.enabled())
      .map((inst) => inst.id);
  }

  persist() {
    if (this._presetEditorMode.get()) return;
    const settings = this.serializeSettings();

    // Remove any keys with undefined or empty objects (rpcSetSUA handles unsetting)
    for (const key of Object.keys(settings)) {
      if (
        typeof settings[key] === 'undefined' ||
        (typeof settings[key] === 'object' && Object.keys(settings[key] || {}).length === 0)
      )
        settings[key] = null;
    }

    if (this._preset && this._preset === this.authorPreset) {
      this.emit('persistPlease', { band: null, presetId: null });
    } else {
      const omitPresetId = this._preset?.default && !this.authorPreset;
      this.emit('persistPlease', {
        band: Object.keys(settings).length > 0 ? settings : null,
        presetId: omitPresetId ? null : (this._preset?.id ?? null),
      });
    }
  }

  serializeSettings(): Record<string, unknown> {
    const defaults = defaultInstrumentEnabledPropsForTimeSignature(this.timeSignature());

    const customSettingsKeys = Object.keys(this._customSettings ?? {});
    const presetKeys = Object.keys(this._preset?.band ?? {});
    const keysToSerialize = unique([...customSettingsKeys, ...presetKeys]);

    return keysToSerialize.reduce<Record<string, unknown>>((acc, key) => {
      const value = this._customSettings?.[key] ?? this._preset?.band[key];
      if (value !== defaults[key]) acc[key] = value;
      return acc;
    }, {});
  }

  presetEditorName() {
    return this._presetEditorName.get();
  }

  setPresetEditorName(name: string) {
    this._presetEditorName.set(name);
  }

  savePreset() {
    assert(this._preset, 'Trying to save preset, but none set');
    const name = this._presetEditorName.get();
    const changesToName = name && name != this._preset.name;
    const changesToBand =
      JSON.stringify(this.serializeSettings()) != JSON.stringify(this._preset.band);
    if (changesToName) {
      BandPresets.updateAndRename(this._preset, this.serializeSettings(), name);
    } else if (changesToBand) {
      BandPresets.update(this._preset, this.serializeSettings());
    }
    const preset = BandPresets.findById(this._preset.id || '');
    assert(preset, 'Preset disappeared even though we just saved it!');
    this.setPreset(preset);
    if (!changesToName && !changesToBand) return;
    eventTracker.bandPresetSave({ presetId: preset.id, name: preset.name });
  }

  updatePresetName(name: string) {
    assert(this._preset, 'Trying to save preset, but none set');
    if (name && name != this._preset.name) {
      BandPresets.rename(this._preset, name);
      const preset = BandPresets.findById(this._preset.id || '');
      assert(preset, 'Preset disappeared even though we just saved it!');
      this.setPreset(preset);
      eventTracker.bandPresetSave({ presetId: preset.id, name: preset.name });
    }
    this.enterNormalPresetMode();
  }

  saveAsNewPreset(name?: string) {
    name ??= this._presetEditorName.get();
    assert(name, 'Tried to save new preset without a name');
    const newPreset = BandPresets.create({
      name,
      timeSignature: this.timeSignature(),
      band: this.serializeSettings(),
    });
    this.setPreset(newPreset);
    eventTracker.bandPresetCreate({ presetId: newPreset.id, name: newPreset.name });
  }

  regeneratePlans() {
    // reactive dependencies
    this._settingsDep.depend();
    for (const instrument of Object.values(this.instruments)) void instrument.noEmbellishments();
    this.clickTrack.depend();
    void this.song.reactiveLinearized();
    void Conductor.beatDuration();
    void allDevKnobs.get();

    this.throttledRegeneratePlans();
  }

  private throttledRegeneratePlans = _.throttle(() => {
    const measures = this.song.reactiveLinearized().measures;

    // first we clear any existing plans or player instructions
    for (const measure of measures) {
      for (const cell of measure.cells) {
        cell.plans = {};
      }
      for (const beat of measure.beats) {
        beat.playerInstructions = {};
        beat.plans = {};
      }
    }

    const tpm = 60 / Conductor.beatDuration(); // need to do it this way for medleys

    Object.values(this.instruments).forEach((instrument) => {
      instrument.generatePlans(measures, { tpm });
    });

    if (this.clickTrack.enabled())
      this.clickTrack.addClickToMeasures(measures, { tpm, swing: this.instruments.guitar.swing() });
  }, 250);
}
