import type { SampleDefinition } from '@/audio/engine/base/Sample';

function definePackage(packageId: string, ids: string[], preroll = 0.02): SampleDefinition[] {
  return ids.map((id) => {
    return { id, packageId, preroll };
  });
}

function splitFileList(files: string): string[] {
  return files.split('\n').filter((line) => line.length > 0);
}

export default [
  ...definePackage(
    'feet_2022-11',
    splitFileList(`
toeL-01
toeL-02
toeL-03
toeL-04
toeL-05
toeL-06
toeL-07
toeR-01
toeR-02
toeR-03
toeR-04
toeR-05
toeR-06
flam-01
flam-02
flam-03
flam-04
flam-05
bigflam-01
bigflam-02
stomp-01
stomp-02
stomp-03
stomp-04
stomp-05
`),
    0.05
  ),

  // that's all!
];
