import { z } from 'zod';
import type { InstrumentSetting } from '@/band/instruments/InstrumentSetting';
import { bounded } from '@/utilities/bounded';
import cloneJSON from '@/utilities/cloneJSON';

const serializedSchema = z.object({
  m: z.enum(['r', 'a', 'w']).optional(), // mode: root-only, alternating, walking
  ro: z.enum(['l', 'h', 'a']).optional(), // root octave: low, high, alternating
  ao: z.enum(['l', 'm', 'h', 'r']).optional(), // alternating octave: low, medium, high, random
  l: z.number().optional(), // leading note frequency (0-1)
});

type Serialization = z.infer<typeof serializedSchema>;

const abbreviations = {
  'l': 'low',
  'm': 'middle',
  'h': 'high',
  'r': 'random',
  'a': 'alternating',
} as const;

const deabbreviate = (abbreviated: (typeof abbreviations)[keyof typeof abbreviations]) =>
  Object.entries(abbreviations).find(([k, v]) => v === abbreviated)?.[0];

export class BassOnbeatNotesSetting implements InstrumentSetting<Serialization> {
  readonly rootOnly: boolean;
  readonly altBass: boolean;
  readonly walking: boolean;
  readonly leadingNoteFrequency: number;
  private readonly mode: 'r' | 'a' | 'w';
  readonly rootOctaves: 'low' | 'high' | 'alternating';
  readonly alternatingOctaves: 'low' | 'middle' | 'high' | 'random';

  octaveForBeat(alternate: boolean): 0 | 1 | 2 {
    if (this.rootOnly) {
      return this.rootOctaves === 'low' ? 0 : this.rootOctaves === 'high' ? 2 : alternate ? 2 : 0;
    } else {
      return this.alternatingOctaves === 'low'
        ? 0
        : this.alternatingOctaves === 'middle'
          ? 1
          : this.alternatingOctaves === 'high'
            ? 2
            : (Math.floor(Math.random() * 3) as 0 | 1 | 2);
    }
  }

  get leadingNotesPerMeasure() {
    if (this.leadingNoteFrequency === 0) return 0;
    return Math.pow(2, this.leadingNoteFrequency * 4 - 1) / 8;
  }

  constructor(input: unknown = {}) {
    const data = serializedSchema.catch({}).parse(input);
    this.rootOnly = data.m === 'r';
    this.walking = data.m === 'w';
    this.altBass = data.m !== 'r';
    this.mode = this.rootOnly ? 'r' : this.walking ? 'w' : 'a';
    this.rootOctaves = abbreviations[data.ro ?? 'l'];
    this.alternatingOctaves = abbreviations[data.ao ?? 'm'];
    this.leadingNoteFrequency = bounded(data.l ?? 0, 0, 1);
  }

  serialize(): Serialization {
    const ro = deabbreviate(this.rootOctaves) as Serialization['ro'] | undefined;
    const ao = deabbreviate(this.alternatingOctaves) as Serialization['ao'] | undefined;
    return cloneJSON({
      m: this.mode == 'a' ? undefined : this.mode,
      ro: ro == 'l' ? undefined : ro,
      ao: ao == 'm' ? undefined : ao,
      l: this.leadingNoteFrequency || undefined,
    });
  }

  closeTo(serialized?: Serialization): boolean {
    const other = new BassOnbeatNotesSetting(serialized);
    return (
      this.mode === other.mode &&
      this.leadingNoteFrequency === other.leadingNoteFrequency &&
      this.rootOctaves === other.rootOctaves &&
      this.alternatingOctaves === other.alternatingOctaves
    );
  }

  withRootOnly() {
    return new BassOnbeatNotesSetting({ ...this.serialize(), m: 'r' });
  }

  withAlternatingBass() {
    return new BassOnbeatNotesSetting({ ...this.serialize(), m: 'a' });
  }

  withWalkingBass() {
    return new BassOnbeatNotesSetting({ ...this.serialize(), m: 'w' });
  }

  withRootOctaves(value: typeof this.rootOctaves) {
    return new BassOnbeatNotesSetting({ ...this.serialize(), ro: deabbreviate(value) });
  }

  withAlternatingOctaves(value: typeof this.alternatingOctaves) {
    return new BassOnbeatNotesSetting({ ...this.serialize(), ao: deabbreviate(value) });
  }

  withLeadingNoteFrequency(value: number) {
    return new BassOnbeatNotesSetting({ ...this.serialize(), l: value });
  }
}
