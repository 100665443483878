import EditorSettingsMenu from '@/editor/ui/EditorSettingsMenu.svelte';

Template.editorSettingsModal.helpers({
  EditorSettingsMenu: () => EditorSettingsMenu,
});

Template.editorSettingsModal.events({
  'click .js-close': function () {
    Modal.hide();
  },
});
