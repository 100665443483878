<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { crntPreferences } from '@/client/crntPreferences.svelte';
  import Icon from '@/ui/icons/Icon.svelte';

  const tabs = [
    {
      id: 'basics',
      label: 'Moving/Selecting',
    },
    {
      id: 'chords',
      label: 'Typing Chords',
    },
    {
      id: 'chart',
      label: 'Effects & Chart',
    },
    {
      id: 'clipboard',
      label: 'Editor Commands',
    },
  ] as const;
  let activeTab: string = $state(tabs[0].id);

  const cmdCtrl =
    /^Mac/.test(navigator.platform) || /iP(hone|ad|od)/.test(navigator.userAgent) ? '⌘' : 'Ctrl';

  let chordSystem = $derived(crntPreferences().chordSystem);

  const dispatchEvent = createEventDispatcher<{ close: void }>();
</script>

<div class="__container">
  <div class="__tabs flex" role="tablist">
    <div class="__tabs_main flex flex-1 justify-evenly">
      {#each tabs as tab (tab.id)}
        <button
          class="__tab z-10 border-x border-transparent px-3 py-2 text-sm font-medium text-gray-600 decoration-muted-200 decoration-[1.5px] underline-offset-4 hover:bg-primary-50 hover:text-primary-800 aria-selected:border-editToolbarSeparator aria-selected:bg-editToolbarBg aria-selected:text-primary-600 aria-selected:underline"
          onclick={() => (activeTab = tab.id)}
          role="tab"
          aria-selected={activeTab == tab.id}
          aria-controls="editor_{tab.id}_tabpanel"
        >
          {tab.label}
        </button>
      {/each}
    </div>
    <button
      class="__closeButton z-10 justify-self-end px-3 py-2 text-sm font-medium text-gray-600 hover:bg-primary-50 hover:text-primary-800"
      onclick={() => dispatchEvent('close')}
    >
      <Icon id="close-big" size={12} />
    </button>
  </div>

  <div class="h-[10rem] overflow-y-auto">
    <div hidden={activeTab != 'basics'} id="editor_basics_tabpanel" role="tabpanel" tabindex="-1">
      <div class="__columns">
        <div class="__column">
          <h3 class="__sectionHeading">Adding &amp; Removing</h3>

          <div class="__shortcutRow">
            <div class="__label">Remove</div>
            <div class="__keys">
              <kbd>backspace</kbd> / <kbd>delete</kbd>
            </div>
          </div>

          <div class="__shortcutRow">
            <div class="__label">
              Add/Insert <br /><span class="_muted">
                <!-- (duplicates whatever you’ve selected;
                <a href="#" class="whitespace-nowrap font-medium">tips on how this can be used</a>) -->
                (duplicates whatever you’ve selected)
              </span>
            </div>
            <div class="__keys"><kbd class="min-w-24">spacebar</kbd></div>
          </div>
        </div>

        <div class="__column">
          <h3 class="__sectionHeading">Moving/Selecting</h3>

          <div class="__shortcutRow">
            <div class="__label">Select neighboring cell</div>
            <div class="__keys">
              <!-- <kbd>⇦</kbd><kbd>⇨</kbd>
              <kbd>⇧</kbd><kbd>⇩</kbd> -->
              <kbd>Arrow Keys</kbd>
            </div>
          </div>

          <div class="__shortcutRow">
            <div class="__label">Go to end of line</div>
            <div class="__keys">
              {#if /^Mac/.test(navigator.platform) || /iP(hone|ad|od)/.test(navigator.userAgent)}
                <kbd>{cmdCtrl}</kbd>+<kbd>Arrow Keys</kbd>
              {:else}
                <kbd>Home</kbd> / <kbd>End</kbd>
              {/if}
            </div>
          </div>

          <div class="__shortcutRow">
            <div class="__label">Multi-select</div>
            <div class="__keys">
              <kbd>Shift</kbd>+<kbd>Arrow Keys</kbd>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div hidden={activeTab != 'chords'} id="editor_chords_tabpanel" role="tabpanel" tabindex="-1">
      {#if chordSystem == 'numbers' || chordSystem == 'roman'}
        <div class="__columns _chordsPanel">
          <div class="__column">
            <div class="__shortcutRow">
              <div class="__label whitespace-nowrap">Set chord number</div>
              <div class="__keys"><kbd>1</kbd> – <kbd>7</kbd></div>
            </div>

            <div class="__shortcutRow">
              <div class="__label">Sharp/Flat</div>
              <div class="__keys"><kbd>b</kbd> / <kbd>#</kbd></div>
            </div>

            <div class="__shortcutRow">
              <div class="__label">
                Set Bass Note <span class="_muted">(slash&nbsp;chord)</span>
              </div>
              <div class="__keys"><kbd>/</kbd></div>
            </div>

            <div class="__shortcutRow">
              <div class="__label whitespace-nowrap">
                Set chord letter<br /><span class="_muted">(hold Shift for B)</span>
              </div>
              <div class="__keys"><kbd>A</kbd> - <kbd>G</kbd></div>
            </div>
          </div>

          <div class="__column whitespace-nowrap">
            <div class="__shortcutRow">
              <div class="__label">Minor</div>
              <div class="__keys"><kbd>m</kbd></div>
            </div>

            <div class="__shortcutRow">
              <div class="__label">Maj7 (Δ)</div>
              <div class="__keys"><kbd>^</kbd></div>
            </div>

            <div class="__shortcutRow">
              <div class="__label">dim</div>
              <div class="__keys">(letter) <kbd>o</kbd></div>
            </div>

            <div class="__shortcutRow">
              <div class="__label">m7b5 / ø</div>
              <div class="__keys">(zero) <kbd>0</kbd></div>
            </div>
          </div>

          <div class="__column whitespace-nowrap">
            <div class="text-sm text-gray-600">
              For other chord types, <br />use the Chords menu.
            </div>
          </div>
        </div>
      {:else}
        <div class="__columns _chordsPanel">
          <div class="__column">
            <div class="__shortcutRow">
              <div class="__label whitespace-nowrap">
                Set chord letter<br /><span class="_muted">(for “B”, tap twice)</span>
              </div>
              <div class="__keys"><kbd>A</kbd> - <kbd>G</kbd></div>
            </div>

            <div class="__shortcutRow">
              <div class="__label">Sharp/Flat</div>
              <div class="__keys">
                <kbd>b</kbd> /
                <kbd>#</kbd>
              </div>
            </div>

            <div class="__shortcutRow">
              <div class="__label">
                Set Bass Note <span class="_muted">(slash&nbsp;chord)</span>
              </div>
              <div class="__keys"><kbd>/</kbd></div>
            </div>
          </div>

          <div class="__column whitespace-nowrap">
            <div class="__shortcutRow">
              <div class="__label">Minor</div>
              <div class="__keys"><kbd>m</kbd></div>
            </div>

            <div class="__shortcutRow">
              <div class="__label">Maj7 (Δ)</div>
              <div class="__keys"><kbd>^</kbd></div>
            </div>

            <div class="__shortcutRow">
              <div class="__label">6</div>
              <div class="__keys"><kbd>6</kbd></div>
            </div>

            <div class="__shortcutRow">
              <div class="__label">Power chord</div>
              <div class="__keys"><kbd>5</kbd></div>
            </div>
          </div>

          <div class="__column">
            <div class="__shortcutRow">
              <div class="__label">7</div>
              <div class="__keys"><kbd>7</kbd></div>
            </div>

            <div class="__shortcutRow">
              <div class="__label">m7</div>
              <div class="__keys"><kbd>m</kbd> <kbd>7</kbd></div>
            </div>

            <div class="__shortcutRow">
              <div class="__label">7sus</div>
              <div class="__keys"><kbd>7</kbd> <kbd>s</kbd></div>
            </div>

            <div class="__shortcutRow">
              <div class="__label">9</div>
              <div class="__keys"><kbd>9</kbd></div>
            </div>
          </div>

          <div class="__column whitespace-nowrap">
            <div class="__shortcutRow">
              <div class="__label">dim</div>
              <div class="__keys">(letter) <kbd>o</kbd></div>
            </div>

            <div class="__shortcutRow">
              <div class="__label">m7b5 / ø</div>
              <div class="__keys">(zero) <kbd>0</kbd></div>
            </div>

            <div class="__shortcutRow">
              <div class="__label">sus4</div>
              <div class="__keys"><kbd>s</kbd> or <kbd>4</kbd></div>
            </div>

            <div class="__shortcutRow">
              <div class="__label">sus2</div>
              <div class="__keys"><kbd>2</kbd></div>
            </div>
          </div>
        </div>
      {/if}
    </div>

    <div hidden={activeTab != 'chart'} id="editor_chart_tabpanel" role="tabpanel" tabindex="-1">
      <div class="__columns">
        <div class="__column">
          <h3 class="__sectionHeading">Effects</h3>
          <div class="__shortcutRow">
            <div class="__label">
              Cycle Through Effects<br />
              <span class="_muted">Rest → Stop → Diamond → None</span>
            </div>
            <div class="__keys"><kbd>X</kbd></div>
          </div>
        </div>

        <div class="__column">
          <h3 class="__sectionHeading">Chart</h3>

          <div class="__shortcutRow">
            <div class="__label">Toggle "Half-Measure"</div>
            <div class="__keys"><kbd>H</kbd></div>
          </div>

          <div class="__shortcutRow">
            <div class="__label">Set Line Length</div>
            <div class="__keys"><kbd>L</kbd></div>
          </div>

          <div class="__shortcutRow">
            <div class="__label">Start New Section</div>
            <div class="__keys"><kbd>{cmdCtrl}</kbd><kbd>Enter</kbd></div>
          </div>
        </div>
      </div>
    </div>

    <div
      hidden={activeTab != 'clipboard'}
      id="editor_clipboard_tabpanel"
      role="tabpanel"
      tabindex="-1"
    >
      <div class="__columns _editorCommands">
        <div class="__column">
          <h3 class="__sectionHeading">Clipboard</h3>

          <div class="__shortcutRow">
            <div class="__label">Cut</div>
            <div class="__keys"><kbd>{cmdCtrl}</kbd><kbd>X</kbd></div>
          </div>

          <div class="__shortcutRow">
            <div class="__label">Copy</div>
            <div class="__keys"><kbd>{cmdCtrl}</kbd><kbd>C</kbd></div>
          </div>

          <div class="__shortcutRow">
            <div class="__label">Paste</div>
            <div class="__keys"><kbd>{cmdCtrl}</kbd><kbd>V</kbd></div>
          </div>
        </div>

        <div class="__column">
          <h3 class="__sectionHeading">History</h3>

          <div class="__shortcutRow">
            <div class="__label">Undo</div>
            <div class="__keys"><kbd>{cmdCtrl}</kbd><kbd>Z</kbd></div>
          </div>

          <div class="__shortcutRow">
            <div class="__label">Redo</div>
            <div class="__keys"><kbd>{cmdCtrl}</kbd><kbd>Shift</kbd><kbd>Z</kbd></div>
          </div>
        </div>

        <div class="__column">
          <h3 class="__sectionHeading">Playback</h3>

          <div class="__shortcutRow">
            <div class="__label">Play/Stop</div>
            <div class="__keys">
              <kbd>Enter</kbd>
            </div>
          </div>

          <div class="__shortcutRow">
            <div class="__label">Change&nbsp;Tempo</div>
            <div class="__keys"><kbd>+</kbd> / <kbd>–</kbd></div>
          </div>
        </div>

        <div class="__column whitespace-nowrap">
          <h3 class="__sectionHeading">More Actions</h3>

          <div class="__shortcutRow">
            <div class="__label">Show Shortcuts</div>
            <div class="__keys"><kbd>?</kbd></div>
          </div>

          <div class="__shortcutRow">
            <div class="__label">Select All</div>
            <div class="__keys"><kbd>{cmdCtrl}</kbd><kbd>A</kbd></div>
          </div>

          <div class="__shortcutRow">
            <div class="__label">Save &amp; Exit</div>
            <div class="__keys"><kbd>{cmdCtrl}</kbd><kbd>S</kbd></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  .__container {
    width: 100vw;
    width: 100dvw;
    @media screen and (min-width: 820px) {
      max-width: 100%;
      width: 801px;
      border-color: var(--editToolbarSeparator);
      border-width: 0 1px;
    }
  }

  .__tabs {
    position: relative;
    background: hsl(206, 20%, 97%);

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: var(--editToolbarSeparator);
    }
  }

  @media (max-width: 580px) {
    .__closeButton {
      display: none;
    }
  }

  @media (max-width: 48rem) {
    .__tabs_main {
      justify-stretch: stretch;
    }
    .__tab {
      flex-grow: 1;
    }
  }

  .__columns {
    display: flex;
    width: 100%;
    padding: 1rem 0 0.5rem 0;
  }

  .__column {
    flex: 1 1 100%;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    position: relative;

    ._editorCommands & {
      flex: 1 1 100%;
    }

    ._chordsPanel & {
      flex: 1 1 auto;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 1px;
      background: var(--editToolbarSeparator);
    }

    &:last-child::after {
      content: none;
    }
  }

  .__shortcutRow {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .__label {
    flex-grow: 1;
    font-weight: 500;
    font-size: 14px;
    margin-right: 1.5rem;
    @media screen and (max-width: 700px) {
      margin-right: 0.75rem;
    }

    ._muted {
      font-weight: normal;
      color: var(--gray-500);
    }
  }

  .__sectionHeading {
    font-weight: 500;
    font-size: 14px;
    color: var(--gray-500);
  }

  .__keys {
    color: var(--gray-500);
    font-size: 14px;
    white-space: nowrap;

    kbd {
      padding: 0.25rem 0.375rem;
      color: black;
      border-radius: 0.25rem;
      border: 1px solid var(--muted-200);
      background: var(--muted-50);
      min-width: 23px;
      box-shadow: none;
      font-size: 13px;
      font-weight: 500;
    }
  }

  ._sharp {
    width: 0.5em;
    height: 0.85em;
  }
  ._flat {
    width: 0.45em;
    height: 0.95em;
  }
</style>
