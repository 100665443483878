import type { SongListItemRecord, SongListRecord } from '@/collections/SongListsCollection';
import { makeSongNameForSorting } from '@/library/makeSongNameForSorting';

const songListSortFunction = (a: SongListItemRecord, b: SongListItemRecord) => {
  return makeSongNameForSorting(a.name).localeCompare(makeSongNameForSorting(b.name));
};

/**
 * Sorts a song list in place according to the list's sortMethod property
 * @param list - The list to be sorted *in place*
 */
export function sortList(list: SongListRecord): void {
  if (list && (list.sortMethod || 'a') == 'a') {
    list.songs.sort(songListSortFunction);
  }
}
