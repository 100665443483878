import { putArticleAtEnd } from '@/library/putArticleAtEnd';

export function makeSongNameForSorting(name: string): string {
  return putArticleAtEnd(name)
    .toLocaleLowerCase()
    .replace(
      /\d+/g,
      (digits) => `${String.fromCodePoint(Math.min(92, digits.length) + 33)} ${digits}`
    );
}
