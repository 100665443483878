<script lang="ts">
  import MultiPanelModalTopNav from '@/band/ui/components/MultiPanelModalTopNav.svelte';
  import EditorSettingsRootScreen from '@/editor/ui/EditorSettingsRootScreen.svelte';
  import MultiPanelModal from '@/ui/settings/MultiPanelModal.svelte';
  import { MultiPanelModalController } from '@/ui/settings/MultiPanelModalController.svelte';

  // This component is the root of the menu, so it creates the context.
  const modalController = MultiPanelModalController.create({
    component: EditorSettingsRootScreen,
  });
</script>

<MultiPanelModal {modalController} --panelMinWidth="17rem" --panelWidth="20rem">
  {#snippet topnav()}
    <MultiPanelModalTopNav />
  {/snippet}
</MultiPanelModal>
