import { Meteor } from 'meteor/meteor';
import { ReactiveVar } from 'meteor/reactive-var';

declare const Package: any;

const hasOwn = Object.prototype.hasOwnProperty;

export default class ReloadableReactiveVar<Type> extends ReactiveVar<Type> {
  static _varsToMigrate: Record<string, ReloadableReactiveVar<unknown>> = {}; // varName -> ReloadableReactiveVar
  static _migratedVarsData: Record<string, any> = {}; // name -> value

  varName?: string;

  declare curValue; // inherited private API

  constructor(
    varName = '',
    initialValue: Type,
    equalsFunc?: (oldValue: Type, newValue: Type) => boolean
  ) {
    super(initialValue, equalsFunc);
    if (varName) {
      this.varName = varName;
      // Only run migration logic on client, it will cause
      // duplicate name errors on server during reloads.
      // _registerVarForMigrate will throw an error on duplicate name.
      Meteor.isClient && ReloadableReactiveVar._registerVarForMigrate(varName, this);

      if (Meteor.isClient && hasOwn.call(ReloadableReactiveVar._migratedVarsData, varName)) {
        const migratedValue = ReloadableReactiveVar._migratedVarsData[varName];

        delete ReloadableReactiveVar._migratedVarsData[varName];
        this.curValue = migratedValue;
      }
    }
  }

  static _registerVarForMigrate(varName: string, varObj: ReloadableReactiveVar<unknown>) {
    if (hasOwn.call(ReloadableReactiveVar._varsToMigrate, varName))
      throw new Error('Duplicate ReloadableReactiveVar name: ' + varName);

    ReloadableReactiveVar._varsToMigrate[varName] = varObj;
  }
}

if (Meteor.isClient && Package.reload) {
  // Put old migrated data into ReloadableReactiveVar._migratedVarsData,
  // where it can be accessed by ReloadableReactiveVar._loadMigratedVar.
  const migrationData = Package.reload.Reload._migrationData('reloadable-reactive-var');
  if (migrationData && migrationData.values)
    ReloadableReactiveVar._migratedVarsData = migrationData.values;

  // On migration, assemble the data from all the dicts that have been registered.
  Package.reload.Reload._onMigrate('reloadable-reactive-var', () => {
    const varsToMigrate = ReloadableReactiveVar._varsToMigrate;
    const valuesToMigrate: Record<string, any> = {};

    Object.keys(varsToMigrate).forEach(
      (varName) => (valuesToMigrate[varName] = varsToMigrate[varName]?.curValue)
    );

    return [true, { values: valuesToMigrate }];
  });
}
