import type { AudioPackage } from '@/audio/samples/AudioPackage';
import { fetchAudioPackageFromUrl } from '@/audio/samples/fetchAudioPackageFromUrl';
import { LazyMap } from '@/lib/LazyMap';

const filePackageMap = new LazyMap<string, AudioPackage>();
const state = {
  packagesQueued: 0,
  packagesDownloaded: 0,
  packagesFailedToDownload: 0,
};

export default class AudioPackageLoader {
  static async downloadPackages(packages: string[]) {
    return Promise.all(packages.map(this.downloadPackage));
  }

  static async downloadPackage(url: string): Promise<void> {
    state.packagesQueued += 1;
    try {
      const pkg = await fetchAudioPackageFromUrl(url);
      state.packagesDownloaded += 1;
      pkg.fileNames.forEach((filename) => {
        filePackageMap.set(filename, pkg);
      });
    } catch (err) {
      state.packagesFailedToDownload += 1;
      throw err;
    }
  }

  static async getDurationOfSample(id: string): Promise<number> {
    const pkg = await filePackageMap.getLazy(id);
    return pkg.getDuration(id);
  }

  static async getFileForSample(id: string): Promise<{ buffer: ArrayBuffer; format: string }> {
    const pkg = await filePackageMap.getLazy(id);
    return {
      buffer: pkg.getFileBuffer(id),
      format: pkg.format,
    };
  }
}
