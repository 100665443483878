<script lang="ts">
  import { onMount } from 'svelte';
  import { MasterVolume } from '@/audio/mixer/MasterVolume';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import VolumeIcon from '@/ui/icons/VolumeIcon.svelte';

  interface Props {
    noIcon?: boolean;
  }

  let { noIcon = false }: Props = $props();

  let volume: number = $state(1);
  trackerEffect(() => (volume = MasterVolume.get()));

  let volumeRange: HTMLInputElement;
  onMount(() => (volumeRange.value = volume.toString()));

  function rangeChangeHandler() {
    if (volumeRange.value == '0') volumeRange.value = '0.05';
    const val = +volumeRange.value;
    MasterVolume.set(val);
  }
</script>

<div class="IVC_wrap">
  {#if !noIcon}
    <span class="IVC_iconWrap" aria-label="Volume">
      <VolumeIcon {volume} color="hsl(206, 20%, 50%)" />
    </span>
  {/if}

  <input
    type="range"
    min={0}
    aria-valuemin={0}
    max={1}
    aria-valuemax={100}
    bind:this={volumeRange}
    value={volume}
    aria-valuenow={volume * 100}
    aria-valuetext={`${(volume * 100).toFixed(0)} percent`}
    step={0.05}
    style="--val: {volume}; --min: 0.0; --max: 1.0; --fill-color: var(--gray-500);"
    class="styled-range"
    aria-label="master output volume"
    oninput={rangeChangeHandler}
    onchange={rangeChangeHandler}
  />
</div>

<style>
  .IVC_wrap {
    display: flex;
    align-items: center;
  }

  input.styled-range {
    width: 7.125em;
    --track-height: 0.75rem;
    --thumb-size: 2rem;
  }

  .IVC_iconWrap {
    margin-right: 0.5rem;
    line-height: 1;
  }
</style>
