import { MusicLibrary } from '@/library/MusicLibrary';

Template.publicIndexPage.onCreated(() => {
  window.prerenderReady = false;
  MusicLibrary.load();
});

Template.publicIndexPage.onRendered(() => {
  Template.instance().autorun(() => {
    if (MusicLibrary.songs.ready()) {
      Meteor.setTimeout(() => (window.prerenderReady = true), 500);
    }
  });

  $(document.scrollingElement).animate(
    {
      scrollTop: 0,
    },
    300
  );
});
