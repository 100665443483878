import type { InstrumentTimingSetting } from '@/band/instruments/InstrumentTimingSetting';
import { serializedTimingSettingSchema } from '@/band/instruments/InstrumentTimingSetting';
import { rounded } from '@/utilities/rounded';

export class BassTimingSetting implements InstrumentTimingSetting {
  beatAdjustments: number[];

  constructor(input: unknown = []) {
    const data = serializedTimingSettingSchema.catch([]).parse(input);
    this.beatAdjustments = data;
  }

  serialize() {
    return this.beatAdjustments.slice();
  }

  closeTo(serialized?: number[]): boolean {
    const [a, b] = [this.beatAdjustments, serialized ?? []];
    return [0, 1, 2, 3].every((i) => Math.abs((a[i] ?? 0) - (b[i] ?? 0)) < 0.01);
  }

  withAdjustedBeat(index: number, value: number): BassTimingSetting {
    const newBeatAdjustments = this.beatAdjustments.slice();
    newBeatAdjustments[index] = rounded(value, 4);
    return new BassTimingSetting(newBeatAdjustments);
  }
}
