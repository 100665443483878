import { ReactiveVar } from 'meteor/reactive-var';

export class AutoSpeedupState {
  reset(): void {
    this._currentRep.set(0);
  }

  private _startingBpm = new ReactiveVar(0);
  startingBpm(): number {
    return this._startingBpm.get();
  }
  setStartingBpm(value: number | undefined | null): void {
    this._startingBpm.set(value || 0);
  }

  private _currentRep = new ReactiveVar(0);
  currentRep(): number {
    return this._currentRep.get();
  }

  setCurrentRep(value: number): void {
    this._currentRep.set(value);
  }

  incrementCurrentRep(): void {
    this._currentRep.set(this._currentRep.get() + 1);
  }
}
