import { cMajorScale, majorScaleIntervals } from '@/music/music-knowledge';

const noteChromaDictionary = cMajorScale.reduce(
  (dict, note, idx) => {
    dict[note] = majorScaleIntervals[idx] as ZeroThroughEleven;
    dict[`${note}#`] = ((dict[note] + 12 + 1) % 12) as ZeroThroughEleven;
    dict[`${note}b`] = ((dict[note] + 12 - 1) % 12) as ZeroThroughEleven;
    return dict;
  },
  <Record<LetterAndAccidental, ZeroThroughEleven>>{}
);

function chromaForNote(note: LetterAndAccidental): ZeroThroughEleven {
  const chroma = noteChromaDictionary[note];
  if (typeof chroma === 'undefined') throw new Error(`Chroma not found for note "${note}"`);
  return chroma;
}

export default chromaForNote;
