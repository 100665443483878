// These were generated with TonalJS: Scale.get("__ chromatic").notes
// Double-flats and Double-sharps were simplified by hand
const chromaticScaleList = [
  'Cb C Db D Eb Fb F Gb G Ab A Bb',
  'C Db D Eb E F Gb G Ab A Bb B',
  'C# D D# E E# F# G G# A A# B B#',
  'Db D Eb Fb F Gb G Ab A Bb Cb C',
  'D Eb E F F# G Ab A Bb B C C#',
  'D# E E# F# G G# A A# B B# C# D',
  'Eb Fb F Gb G Ab A Bb Cb C Db D',
  'E F F# G G# A Bb B C C# D D#',
  'E# F# G G# A A# B B# C# D D# E',
  'Fb F Gb G Ab A Cbb Cb C Db D Eb',
  'F Gb G Ab A Bb Cb C Db D Eb E',
  'F# G G# A A# B C C# D D# E E#',
  'Gb G Ab A Bb Cb C Db D Eb Fb F',
  'G Ab A Bb B C Db D Eb E F F#',
  'G# A A# B B# C# D D# E E# F# G',
  'Ab A Bb Cb C Db D Eb Fb F Gb G',
  'A Bb B C C# D Eb E F F# G G#',
  'A# B B# C# D D# E E# F# G G# A',
  'Bb Cb C Db D Eb Fb F Gb G Ab A',
  'B C C# D D# E F F# G G# A A#',
  'B# C# D D# E E# F# G G# A A# B',
] as const;

type ScaleLetters = 'C' | 'D' | 'E' | 'F' | 'G' | 'A' | 'B';
type LetterAndAccidental = `${ScaleLetters}${'#' | 'b' | ''}`;

export const chromaticScales = chromaticScaleList
  .map((scale) => scale.split(' '))
  .reduce(
    (dict, scale) => {
      dict[scale[0] as LetterAndAccidental] = scale.map((note) =>
        note.replace(/B#/g, 'C').replace(/E#/g, 'F').replace(/Cb/g, 'B').replace(/Fb/g, 'E')
      ) as LetterAndAccidental[];
      return dict;
    },
    {} as Record<LetterAndAccidental, LetterAndAccidental[]>
  );
