import tippyJs from 'tippy.js';
import UserPreferences from '@/user/UserPreferences';

Template.optionLabel.onRendered(() => {
  const instance = Template.instance();
  tippyJs(instance.find('[data-tippy-content]'), {
    placement: 'bottom',
    delay: [300, 50],
    hideOnClick: false,
    maxWidth: 250,
  });
});

Template.optionLabel.helpers({
  label() {
    const rawLabel = this.label || (this.key && UserPreferences.settings[this.key]?.label);
    if (rawLabel) return rawLabel.replace(/\[(.+)\]/, '<span class="hide-when-small">$1</span>');
  },
  help() {
    if (this.help) return this.help;
    if (this.key) return UserPreferences.settings[this.key].help;
  },
});
