export const mandolinChordDefinitionsArray: [string, string, string?][] = [
  // Major

  ['4224', 'E'],
  ['2245', 'A'],
  ['2455', 'D'],
  ['7523', 'G', 'bluegrass'],
  ['523_', 'C', 'bluegrass'],
  ['7452', 'D', 'bluegrass'],

  // Major with added tones

  ['4244', 'E6'],
  ['2445', 'A6'],

  ['4242', 'E69'],
  ['3333', 'Bb69'],
  ['5255', 'C69'],

  // Major sevenths

  ['4264', 'E^'],
  ['2244', 'A^'],

  ['2224', 'A^9'],
  ['4262', 'E^9'],

  ['2444', 'A^13'],
  ['4242', 'E^13'],

  // Dominant sevenths

  ['4254', 'E7'],
  ['2243', 'A7'],
  ['3233', 'C7'],
  ['732_', 'G7'],
  ['4353', 'G7', 'bluegrass'],
  ['323_', 'C7', 'bluegrass'],

  ['2223', 'A9'],
  ['4252', 'E9'],

  ['7333', 'G11'],
  ['2253', 'A11'],

  ['2222', 'A13'],
  ['4242', 'E13'],

  // Suspended dominants

  ['4255', 'E7s'],
  ['7333', 'G7s'],
  ['2253', 'A7s'],

  ['4255', 'E9s'],
  ['7733', 'G9s'],
  ['2253', 'A9s'], // or 2223 works as well

  ['4245', 'E13s'],
  ['7733', 'G13s'],
  ['2252', 'A13s'],

  // Minor

  ['3112', 'Ebm'],
  ['1124', 'Abm'],
  ['124_', 'Dbm'],

  // Minor with added tones

  ['3132', 'Ebm6'],
  ['1324', 'Abm6'],

  ['3131', 'Ebm69'],
  ['1321', 'Abm69'],

  ['_122', 'Ebmb6'],
  ['1224', 'Abmb6'],

  // Minor sevenths

  ['314_', 'Ebm7'],
  // ['142_', 'Abm7'],
  ['1122', 'Abm7'],

  ['3141', 'Ebm9'],
  ['1114', 'Abm9'],

  ['3144', 'Ebm11'],
  ['1122', 'Abm11'],

  // Minor major-sevenths

  ['3152', 'Ebm^'],
  ['1123', 'Abm^'],

  ['3151', 'Ebm^9'],
  ['1113', 'Abm^9'],

  // Diminished

  ['324_', 'Bbo'],
  ['324_', 'Dbo'],
  ['324_', 'Eo'],
  ['324_', 'Go'],

  ['324_', 'Bb0'],
  ['325_', 'E0'],

  // Augmented

  ['234_', 'Db+'],
  ['234_', 'A+'],

  // Power & sus

  ['311_', 'Eb5'],
  ['3311', 'Bb5'],

  ['225_', 'A4'],
  ['255_', 'D4'],

  ['222_', 'A2'],
  ['225_', 'D2'],

  // Basic altered dominants

  ['4251', 'E7b9'],
  ['2213', 'A7b9'],
  ['3243', 'C7b9'],

  ['4253', 'E7#9'],
  ['2233', 'A7#9'],

  ['3254', 'E7b5'],
  ['2143', 'A7b5'],
  ['5454', 'D7b5'],

  ['3254', 'E7#11'],
  ['2143', 'A7#11'],
  ['5454', 'D7#11'],

  ['1234', 'E7#5'],
  ['2343', 'A7#5'],

  ['1234', 'E7b13'],
  ['2343', 'A7b13'],

  // DRONING chords (for Celtic, old-time... open strings included)
];
