<script lang="ts">
  import { assertObject } from '@sindresorhus/is';
  import PanelHeading from '@/band/ui/components/PanelHeading.svelte';
  import PanelModalLayout from '@/band/ui/panels/PanelModalLayout.svelte';
  import type { Section } from '@/chart/Section.svelte';
  import { sectionTypes } from '@/chart/sectionTypes';
  import type { Song } from '@/chart/Song';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import HelpCircleTooltip from '@/ui/HelpCircleTooltip.svelte';
  import ChecklistCheck from '@/ui/icons/ChecklistCheck.svelte';

  interface Props {
    song: Song;
    sectionIndex: number;
  }

  let { song, sectionIndex }: Props = $props();

  let section = $state() as Section;
  trackerEffect(() => {
    const sectionByIndex = song.sections.reactive()[sectionIndex];
    assertObject(sectionByIndex);
    section = sectionByIndex;
  });

  function setSectionType(type: Section['type'] | '') {
    section.setType(type || undefined);
  }
</script>

<PanelModalLayout>
  {#snippet header()}
    <header>
      <PanelHeading title="Section Settings" padX={6}>
        {#snippet text()}
          These settings apply to this section only.
        {/snippet}
      </PanelHeading>
    </header>
  {/snippet}

  {#snippet main()}
    <div class="relative">
      <h3 class="pb-2 pl-6 pt-4 text-sm font-medium text-gray-500">
        When to play this section:
        <HelpCircleTooltip
          text="This setting determines when this section will or will not be played, based on how far along you are in the song."
        />
      </h3>
      <div class="px-3">
        {#each sectionTypes as type (type.id)}
          <div
            class="group border-x border-t border-panelWellBorder first:rounded-t-lg last:rounded-b-lg last:border-b"
          >
            <button
              class="colors-panelOpener relative block w-full py-2.5 pl-2.5 pr-9 text-left group-first:rounded-t-lg group-last:rounded-b-lg"
              onclick={() => setSectionType(type.id)}
            >
              <div class="pl-1 sm-band:pl-0">
                <div class="flex items-center">
                  <div class="flex-1 font-medium leading-6 text-gray-900 transition-colors">
                    {type.name}
                  </div>
                </div>
              </div>
              <div
                class="absolute right-4 top-1/2 flex -translate-y-1/2 scale-125 items-center justify-center"
              >
                <ChecklistCheck size={16} checked={type.id == (section.type ?? '')} />
              </div>
            </button>
          </div>
        {/each}
      </div>
      <div class="ml-0.5 px-6 pb-0 pt-2 text-sm italic text-gray-600">
        {@html sectionTypes.find((type) => type.id === (section.type ?? ''))?.description}
      </div>
      {#if section.type == 'o' || section.type == 'sl'}
        <div
          class="mx-6 mt-4 rounded-md border border-muted-150 bg-muted-100 px-3 py-2 text-sm text-gray-600"
        >
          * Since Auto-Finish is not available in the editor, this setting won’t have an effect
          until you save the song.
        </div>
      {/if}

      <div class="mb-6"></div>
      <!--
      <h3 class="pb-2 pl-6 pt-4 text-sm font-medium text-gray-500">
        Key change:
        <HelpCircleTooltip
          text="This setting determines when this section will or will not be played, based on how far along you are in the song."
        />
      </h3>
      <div class="px-3 pb-4">
        <div class="overflow-hidden rounded-lg border border-panelWellBorder bg-white">
          <button
            class="colors-panelOpener relative block w-full py-2.5 pl-3 pr-9 text-left group-first:rounded-t-lg group-last:rounded-b-lg"
            class:text-gray-500={true}
          >
            No key change in this section
            <PanelOpenerChevron direction="right" />
          </button>
        </div>
      </div>
      -->
    </div>
  {/snippet}
</PanelModalLayout>
