import type { BassSettingsHash } from '@/band/instruments/bass/BassSettingsHash';
import type { LinearizedMeasure } from '@/chart/LinearizedMeasure';
import { bounded } from '@/utilities/bounded';

export function assignBassOctaves({
  measures,
  settings,
}: {
  measures: readonly LinearizedMeasure[];
  settings: BassSettingsHash;
}) {
  if (settings.onbeatNotes.rootOnly) {
    for (const measure of measures) {
      for (const cell of measure.cells) {
        if (settings.onbeatNotes.rootOctaves == 'alternating') {
          (cell.plans.bass ??= {}).octave =
            cell.beats[0].chordRootChanged ||
            cell.layout.barStart ||
            cell.split ||
            cell.effect == 'stop' ||
            cell.effect == 'diamond' ||
            measure.endOfSong
              ? 0
              : 2;
        } else {
          (cell.plans.bass ??= {}).octave = settings.onbeatNotes.rootOctaves == 'low' ? 0 : 2;
        }
      }
    }
  } else {
    const fixedOctave = {
      'low': 0,
      'middle': 1,
      'high': 2,
      'random': undefined,
    }[settings.onbeatNotes.alternatingOctaves];
    let floatingOctave = Math.round(Math.random());
    for (const measure of measures) {
      if (typeof fixedOctave !== 'number') {
        if (measure.cells[0].layout.lineStart || measure.cells[0].beats[0].chordRootChanged) {
          const inc = Math.sign(Math.floor(Math.random() * (floatingOctave == 0 ? 5 : 4) - 2));
          floatingOctave = bounded(floatingOctave + inc, 0, 2);
        }
      }
      for (const cell of measure.cells) {
        (cell.plans.bass ??= {}).octave = (fixedOctave ?? floatingOctave) as 0 | 1 | 2;
      }
    }
  }
}
