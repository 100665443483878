import WebAudioMetering from '@/audio/metering/ui/WebAudioMetering.svelte';

Template.webAudioMeteringWindow.helpers({
  floatingWindowDataContext() {
    return {
      class: 'metering',
      moveableMinWidth: '500px',
      minFromBottom: 250,
      shouldBeVisible() {
        return !!Session.get('showWebAudioMeteringWindow');
      },
      closeClickHandler() {
        Session.set('showWebAudioMeteringWindow', false);
      },
      title() {
        return 'Web Audio Metering';
      },
    };
  },
});

Template.webAudioMeteringWindow.helpers({
  visible() {
    return !!Session.get('showWebAudioMeteringWindow');
  },

  WebAudioMetering() {
    return WebAudioMetering;
  },
});
