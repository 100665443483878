import { assertHtmlElement, assertTruthy } from '@sindresorhus/is';
import jQuery from 'jquery';
import { animate } from 'motion/mini';
import { tick } from 'svelte';
import { clog } from '@/browser/clog';
import type { Cell } from '@/chart/Cell.svelte';

const runningAnimations = new Set<ReturnType<typeof animate>>();

export function addCellTransition(
  _node: HTMLElement,
  { duration = 300, cell }: { duration?: number; cell: Cell }
) {
  tick()
    .then(() => animateCellsAfter(cell, 'adding'))
    .catch(clog.error);

  return {
    duration,
  };
}

export function removeCellTransition(
  node: HTMLElement,
  { cell, cellCount }: { cell: Cell; cellCount: number }
) {
  const boundingRect = node.getBoundingClientRect();
  const zIndex = (cellCount || 500) + 2;
  const fallingCell = node.cloneNode(true) as HTMLElement;
  node.style.display = 'none';
  Object.assign(fallingCell.style, {
    position: 'absolute',
    top: `${Math.floor(boundingRect.top)}px`,
    left: `${Math.floor(boundingRect.left)}px`,
    width: `${Math.floor(boundingRect.width)}px`,
    height: `${Math.floor(boundingRect.height)}px`,
    zIndex: zIndex.toString(),
    pointerEvents: 'none',
  });
  fallingCell.inert = true;
  fallingCell.classList.add('animateOut', `var-${Math.ceil(Math.random() * 6)}`);
  document.body.appendChild(fallingCell);

  tick()
    .then(() => animateCellsAfter(cell, 'removing'))
    .catch(clog.error);
  return {
    duration: 0, // detach from this immediately!
  };
}

function animateCellsAfter(cell: Cell, action: 'adding' | 'removing') {
  for (const animation of runningAnimations) {
    animation.complete();
  }
  runningAnimations.clear();

  const sign = action == 'adding' ? 1 : -1;

  let halfSeen = false;
  let laterCell = cell.next;
  if (!laterCell) return;
  let prevCell = cell;
  const boundingRect = laterCell?.layout.chartCell?.getBoundingClientRect();
  assertTruthy(boundingRect);
  while (laterCell) {
    if (
      halfSeen &&
      laterCell.layout.lineStart &&
      prevCell.layout.column % 2 === (action == 'adding' ? 1 : 0)
    ) {
      halfSeen = false;
      prevCell = laterCell;
      laterCell = laterCell.next;
      continue;
    }
    if (laterCell.half) halfSeen = true;

    const cellContentsEl = laterCell.layout.chartCell?.querySelector('.ChartCell__walls');
    assertHtmlElement(cellContentsEl);
    if (laterCell.layout.lineStart && action == 'adding') {
      const prevCellEl = prevCell.layout.chartCell;
      assertHtmlElement(prevCellEl, JSON.stringify(prevCell.layout));
      const duplicatedCellContentsEl = cellContentsEl.cloneNode(true) as HTMLElement;
      Object.assign(duplicatedCellContentsEl.style, {
        position: 'absolute',
        top: '0',
        left: '0',
        width: `${Math.floor(boundingRect.width)}px`,
        height: `${Math.floor(boundingRect.height)}px`,
        pointerEvents: 'none',
        zIndex: '100',
      });
      duplicatedCellContentsEl.inert = true;
      duplicatedCellContentsEl.classList.add('animatingOut');
      prevCellEl.appendChild(duplicatedCellContentsEl);
      const animation = animate(
        duplicatedCellContentsEl,
        {
          opacity: [1, 0],
          transform: [
            '',
            `translateX(${sign * boundingRect.width}px) translateZ(-${boundingRect.width}px) rotateY(60deg)`,
          ],
        },
        {
          duration: 0.4,
          ease: [0.4, 0, 0.6, 1],
        }
      );
      void animation.then(() => {
        duplicatedCellContentsEl.remove();
      });
      runningAnimations.add(animation);
    }

    const nextCell = laterCell.next;
    if (nextCell?.layout.lineStart && action == 'removing') {
      const nextCellEl = nextCell.layout.chartCell;
      assertHtmlElement(nextCellEl, JSON.stringify(nextCell.layout));
      const duplicatedCellContentsEl = cellContentsEl.cloneNode(true) as HTMLElement;
      Object.assign(duplicatedCellContentsEl.style, {
        position: 'absolute',
        top: '0',
        left: '0',
        width: `${Math.floor(boundingRect.width)}px`,
        height: `${Math.floor(boundingRect.height)}px`,
        background: 'red',
        pointerEvents: 'none',
        zIndex: '100',
      });
      duplicatedCellContentsEl.inert = true;
      duplicatedCellContentsEl.classList.add('animatingOut');
      nextCellEl.appendChild(duplicatedCellContentsEl);
      const animation = animate(
        duplicatedCellContentsEl,
        {
          opacity: [1, 0],
          transform: [
            '',
            `translateX(${sign * boundingRect.width}px) translateZ(-${boundingRect.width}px) rotateY(${sign * 60}deg)`,
          ],
        },
        {
          duration: 0.4,
          ease: [0.4, 0, 0.6, 1],
        }
      );
      void animation.then(() => {
        duplicatedCellContentsEl.remove();
      });
      runningAnimations.add(animation);
    }

    const animation = animate(
      cellContentsEl,
      laterCell.layout.lineStart && action == 'adding'
        ? {
            opacity: [0, 1],
            transform: [
              `translateX(${sign * -boundingRect.width}px) translateZ(${-boundingRect.width}px) rotateY(-60deg)`,
              '',
            ],
          }
        : laterCell.layout.lineEnd && action == 'removing'
          ? {
              opacity: [0, 1],
              transform: [
                `translateX(${sign * -boundingRect.width}px) translateZ(-${boundingRect.width}px) rotateY(60deg)`,
                '',
              ],
            }
          : {
              transform: [`translateX(${sign * -boundingRect.width}px)`, ''],
            },
      {
        duration: 0.4,
        ease: [0.4, 0, 0.6, 1],
      }
    );
    runningAnimations.add(animation);

    prevCell = laterCell;
    laterCell = laterCell.next;
  }
}
