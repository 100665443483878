import { FlowRouter } from 'meteor/ostrio:flow-router-extra';

Template.forgotPasswordSent.helpers({
  emailAddress() {
    return Session.get('email-inbox-to-open');
  },
});

if (Meteor.isCordova) {
  document.addEventListener(
    'resume',
    function cordovaResumeLeaveForgotPasswordSentPage() {
      if (FlowRouter.getRouteName() == 'forgot-password-sent') {
        FlowRouter.go('login');
      }
    },
    false
  );
}
