import { Tracker } from 'meteor/tracker';
import { Section } from '@/chart/Section.svelte';
import type { Song } from '@/chart/Song';
import type { Chord } from '@/music/Chord';
import { quickRandomId } from '@/utilities/quickRandomId';

export class SectionArray extends Array<Section> {
  private song: Song;

  constructor(song: Song) {
    super();
    this.song = song;
  }

  reactiveDep = new Tracker.Dependency();
  reactive(): Section[] {
    this.reactiveDep.depend();
    return this;
  }
  changed(): void {
    this.reactiveDep.changed();
  }

  insertBefore(index: number, section?: Section): void {
    this.song.history.takeSnapshot();
    const sectionToInsert = section || Section.blank(this.song);
    this.splice(index, 0, sectionToInsert);
    this.song.processChanges();
    this.song.focus.setToSingleCell(sectionToInsert.cells[0]);
  }

  append(section?: Section): void {
    this.insertBefore(this.length, section);
  }

  duplicate(index: number): void {
    const section = this[index];
    if (!section) return;

    const sectionTemplate = section.serialize();
    sectionTemplate.type = undefined;
    sectionTemplate.repetitions = 1;
    this.insertBefore(this.length, new Section(this.song, sectionTemplate));
  }

  delete(index: number): void {
    this.song.history.takeSnapshot();
    this.splice(index, 1);
    if (this.length === 0) this.push(Section.blank(this.song));
    this.song.focus.revalidate();
    this.song.processChanges();
  }

  move(index: number, direction: number): void {
    const up = direction === -1;
    if (index === (up ? 0 : this.length - 1)) return;
    this.song.history.takeSnapshot();
    const thisIndex = index;
    const swappedSectionIndex = thisIndex + (up ? -1 : 1);
    this.splice(swappedSectionIndex, 0, this.splice(thisIndex, 1)[0]!);
    this.song.processChanges();
  }

  updateMeta(): void {
    this.forEach((section, index) => {
      if (!section._id) section._id = quickRandomId();
      section.index = index;
      section.repetitions ||= 1;
      section.updateCellsMeta();
    });
    this.changed();
  }

  removeEmptySections(): void {
    // iterate through array backwards so we can remove items without messing up the index
    for (let index = this.length - 1; index >= 0; index--) {
      if (this[index]?.cells.length === 0) {
        this.splice(index, 1);
      }
    }
    if (this.length === 0) this.append();
  }

  removeImpliedFlags(): void {
    this.forEach((section) =>
      section.cells.forEach((cell) => {
        if (cell.implied) {
          delete cell.implied;
          this.changed();
        }
      })
    );
  }

  fillInMissingChords(): void {
    this.forEach((section) => {
      let prevChord: Chord;
      section.cells.forEach((cell) => {
        if (!cell.chord) {
          cell.chord = prevChord;
          this.changed();
        }
        prevChord = cell.chord;
      });
    });
  }

  /**
   * Load serialized song data, replacing any existing sections.
   * @param sections - Section data to import
   */
  load(sections: SerializedSection[]): void {
    this.length = 0;
    sections.forEach((sectionData) => {
      this.push(new Section(this.song, sectionData));
    });
    this.updateMeta();
    this.fillInMissingChords();
  }

  serialize({ preserveIds = false } = {}): SerializedSection[] & { _id?: string } {
    return [...this].map((section) => section.serialize({ preserveIds }));
  }
}
