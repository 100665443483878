import { dampenedDownbeat } from '@/band/instruments/guitar/blocks/dampenedDownbeat';
import { DampenedGuitarCore } from '@/band/instruments/guitar/cores/DampenedGuitarCore';
import { chop, strumDown } from '@/band/instruments/guitar/GuitarActions';
import type { GuitarCore } from '@/band/instruments/guitar/GuitarCore';
import type { GuitarSettingsHash } from '@/band/instruments/guitar/GuitarSettingsHash';
import { lerp } from '@/utilities/lerp';
import { randomPlusMinus } from '@/utilities/randomPlusMinus';

export const DampenedWaltzGuitarCore: GuitarCore = {
  id: 'dw',
  title: 'Dampened',

  timeSignatures: ['3/4'],
  equivilantCoreFor(timeSignature) {
    return timeSignature === '4/4' ? DampenedGuitarCore : undefined;
  },

  linkedSettingsKeys: ['dampenedOnbeats', 'dampenedSustain', 'dampenedBackbeat'],
  otherSettingsKeys: ['timing'],

  chordStyle: 'barred',

  // swingCategory: 'jazz',
  // defaultSwing: swingOptions['2:1'],

  presets: [
    {
      name: 'Strum, dampened strum',
      settings: {
        'dampenedOnbeats': { s: 1 },
        'dampenedSustain': { b1: 1, b2: 1 / 12 },
        'dampenedBackbeat': {},
      },
    },
    {
      name: 'Bass note, dampened strum',
      settings: {
        'dampenedOnbeats': { s: 0 },
        'dampenedSustain': { b1: 1, b2: 2 / 12 },
        'dampenedBackbeat': { v: 0 / 6 },
      },
    },
  ],

  processMeasure(measure, settings) {
    const [firstCell, secondCell] = measure.cells;
    const { offbeatSustain } = settings.dampenedSustain;
    const offbeatBoost = settings.dampenedBackbeat.offbeatDbBoost;

    if (measure.endOfSong) {
      return [
        dampenedDownbeat(settings),
        null,
        chop(offbeatSustain, 'low', { spread: 3, db: 0 }),
        null,
        chop(offbeatSustain, 'low', { spread: 4, db: 2 }),
        null,
        strumDown('bass', { spread: 5, db: 0 }),
      ];
    }

    const chopSpread = lerp(3.5, 4.2, settings.dampenedBackbeat.value) + randomPlusMinus(0.3);
    const standardCellPattern = [
      dampenedDownbeat(settings),
      null,
      chop(offbeatSustain, 'jazzD', { spread: chopSpread, db: offbeatBoost }),
      null,
      chop(offbeatSustain, 'jazzD', { spread: chopSpread, db: offbeatBoost }),
      null,
    ];

    return [
      ...(firstCell.split ? splitWaltzCell(settings) : standardCellPattern),
      ...(secondCell?.split ? splitWaltzCell(settings) : standardCellPattern),
    ];
  },

  generateViz(settings) {
    const onbeatTail = settings.dampenedOnbeats.singleNote
      ? 0.0
      : settings.dampenedOnbeats.shortStrum
        ? 0.3
        : 0.7;
    const backbeat = settings.dampenedBackbeat.value;

    const onbeatViz =
      backbeat < 1
        ? ({
            dir: 'd',
            biasY: 0.5 - (1 - settings.dampenedOnbeats.spread * (1 - backbeat) * 0.2) * 0.3,
            tail: onbeatTail * (1 - backbeat / 3),
            thickness: 0.83 - backbeat, // max visible backbeat is 0.83
            dampened: settings.dampenedSustain.onbeatSustain < 1,
            sustain: settings.dampenedSustain.onbeatSustain,
          } as const)
        : null;
    const offbeatViz = {
      dir: 'd',
      biasY: 0.5,
      tail: 0.8,
      thickness: 0.83 + backbeat * 0.17,
      dampened: settings.dampenedSustain.offbeatSustain < 1,
      sustain: settings.dampenedSustain.offbeatSustain,
    } as const;
    return [onbeatViz, null, offbeatViz, null, offbeatViz, null];
  },
};

function splitWaltzCell(settings: GuitarSettingsHash) {
  const duration = settings.dampenedSustain.offbeatSustain;
  const onbeatReduction = settings.dampenedBackbeat.onbeatDbReduction;
  const action = chop(duration, 'jazzD', {
    spread: 3 + Math.random(),
    db: -onbeatReduction / 3,
  });
  return [action, null, action, null, action, null];
}
