<script lang="ts">
  import type { Song } from '@/chart/Song';
  import { Crnt } from '@/Crnt';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import { tooltip } from '@/ui/tooltip';

  interface Props {
    button: 'add' | 'remove';
    cellsToDuplicate?: number;
  }

  let { button, cellsToDuplicate = 1 }: Props = $props();

  let song: Song | undefined;
  trackerEffect(() => (song = Crnt.song()));

  let atEndOfSection: boolean = $state(false);
  trackerEffect(
    () =>
      (atEndOfSection =
        song?.focus.endCell()?.layout.cellIndex ===
        (song?.focus.endCell()?.section?.cells.length ?? 0) - 1)
  );

  let addActionDescription = $derived(
    cellsToDuplicate > 1
      ? 'Duplicate selected cells'
      : atEndOfSection
        ? 'Add another cell'
        : 'Insert cell'
  );

  let removeActionDescription = $derived(
    'Delete selected ' + (cellsToDuplicate > 1 ? 'cells' : 'cell')
  );

  function clickAdd() {
    song?.withFocused().duplicate();
  }

  function clickRemove() {
    song?.withFocused().delete();
  }
</script>

{#if button === 'remove'}
  <button
    class="removeButton group"
    aria-label={removeActionDescription}
    onclick={clickRemove}
    use:tooltip={{
      placement: 'top',
      delay: [400, 50],
      allowHTML: true,
      touch: false,
      onShow: (reference) =>
        reference.setContent(`${removeActionDescription} <kbd class="ml-2">backspace</kbd>`),
    }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="32"
      class="overflow-visible"
      aria-hidden="true"
    >
      <path
        fill="currentColor"
        d="M35 32a5 5 0 0 0 5-5V5a5 5 0 0 0-5-5H16a5 5 0 0 0-3.54 1.47l-11 11a5 5 0 0 0 0 7.07l11 11A5 5 0 0 0 16 32h19Z"
      />
      <!-- class="stroke-primary-400 stroke-0 group-focus-visible:stroke-[3]" -->
      <path fill="#fff" d="M31 16a1 1 0 0 1-1 1H17a1 1 0 1 1 0-2h13a1 1 0 0 1 1 1Z" />
    </svg>
  </button>
{/if}

{#if button === 'add'}
  <button
    class="addButton group"
    aria-label={addActionDescription}
    onclick={clickAdd}
    use:tooltip={{
      placement: 'top',
      delay: [400, 50],
      allowHTML: true,
      touch: false,
      onShow: (reference) =>
        reference.setContent(`${addActionDescription} <kbd class="ml-2">spacebar</kbd>`),
    }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="32"
      class="overflow-visible"
      aria-hidden="true"
    >
      {#if (cellsToDuplicate ?? 1) > 1}
        <path
          fill="currentColor"
          opacity="0.3"
          d="M5 0a5 5 0 0 0-5 5v22a5 5 0 0 0 5 5h19a5 5 0 0 0 3.54-1.47l11-11a5 5 0 0 0 0-7.07l-11-11A5 5 0 0 0 24 0H5Z"
          transform="translate(-3.5 0) scale(1 0.97)"
          class="origin-center"
        />
      {/if}
      {#if (cellsToDuplicate ?? 1) > 2}
        <path
          fill="currentColor"
          opacity="0.25"
          d="M5 0a5 5 0 0 0-5 5v22a5 5 0 0 0 5 5h19a5 5 0 0 0 3.54-1.47l11-11a5 5 0 0 0 0-7.07l-11-11A5 5 0 0 0 24 0H5Z"
          transform="translate(-7 0) scale(1 0.94)"
          class="origin-center"
        />
      {/if}
      <path
        fill="currentColor"
        d="M5 0a5 5 0 0 0-5 5v22a5 5 0 0 0 5 5h19a5 5 0 0 0 3.54-1.47l11-11a5 5 0 0 0 0-7.07l-11-11A5 5 0 0 0 24 0H5Z"
        class="stroke-primary-600 stroke-0 group-focus-visible:stroke-[3]"
      />
      <path fill="#fff" d="M9 16a1 1 0 0 1 1-1h13a1 1 0 1 1 0 2H10a1 1 0 0 1-1-1Z" />
      <path fill="#fff" d="M16.5 8.5a1 1 0 0 1 1 1v13a1 1 0 1 1-2 0v-13a1 1 0 0 1 1-1Z" />
    </svg>
  </button>
{/if}

<style>
  .addButton,
  .removeButton {
    all: unset;
    pointer-events: none;
    touch-action: manipulation;
    transition: color 0.1s ease-in-out;

    &:focus-visible {
      outline: none;
    }

    svg {
      pointer-events: auto;
      cursor: pointer;
    }

    &:active > svg {
      filter: drop-shadow(0 0 4px hsla(30, 100%, 60%, 0.5));
    }
  }

  .removeButton {
    color: hsl(0, 100%, 40%);
    &:hover {
      color: hsl(0, 100%, 50%);
    }
    &:active {
      color: hsl(0, 90%, 45%);
    }
  }

  .addButton {
    color: hsl(120, 100%, 35%);
    &:hover {
      color: hsl(120, 100%, 45%);
    }
    &:active {
      color: hsl(120, 90%, 40%);
    }
  }
</style>
