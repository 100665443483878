import getVideoId from 'get-video-id';
import SongReferencePlatformSVGs from '@/references/SongReferencePlatformSVGs';

const attributionForBluegrassLyrics =
  '<div class="lyrics-attribution">Lyrics provided by <a href="https://www.bluegrasslyrics.com" target="_blank" rel="nofollow">BluegrassLyrics.com</a>. Excerpted&nbsp;with&nbsp;permission.</div>';
const attributionForToneway =
  '<div class="lyrics-attribution">Lyrics provided by <a href="https://toneway.com" target="_blank" rel="nofollow">The ToneWay Project</a>. Excerpted&nbsp;with&nbsp;permission.</div>';

const platforms = [
  {
    id: 'youtube',
    name: 'YouTube',
    urlRegex:
      /(?:youtube\.com\/\S*(?:(?:\/e(?:mbed))?\/|watch\/?\?(?:\S*?&?v=))|youtu\.be\/)([a-zA-Z0-9_-]{6,11})/i,
    async getEmbedCode(url) {
      const rId = getVideoId(url).id;
      const timeParam = /\bt=\d+/.test(url) && `&start=${/\bt=(\d+)/.exec(url)[1]}`;
      return `<iframe src="https://www.youtube-nocookie.com/embed/${rId}?autoplay=1&modestbranding=1&playsinline=1&rel=0${timeParam}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
    },
  },
  {
    id: 'vimeo',
    name: 'Vimeo',
    urlRegex: /https?:\/\/(\w+\.)?vimeo.com\/\d+/i,
    async getEmbedCode(url) {
      const rId = getVideoId(url).id;
      return `<iframe src="https://player.vimeo.com/video/${rId}" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`;
    },
  },
  {
    id: 'spotify',
    name: 'Spotify',
    urlRegex: /https?:\/\/(\w+\.)?spotify\.com\/(embed\/)?track\/([a-z0-9_-]+)/i,
    async getEmbedCode(url) {
      const rId = /spotify\.com\/(?:embed\/)?track\/([a-z0-9_-]+)/i.exec(url)[1];
      return `<iframe allowtransparency="true" frameborder="0" allow="encrypted-media" src="https://open.spotify.com/embed/track/${rId}"></iframe>`;
    },
  },
  {
    id: 'soundcloud',
    name: 'SoundCloud',
    urlRegex: /https?:\/\/(\w+\.)?soundcloud\.com\/([a-z0-9_-]+)\/([a-z0-9_-]+)/i,
    async getEmbedCode(url) {
      return fetch(Meteor.absoluteUrl(`/fetch-oembed?url=${encodeURIComponent(url)}`)).then((res) =>
        res.text()
      );
    },
  },
  {
    id: 'google',
    name: 'Google Play',
    urlRegex: new RegExp('^https://play\\.google\\.com/', 'i'),
  },
  {
    id: 'appleMusic',
    name: 'Apple Music',
    urlRegex: new RegExp('^https://([a-z]+\\.)?(itunes|music)\\.apple\\.com/[a-z]+/album/', 'i'),
  },
  {
    id: 'amazonMusic',
    name: 'Amazon Music',
    urlRegex: new RegExp('^https://music\\.amazon\\.com/', 'i'),
  },
  {
    id: 'clyp',
    name: 'Clyp',
    urlRegex: /https?:\/\/(\w+\.)?clyp\.it\/([a-z0-9_-]+)/i,
    async getEmbedCode(url) {
      const rId = /clyp\.it\/([a-z0-9_-]+)/i.exec(url)[1];
      return `<iframe width="100%" height="265" src="https://clyp.it/${rId}/widget" frameborder="0"></iframe>`;
    },
  },
  {
    id: 'toneway',
    name: 'The ToneWay Project Lyrics',
    isText: true,
    urlRegex: /https?:\/\/(\w+\.)?toneway\.com\/songs\/([a-z0-9_-]+)/i,
    async getEmbedCode(url) {
      return fetch(Meteor.absoluteUrl(`/fetch-lyrics?url=${encodeURIComponent(url)}`))
        .then((res) => res.text())
        .then(
          (text) => `<div class="lyrics-container toneway">${text}${attributionForToneway}</div>`
        );
    },
  },
  {
    id: 'bluegrasslyrics',
    name: 'BluegrassLyrics.com',
    isText: true,
    urlRegex: /https?:\/\/(\w+\.)?bluegrasslyrics\.com\/song\/([a-z0-9_-]+)/i,
    async getEmbedCode(url) {
      return fetch(Meteor.absoluteUrl(`/fetch-lyrics?url=${encodeURIComponent(url)}`))
        .then((res) => res.text())
        .then(
          (text) =>
            `<div class="lyrics-container bluegrasslyrics">${text}${attributionForBluegrassLyrics}</div>`
        );
    },
  },
  {
    id: 'bluegrassnet',
    name: 'BluegrassNet Lyrics',
    isText: true,
    urlRegex: /https?:\/\/(\w+\.)?bluegrassnet\.com\/lyrics\/([a-z0-9_-]+)/i,
    async getEmbedCode(url) {
      return fetch(Meteor.absoluteUrl(`/fetch-lyrics?url=${encodeURIComponent(url)}`))
        .then((res) => res.text())
        .then((text) => `<div class="lyrics-container bluegrassnet">${text}</div>`);
    },
  },
  {
    id: 'dropbox',
    name: 'Dropbox',
    urlRegex:
      /(https?:\/\/(\w+\.)?dropbox\.com.+\.(mp3|m4a|aac|flac|ogg|opus|wav|alac))(\?dl=[01])?$/i,
    async getEmbedCode(url) {
      const audioUrl = this.urlRegex.exec(url)[1];
      return `<audio controls src="${audioUrl}?dl=1"></audio>`;
    },
  },
  {
    id: 'googledocs',
    name: 'Google Docs',
    isText: true,
    urlRegex: /^https:\/\/docs.google.com\/document\/d\/e\/.+\/pub$/i,
    async getEmbedCode(url) {
      return `<iframe width="100%" height="100%" src="${url}" frameborder="0"></iframe>`;
    },
  },
];

platforms.forEach((p) => {
  if (SongReferencePlatformSVGs[p.id]) {
    p.svgIcon = SongReferencePlatformSVGs[p.id];
  }
});

export default platforms;
