import * as Sentry from '@sentry/browser';
import { FlowRouter } from 'meteor/ostrio:flow-router-extra';

export default function logout() {
  if (Meteor.status().connected) {
    Meteor.logout((error) => {
      if (error) {
        Bert.alert(error.reason, 'warning');
        Sentry.captureException(error);
      } else {
        Bert.alert('You are now logged out. Come back soon!', 'success', 'growl-top-left');
        FlowRouter.go('landing-page');
      }
    });
  } else {
    bootbox.confirm({
      size: 'small',
      message: "Are you sure? You won't be able to log back in until you go back online.",
      closeButton: false,
      buttons: {
        confirm: {
          label: 'Log out',
          className: 'btn-danger',
        },
      },
      callback(result) {
        if (result) {
          Accounts.makeClientLoggedOut();
          Bert.alert('You are now logged out. Come back soon!', 'success', 'growl-top-left');
          FlowRouter.go('landing-page');
        }
      },
    });
  }
}
