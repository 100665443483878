export const roleNames = [
  'admin',
  'lifetime-member',
  'paid-member',
  'trial-member',
  'metrics',
  'impersonator',
  'contributor',
  // These are used in the DB, but have no permissions attached
  // 'trial-ended',
  // 'canceled-member',
  // 'unverified',
  // 'verified',
] as const;
