import { Tracker } from 'meteor/tracker';
import type { Song } from '@/chart/Song';

export interface SongSnapshot {
  key: Key;
  timeSignature: TimeSignature;
  sections: SerializedSection[] & { _id?: string };
  focus?: SelectionRange;
}

export class SongEditHistory {
  private song: Song;

  constructor(song: Song) {
    this.song = song;
  }

  _undoStack: Array<SongSnapshot> = [];
  _redoStack: Array<SongSnapshot> = [];
  _undoRedoDep = new Tracker.Dependency();

  takeSnapshot(): void {
    const snapshot = this.song.getSnapshotData();
    if (!snapshot) return;
    if (snapshot.sections.length === 0) return;
    this._redoStack = [];
    this._undoStack.push(snapshot);
    this._undoRedoDep.changed();
  }

  undo(): void {
    if (this._undoStack.length === 0) return;
    const redoSnapshot = this.song.getSnapshotData() as SongSnapshot;
    this._redoStack.push(redoSnapshot);
    const snapshot = this._undoStack.pop();
    if (snapshot) this.restoreSnapshot(snapshot);
  }

  redo(): void {
    if (this._redoStack.length === 0) return;
    const undoSnapshot = this.song.getSnapshotData() as SongSnapshot;
    this._undoStack.push(undoSnapshot);
    const snapshot = this._redoStack.pop();
    if (snapshot) this.restoreSnapshot(snapshot);
  }

  private restoreSnapshot(snapshot: SongSnapshot): void {
    this.song.loadSnapshot(snapshot);
    this._undoRedoDep.changed();
  }

  canUndo(): boolean {
    this._undoRedoDep.depend();
    return this._undoStack.length > 0;
  }

  canRedo(): boolean {
    this._undoRedoDep.depend();
    return this._redoStack.length > 0;
  }

  clear(): void {
    this._undoStack = [];
    this._redoStack = [];
  }
}
