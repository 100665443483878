import type { ClickTrack } from '@/band/ClickTrack';
import { Crnt } from '@/Crnt';
import UserPreferences from '@/user/UserPreferences';

type CountBeatPushingFunction = (args?: { longCountIn?: boolean }) => void;

const CountSequencer = {
  getInstructions: ({
    timeSignature,
    clickTrack,
  }: {
    timeSignature: TimeSignature;
    clickTrack: ClickTrack;
  }): CountInstruction[] => {
    const countInSpeak = UserPreferences.get('countInSpeak');
    const countInDampenedStrum = UserPreferences.get('countInDampenedStrum');
    const countInDouble = UserPreferences.get('countInDouble') && !Crnt.song()?.editMode();

    const beats: CountInstruction[] = [];
    timeSignature = timeSignature || '4/4';
    const playVoice = countInSpeak;
    const playStrums = countInDampenedStrum;

    const beatPushingFunctions: Record<TimeSignature, CountBeatPushingFunction> = {
      '3/4': function ({ longCountIn = false } = {}) {
        for (let measure = 1; measure <= 2; measure++) {
          for (let beat = 1; beat <= 3; beat++) {
            const voiceCount = longCountIn ? (beat == 1 ? measure : undefined) : beat;
            beats.push({
              label: voiceCount,
              voice: voiceCount && playVoice ? 'count-' + voiceCount : undefined,
              guitar: playStrums ? (beat == 1 ? 'muted-boom' : 'muted-chuck') : undefined,
            });
          }
        }
      },
      '6/8': function ({ longCountIn = false } = {}) {
        for (let i = 1; i <= 4; i++) {
          const voiceCount = longCountIn ? (i == 1 ? 1 : i == 3 ? 2 : undefined) : i;
          beats.push({
            label: voiceCount,
            voice: voiceCount && playVoice ? 'count-' + voiceCount : undefined,
            guitar: playStrums ? 'muted-boom' : undefined,
          });
          beats.push({});
          beats.push({
            guitar: playStrums ? 'muted-chuck' : undefined,
          });
        }
      },
      '9/8': function ({ longCountIn = false } = {}) {
        for (let i = 1; i <= 3; i++) {
          beats.push({
            label: i,
            voice: playVoice ? 'count-' + i : undefined,
            guitar: playStrums ? 'muted-boom' : undefined,
          });
          beats.push({});
          beats.push({
            guitar: playStrums ? 'muted-chuck' : undefined,
          });
        }
      },
      '4/4': function ({ longCountIn = false } = {}) {
        for (let i = 1; i <= 4; i++) {
          const voiceCount = longCountIn ? (i == 1 ? 1 : i == 3 ? 2 : undefined) : i;
          beats.push({
            label: voiceCount,
            voice: voiceCount && playVoice ? 'count-' + voiceCount : undefined,
            guitar: playStrums ? 'muted-boom' : undefined,
          });
          beats.push({
            guitar: playStrums ? 'muted-chuck' : undefined,
          });
        }
      },
    };

    if (countInDouble) {
      beatPushingFunctions[timeSignature]({ longCountIn: true });
    }
    beatPushingFunctions[timeSignature]();
    if (clickTrack.enabled()) {
      const clickInstructions = clickTrack.generateClickInstructionsToApplyToMeasure({
        songTimeSignature: timeSignature,
        measureTimeSignature: timeSignature,
      });
      beats.forEach((beat, i) => {
        const item = clickInstructions[i % clickInstructions.length];
        if (item) beat.playerInstructions = item;
      });
    }
    return beats;
  },
};
export default CountSequencer;
