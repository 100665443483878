import * as Sentry from '@sentry/browser';
import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { mainIndexSearcher } from '@/library/mainIndexSearcher';
import { rpcAddListToAccount } from '@/server/methods/lists/rpcAddListToAccount';
import { rpcFetchSongListPageData } from '@/server/methods/lists/rpcFetchSongListPageData';
import { rpcSetListPermissions } from '@/server/methods/lists/rpcSetListPermissions';
import ToggleSwitch from '@/ui/ToggleSwitch';

Template.songListPage.onCreated(() => {
  const instance = Template.instance();
  instance.ready = new ReactiveVar(false);
  instance.listId = new ReactiveVar();
  instance.list = new ReactiveVar();
  instance.songs = new ReactiveVar();
  instance.ownerName = new ReactiveVar();
  instance.publiclyEditable = new ReactiveVar();
  instance.autorun(async () => {
    instance.listId.set(FlowRouter.getParam('listId'));
    if (!instance.listId.get()) return;
    try {
      const data = await rpcFetchSongListPageData(instance.listId.get());
      instance.list.set(data.list);
      instance.songs.set(data.songs);
      instance.ownerName.set(
        `${data.owner.profile.firstName} ${data.owner.profile.lastName || ''}`.trim()
      );
      instance.publiclyEditable.set(data.list.publiclyEditable);
      instance.ready.set(true);
    } catch (err) {
      if (err && err.error == 'document-not-found') {
        Meteor.defer(() => {
          $('head').append(
            '<meta name="robots" content="noindex, follow" data-flow-router-seo="true" />'
          );
        });
        FlowRouter.go('song-index', {}, mainIndexSearcher.getQueryParams());
        Bert.alert("Sorry, that list couldn't be found.", 'warning');
      } else {
        Bert.alert(err.reason, 'warning');
      }
    }
  });
});

const shareUrl = function () {
  return Meteor.absoluteUrl(
    FlowRouter.path('song-list', { listId: FlowRouter.getParam('listId') }).substr(1)
  );
};

Template.songListPage.onRendered(() => {
  const instance = Template.instance();
  instance.autorun(() => {
    if (instance.ready.get() && instance.list.get().ownerId == Meteor.userId()) {
      Tracker.afterFlush(() => {
        instance.find('.js-editSongsSwitch input[type=checkbox]').checked =
          instance.list.get().publiclyEditable;
        instance.editSongsSwitch = new ToggleSwitch({
          element: instance.find('.js-editSongsSwitch'),
        });
      });
    }
  });
});

Template.songListPage.onDestroyed(() => {
  if (Template.instance().editSongsSwitch) Template.instance().editSongsSwitch.destroy();
});

Template.songListPage.helpers({
  ready() {
    return Template.instance().ready.get();
  },
  listId() {
    return Template.instance().listId.get();
  },
  list() {
    return Template.instance().list.get();
  },
  ownList() {
    return Template.instance().list.get()?.ownerId == Meteor.userId();
  },
  publiclyEditable() {
    return Template.instance().publiclyEditable.get();
  },
  songs() {
    return Template.instance().songs.get();
  },
  ownerName() {
    return Template.instance().ownerName.get();
  },
  songCountText() {
    var songsToCount = Template.instance().songs.get() || [];
    return songsToCount.length + ' ' + (songsToCount.length == 1 ? 'song' : 'songs');
  },
  shareUrl() {
    return shareUrl();
  },
  emailUrl() {
    var url = shareUrl();
    var name = Template.instance().list.get().name;
    var subject = encodeURIComponent('Strum Machine list for you');
    var body = encodeURIComponent(
      `Here's a link to my list "${name}" on Strum Machine:\n\n${url}\n`
    );
    return `mailto:?&subject=${subject}&body=${body}`;
  },
  loginUrl() {
    return FlowRouter.path(
      'login',
      {},
      { redirect: `${window.location.pathname}${window.location.search}` }
    );
  },
});

Template.songListPage.events({
  'click .js-saveListToAccount': async function (event, instance) {
    if (instance.list.get().ownerId == Meteor.userId()) {
      Bert.alert(
        'This is your own list, so it’s already in your accounnt... but if it wasn’t your list, it’d be in your account now!',
        'success'
      );
    } else {
      await rpcAddListToAccount({ listId: instance.listId.get() });
      FlowRouter.go('song-index', {}, mainIndexSearcher.getQueryParams());
      Bert.alert('List added to your account!', 'success');
    }
  },
  'click .js-listUrl, mousedown .js-listUrl': function (event, instance) {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(instance.find('.js-listUrl'));
    selection.removeAllRanges();
    selection.addRange(range);
  },
  'click .js-copyUrl': function (event, instance) {
    event.preventDefault();
    instance.$('.js-listUrl').click();

    var success = false;
    try {
      success = document.execCommand('copy');
    } finally {
      if (success) {
        Bert.alert('Share URL copied to your clipboard!', 'success');
      } else {
        Bert.alert(
          'Whoops, something went wrong when trying to copy the URL to your clipboard. Please do it manually.',
          'warning'
        );
      }
    }
  },
  'change .js-editSongsSwitch': async function (event, instance) {
    const checkboxEl = instance.find('.js-editSongsSwitch input[type=checkbox]');
    try {
      const { newValue } = await rpcSetListPermissions({
        listId: instance.listId.get(),
        editable: checkboxEl.checked,
      });
      instance.publiclyEditable.set(newValue);
    } catch (err) {
      checkboxEl.checked = !checkboxEl.checked;
      Sentry.captureException(err);
    }
  },
});
