import { Crnt } from '@/Crnt';
import NotesInterface from '@/notes/NotesInterface';
import defineTippyDropdown from '@/ui/dropdowns/defineTippyDropdown';

defineTippyDropdown(Template.songMetadata, {
  id: 'about-menu',
  placement: 'top',
});
defineTippyDropdown(Template.songMetadata, {
  id: 'references-menu',
  placement: 'top',
});

Template.songMetadata.onRendered(() => {});

Template.songMetadata.helpers({
  notesOpen() {
    return NotesInterface.visible();
  },
  showNotesArrow() {
    return NotesInterface.visible() && NotesInterface.displayPosition() == 'bottom';
  },
  showAbout() {
    return !!Crnt.song()?.aboutText() || Crnt.song()?.userFirst() || Crnt.song()?.editMode();
  },
  aboutAvailable() {
    return !!Crnt.song()?.aboutText();
  },
  referencesAvailable() {
    return Crnt.references()?.count() > 0;
  },
  notesAvailable() {
    return !!Crnt.notepad()?.notes();
  },
});

Template.songMetadata.events({
  'click .js-notesButton': function (event, instance) {
    event.preventDefault();
    NotesInterface.toggle();
  },
});
