import EventEmitter from 'eventemitter3';
import { ReactiveDict } from 'meteor/reactive-dict';
import type { Song } from '@/chart/Song';

export abstract class AutoTool extends EventEmitter {
  protected settings = new ReactiveDict();
  protected prefix = '';
  protected enabledSetting(): string {
    return this.prefix + 'On';
  }

  song: Song;
  constructor(song: Song) {
    super();
    this.song = song;
  }

  protected init(settings: Record<string, unknown>): void {
    this.clearSettings();
    if (settings[this.enabledSetting()]) {
      this.settings.set(this.enabledSetting(), true);
    }
  }

  protected clearSettings(): void {
    this.settings.clear();
  }

  setSetting(key: string, value: number | string | null | undefined, noPersist?: boolean): void {
    this.settings.set(key, value);
    this.emit('changedSettings', { [key]: value });
    if (!noPersist) this.emit('persistPlease', { [key]: value });
  }

  //#region === Enabling/Disabling ===

  enabled(): boolean {
    if (this.song.editMode()) return false;
    return !!this.settings.get(this.enabledSetting());
  }

  toggle(): void {
    // note that this setting is persisted as either true or unset
    this.settings.set(this.enabledSetting(), this.enabled() ? null : true);
    this.emit('toggled');
    this.emit(this.enabled() ? 'enabled' : 'disabled');
    this.emit('persistPlease', { [this.enabledSetting()]: this.enabled() ? true : null });
  }

  enable(): void {
    if (!this.enabled()) this.toggle();
  }

  disable(): void {
    if (this.enabled()) this.toggle();
  }

  //#endregion
}
