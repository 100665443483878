<script lang="ts">
  import BandHelpPagePanel from '@/band/ui/panels/BandHelpPagePanel.svelte';
  import PanelModalLayout from '@/band/ui/panels/PanelModalLayout.svelte';
  import { MultiPanelModalController } from '@/ui/settings/MultiPanelModalController.svelte';

  const modalController = MultiPanelModalController.current();

  function handleClick(event: Event) {
    const target = event.target as HTMLElement;
    if (target.tagName !== 'A') return;
    const href = target.getAttribute('href');
    if (href?.includes('help')) {
      event.preventDefault();
      const pageId = href.substring(href.indexOf('/help') + 6) || 'index';
      modalController.zoomInto({ component: BandHelpPagePanel, props: { pageId }, title: 'Help' });
    }
  }
</script>

<PanelModalLayout mainBg="white">
  {#snippet main()}
    <div class="px-5 py-4">
      <div class="pb-4 text-xl font-medium text-gray-800">Band Settings Help</div>
      <!-- svelte-ignore a11y_click_events_have_key_events -->
      <!-- svelte-ignore a11y_no_static_element_interactions -->
      <div
        class="helpPage"
        style="padding: 0; font-size: 14px; line-height: 1.25rem;"
        onclick={handleClick}
      >
        <div>
          <p>
            Each song has its own set of band settings, which Strum Machine uses during playback.
            The Band Settings menu gives you two ways to customize the band:
          </p>

          <ol class="space-y-3">
            <li>
              <a href="/help/band-instrument-settings" class="font-bold">
                Change instrument settings directly
              </a>
              by clicking on an instrument. You can also
              <a href="/help/band-instrument-lineup">add/remove instruments</a> in the current band.
            </li>
            <li>
              <a href="/help/band-presets" class="font-bold">Load a preset style</a>. It’s the
              fastest way to get the band sounding at least <em>close</em> to what you want to hear,
              so
              <strong>we recommend starting with this</strong>.
            </li>
          </ol>

          <img
            src="/images/help/band-menu-twoways.2024-12-05.png"
            alt="Band Settings menu; annotations are repeated below"
            width="388"
            height="560"
            style="margin-bottom: 0.75em; max-width: 100%"
          />

          <p>
            As you’re customizing the band, be sure to utilize the Play button in the top-right
            corner to hear how things are sounding with the current song!
          </p>

          <h2 id="useful-things-to-know">Useful things to know</h2>
          <ul class="space-y-3">
            <li>Each song has its own individual band settings.</li>
            <li>Any time you modify the band, your changes are saved automatically.</li>
            <li>
              Built-in presets are not editable, but you <em>can</em> load a preset and then make changes
              to the instruments, in which case you’ll switch from using the preset to a “Custom Band”
              for that song.
            </li>
            <li>
              Once you have a Custom Band that you like, you can optionally <a
                href="/help/band-preset-creation">add it as a new preset</a
              >
              to Your Presets, which will make it easy to apply to other songs. You can also
              <a href="/help/band-preset-editing">edit your own presets</a>.
            </li>
            <li>
              The default band can be changed. <a href="/help/edit-default-band">Here's how</a>.
            </li>
          </ul>
        </div>
      </div>
    </div>
  {/snippet}
</PanelModalLayout>
