<script lang="ts">
  import VolumePanCustomOverlay from '@/audio/mixer/ui/VolumePanCustomOverlay.svelte';
  import type { Band } from '@/band/Band';
  import { staticInstruments } from '@/band/staticInstruments';
  import InstrumentPanControl from '@/band/ui/components/InstrumentPanControl.svelte';
  import InstrumentVolumeControl from '@/band/ui/components/InstrumentVolumeControl.svelte';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import { slideFade } from '@/ui/svelte-transitions';

  interface Props {
    band: Band | undefined;
    instId: InstrumentId;
    inactive?: boolean;
    canBeWide?: boolean;
    background?: 'gray' | 'white';
  }

  let { band, instId, inactive = false, canBeWide = true, background = 'gray' }: Props = $props();

  let muted: boolean = $state(false);
  trackerEffect(() => (muted = staticInstruments[instId].globalMute()));

  let volumeCustom: boolean = $state(false);
  trackerEffect(() => (volumeCustom = typeof band?.instruments[instId].customVolume() == 'number'));

  let panCustom: boolean = $state(false);
  trackerEffect(() => (panCustom = typeof band?.instruments[instId].customPan() == 'number'));

  function toggleMute(instId: InstrumentId) {
    staticInstruments[instId].setGlobalMute(!staticInstruments[instId].globalMute());
  }
</script>

<div
  class="InstrumentMixer {background == 'white' ? 'bg-white' : 'bg-gray-50'}"
  class:muted
  class:inactive
  class:canBeWide
>
  <div class="InstrumentMixer_heading">
    <div class="IM_heading-name" aria-hidden="true">
      {instId.replace(/^\w/, (c) => c.toUpperCase())}
    </div>
    <button
      class="IM_heading-mute btn btn-danger btn-sm"
      role="switch"
      aria-label="Mute {instId}"
      aria-checked={muted ? 'true' : 'false'}
      onclick={() => toggleMute(instId)}
    >
      {muted ? 'Muted' : 'Mute'}
    </button>
  </div>
  {#if !muted}
    <div class="InstrumentMixer_controls" transition:slideFade>
      <div class="InstrumentMixer_volume">
        {#if volumeCustom}
          <div class="IM_customOverlay">
            <VolumePanCustomOverlay {band} {instId} property="volume" />
          </div>
        {/if}
        <div class:IM_blurWrap={volumeCustom}>
          <InstrumentVolumeControl {instId} global={true} />
        </div>
      </div>
      <div class="InstrumentMixer_pan">
        {#if panCustom}
          <div class="IM_customOverlay">
            <VolumePanCustomOverlay {band} {instId} property="pan" />
          </div>
        {/if}
        <div class:IM_blurWrap={panCustom}>
          <InstrumentPanControl {instId} global={true} />
        </div>
      </div>
    </div>
  {/if}
</div>

<style>
  .InstrumentMixer {
    display: flex;
    flex-direction: column;
    border-radius: 7px;
    border: solid 1px var(--panelWellBorder);
    transition: background-color 300ms ease;
    &.muted {
      background: hsl(23, 20%, 90%);
      border-color: transparent;
    }
    &.inactive {
      opacity: 0.8;
    }
  }

  .InstrumentMixer_heading {
    padding: 0 0.375rem 0 0;
    display: flex;
    align-items: baseline;
  }

  .IM_heading-name {
    padding-left: 0.625rem;
    line-height: 2.5rem;
    font-weight: 500;
    font-size: 18px;
    color: var(--gray-900);

    flex: 1 1 auto;

    .muted & {
      color: hsl(23, 10%, 40%);
    }
    .inactive & {
      font-weight: normal;
    }
  }

  .IM_heading-mute.btn {
    padding: 0.125rem 0.5rem;
    margin-left: 0.5rem;
    align-self: center;
    height: 1.75rem;
    font-size: 13px;
    opacity: 0.8;
    &:hover,
    &:active {
      opacity: 1;
    }

    &[aria-checked='true'] {
      background-color: hsl(5, 100%, 76%) !important;
      color: hsl(8, 100%, 20%) !important;
      border: solid 1px hsl(7, 69%, 59%);
    }
    &[aria-checked='false'] {
      background-color: hsl(30, 29%, 95%) !important;
      color: hsl(28, 39%, 31%) !important;
      border: solid 1px hsl(28, 23%, 80%);
    }
  }

  .InstrumentMixer_controls {
    display: flex;
    align-items: center;
    border-top: solid 1px var(--panelWellBorder);
    padding: 0.375rem 0.75rem 0.25rem 0.375rem;
    flex: 1 1 auto;
  }

  .InstrumentMixer_volume {
    position: relative;
    padding: 0.25rem 0.5rem;
    flex: 1 1 auto;
  }

  .InstrumentMixer_pan {
    margin-left: 0rem;
    position: relative;
    padding: 0.25rem 0.5rem;
  }

  .IM_customOverlay {
    position: absolute;
    top: -6px;
    left: -4px;
    bottom: -3px;
    right: -4px;
  }

  .IM_blurWrap {
    opacity: 0.2;
    filter: blur(1px);
    pointer-events: none;
  }

  @media (min-width: 40rem) {
    .canBeWide {
      &.InstrumentMixer {
        flex-direction: row;
        align-items: center;
        padding: 0.25rem;
      }
      .InstrumentMixer_heading {
        flex-direction: row-reverse;
      }
      .IM_heading-name {
        width: 6em;
        text-align: center;
      }
      .InstrumentMixer_controls {
        border-top: none;
        padding: 0 0.5rem 0 0;
      }
    }
  }
</style>
