import SongProgression from '@/chart/ui/SongProgression.svelte';
import { Crnt } from '@/Crnt';

Template.medley.onRendered(() => {
  // const instance = Template.instance();
});

Template.medley.helpers({
  loaded() {
    return !!Crnt.medley();
  },
  medleySongs() {
    return Crnt.medley()?.songs.reactive();
  },
  SongProgression() {
    return SongProgression;
  },
});
