import { FlowRouter } from 'meteor/ostrio:flow-router-extra';

Template.sidePageLayout.onCreated(() => {
  Template.instance().title = new ReactiveVar();
});

Template.sidePageLayout.helpers({
  subTemplateData() {
    var instance = Template.instance();
    return {
      setTitle(value) {
        instance.title.set(value);
      },
      active: Template.appContainer.activeScreen() == 'side',
    };
  },
  title() {
    return Template.instance().title.get();
  },
});

Template.sidePageLayout.events({
  'click .js-backAction': function (event, instance) {
    if (FlowRouter.getParam('songId'))
      return FlowRouter.go('show-song', { songId: FlowRouter.getParam('songId') });
    return FlowRouter.go('song-index');
  },
  //   "scroll .sidePageLayout > .main": _.throttle((event, instance) => {
  //     var showShadow = $(event.target).scrollTop() > 0;
  //     instance.$('.js-headerShadow').velocity({ opacity: showShadow ? 1 : 0 }, { duration: 150 });
  //   }, 150)
});
