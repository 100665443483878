import { Crnt } from '@/Crnt';
import SongMenuManager from '@/ui/SongMenuManager';

Template.bandButton.events({
  'click .js-button'(_event, _instance) {
    SongMenuManager.showMenu(undefined);
    Modal.show(
      'bandSettingsModal',
      { band: Crnt.song()?.band },
      { backdrop: 'unless-tippy-or-presetMode', keyboard: false }
    );
  },
});

Template.bandButton.helpers({
  band: () => Crnt.song()?.band,

  buttonText() {
    const band = Crnt.song()?.band;
    if (!band) return 'Band Settings';
    const preset = band.preset();
    if (!preset) return 'Band Settings (custom)';
    if (band.presetMode() == 'edit')
      return `${preset.default ? 'Default Band' : preset.name} (editing)`;
    return preset.default ? 'Band Settings' : `Band: ${preset.name}`;
  },

  ariaLabel() {
    const band = Crnt.song()?.band;
    if (!band) return 'Band settings';
    const preset = band.preset();
    return preset?.default
      ? 'Default band settings'
      : preset?.settingsFromAuthor
        ? `Using ${preset.name} for band`
        : preset
          ? `Current band preset is "${preset?.name}"`
          : 'Custom band settings';
  },
});
