import { z } from 'zod';
import type { InstrumentSetting } from '@/band/instruments/InstrumentSetting';
import cloneJSON from '@/utilities/cloneJSON';
import { rounded } from '@/utilities/rounded';

const serializedSchema = z.object({
  // if you update 'e', change the migration too!
  e: z.number().min(0).max(1).optional(),
  // don't use 'v', since that's used for backwards compatibility with the old "fills" upstrokes setting
});

type Serialization = z.infer<typeof serializedSchema>;

export class GuitarDampenedFillsSetting implements InstrumentSetting<Serialization> {
  readonly probability: number;

  constructor(input: unknown = {}) {
    const data = serializedSchema.catch({}).parse(input);
    this.probability = data.e ?? 0.0;
  }

  closeTo(serialized?: Serialization) {
    const other = new GuitarDampenedFillsSetting(serialized);
    return Math.abs(other.probability - this.probability) <= 0.05;
  }

  serialize(): Serialization {
    return cloneJSON({
      e: rounded(this.probability, 3),
    });
  }

  withEagerness(value: number) {
    return new GuitarDampenedFillsSetting({
      ...this.serialize(),
      'e': value,
    });
  }
}
