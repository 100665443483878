import * as Sentry from '@sentry/browser';
import { Meteor } from 'meteor/meteor';
import getSentryEnvironment from '@/sentry/getSentryEnvironment';
import wrapMeteorDebug from '@/sentry/wrapMeteorDebug';
import Version from '@/Version';

export function initBrowserSentry() {
  // window.Sentry is used in some places
  (window as any).Sentry = Sentry;

  Sentry.init({
    dsn: Meteor.settings.public?.sentryDSN,
    tunnel: 'https://strummachine.com/centri',
    release: `sm-app@${Version.current}`,
    environment: getSentryEnvironment(),

    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
    transportOptions: {
      maxQueueSize: 100,
    },

    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,

    /// Caution: Sentry Replay adds a non-trivial amount of overhead to chart rendering during playback
    // integrations: Meteor.isCordova ? [] : [new Sentry.Replay({
    //   maskAllText: false,
    //   blockAllMedia: false,
    //   minReplayDuration: 10000
    // })],

    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category == 'ui.click' && breadcrumb.message) {
        breadcrumb.message = breadcrumb.message.replace(/div#__blaze-root > /, '');
        breadcrumb.message = breadcrumb.message.replace(/(^| )(div|span)(\.|#)/g, '$1$3');
        breadcrumb.message = breadcrumb.message.replace(
          /.btn-(lower|outline|block|raised|sm|lg)/g,
          ''
        );
        if (breadcrumb.message.includes('a.songIndexItem-link')) {
          const linkEl = $(hint?.event.target).closest('.songIndexItem-link');
          if (linkEl) {
            const name = linkEl.find('.songIndexItem-name').text();
            const label = linkEl.find('.songIndexItem-label').text();
            const sId = (linkEl[0] as HTMLAnchorElement).href.match(/\w{14,19}/);
            const mainList = breadcrumb.message.includes('.songIndexList.not-a-list');
            breadcrumb.message = `${mainList ? 'Song (library)' : 'Song (list)'}: ${sId} - ${name}${label ? ` [${label}]` : ''}`;
          }
        }
      }
      if (
        breadcrumb.category?.startsWith('ui.') &&
        breadcrumb.message?.includes('.contactForm-form')
      )
        return null;
      if (breadcrumb.message) {
        if (/^Exception from Tracker (afterFlush|recompute) function:/.test(breadcrumb.message))
          return null;
        if (/^Exception in template helper:/.test(breadcrumb.message)) return null;
        if (/Crafted with love by Luke Abbott/.test(breadcrumb.message)) return null;
      }
      if (breadcrumb.category == 'xhr') return null;
      return breadcrumb;
    },

    beforeSend: (event) => {
      if (
        typeof event.exception == 'object' &&
        typeof (event.exception.values || [])[0] == 'object'
      ) {
        const message = event.exception.values?.[0]?.value;

        // filter out errors we don't want to track
        if (!event.request?.url?.startsWith('http')) return null; // no error reporting if someone tries to save the file locally
        if (/MSIE|Trident\/7\./g.test(navigator.userAgent)) return null; // IE is dead to me
        if (message && /vc_request_action/i.test(message)) return null;
        if (message && /^Exception in template helper:/.test(message)) return null;
        if (message && /^Exception from Tracker (afterFlush|recompute) function:/.test(message))
          return null;
        if (
          message ==
          'Method invocation might have failed due to dropped connection. Failing because `noRetry` option was passed to Meteor.apply. [invocation-failed]'
        )
          return null;
        if (window.location.protocol == 'file:') return null;

        // handle Tracker errors
        const firstException = event.exception.values?.[0];
        if (
          firstException &&
          message &&
          firstException.type == 'Error' &&
          /^[a-z]+: .+\n {4}at/i.test(message) &&
          firstException.stacktrace?.frames
        ) {
          const matches = /^([a-z]+): (.+)\n/i.exec(message);
          if (matches) {
            firstException.type = matches[1];
            firstException.value = matches[2];
          }
        }
      }

      // if (Meteor.isDevelopment) return null;
      return event;
    },
  });

  const scope = Sentry.getCurrentScope();
  scope.setTag('swActive', 'serviceWorker' in navigator && !!navigator.serviceWorker.controller);
  // scope.setTag('electron', !!Meteor.isDesktop);
  scope.setTag('cordova', !!Meteor.isCordova);
  scope.setTag('desktop', !!window.todesktop);
  const pwa =
    (window.navigator as any).standalone == true ||
    window.matchMedia('(display-mode: standalone)').matches ||
    /pwa=1/.test(window.location.href); // don't know if this is necessary, but...
  scope.setTag('pwa', pwa);
  Sentry.setTag(
    'iPad',
    /iPad/i.test(navigator.userAgent) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
  );

  wrapMeteorDebug(Sentry);

  // monkeyPatchBlazeReportException();
}
