import { strict as assert } from 'assert';
import { Cell } from '@/chart/Cell.svelte';
import type { Song } from '@/chart/Song';
import { quickRandomId } from '@/utilities/quickRandomId';
import { setLinkedListPrevAndNext } from '@/utilities/setLinkedListPrevAndNext';

export class Section {
  song: Song;
  _id: string;
  index: number | undefined = $state();
  name: string | undefined = $state();
  repetitions: number = $state(1);
  type: 'i' | 'o' | 'sf' | 'sl' | undefined = $state();
  cells: Cell[] = $state([]);
  lineLengths = $state<number[]>([]);

  constructor(song: Song, data?: SerializedSection & { cells?: SerializedCell[] }) {
    this.song = song;
    this._id = data?._id || quickRandomId();
    if (data?.name) this.name = data.name;
    if (data?.type) this.type = data.type;
    if (data?.lineLengths) this.lineLengths = data.lineLengths;
    this.repetitions = data?.repetitions || 1;
    this.cells = (data?.cells || data?.measures || []).map(
      (serializedCell) => new Cell(serializedCell)
    );
  }

  serialize({ preserveIds = false } = {}): SerializedSection {
    const data: SerializedSection = {
      repetitions: this.repetitions || 1,
      measures: [...this.cells].map((cell) => cell.serialize({ preserveIds })),
    };
    if (preserveIds && this._id) data._id = this._id;
    if (this.name) data.name = this.name;
    if (this.type) data.type = this.type;
    if (this.lineLengths) data.lineLengths = this.lineLengths.slice();
    return data;
  }

  updateCellsMeta(): void {
    this.cells.forEach((cell) => (cell.section = this));
    setLinkedListPrevAndNext(this.cells);
    this.cells.forEach((cell, index) => {
      cell.index = index;
      cell.negativeIndex = this.cells.length - 1 - index;

      // Yeah, it's redundant, but doesn't hurt. Also we're reinitializing the layout object.
      cell.layout = {
        sectionIndex: this.index as number,
        cellIndex: index,
        chartCell: cell.layout?.chartCell, // keep reference to DOM element if present
      } as typeof cell.layout; // HACK, but it works
    });

    // Update chord metadata
    const keyCharted = this.song.key();
    const keyHeard = this.song.keyHeard();
    const keyShown = this.song.keyShown();
    assert(keyCharted && keyHeard && keyShown, 'updateChordMeta with null key');

    this.cells.forEach((cell) => {
      cell.updateChordHeardAndShown({ keyCharted, keyHeard, keyShown });
      cell.updateRepeated();
    });

    // update chord volta
    const usedVolta = new Set<number>();
    this.cells.forEach((cell) => {
      cell.volta = cell.volta?.filter((v) => !usedVolta.has(v) && v <= this.repetitions);
      if (cell.volta?.length === 0 || this.repetitions == 1) cell.volta = undefined;
      cell.volta?.forEach((v) => usedVolta.add(v));
      cell.updateRepConstraint();
    });

    // update time signatures
    this.cells.forEach((cell) => {
      cell.effectiveChangeToTimeSignature = undefined;
      cell.effectiveTimeSignature =
        cell.timeSig ?? cell.prev?.effectiveTimeSignature ?? this.song.timeSignature();
      if (cell.timeSig) {
        const cellToUpdate = cell;
        if (
          cellToUpdate.prev?.effectiveTimeSignature == cell.timeSig ||
          (!cellToUpdate.prev && this.song.timeSignature() == cell.timeSig)
        )
          cell.timeSig = undefined;
        cellToUpdate.effectiveChangeToTimeSignature = cell.timeSig;
      }
    });

    // recalculate bar layout and add bar groupings
    const cellsPerBar = this.song.timeSignature() == '9/8' ? 3 : 2;

    this.cells.forEach((cell) => {
      cell.layout.barIndex =
        typeof cell.prev?.layout.barIndex == 'number' ? cell.prev.layout.barIndex : -1;

      if (!cell.prev || cell.prev.layout.barEnd) {
        cell.layout.barPos = 0;
        cell.layout.barStart = true;
        cell.layout.barIndex += 1;
      } else {
        cell.layout.barPos = cell.prev.layout.barPos + 1;
      }

      if (
        cell.layout.barPos === cellsPerBar - 1 ||
        cell.half ||
        cell.next?.half ||
        cell.next?.effectiveChangeToTimeSignature
      ) {
        cell.layout.barEnd = true;
      }
    });

    // recalculate line wrapping
    const defaultLineLength = Math.ceil(8 / cellsPerBar);

    let currentLine = 0;
    let currentColumn = 0;

    let cell = this.cells[0];

    const bars: Cell[][] = [];

    while (cell) {
      const cellsInBar: Cell[] = [];
      do {
        cellsInBar.push(cell);
        cell = cell.next;
      } while (cell && !cell.layout.barStart);
      bars.push(cellsInBar);
    }

    bars.forEach((cellsInBar) => {
      if (
        currentColumn + cellsInBar.length >
        (this.lineLengths[currentLine] || defaultLineLength) * cellsPerBar
      ) {
        currentLine += 1;
        currentColumn = 0;
      }

      cellsInBar.forEach((cell) => {
        if (cell.lineLengthOverride) this.lineLengths[currentLine] = cell.lineLengthOverride;
        delete cell.lineLengthOverride;
      });

      cellsInBar.forEach((cell) => {
        cell.layout.lineNumber = currentLine;
        cell.layout.column = currentColumn;
        if (currentColumn == 0) {
          cell.layout.lineStart = true;
          if (cell.prev) cell.prev.layout.lineEnd = true;
        }
        currentColumn += 1;
      });
    });

    const lastCell = this.cells[this.cells.length - 1];
    const lineCount = (lastCell?.layout.lineNumber || 0) + 1;
    this.lineLengths = Array.from({ length: lineCount }, (_, i) => {
      return this.lineLengths[i] || defaultLineLength;
    });
    if (lastCell?.layout.barEnd) {
      lastCell.layout.lineEnd = true;
    }
  }

  setName(name?: string): void {
    this.song.history.takeSnapshot();
    this.name = name?.trim();
    this.song.processChanges();
  }

  setReps(reps: number | string): void {
    this.song.history.takeSnapshot();
    this.repetitions = Math.max(Math.min(+reps || 1, 8), 1);
    this.song.processChanges();
  }

  setType(type?: typeof this.type): void {
    this.song.history.takeSnapshot();
    this.type = type || undefined;
    this.song.processChanges();
  }

  static blank(song: Song): Section {
    const section = new Section(song);
    const key = song.key();
    assert(key, 'key for new section');
    section.cells.push(
      new Cell({
        chord: key,
      })
    );
    section.repetitions = 1;
    return section;
  }
}
