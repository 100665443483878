import { FlowRouter } from 'meteor/ostrio:flow-router-extra';

Template.publicSongListPage.onCreated(() => {
  window.prerenderReady = false;
});

Template.publicSongListPage.onRendered(() => {
  var instance = Template.instance();

  instance.autorun(() => {
    if (instance.subscriptionsReady() && FlowRouter.subsReady()) {
      Meteor.setTimeout(() => (window.prerenderReady = true), 500);
    }
  });

  $(document.scrollingElement).animate(
    {
      scrollTop: 0,
    },
    300
  );
});

Template.publicSongListPage.helpers({});

Template.publicSongListPage.events({});
