import { ReactiveVar } from 'meteor/reactive-var';
import { Band } from '@/band/Band';
import { BandPresets } from '@/band/presets/BandPresets';
import type { Song } from '@/chart/Song';
import type { Medley } from '@/medleys/Medley';
import { migrateOldKeyStyle } from '@/music/migrateOldKeyStyle';

export class MedleySongPrefs {
  private song: Song;
  private medley: Medley;

  band: Band;

  constructor(song: Song, medley: Medley) {
    this.song = song;
    this.medley = medley;

    this.band = new Band(song);
    this.band.addListener('persistPlease', (_changes: Record<string, unknown>) => {
      void this.medley.persistChanges();
    });
  }

  load(songPrefs: Omit<SerializedMedleySong, '_id' | 'name'>): void {
    this._key.set(songPrefs.key ? migrateOldKeyStyle(songPrefs.key) : undefined);
    this._reps.set(songPrefs.reps || 1);
    this._bpmX.set(songPrefs.bpmX || 1);
    this._skipIntros.set(songPrefs.skipIntros);
    this._skipOutros.set(songPrefs.skipOutros);
    const preset = BandPresets.findById(songPrefs.presetId || '');
    if (preset) {
      this.band.loadPreset(preset);
    } else {
      this.band.loadSettings(songPrefs.band);
    }
  }

  //#region === Key ===

  private _key = new ReactiveVar<Key | undefined>(undefined);
  key(): Key | undefined {
    return this._key.get();
  }

  setKey(key: Key): void {
    this._key.set(key);
    this.song.sections.updateMeta();
    this.song.relinearize();
    void this.medley.persistChanges();
  }

  //#endregion ^ Key

  //#region === Reps ===

  private _reps = new ReactiveVar<number>(1);
  reps(): number {
    return this._reps.get();
  }

  setReps(reps: number): void {
    this._reps.set(reps);
    void this.medley.persistChanges();
  }

  //#endregion ^ Reps

  //#region === BPM Multiplier ===

  private _bpmX = new ReactiveVar<number>(1);
  bpmX(): number {
    return this._bpmX.get();
  }

  setBpmX(multiplier: number): void {
    if (typeof multiplier == 'string') multiplier = +multiplier;
    multiplier = Math.min(Math.max(multiplier, 0.2), 5);
    this._bpmX.set(multiplier);
    void this.medley.persistChanges();
  }

  adjustBpmX(amount: number): void {
    this.setBpmX(this.bpmX() + amount);
  }

  //#endregion ^ BPM

  //#region === Section skipping ===

  private _skipIntros = new ReactiveVar<boolean | undefined>(undefined);
  private _skipOutros = new ReactiveVar<boolean | undefined>(undefined);

  setSkipIntros(value: boolean | undefined): void {
    this._skipIntros.set(value == this.song.index() > 0 ? undefined : value);
    void this.medley.persistChanges();
  }
  setSkipOutros(value: boolean | undefined): void {
    this._skipOutros.set(
      value == this.song.index() < this.medley.songs.reactive().length - 1 ? undefined : value
    );
    void this.medley.persistChanges();
  }

  skipIntros(): boolean | undefined {
    return this._skipIntros.get();
  }

  skipOutros(): boolean | undefined {
    return this._skipOutros.get();
  }

  skippingIntros(): boolean {
    return (
      this._skipIntros.get() ??
      (this.song.sections.reactive().some((s) => s.type == 'i') && this.song.index() > 0)
    );
  }
  skippingOutros(): boolean {
    return (
      this._skipOutros.get() ??
      (this.song.sections.reactive().some((s) => s.type == 'o') &&
        this.song.index() < this.medley.songs.reactive().length - 1)
    );
  }
}
