<script lang="ts">
  import type { GuitarBassRunsSetting } from '@/band/instruments/guitar/settings/GuitarBassRunsSetting';
  import { swingOptions } from '@/band/swingOptions';
  import HorizontalRadioButtons from '@/band/ui/components/HorizontalRadioButtons.svelte';
  import PanelOpenerChevron from '@/band/ui/components/PanelOpenerChevron.svelte';
  import PanelSettingSection from '@/band/ui/components/PanelSettingSection.svelte';
  import PrettySlider from '@/band/ui/components/PrettySlider.svelte';
  import GuitarBassRunsQuarterEighthBalanceUI from '@/band/ui/panels/GuitarBassRunsQuarterEighthBalanceUI.svelte';
  import PanelModalLayout from '@/band/ui/panels/PanelModalLayout.svelte';
  import SwingPanel from '@/band/ui/panels/SwingPanel.svelte';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import type { Chord } from '@/music/Chord';
  import { MultiPanelModalController } from '@/ui/settings/MultiPanelModalController.svelte';
  import ToggleSwitch from '@/ui/ToggleSwitch.svelte';

  const modalController = MultiPanelModalController.current();

  const bandMenu = getBandMenuState();
  let band = $derived($bandMenu.band);

  let guitarCore = $derived(
    $bandMenu.guitar.coreOrFallbackForTimeSignature($bandMenu.timeSignature)
  );

  let setting = $derived($bandMenu.guitarSettings.bassRuns);

  let swing: ReturnType<typeof $bandMenu.band.swing> = $state(swingOptions['0']);
  trackerEffect(() => (swing = $bandMenu.band.swing()));

  const maxEagerness = 4;

  function isSupportedChord(chord: Chord) {
    const interval = chord.getInterval();
    return (
      !interval.accidental &&
      (([1, 4, 5, 2, 6].includes(interval.number) && (!chord.type || chord.type === '7')) ||
        ([2, 6].includes(interval.number) && chord.type === 'm'))
    );
  }

  let currentSongHasUnsupportedChords = $state(false);
  trackerEffect(() => {
    const chordsHeard = band.song.sections.flatMap((s) => s.cells).map((c) => c.chordHeard);
    currentSongHasUnsupportedChords =
      band.song.key()?.includes('m') ||
      (![1, 4, 5].every((n) =>
        chordsHeard.some((c) => c.getInterval().number == n && isSupportedChord(c))
      ) &&
        chordsHeard.some((c) => !isSupportedChord(c)));
  });

  function toggleDescendingRuns(value: boolean) {
    $bandMenu.guitar.setSetting('bassRuns', setting.withDescendingRuns(value));
  }

  const majorFeelOptions: {
    caption: string;
    value: GuitarBassRunsSetting['majorFeel'];
  }[] = [
    { value: 'sm', caption: 'Major' },
    { value: 'mm', caption: 'Major-ish' },
    { value: 'b', caption: 'Bluesy' },
  ];
</script>

<PanelModalLayout>
  {#snippet header()}
    <header class="panelHeader">
      Strum Machine can automatically play semi‑random bass runs for chord changes.
    </header>
  {/snippet}

  {#snippet main()}
    <div class="p-3 pt-4">
      <PanelSettingSection
        title="Number of bass runs"
        helpText="This controls how often the guitar decides to play bass notes that lead into the next chord."
      >
        <div class="flex items-center gap-4 px-4 py-2">
          <div class="basis-12 text-center text-gray-600">None</div>
          <div class="flex-1">
            <PrettySlider
              min={0}
              max={maxEagerness}
              step={1}
              value={setting.eagerness * maxEagerness}
              tickCount={maxEagerness + 1}
              thumbDiameter={2}
              tickHeight={0.175}
              on:change={({ detail: value }) => {
                $bandMenu.guitar.setSetting(
                  'bassRuns',
                  setting.withEagerness(value / maxEagerness)
                );
              }}
            />
          </div>
          <div class="basis-12 text-center text-gray-600">Tons</div>
        </div>
      </PanelSettingSection>

      <div
        class="transition-opacity duration-500"
        class:opacity-20={setting.eagerness == 0}
        inert={setting.eagerness == 0}
      >
        {#if currentSongHasUnsupportedChords}
          <div
            class="mt-4 rounded-md border border-muted-150 bg-muted-100 px-3 py-2 text-sm text-gray-600"
          >
            Note: For now, bass runs are only available between <span class="sm-band:hidden"
              >a few</span
            > common chords in major keys.
          </div>
        {/if}

        {#if !band.currentKey()?.includes('m')}
          <div class="pt-5">
            <PanelSettingSection
              title="Melodic “feel” (scale used)"
              helpText="It’s better to have the guitar use bass runs that use notes in the scale which match the feel of the song. For songs that are bluesy or “modal” (in the bluegrass/old-time sense), use the “bluesy” setting, which means the guitar will use more blue notes from the scale."
              whiteBg={false}
            >
              <HorizontalRadioButtons
                options={majorFeelOptions}
                selectedValue={setting.majorFeel}
                on:select={({ detail: value }) => {
                  $bandMenu.guitar.setSetting('bassRuns', setting.withMajorFeel(value));
                }}
              />
            </PanelSettingSection>
          </div>
        {/if}

        <div class="pt-5">
          <PanelSettingSection
            title="Types of runs to play"
            helpHTML="Here you can change how much each type of run is used:<ul>
                <li class='mt-2'><b>Quarter notes:</b> Runs with notes that land on the main “boom chuck” beats of the strum.</li>
                <li class='mt-2'><b>Eighth notes:</b> These runs use shorter notes, and therefore have more of a feeling of movement to them.</li>
              </ul>"
          >
            <GuitarBassRunsQuarterEighthBalanceUI />
          </PanelSettingSection>
        </div>

        <div
          class="mt-2 divide-y divide-panelWellBorder rounded-lg border border-panelWellBorder bg-white"
        >
          <div class="flex px-4 py-3">
            <div class="flex-1 text-gray-700">Include descending runs</div>
            <div>
              <ToggleSwitch
                checked={setting.useDescendingRuns}
                on:change={({ detail: value }) => toggleDescendingRuns(value)}
              />
            </div>
          </div>
        </div>

        {#if guitarCore.swingCategory == 'none'}
          <div class="pt-5">
            <PanelSettingSection
              title="Bounciness (swing) for eighth notes"
              helpText="Eighth note runs will be played with the specified amount of swing feel. This setting is only relevant if you have eighth notes enabled in the “Types of runs to play” section. (You won’t see this for strumming patterns that use upstrokes because the overall upstroke swing setting is used instead.)"
              whiteBg={false}
            >
              <div class="overflow-hidden rounded-lg border border-panelWellBorder bg-white">
                <button
                  class="colors-panelOpener relative block w-full py-2.5 pl-3.5 pr-9 text-left group-first:rounded-t-lg group-last:rounded-b-lg"
                  onclick={() =>
                    modalController.zoomInto({ component: SwingPanel, title: 'Upstroke Swing' })}
                >
                  {swing.name}
                  <PanelOpenerChevron direction="right" />
                </button>
              </div>
            </PanelSettingSection>
          </div>
        {/if}
      </div>
    </div>
  {/snippet}
</PanelModalLayout>
