Template.policyModal.onCreated(() => {
  var instance = Template.instance();
  instance.showTerms = new ReactiveVar();
  instance.ready = new ReactiveVar();
  if (instance.data.policy == 'privacy') {
    // we're no longer doing async import of the Blaze template, since meteor-vite doesn't support it
    instance.ready.set(true);
  }
  if (instance.data.policy == 'terms') {
    instance.showTerms.set(true);
  }
});

Template.policyModal.events({
  'click .js-showCompletePolicy': function (event, instance) {
    instance.ready.set(false);
    instance.ajaxContent = true;
    var url;
    if (instance.data.policy == 'privacy')
      url = 'https://www.iubenda.com/api/privacy-policy/7814360/no-markup';
    jQuery.getJSON(url, (json, textStatus) => {
      instance.$('.modal-body').html(json.content);
      instance.ready.set(true);
    });
    return false;
  },
});

Template.policyModal.helpers({
  modalTitle() {
    if (this.policy == 'privacy') return 'Privacy Policy';
    if (this.policy == 'terms') return 'Terms of Use';
  },
  templateName() {
    if (this.policy == 'privacy') return 'privacyPolicy';
  },
  showTemplate() {
    return Template.instance().ready.get() && !Template.instance().ajaxContent;
  },
  showTerms() {
    return Template.instance().showTerms.get();
  },
});
