import { Meteor } from 'meteor/meteor';
import bassSampleDefinitions from '@/audio/samples/definitions/bassSampleDefinitions';
import clickSampleDefinitions from '@/audio/samples/definitions/clickSampleDefinitions';
import countInsSampleDefinitions from '@/audio/samples/definitions/countSampleDefinitions';
import percussionSampleDefinitions from '@/audio/samples/definitions/feetSampleDefinitions';
import guitarSampleDefinitions from '@/audio/samples/definitions/guitarSampleDefinitions';
import mandolinSampleDefinitions from '@/audio/samples/definitions/mandolinSampleDefinitions';
import { unique } from '@/utilities/unique';

export default function getAllAudioPackageURLs() {
  const filetype = 'mp3';
  const basePath = Meteor.isCordova
    ? '/audio/'
    : 'https://storage.googleapis.com/sm-audio/packages/';

  const packageBaseNames = unique(
    [
      countInsSampleDefinitions,
      bassSampleDefinitions,
      guitarSampleDefinitions,
      mandolinSampleDefinitions,
      percussionSampleDefinitions,
      clickSampleDefinitions,
    ]
      .map((sampleDefs) => sampleDefs.map((def) => def.packageId))
      .flat()
  );

  return packageBaseNames.map((baseName) => `${basePath}${baseName}_${filetype}.audiopkg`);
}
