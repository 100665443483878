import type { ChordTypeCode } from '@/music/ChordTypeCode';

export const chordAriaLabels: Record<ChordTypeCode, string> = {
  '': '',
  '6': 'six',
  '^': 'major seven',
  '^9': 'major nine',
  '^13': 'major thirteen',
  '69': 'six nine',
  // 'add9': 'add nine',

  '7': 'seven',
  '9': 'nine',
  '13': 'thirteen',
  '7s': 'seven sus',
  '11': 'eleven',
  '9s': 'nine sus',
  '13s': 'thirteen sus',

  'm': 'minor',
  'm7': 'minor seven',
  'm6': 'minor six',
  'm^': 'minor major seven',
  'm9': 'minor nine',
  'm11': 'minor eleven',
  'mb6': 'minor flat 6',
  'm^9': 'minor major nine',
  'm69': 'minor six nine',
  // 'madd9': 'minor add nine',
  // 'm2': 'minor two',
  // 'm4': 'minor four',

  'o': 'diminished', // make this explicitly a dim7?
  '0': 'minor seven flat five', // what to do about half-diminished?
  '+': 'augmented',
  'o^': 'diminished major seven',
  'o9': 'diminished nine',
  '+7': 'augmented seventh',

  '5': 'five',
  '4': 'sus',
  '2': 'sus two',

  // TODO: alterations
  '7b9': 'seven flat nine',
  '7#11': 'seven sharp eleven',
  '7#9': 'seven sharp nine',
  '7#5': 'seven sharp five',
  '7b13': 'seven flat thirteen',
};
