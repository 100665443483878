import type { SampleDefinition } from '@/audio/engine/base/Sample';

function definePackage(packageId: string, ids: string[], preroll = 0.02): SampleDefinition[] {
  return ids.map((id) => {
    return { id, packageId, preroll };
  });
}

function splitFileList(files: string): string[] {
  return files.split('\n').filter((line) => line.length > 0);
}

export default [
  ...definePackage(
    'newguitar-strikes',
    splitFileList(`
g+++++0 S
g+++++1 S
g+++++2 S
g+++++3 S
g+++++4 S
g+++++5 S
g+++++6 S
g+++++7 S
g+++++8 S
g+++++9 S
g+++++D S
g+++++a S
g+++++b S
g+++++c S
g++++0+ S
g++++1+ S
g++++2+ S
g++++3+ S
g++++4+ S
g++++5+ S
g++++6+ S
g++++7+ S
g++++8+ S
g++++9+ S
g++++D+ S
g++++a+ S
g++++b+ S
g++++c+ S
g+++0++ S
g+++1++ S
g+++2++ S
g+++3++ S
g+++4++ S
g+++5++ S
g+++6++ S
g+++7++ S
g+++8++ S
g+++9++ S
g++0+++ S
g++1+++ S
g++2+++ S
g++3+++ S
g++4+++ S
g++5+++ S
g++6+++ S
g++7+++ S
g++8+++ S
g++9+++ S
g++a+++ S
g++b+++ S
g++c+++ S
g+0++++ S
g+1++++ S
g+2++++ S
g+3++++ S
g+4++++ S
g+5++++ S
g+6++++ S
g+7++++ S
g+8++++ S
g+9++++ S
g+a++++ S
g+b++++ S
g+c++++ S
g0+++++ S
g1+++++ S
g2+++++ S
g3+++++ S
g4+++++ S
g5+++++ S
g6+++++ S
g7+++++ S
g8+++++ S
g9+++++ S
gD+++++ S
ga+++++ S
gb+++++ S
gc+++++ S
`),
    0.05
  ),

  ...definePackage(
    'newguitar-foley',
    splitFileList(`
g++++DD F
g++++UU F
g+++DD+ F
g+++DDD F
g+++UU+ F
g+++UUU F
g++DD++ F
g++DDD+ F
g++DDDD F
g++UU++ F
g++UUU+ F
g++UUUU F
g+DDD++ F
g+DDDD+ F
g+UUU++ F
g+UUUU+ F
gDD++++ F
gDDD+++ F
gDDDD++ F
gUUU+++ F
gUUUU++ F
`),
    0.01
  ),

  ...definePackage(
    'newguitar-foley',
    splitFileList(`
g+++++F F
g++++F+ F
g+++F++ F
g++F+++ F
g+F++++ F
gF+++++ F
`),
    0.03
  ),

  ...definePackage(
    'newguitar-chops',
    splitFileList(`
g+++++0 C
g+++++1 C
g+++++2 C
g+++++3 C
g+++++4 C
g+++++5 C
g+++++6 C
g+++++7 C
g+++++8 C
g+++++9 C
g+++++D C
g+++++a C
g+++++b C
g+++++c C
g++++0+ C
g++++1+ C
g++++2+ C
g++++3+ C
g++++4+ C
g++++5+ C
g++++6+ C
g++++7+ C
g++++8+ C
g++++9+ C
g++++D+ C
g++++a+ C
g++++b+ C
g++++c+ C
g+++0++ C
g+++1++ C
g+++2++ C
g+++3++ C
g+++4++ C
g+++5++ C
g+++6++ C
g+++7++ C
g+++8++ C
g+++9++ C
g+++a++ C
g+++b++ C
g+++c++ C
g++0+++ C
g++1+++ C
g++2+++ C
g++3+++ C
g++4+++ C
g++5+++ C
g++6+++ C
g++7+++ C
g++8+++ C
g++9+++ C
g++a+++ C
g++b+++ C
g++c+++ C
g+0++++ C
g+1++++ C
g+2++++ C
g+3++++ C
g+4++++ C
g+5++++ C
g+6++++ C
g+7++++ C
g+8++++ C
g+9++++ C
g+a++++ C
g+b++++ C
g+c++++ C
g0+++++ C
g1+++++ C
g2+++++ C
g3+++++ C
g4+++++ C
g5+++++ C
g6+++++ C
g7+++++ C
g8+++++ C
g9+++++ C
gD+++++ C
ga+++++ C
gb+++++ C
gc+++++ C
`),
    0.05
  ),

  ...definePackage(
    'newguitar-zed',
    splitFileList(`
g+++++0 Z
g+++++1 Z
g+++++2 Z
g+++++3 Z
g+++++4 Z
g+++++5 Z
g+++++6 Z
g+++++7 Z
g+++++8 Z
g+++++9 Z
g+++++D Z
g+++++a Z
g+++++b Z
g+++++c Z
g++++0+ Z
g++++1+ Z
g++++2+ Z
g++++3+ Z
g++++4+ Z
g++++5+ Z
g++++6+ Z
g++++7+ Z
g++++8+ Z
g++++9+ Z
g++++D+ Z
g++++a+ Z
g++++b+ Z
g++++c+ Z
g+++0++ Z
g+++1++ Z
g+++2++ Z
g+++3++ Z
g+++4++ Z
g+++5++ Z
g+++6++ Z
g+++7++ Z
g+++8++ Z
g+++9++ Z
g+++a++ Z
g+++b++ Z
g+++c++ Z
g++0+++ Z
g++1+++ Z
g++2+++ Z
g++3+++ Z
g++4+++ Z
g++5+++ Z
g++6+++ Z
g++7+++ Z
g++8+++ Z
g++9+++ Z
g++a+++ Z
g++b+++ Z
g++c+++ Z
g+0++++ Z
g+1++++ Z
g+2++++ Z
g+3++++ Z
g+4++++ Z
g+5++++ Z
g+6++++ Z
g+7++++ Z
g+8++++ Z
g+9++++ Z
g+a++++ Z
g+b++++ Z
g+c++++ Z
`),
    0.05
  ),

  ...definePackage(
    'newguitar-zedE',
    splitFileList(`
g0+++++ Z
g1+++++ Z
g2+++++ Z
g3+++++ Z
g4+++++ Z
g5+++++ Z
g6+++++ Z
g7+++++ Z
g8+++++ Z
g9+++++ Z
gD+++++ Z
`),
    0.05
  ),

  ...definePackage(
    'newguitar-zedalts',
    splitFileList(`
g+++++0 Z^
g+++++0 Z^^
g+++++1 Z^
g+++++1 Z^^
g+++++2 Z^
g+++++2 Z^^
g+++++3 Z^
g+++++3 Z^^
g+++++D Z^
g+++++D Z^^
g++++0+ Z^
g++++0+ Z^^
g++++1+ Z^
g++++1+ Z^^
g++++2+ Z^
g++++2+ Z^^
g++++3+ Z^
g++++3+ Z^^
g++++4+ Z^
g++++4+ Z^^
g++++D+ Z^
g++++D+ Z^^
g+++0++ Z^
g+++0++ Z^^
g+++1++ Z^
g+++1++ Z^^
g+++2++ Z^
g+++2++ Z^^
g+++3++ Z^
g+++3++ Z^^
g+++4++ Z^
g+++4++ Z^^
g++0+++ Z^
g++0+++ Z^^
g++1+++ Z^
g++1+++ Z^^
g++2+++ Z^
g++2+++ Z^^
g++3+++ Z^
g++3+++ Z^^
g++4+++ Z^
g++4+++ Z^^
g+0++++ Z^
g+0++++ Z^^
g+1++++ Z^
g+1++++ Z^^
g+2++++ Z^
g+2++++ Z^^
g+3++++ Z^
g+3++++ Z^^
`),
    0.05
  ),

  /*
  ...definePackage(
    'newguitar-muted',
    splitFileList(`
g+++++0 M
g+++++1 M
g+++++2 M
g+++++3 M
g+++++4 M
g+++++5 M
g+++++6 M
g+++++7 M
g+++++8 M
g+++++D M
g++++0+ M
g++++1+ M
g++++2+ M
g++++3+ M
g++++4+ M
g++++5+ M
g++++6+ M
g++++7+ M
g++++8+ M
g++++D+ M
g+++0++ M
g+++1++ M
g+++2++ M
g+++3++ M
g+++4++ M
g+++5++ M
g+++6++ M
g+++7++ M
g+++8++ M
g+++9++ M
g++0+++ M
g++1+++ M
g++2+++ M
g++3+++ M
g++4+++ M
g++5+++ M
g++6+++ M
g++7+++ M
g++8+++ M
g++9+++ M
g++a+++ M
g++b+++ M
g++c+++ M
g+0++++ M
g+1++++ M
g+2++++ M
g+3++++ M
g+4++++ M
g+5++++ M
g+6++++ M
g+7++++ M
g+8++++ M
g+9++++ M
g+a++++ M
g+b++++ M
g+c++++ M
g0+++++ M
g1+++++ M
g2+++++ M
g3+++++ M
g4+++++ M
g5+++++ M
g6+++++ M
g7+++++ M
g8+++++ M
g9+++++ M
gD+++++ M
ga+++++ M
gb+++++ M
gc+++++ M
`),
    0.05
  ),
  */

  // that's all!
]
  .map((def) => ({ ...def, packageId: 'newguitar_2022-11' }))
  .concat([
    ...definePackage(
      'newguitar-lifts_2023-10',
      splitFileList(`
g+++++0 L
g+++++1 L
g+++++2 L
g+++++3 L
g+++++4 L
g+++++5 L
g+++++6 L
g+++++7 L
g+++++8 L
g+++++9 L
g+++++D L
g+++++a L
g+++++b L
g+++++c L
g++++0+ L
g++++1+ L
g++++2+ L
g++++3+ L
g++++4+ L
g++++5+ L
g++++6+ L
g++++7+ L
g++++8+ L
g++++9+ L
g++++D+ L
g++++a+ L
g++++b+ L
g++++c+ L
g+++0++ L
g+++1++ L
g+++2++ L
g+++3++ L
g+++4++ L
g+++5++ L
g+++6++ L
g+++7++ L
g+++8++ L
g+++9++ L
g+++a++ L
g+++b++ L
g+++c++ L
g++0+++ L
g++1+++ L
g++2+++ L
g++3+++ L
g++4+++ L
g++5+++ L
g++6+++ L
g++7+++ L
g++8+++ L
g++9+++ L
g++a+++ L
g++b+++ L
g++c+++ L
g+0++++ L
g+1++++ L
g+2++++ L
g+3++++ L
g+4++++ L
g+5++++ L
g+6++++ L
g+7++++ L
g+8++++ L
g+9++++ L
g+a++++ L
g+b++++ L
g+c++++ L
g0+++++ L
g1+++++ L
g2+++++ L
g3+++++ L
g4+++++ L
g5+++++ L
g6+++++ L
g7+++++ L
g8+++++ L
g9+++++ L
gD+++++ L
ga+++++ L
gb+++++ L
gc+++++ L
`),
      0.0
    ),
  ]);
