<script lang="ts">
  import HorizontalRadioButtons from '@/band/ui/components/HorizontalRadioButtons.svelte';
  import PanelHeading from '@/band/ui/components/PanelHeading.svelte';
  import PanelSettingSection from '@/band/ui/components/PanelSettingSection.svelte';
  import PanelModalLayout from '@/band/ui/panels/PanelModalLayout.svelte';
  import { renderChordAsHtml } from '@/chart/ui/renderChordAsHtml';
  import { crntPreferences } from '@/client/crntPreferences.svelte';
  import { Crnt } from '@/Crnt';
  import { Chord } from '@/music/Chord';
  import UserPreferences from '@/user/UserPreferences';

  let chordSystem = $derived(crntPreferences().chordSystem);

  const exampleFoursome: Chord[] = [
    new Chord('C', 'C'),
    new Chord('Am', 'C'),
    new Chord('F', 'C'),
    new Chord('G', 'C'),
  ].map((chord) => chord.transpose(Crnt.song()?.key() ?? 'C'));

  const chordSystemOptions = [
    { caption: 'Letters', value: 'letters' },
    { caption: 'Numbers', value: 'numbers' },
    { caption: 'Roman', value: 'roman' },
  ] as const;
</script>

<PanelModalLayout>
  {#snippet header()}
    <header>
      <PanelHeading title="Chart Display" padX={6}>
        {#snippet text()}
          Changes to these settings will apply globally.
        {/snippet}
      </PanelHeading>
    </header>
  {/snippet}

  {#snippet main()}
    <div class="relative">
      <div class="mb-2 mt-6 flex justify-center">
        <div
          style="box-shadow: 4px 6px 12px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.05), -4px -6px 12px 0px rgba(255, 255, 255, 0.95); border-radius: 12px; overflow: hidden; border: solid 2px var(--gray-100); background: white; width: 100%; margin: 0 2rem;"
        >
          <div class="clearfix my-2 w-full px-3.5" inert>
            {#each exampleFoursome as chord, i (i)}
              <div
                class="ChartCell whole"
                class:bar-start={i % 2 == 0}
                class:bar-end={i % 2 == 1}
                class:line-end={i == 3}
                style="width: 24.9%; --chord-font-size: 1.75rem;"
              >
                <div class="ChartCell__walls">
                  <div class="ChartCell__beat">
                    <div class="flex items-center justify-center">
                      <span class="ChartChord"
                        >{@html renderChordAsHtml(chord, { system: chordSystem })}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            {/each}
          </div>
        </div>
      </div>
      <div class="px-3 pb-3 pt-4">
        <PanelSettingSection
          title="Chord System"
          helpText="Roman numerals are not supported in the editor."
          whiteBg={false}
        >
          <HorizontalRadioButtons
            options={chordSystemOptions}
            selectedValue={chordSystem}
            on:select={({ detail: value }) => {
              UserPreferences.set('chordSystem', value);
            }}
          />
        </PanelSettingSection>

        <div class="mb-4"></div>
      </div>
    </div>
  {/snippet}
</PanelModalLayout>
