<script lang="ts">
  import type { Section } from '@/chart/Section.svelte';
  import type { Song } from '@/chart/Song';
  import SectionChords from '@/chart/ui/SectionChords.svelte';
  import SongSectionHeading from '@/chart/ui/SongSectionHeading.svelte';
  import { Conductor } from '@/Conductor';
  import { Crnt } from '@/Crnt';
  import SectionEditBar from '@/editor/ui/SectionEditBar.svelte';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';

  interface Props {
    section: Section;
    sectionIndex: number;
    song: Song;
    editMode: boolean;
  }

  let { section, sectionIndex, song, editMode }: Props = $props();

  let hideCells = $derived(
    !!(
      section.song.medleyPrefs &&
      ((section.type == 'i' && section.song.medleyPrefs.skippingIntros()) ||
        (section.type == 'o' && section.song.medleyPrefs.skippingOutros()))
    )
  );

  let loopExists = $state(false);
  trackerEffect(() => (loopExists = song.loop.exists()));

  let playingThisSong = $state(false);
  trackerEffect(
    () =>
      (playingThisSong =
        !Crnt.medley() || Conductor.displayState.position()?.song() === song.index())
  );

  let currentSectionIndex: number | undefined = $state(0);
  trackerEffect(
    () =>
      (currentSectionIndex = (
        Conductor.displayState.position() || Conductor.displayState.startingPosition()
      )?.section())
  );

  let round = $state(0);
  trackerEffect(() => (round = Conductor.displayState.position()?.round() || 0));

  let maxRound: number | undefined = $state();
  trackerEffect(() => (maxRound = song.maxRound()));

  let lastRep = $derived(typeof maxRound == 'number' && round >= maxRound);

  let skipping = $derived.by(() => {
    if (loopExists) return false;
    if (playingThisSong && currentSectionIndex == sectionIndex) return false;
    if (
      section.type == 'i' &&
      playingThisSong &&
      (round > 0 || (currentSectionIndex ?? 0) > sectionIndex)
    )
      return true;
    if (section.type == 'o' && !editMode && !lastRep) return true;
    if (section.type == 'sf' && !editMode && playingThisSong && round === 0) return true;
    if (section.type == 'sl' && !editMode && lastRep) return true;
    return false;
  });
</script>

<section
  class="songSection pb-6 transition-opacity duration-200"
  class:skipping
  data-sectionIndex={sectionIndex}
>
  {#if editMode}
    <SectionEditBar {section} {sectionIndex} />
  {:else}
    <SongSectionHeading {section} {sectionIndex} />
  {/if}

  {#if !hideCells}
    <div
      class="clear-both m-0 w-full cursor-default pt-4 after:clear-both after:table focus:outline-none"
      class:pt-3={editMode}
      tabindex="-1"
      role="region"
      aria-roledescription="chord chart"
      aria-label="section {sectionIndex + 1}"
    >
      <SectionChords {section} {song} {editMode} />
    </div>
  {/if}
</section>

<style>
  .songSection.skipping {
    opacity: 0.5;
    @media print {
      opacity: 1;
    }
  }
</style>
