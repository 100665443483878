<script lang="ts" module>
  export type TypeGroupKey = 'maj' | 'dom' | 'min' | 'dim' | 'no3' | 'alt';

  const typeGroups: { key: TypeGroupKey; lines: ChordTypeCode[][] }[] = [
    {
      key: 'maj',
      lines: [
        ['', '6', '^'],
        ['69', '^9', '^13'],
      ],
    },
    {
      key: 'dom',
      lines: [
        ['7', '9', '13', '7s'],
        ['11', '9s', '13s'],
      ],
    },
    {
      key: 'min',
      lines: [
        ['m', 'm7', 'm6'],
        ['m^', 'm9', 'm69'],
        ['m^9', 'm11', 'mb6'],
      ],
    },
    {
      key: 'dim',
      lines: [
        ['o', '0', '+'],
        // ['o9', 'o^', '+7'],
      ],
    },
    {
      key: 'no3',
      lines: [
        ['5', '4', '2'],
        // ['add9', 'madd9'],
      ],
    },
    {
      key: 'alt',
      lines: [
        ['7b9', '7#9', '7#11'],
        ['7b5', '7#5', '7b13'],
        // ['7b9s', '9#11', '13b9'],
        // ['^7#11', '^7#5', '13#11'],
        // The following I almost certainly don't want to include:
        // ['7b9#5', '7#9#5', '7b9b13', '7b9b5', '7#9b5'],
      ],
    },
  ];
</script>

<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import { Chord } from '@/music/Chord';
  import type { ChordTypeCode } from '@/music/ChordTypeCode';
  import { slideFade } from '@/ui/svelte-transitions';
  import UserPreferences from '@/user/UserPreferences';

  const dispatch = createEventDispatcher<{ select: NonNullable<Chord['type']> }>();

  interface Props {
    selectedChords: Chord[] | undefined;
  }

  let { selectedChords }: Props = $props();

  let singleSelectedType = $derived(
    selectedChords?.every((c) => c.type == selectedChords?.[0]?.type)
      ? selectedChords[0]?.type
      : undefined
  );

  let expandedTypeGroups: TypeGroupKey[] = $state([]);
  trackerEffect(
    () => (expandedTypeGroups = UserPreferences.get('expandedChordTypeGroups') as TypeGroupKey[])
  );

  function toggleTypeGroup(typeGroup: 'maj' | 'dom' | 'min' | 'dim' | 'no3' | 'alt') {
    const newArray = expandedTypeGroups.includes(typeGroup)
      ? expandedTypeGroups.filter((t) => t != typeGroup)
      : [...expandedTypeGroups, typeGroup];
    UserPreferences.set('expandedChordTypeGroups', newArray);
  }

  function typeLabelFor(type: ChordTypeCode) {
    return (
      typeLabels[type] ??
      type
        .replace(/b/g, '♭')
        .replace(/#/g, '♯')
        .replace(/\^$/, '^7')
        .replace('^', 'Δ')
        .replace(/^o/, 'dim')
        .replace('+', 'aug')
        .replace('69', '6/9')
        .replace('s', 'sus')
    )
      .replace(/(\d)(sus)/g, '$1<span style="font-size: 0.9em;">$2</span>')
      .replace(
        /(♯|♭)(\d+)/g,
        '<sup style="font-size: 0.9em; top: -0.09em; margin-left: 0.1em;">$1$2</sup>'
      )
      .replace(
        /♯/g,
        '<svg style="width: 0.45em; height: 0.95em; vertical-align: -0.05em; margin: 0 0.01em 0 0.09em;"><use xlink:href="#sharp"></use></svg>'
      )
      .replace(
        /♭/g,
        '<svg style="width: 0.45em; height: 0.8em; vertical-align: 0.08em; margin: 0 -0.04em 0 0.08em;"><use xlink:href="#flat"></use></svg>'
      );
  }

  const typeLabels: Partial<Record<ChordTypeCode, string>> = {
    '': 'major',
    '^': 'Δ7 <small>(maj7)</small>',
    'm': 'minor',
    '0': 'm7♭5 <span class="font-normal">(</span>ø<span class="font-normal">)</span>',
    '5': 'power',
    '4': 'sus4',
    '2': 'sus2',
    // 'madd9': 'm(add9)',
  };
</script>

<div class="notranslate flex flex-col gap-2">
  {#each typeGroups as { key: typeGroupKey, lines } (typeGroupKey)}
    <div class="relative flex flex-col">
      {#each lines.slice(0, expandedTypeGroups.includes(typeGroupKey) ? undefined : 1) as typeGroupLine (typeGroupLine)}
        <div class="flex w-full gap-x-1 pr-7" transition:slideFade={{ duration: 300 }}>
          {#each typeGroupLine as type (type)}
            <button
              class="btn mb-2 min-w-[29px] flex-grow"
              class:bg-muted-100={!singleSelectedType &&
                selectedChords?.some((c) => c.type == type)}
              aria-pressed={singleSelectedType === type}
              aria-label={new Chord('C' + type).toAria().replace(/^C\s*/, '')}
              onclick={() => dispatch('select', type)}
            >
              <span aria-hidden="true">{@html typeLabelFor(type)}</span>
            </button>
          {/each}
        </div>
      {/each}
      {#if lines.length > 1}
        <div class="absolute right-0 top-0">
          <button
            class="btn-base inline-flex h-5 w-5 items-center justify-center rounded-full bg-muted-50 p-0 text-muted-500 hover:bg-muted-150 hover:text-primary-600 aria-expanded:bg-primary-100 aria-expanded:text-primary-800
           hover:aria-expanded:bg-primary-150 hover:aria-expanded:text-primary-900"
            aria-expanded={expandedTypeGroups.includes(typeGroupKey)}
            onclick={() => toggleTypeGroup(typeGroupKey)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="-2 -2 12 12"
              class="size-3 stroke-current stroke-2 transition-all duration-200"
              class:rotate-90={expandedTypeGroups.includes(typeGroupKey)}
              stroke-linecap="round"
            >
              <path
                class="transition-opacity duration-150"
                class:opacity-0={expandedTypeGroups.includes(typeGroupKey)}
                d="M0.5 4 H 7.5"
              />
              <path d="M4 0.5 V 7.5" />
            </svg>
          </button>
        </div>
      {/if}
    </div>
  {/each}
</div>

<style>
  .btn {
    padding: 3px 7px;
    text-transform: none;
    border: solid 1px var(--muted-200);
    &[aria-pressed='true'] {
      border-color: var(--primary-900);
      color: white;
      background: var(--primary-900);
    }
  }
</style>
