import { ReactiveVar } from 'meteor/reactive-var';
import { BandPresets } from '@/band/presets/BandPresets';
import { eventTracker } from '@/browser/analytics/eventTracker';
import { Song } from '@/chart/Song';
import { MusicLibrary } from '@/library/MusicLibrary';
import { MusicLibrarySearcher } from '@/library/MusicLibrarySearcher';
import { MedleySongIndexArray } from '@/medleys/MedleySongIndexArray';

Template.editMedleyModal.onCreated(() => {
  const instance = Template.instance();
  instance.searcher = new MusicLibrarySearcher();
  instance.songsInMedley = new MedleySongIndexArray();
  instance.savingMedley = new ReactiveVar(false);

  instance.songsInMedley.push(...instance.data.medley.songs);

  MusicLibrary.load();
});

Template.editMedleyModal.events({
  'click .js-done': async (_event, instance) => {
    instance.savingMedley.set(true);
    const medley = instance.data.medley;
    const orderedIds = instance.songsInMedley.map((s) => s._id);

    // remove undesired songs
    medley.songs
      .filter((song) => !orderedIds.includes(song._id))
      .forEach((song) => {
        medley.songs.delete(medley.songs.findIndex((s) => s._id == song._id));
      });

    // add new songs
    await Promise.all(
      orderedIds
        .filter((songId) => !medley.songs.find((s) => s._id == songId))
        .map(async (songId) => {
          const { song, songUserAttributes: sua } = await MusicLibrary.songs.fetchFull(songId);
          const key = sua?.key || song.key;
          const songObject = new Song({ songData: { ...song, key }, medley });
          // Band needs to be set up after song is created
          const preset = BandPresets.findById(sua?.presetId || song.presetId || '');
          if (preset) {
            songObject.band.loadPreset(preset);
          } else {
            songObject.band.loadSettings(sua?.band || song.band || {});
          }
          medley.songs.append(songObject);
        })
    );

    // get everything in the right order
    medley.songs.orderByIds(orderedIds);

    setTimeout(() => {
      Modal.hide();
      eventTracker.medleyEditDone();
      const scrollContainer = document.querySelector('.js-global-songScrollContainer');
      if (scrollContainer) scrollContainer.scrollTop = 0;
    }, 200); // for user experience, not necessity
  },

  'click .js-close': (_event, _instance) => {
    Modal.hide();
  },
});

Template.editMedleyModal.helpers({
  savingInProgress() {
    const instance = Template.instance();
    return instance.savingMedley.get();
  },

  searcher() {
    const instance = Template.instance();
    return instance.searcher;
  },

  medleySongs() {
    return Template.instance().songsInMedley.reactive();
  },

  moreThanOneSong() {
    return Template.instance().songsInMedley.reactive().length > 1;
  },

  songPickedFunc() {
    const instance = Template.instance();
    return (songId) => {
      const editSong = MusicLibrary.index.getIndexRecord(songId);
      instance.songsInMedley.append(editSong);
      if (!instance.searcher.query()?.own) instance.searcher.setQuery(null);
    };
  },
});
