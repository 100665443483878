import BandSettingsMenu from '@/band/ui/shell/BandSettingsMenu.svelte';

Template.bandSettingsModal.helpers({
  BandSettingsMenu: () => BandSettingsMenu,
  presetMode: () => Template.currentData().band?.presetMode(),
});

Template.bandSettingsModal.events({
  'click .js-close': function () {
    Modal.hide();
  },
});
