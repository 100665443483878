import '@/ui/fit-vids';
import * as Sentry from '@sentry/browser';
import Cookies from 'js-cookie';
import { Meteor } from 'meteor/meteor';
import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { Analytics } from '@/browser/analytics/Analytics';
import { trackUserAnalytics } from '@/browser/analytics/trackUserAnalytics';
import { ClientManager } from '@/browser/ClientManager';
import { currentTimeZone } from '@/browser/currentTimeZone';
import { setPageTitleAndMeta } from '@/browser/setPageTitleAndMeta';
import { DemoMode } from '@/client/DemoMode';
import { initLoginTokenCookie } from '@/clientModules/initLoginTokenCookie';
import { trackPageView } from '@/clientModules/trackPageView';
import { HelpLibrary } from '@/help/HelpLibrary';
import cleanLocalStorage from '@/local-db/cleanLocalStorage';
import { startTrackingPlayedMinutes } from '@/playback/startTrackingPlayedMinutes';
import { Roles } from '@/Roles';
import { initBrowserSentry } from '@/sentry/client/initBrowserSentry';
import { rpcTouchLastVisit } from '@/server/methods/user/rpcTouchLastVisit';
import { rpcUpdateTZ } from '@/server/methods/user/rpcUpdateTZ';
import { reactivelyAssignBodyClasses } from '@/ui/reactivelyAssignBodyClasses';
import setupUsersCollection from '@/user/setupUsersCollection';
import waitUntilReactive from '@/utilities/waitUntilReactive';

initBrowserSentry();
cleanLocalStorage();
setupUsersCollection();
initLoginTokenCookie();

Tracker.autorun(() => {
  if (window.userIsBeingImpersonated) return;
  const user = Meteor.user(/* 'profile.firstName', 'profile.lastName', 'emails' */); // not specifying scope because it breaks in Cordova
  if (user) {
    Sentry.getCurrentScope().setUser({
      id: user._id,
      username: `${user.profile?.firstName} ${user.profile?.lastName || ''}`.trim(),
      email: user.emails?.[0]?.address,
    });
  } else {
    Sentry.getCurrentScope().setUser({});
  }
});

// automatically reload in development, except in Cordova
if (Meteor.isDevelopment && !Meteor.isCordova && !window.todesktop) {
  Tracker.autorun(() => {
    if (ClientManager.updateReady()) {
      ClientManager.update();
    }
  });
}

if (Meteor.isDevelopment) {
  window.c = Meteor.reconnect;
  window.d = Meteor.disconnect;
}

Meteor.startup(() => {
  waitUntilReactive(() => Meteor.userId() || DemoMode.active()).then(() => {
    HelpLibrary.load();
  });

  if (Meteor.isCordova) {
    // window.open doesn't work in Cordova, so we do this:
    document.addEventListener(
      'deviceready',
      function hookWindowOpen() {
        window.open = cordova.InAppBrowser.open;
      },
      false
    );
  }

  document.addEventListener('resume', () => {
    Meteor.reconnect();
    Sentry.addBreadcrumb({
      category: 'connection',
      message: 'Reconnecting to Meteor (resume event)',
    });
  });
  document.addEventListener('pause', () => {
    Meteor.disconnect();
    Sentry.addBreadcrumb({
      category: 'connection',
      message: 'Disconnecting from Meteor (pause event)',
    });
  });
  document.addEventListener('freeze', () => {
    Meteor.disconnect();
    Sentry.addBreadcrumb({
      category: 'connection',
      message: 'Disconnecting from Meteor (freeeze event)',
    });
  });

  let cordovaPlatformId = undefined;
  document.addEventListener('deviceready', async () => {
    cordovaPlatformId = cordova.platformId;
  });
  const touchLastVisitDebounced = _.debounce(
    () =>
      Meteor.setTimeout(() => {
        const touchPayload = {};
        if (Meteor.isCordova) touchPayload.cordova = cordovaPlatformId;
        if (Roles.userHasRole(Meteor.userId(), { $in: ['trial-ended'] }))
          touchPayload.inactiveUser = true;
        rpcTouchLastVisit(touchPayload);
      }, 3000),
    10000,
    true
  );
  Tracker.autorun(() => {
    FlowRouter.watchPathChange();
    if (Meteor.status().connected && Meteor.userId()) {
      touchLastVisitDebounced();
    }
  });

  Analytics.init();

  _.each(
    ['utm_campaign', 'utm_medium', 'utm_source', 'utm_term', 'utm_content', 'offer'],
    (key) => {
      if (FlowRouter.getQueryParam(key)) {
        Cookies.set(key, FlowRouter.getQueryParam(key), { expires: 3 });
      }
    }
  );

  waitUntilReactive(() => Meteor.userId()).then(() => {
    startTrackingPlayedMinutes();
  });

  waitUntilReactive(() => Meteor.userId()).then(() => {
    trackUserAnalytics();
  });

  waitUntilReactive(() => Meteor.userId()).then(() => {
    rpcUpdateTZ({
      utcOffset: currentTimeZone.utcOffset,
      timeZone: currentTimeZone.name,
    });
  });

  reactivelyAssignBodyClasses();

  clientModules.globalEventHandlers.attach();

  bootbox.setDefaults({
    closeButton: false,
  });

  const reactToPathChange = function () {
    if (!Meteor.isCordova) setPageTitleAndMeta();
    trackPageView();
    // Timeout is a hacky workaround for premature trial cancellation.
    // Really, the client should not be cancelling subscriptions at all.
    setTimeout(() => {
      if (
        Roles.userHasRole(Meteor.userId(), 'trial-member') &&
        !Meteor.user('subscription').subscription.subscriptionId &&
        Meteor.user('subscription').subscription.trialEnd < Date.now()
      ) {
        Meteor.call('endTrial');
      }
    }, 3000);
  };

  Tracker.autorun((comp) => {
    FlowRouter.watchPathChange();
    if (comp.firstRun) {
      Tracker.nonreactive(reactToPathChange);
    } else {
      setTimeout(reactToPathChange, 0);
    }
  });

  Tracker.autorun(() => {
    window.userIsAdmin = Roles.userHasRole(Meteor.userId(), 'admin');
  });

  document.addEventListener('deviceready', () => {
    if ('universalLinks' in window) {
      window.universalLinks.subscribe(null, (eventData) => {
        console.info(`Captured link: ${eventData.url}`);
        const path = eventData.url.split('.com')[1];
        setTimeout(() => FlowRouter.go(path), 300);
      });
    }
  });
});
