// C chromatic (G chromatic has the F# if you want it)
export const majorKeys = ['C', 'Db', 'D', 'Eb', 'E', 'F', 'Gb', 'G', 'Ab', 'A', 'Bb', 'B'] as const;

// Am chromatic
// prettier-ignore
export const minorKeys = ['Cm', 'C#m', 'Dm', 'Ebm', 'Em', 'Fm', 'F#m', 'Gm', 'G#m', 'Am', 'Bbm', 'Bm'] as const;

export const cMajorScale = ['C', 'D', 'E', 'F', 'G', 'A', 'B'] as const;
export const majorScaleIntervals = [0, 2, 4, 5, 7, 9, 11];
export const minorScaleIntervals = [0, 2, 3, 5, 7, 8, 10];
// const majorAccidentalIntervals = [1, 3, 6, 8, 10];
// const minorAccidentalIntervals = [1, 4, 6, 9, 11];
// const majorAccidentalIntervalsWithSpaces = [1, 3, null, 6, 8, 10, null];
// const minorAccidentalIntervalsWithSpaces = [1, null, 4, 6, null, 9, 11];
export const majorIntervals = [1, 1.5, 2, 2.5, 3, 4, 4.5, 5, 5.5, 6, 6.5, 7] as const;
export const minorIntervals = [1, 1.5, 2, 3, 3.5, 4, 4.5, 5, 6, 6.5, 7, 7.5] as const;
// const intervalsForKey = {
//   major: majorIntervals,
//   minor: minorIntervals
// }

// prettier-ignore
export const chromaticLetterCodes = ['C', 'CD','D', 'DE', 'E', 'F', 'FG', 'G', 'GA', 'A', 'AB', 'B'] as const;

// const majorIntervalChromaDictionary = cMajorScale.reduce((dict, note, idx) => {
//   dict[`${idx}`] = majorScaleIntervals[idx];
//   dict[`${idx}#`] = majorScaleIntervals[idx] + 1;
//   dict[`${idx}b`] = majorScaleIntervals[idx] - 1;
//   return dict;
// }, {});
