import { chick, strumDown, strumUp } from '@/band/instruments/guitar/GuitarActions';
import type { GuitarCore } from '@/band/instruments/guitar/GuitarCore';
import { swingOptions } from '@/band/swingOptions';

export const LatinGuitarCore: GuitarCore = {
  id: 'l',

  timeSignatures: ['4/4'],

  linkedSettingsKeys: [],
  otherSettingsKeys: ['timing'],

  chordStyle: 'barred',

  swingCategory: 'latin',
  defaultSwing: swingOptions['0'],

  presets: [
    {
      name: 'Latin???',
      settings: {},
    },
  ],

  measurePreprocessing(measures, settings, flags) {
    let beat = measures[0]?.beats[0];
    while (beat) {
      if (beat.next?.chordChanged)
        (beat.plans.guitar ??= {}).upstrokeChord = beat.next.plans.guitar?.chord;
      beat = beat.next;
    }
  },

  processMeasure(measure, _settings) {
    if (measure.endOfSong) {
      return [
        strumDown('bass', { spread: 3 + Math.random(), db: -2 - 2 * Math.random() }),

        measure.firstInRep || measure.cells[0].split
          ? null
          : strumUp('low', { spread: 3 + Math.random(), db: -2 }),

        measure.beats[1].chordChanged
          ? strumDown('bass', { spread: 3 + Math.random(), db: -2 - 2 * Math.random() })
          : chick('mid', { spread: 5, db: 2 }),

        strumUp('bass', { spread: 3 + Math.random(), db: -2 * Math.random() }),
      ];
    }

    return [
      strumDown('bass', { spread: 3 + Math.random(), db: -2 - 2 * Math.random() }),

      measure.firstInRep || measure.cells[0].split
        ? null
        : strumUp('mid', { spread: 3 + Math.random(), db: -2 }),

      measure.beats[1].chordChanged
        ? strumDown('bass', { spread: 3 + Math.random(), db: -2 - 2 * Math.random() })
        : chick('mid', { spread: 5, db: 2 }),

      measure.cells[0].split
        ? null
        : measure.beats[4]?.chordRootChanged
          ? strumUp('mid', { spread: 3 + Math.random(), db: -8 })
          : strumUp('high', { spread: 3 + Math.random(), db: -2 }),

      strumDown('bass', { spread: 3 + Math.random(), db: -2 - 2 * Math.random() }),

      measure.cells[1]?.split ? null : strumUp('mid', { spread: 3 + Math.random(), db: -2 }),

      measure.beats[5]?.chordChanged
        ? strumDown('bass', { spread: 3 + Math.random(), db: -2 - 2 * Math.random() })
        : chick('mid', { spread: 5, db: 2 }),

      measure.cells[1]?.split
        ? null
        : measure.next?.beats[0]?.chordRootChanged
          ? strumUp('low', { spread: 3 + Math.random(), db: -8 })
          : strumUp('high', { spread: 3 + Math.random(), db: -2 }),
    ];
  },

  generateViz(settings) {
    settings;
    return [];
  },
};
