import { Roles } from '@/Roles';

Template.registerHelper('authenticated', () => {
  return Meteor.userId();
});

Template.registerHelper('firstName', () => {
  return Meteor.user('profile')?.profile?.firstName;
});

Template.registerHelper('userName', () => {
  const profile = Meteor.user('profile')?.profile || {};
  return profile.firstName ? `${profile.firstName} ${profile.lastName || ''}`.trim() : '';
});

Template.registerHelper('hasFullAccess', () => {
  if (!Meteor.userId()) return false;
  if (
    Roles.userHasRole(Meteor.userId(), { $in: ['paid-member', 'lifetime-member', 'trial-member'] })
  )
    return true;
});

Template.registerHelper('hasSubscription', () => {
  if (!Meteor.userId()) return false;
  if (Roles.userHasRole(Meteor.userId(), { $in: ['paid-member', 'lifetime-member'] })) return true;
  if (Roles.userHasRole(Meteor.userId(), 'trial-member')) {
    return !!Meteor.user('subscription')?.subscription?.subscriptionId;
  }
});

Template.registerHelper('hasTrialWithoutPlan', () => {
  return (
    Roles.userHasRole(Meteor.userId(), 'trial-member') &&
    Meteor.user('subscription') &&
    !Meteor.user('subscription')?.subscription?.subscriptionId
  );
});

Template.registerHelper('hasTrialWithPlan', () => {
  return (
    Roles.userHasRole(Meteor.userId(), 'trial-member') &&
    !!Meteor.user('subscription')?.subscription?.subscriptionId
  );
});

Template.registerHelper('hasActiveTrial', () => {
  return Roles.userHasRole(Meteor.userId(), 'trial-member');
});

Template.registerHelper('lifetimeMember', () => {
  return Roles.userHasRole(Meteor.userId(), 'lifetime-member');
});
