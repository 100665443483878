import { strict as assert } from 'assert';
import { patternLandscapeForGuitarCore } from '@/band/instruments/guitar/patternLandscapeForGuitarCore';
import type { GuitarBassNotesSetting } from '@/band/instruments/guitar/settings/GuitarBassNotesSetting';
import type { GuitarBassRunsSetting } from '@/band/instruments/guitar/settings/GuitarBassRunsSetting';
import type { GuitarBoomChuckBalanceSetting } from '@/band/instruments/guitar/settings/GuitarBoomChuckBalanceSetting';
import type { GuitarBoomChuckWaltzBalanceSetting } from '@/band/instruments/guitar/settings/GuitarBoomChuckWaltzBalanceSetting';
import type { GuitarBrushinessSetting } from '@/band/instruments/guitar/settings/GuitarBrushinessSetting';
import type { GuitarCircularStrumShapeSetting } from '@/band/instruments/guitar/settings/GuitarCircularStrumShapeSetting';
import type { GuitarDampenedBackbeatSetting } from '@/band/instruments/guitar/settings/GuitarDampenedBackbeatSetting';
import type { GuitarDampenedFillsSetting } from '@/band/instruments/guitar/settings/GuitarDampenedFillsSetting';
import type { GuitarDampenedOnbeatsSetting } from '@/band/instruments/guitar/settings/GuitarDampenedOnbeatsSetting';
import type { GuitarDampenedSustainSetting } from '@/band/instruments/guitar/settings/GuitarDampenedSustainSetting';
import type { GuitarDampenedUpstrokePatternSetting } from '@/band/instruments/guitar/settings/GuitarDampenedUpstrokePatternSetting';
import type { GuitarOpenVoicingsSetting } from '@/band/instruments/guitar/settings/GuitarOpenVoicingsSetting';
import type { GuitarSyncopatedLeadingNotesSetting } from '@/band/instruments/guitar/settings/GuitarSyncopatedLeadingNotesSetting';
import type { GuitarTimingSetting } from '@/band/instruments/guitar/settings/GuitarTimingSetting';
import type { InstrumentCustomizationUI } from '@/band/instruments/InstrumentCustomizationUI';
import { timingRowStatusGetter } from '@/band/instruments/timingRowStatusGetter';
import GuitarBassNotesPanel from '@/band/ui/panels/GuitarBassNotesPanel.svelte';
import GuitarBassRunsPanel from '@/band/ui/panels/GuitarBassRunsPanel.svelte';
import GuitarBoomChuckBalancePanel from '@/band/ui/panels/GuitarBoomChuckBalancePanel.svelte';
import GuitarBoomChuckWaltzBalancePanel from '@/band/ui/panels/GuitarBoomChuckWaltzBalancePanel.svelte';
import GuitarBrushinessPanel from '@/band/ui/panels/GuitarBrushinessPanel.svelte';
import GuitarCircularStrumShapePanel from '@/band/ui/panels/GuitarCircularStrumShapePanel.svelte';
import GuitarDampenedBackbeatPanel from '@/band/ui/panels/GuitarDampenedBackbeatPanel.svelte';
import GuitarDampenedFillsPanel from '@/band/ui/panels/GuitarDampenedFillsPanel.svelte';
import GuitarDampenedOnbeatsPanel from '@/band/ui/panels/GuitarDampenedOnbeatsPanel.svelte';
import GuitarDampenedSustainPanel from '@/band/ui/panels/GuitarDampenedSustainPanel.svelte';
import GuitarDampenedUpstrokePatternPanel from '@/band/ui/panels/GuitarDampenedUpstrokePatternPanel.svelte';
import GuitarOpenVoicingsPanel from '@/band/ui/panels/GuitarOpenVoicingsPanel.svelte';
import GuitarSyncopatedLeadingNotesPanel from '@/band/ui/panels/GuitarSyncopatedLeadingNotesPanel.svelte';
import InstrumentTimingPanel from '@/band/ui/panels/InstrumentTimingPanel.svelte';
import { capitalize } from '@/utilities/capitalize';
import { decapitalize } from '@/utilities/decapitalize';

export const guitarCustomizationUI = {
  bassRuns: <InstrumentCustomizationUI<GuitarBassRunsSetting>>{
    panel: { component: GuitarBassRunsPanel, title: 'Bass Runs' },
    getRowStatus({ setting }) {
      const disabled = setting.eagerness === 0;
      const number =
        ['None', 'A few', 'Some', 'Many', 'Tons of'][Math.ceil(setting.eagerness * 4)] ??
        'Unknown [error]';
      const scale = setting.majorFeel == 'b' ? 'bluesy' : '';
      return {
        text: disabled ? 'No bass runs' : `${number} ${scale} bass runs`,
      };
    },
  },

  circularStrumShape: <InstrumentCustomizationUI<GuitarCircularStrumShapeSetting>>{
    panel: { component: GuitarCircularStrumShapePanel, title: 'Feel' },
    getRowStatus({ setting, core }) {
      assert(core, "Core required for circularStrumShape's getRowStatus");
      const landscape = patternLandscapeForGuitarCore(core);
      const adverb =
        setting.polarR >= 0.8
          ? 'Very'
          : setting.polarR < 0.5 && setting.polarR > 0.2
            ? 'Slightly'
            : '';
      const mainLabel = setting.closestPresetForLandscape(landscape).name.replace('with ', 'w/');
      const text = adverb ? `${adverb} ${decapitalize(mainLabel)}` : mainLabel;
      return { prefix: 'Feel:', text };
    },
  },

  boomChuckBalance: <InstrumentCustomizationUI<GuitarBoomChuckBalanceSetting>>{
    panel: { component: GuitarBoomChuckBalancePanel, title: 'Boom/Chuck Balance' },
    getRowStatus({ setting }) {
      return {
        prefix: 'Balance:',
        text: setting.closestPreset.caption,
      };
    },
  },

  boomChuckWaltzBalance: <InstrumentCustomizationUI<GuitarBoomChuckWaltzBalanceSetting>>{
    panel: { component: GuitarBoomChuckWaltzBalancePanel, title: 'Boom/Chuck Balance' },
    getRowStatus({ setting }) {
      return {
        prefix: 'Balance:',
        text: setting.closestPreset.caption,
      };
    },
  },

  bassNotes: <InstrumentCustomizationUI<GuitarBassNotesSetting>>{
    panel: { component: GuitarBassNotesPanel, title: 'Bass Notes' },
    getRowStatus({ setting }) {
      const leadingNoteAmount =
        ['', 'few', 'some', 'many', 'tons of'][Math.round(setting.leadingNoteFrequency * 4)] ?? '';
      return {
        text: setting.rootOnly
          ? 'Root bass notes only'
          : setting.walking
            ? 'Walking bass lines'
            : setting.leadingNoteFrequency
              ? `Alternating w/${leadingNoteAmount} leading notes`
              : 'Alternating bass notes',
      };
    },
  },

  syncopatedLeadingNotes: <InstrumentCustomizationUI<GuitarSyncopatedLeadingNotesSetting>>{
    panel: { component: GuitarSyncopatedLeadingNotesPanel, title: 'Leading Notes' },
    getRowStatus({ setting }) {
      const leadingNoteAmount =
        ['', 'few', 'some', 'many', 'tons of'][Math.round(setting.frequency * 4)] ?? '';
      return {
        text: setting.frequency
          ? `${capitalize(leadingNoteAmount)} leading notes`
          : 'No leading notes',
      };
    },
  },

  brushiness: <InstrumentCustomizationUI<GuitarBrushinessSetting>>{
    panel: { component: GuitarBrushinessPanel, title: 'Pick-Strum Balance' },
    getRowStatus({ setting }) {
      return {
        prefix: 'Pick-Strum:',
        text: setting.closestPreset.caption.replace('with ', 'w/'),
      };
    },
  },

  openVoicings: <InstrumentCustomizationUI<GuitarOpenVoicingsSetting>>{
    panel: { component: GuitarOpenVoicingsPanel, title: 'Chord Voicings' },
    getRowStatus({ setting, currentKey }) {
      if (!currentKey) return { text: 'No key set', gray: true };
      if (currentKey.includes('m')) return { text: 'N/A in minor keys', gray: true };
      const pos = setting.positionToUseForKey(currentKey);
      if (!pos) return { text: 'Chord voicings', gray: true };

      const tuning = pos.shape == 'D' && setting.dropD ? 'Drop-D tuning' : '';
      const power =
        pos.shape == 'G'
          ? setting.voicingForG == 'G5'
          : pos.shape == 'D'
            ? setting.voicingForD == 'D5'
            : false;
      const sus = pos.shape == 'D' && setting.voicingForD == 'D2';
      const openB = !power && pos.shape == 'G';
      const shapeText = sus ? `${pos.shape}sus2` : pos.shape;
      const openPrefix = pos.capo === 0 ? 'open ' : 'capoed ';
      const text = tuning
        ? `${openPrefix}${shapeText} position in ${tuning}`
        : openB
          ? `${openPrefix}${shapeText} position (w/open B string)`
          : power && pos.shape !== 'G'
            ? `${openPrefix}${shapeText} position (power ${pos.shape} shape)`
            : `${openPrefix}${shapeText} position`;
      return {
        text: capitalize(text),
      };
    },
  },

  dampenedOnbeats: <InstrumentCustomizationUI<GuitarDampenedOnbeatsSetting>>{
    panel: { component: GuitarDampenedOnbeatsPanel, title: 'Onbeats' },
    getRowStatus({ setting }) {
      const whatItIs = setting.singleNote
        ? 'Bass note'
        : setting.shortStrum
          ? 'Short strum'
          : 'Strum';
      return {
        prefix: 'Onbeats:',
        text: whatItIs,
      };
    },
  },

  dampenedSustain: <InstrumentCustomizationUI<GuitarDampenedSustainSetting>>{
    panel: { component: GuitarDampenedSustainPanel, title: 'Sustain' },
    getRowStatus({ setting }) {
      const same = setting.closestOnbeatPreset.value == setting.closestOffbeatPreset.value;
      return {
        prefix: 'Sustain:',
        text: same
          ? `${setting.closestOnbeatPreset.caption} (all beats)`
          : `${setting.closestOnbeatPreset.caption} / ${setting.closestOffbeatPreset.caption}`,
      };
    },
  },

  dampenedBackbeat: <InstrumentCustomizationUI<GuitarDampenedBackbeatSetting>>{
    panel: { component: GuitarDampenedBackbeatPanel, title: 'Backbeat' },
    getRowStatus({ setting }) {
      return {
        prefix: 'Backbeat:',
        text: setting.closestPreset.caption,
      };
    },
  },

  dampenedUpstrokePattern: <InstrumentCustomizationUI<GuitarDampenedUpstrokePatternSetting>>{
    panel: { component: GuitarDampenedUpstrokePatternPanel, title: 'Upstroke Pattern' },
    getRowStatus({ setting }) {
      const text =
        {
          'n': 'None',
          'm': 'Muted',
          'lp': 'La Pompe',
        }[setting.pattern] ?? `Unknown value (${setting.pattern})`;
      return { prefix: 'Upstroke Pattern:', text };
    },
  },

  dampenedFills: <InstrumentCustomizationUI<GuitarDampenedFillsSetting>>{
    panel: { component: GuitarDampenedFillsPanel, title: 'Upstroke Fills' },
    getRowStatus({ setting }) {
      let text = `Unknown sustain setting (${setting.probability})`;
      if (setting.probability < 0.05) text = 'None';
      else if (setting.probability < 0.25) text = 'Rare';
      else if (setting.probability < 0.45) text = 'Occasional';
      else if (setting.probability < 0.65) text = 'Some';
      else if (setting.probability < 0.85) text = 'Many';
      else if (setting.probability < 1.05) text = 'Tons';
      return { prefix: 'Fills:', text, gray: setting.probability < 0.05 };
    },
  },

  timing: <InstrumentCustomizationUI<GuitarTimingSetting>>{
    panel: {
      component: InstrumentTimingPanel,
      props: { instId: 'guitar' },
      title: 'Timing Tweaks',
    },
    getRowStatus: timingRowStatusGetter('guitar'),
  },
} as const;
