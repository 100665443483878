import { Crnt } from '@/Crnt';
import closeNearestTippy from '@/ui/dropdowns/closeNearestTippy';
import defineTippyDropdown from '@/ui/dropdowns/defineTippyDropdown';

defineTippyDropdown(Template.songTimeEditor, {
  id: 'time-sig',
});

Template.songTimeEditor.helpers({
  currentTimeSig() {
    return Crnt.song()?.timeSignature() || '4/4';
  },
  isFourFour(sig) {
    return sig == '4/4';
  },
  sigDefsSansFourFour() {
    return [
      { sig: '3/4', desc: 'waltz' },
      { sig: '6/8', desc: 'jig' },
      { sig: '9/8', desc: 'slip jig' },
    ];
  },
});

Template.songTimeEditor.events({
  'click .js-timeSigButtons a[data-value]': function (event, _instance) {
    event.preventDefault();
    closeNearestTippy(event.currentTarget);
    const song = Crnt.song();
    if (!song) return;
    const newTimeSig = event.currentTarget?.dataset.value;
    if (newTimeSig && newTimeSig != song.timeSignature()) {
      if (!song.band.preset()) {
        bootbox.confirm({
          message: `If you change the time signature, your custom band settings will be reset.`,
          buttons: {
            confirm: {
              label: 'Continue',
              className: 'btn-danger',
            },
            cancel: {
              label: 'Cancel',
              className: 'btn-primary',
            },
          },
          callback: function (result) {
            if (result) song.setTimeSignature(newTimeSig);
          },
        });
      } else {
        song.setTimeSignature(newTimeSig);
      }
    }
  },
});
