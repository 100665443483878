import SongRecorder from '@/audio/recording/SongRecorder';
import { Conductor } from '@/Conductor';
import { Crnt } from '@/Crnt';

Template.recorderMenu.onCreated(() => {
  const instance = Template.instance();
  SongRecorder.load();
});

Template.recorderMenu.helpers({
  showControls() {
    if (!Meteor.userId()) return false;
    if (!(Crnt.song() || Crnt.medley()?.songs.reactive().length > 0)) return false;
    return true;
  },
  loading() {
    return !SongRecorder.loaded();
  },
  stopped() {
    return Conductor.stopped();
  },
  canArm() {
    return SongRecorder.loaded() && Conductor.ready() && !SongRecorder.armed();
  },
  canDisarm() {
    return SongRecorder.armed();
  },
});

Template.recorderMenu.events({
  'click .js-arm': function (event) {
    event.stopPropagation();
    SongRecorder.arm();
  },
  'click .js-disarm': function (event) {
    event.stopPropagation();
    SongRecorder.disarm();
  },
});
