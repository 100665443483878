<script lang="ts">
  import type { Instance as TippyInstance } from 'tippy.js';
  import AddNewPresetPanel from '@/band/ui/panels/AddNewPresetPanel.svelte';
  import { makeTippyMenu } from '@/ui/dropdowns/makeTippyMenu';
  import Icon from '@/ui/icons/Icon.svelte';
  import { MultiPanelModalController } from '@/ui/settings/MultiPanelModalController.svelte';

  const modalController = MultiPanelModalController.current();

  let tippyInstance: TippyInstance | undefined;
  function tippyMenu(node: HTMLElement, params: Record<string, unknown> = {}) {
    tippyInstance = makeTippyMenu(node, params, { keysThatShow: [' ', 'Enter'] });
    return { destroy: () => tippyInstance?.destroy() };
  }

  function savePreset() {
    tippyInstance?.hide();
    modalController.zoomInto({
      component: AddNewPresetPanel,
      title: 'Add Preset to Library',
    });
  }
</script>

<div use:tippyMenu={{ 'theme': 'light', 'placement': 'bottom-end' }}>
  <button class="btn btn-round scale-125 px-1.5 py-[3px] font-normal" aria-label="Preset options">
    <i class="smi smi-dots-vertical" aria-hidden="true"></i>
  </button>
  <div
    role="menu"
    class="max-w-[16rem] p-1.5 [&_.btn]:flex [&_.btn]:items-center [&_.btn]:px-3 [&_.btn]:py-1.5 [&_.btn]:text-left [&_.btn]:font-normal [&_button]:w-full"
    tabindex="-1"
  >
    <div class="mx-3 mb-3 mt-2 flex">
      <div class="mt-0.5 text-wrap text-sm text-gray-500">
        These settings were set by the person who owns this chart in Strum Machine.
      </div>
    </div>
    <div class="mx-1 my-1.5 border-t border-panelWellBorder"></div>
    <button class="btn" onclick={savePreset}>
      <span class="w-6"><Icon id="add-button-icon" size={14} /></span>
      Save to Your Presets
    </button>
  </div>
</div>
