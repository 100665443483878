<script lang="ts">
  import { MandolinChopDynamicsSetting } from '@/band/instruments/mandolin/settings/MandolinChopDynamicsSetting';
  import CycleThroughDropdown from '@/band/ui/components/CycleThroughDropdown.svelte';
  import PrettySlider from '@/band/ui/components/PrettySlider.svelte';
  import RhythmPatternVisualization from '@/band/ui/components/RhythmPatternVisualization.svelte';
  import PanelModalLayout from '@/band/ui/panels/PanelModalLayout.svelte';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';

  const bandMenu = getBandMenuState();

  let setting = $derived($bandMenu.mandolinSettings.chopDynamics);

  function setIntensity(value: 0 | 1 | 2) {
    $bandMenu.mandolin.setSetting('chopDynamics', setting.withIntensity(value));
  }

  function setSustain(value: 0 | 1 | 2) {
    $bandMenu.mandolin.setSetting('chopDynamics', setting.withSustain(value));
  }

  function setPreset(value: unknown) {
    $bandMenu.mandolin.setSetting('chopDynamics', new MandolinChopDynamicsSetting(value));
  }
</script>

<PanelModalLayout>
  {#snippet header()}
    <header class="panelHeader">
      This setting controls how long the mandolin chop rings out and how hard it's played.
    </header>
  {/snippet}

  {#snippet main()}
    <div>
      <div class="mt-8 flex justify-center">
        <div
          style="box-shadow: 4px 6px 12px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.05), -4px -6px 12px 0px rgba(255, 255, 255, 0.95); border-radius: 12px; overflow: hidden; border: solid 2px var(--gray-100);"
        >
          <RhythmPatternVisualization
            actions={$bandMenu.mandolin
              .coreOrFallbackForTimeSignature($bandMenu.timeSignature)
              .generateViz($bandMenu.mandolinSettings)}
            timeSignature={$bandMenu.timeSignature}
            scale={1.7}
            highlightCurrentBeat={true}
            showBeatNumbers={false}
          />
        </div>
      </div>
      <div class="pt-6"></div>

      <div class="cycle-shadow mx-11 rounded-lg bg-muted-100 p-1 pb-[5px]">
        <CycleThroughDropdown
          options={MandolinChopDynamicsSetting.presets.slice().reverse()}
          selectedValue={setting.closestPreset.value}
          on:select={(event) => setPreset(event.detail)}
          wrapAround={true}
          captureLeftRightKeys={true}
        />
      </div>

      <div class="pt-8"></div>
      <div class="relative border-y border-b-white border-t-gray-200"></div>
      <div class="flex">
        <div class="flex flex-1 flex-col pb-6 pt-4">
          <div class="text-center text-xs font-extrabold uppercase tracking-widest text-gray-300">
            Loud
          </div>
          <div class="my-3 flex h-32 items-center justify-center">
            <div class="w-32 origin-center -rotate-90">
              <PrettySlider
                min={0}
                max={2}
                step={1}
                value={setting.intensityIndex}
                tickCount={3}
                thumbDiameter={2.75}
                tickHeight={1.25}
                tickSqueezes={[0.8, 0.5, 0.2]}
                vertical={true}
                ariaLabel="intensity"
                on:change={({ detail }) => setIntensity(detail as 0 | 1 | 2)}
              />
            </div>
          </div>
          <div class="text-center text-xs font-extrabold uppercase tracking-widest text-gray-300">
            Soft
          </div>
        </div>
        <div
          class="relative -mt-[1px] w-[3px] self-stretch border-x border-white bg-gray-200"
        ></div>
        <div class="flex flex-1 flex-col pb-6 pt-4">
          <div class="text-center text-xs font-extrabold uppercase tracking-widest text-gray-300">
            Voiced
          </div>
          <div class="my-3 flex h-32 items-center justify-center">
            <div class="w-32 origin-center -rotate-90">
              <PrettySlider
                min={0}
                max={2}
                step={1}
                value={setting.sustainIndex}
                tickCount={3}
                thumbDiameter={2.75}
                tickHeight={1.25}
                tickSqueezes={[0.8, 0.5, 0.2]}
                vertical={true}
                ariaLabel="intensity"
                on:change={({ detail }) => setSustain(detail as 0 | 1 | 2)}
              />
            </div>
          </div>
          <div class="text-center text-xs font-extrabold uppercase tracking-widest text-gray-300">
            Dry
          </div>
        </div>
      </div>
    </div>
  {/snippet}
</PanelModalLayout>
