import type { BuiltInPresetDefinition } from '@/band/presets/BuiltInPresetDefinition';

export const bluesPresetDefinitions: Partial<Record<TimeSignature, BuiltInPresetDefinition[]>> = {
  // --- 4/4 ---

  '4/4': [
    {
      id: 'bls-str',
      name: 'Blues - Strummy', // Standard, Simple, All-Purpose, Generic
      genreId: 'of',
      genreCategoryId: 'of-bls',
      description: 'Down-up shuffle strum with walking bass. ',
      band: {
        'sw': '2:1',
        'g_bcbr': { 'v': 12 / 14 },
        'g_br': { 'qeb': 1, 'jf': 'b' },
        'g_c': 'bg',
        'g_ov': { 'mj': 'ea' },
        'g_ss': { 'r': 1, 't': 0.625 },
        'b_c': 'wb',
        'm_c': 'd',
        'm_db': { 'v': 0 },
        'm_ds': { 'b1': 1, 'b2': 1 / 3 },
      },
    },
  ],
};
