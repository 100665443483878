/* eslint-disable @typescript-eslint/unbound-method */
import { addDays } from 'date-fns';
import { Meteor } from 'meteor/meteor';

// This is taken from meteor-fast-render. Note that loginToken cannot be 100% trusted on the server side.

export function initLoginTokenCookie() {
  overrideLocalStorageMethods();
  // Meteor calls Meteor._localStorage.setItem() on the boot,
  // but we can do it ourselves with this to get the token for the first time
  Meteor.startup(resetToken);
}

function overrideLocalStorageMethods() {
  // override Meteor._localStorage methods and resetToken accordingly
  const originalSetItem = Meteor._localStorage.setItem;
  Meteor._localStorage.setItem = function (key, value) {
    if (key === 'Meteor.loginToken') Meteor.defer(resetToken);
    originalSetItem.call(Meteor._localStorage, key, value);
  };

  const originalRemoveItem = Meteor._localStorage.removeItem;
  Meteor._localStorage.removeItem = function (key) {
    if (key === 'Meteor.loginToken') Meteor.defer(resetToken);
    originalRemoveItem.call(Meteor._localStorage, key);
  };
}

function resetToken() {
  const loginToken = Meteor._localStorage.getItem('Meteor.loginToken');
  const loginTokenExpires = Meteor._localStorage.getItem('Meteor.loginTokenExpires');

  if (loginToken && loginTokenExpires) {
    setTokenCookie(loginToken, new Date(loginTokenExpires));
  } else {
    removeTokenCookie();
  }
}

const cookieName = 'meteor_login_token';

function setTokenCookie(loginToken: string, expires: Date) {
  const value = encodeURIComponent(loginToken ?? '');
  const cookieString = `${cookieName}=${value}; expires=${expires.toUTCString()}; path=/`;
  document.cookie = cookieString;
}

function removeTokenCookie() {
  const expires = addDays(new Date(), -1);
  const cookieString = `${cookieName}=; expires=${expires.toUTCString()}; path=/`;
  document.cookie = cookieString;
}
