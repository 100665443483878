<script lang="ts">
  import type { Section } from '@/chart/Section.svelte';
  import type { Song } from '@/chart/Song';
  import ChartCell from '@/chart/ui/ChartCell.svelte';
  import { crntPreferences } from '@/client/crntPreferences.svelte';

  interface Props {
    song: Song;
    section: Section;
    editMode: boolean;
  }

  const { song, section, editMode }: Props = $props();

  let cells = $derived([...section.cells]);

  let chordSystem = $derived(crntPreferences().chordSystem);
  let useSlashes = $derived(editMode ? false : crntPreferences().chordRepeatedSlash);
</script>

{#each cells as cell (cell._id)}
  <ChartCell {song} {cell} cellCount={cells.length} {chordSystem} {useSlashes} {editMode} />
{/each}
