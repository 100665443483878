import autosize from 'autosize';
import { marked } from 'marked';
import TextareaEditor, { Formats } from 'textarea-editor';
import { Crnt } from '@/Crnt';
import NotesInterface from '@/notes/NotesInterface';
import { controlBarBelow } from '@/ui/controlBarBelow';
import UserPreferences from '@/user/UserPreferences';

const isIPhone = /iP(hone|od)/i.test(navigator.userAgent);

Formats.italic.prefix = Formats.italic.suffix = '*';

Template.notesArea.onCreated(() => {
  const instance = Template.instance();
  instance.editing = new ReactiveVar(false);
  instance.visible = new ReactiveVar(false);
  instance.autorun(() => {
    instance.visible.set(
      NotesInterface.visible() &&
        (Crnt.song()?.key() || Crnt.medley()?.songs.reactive().length > 0) &&
        Template.currentData().position == NotesInterface.displayPosition()
    );
  });
});

Template.notesArea.onRendered(() => {
  const instance = Template.instance();

  instance.keyboardDidShowHandler = () => {
    const notepadEl = document.querySelector('.js-global-notepad');
    notepadEl?.scrollIntoView(true);
  };
  window.addEventListener('keyboardDidShow', instance.keyboardDidShowHandler);

  instance.enterEditMode = () => {
    instance.editing.set(true);
    instance.$('.js-editArea').show();
    instance.$('.js-displayArea').hide();
    instance.$('.js-editBox').val(Crnt.notepad()?.notes()).focus();
    autosize.update(instance.find('.js-editBox'));
    instance.markdownEditor.range([0, 0]);
    instance.find('.js-editBox').scrollTop = 0;
    const notepadEl = document.querySelector('.js-global-notepad');
    notepadEl?.scrollIntoView(isIPhone ? true : { behavior: 'smooth', block: 'nearest' });
  };

  instance.leaveEditMode = () => {
    instance.editing.set(false);
    instance.$('.js-editArea').hide();
    instance.$('.js-displayArea').show();
    $('.js-global-controlBar').show();
  };

  instance.autorun(() => {
    if (instance.visible.get()) {
      Tracker.afterFlush(() => {
        autosize(instance.find('.js-editBox'));
        instance.markdownEditor = new TextareaEditor(instance.find('.js-editBox'));
        instance[instance.editing.get() ? 'enterEditMode' : 'leaveEditMode']();
      });
    }
  });
});

Template.notesArea.onDestroyed(() => {
  const instance = Template.instance();
  window.removeEventListener('keyboardDidShow', instance.keyboardDidShowHandler);
  $('.js-global-controlBar').show();
});

Template.notesArea.helpers({
  editing() {
    return Template.instance().editing.get();
  },

  visible() {
    return Template.instance().visible.get();
  },

  noteContent() {
    return Crnt.notepad()?.notes()?.length > 0;
  },

  waltzesOrJigs() {
    return Crnt.song()?.timeSignature() == '3/4' ? 'waltzes' : 'jigs';
  },

  waltzOrJig() {
    return Crnt.song()?.timeSignature() == '3/4' ? 'waltz' : 'jig';
  },

  monospace() {
    return UserPreferences.get('monospaceNotepad');
  },

  formattedNoteContent() {
    const notes = Crnt.notepad()?.linkedNotes();
    return notes
      ? marked(notes, { breaks: true, smartypants: true }).replace(
          /<a href="http/gi,
          '<a target="_blank" href="http'
        )
      : undefined;
  },
});

Template.notesArea.events({
  'click .js-edit': function (event, instance) {
    instance.enterEditMode();
  },

  'dblclick .js-renderedNotes': function (event, instance) {
    instance.enterEditMode();
    return false;
  },

  'click .js-emptyArea': function (event, instance) {
    instance.enterEditMode();
  },

  'keydown .js-emptyArea': function (event, instance) {
    if (event.key == ' ' || event.key == 'Enter' || event.key == 'NumpadEnter') {
      instance.enterEditMode();
      return false;
    }
  },

  'click .js-close': function (event, instance) {
    NotesInterface.hide();
  },

  'keyup .js-editBox': function (event, instance) {
    if (event.key == 'Enter' && (event.ctrlKey || event.metaKey)) {
      instance.$('.js-saveEdits').click();
      return false;
    }
  },

  'focus .js-editBox': function (event, instance) {
    if (controlBarBelow()) {
      $('.js-global-controlBar').hide();
    }
  },

  'blur .js-editBox': function (event, instance) {
    $('.js-global-controlBar').show();
  },

  'click .js-saveEdits': function (event, instance) {
    var notes = instance.$('.js-editBox').val();
    Crnt.notepad()?.setNotes(notes);
    instance.leaveEditMode();
  },

  'click a[href^="#bpm="]': function (event, instance) {
    event.preventDefault();
    var bpm = event.currentTarget.href.substring(event.currentTarget.href.indexOf('#') + 5);
    Crnt.song()?.prefs.setBpm(parseInt(bpm, 10));
    Crnt.medley()?.setBpm(parseInt(bpm, 10));
    $('.js-global-bpmDisplay').velocity(
      { backgroundColor: ['#ffffff', '#ffe5a3'] },
      {
        duration: 800,
        complete(elements) {
          elements[0].style.backgroundColor = '';
        },
      }
    );
  },

  'click a[href^="#key="]': function (event, instance) {
    event.preventDefault();
    if (!Crnt.song()) return;
    let keyString = event.currentTarget.href.match(/#key=([A-Ga-g][b#]?m?)/)?.[1];
    if (!keyString) return;
    keyString = keyString.charAt(0).toUpperCase() + keyString.slice(1).toLowerCase();
    Crnt.song()?.setKey(keyString);
    $('.js-global-keyButton')
      .velocity('stop')
      .css({ transition: 'none' })
      .velocity(
        { backgroundColor: ['#fafafa', '#ffe5a3'] },
        {
          duration: 800,
          complete(elements) {
            elements[0].style.transition = '';
            elements[0].style.backgroundColor = '';
          },
        }
      );
  },

  'click .js-changePosition': function (event, instance) {
    event.preventDefault();
    const newPos = $(event.currentTarget).data('position');
    NotesInterface.setDisplayPosition(newPos);
  },

  'click .js-cancelEdits': function (event, instance) {
    instance.leaveEditMode();
  },

  'keydown .js-editBox': function (event, instance) {
    const onlyCtrl = (event.metaKey || event.ctrlKey) && !event.shiftKey && !event.altKey;
    const onlyCtrlShift = (event.metaKey || event.ctrlKey) && event.shiftKey && !event.altKey;

    if (event.key == 'b' && onlyCtrl) {
      instance.markdownEditor.toggle('bold');
      event.preventDefault();
    }

    if (event.key == 'i' && onlyCtrl) {
      instance.markdownEditor.toggle('italic');
      event.preventDefault();
    }

    if (event.key == 'k' && onlyCtrl) {
      instance.markdownEditor.toggle('link', window.prompt('URL:'));
      event.preventDefault();
    }

    if (event.key == '7' && onlyCtrlShift) {
      instance.markdownEditor.toggle('orderedList');
      event.preventDefault();
    }

    if (event.key == '8' && onlyCtrlShift) {
      instance.markdownEditor.toggle('unorderedList');
      event.preventDefault();
    }
  },
});
