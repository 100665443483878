import { Meteor } from 'meteor/meteor';
import { ReactiveVar } from 'meteor/reactive-var';
import { Tracker } from 'meteor/tracker';
import _ from 'underscore';
import { Crnt } from '@/Crnt';
import { MusicLibrary } from '@/library/MusicLibrary';

Template.musicPageTitle.onCreated(() => {
  const instance = Template.instance();
  instance.editing = new ReactiveVar(false);
});

Template.musicPageTitle.onRendered(() => {
  const instance = Template.instance();

  // Automatically enter editing mode if editing song with no name
  instance.autorun(() => {
    if (Crnt.song()?.editMode()) {
      Tracker.nonreactive(() => {
        if (!Crnt.song()?.name()) {
          Meteor.defer(() => instance.editing.set(true));
        }
      });
    }
  });

  // Going in and out of editing mode
  Meteor.defer(() => {
    instance.autorun(() => {
      const canEdit = Crnt.song()?.editMode() || Crnt.medley()?.editable();
      const editing = instance.editing.get() && canEdit;
      if (editing) {
        instance.$('.js-nameDisplay').hide();
        instance.$('.js-editingControls').show();
        instance.$('.js-nameInput').val(Crnt.musicTitle());
        instance.$('.js-nameInput').show();
        instance.$('.js-enterHint').stop().css('opacity', 0);
        Meteor.defer(() => instance.$('.js-nameInput').focus());
      } else {
        instance.$('.js-nameDisplay').show();
        instance.$('.js-editingControls').hide();
        $('.js-global-chooseKeyHelp').fadeIn(); // if it's out there...
      }
    });
  });
});

const showEnterHint = _.debounce((instance) => {
  instance.$('.js-enterHint').animate({ 'opacity': 1 });
}, 1000);

Template.musicPageTitle.events({
  'click .js-nameDisplay': function (_event, instance) {
    if (MusicLibrary.blockingOperationInProgress()) return;
    if (Crnt.song()?.editMode() || Crnt.medley()?.editable()) {
      instance.$('input').val(Crnt.musicTitle());
      instance.editing.set(true);
    }
  },

  'keyup .js-nameInput': function (event, instance) {
    if (event.key == 'Enter' || event.key == 'NumpadEnter') {
      (Crnt.song() || Crnt.medley())?.setName($.trim(instance.$('input').val()));
      instance.editing.set(false);
      //@ts-expect-error TS doesn't know Element subtype
      document.querySelector('.js-global-keyButton')?.focus();
    } else {
      showEnterHint(instance);
    }
  },

  'blur .js-nameInput': function (_event, instance) {
    if (!(Crnt.song()?.editMode() || Crnt.medley()?.editable())) {
      //  this is sometimes triggered erroneously when leaving edit mode
      instance.editing.set(false);
    }
    (Crnt.song() || Crnt.medley())?.setName(instance.$('input').val().trim());
    Meteor.setTimeout(() => {
      instance.editing.set(false);
      Meteor.defer(() => {
        window.getSelection()?.removeAllRanges();
      });
    }, 120); // for looks
  },
});

Template.musicPageTitle.helpers({
  musicName() {
    const name = Crnt.musicTitle() || (Crnt.song() || Crnt.medley() ? 'Untitled' : 'Loading...');
    return name
      .replace(/ +\[.+\]$/, '')
      .replace(/(^|[-\u2014\s(["])'/g, '$1\u2018')
      .replace(/'/g, '\u2019');
  },

  musicLabel() {
    const name = Crnt.musicTitle() || '';
    const match = name.match(/ \[(.+)\]$/);
    return match ? match[1] : null;
  },

  canEdit() {
    if (MusicLibrary.blockingOperationInProgress()) return false;
    return Crnt.song()?.editMode() || Crnt.medley()?.editable();
  },

  isMedley() {
    return !!Crnt.medley();
  },

  medleyAutoName() {
    return Crnt.medley()?.autoName();
  },
});
