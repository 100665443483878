Template.signupVerifyPage.helpers({
  emailAddress() {
    return Session.get('email-inbox-to-open');
  },
});

Template.signupVerifyPage.events({
  'click .js-triggerWebEmailOpen': function () {
    $('.js-openWebEmail').click();
  },
});
