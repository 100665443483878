import AudioManager from '@/audio/engine/AudioManager';
import type { Playback } from '@/audio/engine/base/Playback';
import { executePlayerInstructions } from '@/audio/executePlayerInstructions';
import { generateChordPickingOptions } from '@/band/instruments/guitar/generateChordPickingOptions';
import { GuitarChordLibrary } from '@/band/instruments/guitar/GuitarChordLibrary';
import type { GuitarIntent } from '@/band/instruments/guitar/GuitarIntent';
import { makeInstructionsFromGuitarIntent } from '@/band/instruments/guitar/makeInstructionsFromGuitarIntent';
import type { PlayerInstruction } from '@/band/instruments/PlayerInstruction';
import type { Cell } from '@/chart/Cell.svelte';
import { Conductor } from '@/Conductor';
import { Crnt } from '@/Crnt';
import type { Chord } from '@/music/Chord';
import UserPreferences from '@/user/UserPreferences';

export class HearAsYouEdit {
  static trigger(): void {
    if (!UserPreferences.get('haye')) return;
    const focus = Crnt.song()?.focus;
    const focusedCell = focus?.endCell();
    if (!focusedCell) return;
    if (!Conductor.ready() || Conductor.playing()) return;
    void this.previewMeasure({ cell: focusedCell, beat: focus?.focusedBeat() });
  }

  protected static async previewMeasure({
    cell,
    beat,
  }: {
    cell?: Cell;
    beat?: number;
  }): Promise<void> {
    if (!cell?.chordHeard) return;
    if (cell.effect == 'rest') return;

    await AudioManager.gonnaPlay();

    AudioManager.playSample({ id: 'count-primer', volume: 0.05 });

    const delay = AudioManager.mode != 'web-audio' ? 0.05 : 0.2;

    const chordHeard =
      cell.subdividedBeats?.[beat ?? 0]?.chordHeard ??
      cell.subdividedBeats?.[(beat ?? 0) - 1]?.chordHeard ??
      cell.chordHeard;
    this.previewChord(chordHeard, AudioManager.currentTime() + delay);

    if (cell.effect == 'stop') {
      AudioManager.mixer?.channel('guitar')?.playbacks.forEach((pb: Playback) => {
        void pb.stop({
          atTime: AudioManager.currentTime() + delay + 0.2,
          fadeDuration: 0.18,
        });
      });
    }

    void AudioManager.gonnaStop();
  }

  static previewChord(chord: Chord, atTime: number): void {
    const song = Crnt.song();
    const openVoicings = song?.band.instruments.guitar.settings().openVoicings;
    const chordStyle = song?.band.instruments.guitar.core()?.chordStyle;

    const chordPickingOptions = openVoicings
      ? generateChordPickingOptions({ key: chord.key, openVoicings, chordStyle })
      : undefined;

    const intent = <GuitarIntent>{
      'action': 'strum',
      'bias': 'haye',
      'spread': 4.7,
      'direction': 'D',
      'db': -2,
    };
    const instructions = <PlayerInstruction[]>[
      { 'type': 'mute', 'fadeDuration': 0.15, 'offset': 0.05, 'channel': 'guitar' },
      ...makeInstructionsFromGuitarIntent(
        intent,
        GuitarChordLibrary.pickChord(chord, chordPickingOptions)
      ),
    ];
    executePlayerInstructions(instructions, atTime);
  }
}
