<script lang="ts">
  import demoSongList from '@/lists/demoSongList';
  import SongIndexItem from '@/ui/SongIndexItem';
</script>

<section class="flex w-full flex-col items-stretch" aria-roledescription="song list">
  <header
    class="relative flex items-center rounded-xl bg-white py-[3px]"
    style:box-shadow="0 6px 9px 0 rgba(0, 0, 0, 0.14), 0 3px 5px -1px rgba(0, 0, 0, 0.2)"
    role="heading"
    aria-level="3"
    aria-label="Demo songs"
  >
    <div class="flex flex-auto cursor-default rounded-xl px-6 py-2.5 text-black">
      <strong class="notranslate mr-1.5 font-medium [@media(min-width:500px)]:text-lg">
        Demo songs
      </strong>
    </div>
  </header>
  <div
    class={['relative mx-4 my-0 rounded-b-md bg-white outline-none']}
    style:background-image="linear-gradient(to bottom, #ccc 0%, rgba(255, 255, 255, 0) 100%)"
    style:background-position="left top"
    style:background-repeat="repeat-x"
    style:background-size="100% 3px;"
    style:box-shadow="0 2px 4px 0 rgba(0, 0, 0, 0.17), 0 2px 7px 0 rgba(0, 0, 0, 0.13);"
    style:clip-path="inset(0px -200px -200px -200px)"
  >
    <div class={['pb-1 pt-[3px]']}>
      <ul class="songIndexList">
        {#each demoSongList as song (song._id)}
          {@html SongIndexItem.renderHtml({
            song,
            loggedIn: false,
          })}
        {/each}
      </ul>
    </div>
  </div>
</section>
