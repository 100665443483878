import HelpWindow from '@/help/HelpWindow';

declare const bootbox: any;

export function showAudioIssueNotice({
  message,
  action,
}: {
  message: string;
  action: 'reload' | 'restart-help' | 'dismiss';
}): void {
  const label = (
    {
      'reload': 'Reload Strum Machine',
      'restart-help': 'Learn how to do this',
      'dismiss': 'Dismiss',
    } as Record<typeof action, string>
  )[action];
  bootbox.confirm({
    title: 'Audio Issue Detected',
    message,
    buttons: {
      confirm: { label, className: 'btn-block' },
      cancel: { className: 'hide' },
    },
    closeButton: true,
    callback(result: boolean) {
      if (result) {
        if (action == 'reload') {
          window.location.reload();
        } else if (action == 'restart-help') {
          HelpWindow.show('restart-ios');
        }
      }
    },
  });
}
