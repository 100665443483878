import * as Sentry from '@sentry/browser';
import { Meteor } from 'meteor/meteor';
import AudioManager from '@/audio/engine/AudioManager';
import { Conductor } from '@/Conductor';
import { Crnt } from '@/Crnt';
import { ReactiveTimer } from '@/lib/ReactiveTimer';

const SongRecorder = {};

const _armed = new ReactiveVar(false);
SongRecorder.armed = () => _armed.get();
SongRecorder.arm = () => _armed.set(true);
SongRecorder.disarm = () => {
  _armed.set(false);
  if (recorder?.isRecording()) recorder?.cancelRecording();
};

var recorder;
var recordTimer;
const _loaded = new ReactiveVar(false);
SongRecorder.load = () => {
  if (Meteor.isCordova) return;
  if (!recordTimer) recordTimer = new ReactiveTimer(1);
  loadRecorder()
    .then((loadedRecorder) => {
      recorder = loadedRecorder;
      const initialTitle = Crnt.musicTitle() ?? 'Unknown song';
      recorder.onComplete = (recording, blob) => {
        const song = Crnt.song();
        const bpm = !song || song.prefs.autoSpeedup.enabled() ? null : song.bpm();
        const filename = (Crnt.musicTitle() ?? initialTitle)
          .replace(/\//g, ' - ')
          .replace(/[\\/:"*?<>|]+/gi, '');
        const link = document.createElement('a');
        link.download = `SM - ${filename}${bpm ? ` - ${bpm} BPM` : ''}.${recording.encoding}`;
        link.href = URL.createObjectURL(blob);
        link.click();
        URL.revokeObjectURL(link.href);
      };
      _loaded.set(true);
    })
    .catch((err) => Sentry.captureException(err));
};
SongRecorder.loaded = () => _loaded.get();

SongRecorder.recordingTime = () => {
  recordTimer.watch();
  return recorder.recordingTime();
};

Meteor.startup(() => {
  Tracker.autorun(() => {
    if (Conductor.stopped() && recorder?.isRecording()) {
      if (recorder?.recordingTime() < 2) {
        recorder.cancelRecording();
      } else {
        _armed.set(false);
        Bert.alert('Will begin downloading shortly...', 'success');
        setTimeout(() => {
          Bert.hide();
          recorder.finishRecording();
        }, 4500);
      }
    }
    if (!Conductor.stopped() && SongRecorder.armed()) {
      recorder.startRecording();
    }
  });
});

var loadPromise;
function loadRecorder() {
  loadPromise ??= new Promise((resolve, reject) => {
    setTimeout(() => {
      $.getScript('/javascript/WebAudioRecorder.js')
        .done(() => {
          resolve(
            // eslint-disable-next-line no-undef
            new WebAudioRecorder(AudioManager.webAudioRecoderTap, {
              workerDir: '/javascript/',
              encoding: 'mp3',
              options: {
                'timeLimit': 600,
                'bufferSize': 16384,
                'mp3': {
                  'bitRate': 256,
                },
                'encodeAfterRecord': true,
              },
            })
          );
        })
        .fail((jqxhr, settings, exception) => {
          reject(exception);
        });
    }, 1000);
  });
  return loadPromise;
}

export default SongRecorder;
