import { crosspick } from '@/band/instruments/guitar/GuitarActions';
import type { GuitarCore } from '@/band/instruments/guitar/GuitarCore';
import type { GuitarIntent } from '@/band/instruments/guitar/GuitarIntent';
import { swingOptions } from '@/band/swingOptions';

const firstHalf: GuitarIntent[] = [
  crosspick({ series: 'std', noteInSeries: 1, db: 0 }),
  crosspick({ series: 'std', noteInSeries: 2, db: -2 }),
  crosspick({ series: 'std', noteInSeries: 3, db: 0 }),
  crosspick({ series: 'std', noteInSeries: 4, db: -2 }),
];

const secondHalf: GuitarIntent[] = [
  crosspick({ series: 'std', noteInSeries: 3, db: 0 }),
  crosspick({ series: 'std', noteInSeries: 2, db: -2 }),
  crosspick({ series: 'std', noteInSeries: 1, db: 0 }),
  crosspick({ series: 'std', noteInSeries: 3, db: -2 }),
];

export const ArpeggiatedGuitarCore: GuitarCore = {
  id: 'a',
  title: 'Arpeggios',

  timeSignatures: ['4/4'],

  linkedSettingsKeys: [],
  otherSettingsKeys: ['timing'],

  chordStyle: 'cowboy',

  swingCategory: 'normal',
  defaultSwing: swingOptions['5:4'],

  presets: [
    {
      name: 'Arpeggios',
      settings: {},
    },
  ],

  processMeasure(measure, _settings) {
    const chordChanged = measure.beats[2]?.chordChanged;
    return [...firstHalf, ...(chordChanged ? firstHalf : secondHalf)];
  },

  generateViz(settings) {
    settings;
    return [];
  },
};
