import { strict as assert } from 'assert';
import { Meteor } from 'meteor/meteor';
import { ReactiveVar } from 'meteor/reactive-var';
import { Tracker } from 'meteor/tracker';
import type { Song } from '@/chart/Song';
import type { SongRecord } from '@/collections/SongsCollection';
import { SongsCollection } from '@/collections/SongsCollection';
import type { SUARecord } from '@/collections/SUACollection';
import type { ListBackend } from '@/library/ListBackend';
import { OfflineSongBackend } from '@/library/OfflineSongBackend';
import { SongUserAttributesBackend } from '@/library/SongUserAttributesBackend';
import { checkOfflineStorageAvailable } from '@/local-db/checkOfflineStorageAvailable';
import { rpcFetchSong } from '@/server/methods/songs/rpcFetchSong';
import { rpcFetchSUA } from '@/server/methods/songs/rpcFetchSUA';
import { callServerMethodWithoutRetry } from '@/utilities/callServerMethodWithoutRetry';
import waitUntilReactive from '@/utilities/waitUntilReactive';

export class MusicLibrarySongs {
  indexCollection = SongsCollection;

  private listBackend?: ListBackend;
  offlineSongBackend?: OfflineSongBackend;
  private suaBackend?: SongUserAttributesBackend;

  private subscriptions: Record<string, Meteor.SubscriptionHandle> = {};

  private _loadingStarted = false;
  async load({ listBackend }: { listBackend: ListBackend }): Promise<void> {
    if (this._loadingStarted) return await waitUntilReactive(() => this._ready.get());
    this._loadingStarted = true;

    this.listBackend = listBackend;

    this.subscriptions.publicSongs = Meteor.subscribe('songs.public');
    this.subscriptions.privateSongs = Meteor.subscribe('songs.private');

    this.suaBackend = new SongUserAttributesBackend();

    const offlineAvailable = await checkOfflineStorageAvailable();
    if (offlineAvailable) {
      this.offlineSongBackend = new OfflineSongBackend();
      this.indexCollection = this.offlineSongBackend.groundedIndex;
      this.groundData();
      Tracker.autorun(() => {
        assert(this.suaBackend && this.offlineSongBackend);
        this.suaBackend.preventSync.set(this.offlineSongBackend.pendingChanges() > 0);
      });
    }

    // Assumption: if no subscriptions, data has already been grounded
    await waitUntilReactive(
      () =>
        (this.subscriptions.publicSongs.ready() && this.subscriptions.privateSongs.ready()) ||
        (!Meteor.status().connected && this.offlineSongBackend?.groundedIndex.loaded())
    );

    this.suaBackend.startAttributeUpdateAutorun();

    this._ready.set(true);
  }

  // This is a separate async function because we don't want it holding up live access
  private async groundData(): Promise<void> {
    await waitUntilReactive(
      () =>
        this.subscriptions.publicSongs.ready() &&
        this.subscriptions.privateSongs.ready() &&
        this.listBackend?.ready()
    );
    assert(this.listBackend);
    this.offlineSongBackend?.groundWithLiveCollections(
      SongsCollection,
      this.listBackend.songsOnlyInLists
    );
  }

  private _ready = new ReactiveVar(false);
  ready(): boolean {
    return this._ready.get();
  }

  offlineReady(): boolean {
    return !!(this.offlineSongBackend?.offlineReady() && this.suaBackend?.ready());
  }

  private _blockingOp = new ReactiveVar(false);
  blockingOperationInProgress(): boolean {
    return this._blockingOp.get();
  }
  block(): void {
    this._blockingOp.set(true);
  }
  unblock(): void {
    this._blockingOp.set(false);
  }

  getAllSongNamesForDupeCheck(): { _id: string; name: string }[] {
    return (this.offlineSongBackend?.groundedIndex || SongsCollection).find().map((song) => {
      return {
        _id: song._id,
        name: song.name,
      };
    });
  }

  /**
   * Fetches a song record from local storage (if available) and/or the server.
   * @param songId - _id of song to fetch
   * @returns { song: SongRecord, songUserAttributes: SUARecord }
   * @throws Meteor.Error if song can't be found
   */
  fetchFull(
    songId: string,
    options: { blocking?: boolean } = {}
  ): Promise<{ song: SongRecord; songUserAttributes?: Partial<SUARecord> }> {
    if (options.blocking) this._blockingOp.set(true);
    return new Promise((resolve, reject) => {
      // Let's have a race between offline and online fetching!
      let onlineFetchSuccessful = false;
      let offlineFetchSuccessful = false;
      if (this.offlineSongBackend) {
        this.offlineSongBackend
          .fetchFullRecord(songId)
          .then((song?: SongRecord) => {
            if (song && !onlineFetchSuccessful) {
              offlineFetchSuccessful = true;
              const songUserAttributes = this.suaBackend?.fetchAttributes(song._id);
              resolve({ song, songUserAttributes });
            }
          })
          .finally(() => this._blockingOp.set(false));
      }
      Meteor.setTimeout(
        () => {
          if (offlineFetchSuccessful) return;
          rpcFetchSong({ songId })
            .then((data) => {
              if (offlineFetchSuccessful) return;
              onlineFetchSuccessful = true;
              resolve(data);
            })
            .catch((err) => {
              if (offlineFetchSuccessful) return;
              reject(err);
            })
            .finally(() => this._blockingOp.set(false));
        },
        this.offlineSongBackend ? 1000 : 0
      ); // timeout is to give offline DB a head start
    });
  }

  async fetchSUA(songId: string): Promise<Partial<SUARecord> | undefined> {
    if (this.suaBackend?.offlineReady()) {
      return this.suaBackend.fetchAttributes(songId);
    } else {
      return await rpcFetchSUA({ songId });
    }
  }

  async saveAttributes(
    subjectId: string,
    attributes: Partial<{ [Property in keyof SUARecord]: SUARecord[Property] | null }>
  ): Promise<void> {
    await waitUntilReactive(() => this._ready.get());
    this.suaBackend?.saveAttributes(subjectId, attributes);
  }

  handleUpdatedSongId(oldId: string, newId: string): void {
    this.suaBackend?.reviseSubjectId(oldId, newId);
  }

  async upsert(song: SerializedSong | Song) {
    return song._id ? this.update(song) : this.add(song);
  }

  async add(songOrSongData: Song | SerializedSong): Promise<string> {
    this._blockingOp.set(true);
    const songData = 'serialize' in songOrSongData ? songOrSongData.serialize() : songOrSongData;
    try {
      if (Meteor.status().connected) {
        return await callServerMethodWithoutRetry('songs.insert', songData);
      } else {
        if (this.offlineSongBackend) {
          return this.offlineSongBackend.insert(songData);
        } else {
          throw new Meteor.Error(
            'AddSongFailed',
            "Can't save song while offline. Please try again after connection is restored."
          );
        }
      }
    } finally {
      Meteor.defer(() => this._blockingOp.set(false));
    }
  }

  async update(songOrSongData: Song | SerializedSong): Promise<void> {
    this._blockingOp.set(true);
    const songData = 'serialize' in songOrSongData ? songOrSongData.serialize() : songOrSongData;
    assert(songData._id);
    try {
      songData.updatedAt = new Date();
      if (Meteor.status().connected) {
        await callServerMethodWithoutRetry('songs.update', songData);
        await this.offlineSongBackend?.setFullRecord(songData._id, songData);
      } else {
        if (this.offlineSongBackend) {
          await this.offlineSongBackend.update(songData);
        } else {
          throw new Meteor.Error(
            'UpdateSongFailed',
            "Can't save song while offline. Please try again after connection is restored."
          );
        }
      }
    } finally {
      Meteor.defer(() => this._blockingOp.set(false));
    }
  }

  async delete(songId: string): Promise<void> {
    this._blockingOp.set(true);
    try {
      if (Meteor.status().connected) {
        await callServerMethodWithoutRetry('songs.delete', songId);
        await this.offlineSongBackend?.removeFullRecord(songId);
      } else {
        if (this.offlineSongBackend) {
          await this.offlineSongBackend.remove(songId);
        } else {
          throw new Meteor.Error(
            'DeleteSongFailed',
            "Can't delete song while offline. Please try again after connection is restored."
          );
        }
      }
    } finally {
      Meteor.defer(() => this._blockingOp.set(false));
    }
  }
}
