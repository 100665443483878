<script lang="ts">
  import { quadInOut } from 'svelte/easing';
  import { fade, fly } from 'svelte/transition';
  import type { Band } from '@/band/Band';
  import { eventTracker } from '@/browser/analytics/eventTracker';
  import { Conductor } from '@/Conductor';
  import { Crnt } from '@/Crnt';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import { MultiPanelModalController } from '@/ui/settings/MultiPanelModalController.svelte';
  import { slideFadeHorizontal } from '@/ui/svelte-transitions';
  import { UserProfile } from '@/user/UserProfile';

  const modalController = MultiPanelModalController.current();

  interface Props {
    title?: string;
    band: Band;
  }

  let { title = '', band }: Props = $props();

  let isMedley = $state(false);
  trackerEffect(() => (isMedley = !!Crnt.medley()));

  let listenDisabled = $state(false);
  trackerEffect(() => (listenDisabled = !Crnt.song()?.key()));

  let playing: boolean = $state(false);
  trackerEffect(() => (playing = Conductor.playing()));

  let canGoBack: boolean = $derived(
    modalController.activeScreenIndex > 0 || !!modalController.subview
  );

  let presetModeActive = $state(false);
  trackerEffect(() => (presetModeActive = !!band.presetMode()));

  let direction = $derived(modalController.mostRecentZoomDirection);

  function onClickBack(event: MouseEvent & { currentTarget: HTMLButtonElement }) {
    event.currentTarget.blur();
    modalController.zoomOut();
  }

  function onClickClose() {
    if (presetModeActive) {
      band.enterNormalPresetMode();
    } else {
      Modal.hide();
    }
  }

  function onClickListen() {
    if (Conductor.playing()) {
      Conductor.stop();
      eventTracker.bandListenStop();
    } else {
      const sections = Crnt.song()?.sections;
      const firstSectionHasTooManyFX =
        sections &&
        sections[0] &&
        sections[1] &&
        sections[0].cells.filter((c) => c.effect).length > sections[0].cells.length / 3 &&
        sections[1].cells.filter((c) => c.effect).length < sections[1].cells.length / 4;
      if (Conductor.stopped() && firstSectionHasTooManyFX) {
        Conductor.playFrom({ section: 1, rep: 0, cell: 0, beat: 0 }, { skipCountIn: true });
      } else {
        Conductor.play({ skipCountIn: true });
      }
      eventTracker.bandListenStart();
    }
    UserProfile.recordMilestone('BAND_LISTEN');
    pulsateIfNotAtRoot = false;
  }

  let pulsateIfNotAtRoot = $state(false);
  let firstPulsateCheck = true;
  trackerEffect(() => {
    Crnt.song()?.band.reactiveInstruments(); // trigger reactive dependency
    pulsateIfNotAtRoot = !firstPulsateCheck && !playing && UserProfile.notMilestone('BAND_LISTEN');
    firstPulsateCheck = false;
  });
</script>

<nav class="rounded-t-[10px] bg-primary-700">
  <div class="nav-buttons flex items-stretch">
    <button
      class="btn back-btn flex items-center justify-center"
      onclick={canGoBack ? onClickBack : onClickClose}
    >
      <div class="flex items-center">
        {#if !canGoBack}
          <div class="flex items-center" transition:slideFadeHorizontal={{ duration: 250 }}>
            {#if presetModeActive}
              <svg class="h-3.5 w-2.5 opacity-80"><use xlink:href="#close" /></svg>
              <span class="translate-x-1 pl-1">Cancel</span>
            {:else}
              <svg class="h-3.5 w-3.5 opacity-80"><use xlink:href="#close-big" /></svg>
              <span class="translate-x-1 pl-1">Close</span>
            {/if}
          </div>
        {/if}
        {#if canGoBack}
          <div class="flex items-center" transition:slideFadeHorizontal={{ duration: 250 }}>
            <svg class="h-4 w-3 opacity-80"><use xlink:href="#back-chevron" /></svg>
            <span class="inline-block overflow-hidden pl-2">Back</span>
          </div>
        {/if}
      </div>
    </button>
    <div class="grid flex-1 grid-cols-1 grid-rows-1 overflow-hidden">
      {#key modalController.activeScreenIndex}
        <div
          class="col-span-1 col-start-1 row-span-1 row-start-1 flex h-[2.875rem] flex-1 items-center justify-center text-center font-medium leading-4 text-white"
          in:fly={{
            x: direction * 150,
            opacity: 0.75,
            duration: 250,
            easing: quadInOut,
          }}
          out:fly={{
            x: -direction * 150,
            opacity: 0.75,
            duration: 250,
            easing: quadInOut,
          }}
        >
          {title || ''}
        </div>
      {/key}
    </div>
    {#if !isMedley}
      <button
        class="btn listen-btn flex items-center justify-center"
        disabled={listenDisabled}
        class:pulsate={pulsateIfNotAtRoot && canGoBack}
        onclick={onClickListen}
      >
        <div class="flex items-center [&>span]:inline-block [&>span]:overflow-hidden">
          <svg
            aria-hidden="true"
            class="mr-1.5 block h-4 w-4 scale-110 opacity-80"
            class:animate-pulse={playing}
            fill="currentColor"
            stroke="currentColor"
            stroke-width="2"
            stroke-linejoin="round"
            stroke-miterlimit="1"
            viewBox="0 0 16 16"
          >
            {#if playing}
              <path transition:fade={{ duration: 150 }} d="M3,3 L13,3 13,13 3,13 z" />
            {:else}
              <path transition:fade={{ duration: 150 }} d="M2,2 L12,8 2,14 z" />
            {/if}
          </svg>
          {#if playing}
            <span transition:slideFadeHorizontal={{ duration: 200 }}>Stop</span>
          {:else}
            <span transition:slideFadeHorizontal={{ duration: 200 }}>Play</span>
          {/if}
        </div>
      </button>
    {/if}
  </div>
</nav>

<style>
  .nav-buttons {
    .btn {
      color: white;
      font-weight: normal;
      letter-spacing: 0.3px;
      border-radius: 0;
      min-width: 5.5rem;
      height: 2.875rem;
      padding: 0.5rem 0.75rem 0.5rem 0.75rem;

      &:hover {
        background-color: var(--primary-600);
      }

      &:active {
        box-shadow: inset 0 0 2rem var(--primary-500);
      }

      &:focus-visible {
        box-shadow: inset 0 0 0 0.2rem var(--primary-300);
      }

      &:first-child {
        border-radius: 10px 0 0 0;
      }

      &:last-child {
        border-radius: 0 10px 0 0;
      }

      svg {
        transition: all 200ms ease;
      }
    }

    .btn.back-btn {
      &:active {
        svg {
          transform: translateX(-1.5px);
        }
      }
    }

    .btn.listen-btn {
      &.pulsate {
        animation: pulsate 4s 4s ease-in-out infinite;
      }
    }
  }

  @keyframes pulsate {
    0% {
      background-color: transparent;
    }
    10% {
      background-color: var(--primary-500);
    }
    30% {
      background-color: transparent;
    }
    100% {
    }
  }
</style>
