import { Meteor } from 'meteor/meteor';
import type { TypeGroupKey } from '@/editor/ui/EditorChordTypes.svelte';
import { UserProfile } from '@/user/UserProfile'; // because these are gotten at startup by instrument sequencers

const beatOffsetDisplayFunc = (value: number): string => {
  if (value > 0) return `${(value * 100).toFixed(0)}% of beat ahead`;
  if (value < 0) return `${(-value * 100).toFixed(0)}% of beat behind`;
  return 'Right on the beat';
};

const settings = Object.freeze({
  chordSystem: {
    label: 'Show chords as:',
    default: <'letters' | 'numbers' | 'roman'>'letters',
  },
  chordRepeatedColor: {
    label: 'Shading [for repeated chords]',
    default: <'black' | 'darkgray' | 'mediumgray' | 'lightgray'>'darkgray',
  },
  chordRepeatedSlash: {
    label: 'Use slashes',
    help: 'Only write the chord in when it changes, otherwise use a slash.',
    default: false,
  },
  chordRepeatedOnePerBar: {
    label: 'One chord per bar [(if possible)]',
    help: 'Offers a cleaner look by leaving out the second chord in each bar (unless there’s a chord change).',
    default: false,
  },

  editorChordSystem: {
    default: <'letters' | 'numbers'>'letters',
  },

  countInEnabled: {
    label: 'Enable count-ins',
    help: 'Turns on/off the “1, 2, 3, 4” count-in you hear when you first hit play.',
    default: true,
  },
  countInEveryTime: {
    label: 'Count [into] every repetition',
    help: 'Do a count-in every time the song or looped section repeats.',
    default: false,
  },
  countInSpeak: {
    label: 'Spoken count',
    help: 'E.g., “1, 2, 3, 4...”',
    default: true,
  },
  countInDampenedStrum: {
    label: 'Dampened strum',
    help: 'Strum with strings dampened during the count-in.',
    default: true,
  },
  countInDouble: {
    label: 'Double-length count[-in]',
    help: 'Extend the count-in so that you have more time to get ready before the strumming starts.',
    default: false,
  },
  countInVolume: {
    label: 'Volume',
    default: 1.0,
    min: 0.1,
    max: 1,
    step: 0.05,
    displayFunc: (value: number) => `${(value * 100).toFixed(0)}%`,
  },

  guitarEnabled: {
    label: 'Guitar',
    default: true,
  },
  guitarVolume: {
    label: 'Volume',
    default: 1.0,
    min: 0.1,
    max: 1,
    step: 0.05,
    displayFunc: (value: number) => `${(value * 100).toFixed(0)}%`,
  },
  guitarPan: {
    label: 'Pan',
    default: 0.0,
    min: 1,
    max: -1,
    step: 0.1,
    displayFunc: (value: number) =>
      value == 0 ? 'center' : `${(Math.abs(value) * 100).toFixed(0)}% ${value > 0 ? 'R' : 'L'}`,
  },
  guitarBCBalance: {
    label: 'Boom/chuck balance',
    default: 0.5,
    min: 0,
    max: 1,
  },
  guitarUpbeatOffset: {
    label: 'Guitar “chuck” offset',
    default: 0,
    min: -0.2,
    max: 0.2,
    step: 0.01,
    displayFunc: beatOffsetDisplayFunc,
  },
  guitarCapoG: {
    label: 'Guitar: use capoed G',
    help: "When in the key of A, Bb, or B, and with this enabled, you'll hear chords being played out of a capoed G position, which sounds better on a wider range of songs (e.g., modal songs). Recommended.",
    default: true,
  },

  bassEnabled: {
    label: 'Standup bass',
    default: false,
  },
  bassVolume: {
    label: 'Volume',
    default: 1.0,
    min: 0.1,
    max: 1,
    step: 0.05,
    displayFunc: (value: number) => `${(value * 100).toFixed(0)}%`,
  },
  bassPan: {
    label: 'Pan',
    default: 0.0,
    min: 1,
    max: -1,
    step: 0.1,
    displayFunc: (value: number) =>
      value == 0 ? 'center' : `${(Math.abs(value) * 100).toFixed(0)}% ${value > 0 ? 'R' : 'L'}`,
  },
  bassOffset: {
    label: 'Bass notes offset',
    default: 0,
    min: -0.2,
    max: 0.2,
    step: 0.01,
    displayFunc: beatOffsetDisplayFunc,
  },

  mandolinEnabled: {
    label: 'Mandolin [(experimental)]',
    default: false,
  },
  mandolinVolume: {
    label: 'Volume',
    default: 1.0,
    min: 0.1,
    max: 1,
    step: 0.05,
    displayFunc: (value: number) => `${(value * 100).toFixed(0)}%`,
  },
  mandolinPan: {
    label: 'Pan',
    default: 0.0,
    min: 1,
    max: -1,
    step: 0.1,
    displayFunc: (value: number) =>
      value == 0 ? 'center' : `${(Math.abs(value) * 100).toFixed(0)}% ${value > 0 ? 'R' : 'L'}`,
  },
  mandolinChopOffset: {
    label: 'Mandolin chop offset',
    default: 0,
    min: -0.2,
    max: 0.2,
    step: 0.01,
    displayFunc: beatOffsetDisplayFunc,
  },

  feetVolume: {
    label: 'Volume',
    default: 1.0,
    min: 0.1,
    max: 1,
    step: 0.05,
    displayFunc: (value: number) => `${(value * 100).toFixed(0)}%`,
  },
  feetEnabled: {
    label: 'Feet',
    default: true,
  },
  feetPan: {
    label: 'Pan',
    default: 0.0,
    min: 1,
    max: -1,
    step: 0.1,
    displayFunc: (value: number) =>
      value == 0 ? 'center' : `${(Math.abs(value) * 100).toFixed(0)}% ${value > 0 ? 'R' : 'L'}`,
  },

  guitarMuted: { default: false },
  bassMuted: { default: false },
  mandolinMuted: { default: false },
  feetMuted: { default: false },

  clickFeaturePreview: {
    label: 'Metronome feature preview',
    help: 'Adds a “Metronome” option to the right-side menu on song pages, which lets you toggle a click track or metronome that is heard with song playback. This is an early feature preview, subject to change.',
    default: false,
  },

  clickEnabled: {
    label: 'Enable metronome w/playback',
    default: false,
  },

  clickFreqStd: {
    label: 'Clicks per measure',
    help: 'The number of clicks in each measure. "Follow band swing" uses the "Upstroke swing" setting from Band Settings.',
    default: '2' as '1' | '2' | '2o' | '4' | '8' | '8s',
    defaultFunc: (): '2' | '4' => {
      return UserProfile.getPreference('tpbStandard') == 2 ? '2' : '4';
    },
  },

  clickFreqWaltz: {
    label: 'Clicks per measure',
    help: 'The number of clicks in each measure of 3/4 time. "Follow band swing" uses the "Upstroke swing" setting from Band Settings.',
    default: '3' as '1' | '2e' | '2o' | '3' | '6' | '6s',
    defaultFunc: (): '1' | '3' => {
      return UserProfile.getPreference('tpbWaltzes') == 3 ? '1' : '3';
    },
  },

  clickFreqJig: {
    label: 'Clicks per measure',
    help: 'The number of clicks in each measure. This setting only applies when in 6/8 time.',
    default: '2' as '1' | '2' | '3' | '6',
  },

  clickFreqSlipJig: {
    label: 'Clicks per measure',
    help: 'The number of clicks in each measure. This setting only applies when in 9/8 time.',
    default: '3' as '1' | '3' | '9',
  },

  clickSound: {
    label: 'Sound',
    default: 'block',
  },

  clickVolume: {
    label: 'Volume',
    default: 1,
    min: 0,
    max: 1,
    step: 0.05,
    displayFunc: (value: number) => `${(value * 100).toFixed(0)}%`,
  },

  clickMuteBand: {
    label: 'Mute the band',
    default: false,
  },

  recorder: {
    label: 'Backing track recorder',
    help: "Adds a “Download” option to the right-side menu on song pages, where you can record Strum Machine's output (not your mic) and download to MP3. Only available in the web browser, not the mobile apps.",
    default: null,
  },

  footOutShortcut: {
    label: '"Foot out" shortcut',
    help: 'Gives the spacebar double duty as a "Foot out" key. Tapping it during playback tells the app to finish the song at the end of the current rep. (Tap again to end immediately)',
    default: false,
  },

  asReset: {
    label: 'Auto-Speedup BPM reset',
    help: 'Set the tempo back to the starting speed after ending a song when Auto-Speedup is enabled.',
    default: false,
  },

  amReset: {
    label: 'Auto-Modulate key reset',
    help: 'Set the key back to the original key after ending a song when Auto-Modulate is enabled.',
    default: false,
  },

  haye: {
    label: 'Hear As You Edit',
    help: '',
    default: false,
  },

  tpbStandard: {
    label: 'Show standard time BPMs as',
    default: 2 as 2 | 1,
  },

  tpbWaltzes: {
    label: 'Show waltz BPMs as',
    default: 1 as 1 | 3,
  },

  monospaceNotepad: {
    label: 'Notepad editor monospace font',
    help: 'Renders all text in each song’s Notepad in a monospace font. You can also make a certain block monospace by putting three backticks (```) on their own line before and after the area you want to be monospace.',
    default: false,
  },

  doubleTapPlayFromHere: {
    label: 'Two taps on chord to start playing',
    help: 'By default, you can tap on any chord to start playing from that point. Turning this on requires you to double-tap on the chord to start playing when using a touchscreen or mobile device. This makes it less likely that you will accidentally start playback.',
    default: false,
  },

  expandedChordTypeGroups: {
    label: 'Expanded chord type groups',
    default: [] as TypeGroupKey[],
  },

  // HACK: to get data on notes position
  notesPosition: {
    label: 'Notes position',
    default: null,
  },
});

export type UserPreferenceKey = keyof typeof settings;

export type UserPreferencesValues = {
  [K in UserPreferenceKey]: (typeof settings)[K]['default'];
};

export type UserPreference<Key extends UserPreferenceKey> = (typeof settings)[Key];

const UserPreferences = {
  settings: settings,

  set<Key extends UserPreferenceKey>(key: Key, value: (typeof settings)[Key]['default']): void {
    UserProfile.setPreference(key, value);
  },

  get<Key extends UserPreferenceKey>(key: Key): (typeof settings)[Key]['default'] {
    return UserProfile.getPreference(key);
  },
};

export default UserPreferences;

if (Meteor.isDevelopment) {
  const dev: Record<string, any> = ((window as unknown as Record<string, unknown>).dev ||= {});
  dev.UserPreferences = UserPreferences;
}
