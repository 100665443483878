import type { Cell } from '@/chart/Cell.svelte';
import type { Song } from '@/chart/Song';
import { Conductor } from '@/Conductor';
import { HearAsYouEdit } from '@/editor/HearAsYouEdit';
import { UserProfile } from '@/user/UserProfile';

export function handleInteractionsWithCellsIfSongPresent(
  node: HTMLElement,
  { cell, song, noClickToPlay }: { cell: Cell; song: Song | undefined; noClickToPlay?: boolean }
) {
  if (song) return handleInteractionsWithCells(node, { cell, song, noClickToPlay });
  return;
}

export function handleInteractionsWithCells(
  node: HTMLElement,
  { cell, song, noClickToPlay }: { cell: Cell; song: Song; noClickToPlay?: boolean }
) {
  let touchLockSelect: boolean, touchLockScroll: boolean;
  let startCoords = { x: NaN, y: NaN };

  let thisPos: SectionCellBeat = [cell.layout.sectionIndex, cell.layout.cellIndex];
  let thisSongIndex = song.index();

  let lastTouchTimeMs: number = -Infinity;
  let thisTouchTimeMs: number = 0;

  const handleClick = (event: MouseEvent) => {
    lastTouchTimeMs = thisTouchTimeMs;
    thisTouchTimeMs = performance.now();

    if (
      song.loop.spansMultipleCells() &&
      !(event.currentTarget as HTMLElement | undefined)?.parentElement?.classList.contains('loop')
    ) {
      song.loop.reset();
      return;
    }

    if (noClickToPlay) return;
    if (!Conductor.ready()) return;

    const mustDoubleClickToPlay =
      !song.loop.exists() &&
      (UserProfile.getPreference('doubleTapPlayFromHere') || song.editMode());

    if (mustDoubleClickToPlay && thisTouchTimeMs - lastTouchTimeMs > 700) return;

    void Conductor.playFrom({
      song: thisSongIndex,
      section: thisPos[0],
      cell: thisPos[1],
      beat: 0,
      rep: (cell.repConstraint?.[0] ?? 1) - 1,
    });

    UserProfile.recordMilestone('PLAY_FROM_HERE');
  };

  function detectBeatFromTargetElement(element: HTMLElement): number {
    if (element.classList.contains('ChartCell')) return 0;
    if (!element.parentElement) return 0;
    if ('beat' in element.dataset) return +(element.dataset.beat ?? 0);
    return detectBeatFromTargetElement(element.parentElement);
  }

  function findCellForElement(element: Element | null | undefined): Cell | undefined {
    if (!element) return undefined;
    if ('cell' in element) return element.cell as Cell;
    if (element.classList.contains('ChartCell')) return undefined;
    return findCellForElement(element.parentElement);
  }

  const handleMousedown = (event: MouseEvent) => {
    if (!(song.editMode() || Conductor.ready())) return;
    if (event.shiftKey && song.focus.exists()) {
      song.dragSelection.setCells({ start: song.focus.firstCell(), end: cell });
    } else {
      song.dragSelection.setToSingleCell(
        cell,
        detectBeatFromTargetElement(event.target as HTMLElement)
      );
    }
    window.addEventListener('mouseup', dragEndHandler);
  };

  const handleTouchstart = (event: TouchEvent) => {
    if (!(song.editMode() || Conductor.ready())) return;
    song.dragSelection.setToSingleCell(cell);
    const loc = event.changedTouches[0];
    if (!loc) return;
    startCoords = { x: loc.clientX, y: loc.clientY };
    touchLockSelect = false;
    touchLockScroll = false;
    window.addEventListener('touchend', dragEndHandler);
    window.addEventListener('touchcancel', dragEndHandler);
  };

  const handleMousemove = () => {
    const dragSelectionEndCell = song.dragSelection.endCell();
    if (!dragSelectionEndCell) return;
    if (dragSelectionEndCell == cell) return;
    if (song.dragSelection.spansMultipleCells() && Conductor.playing()) {
      Conductor.pause();
    }
    song.dragSelection.extendToCell(cell);
    window.getSelection()?.removeAllRanges();
  };

  const handleTouchmove = (event: TouchEvent) => {
    if (!song.dragSelection.exists()) return;

    const touchLocation = event.changedTouches[0];
    if (!touchLocation) return;
    if (touchLockSelect) {
      event.preventDefault();
      window.getSelection()?.removeAllRanges();
      // Yes, we need to look under the fingertip, because touchmove only gets fired for the element that was first touched
      const hoveredCell = findCellForElement(
        document.elementFromPoint(touchLocation.clientX, touchLocation.clientY)
      );
      if (!hoveredCell) return;
      if (song.dragSelection.endCell() == hoveredCell) return;
      song.dragSelection.extendToCell(hoveredCell);
      if (song.dragSelection.spansMultipleCells() && Conductor.playing()) {
        Conductor.pause();
      }
    } else if (!touchLockScroll) {
      const offset = {
        x: touchLocation.clientX - startCoords.x,
        y: touchLocation.clientY - startCoords.y,
      };
      touchLockSelect = Math.abs(offset.x) > 20;
      touchLockScroll = !touchLockSelect && Math.abs(offset.y) > 30;
    }
  };

  const dragEndHandler = function (event: MouseEvent | TouchEvent) {
    window.removeEventListener('mouseup', dragEndHandler);
    window.removeEventListener('touchend', dragEndHandler);
    window.removeEventListener('touchcancel', dragEndHandler);
    if (event.type != 'touchcancel') {
      if (song.editMode() && !touchLockScroll) {
        if (song.dragSelection.spansMultipleCells()) {
          song.focus.setCells({
            start: song.dragSelection.firstCell(),
            end: song.dragSelection.lastCell(),
          });
        } else {
          song.focus.setToSingleCell(
            song.dragSelection.firstCell(),
            detectBeatFromTargetElement(event.target as HTMLElement)
          );
        }
        if (!song.dragSelection.spansMultipleCells()) {
          if (event.type == 'mouseup') {
            // avoids double playback
            // TODO: find a more elegant solution
            HearAsYouEdit.trigger();
          }
        }
      } else {
        if (song.dragSelection.spansMultipleCells()) {
          song.loop.setCells({
            start: song.dragSelection.firstCell(),
            end: song.dragSelection.lastCell(),
          });
        }
      }
    }
    touchLockSelect = false;
    touchLockScroll = false;
    song.dragSelection.reset();
  };

  node.addEventListener('click', handleClick);
  node.addEventListener('mousedown', handleMousedown);
  node.addEventListener('touchstart', handleTouchstart);
  node.addEventListener('mousemove', handleMousemove, { passive: true });
  node.addEventListener('touchmove', handleTouchmove);

  return {
    update({ cell: newCell, song: newSong }: { cell: Cell; song: Song }) {
      cell = newCell;
      song = newSong;
      thisPos = [cell.layout.sectionIndex, cell.layout.cellIndex];
      thisSongIndex = newSong.index();
    },
    destroy() {
      node.removeEventListener('click', handleClick);
      node.removeEventListener('mousedown', handleMousedown);
      node.removeEventListener('touchstart', handleTouchstart);
      node.removeEventListener('mousemove', handleMousemove, {
        passive: true,
      } as EventListenerOptions);
      node.removeEventListener('touchmove', handleTouchmove);
    },
  };
}
