const grabberDataSVG = `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="31 30 19 19"><path style="fill: hsla(206deg 100% 90% / 80%)" d="M34 31c.6 0 1 .4 1 1v14a1 1 0 1 1-2 0V32c0-.6.4-1 1-1Zm6 0c.6 0 1 .4 1 1v14a1 1 0 1 1-2 0V32c0-.6.4-1 1-1Zm6 0c.6 0 1 .4 1 1v14a1 1 0 1 1-2 0V32c0-.6.4-1 1-1Z"/></svg>')`;

export function fancySliderCSS({
  thumbDiameter,
  trackHeight,
  vertical,
}: {
  thumbDiameter: number;
  trackHeight: number;
  vertical?: boolean;
}): string {
  const thumbBorderWidth = thumbDiameter; // but in px
  const grabberSize = thumbDiameter / 2.5;
  return `
      --thumb-size: ${thumbDiameter}rem;
      --track-height: ${trackHeight}rem;
      --track-color: var(--muted-150);
      --track-shadow: inset ${vertical ? '.7px -.7px' : '0px -1px'} 1.5px rgba(255, 255, 255, 0.3),
          inset ${vertical ? '-0.7px 0.7px' : '0px 1px'} 2px rgba(0, 0, 0, 0.2);
      --fill-color: var(--primary-500);
      --thumb-border: ${thumbBorderWidth}px solid var(--primary-500);
      --thumb-background: var(--primary-300) ${grabberDataSVG} 50% 50% / ${grabberSize}rem ${grabberSize}rem no-repeat;
      --thumb-focused-border: ${thumbBorderWidth}px solid var(--primary-600);
      --thumb-focused-background: var(--primary-400) ${grabberDataSVG} 50% 50% / ${grabberSize}rem ${grabberSize}rem no-repeat;
      --thumb-hover-border: ${thumbBorderWidth}px solid var(--primary-500);
      --thumb-hover-background: var(--primary-400) ${grabberDataSVG} 50% 50% / ${grabberSize}rem ${grabberSize}rem no-repeat;
      --thumb-shadow: 0px 1px ${thumbDiameter * 4}px rgba(0, 0, 0, 0.2);
      `;
}
