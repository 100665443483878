import { ReactiveVar } from 'meteor/reactive-var';

export class AutoModulateState {
  reset(): void {
    this._currentRep.set(0);
    this._posInSeq.set(0);
  }

  private _startingKey = new ReactiveVar<Key | undefined>(undefined);
  startingKey(): Key | undefined {
    return this._startingKey.get();
  }

  setStartingKey(value: Key | undefined | null): void {
    this._startingKey.set(value || undefined);
  }

  //#region === Current Rep ===

  private _currentRep = new ReactiveVar(0);
  currentRep(): number {
    return this._currentRep.get();
  }

  setCurrentRep(value: number): void {
    this._currentRep.set(value);
  }

  incrementCurrentRep(): void {
    this._currentRep.set(this._currentRep.get() + 1);
  }

  //#endregion

  //#region === Position in sequence ===

  private _posInSeq = new ReactiveVar(0);
  posInSeq(): number {
    return this._posInSeq.get();
  }

  setPosInSeq(value: number): void {
    this._posInSeq.set(value);
  }

  //#endregion
}
