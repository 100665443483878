Template.accordionItem.onCreated(() => {
  Template.instance().itemId = Template.instance().data.id || Random.id();
  Template.instance().parentId = new ReactiveVar();
});

Template.accordionItem.onRendered(() => {
  Template.instance().parentId.set(Template.instance().view._domrange.parentElement.id);
});

Template.accordionItem.events({
  'show.bs.collapse': function (event, instance) {
    instance.$('.accordionItem').addClass('expanded').removeClass('collapsed');
    instance.$('.accordionItem-heading').addClass('expanded');
  },
  'hide.bs.collapse': function (event, instance) {
    instance.$('.accordionItem').removeClass('expanded').addClass('collapsed');
    instance.$('.accordionItem-heading').removeClass('expanded');
  },
});

Template.accordionItem.helpers({
  parentId() {
    return Template.instance().parentId.get();
  },
  itemId() {
    return Template.instance().itemId;
  },
});
