import { isArray, isString } from '@sindresorhus/is';
import { Accounts } from 'meteor/accounts-base';
import { Meteor } from 'meteor/meteor';
import type { Mongo } from 'meteor/mongo';
import { ReactiveVar } from 'meteor/reactive-var';
import { clog } from '@/browser/clog';
import { checkOfflineStorageAvailable } from '@/local-db/checkOfflineStorageAvailable';
import { GroundedCollection } from '@/local-db/GroundedCollection';
import localStorageDBNames from '@/local-db/localStorageDBNames';
import waitUntilReactive from '@/utilities/waitUntilReactive';

const _offlineReady = new ReactiveVar(false);

//@ts-expect-error Accessing private Accounts API
const liveUsers = Accounts.users as Mongo.Collection<Meteor.User>;
//@ts-expect-error This works fine
let groundedUsers: GroundedCollection<Meteor.User>;

checkOfflineStorageAvailable()
  .then(() => {
    //@ts-expect-error This works fine
    groundedUsers = new GroundedCollection(localStorageDBNames.users);
    //@ts-expect-error We may need this somewhere
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    Accounts.liveUsers = Accounts.users;
    //@ts-expect-error Any internal-to-Meteor access of Users table should use grounded db
    Accounts.users = groundedUsers;

    waitUntilReactive(() => liveUsers.find().count() > 0)
      .then(() => {
        groundedUsers.observeSource(liveUsers.find());
        groundedUsers.keep(liveUsers.find());
      })
      .catch((err: unknown) => clog.error(err));

    // This will work even if offline
    waitUntilReactive(() => groundedUsers.find().count() > 0)
      .then(() => {
        _offlineReady.set(true);
      })
      .catch((err: unknown) => clog.error(err));
  })
  .catch((err: unknown) => clog.error(err));

export function groundedUser(
  fieldsToInclude?: string | string[] | { fields?: Mongo.FieldSpecifier }
): Meteor.User | undefined {
  _offlineReady.get(); // in case offline status changes, refresh query
  const userId = Meteor.userId();
  if (!userId) return undefined;
  if (isString(fieldsToInclude)) fieldsToInclude = [fieldsToInclude];
  const fields = isArray(fieldsToInclude)
    ? fieldsToInclude.reduce<Record<string, 1>>((obj, cur) => {
        obj[cur] = 1;
        return obj;
      }, {})
    : fieldsToInclude?.fields;
  return (groundedUsers ?? liveUsers).findOne(userId, { fields });
}
