import Cookies from 'js-cookie';
import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { currentTimeZone } from '@/browser/currentTimeZone';
import { rpcCreatePasswordlessUserAccount } from '@/server/methods/accounts/rpcCreatePasswordlessUserAccount';
import { rpcStartTrial } from '@/server/methods/accounts/rpcStartTrial';

Template.appEntryNameForm.onCreated(() => {
  const instance = Template.instance();
  instance.submitting = new ReactiveVar();
  if (!Session.get('loginCode.email') || !Session.get('loginCode.codeForNewUser'))
    FlowRouter.go('login');
});

Template.appEntryNameForm.onRendered(() => {
  const instance = Template.instance();
  setTimeout(() => {
    instance.$('input[name="firstName"]').focus();
  }, 50);
});

Template.appEntryNameForm.helpers({
  submitting() {
    return Template.instance().submitting.get();
  },
});

const transferFocusOnEnter = (target) => {
  return (event, instance) => {
    if (event.key == 'Enter' && event.target.validity?.valid) {
      instance.$(target).focus();
      return false;
    }
  };
};

Template.appEntryNameForm.events({
  'keydown input[name="firstName"]': transferFocusOnEnter('input[name="lastName"]'),
  'submit form': async function (event, instance) {
    event.preventDefault();
    event.stopPropagation();
    instance.submitting.set(true);

    const userDetails = {
      firstName: instance.$('[name="firstName"]').val(),
      lastName: instance.$('[name="lastName"]').val(),
      email: Session.get('loginCode.email'),
      offer: Cookies.get('offer'),
      utcOffset: currentTimeZone.utcOffset,
      timeZone: currentTimeZone.name,
      utm: _.object(
        _.map(['campaign', 'medium', 'source', 'term', 'content'], (key) => [
          key,
          Cookies.get(`utm_${key}`),
        ])
      ),
    };

    try {
      await rpcCreatePasswordlessUserAccount(userDetails);
    } catch (err) {
      instance.submitting.set(false);
      if (err.error == 'userSignup.emailExists') {
        clientModules.analytics.track('NewAccount.Error', { label: 'account-exists' });
      } else if (err.error == 'userSignup.fakeEmail') {
        clientModules.analytics.track('NewAccount.Error', { label: 'fake-email' });
      } else {
        clientModules.analytics.track('NewAccount.Error', { label: err.error });
      }
      bootbox.alert({
        message: err.reason || err.message,
      });
      return;
    }

    Accounts.callLoginMethod({
      methodArguments: [
        {
          email: Session.get('loginCode.email'),
          code: Session.get('loginCode.codeForNewUser'),
        },
      ],
      userCallback: () => {
        instance.submitting.set(false);
        if (Meteor.isCordova && !/Android/i.test(navigator.userAgent)) {
          FlowRouter.go('mobile-subscribe');
        } else {
          rpcStartTrial().then(() => {
            clientModules.analytics.track('NewAccount.TrialStarted');
            FlowRouter.withReplaceState(() => {
              FlowRouter.go('signup-finish', {}, {});
            });
          });
        }
      },
    });
  },
});
