import type { FeetCore } from '@/band/instruments/feet/FeetCore';
import { tap } from '@/band/instruments/feet/FootActions';

export const DownbeatFeetCore = {
  id: 'dt',
  title: 'Downbeat foot taps',

  timeSignatures: ['4/4'],

  linkedSettingsKeys: [],
  otherSettingsKeys: ['timing'],

  presets: [
    {
      name: 'Tap on the downbeats',
      settings: {},
    },
  ],

  processMeasure(measure, _settings) {
    for (const beatIndex of [0, 2]) {
      const beat = measure.beats[beatIndex];
      if (beat) beat.playerInstructions.feet = [tap('stomp', 9)];
    }
    return []; // Not using Intents for now
  },

  generateViz(settings) {
    settings;
    return [];
  },
} as const satisfies FeetCore;
