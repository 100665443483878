<script lang="ts">
  import type { Song } from '@/chart/Song';
  import { Crnt } from '@/Crnt';
  import { hideClipboardToast } from '@/editor/ui/clipboard-toasts';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import Icon from '@/ui/icons/Icon.svelte';
  import { tooltip } from '@/ui/tooltip';
  import { capitalize } from '@/utilities/capitalize';

  interface Props {
    command: 'undo' | 'redo';
  }

  let { command }: Props = $props();

  let song: Song | undefined = $state();
  trackerEffect(() => (song = Crnt.song()));

  let disabled: boolean = $state(true);
  trackerEffect(() => (disabled = !song?.history[command == 'undo' ? 'canUndo' : 'canRedo']()));

  const appleDevice =
    /iP(hone|ad|od)/i.test(navigator.userAgent) || navigator.platform === 'MacIntel';
  const cmdCtrlKeyPrefix = appleDevice ? '⌘' : 'Ctrl+';
  const shiftKeyPrefix = appleDevice ? '⇧' : 'Shift+';

  let shortcutKey = $derived(cmdCtrlKeyPrefix + (command == 'redo' ? shiftKeyPrefix : '') + 'Z');
</script>

<button
  class="colors-button flex items-center justify-center p-2.5"
  style="border-radius: var(--editor-icon-button-radius, 0.375rem);"
  class:colors-disabled-button={disabled}
  aria-label={capitalize(command)}
  onclick={() => {
    hideClipboardToast();
    song?.history[command]();
  }}
  use:tooltip={{
    placement: 'top',
    delay: [400, 50],
    content: `${capitalize(command)} (${shortcutKey})`,
  }}
>
  <Icon id={`${command}-icon`} size={15} />
</button>
