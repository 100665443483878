import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { AudioSystem } from '@/audio/AudioSystem';
import { eventTracker } from '@/browser/analytics/eventTracker';
import { DemoMode } from '@/client/DemoMode';
import { indexPageKeys } from '@/keyboard/indexPageKeys';
import { MusicLibrary } from '@/library/MusicLibrary';
import { MusicLibrarySearcher } from '@/library/MusicLibrarySearcher';
import CollapsableSongList from '@/ui/lists/CollapsableSongList.svelte';
import CollapsableSongListPlaceholder from '@/ui/lists/CollapsableSongListPlaceholder.svelte';
import { ListBeingReordered } from '@/ui/lists/ListBeingReordered.svelte';
import { UserProfile } from '@/user/UserProfile';
import wait from '@/utilities/wait';
import waitUntilReactive from '@/utilities/waitUntilReactive';

var indexPageNoticesUpdateDep = new Tracker.Dependency();

Template.indexPage.onCreated(() => {
  const instance = Template.instance();
  MusicLibrary.load();
  instance.searcher = new MusicLibrarySearcher();
  instance.searcher.setQuery(
    FlowRouter.getQueryParam('search') || FlowRouter.getQueryParam('q') || null
  );

  const setURLParams = _.debounce((searchParam) => {
    if (
      FlowRouter.getRouteName() == 'song-index' &&
      FlowRouter.getQueryParam('search') !== searchParam
    ) {
      FlowRouter.withReplaceState(() => FlowRouter.go('song-index', {}, { search: searchParam }));
    }
  }, 1000);

  instance.autorun(() => {
    const searchParam = instance.searcher.getQueryParams()?.search;
    if (FlowRouter.getRouteName() == 'song-index') setURLParams(searchParam);
  });

  // preload audio before song is loaded... this might have to go bye-bye when new samples are introduced
  waitUntilReactive(() => Meteor.user('createdAt') && MusicLibrary.ready()).then(() => {
    wait(3000).then(() => AudioSystem.initialize());
  });
});

Template.indexPage.onRendered(() => {
  const instance = Template.instance();

  // attach keyboard shortcut handler only when page is visible
  instance.autorun(() => {
    if (Template.appContainer.fullyVisibleScreen() == 'index') {
      indexPageKeys.attach();
    } else {
      indexPageKeys.detach();
    }
  });

  // update index page notices only when page is not visible
  instance.autorun(() => {
    if (
      !Template.appContainer.activeScreen() == 'index' &&
      !Template.appContainer.fullyVisibleScreen() == 'index'
    ) {
      indexPageNoticesUpdateDep.changed();
    }
  });

  // when song search results change, scroll to top of page
  instance.autorun(() => {
    instance.searcher.resultsCount();
    if (instance.searcher.queryPresent() && !DemoMode.active()) {
      if (instance.$('.indexPage-main').scrollTop() > 0) {
        instance.$('.indexPage-main').stop().animate({ scrollTop: 0 }, 'fast');
      }
    } else {
      instance.$('.indexPage-main').scrollTop(0);
    }
  });

  instance.find('.js-searchBarWrapper')._uihooks = {
    insertElement(node, next) {
      $(node).insertBefore(next).hide().slideDown({ duration: 300 });
    },
    removeElement(node) {
      $(node)
        .slideUp({ duration: 300 })
        .promise()
        .done(() => $(node).remove());
    },
  };

  // listen for scroll/resize events and search results changes
  const indexPageMainEl = instance.find('.indexPage-main');
  const scrollHandler = _.throttle((event) => {
    const listsScrollPoint = instance.$('.js-indexPageListsScrollPoint');
    if (listsScrollPoint.length === 0) return;
    const listsScrollPointTop = listsScrollPoint.position().top;
    const pageHeight = $(indexPageMainEl).height();
    const listsBelowBottom = listsScrollPointTop > pageHeight;
    let hideSearchStatus = listsScrollPointTop < 180 && indexPageMainEl.scrollTop > pageHeight / 2;

    // special case: no status for empty Mine
    if (instance.searcher.resultsCount() === 0 && instance.searcher.query()?.own) {
      hideSearchStatus = true;
    }

    instance.$('.js-scrollToListsButton').toggleClass('shown', listsBelowBottom);
    instance
      .$('.js-global-searchStatusLine')
      .toggleClass(
        'shown',
        MusicLibrary.index.readyToSearch() && instance.searcher.queryPresent() && !hideSearchStatus
      );
  }, 100);

  indexPageMainEl.addEventListener('scroll', scrollHandler, { passive: true });
  window.addEventListener('resize', scrollHandler);
  instance.autorun(() => {
    MusicLibrary.ready();
    MusicLibrary.lists.count();
    instance.searcher.queryPresent();
    instance.searcher.resultsCount();
    Meteor.setTimeout(scrollHandler, 100);
    Meteor.setTimeout(scrollHandler, 500);
  });
});

Template.indexPage.onDestroyed(() => {
  indexPageKeys.detach();
});

Template.indexPage.events({
  'click .songIndexItem-listsButton': function (event, instance) {
    if (!Meteor.status().connected) {
      bootbox.alert({
        title: 'Lists are read-only when offline',
        message: 'Sorry, you need to be connected to the internet to make changes to song lists.',
      });
      return;
    }
    const $item = $(event.target).closest('.songIndexItem');
    const indexRecord = MusicLibrary.index.getIndexRecord($item.data('id'));
    Modal.show('listsMenuModal', {
      [indexRecord.medley ? 'medleyId' : 'songId']: indexRecord._id,
      songName: indexRecord.name,
      // if you add any parameters to this in the future, change listsMenuModal.html!
    });
    eventTracker.indexListsMenu({ songName: indexRecord.name });
  },

  'click .js-scrollToListsButton': function (event, instance) {
    $('.indexPage-main').animate(
      {
        scrollTop:
          instance.$('.js-indexPageListsScrollPoint').position().top +
          instance.$('.indexPage-main').scrollTop() -
          80,
      },
      'fast'
    );
    $(event.target).removeClass('shown').blur();
    return false;
  },
});

Template.indexPage.helpers({
  indexPageNoticesUpdateDep() {
    return indexPageNoticesUpdateDep;
  },

  showOpenSongHint() {
    return Meteor.userId() && UserProfile.notMilestone('PLAY');
  },

  showNewSongSuggestion() {
    const instance = Template.instance();
    return (
      UserProfile.hasMilestone('PLAY') &&
      !instance.searcher.query()?.own &&
      instance.searcher.resultsCount() === 0
    );
  },

  showEmptyMine() {
    const instance = Template.instance();
    return instance.searcher.query()?.own && instance.searcher.resultsCount() === 0;
  },

  showListHint() {
    return UserProfile.hasMilestone('PLAY') && UserProfile.notMilestone('NEW_LIST');
  },

  showNoListsMessage() {
    return UserProfile.hasMilestone('PLAY') && UserProfile.hasMilestone('NEW_LIST');
  },

  ready() {
    return (Meteor.userId() || DemoMode.active()) && MusicLibrary.ready();
  },

  listsReady() {
    return Meteor.userId() && MusicLibrary.lists.ready();
  },

  mySongLists() {
    return MusicLibrary.lists.findAndFetch({ sort: true, own: true });
  },

  otherSongLists() {
    return MusicLibrary.lists.findAndFetch({ sort: true, own: false });
  },

  songListsExist() {
    return MusicLibrary.lists.count() > 0;
  },

  mySongListsExist() {
    return MusicLibrary.lists.count({ own: true }) > 0;
  },

  otherSongListsExist() {
    return MusicLibrary.lists.count({ own: false }) > 0;
  },

  searching() {
    const instance = Template.instance();
    return instance.searcher.queryPresent() && !DemoMode.active();
  },

  searchResults() {
    const instance = Template.instance();
    return instance.searcher.results();
  },

  showListPreviews() {
    const lp = Meteor.user('listPreviews')?.listPreviews || {};
    return !MusicLibrary.lists.ready() && Object.keys(lp).find((k) => lp[k].length > 0);
  },

  listPreviews() {
    return Meteor.user('listPreviews')?.listPreviews;
  },

  showListsDivider() {
    const lp = Meteor.user('listPreviews')?.listPreviews || {};
    return (
      Meteor.userId() &&
      (UserProfile.hasMilestone('PLAY') ||
        MusicLibrary.lists.count() > 0 ||
        Object.keys(lp).find((k) => lp[k].length > 0))
    );
  },

  hideSearchBar() {
    return DemoMode.active();
  },

  disableIndex() {
    return ListBeingReordered.reactive();
  },

  inertIfIndexDisabled() {
    return ListBeingReordered.reactive() ? { inert: true } : {};
  },

  searcher() {
    const instance = Template.instance();
    return instance.searcher;
  },

  CollapsableSongList() {
    return CollapsableSongList;
  },

  CollapsableSongListPlaceholder() {
    return CollapsableSongListPlaceholder;
  },
});
