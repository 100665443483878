import * as Sentry from '@sentry/browser';
import { strict as assert } from 'assert';
import { Meteor } from 'meteor/meteor';
import { Mongo } from 'meteor/mongo';
import { ReactiveVar } from 'meteor/reactive-var';
import { Tracker } from 'meteor/tracker';
import { checkOfflineStorageAvailable } from '@/local-db/checkOfflineStorageAvailable';
import type { GroundedDocumentCompressor } from '@/local-db/GroundedCollection';
import { GroundedCollection } from '@/local-db/GroundedCollection';
import { removeFromCollectionWhenSafe } from '@/local-db/removeFromCollectionWhenSafe';
import wait from '@/utilities/wait';
import waitUntilReactive from '@/utilities/waitUntilReactive';

interface AttributeBaseRecord {
  _id: string;
  songId?: string;
  medleyId?: string;
  updatedAt?: Date;
}

export abstract class UserAttributesBackend<RecordType extends AttributeBaseRecord> {
  private subscription?: Meteor.SubscriptionHandle;
  private groundedCollection?: GroundedCollection<RecordType>;
  private liveCollection: Mongo.Collection<RecordType>;

  private pendingUpdates: GroundedCollection<RecordType> | Mongo.Collection<RecordType>;

  private idField: 'songId' | 'medleyId';

  constructor(options: {
    liveCollection: Mongo.Collection<RecordType>;
    idField: 'songId' | 'medleyId';
    publicationName: string;
    compressor: GroundedDocumentCompressor<RecordType>;
    storageDBName: string;
    updatesDBName: string;
  }) {
    this.pendingUpdates = new Mongo.Collection<RecordType>(null);
    this.liveCollection = options.liveCollection;
    this.idField = options.idField;

    checkOfflineStorageAvailable().then(async (offlineAvailable) => {
      if (!offlineAvailable) return;
      this.subscription = Meteor.subscribe(options.publicationName);
      this.groundedCollection = new GroundedCollection(options.storageDBName, {
        idField: options.idField,
        collection: this.pendingUpdates,
        compressor: options.compressor,
      });
      this.pendingUpdates = new GroundedCollection<RecordType>(options.updatesDBName, {
        idField: options.idField,
        compressor: options.compressor,
      });
      this.groundData();
    });
  }

  private async groundData(): Promise<void> {
    assert(this.groundedCollection);
    await this.groundedCollection.waitUntilLoaded();
    await waitUntilReactive(() => this.subscription?.ready());
    this.groundedCollection.observeSource(this.liveCollection.find());
    this.groundedCollection.keep(this.liveCollection.find());
    await (this.pendingUpdates as GroundedCollection<RecordType>).waitUntilLoaded();
    this._offlineReady.set(true);
  }

  private _ready = new ReactiveVar(false);
  ready(): boolean {
    return this._ready.get();
  }

  private _offlineReady = new ReactiveVar(false);
  offlineReady(): boolean {
    return this._offlineReady.get();
  }

  fetchAttributes(subjectId: string): Partial<RecordType> {
    return {
      ...this.groundedCollection?.findOne({ [this.idField]: subjectId } as Mongo.Query<RecordType>),
      ...this.pendingUpdates.findOne({ [this.idField]: subjectId } as Mongo.Query<RecordType>),
    } as Partial<RecordType>;
  }

  saveAttributes(
    subjectId: string,
    attributes: Partial<{ [Property in keyof RecordType]: RecordType[Property] | null | undefined }>
  ): void {
    if (!subjectId) throw new Error('Tried to save user attributes without subject ID');
    if (!Meteor.userId()) return;
    const record = { [this.idField]: subjectId, ...attributes };
    this.pendingUpdates.upsert(
      // { [this.idField]: subjectId } as Mongo.Selector<RecordType>,
      subjectId,
      {
        $set: record,
        $currentDate: { updatedAt: true },
      } as Mongo.Modifier<RecordType>
    );
  }

  reviseSubjectId(oldId: string, newId: string): void {
    if (this.groundedCollection) {
      const storedRecord = this.groundedCollection.findOne({
        [this.idField]: oldId,
      } as Mongo.Query<RecordType>);
      if (storedRecord) {
        this.groundedCollection.remove(storedRecord._id);
        storedRecord[this.idField] = newId;
        this.groundedCollection.insert(storedRecord as any);
      }
    }
    const pendingUpdate = this.pendingUpdates.findOne({
      [this.idField]: oldId,
    } as any);
    if (pendingUpdate) {
      this.pendingUpdates.remove(pendingUpdate._id);
      pendingUpdate[this.idField] = newId;
      this.pendingUpdates.insert(pendingUpdate as any);
    }
  }

  protected abstract doServerUpdate(record: RecordType): Promise<void>;

  preventSync = new ReactiveVar(false);

  async startAttributeUpdateAutorun(): Promise<void> {
    if (this.groundedCollection) await waitUntilReactive(() => this.offlineReady());
    const syncInProgress = new ReactiveVar(false);
    Tracker.autorun(() => {
      if (syncInProgress.get()) return;
      if (this.preventSync.get()) return;
      if (!Meteor.status().connected) return; // can't do this offline!
      if (!Meteor.userId()) return; // can't do this if not logged in!
      const attributesToUpload = this.pendingUpdates.find().fetch();
      if (attributesToUpload.length === 0) return;
      syncInProgress.set(true);
      Promise.all(
        attributesToUpload.map(async (record: RecordType) => {
          await this.doServerUpdate(record);
          await removeFromCollectionWhenSafe(this.pendingUpdates, record[this.idField]);
        })
      )
        .then(() => wait(1000))
        .then(() => syncInProgress.set(false))
        .catch((error) => {
          console.error(error);
          Sentry.captureException(error);
          Meteor.setTimeout(() => syncInProgress.set(false), 5000);
        });
    });
    this._ready.set(true);
  }
}
