import { eventTracker } from '@/browser/analytics/eventTracker';
import { Chord } from '@/music/Chord';
import closeNearestTippy from '@/ui/dropdowns/closeNearestTippy';
import { UserProfile } from '@/user/UserProfile';

Template.keyChooser.helpers({
  currentKey() {
    const song = Template.currentData().song;
    if (!song) return;
    return song.keyHeard();
  },

  setKeyFunc() {
    const instance = Template.instance();
    return (newKey) => {
      eventTracker.keySet({ key: newKey });
      UserProfile.recordMilestone('KEY_CHANGE');
      instance.data.song.setKey(newKey);
      closeNearestTippy(instance.find('.keyChooser'));
    };
  },

  showOriginalKey() {
    const song = Template.currentData().song;
    if (!song) return;
    const customKey = song.medleyPrefs?.key() || song.prefs.key();
    return customKey && customKey !== song.key();
  },

  originalKey() {
    const song = Template.currentData().song;
    if (!song) return;
    return song && new Chord(song.key()).toHtml();
  },

  minor() {
    const song = Template.currentData().song;
    if (!song) return;
    return song && song.key()?.includes('m');
  },
});
