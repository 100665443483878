<script lang="ts">
  import { Conductor } from '@/Conductor';
  import { Crnt } from '@/Crnt';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import { Roles } from '@/Roles';

  let readyToPlay: boolean = $state(false);
  let playing: boolean = $state(false);
  let paused: boolean = $state(false);
  let stopped: boolean = $state(true);
  let countLabel: string | undefined = $state();

  trackerEffect(() => {
    readyToPlay = Conductor.ready();
    playing = Conductor.playing();
    paused = Conductor.paused();
    stopped = Conductor.stopped();
    countLabel = Conductor.displayState.countIn()?.label;
  });

  let actionLabel: string = $derived(readyToPlay ? (playing ? 'Stop' : 'Play') : '');

  function buttonClick(event: MouseEvent) {
    event.preventDefault();
    if (Conductor.playing()) {
      Conductor.stop();
      return;
    }
    if (!Conductor.ready()) return;
    const song = Crnt.song();
    if (!song || !Roles.currentUserCanPlaySong(song)) return;
    let playPoint = song.focus.firstCell();
    const lastSection = song.sections[song.sections.length - 1];
    const lastCell = lastSection?.cells[lastSection?.cells.length - 1];
    if (!playPoint || playPoint == lastCell) {
      playPoint = song.sections[0]?.cells[0];
    }
    void Conductor.playFrom({
      section: playPoint?.layout.sectionIndex ?? 0,
      cell: playPoint?.layout.cellIndex ?? 0,
      rep: 0,
      beat: 0,
    });
  }
</script>

<button
  class="colors-button _button js-global-editPreviewPlayStop z-10 flex min-w-9 flex-1 items-center justify-center rounded-md"
  class:playing
  class:paused
  class:stopped
  class:counting={!!countLabel}
  disabled={!readyToPlay}
  title={actionLabel}
  aria-label={actionLabel}
  onclick={buttonClick}
>
  {#if readyToPlay}
    {#if playing && countLabel}
      <div class="editPreviewPlayButtonCount count-{countLabel}">{countLabel}</div>
    {:else if playing}
      <span class="stop-icon play"><i class="smi smi-stop" aria-hidden="true"></i></span>
    {:else}
      <span class="play-icon"><i class="smi smi-play" aria-hidden="true"></i></span>
    {/if}
  {:else}
    <div class="loading" aria-label="Loading">
      <i class="smi smi-autorenew" aria-hidden="true"></i>
    </div>
  {/if}
</button>

<style>
  ._button {
    line-height: 1;
    background: hsl(206, 100%, 97%);
    border: solid 1px hsl(209, 50%, 80%);

    &[disabled] {
      background: hsl(206, 20%, 96%);
      border: solid 1px hsl(209, 20%, 80%);
    }

    @media not all and (hover: none), (min--moz-device-pixel-ratio: 1) {
      &:not([disabled]):hover {
        background: hsl(206, 100%, 90%);
        border: solid 1px hsl(209, 60%, 70%);
      }
    }

    .smi::before {
      transform: scale(1.35);
    }
  }

  .loading {
    flex: 1 1 auto;
    color: #aaa;
    font-size: 20px;

    > .smi:before {
      animation: songLoadingButtonSpinner 2s infinite linear;
    }
  }

  .editPreviewPlayButtonCount {
    font-size: 20px;
    font-weight: normal;
  }
</style>
