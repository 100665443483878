export function shuffleArray<T>(arr: readonly T[]): T[] {
  const result = [...arr];
  // Iterate through the array in reverse order
  for (let i = result.length - 1; i > 0; i--) {
    // Generate a random index 'j' between 0 and i (inclusive)
    const j = Math.floor(Math.random() * (i + 1));
    // Swap the elements at indices 'i' and 'j'
    [result[i], result[j]] = [result[j] as T, result[i] as T];
  }
  return result;
}
