import type { CircularStrumLandscape } from '@/band/instruments/CircularStrumLandscape';

export const bluegrassWaltzPatternLandscape: CircularStrumLandscape<SixNumbers> = {
  centerPowers: [0.9, 0.1, 0.8, 0.5, 0.9, 0.5],

  poles: [
    {
      theta: 9 / 12, // NORTH - dense
      fullPowers: [1.0, 0.7, 1.0, 1.0, 1.0, 1.0],
    },
    {
      theta: 11 / 12, // EAST/NORTH
      fullPowers: [0.6, 0.2, 1.0, 0.6, 1.0, 0.6],
    },
    {
      theta: 1 / 12, // EAST/SOUTH
      fullPowers: [0.6, -0.1, 1.0, 0.1, 1.0, 0.5],
    },
    {
      theta: 3 / 12, // SOUTH - spacious; start & end
      halfPowers: [1.0, -0.1, 0.6, 0.0, 0.9, 0.4],
      fullPowers: [1.0, -0.1, -0.05, 0.4, 0.9, 1.0],
    },
    {
      theta: 5 / 12, // WEST/SOUTH
      fullPowers: [1.0, 1.0, 0.8, 0.0, 0.8, 0.3],
    },
    {
      theta: 7 / 12, // WEST/NORTH
      fullPowers: [1.0, 1.0, 0.9, 0.4, 0.9, 0.7],
    },
  ],

  // poles: [
  //   {
  //     theta: 0/6, // EAST - chuck emphasis
  //     fullPowers: [0.6, -0.1, 1.0, 0.1, 1.0, 0.5],
  //   },
  //   {
  //     theta: 0.125,
  //     fullPowers: [0.9, 0.0, 1.0, 0.2, 1.0, 0.9],
  //   },
  //   {
  //     theta: 0.25, // SOUTH - spacious; start & end
  //     fullPowers: [1.0, 0.0, 0.0, 0.4, 0.9, 1.0],
  //   },
  //   {
  //     theta: 0.375,
  //     fullPowers: [1.0, 0.0, 0.0, 0.3, 0.7, 0.9],
  //   },
  //   {
  //     theta: 0.5, // WEST - 1 & 2, 3
  //     fullPowers: [1.0, 1.0, 0.8, 0.0, 0.8, 0.3],
  //   },
  //   {
  //     theta: 0.625,
  //     fullPowers: [1.0, 0.8, 0.9, 0.4, 0.9, 0.7],
  //   },
  //   {
  //     theta: 0.75, // NORTH - dense
  //     fullPowers: [1.0, 0.7, 1.0, 1.0, 1.0, 1.0],
  //   },
  //   {
  //     theta: 0.875,
  //     fullPowers: [0.7, 0.1, 1.0, 0.6, 1.0, 0.6],
  //   },
  // ],

  presets: [
    {
      name: 'Balanced',
      position: [0.0, 0.0],
    },
    {
      name: 'Front-heavy',
      position: [0.75, 0.5],
    },
    {
      name: 'Busy & front-heavy',
      position: [0.75, 0.625],
    },
    {
      name: 'Busy',
      position: [0.75, 0.75],
    },
    {
      name: 'Busy & chuck-heavy',
      position: [0.75, 0.875],
    },
    {
      name: 'Chuck-heavy',
      position: [0.75, 0.0],
    },
    {
      name: 'Spacious & chuck-heavy',
      position: [0.75, 0.125],
    },
    {
      name: 'Spacious',
      position: [0.75, 0.25],
    },
    {
      name: 'Spacious & front-heavy',
      position: [0.75, 0.375],
    },
  ],
};
