import { Meteor } from 'meteor/meteor';
import { ReactiveVar } from 'meteor/reactive-var';
import { Tracker } from 'meteor/tracker';
import { setMediaSession } from '@/browser/setMediaSession';
import { Song } from '@/chart/Song';
import type { MedleyRecord } from '@/collections/MedleysCollection';
import type { SongRecord } from '@/collections/SongsCollection';
import type { SUARecord } from '@/collections/SUACollection';
import { Conductor } from '@/Conductor';
import { MusicLibrary } from '@/library/MusicLibrary';
import { CurrentList } from '@/lists/CurrentList';
import { Medley } from '@/medleys/Medley';
import { Notepad } from '@/notes/Notepad';
import { ReferenceManager } from '@/references/ReferenceManager';
import { Roles } from '@/Roles';
import { rpcFetchNominationForSong } from '@/server/methods/nominations/rpcFetchNominationForSong';

class CrntSingleton {
  constructor() {
    Tracker.autorun(() => this.loadListFromListId());
  }

  private _song = new ReactiveVar<Song | undefined>(undefined);
  song(): Song | undefined {
    return this._song.get();
  }

  get songs(): readonly Song[] {
    return Tracker.nonreactive(() => this.reactiveSongs());
  }

  reactiveSongs(): readonly Song[] {
    const song = this.song();
    const medleySongs = this._medley.get()?.songs.reactive();
    return song ? [song] : medleySongs ? [...medleySongs] : [];
  }

  private _medley = new ReactiveVar<Medley | undefined>(undefined);
  medley(): Medley | undefined {
    return this._medley.get();
  }

  loadNewSong(): void {
    this.unloadMusic();
    const song = new Song({ editMode: true });
    this._song.set(song);
    Conductor.loadSong(song);
    this.loadNotepad();
    void this.loadNomination(song.id());
    this.loadReferences(song.id());
    setMediaSession({ songName: song.name() || 'New Song' });
  }

  loadSong(args: {
    song: SongRecord;
    songUserAttributes?: Partial<SUARecord>;
    editMode?: boolean;
    duplicateAndEdit?: boolean;
  }) {
    this.unloadMusic();
    const song = new Song({
      songData: args.song,
      songPrefs: args.songUserAttributes,
      editMode: args.editMode || args.duplicateAndEdit,
      useAsTemplate: args.duplicateAndEdit,
    });
    this._song.set(song);
    Conductor.loadSong(song);
    setMediaSession({ songName: song.name() || 'Song' });
    if (Meteor.userId()) {
      this.loadNotepad(args.songUserAttributes);
      void this.loadNomination(song.id());
      this.loadReferences(song.id());
    }
  }

  loadMedley(args: { medley: MedleyRecord; songs: SongRecord[] }) {
    this.unloadMusic();
    const medley = new Medley({
      medleyRecord: args.medley,
      songRecords: args.songs,
    });
    this._medley.set(medley);
    Conductor.loadMedley();
    setMediaSession({ songName: medley.name() || 'Medley' });
  }

  preload(songOrMedleyId: string): void {
    const indexDoc = Tracker.nonreactive(() => MusicLibrary.index.getIndexRecord(songOrMedleyId));
    const currentDoc = Tracker.nonreactive(() => this.song() || this.medley());
    if (indexDoc && (!currentDoc || indexDoc._id != currentDoc.id())) {
      this.unloadMusic();
      this.setPrefetchedTitle(indexDoc.name);
    }
  }

  unloadMusic(): void {
    this._song.set(undefined);
    this._medley.set(undefined);
    this._notepad.set(undefined);
    this._referenceManager.set(undefined);
    this._nomination.set(false);
    this._titleFromIndex.set(undefined);
  }

  automaticallyGenerateBandPlans() {
    return Tracker.autorun(() => {
      for (const song of this.reactiveSongs()) {
        song.band.regeneratePlans();
      }
    });
  }

  //#region === Title while loading ===

  private _titleFromIndex = new ReactiveVar<string | undefined>(undefined);

  private setPrefetchedTitle(title: string): void {
    this._titleFromIndex.set(title);
  }

  musicTitle(): string | undefined {
    return this.song()
      ? this.song()?.name()
      : this.medley()
        ? this.medley()?.name()
        : this._titleFromIndex.get();
  }

  //#endregion ^ Title

  //#region === List ===

  private _listId = new ReactiveVar<string | undefined>(undefined);
  private _list = new ReactiveVar<CurrentList | undefined>(undefined);

  setListId(listId: string) {
    this._listId.set(listId);
  }

  list(): CurrentList | undefined {
    return this._list.get();
  }

  // This is run in a Tracker.autorun in the constructor
  private loadListFromListId() {
    const list =
      this._listId.get() && MusicLibrary.lists.ready()
        ? MusicLibrary.lists.findOne(this._listId.get())
        : undefined;
    this._list.set(list ? new CurrentList(list) : undefined);
  }

  //#endregion ^ List

  //#region === Notepad ===

  private _notepad = new ReactiveVar<Notepad | undefined>(undefined);
  notepad(): Notepad | undefined {
    return this._notepad.get();
  }

  private loadNotepad(args?: { notes?: string; notesVisible?: boolean }): void {
    this._notepad.set(
      new Notepad({
        notes: args?.notes,
        visible: args?.notesVisible,
      })
    );
  }

  //#endregion ^ Notepad

  //#region === References ===

  private _referenceManager = new ReactiveVar<ReferenceManager | undefined>(undefined);
  references(): ReferenceManager | undefined {
    return this._referenceManager.get();
  }

  private loadReferences(songId?: string) {
    this._referenceManager.set(new ReferenceManager(songId));
  }

  //#endregion ^ References

  //#region === Nomination ===

  private _nomination = new ReactiveVar<Record<string, any> | false | undefined>(false);
  nomination(): Record<string, any> | undefined {
    return this._nomination.get() || undefined;
  }
  nominationLoaded(): boolean {
    return this._nomination.get() !== false;
  }

  private async loadNomination(songId?: string): Promise<void> {
    this._nomination.set(false);
    if (!songId) return;
    if (
      Meteor.status().connected &&
      Roles.userHasPermission(Meteor.userId(), 'nominations.insert')
    ) {
      const result = await rpcFetchNominationForSong({ songId });
      this._nomination.set(result);
    }
  }

  //#endregion ^ Nomination
}

export const Crnt = new CrntSingleton();

if (Meteor.isDevelopment) {
  (((window as Record<string, any>).dev ||= {}) as Record<string, any>).Crnt = Crnt;
}
