import { strict as assert } from 'assert';
import { EventEmitter } from 'eventemitter3';
import { Tracker } from 'meteor/tracker';
import type { Cell } from '@/chart/Cell.svelte';
import type { Section } from '@/chart/Section.svelte';
import type { Song } from '@/chart/Song';
import { Chord } from '@/music/Chord';
import { bounded } from '@/utilities/bounded';

export class SongSelection extends EventEmitter<'set'> {
  private trackerDep = new Tracker.Dependency();

  readonly song: Song;
  readonly constrainToOneSectionInEditMode: boolean;

  constructor(song: Song, options?: { constrainToOneSectionInEditMode?: boolean }) {
    super();
    this.song = song;
    this.constrainToOneSectionInEditMode = !!options?.constrainToOneSectionInEditMode;
  }

  private _startCell?: Cell;
  private _endCell?: Cell;
  private _cells: Cell[] = [];
  private _cellsSet: Set<Cell> = new Set();
  private _endIsBeforeStart?: boolean;

  depend(): void {
    this.trackerDep.depend();
  }

  exists(): boolean {
    this.trackerDep.depend();
    return !!this._startCell;
  }

  hasCell(cell: Cell): boolean {
    this.trackerDep.depend();
    return this._cellsSet.has(cell);
  }

  bounds(): SelectionRange | undefined {
    this.trackerDep.depend();
    if (!this._startCell || !this._endCell) return;
    const beat = this._startCell === this._endCell ? this._focusedBeat : undefined;
    return {
      start: [this._startCell.layout.sectionIndex, this._startCell.layout.cellIndex, beat],
      end: [this._endCell.layout.sectionIndex, this._endCell.layout.cellIndex, beat],
    };
  }

  normalizedBounds(): SelectionRange | undefined {
    const bounds = this.bounds();
    if (!bounds) return;
    if (
      bounds.start[0] > bounds.end[0] ||
      (bounds.start[0] === bounds.end[0] && bounds.start[1] > bounds.end[1])
    ) {
      return {
        start: bounds.end,
        end: bounds.start,
      };
    }
    return bounds;
  }

  setToBounds(serialized: SelectionRange | null | undefined): void {
    const startCell = findCellAtPosition(serialized?.start, this.song);
    const endCell = findCellAtPosition(serialized?.end, this.song);
    this.setCells({ start: startCell, end: endCell }, { noCallback: true });
  }

  spansMultipleCells(): boolean {
    this.trackerDep.depend();
    return !!(this._startCell && this._endCell && this._startCell !== this._endCell);
  }

  containsEntireSection(section: Section): boolean {
    this.trackerDep.depend();
    return (
      this.hasCell(section.cells[0]!) && this.hasCell(section.cells[section.cells.length - 1]!)
    );
  }

  setToSingleCell(cell: Cell | undefined, beat?: number): void {
    this.setCells(cell ? { start: cell, end: cell } : undefined, { focusedBeat: beat });
  }

  setCells(
    range?: { start?: Cell; end?: Cell },
    {
      noCallback,
      forceRevalidate,
      focusedBeat,
    }: { noCallback?: boolean; forceRevalidate?: boolean; focusedBeat?: number } = {}
  ): void {
    if (range?.start && !range.end) range.end = range.start;
    if (range && !range.start) range.end = range.start;
    if (
      !forceRevalidate &&
      this._startCell === range?.start &&
      this._endCell === range?.end &&
      this._focusedBeat === focusedBeat
    )
      return;
    this._startCell = range?.start;
    if (
      this.constrainToOneSectionInEditMode &&
      this.song.editMode() &&
      range?.start?.section != range?.end?.section
    ) {
      const endBeforeStart =
        (range?.end?.section?.index ?? 0) < (range?.start?.section?.index ?? 0);
      this._endCell = range?.start?.section?.cells[endBeforeStart ? 0 : 999];
    } else {
      this._endCell = range?.end;
    }
    if (this._startCell == this._endCell && this._startCell?.split) {
      this._focusedBeat = bounded(focusedBeat ?? 0, 0, this._startCell.beatCount - 1);
    } else {
      this._focusedBeat = undefined;
    }
    this._endIsBeforeStart = this.isEndBeforeStart();
    this._cells = this.collectCells();
    this._cellsSet = new Set(this._cells);
    if (!noCallback) this.emit('set', this);
    this.trackerDep.changed();
  }

  extendToCell(cell: Cell, { noCallback = false } = {}): void {
    if (!cell) return;
    this.setCells({ start: this._startCell, end: cell }, { noCallback });
  }

  // This is only calculated on change, and then stored
  protected isEndBeforeStart(): boolean {
    return !!(
      this._startCell &&
      this._endCell &&
      (this._startCell.layout.sectionIndex > this._endCell.layout.sectionIndex ||
        (this._startCell.layout.sectionIndex === this._endCell.layout.sectionIndex &&
          this._startCell.layout.cellIndex > this._endCell.layout.cellIndex))
    );
  }

  protected collectCells(): Cell[] {
    const [firstCell, lastCell] = [this.firstCell(), this.lastCell()];
    if (!firstCell || !lastCell) return [];
    const results: Cell[] = [];
    for (let sIdx = firstCell.layout.sectionIndex; sIdx <= lastCell.layout.sectionIndex; sIdx++) {
      const section = this.song.sections[sIdx];
      if (!section) continue;
      results.push(
        ...section.cells.slice(
          sIdx == firstCell.layout.sectionIndex ? firstCell.layout.cellIndex : 0,
          sIdx == lastCell.layout.sectionIndex
            ? lastCell.layout.cellIndex + 1
            : section.cells.length
        )
      );
    }
    return results;
  }

  cells(): Cell[] {
    this.trackerDep.depend();
    this.song.sections.reactive();
    return this._cells;
  }

  firstCell(): Cell | undefined {
    this.trackerDep.depend();
    this.song.sections.reactive();
    return this._endIsBeforeStart ? this._endCell : this._startCell;
  }

  lastCell(): Cell | undefined {
    this.trackerDep.depend();
    this.song.sections.reactive();
    return this._endIsBeforeStart ? this._startCell : this._endCell;
  }

  startCell(): Cell | undefined {
    this.trackerDep.depend();
    this.song.sections.reactive();
    return this._startCell;
  }

  endCell(): Cell | undefined {
    this.trackerDep.depend();
    this.song.sections.reactive();
    return this._endCell;
  }

  selectedChords(): Chord[] {
    this.trackerDep.depend();
    this.song.sections.reactive();
    const splitBeatChord =
      typeof this._focusedBeat == 'number'
        ? (this._startCell?.subdividedBeats?.[this._focusedBeat]?.chord ??
          this._startCell?.subdividedBeats?.[this._focusedBeat - 1]?.chord ??
          this._startCell?.chord)
        : undefined;
    return splitBeatChord
      ? [splitBeatChord]
      : [
          ...new Set(
            this._cells
              .flatMap((cell) => cell.subdividedBeats?.map((b) => b.chord) ?? cell.chord)
              .filter((chord) => !!chord)
              .map((c) => c.toString())
          ),
        ].map((chord) => new Chord(chord));
  }

  /**
   * Returns the chord in the first cell of the selection. If a split beat is focused,
   * and that beat does not have a chord defined, `undefined` is returned.
   */
  firstSelectedChordNoSplitFallback(): Chord | undefined {
    this.trackerDep.depend();
    this.song.sections.reactive();
    return typeof this._focusedBeat == 'number'
      ? this._startCell?.subdividedBeats?.[this._focusedBeat]?.chord
      : this._cells[0]?.chord;
  }

  homogenousChordSelection(): boolean {
    return this.selectedChords().length == 1;
  }

  private _focusedBeat?: number;
  focusedBeat(): number | undefined {
    this.trackerDep.depend();
    return this._focusedBeat;
  }

  reset({ noCallback = false, forceRevalidate = false } = {}): void {
    this.setCells(undefined, { noCallback, forceRevalidate });
  }

  private translateAcross(
    cell: Cell,
    steps: number,
    opts?: { skipBeats: boolean; constrainToSection: true }
  ): { cell: Cell; beat?: number } {
    let beat = this._focusedBeat;
    const dir = Math.sign(steps);
    for (let i = 0; i < Math.abs(steps); i++) {
      if (
        cell.split &&
        !opts?.skipBeats &&
        typeof beat == 'number' &&
        beat + dir >= 0 &&
        beat + dir < cell.beatCount
      ) {
        beat += dir;
      } else {
        beat = undefined;
        let newCell = dir > 0 ? cell.next : cell.prev;
        if (!newCell) {
          if (
            opts?.constrainToSection ||
            (dir < 0 && cell.section?.index === 0) ||
            (dir > 0 && cell.section?.index === this.song.sections.length - 1)
          ) {
            newCell = cell;
          } else {
            const neighboringSection = this.song.sections[cell.layout.sectionIndex + dir]!;
            newCell = neighboringSection.cells[dir > 0 ? 0 : neighboringSection.cells.length - 1]!;
          }
        }
        if (newCell.split) {
          beat = dir > 0 ? 0 : newCell.beatCount - 1;
        }
        cell = newCell;
      }
    }
    return { cell, beat };
  }

  move(steps: number): void {
    if (!this._endCell) return;
    const { cell, beat } = this.translateAcross(this._endCell, steps);
    this.setToSingleCell(cell, beat);
  }

  moveFromFirst(steps: number): void {
    const firstCell = this.firstCell();
    if (!firstCell) return;
    const { cell, beat } = this.translateAcross(firstCell, steps);
    this.setToSingleCell(cell, beat);
  }

  moveLines(lines: number): void {
    if (!this._endCell) return;
    const cell = findCellShiftedByLines(this._endCell, lines);
    this.setToSingleCell(cell);
  }

  moveToEndOfLine(dir: -1 | 1): void {
    if (!this._endCell) return;
    const cell = findCellAtEndOfLine(this._endCell, dir);
    this.setToSingleCell(cell);
  }

  extendByLines(lines: number): void {
    if (!this._endCell) return;
    const cell = findCellShiftedByLines(this._endCell, lines, { constrainToSection: true });
    this.setCells({ start: this._startCell, end: cell });
  }

  extendToEndOfLine(dir: -1 | 1): void {
    if (!this._endCell) return;
    const cell = findCellAtEndOfLine(this._endCell, dir);
    this.setCells({ start: this._startCell, end: cell });
  }

  extendBy(steps: number): void {
    if (!this._endCell) return;
    const { cell } = this.translateAcross(this._endCell, steps, {
      skipBeats: true,
      constrainToSection: true,
    });
    this.setCells({ start: this._startCell, end: cell });
  }

  revalidate(): void {
    if (!this._startCell || !this._endCell) return;
    this.setCells({ start: this._startCell, end: this._endCell }, { forceRevalidate: true });
  }

  // Select All with progressive focus expansion
  selectAll(): void {
    this.trackerDep.depend();
    if (!this._startCell || this.containsEntireSection(this._startCell.section!)) {
      this.setCells({
        start: this.song.sections[0]?.cells[0],
        end: this.song.sections.at(-1)?.cells.at(-1),
      });
    } else {
      this.setCells({
        start: this._startCell.section?.cells[0],
        end: this._endCell?.section?.cells.at(-1),
      });
    }
  }

  // shiftRange(): void {
  //   // TODO: implement focus range shifting - bigger feature that uses
  //   // buttons/shortcuts to increment loop for practice
  // }
}

function findCellShiftedByLines(
  originCell: Cell,
  linesToShift: number,
  opts?: { constrainToSection?: boolean }
): Cell {
  return findCellAtCoordinates(
    {
      section: originCell.layout.sectionIndex,
      line: originCell.layout.lineNumber + linesToShift,
      column: originCell.layout.column,
    },
    originCell.section!.song,
    opts
  );
}

function findCellAtEndOfLine(originCell: Cell, direction: -1 | 1): Cell {
  return findCellAtCoordinates(
    {
      section: originCell.layout.sectionIndex,
      line: originCell.layout.lineNumber,
      column: direction === -1 ? 0 : 999,
    },
    originCell.section!.song
  );
}

function findCellAtCoordinates(
  targetPos: { section: number; line: number; column: number },
  song: Song,
  opts?: { constrainToSection?: boolean }
): Cell {
  const section = song.sections[bounded(targetPos.section, 0, song.sections.length - 1)]!;
  if (targetPos.line < 0) {
    // moving up from first line of section; move to previous section if it exists
    if (targetPos.section > 0 && !opts?.constrainToSection) {
      targetPos.section -= 1;
      targetPos.line = (song.sections[targetPos.section]?.lineLengths.length ?? 1) - 1;
    } else {
      targetPos.line = 0;
      targetPos.column = 0;
    }
  } else if (targetPos.line > section.lineLengths.length - 1) {
    // moving down from last line of section; move to next section if it exists
    if (targetPos.section < song.sections.length - 1 && !opts?.constrainToSection) {
      targetPos.section += 1;
      targetPos.line = 0;
    } else {
      targetPos.line = section.lineLengths.length - 1;
      targetPos.column = 999;
    }
  }
  const targetSection = song.sections[targetPos.section];
  assert(targetSection, `Section ${targetPos.section} (target) does not exist`);

  return targetSection.cells.reduce((targetCell, cell) => {
    if (
      cell &&
      cell.layout.lineNumber == targetPos.line &&
      cell.layout.column <= targetPos.column
    ) {
      targetCell = cell;
    }
    return targetCell;
  }, targetSection.cells[0]!);
}

function constrainPositionWithinSong(position: SectionCellBeat, song: Song): SectionCellBeat {
  const lastSectionIndex = song.sections.length - 1;
  const lastSection = song.sections[lastSectionIndex];
  assert(lastSection, `No sections in song?!?`);
  if (position[0] > lastSectionIndex) {
    /* we're past the end of the section list */
    const lastCellIndex = lastSection.cells.length - 1;
    return [lastSectionIndex, lastCellIndex];
  }
  if (position[0] < 0) {
    /* we're before the beginning of the section list */
    return [0, 0];
  }
  if (position[1] < 0) {
    /* cell focus is before the beginning of the section */
    if (position[0] > 0) {
      const prevSection = song.sections[position[0] - 1];
      assert(prevSection, `Section ${position[0] - 1} does not exist`);
      const prevSectionLastCellIndex = prevSection.cells.length - 1;
      return [position[0] - 1, prevSectionLastCellIndex];
    } else {
      return [0, 0];
    }
  }
  if (position[1] > (song.sections[position[0]]?.cells.length ?? 0) - 1) {
    /* cell focus is after the end of the section */
    if (position[0] < lastSectionIndex) {
      return [position[0] + 1, 0];
    } else {
      const lastCellIndex = lastSection.cells.length - 1;
      return [lastSectionIndex, lastCellIndex];
    }
  }
  return position;
}

function findCellAtPosition(position: SectionCellBeat = [0, 0], song: Song): Cell {
  const constrainedPosition = constrainPositionWithinSong(position, song);
  const section = song.sections[constrainedPosition[0]];
  assert(section, `Section ${constrainedPosition[0]} does not exist`);
  const cell = section.cells[constrainedPosition[1]];
  assert(cell, `Cell ${constrainedPosition[1]} does not exist`);
  return cell;
}
