import { Tracker } from 'meteor/tracker';
import { clog } from '@/browser/clog';
import type { Cell } from '@/chart/Cell.svelte';
import { Conductor } from '@/Conductor';
import { Crnt } from '@/Crnt';
import { MusicPageScroller } from '@/ui/auto-scroll/MusicPageScroller';
import { controlBarBelow } from '@/ui/controlBarBelow';

function* getAllElementsAfter(e: Element, selector?: string) {
  while (e.nextElementSibling) {
    e = e.nextElementSibling;
    if (!selector || e.matches(selector)) yield e;
  }
}

function getNextElement(el: Element, selector?: string) {
  const nextEl = el.nextElementSibling;
  if (!selector || (nextEl && nextEl.matches(selector))) {
    return nextEl;
  }
  return null;
}

export function bringCellIntoView(cellEl: HTMLElement, cell: Cell) {
  if (MusicPageScroller.holding()) return;

  if (!cell) {
    clog.warn('bringCellIntoView called with no cell');
    return;
  }

  const scrollContainerEl = document.querySelector('.js-global-songScrollContainer');
  if (!scrollContainerEl) return; // but after getting tracked value

  const editMode = Crnt.song()?.editMode();
  const cellBoundingRect = cellEl.getBoundingClientRect();
  let {
    top: containerBoundingRectTop,
    bottom: containerBoundingRectBottom,
    height: containerBoundingRectHeight,
    width: containerBoundingRectWidth,
  } = scrollContainerEl.getBoundingClientRect();

  const editorMenuPosition = MusicPageScroller.editorMenuPosition;
  if (editorMenuPosition) {
    const left =
      containerBoundingRectWidth / 2 + editorMenuPosition.centerX - editorMenuPosition.width / 2;
    const right = left + editorMenuPosition.width;
    const cellCenterX = cellBoundingRect.left + cellBoundingRect.width / 2;
    if (left < cellCenterX && right > cellCenterX) {
      containerBoundingRectHeight -= editorMenuPosition.height;
      containerBoundingRectBottom -= editorMenuPosition.height;
    }
  }

  const scrollBasis = scrollContainerEl.scrollTop - containerBoundingRectTop;
  const overlayHeightOnTop = controlBarBelow() || Crnt.song()?.editMode() ? 0 : 60;

  const viewHeight = scrollContainerEl.clientHeight;
  const notepadEl = document.querySelector('.js-global-notepad');
  if (notepadEl && !editMode) {
    const notepadBoundingRect = notepadEl.getBoundingClientRect();
    const notepadHasMuchoContent = notepadBoundingRect.height > 150;
    const bottomOfNotepadVisible =
      notepadBoundingRect.top + notepadBoundingRect.height - 40 < viewHeight;
    const notepadFillingMostOfScreen =
      (notepadBoundingRect.top ?? 999) < containerBoundingRectHeight * 0.5;
    if (notepadHasMuchoContent && (bottomOfNotepadVisible || notepadFillingMostOfScreen)) return;
  }

  if (cellBoundingRect.top < containerBoundingRectTop + overlayHeightOnTop) {
    // need to scroll up
    if (cell.layout.lineNumber === 0) {
      const section = cellEl.closest('.songSection');
      if (!section) return;
      const topOfSection = section.getBoundingClientRect().top;
      MusicPageScroller.scrollTo(scrollBasis - overlayHeightOnTop + topOfSection - 5);
    } else {
      const justAboveTopOfCell = cellBoundingRect.top - cellBoundingRect.height * 0.6;
      MusicPageScroller.scrollTo(scrollBasis - overlayHeightOnTop + justAboveTopOfCell);
    }
  } else {
    const bottom = (function calculateBottom(): number {
      const isFirstLine = cell.layout.lineNumber === 0;
      const linesLeft = [...getAllElementsAfter(cellEl, '.line-start')].length;
      const notCountIn = !Tracker.nonreactive(() => Conductor.displayState.startingPosition());
      const lastSection = cell.layout.sectionIndex + 1 == cell.section?.song.sections.length;

      if (lastSection && linesLeft <= 1 && notCountIn && !editMode) {
        const isLastRepInMedley =
          Crnt.medley() &&
          cell.section?.song.maxRound() ===
            Tracker.nonreactive(() => Conductor.displayState.position()?.round());
        if (isLastRepInMedley) {
          const thisMedleySong = cellEl.closest('.medleySong');
          const nextMedleySong = thisMedleySong
            ? getNextElement(thisMedleySong, '.medleySong')
            : undefined;
          const firstCellOfNextMedleySong = nextMedleySong?.querySelectorAll(
            ':scope .ChartCell:first-child'
          )[0];
          if (firstCellOfNextMedleySong)
            return firstCellOfNextMedleySong.getBoundingClientRect().bottom + 50;
        }
      }

      if (isFirstLine && notCountIn && !editMode) {
        const currentSection = cellEl.closest('.songSection');
        if (currentSection)
          return (
            currentSection.getBoundingClientRect().top +
            Math.min(currentSection.clientHeight, scrollContainerEl.clientHeight * 0.9)
          );
      }

      if (linesLeft === 0 && !editMode) {
        const thisSection = cellEl.closest('.songSection');
        const nextSection = thisSection ? getNextElement(thisSection, '.songSection') : undefined;
        const firstCellOfNextSection = nextSection?.querySelectorAll(
          ':scope .ChartCell:first-child'
        )[0];
        if (firstCellOfNextSection) return firstCellOfNextSection.getBoundingClientRect().bottom;
      }

      const bottomOfNextLine =
        cellBoundingRect.bottom + cellBoundingRect.height * (editMode ? 0.25 : 1.25);
      return bottomOfNextLine;
    })();

    if (bottom > containerBoundingRectBottom) {
      // need to scroll down
      MusicPageScroller.scrollTo(scrollBasis + bottom - containerBoundingRectHeight);
    }
  }
}
