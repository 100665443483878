<script lang="ts">
  interface Props {
    volume?: number;
    color?: string;
    size?: string;
  }

  let { volume = 1, color = 'inherit', size = '1.25em' }: Props = $props();
</script>

<svg
  viewBox="0 0 576 512"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  style="fill: currentColor; color: {color}; width: calc(1.125 * {size}); height: {size};"
>
  <path
    opacity={volume === 0 ? 0.7 : 1}
    d="M231.81,64C225.9,64 219.89,66.18 215.03,71.05L126.06,160L24,160C10.74,160 0,170.74 0,184L0,328C0,341.25 10.74,352 24,352L126.06,352L215.03,440.95C219.9,445.81 225.91,448 231.81,448C244.14,448 256,438.48 256,423.98L256,88.02C256,73.51 244.13,64 231.81,64ZM208,366.04L145.94,304L48,304L48,208L145.94,208L208,145.95L208,366.04Z"
    style="fill-rule:nonzero;"
  />
  <path
    opacity={Math.max(0, Math.min(1, volume * 3))}
    d="M338.23,179.12C326.65,172.79 312.04,176.96 305.62,188.57C299.23,200.18 303.46,214.77 315.07,221.18C327.98,228.28 336,241.62 336,256C336,270.37 327.98,283.72 315.08,290.81C303.47,297.22 299.24,311.81 305.63,323.42C312.06,335.08 326.68,339.22 338.24,332.87C366.47,317.32 384.01,287.87 384.01,256C384.01,224.13 366.47,194.67 338.23,179.12Z"
  />
  <path
    opacity={Math.max(0, Math.min(1, (volume - 0.25) / 0.5))}
    d="M480,256C480,192.91 447.94,134.91 394.23,100.85C383.04,93.76 368.2,97.05 361.11,108.26C354.02,119.47 357.33,134.29 368.52,141.38C408.27,166.59 432,209.44 432,256C432,302.56 408.27,345.4 368.52,370.62C357.33,377.71 354.02,392.54 361.11,403.74C367.62,414.02 382.23,418.77 394.23,411.15C447.94,377.09 480,319.09 480,256Z"
  />
  <path
    opacity={Math.max(0, Math.min(1, (volume - 0.6) / 0.4))}
    d="M448.35,19.97C437.18,12.64 422.17,15.73 414.84,26.92C407.5,38.09 410.62,53.1 421.79,60.43C488.06,103.91 527.61,177.02 527.61,256C527.61,334.98 488.06,408.08 421.79,451.57C410.62,458.89 407.5,473.91 414.84,485.07C421.88,495.78 436.77,499.63 448.35,492.02C528.27,439.57 576,351.33 576,256C576,160.67 528.27,72.42 448.35,19.97Z"
  />
  {#if volume === 0}
    <path
      d="M465.94,256L507.31,214.63C513.56,208.38 513.56,198.25 507.31,192L496,180.69C489.75,174.44 479.62,174.44 473.37,180.69L432,222.06L390.63,180.69C384.38,174.44 374.25,174.44 368,180.69L356.69,192C350.44,198.25 350.44,208.38 356.69,214.63L398.06,256L356.69,297.37C350.44,303.62 350.44,313.75 356.69,320L368,331.32C374.25,337.57 384.38,337.57 390.63,331.32L432,289.94L473.37,331.31C479.62,337.56 489.75,337.56 496,331.31L507.31,320C513.56,313.75 513.56,303.62 507.31,297.37L465.94,256Z"
      style="fill:rgb(235,4,4); transform: scale(1.4); transform-origin: 70% 50%;"
    />
  {/if}
</svg>

<style>
  svg {
    width: 1.25em;
    height: 1.25em;
  }
</style>
