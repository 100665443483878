import * as Sentry from '@sentry/browser';

let _cordovaReportedLatency = 0;

document.addEventListener(
  'deviceready',
  () => {
    let lastGetLatencyError: Error;
    function pollLatency() {
      if (document.visibilityState !== 'visible') {
        setTimeout(() => pollLatency(), 5000);
        return;
      }
      window.cordova.plugins.SMExtras.getLatency(
        (ms: number) => {
          const seconds = Math.max(0, Math.min(ms ?? NaN, 3000)) / 1000;
          if (_cordovaReportedLatency != seconds)
            console.info(`Audio latency reported as ${ms} ms`);
          _cordovaReportedLatency = seconds;
          setTimeout(() => pollLatency(), /Android/i.test(navigator.userAgent) ? 10000 : 3000);
        },
        (err: Error) => {
          console.error(err);
          if (lastGetLatencyError != err) {
            Sentry.captureException(err);
            setTimeout(() => pollLatency(), 3000);
            lastGetLatencyError = err;
          } else {
            setTimeout(() => pollLatency(), 10000);
          }
        }
      );
    }
    pollLatency();
  },
  false
);

export function getCordovaReportedLatency(): number {
  return _cordovaReportedLatency;
}
