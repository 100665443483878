export default {
  songLists: 'sm-songLists',
  songsData: 'sm-songs-data-v5',
  songsIndex: 'sm-songs-index-v2',
  songsInserted: 'sm-songs-inserted',
  songsUpdated: 'sm-songs-updated',
  songsRemoved: 'sm-songs-removed',
  medleysData: 'sm-medleys-data-v1',
  medleysIndex: 'sm-medleys-index-v1',
  medleysInserted: 'sm-medleys-inserted',
  medleysUpdated: 'sm-medleys-updated',
  medleysRemoved: 'sm-medleys-removed',
  sua: 'sm-sua',
  suaUpdates: 'sm-sua-updates',
  users: 'sm-users',
  usersUpdates: 'sm-users-updates',
  usersBandPresets: 'sm-users-band-presets',
  outdated: [
    'sm-songs-data',
    'sm-songs-data-v2',
    'sm-songs-data-v3',
    'sm-songs-data-v4',
    'sm-songs-index',
    'sm-help-pages',
    'sm-precache-v1', // workbox doesn't use IndexedDB anymore
  ],
};
