<script lang="ts">
  import { assertTruthy } from '@sindresorhus/is';
  import { strict as assert } from 'assert';
  import { RadioGroup } from 'bits-ui';
  import { Tracker } from 'meteor/tracker';
  import { onMount } from 'svelte';
  import { bassSettingDefinitions } from '@/band/instruments/bass/bassSettingDefinitions';
  import { createSettingsHashWithPreset } from '@/band/instruments/createSettingsHashWithPreset';
  import { feetSettingDefinitions } from '@/band/instruments/feet/feetSettingDefinitions';
  import { findMatchingInstrumentPreset } from '@/band/instruments/findMatchingInstrumentPreset';
  import { guitarSettingDefinitions } from '@/band/instruments/guitar/guitarSettingDefinitions';
  import type { InstrumentCore } from '@/band/instruments/InstrumentCore';
  import type { InstrumentSetting } from '@/band/instruments/InstrumentSetting';
  import type { InstrumentStylePreset } from '@/band/instruments/InstrumentStylePreset';
  import { mandolinSettingDefinitions } from '@/band/instruments/mandolin/mandolinSettingDefinitions';
  import { bassPatternLineup } from '@/band/ui/bassPatternLineup';
  import InstrumentPresetRow from '@/band/ui/components/InstrumentPresetRow.svelte';
  import { feetPatternLineup } from '@/band/ui/feetPatternLineup';
  import { guitarPatternLineup } from '@/band/ui/guitarPatternLineup';
  import { mandolinPatternLineup } from '@/band/ui/mandolinPatternLineup';
  import PanelModalLayout from '@/band/ui/panels/PanelModalLayout.svelte';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';
  import { eventTracker } from '@/browser/analytics/eventTracker';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import { MultiPanelModalController } from '@/ui/settings/MultiPanelModalController.svelte';
  import { ClickTap } from '@/ui-helpers/click-tap';

  const modalController = MultiPanelModalController.current();
  const bandMenu = getBandMenuState();

  interface Props {
    instId?: InstrumentId;
  }

  let { instId = 'guitar' }: Props = $props();

  let instrument = $derived($bandMenu.band.instruments[instId]);
  let timeSignature = $derived($bandMenu.timeSignature);

  let lineup = $derived(
    timeSignature
      ? {
          'guitar': guitarPatternLineup[timeSignature],
          'bass': timeSignature == '4/4' ? bassPatternLineup[timeSignature] : [],
          'mandolin': mandolinPatternLineup[timeSignature],
          'feet': timeSignature == '4/4' ? feetPatternLineup[timeSignature] : [],
        }[instId]
      : []
  );

  let settingDefinitions = $derived(
    {
      'guitar': guitarSettingDefinitions,
      'bass': bassSettingDefinitions,
      'mandolin': mandolinSettingDefinitions,
      'feet': feetSettingDefinitions,
    }[instId]
  );

  // svelte-ignore state_referenced_locally
  let currentCore = $state<InstrumentCore>(
    instrument.coreOrFallbackForTimeSignature(timeSignature)
  );
  trackerEffect(() => (currentCore = instrument.coreOrFallbackForTimeSignature(timeSignature)));

  // svelte-ignore state_referenced_locally
  let currentSettings = $state<Record<string, InstrumentSetting<unknown>>>(instrument.settings());
  trackerEffect(() => (currentSettings = instrument.settings()));

  let currentPreset: InstrumentStylePreset<unknown> | undefined = $derived(
    findMatchingInstrumentPreset(currentCore, currentSettings)
  );

  let optionsContainer: HTMLElement;

  // svelte-ignore state_referenced_locally
  const customStyle:
    | {
        core: InstrumentCore;
        settings: Record<string, InstrumentSetting<unknown>>;
        data: Record<string, unknown>;
      }
    | undefined = currentPreset
    ? undefined
    : {
        core: currentCore,
        settings: currentSettings,
        data: {
          ...instrument.serialize(),
          'sw': instrument.swing().id,
        },
      };

  let elementToBringIntoView: Element | undefined = $state();
  onMount(() =>
    setTimeout(function scrollCurrentStyleIntoView() {
      elementToBringIntoView = optionsContainer?.querySelector(':checked') || undefined;
    }, 150)
  );

  function revertToCustomStyle() {
    assert(customStyle);
    instrument.setSavedData(customStyle.data);
    eventTracker.bandInstrumentStyleReverted({ instrument: instId });
  }

  function getPresetByName() {
    return currentPreset?.name ?? 'custom';
  }

  function setPresetByName(name: string) {
    if (name == 'custom') {
      revertToCustomStyle();
    } else {
      const section = lineup.find((section) => section.core.presets.some((p) => p.name == name));
      const core = section?.core;
      const preset = core?.presets.find((p) => p.name == name);
      assertTruthy(section);
      assertTruthy(core);
      assertTruthy(preset);
      //@ts-expect-error Instrument typing issue
      instrument.loadCoreAndPreset(core, preset.settings);
      eventTracker.bandInstrumentPresetSet({
        instrument: instId,
        core: section.core.id,
        preset: preset.name,
      });
    }
  }
</script>

<PanelModalLayout
  bottomFogHeight={50}
  overflowHidden={true}
  scrollMilestone="SCROLL_PATTERNS"
  scrollHintLabel="Scroll for more patterns"
  {elementToBringIntoView}
>
  {#snippet header()}
    <header class="panelHeader text-left">
      {ClickTap} "Play" above to hear the selected style.
    </header>
  {/snippet}

  {#snippet main()}
    <div>
      <RadioGroup.Root class="relative" bind:value={getPresetByName, setPresetByName}>
        <div
          class="divide-y divide-panelWellBorder border-b border-panelWellBorder"
          bind:this={optionsContainer}
        >
          {#each lineup as section (section.heading)}
            {#if section.heading}
              <div class="px-5 pb-3 pt-4 sm-band:px-4">
                <div class="font-bold text-gray-900">
                  {section.heading}
                </div>
                {#if section.description}
                  <div class="text-sm text-gray-600">
                    {section.description}
                  </div>
                {/if}
              </div>
            {/if}

            {#if customStyle && customStyle.core.id == section.core.id}
              <InstrumentPresetRow
                name="Customized style"
                settings={customStyle.settings}
                core={section.core}
                {timeSignature}
                customStyle={true}
                value="custom"
                doubleClickHandler={() => modalController.zoomOut()}
              />
            {/if}

            {#each section.core.presets as preset (preset.name)}
              <InstrumentPresetRow
                name={preset.name}
                settings={createSettingsHashWithPreset(settingDefinitions, preset.settings)}
                core={section.core}
                {timeSignature}
                value={preset.name}
                doubleClickHandler={() => modalController.zoomOut()}
              />
            {/each}
          {:else}
            <div class="mx-auto max-w-[17em] py-6 text-center text-gray-700">
              Check back later when we have multiple {instId} styles to choose from!
            </div>
          {/each}
        </div>
      </RadioGroup.Root>
      <div class="pb-4"></div>
    </div>
  {/snippet}
</PanelModalLayout>
