<script lang="ts">
  import type { BandPreset } from '@/band/presets/BandPreset';
  import PresetEditModeHeaderSaveCancelButtons from '@/band/ui/components/PresetEditModeHeaderSaveCancelButtons.svelte';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';

  const bandMenu = getBandMenuState();

  let band = $derived($bandMenu.band);

  let preset: BandPreset | undefined = $state();
  trackerEffect(() => (preset = band.preset()));

  let friendlyTimeSignature: string = $derived(
    $bandMenu.timeSignature == '4/4' ? 'standard time' : `${$bandMenu.timeSignature} time`
  );
</script>

<div class="px-6 pb-6 pt-4 sm-band:pl-4">
  <div class="flex">
    <div class="flex-1">
      <h1 class="pb-1.5 pt-0.5 text-xl font-medium leading-6 text-gray-900">
        {#if preset?.default}
          Editing Default Band
        {:else if preset?.builtIn}
          Editing Built-in Preset
        {:else}
          Editing Your Preset
        {/if}
      </h1>

      <div class="pb-3 pr-2 text-sm text-gray-600">
        {#if preset?.default}
          These are the default settings used by every song in {friendlyTimeSignature}. Changes made
          here will apply to all songs in {friendlyTimeSignature} that don’t have a preset or custom
          band set.
        {:else}
          Changes made to
          {preset?.builtIn ? 'this built-in' : 'your custom'}
          preset will be reflected in any song using this preset.
        {/if}
      </div>
      <PresetEditModeHeaderSaveCancelButtons />
    </div>
  </div>
</div>
