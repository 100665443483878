import { strumDown, strumUp } from '@/band/instruments/guitar/GuitarActions';
import type { GuitarCore } from '@/band/instruments/guitar/GuitarCore';
import { swingOptions } from '@/band/swingOptions';

export const HalfTimeGuitarCore: GuitarCore = {
  id: 'ht',
  title: 'Half-time strum',

  timeSignatures: ['4/4'],

  linkedSettingsKeys: [],
  otherSettingsKeys: ['timing'],

  chordStyle: 'cowboy',

  swingCategory: 'pop',
  defaultSwing: swingOptions['3:2'],

  presets: [
    {
      name: 'Half-time',
      settings: {},
    },
  ],

  processMeasure(measure, _settings) {
    const emphasize = measure.beats[0].chordChanged || measure.cells[0].layout.column === 0;
    const secondInMeasurePair = (measure.cells[0].layout.column ?? 0) % 4 == 2;
    if (!secondInMeasurePair) {
      return [
        strumDown('bass', { spread: Math.random() * 2 + (emphasize ? 2 : 1), db: 0 }),
        null,
        Math.random() > 0.5 ? strumDown('bass', { spread: Math.random() + 0.5, db: -3 }) : null,
        null,
        strumDown('high', { spread: Math.random() + 3, db: 0 }),
        null,
        null,
        strumUp('mid', { spread: Math.random() + 2, db: 0 }),
      ];
    } else {
      return [
        strumDown('bass', { spread: Math.random() * 2 + 1, db: -2 }),
        strumUp('low', { spread: Math.random() + 2, db: 0 }),
        strumDown('bass', { spread: Math.random() * 2 + 1, db: -2 }),
        null,
        strumDown('high', { spread: Math.random() + 3, db: 0 }),
        null,
        strumDown('high', { spread: Math.random() + 2, db: -3 }),
        strumUp('mid', { spread: Math.random() + 1, db: -4 }),
      ];
    }
  },

  generateViz(settings) {
    settings;
    return [
      { dir: 'd', biasY: 0, tail: 0.5, thickness: 0.8 },
      null,
      { dir: 'd', biasY: 0, tail: 0.25, thickness: 0.4, opacity: 0.5, tailOpacity: 0.5 },
      null,
      { dir: 'd', biasY: 1, tail: 0.7, thickness: 0.9 },
      null,
      null,
      { dir: 'u', biasY: 1, tail: 0.4, thickness: 0.7 },

      { dir: 'd', biasY: 0, tail: 0.5, thickness: 0.8 },
      { dir: 'u', biasY: 0.5, tail: 0.4, thickness: 0.7 },
      { dir: 'd', biasY: 0, tail: 0.3, thickness: 0.5 },
      null,
      { dir: 'd', biasY: 1, tail: 0.7, thickness: 0.9 },
      null,
      { dir: 'd', biasY: 1, tail: 0.7, thickness: 0.6 },
      { dir: 'u', biasY: 0.8, tail: 0.4, thickness: 0.5 },
    ];
  },
};
