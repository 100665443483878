import type { GuitarChord } from '@/band/instruments/guitar/GuitarChord';
import { rawGuitarBassRunDefinitions } from '@/band/instruments/guitar/rawGuitarBassRunDefinitions';
import type { GuitarOpenVoicingsSetting } from '@/band/instruments/guitar/settings/GuitarOpenVoicingsSetting';
import type { Chord } from '@/music/Chord';

type KeyShape = 'G' | 'A' | 'C' | 'D' | 'dD' | 'E';
type BassRunScale = 'sm' | 'mm' | 'b';
type BassRunAttr = 'single' | 'quarters' | 'eighths' | 'syncopated' | 'extended' | 'descending';

export type BassRunResult = {
  /** Intervals in the run, expressed as zero-indexed semitones relative to the tonic of the key */
  intervals: (number | null)[];
  attrs: Partial<Record<BassRunAttr, boolean>>;
  scaleWeighting: number;
  keyWeighting: number;
};

export type LeadingNoteResult = {
  /** Leading note, expressed in zero-indexed semitones relative to the tonic of the key */
  interval: number;
  scaleWeighting: number;
  keyWeighting: number;
};

type BassRunDefinition = {
  attrs: Partial<Record<BassRunAttr, boolean>>;
  majorScaleWeightings: Partial<Record<BassRunScale, number>>;
  keyShapeWeightings: Partial<Record<KeyShape, number>>;
  intervalsInKeyShapes: Partial<Record<KeyShape, (number | null)[]>>;
};

function getNNS(guitarChord: GuitarChord, key: Chord) {
  const interval = guitarChord.chord.inKey(key.toString()).getInterval();
  return `${interval.number}${interval.accidental ?? ''}${
    guitarChord.chord.type.replace(/[75]$/g, '') ?? ''
  }`;
}

export class GuitarBassRunLibrary {
  private static _runLibrary: ReturnType<typeof organizeDefs>;
  private static get runLibrary() {
    return (this._runLibrary ??= organizeDefs(rawGuitarBassRunDefinitions));
  }

  static getLeadingNotes({
    keyChord,
    fromChord,
    toChord,
    voicing,
    scale,
  }: {
    keyChord: Chord;
    fromChord: GuitarChord;
    toChord: GuitarChord;
    voicing: GuitarOpenVoicingsSetting;
    scale: 'sm' | 'mm' | 'b';
  }): LeadingNoteResult[] {
    if (fromChord.capo !== toChord.capo) return [];
    const fromNNS = getNNS(fromChord, keyChord);
    const toNNS = getNNS(toChord, keyChord);
    const allRunsForChordPair = this.runLibrary[fromNNS]?.[toNNS] ?? [];
    const position = voicing.positionToUseForKey(
      `${keyChord.rootNote}${keyChord.minor ? 'm' : ''}`
    );
    const keyShape = position.shape == 'D' && voicing.dropD ? 'dD' : (position.shape as KeyShape);
    return allRunsForChordPair.reduce<LeadingNoteResult[]>((acc, run) => {
      if (!run.attrs.single) return acc;
      const keyWeighting = (run.keyShapeWeightings[keyShape] ?? 0) / 5;
      const scaleWeighting = (run.majorScaleWeightings[scale] ?? 0) / 9;
      const interval = run.intervalsInKeyShapes[keyShape]?.[0];
      if (!keyWeighting || !scaleWeighting || typeof interval !== 'number') return acc;
      acc.push({ interval, keyWeighting, scaleWeighting });
      return acc;
    }, []);
  }

  static getRuns({
    keyChord,
    fromChord,
    toChord,
    voicing,
    scale,
    timeSignature,
    filters = {},
  }: {
    keyChord: Chord;
    fromChord: GuitarChord;
    toChord: GuitarChord;
    voicing: GuitarOpenVoicingsSetting;
    scale: 'sm' | 'mm' | 'b';
    timeSignature: TimeSignature;
    filters: Partial<Record<BassRunAttr, boolean>>;
  }): BassRunResult[] {
    if (fromChord.capo !== toChord.capo) return [];
    const fromNNS = getNNS(fromChord, keyChord);
    const toNNS = getNNS(toChord, keyChord);
    const allRunsForChordPair = this.runLibrary[fromNNS]?.[toNNS] ?? [];
    const position = voicing.positionToUseForKey(
      `${keyChord.rootNote}${keyChord.minor ? 'm' : ''}`
    );
    const keyShape = position.shape == 'D' && voicing.dropD ? 'dD' : (position.shape as KeyShape);
    return allRunsForChordPair.reduce<BassRunResult[]>((acc, run) => {
      if (run.attrs.single) return acc;
      if (!filterObjectWithFilters(filters)(run.attrs)) return acc;
      const keyWeighting = (run.keyShapeWeightings[keyShape] ?? 0) / 5;
      const scaleWeighting = (run.majorScaleWeightings[scale] ?? 0) / 9;
      let intervals = run.intervalsInKeyShapes[keyShape];
      if (!keyWeighting || !scaleWeighting || !intervals) return acc;
      if (timeSignature == '3/4') {
        if (run.attrs.quarters) intervals = [null, null, ...intervals];
        if (run.attrs.eighths) intervals = [null, null, ...intervals];
      }
      acc.push({ attrs: run.attrs, intervals, keyWeighting, scaleWeighting });
      return acc;
    }, []);
  }
}

function filterObjectWithFilters<K extends string>(filters: {
  [key in K]?: boolean;
}): (obj: { [key in K]?: boolean }) => boolean {
  return (obj) => {
    for (const key of Object.keys(filters) as K[]) {
      if (typeof filters[key] === 'boolean' && !!obj[key] !== filters[key]) return false;
    }
    return true;
  };
}

function organizeDefs(defs: typeof rawGuitarBassRunDefinitions) {
  return defs.reduce<Record<string, Record<string, BassRunDefinition[]>>>(
    (acc, [fromNNS, toNNS, tags, tones, majorScaleWeightings, keyShapeWeightings]) => {
      const cellTones = tones.slice(4);
      const cellTonesDownAnOctave = cellTones.map((t) => (t === null ? null : t - 12));
      const attrs = {
        single: tags.includes('S'),
        quarters: tags.includes('Q'),
        eighths: tags.includes('E'),
        syncopated: tags.includes('s'),
        extended: tags.includes('x'),
        descending: tags.includes('D'),
      };
      const run = {
        attrs,
        majorScaleWeightings,
        keyShapeWeightings,
        intervalsInKeyShapes: {
          G:
            tones.every((t) => (t ?? 99) >= 9) &&
            !(attrs.descending && tones.every((t) => (t ?? 9) >= 9 && (t ?? 11) <= 11))
              ? cellTonesDownAnOctave
              : cellTones,
          A: tones.every((t) => (t ?? 99) >= 7) ? cellTonesDownAnOctave : cellTones,
          C: tones.every((t) => (t ?? 99) >= 4) ? cellTonesDownAnOctave : cellTones,
          D: tones.every((t) => (t ?? 99) >= 2) ? cellTonesDownAnOctave : cellTones,
          dD: cellTones,
          E: cellTones,
        },
      };
      ((acc[fromNNS] ??= {})[toNNS] ??= []).push(run);
      return acc;
    },
    {}
  );
}
