import { Tracker } from 'meteor/tracker';
import type { UserPreferenceKey, UserPreferencesValues } from '@/user/UserPreferences';
import UserPreferences from '@/user/UserPreferences';
import { UserProfile } from '@/user/UserProfile';

class GlobalUserPreferencesState {
  #meteorUserPreferences = $state<Partial<UserPreferencesValues>>();

  constructor() {
    Tracker.autorun(() => {
      this.#meteorUserPreferences = UserProfile.preferences() ?? {};
    });
  }

  #preferences = $derived.by(
    () =>
      Object.fromEntries(
        Object.keys(UserPreferences.settings).map((key) => [
          key,
          this.#meteorUserPreferences?.[key as UserPreferenceKey],
          // $derived(UserPreferences.get(key as UserPreferenceKey)),
        ])
      ) as UserPreferencesValues
  );

  preferences() {
    return this.#preferences;
  }
}

const _preferencesState = new GlobalUserPreferencesState();

export function crntPreferences(): UserPreferencesValues {
  return _preferencesState.preferences();
}
