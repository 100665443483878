import EventEmitter from 'eventemitter3';
import type { Channel } from '@/audio/engine/base/Channel';
import type { PlayProps } from '@/audio/engine/base/PlayProps';
import type { Sample } from '@/audio/engine/base/Sample';

export abstract class Playback extends EventEmitter {
  readonly sampleId: string;
  readonly startTime: number;
  readonly volume: number;
  offset: number;
  readonly playDuration: number;
  abstract readonly channel: Channel;

  readonly db: number;

  destroyed = false;

  // new Playbacks should only be constructed by the Sample class
  constructor({
    sample,
    playProps,
    currentTime,
  }: {
    sample: Sample;
    playProps: PlayProps;
    currentTime: number;
  }) {
    super();

    this.startTime = playProps.atTime ?? currentTime;
    this.offset = playProps.offset || 0;
    this.volume = playProps.volume;
    this.db = 20 * Math.log10(this.volume);

    this.sampleId = sample.id;

    // always set duration, because if a Web Audio source node is told to start with
    // an undefined duration, iOS/Safari will throw a DOM Exception 11
    this.playDuration =
      (playProps.playDuration == null ? sample.duration : playProps.playDuration) || 0;
    this.playDuration -= this.offset;
    this.playDuration = Math.max(0, this.playDuration);
  }

  abstract fade({ to, at, duration }: { to: number; at: number; duration: number }): Promise<void>;

  // Isn't this the same as .stop() with a fadeDuration?
  async fadeOut({ at, duration }: { at: number; duration: number }): Promise<void> {
    return this.fade({ to: 0, at, duration });
  }

  scheduledStopTime?: number;
  abstract stop({
    atTime,
    fadeDuration,
  }: {
    atTime?: number;
    fadeDuration?: number;
  }): Promise<void>;
  abstract stop(): Promise<void>;

  // eslint-disable-next-line @typescript-eslint/require-await
  async destroy(): Promise<void> {
    if (this.destroyed) return;
    this._destroy();
    this.destroyed = true;
    this.emit('destroyed', { target: this });
    this.removeAllListeners();
  }

  abstract _destroy(): void;
}
