import { FlowRouter } from 'meteor/ostrio:flow-router-extra';

Template.forgotPasswordForm.onCreated(() => {
  const instance = Template.instance();
  instance.errorMessage = new ReactiveVar();
  instance.submitting = new ReactiveVar();
});

Template.forgotPasswordForm.onRendered(() => {
  const instance = Template.instance();
  setTimeout(() => {
    instance.$('input[name="email"]').focus();
  }, 50);
});

Template.forgotPasswordForm.helpers({
  errorMessage() {
    return Template.instance().errorMessage.get();
  },
  submitting() {
    return Template.instance().submitting.get();
  },
});

Template.forgotPasswordForm.events({
  'submit form': function (event, instance) {
    event.preventDefault();
    instance.submitting.set(true);
    const email = instance.$('[name="email"]').val().trim();
    Accounts.forgotPassword({ email }, (err) => {
      if (!err) {
        clientModules.analytics.track('ForgotPassword.EmailSent');
        Session.set('email-inbox-to-open', email);
        FlowRouter.go('forgot-password-sent');
      } else {
        if (err.reason == 'User not found') {
          clientModules.analytics.track('ForgotPassword.EmailNotFound');
          instance.errorMessage.set(
            'Sorry, that email doesn’t match an existing account. Check for typos, or <a href="/signup">sign up for a new&nbsp;account</a>.'
          );
        } else {
          clientModules.analytics.track('ForgotPassword.Error', { label: err.reason });
          instance.errorMessage.set(err.reason);
        }
        instance.submitting.set(false);
      }
    });
  },
});
