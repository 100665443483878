<script lang="ts">
  import { onDestroy, onMount } from 'svelte';
  import type { Instance as TippyInstance } from 'tippy.js';
  import tippyJs from 'tippy.js';
  import AudioManager from '@/audio/engine/AudioManager';
  import BpmPopoverWithTapTempo from '@/ui/BPMPopoverWithTapTempo.svelte';
  import ScalarInputTrimmer from '@/ui/ScalarInputTrimmer.svelte';
  import { convertNumberToFractionString } from '@/utilities/convertNumberToFractionString';

  // Props
  interface Props {
    value?: number;
    unit?: string;
    prefix?: string;
    increment?: number;
    min?: number;
    max?: number;
    delay?: boolean;
    allowEditNull?: boolean;
    disabled?: boolean;
    masterBPM?: boolean;
    setValueFunc: (value: string | number) => void;
    adjustFunc: (amount: number) => void;
  }

  let {
    value,
    unit,
    prefix = '',
    increment = 1,
    min,
    max,
    delay,
    allowEditNull = false,
    disabled = false,
    masterBPM = false,
    setValueFunc,
    adjustFunc,
  }: Props = $props();

  // State variables
  let inputFocused = $state(false);
  let arrowKeyDown = $state(false);
  let isDisabled = $state(disabled); // separate $state so we can override locally
  let announceChanges = $state(false);
  let textInput: HTMLInputElement;
  let label: HTMLElement;

  const inflectedUnits = /^time|rep|repetition|bar$/;

  let inflectedUnit = $derived(
    inflectedUnits.test(unit ?? '') ? (value == 1 ? unit : `${unit ?? ''}s`) : unit
  );

  let pluralUnit = $derived(unit ? (inflectedUnits.test(unit) ? `${unit}s` : unit) : '');

  let canIncrease = $derived(typeof max === 'number' && typeof value === 'number' && value < max);
  let canDecrease = $derived(typeof min === 'number' && typeof value === 'number' && value > min);

  // Update input value when not focused
  $effect(() => {
    if (!inputFocused || arrowKeyDown) {
      if (value === undefined || value === null) {
        if (!allowEditNull) {
          isDisabled = true;
          announceChanges = false;
          if (textInput) {
            textInput.value = '· · ·';
            textInput.setAttribute('aria-hidden', 'true');
          }
        } else {
          isDisabled = disabled;
          if (textInput) {
            textInput.value = 'set';
            textInput.setAttribute('aria-hidden', 'true');
          }
        }
      } else {
        isDisabled = disabled;
        if (textInput) {
          textInput.value = (prefix ?? '') + convertNumberToFractionString(value);
          textInput.removeAttribute('aria-hidden');
        }
      }
      requestAnimationFrame(autolayout);
    }
  });

  let triggerTap: (() => void) | undefined;

  function cleanedValueString(): string {
    if (!textInput?.value) return '';
    return textInput.value
      ?.replace(/,/g, '.')
      .replace('⅓', '.33')
      .replace('½', '.5')
      .replace('⅔', '.66')
      .replace(/[^0-9.,-]/g, '');
  }

  function autolayout() {
    if (!textInput || !label) return;

    textInput.style.textAlign = 'right';
    label.style.textAlign = 'right';

    setTimeout(() => {
      if (!textInput || !label) return;
      textInput.style.transition = 'all 0.2s ease';
      label.style.transition = 'all 0.2s ease';
    }, 200);

    const labelWidth = label.clientWidth;
    if (labelWidth == 0) {
      requestAnimationFrame(autolayout);
      return;
    }

    if (inputFocused) {
      textInput.style.paddingRight = `${labelWidth + 8}px`;
      label.style.right = '8px';
    } else {
      // each digit is approximately 9 pixels wide (16px @ 500 weight)
      const valueWidth = textInput.value.length * 9;
      textInput.style.paddingRight = `calc(50% + ${labelWidth / 2}px - ${valueWidth / 2}px)`;
      label.style.right = `calc(50% - ${labelWidth / 2}px - ${valueWidth / 2}px)`;
    }
  }
  onMount(() => requestAnimationFrame(autolayout));

  let stepHandle: number;
  let stepCount = 0;

  const trimmerFlashAttrs = $state<
    Record<'decrease' | 'increase', { flashIteration: number; flashDuration: number }>
  >({
    decrease: { flashIteration: 0, flashDuration: 0 },
    increase: { flashIteration: 0, flashDuration: 0 },
  });

  function step(adjustFunc: (amount: number) => void, amount: number) {
    adjustFunc(amount);
    stepCount++;
    const stepTime = 100 + 200 / stepCount;
    const flashAttrs = trimmerFlashAttrs[amount < 0 ? 'decrease' : 'increase'];
    flashAttrs.flashIteration++;
    flashAttrs.flashDuration = stepTime;
    stepHandle = window.setTimeout(() => step(adjustFunc, amount), stepTime);
  }

  function startStepping(amount: number) {
    window.clearTimeout(stepHandle);
    stepCount = 0;
    step(adjustFunc, amount);
  }

  function stopStepping() {
    window.clearTimeout(stepHandle);
    stepCount = 0;
    announceChanges = true;
  }

  onDestroy(stopStepping);

  function onKeyDown(event: KeyboardEvent) {
    if (event.key == ' ') {
      triggerTap?.();
      return false;
    }
    if (['ArrowUp', 'ArrowDown'].includes(event.key) && !isDisabled) {
      stopStepping();
      const direction = event.key == 'ArrowDown' ? -1 : 1;
      startStepping(direction * (event.ctrlKey || event.shiftKey ? 5 : 1));
      announceChanges = true;
      arrowKeyDown = true;
      return false;
    }
    return;
  }

  function onKeyUp(event: KeyboardEvent) {
    if (event.key == 'Escape') {
      inputFocused = false;
      textInput.blur();
    }
    if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
      stopStepping();
      arrowKeyDown = false;
      return false;
    }
    return;
  }

  function onKeyPress(event: KeyboardEvent) {
    if (event.key == 'Tab') {
      setValueFunc(cleanedValueString());
      inputFocused = false;
      setTimeout(() => {
        // TODO: improve this; don't query entire document, just focus sibling trimmer directly
        const element = document.querySelector(
          event.shiftKey ? '.js-decrease' : '.js-increase'
        ) as HTMLElement;
        if (element) element.focus();
      }, 100);
      return false;
    }

    if (event.key == 'Enter') {
      setValueFunc(cleanedValueString());
      inputFocused = false;
      textInput.blur();
      return false;
    }

    const value = textInput.value;
    const cleaned = cleanedValueString();
    if (value != cleaned) {
      textInput.value = cleaned;
    }
    return;
  }

  function onFocus() {
    tapTippyInstance?.show();
    if (/iP(hone|od)/i.test(navigator.userAgent)) {
      // iPhones show that stupid text-selection menu which gets in the way
      // so instead we'll just clear the value
      textInput.value = '';
      textInput.select();
    } else {
      textInput.value =
        value
          ?.toString()
          .replace(/\.3.+/, '.3')
          .replace(/\.5.+/, '.5')
          .replace(/\.(6|7).+/, '.7') || '';
      textInput.select();
      textInput.setSelectionRange(0, 9999);
    }
    inputFocused = true;
    requestAnimationFrame(autolayout);
  }

  function onInput() {
    if (!delay) setValueFunc(cleanedValueString());
  }

  const inputBlurDelayMs = 100;

  function onBlur() {
    tapTippyInstance?.hide();
    setTimeout(() => {
      setValueFunc(cleanedValueString());
      inputFocused = false;
      announceChanges = true;
      requestAnimationFrame(autolayout);
    }, inputBlurDelayMs); // slight delay so that the OK button can be clicked (seemingly)
  }

  let tapTippyInstance: TippyInstance | undefined;
  function tippyMenuForMasterBPM(contentNode: HTMLElement) {
    tapTippyInstance = tippyJs(textInput, {
      animation: 'scale-subtle',
      arrow: false,
      content: contentNode,
      duration: [300, 150],
      hideOnClick: false,
      interactive: true,
      offset: [0, 5],
      placement: 'bottom',
      popperOptions: { modifiers: [{ name: 'flip', options: { padding: 22 } }] },
      role: '',
      theme: 'light',
      trigger: 'manual',
    });
    return { destroy: () => tapTippyInstance?.destroy() };
  }
</script>

<!-- svelte-ignore a11y_no_static_element_interactions -->
<div
  class="scalarInputWithTrimmers relative flex select-none items-center justify-center"
  class:master-bpm={masterBPM}
  onmouseenter={() => masterBPM && AudioManager.setHighlyResponsive(true)}
  onmouseleave={() => masterBPM && AudioManager.setHighlyResponsive(false)}
>
  <ScalarInputTrimmer
    increment={-increment}
    altIncrement={-1}
    {pluralUnit}
    hidden={isDisabled || inputFocused}
    disabled={!canDecrease}
    {...trimmerFlashAttrs.decrease}
    startStepping={(amount) => {
      if (document.activeElement === textInput) textInput.blur();
      startStepping(+amount);
    }}
    {stopStepping}
  />

  <div
    class="scalarInputWithTrimmers-center relative flex h-[38px] w-[5.6em] items-center py-[0.1em]"
    class:not-editing={!inputFocused}
    class:js-global-bpmDisplay={masterBPM}
    aria-label={inflectedUnit}
    data-tippy-dropdown-id="tap-tempo"
  >
    <input
      bind:this={textInput}
      type="text"
      inputmode="decimal"
      autocomplete="off"
      pattern="[0-9 ,.⅓½⅔\-]*"
      enterkeyhint="done"
      aria-roledescription="textbox"
      aria-live={announceChanges ? 'polite' : null}
      aria-haspopup={masterBPM ? 'true' : null}
      class="scalarInputWithTrimmers-centerTextBox notranslate js-textInput"
      class:btn={!inputFocused}
      class:form-control={inputFocused}
      disabled={isDisabled}
      data-srec-nomask
      onkeydown={onKeyDown}
      onkeyup={onKeyUp}
      onkeypress={onKeyPress}
      onfocus={onFocus}
      oninput={onInput}
      onblur={onBlur}
    />

    {#if masterBPM}
      <!-- svelte-ignore a11y_interactive_supports_focus -->
      <div
        class="dropdown-tippy js-tapTempoContainer"
        role="menu"
        use:tippyMenuForMasterBPM
        onmousedown={(event) => {
          event.stopPropagation();
          event.preventDefault();
        }}
        ontouchstart={(event) => {
          event.stopPropagation();
          event.preventDefault();
        }}
      >
        <BpmPopoverWithTapTempo
          registerTapFn={(fn) => (triggerTap = fn)}
          updateValue={(amount) => {
            textInput.value = Math.round(+amount).toString();
          }}
        />
      </div>
    {/if}

    <span
      bind:this={label}
      class="scalarInputWithTrimmers-centerUnitLabel notranslate js-label"
      aria-hidden="true"
    >
      {inputFocused ? pluralUnit : inflectedUnit}
    </span>
  </div>

  {#if inputFocused}
    <button
      class="btn btn-round btn-success right-0 top-1/2 z-10 -translate-y-1/2"
      style="position: absolute !important; outline: 0;"
      aria-label="OK"
      onclick={(event) => {
        event.target.blur();
      }}
    >
      <i class="smi smi-check" aria-hidden="true"></i>
    </button>
  {/if}

  <ScalarInputTrimmer
    {increment}
    altIncrement={1}
    {pluralUnit}
    hidden={isDisabled || inputFocused}
    disabled={!canIncrease}
    {...trimmerFlashAttrs.increase}
    {startStepping}
    {stopStepping}
  />
</div>

<style>
  .scalarInputWithTrimmers-centerTextBox.btn,
  .scalarInputWithTrimmers-centerTextBox.form-control {
    width: 100%;
    height: 100%;
    line-height: 1;
    padding-left: 0;
    padding-right: 0;
    font-size: 16px;
    font-weight: 500;
    border-width: 1px;
  }
  .scalarInputWithTrimmers-centerTextBox.btn {
    border-color: transparent;
  }
  .scalarInputWithTrimmers-centerTextBox.form-control {
    color: #333;
    margin: 2px 0;
    padding-left: 2px;
    padding-right: 0;
    flex: 1 1 auto;
  }

  .scalarInputWithTrimmers-centerUnitLabel {
    font-size: 14px;
    padding-top: 2px; /* to align with 16px text */
    font-weight: 400;
    padding-left: 3px;
    cursor: default;
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: 4px;
    transform: translateY(-50%);
    color: #aaa;

    .btn + & {
      color: var(--primary-500);
    }
  }
</style>
