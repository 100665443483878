<script lang="ts">
  import GlobalInstrumentMixerRow from '@/audio/mixer/ui/GlobalInstrumentMixerRow.svelte';
  import MasterMixer from '@/audio/mixer/ui/MasterMixer.svelte';
  import PanelHeading from '@/band/ui/components/PanelHeading.svelte';
  import PanelModalLayout from '@/band/ui/panels/PanelModalLayout.svelte';
  import { Crnt } from '@/Crnt';

  // Not using $bandMenu so that this works in other settings menus
  const band = Crnt.song()?.band;

  const allInstrumentIds = ['guitar', 'bass', 'mandolin', 'feet'] as InstrumentId[];
</script>

<PanelModalLayout>
  {#snippet header()}
    <header>
      <PanelHeading title="Global Volume/Pan Mixer">
        {#snippet text()}
          <div>
            These settings will be used in every song that doesn't have custom instrument volume/pan
            settings, in both editing and playback.
          </div>
        {/snippet}
      </PanelHeading>
    </header>
  {/snippet}

  {#snippet main()}
    <div class="space-y-4 p-4">
      <ul class="space-y-4">
        {#each allInstrumentIds as instId (instId)}
          <li>
            <GlobalInstrumentMixerRow
              {band}
              {instId}
              background="white"
              inactive={false}
              canBeWide={false}
            />
          </li>
        {/each}
      </ul>

      <hr class="mb-1 mt-1 text-muted-100" />

      <MasterMixer />
    </div>
  {/snippet}
</PanelModalLayout>
