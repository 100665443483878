export function nativeDecode(context: AudioContext, data: ArrayBuffer): Promise<AudioBuffer> {
  return new Promise<AudioBuffer>((resolve, reject) => {
    // We prefer the promise-based API (Safari throws better exceptions that way)
    // but we fall back to the old callback API after a delay
    const promiseResult = context.decodeAudioData(
      data,
      (result) => setTimeout(() => resolve(result), 0),
      (error) => setTimeout(() => reject(error), 0)
    );
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    if (promiseResult) promiseResult.then(resolve, reject);
  });
}
