import { Meteor } from 'meteor/meteor';
import { ListBackend } from '@/library/ListBackend';
import { MusicLibraryMedleys } from '@/library/MusicLibraryMedleys';
import { MusicLibrarySearchIndex } from '@/library/MusicLibrarySearchIndex';
import { MusicLibrarySongs } from '@/library/MusicLibrarySongs';
import { guardAgainstListPreviewDiscrepancy } from '@/lists/guardAgainstListPreviewDiscrepancy';
import waitUntilReactive from '@/utilities/waitUntilReactive';

class MusicLibrarySingleton {
  index = new MusicLibrarySearchIndex();
  songs = new MusicLibrarySongs();
  medleys = new MusicLibraryMedleys();
  lists = new ListBackend();

  private _loadingStarted = false;

  async load(): Promise<void> {
    if (this._loadingStarted) return;
    this._loadingStarted = true;

    this.lists.load();
    this.songs.load({ listBackend: this.lists });
    this.medleys.load({ listBackend: this.lists, songsBackend: this.songs });

    guardAgainstListPreviewDiscrepancy();

    await waitUntilReactive(
      () => this.songs.ready() && this.medleys.ready() && (!Meteor.userId() || this.lists.ready())
    );
    this.index.addSources(
      this.songs.indexCollection,
      this.medleys.indexCollection,
      this.lists.songsOnlyInLists,
      this.lists.medleysOnlyInLists
    );
    this.index.initIndex();
  }

  ready(): boolean {
    return this.index.readyToSearch();
  }
  offlineReady(): boolean {
    return !!(
      this.ready() &&
      this.lists.offlineReady() &&
      this.songs.offlineReady() &&
      this.medleys.offlineReady() &&
      this.index.readyToSearch()
    );
  }

  blockingOperationInProgress(): boolean {
    return this.songs.blockingOperationInProgress() || this.medleys.blockingOperationInProgress();
  }
}

export const MusicLibrary = new MusicLibrarySingleton();

if (Meteor.isDevelopment) {
  ((window as any).dev ||= {}).MusicLibrary = MusicLibrary;
}
