<script lang="ts">
  import type { Snippet } from 'svelte';
  import type { Instance as TippyInstance } from 'tippy.js';
  import { makeTippyMenu } from '@/ui/dropdowns/makeTippyMenu';

  interface Props {
    placement?: string;
    button?: Snippet;
    menu?: Snippet<[{ tippyInstance: TippyInstance }]>;
  }

  let { placement = 'bottom', button, menu }: Props = $props();

  let tippyInstance = $state() as TippyInstance;
  function tippyMenu(node: HTMLElement, params: Record<string, any> = {}) {
    tippyInstance = makeTippyMenu(node, params, { keysThatShow: [' ', 'Enter'] });
    return { destroy: () => tippyInstance.destroy() };
  }
</script>

<div use:tippyMenu={{ theme: 'light', placement: placement }}>
  {@render button?.()}
  <div class="isolate z-10 text-left text-base" role="menu">
    {@render menu?.({ tippyInstance })}
  </div>
</div>

<style>
</style>
