class TimerGroup {
  timeoutIds = new Set();

  schedule(callback, delay) {
    callback.timeoutId = setTimeout(() => {
      callback();
      this.timeoutIds.delete(callback.timeoutId);
    }, delay);
    this.timeoutIds.add(callback.timeoutId);
  }

  clear() {
    this.timeoutIds.forEach((id) => {
      clearTimeout(id);
    });
    this.timeoutIds.clear();
  }
}

export default TimerGroup;
