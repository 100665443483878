import { Meteor } from 'meteor/meteor';
import { delegate as delegateTippyJs } from 'tippy.js';
import { putArticleAtEnd } from '@/library/putArticleAtEnd';

const escapedEntitiesRegex = /[&<>"]/;

let inPolypane: boolean | undefined;

function quickSanitize(text: string): string {
  return escapedEntitiesRegex.test(text)
    ? text
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
    : text;
}

function renderHtml({
  song,
  showNew,
  otherUsers,
  isOwn,
  textToHighlight,
  loggedIn,
  listId,
  noLinks,
  noLIWrapper,
}: {
  song: {
    _id: string;
    name: string;
    userId?: string;
    userFirst?: string;
    userLast?: string;
    medley?: boolean;
    medleySongs?: unknown[];
    unsynced?: boolean;
    listCount?: number;
  };
  showNew?: boolean;
  otherUsers?: boolean;
  isOwn?: boolean;
  textToHighlight?: string;
  loggedIn?: boolean;
  listId?: string;
  noLinks?: boolean;
  noLIWrapper?: boolean;
}): string {
  const html: string[] = [];
  let o = 0;

  let indexName, indexLabel;
  const sanitizedName = quickSanitize(song.name);
  indexName = sanitizedName.replace(/ +\[.+\]$/, '');
  if (!song.medley) indexName = putArticleAtEnd(indexName);
  if (textToHighlight) {
    indexName = indexName.replace(
      new RegExp('\\b(' + textToHighlight + ')', 'gi'),
      "<span class='hl'>$1</span>"
    );
  }
  const labelMatch = sanitizedName.match(/ \[(.+)\]$/);
  if (labelMatch) {
    indexLabel = labelMatch[1];
    if (textToHighlight && indexLabel) {
      indexLabel = indexLabel.replace(
        new RegExp('\\b(' + textToHighlight + ')', 'gi'),
        "<span class='hl'>$1</span>"
      );
    }
  }

  if (!noLIWrapper) html[o++] = `<li class="songIndexItem" data-id="${song._id}">`;
  if (noLinks) {
    html[o++] = '<span class="songIndexItem-link">';
  } else {
    const songsOrMedleys = song.medley || song.medleySongs ? 'medleys' : 'songs';
    html[o++] = `<a href="/app/${songsOrMedleys}/${song._id}${listId ? '?list=' + listId : ''}"`;
    inPolypane ??= document.documentElement.classList.contains('in-polypane');
    if (inPolypane) html[o++] = `aria-label="${song.name}" `;
    html[o++] = `class="songIndexItem-link">`;
  }
  html[o++] = `<strong class="songIndexItem-name">${indexName}</strong>`;

  if (indexLabel) {
    html[o++] = '<span class="songIndexItem-details">';
    html[o++] = ` <span class="songIndexItem-label">${indexLabel}</span>`;
  }

  if (song.medley || song.medleySongs) {
    html[o++] = ' <span class="songIndexItem-medleyLabel">Medley</span>';
  }

  if (song.userId) {
    if (isOwn) {
      html[o++] = ' <span class="songIndexItem-userLabel mine">Mine</span>';
    } else {
      let fullName, firstName;
      if (song.userFirst) {
        firstName = quickSanitize(song.userFirst);
        fullName = quickSanitize(song.userFirst + (song.userLast ? ` ${song.userLast}` : ''));
      } else {
        firstName = 'Community';
        fullName = 'Another user in the community';
      }
      html[o++] =
        ` <span class="songIndexItem-userLabel" data-name="${fullName}">${firstName}</span>`;
    }
  }

  if (indexLabel) {
    html[o++] = '</span>';
  }

  /*
  if (showNew) {
    let daysOld = Math.floor((Date.now() - song.createdAt) / 86400000);
    if ( daysOld < 0 ) {
      html[o++] = `<span class="songIndexItem-new unreleased" title="Will be released on ${song.createdAt}">In ${-daysOld} days</span>`;
    } else if ( daysOld < 8 ) {
      let ageString;
      if (daysOld === 0) ageString = 'today';
      else if (daysOld === 1) ageString = 'yesterday';
      else ageString = daysOld + ' days ago';
      html[o++] = '<span class="songIndexItem-new age-' + daysOld + '" title="Added ' + ageString + '">New</span>';
    }
  }

  const otherUserSong = otherUsers && song.userId && !isOwn;
  if (otherUserSong) {
    let otherUserName = song.userId;
    let otherUserColor = song.userId;
    let foundUser = Meteor.users.find({_id: song.userId}).fetch()[0];
    if (foundUser && foundUser.profile) {
      otherUserName = foundUser.profile.lastName ? `${foundUser.profile.firstName} ${foundUser.profile.lastName}` : foundUser.profile.firstName;
    }
    let uid = song.userId;
    let hash = 7;
    for (let i = 3; i >= 0; i--) {
      hash = hash*31 + song.userId.charCodeAt(i);
    }
    let hue = hash % 360;
    html[o++] = '<span class="songIndexItem-otherUser" style="background-color: hsl(' + hue + ', 50%, 60%)">' + otherUserName + '</span>';
  }
  */
  if (noLinks) {
    html[o++] = '</span>';
  } else {
    html[o++] = '</a>';
  }

  // if (song.score !== undefined) {
  //   html[o++] = `<span class="songIndexItem-matchScore"><span class="fill" style="width: ${(
  //     (1 - song.score) *
  //     100
  //   ).toFixed(1)}%"></span></span>`;
  // }

  // if (isOwn) {
  //   html[o++] = '<div class="songIndexItem-icons"><i class="smi smi-account" aria-hidden="true" title="You added this song"></i></div>';
  // }
  if (loggedIn) {
    html[o++] = syncLabel(song);
    html[o++] = listsButton(song.listCount || 0);
  }
  if (!noLIWrapper) html[o++] = '</li>';
  return html.join(''); // consider adding an empty space...  there might be a weird bug otherwise...
}

function listsButton(listCount: number): string {
  const html: string[] = [];
  let o = 0;
  if (listCount > 0) {
    html[o++] =
      `<div class="songIndexItem-listsButton has-lists" data-list-count="${listCount}" role="button" aria-label="Change lists" tabindex="0">`;
    html[o++] = `<span class="list-count-label">${listCount} list${
      listCount == 1 ? '' : 's'
    }</span>`;
  } else {
    html[o++] =
      '<div class="songIndexItem-listsButton no-lists" role="button" aria-label="Add to list" tabindex="0">';
    html[o++] = '<i class="smi smi-plus add-icon" aria-hidden="true"></i>';
  }
  html[o++] = '</div>';
  return html.join('');
}

function syncLabel(song: { unsynced?: boolean | string }) {
  const html: string[] = [];
  let o = 0;
  if (song.unsynced) {
    html[o++] = `<span class="songIndexItem-syncLabel unsynced ${
      song.unsynced == 'new' ? 'new' : ''
    }">`;
    html[o++] = 'Unsynced';
    html[o++] = '</span>';
  }
  return html.join('');
}

export function createSongListTippyOnParent(parent: HTMLElement) {
  return delegateTippyJs([parent], {
    target: '.songIndexItem-listsButton, .songIndexItem-syncLabel, .songIndexItem-userLabel',
    delay: [150, 50],
    content(target: Element | HTMLElement) {
      if (!('dataset' in target)) return '';
      if (target.classList.contains('songIndexItem-userLabel')) {
        if (target.classList.contains('mine')) return 'You made this song';
        return `${target.dataset.name ?? 'Someone else'} made this song`;
      }
      if (target.classList.contains('songIndexItem-syncLabel')) {
        return target.classList.contains('new')
          ? "Song was made offline and hasn't been synced to the cloud"
          : "Changes to this song haven't been synced to the cloud";
      }
      if (target.classList.contains('songIndexItem-listsButton')) {
        const online = Meteor.status().connected;
        if (target.classList.contains('no-lists'))
          return `Add to list${online ? '' : ' (only works online)'}`;
        const listCount = +(target.dataset.listCount ?? 0);
        return `Song is on ${listCount} list${listCount > 1 ? 's' : ''}.${
          online ? '\nClick to change.' : ''
        }`;
      }
      return '';
    },
    appendTo: parent,
    placement: 'bottom',
  });
}

const SongIndexItem = {
  renderHtml,
};

export default SongIndexItem;
