import { applyGuitarIntentsToMeasure } from '@/band/instruments/guitar/applyGuitarIntentsToMeasure';
import { assignGuitarChords } from '@/band/instruments/guitar/assignGuitarChords';
import { BoomChuckGuitarCore } from '@/band/instruments/guitar/cores/BoomChuckGuitarCore';
import { BoomChuckWaltzGuitarCore } from '@/band/instruments/guitar/cores/BoomChuckWaltzGuitarCore';
import { CelticJigGuitarCore } from '@/band/instruments/guitar/cores/CelticJigGuitarCore';
import { CelticSlipJigGuitarCore } from '@/band/instruments/guitar/cores/CelticSlipJigGuitarCore';
import { distributeGuitarIntentsToMeasure } from '@/band/instruments/guitar/distributeGuitarIntentsToMeasure';
import type { GuitarCore } from '@/band/instruments/guitar/GuitarCore';
import { guitarCores } from '@/band/instruments/guitar/guitarCores';
import { guitarSettingDefinitions } from '@/band/instruments/guitar/guitarSettingDefinitions';
import type { GuitarSettingsHash } from '@/band/instruments/guitar/GuitarSettingsHash';
import { overlayMeasureEffectsForGuitar } from '@/band/instruments/guitar/overlayMeasureEffectsForGuitar';
import { initializeInstrumentSettings } from '@/band/instruments/initializeInstrumentSettings';
import { Instrument } from '@/band/instruments/Instrument';
import type { InstrumentPresetSettings } from '@/band/instruments/InstrumentPresetSettings';
import type { LinearizedMeasure } from '@/chart/LinearizedMeasure';
import { safeCallback } from '@/utilities/safeCallback';
import { safeExecute } from '@/utilities/safeExecute';

export class Guitar extends Instrument<GuitarSettingsHash, GuitarCore> {
  readonly id = 'guitar';
  readonly name = 'Guitar';
  readonly shortCode = 'g';

  readonly cores = guitarCores;
  readonly fallbackCores: Record<TimeSignature, GuitarCore> = {
    '4/4': BoomChuckGuitarCore,
    '3/4': BoomChuckWaltzGuitarCore,
    '6/8': CelticJigGuitarCore, // TODO: Change to simpler Celtic DUDDUD strum
    '9/8': CelticSlipJigGuitarCore,
  };

  readonly settingDefinitions = guitarSettingDefinitions;
  protected _settings = initializeInstrumentSettings<GuitarSettingsHash>(this.settingDefinitions);

  loadCoreAndPreset(
    core: GuitarCore,
    presetSettings: InstrumentPresetSettings<GuitarSettingsHash>
  ) {
    const oldSwingCategory = this.core()?.swingCategory ?? 'none';
    const newSwingCategory = core.swingCategory;
    const shouldUpdateSwing = this.enabled() && newSwingCategory != oldSwingCategory;
    this.emit('updatedData', {
      [`${this.shortCode}_c`]: core.id,
      // ...this.irrelevantSettingsResetHash(core),
      ...this.serializePresetSettings(presetSettings),
      ...(shouldUpdateSwing ? { 'sw': core.defaultSwing.id } : {}),
    });
  }

  processMeasures(
    measures: readonly LinearizedMeasure[],
    core: GuitarCore,
    settings: Readonly<GuitarSettingsHash>,
    context: Readonly<{ swing: SwingOption; tpm: number }>
  ) {
    const swing = context.swing.formula(context.tpm);
    const beatDuration = 60 / context.tpm;
    const timingTweaks = settings.timing.beatAdjustments;
    const openVoicings = settings.openVoicings;

    assignGuitarChords({ measures, openVoicings, chordStyle: core.chordStyle });

    safeExecute(() =>
      core.measurePreprocessing?.(measures, settings, {
        noEmbellishments: this.noEmbellishments(),
      })
    );

    measures.forEach(
      safeCallback((measure) => {
        const intents = core.processMeasure(measure, settings, { swing, beatDuration });
        distributeGuitarIntentsToMeasure(intents, measure);
        overlayMeasureEffectsForGuitar(measure);
      })
    );

    safeExecute(() => core.measurePostprocessing?.(measures, settings));

    measures.forEach(
      safeCallback((measure) => {
        applyGuitarIntentsToMeasure(measure, swing, timingTweaks);
      })
    );
  }
}
