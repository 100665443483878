import { downstroke } from '@/band/instruments/mandolin/MandolinActions';
import type { MandolinIntent } from '@/band/instruments/mandolin/MandolinIntent';
import type { LinearizedCell } from '@/chart/LinearizedCell';

export function standardChopSplitCell(
  cell: LinearizedCell,
  _options: Record<string, unknown>
): (MandolinIntent | null)[] {
  return cell.beats.flatMap(() => [
    downstroke({
      db: -3,
      velocity: 3,
      chordSpread: 1.8,
      chordDuration: 0.03,
    }),
    null,
  ]);
}
