import { bassNote, chop, strumDown } from '@/band/instruments/guitar/GuitarActions';
import type { GuitarSettingsHash } from '@/band/instruments/guitar/GuitarSettingsHash';
import { interpolateAcrossGradient } from '@/band/instruments/guitar/settings/interpolateAcrossGradient';
import { lerp } from '@/utilities/lerp';
import { randomPlusMinus } from '@/utilities/randomPlusMinus';

export function dampenedDownbeat(settings: GuitarSettingsHash) {
  const duration = settings.dampenedSustain.onbeatSustain;
  const backbeatSpreadReduction = interpolateAcrossGradient(settings.dampenedBackbeat.value, [
    [0, 0],
    [0.25, 1],
    [1, 2],
  ]);
  const spread = lerp(
    1,
    4 + randomPlusMinus(0.2) - backbeatSpreadReduction,
    settings.dampenedOnbeats.spread
  );
  const db =
    -settings.dampenedBackbeat.onbeatDbReduction + lerp(2, 0, settings.dampenedOnbeats.spread);
  if (settings.dampenedSustain.onbeatSustain < 1) {
    return chop(duration, 'jazzD', { spread, db });
  } else {
    if (settings.dampenedOnbeats.singleNote) {
      return bassNote('root', { db });
    } else {
      return strumDown('jazzD', { spread, db });
    }
  }
}
