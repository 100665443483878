<script lang="ts">
  import { untrack } from 'svelte';
  import type { Instance as TippyInstance } from 'tippy.js';
  import tippyJs from 'tippy.js';
  import type { Cell } from '@/chart/Cell.svelte.ts';
  import type { Song } from '@/chart/Song';
  import autoFitCellContents from '@/chart/ui/autoFitCellContents';
  import ChartCellMagnifier from '@/chart/ui/ChartCellMagnifier.svelte';
  import {
    reactiveDragSelectClasses,
    reactiveFocusClasses,
    reactiveLoopClasses,
    reactivePlaybackClasses,
    reactiveStartAnimationDuration,
  } from '@/chart/ui/ChartCellReactiveClasses';
  import { addCellTransition, removeCellTransition } from '@/chart/ui/ChartCellTransitions';
  import { handleInteractionsWithCellsIfSongPresent } from '@/chart/ui/handleInteractionsWithCells';
  import { renderChordAsHtml } from '@/chart/ui/renderChordAsHtml';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import type { Chord } from '@/music/Chord';

  interface Props {
    song: Song | undefined;
    cell: Cell;
    cellCount: number;
    chordSystem: 'letters' | 'numbers' | 'roman' | undefined;
    useSlashes: boolean;
    editMode: boolean;
  }

  let { song, cell, cellCount, chordSystem, useSlashes, editMode }: Props = $props();

  let cellDiv = $state() as HTMLElement;

  const getChordArray = (cell: Cell) => {
    const chords: ({ chordHeard?: Chord; chordShown?: Chord; effect?: string } | undefined)[] =
      cell.subdividedBeats?.slice(0, cell.beatCount) ?? [cell];
    return chords;
  };

  // I tried combining these into a single Tracker to improve first-render performance, but it make 0 difference
  let dragSelectClasses: string = $state('');
  trackerEffect(
    () => (dragSelectClasses = song ? reactiveDragSelectClasses({ cell, song }).join(' ') : '')
  );

  let focusClasses: string = $state('');
  trackerEffect(() => (focusClasses = song ? reactiveFocusClasses({ cell, song }).join(' ') : ''));

  let loopClasses: string = $state('');
  trackerEffect(() => (loopClasses = song ? reactiveLoopClasses({ cell, song }).join(' ') : ''));

  let playbackClasses: string = $state('');
  trackerEffect(
    () => (playbackClasses = song ? reactivePlaybackClasses({ cell, song }).join(' ') : '')
  );

  let animationDuration: string | undefined = $state();
  trackerEffect(() => (animationDuration = reactiveStartAnimationDuration(cell)));

  $effect.pre(() => {
    const chartCell = cellDiv;
    untrack(() => {
      cell.layout.chartCell = chartCell;
    });
  });

  let ariaLabel = $derived.by(() => {
    let ariaLabel = '';
    if (cell.effect != 'rest') {
      if (cell.split) {
        const chords = getChordArray(cell);
        ariaLabel = `subdivided beat with chords: ${chords
          .slice(0, -1)
          .map((c) => c?.chordShown?.toAria())
          .join(', ')}, and ${chords[chords.length - 1]?.chordShown?.toAria() ?? ''}`;
      } else {
        ariaLabel = cell.chordShown.toAria() + ' chord';
      }
      if (cell.effect) {
        ariaLabel += `, ${cell.effect} effect`;
      }
    } else {
      ariaLabel = 'rest';
    }
    if (cell.effectiveChangeToTimeSignature) {
      ariaLabel += `, change to ${cell.effectiveChangeToTimeSignature.replace('/', ' ')} time`;
    }
    if (cell.half) {
      ariaLabel += ', single-cell measure';
    }
    if (cell.volta) {
      ariaLabel += `, start of ending bracket for rep ${cell.volta.join(' and ')}`;
    }
    return ariaLabel;
  });

  let ariaPosition = $derived.by(() =>
    [
      cell.layout.barStart
        ? cell.layout.barEnd
          ? 'bar'
          : 'start of'
        : cell.layout.barEnd
          ? 'end of'
          : `cell ${cell.layout.barPos + 1} of`,
      ` bar ${cell.layout.barIndex + 1}`,
      cell.layout.cellIndex == cellCount - 1 ? ', end of' : ' in',
      ` section ${cell.layout.sectionIndex + 1}.`,
    ].join('')
  );

  const ariaEditorStatus = $derived(
    focusClasses.includes('focused-single') ? `${ariaLabel} at ${ariaPosition}` : ''
  );

  const renderTimeSig = $derived(
    cell.effectiveChangeToTimeSignature ||
      (cell.layout.cellIndex == 0 &&
        cell.layout.sectionIndex == 0 &&
        cell.effectiveTimeSignature &&
        cell.effectiveTimeSignature != '4/4')
  );

  let magnifierTippy: TippyInstance | undefined;
  let magnifierEl: HTMLElement | undefined = $state();

  const showMagnifiedView = $derived(
    editMode &&
      cell.split &&
      (focusClasses.includes('focused') || dragSelectClasses.includes('dragSelect-only'))
  );

  $effect(() => {
    if (showMagnifiedView) {
      requestAnimationFrame(() => {
        if (!magnifierTippy && magnifierEl) {
          magnifierTippy = tippyJs(cellDiv, {
            content: magnifierEl,
            placement: 'top',
            theme: 'nothing',
            hideOnClick: false,
            trigger: 'manual',
            animation: 'magnifyCell',
            duration: [200, 0],
            arrow: false,
            interactive: true,
            offset: [0, 3],
            moveTransition: 'transform 80ms ease-out',
          });
          magnifierTippy.show();
        }
      });
    } else {
      magnifierTippy?.destroy();
      magnifierTippy = undefined;
    }
  });
</script>

<div
  bind:this={cellDiv}
  in:addCellTransition={{ cell }}
  out:removeCellTransition={{ cell, cellCount }}
  class="
    ChartCell
    {dragSelectClasses}
    {focusClasses}
    {loopClasses}
    {playbackClasses}
  "
  class:repeated={cell.repeated}
  class:bar-start={cell.layout.barStart}
  class:bar-end={cell.layout.barEnd}
  class:line-start={cell.layout.lineStart}
  class:line-end={cell.layout.lineEnd}
  class:actual-line-end={cell.layout.lineEnd && cell.layout.column >= 7}
  class:section-start={cell.layout.cellIndex === 0}
  class:half={cell.half}
  class:implied={cell.implied}
  class:split={cell.split}
  class:whole={!cell.split}
  class:has-time-sig={renderTimeSig}
  class:is-waltz={cell.effectiveTimeSignature == '3/4'}
  class:magnifying={showMagnifiedView}
  role="button"
  aria-roledescription="cell"
  aria-describedby="measuredesc_{cell._id}"
  aria-label={`${ariaLabel}.`}
>
  {#if cell.layout.lineEnd || cell.layout.cellIndex + 1 == cell.section?.cells.length}
    <div
      class="absolute inset-y-[-3px] left-full w-[1000px] cursor-default"
      aria-hidden="true"
      use:handleInteractionsWithCellsIfSongPresent={{ cell, song, noClickToPlay: true }}
      onclick={() => {
        if (song?.loop.exists()) song.loop.reset();
      }}
    ></div>
  {/if}
  {#if cell.layout.lineStart}
    <div
      class="absolute inset-y-[-3px] right-full w-[1000px] cursor-default"
      aria-hidden="true"
      use:handleInteractionsWithCellsIfSongPresent={{ cell, song, noClickToPlay: true }}
      onclick={() => {
        if (song?.loop.exists()) song.loop.reset();
      }}
    ></div>
  {/if}
  <span
    use:autoFitCellContents={{ song }}
    use:handleInteractionsWithCellsIfSongPresent={{ cell, song }}
    class="ChartCell__walls"
    style={animationDuration ? `animation-duration: ${animationDuration}s` : ''}
    aria-hidden="true"
  >
    {#if cell.repeated && useSlashes && cell.layout.column !== 0}
      <div class="ChartCell__beat {cell.effect || ''}">
        <span class="chord"> / </span>
      </div>
    {:else}
      {#each getChordArray(cell) as obj, i (i)}
        <div class="ChartCell__beat {obj?.effect || ''}" data-beat={i}>
          {#if obj?.chordShown}
            <div class="flex items-center justify-center">
              {@html renderChordAsHtml(obj.chordShown, { system: chordSystem })}
            </div>
          {:else}
            <span class="chord empty">
              {@html editMode ? '-' : '&nbsp;'}
            </span>
          {/if}
        </div>
      {/each}
    {/if}
  </span>
  {#if showMagnifiedView && song}
    <div bind:this={magnifierEl}>
      <ChartCellMagnifier {cell} {song} {chordSystem} />
    </div>
  {/if}
  {#if renderTimeSig}
    {@const [above, below] = cell.effectiveTimeSignature.split('/')}
    <span class="ChartCell__timeSig" aria-hidden="true">
      {above == '4' && cell.layout.barEnd ? '2' : above}<br />{below}
    </span>
  {/if}
  {#if cell.layout.cellIndex == 0 && cell.layout.sectionIndex == 0 && cell.effectiveTimeSignature && cell.effectiveTimeSignature != '4/4'}
    {@const [above, below] = cell.effectiveTimeSignature.split('/')}
    <span class="ChartCell__timeSig" aria-hidden="true">
      {above}<br />{below}
    </span>
  {/if}
  {#if cell.volta}
    <span class="ChartCell__volta" aria-hidden="true">
      {cell.volta.join()}
    </span>
  {:else if cell.repConstraint}
    <span class="ChartCell__voltaContinued" aria-hidden="true"></span>
  {/if}
  <span style="display: none;" id="measuredesc_{cell._id}" aria-hidden="true">
    {ariaPosition}
  </span>
  {#if ariaEditorStatus}
    <div class="sr-only" role="alert" aria-live="assertive" aria-atomic="true" aria-relevant="all">
      {ariaEditorStatus}
    </div>
  {/if}
</div>
