import UserPreferences from '@/user/UserPreferences';

Template.optionSlider.onRendered(() => {
  const instance = Template.instance();
  instance.displayFunc =
    instance.data.displayFunc ||
    (instance.data.key && UserPreferences.settings[instance.data.key].displayFunc);
  instance.autorun(() => {
    const value = UserPreferences.get(instance.data.key);
    instance.$('.current-value').html(instance.displayFunc ? instance.displayFunc(value) : value);
    instance.$('input[type="range"]').val(value);
  });
});

const sliderUpdateEvent = (event, instance) => {
  UserPreferences.set(instance.data.key, $(event.currentTarget).val());
  event.preventDefault();
};

Template.optionSlider.events({
  'input input[type="range"]': sliderUpdateEvent,
  'change input[type="range"]': sliderUpdateEvent,
  'doubleclick input[type="range"]': function (event, instance) {
    if (instance.data.key)
      UserPreferences.set(instance.data.key, UserPreferences.settings[instance.data.key].default);
  },
});

Template.optionSlider.helpers({
  min() {
    if (this.min) return this.min;
    if (this.key) return UserPreferences.settings[this.key].min;
  },
  max() {
    if (this.max) return this.max;
    if (this.key) return UserPreferences.settings[this.key].max;
  },
  step() {
    if (this.step) return this.step;
    if (this.key) return UserPreferences.settings[this.key].step;
  },
});
