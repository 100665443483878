import { assignMandolinRhythmAccents } from '@/band/instruments/mandolin/assignMandolinRhythmAccents';
import { endingStandardChopMeasure } from '@/band/instruments/mandolin/blocks/endingStandardChopMeasure';
import { standardChopMeasure } from '@/band/instruments/mandolin/blocks/standardChopMeasure';
import { standardChopSplitCell } from '@/band/instruments/mandolin/blocks/standardChopSplitCell';
import type { MandolinCore } from '@/band/instruments/mandolin/MandolinCore';
import { standardChopPatternLandscape } from '@/band/instruments/mandolin/standardChopPatternLandscape';
import { swingOptions } from '@/band/swingOptions';
import { lerp } from '@/utilities/lerp';

export const ChopMandolinCore: MandolinCore = {
  id: 'c',
  title: 'Offbeat chop',

  timeSignatures: ['4/4'],

  linkedSettingsKeys: ['chopDynamics', 'chopAccents'],
  otherSettingsKeys: ['timing'],

  chordStyle: 'bluegrass',

  swingCategory: 'normal',
  defaultSwing: swingOptions['5:4'],

  presets: [
    {
      name: 'Basic chop',
      settings: {
        'chopDynamics': { i: 1, s: 1 },
        'chopAccents': { r: 1, theta: 0.25 },
      },
    },
    {
      name: 'Soft voiced chop',
      settings: {
        'chopDynamics': { i: 0, s: 2 },
        'chopAccents': { r: 1, theta: 0.25 },
      },
    },
    {
      name: 'Accented chop',
      settings: {
        'chopDynamics': { i: 1, s: 1 },
        'chopAccents': { r: 0, theta: 0 },
      },
    },
    {
      name: 'Busy chop',
      settings: {
        'chopDynamics': { i: 1, s: 1 },
        'chopAccents': { r: 1, t: 0.75 },
      },
    },
  ],

  measurePreprocessing(measures, settings, _flags) {
    assignMandolinRhythmAccents({ measures, settings });
  },

  processMeasure(measure, settings) {
    if (measure.endOfSong) {
      return endingStandardChopMeasure(measure, {});
    }

    const [firstCell, secondCell] = measure.cells;

    if (
      !measure.cells.some((cell) => cell.effect == 'diamond' || cell.effect == 'stop' || cell.split)
    ) {
      return standardChopMeasure([firstCell, secondCell], settings);
    }

    return [
      ...(firstCell.split
        ? standardChopSplitCell(firstCell, {})
        : standardChopMeasure([firstCell], settings)),
      ...(!secondCell
        ? []
        : secondCell.split
          ? standardChopSplitCell(secondCell, {})
          : standardChopMeasure([secondCell], settings)),
    ];
  },

  generateViz(settings) {
    // See /imports/band/instruments/guitar/cores/BluegrassGuitarCore.ts:94
    const [
      isolatedDownstrokeRatio,
      isolatedSecondDownstrokeRatio,
      upstrokeRatio,
      secondUpstrokeRatio,
      downstrokePower,
      upstrokePower,
      preUpstrokeDownPower,
    ] = settings.chopAccents.powersForLandscape(standardChopPatternLandscape);

    const chick = ([true, 'partial', false] as const)[settings.chopDynamics.sustainIndex];
    const intensity = settings.chopDynamics.intensityIndex;
    const chopThickness = ([0.65, 0.8, 0.95] as const)[intensity];
    const chopTail = ([0.6, 0.8, 1.0] as const)[intensity];
    const downstrokeTail = lerp(
      [0.1, 0.2, 0.3][intensity] as number,
      [0.3, 0.5, 0.7][intensity] as number,
      downstrokePower
    );
    const primaryUpstrokeTail = lerp(
      [0.2, 0.3, 0.5][intensity] as number,
      [0.4, 0.6, 0.8][intensity] as number,
      upstrokePower
    );
    const secondaryUpstrokeTail = lerp(
      [0.1, 0.2, 0.3][intensity] as number,
      [0.3, 0.5, 0.6][intensity] as number,
      upstrokePower
    );

    return [
      {
        dir: 'd',
        biasY: 0.2,
        tail: downstrokeTail,
        thickness: lerp(0.3, 0.7, downstrokePower),
        opacity: clearerOpacity(Math.max(isolatedDownstrokeRatio, upstrokeRatio)),
      },
      null,
      {
        dir: 'd',
        biasY: 0.3,
        tail: chopTail,
        thickness: chopThickness,
        sustain: 0,
        chick: chick,
      },
      {
        dir: 'u',
        biasY: 0.5,
        tail: secondaryUpstrokeTail,
        thickness: lerp(0.3, 0.7, upstrokePower),
        sustain: 0,
        opacity: clearerOpacity(secondUpstrokeRatio - 0.3),
      },
      {
        dir: 'd',
        biasY: 0.2,
        tail: downstrokeTail,
        thickness: lerp(0.3, 0.7, downstrokePower),
        opacity: clearerOpacity(
          Math.max(
            isolatedDownstrokeRatio * isolatedSecondDownstrokeRatio,
            upstrokeRatio * secondUpstrokeRatio
          )
        ),
      },
      {
        dir: 'u',
        biasY: 0.5,
        tail: primaryUpstrokeTail,
        thickness: lerp(0.3, 0.7, upstrokePower),
        opacity: clearerOpacity(upstrokeRatio),
      },
      {
        dir: 'd',
        biasY: 0.3,
        tail: chopTail,
        thickness: chopThickness,
        sustain: 0,
        chick: chick,
      },
      {
        dir: 'u',
        biasY: 0.5,
        tail: secondaryUpstrokeTail,
        thickness: lerp(0.3, 0.7, upstrokePower),
        sustain: 0,
        opacity: clearerOpacity(secondUpstrokeRatio),
      },
    ];
  },
};

function clearerOpacity(opacity: number) {
  if (opacity >= 0.92) return 1;
  if (opacity <= 0.08) return 0;
  return 0.2 + opacity * 0.6;
}
