<script lang="ts">
  import type { Instance as TippyInstance, Props as TippyProps } from 'tippy.js';
  import type { Band } from '@/band/Band';
  import { makeTippyMenu } from '@/ui/dropdowns/makeTippyMenu';

  interface Props {
    band: Band | undefined;
    instId: InstrumentId;
    property: 'volume' | 'pan';
  }

  let { band, instId, property }: Props = $props();

  let tippyInstance: TippyInstance | undefined = $state();
  function tippyMenu(node: HTMLElement, params: Partial<TippyProps> = {}) {
    tippyInstance = makeTippyMenu(node, params, { keysThatShow: [' ', 'Enter'] });
    return { destroy: () => tippyInstance?.destroy() };
  }
</script>

<div
  class="absolute inset-0 flex h-full w-full select-none items-stretch justify-stretch"
  use:tippyMenu={{ 'theme': 'light', 'placement': 'bottom' }}
>
  <button class="btn-base customOverlayButton"> custom for song </button>
  <div
    role="menu"
    tabindex="0"
    class="p-1.5 [&_.btn]:block [&_.btn]:px-3 [&_.btn]:py-1.5 [&_.btn]:text-left [&_.btn]:font-normal [&_button]:w-full"
  >
    <div class="px-3 pb-1 pt-2 text-sm text-gray-600">
      This instrument's {property == 'pan' ? 'left-right pan' : property} has been customized for this
      song, so the global {property} setting is not used.
    </div>
    <button
      class="btn"
      onclick={() => {
        if (property == 'volume') band?.instruments[instId].setCustomVolume(undefined);
        if (property == 'pan') band?.instruments[instId].setCustomPan(undefined);
        tippyInstance?.hide();
      }}
    >
      Remove custom {property} for this song
    </button>
  </div>
</div>

<style>
  button.customOverlayButton {
    background: none;
    border: none;
    display: block;
    width: 100%;
    text-align: center;
    text-shadow:
      1px 1px 2px white,
      -1px -1px 2px white;
    font-weight: 500;
    text-align: center;
    font-size: 14px;
    color: hsl(110, 55%, 25%);
    &:hover,
    &:active {
      color: hsl(110, 95%, 30%);
    }
  }
</style>
