import { ReactiveVar } from 'meteor/reactive-var';
import { getLocalStorageSafe, setLocalStorageSafe } from '@/utilities/safe-local-storage';

export class MasterVolume {
  private static reactiveValue = new ReactiveVar<number | undefined>(undefined);

  static get(): number {
    if (typeof this.reactiveValue.get() === 'undefined') {
      this.reactiveValue.set(+(getLocalStorageSafe(`sm.masterChannel.volume`) ?? '1'));
    }
    return this.reactiveValue.get() ?? 1;
  }

  static set(value: number) {
    if (this.reactiveValue.get() != value) {
      setLocalStorageSafe(`sm.masterChannel.volume`, value.toString());
    }
    this.reactiveValue.set(value);
  }
}
