const timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone || 'Unknown';

const canadaTimeZones = [
  'America/Cambridge_Bay',
  'America/Dawson',
  'America/Dawson_Creek',
  'America/Edmonton',
  'America/Fort_Nelson',
  'America/Glace_Bay',
  'America/Goose_Bay',
  'America/Halifax',
  'America/Inuvik',
  'America/Iqaluit',
  'America/Moncton',
  'America/Montreal',
  'America/Nassau',
  'America/Nipigon',
  'America/Pangnirtung',
  'America/Rainy_River',
  'America/Rankin_Inlet',
  'America/Regina',
  'America/Resolute',
  'America/St_Johns',
  'America/Swift_Current',
  'America/Thunder_Bay',
  'America/Toronto',
  'America/Vancouver',
  'America/Whitehorse',
  'America/Winnipeg',
  'America/Yellowknife',
  'Canada/Atlantic',
  'Canada/Central',
  'Canada/Eastern',
  'Canada/Mountain',
  'Canada/Newfoundland',
  'Canada/Pacific',
  'Canada/Saskatchewan',
  'Canada/Yukon',
];

const usTimeZones = [
  'America/Adak',
  'America/Anchorage',
  'America/Atka',
  'America/Boise',
  'America/Chicago',
  'America/Creston',
  'America/Denver',
  'America/Detroit',
  'America/Fort_Wayne',
  'America/Indiana/Indianapolis',
  'America/Indiana/Knox',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Tell_City',
  'America/Indiana/Vevay',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Indianapolis',
  'America/Juneau',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Knox_IN',
  'America/Los_Angeles',
  'America/Louisville',
  'America/Menominee',
  'America/Metlakatla',
  'America/New_York',
  'America/Nome',
  'America/North_Dakota/Beulah',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/Phoenix',
  'America/Shiprock',
  'America/Sitka',
  'America/Yakutat',
  'Navajo',
  'Pacific/Honolulu',
  'Pacific/Johnston',
];

const ukTimeZones = [
  'Europe/London',
  'Europe/Belfast',
  'Europe/Guernsey',
  'Europe/Isle_of_Man',
  'Europe/Jersey',
  'GB',
  'GB-Eire',
];

export const currentTimeZone = {
  name: timeZone,
  inEurope:
    timeZone == 'Unknown'
      ? -new Date().getTimezoneOffset() > -120 && -new Date().getTimezoneOffset() < 600
      : timeZone.startsWith('Europe') || timeZone.startsWith('GB'),
  inCanada: canadaTimeZones.includes(timeZone),
  inUS: usTimeZones.includes(timeZone),
  inUK: ukTimeZones.includes(timeZone),
  inAustralia: timeZone.startsWith('Australia/'),
  inAusNZ: /^Australia\/|Pacific\/(Auckland|Chatham)/.test(timeZone),
  utcOffset: -new Date().getTimezoneOffset(),
};

/**
 * How to generate this data:
 *

# fetch data from https://nodatime.org/TimeZones
curl https://nodatime.org/TimeZones\?version\=2021e\&format\=json > iana.json

# US time zones
cat iana.json | jq '.zones[] | select( .location.countryCode == "US" ) | [.id, .aliases]' | awk -F'"' '{print $2}' | grep .

# Europe time zones
cat iana.json | jq '.zones[] | select( .id | contains("Europe")) | [.id, .aliases]' | awk -F'"' '{print $2}' | grep .

# Canada time zones
cat iana.json | jq '.zones[] | select( .location.countryCode == "CA" ) | [.id, .aliases]' | awk -F'"' '{print $2}' | grep .

# Australia time zones (or just find "Australia/")
# NZ is Pacific/(Auckland|Chatham)
cat iana.json | jq '.zones[] | select( .location.countryCode == "AU" ) | [.id, .aliases]' | awk -F'"' '{print $2}' | grep .

*/
