<script lang="ts">
  import { GuitarBoomChuckBalanceSetting } from '@/band/instruments/guitar/settings/GuitarBoomChuckBalanceSetting';
  import CycleThroughDropdown from '@/band/ui/components/CycleThroughDropdown.svelte';
  import PrettySlider from '@/band/ui/components/PrettySlider.svelte';
  import RhythmPatternVisualization from '@/band/ui/components/RhythmPatternVisualization.svelte';
  import SliderRule from '@/band/ui/components/SliderRule.svelte';
  import PanelModalLayout from '@/band/ui/panels/PanelModalLayout.svelte';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';

  const bandMenu = getBandMenuState();

  const [min, max] = [-3, 3];

  let setting = $derived($bandMenu.guitarSettings.boomChuckBalance);

  function setBalance(val: number) {
    $bandMenu.guitar.setSetting('boomChuckBalance', setting.withBalance(val));
  }
</script>

<PanelModalLayout>
  {#snippet header()}
    <header class="panelHeader">
      This setting allows you to accentuate the
      <span class="whitespace-nowrap">guitar's onbeats</span>
      (boom) or offbeats (chuck).
    </header>
  {/snippet}

  {#snippet main()}
    <div>
      <div class="mb-7 mt-6 flex justify-center">
        <div
          style="box-shadow: 4px 6px 12px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.05), -4px -6px 12px 0px rgba(255, 255, 255, 0.95); border-radius: 12px; overflow: hidden; border: solid 2px var(--gray-100);"
        >
          <RhythmPatternVisualization
            actions={$bandMenu.guitar
              .coreOrFallbackForTimeSignature($bandMenu.timeSignature)
              .generateViz($bandMenu.guitarSettings)}
            timeSignature={$bandMenu.timeSignature}
            scale={1.9}
            highlightCurrentBeat={true}
            showBeatNumbers={false}
          />
        </div>
      </div>

      <div class="cycle-shadow mx-6 mb-3 rounded-lg bg-muted-100 p-1 pb-[5px]">
        <CycleThroughDropdown
          options={GuitarBoomChuckBalanceSetting.presets}
          selectedValue={setting.closestPreset.value}
          on:select={(event) => setBalance(event.detail)}
          wrapAround={true}
          captureLeftRightKeys={true}
        />
      </div>

      <div class="pb-0.5"><SliderRule /></div>
      <div class="mx-6 py-2">
        <PrettySlider
          {min}
          {max}
          value={setting.value * max}
          step={1}
          tickCount={7}
          tickSqueezes={[0.0, 0.3, 0.5, 0.6, 0.5, 0.3, 0.0]}
          tickFades={[1.0, 0.9, 0.8, 0.7, 0.8, 0.9, 1.0]}
          thumbDiameter={2.75}
          tickHeight={0.875}
          ariaLabel="boom/chuck balance; lower value emphasizes booms, higher value emphasizes chucks"
          fillFromCenter={true}
          centerDot={true}
          on:change={({ detail }) => setBalance(detail / max)}
        />
      </div>
      <SliderRule lowerLabel="Booms" upperLabel="Chucks" />
      <div class="mb-3"></div>
    </div>
  {/snippet}
</PanelModalLayout>
