import { strict as assert } from 'assert';

type DecoderWebWorkerConstructor = {
  new (): DecoderWebWorker;
};

type DecoderWebWorkerDecodeResult = Promise<{
  channelData: Float32Array[];
  samplesDecoded: number;
  sampleRate: number;
}>;

type DecoderWebWorker = {
  ready: Promise<void>;
  reset: () => Promise<void>;
  decode: (data: Uint8Array) => DecoderWebWorkerDecodeResult;
};

export class DecoderPool {
  workers: InstanceType<typeof this.workerConstructor>[] = [];
  readonly threadCount: number;
  private nextThreadIndex = 0;
  private workerConstructor: DecoderWebWorkerConstructor;
  constructor(workerConstructor: DecoderWebWorkerConstructor, threadCount: number) {
    this.threadCount = threadCount;
    this.workerConstructor = workerConstructor;
  }

  private ensureWorkersInitialized() {
    if (this.workers.length === 0) {
      for (let i = 0; i < this.threadCount; i++) {
        const worker = new this.workerConstructor();
        this.workers.push(worker);
      }
    }
  }

  private queuedDecodes: (() => Promise<void>)[] = [];
  async decode(data: ArrayBuffer): Promise<AudioBuffer> {
    this.ensureWorkersInitialized();
    const workerIndex = this.nextThreadIndex;
    this.nextThreadIndex = (this.nextThreadIndex + 1) % this.threadCount;
    const queueEmpty = this.queuedDecodes.length === 0;
    const promise = new Promise<AudioBuffer>((resolve) => {
      this.queuedDecodes.push(async () => {
        const worker = this.workers[workerIndex];
        assert(worker, 'Trying to decode with uninitialized worker');
        await worker.ready;
        const { channelData, samplesDecoded, sampleRate } = await worker.decode(
          new Uint8Array(data)
        );
        await worker.reset();
        if (!samplesDecoded) throw new Error('Failed to decode audio with WASM decoder');
        this.queuedDecodes.shift();
        void this.queuedDecodes[0]?.();
        setTimeout(() => {
          // This only decodes to mono-left for now
          const audioBuffer = new AudioBuffer({
            numberOfChannels: 1, // channelData.length,
            length: samplesDecoded,
            sampleRate: sampleRate > 40000 && sampleRate < 50000 ? sampleRate : 44100,
          });
          if ('copyToChannel' in audioBuffer) {
            audioBuffer.copyToChannel(channelData[0], 0);
            // audioBuffer.copyToChannel(channelData[1], 1);
          } else {
            //@ts-expect-error This code is for obsolete browsers
            // eslint-disable-next-line
            audioBuffer.getChannelData(0).set(channelData[0]);
            // audioBuffer.getChannelData(1).set(channelData[1]);
          }
          resolve(audioBuffer);
        }, 0);
      });
    });
    if (queueEmpty) {
      setTimeout(() => void this.queuedDecodes[0]?.(), 0);
    }
    return promise;
  }
}
