<script lang="ts">
  import BandPanelAlert from '@/band/ui/components/BandPanelAlert.svelte';
  import PanelSettingSection from '@/band/ui/components/PanelSettingSection.svelte';
  import PrettySlider from '@/band/ui/components/PrettySlider.svelte';
  import PanelModalLayout from '@/band/ui/panels/PanelModalLayout.svelte';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';
  import { slideFade } from '@/ui/svelte-transitions';
  import { clickTap } from '@/ui-helpers/click-tap';

  const bandMenu = getBandMenuState();

  let setting = $derived($bandMenu.guitarSettings.syncopatedLeadingNotes);

  let someBoomStrums = $derived($bandMenu.guitarSettings.brushiness.boomStrumProbability > 0.3);
  let allBoomStrums = $derived($bandMenu.guitarSettings.brushiness.boomStrumProbability == 1);
</script>

<PanelModalLayout>
  {#snippet header()}
    <header class="panelHeader">
      Leading notes are transitional notes played on beat 3 of the measure preceding a chord change.
      <span class="whitespace-nowrap">Since that</span> beat is normally skipped over in a syncopated
      strumming pattern, leading notes diminish the syncopation effect when played.
    </header>
  {/snippet}

  {#snippet main()}
    <div class="p-4">
      {#if someBoomStrums}
        {@const caption = $bandMenu.guitarSettings.brushiness.closestPreset.caption}
        <div class="pb-4">
          <BandPanelAlert>
            {#if allBoomStrums}
              <div>
                The Pick-Strum setting is making the guitar strum on every downbeat.
                <span class="whitespace-nowrap">No bass notes</span> are being played.
              </div>
              <div class="pt-3">
                To use the options below, {clickTap} Back, then open the Pick-Strum setting,
                <span class="whitespace-nowrap">then lower</span>
                the slider.
              </div>
            {:else}
              <div>
                Pick-Strum Balance is currently set to “{caption}”, so you may not hear as many
                individual bass notes.
              </div>
            {/if}
          </BandPanelAlert>
        </div>
      {/if}

      <PanelSettingSection
        title="Use leading notes"
        helpText="Leading notes are bass note that the guitar plays just before a chord change which “lead your ear” to the next chord."
      >
        <div class="flex items-center gap-5 px-4 py-2">
          <div class="text-center text-muted-500">Never</div>
          <div class="flex-1">
            <PrettySlider
              min={0}
              max={4}
              step={1}
              value={4 * setting.frequency}
              thumbDiameter={1.5}
              tickHeight={0.175}
              tickCount={5}
              on:change={({ detail }) =>
                $bandMenu.guitar.setSetting(
                  'syncopatedLeadingNotes',
                  setting.withFrequency(detail / 4)
                )}
            />
          </div>
          <div class="text-center text-muted-500">Often</div>
        </div>
      </PanelSettingSection>

      {#if $bandMenu.guitarSettings.bassRuns.eagerness > 0.5 && $bandMenu.guitarSettings.bassRuns.eagerness + setting.frequency > 1}
        <div class="px-4 pt-2 text-sm italic text-gray-500" transition:slideFade>
          Bass notes take precidence over leading notes. Since your bass runs setting is turned up,
          you may not hear as many leading notes.
        </div>
      {/if}

      {#if $bandMenu.guitarSettings.bassRuns.majorFeel == 'b' && $bandMenu.guitarSettings.bassRuns.eagerness > 0 && setting.frequency > 0}
        <div class="px-4 pt-2 text-sm italic text-gray-500" transition:slideFade>
          You’ve selected “bluesy” bass runs. Leading notes tend to sound more “major” than you
          might want in a bluesy song, though not always.
        </div>
      {/if}
    </div>
  {/snippet}
</PanelModalLayout>
