import { footSamplePermutationCounts } from '@/band/instruments/feet/footSamplePermutationCounts';
import type { PlayerInstruction } from '@/band/instruments/PlayerInstruction';

let lastRandInt = 0;
export function randIntTo(max: number) {
  let result: number;
  do {
    result = Math.ceil(Math.random() * max);
  } while (result == lastRandInt);
  lastRandInt = result;
  return result;
}

export function tap(
  sampleType: 'flam' | 'bigflam-01' | 'bigflam-02' | 'toeR' | 'toeL' | 'stomp',
  db: number,
  beatOffset?: number
): PlayerInstruction {
  return {
    type: 'play',
    channel: 'feet',
    sample:
      sampleType +
      (sampleType.includes('-') ? '' : `-0${randIntTo(footSamplePermutationCounts[sampleType])}`),
    db: db + 7,
    beatOffset,
  };
}
