export function setLocalStorageSafe(key: string, value: string): void {
  try {
    window.localStorage.setItem(key, value);
  } catch (e) {
    console.error(e);
  }
}

export function getLocalStorageSafe(key: string): string | null | undefined {
  try {
    return window.localStorage.getItem(key);
  } catch (e) {
    console.error(e);
    return undefined;
  }
}
