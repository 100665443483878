<script lang="ts">
  interface Props {
    volume: number;
    overridden?: boolean;
    inButton?: boolean;
    indicator?: 'bar' | 'percent';
  }

  let { volume, overridden = false, inButton = false, indicator = 'bar' }: Props = $props();
</script>

<div class="indicator" class:overridden class:inButton class:zeroVolume={volume === 0}>
  <svg style="width: 1em; height: 1em;">
    {#if volume > 0}
      <use xlink:href="#speaker-icon" />
    {:else}
      <use xlink:href="#speaker-muted-icon" />
    {/if}
  </svg>
  {#if indicator == 'percent'}
    <span class="percent">{(volume * 100).toFixed(0)}<span class="symbol">%</span></span>
  {:else if indicator == 'bar'}
    <span class="bar" style="--val: {volume.toFixed(3)}"></span>
  {/if}
</div>

<style>
  .indicator {
    display: inline-flex;
    align-items: center;

    > svg {
      color: hsl(206, 30%, 70%);
      transition: all 0.15s ease;
    }

    > .bar {
      display: inline-block;
      box-sizing: content-box;
      margin-left: 0.25rem;
      width: 24px;
      height: 9px;
      border: solid 1px hsl(206, 30%, 70%);
      border-radius: 1px;
      line-height: 0;
      background: linear-gradient(
        90deg,
        hsl(206, 30%, 75%) 0%,
        hsl(206, 30%, 75%) calc(5% + 94% * var(--val) - 1px),
        white calc(6% + 94% * var(--val) - 0px)
      );
      transition: all 0.15s ease;
    }

    > .percent {
      display: inline-block;
      box-sizing: content-box;
      margin-left: 0.25rem;
      width: 2.3em;
      font-size: 14px;
      color: var(--muted-400);
      line-height: 1;

      > .symbol {
        font-size: 10px;
      }
    }

    &.inButton > svg {
      color: var(--muted-400);
    }

    &.inButton > .bar {
      border-color: var(--primary-300);
      background: linear-gradient(
        90deg,
        hsl(206, 50%, 75%) 0%,
        hsl(206, 50%, 75%) calc(5% + 94% * var(--val) - 1px),
        white calc(6% + 94% * var(--val) - 1px)
      );
    }

    &.overridden {
      > svg {
        color: var(--primary-500);
      }
      > .bar {
        border-color: hsl(206, 75%, 45%);
        background: linear-gradient(
          90deg,
          hsl(206, 75%, 55%) 0%,
          hsl(206, 75%, 55%) calc(5% + 94% * var(--val) - 1px),
          white calc(6% + 94% * var(--val) - 0px)
        );
      }
      > .percent {
        color: var(--primary-500);
      }
    }

    &.zeroVolume {
      > .bar {
        border-color: hsl(0, 25%, 55%);
        background: linear-gradient(
          90deg,
          hsl(0, 25%, 75%) 0%,
          hsl(0, 25%, 75%) calc(5% + 94% * var(--val) - 1px),
          white calc(6% + 94% * var(--val) - 0px)
        );
      }
    }
  }
</style>
