<script lang="ts">
  import type { Cell } from '@/chart/Cell.svelte.ts';
  import type { Song } from '@/chart/Song';
  import { reactiveFocusClasses } from '@/chart/ui/ChartCellReactiveClasses';
  import { renderChordAsHtml } from '@/chart/ui/renderChordAsHtml';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import type { Chord } from '@/music/Chord';

  interface Props {
    song: Song;
    cell: Cell;
    chordSystem: 'letters' | 'numbers' | 'roman' | undefined;
  }

  let { song, cell, chordSystem }: Props = $props();

  const getChordArray = (cell: Cell) => {
    const chords: ({ chordHeard?: Chord; chordShown?: Chord; effect?: string } | undefined)[] =
      cell.subdividedBeats?.slice(0, cell.beatCount) ?? [cell];
    return chords;
  };

  let focusClasses: string = $state('');
  trackerEffect(() => (focusClasses = (reactiveFocusClasses({ cell, song }) || []).join(' ')));
</script>

<div class="ChartCell__magnifier split {focusClasses}">
  <span class="ChartCell__walls" aria-hidden="true">
    {#each getChordArray(cell) as obj, i (i)}
      <div
        class="ChartCell__beat {obj?.effect || ''}"
        data-beat={i}
        onpointerdown={() => {
          song.focus.setToSingleCell(cell, i);
        }}
      >
        {#if obj?.chordShown}
          <div class="flex items-center justify-center">
            {@html renderChordAsHtml(obj.chordShown, { system: chordSystem })}
          </div>
        {:else}
          <span class="chord empty">-</span>
        {/if}
      </div>
    {/each}
  </span>
</div>
