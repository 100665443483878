import { Tracker } from 'meteor/tracker';
import type { Song } from '@/chart/Song';
import type { Medley } from '@/medleys/Medley';

export class MedleySongArray extends Array<Song> {
  private medley: Medley;

  constructor(medley: Medley) {
    super();
    this.medley = medley;
  }

  reactiveDep = new Tracker.Dependency();
  reactive(): this {
    this.reactiveDep.depend();
    return this;
  }
  changed(): void {
    this.reactiveDep.changed();
  }

  insertBefore(index: number, song: Song): void {
    // this.medley.history.takeSnapshot();
    this.splice(index, 0, song);
    this.updateMeta();
  }

  append(song: Song): void {
    this.insertBefore(this.length, song);
    this.updateMeta();
  }

  delete(index: number): void {
    // this.medley.history.takeSnapshot();
    this.splice(index, 1);
    this.updateMeta();
  }

  move(index: number, direction: number): void {
    const up = direction === -1;
    if (index === (up ? 0 : this.length - 1)) return;
    // this.medley.history.takeSnapshot();
    const thisIndex = index;
    const swappedSongIndex = thisIndex + (up ? -1 : 1);
    this.splice(swappedSongIndex, 0, this.splice(thisIndex, 1)[0] as Song);
    this.updateMeta();
  }

  orderByIds(songIds: string[]) {
    this.sort((a: Song, b: Song) => {
      const aIndex = songIds.findIndex((id: string) => id == a._id);
      const bIndex = songIds.findIndex((id: string) => id == b._id);
      return aIndex > bIndex ? 1 : -1;
    });
    this.updateMeta();
  }

  updateMeta(): void {
    this.forEach((song, index) => {
      song.setIndex(index);
    });
    // Medley class watches this and persists changes
    this.reactiveDep.changed();
  }

  serialize(): SerializedMedleySong[] {
    return this.map((song) => {
      const prefs = song.medleyPrefs;
      const serializedBandSettings = song.band.serializeSettings();
      const preset = song.band.preset();
      const medleySong: SerializedMedleySong = {
        _id: song.id() as string,
        name: song.name(),
        userId: song.userId(),
        userFirst: song.userFirst(),
        userLast: song.userLast(),
        key: song.keyHeard(),
        updatedAt: song.updatedAt,
        band: Object.keys(serializedBandSettings).length > 0 ? serializedBandSettings : undefined,
        presetId: preset?.default ? undefined : preset?.id,
        reps: (prefs?.reps() || 1) > 1 ? prefs?.reps() : undefined,
        bpmX: (prefs?.bpmX() || 1) != 1 ? prefs?.bpmX() : undefined,
        skipIntros: prefs?.skipIntros(),
        skipOutros: prefs?.skipOutros(),
      };
      return JSON.parse(JSON.stringify(medleySong)) as SerializedMedleySong;
    });
  }
}
