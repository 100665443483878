import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { eventTracker } from '@/browser/analytics/eventTracker';
import defineTippyDropdown from '@/ui/dropdowns/defineTippyDropdown';
import { UserProfile } from '@/user/UserProfile';

Template.indexSearchBar.searchBoxFocused = new ReactiveVar(false);

defineTippyDropdown(Template.indexSearchBar, {
  id: 'az-menu',
});

Template.indexSearchBar.onCreated(() => {
  const instance = Template.instance();
  instance.searcher = Template.currentData().searcher;
});

Template.indexSearchBar.onRendered(() => {
  const instance = Template.instance();
  Meteor.defer(() => {
    instance.autorun(() => {
      var query = instance.searcher.queryText(); // not in "if" statement because it needs to be reactive
      if (!instance.typingQuery) {
        instance.$('.js-songSearchBoxInput').val(query);
      }
    });
  });
});

Template.indexSearchBar.helpers({
  autofocusOnDesktop() {
    const isMobile =
      /iP(hone|ad|od)|Android/i.test(navigator.userAgent) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    return Meteor.userId() && !isMobile ? { autofocus: true } : {};
  },
  ownSelected() {
    const instance = Template.instance();
    return instance.searcher.query()?.own;
  },
  azSelected() {
    const instance = Template.instance();
    var query = instance.searcher.query() || {};
    return query.all || query.allSongs || query.allMedleys || query.firstLetter || query.numbers;
  },
  showHints() {
    const instance = Template.instance();
    return Meteor.userId() && UserProfile.notMilestone('PLAY') && !instance.searcher.queryPresent();
  },
  showMineHint() {
    const instance = Template.instance();
    var oneWeekAgo = new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 7);
    return (
      Meteor.userId() &&
      UserProfile.hasMilestone('PLAY') &&
      UserProfile.notMilestone('NEW_SONG') &&
      UserProfile.notMilestone('MINE') &&
      Meteor.user().createdAt < oneWeekAgo &&
      !instance.searcher.queryPresent()
    );
  },
  showMine() {
    return Meteor.userId() && UserProfile.hasMilestone('PLAY');
  },
});

Template.indexSearchBar.events({
  'click .js-viewOwn': (event, instance) => {
    if (instance.searcher.query()?.own) {
      instance.searcher.setQuery(null);
    } else {
      instance.searcher.setQuery({ own: true });
    }
    eventTracker.indexMineList();
    UserProfile.recordMilestone('MINE');
  },

  'click .js-newSong': (event, instance) => {
    if (!UserProfile.hasMilestone('PLAY')) {
      bootbox.alert({
        message:
          'The “New Song” button lets you create a new song from scratch. We advise that you try playing one of the preloaded songs before making your own.',
      });
    } else {
      FlowRouter.go('new-song');
    }
  },

  'focus .js-songSearchBoxInput': (event, instance) => {
    Template.indexSearchBar.searchBoxFocused.set(true);
    if (instance.$('.js-songSearchBoxInput').val()) {
      // only select if placeholder is gone (iOS bug)
      instance.$('.js-songSearchBoxInput').select();
      instance.$('.js-songSearchBoxInput')[0].setSelectionRange(0, 9999);
    }
  },

  'blur .js-songSearchBoxInput': (event, instance) => {
    Template.indexSearchBar.searchBoxFocused.set(false);
  },

  'input .js-songSearchBoxInput': _.throttle(
    (event, instance) => {
      var query = instance.$('.js-songSearchBoxInput').val();
      instance.typingQuery = true;
      instance.searcher.setQuery(query);
      Meteor.defer(() => {
        instance.typingQuery = false;
      });
    },
    200,
    { leading: false }
  ),

  'keydown form': (event, instance) => {
    if (Template.appContainer.activeScreen() !== 'index') return;
    if (event.key == ' ' || event.key == '/') {
      if (!instance.$('.js-songSearchBoxInput').val()) {
        event.preventDefault();
      }
    }
    if (event.key == 'Escape') {
      instance.$('.js-clear').click();
    }
  },

  'submit form': (event, instance) => {
    instance.$('.js-songSearchBoxInput').blur();
    event.preventDefault();
  },
});
