import * as Sentry from '@sentry/browser';
import { Meteor } from 'meteor/meteor';
import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { rpcCalculateProration } from '@/server/methods/accounts/rpcCalculateProration';
import { rpcDeleteUserAccount } from '@/server/methods/accounts/rpcDeleteUserAccount';
import { rpcGetUserPaymentSource } from '@/server/methods/accounts/rpcGetUserPaymentSource';
import { formatCurrencyAmount } from '@/utilities/formatCurrencyAmount';
import waitUntilReactive from '@/utilities/waitUntilReactive';

Template.deleteAccountPage.onCreated(() => {
  const instance = Template.instance();

  if (!Meteor.userId()) {
    FlowRouter.go('song-index');
  }

  instance.card = new ReactiveVar();
  instance.refundAmount = new ReactiveVar();
  instance.refundFormatted = new ReactiveVar();
  instance.refundChoice = new ReactiveVar();
  instance.submitting = new ReactiveVar();

  waitUntilReactive(() => Meteor.user()?.roles && Meteor.user()?.subscription).then(async () => {
    if (Meteor.user('subscription').subscription?.status == 'trialing') {
      instance.refundAmount.set(0);
      instance.refundChoice.set('use-up');
    } else {
      try {
        const card = await rpcGetUserPaymentSource({ userId: Meteor.userId() });
        instance.card.set(card);
        const { amount, currency } = await rpcCalculateProration();
        instance.refundAmount.set(amount);
        if (amount > 0) {
          instance.refundFormatted.set(formatCurrencyAmount(currency, amount));
        } else {
          instance.refundChoice.set('refund');
        }
      } catch (err) {
        abortForm(err);
      }
    }
  });

  instance.data.setTitle('');
});

Template.deleteAccountPage.helpers({
  loading() {
    return !(
      Meteor.user('subscription')?.subscription &&
      typeof Template.instance().refundAmount.get() == 'number'
    );
  },

  showRefundChoice() {
    return Meteor.user('subscription')?.subscription?.status == 'active';
  },

  setRefundChoice() {
    const instance = Template.instance();
    return (choice) => {
      instance.refundChoice.set(choice);
    };
  },

  refundFormatted() {
    return Template.instance().refundFormatted.get();
  },

  card() {
    return Template.instance().card.get();
  },

  readyToDelete() {
    return !!Template.instance().refundChoice.get();
  },

  email() {
    return Meteor.user().emails[0].address;
  },

  submitting() {
    return Template.instance().submitting.get();
  },
});

Template.deleteAccountPage.events({
  'submit form': async function (event, instance) {
    event.preventDefault();
    instance.submitting.set(true);
    const user = Meteor.user();
    const name = `${user?.profile.firstName} ${user?.profile.lastName}`.trim();
    const email = user?.emails[0]?.address;
    try {
      await rpcDeleteUserAccount({
        expectedRefund: instance.refundAmount.get(),
        refundChoice: instance.refundChoice.get(),
        // deleteSongs: false,
        // deleteLists: false,
        // deleteSUA: false,
      });
      FlowRouter.go(
        'account-deleted',
        {},
        {
          name,
          email,
          refund:
            instance.refundChoice.get() == 'refund' && instance.refundAmount.get() > 0
              ? instance.refundFormatted.get()
              : undefined,
          donated: instance.refundChoice.get() == 'donate' ? 1 : undefined,
        }
      );
    } catch (err) {
      abortForm(err);
    }
  },
});

const abortForm = (err) => {
  Sentry.captureException(err);
  console.error(err);
  Meteor.setTimeout(
    () =>
      bootbox.alert(
        `Sorry, something went wrong with the self-serve account-deletion form. Please <b><a href="mailto:support@strummachine.com?subject=Delete%20my%20account&amp;body=User%20ID%3A%20${Meteor.user()._id}" target="_blank">email us</a></b> and we’ll cancel your subscription manually.`
      ),
    400
  );
  FlowRouter.go('/app/account');
};
