import type { CircularStrumLandscape } from '@/band/instruments/CircularStrumLandscape';

export const syncopatedPatternLandscape: CircularStrumLandscape<EightNumbers> = {
  centerPowers: [0.8, -0.4, 0.6, 0.8, -0.5, 0.7, 0.8, 0.7],

  poles: [
    {
      theta: 0.0, // E (chucks)
      fullPowers: [0.5, 0.0, 0.3, 1.0, 0.0, 0.1, 0.7, 0.2],
    },
    {
      theta: 0.125, // SE
      halfPowers: [0.7, 0.0, 0.3, 1.0, 0.0, 0.4, 0.75, 0.1],
      fullPowers: [0.6, 0.0, 0.0, 1.0, 0.0, 0.0, 0.7, 0.0],
    },
    {
      theta: 0.25, // S (spacious)
      fullPowers: [0.8, 0.0, -0.1, 0.9, 0.0, 0.0, 0.9, -0.1],
    },
    {
      theta: 0.375, // SW
      fullPowers: [0.9, 0.0, 0.0, 0.6, 0.0, 0.1, 1.0, 0.1],
    },
    {
      theta: 0.5, // W (booms)
      fullPowers: [1.0, 0.0, 0.1, 0.3, 0.0, 0.2, 1.0, 0.2],
    },
    {
      theta: 0.625, // NW
      fullPowers: [1.0, 0.1, 0.3, 0.5, 0.0, 0.3, 1.0, 0.7],
    },
    {
      theta: 0.75, // N (full)
      fullPowers: [1.0, 1.0, 1.0, 1.0, 0.1, 1.0, 1.0, 1.0],
    },
    {
      theta: 0.875, // NE
      fullPowers: [0.7, 0.1, 0.3, 1.0, 0.0, 0.2, 0.7, 0.4],
    },
  ],

  presets: [
    {
      name: 'Balanced',
      position: [0.0, 0.0],
    },
    {
      name: 'Downstroke-heavy',
      position: [0.75, 0.5],
    },
    {
      name: 'Busy & downstroke-heavy',
      position: [0.75, 0.625],
    },
    {
      name: 'Busy',
      position: [0.75, 0.75],
    },
    {
      name: 'Busy & upstroke-heavy',
      position: [0.75, 0.875],
    },
    {
      name: 'Upstroke-heavy',
      position: [0.75, 0.0],
    },
    {
      name: 'Spacious & upstroke-heavy',
      position: [0.75, 0.125],
    },
    {
      name: 'Spacious',
      position: [0.75, 0.25],
    },
    {
      name: 'Spacious & down-heavy',
      position: [0.75, 0.375],
    },
  ],
};
