import { standardChopSplitCell } from '@/band/instruments/mandolin/blocks/standardChopSplitCell';
import { downstroke } from '@/band/instruments/mandolin/MandolinActions';
import type { MandolinIntent } from '@/band/instruments/mandolin/MandolinIntent';
import type { LinearizedMeasure } from '@/chart/LinearizedMeasure';

export function endingStandardChopMeasure(
  measure: LinearizedMeasure,
  _options: Record<string, unknown>
): (MandolinIntent | null)[] {
  const firstCell = measure.cells[0];
  if (!firstCell) return [];
  return [
    ...(firstCell.split
      ? standardChopSplitCell(firstCell, {})
      : [
          downstroke({
            db: -3,
            velocity: 3,
            chordSpread: 1.7,
            chordDuration: 0.03,
          }),
          null,
          null,
          null,
          ...(measure.timeSignature == '3/4' ? [null, null] : []),
        ]),
    downstroke({
      db: 0,
      velocity: 3,
      chordSpread: 4,
    }),
  ];
}
