import { Meteor } from 'meteor/meteor';
import type { Mongo } from 'meteor/mongo';
import { ReactiveVar } from 'meteor/reactive-var';
import { Tracker } from 'meteor/tracker';
import type { MedleyRecord } from '@/collections/MedleysCollection';
import type { SongRecord } from '@/collections/SongsCollection';
import { makeSongNameForSorting } from '@/library/makeSongNameForSorting';
import { stringToLowercaseAlpha } from '@/utilities/stringToLowercaseAlpha';

interface SearchIndexRecord {
  _id: string;
  sn: string; // search name
  sn4s: string; // search name for sorting
  o?: boolean; // owned by user
  doc: SearchResultRecord;
}

// this is somewhat related to musicLibraryIndexFieldsSelector
export interface SearchResultRecord {
  _id: string;
  name: string;
  userId?: string;
  userFirst?: string;
  userLast?: string;
  medley?: boolean;
  medleySongIds?: string[];
  unsynced?: boolean | string;
  listCount?: number;
}

export class MusicLibrarySearchIndex {
  private readonly indexArray: SearchIndexRecord[] = [];
  private _indexDep = new Tracker.Dependency();
  private _indexBuilt = new ReactiveVar(false);
  private _ownSongCount = new ReactiveVar(0);
  private _ownMedleyCount = new ReactiveVar(0);
  private _medleyCount = new ReactiveVar(0);

  private _collectionsToObserve: Mongo.Collection<SongRecord | MedleyRecord>[] = [];
  private _observeHandles: Meteor.LiveQueryHandle[] = [];

  private _buildingStarted = false;
  initIndex(): void {
    if (this._buildingStarted) return;
    this._buildingStarted = true;
    Tracker.autorun(() => {
      Meteor.userId(); // regenerate array when user changes
      this.indexArray.length = 0; // empty the array
      this._observeHandles.forEach((handle) => handle.stop());
      this._observeHandles.length = 0;
      this._collectionsToObserve.forEach((collection) => {
        this._observeHandles.push(
          collection.find().observe({
            added: this.insertSearchRecord.bind(this),
            changed: this.insertAndRemoveSearchRecord.bind(this),
            removed: this.removeSearchRecord.bind(this),
          })
        );
      });
    });
    this._indexBuilt.set(true);
  }

  addSources(...collections: Mongo.Collection<SongRecord | MedleyRecord>[]): void {
    this._collectionsToObserve.push(...collections);
  }

  watchForUpdates(): void {
    this._indexDep.depend();
  }

  search({
    nameRegex,
    firstLetter,
    own,
    medley,
  }: {
    nameRegex?: RegExp;
    firstLetter?: string;
    own?: boolean;
    medley?: boolean;
  }): SearchResultRecord[] {
    return this.indexArray.reduce((results: SearchResultRecord[], item) => {
      const passesOwnCheck = typeof own !== 'boolean' || own == item.o;
      const passesMedleyCheck = typeof medley !== 'boolean' || medley == !!item.doc.medley;
      const passesFirstLetterCheck =
        !firstLetter || stringToLowercaseAlpha(firstLetter) == item.sn4s[0];
      const passesNameCheck = !nameRegex || nameRegex.test(item.sn);
      if (passesOwnCheck && passesMedleyCheck && passesNameCheck && passesFirstLetterCheck)
        results.push(item.doc);
      return results;
    }, []);
  }

  getIndexRecord(id: string): SearchResultRecord | undefined {
    return this.indexArray.find((r) => r._id == id)?.doc;
  }

  medleysWithSong(songId: string): SearchResultRecord[] {
    this._indexDep.depend();
    if (!songId) return [];
    return this.indexArray.filter((r) => r.doc.medleySongIds?.includes(songId)).map((r) => r.doc);
  }

  readyToSearch(): boolean {
    return this._indexBuilt.get();
  }

  medleyCount(): number {
    return this._medleyCount.get();
  }

  ownMedleyCount(): number {
    return this._ownMedleyCount.get();
  }

  ownSongCount(): number {
    return this._ownSongCount.get();
  }

  private generateEmbeddedSearchRecord(doc: SongRecord | MedleyRecord): SearchResultRecord {
    const result: SearchResultRecord = {
      _id: doc._id,
      name: doc.name,
    };
    if (doc.userId) {
      result.userId = doc.userId;
      result.userFirst = doc.userFirst;
      result.userLast = doc.userLast;
    }
    if ('medleySongs' in doc) {
      result.medley = true;
      result.medleySongIds = doc.medleySongs.map((ms: { _id: string }) => ms._id);
    }
    if (doc.unsynced) {
      result.unsynced = doc.unsynced;
    }
    return result;
  }

  private insertSearchRecord(doc: SongRecord | MedleyRecord): void {
    const searchName = stringToLowercaseAlpha(doc.name.replace(/&/, ' and '));
    const record: SearchIndexRecord = {
      _id: doc._id,
      // if you want to not match [bracketed labels], use this:
      //   doc.name.replace(/ +\[.+\]$/, '')
      sn: searchName,
      sn4s: makeSongNameForSorting(doc.name),
      doc: this.generateEmbeddedSearchRecord(doc),
    };
    if ('medleySongs' in doc) {
      this._medleyCount.set(Tracker.nonreactive(() => this._medleyCount.get()) + 1);
    }
    if (Meteor.userId() && doc.userId === Meteor.userId()) {
      record.o = true;
      if ('medleySongs' in doc) {
        this._ownMedleyCount.set(Tracker.nonreactive(() => this._ownMedleyCount.get()) + 1);
      } else {
        this._ownSongCount.set(Tracker.nonreactive(() => this._ownSongCount.get()) + 1);
      }
    }
    for (let i = 0, len = this.indexArray.length; i < len; i++) {
      if (record._id == this.indexArray[i]?._id) {
        // for some inexplicable reason, songs on shared lists are
        // getting added twice. This prevents duplicates from showing up.
        return;
      }
      if (record.sn4s < (this.indexArray[i]?.sn4s ?? '')) {
        this.indexArray.splice(i, 0, record);
        this._indexDep.changed();
        return;
      }
    }
    this.indexArray.push(record); // if got to end of array
    this._indexDep.changed();
  }

  _insertSearchRecord = this.insertSearchRecord; // for god mode

  private removeSearchRecord({ _id }: { _id: string }) {
    const existingIndex = this.indexArray.findIndex((item) => item._id === _id);
    if (existingIndex != -1) {
      const removed = this.indexArray.splice(existingIndex, 1);
      if (removed[0]?.doc.medley) {
        this._medleyCount.set(Tracker.nonreactive(() => this._medleyCount.get()) - 1);
      }
      if (removed[0]?.o) {
        if (removed[0]?.doc.medley) {
          this._ownMedleyCount.set(Tracker.nonreactive(() => this._ownMedleyCount.get()) - 1);
        } else {
          this._ownSongCount.set(Tracker.nonreactive(() => this._ownSongCount.get()) - 1);
        }
      }
    }
    this._indexDep.changed();
  }

  private insertAndRemoveSearchRecord(
    newDoc: SongRecord | MedleyRecord,
    oldDoc: SongRecord | MedleyRecord
  ): void {
    if (oldDoc.name !== newDoc.name || oldDoc.userFirst !== newDoc.userFirst) {
      this.removeSearchRecord(oldDoc);
      this.insertSearchRecord(newDoc);
    }
  }
}
