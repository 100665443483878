<script lang="ts">
  import { Tracker } from 'meteor/tracker';
  import { onMount } from 'svelte';
  import type { SlideParams, TransitionConfig } from 'svelte/transition';
  import { slide } from 'svelte/transition';
  import type { Section } from '@/chart/Section.svelte';
  import type { Song } from '@/chart/Song';
  import SongSection from '@/chart/ui/SongSection.svelte';
  import { crntPreferences } from '@/client/crntPreferences.svelte';
  import { crntSong } from '@/client/crntSong.svelte';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import { autoScrollCellFocusWatcher } from '@/ui/auto-scroll/autoScrollCellFocusWatcher';
  import { autoScrollCellPlaybackWatcher } from '@/ui/auto-scroll/autoScrollCellPlaybackWatcher';
  import { MusicPageScroller } from '@/ui/auto-scroll/MusicPageScroller';
  import { slideFade } from '@/ui/svelte-transitions';
  import wait from '@/utilities/wait';

  interface Props {
    song: Song;
  }

  let { song }: Props = $props();

  let { chordSystem, chordRepeatedColor, chordRepeatedSlash, chordRepeatedOnePerBar } =
    $derived(crntPreferences());
  let timeSig = $state() as TimeSignature;
  trackerEffect(() => (timeSig = song.timeSignature()));

  let cpl: number = $state(8);
  trackerEffect(() => (cpl = song.cpl()));

  // Style classes computation
  let stylingClasses = $derived.by(() => {
    const classes = [];
    classes.push('chordSystem-' + chordSystem);
    classes.push('chordRepeatedColor-' + chordRepeatedColor);
    if (chordRepeatedSlash) classes.push('chordRepeatedSlash');
    if (chordRepeatedOnePerBar) classes.push('chordRepeatedOnePerBar');
    return classes.join(' ');
  });

  let timeSigClass = $derived(
    timeSig == '3/4'
      ? 'timeSigThreeFour'
      : timeSig == '6/8'
        ? 'timeSigSixEight'
        : timeSig == '9/8'
          ? 'timeSigNineEight'
          : 'timeSigFourFour'
  );
  let cplClass = $derived(`cpl-${cpl}`);

  let looping = $state(false);
  trackerEffect(() => {
    looping = song.loop.spansMultipleCells() && !song.dragSelection.spansMultipleCells;
  });

  let editMode = $derived(!!crntSong()?.editMode);
  let beenInEditModeForAwhile = $state(false);

  // Set up edit mode timer
  $effect(() => {
    if (editMode) {
      void wait(500).then(() => (beenInEditModeForAwhile = true));
    } else {
      beenInEditModeForAwhile = false;
    }
  });

  onMount(() => {
    MusicPageScroller.attach();
    return () => MusicPageScroller.detach();
  });

  $effect(() => {
    const computations = [
      Tracker.autorun(autoScrollCellPlaybackWatcher),
      Tracker.autorun(autoScrollCellFocusWatcher),
    ];
    return () => computations.forEach((c) => c.stop());
  });

  function handleClick(event: MouseEvent) {
    if (
      song.loop.spansMultipleCells() &&
      !song.loopJustSet &&
      !(event.target as HTMLElement).closest('.ChartCell')
    ) {
      song.loop.reset();
    }

    if (
      song.focus.spansMultipleCells() &&
      !song.focusJustSet &&
      !(event.target as HTMLElement).closest('.ChartCell')
    ) {
      song.focus.setToSingleCell(song.focus.lastCell());
    }
  }

  let sections: Section[] = $state([]);
  trackerEffect(() => (sections = [...song.sections.reactive()]));

  const sectionIn = (node: HTMLElement, params?: SlideParams) =>
    beenInEditModeForAwhile ? slide(node, params) : (): TransitionConfig => ({});

  const sectionOut = (node: HTMLElement, params?: SlideParams) =>
    beenInEditModeForAwhile ? slideFade(node, params) : (): TransitionConfig => ({});
</script>

<div class="songProgression {stylingClasses} {timeSigClass} {cplClass} notranslate">
  <!-- svelte-ignore a11y_click_events_have_key_events -->
  <!-- svelte-ignore a11y_no_noninteractive_element_interactions -->
  <div
    class="js-global-songSections select-none"
    class:looping
    role="region"
    aria-roledescription="chart"
    onclick={handleClick}
  >
    {#each sections as section, i (section._id)}
      <div in:sectionIn out:sectionOut>
        <SongSection {section} sectionIndex={i} {song} {editMode} />
      </div>
    {/each}
  </div>
</div>

<style>
  :root {
    --chord-font-size: min(38px, calc(6px + 4vw));
    --song-progression-max-width: 720px;
  }

  .songProgression {
    container: song-section / inline-size;
    max-width: var(--song-progression-max-width);
    min-height: calc(2 * var(--chord-font-size) + 6em);
    margin: 1.75em auto 0;

    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }

  @container song-section (min-width: 0px) {
    /* not :root because that doesn't work with container queries */
    * {
      --chord-font-size: min(38px, calc(14px + 3.25cqw));
    }
  }
</style>
