<script lang="ts">
  import HorizontalRadioButtons from '@/band/ui/components/HorizontalRadioButtons.svelte';
  import PanelOpenerChevron from '@/band/ui/components/PanelOpenerChevron.svelte';
  import PanelSettingSection from '@/band/ui/components/PanelSettingSection.svelte';
  import PanelModalLayout from '@/band/ui/panels/PanelModalLayout.svelte';
  import SwingPanel from '@/band/ui/panels/SwingPanel.svelte';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import { MultiPanelModalController } from '@/ui/settings/MultiPanelModalController.svelte';
  import { slideFade } from '@/ui/svelte-transitions';

  const modalController = MultiPanelModalController.current();
  const bandMenu = getBandMenuState();

  let setting = $derived($bandMenu.guitarSettings.dampenedUpstrokePattern);

  let swing: ReturnType<typeof $bandMenu.band.swing> = $state() as SwingOption;
  trackerEffect(() => (swing = $bandMenu.band.swing()));

  function setPattern(value: 'n' | 'm' | 'lp') {
    $bandMenu.guitar.setSetting('dampenedUpstrokePattern', setting.withUpstrokePattern(value));
  }

  const patternOptions = [
    { caption: 'None', value: 'n' },
    { caption: 'Muted', value: 'm' },
    { caption: 'La Pompe', value: 'lp' },
  ] as const;
</script>

<PanelModalLayout>
  {#snippet header()}
    <header class="panelHeader">
      These regular, repeating upstroke patterns sound good in certain situations.
    </header>
  {/snippet}

  {#snippet main()}
    <div class="p-4">
      <PanelSettingSection title="Repeating upstroke pattern" whiteBg={false}>
        <HorizontalRadioButtons
          options={patternOptions}
          selectedValue={setting.pattern}
          on:select={({ detail: value }) => setPattern(value)}
        />

        {#if setting.pattern !== 'n'}
          <div class="mx-4 mt-2 text-sm italic text-gray-500" transition:slideFade>
            <div class="min-h-[4.3em]">
              {#if setting.pattern == 'm'}
                The pick will drag up across the muted strings between every downstroke. Used mostly
                for slower ballads with strums on every beat.
              {/if}
              {#if setting.pattern == 'lp'}
                Quick upstroke before beats 1 and 3, as found in gypsy jazz.
              {/if}
            </div>
          </div>
        {/if}
      </PanelSettingSection>

      {#if setting.pattern !== 'n'}
        <div class="pt-4" transition:slideFade>
          <PanelSettingSection
            title="Upstroke timing"
            helpText="Upstrokes will be played with the specified amount of swing feel."
            whiteBg={false}
          >
            <div style="border-radius: 6px; border: solid 1px var(--gray-150); overflow: hidden;">
              <button
                class="colors-panelOpener relative block w-full py-2.5 pl-3.5 pr-9 text-left group-first:rounded-t-lg group-last:rounded-b-lg"
                onclick={() =>
                  modalController.zoomInto({ component: SwingPanel, title: 'Upstroke Swing' })}
              >
                {swing.name}
                <PanelOpenerChevron direction="right" />
              </button>
            </div>
          </PanelSettingSection>
        </div>
      {/if}
    </div>
  {/snippet}
</PanelModalLayout>
