import { strict as assert } from 'assert';
import { Meteor } from 'meteor/meteor';
import type { BandPreset } from '@/band/presets/BandPreset';
import type { BuiltInPresetDefinition } from '@/band/presets/BuiltInPresetDefinition';
import { bluegrassPresetDefinitions } from '@/band/presets/definitions/bluegrassPresetDefinitions';
import { bluesPresetDefinitions } from '@/band/presets/definitions/bluesPresetDefinitions';
import { celticPresetDefinitions } from '@/band/presets/definitions/celticPresetDefinitions';
import { countryPresetDefinitions } from '@/band/presets/definitions/countryPresetDefinitions';
import { jazzSwingPresetDefinitions } from '@/band/presets/definitions/jazzSwingPresetDefinitions';
import { oldTimePresetDefinitions } from '@/band/presets/definitions/oldTimePresetDefinitions';
import { otherTraditionalPresetDefinitions } from '@/band/presets/definitions/otherTraditionalPresetDefinitions';
import { legacyPresetsById } from '@/band/presets/legacyPresetsById';

function presetGeneratorForTimeSignature(timeSignature: TimeSignature) {
  return (presetDefinition: BuiltInPresetDefinition) => {
    return Object.freeze({
      ...presetDefinition,
      builtIn: true,
      timeSignature: timeSignature,
    }) as BandPreset;
  };
}

const arrayOfDefinitionObjects = [
  bluegrassPresetDefinitions,
  oldTimePresetDefinitions,
  countryPresetDefinitions,
  bluesPresetDefinitions,
  jazzSwingPresetDefinitions,
  celticPresetDefinitions,
  otherTraditionalPresetDefinitions,
];

export const builtInPresetsArrayByMeter = Object.freeze(
  arrayOfDefinitionObjects.reduce<Record<TimeSignature, BandPreset[]>>(
    (map, defsByTimeSig) => {
      (['4/4', '3/4', '6/8', '9/8'] as TimeSignature[]).forEach((timeSignature) => {
        const defs = defsByTimeSig[timeSignature];
        if (!defs) return;
        const presets = defs.map(presetGeneratorForTimeSignature(timeSignature));
        (map[timeSignature] ??= []).push(...presets);
      });
      return map;
    },
    {
      '4/4': [],
      '3/4': [],
      '6/8': [],
      '9/8': [],
    }
  )
);

if (Meteor.isDevelopment) {
  // Validate built-in presets to ensure there are no duplicate ids
  const defIds = new Set();
  arrayOfDefinitionObjects.forEach((defsByTimeSig) => {
    Object.values(defsByTimeSig).forEach((defs) => {
      defs.forEach((def) => {
        assert(!defIds.has(def.id), `Duplicate preset id: ${def.id}`);
        defIds.add(def.id);
      });
    });
  });

  // Validate that all legacy presets match with a built-in preset
  Object.values(legacyPresetsById).forEach(({ newPresetId }) => {
    assert(defIds.has(newPresetId), `Missing built-in preset for legacy preset: ${newPresetId}`);
  });
}
