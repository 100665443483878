import * as Sentry from '@sentry/browser';
import { ReactiveVar } from 'meteor/reactive-var';
import type { ChannelDefinition } from '@/audio/engine/AudioManager';
import { FMBCordovaPlugin } from '@/audio/engine/fmb/FMBCordovaPlugin';

declare const window: {
  cordova?: {
    plugins?: {
      SMFMB?: {
        apiVersion: number;
      };
    };
  };
};

const minApiVersion = 5;

const reactiveAvailable = new ReactiveVar<boolean | undefined>(undefined);

const availablePromise = new Promise<boolean>((resolve) => {
  document.addEventListener('deviceready', () => {
    const apiVersion = window.cordova?.plugins?.SMFMB?.apiVersion;
    const available = !!apiVersion && apiVersion >= minApiVersion;
    reactiveAvailable.set(available);
    resolve(available);
  });
});

export class FMBSystem {
  static available(): boolean | undefined {
    return reactiveAvailable.get();
  }

  readonly availablePromise = availablePromise;
  static readonly availablePromise = availablePromise;

  initialize(args: { channels: ChannelDefinition[] }) {
    return new Promise((resolve, reject) => {
      const apiVersion = window.cordova?.plugins?.SMFMB?.apiVersion;
      if (!apiVersion || apiVersion < minApiVersion) reject();
      Sentry.addBreadcrumb({
        category: 'audio',
        message: `Initializing FMOD Cordova plugin with API version ${apiVersion ?? '_'}`,
      });
      FMBCordovaPlugin.setup(args)
        .then(resolve)
        .catch((err) => {
          Sentry.captureException(err);
          reject();
        });
    });
  }

  static get canTearDown(): boolean {
    const apiVersion = (window as any).cordova?.plugins?.SMFMB?.apiVersion;
    return apiVersion >= 8;
  }

  async teardown(): Promise<void> {
    await FMBCordovaPlugin.teardown();
  }

  async startEngine(): Promise<void> {
    Sentry.addBreadcrumb({ category: 'audio', message: `FMB engine started`, level: 'debug' });
    await FMBCordovaPlugin.startEngine();
  }

  async stopEngine(): Promise<void> {
    Sentry.addBreadcrumb({ category: 'audio', message: `FMB engine stopped`, level: 'debug' });
    await FMBCordovaPlugin.stopEngine();
  }

  get currentTime(): number {
    return performance.now() / 1000;
  }
}
