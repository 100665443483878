import type { SampleDefinition } from '@/audio/engine/base/Sample';
import { SampleLibrary } from '@/audio/engine/base/SampleLibrary';
import { WebAudioSample } from '@/audio/engine/web/WebAudioSample';
import AudioPackageLoader from '@/audio/samples/AudioPackageLoader';

export class WebAudioSampleLibrary extends SampleLibrary {
  async prepare(sampleDefinitions: SampleDefinition[]): Promise<void> {
    await Promise.all(
      sampleDefinitions.map(async (sampleDefinition) => {
        const duration = await AudioPackageLoader.getDurationOfSample(sampleDefinition.id);
        sampleDefinition.duration = duration;
        const sample = new WebAudioSample(sampleDefinition);
        this.sampleMap.set(sample.id, sample);
      })
    );
    // not much to do in Web Aduio
  }
}
