<script lang="ts" generics="OptionValue">
  import type { Snippet } from 'svelte';
  import { createEventDispatcher } from 'svelte';
  import { quintOut } from 'svelte/easing';
  import { crossfade } from 'svelte/transition';
  import { quickRandomId } from '@/utilities/quickRandomId';

  type Option = Readonly<{
    caption: string;
    meta?: any;
    value: OptionValue;
  }>;

  interface Props {
    options?: Readonly<Option[]>;
    selectedValue?: OptionValue;
    noBackground?: boolean;
    juicyShadows?: boolean;
    children?: Snippet<[{ option: Option; selected: boolean }]>;
  }

  let {
    options = [],
    selectedValue = $bindable(),
    noBackground = false,
    juicyShadows = false,
    children,
  }: Props = $props();

  const dispatch = createEventDispatcher<{ select: OptionValue }>();

  const randomId = quickRandomId();

  const [send, receive] = crossfade({
    duration: 400,
    easing: quintOut,
  });

  const key = quickRandomId();
</script>

<fieldset
  class="flex gap-x-1 {noBackground ? '' : 'rounded-lg p-1 pb-[5px]'}"
  class:fieldsetBackground={!noBackground && !juicyShadows}
  class:juicyShadows
>
  {#each options as option (option.value)}
    {@const selected = selectedValue == option.value}
    <div class="relative w-full flex-grow basis-1/3">
      {#if selected}
        <div
          in:send={{ key }}
          out:receive={{ key }}
          class="checkedShadow absolute inset-0 rounded bg-white will-change-transform"
        ></div>
      {/if}
      <input
        id={`${randomId}_${option.value}`}
        value={option.value}
        class="peer absolute h-0 w-0 opacity-0"
        type="radio"
        bind:group={selectedValue}
        name={`${randomId}_${option.value}`}
        onchange={() => dispatch('select', option.value)}
      />
      <label
        for={`${randomId}_${option.value}`}
        class="btn-base relative flex w-full items-center justify-center rounded px-2 py-1 text-xs font-bold uppercase tracking-wide transition-colors duration-200 will-change-transform
        {selected ? 'text-primary-600 duration-75' : 'text-gray-400'}
        {selected ? '' : noBackground ? 'hover:bg-primary-100' : 'hover:text-primary-500'}"
      >
        {#if children}
          {@render children?.({ option, selected })}
        {:else}
          {option.caption}
        {/if}
      </label>
    </div>
  {/each}
</fieldset>

<style>
  .fieldsetBackground {
    background-color: #e9ebed;
  }

  .checkedShadow {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12);
  }
</style>
