import { endingStandardChopMeasure } from '@/band/instruments/mandolin/blocks/endingStandardChopMeasure';
import { chop } from '@/band/instruments/mandolin/MandolinActions';
import type { MandolinCore } from '@/band/instruments/mandolin/MandolinCore';
import { swingOptions } from '@/band/swingOptions';

export const HalfTimeChopMandolinCore: MandolinCore = {
  id: 'htc',
  title: 'Half-time chop',

  timeSignatures: ['4/4'],

  linkedSettingsKeys: ['chopDynamics' /*'chopAccents'*/],
  otherSettingsKeys: ['timing'],

  chordStyle: 'bluegrass',

  swingCategory: 'pop',
  defaultSwing: swingOptions['3:2'],

  presets: [
    {
      name: 'Basic half-time chop',
      settings: {
        'chopDynamics': { i: 1, s: 0 },
      },
    },
  ],

  measurePreprocessing(measures, settings, _flags) {
    // assignMandolinRhythmAccents({ measures, settings });
  },

  processMeasure(measure, settings) {
    if (measure.endOfSong) {
      return endingStandardChopMeasure(measure, {});
    }

    // const [firstCell, secondCell] = measure.cells;

    const intensity = settings.chopDynamics.intensityIndex;
    const chopSustain = settings.chopDynamics.sustainIndex;
    return [
      null,
      null,
      null,
      null,
      chop({
        db: [1, 0, -4][chopSustain] as number,
        attack: ['soft', 'normal', 'hard'][intensity] as 'soft' | 'normal' | 'hard',
        chordSpread:
          0.75 * Math.random() +
          ([1.5, 2.25, 3.2] as const)[intensity] +
          ([0, -0.25, 0.5] as const)[chopSustain],
        chordDuration: [0, 0.03, 0.2][chopSustain] as number,
        chordAttenuation: ([-3, -1, 0] as const)[intensity] + ([-9, -4, 4] as const)[chopSustain],
      }),
      null,
      null,
      null,
    ];
  },

  generateViz(settings) {
    const chick = ([true, 'partial', false] as const)[settings.chopDynamics.sustainIndex];
    const intensity = settings.chopDynamics.intensityIndex;
    const chopThickness = ([0.65, 0.8, 0.95] as const)[intensity];
    const chopTail = ([0.6, 0.8, 1.0] as const)[intensity];

    return [
      null,
      null,
      null,
      null,
      {
        dir: 'd',
        biasY: 0.3,
        tail: chopTail,
        thickness: chopThickness,
        sustain: 0,
        chick: chick,
      },
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      {
        dir: 'd',
        biasY: 0.3,
        tail: chopTail,
        thickness: chopThickness,
        sustain: 0,
        chick: chick,
      },
      null,
      null,
      null,
    ];
  },
};
