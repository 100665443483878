import type { MedleyRecord, MedleySongRecord } from '@/collections/MedleysCollection';
import { ObjectPropertyKeyTransformer } from '@/lib/ObjectPropertyKeyTransformer';
import type { GroundedDocumentCompressor } from '@/local-db/GroundedCollection';

export const GroundedMedleysCompressor: GroundedDocumentCompressor<MedleyRecord> = {
  compress(doc: MedleyRecord): Record<string, any> {
    if (!doc) return doc;
    return medleyTransformer.abbreviate(doc);
  },

  decompress(pckd: Record<string, any>): MedleyRecord {
    if (!pckd) return pckd;
    return medleyTransformer.unabbreviate(pckd);
  },
};

const medleyTransformer = new ObjectPropertyKeyTransformer<MedleyRecord>([
  ['_id', '_id'],
  ['n', 'name'],
  ['cn', 'customName'],
  ['uId', 'userId'],
  ['uF', 'userFirst'],
  ['uL', 'userLast'],
  ['uAt', 'updatedAt', { date: true }],
  ['cAt', 'createdAt', { date: true }],
  ['b', 'bpm'],
  ['u', 'unsynced'],
  [
    'ms',
    'medleySongs',
    {
      compress: (medleySongs: MedleySongRecord[]) =>
        medleySongs.map((song) => medleySongTransformer.abbreviate(song)),
      decompress: (ms: Record<string, any>[]) =>
        ms.map((sng) => medleySongTransformer.unabbreviate(sng)),
    },
  ],
]);

const medleySongTransformer = new ObjectPropertyKeyTransformer<MedleySongRecord>([
  ['_id', '_id'],
  ['n', 'name'],
  ['k', 'key'],
  ['r', 'reps'],
  ['bx', 'bpmX'],
  ['si', 'skipIntros'],
  ['so', 'skipOutros'],
  ['uAt', 'updatedAt', { date: true }],
  ['uId', 'userId'],
  ['uF', 'userFirst'],
  ['uL', 'userLast'],
  // Yes, band and presetId are missing from this. But apparently, they're passed through uncompressed, so no worries.
]);
