<script lang="ts">
  import { throttle } from 'underscore';
  import { patternLandscapeForMandolinCore } from '@/band/instruments/mandolin/patternLandscapeForMandolinCore';
  import CycleThroughDropdown from '@/band/ui/components/CycleThroughDropdown.svelte';
  import RhythmPatternVisualization from '@/band/ui/components/RhythmPatternVisualization.svelte';
  import CircularStrumShapeSpatialUI from '@/band/ui/panels/CircularStrumShapeSpatialUI.svelte';
  import PanelModalLayout from '@/band/ui/panels/PanelModalLayout.svelte';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';

  let tweenThumb = $state(false);

  const unTweenThumbAfterDelay = throttle(() => (tweenThumb = false), 200, {
    leading: false,
    trailing: true,
  });

  const bandMenu = getBandMenuState();

  let core = $derived($bandMenu.mandolin.coreOrFallbackForTimeSignature($bandMenu.timeSignature));
  let landscape = $derived(patternLandscapeForMandolinCore(core));

  let setting = $derived($bandMenu.mandolinSettings.chopAccents);

  let timeSignature = $derived($bandMenu.timeSignature);

  let actions = $derived(core.generateViz($bandMenu.mandolinSettings));

  let quadrantLabels = $derived({
    top: 'Many Accents',
    bottom: 'No Accents',
    left: 'On the Beat',
    right: 'Syncopated',
  });

  function handleRadialUpdate(e: CustomEvent<[r: number, theta: number]>) {
    $bandMenu.mandolin.setSetting('chopAccents', setting.withPolarCoordinates(...e.detail));
  }
</script>

<PanelModalLayout>
  {#snippet header()}
    <header class="panelHeader">
      <div class="mx-auto max-w-[18em]">
        You can change how often the mandolin plays accents between offbeat chops.
        <!-- TODO: link to a real help article -->
        <!-- <a href="#" class="whitespace-nowrap font-medium"> Learn more</a>. -->
      </div>
    </header>
  {/snippet}

  {#snippet main()}
    <div class="px-4 pb-2 pt-4">
      <div class="mb-7 mt-2 flex justify-center">
        <div
          style="box-shadow: 4px 6px 12px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.05), -4px -6px 12px 0px rgba(255, 255, 255, 0.95); border-radius: 12px; overflow: hidden; border: solid 2px var(--gray-100);"
        >
          <RhythmPatternVisualization
            {actions}
            {timeSignature}
            scale={1.9}
            highlightCurrentBeat={true}
            showBeatNumbers={true}
          />
        </div>
      </div>

      <div class="cycle-shadow rounded-lg bg-muted-100 p-1 pb-[5px]">
        <CycleThroughDropdown
          options={landscape.presets.map((preset) => ({
            caption: preset.name,
            value: preset,
          }))}
          selectedValue={setting.closestPresetForLandscape(landscape)}
          on:select={(event) => {
            $bandMenu.mandolin.setSetting(
              'chopAccents',
              setting.withPolarCoordinates(...event.detail.position)
            );
            tweenThumb = true;
            unTweenThumbAfterDelay();
          }}
          wrapAround={true}
          captureLeftRightKeys={true}
          doCrossfades={true}
        />
      </div>

      <div class="pt-1">
        <CircularStrumShapeSpatialUI
          polarCoordinates={[setting.polarR, setting.polarTheta]}
          labels={quadrantLabels}
          on:change={handleRadialUpdate}
          {tweenThumb}
          snapToEdges={true}
          snapToPoles={true}
          poleSnapCount={4}
          snapThreshold={0.2}
        />
      </div>
    </div>
  {/snippet}
</PanelModalLayout>
