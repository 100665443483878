<script lang="ts">
  import { bassCustomizationUI } from '@/band/instruments/bass/bassCustomizationUI';
  import PrettySlider from '@/band/ui/components/PrettySlider.svelte';
  import SliderRule from '@/band/ui/components/SliderRule.svelte';
  import PanelModalLayout from '@/band/ui/panels/PanelModalLayout.svelte';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';

  const bandMenu = getBandMenuState();

  const min = 0;
  const max = 12;

  let setting = $derived($bandMenu.bassSettings.onbeatWaltzSustain);
  let notch = $derived(setting.sustain * max);
  let caption = $derived(bassCustomizationUI.onbeatWaltzSustain.getRowStatus({ setting }).text);

  function setSustainToNotch(notch: number) {
    $bandMenu.bass.setSetting('onbeatWaltzSustain', setting.withSustain(notch / max));
  }
</script>

<PanelModalLayout>
  {#snippet header()}
    <header class="panelHeader">
      This setting controls the amount of time each bass note rings out before being dampened.
    </header>
  {/snippet}

  {#snippet main()}
    <div>
      <div class="p-6 text-center text-lg {notch === 0 ? 'text-gray-500' : 'text-gray-700'}">
        {caption}
      </div>

      <div class="mx-6 py-2">
        <PrettySlider
          {min}
          {max}
          value={notch}
          step={1}
          tickCount={max - min + 1}
          thumbDiameter={2.75}
          tickHeight={1.25}
          tickSqueezes={Array.from(
            { length: max - min + 1 },
            (_, i) => 1 - (i + 2) / (max - min + 2)
          )}
          ariaLabel="sustain"
          on:change={({ detail }) => setSustainToNotch(detail)}
        />
      </div>
      <SliderRule lowerLabel="Short" upperLabel="Long" />
      <div class="mb-3"></div>
    </div>
  {/snippet}
</PanelModalLayout>
