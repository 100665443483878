<script lang="ts">
  import { ChopMandolinCore } from '@/band/instruments/mandolin/cores/ChopMandolinCore';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';
  import { generateBandInstrumentTimingSummary } from '@/band/ui/status/generateBandInstrumentTimingSummary';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import { slideFade } from '@/ui/svelte-transitions';

  const bandMenu = getBandMenuState();

  interface Props {
    instId: InstrumentId;
  }

  let { instId }: Props = $props();

  function swingEnabledForInstrument(instId: InstrumentId): boolean {
    const instrument = $bandMenu.instruments[instId];
    if (!instrument.enabled()) return false;
    const core = instrument.coreOrFallbackForTimeSignature($bandMenu.band.timeSignature());
    const swingMandolinNotApplicable =
      core == ChopMandolinCore && !core.generateViz($bandMenu.mandolinSettings)[5]?.opacity;
    return (
      'swingCategory' in core &&
      !!core.swingCategory &&
      core.swingCategory !== 'none' &&
      !swingMandolinNotApplicable
    );
  }

  let swingStatus = $state('');
  trackerEffect(() => {
    if (
      !swingEnabledForInstrument(instId) ||
      (instId != 'guitar' &&
        (swingEnabledForInstrument('guitar') ||
          (instId != 'mandolin' && swingEnabledForInstrument('mandolin'))))
    ) {
      swingStatus = '';
    } else {
      const swingSetting = $bandMenu.band.swing();
      swingStatus =
        typeof swingSetting == 'number'
          ? `Swing: ${Math.round(100 * swingSetting)}%`
          : swingSetting.name;
    }
  });

  let timeSignature = $derived($bandMenu.timeSignature);

  let timingSetting = $derived($bandMenu.instruments[instId].settings().timing);

  // TODO: replace with generalized customization summary
  let timingSummaryLines: string[] = $state([]);
  trackerEffect(
    () =>
      (timingSummaryLines = generateBandInstrumentTimingSummary({
        setting: timingSetting,
        timeSignature,
        instId,
      }))
  );
</script>

{#if swingStatus}
  <div transition:slideFade>{swingStatus}</div>
{/if}
{#each Object.values(timingSummaryLines) as value, index (index)}
  <div>
    {value}
  </div>
{/each}
