import { DemoMode } from '@/client/DemoMode';
import { Crnt } from '@/Crnt';
import EditorToolbar from '@/editor/ui/EditorToolbar.svelte';
import { Roles } from '@/Roles';
import BpmAdjuster from '@/ui/BPMAdjuster.svelte';
import { controlBarBelow } from '@/ui/controlBarBelow';
import { shouldUseCompactToolsDropdown } from '@/ui/shouldUseCompactToolsDropdown';
import { UserProfile } from '@/user/UserProfile';

Template.controlBar.onCreated(() => {
  const instance = Template.instance();
  const codes = ['PLAY', 'BPM_ADJUST']; // removed BPM_SET
  instance.showHints = codes.some((c) => UserProfile.notMilestone(c)) || DemoMode.active();
});

Template.controlBar.onRendered(() => {
  const instance = Template.instance();
  if (!('IntersectionObserver' in window)) return;
  setTimeout(() => {
    instance.ctrlBarTopEl = $('.js-global-songScrollContainer .controlBar-top').get(0);
    instance.ctrlBarEl = $('.js-global-songScrollContainer .controlBar').get(0);
    if (!instance.ctrlBarTopEl || !instance.ctrlBarEl) return;
    instance.intersectionObserver = new IntersectionObserver(
      ([entry]) => {
        if (!entry) return;
        instance.ctrlBarEl.classList.toggle('stuck-to-top', !entry.isIntersecting);
      },
      { threshold: [0] }
    ).observe(instance.ctrlBarTopEl);
  }, 0); // timeout prevents a display bug where 'stuck-to-top' is set on page load

  instance.autorun(function showHideControlBarMilestoneHints() {
    if (
      Crnt.song()?.editMode() ||
      $('.js-global-songScrollContainer').length === 0 ||
      !Roles.currentUserCanPlaySong(Crnt.song() || Crnt.medley())
    )
      return;
    const actionsWithHints = [
      { code: 'PLAY', hint: '.js-playHint' },
      { code: 'BPM_ADJUST', hint: '.js-speedHint' },
      // { code: 'BPM_SET', hint: '.js-speedSetHint' },
    ];
    for (const action of actionsWithHints) {
      // not .forEach for reactivity purposes
      if (UserProfile.notMilestone(action.code)) {
        setTimeout(() => {
          if (!instance.view.isDestroyed) instance.$(action.hint)?.addClass('visible-hint');
        }, 50);
        return;
      } else if (UserProfile.hasMilestone(action.code)) {
        setTimeout(() => {
          if (!instance.view.isDestroyed) instance.$(action.hint)?.removeClass('visible-hint');
        }, 50);
      }
    }
  });
});

Template.controlBar.onDestroyed(() => {
  const instance = Template.instance();
  instance.intersectionObserver?.disconnect();
});

Template.controlBar.helpers({
  anyHints() {
    const instance = Template.instance();
    return instance.showHints && Roles.currentUserCanPlaySong(Crnt.song() || Crnt.medley());
  },
  controlBarBelow() {
    return controlBarBelow();
  },
  compactTools() {
    return shouldUseCompactToolsDropdown();
  },
  showTools() {
    return !Template.currentData().medley;
  },
  loaded() {
    return Crnt.song() || Crnt.medley();
  },
  BpmAdjuster() {
    return BpmAdjuster;
  },
});
