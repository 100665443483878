import Cookies from 'js-cookie';
import { Meteor } from 'meteor/meteor';
import { Tracker } from 'meteor/tracker';
import { Analytics } from '@/browser/analytics/Analytics';

export function trackUserAnalytics() {
  void Analytics.init().then(() => {
    Tracker.autorun(() => {
      if (window.userIsBeingImpersonated) return;
      const user = Meteor.user(
        'profile',
        'emails',
        'createdAt',
        'roles',
        'customerId',
        'subscription'
      );
      if (user) {
        Analytics.setUserId(user._id);
        Analytics.setUserProperties({
          // no name or email for privacy reasons
          created_at: user.createdAt,
          subscription_status:
            user.subscription?.status || (user.roles?.includes('paid-member') ? 'active' : ''),
          card_added: !!user.customerId,
          /// device-specific
          cordova: !!Meteor.isCordova,
          electron: !!window.todesktop,
          safariRequestingDesktop:
            navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1,
          swActive: 'serviceWorker' in navigator && !!navigator.serviceWorker.controller,
          releaseHash: Meteor.gitCommitHash,
          pwa:
            !window.todesktop &&
            (window.navigator.standalone == true ||
              window.matchMedia('(display-mode: standalone)').matches ||
              window.location.href.includes('pwa=1')),
        });

        Tracker.autorun(() => {
          const minutes_played = Meteor.user('playedMinutes')?.playedMinutes;
          if (minutes_played) Analytics.setUserProperties({ minutes_played });
        });
      } else {
        Analytics.setUserId(undefined);
        if (Cookies.get('offer')) {
          Analytics.setUserProperties({ signupOffer: Cookies.get('offer') });
        }
      }
    });
  });
}
