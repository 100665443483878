import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { ReactiveTimer } from '@/lib/ReactiveTimer';
import { Roles } from '@/Roles';

Template.subscriptionNotices.onCreated(() => {
  const timeLeftReactiveVar = new ReactiveVar();
  const instance = Template.instance();
  instance.trialing = new ReactiveVar();
  instance.trialTimeLeft = () => timeLeftReactiveVar.get();
  instance.trialTimer = new ReactiveTimer(60); // once a minute
  instance.autorun(() => {
    instance.trialTimer.watch();
    const subscription = Meteor.user('subscription')?.subscription || {};
    const trialEndTimestamp = subscription.trialEnd || 0;
    instance.trialing.set(
      subscription.status == 'trialing' &&
        !subscription.subscriptionId &&
        trialEndTimestamp > Date.now()
    );
    timeLeftReactiveVar.set({
      days: Math.max(0, Math.ceil((trialEndTimestamp - Date.now()) / 86400000)),
      hours: Math.max(0, Math.ceil((trialEndTimestamp - Date.now()) / 3600000)),
      minutes: Math.max(0, Math.ceil((trialEndTimestamp - Date.now()) / 60000)),
    });
  });
});

Template.subscriptionNotices.onDestroyed(() => {
  if (Template.instance().trialTimer) Template.instance().trialTimer.stop();
});

Template.subscriptionNotices.helpers({
  showNotices() {
    if (FlowRouter.getQueryParam('subscribed')) return false;
    return (
      (!Meteor.isCordova && Meteor.user('roles') && !Meteor.user('subscription')?.subscription) ||
      (Meteor.user('subscription')?.subscription &&
        Meteor.user('subscription')?.subscription?.status !== 'active')
    );
  },
  subscriptionPastDue() {
    return !Meteor.isCordova && Meteor.user('subscription')?.subscription?.status == 'past_due';
  },
  subscriptionCanceled() {
    return Meteor.user('subscription')?.subscription?.status == 'canceled';
  },
  noWebSubscriptionOnWeb() {
    return (
      !Meteor.isCordova &&
      Meteor.user('subscription') &&
      !Meteor.user('subscription')?.subscription &&
      !Roles.userHasRole(Meteor.userId(), 'paid-member') &&
      !Roles.userHasRole(Meteor.userId(), 'lifetime-member')
    );
  },
  trialDaysLeft() {
    const instance = Template.instance();
    return instance.trialTimeLeft().days;
  },
  verySoonDaysLeft() {
    const instance = Template.instance();
    if (instance.trialTimeLeft().days > 1) {
      return instance.trialTimeLeft().days == 2 ? 'in a couple days' : 'in a few days';
    } else {
      return instance.trialTimeLeft().hours > 1
        ? `in ${instance.trialTimeLeft().hours} hours`
        : `in ${instance.trialTimeLeft().minutes} minutes`;
    }
  },
  trialNoticeCalm() {
    if (Meteor.isCordova) return;
    const instance = Template.instance();
    return (
      instance.trialing.get() &&
      /* FlowRouter.getRouteName() == 'song-index' && */ instance.trialTimeLeft().days < 15 &&
      instance.trialTimeLeft().days > 5
    );
  },
  trialEndingVerySoon() {
    if (Meteor.isCordova) return;
    const instance = Template.instance();
    return instance.trialing.get() && instance.trialTimeLeft().days <= 4;
  },
  trialEnded() {
    return (
      Roles.userHasRole(Meteor.userId(), 'trial-ended') &&
      Meteor.user('subscription')?.subscription?.status != 'canceled'
    );
  },
});
