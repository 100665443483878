import { z } from 'zod';
import type { InstrumentSetting } from '@/band/instruments/InstrumentSetting';
import { bounded } from '@/utilities/bounded';
import cloneJSON from '@/utilities/cloneJSON';
import { rounded } from '@/utilities/rounded';

const serializedSchema = z.object({
  l: z.number().optional(), // leading note frequency (0-1)
});

type Serialization = z.infer<typeof serializedSchema>;

export class GuitarSyncopatedLeadingNotesSetting implements InstrumentSetting<Serialization> {
  readonly frequency: number;

  get leadingNotesPerMeasure() {
    if (this.frequency === 0) return 0;
    return Math.pow(2, this.frequency * 4 - 1) / 8;
  }

  constructor(input: unknown = {}) {
    const data = serializedSchema.catch({}).parse(input);
    this.frequency = bounded(data.l ?? 0, 0, 1);
  }

  serialize(): Serialization {
    return cloneJSON({
      l: rounded(this.frequency, 3) || undefined,
    });
  }

  closeTo(serialized?: Serialization): boolean {
    const other = new GuitarSyncopatedLeadingNotesSetting(serialized);
    return this.frequency === other.frequency;
  }

  withFrequency(value: number) {
    return new GuitarSyncopatedLeadingNotesSetting({ ...this.serialize(), l: value });
  }
}
