Template.nominationsIndex.helpers({
  hueForUserId(uid) {
    let hash = 7;
    for (let i = 3; i >= 0; i--) {
      hash = hash * 31 + uid.charCodeAt(i);
    }
    const hue = hash % 360;
    return hue;
  },
});
