import type { InstrumentCustomizationUI } from '@/band/instruments/InstrumentCustomizationUI';
import type { InstrumentTimingSetting } from '@/band/instruments/InstrumentTimingSetting';
import { generateBandInstrumentTimingSummary } from '@/band/ui/status/generateBandInstrumentTimingSummary';

export function timingRowStatusGetter(
  instId: InstrumentId
): InstrumentCustomizationUI<any>['getRowStatus'] {
  return function getRowStatus({
    setting,
    timeSignature,
  }: {
    setting: InstrumentTimingSetting;
    timeSignature?: TimeSignature;
  }) {
    const lines = generateBandInstrumentTimingSummary({ setting, timeSignature, instId });
    if (lines.length === 0) {
      return { text: 'No timing tweaks', gray: true };
    }
    return {
      text: lines.join('\n'),
    };
  };
}
