import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { MusicLibrary } from '@/library/MusicLibrary';
import { MusicLibrarySearcher } from '@/library/MusicLibrarySearcher';
import { putArticleAtEnd } from '@/library/putArticleAtEnd';
import defineTippyDropdown from '@/ui/dropdowns/defineTippyDropdown';

defineTippyDropdown(Template.publicSongIndex, {
  id: 'public-az',
});

function canonicalPathFor(song) {
  var songId = song._id;
  var songName = song.name
    .replace(/ +\[.+\]$/, '')
    .replace(/ /g, '-')
    .replace(/[^a-z0-9-]/gi, '')
    .toLowerCase();
  return FlowRouter.path('public-song', { songId, songName });
}

Template.publicSongIndex.onCreated(() => {
  MusicLibrary.load();
  const instance = Template.instance();
  instance.searcher = new MusicLibrarySearcher();
  Meteor.defer(() => {
    instance.autorun(() => {
      var query = instance.searcher.queryText(); // not in "if" statement because it needs to be reactive
      if (!instance.typingQuery) {
        instance.$('.js-songSearchBoxInput').val(query);
      }
    });
  });
});

Template.publicSongIndex.helpers({
  ready() {
    return MusicLibrary.index.readyToSearch();
  },
  songSearchResults() {
    const instance = Template.instance();
    return (
      MusicLibrary.index.readyToSearch() &&
      (instance.searcher.queryPresent()
        ? instance.searcher.results()
        : MusicLibrary.index.search({}))
    );
  },
  indexNameFor(song) {
    return putArticleAtEnd(song.name.replace(/ +\[.+\]$/, ''));
  },
  indexLabelFor(song) {
    var labelMatch = song.name.match(/ \[(.+)\]$/);
    if (labelMatch) return labelMatch[1];
  },
  publicPathFor(song) {
    return canonicalPathFor(song);
  },
  autofocusOnDesktop() {
    return /iP(hone|ad|od)|Android/i.test(navigator.userAgent) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
      ? {}
      : { autofocus: true };
  },
  azSelected() {
    const instance = Template.instance();
    var query = instance.searcher.query() || {};
    return query.all || query.allSongs || query.allMedleys || query.firstLetter || query.numbers;
  },
  searcher() {
    const instance = Template.instance();
    return instance.searcher;
  },
});

Template.publicSongIndex.events({
  'focus .js-songSearchBoxInput': (event, instance) => {
    if (instance.$('.js-songSearchBoxInput').val()) {
      // only select if placeholder is gone (iOS bug)
      instance.$('.js-songSearchBoxInput').select();
      instance.$('.js-songSearchBoxInput')[0].setSelectionRange(0, 9999);
    }
  },

  'input .js-songSearchBoxInput': _.throttle(
    (event, instance) => {
      var query = instance.$('.js-songSearchBoxInput').val();
      instance.typingQuery = true;
      instance.searcher.setQuery(query);
      Meteor.defer(() => {
        instance.typingQuery = false;
      });
    },
    200,
    { leading: false }
  ),

  'keydown form': (event, instance) => {
    if (event.key == '/') {
      return false;
    }
    if (event.key == ' ') {
      if (!instance.$('.js-songSearchBoxInput').val()) {
        event.preventDefault();
      }
    }
    if (event.key == 'Escape') {
      instance.$('.js-clear').click();
    }
  },

  'submit form': (event, instance) => {
    instance.$('.js-songSearchBoxInput').blur();
    event.preventDefault();
  },

  'click .js-viewAll': (event, instance) => {
    instance.searcher.setQuery({ all: true });
    return false;
  },
});
