<script lang="ts">
  import type { GuitarOpenVoicingsSetting } from '@/band/instruments/guitar/settings/GuitarOpenVoicingsSetting';
  import GuitarChordCapoPositionDiagram from '@/band/ui/components/GuitarChordCapoPositionDiagram.svelte';
  import PanelSettingSection from '@/band/ui/components/PanelSettingSection.svelte';
  import GuitarOpenVoicingsShapeDropdowns from '@/band/ui/panels/GuitarOpenVoicingsShapeDropdowns.svelte';
  import PanelModalLayout from '@/band/ui/panels/PanelModalLayout.svelte';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';
  import { Chord } from '@/music/Chord';
  import { majorKeys } from '@/music/music-knowledge';
  import SimpleListbox from '@/unused/SimpleListbox.svelte';

  const bandMenu = getBandMenuState();

  const allPossibleListboxOptions: {
    name: string;
    value: GuitarOpenVoicingsSetting['majorRootShapes'];
  }[] = [
    { value: 'gacde', name: 'G, A, C, D, or E' },
    { value: 'gcde', name: 'G, C, D, or E' },
    { value: 'gcd', name: 'G, C, or D' },
    { value: 'gd', name: 'G or D' },
    { value: 'gc', name: 'G or C' },
    { value: 'ea', name: 'E or A' },
  ];

  let setting = $derived($bandMenu.guitarSettings.openVoicings);

  let keysForShapes: Partial<
    Record<
      GuitarOpenVoicingsSetting['majorChordShapesChosen'][number],
      (typeof majorKeys)[number][]
    >
  > = $derived(
    Object.fromEntries(
      setting.majorChordShapesChosen.reduce<
        [(typeof majorKeys)[number], (typeof majorKeys)[number][]][]
      >((acc, shape) => {
        const keys = majorKeys.filter((key) => setting.positionToUseForKey(key)?.shape == shape);
        const indexOfShape = Math.max(0, keys.indexOf(shape));
        const reorderedKeys = [...keys.slice(indexOfShape), ...keys.slice(0, indexOfShape)];
        acc.push([shape, reorderedKeys]);
        return acc;
      }, [])
    )
  );

  function setMajorVoicing(value: GuitarOpenVoicingsSetting['majorRootShapes']) {
    $bandMenu.guitar.setSetting('openVoicings', setting.withMajorRootShapes(value));
  }
</script>

<PanelModalLayout>
  {#snippet header()}
    <header class="panelHeader">
      <div class="text-balance">
        Use this setting to control which chord shapes the guitar will use across various keys. This
        is especially useful when making your own preset.
        <!-- <a href="#" class="font-medium">Learn more</a>. -->
      </div>
    </header>
  {/snippet}

  {#snippet main()}
    <div class="p-3">
      <PanelSettingSection
        title="Chord shapes to choose from"
        helpText="The guitar will only use the root chord shapes selected, choosing one depending on the key of the song and using a capo as necessary."
        whiteBg={false}
      >
        <SimpleListbox
          options={allPossibleListboxOptions}
          value={setting.majorRootShapes}
          fontWeight="semibold"
          on:change={(e) => setMajorVoicing(e.detail)}
        />
      </PanelSettingSection>

      <div class="p-3">
        <div class="mb-4 text-sm text-gray-600">
          Below, you can see how the root chord shapes selected above will be used in each key.
          (Voicings can be customized below as well.)
        </div>

        {#each setting.majorChordShapesChosen as shape (shape)}
          <div class="pb-4 last:pb-0">
            <div
              class="flex rounded-lg bg-white p-4 pl-2"
              style="box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.1);"
            >
              <div class="flex-0 overflow-hidden pr-3">
                <GuitarChordCapoPositionDiagram
                  chordShape={shape == 'G'
                    ? setting.voicingForG
                    : shape == 'D'
                      ? setting.voicingForD
                      : shape}
                  capoPosition={0}
                  fretsToShow={shape == 'D' ||
                  (shape == 'G' && (keysForShapes[shape]?.length ?? 0) > 5)
                    ? 4
                    : 3}
                  scale={1.5}
                />
              </div>
              <div class="flex-1">
                <div class="text-xl font-medium text-gray-800">{shape} root shape</div>
                <div class="pb-1 text-gray-600">Used in these keys:</div>
                <div class="flex max-w-[12rem] flex-wrap gap-1.5">
                  {#each keysForShapes[shape] ?? [] as key (key)}
                    <div
                      class="w-7 rounded text-center leading-7 text-white {key.length > 1
                        ? 'bg-muted-400'
                        : 'bg-muted-600'}"
                    >
                      {@html new Chord(key).toHtml('letters')}
                    </div>
                  {/each}
                </div>
                <div class="mt-2">
                  <GuitarOpenVoicingsShapeDropdowns {shape} shortenLabels={true} />
                </div>
              </div>
            </div>
          </div>
        {/each}
      </div>
    </div>
  {/snippet}
</PanelModalLayout>
