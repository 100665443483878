import { strict as assert } from 'assert';
import { Meteor } from 'meteor/meteor';
import { ReactiveVar } from 'meteor/reactive-var';
import type { MedleyRecord } from '@/collections/MedleysCollection';
import { MedleysCollection } from '@/collections/MedleysCollection';
import type { SongRecord } from '@/collections/SongsCollection';
import type { ListBackend } from '@/library/ListBackend';
import { MusicLibrary } from '@/library/MusicLibrary';
import type { MusicLibrarySongs } from '@/library/MusicLibrarySongs';
import { OfflineMedleyBackend } from '@/library/OfflineMedleyBackend';
import { checkOfflineStorageAvailable } from '@/local-db/checkOfflineStorageAvailable';
import { tpmToBpm } from '@/music/bpm-tpm-conversion';
import { rpcFetchMedleyWithSongs } from '@/server/methods/medleys/rpcFetchMedleyWithSongs';
import { callServerMethodWithoutRetry } from '@/utilities/callServerMethodWithoutRetry';
import waitUntilReactive from '@/utilities/waitUntilReactive';

export class MusicLibraryMedleys {
  indexCollection = MedleysCollection;

  private listBackend?: ListBackend;
  private songsBackend?: MusicLibrarySongs;
  offlineMedleyBackend?: OfflineMedleyBackend;

  private subscriptions: Record<string, Meteor.SubscriptionHandle> = {};

  private _loadingStarted = false;
  async load({
    listBackend,
    songsBackend,
  }: {
    listBackend: ListBackend;
    songsBackend: MusicLibrarySongs;
  }): Promise<void> {
    if (this._loadingStarted) return await waitUntilReactive(() => this._ready.get());
    this._loadingStarted = true;

    this.listBackend = listBackend;
    this.songsBackend = songsBackend;

    this.subscriptions.medleys = Meteor.subscribe('medleys');

    const offlineAvailable = await checkOfflineStorageAvailable();
    if (offlineAvailable) {
      assert(this.songsBackend.offlineSongBackend);
      this.offlineMedleyBackend = new OfflineMedleyBackend(this.songsBackend.offlineSongBackend);
      this.indexCollection = this.offlineMedleyBackend.groundedIndex;
      this.groundData();
    }

    // Assumption: if no subscriptions, data has already been grounded
    await waitUntilReactive(
      () =>
        this.subscriptions.medleys.ready() ||
        (!Meteor.status().connected && this.offlineMedleyBackend?.groundedIndex.loaded())
    );

    this._ready.set(true);
  }
  // This is a separate async function because we don't want it holding up live access
  private async groundData(): Promise<void> {
    await waitUntilReactive(() => this.subscriptions.medleys.ready() && this.listBackend?.ready());
    assert(this.listBackend);
    this.offlineMedleyBackend?.groundWithLiveCollections(
      MedleysCollection,
      this.listBackend.medleysOnlyInLists
    );
  }

  private _ready = new ReactiveVar(false);
  ready(): boolean {
    return this._ready.get();
  }

  offlineReady(): boolean {
    return !!this.offlineMedleyBackend?.offlineReady();
  }

  private _blockingOp = new ReactiveVar(false);
  blockingOperationInProgress(): boolean {
    return this._blockingOp.get();
  }
  block(): void {
    this._blockingOp.set(true);
  }

  /**
   * Fetches a medley record from local storage (if available) and/or the server.
   * @param medleyId - _id of medley to fetch
   * @returns { medley: MedleyRecord, songs: SongRecord[] }
   * @throws Meteor.Error if medley can't be found
   */
  fetch(
    medleyId: string,
    options: { blocking?: boolean } = {}
  ): Promise<{ medley: MedleyRecord; songs: SongRecord[] }> {
    if (options.blocking) this._blockingOp.set(true);
    return new Promise((resolve, reject) => {
      // Let's have a race between offline and online fetching!
      let onlineFetchSuccessful = false;
      let offlineFetchSuccessful = false;
      if (this.offlineMedleyBackend) {
        this.offlineMedleyBackend
          .fetchFullRecord(medleyId)
          .then(async (medley?: MedleyRecord) => {
            if (medley && !onlineFetchSuccessful) {
              offlineFetchSuccessful = true;
              const songs = await Promise.all(
                medley.medleySongs.map((ms) =>
                  this.songsBackend?.offlineSongBackend?.fetchFullRecord(ms._id)
                )
              );
              resolve({
                medley,
                songs: songs.filter((s) => s), // just in case a song was deleted offline
              });
            }
          })
          .finally(() => this._blockingOp.set(false));
      }
      Meteor.setTimeout(
        () => {
          if (offlineFetchSuccessful) return;
          rpcFetchMedleyWithSongs({ medleyId })
            .then(({ medley, songs }) => {
              if (offlineFetchSuccessful) return;
              onlineFetchSuccessful = true;
              resolve({ medley, songs });
            })
            .catch((err) => {
              if (offlineFetchSuccessful) return;
              reject(err);
            })
            .finally(() => this._blockingOp.set(false));
        },
        this.offlineMedleyBackend ? 1000 : 0
      ); // timeout is to give offline DB a head start
    });
  }

  async createNewWithSongs(songs: [SongRecord, ...SongRecord[]]): Promise<string> {
    const songNames: string[] = [];
    for (const song of songs) {
      songNames.push(song.name.replace(/ +\[.+\]$/, ''));
    }
    const librarySongs = await Promise.all(songs.map((s) => MusicLibrary.songs.fetchFull(s._id)));
    const firstSongTPM = librarySongs[0]?.songUserAttributes?.tpm || librarySongs[0]?.song.tpm;
    const firstSongTimeSignature = librarySongs[0]?.song.timeSignature;
    return this.add({
      name: songNames.join(' / '),
      userId: Meteor.userId() as string,
      bpm: firstSongTPM
        ? tpmToBpm({
            tpm: firstSongTPM,
            timeSignature: firstSongTimeSignature || '4/4',
            ignoreUserPreferences: true,
          })
        : 100,
      medleySongs: await Promise.all(
        librarySongs.map(({ song, songUserAttributes: sua }) => {
          const { _id, name, userId, userFirst, userLast, updatedAt } = song;
          const key = sua?.key || song.key;
          const band = sua?.band || song.band;
          const presetId = sua?.presetId || song.presetId;
          return {
            _id,
            name,
            userId,
            userFirst,
            userLast,
            updatedAt,
            key,
            band,
            presetId,
          } satisfies SerializedMedleySong;
        })
      ),
    });
  }

  handleUpdatedSongId(oldId: string, newId: string): void {
    this.offlineMedleyBackend?.reviseSongId(oldId, newId);
  }

  async upsert(medleyRecord: SerializedMedley): Promise<string | undefined> {
    return medleyRecord._id ? this.update(medleyRecord) : this.add(medleyRecord);
  }

  async add(medleyRecord: SerializedMedley): Promise<string> {
    this._blockingOp.set(true);
    try {
      if (Meteor.status().connected) {
        // const oldId = medleyRecord._id;
        const newId = await callServerMethodWithoutRetry('medleys.insert', medleyRecord);
        return newId;
      } else {
        if (this.offlineMedleyBackend) {
          const newId = this.offlineMedleyBackend.insert(medleyRecord);
          return newId;
        } else {
          throw new Meteor.Error(
            'AddMedleyFailed',
            "Can't save medley while offline. Please try again after connection is restored."
          );
        }
      }
    } finally {
      Meteor.defer(() => this._blockingOp.set(false));
    }
  }

  async update(medleyRecord: SerializedMedley): Promise<undefined> {
    assert(medleyRecord._id);
    medleyRecord.updatedAt = new Date();
    if (Meteor.status().connected) {
      await callServerMethodWithoutRetry('medleys.update', medleyRecord);
      await this.offlineMedleyBackend?.setFullRecord(medleyRecord._id, medleyRecord);
    } else {
      if (this.offlineMedleyBackend) {
        this.offlineMedleyBackend.update(medleyRecord);
      } else {
        throw new Meteor.Error(
          'UpdateMedleyFailed',
          "Can't update medley while offline. Please try again after connection is restored."
        );
      }
    }
    return undefined;
  }

  async delete(medleyId: string): Promise<void> {
    this._blockingOp.set(true);
    try {
      if (Meteor.status().connected) {
        await callServerMethodWithoutRetry('medleys.delete', medleyId);
        await this.offlineMedleyBackend?.removeFullRecord(medleyId);
      } else {
        if (this.offlineMedleyBackend) {
          this.offlineMedleyBackend.remove(medleyId);
        } else {
          throw new Meteor.Error(
            'DeleteMedleyFailed',
            "Can't delete medley while offline. Please try again after connection is restored."
          );
        }
      }
    } finally {
      Meteor.defer(() => this._blockingOp.set(false));
    }
  }
}
