import * as Sentry from '@sentry/browser';
import { strict as assert } from 'assert';
import { Meteor } from 'meteor/meteor';
import { ReactiveVar } from 'meteor/reactive-var';
import { Tracker } from 'meteor/tracker';
import type { BandPreset } from '@/band/presets/BandPreset';
import { builtInPresetsArrayByMeter } from '@/band/presets/builtInPresetsArrayByMeter';
import { createCustomizedDefaultPreset } from '@/band/presets/createCustomizedDefaultPreset';
import { createDefaultPresetForTimeSignature } from '@/band/presets/createDefaultPresetForTimeSignature';
import { createUserPreset } from '@/band/presets/createUserPreset';
import { createUserPresetLegacyCustomization } from '@/band/presets/createUserPresetLegacyCustomization';
import { legacyPresetsById } from '@/band/presets/legacyPresetsById';
import { UserBandPresetManager } from '@/band/presets/UserBandPresetManager';

type PresetLibrary = Record<
  TimeSignature,
  { default: BandPreset; builtIn: readonly BandPreset[]; custom: BandPreset[] }
>;

const defaultPresetByMeter = {
  '4/4': createDefaultPresetForTimeSignature('4/4'),
  '3/4': createDefaultPresetForTimeSignature('3/4'),
  '6/8': createDefaultPresetForTimeSignature('6/8'),
  '9/8': createDefaultPresetForTimeSignature('9/8'),
} as const satisfies Record<TimeSignature, BandPreset>;

function generateEmptyPresetLibrary(): PresetLibrary {
  return {
    '4/4': {
      default: defaultPresetByMeter['4/4'],
      builtIn: builtInPresetsArrayByMeter['4/4'],
      custom: [],
    },
    '3/4': {
      default: defaultPresetByMeter['3/4'],
      builtIn: builtInPresetsArrayByMeter['3/4'],
      custom: [],
    },
    '6/8': {
      default: defaultPresetByMeter['6/8'],
      builtIn: builtInPresetsArrayByMeter['6/8'],
      custom: [],
    },
    '9/8': {
      default: defaultPresetByMeter['9/8'],
      builtIn: builtInPresetsArrayByMeter['9/8'],
      custom: [],
    },
  };
}

const presetLibrary = new ReactiveVar<PresetLibrary>(generateEmptyPresetLibrary());

function regenerateAvailablePresets() {
  const userId = Meteor.userId();
  const newLibrary = generateEmptyPresetLibrary();
  if (userId) {
    const consolidatedRecords = UserBandPresetManager.getPresetRecords();
    consolidatedRecords.forEach((r) => {
      if (r.builtInId && Object.keys(r.band ?? {}).length === 0) return;
      if (r.builtInId?.startsWith('d:')) {
        // Record is a customization of a default preset
        const timeSig = r.builtInId.slice(2) as TimeSignature;
        const defaultBeingOverridden = defaultPresetByMeter[timeSig];
        newLibrary[timeSig].default = createCustomizedDefaultPreset(r, defaultBeingOverridden);
      } else if (r.builtInId) {
        // Record is a customization of a built-in preset, from back when we allowed that
        const id = r.builtInId;
        const legacyPreset = legacyPresetsById[id];
        if (!legacyPreset) {
          Sentry.captureMessage('Unknown legacy preset: ' + id);
        } else {
          const { name, timeSignature } = legacyPreset;
          const preset = createUserPresetLegacyCustomization(r, { id, name, timeSignature });
          newLibrary[timeSignature].custom.push(preset);
        }
      } else {
        // Record is a custom user preset
        if (r.timeSignature) {
          newLibrary[r.timeSignature].custom.push(createUserPreset(r));
        } else {
          Sentry.captureMessage('Preset has no time signature: ' + JSON.stringify(r));
        }
      }
    });
    (<TimeSignature[]>['4/4', '3/4', '6/8', '9/8']).forEach((timeSig) => {
      newLibrary[timeSig].custom.sort((a, b) => a.name.localeCompare(b.name));
    });
  }
  presetLibrary.set(newLibrary);
}

Meteor.startup(() => {
  Tracker.autorun(regenerateAvailablePresets);
});

export const BandPresets = {
  all(timeSignature?: TimeSignature): BandPreset[] {
    const library = presetLibrary.get();
    return timeSignature
      ? [
          library[timeSignature].default,
          ...library[timeSignature].builtIn,
          ...library[timeSignature].custom,
        ]
      : Object.values(library).flatMap((l) => [l.default, ...l.builtIn, ...l.custom]);
  },

  builtIn(timeSignature?: TimeSignature): BandPreset[] {
    const library = presetLibrary.get();
    return timeSignature
      ? [...library[timeSignature].builtIn]
      : Object.values(library).flatMap((l) => [...l.builtIn]);
  },

  custom(timeSignature?: TimeSignature): BandPreset[] {
    const library = presetLibrary.get();
    return timeSignature
      ? [...library[timeSignature].custom]
      : Object.values(library).flatMap((l) => [...l.custom]);
  },

  defaultForTimeSignature(timeSignature: TimeSignature): BandPreset {
    return presetLibrary.get()[timeSignature].default;
  },

  findById(id: string) {
    if (id in legacyPresetsById) {
      return (
        this.custom().find((p) => p.originalPresetId == id) ??
        this.builtIn().find((p) => p.id == legacyPresetsById[id]?.newPresetId)
      );
    }
    return this.all().find((p) => p.id == id);
  },

  create({
    name,
    timeSignature,
    band,
  }: {
    name: string;
    timeSignature: TimeSignature;
    band: Partial<SerializedBandSettings>;
  }) {
    const newPresetId = UserBandPresetManager.createPreset({ name, timeSignature, band });
    regenerateAvailablePresets();
    const newPreset = this.custom(timeSignature).find((p) => p.id == newPresetId);
    assert(newPreset, 'New preset vanished!');
    return newPreset;
  },

  delete(preset: BandPreset) {
    const presetId = preset.overrideRecordId ?? preset.id;
    UserBandPresetManager.deletePreset(presetId);
    regenerateAvailablePresets();
  },

  update(preset: BandPreset, band: SerializedBandPreset['band']) {
    UserBandPresetManager.updatePreset(preset, { band, name: preset.name });
    regenerateAvailablePresets();
  },

  updateAndRename(preset: BandPreset, band: SerializedBandPreset['band'], name: string) {
    UserBandPresetManager.updatePreset(preset, { band, name });
    regenerateAvailablePresets();
  },

  rename(preset: BandPreset, name: string) {
    UserBandPresetManager.updatePreset(preset, { name });
    regenerateAvailablePresets();
  },
};

if (Meteor.isDevelopment) {
  // eslint-disable-next-line
  ((window as any).dev ??= {}).BandPresets = BandPresets;
}
