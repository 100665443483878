<script lang="ts">
  import { animate } from 'motion/mini';
  import type { Component, Snippet } from 'svelte';
  import { quadInOut } from 'svelte/easing';
  import { fly } from 'svelte/transition';
  import type { MultiPanelModalController } from '@/ui/settings/MultiPanelModalController.svelte';

  interface Props {
    modalController: MultiPanelModalController;
    blockEscapeToClose?: boolean;
    topnav?: Snippet;
  }

  let { modalController, blockEscapeToClose = false, topnav }: Props = $props();

  function onKeyDown(event: KeyboardEvent) {
    if (
      event.key == 'Escape' &&
      !(event.altKey || event.metaKey || event.shiftKey || event.ctrlKey)
    ) {
      event.preventDefault();
      if (modalController.activeScreenIndex > 0 || modalController.subview) {
        modalController.zoomOut();
      } else if (!blockEscapeToClose) {
        Modal.hide();
      }
    }
  }

  // svelte-ignore non_reactive_update
  let container: HTMLDivElement;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let SvelteComponent = $derived<Component<any, any, any> | undefined>(
    //@ts-expect-error Can't be bothered to type this
    modalController.screens[modalController.activeScreenIndex]?.component
  );
  let direction = $derived(modalController.mostRecentZoomDirection);

  const transitionDuration = 250;
  let transitionCounter = 0;
  let containerStartingHeight = 0;
  let runningAnimation: ReturnType<typeof animate> | undefined;
</script>

<svelte:window onkeydown={onKeyDown} />

<div class="MultiPanelModal">
  {@render topnav?.()}

  <div class="MultiPanelModal__container" bind:this={container}>
    {#key modalController.activeScreenIndex}
      <div
        class="MultiPanelModal__screen"
        in:fly={{
          x: direction * container.clientWidth,
          opacity: 0.75,
          duration: transitionDuration,
          easing: quadInOut,
        }}
        out:fly={{
          x: -direction * container.clientWidth,
          opacity: 0.75,
          duration: transitionDuration,
          easing: quadInOut,
        }}
        onoutrostart={() => {
          transitionCounter++;
          containerStartingHeight = container.clientHeight;
          container.style.height = `${container.clientHeight}px`;
          container.inert = true;
          container.style.overflow = 'hidden';
        }}
        onintrostart={({ currentTarget: incomingScreen }) => {
          // Calculate available space
          const maxNodeHeight = window.innerHeight - container.getBoundingClientRect().top - 21;

          incomingScreen.style.width = `${incomingScreen.clientWidth}px`;
          incomingScreen.style.maxHeight = `${maxNodeHeight}px`;
          incomingScreen.style.position = 'absolute';

          // Get natural height within constraints
          const newHeight = Math.min(incomingScreen.scrollHeight, maxNodeHeight);

          runningAnimation?.complete();
          runningAnimation = animate(
            container,
            { height: [containerStartingHeight, newHeight] },
            {
              duration: transitionDuration / 1000,
              ease: [0.4, 0, 0.6, 1],
            }
          );
        }}
        onintroend={({ currentTarget: incomingScreen }) => {
          transitionCounter--;
          incomingScreen.style.position = 'relative';
          incomingScreen.style.width = '';
          incomingScreen.style.maxHeight = '100%';
          if (transitionCounter == 0) {
            container.style.height = 'auto';
            container.inert = false;
            container.style.overflow = 'visible';
          }
        }}
        tabindex="-1"
      >
        {#if SvelteComponent}
          <SvelteComponent {...modalController.screens[modalController.activeScreenIndex]?.props} />
        {/if}
      </div>
    {/key}
  </div>
</div>

<style>
  .MultiPanelModal {
    min-width: var(--panelMinWidth);
    max-width: calc(100vw - 2rem);
    width: var(--panelWidth);
    display: flex;
    flex-direction: column;
    max-height: 100%;
    min-height: 0;
    cursor: default;
    user-select: none;
  }

  .MultiPanelModal__container {
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    min-height: 0;
    border-radius: 0 0 10px 10px;
    background-color: var(--panelBg);
  }

  .MultiPanelModal__screen {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    min-height: 4rem;
    border-radius: 0 0 10px 10px;
    background-color: var(--panelBg);

    &:focus {
      outline: 0;
    }
  }
</style>
