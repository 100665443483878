<script lang="ts">
  interface Props {
    text?: string;
    delayBeforeTyping?: number;
    typingDuration?: number;
    typingLetterDelay?: number;
  }

  let {
    text = '',
    delayBeforeTyping = 0,
    typingDuration = 0,
    typingLetterDelay = 0,
  }: Props = $props();

  let typingPosition = $state(0);

  const startTypingAt = performance.now() + delayBeforeTyping;

  function type() {
    const now = performance.now();
    const progress = (now - startTypingAt) / typingDuration;
    typingPosition = Math.min(processedText.length, Math.floor(progress * processedText.length));
    if (typingPosition < processedText.length)
      setTimeout(type, typingDuration / (processedText.length + 1));
  }
  let processedText = $derived(text.replace('\\n', '\n'));
  $effect(() => {
    if (typingLetterDelay > 0) typingDuration = typingLetterDelay * processedText.length;
  });
  $effect(() => {
    if (typingDuration === 0) typingPosition = processedText.length;
    else type();
  });
</script>

<div class="font-hints text-hintColor relative select-none text-left text-xl leading-[1.375rem]">
  <div class="absolute left-0 top-0 whitespace-pre">
    {processedText.substring(0, typingPosition)}
  </div>
  <div class="whitespace-pre text-transparent">{processedText}</div>
</div>
