import SongReferencePlatforms from '@/references/SongReferencePlatforms';

const ReferenceEmbedDisplay = {};
const _embedData = new ReactiveDict();
const _contentTypesVisible = new ReactiveDict();

ReferenceEmbedDisplay.show = (url) => {
  SongReferencePlatforms.forEach((platform) => {
    if (platform.urlRegex.test(url) && 'getEmbedCode' in platform) {
      const contentType = platform.isText ? 'text' : 'media';
      _embedData.set(contentType, {
        url: Meteor.isCordova ? url.replace(/http:/, 'https:') : url, // gotta be secure in Cordova
        providerId: platform.id,
        providerName: platform.name,
        isText: platform.isText,
      });
      platform.getEmbedCode(url).then((embedCode) => {
        _embedData.set(
          contentType,
          Object.assign(_embedData.get(contentType) || {}, { embedCode })
        );
      });
      _contentTypesVisible.set(contentType, true);
    }
  });
};

ReferenceEmbedDisplay.supportsUrl = (url) => {
  if (Meteor.isCordova && /dropbox.com/.test(url)) return false;
  return !!SongReferencePlatforms.find(
    (platform) => platform.urlRegex.test(url) && 'getEmbedCode' in platform
  );
};

ReferenceEmbedDisplay.visible = (type) => _contentTypesVisible.get(type);
ReferenceEmbedDisplay.embedData = (type) => _embedData.get(type) || {};
ReferenceEmbedDisplay.hide = (type) =>
  type
    ? _contentTypesVisible.set(type, false)
    : _contentTypesVisible.set('text', false) && _contentTypesVisible.set('media', false);

export default ReferenceEmbedDisplay;
