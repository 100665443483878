<script lang="ts">
  import GlobalInstrumentMixerRow from '@/audio/mixer/ui/GlobalInstrumentMixerRow.svelte';
  import PanelModalLayout from '@/band/ui/panels/PanelModalLayout.svelte';
  import { Crnt } from '@/Crnt';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';

  // Not using $bandMenu so that this works in other settings menus
  const band = Crnt.song()?.band;

  const allInstrumentIds = ['guitar', 'bass', 'mandolin', 'feet'] as InstrumentId[];

  let activeInstrumentIds: InstrumentId[] = $state([]);
  trackerEffect(() => (activeInstrumentIds = band ? band.activeInstruments() : allInstrumentIds));

  let inactiveInstrumentIds: InstrumentId[] = $state([]);
  trackerEffect(() => (inactiveInstrumentIds = band ? band.inactiveInstruments() : []));
</script>

<PanelModalLayout>
  {#snippet header()}
    <header class="panelHeader">
      These settings will be used in every song that doesn't have custom volume/pan settings.
    </header>
  {/snippet}

  {#snippet main()}
    <div class="space-y-4 p-4">
      <ul class="space-y-4">
        {#each activeInstrumentIds as instId (instId)}
          <li>
            <GlobalInstrumentMixerRow
              {band}
              {instId}
              background="white"
              inactive={false}
              canBeWide={false}
            />
          </li>
        {/each}
      </ul>

      {#if inactiveInstrumentIds.length > 0}
        <div class="pl-2.5 pt-2 text-center text-sm text-gray-500">
          Not used in the current band:
        </div>
      {/if}

      <ul class="space-y-4">
        {#each inactiveInstrumentIds as instId (instId)}
          <li>
            <GlobalInstrumentMixerRow
              {band}
              {instId}
              background="white"
              inactive={true}
              canBeWide={false}
            />
          </li>
        {/each}
      </ul>

      <div class="px-5 pt-2 text-center text-sm text-gray-500">
        You can also access these controls from any song page by going to the right-side menu &gt;
        Audio/Mix.
      </div>
    </div>
  {/snippet}
</PanelModalLayout>
