import type { BuiltInPresetDefinition } from '@/band/presets/BuiltInPresetDefinition';

export const oldTimePresetDefinitions: Partial<Record<TimeSignature, BuiltInPresetDefinition[]>> = {
  // --- 4/4 ---

  '4/4': [
    {
      id: 'ot-s',
      name: 'Old-Time',
      genreId: 'ot',
      genreCategoryId: 'ot-n',
      description: 'Old-time backup for major songs and tunes, with automatic guitar bass runs.',
      band: {
        'g_c': 'bc',
        'g_bn': { 'm': 'a', 'l': 0.75 },
        'g_br': { 'e': 0.5, 'qeb': 0, 'jf': 'sm' },
        'g_ov': { 'mj': 'gacde', 'gv': 'G' },
        'm_cd': { 'i': 0, 's': 2 },
      },
    },

    {
      id: 'ot-m',
      name: 'Old-Time - Modal',
      genreId: 'ot',
      genreCategoryId: 'ot-m',
      description: 'Backup for modal songs and tunes; avoid thirds in chord voicings and runs.',
      band: {
        'g_c': 'bc',
        'g_bn': { 'm': 'a' },
        'g_br': { 'e': 0.25, 'qeb': 0, 'jf': 'b' },
        'g_ov': { 'mj': 'gd' },
        'm_cd': { 'i': 0, 's': 1 },
      },
    },
  ],

  // --- 3/4 ---

  '3/4': [
    {
      id: 'otw-s',
      name: 'Old-Time Waltz',
      genreId: 'ot',
      genreCategoryId: 'ot-n',
      description: 'Simple boom-chuck backup for waltzes.',
      band: {
        'g_c': 'bcw',
        'g_bn': {
          'm': 'a',
          'l': 0.5,
        },
        'm_cd': { 'i': 0, 's': 2 },
      },
    },
  ],
};
