<script lang="ts">
  import { debounce } from 'underscore';
  import { eventTracker } from '@/browser/analytics/eventTracker';
  import type { Song } from '@/chart/Song';
  import { Conductor } from '@/Conductor';
  import { Crnt } from '@/Crnt';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import { Medley } from '@/medleys/Medley';
  import { tpmToBpm } from '@/music/bpm-tpm-conversion';
  import ScalarInputWithTrimmers from '@/ui/ScalarInputWithTrimmers.svelte';
  import { UserProfile } from '@/user/UserProfile';

  let song = $state<Song | undefined>();
  trackerEffect(() => (song = Crnt.song()));

  let medley = $state<Medley | undefined>();
  trackerEffect(() => (medley = Crnt.medley()));

  let tpmBounds = $state<{ min: number; max: number } | undefined>();
  trackerEffect(() => (tpmBounds = song?.prefs.tpmBounds()));

  let timeSignature = $state<TimeSignature | undefined>();
  trackerEffect(() => {
    timeSignature = Crnt.song()?.timeSignature();
  });

  let value = $state<number | undefined>();
  trackerEffect(() => {
    if (Conductor.ready()) value = Crnt.song()?.bpm() || Crnt.medley()?.bpm();
  });

  let min = $derived(
    song && tpmBounds && timeSignature
      ? tpmToBpm({
          tpm: tpmBounds?.min ?? 0,
          timeSignature,
        })
      : Medley.MIN_BPM
  );

  let max = $derived(
    song && tpmBounds && timeSignature
      ? tpmToBpm({
          tpm: tpmBounds?.max ?? 0,
          timeSignature,
        })
      : Medley.MAX_BPM
  );

  function adjustFunc(amount: number) {
    (song?.prefs ?? medley)?.adjustBpm(amount);
    recordAdjustMilestoneDebounced();
    trackAdjustEventDebounced();
  }

  function setValueFunc(value: string | number) {
    if (+value < 10) return;
    (song?.prefs ?? medley)?.setBpm(value);
    UserProfile.recordMilestone('BPM_SET');
    const newBpm = (song?.prefs ?? medley)?.bpm();
    if (newBpm) eventTracker.bpmSet({ to: newBpm });
  }

  const recordAdjustMilestoneDebounced = debounce(
    () => UserProfile.recordMilestone('BPM_ADJUST'),
    1000
  );

  const trackAdjustEventDebounced = debounce(() => {
    const newBpm = (song?.prefs ?? medley)?.bpm();
    if (newBpm) eventTracker.bpmAdjust({ to: newBpm });
  }, 2000);
</script>

<ScalarInputWithTrimmers
  {value}
  {min}
  {max}
  unit="BPM"
  increment={5}
  delay={true}
  masterBPM={true}
  {setValueFunc}
  {adjustFunc}
/>
