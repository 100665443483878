import validUrl from 'valid-url';
import { providerInfo, refTypeInfo } from '@/collections/schemas/SongReferenceSchema';
import { Crnt } from '@/Crnt';
import SongReferencePlatforms from '@/references/SongReferencePlatforms';
import { Roles } from '@/Roles';
import { rpcProcessReferenceURL } from '@/server/methods/references/rpcProcessReferenceURL';

Template.songReferenceForm.onCreated(() => {
  const instance = Template.instance();
  instance.animate = new ReactiveVar();
  instance.errorMessage = new ReactiveVar();
  instance.refData = new ReactiveVar();
  instance.step = new ReactiveVar(1);
  instance.processingUrl = new ReactiveVar(false);
  instance.submittingForm = new ReactiveVar(false);
  instance.refType = new ReactiveVar();
  instance.refTypeFixed = new ReactiveVar();

  instance.refProviders = [];
  instance.refProvidersDep = new Tracker.Dependency();

  instance.keyupHandler = (event) => {
    if (!instance.step.get() == 2) return;
    const matchingRefType = refTypeInfo.find((t) => t.id == event.key.toLowerCase());
    if (matchingRefType) {
      instance.$(`.js-setRefType[data-ref-type="${matchingRefType.id}"]`).click();
    }
  };
  document.addEventListener('keyup', instance.keyupHandler);
});

Template.songReferenceForm.onRendered(() => {
  const instance = Template.instance();
  instance.find('.songReferenceForm')._uihooks = {
    insertElement(node, next) {
      $(node).insertBefore(next);
      if (instance.animate.get()) {
        $(node).hide().slideDown({ duration: 200 });
      }
    },
    removeElement(node) {
      $(node).remove();
      // if (instance.step.get() > 1) {
      //   $(node).slideUp({ duration: 200 })
      //     .promise()
      //     .done(function () {
      //       $(node).remove();
      //     });
      // }
    },
  };
});

Template.songReferenceForm.onDestroyed(() => {
  const instance = Template.instance();
  document.removeEventListener('keyup', instance.keyupHandler);
});

Template.songReferenceForm.helpers({
  isZero(number) {
    return number === 0;
  },
  onStep(step) {
    return Template.instance().step.get() == +step;
  },
  pastStep(step) {
    return Template.instance().step.get() > +step;
  },
  processingUrl() {
    return Template.instance().processingUrl.get();
  },
  submittingForm() {
    return Template.instance().submittingForm.get();
  },
  errorMessage() {
    return Template.instance().errorMessage.get();
  },
  linkingToHeading() {
    Template.instance().refProvidersDep.depend();
    const providers = Template.instance().refProviders.filter((p) => p.selected);
    if (providers.length > 0) {
      const extraProviderList = providers
        .filter((p, index) => index > 0)
        .map((p) => p.prettyProvider);
      const suffix =
        extraProviderList.length > 0 ? ` plus ${extraProviderList.join(' and ')}:` : ':';
      return `You’re linking to <strong>${providers[0].prettyProvider}</strong>${suffix}`;
    } else {
      const refType = Template.instance().refType.get();
      if (!refType) return 'Choose a reference type:';
      let end = ':';
      if (refType == 'r') {
        end = ' a recording:';
      } else if (refType == 'l') {
        end = ' a lesson:';
      } else if (refType == 'w') {
        end = ' lyrics:';
      } else if (/\/\/tunearch\.org\/wiki\/\w/i.test(Template.instance().refData.get().url)) {
        end = ' the Traditional Tune Archive entry for:';
      }
      return 'You are linking to' + end;
    }
  },
  refTitle() {
    return Template.instance().fixedTitle
      ? Template.instance().initialTitle
      : Template.instance().refData.get().title;
  },
  selectedProviders() {
    Template.instance().refProvidersDep.depend();
    return Template.instance().refProviders.filter((p) => p.selected);
  },
  showSonglinkAttribution() {
    Template.instance().refProvidersDep.depend();
    return Template.instance().refProviders.filter((p) => p.selected).length > 0;
  },
  // providerExcluded(p) {
  //   return Template.instance().providersToExclude.get(p);
  // },
  isRecording() {
    return Template.instance().refType.get() == 'r';
  },
  isLesson() {
    return Template.instance().refType.get() == 'l';
  },
  isOther() {
    return Template.instance().refType.get() == 'o';
  },
  showTitle() {
    return Template.instance().refType.get() == 'o' && !Template.instance().fixedTitle;
  },
  isLyrics() {
    return Template.instance().refType.get() == 'w';
  },
  initialArtist() {
    return Template.instance().initialArtist;
  },
  initialTitle() {
    return Template.instance().initialTitle;
  },
  refTypeFriendly() {
    return refTypeInfo.find((t) => t.id == Template.instance().refType.get())?.name;
  },
  keyFor(refType) {
    return refType.id.toUpperCase();
  },
  svgIconFor(provider) {
    return SongReferencePlatforms.find((p) => p.id == provider)?.svgIcon;
  },
  canChangeRefType() {
    return !Template.instance().refTypeFixed.get();
  },
  refTypesAvailable() {
    const refData = Template.instance().refData.get();
    if (!refData) return;
    const onlyVideo =
      (refData.providers &&
        Object.keys(refData.providers).length == 1 &&
        !!refData.providers.youtube) ||
      (!refData.providers && /vimeo\.com/i.test(refData.url));
    if (onlyVideo) {
      return refTypeInfo.filter((type) => type.forVideo);
    }
    return refTypeInfo;
  },
  alternatePlatformsLinkHTML() {
    Template.instance().refProvidersDep.depend();
    const excludedProviders = Template.instance().refProviders.filter((p) => !p.selected);
    const alternates = excludedProviders.filter(
      (p) => p.provider == 'youtube' || p.provider == 'spotify' || p.isOriginal == true
    );
    if (alternates.length == 0) return;
    return (
      '(' +
      alternates
        .map(
          (p) =>
            `<a href="#" class="js-addProvider" data-provider="${p.provider}">${p.prettyProvider}</a>`
        )
        .join(' and ') +
      (alternates.length > 1 ? ' links ' : ' link ') +
      'also available)'
    );
  },
});

// TODO: what about URL escaping?

const processUrl = async function (url) {
  // needs to be bindable
  if (this.processingUrl.get()) return;
  this.errorMessage.set(null);
  setRefData.call(this, null);
  url = url.trim();
  if (!url) {
    this.errorMessage.set('Please paste in a URL to link to.');
    return;
  }
  if (!validUrl.isWebUri(url) && !/^spotify:/.test(url)) {
    this.errorMessage.set('Invalid URL. Try copy-and-pasting it again.');
    return;
  }
  this.processingUrl.set(true);
  try {
    const data = await rpcProcessReferenceURL({ url });
    setRefData.call(this, data);
  } catch (err) {
    console.error(err);
    this.errorMessage.set(err.reason);
  } finally {
    this.processingUrl.set(false);
  }
};

const setRefData = function (data) {
  // needs to be bindable
  this.refType.set(null);
  this.refTypeFixed.set(false);
  this.refData.set(data);
  this.refProviders = [];
  this.initialArtist = null;
  this.initialTitle = null;
  this.fixedTitle = null;
  if (!data) return;
  this.animate.set(true);
  Meteor.setTimeout(() => this.animate.set(false), 100);
  this.step.set(2);
  if (data.providers && Object.keys(data.providers).length > 1) {
    this.step.set(3);
    this.refType.set('r');
    this.refTypeFixed.set(true);
  } else if (
    (data.providers && data.providers.soundcloud) ||
    (!data.providers && /soundcloud\.com\/|clyp\.it\/|\.mp3$/i.test(data.url))
  ) {
    this.step.set(3);
    this.refType.set('r');
    this.refTypeFixed.set(true);
  } else if (
    /bluegrasslyrics\.com\/|bluegrassnet\.com\/|toneway\.com|genius\.com|lyrics\.com/i.test(
      data.url
    )
  ) {
    this.step.set(3);
    this.refType.set('w');
    this.refTypeFixed.set(true);
  } else if (/\/\/tunearch\.org\/wiki\/\w/i.test(data.url)) {
    this.step.set(3);
    this.refType.set('o');
    this.refTypeFixed.set(true);
    this.initialTitle = data.title.replace(/(.+) . (The )?Traditional Tune Archive/, '$1');
    this.fixedTitle = true;
  } else if (/slippery-hill\.com\/recording\/\w/i.test(data.url)) {
    this.step.set(3);
    this.refType.set('r');
    this.refTypeFixed.set(true);
    this.initialArtist = data.artist;
  }

  const contributor = Roles.userHasPermission(Meteor.userId(), 'nominations.insert');
  const preferredPlatforms = ['youtube', 'spotify'];
  if (data.providers && Object.keys(data.providers).length > 0) {
    const providerArray = Object.keys(data.providers)
      .filter((p) => data.originalProvider == p || preferredPlatforms.includes(p))
      .map((p) => ({
        provider: p,
        prettyProvider: providerInfo[p].name,
        ...data.providers[p],
        ...(data.originalProvider == p ? { isOriginal: true } : {}),
        ...(contributor ? { selected: true } : {}),
      }))
      .sort((a, b) =>
        contributor
          ? preferredPlatforms.indexOf(b) - preferredPlatforms.indexOf(a)
          : a.isOriginal
            ? -1
            : 1
      );
    providerArray[0].selected = true;

    this.refProviders = providerArray;
    this.refProvidersDep.changed();

    this.initialArtist = Object.values(providerArray).find(
      (p) => p.provider != 'youtube'
    )?.artistName;
    this.initialTitle = Object.values(providerArray).find((p) => p.isOriginal)?.title || data.title;
  }
  this.refProvidersDep.changed();

  Meteor.setTimeout(() => this.$('.js-setRefType').first().focus(), 100);
};

Template.songReferenceForm.events({
  'keyup .js-urlInput': function (event, instance) {
    if (event.key == 'Enter') {
      processUrl.call(instance, instance.$('.js-urlInput').val());
    }
  },
  'paste .js-urlInput': function (event, instance) {
    Meteor.defer(() => {
      const url = instance.$('.js-urlInput').val().trim();
      if (validUrl.isWebUri(url) || /^spotify:/.test(url)) {
        processUrl.call(instance, url);
      }
    });
  },

  'click .js-processUrl': function (event, instance) {
    event.preventDefault();
    processUrl.call(instance, instance.$('.js-urlInput').val());
  },

  'click .js-goToStep': function (event, instance) {
    event.preventDefault();
    instance.step.set(+$(event.target).data('step'));
  },

  'click .js-resetRefType': function (event, instance) {
    event.preventDefault();
    instance.refType.set(null);
    instance.step.set(2);
  },
  'click .js-setRefType': function (event, instance) {
    event.preventDefault();
    instance.refType.set(event.currentTarget.dataset['refType']);
    instance.step.set(3);
    instance.animate.set(true);
    Meteor.setTimeout(() => instance.animate.set(false), 100);
  },

  'click .js-addProvider': function (event, instance) {
    event.preventDefault();
    const provider = event.currentTarget.dataset['provider'];
    instance.refProviders.find((p) => p.provider == provider).selected = true;
    instance.refProvidersDep.changed();
  },
  'click .js-removeProvider': function (event, instance) {
    event.preventDefault();
    const provider = event.currentTarget.dataset['provider'];
    instance.refProviders.find((p) => p.provider == provider).selected = null;
    instance.refProvidersDep.changed();
  },
  'click .js-promoteProvider': function (event, instance) {
    event.preventDefault();
    const provider = event.currentTarget.dataset['provider'];
    const index = instance.refProviders.findIndex((p) => p.provider == provider);
    const [item] = instance.refProviders.splice(index, 1);
    instance.refProviders.unshift(item);
    instance.refProvidersDep.changed();
  },
  'submit form': function (event, instance) {
    event.preventDefault();
    if (instance.submittingForm.get()) return;
    instance.submittingForm.set(true);
    const providers = instance.refProviders.filter((p) => p.selected);
    const data = {
      type: instance.refType.get(),
      url: providers.length > 0 ? providers[0].url : instance.refData.get().url,
      title: instance.fixedTitle
        ? instance.initialTitle
        : instance.find('.js-titleInput')?.value || undefined,
      artist: instance.find('.js-artistInput')?.value || undefined,
      instrument: instance.find('.js-instrumentInput')?.value || undefined,
      description: instance.find('.js-descriptionInput')?.value || undefined,
      alternates:
        providers.length > 1
          ? providers.filter((p, index) => index > 0).map((p) => p.url)
          : undefined,
    };
    Crnt.references()
      .add(data)
      .then((result) => {
        $(".js-global-referencesDropdown:not('.open')").find('.dropdown-toggle').first().click();
      })
      .catch((err) => {
        console.error(err);
        Bert.alert(err, 'warning');
      })
      .finally((err) => {
        instance.submittingForm.set(false);
        Modal.hide();
      });
  },
  'click .js-close': function (event, instance) {
    Modal.hide();
  },
});
