export const legacyPresetsById: Record<
  string,
  {
    name: string;
    timeSignature: TimeSignature;
    newPresetId: string;
  }
> = {
  bg: {
    name: 'Bluegrass',
    timeSignature: '4/4',
    newPresetId: 'bg-s',
  },

  ot: {
    name: 'Old-Time',
    timeSignature: '4/4',
    newPresetId: 'ot-s',
  },

  ce: {
    name: 'Celtic Reel',
    timeSignature: '4/4',
    newPresetId: 'ce-l',
  },

  qr: {
    name: 'Québécois Reel',
    timeSignature: '4/4',
    newPresetId: 'ca-qbr',
  },

  sw: {
    name: 'Swing',
    timeSignature: '4/4',
    newPresetId: 'sw-b',
  },

  ws: {
    name: 'Texas/Western Swing',
    timeSignature: '4/4',
    newPresetId: 'ws-b',
  },

  gj: {
    name: 'Gypsy Jazz',
    timeSignature: '4/4',
    newPresetId: 'gj-b',
  },

  bgw: {
    name: 'Bluegrass Waltz',
    timeSignature: '3/4',
    newPresetId: 'bgw-s',
  },

  otw: {
    name: 'Old-Time Waltz',
    timeSignature: '3/4',
    newPresetId: 'otw-s',
  },

  cj: {
    name: 'Celtic Jig',
    timeSignature: '6/8',
    newPresetId: 'cj-l',
  },

  csj: {
    name: 'Celtic Slip Jig',
    timeSignature: '9/8',
    newPresetId: 'csj-l',
  },
};
