import { eventTracker } from '@/browser/analytics/eventTracker';
import { Crnt } from '@/Crnt';
import ScalarInputWithTrimmers from '@/ui/ScalarInputWithTrimmers.svelte';
import { UserProfile } from '@/user/UserProfile';

Template.autoSpeedupSettings.onCreated(() => {
  Template.instance().maxBpmInputFocused = new ReactiveVar(false);
});

Template.autoSpeedupSettings.onRendered(() => {
  const instance = Template.instance();
  UserProfile.recordMilestone('AUTO_SPEEDUP_OPEN');
  eventTracker.autoSpeedupOpenMenu();
  instance.autorun(() => {
    if (Crnt.song()?.prefs.autoSpeedup.enabled() && !instance.maxBpmInputFocused.get()) {
      Meteor.setTimeout(() => {
        instance.$('.js-maxBpmTextInput').val(`${Crnt.song()?.prefs.autoSpeedup.maxBpm()} BPM`);
      }, 1);
    }
  });
});

Template.autoSpeedupSettings.helpers({
  currentValue() {
    return Crnt.song()?.prefs.autoSpeedup.bpmDelta();
  },
  adjustFunc() {
    return function (increment) {
      const autoSpeedup = Crnt.song()?.prefs.autoSpeedup;
      if (!autoSpeedup) return;
      if (autoSpeedup.bpmDelta() == -increment) autoSpeedup.setBpmDelta(increment);
      else autoSpeedup.adjustBpmDelta(increment);
      UserProfile.recordMilestone('AUTO_SPEEDUP_SET');
      eventTracker.autoSpeedupAdjustDelta({ to: autoSpeedup.bpmDelta() });
    };
  },
  setValueFunc() {
    return function (value) {
      const autoSpeedup = Crnt.song()?.prefs.autoSpeedup;
      if (!autoSpeedup) return;
      if (/[1-9]/.test(value)) {
        autoSpeedup.setBpmDelta(value);
        UserProfile.recordMilestone('AUTO_SPEEDUP_SET');
        eventTracker.autoSpeedupSetDelta({ to: autoSpeedup.bpmDelta() });
      }
    };
  },
  enabled() {
    return Crnt.song()?.prefs.autoSpeedup.enabled();
  },
  negativeDelta() {
    return Crnt.song()?.prefs.autoSpeedup.bpmDelta() < 0;
  },
  hideDecreaseDeltaButton() {
    return (
      Crnt.song()?.prefs.autoSpeedup.bpmDelta() >= 0 &&
      Crnt.song()?.prefs.autoSpeedup.bpmDelta() <= 1
    );
  },
  maxBpmCurrentValue() {
    return Crnt.song()?.prefs.autoSpeedup.maxBpm();
  },
  maxBpmEnabled() {
    return Crnt.song()?.prefs.autoSpeedup.maxBpm();
  },
  maxBpmInputFocused() {
    return Template.instance().maxBpmInputFocused.get();
  },
  repetitionWord() {
    var reps = Crnt.song()?.prefs.autoSpeedup.reps();
    return reps > 1 ? reps + ' reps' : 'repetition';
  },
  ScalarInputWithTrimmers() {
    return ScalarInputWithTrimmers;
  },
});

Template.autoSpeedupSettings.events({
  'click .js-enableLimit': function (event, instance) {
    const song = Crnt.song();
    if (song) {
      song.prefs.autoSpeedup.setMaxBpm(song.bpm() + 20);
      eventTracker.autoSpeedupEnableMaxBPM({ to: song.prefs.autoSpeedup.maxBpm() });
      Meteor.setTimeout(() => {
        instance.$('.js-maxBpmTextInput').focus().val('');
      }, 10);
    }
    return false;
  },
  'click .js-disableLimit': function (event, instance) {
    Crnt.song()?.prefs.autoSpeedup.setMaxBpm(null);
    eventTracker.autoSpeedupDisableMaxBPM();
    return false;
  },
  'click .js-repCountDropdown a': function (event, instance) {
    Crnt.song()?.prefs.autoSpeedup.setReps($(event.currentTarget).data('value'));
    eventTracker.autoSpeedupSetReps({ to: Crnt.song()?.prefs.autoSpeedup.reps() });
  },
  'click .js-maxBpmConfirm': function (event, instance) {
    $(event.target).blur();
  },
  'keyup .js-maxBpmTextInput': function (event, instance) {
    if (event.key == 'Enter' || event.key == 'NumpadEnter' || event.key == 'Escape') {
      instance.$('.js-maxBpmTextInput').blur();
      return;
    }
    var value = instance.$('.js-maxBpmTextInput').val();
    var cleanedValue = value.replace(/[^0-9]/g, '').substring(0, 3);
    if (value != cleanedValue) {
      instance.$('.js-maxBpmTextInput').val(cleanedValue);
    }
  },
  'focus .js-maxBpmTextInput': function (event, instance) {
    instance.maxBpmInputFocused.set(true);
    var $input = instance.$('.js-maxBpmTextInput');
    $input.val(Crnt.song()?.prefs.autoSpeedup.maxBpm());
    $input.select();
    $input[0].setSelectionRange(0, 9999);
  },
  'input .js-maxBpmTextInput': function (event, instance) {
    const value = instance.$('.js-maxBpmTextInput').val();
    if (/\d\d/.test(value)) {
      setMaxBpm(value);
    }
  },
  'blur .js-maxBpmTextInput': function (event, instance) {
    const value = instance.$('.js-maxBpmTextInput').val();
    Meteor.setTimeout(() => {
      setMaxBpm(value);
      instance.maxBpmInputFocused.set(false);
      eventTracker.autoSpeedupSetMaxBPM({ to: Crnt.song()?.prefs.autoSpeedup.maxBpm() });
    }, 120); // slight delay so that the OK button can be clicked (seemingly)
  },
});

const setMaxBpm = (value) => {
  const song = Crnt.song();
  const maxHigherThanCurrentBpm = value && value > 20 && value < song.bpm();
  if (maxHigherThanCurrentBpm) song.prefs.setBpm(value - 10);
  song.prefs.autoSpeedup.setMaxBpm(value);
};
