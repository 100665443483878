import { Crnt } from '@/Crnt';
import { UserProfile } from '@/user/UserProfile';

Template.chordSelectionHint.helpers({
  focusAria() {
    const cells = Crnt.song()?.focus.cells() || [];
    const [firstCell, lastCell] = Crnt.song()?.focus.cells() || [];
    return firstCell
      ? firstCell == lastCell
        ? `Editing ${['first', 'second', 'third', 'fourth'][firstCell.layout.barPos]} cell of measure ${firstCell.layout.barIndex + 1} in section ${firstCell.layout.sectionIndex + 1}, currently ${firstCell.chordShown.toAria()}`
        : `Editing beats ${firstCell.layout.cellIndex + 1} through ${lastCell.layout.cellIndex + 1} of section ${firstCell.layout.sectionIndex + 1}`
      : null;
  },
  showHint() {
    return (
      UserProfile.notMilestone('NEW_SONG') &&
      UserProfile.notMilestone('EDIT_SONG') &&
      UserProfile.notMilestone('CHORD_SET') &&
      UserProfile.notMilestone('EDIT_KEYS') &&
      Crnt.song()?.sections.reactive()[0]?.cells.length > 1
    );
  },
  showKeyboardHintEllipsis() {
    return !window.matchMedia('(max-width: 550px)').matches;
  },
});
