<script lang="ts">
  import { onDestroy, onMount } from 'svelte';
  import { Conductor } from '@/Conductor';
  import waitUntilReactive from '@/utilities/waitUntilReactive';

  interface Props {
    gainNode: GainNode;
    compressorNode?: DynamicsCompressorNode | undefined;
    decayConstant?: number;
  }

  let { gainNode, compressorNode = undefined, decayConstant = 1 }: Props = $props();

  let volumeLevel = $state(-100);
  let volumeMeter = $state(-100);
  let compressionLevel = $state(0);

  const minDb = 54;

  let stopAnalyzing = false;

  onMount(async () => {
    await waitUntilReactive(() => Conductor.ready());

    const analyzer = gainNode.context.createAnalyser();
    analyzer.fftSize = 4096;
    const dataArray = new Float32Array(analyzer.fftSize);
    gainNode.connect(analyzer);

    let lastMeterDB = 0;
    (function updateLevelMeter() {
      if (stopAnalyzing) return;

      analyzer.getFloatTimeDomainData(dataArray);
      const peak = Math.max(-Math.min(...dataArray), Math.max(...dataArray));
      volumeLevel = 20 * Math.log10(peak);
      volumeMeter = Math.max(volumeLevel, lastMeterDB - decayConstant);
      lastMeterDB = volumeMeter;

      if (compressorNode) {
        compressionLevel = -compressorNode.reduction; // in dB
      }
      requestAnimationFrame(updateLevelMeter);
    })();
  });

  onDestroy(() => (stopAnalyzing = true));
</script>

<div class="stack">
  <div class="meter">
    <div class="compressionBar" style="--value: {Math.max(0, compressionLevel / minDb)};"></div>
    <div class="volumeBar" style="--value: {Math.max(0, 1 - -volumeMeter / minDb)};"></div>
  </div>
  <div class="numbers">
    {volumeLevel.toFixed(1).replace('Infinity', '-')}<br />
    {@html compressionLevel == 0 ? '&nbsp;' : compressionLevel.toFixed(1)}
  </div>
</div>

<style>
  .stack {
    height: 100%;
    min-width: 4em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .meter {
    flex: 1 1 auto;
    width: 30px;
    position: relative;
    background: linear-gradient(
        to top,
        #fff 0%,
        #eee 9.9%,
        #ddd 10%,
        #ddd 19.9%,
        #eee 20%,
        #eee 29.9%,
        #ddd 30%,
        #ddd 39.9%,
        #eee 40%,
        #eee 49.9%,
        #ddd 50%,
        #ddd 59.9%,
        #eee 60%,
        #eee 69.9%,
        #ddd 70%,
        #ddd 79.9%,
        #eee 80%,
        #eee 89.9%,
        #edd 90%,
        #fcc 99.9%
      )
      100% 100%;
  }
  .compressionBar {
    position: absolute;
    left: 0px;
    top: 10%;
    width: 10px;
    height: 100%;
    background-color: red;
    clip-path: inset(0 0 calc((1 - var(--value)) * 100%) 0);
  }
  .volumeBar {
    position: absolute;
    left: 10px;
    top: 0;
    width: 10px;
    height: 100%;
    background: linear-gradient(
        to top,
        #0a9600 0%,
        #37ff00 71%,
        #ffe502 89.8%,
        #ff0000 90%,
        #ff0000 100%
      )
      100% 100%;
    clip-path: inset(calc(10% + 90% * (1 - var(--value))) 0 0 0);
  }
</style>
