import { bassNote, strumDown } from '@/band/instruments/guitar/GuitarActions';
import type { GuitarIntent } from '@/band/instruments/guitar/GuitarIntent';
import type { GuitarSettingsHash } from '@/band/instruments/guitar/GuitarSettingsHash';
import type { LinearizedMeasure } from '@/chart/LinearizedMeasure';
import { lerp } from '@/utilities/lerp';

export function boomChuckEndingMeasure(
  measure: LinearizedMeasure,
  settings: GuitarSettingsHash
): (GuitarIntent | null)[] {
  const { boomPower, chuckPower } = settings.boomChuckBalance;
  return measure.cells.flatMap((cell) => {
    if (cell.split) {
      const averagePower = (boomPower + chuckPower) / 2;
      return cell.beats.flatMap(() => [
        strumDown('root', {
          spread: lerp(2.25, 3.75, averagePower),
          db: lerp(-9, 3, averagePower),
        }),
        null,
      ]);
    }
    const lastStrumPower = Math.max(boomPower, chuckPower);
    return [
      cell.layout.barEnd
        ? strumDown(measure.cells[0].split ? 'root' : 'strum', {
            spread: lerp(3, 4.4, lastStrumPower),
            db: lerp(-4, 2, lastStrumPower),
          })
        : bassNote('root', {
            db: lerp(-7, 5, boomPower),
          }),
      null,
      null,
      null,
    ];
  });
}
