import {
  bassNote,
  pickSpecified,
  strumDown,
  strumUp,
} from '@/band/instruments/guitar/GuitarActions';
import type { GuitarIntent, GuitarIntentStrum } from '@/band/instruments/guitar/GuitarIntent';
import type { GuitarBassNotesSetting } from '@/band/instruments/guitar/settings/GuitarBassNotesSetting';
import type { GuitarBoomChuckWaltzBalanceSetting } from '@/band/instruments/guitar/settings/GuitarBoomChuckWaltzBalanceSetting';
import type { GuitarOpenVoicingsSetting } from '@/band/instruments/guitar/settings/GuitarOpenVoicingsSetting';
import type { LinearizedCell } from '@/chart/LinearizedCell';
import { lerp } from '@/utilities/lerp';
import { randomPlusMinus } from '@/utilities/randomPlusMinus';

const zeroThroughFive = [0, 1, 2, 3, 4, 5] as const;
type BeatAction = 'strum' | 'pick' | 'interval';

export function boomChuckWaltzCell(
  cell: LinearizedCell,
  {
    boomChuckWaltzBalance,
    bassNotes,
    openVoicings,
  }: {
    boomChuckWaltzBalance: GuitarBoomChuckWaltzBalanceSetting;
    bassNotes: GuitarBassNotesSetting;
    openVoicings: GuitarOpenVoicingsSetting;
  }
): (GuitarIntent | null)[] {
  const { boomPower, chuck1Power, chuck2Power } = boomChuckWaltzBalance;

  const beatPowers = [boomPower, 0.75, chuck1Power, 0.75, chuck2Power, 0.75] as SixNumbers;

  const chuck1Spread = lerp(2, 4.2, chuck1Power) + randomPlusMinus(0.4);
  const chuck2Spread = lerp(2, 4.4, chuck2Power) + randomPlusMinus(0.4);
  const beatSpreads = [3, 0, chuck1Spread, 0, chuck2Spread, 0] as SixNumbers;

  const beat1RootOrFifth =
    bassNotes.altBass && !cell.layout.barStart && !cell.beats[0].chordChanged ? 'fifth' : 'root';

  const beatBiases: SixOf<GuitarIntentStrum['bias']> = [
    beat1RootOrFifth,
    'mid',
    'strum',
    'mid',
    'strum',
    'mid',
  ];

  const beatActions = ['pick', null, 'strum', null, 'strum', null] as SixOf<BeatAction | null>;

  // Bass run handling

  zeroThroughFive.forEach((i) => {
    if (typeof cell.plans.guitar?.bassRun?.intervals[i] == 'number') {
      beatActions[i] = 'interval';
      beatPowers[i] = Math.max(0.6, beatPowers[i]);
    }
  });
  if (beatActions[2] === 'interval') {
    if (beatActions[3] !== 'interval' && Math.random() < 0.6) beatPowers[3] -= Math.random() * 1.4;
    else beatSpreads[3] = Math.min(2, beatSpreads[3]);
  }
  if (beatActions[4] === 'interval') {
    if (beatActions[5] !== 'interval' && Math.random() < 0.4) beatPowers[5] -= Math.random() * 1.2;
    else beatSpreads[5] = Math.min(2, beatSpreads[5]);
  }

  // Split cell handling

  if (cell.beats[1]?.chordChanged) {
    beatActions[0] = 'strum';
    beatPowers[0] = Math.max(0.8, beatPowers[0]);
    beatSpreads[0] = Math.max(3, beatSpreads[0]);
    beatPowers[2] = Math.max(0.8, beatPowers[2]);
    beatSpreads[2] = Math.max(3.5, beatSpreads[2]);
    beatBiases[2] = 'root';
  }

  if (cell.beats[2]?.chordChanged) {
    beatPowers[2] = Math.max(0.3, beatPowers[2]);
    beatPowers[4] = Math.max(0.8, beatPowers[4]);
    beatSpreads[4] = Math.max(3.5, beatSpreads[4]);
    beatBiases[4] = 'root';
  }

  // Calculate dBs

  const beatDbs = [
    beatActions[0] == 'pick' ? lerp(-5, 6, beatPowers[0]) : lerp(-6, 1, beatPowers[0]),
    lerp(-13, 0, beatPowers[1]),
    lerp(-6, 1, beatPowers[2]),
    lerp(-8, 0, beatPowers[3]),
    lerp(-5, 2, beatPowers[4]),
    lerp(-7, 0, beatPowers[5]),
  ].map((db, i) => db + randomPlusMinus(i % 4 > 0 ? 0.5 : 0)) as SixNumbers;

  // Put it all together!

  return zeroThroughFive.map((i) => {
    if (beatActions[i] === 'pick') {
      return bassNote(beat1RootOrFifth, { db: beatDbs[i] });
    }

    if (beatActions[i] === 'strum') {
      return (i % 2 == 0 ? strumDown : strumUp)(beatBiases[i], {
        spread: beatSpreads[i],
        db: beatDbs[i],
      });
    }

    if (beatActions[i] === 'interval') {
      const interval = cell.plans.guitar?.bassRun?.intervals[i];
      if (typeof interval != 'number') return null;
      const stringFret = openVoicings.intervalStringFretInKey(cell.measure.key, interval);
      return pickSpecified(stringFret, { db: beatDbs[i] });
    }

    return null;
  });
}
