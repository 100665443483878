import { ChopMandolinCore } from '@/band/instruments/mandolin/cores/ChopMandolinCore';
import { HalfTimeChopMandolinCore } from '@/band/instruments/mandolin/cores/HalfTimeChopMandolinCore';
import { JigChopMandolinCore } from '@/band/instruments/mandolin/cores/JigChopMandolinCore';
import { SwingMandolinCore } from '@/band/instruments/mandolin/cores/SwingMandolinCore';
import { WaltzChopMandolinCore } from '@/band/instruments/mandolin/cores/WaltzChopMandolinCore';
import type { MandolinCore } from '@/band/instruments/mandolin/MandolinCore';

export const mandolinPatternLineup: Readonly<
  Record<TimeSignature, { heading: string; description?: string; core: MandolinCore }[]>
> = {
  '4/4': [
    {
      heading: ChopMandolinCore.title,
      description: 'Offbeat chop chords, as played in bluegrass.',
      core: ChopMandolinCore,
    },
    {
      heading: SwingMandolinCore.title,
      description: 'Swing-style four-to-the-bar strumming/chopping.',
      core: SwingMandolinCore,
    },
    {
      heading: HalfTimeChopMandolinCore.title,
      description:
        '“Spread out” chops that come half as often as normal, to compliment the half-time guitar strum.',
      core: HalfTimeChopMandolinCore,
    },
  ],

  '3/4': [
    {
      heading: WaltzChopMandolinCore.title,
      description: 'Offbeat chop chords, as played in bluegrass.',
      core: WaltzChopMandolinCore,
    },
  ],

  '6/8': [
    {
      heading: JigChopMandolinCore.title,
      description:
        'Chop on beats 3 and 6. (We know this isn’t suitable for most 6/8 songs; more mandolin styles will be made available in the future.)',
      core: JigChopMandolinCore,
    },
  ],

  '9/8': [
    {
      heading: JigChopMandolinCore.title,
      description:
        'Chop on beats 3, 6, and 9. (We know this isn’t suitable for most 9/8 songs; more mandolin styles will be made available in the future.)',
      core: JigChopMandolinCore,
    },
  ],
};
