<script lang="ts">
  import { strict as assert } from 'assert';
  import { Tracker } from 'meteor/tracker';
  import AudioManager from '@/audio/engine/AudioManager';
  import { executePlayerInstructions } from '@/audio/executePlayerInstructions';
  import { GuitarChordLibrary } from '@/band/instruments/guitar/GuitarChordLibrary';
  import type { GuitarIntent } from '@/band/instruments/guitar/GuitarIntent';
  import { makeInstructionsFromGuitarIntent } from '@/band/instruments/guitar/makeInstructionsFromGuitarIntent';
  import { GuitarOpenVoicingsSetting } from '@/band/instruments/guitar/settings/GuitarOpenVoicingsSetting';
  import GuitarChordCapoPositionDiagram from '@/band/ui/components/GuitarChordCapoPositionDiagram.svelte';
  import HorizontalRadioButtons from '@/band/ui/components/HorizontalRadioButtons.svelte';
  import GuitarOpenVoicingsShapeDropdowns from '@/band/ui/panels/GuitarOpenVoicingsShapeDropdowns.svelte';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';
  import { Conductor } from '@/Conductor';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import { Chord } from '@/music/Chord';

  const bandMenu = getBandMenuState();

  interface Props {
    currentKey: NonNullable<Chord['key']>;
    setting: GuitarOpenVoicingsSetting;
  }

  let { currentKey, setting }: Props = $props();

  let optionsForCurrentKey: ReturnType<(typeof GuitarOpenVoicingsSetting)['positionsInKey']> =
    $state([]);
  let selectedOption: (typeof optionsForCurrentKey)[number] | undefined = $state();

  trackerEffect(
    () => (optionsForCurrentKey = GuitarOpenVoicingsSetting.positionsInKey(currentKey))
  );
  trackerEffect(() => (selectedOption = setting.positionToUseForKey(currentKey)));

  function selectOptionForKey(shape: (typeof optionsForCurrentKey)[number]['shape']) {
    const option = optionsForCurrentKey.find((o) => o.shape == shape);
    assert(option);
    assert(currentKey);
    selectedOption = option;
    const newSetting = setting.withMajorRootShapeInKey(currentKey, shape);
    $bandMenu.guitar.setSetting('openVoicings', newSetting);
    Tracker.afterFlush(() => previewChord());
  }

  function previewChord(interval: number = 0) {
    if (!Conductor.stopped()) return;
    void AudioManager.gonnaPlay().then(() => {
      AudioManager.playSample({ id: 'count-primer', volume: 0.2 });
      const { shape, capo } = setting.positionToUseForKey(currentKey);
      const inG = shape.startsWith('G');
      const inD = shape.startsWith('D');
      const inE = shape.startsWith('E');
      const intent: GuitarIntent = {
        action: 'strum',
        bias: 'mid',
        spread: inG || inE || (inD && setting.dropD) ? 6 : inD ? 4.5 : 5,
        direction: 'D',
        db: -3,
        stringToStringDelay: 0.025,
      };
      const chordToPlay = new Chord(currentKey, currentKey).shift(interval).toString();
      const guitarChord = GuitarChordLibrary.pickChord(chordToPlay, {
        capo,
        dropD: setting.dropD,
        useB7: setting.useB7,
        useCadd9: (inG || inD) && setting.useCadd9,
        useThirdlessG: setting.voicingForG == 'G',
        useThirdlessD: setting.voicingForD == 'D5',
        useSusD: setting.voicingForD == 'D2',
      });
      executePlayerInstructions(
        [{ 'type': 'mute', 'fadeDuration': 0.1, 'channel': 'guitar' }],
        AudioManager.currentTime() + 0.01
      );
      executePlayerInstructions(
        makeInstructionsFromGuitarIntent(intent, guitarChord),
        AudioManager.currentTime() + 0.1
      );
      void AudioManager.gonnaStop();
    });
  }
</script>

<div class="mx-auto flex flex-col items-center">
  <div class="flex flex-row justify-center space-x-3">
    <HorizontalRadioButtons
      options={optionsForCurrentKey.map((o) => ({ caption: o.shape, value: o.shape, meta: o }))}
      selectedValue={selectedOption?.shape}
      noBackground={true}
      on:select={({ detail: shape }) => selectOptionForKey(shape)}
    >
      {#snippet children({ option, selected })}
        {@const { shape, capo } = option.meta}
        <div class="flex flex-col items-center">
          <div class="flex-0 mb-2" class:text-muted-700={selected}>
            {#if capo > 0}
              <span class="font-medium">{shape}</span>
              <span class="text-sm font-normal opacity-70">capo</span>
              {capo}
            {:else}
              Open {shape}
            {/if}
          </div>
          <div class="diagram flex-1 px-1" class:opacity-75={!selected}>
            <GuitarChordCapoPositionDiagram
              chordShape={shape == 'G'
                ? setting.voicingForG
                : shape == 'D'
                  ? setting.voicingForD
                  : shape}
              capoPosition={capo}
              fretsToShow={Math.max(...optionsForCurrentKey.map((o) => o.capo)) + 3}
            />
          </div>
        </div>
      {/snippet}
    </HorizontalRadioButtons>
  </div>

  {#if selectedOption}
    <div class="w-64 pt-1">
      <GuitarOpenVoicingsShapeDropdowns
        shape={selectedOption.shape}
        on:change={({ detail: { interval } }) => previewChord(interval)}
      />
    </div>
  {/if}
</div>

<style>
  .btn {
    .diagram {
      transition: all 0.2s ease;
    }
  }
  .btn[aria-checked='false'] {
    color: var(--gray-400);
    &:hover,
    &:active {
      color: var(--muted-400);
      background: var(--muted-50);
    }
    .diagram {
      filter: sepia(0) hue-rotate(160deg);
    }
  }
  .btn[aria-checked='true'] {
    background: var(--primary-100);
    &:hover,
    &:active {
      background: var(--primary-200);
    }
    .diagram {
      filter: sepia(0.2) hue-rotate(160deg);
    }
  }

  .btn:focus:not(:focus-visible) {
    outline: 0;
    box-shadow: none;
  }
</style>
