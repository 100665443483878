import { dampenedDownbeat } from '@/band/instruments/guitar/blocks/dampenedDownbeat';
import { DampenedWaltzGuitarCore } from '@/band/instruments/guitar/cores/DampenedWaltzGuitarCore';
import { chop, strumDown, strumUp } from '@/band/instruments/guitar/GuitarActions';
import type { GuitarCore } from '@/band/instruments/guitar/GuitarCore';
import type { GuitarSettingsHash } from '@/band/instruments/guitar/GuitarSettingsHash';
import { swingOptions } from '@/band/swingOptions';
import { lerp } from '@/utilities/lerp';
import { randomPlusMinus } from '@/utilities/randomPlusMinus';

export const DampenedGuitarCore: GuitarCore = {
  id: 'd',
  title: 'Dampened strum',

  timeSignatures: ['4/4'],
  equivilantCore(timeSignature: TimeSignature) {
    return timeSignature === '3/4' ? DampenedWaltzGuitarCore : undefined;
  },

  linkedSettingsKeys: [
    'dampenedOnbeats',
    'dampenedSustain',
    'dampenedBackbeat',
    // 'dampenedUpstrokePattern',
  ],
  otherSettingsKeys: ['dampenedFills', 'timing'],

  chordStyle: 'barred',

  swingCategory: 'jazz',
  defaultSwing: swingOptions['2:1'],

  presets: [
    {
      name: 'Strum, dampened strum',
      settings: {
        'dampenedOnbeats': { s: 1 },
        'dampenedSustain': { b1: 1, b2: 0 },
        'dampenedBackbeat': {},
        'dampenedUpstrokePattern': {},
      },
    },
    {
      name: 'Bass note, dampened strum',
      settings: {
        'dampenedOnbeats': { s: 0 },
        'dampenedSustain': { b1: 1, b2: 0 },
        'dampenedBackbeat': {},
        'dampenedUpstrokePattern': {},
      },
    },
    {
      name: 'Dampened strums',
      settings: {
        'dampenedOnbeats': { s: 1 },
        'dampenedSustain': { b1: 1 / 12, b2: 0 },
        'dampenedBackbeat': { v: 1 / 6 },
        'dampenedUpstrokePattern': {},
      },
    },
    {
      name: 'Slow jazz ballad strum',
      settings: {
        'dampenedOnbeats': { s: 1 },
        'dampenedSustain': { b1: 1, b2: 2 / 3 },
        'dampenedBackbeat': { v: 1 / 6 },
        'dampenedUpstrokePattern': {},
      },
    },
    {
      name: 'La Pompe',
      settings: {
        'dampenedOnbeats': { s: 0.5 },
        'dampenedSustain': { b1: 1 / 12, b2: 0 },
        'dampenedBackbeat': {},
        'dampenedUpstrokePattern': {},
      },
    },
  ],

  measurePreprocessing(measures, settings, flags) {
    const upstrokeProbability = settings.dampenedFills.probability;
    if (!flags?.noEmbellishments) {
      measures.forEach((measure) => {
        const col = measure.cells[1]?.layout.column ?? 0;
        const upstroke =
          !measure.cells[1]?.split &&
          Math.random() <
            (col == 7
              ? upstrokeProbability * 3
              : col == 3
                ? (upstrokeProbability - 0.33) * 1.5
                : upstrokeProbability - 0.67);
        if (upstroke && measure.cells[1]) {
          (measure.cells[1].plans.guitar ??= {}).upstrokeAccent = true;
        }
      });
    }
  },

  processMeasure(measure, settings) {
    const [firstCell, secondCell] = measure.cells;
    const { offbeatSustain } = settings.dampenedSustain;
    const offbeatBoost = settings.dampenedBackbeat.offbeatDbBoost;

    if (measure.endOfSong) {
      return [
        chop(offbeatSustain, 'bass', {
          spread: lerp(1.5, 4, settings.dampenedOnbeats.spread),
          db: 2,
        }),
        null,
        null,
        null,
        strumDown('bass', { spread: 5, db: 0 }),
      ];
    }

    const chopSpread = lerp(3.5, 4.2, settings.dampenedBackbeat.value) + randomPlusMinus(0.3);
    const standardCellPattern = [
      dampenedDownbeat(settings),
      null,
      chop(offbeatSustain, 'jazzD', { spread: chopSpread, db: offbeatBoost }),
      null,
    ];

    return [
      ...(firstCell.split ? splitCell(settings) : standardCellPattern),
      ...(measure.cells[1]?.plans.guitar?.upstrokeAccent
        ? [
            strumDown('jazzD', {
              spread: lerp(
                1,
                4 + randomPlusMinus(0.3) - settings.dampenedBackbeat.value * 2,
                settings.dampenedOnbeats.spread
              ),
              db: -settings.dampenedBackbeat.onbeatDbReduction,
            }),
            strumUp('mid', { spread: Math.random() * 2 + 1, db: Math.random() * -3 - 2 }),
            chop(offbeatSustain, 'low', { spread: chopSpread, db: offbeatBoost }),
            null,
          ]
        : secondCell?.split
          ? splitCell(settings)
          : standardCellPattern),
    ];
  },

  generateViz(settings) {
    const onbeatTail = settings.dampenedOnbeats.singleNote
      ? 0.0
      : settings.dampenedOnbeats.shortStrum
        ? 0.3
        : 0.7;
    const backbeat = settings.dampenedBackbeat.value;

    const onbeatViz =
      backbeat < 1
        ? ({
            dir: 'd',
            biasY: 0.5 - (1 - settings.dampenedOnbeats.spread * (1 - backbeat) * 0.2) * 0.3,
            tail: onbeatTail * (1 - backbeat / 3),
            thickness: 0.83 - backbeat, // max visible backbeat is 0.83
            dampened: settings.dampenedSustain.onbeatSustain < 1,
            sustain: settings.dampenedSustain.onbeatSustain,
          } as const)
        : null;
    const offbeatViz = {
      dir: 'd',
      biasY: 0.5,
      tail: 0.8,
      thickness: 0.83 + backbeat * 0.17,
      dampened: settings.dampenedSustain.offbeatSustain < 1,
      sustain: settings.dampenedSustain.offbeatSustain,
    } as const;
    return [onbeatViz, null, offbeatViz, null, onbeatViz, null, offbeatViz, null];
  },
};

function splitCell(settings: GuitarSettingsHash) {
  const duration =
    (settings.dampenedSustain.onbeatSustain + settings.dampenedSustain.offbeatSustain) / 2;
  const onbeatReduction = settings.dampenedBackbeat.onbeatDbReduction;
  const action = chop(duration, 'jazzD', {
    spread: 3 + Math.random(),
    db: -onbeatReduction / 3,
  });
  return [action, null, action, null];
}
