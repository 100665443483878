import { crosspick } from '@/band/instruments/guitar/GuitarActions';
import type { GuitarCore } from '@/band/instruments/guitar/GuitarCore';
import type { GuitarIntent } from '@/band/instruments/guitar/GuitarIntent';
import { swingOptions } from '@/band/swingOptions';

const form: GuitarIntent[] = [
  crosspick({ series: 'std', noteInSeries: 1, db: 0 }),
  crosspick({ series: 'std', noteInSeries: 2, db: -5 }),
  crosspick({ series: 'std', noteInSeries: 3, db: 0 }),
  crosspick({ series: 'std', noteInSeries: 4, db: -2 }),
  crosspick({ series: 'std', noteInSeries: 3, db: 0 }),
  crosspick({ series: 'std', noteInSeries: 2, db: -2 }),
];

export const ArpeggiatedWaltzGuitarCore: GuitarCore = {
  id: 'aw',
  title: 'Arpeggios',

  timeSignatures: ['3/4'],

  linkedSettingsKeys: [],
  otherSettingsKeys: ['timing'],

  chordStyle: 'cowboy',

  swingCategory: 'normal',
  defaultSwing: swingOptions['5:4'],

  presets: [
    {
      name: 'Arpeggiated Waltz',
      settings: {},
    },
  ],

  processMeasure(measure, _settings) {
    // const chordChanged = measure.beats[2]?.chordChanged;
    measure;
    return [...form, ...form];
  },

  generateViz(settings) {
    settings;
    return [
      { biasY: 0.1, dir: 'd', thickness: 0.8 },
      { biasY: 0.5, dir: 'u', thickness: 0.4 },
      { biasY: 0.7, dir: 'd', thickness: 0.8 },
      { biasY: 0.9, dir: 'u', thickness: 0.6 },
      { biasY: 0.7, dir: 'd', thickness: 0.8 },
      { biasY: 0.5, dir: 'u', thickness: 0.6 },
    ];
  },
};
