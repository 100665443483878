import { strict as assert } from 'assert';
import type { Cell } from '@/chart/Cell.svelte';
import { getLinearizedMeasuresForSong } from '@/chart/getLinearizedMeasuresForSong';
import type { LinearizedBeat } from '@/chart/LinearizedBeat';
import type { LinearizedCell } from '@/chart/LinearizedCell';
import type { LinearizedMeasure } from '@/chart/LinearizedMeasure';
import type { Song } from '@/chart/Song';
import type { SongSelection } from '@/chart/SongSelection';
import { PlayerPosition } from '@/playback/PlayerPosition';
import { makeLinkedListInPlace } from '@/utilities/makeLinkedListInPlace';

interface LinearizationUpdateArguments {
  song: Song;
  loop?: SongSelection;
  skippedSections?: number[];
}

export class LinearizedSong {
  readonly measures: readonly LinearizedMeasure[];
  measuresInPlay: LinearizedMeasure[] = [];
  skippedSections: number[] = [];
  private measureSet: Set<LinearizedMeasure>;
  private song: Song;

  cellWeakLookupByRep: WeakMap<Cell, LinearizedCell>[] = [];

  constructor({ song, loop, skippedSections }: LinearizationUpdateArguments) {
    this.song = song;
    this.measures = getLinearizedMeasuresForSong({ linearization: this, song, loop });
    if (skippedSections) this.skippedSections = skippedSections;
    this.measures.forEach((measure) => {
      const rep = measure.position.rep;
      const weakMap = (this.cellWeakLookupByRep[rep] ??= new WeakMap<Cell, LinearizedCell>());
      measure.cells.forEach((linearizedCell) =>
        weakMap.set(linearizedCell.originalCell, linearizedCell)
      );
    });
    this.linkMeasuresInPlayAndChildren();
    this.measureSet = new Set(this.measures);
  }

  setSkippedSections(skippedSections: number[]): void {
    if (this.skippedSections.join(',') == skippedSections.join(',')) return;
    this.skippedSections = skippedSections;
    this.linkMeasuresInPlayAndChildren();
  }

  endingMeasure?: LinearizedMeasure;
  setLastTimeThrough(value: boolean): void {
    this.endingMeasure = value ? this.measuresInPlay[this.measuresInPlay.length - 1] : undefined;
  }

  unskipSection(section: number): void {
    this.setSkippedSections(this.skippedSections.filter((ss) => ss != section));
  }

  private linkMeasuresInPlayAndChildren() {
    this.measuresInPlay = this.measures.filter(
      (measure) => !this.skippedSections.includes(measure.position.section)
    );
    if (this.measuresInPlay.length == 0) this.measuresInPlay = this.measures.slice();
    makeLinkedListInPlace(this.measuresInPlay);
    const [firstMeasure, lastMeasure] = [this.measuresInPlay[0], this.measuresInPlay.slice(-1)[0]];
    if (firstMeasure) firstMeasure.firstInRep = true;
    if (lastMeasure) lastMeasure.lastInRep = true;
    makeLinkedListInPlace(this.measuresInPlay.flatMap((m) => m.cells));
    makeLinkedListInPlace(this.measuresInPlay.flatMap((m) => m.beats));

    this.setChangedAttributes();
  }

  private setChangedAttributes(): void {
    let beat: LinearizedBeat | undefined = this.measuresInPlay[0]?.beats[0];
    while (beat) {
      beat.chordChanged = beat.chord.toString() !== beat.prev?.chord.toString();
      beat.chordRootChanged = beat.chord.letterCode() !== beat.prev?.chord.letterCode();
      beat = beat.next;
    }

    let measure: LinearizedMeasure | undefined = this.measuresInPlay[0];
    while (measure) {
      measure.timeSignatureChanged =
        measure.prev && measure.timeSignature !== measure.prev.timeSignature;
      measure = measure.next;
    }
  }

  private hasMeasureObject(measure: LinearizedMeasure): boolean {
    return this.measureSet.has(measure);
  }

  firstValidPosition({ round }: { round?: number } = {}): PlayerPosition {
    const firstMeasure = this.measuresInPlay[0];
    assert(firstMeasure, 'No first measure');
    const firstMeasureBeat = firstMeasure.beats[0];
    assert(firstMeasureBeat, 'No first measure beat');
    return new PlayerPosition({
      song: this.song,
      round: round ?? 0,
      beat: firstMeasureBeat,
    });
  }

  getValidSequencerPosition(seqPos: PlayerPosition): PlayerPosition {
    if (this.hasMeasureObject(seqPos.measure)) return seqPos;
    // this.setSkippedSections(this.skippedSections.filter((ss) => ss != seqPos.measure.position.section));
    return (
      this.lookupBySequencerPosition(seqPos) ||
      this.lookupByChartPosition({
        section: seqPos.measure.position.section,
        rep: seqPos.measure.position.rep,
        cell: seqPos.beat.cell.layout.cellIndex,
        beat: seqPos.beat.beatInCell,
      })
    );
  }

  getSequencerPositionAdvancedByOneBeat(seqPos: PlayerPosition): PlayerPosition | undefined {
    const validPos = this.hasMeasureObject(seqPos.measure)
      ? seqPos
      : this.lookupBySequencerPosition(seqPos) ||
        this.lookupByChartPositionWithoutFallback(seqPos.position);
    if (!validPos) return this.firstValidPosition({ round: seqPos.round });
    const nextBeat = validPos.beat.next;
    return nextBeat
      ? new PlayerPosition({
          song: validPos.song,
          round: validPos.round,
          beat: nextBeat,
        })
      : undefined;
  }

  lookupBySequencerPosition(seqPos: PlayerPosition): PlayerPosition | undefined {
    const currentCell = seqPos.beat.cell.originalCell;
    const currentCellBeat = seqPos.beat.beatInCell;
    const currentRep = seqPos.measure.position.rep;
    const cell =
      this.cellWeakLookupByRep[currentRep]?.get(currentCell) ||
      this.cellWeakLookupByRep[0]?.get(currentCell);
    if (!cell) return;
    const beatInMeasure = cell.beats.findIndex(
      (b) =>
        b.cell.layout.cellIndex == currentCell.layout.cellIndex && b.beatInCell == currentCellBeat
    );
    if (beatInMeasure == -1) return;
    const beat = cell.beats[beatInMeasure] as LinearizedBeat;
    return new PlayerPosition({
      song: seqPos.song,
      round: seqPos.round,
      beat,
    });
  }

  lookupByChartPosition(pos: ChartPositionWithinSong): PlayerPosition {
    assert(this.measuresInPlay[0], 'No first measure');
    return (
      this.lookupByChartPositionWithoutFallback(pos) ||
      new PlayerPosition({
        song: this.song,
        round: pos.round,
        beat:
          // return either first measure in section or in song
          this.measures.find((item) => item.position.section == pos.section)?.beats[0] ||
          this.measuresInPlay[0].beats[0],
      })
    );
  }

  private lookupByChartPositionWithoutFallback(
    pos: ChartPositionWithinSong
  ): PlayerPosition | undefined {
    const measure =
      this.measures.find(
        (item) =>
          item.position.section == pos.section &&
          item.position.rep == pos.rep &&
          item.position.cells.includes(pos.cell)
      ) ||
      this.measures.find(
        (item) =>
          item.position.section == pos.section &&
          item.position.rep === 0 &&
          item.position.cells.includes(pos.cell)
      );
    if (!measure) return undefined;
    const cell = measure.cells[
      Math.min(measure.cells.length - 1, Math.max(0, measure.position.cells.indexOf(pos.cell)))
    ] as LinearizedCell;
    const beat = cell.beats[Math.min(cell.beats.length - 1, pos.beat ?? 0)] as LinearizedBeat;
    return new PlayerPosition({ song: this.song, round: pos.round, beat });
  }
}
