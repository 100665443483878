import { makeInstructionsFromGuitarIntent } from '@/band/instruments/guitar/makeInstructionsFromGuitarIntent';
import type { PlayerInstruction } from '@/band/instruments/PlayerInstruction';
import type { LinearizedBeat } from '@/chart/LinearizedBeat';
import type { LinearizedMeasure } from '@/chart/LinearizedMeasure';

export function applyGuitarIntentsToMeasure(
  measure: LinearizedMeasure,
  swing = 0,
  timingTweaks: InstrumentTimingTweaks = []
) {
  const upstrokeBeatOffset = 0.5 + 0.25 * swing;

  for (let i = 0; i < measure.beats.length; i++) {
    const beat = measure.beats[i] as LinearizedBeat;
    const guitarChord = beat.plans.guitar?.chord;
    if (!guitarChord) {
      beat.playerInstructions.guitar = [];
      continue;
    }

    let downstrikeOffset = 0;
    if (measure.timeSignature == '4/4') downstrikeOffset = timingTweaks[i % 2] ?? 0;
    if (measure.timeSignature == '3/4') downstrikeOffset = timingTweaks[i % 3] ?? 0;

    const downstrokeIntent = beat.plans.guitar?.onbeatIntent;
    const upstrokeIntent = beat.plans.guitar?.offbeatIntent;
    beat.playerInstructions.guitar = [
      ...(downstrokeIntent
        ? addBeatOffsetToInstructions(
            makeInstructionsFromGuitarIntent(downstrokeIntent, guitarChord),
            downstrikeOffset
          )
        : []),
      ...(upstrokeIntent
        ? addBeatOffsetToInstructions(
            makeInstructionsFromGuitarIntent(upstrokeIntent, guitarChord),
            upstrokeBeatOffset
          )
        : []),
    ];
  }
}

function addBeatOffsetToInstructions(
  instructions: PlayerInstruction[],
  beatOffset: number
): PlayerInstruction[] {
  instructions.forEach((inst) => {
    if (inst.type == 'play' || inst.type == 'mute')
      inst.beatOffset = (inst.beatOffset || 0) + beatOffset;
  });
  return instructions;
}
