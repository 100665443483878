<script lang="ts">
  import { strict as assert } from 'assert';
  import { createEventDispatcher } from 'svelte';
  import { run } from 'svelte/legacy';
  import { fade } from 'svelte/transition';
  import { createListbox } from 'svelte-headlessui';

  type ValueType = $$Generic;

  interface Props {
    options: readonly {
      readonly caption?: string;
      readonly name: string;
      readonly description?: string;
      readonly value: ValueType;
    }[];
    value: ValueType;
    labelFallback?: string;
    ariaLabel?: string;
    disabled?: boolean;
    fontWeight?: 'normal' | 'semibold' | 'bold';
    fontSize?: 'sm' | 'base' | 'lg' | 'xl';
  }

  let {
    options,
    value,
    labelFallback = '-',
    ariaLabel = '',
    disabled = false,
    fontWeight = 'normal',
    fontSize = 'base',
  }: Props = $props();

  const listbox = createListbox<(typeof options)[number]>({
    label: ariaLabel,
  });

  run(() => {
    const currentOption = options.find((o) => o.value === value);
    listbox.set({ selected: currentOption });
  });

  const dispatch = createEventDispatcher<{
    'change': ValueType;
  }>();

  function onSelect(e: CustomEvent) {
    assert(typeof e.detail.selected !== 'undefined', 'SimpleListbox e.detail is undefined');
    dispatch('change', e.detail.selected.value);
  }
</script>

<div class="relative">
  <button
    use:listbox.button
    onselect={onSelect}
    {disabled}
    class="sm:text-sm relative w-full cursor-default rounded-lg border border-gray-200 bg-white py-1.5 pl-3 pr-10 text-left focus:outline-none focus-visible:border-primary-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-primary-300 disabled:bg-gray-50 disabled:text-gray-500 dsktp:hover:bg-primary-50"
    style="
    font-weight: {fontWeight == 'semibold' ? '500' : fontWeight};
    font-size: {fontSize == 'sm'
      ? '0.875rem'
      : fontSize == 'lg'
        ? '1.125rem'
        : fontSize == 'xl'
          ? '1.25rem'
          : '1rem'};
    "
  >
    <span class="block truncate text-gray-900"
      >{$listbox.selected?.caption || $listbox.selected?.name || labelFallback}</span
    >
    <span
      class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-muted-500"
    >
      <svg class="h-5 w-4"><use xlink:href="#up-down-chevrons-icon" /></svg>
    </span>
  </button>

  {#if $listbox.expanded}
    <ul
      transition:fade={{ duration: 100 }}
      use:listbox.items
      class="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none"
    >
      {#each options as option}
        {@const active = $listbox.active === option}
        {@const selected = $listbox.selected === option}
        <li
          class="relative cursor-default select-none py-1.5 pl-3.5 pr-10 {active
            ? 'bg-primary-100 text-primary-900'
            : 'text-gray-900'}"
          style="font-size: {fontSize == 'sm'
            ? '0.875rem'
            : fontSize == 'lg'
              ? '1.125rem'
              : fontSize == 'xl'
                ? '1.25rem'
                : '1rem'}; "
          use:listbox.item={{ value: option }}
        >
          <span class="block truncate {selected ? 'font-medium' : 'font-normal'}">
            {#if option.description}
              <strong>{option.name}</strong>
              <p>{option.description}</p>
            {:else}
              {option.name}
            {/if}
          </span>
          {#if selected}
            <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-muted-500">
              <svg class="h-4 w-4"><use xlink:href="#check-icon" /></svg>
            </span>
          {/if}
        </li>
      {/each}
    </ul>
  {/if}
</div>

<style>
  :global(.SimpleListbox) {
    user-select: none;

    :global(> button) {
      display: flex;
      width: 100%;
      align-items: center;
      text-align: left;
      touch-action: manipulation;
      cursor: pointer;
      background: white;
      font: inherit;
      color: hsl(206, 10%, 25%);
      border: solid 1px hsl(206, 25%, 75%);
      white-space: nowrap;
      transition: all 120ms ease;
      padding: 0.375em 0.625em;
      line-height: 1.4;
      border-radius: 6px;

      &:focus-visible,
      &:active {
        outline: 0;
        box-shadow: 0 0 0 0.2rem hsla(207, 62%, 52%, 0.5);
      }

      @media not all and (hover: none), (min--moz-device-pixel-ratio: 1) {
        &:focus-visible,
        &:hover {
          text-decoration: none;
        }
        &:hover {
          background-color: hsla(207, 79%, 44%, 0.12);
        }
      }

      &[aria-expanded='true'] {
        color: hsl(206, 25%, 65%);
        border-color: hsl(206, 20%, 90%);
        background: hsl(206, 10%, 94%);
        box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
        outline: 0;
        transition: all 300ms ease;
      }

      > span {
        flex: 1 1 auto;
        padding-right: 0.5em;
      }

      > svg {
        color: hsl(206, 10%, 60%);
        margin-right: -5px;
        width: 20px;
        height: 20px;
        fill: currentColor;
      }

      &[disabled] {
        background-color: hsl(206, 10%, 95%) !important;
        color: hsl(206, 10%, 40%) !important;
        pointer-events: none;
      }
    }

    .SimpleListbox-menu {
      padding: 0; /* clear overrides */
    }

    :global(ul) {
      overflow: auto;
      padding: 0.25rem 0;
      max-height: 60vh;
    }
    :global(ul > li) {
      padding: 0.4rem 0.7rem;
      user-select: none;
      cursor: pointer;

      &.active {
        background-color: var(--primary);
        color: white;
      }

      > p {
        font-size: 14px;
        opacity: 0.7;
        margin: 0;
      }
    }
  }
</style>
