import * as Sentry from '@sentry/browser';
import type { BassSettingsHash } from '@/band/instruments/bass/BassSettingsHash';
import { getWalkingBassPatterns } from '@/band/instruments/bass/WalkingBassPatternLibrary';
import type { LinearizedCell } from '@/chart/LinearizedCell';
import type { LinearizedMeasure } from '@/chart/LinearizedMeasure';
import { chordTypeDefinitions } from '@/music/chordTypeDefinitions';
import { range } from '@/utilities/range';

const lowestChromaIndex = -8;
const highestChromaIndex = 12;

export function assignWalkingBassLines({
  measures,
  settings,
}: {
  measures: readonly LinearizedMeasure[];
  settings: BassSettingsHash;
}) {
  settings;
  const cells = measures.flatMap((m) => m.cells);
  let cell = cells[0];
  if (!cell) return;
  const veryFirstCell = cell;
  let nextCell: LinearizedCell | undefined;
  let priorTargetChromaIndex: number | undefined;
  // let lastUsedPattern: WalkingBassPattern | undefined;
  while (cell) {
    if (shouldSkipCell(cell)) {
      priorTargetChromaIndex = undefined;
      cell = nextCell = cell.next;
      continue;
    }

    priorTargetChromaIndex ??= ((cell.chordHeard.chroma + 5) % 12) - 5;

    /** How many steps away from the root we're starting with */
    const startingRelativeChroma = (priorTargetChromaIndex - cell.chordHeard.chroma + 12) % 12;

    /** The chroma index (with octaves) of the starting chord context */
    const chromaIndexOffset = priorTargetChromaIndex - startingRelativeChroma;

    const cellsToAnalyze = pickCellsToAnalyze(cell);

    nextCell = cellsToAnalyze[cellsToAnalyze.length - 1]!.next;

    let forceRootApproach = false;

    let patternsAvailable = getPatterns({
      cells: cellsToAnalyze,
      targetCell: nextCell || veryFirstCell,
      startingRelativeChroma,
      chromaIndexOffset,
    });

    if (patternsAvailable.length === 0 && cellsToAnalyze.length > 2) {
      patternsAvailable = getPatterns({
        cells: [cellsToAnalyze[0], cellsToAnalyze[1]!],
        targetCell: cellsToAnalyze[1]?.next ?? veryFirstCell,
        startingRelativeChroma,
        chromaIndexOffset,
      });
    }

    if (patternsAvailable.length === 0) {
      patternsAvailable = getPatterns({
        cells: [cellsToAnalyze[0]],
        targetCell: cellsToAnalyze[0].next ?? veryFirstCell,
        startingRelativeChroma,
        chromaIndexOffset,
      });
    }

    if (patternsAvailable.length === 0) {
      forceRootApproach = true;
      patternsAvailable = getPatterns({
        cells: [cellsToAnalyze[0]],
        targetCell: cellsToAnalyze[0],
        startingRelativeChroma,
        chromaIndexOffset,
      });
    }

    if (patternsAvailable.length === 0) {
      const debugInfo = {
        cellIndex: cellsToAnalyze[0].layout.cellIndex,
        sectionIndex: cellsToAnalyze[0].layout.sectionIndex,
        key: cellsToAnalyze[0].chordHeard.key,
        cellChords: cellsToAnalyze.map((c) => c.chordHeard.toString()).join(','),
        nextChord: (nextCell ?? veryFirstCell)?.chordHeard.toString(),
        startingRelativeChroma,
        chromaIndexOffset,
      };
      console.error('No walking bass patterns found', debugInfo);
      Sentry.withScope((scope) => {
        scope.setExtra('debugInfo', debugInfo);
        Sentry.captureMessage('No walking bass patterns found');
      });
    }

    const patternsWithoutJumps = patternsAvailable.filter((p) => p.biggestJump < 9);
    if (patternsWithoutJumps.length > 0) patternsAvailable = patternsWithoutJumps;
    const pattern = patternsAvailable[Math.floor(Math.random() * patternsAvailable.length)];
    // lastUsedPattern = pattern;

    if (!pattern) {
      cell = cell.next;
      priorTargetChromaIndex = undefined;
      continue;
    }

    const cellsInPattern = pattern.runLength / 2;

    for (const i of range(0, cellsInPattern - 1)) {
      if (cell) {
        cell.plans.bass ??= {};
        cell.plans.bass.walkingBassChromaIndices = pattern.intervals
          .slice(i * 2, i * 2 + (cell.effect ? 1 : 2))
          .map((i) => i + chromaIndexOffset);
      }
      cell = cell?.next;
    }

    const landingInterval = pattern.intervals[pattern.intervals.length - 1]!;
    priorTargetChromaIndex = forceRootApproach ? undefined : landingInterval + chromaIndexOffset;
  }
}

function shouldSkipCell(cell: LinearizedCell) {
  return cell.effect == 'rest' || cell.split;
}

function pickCellsToAnalyze(startingCell: LinearizedCell): [LinearizedCell, ...LinearizedCell[]] {
  const measure = startingCell.measure;
  if (startingCell.layout.barStart) {
    if (measure.next && startingCell.layout.column % 4 === 0) {
      if (
        measure.onlyOneChord &&
        measure.next.onlyOneChord &&
        measure.cells[0].chordHeard.equals(measure.next.cells[0].chordHeard)
      ) {
        const nextTwoMeasureCells = [measure, measure.next].flatMap((m) => m.cells);
        if (nextTwoMeasureCells.length === 4 && !nextTwoMeasureCells.some(shouldSkipCell)) {
          return nextTwoMeasureCells as [LinearizedCell, ...LinearizedCell[]];
        }
      }
    }
    if (measure.onlyOneChord && !measure.cells.some(shouldSkipCell)) {
      return measure.cells.slice() as [LinearizedCell, ...LinearizedCell[]];
    }
  }
  return [startingCell];
}

function getPatterns({
  cells,
  targetCell,
  startingRelativeChroma,
  chromaIndexOffset,
}: {
  cells: [LinearizedCell, ...LinearizedCell[]];
  targetCell: LinearizedCell;
  startingRelativeChroma: number;
  chromaIndexOffset: number;
}) {
  const allPatterns = getWalkingBassPatterns({
    cells,
    startingRelativeChroma,
    highestInterval: highestChromaIndex - chromaIndexOffset,
    lowestInterval: lowestChromaIndex - chromaIndexOffset,
  });

  const includesLastCellInRep = cells[cells.length - 1]?.measure.lastInRep;
  // If we're on the last cell, make sure we resolve to the default octave
  const targetInterval = includesLastCellInRep
    ? ((targetCell.chordHeard.chroma + 5) % 12) - 5 - chromaIndexOffset
    : undefined;
  const targetRelativeChroma = (targetCell.chordHeard.chroma - chromaIndexOffset + 12) % 12;
  const changingRootChroma = targetRelativeChroma !== 0;
  const noIndirect = true;

  const targetChordIntervals = chordTypeDefinitions[targetCell.chordHeard.type]
    .intervals as number[];
  const patternsInRange = allPatterns.filter(
    (p) =>
      !p.nextChordMustContain?.some((n) => !targetChordIntervals.includes(n)) &&
      (includesLastCellInRep
        ? p.intervals[p.intervals.length - 1] == targetInterval
        : p.endingRelativeChroma === targetRelativeChroma ||
          (changingRootChroma || noIndirect
            ? false
            : p.endingRelativeChroma ===
              targetRelativeChroma + (targetCell.chordHeard.minor ? 3 : 4)))
  );
  let chosenPatterns = patternsInRange.filter(
    (p) => p.biggestJump < 12 || (p.biggestJump < 9 && Math.random() < 0.2)
  );
  if (chosenPatterns.length === 0) chosenPatterns = patternsInRange;

  return chosenPatterns;
}
