import { Chord } from '@/music/Chord';

export function commonCapoPositionsForKey(key: string | undefined): number[] {
  if (!key) return [];
  return commonCapoPositions.get(new Chord(key).letterCode().toUpperCase()) || [2, 3, 4];
}

const commonCapoPositions = new Map([
  ['C', [3, 5]],
  ['CD', [1, 4, 6]],
  ['D', [2, 5]],
  ['DE', [1, 3, 6]],
  ['E', [2, 4]],
  ['F', [1, 3, 5]],
  ['FG', [2, 4]],
  ['G', [3, 5]],
  ['GA', [1, 4, 6]],
  ['A', [2, 5]],
  ['AB', [1, 3, 6]],
  ['B', [2, 4]],
]);

export function smartDefaultCapoForKey(key: string | undefined): number {
  if (!key) return 0;
  return smartCapoDefaults.get(new Chord(key).letterCode().toUpperCase()) || 2;
}

const smartCapoDefaults = new Map([
  ['C', 3],
  ['CD', 1],
  ['D', 2],
  ['DE', 3],
  ['E', 2],
  ['F', 3],
  ['FG', 2],
  ['G', 3],
  ['GA', 1],
  ['A', 2],
  ['AB', 3],
  ['B', 4],
]);
