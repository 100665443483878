import { initializeInstrumentSettings } from '@/band/instruments/initializeInstrumentSettings';
import { Instrument } from '@/band/instruments/Instrument';
import type { InstrumentPresetSettings } from '@/band/instruments/InstrumentPresetSettings';
import { applyMandolinIntentsToMeasure } from '@/band/instruments/mandolin/applyMandolinIntentsToMeasure';
import { assignMandolinChords } from '@/band/instruments/mandolin/assignMandolinChords';
import { ChopMandolinCore } from '@/band/instruments/mandolin/cores/ChopMandolinCore';
import { JigChopMandolinCore } from '@/band/instruments/mandolin/cores/JigChopMandolinCore';
import { WaltzChopMandolinCore } from '@/band/instruments/mandolin/cores/WaltzChopMandolinCore';
import { distributeMandolinIntentsToMeasure } from '@/band/instruments/mandolin/distributeMandolinIntentsToMeasure';
import type { MandolinCore } from '@/band/instruments/mandolin/MandolinCore';
import { mandolinCores } from '@/band/instruments/mandolin/mandolinCores';
import { mandolinSettingDefinitions } from '@/band/instruments/mandolin/mandolinSettingDefinitions';
import type { MandolinSettingsHash } from '@/band/instruments/mandolin/MandolinSettingsHash';
import { overlayMeasureEffectsForMandolin } from '@/band/instruments/mandolin/overlayMeasureEffectsForMandolin';
import type { LinearizedMeasure } from '@/chart/LinearizedMeasure';
import { safeCallback } from '@/utilities/safeCallback';
import { safeExecute } from '@/utilities/safeExecute';

export class Mandolin extends Instrument<MandolinSettingsHash, MandolinCore> {
  readonly id = 'mandolin';
  readonly name = 'Mandolin';
  readonly shortCode = 'm';

  readonly cores = mandolinCores;
  readonly fallbackCores: Record<TimeSignature, MandolinCore> = {
    '4/4': ChopMandolinCore,
    '3/4': WaltzChopMandolinCore,
    '6/8': JigChopMandolinCore,
    '9/8': JigChopMandolinCore,
  };

  readonly settingDefinitions = mandolinSettingDefinitions;
  protected _settings = initializeInstrumentSettings<MandolinSettingsHash>(this.settingDefinitions);

  loadCoreAndPreset(
    core: MandolinCore,
    presetSettings: InstrumentPresetSettings<MandolinSettingsHash>
  ) {
    this.emit('updatedData', {
      [`${this.shortCode}_c`]: core.id,
      // ...this.irrelevantSettingsResetHash(core),
      ...this.serializePresetSettings(presetSettings),
    });
  }

  processMeasures(
    measures: readonly LinearizedMeasure[],
    core: MandolinCore,
    settings: Readonly<MandolinSettingsHash>,
    state: { tpm: number }
  ) {
    const swing = this.swing().formula(state.tpm);
    const beatDuration = 60 / state.tpm;
    const timingTweaks = settings.timing.beatAdjustments ?? [];

    assignMandolinChords({ measures, chordStyle: core.chordStyle });

    safeExecute(() => core.measurePreprocessing?.(measures, settings));

    measures.forEach(
      safeCallback((measure) => {
        const intents = core.processMeasure(measure, settings, { swing, beatDuration });
        distributeMandolinIntentsToMeasure(intents, measure);
        overlayMeasureEffectsForMandolin(measure);
      })
    );

    safeExecute(() => core.measurePostprocessing?.(measures, settings));

    measures.forEach(
      safeCallback((measure) => {
        applyMandolinIntentsToMeasure(measure, swing, timingTweaks);
      })
    );
  }
}
