import type { Props } from 'tippy.js';
import tippyJs from 'tippy.js';

export function tooltip(node: HTMLElement, params: Partial<Props> = {}) {
  const tippyInstance = tippyJs(node, {
    placement: 'bottom',
    delay: [300, 50],
    hideOnClick: false,
    maxWidth: 250,
    ...params,
  });
  return { destroy: () => tippyInstance.destroy() };
}
