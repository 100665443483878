import { z } from 'zod';
import type { InstrumentSetting } from '@/band/instruments/InstrumentSetting';
import { bounded } from '@/utilities/bounded';
import cloneJSON from '@/utilities/cloneJSON';
import { rounded } from '@/utilities/rounded';

const serializedSchema = z.object({
  e: z.number().min(0).max(1).optional(),
  qeb: z.number().min(0).max(1).optional(),
  d: z.boolean().optional(),
  jf: z.enum(['sm', 'mm', 'b']).optional(),

  // Legacy weightings
  ws: z.number().min(0).max(1).optional(),
  wq: z.number().min(0).max(1).optional(),
  we: z.number().min(0).max(1).optional(),
});

type Serialization = z.infer<typeof serializedSchema>;

export class GuitarBassRunsSetting implements InstrumentSetting<Serialization> {
  readonly eagerness: number;
  readonly majorFeel: 'sm' | 'mm' | 'b';
  readonly quarterEighthBalance: number;
  readonly useDescendingRuns: boolean;

  // derived properties
  readonly quarterWeight: number;
  readonly eighthWeight: number;

  /**
   * Amount of runs to play is calculated from `eagerness`:
   *
   *  - 0.00 = no runs
   *  - 0.25 = 1 run per 8 measures
   *  - 0.50 = 1 run per 4 measures
   *  - 0.75 = 2 runs per 4 measures
   *  - 1.00 = 4 runs per 4 measures
   */
  get runsPerMeasure() {
    if (this.eagerness === 0) return 0;
    return Math.pow(2, this.eagerness * 4 - 1) / 8;
  }

  constructor(input: unknown = {}) {
    const data = serializedSchema.catch({}).parse(input);

    // set properties from the serialized data here
    this.eagerness = Math.round(bounded(data.e ?? 0, 0, 1) * 4) / 4;
    this.majorFeel = data.jf ?? 'mm';
    this.useDescendingRuns = !!data.d;
    this.quarterEighthBalance = Math.round(bounded(data.qeb ?? 0, 0, 1) * 4) / 4;

    this.quarterWeight = 1 - this.quarterEighthBalance;
    this.eighthWeight = this.quarterEighthBalance;
  }

  serialize(): Serialization {
    return cloneJSON({
      e: rounded(this.eagerness, 2) || undefined,
      qeb: rounded(this.quarterEighthBalance, 2) || undefined,
      d: this.useDescendingRuns ? true : undefined,
      jf: this.majorFeel == 'mm' ? undefined : this.majorFeel,
    });
  }

  closeTo(serialized?: Serialization): boolean {
    const other = new GuitarBassRunsSetting(serialized);
    return (
      Math.abs(this.eagerness - other.eagerness) < 0.1 &&
      Math.abs(this.quarterEighthBalance - other.quarterEighthBalance) < 0.1 &&
      this.useDescendingRuns === other.useDescendingRuns &&
      this.majorFeel === other.majorFeel
    );
  }

  withEagerness(eagerness: number): GuitarBassRunsSetting {
    return new GuitarBassRunsSetting({
      ...this.serialize(),
      e: eagerness,
    });
  }

  withQuarterEighthBalance(quarterEighthBalance: number): GuitarBassRunsSetting {
    return new GuitarBassRunsSetting({
      ...this.serialize(),
      qeb: quarterEighthBalance,
    });
  }

  withDescendingRuns(useDescendingRuns: boolean): GuitarBassRunsSetting {
    return new GuitarBassRunsSetting({
      ...this.serialize(),
      d: useDescendingRuns,
    });
  }

  withMajorFeel(majorFeel: typeof this.majorFeel): GuitarBassRunsSetting {
    return new GuitarBassRunsSetting({
      ...this.serialize(),
      jf: majorFeel,
    });
  }
}
