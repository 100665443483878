<script lang="ts">
  import { fade } from 'svelte/transition';
  import type { BandPreset } from '@/band/presets/BandPreset';
  import BandHistorySwapIcon from '@/band/ui/panels/BandHistorySwapIcon.svelte';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import HintArrow from '@/ui/HintArrow.svelte';
  import HintText from '@/ui/HintText.svelte';
  import Icon from '@/ui/icons/Icon.svelte';
  import { MultiPanelModalController } from '@/ui/settings/MultiPanelModalController.svelte';
  import { tooltip } from '@/ui/tooltip';
  import { UserProfile } from '@/user/UserProfile';

  const modalController = MultiPanelModalController.current();

  const bandMenu = getBandMenuState();

  let currentBandPreset: BandPreset | undefined = $state();
  trackerEffect(() => (currentBandPreset = $bandMenu.band.preset()));

  let canRestorePrevious = $state(false);
  trackerEffect(
    () =>
      (canRestorePrevious =
        !!($bandMenu.band.previousPreset() || $bandMenu.band.previousCustomSettings()) &&
        !$bandMenu.band.presetMode())
  );

  let willRestorePreset = $derived(canRestorePrevious && !currentBandPreset);

  let showRedo = $state(false);
  trackerEffect(() => (showRedo = $bandMenu.band.didUndo()));

  let clickedRestore = $state(false);

  function restorePrevious() {
    const band = $bandMenu.band;
    if (band.preset()) {
      band.loadPreviousCustomBand();
    } else {
      band.loadPreviousPreset();
    }
    UserProfile.recordMilestone('BAND_HISTORY_SWAP');
    clickedRestore = true;
  }

  let enabledInstruments: InstrumentId[] = $state([]);
  trackerEffect(() => (enabledInstruments = $bandMenu.band.activeInstruments()));

  interface Props {
    showHistoryHint?: boolean;
  }

  let { showHistoryHint = false }: Props = $props();

  let hintText = $derived(
    willRestorePreset
      ? showRedo
        ? 'Tap again to load \n the previous preset'
        : 'Tap to load the \n previous preset'
      : showRedo
        ? 'Tap again to restore \n your Custom Band'
        : 'Tap to restore your \n last Custom Band'
  );
</script>

<div class="flex rounded-b-[25px] border-x border-b border-panelWellBorder">
  <button
    class="colors-panelOpener relative flex flex-grow basis-full items-center justify-center gap-1.5 p-3 pl-4 text-sm font-medium leading-5 text-primary-600 transition-colors first:rounded-bl-3xl last:rounded-br-3xl"
    onclick={() => modalController.setSubview('instruments')}
  >
    {#if enabledInstruments.length > 0}
      <Icon id="toggle-instruments" size={16} />
      Add/Remove Instruments
    {:else}
      <Icon id="add-button-icon" size={16} />
      Add Instruments
    {/if}
  </button>
  {#if canRestorePrevious}
    <button
      class="colors-panelOpener relative flex-shrink-0 whitespace-nowrap border-l border-panelWellBorder pl-4 pr-5 text-sm font-medium leading-5 text-primary-600 transition-colors first:rounded-bl-3xl last:rounded-br-3xl"
      transition:fade
      onclick={(e) => {
        e.preventDefault();
        restorePrevious();
      }}
      use:tooltip={{
        placement: 'bottom',
        delay: [400, 50],
        allowHTML: true,
        hideOnClick: true,
        touch: false,
        onShow: (reference) =>
          showHistoryHint
            ? false
            : reference.setContent(
                willRestorePreset
                  ? 'Clicking this will restore the last preset you loaded.'
                  : 'Clicking this will bring back your last Custom Band.'
              ),
      }}
    >
      <div class="flex h-full items-center">
        <BandHistorySwapIcon {showRedo} />
      </div>
      {#if showHistoryHint && hintText}
        <div class="pointer-events-none relative h-0 w-0">
          {#key hintText}
            <div
              class="absolute -right-5 top-full z-10 translate-y-3 -rotate-2"
              out:fade={{ duration: 250 }}
            >
              <HintText
                text={hintText}
                typingDuration={800}
                delayBeforeTyping={clickedRestore ? 1000 : 1800}
              />
            </div>
            <div
              class="absolute -right-2 top-full z-10 translate-x-3.5 translate-y-8"
              out:fade={{ duration: 250 }}
            >
              <HintArrow
                fromPoint={{ x: 0, y: 37 }}
                toPoint={{ x: 0, y: 0 }}
                controlPoint={{ x: 30, y: 37 }}
                drawDuration={300}
                drawDelay={clickedRestore ? 1700 : 2500}
              />
            </div>
          {/key}
        </div>
      {/if}
    </button>
  {/if}
</div>

{#if showHistoryHint}
  <div class="pt-4"></div>
{/if}
