<script lang="ts">
  import type { Section } from '@/chart/Section.svelte';
  import { sectionTypes } from '@/chart/sectionTypes';
  import SectionEditBarDeleteButton from '@/editor/ui/SectionEditBarDeleteButton.svelte';
  import SectionEditBarMoveButtons from '@/editor/ui/SectionEditBarMoveButtons.svelte';
  import SectionEditBarName from '@/editor/ui/SectionEditBarName.svelte';
  import SectionEditBarReps from '@/editor/ui/SectionEditBarReps.svelte';
  import SectionEditBarTypeLabelButton from '@/editor/ui/SectionEditBarTypeLabelButton.svelte';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import Icon from '@/ui/icons/Icon.svelte';

  interface Props {
    section: Section;
    sectionIndex: number;
  }

  let { section, sectionIndex }: Props = $props();

  let songSections: Section[] = $state([]);
  trackerEffect(() => (songSections = section.song.sections.reactive()));

  let typeLabel = $derived(sectionTypes.find((type) => type.id === section.type)?.label || '');
</script>

<header
  class="flex flex-wrap items-center gap-0.5 rounded-lg bg-editSectionBarBg p-0.5 pr-1.5"
  role="heading"
  aria-level="2"
  aria-roledescription="section heading"
  aria-label="section {sectionIndex + 1}, {section.name || ''}"
>
  <SectionEditBarName {section} />

  <SectionEditBarReps {section} {sectionIndex} />

  <SectionEditBarTypeLabelButton {section} />

  <div class="flex flex-1 items-center justify-end">
    <button
      class="colors-button flex rounded-full p-1.5"
      aria-label="More section options"
      onclick={() => Modal.show('sectionSettingsModal', { song: section.song, sectionIndex })}
    >
      <Icon id="gear-icon" size={14} />
    </button>

    {#if songSections.length > 1}
      <div class="mx-1 h-6 border-l border-gray-200"></div>
      <SectionEditBarMoveButtons {section} {sectionIndex} />
    {/if}

    <div class="mx-1 h-6 border-l border-gray-200"></div>

    <SectionEditBarDeleteButton {section} {sectionIndex} />
  </div>
</header>
