<script lang="ts">
  import { MandolinSwingBackbeatSetting } from '@/band/instruments/mandolin/settings/MandolinSwingBackbeatSetting';
  import CycleThroughDropdown from '@/band/ui/components/CycleThroughDropdown.svelte';
  import PrettySlider from '@/band/ui/components/PrettySlider.svelte';
  import RhythmPatternVisualization from '@/band/ui/components/RhythmPatternVisualization.svelte';
  import SliderRule from '@/band/ui/components/SliderRule.svelte';
  import PanelModalLayout from '@/band/ui/panels/PanelModalLayout.svelte';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';

  const bandMenu = getBandMenuState();

  const min = 0;
  const max = MandolinSwingBackbeatSetting.presets.length - 1;

  let setting = $derived($bandMenu.mandolinSettings.swingBackbeat);
  let notch = $derived(setting.value * max);

  function setToNotch(notch: number) {
    $bandMenu.mandolin.setSetting('swingBackbeat', setting.withValue(notch / max));
  }

  const options = MandolinSwingBackbeatSetting.presets;
</script>

<PanelModalLayout>
  {#snippet header()}
    <header class="panelHeader">
      More backbeat means beats 2 and 4 are played louder than beats 1 and 3.
      <!-- <a href="#" class="font-medium">Learn more</a>. -->
    </header>
  {/snippet}

  {#snippet main()}
    <div>
      <div class="mb-7 mt-6 flex justify-center">
        <div
          style="box-shadow: 4px 6px 12px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.05), -4px -6px 12px 0px rgba(255, 255, 255, 0.95); border-radius: 12px; overflow: hidden; border: solid 2px var(--gray-100);"
        >
          <RhythmPatternVisualization
            actions={$bandMenu.mandolin
              .coreOrFallbackForTimeSignature($bandMenu.timeSignature)
              .generateViz($bandMenu.mandolinSettings)}
            timeSignature={$bandMenu.timeSignature}
            scale={1.9}
            highlightCurrentBeat={true}
            showBeatNumbers={false}
          />
        </div>
      </div>

      <div class="cycle-shadow mx-6 mb-3 rounded-lg bg-muted-100 p-1 pb-[5px]">
        <CycleThroughDropdown
          {options}
          selectedValue={setting.closestPreset.value}
          on:select={({ detail: notch }) => setToNotch(notch)}
          wrapAround={true}
          captureLeftRightKeys={true}
        />
      </div>

      <SliderRule />
      <div class="mx-6 py-1">
        <PrettySlider
          {min}
          {max}
          value={notch}
          step={1}
          tickCount={max - min + 1}
          thumbDiameter={2.75}
          tickHeight={1.25}
          tickSqueezes={Array.from(
            { length: max - min + 1 },
            (_, i) => 1 - (i + 1) / (max - min + 1)
          )}
          ariaLabel="backbeat"
          on:change={({ detail }) => setToNotch(detail)}
        />
      </div>
      <SliderRule lowerLabel="None" upperLabel="100%" />
    </div>
  {/snippet}
</PanelModalLayout>
