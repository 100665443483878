import { Meteor } from 'meteor/meteor';

const milestoneCodes = {
  ADD_TO_LIST: 'nl',
  ADD_REMOVE_CELL_2024: 'ar24',
  AUTO_FINISH_OPEN: 'afo',
  AUTO_FINISH_SET: 'af',
  AUTO_SPEEDUP_OPEN: 'aso',
  AUTO_SPEEDUP_SET: 'as',
  AUTO_MODULATE: 'am',
  AUTO_ADVANCE_OPEN: 'aao',
  BAND_LISTEN: 'bl',
  BAND_HISTORY_SWAP: 'bhs',
  BASS_RUN_TYPE_ADJUSTED: 'brt',
  BPM_ADJUST: 's',
  BPM_SET: 'ss',
  CAPO_SET: 'cp',
  CHORD_SET: 'cs',
  CHORD_DISPLAY_MENU_OPEN: 'ocd',
  CHART_MENU_OPEN: 'och',
  CIRCULAR_SPATIAL_UI_DRAG: 'csd',
  CLICK_TRACK_MENU_OPEN: 'cto',
  COUNT_IN_MENU_OPEN: 'oci',
  EDIT_KEYS: 'ke',
  EDIT_SONG: 'es',
  FX_MENU_OPEN: 'fx',
  HELP_MENU_OPEN: 'h',
  HELP_OPEN: 'ho',
  INSTRUMENTS_MENU_OPEN: 'oi',
  KEY_CHANGE: 'k',
  KEY_OPEN: 'ko',
  LISTS_MENU_OPEN: 'ol',
  LOOP_CANCEL: 'lc',
  LOOP_SET: 'ls',
  MINE: 'mn',
  NEW_LIST: 'nlo',
  NEW_SONG_OPEN: 'ca',
  NEW_SONG: 'ns',
  NEW_MEDLEY: 'nm',
  NOTEPAD_HIDE: 'nph',
  NOTEPAD_SAVE: 'npu',
  NOTEPAD_SHOW: 'nps',
  PLAY_FROM_HERE: 'c',
  PLAY: 'p',
  PRESETS_OPEN: 'po',
  PRESETS_CATEGORY_OPEN: 'pco',
  LEGACY_PRESET_CATEGORY_MSG_SEEN: 'lpmc',
  LEGACY_PRESET_YP_MSG_SEEN: 'lpmyp',
  PRESET_SET: 'ps',
  REFERENCES_OPEN: 'ro',
  SCROLL_PATTERNS: 'sp',
  SCROLL_PRESETS: 'spr',
  SUBDIVIDE_BEAT: 'sb',
  TOOLS_OPEN: 't',
} as const;

// check just in case I made a mistake...
if (
  Meteor.isDevelopment &&
  Object.values(milestoneCodes).length !== new Set(Object.values(milestoneCodes)).size
) {
  throw new Error('Duplicate milestone code configured!');
}

export default milestoneCodes;
