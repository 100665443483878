<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { trackerEffect } from '@/lib/trackerEffect.svelte';
  import { Chord } from '@/music/Chord';
  import { chromaticScales } from '@/music/chromaticScale';
  import UserPreferences from '@/user/UserPreferences';

  const dispatch = createEventDispatcher<{ select: Chord['rootNote'] }>();

  interface Props {
    orientation?: 'horizontal' | 'vertical';
    key: string;
    selectedChords: Chord[] | undefined;
  }

  let { orientation = 'vertical', key, selectedChords }: Props = $props();

  let chordSystem = $state() as 'letters' | 'numbers' | 'roman';
  trackerEffect(() => (chordSystem = UserPreferences.get('chordSystem')));
  let minorKey = $derived(key.includes('m'));
  let keyTonic = $derived(key.replace('m', '') as keyof typeof chromaticScales);
  let scaleTonics = $derived(chromaticScales[keyTonic]);
  let singleSelectedTonic = $derived(
    selectedChords?.every((c) => c.rootNote == selectedChords?.[0]?.rootNote)
      ? selectedChords[0]?.rootNote
      : undefined
  );
</script>

<div class="letterGrid {orientation} {minorKey ? 'minor' : 'major'} notranslate">
  {#each scaleTonics as tonic, index (index)}
    {@const tonicChord = new Chord(tonic, key)}
    {@const letterOrNumber =
      chordSystem == 'numbers'
        ? tonicChord.getInterval().number
        : chordSystem == 'letters'
          ? tonicChord.letter
          : ''}
    {@const roman =
      chordSystem == 'roman'
        ? (['I', 'II', 'III', 'IV', 'V', 'VI', 'VII'][tonicChord.getInterval().number - 1] ?? '?')
        : ''}
    {@const accidental =
      chordSystem == 'letters' ? tonicChord.accidental : tonicChord.getInterval().accidental}
    <button
      class="btn step{index} inline-flex items-center justify-center"
      class:bg-muted-100={!singleSelectedTonic && selectedChords?.some((c) => c.rootNote == tonic)}
      aria-pressed={singleSelectedTonic == tonic}
      aria-label={tonicChord.toAria()}
      onclick={() => dispatch('select', tonic)}
    >
      {letterOrNumber}
      {#if accidental}
        {@const sharpOrFlat = tonicChord.accidental == '#' ? 'sharp' : 'flat'}
        <svg class="_{sharpOrFlat}"><use xlink:href="#{sharpOrFlat}"></use></svg>
      {/if}
      {roman}
    </button>
  {/each}
</div>

<style>
  .btn {
    margin: 2px;
    padding: 3px 6px;
    min-width: 2.25rem;
    text-transform: none;
    border: solid 1px var(--muted-200);
    &[aria-pressed='true'] {
      border-color: var(--primary-900);
      color: white;
      background-color: var(--primary-900);
    }
  }

  ._sharp {
    width: 0.38em;
    height: 1em;
  }
  ._flat {
    width: 0.36em;
    height: 1em;
  }

  .letterGrid {
    display: grid;

    &.vertical {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(15, 1fr);
    }

    /* prettier-ignore */
    &.major.vertical {
    .step0 { grid-area: 1 / 1 / 3 / 2; }
    .step2 { grid-area: 3 / 1 / 5 / 2; }
    .step4 { grid-area: 5 / 1 / 7 / 2; }
    .step5 { grid-area: 7 / 1 / 9 / 2; }
    .step7 { grid-area: 9 / 1 / 11 / 2; }
    .step9 { grid-area: 11 / 1 / 13 / 2; }
    .step11 { grid-area: 13 / 1 / 15 / 2; }
    .step1 { grid-area: 2 / 2 / 4 / 3; }
    .step3 { grid-area: 4 / 2 / 6 / 3; }
    .step6 { grid-area: 8 / 2 / 10 / 3; }
    .step8 { grid-area: 10 / 2 / 12 / 3; }
    .step10 { grid-area: 12 / 2 / 14 / 3; }
  }
    /* prettier-ignore */
    &.minor.vertical {
    .step0 { grid-area: 1 / 1 / 3 / 2; }
    .step2 { grid-area: 3 / 1 / 5 / 2; }
    .step3 { grid-area: 5 / 1 / 7 / 2; }
    .step5 { grid-area: 7 / 1 / 9 / 2; }
    .step7 { grid-area: 9 / 1 / 11 / 2; }
    .step8 { grid-area: 11 / 1 / 13 / 2; }
    .step10 { grid-area: 13 / 1 / 15 / 2; }
    .step1 { grid-area: 2 / 2 / 4 / 3; }
    .step4 { grid-area: 6 / 2 / 8 / 3; }
    .step6 { grid-area: 8 / 2 / 10 / 3; }
    .step9 { grid-area: 12 / 2 / 14 / 3; }
    .step11 { grid-area: 14 / 2 / 16 / 3; }
  }

    &.horizontal {
      grid-template-columns: repeat(15, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }

    /* prettier-ignore */
    &.major.horizontal {
    .step0 { grid-area: 1 / 1 / 2 / 3; }
    .step1 { grid-area: 2 / 2 / 3 / 4; }
    .step2 { grid-area: 1 / 3 / 2 / 5; }
    .step3 { grid-area: 2 / 4 / 3 / 6; }
    .step4 { grid-area: 1 / 5 / 2 / 7; }
    .step5 { grid-area: 1 / 7 / 2 / 9; }
    .step6 { grid-area: 2 / 8 / 3 / 10; }
    .step7 { grid-area: 1 / 9 / 2 / 11; }
    .step8 { grid-area: 2 / 10 / 3 / 12; }
    .step9 { grid-area: 1 / 11 / 2 / 13; }
    .step10 { grid-area: 2 / 12 / 3 / 14; }
    .step11 { grid-area: 1 / 13 / 2 / 15; }
  }
    /* prettier-ignore */
    &.minor.horizontal {
    .step0 { grid-area: 1 / 1 / 2 / 3; }
    .step2 { grid-area: 1 / 3 / 2 / 5; }
    .step3 { grid-area: 1 / 5 / 2 / 7; }
    .step5 { grid-area: 1 / 7 / 2 / 9; }
    .step7 { grid-area: 1 / 9 / 2 / 11; }
    .step8 { grid-area: 1 / 11 / 2 / 13; }
    .step10 { grid-area: 1 / 13 / 2 / 15; }
    .step1 { grid-area: 2 / 2 / 3 / 4; }
    .step4 { grid-area: 2 / 6 / 3 / 8; }
    .step6 { grid-area: 2 / 8 / 3 / 10; }
    .step9 { grid-area: 2 / 12 / 3 / 14; }
    .step11 { grid-area: 2 / 14 / 3 / 16; }
  }
  }
</style>
