<script lang="ts">
  import { GuitarBrushinessSetting } from '@/band/instruments/guitar/settings/GuitarBrushinessSetting';
  import CycleThroughDropdown from '@/band/ui/components/CycleThroughDropdown.svelte';
  import PrettySlider from '@/band/ui/components/PrettySlider.svelte';
  import RhythmPatternVisualization from '@/band/ui/components/RhythmPatternVisualization.svelte';
  import PanelModalLayout from '@/band/ui/panels/PanelModalLayout.svelte';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';

  const bandMenu = getBandMenuState();

  let setting = $derived($bandMenu.guitarSettings.brushiness);

  function setBrushiness(val: number) {
    $bandMenu.guitar.setSetting('brushiness', setting.withBrushiness(val));
  }

  function onKeyDown(event: KeyboardEvent) {
    if (
      ['ArrowUp', 'ArrowDown'].includes(event.key) &&
      !(event.altKey || event.metaKey || event.shiftKey || event.ctrlKey)
    ) {
      event.preventDefault();
      setBrushiness(Math.min(1, setting.value + (event.key == 'ArrowUp' ? 1 : -1) / 14));
    }
  }
</script>

<svelte:window onkeydown={onKeyDown} />

<PanelModalLayout>
  {#snippet header()}
    <header class="panelHeader">
      Here you can adjust the blend between
      <span class="whitespace-nowrap">single picked notes</span>
      and big strums.
      <!-- <a href="#" class="font-medium">Learn more</a>. -->
    </header>
  {/snippet}

  {#snippet main()}
    <div class="p-4 pb-5">
      <div class="mb-7 mt-2 flex justify-center">
        <div
          style="box-shadow: 4px 6px 12px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.05), -4px -6px 12px 0px rgba(255, 255, 255, 0.95); border-radius: 12px; overflow: hidden; border: solid 2px var(--gray-100);"
        >
          <RhythmPatternVisualization
            actions={$bandMenu.guitar
              .coreOrFallbackForTimeSignature($bandMenu.timeSignature)
              .generateViz($bandMenu.guitarSettings)}
            timeSignature={$bandMenu.timeSignature}
            scale={1.9}
            highlightCurrentBeat={true}
            showBeatNumbers={false}
          />
        </div>
      </div>

      <div class="cycle-shadow mx-4 mb-5 rounded-lg bg-muted-100 p-1 pb-[5px]">
        <CycleThroughDropdown
          options={GuitarBrushinessSetting.presets.slice().reverse()}
          selectedValue={setting.closestPreset.value}
          on:select={(event) => setBrushiness(event.detail)}
          wrapAround={true}
          captureLeftRightKeys={true}
        />
      </div>

      <div class="text-center text-xs font-extrabold uppercase tracking-widest text-gray-300">
        Big Strums
      </div>
      <div class="my-3 flex h-52 items-center justify-center">
        <div class="w-52 origin-center -rotate-90">
          <PrettySlider
            min={0}
            max={14}
            step={1}
            value={setting.value * 14}
            tickCount={15}
            thumbDiameter={2.75}
            tickHeight={1.25}
            tickSqueezes={Array.from({ length: 15 }, (_, i) => 1 - (i + 2) / (14 + 2))}
            vertical={true}
            ariaLabel="pick-strum preference"
            on:change={({ detail }) => setBrushiness(detail / 14)}
          />
        </div>
      </div>
      <div class="text-center text-xs font-extrabold uppercase tracking-widest text-gray-300">
        Picked Notes
      </div>
    </div>
  {/snippet}
</PanelModalLayout>
