import { chop } from '@/band/instruments/mandolin/MandolinActions';
import type { MandolinCore } from '@/band/instruments/mandolin/MandolinCore';
import { swingOptions } from '@/band/swingOptions';

export const JigChopMandolinCore: MandolinCore = {
  id: 'cj',
  title: 'Chop on beats 3 and 6',

  timeSignatures: ['6/8'],

  linkedSettingsKeys: [],
  otherSettingsKeys: [],

  chordStyle: 'bluegrass',

  swingCategory: 'none',
  defaultSwing: swingOptions['0'],

  presets: [
    {
      name: 'Chop on beats 3 and 6',
      settings: {},
    },
  ],

  processMeasure(measure, _settings) {
    const theChop = chop({
      db: 0,
      attack: 'normal',
      chordSpread: 3,
      chordDuration: 0.1,
      chordAttenuation: 0,
    });
    if (measure.endOfSong) {
      return [null, null, theChop];
    }
    return measure.cells.flatMap(() => [null, null, theChop]);
  },

  generateViz(settings) {
    settings;
    return [
      null,
      null,
      {
        dir: 'd',
        biasY: 0.3,
        tail: 0.7,
        thickness: 0.8,
        sustain: 0,
      },
      null,
      null,
      {
        dir: 'd',
        biasY: 0.3,
        tail: 0.7,
        thickness: 0.8,
        sustain: 0,
      },
    ];
  },
};
