import { CanadianJigFeetCore } from '@/band/instruments/feet/cores/CanadianJigFeetCore';
import { CanadianReelFeetCore } from '@/band/instruments/feet/cores/CanadianReelFeetCore';
import { DownbeatFeetCore } from '@/band/instruments/feet/cores/DownbeatFeetCore';
import type { FeetCore } from '@/band/instruments/feet/FeetCore';

export const feetCores = {
  [DownbeatFeetCore.id]: DownbeatFeetCore,
  [CanadianReelFeetCore.id]: CanadianReelFeetCore,
  [CanadianJigFeetCore.id]: CanadianJigFeetCore,
} as const satisfies Record<string, FeetCore>;
