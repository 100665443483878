import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { rpcSendLoginCode } from '@/server/methods/accounts/rpcSendLoginCode';

Template.appEntryCodeForm.onCreated(() => {
  const instance = Template.instance();
  instance.codeResent = new ReactiveVar();
  if (!Session.get('loginCode.email')) FlowRouter.go('login');
});

Template.appEntryCodeForm.onRendered(() => {});

Template.appEntryCodeForm.helpers({
  codeResent() {
    return Template.instance().codeResent.get();
  },
  email() {
    return Session.get('loginCode.email');
  },
});

Template.appEntryCodeForm.events({
  'click .js-resendCode': async function (event, instance) {
    event.preventDefault();
    instance.codeResent.set(true);
    try {
      rpcSendLoginCode({ email: Session.get('loginCode.email') });
    } catch (err) {
      window.alert(err.message);
    }
  },
});
