import * as Sentry from '@sentry/browser';
import Cookies from 'js-cookie';
import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { eventTracker } from '@/browser/analytics/eventTracker';
import { rpcLookUpEmailAddress } from '@/server/methods/accounts/rpcLookUpEmailAddress';
import { rpcSendLoginCode } from '@/server/methods/accounts/rpcSendLoginCode';

Template.appEntryEmailPasswordForm.onCreated(() => {
  const instance = Template.instance();
  instance.submitting = new ReactiveVar();
  instance.passwordRequiredEmail = new ReactiveVar();
  instance.notFoundEmail = new ReactiveVar();
  instance.notFoundEmailDisposable = new ReactiveVar();
  instance.passwordIncorrect = new ReactiveVar();
});

Template.appEntryEmailPasswordForm.onRendered(() => {
  const instance = Template.instance();
  setTimeout(() => {
    instance.$('input[name="email"]').focus();
  }, 50);
  instance.find('.js-dynamicFormElements')._uihooks = {
    insertElement(node, next) {
      $(node)
        .insertBefore(next)
        .hide()
        .velocity(
          { opacity: [1, 0] },
          {
            duration: 300,
            easing: [0.4, 0, 0.4, 1],
          }
        )
        .velocity('slideDown', {
          duration: 300,
          easing: [0.4, 0, 0.4, 1],
          queue: false,
        });
    },
    removeElement(node) {
      $(node)
        .velocity(
          { opacity: [0, 1] },
          {
            duration: 300,
            easing: [0.4, 0, 0.4, 1],
          }
        )
        .velocity('slideUp', {
          duration: 300,
          easing: [0.4, 0, 0.4, 1],
          queue: false,
          complete: () => $(node).remove(),
        });
    },
  };
  instance.autorun(() => {
    if (instance.passwordRequiredEmail.get()) {
      instance.$('#email_field_container').removeAttr('style').removeAttr('inert').hide();
      setTimeout(() => instance.$('#email_field_container').slideDown(), 50);
      setTimeout(() => {
        instance.find('input[name="password"]').required = true;
        instance.$('input[name="password"]').focus();
      }, 100);
    }
  });
});

Template.appEntryEmailPasswordForm.helpers({
  showEmailHeader() {
    return (
      !Template.instance().passwordRequiredEmail.get() &&
      !Template.instance().notFoundEmail.get() &&
      !Template.instance().data?.signup
    );
  },
  showSignupHeader() {
    return (
      !Template.instance().passwordRequiredEmail.get() &&
      !Template.instance().notFoundEmail.get() &&
      Template.instance().data?.signup
    );
  },
  showExistingAccountHeader() {
    return Template.instance().passwordRequiredEmail.get() && true;
  },
  passwordIncorrect() {
    return Template.instance().passwordIncorrect.get();
  },
  redirectingToSubscribe() {
    return Template.instance().data?.redirect == 'subscribe';
  },
  redirectingToDiscourse() {
    return Template.instance().data?.redirect?.includes('discourse');
  },
  redirectingToBeta() {
    return Template.instance().data?.redirect?.includes('beta');
  },
  redirectingToPayment() {
    return Template.instance().data?.redirect?.includes('update-payment');
  },
  submittingOrOffline() {
    return Template.instance().submitting.get() || !/^connect/.test(Meteor.status().status);
  },
  emailDisabledAttr() {
    return Template.instance().submitting.get() || Template.instance().passwordRequiredEmail.get()
      ? 'disabled'
      : null;
  },
  submitting() {
    return Template.instance().submitting.get();
  },
  passwordRequiredEmail() {
    return Template.instance().passwordRequiredEmail.get();
  },
  notFoundEmail() {
    return Template.instance().notFoundEmail.get();
  },
  notFoundEmailDisposable() {
    return Template.instance().notFoundEmailDisposable.get();
  },
  submitButtonCaption() {
    return Template.instance().passwordRequiredEmail.get() ? 'Log In' : 'Next';
  },
  noInAppSignup() {
    return Meteor.isCordova && /Android/i.test(navigator.userAgent);
  },
  product() {
    return IAPBilling.monthlySub();
  },
  noFreeTrial() {
    return IAPBilling.monthlySub() && !IAPBilling.monthlySub().freeTrialPeriod;
  },
  extendedTrialLength() {
    if (/60$/i.test(Cookies.get('offer'))) return 60;
    if (/45$/i.test(Cookies.get('offer')) || /^(sbjc)$/i.test(Cookies.get('offer'))) return 45;
    if (/30$/i.test(Cookies.get('offer'))) return 30;
    if (/^(lukecard)$/i.test(Cookies.get('offer'))) return 30;
  },
});

Template.appEntryEmailPasswordForm.events({
  'click .js-tryAnotherEmail': function (event, instance) {
    const email = instance.notFoundEmail.get();
    setTimeout(() => {
      instance.$('input[name="email"]').val(email).focus();
    }, 50);
    instance.notFoundEmail.set(null);
    instance.notFoundEmailDisposable.set(null);
  },
  'click .js-forgotPassword': function (event, instance) {
    event.preventDefault();
    instance.submitting.set(true);
    if (Meteor.isCordova) {
      sendLoginCode(instance, instance.passwordRequiredEmail.get());
    } else {
      resetPassword(instance, instance.passwordRequiredEmail.get());
    }
  },
  'click .js-signupWithNotFoundEmail': function (event, instance) {
    instance.submitting.set(true);
    sendLoginCode(instance, instance.notFoundEmail.get());
  },
  'submit form': function (event, instance) {
    event.preventDefault();
    event.stopPropagation();
    instance.submitting.set(true);
    instance.passwordIncorrect.set(false);

    if (instance.passwordRequiredEmail.get()) {
      submitPassword(instance);
    } else {
      submitEmail(instance);
    }
  },
});

const submitEmail = async (instance) => {
  const email = instance.$('.js-emailInput').val().trim();
  try {
    const { verified, hasPassword } = await rpcLookUpEmailAddress({ email });
    if (verified && hasPassword) {
      instance.submitting.set(false);
      instance.passwordRequiredEmail.set(email);
      setTimeout(() => {
        instance.$('input[name="password"]').focus();
      }, 50);
    } else {
      sendLoginCode(instance, email);
    }
  } catch (err) {
    if (String(err.error).startsWith('email-not-found')) {
      if (instance.data?.signup) {
        sendLoginCode(instance, email);
      } else {
        instance.submitting.set(false);
        instance.notFoundEmail.set(email);
        instance.notFoundEmailDisposable.set(/disposable/.test(err.error));
        eventTracker.loginFailed({ label: 'email-not-found' });
        setTimeout(() => {
          instance.$('input[name="email"]').focus();
        }, 50);
      }
    } else {
      instance.submitting.set(false);
      Sentry.withScope((scope) => {
        scope.setExtra('email', email);
        Sentry.captureException(err);
      });
      bootbox.alert({
        message: err.reason || err.error,
      });
    }
  }
};

const submitPassword = (instance) => {
  const email = instance.passwordRequiredEmail.get();
  const password = instance.$('[name="password"]').val();
  Meteor.loginWithPassword(email, password, (err) => {
    if (err) {
      instance.submitting.set(false);
      if (err.reason == 'Incorrect password') {
        eventTracker.loginFailed({ label: 'wrong-password' });
        instance.passwordIncorrect.set(true);
        setTimeout(() => {
          instance.$('input[name="password"]').focus();
        }, 50);
      } else {
        Sentry.withScope((scope) => {
          scope.setExtra('email', email);
          Sentry.captureException(err);
        });
        window.alert(err.reason);
      }
    } else {
      successfulLogin({ redirect: instance.data?.redirect });
    }
  });
};

const successfulLogin = ({ redirect } = {}) => {
  const route = FlowRouter.getRouteName();
  if (route == 'discourse-sso') {
    FlowRouter.reload();
  } else if (route == 'landing-page' || route?.startsWith('login') || route == 'mobile-subscribe') {
    Meteor.setTimeout(() => FlowRouter.go(redirect || 'song-index'), 0);
  } else {
    history.pushState({ loggedIn: true }, ''); // for password managers
  }
};

const sendLoginCode = async (instance, email) => {
  try {
    await rpcSendLoginCode({ email });
    Session.set('loginCode.email', email);
    FlowRouter.go('login-code');
  } catch (err) {
    Sentry.captureException(err);
    bootbox.alert({
      message: 'Could not send login code. ' + (err.reason || err.message),
    });
    instance.submitting.set(false);
  }
};

const resetPassword = (instance, email) => {
  Accounts.forgotPassword({ email }, (err) => {
    if (!err) {
      eventTracker.passwordResetSent();
      Session.set('email-inbox-to-open', email);
      FlowRouter.go('forgot-password-sent');
    } else {
      eventTracker.passwordResetSendError({ label: err.reason });
      window.alert('Error while sending reset-password email. ' + (err.reason || err.message));
      instance.submitting.set(false);
    }
  });
};
