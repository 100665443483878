import { ReactiveVar } from 'meteor/reactive-var';
import { Tracker } from 'meteor/tracker';
import { MusicLibrary } from '@/library/MusicLibrary';
import type { SearchResultRecord } from '@/library/MusicLibrarySearchIndex';
import { stringToLowercaseAlpha } from '@/utilities/stringToLowercaseAlpha';

type MusicLibrarySearchQuery = {
  text?: string;
  all?: boolean;
  allSongs?: boolean;
  allMedleys?: boolean;
  numbers?: boolean;
  firstLetter?: string;
  own?: boolean;
};

export class MusicLibrarySearcher {
  private _query = new ReactiveVar<MusicLibrarySearchQuery | null>(null);
  private _results = new ReactiveVar<SearchResultRecord[]>([]);
  private _resultsCount = new ReactiveVar<number>(0);
  private _reloadResultsDep = new Tracker.Dependency();

  constructor() {
    this.initSearchWatcher();
  }

  setQuery(query: MusicLibrarySearchQuery | string): void {
    if (typeof query == 'string') {
      this._query.set(
        query.length > 0
          ? {
              text: query
                .replace(/[\u2018\u2019]/g, "'")
                .replace(/&/i, ' and ')
                .replace(/[^a-z0-9À-ȕ'#*@ ]/i, ' ')
                .replace(/  +/, ' ')
                .trim(),
            }
          : null
      );
    } else {
      this._query.set(query);
    }
  }

  query(): MusicLibrarySearchQuery | null {
    return this._query.get();
  }

  queryText(): string | undefined {
    return this._query.get()?.text;
  }

  getQueryParams(): { search?: string } {
    return this.queryText() ? { search: this.queryText() } : {};
  }

  queryPresent(): boolean {
    return !!this._query.get();
  }

  results(): SearchResultRecord[] {
    return this._results.get();
  }

  resultsCount(): number {
    return this._resultsCount.get();
  }

  refreshResults(): void {
    this._reloadResultsDep.changed();
  }

  initSearchWatcher(): void {
    Tracker.autorun(() => {
      if (!MusicLibrary.index.readyToSearch()) {
        this._results.set([]);
        this._resultsCount.set(0);
        return;
      }

      const query = this._query.get();

      if (!query) return;

      MusicLibrary.index.watchForUpdates();
      this._reloadResultsDep.depend();

      const searchParams: {
        own?: boolean;
        nameRegex?: RegExp;
        firstLetter?: string;
        medley?: boolean;
      } = {
        own: query.own,
      };

      if (query.all) {
        // no filter
      } else if (query.allSongs) {
        searchParams.medley = false;
      } else if (query.allMedleys) {
        searchParams.medley = true;
      } else if (query.numbers) {
        searchParams.nameRegex = /^\d/;
      } else if (query.text ? /^[a-z]$/i.test(query.text) : query.firstLetter) {
        //@ts-expect-error TypeScript is just being stupid
        const letter = (query.text || query.firstLetter).toLowerCase();
        searchParams.firstLetter = letter;
      } else if (query.text && /^([0-9]|[A-Za-zÀ-ȕ].)/.test(query.text)) {
        searchParams.nameRegex = searchQueryToRegex(query.text);
      }

      const songs = MusicLibrary.index.search(searchParams).map((song) => {
        return {
          ...song,
          listCount: MusicLibrary.lists.countWithItem(song._id),
        };
      });

      this._results.set(songs);
      this._resultsCount.set(songs.length);
    });
  }
}

const searchQueryToRegex = (text: string): RegExp => {
  text = stringToLowercaseAlpha(text);
  for (const [one, two] of searchStringEquivilants) {
    text = text.replace(new RegExp(`\\b(${one}|${two})\\b`), `(${one}|${two})`.replace('?', ''));
  }
  text = text.replace(new RegExp(`\\b(st|saint)\\b`), `(st[ .]|saint)`.replace('?', ''));
  text = text.replace(new RegExp('ing?\\b'), 'in(g\\b)?');
  return new RegExp('\\b' + text);
};

const searchStringEquivilants = [
  ['2', 'two'],
  ['3', 'three'],
  ['4', 'four'],
  ['5', 'five'],
  ['6', 'six'],
  ['7', 'seven'],
  ['8', 'eight'],
  ['9', 'nine'],
  ['10', 'ten'],
  ['11', 'eleve?n?'],
  ['12', 'twelv?e?'],
  ['15', 'fiftee?n?'],
  ['16', 'sixtee?n?'],
  ['17', 'sevente?e?n?'],
  ['20', 'twenty?'],
  ['40', 'forty'],
  ['8th', 'eighth'],
  ['stony', 'stoney'],
  ['gray', 'grey'],
  ['cory', 'corey'],
  ['sled', 'sledd'],
  ['ol', 'old'],
  ['round?', 'aroun?d?'],
  ['sciota', 'scioty'],
  ['mr', 'miste?r?'],
  ['waterbo?u?n?d?', 'water bo?u?n?d?'],
  ['high on a', 'high on the'],
];
