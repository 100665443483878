import { Mongo } from 'meteor/mongo';
import type { z } from 'zod';
import type { SongSchema } from '@/collections/schemas/SongSchema';

export type SongRecord = z.infer<typeof SongSchema> & {
  unsynced?: boolean | string;
};

export type SongRecordSection = SongRecord['sections'][number];

export type SongRecordMeasure = SongRecordSection['measures'][number];

export const SongsCollection = new Mongo.Collection<SongRecord>('songs');
SongsCollection.deny({ insert: () => true, update: () => true, remove: () => true });
