import { Tracker } from 'meteor/tracker';
import type { Song } from '@/chart/Song';
import { Crnt } from '@/Crnt';

class GlobalSongState {
  #songObject = $state<Song | undefined>();

  constructor() {
    Tracker.autorun(() => {
      this.#songObject = Crnt.song();
    });
  }

  #song = $derived(
    this.#songObject
      ? {
          _id: this.#songObject.editMode(),
          editMode: this.#songObject.editMode(),
          name: this.#songObject.name(),
          tpm: this.#songObject.tpm(),
          key: this.#songObject.key(),
          timeSignature: this.#songObject.timeSignature(),
          sections: this.#songObject.sections.serialize({ preserveIds: true }),
          focus: this.#songObject.focus.bounds(),
        }
      : undefined
  );

  song() {
    return this.#song;
  }
}

const songState = new GlobalSongState();

export function crntSong() {
  return songState.song();
}
