<script lang="ts">
  import { Meteor } from 'meteor/meteor';
  import { throttle } from 'underscore';
  import { BluegrassGuitarCore } from '@/band/instruments/guitar/cores/BluegrassGuitarCore';
  import { BluegrassWaltzGuitarCore } from '@/band/instruments/guitar/cores/BluegrassWaltzGuitarCore';
  import { SyncopatedBluegrassGuitarCore } from '@/band/instruments/guitar/cores/SyncopatedBluegrassGuitarCore';
  import { patternLandscapeForGuitarCore } from '@/band/instruments/guitar/patternLandscapeForGuitarCore';
  import { bluegrassSpreadGradients } from '@/band/instruments/guitar/settings/bluegrassSpreadGradients';
  import { syncopatedSpreadGradients } from '@/band/instruments/guitar/settings/syncopatedSpreadGradients';
  import CycleThroughDropdown from '@/band/ui/components/CycleThroughDropdown.svelte';
  import PrettySlider from '@/band/ui/components/PrettySlider.svelte';
  import RhythmPatternVisualization from '@/band/ui/components/RhythmPatternVisualization.svelte';
  import SliderRule from '@/band/ui/components/SliderRule.svelte';
  import CircularStrumShapeSpatialUI from '@/band/ui/panels/CircularStrumShapeSpatialUI.svelte';
  import PanelModalLayout from '@/band/ui/panels/PanelModalLayout.svelte';
  import { getBandMenuState } from '@/band/ui/shell/band-menu-state';
  import { decapitalize } from '@/utilities/decapitalize';

  let tweenThumb = $state(false);

  const unTweenThumbAfterDelay = throttle(() => (tweenThumb = false), 200, {
    leading: false,
    trailing: true,
  });

  const bandMenu = getBandMenuState();

  let core = $derived($bandMenu.guitar.coreOrFallbackForTimeSignature($bandMenu.timeSignature));
  let landscape = $derived(patternLandscapeForGuitarCore(core));

  let setting = $derived($bandMenu.guitarSettings.circularStrumShape);
  let strokePowers = $derived(setting.powersForLandscape(landscape));
  let polarCoordinates = $derived<[number, number]>([setting.polarR, setting.polarTheta]);

  let timeSignature = $derived($bandMenu.timeSignature);

  let actions = $derived(core.generateViz($bandMenu.guitarSettings));

  let quadrantLabels = $derived({
    top: 'Busy',
    bottom: 'Spacious',
    left:
      core == SyncopatedBluegrassGuitarCore
        ? 'Downstrokes'
        : core == BluegrassWaltzGuitarCore
          ? 'First Upstroke'
          : 'Booms',
    right: core == SyncopatedBluegrassGuitarCore ? 'Upstroke' : 'Chucks',
  });

  let captionAdverb = $derived(
    setting.polarR >= 0.8 ? 'Very' : setting.polarR < 0.5 && setting.polarR > 0.2 ? 'Slightly' : ''
  );

  function handleRadialUpdate(e: CustomEvent<[r: number, theta: number]>) {
    $bandMenu.guitar.setSetting('circularStrumShape', setting.withPolarCoordinates(...e.detail));
  }

  let showDebugUI = $state(false);
</script>

<PanelModalLayout>
  {#snippet header()}
    <!-- svelte-ignore a11y_no_static_element_interactions -->
    <header class="panelHeader" ondblclick={() => (showDebugUI = !showDebugUI)}>
      <div class="mx-auto max-w-[22em]">
        You can shift the strumming pattern to match your desired feel.
        <!-- TODO: link to a real help article -->
        <!-- <a href="#" class="whitespace-nowrap font-medium"> Learn more</a>. -->
      </div>
    </header>
  {/snippet}

  {#snippet main()}
    <div class="px-4 pb-2 pt-4">
      <div class="mb-7 mt-2 flex justify-center">
        <div
          style="box-shadow: 4px 6px 12px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.05), -4px -6px 12px 0px rgba(255, 255, 255, 0.95); border-radius: 12px; overflow: hidden; border: solid 2px var(--gray-100);"
        >
          <RhythmPatternVisualization
            {actions}
            {timeSignature}
            scale={1.9}
            highlightCurrentBeat={true}
            showBeatNumbers={true}
          />
        </div>
      </div>

      <div class="cycle-shadow rounded-lg bg-muted-100 p-1 pb-[5px]">
        <CycleThroughDropdown
          options={landscape.presets.map((preset) => ({
            caption: captionAdverb ? `${captionAdverb} ${decapitalize(preset.name)}` : preset.name,
            value: preset,
          }))}
          selectedValue={setting.closestPresetForLandscape(landscape)}
          on:select={(event) => {
            $bandMenu.guitar.setSetting(
              'circularStrumShape',
              setting.withPolarCoordinates(...event.detail.position)
            );
            tweenThumb = true;
            unTweenThumbAfterDelay();
          }}
          wrapAround={true}
          captureLeftRightKeys={true}
          doCrossfades={true}
        />
      </div>

      <div class="pt-1">
        <CircularStrumShapeSpatialUI
          {polarCoordinates}
          labels={quadrantLabels}
          on:change={handleRadialUpdate}
          {tweenThumb}
        />
      </div>

      {#if Meteor.isDevelopment && showDebugUI}
        <div class="flex h-[64px] w-full items-end justify-stretch gap-0.5">
          {#each strokePowers as power, i (i)}
            <div class="flex flex-1 flex-col items-center justify-end">
              <span class="text-muted-600" style="opacity: {0.4 + 0.6 * power}">
                {(power * 100).toFixed(0)}
              </span>

              <div
                class="w-full rounded-sm bg-muted-300 text-center {i % 2 == 0 ? 'bold' : ''}"
                style="opacity: {0.2 + 0.8 * power}; flex-basis: {40 * power}px;"
              ></div>
            </div>
          {/each}
        </div>

        <SliderRule />
        <PrettySlider
          min={0}
          max={14}
          step={1}
          value={$bandMenu.guitarSettings.brushiness.value * 14}
          tickCount={15}
          thumbDiameter={2.75}
          tickHeight={1.25}
          tickSqueezes={Array.from({ length: 15 }, (_, i) => 1 - (i + 2) / (14 + 2))}
          ariaLabel="pick-strum preference"
          on:change={({ detail }) => {
            $bandMenu.guitar.setSetting(
              'brushiness',
              $bandMenu.guitarSettings.brushiness.withBrushiness(detail / 14)
            );
          }}
        />
        <SliderRule lowerLabel="Picks" upperLabel="Strums" />

        <div class="flex h-[64px] w-full items-end justify-stretch gap-0.5">
          {#each $bandMenu.guitarSettings.brushiness.spreadsForGradients(core == BluegrassGuitarCore ? bluegrassSpreadGradients : syncopatedSpreadGradients) as spread, i (i)}
            <div class="flex flex-1 flex-col items-center justify-end">
              <span class="text-muted-600" style="opacity: {0.4 + (0.6 * spread) / 5}">
                {spread.toFixed(1)}
              </span>

              <div
                class="w-full rounded-sm bg-muted-300 text-center {i % 2 == 0 ? 'bold' : ''}"
                style="opacity: {0.2 + (0.8 * spread) / 5}; flex-basis: {(40 * spread) / 5}px;"
              ></div>
            </div>
          {/each}
        </div>
      {/if}
    </div>
  {/snippet}
</PanelModalLayout>
