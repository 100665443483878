import SongIndexItem, { createSongListTippyOnParent } from '@/ui/SongIndexItem';
import { isMobile } from '@/ui-helpers/isMobile';

function getScrollParent(node) {
  const isElement = node instanceof HTMLElement;
  const overflowY = isElement && window.getComputedStyle(node).overflowY;
  const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden';

  if (!node) return null;

  if (isScrollable && node.scrollHeight >= node.clientHeight) {
    return node;
  }

  return getScrollParent(node.parentNode) || document.body;
}

Template.songIndexList.onRendered(() => {
  const instance = Template.instance();
  instance.songs = [];
  instance.lastRequestedUpdate = new ReactiveVar();
  instance.lastUpdateCompleted = new ReactiveVar();
  instance.listParentElement = instance.find('.songIndexList');
  instance.scrollingParent = getScrollParent(instance.listParentElement);
  instance.autorun(function songIndexListRenderAutorun() {
    instance.lastRequestedUpdate.set(Random.id());
    const songs = Template.currentData().list;
    const listId = Template.currentData().listId;
    const noLinks = Template.currentData().noLinks;
    // const visibleQuery = clientModules.songSearch.getVisibleQuery();
    // const searchHighlight = (visibleQuery && visibleQuery.length < 2) ? visibleQuery : null;
    const stale = Tracker.nonreactive(
      () => instance.lastRequestedUpdate.get() !== instance.lastUpdateCompleted.get()
    );
    if (stale) {
      const listElements = getListElements({ songs, listId, noLinks });
      replaceListHtml(instance, listElements);
      instance.lastUpdateCompleted.set(instance.lastRequestedUpdate.get());
    }
  });

  if (!isMobile) {
    instance.tippyInstance = createSongListTippyOnParent(instance.view._domrange.parentElement);
  }
});

function replaceListHtml(instance, newElements) {
  var existingParent = instance.view._domrange.parentElement;
  var existingList = instance.listParentElement;
  var newList = existingList.cloneNode(false);
  newList.innerHTML = newElements.join('');
  existingParent.replaceChild(newList, existingList);
  instance.listParentElement = newList;
}

function getListElements({ songs, searchHighlight, listId, noLinks }) {
  const newElements = [];
  const otherUsers = true;
  const userId = Meteor.userId();
  for (var i = 0; i < songs.length; i++) {
    newElements[i] = SongIndexItem.renderHtml({
      song: songs[i],
      isOwn: songs[i].userId == userId,
      otherUsers,
      loggedIn: !!userId,
      searchHighlight,
      noLinks,
      listId,
    });
  }
  return newElements;
}
