import type { SampleDefinition } from '@/audio/engine/base/Sample';
import { SampleLibrary } from '@/audio/engine/base/SampleLibrary';
import { FMBCordovaPlugin } from '@/audio/engine/fmb/FMBCordovaPlugin';
import { FMBSample } from '@/audio/engine/fmb/FMBSample';
import AudioPackageLoader from '@/audio/samples/AudioPackageLoader';

export class FMBSampleLibrary extends SampleLibrary {
  readonly sampleClass = FMBSample;

  async prepare(sampleDefinitions: SampleDefinition[]): Promise<void> {
    // This will store all of the samples that use a given source file
    const srcSamplesHash: Record<string, FMBSample[]> = {};

    // This will store the package used for each source file
    const srcPackageHash: Record<string, string> = {};

    await Promise.all(
      sampleDefinitions.map(async (sampleDefinition) => {
        const duration = await AudioPackageLoader.getDurationOfSample(sampleDefinition.id);
        sampleDefinition.duration = duration;
        const sample = new FMBSample(sampleDefinition);
        this.sampleMap.set(sample.id, sample);
        (srcSamplesHash[sampleDefinition.id] ??= []).push(sample);
        if (sampleDefinition.packageId) {
          srcPackageHash[sampleDefinition.id] = sampleDefinition.packageId;
        }
      })
    );

    const defsForFMB = [...this.sampleMap.values()].map((sample) => ({
      sampleId: sample.id,
      packageId: sample.packageId,
      duration: sample.duration,
    }));

    const { samplesThatNeedStoring } = await FMBCordovaPlugin.defineSamples(defsForFMB);

    for (const sample of this.sampleMap.values()) {
      if (
        !samplesThatNeedStoring.find(
          (stns) => stns.sampleId == sample.id && stns.packageId == sample.packageId
        )
      ) {
        sample.loaded = true;
      }
    }
  }
}
