import type { guitarChordStrumSpecs } from '@/band/instruments/guitar/guitarChordStrumSpecs';
import type { Chord } from '@/music/Chord';
import type { ChordTypeCode } from '@/music/ChordTypeCode';

export const guitarChordDefinitionsByType: {
  [Type in ChordTypeCode]: [
    string,
    `${Chord['rootNote']}${Type}`,
    keyof typeof guitarChordStrumSpecs,
  ][];
} = {
  //// OPEN CHORDS

  // #region Major

  '': [
    ['3_0003', 'G', 'g'],
    ['002220', 'A', 'a'],
    ['332010', 'C', 'c'],
    ['200232', 'D', 'd'],
    ['D00232', 'D', 'dropD'],
    ['022100', 'E', 'e'],
    ['133211', 'F', 'e'], // so that open strings can be used
    // ---
    ['13321_', 'F', 'eX'],
    ['_3555_', 'C', 'aX'],
    // ---
    ['D5023D', 'D', 'dD'],
    ['133211', 'F', 'dD'],
    ['_32013', 'C', 'aD'],
    ['35_033', 'G', 'dD'],
    ['007650', 'A', 'aD'],
  ],

  // #endregion

  // #region Major with added tones

  '6': [
    ['3_2033', 'G6', 'g'],
    ['00422_', 'A6', 'a'],
    ['332210', 'C6', 'c'],
    ['200432', 'D6', 'd'],
    ['022120', 'E6', 'e'],
    // ---
    ['3_243_', 'G6', 'eX'],
    ['_3525_', 'C6', 'aX'],
    // ['_877a_', 'F6', 'aX'], // otherwise it gets too high
    // ['_2_142', 'B6', 'aX'],
    // ['__1313', 'Eb6', 'dX'],
  ],

  '69': [
    ['3_2233', 'G69', 'g'],
    ['_32233', 'C69', 'c'],
    // ---
    ['3_2233', 'G69', 'eX'],
    ['_32233', 'C69', 'aX'],
  ],

  // #endregion

  // #region Major sevenths

  '^': [
    ['3_0002', 'G^', 'g'],
    ['002120', 'A^', 'a'],
    ['332000', 'C^', 'c'],
    ['200222', 'D^', 'd'],
    ['02110_', 'E^', 'e'],
    ['133210', 'F^', 'd'],
    // ---
    ['3_443_', 'G^', 'eX'],
    ['_3545_', 'C^', 'aX'],
    // ['_1_231', 'Bb^', 'aX'],
    // ['__1333', 'Eb^', 'dX'],
  ],

  '^9': [
    ['3_423_', 'G^9', 'g'],
    ['002100', 'A^9', 'a'],
    ['_00220', 'D^9', 'd'],
    // ---
    ['3_423_', 'G^9', 'eX'],
    ['_3543_', 'C^9', 'aX'],
  ],

  '^13': [
    // ---
    ['_6_788', 'Eb^13', 'aX'],
    ['3_445_', 'G^13', 'eX'],
  ],

  // #endregion

  // #region Dominant sevenths

  '7': [
    ['3_0001', 'G7', 'g'],
    ['002020', 'A7', 'a'],
    ['221202', 'B7', 'c'],
    ['332310', 'C7', 'c'],
    ['200212', 'D7', 'd'],
    ['D00212', 'D7', 'dropD'],
    ['022130', 'E7', 'e'],
    ['131211', 'F7', 'e'], // needed for some unknown reason for alt bass to work
    // ---
    ['3_343_', 'G7', 'eX'],
    ['_3535_', 'C7', 'aX'],
    // ['_1_131', 'Bb7', 'aX'],
    // ['__1323', 'Eb7', 'dX'],
  ],

  '9': [
    ['3_0201', 'G9', 'g'],
    ['002423', 'A9', 'a'],
    ['332333', 'C9', 'c'],
    ['020102', 'E9', 'e'],
    // ---
    ['3_323_', 'G9', 'eX'], // adjusted to remove third
    ['_3233_', 'C9', 'aX'],
    // ['_4_441', 'C#9', 'aX'],
    // ['__2132', 'E9', 'dX'],
  ],

  '11': [
    ['_02032', 'A11', 'a'],
    ['_2_220', 'B11', 'a'],
    ['2_210_', 'F#11', 'e'],
    // ---
    ['_3_331', 'C11', 'aX'],
    ['3_321_', 'G11', 'eX'],
  ],

  '13': [
    ['3_3000', 'G13', 'g'],
    ['002002', 'A13', 'a'],
    ['332233', 'C13', 'c'],
    ['020122', 'E13', 'e'],
    // ---
    ['3_345_', 'G13', 'eX'],
    ['_3_355', 'C13', 'aX'],
  ],

  // #endregion

  // #region Suspended dominants

  '7s': [
    ['_00213', 'D7s', 'd'],
    ['020200', 'E7s', 'e'],
    ['002030', 'A7s', 'a'],
    // ---
    ['35353_', 'G7s', 'eX'],
    ['_3536_', 'C7s', 'aX'],
  ],

  '9s': [
    ['3_0011', 'G9s', 'g'],
    ['_02433', 'A9s', 'a'],
    ['_3_331', 'C9s', 'c'],
    ['_00213', 'D9s', 'd'],
    ['020232', 'E9s', 'e'],
    // ---
    ['3_321_', 'G9s', 'eX'],
    ['_3333_', 'C9s', 'aX'],
  ],

  '13s': [
    ['_68698', 'Eb13s', 'aX'],
    ['3_3555', 'G13s', 'eX'],
  ],

  // #endregion

  // #region Minor

  'm': [
    ['002210', 'Am', 'a'],
    ['_00231', 'Dm', 'd'],
    ['D00231', 'Dm', 'dropD'],
    ['022000', 'Em', 'e'],
    // ---
    ['24422_', 'F#m', 'eX'],
    ['_2443_', 'Bm', 'aX'],
    // ---
    ['D5023D', 'Dm', 'dD'],
    ['022000', 'Em', 'dD'],
    ['002255', 'Am', 'aD'],
    ['224432', 'Bm', 'aD'],
  ],

  // #endregion

  // #region Minor with added tones

  'm6': [
    ['002212', 'Am6', 'a'],
    ['_00201', 'Dm6', 'd'],
    ['D00201', 'Dm6', 'dropD'],
    ['022020', 'Em6', 'e'],
    // ---
    ['2_122_', 'F#m6', 'eX'],
    ['_2413_', 'Bm6', 'aX'],
    // ['_2_132', 'Bm6', 'aX'],
    // ['__1312', 'Ebm6', 'dX'],
  ],

  'm69': [
    ['3_233_', 'Gm69', 'eX'],
    ['_7567_', 'Em69', 'aX'],
  ],

  'mb6': [
    ['3_133_', 'Gmb6', 'eX'],
    ['_31113', 'Cmb6', 'aX'],
    ['_7_587', 'Emb6', 'aX'],
    ['_3545_', 'Emb6/C', 'aX'],
  ],

  // #endregion

  // #region Minor sevenths

  'm7': [
    ['002010', 'Am7', 'a'],
    ['_00211', 'Dm7', 'd'],
    ['D00211', 'Dm7', 'dropD'],
    ['022030', 'Em7', 'e'],
    // ---
    ['3_333_', 'Gm7', 'eX'],
    ['_2423_', 'Bm7', 'aX'],
    // ['_1_121', 'Bb7', 'aX'],
    // ['__1322', 'Ebm7', 'dX'],
  ],

  'm9': [
    ['_5355_', 'Dm9', 'aX'], // raised
    ['__3143', 'Fm9', 'dX'],
    ['3_333_', 'Gm9', 'eX'], // added
    ['6465__', 'Bbm9', 'eX'],
  ],

  'm11': [
    ['3_331_', 'Gm11', 'eX'],
    ['_6_674', 'Em11', 'aX'],
  ],

  // #endregion

  // #region Minor major-sevenths

  'm^': [
    ['3_433_', 'Gm^', 'eX'],
    ['_2433_', 'Bm^', 'aX'],
  ],

  'm^9': [
    ['_3243_', 'Cm^9', 'aX'],
    ['5_645_', 'Am^9', 'eX'],
  ],

  // #endregion

  // #region Diminished

  'o': [
    ['_01212', 'Ao', 'a'],
    ['_12020', 'A#o', 'a'],
    ['_01212', 'D#o', 'd'],
    ['2_121_', 'F#o', 'e'],
    // ---
    ['3_232_', 'Go', 'eX'],
    ['_3424_', 'Co', 'aX'],
    // ['_2_131', 'Bo', 'aX'],
    // ['__1212', 'Ebo', 'dX'],
  ],

  '0': [
    ['_2323_', 'B0', 'a'],
    ['3_332_', 'G0', 'e'],
    // ---
    ['2_221_', 'G0', 'eX'],
    ['_2323_', 'B0', 'aX'],
    // ['_2_231', 'B0', 'aX'],
    // ['__1222', 'Eb0', 'dX'],
  ],

  'o^': [
    ['__3454', 'Fo^', 'dX'],
    ['_5666_', 'Do^', 'aX'],
  ],

  'o9': [
    ['7566__', 'Ao9', 'eX'],
    ['_5345_', 'Do9', 'aX'],
  ],

  // #endregion

  // #region Augmented

  '+': [
    ['3_1003', 'G+', 'g'],
    ['_03221', 'A+', 'a'],
    ['_21003', 'B+', 'a'],
    ['_32110', 'C+', 'c'],
    ['__0332', 'D+', 'd'],
    ['032110', 'E+', 'e'],
    // ---
    ['_32110', 'C+', 'aX'],
  ],

  '+7': [
    ['3_344_', 'G+7', 'eX'],
    ['_6_687', 'Db+7', 'aX'],
    ['_656_7', 'Db+7', 'aX'],
  ],

  // #endregion

  // #region Power & sus

  '5': [
    ['3_0033', 'G5', 'g'],
    ['002255', 'A5', 'a'],
    ['1133_1', 'Bb5', 'a'],
    ['_00235', 'D5', 'd'],
    ['D00235', 'D5', 'dropD'],
    ['022400', 'E5', 'e'],
    ['133_11', 'F5', 'e'],
  ],

  '2': [
    // sus2
    ['3_0233', 'G2', 'g'],
    ['002200', 'A2', 'a'],
    ['332033', 'C2', 'c'],
    ['_00230', 'D2', 'd'],
    ['D00230', 'D2', 'dropD'],
    ['024400', 'E2', 'e'],
    // ---
  ],

  '4': [
    // sus4
    ['3_0013', 'G4', 'g'],
    ['002230', 'A4', 'a'],
    ['333011', 'C4', 'c'],
    ['_00233', 'D4', 'd'],
    ['D00233', 'D4', 'dropD'],
    ['022200', 'E4', 'e'],
    // ---
  ],

  // 'add9': [],

  // 'madd9': [],

  // #endregion

  // #region Basic altered dominants

  '7b9': [
    ['4342__', 'Ab7b9', 'eX'],
    ['_4343_', 'Db7b9', 'aX'],
  ],

  '7#9': [
    ['4344__', 'Ab7#9', 'eX'], // muddy with that 3 fret...
    ['_2123_', 'B7#9', 'aX'],
  ],

  '7b5': [
    ['3_342_', 'G7b5', 'eX'],
    ['_5_574', 'D7b5', 'aX'],
  ],
  '7#11': [
    // make sure #11 is on top
    ['3_342_', 'G7#11', 'eX'],
    ['_5_574', 'D7#11', 'aX'],
  ],

  '7#5': [
    ['3_344_', 'G7#5', 'eX'],
    ['_6_687', 'Eb7#5', 'aX'],
    ['_989_a', 'Gb7#5', 'aX'], // lest it get too high
  ],
  '7b13': [
    // make sure b13 is on top
    ['3_344_', 'G7b13', 'eX'],
    ['_6_687', 'Eb7b13', 'aX'],
    ['_989_a', 'Gb7b13', 'aX'], // lest it get too high
  ],

  '7b9s': [],

  // #region Altered extended dominants

  '9#11': [],

  '13b9': [],

  '13#11': [],

  // #endregion

  // #region Altered major sevenths

  '^7#11': [],

  '^7#5': [],

  // #endregion

  // TODO: add missing chord shapes
  // TODO: check chords - range, voicing
  // TODO: add shell voicings and expanded voicings

  // #region Doubly-altered sevenths (not used)

  // '...': [
  //   ['3_3444', 'G7b9b13', 'eX'],
  //   ['_54546', 'D7b9b13', 'aX'],

  //   ['3_3424', 'G7b9b5', 'eX'],
  //   ['_32322', 'C7b9b5', 'aX'],

  //   ['3_3426', 'G7#9b5', 'eX'],
  //   ['_32342', 'C7#9b5', 'aX'],
  //   ['3_3446', 'G7#9b13', 'eX'],
  //   ['_32344', 'C7#9b13', 'aX'],

  //   ['3_3444', 'G7b9#5', 'eX'],
  //   ['_32324', 'C7b9#5', 'aX'],
  // ],

  // #endregion
};
