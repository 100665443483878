import * as Sentry from '@sentry/browser';
import { Meteor } from 'meteor/meteor';
import { FlowRouter } from 'meteor/ostrio:flow-router-extra';
import { ReactiveVar } from 'meteor/reactive-var';
import { rpcCalculateProration } from '@/server/methods/accounts/rpcCalculateProration';
import waitUntilReactive from '@/utilities/waitUntilReactive';

Template.switchCurrencyPage.onCreated(() => {
  const instance = Template.instance();
  instance.data.setTitle('Switch Currencies');

  if (!Meteor.userId()) {
    FlowRouter.go('login', {}, {});
  }

  instance.refundAmount = new ReactiveVar(undefined);

  waitUntilReactive(() => Meteor.user()?.roles && Meteor.user()?.subscription).then(async () => {
    if (!Meteor.user()?.roles?.includes('paid-member')) {
      FlowRouter.go('account');
      Bert.alert(
        'Your subscription is not active. You can sign up for a new one if you like...',
        'success'
      );
    } else {
      try {
        const { amount, currency } = await rpcCalculateProration();
        instance.refundAmount.set(amount);
      } catch (err) {
        abortForm(err);
      }
    }
  });
});

Template.switchCurrencyPage.helpers({
  loaded() {
    return typeof Template.instance().refundAmount.get() == 'number';
  },
  zeroRefund() {
    return Template.instance().refundAmount.get() === 0;
  },
  refundAmount() {
    return (Template.instance().refundAmount.get() / 100).toFixed(2);
  },
});

const abortForm = (err) => {
  Sentry.captureException(err);
  console.error(err);
  Meteor.setTimeout(
    () =>
      bootbox.alert(
        `Sorry, something went wrong with the self-serve currency-switching form. Please <b><a href="mailto:support@strummachine.com?subject=Currency%20switch&amp;body=Subscription%20ID%3A%20${
          Meteor.user()?.subscription?.subscriptionId
        }" target="_blank">email us</a></b> and we’ll handle it manually.`
      ),
    400
  );
  FlowRouter.go('/app/account');
};
