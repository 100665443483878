import closeNearestTippy from '@/ui/dropdowns/closeNearestTippy';
import defineTippyDropdown from '@/ui/dropdowns/defineTippyDropdown';

defineTippyDropdown(Template.medleyEditReps, {
  id: 'medley-song-reps-menu',
});

Template.medleyEditReps.events({
  'click .js-repsButtons [data-value]': function (event, instance) {
    const reps = +$(event.currentTarget).data('value');
    const song = Template.currentData().song;
    song.medleyPrefs?.setReps(reps);
    closeNearestTippy(event.currentTarget);
  },
});

Template.medleyEditReps.helpers({
  reps() {
    const song = Template.currentData().song;
    return song.medleyPrefs?.reps();
  },
});
